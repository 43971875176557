export const CHANGE_EXPORT_PROGRESS = 'CHANGE_EXPORT_PROGRESS';

export const GET_EXPORTED_DATA_SUCCESS = 'GET_EXPORTED_DATA_SUCCESS';
export const GET_EXPORTED_DATA_FAILURE = 'GET_EXPORTED_DATA_FAILURE';

export const SET_EXPORT_INFO = 'SET_EXPORT_INFO';
export const CANCEL_EXPORT = 'CANCEL_EXPORT';

export const RESET_EXPORTED_DATA = 'RESET_EXPORTED_DATA';

export enum EFileExtensions {
  XLS = 'xls',
  PDF = 'pdf',
}

export enum ExtensionParamsValue {
  EXCEL = 'EXCEL',
  PDF = 'PDF',
}

export enum EFileName {
  ACTIVE_AND_INACTIVE_HOURS = 'activeAndInactiveHours',
  PROJECTS = 'projects',
  USERS_HOURS_ABSENCES = 'usersHoursAbsences',
  EMPLOYEES_TIMESHEET = 'employeesTimesheet',
  EMPLOYEES = 'employees',
  EMPLOYEES_ISSUE_TYPES = 'employeesIssueTypes',
  ABSENCE_PERIODS = 'absencePeriods',
  DAYS_LIMIT = 'daysLimit',
  USER_HOURS = 'userHours',
  PROJECT_HOURS = 'prjectHours',
  MEMBERS = 'members',
  CANDIDATES = 'candidates',
  INCOMES_EXPENSES = 'incomesExpenses',
  ACTIVITY = 'activity',
}

export const EXPORT_PROJECTS_REPORT_REQUEST = 'EXPORT_PROJECTS_REPORT_REQUEST';
export const EXPORT_EMPLOYEES_REPORT_REQUEST = 'EXPORT_EMPLOYEES_REPORT_REQUEST';
export const EXPORT_EMPLOYEES_TIMESHEET_REPORT_REQUEST = 'EXPORT_EMPLOYEES_TIMESHEET_REPORT_REQUEST';
export const EXPORT_EMPLOYEES_ISSUE_TYPES_REPORT_REQUEST = 'EXPORT_EMPLOYEES_ISSUE_TYPES_REPORT_REQUEST';
export const EXPORT_ACTIVE_INACTIVE_HOURS_REPORT_REQUEST = 'EXPORT_ACTIVE_INACTIVE_HOURS_REPORT_REQUEST';
export const EXPORT_USERS_HOURS_ABSENCES_REPORT_REQUEST = 'EXPORT_USERS_HOURS_ABSENCES_REPORT_REQUEST';
export const EXPORT_ABSENCE_PERIODS_REPORT_REQUEST = 'EXPORT_ABSENCE_PERIODS_REPORT_REQUEST';

export const EXPORT_POLL_DATA_REQUEST = 'EXPORT_POLL_DATA_REQUEST';
export const EXPORT_DAYS_USED_LIMIT_REQUEST = 'EXPORT_DAYS_USED_LIMIT_REQUEST';
export const EXPORT_USER_HOURS_REQUEST = 'EXPORT_USER_HOURS_REQUEST';
export const EXPORT_PROJECT_HOURS_REQUEST = 'EXPORT_PROJECT_HOURS_REQUEST';
export const EXPORT_USERS_REQUEST = 'GET_EXPORT_USERS_REQUEST';
export const EXPORT_CANDIDATES_REQUEST = 'EXPORT_CANDIDATES_REQUEST';
export const EXPORT_INCOMES_EXPENSES_REPORT_REQUEST = 'EXPORT_INCOMES_EXPENSES_REPORT_REQUEST';
export const EXPORT_ACTIVITY_REQUEST = 'EXPORT_ACTIVITY_REQUEST';
