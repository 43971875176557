import React, { useCallback } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { GradeInfoType } from '../../../types/libraries';

type ModalDeleteGradeProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onDeleteRequest: (data: Record<string, unknown>) => void;
  gradeData: GradeInfoType;
  gradeError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
};

function ModalDeleteGrade({
  isOpen,
  onCloseRequest,
  onDeleteRequest,
  gradeData,
  gradeError,
  isLoading,
  resetErrors,
}: ModalDeleteGradeProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetErrors();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.deleteModalWithItemNameTitle, {
        name: gradeData?.name,
      })}
      size={'small'}
      classNameModal="center"
    >
      <ErrorMessage>{gradeError}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            onDeleteRequest({ data: gradeData.id, callback: resetAndExit });
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteGrade;
