import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { NewPoll, POLL_VALIDATION_SCHEMA } from '../../../enums/questionnaires.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import Input from '../../Input';
import Select from '../../Select';
import Checkbox from '../../Checkbox';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import { isEmpty } from 'lodash-es';
import { EventPreviewInfo } from '../../../enums/schedule.enum';
import Icon from '../../Icon';
import { v4 } from 'uuid';
import Avatar from '../../Profile/Avatar';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { closedValue, messageClosedPoll, messagePublishedPoll } from '../../../pages/Polls/utils';
import { UserInfo } from '../../../enums/users.enum';
import { PollTemplateCategoryInfoType } from '../../../types/questionnaires';
import { scrollToError, scrollToLastError } from '../../../utils';

type ModalEditPollProps = {
  onCloseRequest: () => void;
  categories: Array<any>;
  responders: Array<any>;
  templates: Array<any>;
  error: string | null;
  isLoading: boolean;
  isOpen: boolean;
  onSubmit: (data: any, callback: any) => void;
  officesList: Array<any>;
  eventList: Array<EventPreviewInfo>;
  poll: NewPoll;
  isPollCanNotBeEdit?: boolean;
};
const allId = v4();

const ModalEditPoll = ({
  isOpen,
  onCloseRequest,
  categories,
  responders,
  templates,
  error,
  isLoading,
  onSubmit,
  officesList,
  eventList,
  poll,
  isPollCanNotBeEdit,
}: ModalEditPollProps) => {
  const [isAnonymousChecked, setIsAnonymousChecked] = useState(false);
  const [shouldChangeTemplate, setShouldChangeTemplate] = useState<boolean>(false);

  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    resetForm,
    handleChange,
    setFieldValue,
    setFieldError,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: new NewPoll(poll),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: POLL_VALIDATION_SCHEMA,
    onSubmit: data => {
      const newData = { ...data, isEditFromModal: true };
      if (poll?.status !== closedValue) {
        onSubmit(POLL_VALIDATION_SCHEMA.cast(newData), resetAndExit);
      }
    },
  });

  useEffect(() => {
    poll && setValues(poll);
    if (isEmpty(templates.filter(template => template.id === poll.templateId))) {
      setFieldValue('categoryId', '');
      setFieldValue('categoryName', '');
      setFieldValue('templateId', '');
      setFieldValue('template', {});
    }
  }, [poll, templates, isOpen]);

  useEffect(() => {
    if (values.templateId == '') {
      setShouldChangeTemplate(true);
      setFieldError('templateId', intl.formatMessage(messages.changeTemplateLabel));
    } else {
      setFieldError('templateId', '');
      setShouldChangeTemplate(false);
    }
  }, [values.templateId]);

  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetForm();
  }, []);

  const officeTemplate = useCallback(
    (name: string) => (
      <>
        <div className="table__data-wrapper">
          <Icon iconName="building" />
          <span style={{ marginLeft: '5px' }}>{name}</span>
        </div>
      </>
    ),
    [],
  );

  const userTemplate = useCallback(
    (user: any) => (
      <>
        <div className="table__data-wrapper">
          <Avatar userInfo={user} size="tiny" externalClass="avatar-select" fileSize={36} />
          <span>{`${user.firstName} ${user.secondName}`}</span>
        </div>
      </>
    ),
    [],
  );

  const categoryOptions = useMemo(
    () =>
      categories?.map(({ name, id }: PollTemplateCategoryInfoType) => ({
        label: name,
        value: id,
      })),
    [categories],
  );

  const getCategoryByName = useMemo(() => categoryOptions?.find((el: any) => el.label === values?.categoryName), [
    values,
    categoryOptions,
  ]);

  const filteredTemplates = useMemo(() => templates.filter((el: any) => el.categoryId === getCategoryByName?.value), [
    values,
  ]);

  const templatesOptions = useMemo(
    () =>
      filteredTemplates?.map((item: { id: string; name: string; categoryId: string }) => ({
        value: item.id,
        label: item.name,
        categoryId: item.categoryId,
      })),
    [filteredTemplates],
  );

  const eventOptions = useMemo(
    () =>
      eventList
        ?.sort(
          (a: EventPreviewInfo, b: EventPreviewInfo) =>
            new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
        )
        .map(event => ({
          value: event,
          label: event.title || event.eventTypeName,
        })),
    [eventList],
  );

  const currentEvent = useMemo(() => eventOptions?.find((event: any) => values?.relatedEventId === event.value?.id), [
    eventOptions,
    values.relatedEventId,
  ]);

  const listResponders = useMemo(
    () =>
      responders?.map((item: UserInfo) => ({
        ...item,
        value: item.id,
        isOffice: false,
        label: item.fullName,
      })),
    [responders],
  );

  const respondersOptions = useMemo(() => {
    const offices = officesList?.map(office => ({
      ...office,
      isOffice: true,
      value: office.id,
      label: officeTemplate(office?.name),
    }));

    const allOffices = {
      value: allId,
      id: allId,
      name: intl.formatMessage(messages.allOffices),
      label: (
        <>
          <div className="table__data-wrapper">
            <Icon iconName="globe" />
            <span style={{ marginLeft: '10px' }}>{intl.formatMessage(messages.allOffices)}</span>
          </div>
        </>
      ),
    };

    if (values.isGeneral) return [allOffices, ...offices];

    const availableResponders = listResponders?.filter(
      user => isEmpty(values.officeIds) || values.officeIds.every(id => id !== user.office?.id),
    );

    return [allOffices, ...offices, ...availableResponders];
  }, [values.isGeneral, officesList, listResponders, values.officeIds]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleChangeCategory = useCallback(
    (item: any) => {
      setFieldValue('categoryId', item.value);
      setFieldValue('categoryName', item.label);
      setFieldValue('templateId', '');
      setFieldValue('template', {});
    },
    [values],
  );

  const handleChangeResponders = useCallback((items: any) => {
    const hasAllItem = !!items.find((el: any) => el.id === allId);
    setFieldValue('isGeneral', hasAllItem);

    const respondersWithoutOffices = items?.filter((el: any) => !el.isOffice);
    const getSelectedOfficeIds = items?.filter((el: any) => el.isOffice).map((el: any) => el.id);
    const getResponderIds = respondersWithoutOffices
      ?.filter((user: any) => getSelectedOfficeIds.every((id: string) => id !== user.office?.id))
      .map((el: any) => el.id);

    setFieldValue('responderIds', hasAllItem ? [] : getResponderIds);
    setFieldValue('officeIds', getSelectedOfficeIds);
  }, []);

  const handleChangeOrganizers = useCallback(
    (items: any) => {
      const updatedOrganizers = responders?.filter((resp: any) => items?.some((el: any) => resp.id === el.value));
      const organizers = updatedOrganizers.map((item: any) => ({
        ...item,
        value: item.id,
        label: userTemplate(item),
      }));
      const getOrganizerIds = organizers?.map((el: any) => el.value);
      setFieldValue('organizerIds', getOrganizerIds);
      setFieldValue('organizers', organizers);
    },
    [values],
  );

  const handleChangeTemplate = useCallback(
    (item: any) => {
      const chosenTemplate = filteredTemplates.find((el: any) => el.id === item.value);
      setFieldValue('templateId', chosenTemplate?.id);
      setFieldValue('template', { ...chosenTemplate });
    },
    [values, filteredTemplates],
  );

  const handleChangePollName = useCallback(
    event => {
      setFieldValue('name', event.target.value);
    },
    [values],
  );

  const handleChangeRelatedEvent = useCallback((item: any) => {
    setFieldValue('relatedEvent', item.value || null);
    setFieldValue('relatedEventId', item.value?.id || '');
  }, []);

  const handleChangeDate = useCallback(
    el => {
      if (el) {
        setFieldValue('deadline', moment(el).endOf('D').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss));
      } else {
        setFieldValue('deadline', '');
      }
    },
    [values],
  );

  const handleCheckAnonymous = useCallback(
    e => {
      setIsAnonymousChecked((prev: any) => {
        setFieldValue('anonymous', !prev);
        return !prev;
      });
      handleChange(e);
    },
    [values, isAnonymousChecked],
  );

  const respondersValues = useMemo(() => {
    const selectedItems = respondersOptions.filter((opt: any) => {
      if (opt.value === allId && values.isGeneral) {
        return true;
      }

      if (opt.isOffice) {
        return values.officeIds?.some(id => id === opt.value);
      } else {
        return values.responderIds?.some(id => id === opt.value);
      }
    });

    return selectedItems;
  }, [values.officeIds, values.responderIds, values.isGeneral]);

  const selectedOrganizers = useMemo(
    () =>
      values?.organizers?.map((organizer: any) => ({
        value: organizer.id,
        label: userTemplate(organizer),
      })),
    [values],
  );

  const selectedCategoryValue = useMemo(
    () =>
      values.categoryName
        ? {
            value: categories?.find((el: any) => el.label === values?.categoryName),
            label: values.categoryName,
          }
        : null,
    [categories, values],
  );

  const selectedTemplate = useMemo(() => {
    const selected = values?.template;
    return selected.id ? { value: selected.id, label: selected.name } : null;
  }, [values]);

  const {
    categorySelect,
    templateSelect,
    pollNameInput,
    anonymousMode,
    respondersSelect,
    relatedEventSelect,
    deadlineLabel,
    organizersSelect,
    descriptionLabel,
  } = messages;

  const messageCondition = useMemo(() => (poll?.status === closedValue ? messageClosedPoll : messagePublishedPoll), [
    poll?.status,
  ]);

  const formatEventOptionLabel = useCallback(data => {
    return data.isClearable ? (
      data.label
    ) : (
      <div className="related-event__wrapper">
        <span className="related-event__name">{data.value?.title || data.value?.eventTypeName}</span>
        <span className="related-event__date">{data.value?.datePeriod}</span>
      </div>
    );
  }, []);

  useEffect(() => {
    scrollToLastError();
  }, [error, messageCondition]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.modalEditTitle)}
      size={'small'}
      shouldCloseOnOverlayClick={false}
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(categorySelect)}
              name={'category'}
              options={categoryOptions}
              handleChange={handleChangeCategory}
              value={selectedCategoryValue}
              isSearchable
            />
            <Select
              label={intl.formatMessage(templateSelect)}
              name={'template'}
              options={templatesOptions}
              handleChange={handleChangeTemplate}
              hasError={hasError('template')}
              errorMessage={errors?.templateId}
              isSearchable
              value={selectedTemplate}
            />
            <Input
              id={'pollName'}
              name={'name'}
              label={intl.formatMessage(pollNameInput)}
              onChange={handleChangePollName}
              hasError={hasError('name')}
              errorMessage={errors?.name}
              defaultValue={values?.name}
            />
            <Checkbox
              externalClass="form__checkbox-capitalazie-label"
              onChange={handleCheckAnonymous}
              id="anonymous"
              label={intl.formatMessage(anonymousMode)}
              checked={values?.anonymous}
            />
            <Select
              isMulti
              isSearchable
              label={intl.formatMessage(respondersSelect)}
              name={'responders'}
              options={respondersOptions}
              handleChange={handleChangeResponders}
              value={respondersValues}
              hasError={hasError('responderIds')}
              errorMessage={errors.responderIds as string}
            />
            <Select
              isMulti
              isSearchable
              label={intl.formatMessage(organizersSelect)}
              name={'organizers'}
              options={listResponders}
              handleChange={handleChangeOrganizers}
              hasError={hasError('organizerIds')}
              errorMessage={errors?.organizers as string}
              defaultValue={selectedOrganizers}
              value={selectedOrganizers}
            />
            <Select
              isClearable
              label={intl.formatMessage(relatedEventSelect)}
              name={'relatedEvent'}
              options={eventOptions}
              handleChange={handleChangeRelatedEvent}
              value={currentEvent || null}
              formatOptionLabel={formatEventOptionLabel}
            />
            <div className={'form__input-block form__input-block--half'}>
              <Input
                id={'deadlineDate'}
                name="deadline"
                label={intl.formatMessage(deadlineLabel)}
                onChange={e => handleChangeDate(e.target.value)}
                hasError={hasError('deadline')}
                errorMessage={errors?.deadline}
                type={'date'}
                defaultValue={values.deadline.split(' ')[0]}
              />
            </div>
            <Input
              tag="textarea"
              label={intl.formatMessage(descriptionLabel)}
              name="description"
              onChange={handleChange}
              defaultValue={values?.description}
            />
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          {isPollCanNotBeEdit && <ErrorMessage>{messageCondition}</ErrorMessage>}
        </div>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            externalClass={'button--modal'}
            type="submit"
            loading={isLoading}
            disabled={isLoading || isPollCanNotBeEdit || shouldChangeTemplate}
          >
            <FormattedMessage {...messages.sendButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalEditPoll;
