import Modal from '../../Modal';
import Button from '../../Button';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import { AssessmentsInfo } from '../../../enums/competencies.enum';

type ModalDeleteDepartmentProps = {
  onCloseRequest: () => void;
  onDeleteRequest: (id: string) => void;
  assessmentError: string | RejectValueErrors[] | null;
  assessmentData: AssessmentsInfo;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalDeleteAssessment({
  onCloseRequest,
  onDeleteRequest,
  assessmentData,
  assessmentError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalDeleteDepartmentProps) {
  useEffect(() => {
    return () => {
      resetErrors();
    };
  }, []);

  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={`${intl.formatMessage(messages.deleteModalTitle)} ${assessmentData?.employee?.fullName}?`}
      size={'small'}
      classNameModal="center"
    >
      <ErrorMessage>{assessmentError}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCloseRequest}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => onDeleteRequest(assessmentData.id)}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteAssessment;
