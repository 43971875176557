import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  newIncomeTypeTitle: {
    id: 'finance.income.type.modal.new.income.type.title.new.income.type',
    defaultMessage: 'New Income Type',
  },
  editIncomeTypeTitle: {
    id: 'finance.income.type.modal.edit.income.type.title.edit.income.type',
    defaultMessage: 'Edit Income Type',
  },
});
