import { Base64 } from 'js-base64';
import isEmpty from 'lodash-es/isEmpty';
const sha512 = require('js-sha512');

type AuthData = {
  accessToken: string;
  refreshToken: string;
  accessExpiryDate: string;
};

export function getAuthData(): AuthData {
  const authData = localStorage.getItem('authData');
  return authData && JSON.parse(authData);
}

export function setAuthData(authData: AuthData) {
  localStorage.setItem('authData', JSON.stringify(authData));
}

export function removeAuthData() {
  localStorage.removeItem('authData');
}

export function isAuthenticated() {
  return !isEmpty(getAuthData());
}

export function authToken() {
  if (!isAuthenticated()) return null;
  return getAuthData().accessToken;
}

export function encodePassword(str: string, salt: string | '', base64?: boolean) {
  try {
    const hash = sha512(str);
    return base64 ? Base64.encode(`${hash}${salt}`) : sha512(`${hash}${salt}`);
  } catch (error) {
    return null;
  }
}
