import { defineMessages } from 'react-intl';
import { editButton, deleteButton, newButton } from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  newButton,
  nameTitle: {
    id: 'resumes.technical.skills.column.skill.group.skills',
    defaultMessage: 'Skill Group / Skills',
  },
  pageTitle: {
    id: 'resumes.technical.skills.title.technical.skills',
    defaultMessage: 'Technical Skills',
  },
});
