import { UsersFilterParams } from './../enums/params/users.params';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/filters.constants';
import { AssessmentsParams } from '../enums/params/competencies.params';
import { FinanceProjectsParams } from '../enums/params/finance.params';
import { PollParams } from '../enums/params/questionnaires.params';
import { EventsParams } from '../enums/params/schedule.params';
import { SavedFilterParams } from '../enums/params/filters.params';
import { SavedFilter } from '../enums/filters.enum';

export const getUsersFilter = (): AnyAction => ({
  type: ActionTypes.GET_USERS_FILTER_REQUEST,
});

export const setUsersFilterValue = (data: string[]): AnyAction => ({
  type: ActionTypes.SET_USERS_FILTER_VALUE,
  payload: data,
});

export const setUsersFilterParams = (data: Partial<UsersFilterParams>): AnyAction => ({
  type: ActionTypes.SET_USERS_FILTER_PARAMS,
  payload: data,
});

export const resetUsersFilterParams = (): AnyAction => ({
  type: ActionTypes.RESET_USERS_FILTER_PARAMS,
});

export const getOfficesFilter = (): AnyAction => ({
  type: ActionTypes.GET_OFFICES_FILTER_REQUEST,
});

export const setOfficesFilterValue = (data: string[]): AnyAction => ({
  type: ActionTypes.SET_OFFICES_FILTER_VALUE,
  payload: data,
});

export const getDepartmentsFilter = (): AnyAction => ({
  type: ActionTypes.GET_DEPARTMENTS_FILTER_REQUEST,
});

export const setDepartmentsFilterValue = (data: string[]): AnyAction => ({
  type: ActionTypes.SET_DEPARTMENTS_FILTER_VALUE,
  payload: data,
});

export const getProjectGroupsFilter = (): AnyAction => ({
  type: ActionTypes.GET_PROJECT_GROUPS_FILTER_REQUEST,
});

export const setProjectGroupsFilterValue = (data: string[]): AnyAction => ({
  type: ActionTypes.SET_PROJECT_GROUPS_FILTER_VALUE,
  payload: data,
});

export const getCompetenciesFilter = (): AnyAction => ({
  type: ActionTypes.GET_COMPETENCIES_FILTER_REQUEST,
});

export const setCompetenciesFilterValue = (data: string[]): AnyAction => ({
  type: ActionTypes.SET_COMPETENCIES_FILTER_VALUE,
  payload: data,
});

export const getEventTypesFilter = (): AnyAction => ({
  type: ActionTypes.GET_EVENT_TYPES_FILTER_REQUEST,
});

export const setEventTypesFilterValue = (data: string[]): AnyAction => ({
  type: ActionTypes.SET_EVENT_TYPES_FILTER_VALUE,
  payload: data,
});

export const getPollsFilter = (data?: Partial<PollParams>): AnyAction => ({
  type: ActionTypes.GET_POLLS_FILTER_REQUEST,
  payload: data,
});

export const getPollTemplatesFilter = (): AnyAction => ({
  type: ActionTypes.GET_POLL_TEMPLATES_FILTER_REQUEST,
});

export const getPollTemplatesCategoryFilter = (): AnyAction => ({
  type: ActionTypes.GET_POLL_TEMPLATES_CATEGORY_FILTER_REQUEST,
});

export const getEventsFilter = (data?: Partial<EventsParams>): AnyAction => ({
  type: ActionTypes.GET_EVENTS_FILTER_REQUEST,
  payload: data,
});

export const getRecognitionTypesFilter = (): AnyAction => ({
  type: ActionTypes.GET_RECOGNITION_TYPES_FILTER_REQUEST,
});

export const getCompanyPositionsFilter = (): AnyAction => ({
  type: ActionTypes.GET_COMPANY_POSITIONS_FILTER_REQUEST,
});

export const getLanguageLevelsFilter = (): AnyAction => ({
  type: ActionTypes.GET_LANGUAGE_LEVELS_FILTER_REQUEST,
});

export const getForeignLanguagesFilter = (): AnyAction => ({
  type: ActionTypes.GET_FOREIGN_LANGUAGES_FILTER_REQUEST,
});

export const getTechnicalSkillsFilter = (): AnyAction => ({
  type: ActionTypes.GET_TECHNICAL_SKILLS_FILTER_REQUEST,
});

export const getProjectsFilter = (): AnyAction => ({
  type: ActionTypes.GET_PROJECTS_FILTER_REQUEST,
});

export const getSpecializationsFilter = (): AnyAction => ({
  type: ActionTypes.GET_SPECIALIZATIONS_FILTER_REQUEST,
});

export const getAssessmentsFilter = (data?: Partial<AssessmentsParams>): AnyAction => ({
  type: ActionTypes.GET_ASSESSMENTS_FILTER_REQUEST,
  payload: data,
});

export const getCompetenciesTypesFilter = (): AnyAction => ({
  type: ActionTypes.GET_COMPETENCIES_TYPES_FILTER_REQUEST,
});

export const getGradesFilter = (): AnyAction => ({
  type: ActionTypes.GET_GRADES_FILTER_REQUEST,
});

export const getEmployeeGroupsFilter = (): AnyAction => ({
  type: ActionTypes.GET_EMPLOYEE_GROUPS_FILTER_REQUEST,
});

export const getClientsFilter = (): AnyAction => ({
  type: ActionTypes.GET_CLIENTS_FILTER_REQUEST,
});

export const getCurrenciesFilter = (): AnyAction => ({
  type: ActionTypes.GET_CURRENCIES_FILTER_REQUEST,
});

export const getProjectTypesFilter = (): AnyAction => ({
  type: ActionTypes.GET_PROJECT_TYPES_FILTER_REQUEST,
});

export const getSkillLevelsFilter = (): AnyAction => ({
  type: ActionTypes.GET_SKILL_LEVELS_FILTER_REQUEST,
});

export const getIncomeTypesFilter = (): AnyAction => ({
  type: ActionTypes.GET_INCOME_TYPES_FILTER_REQUEST,
});

export const getExpenseTypesFilter = (): AnyAction => ({
  type: ActionTypes.GET_EXPENSE_TYPES_FILTER_REQUEST,
});

export const getSuppliersFilter = (): AnyAction => ({
  type: ActionTypes.GET_SUPPLIERS_FILTER_REQUEST,
});

export const getFinanceProjectsFilter = (data?: Partial<FinanceProjectsParams>): AnyAction => ({
  type: ActionTypes.GET_FINANCE_PROJECTS_FILTER_REQUEST,
  payload: data,
});

export const getCandidateStatusesFilter = (): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_STATUSES_FILTER_REQUEST,
});

export const getCandidateTechnologiesFilter = (): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_TECHNOLOGIES_FILTER_REQUEST,
});

export const getCandidateSpecializationsFilter = (): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_FILTER_REQUEST,
});

export const getCandidateReceivingSourcesFilter = (): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_FILTER_REQUEST,
});

export const getHrCuratorsFilter = (): AnyAction => ({
  type: ActionTypes.GET_HR_CURATORS_FILTER_REQUEST,
});

export const getJiraProjectsFilter = (): AnyAction => ({
  type: ActionTypes.GET_JIRA_PROJECTS_FILTER_REQUEST,
});

export const setJiraProjectsFilterValue = (data: string[]): AnyAction => ({
  type: ActionTypes.SET_JIRA_PROJECTS_FILTER_VALUE,
  payload: data,
});

export const resetEventsFilter = (): AnyAction => ({
  type: ActionTypes.RESET_EVENTS_FILTER,
});

export const resetAssessmentsFilter = (): AnyAction => ({
  type: ActionTypes.RESET_ASSESSMENTS_FILTER,
});

export const resetFiltersState = (): AnyAction => ({
  type: ActionTypes.RESET_FILTERS_STATE,
});

export const setSavedFiltersParams = (data: Partial<SavedFilterParams>): AnyAction => ({
  type: ActionTypes.SET_SAVED_FILTERS_LIST_PARAMS,
  payload: data,
});

export const createNewSavedFilter = (data: { data: SavedFilter; callback: () => void }): AnyAction => ({
  type: ActionTypes.CREATE_SAVED_FILTER_REQUEST,
  payload: data,
});

export const editSavedFilter = (data: { data: SavedFilter; callback?: () => void }): AnyAction => ({
  type: ActionTypes.EDIT_SAVED_FILTER_REQUEST,
  payload: data,
});

export const deleteSavedFilter = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_SAVED_FILTER_REQUEST,
  payload: data,
});

export const resetSavedFilterErrors = (): AnyAction => ({
  type: ActionTypes.RESET_SAVED_FILTER_ERRORS,
});
