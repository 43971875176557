import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import Avatar from '../../Profile/Avatar';
import { DisplayingResumeSections, Resume } from '../../../enums/resumes.enum';
import classNames from 'classnames';
import Logo from '../../Logo';

type CVDocumentProps = {
  resume: Resume;
  displayingResumeSections: DisplayingResumeSections;
  inExportModal?: boolean;
};

const CVDocument = ({
  resume,
  displayingResumeSections: {
    includePhoto = true,
    includeName = true,
    includePosition = true,
    includeExperience = true,
    includeEducation = true,
    includeLanguages = true,
    includeSummary = true,
    includePersonalHighlights = true,
    includeInterestsAndHobbies = true,
    includeTechnicalSkills = true,
    includeProjects = true,
  },
  inExportModal = false,
}: CVDocumentProps) => {
  return (
    <div className={classNames('page__cv-document', { 'export-modal': inExportModal })}>
      <div className="page__cv-document-head" id="page__cv-document-head">
        <div className="page__cv-document-head-info">
          <div className="page__cv-document-company">
            <FormattedMessage {...messages.companyNameLabel} />
          </div>
          <div className="page__cv-document-type">
            <FormattedMessage {...messages.memberResumeLabel} />
          </div>
        </div>
        <div className="page__cv-document-logo" id="cv-document-logo">
          <Logo />
        </div>
      </div>
      <div className="page__cv-document-body">
        {includePhoto && (
          <div className="page__cv-document-avatar-wrap">
            <Avatar
              //  @ts-ignore
              userInfo={resume.employeeInfo}
              customSize={inExportModal ? 160 : 260}
              customFontSize={75}
              externalClass="square"
              fileSize={inExportModal ? 320 : 520}
            />
          </div>
        )}
        <div className="page__cv-document-info">
          {includeName && <div className="page__cv-document-name">{resume.employee.fullName}</div>}
          {includePosition && <div className="page__cv-document-post">{resume.companyPosition?.displayName}</div>}
          {includeExperience && (
            <div className="page__cv-document-experience">
              <b>
                {resume.experienceYears}
                <FormattedMessage {...messages.yearsLabel} /> {resume.experienceMonths}{' '}
                <FormattedMessage {...messages.monthsLabel} />
              </b>{' '}
              <FormattedMessage {...messages.ofExperienceLabel} />
            </div>
          )}
          {includeEducation && (
            <div className="page__cv-document-education">
              <div className="page__cv-document-education-degree">{resume.educationFirstString}</div>
              <div>{resume.educationSecondString}</div>
            </div>
          )}
          {includeLanguages && (
            <div className="page__cv-document-langs">
              {resume.languages.map(item => (
                <div className="page__cv-document-lang" key={item.foreignLanguageId}>
                  <b>{item.foreignLanguage?.name}</b> {item.languageLevel?.name && ' - ' + item.languageLevel?.name}
                </div>
              ))}
            </div>
          )}
        </div>
        {includeTechnicalSkills && (
          <div className="page__cv-document-stack-wrapper">
            <table className="page__cv-document-stack">
              <thead className="page__cv-document-stack-thead">
                <tr className="page__cv-document-stack-row">
                  {resume.technicalSkills.map(item => (
                    <th className="page__cv-document-stack-th" key={item.skillGroupId}>
                      {item.skillGroupName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="page__cv-document-stack-tbody">
                <tr className="page__cv-document-stack-row">
                  {resume.technicalSkills.map(item => (
                    <td className="page__cv-document-stack-td" key={item.skillGroupId}>
                      <ul>
                        {item.skills.map(skillsItem => (
                          <li key={skillsItem.id}>{skillsItem.skill}</li>
                        ))}
                      </ul>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className="page__cv-document-additional">
          {(includeSummary || includePersonalHighlights || includeInterestsAndHobbies) && (
            <div className="page__cv-document-additional-column">
              {includeSummary && (
                <div className="page__cv-document-additional-item">
                  <div className="page__cv-document-additional-item-title">
                    <FormattedMessage {...messages.summaryLabel} />
                  </div>
                  <div
                    className="page__cv-document-additional-item-content"
                    dangerouslySetInnerHTML={{ __html: resume.summary }}
                  />
                </div>
              )}
              {includePersonalHighlights && (
                <div className="page__cv-document-additional-item">
                  <div className="page__cv-document-additional-item-title">
                    <FormattedMessage {...messages.personalHighlightsLabel} />
                  </div>
                  <div
                    className="page__cv-document-additional-item-content"
                    dangerouslySetInnerHTML={{
                      __html: resume.personalHighlights,
                    }}
                  />
                </div>
              )}
              {includeInterestsAndHobbies && (
                <div className="page__cv-document-additional-item">
                  <div className="page__cv-document-additional-item-title">
                    <FormattedMessage {...messages.interestAndHobbiesLabel} />
                  </div>
                  <div
                    className="page__cv-document-additional-item-content"
                    dangerouslySetInnerHTML={{
                      __html: resume.interestsAndHobbies,
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div className="page__cv-document-additional-column">
            {includeProjects && (
              <div className="page__cv-document-additional-item">
                <div className="page__cv-document-additional-item-title">
                  <FormattedMessage {...messages.remarkableProjectsTitle} />
                </div>
                <div className="page__cv-document-additional-item-content">
                  {resume.projects.map(project => (
                    <div className="page__cv-remarkable-project" key={project.id}>
                      <div className="page__cv-remarkable-project-name">{project.name}</div>
                      <div
                        className="page__cv-remarkable-project-descr"
                        dangerouslySetInnerHTML={{ __html: project.description }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CVDocument;
