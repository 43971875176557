import { defineMessages } from 'react-intl';
import {
  nameColumn,
  newButton,
  editButton,
  statusLabel,
  cloneButton,
  deleteButton,
  cancelButton,
  createdColumn,
  allLabel,
  employeesLabel,
  saveButton,
  leftPagePromptMessage,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  newButton,
  editButton,
  statusLabel,
  cloneButton,
  deleteButton,
  createdColumn,
  allLabel,
  cancelButton,
  employeesLabel,
  saveButton,
  leftPagePromptMessage,
  confirmationModalTitle: {
    id: 'questionnaires.polls.confirmation.title',
    defaultMessage: 'Are you sure you want to submit answers?',
  },
  confirmationModalText: {
    id: 'questionnaires.polls.confirmation.text',
    defaultMessage: 'Note, once submitted it will be not possible to edit them.',
  },
  pageTitle: {
    id: 'questionnaires.polls.title',
    defaultMessage: 'Polls',
  },
  pollResponders: { id: 'questionnaires.polls.poll.responders.column', defaultMessage: 'Responders' },
  pollOrganizers: { id: 'questionnaires.polls.poll.organizers.column', defaultMessage: 'Organizers' },
  pollOffices: { id: 'questionnaires.polls.poll.Offices.column', defaultMessage: 'Offices' },
  pollReplied: { id: 'questionnaires.polls.poll.replied.column', defaultMessage: 'Replied' },
  pollRelatiedEvent: { id: 'questionnaires.polls.poll.related.event.column', defaultMessage: 'Related Event' },
  pollDeadlines: { id: 'questionnaires.polls.poll.deadlins.column', defaultMessage: 'Deadline' },
  categoriesLabels: { id: 'questionnaires.polls.categories.labels', defaultMessage: 'Categories' },
  locationLabels: { id: 'questionnaires.polls.location.labels', defaultMessage: 'Location' },
  publishedStatusLabel: { id: 'questionnaires.polls.status.label.published', defaultMessage: 'Published' },
  notPublishedStatusLabel: { id: 'questionnaires.polls.status.label.not.published', defaultMessage: 'Not Published' },
  closedStatusLabel: { id: 'questionnaires.polls.status.label.closed', defaultMessage: 'Closed' },
  editAnswersLabel: { id: 'questionnaires.polls.edit.answers.label.completed', defaultMessage: 'Edit Answers' },
  dublicateLabel: { id: 'questionnaires.polls.dublicate.label.completed', defaultMessage: 'Dublicate' },
  saveProgressLabel: { id: 'questionnaires.polls.save.progress.label', defaultMessage: 'Save Progress' },
  saveAnswers: { id: 'questionnaires.polls.save.answers', defaultMessage: 'Submit Answers' },
  exportLabel: { id: 'questionnaires.polls.export.label', defaultMessage: 'Export' },
  repliedText: { id: 'questionnaires.polls.questions.section.replied.text', defaultMessage: 'REPLIED' },
  anonymousLabel: {
    id: 'questionnaires.polls.poll.label.anonymous',
    defaultMessage: 'Anonymous (hide responders in results)',
  },
  deadlineLabel: {
    id: 'questionnaires.polls.poll.label.deadline',
    defaultMessage: 'Deadline',
  },
  myPollsLabel: {
    id: 'questionnaires.polls.label.my.polls',
    defaultMessage: 'My Polls',
  },
});
