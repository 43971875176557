import React, { useCallback, useState } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { Salary } from '../../../enums/finance/finance.enum';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import Checkbox from '../../Checkbox';

type ModalDeleteSalaryProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onDeleteRequest: (data: { id: string; deleteFutureSalaries: boolean; callback: () => void }) => void;
  salary: Salary;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
};

function ModalDeleteSalary({
  isOpen,
  onCloseRequest,
  onDeleteRequest,
  salary,
  error,
  isLoading,
}: ModalDeleteSalaryProps) {
  const intl = useIntl();

  const [deleteFutureSalaries, setDeleteFutureSalaries] = useState(false);

  const onDeleteClick = useCallback(() => {
    onDeleteRequest({ id: salary.id, deleteFutureSalaries: deleteFutureSalaries, callback: onCloseRequest });
  }, [salary, deleteFutureSalaries]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.deleteModalTitle)}
      size={'small'}
      shouldCloseOnOverlayClick
      classNameModal={'salary-delete-modal'}
    >
      <div className="form__inputs-wrapper">
        <div className="data-block">
          <div className="label-block">
            <FormattedMessage {...messages.memberLabel} />
          </div>
          <div>{salary.user.fullName}</div>
        </div>
        <div className="data-block flex-block">
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.startDateLabel} />
            </div>
            <div>{moment(salary.startDate).format(DATE_FORMAT.MMM_YYYY)}</div>
          </div>
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.endDateLabel} />
            </div>
            <div>{moment(salary.startDate).endOf('month').format(DATE_FORMAT.MMM_YYYY)}</div>
          </div>
        </div>
        <div className="data-block flex-block">
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.payLabel} />
            </div>
            <div>{salary.pay.float}</div>
          </div>
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.taxLabel} />
            </div>
            <div>{salary.tax.float}</div>
          </div>
          <div>
            <div className="label-block">
              <FormattedMessage {...messages.totalRow} />
            </div>
            <div>{(salary.tax.float || 0) + (salary.pay.float || 0)}</div>
          </div>
        </div>
        <Checkbox
          externalClass="form__checkbox-capitalazie-label"
          onChange={event => setDeleteFutureSalaries(event.target.checked)}
          id="fromPreviousExperience"
          label={intl.formatMessage(messages.deleteFutureMonthsLabel)}
          checked={deleteFutureSalaries}
        />
      </div>
      <ErrorMessage>{error}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCloseRequest} block>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button externalClass={'button--modal'} onClick={onDeleteClick} loading={isLoading} disabled={isLoading} block>
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteSalary;
