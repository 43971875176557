import { defineMessages } from 'react-intl';
import { membersLabel, departmentsLabel, officesLabel, allLabel, yesLabel, noLabel } from '../../i18n/defaultMessage';
import activeEmployeesMessages from '../../pages/ActiveEmployees/messages';

export default defineMessages({
  officesLabel,
  departmentsLabel,
  membersLabel,
  allLabel,
  yesLabel,
  noLabel,
  includedInPlanningTitle: activeEmployeesMessages.includedInPlanningTitle,
});
