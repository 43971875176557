import React, { useCallback } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { MailerSetting } from '../../../enums/notifications.enum';
import { RejectValueErrors } from '../../../enums/error.enum';

type ModalDeleteMailerSettingProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onDeleteRequest: (data: Record<string, unknown>) => void;
  mailerSettingError: string | null | RejectValueErrors[];
  mailerSettingData: MailerSetting;
  isLoading: boolean;
  resetErrors: () => void;
};

function ModalDeleteMailerSetting({
  onCloseRequest,
  onDeleteRequest,
  mailerSettingError,
  mailerSettingData,
  isLoading,
  resetErrors,
  isOpen,
}: ModalDeleteMailerSettingProps) {
  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetErrors();
  }, []);

  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.deleteModalTitle)}
      shouldCloseOnOverlayClick
      classNameModal="center"
    >
      <ErrorMessage>{mailerSettingError}</ErrorMessage>
      <div className="form__buttons">
        <Button color="gray" externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass="button--modal"
          onClick={() => {
            onDeleteRequest({ data: mailerSettingData.id, callback: resetAndExit });
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteMailerSetting;
