import React, { useMemo } from 'react';
import { Resume } from '../../../enums/resumes.enum';
import Icon from '../../Icon';
import Select from '../../Select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { find } from 'lodash-es';
import { OptionTypeBase } from 'react-select';
import { ReactSortable } from 'react-sortablejs';
import { FormikErrors } from 'formik';
import { TechnicalSkillInfoType } from '../../../types/libraries';

type SkillsProps = {
  resume: Resume;
  technicalSkills: TechnicalSkillInfoType[];
  handleChangeSkills: (value: OptionTypeBase, index: number) => void;
  onChooseSkillGroupe: (value: OptionTypeBase) => void;
  removeSkillGroupe: (index: number) => void;
  setFieldValue: (field: string, value: any) => Promise<void> | Promise<FormikErrors<Resume>>;
};

const Skills = ({
  resume,
  technicalSkills,
  handleChangeSkills,
  onChooseSkillGroupe,
  removeSkillGroupe,
  setFieldValue,
}: SkillsProps) => {
  const intl = useIntl();

  const skillOptions = (groupId: string) =>
    find(technicalSkills, el => el.id == groupId)?.skills.map(({ id, skill }) => ({ label: skill, value: id }));

  const skillGroupsOptions = useMemo(
    () =>
      technicalSkills
        ?.filter(item => !find(resume?.technicalSkills, skillGroup => skillGroup.skillGroupId === item.id))
        .map(({ id, name }) => ({ label: name, value: id })),
    [technicalSkills, resume?.technicalSkills.length],
  );

  return (
    <div className={'form__edit-cv-tab-content form__edit-cv-tab-content--about active'}>
      <ReactSortable
        //@ts-ignore
        list={resume.technicalSkills}
        setList={newState => setFieldValue(`technicalSkills`, newState)}
        animation={200}
        handle={'.form__btn-move-inputs'}
        style={{ marginBottom: '14px' }}
      >
        {resume.technicalSkills.map((item, index) => (
          <div className="form__inputs-subwrapper" key={item.skillGroupId}>
            <button className="form__btn-move-inputs" type={'button'}>
              <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
            </button>
            <Select
              options={skillOptions(item.skillGroupId)}
              label={resume.technicalSkills[index].skillGroupName}
              handleChange={data => handleChangeSkills(data, index)}
              value={resume.technicalSkills[index].skills.map(item => ({
                label: item.skill,
                value: item.id,
              }))}
              isMulti
            />
            <button className="form__btn-clean-inputs" type={'button'} onClick={() => removeSkillGroupe(index)}>
              <Icon iconName={'cross'} />
            </button>
          </div>
        ))}
      </ReactSortable>
      <div className="form__input-block add_button_block">
        <Select
          iconName={'plus'}
          options={skillGroupsOptions}
          externalClass={'skill_group_select'}
          handleChange={onChooseSkillGroupe}
          value={{ label: intl.formatMessage(messages.skillGroupButton), value: 'new' }}
          externalMenuClass={'skill_group_select_menu'}
          upward
        />
      </div>
    </div>
  );
};

export default Skills;
