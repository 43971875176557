import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as financeActions from '../../../actions/finance.actions';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import { EmployeeHours, FinancePlan, FinancePlanUser } from '../../../enums/finance/finance.enum';
import Table from '../../../components/Table';
import { FinancePlanEmployeesParams } from '../../../enums/params/finance.params';
import { SortParams } from '../../../enums/params.enum';
import { useHistory, useParams } from 'react-router';
import * as filtersActions from '../../../actions/filters.actions';
import { TRouteParamId } from '../../../enums/common.enum';
import { useDataForTable } from './useDataForTable';
import ModalAddEmployee from '../../../components/Planning/Modals/ModalAddEmployee';
import Dropdown from '../../../components/Dropdown';
import { checkPolicies } from '../../../utils/policies.utils';
import PoliciesContext from '../../../PoliciesContext';
import ModalDeleteEmployee from '../../../components/Planning/Modals/ModalDeleteEmployee';
import { DELETE_FINANCE_PLAN, UPDATE_FINANCE_PLAN } from '../../../constants/policies.constants';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import ModalEditPlan from '../../../components/Planning/Modals/ModalEditPlan';
import ModalDeletePlan from '../../../components/Planning/Modals/ModalDeletePlan';
import PlanUsersFilters from '../../../components/Planning/Filters/PlanUsersFilters';
import { useUsersParamsChange } from '../../../utils/hooks.utils';
import AccessChecker from '../../../components/AccessChecker';
import ModalEditEmployee from '../../../components/Planning/Modals/ModalEditEmployee';
import ModalClonePlan from '../../../components/Planning/Modals/ModalClonePlan';
import { formatValue } from 'react-currency-input-field';
import classNames from 'classnames';
import r from '../../../constants/routes.constants';
import { UsersFilterParams } from '../../../enums/params/users.params';
import { EmployeeHoursPlanLoadType } from '../../../types/finance';
import InlineDatePicker from '../../../components/InlineDatePicker';
import { InlineDatePickerPeriods } from '../../../components/InlineDatePicker/constants';
import CustomLoader from '../../../components/Loader';
import ModalNewPlan from '../../../components/Planning/Modals/ModalNewPlan';
import ModalImportResources from '../../../components/Planning/Modals/ModalImportResources';
import { DatesOfExistingPlansParams } from '../../../types/finance';
import { getFormattedDate } from '../../../utils';

function PlanInfo({
  tableData,
  clients,
  currencies,
  isLoading,
  errors,
  errorsPlan,
  importResourcesError,
  getProjectGroupsFilter,
  getProjectsList,
  getClientsList,
  getCurrenciesList,
  resetState,
  resetErrors,
  setPlanEmployeesParams,
  getPlanEmployees,
  getDatesOfExistingPlans,
  resetDatesOfExistingPlans,
  datesOfExistingPlans,
  params,
  usersList,
  getUsersFilter,
  createPlanEmployee,
  getPlan,
  currentPlan,
  currentPlanMonth,
  getEmployeeHours,
  userHours,
  userHoursError,
  deletePlanEmployee,
  resetEmployeeHours,
  projects,
  projectGroups,
  clonePlanCheckedInfo,
  importResourcesCheckedInfo,
  editPlan,
  clonePlan,
  resetClonePlanCheckedInfo,
  deletePlan,
  editPlanEmployee,
  setUsersFilterParams,
  resetUsersFilterParams,
  setPlanMonth,
  createPlan,
  importResources,
  resetImportResourcesCheckedInfo,
}: ConnectedProps<typeof connector>) {
  const history = useHistory();
  const { id } = useParams<TRouteParamId>();
  const policies = useContext(PoliciesContext);
  const dispatch = useDispatch();
  const [isNewPlanPage, setIsNewPlanPage] = useState(false);

  const [modalAddEmployeesIsOpen, setModalAddEmployeesIsOpen] = useState(false);
  const [modalEditEmployeesIsOpen, setModalEditEmployeesIsOpen] = useState(false);
  const [modalDeleteEmployeeIsOpen, setModalDeleteEmployeeIsOpen] = useState(false);
  const [modalEditPlanIsOpen, setModalEditPlanIsOpen] = useState(false);
  const [modalClonePlanIsOpen, setModalClonePlanIsOpen] = useState(false);
  const [modalDeletePlanIsOpen, setModalDeletePlanIsOpen] = useState(false);
  const [planEmployeeClicked, setEmployeeClicked] = useState<FinancePlanUser>(new FinancePlanUser());
  const [modalNewPlanIsOpen, setModalNewPlanIsOpen] = useState(false);
  const [modalImportResources, setModailImportResources] = useState(false);

  useEffect(() => {
    if (history.location.pathname === r.newProjectPlan) {
      if (!currentPlan.id) {
        history.replace(r.planning);
      }
      setIsNewPlanPage(true);
    } else {
      setIsNewPlanPage(false);
      getPlanEmployees({ planId: id });
      getPlan(id);
    }
  }, [id]);

  useEffect(() => {
    getUsersFilter();
    return () => {
      resetState();
    };
  }, []);

  const openNewPlanModal = useCallback(() => {
    setModalNewPlanIsOpen(true);
  }, []);

  const closeNewProjectModal = useCallback(() => {
    setModalNewPlanIsOpen(false);
    resetErrors();
  }, []);

  const openAddEmployeesModal = useCallback(() => {
    setModalAddEmployeesIsOpen(true);
  }, []);

  const closeAddEmployeesModal = useCallback(() => {
    setUsersFilterParams({});
    setModalAddEmployeesIsOpen(false);
    resetErrors();
    resetEmployeeHours();
  }, []);

  const closeEditEmployeesModal = useCallback(() => {
    setModalEditEmployeesIsOpen(false);
    resetErrors();
    resetEmployeeHours();
  }, []);

  const closeDeleteEmployeeModal = useCallback(() => {
    setModalDeleteEmployeeIsOpen(false);
    resetErrors();
  }, []);

  const closeEditPlanModal = useCallback(() => {
    setModalEditPlanIsOpen(false);
    resetErrors();
  }, []);

  const closeDeletePlanModal = useCallback(() => {
    setModalDeletePlanIsOpen(false);
    resetErrors();
  }, []);

  const closeClonePlanModal = useCallback(() => {
    setModalClonePlanIsOpen(false);
    resetErrors();
  }, []);

  const openImportResourcesModal = useCallback(() => {
    setModailImportResources(true);
  }, []);

  const closeImportResourcesModal = useCallback(() => {
    setModailImportResources(false);
    resetErrors();
  }, []);

  const handleSort = useCallback((sortBy, direction) => setPlanEmployeesParams({ sortBy, direction }), [id]);

  const sortParams = useMemo(() => new SortParams('name', params), [params]);

  const currentPlanCurrency = useMemo(() => currentPlan?.currency, [currentPlan?.currency]);

  const { tableColumns, tableActions } = useDataForTable(
    setEmployeeClicked,
    setModalEditEmployeesIsOpen,
    setModalDeleteEmployeeIsOpen,
    currentPlanCurrency,
  );

  const backButtonHandler = useCallback(() => {
    history.push(r.planning);
  }, []);

  const addUser = useCallback(
    (data: EmployeeHours & { currentLoad: EmployeeHoursPlanLoadType }) => {
      createPlanEmployee({
        id,
        data,
        callback: () => {
          closeAddEmployeesModal();
          getPlanEmployees({ planId: id });
          getPlan(id);
        },
      });
    },
    [id],
  );

  const updateUser = useCallback(
    (data: EmployeeHours & { currentLoad: EmployeeHoursPlanLoadType }) => {
      editPlanEmployee({
        id,
        data,
        callback: () => {
          closeEditEmployeesModal();
          getPlanEmployees({ planId: id });
          getPlan(id);
        },
      });
    },
    [id],
  );

  const deleteUser = useCallback(
    employeeId => {
      deletePlanEmployee({
        planId: id,
        employeeId,
        callback: () => {
          closeDeleteEmployeeModal();
          getPlanEmployees({ planId: id });
          getPlan(id);
        },
      });
    },
    [id],
  );

  const updatePlan = useCallback(
    data => {
      editPlan({
        ...data,
        callback: () => {
          data.callback();
          getPlanEmployees({ planId: id });
          getPlan(id);
        },
        noGetPlans: true,
      });
    },
    [id],
  );

  const duplicatePlan = useCallback(
    data => {
      clonePlan({
        ...data,
        noGetPlans: true,
      });
    },
    [id],
  );

  const onDeleteRequest = useCallback(
    data => {
      deletePlan({
        ...data,
        callback: () => {
          data.callback();
          history.goBack();
        },
        noGetPlans: true,
      });
    },
    [id],
  );

  const changePlanLocation = (planId?: string) => {
    if (planId) {
      history.push(r.projectPlan.replace(':id', planId));
    } else {
      history.push(r.newProjectPlan);
    }
  };

  const handleDataChange = useCallback(
    async (start: string) => {
      const month = start.split(' ')[0];
      setPlanMonth(month, changePlanLocation);
    },
    [currentPlan?.financeProjectId, currentPlan?.clientId],
  );

  const dropdownList = useMemo(
    () => [
      ...(checkPolicies([UPDATE_FINANCE_PLAN], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.editButton} />
                </>
              ),
              handler: () => setModalEditPlanIsOpen(true),
            },
            {
              label: (
                <>
                  <Icon iconName={'copy'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.cloneButton} />
                </>
              ),
              handler: () => setModalClonePlanIsOpen(true),
            },
          ]
        : []),
      ...(checkPolicies([DELETE_FINANCE_PLAN], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.deleteButton} />
                </>
              ),
              handler: () => setModalDeletePlanIsOpen(true),
            },
          ]
        : []),
    ],
    [],
  );

  const handleCreateNewPlan = (financePlanId?: string) => {
    if (financePlanId) {
      history.replace(r.projectPlan.replace(':id', financePlanId));
    } else {
      history.replace(r.planning);
    }
  };

  const filters = useMemo(
    () => ({
      users: usersList,
    }),
    [usersList],
  );

  const handleUsersParamsChange = useUsersParamsChange(setPlanEmployeesParams, dispatch);

  const isAbleToCreateNewPlan = currentPlan.client?.isActive && currentPlan.financeProject?.isActive;

  return (
    <>
      <div className="page__panel page__panel--fixed user-plan-page">
        <div className="page__wrapper">
          {!isLoading.getPlan ? (
            <>
              <div className="page__panel-top">
                <div className="page__panel__back-title">
                  <Button externalClass="page__back" color="gray" onClick={backButtonHandler}>
                    <FormattedMessage {...messages.pageTitle} />
                  </Button>
                </div>
                <div className="page__profile-panel__label-options">
                  <InlineDatePicker
                    onDateChange={handleDataChange}
                    defaultPeriod={InlineDatePickerPeriods.MONTH_PERIOD}
                    defaultPeriodStart={getFormattedDate(currentPlan.year, currentPlan.month)}
                    defaultPeriodEnd={moment(getFormattedDate(currentPlan.year, currentPlan.month))
                      .endOf('month')
                      .format(DATE_FORMAT.YYYY_MM_DD)}
                    isCustomDatePicker={false}
                    hideTodayButton
                    hidePeriodSelector
                  />
                  <AccessChecker verifiablePolicies={[UPDATE_FINANCE_PLAN, DELETE_FINANCE_PLAN]}>
                    <Dropdown
                      dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                      dropdownList={dropdownList}
                      isDisabled={isNewPlanPage}
                    />
                  </AccessChecker>
                </div>
              </div>
              <h1 className="page__title page__title--smaller">{currentPlan?.financeProject?.name}</h1>
              {!isNewPlanPage ? (
                <>
                  <div className="page__panel-top">
                    <div className="page__panel__back-plan-info">
                      <div className="user-hours-block">
                        <div className="user-hours-title">
                          <FormattedMessage {...messages.clientLabel} />
                        </div>
                        {currentPlan?.client?.name}
                      </div>
                      <div className="user-hours-block">
                        <div className="user-hours-title">
                          <FormattedMessage {...messages.baseRateLabel} />
                        </div>
                        {`${formatValue({
                          value: currentPlan?.currencyWorkHourRate?.value,
                        })}  ${currentPlan?.currency?.name}`}
                      </div>
                      <div className="user-hours-block">
                        <div className="user-hours-title">
                          <FormattedMessage {...messages.plannedIncomeColumn} />
                        </div>
                        <div
                          className={classNames({ 'global-color': +currentPlan?.plannedIncome?.value <= 0 })}
                        >{`${formatValue({
                          value: currentPlan?.plannedIncome?.value,
                        })}  ${currentPlan?.currency?.name}`}</div>
                      </div>
                      <div className="user-hours-block">
                        <div className="user-hours-title">
                          <FormattedMessage {...messages.calculatedIncomeLabel} />
                        </div>
                        {`${
                          formatValue({
                            value: currentPlan?.calculatedPlannedIncome?.toString(),
                          }) || 0
                        }  ${currentPlan?.currency?.name}`}
                      </div>
                    </div>
                  </div>
                  <div className="page__panel-bottom no-border">
                    <div className="page__panel-bottom__wrapper--people">
                      <div className="page__panel-bottom__wrapper--left">
                        <AccessChecker verifiablePolicies={[UPDATE_FINANCE_PLAN]}>
                          <Button externalClass={'button--with-icon'} onClick={openAddEmployeesModal}>
                            <Icon iconName={'plus'} externalClass={'button__icon'} />
                            <span className="button__text">
                              <FormattedMessage {...messages.membersLabel} />
                            </span>
                          </Button>
                          <Button isAccentText externalClass={'button--with-icon'} onClick={openImportResourcesModal}>
                            <Icon iconName={'plus'} externalClass={'button__icon'} />
                            <span className="button__text">
                              <FormattedMessage {...messages.importResourcesBtn} />
                            </span>
                          </Button>
                        </AccessChecker>
                        <PlanUsersFilters
                          filters={filters}
                          values={params}
                          handleUsersParamsChange={handleUsersParamsChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="page__panel-bottom no-border">
                  <div>
                    <AccessChecker verifiablePolicies={[UPDATE_FINANCE_PLAN]}>
                      <Button
                        externalClass={'button--with-icon'}
                        onClick={openNewPlanModal}
                        disabled={!isAbleToCreateNewPlan}
                      >
                        <Icon iconName={'plus'} externalClass={'button__icon'} />
                        <span className="button__text">
                          <FormattedMessage {...messages.planColumn} />
                        </span>
                      </Button>
                    </AccessChecker>
                    {!isAbleToCreateNewPlan && <span>Project or client are inactive</span>}
                  </div>
                </div>
              )}
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
      {!isNewPlanPage && (
        <div className="page__content active_employees_page">
          <div className="page__wrapper">
            <Table
              externalClass={'table employees-plan-table'}
              tableColumns={tableColumns}
              tableData={tableData || []}
              loading={isLoading.getPlanEmployeesList}
              error={errors}
              tableActions={tableActions}
              onSort={handleSort}
              params={sortParams}
            />
          </div>
        </div>
      )}
      {modalAddEmployeesIsOpen && (
        <ModalAddEmployee
          isOpen
          users={usersList}
          onCloseRequest={closeAddEmployeesModal}
          addUser={addUser}
          error={errors}
          isLoading={isLoading.createPlanEmployee}
          loadingUserHours={isLoading.getEmployeeHours}
          getEmployeeHours={getEmployeeHours}
          userHours={userHours}
          userHoursError={userHoursError}
          currentPlan={currentPlan}
          setUsersFilterParams={setUsersFilterParams}
          resetUsersFilterParams={resetUsersFilterParams}
          resetEmployeeHours={resetEmployeeHours}
        />
      )}
      {modalEditEmployeesIsOpen && (
        <ModalEditEmployee
          isOpen
          onCloseRequest={closeEditEmployeesModal}
          updateUser={updateUser}
          error={errors}
          isLoading={isLoading.editPlanEmployee}
          loadingUserHours={isLoading.getEmployeeHours}
          getEmployeeHours={getEmployeeHours}
          userHours={userHours}
          userHoursError={userHoursError}
          planUser={planEmployeeClicked}
          currentPlan={currentPlan}
          planId={id}
        />
      )}
      {modalDeleteEmployeeIsOpen && (
        <ModalDeleteEmployee
          isOpen
          onCloseRequest={closeDeleteEmployeeModal}
          onDeleteRequest={deleteUser}
          planUser={planEmployeeClicked}
          isLoading={isLoading.deletePlanEmployee}
          error={errors}
        />
      )}
      {modalEditPlanIsOpen && (
        <ModalEditPlan
          isOpen
          onCloseRequest={closeEditPlanModal}
          editPlan={updatePlan}
          error={errorsPlan}
          isLoading={isLoading.editPlan}
          loadingDates={isLoading.getDatesOfExistingPlans}
          datesOfExistingPlans={datesOfExistingPlans}
          getDatesOfExistingPlans={getDatesOfExistingPlans}
          resetDatesOfExistingPlans={resetDatesOfExistingPlans}
          getClientsList={getClientsList}
          clients={clients}
          projects={projects}
          getProjectsList={getProjectsList}
          financePlan={currentPlan}
          currencies={currencies}
          getCurrenciesList={getCurrenciesList}
        />
      )}
      {modalClonePlanIsOpen && (
        <ModalClonePlan
          isOpen
          onCloseRequest={closeClonePlanModal}
          clonePlan={duplicatePlan}
          error={errorsPlan}
          isLoading={isLoading.clonePlan}
          financePlan={currentPlan}
          currencies={currencies}
          clonePlanCheckedInfo={clonePlanCheckedInfo}
          loadingDates={isLoading.getDatesOfExistingPlans}
          datesOfExistingPlans={datesOfExistingPlans}
          getDatesOfExistingPlans={getDatesOfExistingPlans}
          resetDatesOfExistingPlans={resetDatesOfExistingPlans}
          getCurrenciesList={getCurrenciesList}
          resetClonePlanCheckedInfo={resetClonePlanCheckedInfo}
        />
      )}
      {modalDeletePlanIsOpen && (
        <ModalDeletePlan
          isOpen
          onCloseRequest={closeDeletePlanModal}
          onDeleteRequest={onDeleteRequest}
          isLoading={isLoading.deletePlan}
          error={errorsPlan}
          financePlan={currentPlan}
        />
      )}
      {modalNewPlanIsOpen && (
        <ModalNewPlan
          isOpen
          onCloseRequest={closeNewProjectModal}
          createPlan={createPlan}
          defaultPLan={currentPlan}
          defaultMonth={moment(currentPlanMonth).format(DATE_FORMAT.YYYY_MM)}
          error={errors}
          isLoading={isLoading.createPlan}
          loadingDates={isLoading.getDatesOfExistingPlans}
          currencies={currencies}
          datesOfExistingPlans={datesOfExistingPlans}
          getCurrenciesList={getCurrenciesList}
          cb={handleCreateNewPlan}
          getDatesOfExistingPlans={getDatesOfExistingPlans}
          resetDatesOfExistingPlans={resetDatesOfExistingPlans}
        />
      )}

      {modalImportResources && (
        <ModalImportResources
          isOpen
          onCloseRequest={closeImportResourcesModal}
          getProjectGroups={getProjectGroupsFilter}
          importResources={importResources}
          resetImportResourcesCheckedInfo={resetImportResourcesCheckedInfo}
          importResourcesCheckedInfo={importResourcesCheckedInfo}
          currentPlan={currentPlan}
          projectGroups={projectGroups}
          error={importResourcesError}
          isLoading={isLoading.importResources}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance, filters }: RootState) => ({
  errors: finance.errors.planEmployeesError,
  errorsPlan: finance.errors.planError,
  userHoursError: finance.errors.employeeHoursError,
  importResourcesError: finance.errors.importResourcesError,
  isLoading: finance.loading,
  tableData: finance.plansListData?.content,
  projects: filters.financeProjectsFilter.financeProjects,
  clients: filters.clientsFilter.clients,
  currencies: filters.currenciesFilter.currencies,
  params: finance.planEmployeesParams,
  usersList: filters.usersFilter.users,
  currentPlan: finance.currentPlanData,
  userHours: finance.employeeHours,
  currentPlanMonth: finance.currenPlanMonth,
  projectGroups: filters.projectGroupsFilter.projectGroups,
  clonePlanCheckedInfo: finance.clonePlanCheckedInfo,
  importResourcesCheckedInfo: finance.importResourcesCheckedInfo,
  datesOfExistingPlans: finance.datesOfExistingPlans,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getClientsList: () => dispatch(filtersActions.getClientsFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getCurrenciesList: () => dispatch(filtersActions.getCurrenciesFilter()),
  getProjectGroupsFilter: () => dispatch(filtersActions.getProjectGroupsFilter()),
  getProjectsList: () => dispatch(filtersActions.getFinanceProjectsFilter()),
  editPlan: (data: { data: FinancePlan; callback: () => void }) => dispatch(financeActions.editPlan(data)),
  clonePlan: (data: {
    data: FinancePlan;
    callback: () => void;
    closeConfirmModal: () => void;
    isConfirmModal: boolean;
  }) => dispatch(financeActions.clonePlan(data)),
  deletePlan: (data: { id: string; callback: () => void }) => dispatch(financeActions.deletePlan(data)),
  setPlanEmployeesParams: (data: Partial<FinancePlanEmployeesParams>) =>
    dispatch(financeActions.setPlanEmployeesParams(data)),
  setPlanMonth: (data: string, cb: (id?: string) => void) => dispatch(financeActions.setPlanMonth(data, cb)),
  getPlanEmployees: (data: { planId: string }) => dispatch(financeActions.getPlanEmployees(data)),
  createPlanEmployee: (data: {
    id: string;
    data: EmployeeHours & { currentLoad: EmployeeHoursPlanLoadType };
    callback: () => void;
  }) => dispatch(financeActions.createPlanEmployee(data)),
  editPlanEmployee: (data: {
    id: string;
    data: EmployeeHours & {
      currentLoad: EmployeeHoursPlanLoadType;
    };
    callback: () => void;
  }) => dispatch(financeActions.editPlanEmployee(data)),
  getPlan: (id: string) => dispatch(financeActions.getPlan(id)),
  getEmployeeHours: (id: string, month: string) => dispatch(financeActions.getEmployeeHours(id, month)),
  deletePlanEmployee: (data: { planId: string; employeeId: string; callback: () => void }) =>
    dispatch(financeActions.deletePlanEmployee(data)),
  resetEmployeeHours: () => dispatch(financeActions.resetEmployeeHours()),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  setUsersFilterParams: (data: Partial<UsersFilterParams>) => dispatch(filtersActions.setUsersFilterParams(data)),
  createPlan: (data: { data: FinancePlan; callback: (financePlanId?: string) => void }) =>
    dispatch(financeActions.createPlan(data)),
  importResources: (data: {
    data: { financePlanId: string; month: string; projectGroupId: string };
    callback: () => void;
    closeConfirmModal: () => void;
    isConfirmModal: boolean;
  }) => dispatch(financeActions.importResources(data)),
  getDatesOfExistingPlans: (data: DatesOfExistingPlansParams) => dispatch(financeActions.getDatesOfExistingPlans(data)),
  resetDatesOfExistingPlans: () => dispatch(financeActions.resetDatesOfExistingPlans()),
  resetUsersFilterParams: () => dispatch(filtersActions.resetUsersFilterParams()),
  resetClonePlanCheckedInfo: () => dispatch(financeActions.resetClonePlanCheckedInfo()),
  resetImportResourcesCheckedInfo: () => dispatch(financeActions.resetImportResourcesCheckedInfo()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PlanInfo);
