import { defineMessages } from 'react-intl';
import { exportToXLSLabel, exportToPDFLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  exportToXLSLabel,
  exportToPDFLabel,
  pageTitle: {
    id: 'planning.user.hours.title.user.hours.details',
    defaultMessage: 'User Hours (+ Details)',
  },
});
