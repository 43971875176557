import { checkPolicies } from '../utils/policies.utils';
import { VIEW_PLANNED_EXPENSE, VIEW_TEMPLATE_CATEGORY, VIEW_PUBLIC_EVENT } from './policies.constants';
import {
  VIEW_ABSENCE_MANUAL_INFO,
  VIEW_ABSENCE_PERIOD,
  VIEW_ACTIVE_EMPLOYEE,
  VIEW_ACTIVE_INACTIVE_HOURS_REPORT,
  VIEW_ALL_EMPLOYEE_ACTIVITY,
  VIEW_ASSESSMENT,
  VIEW_BONUS_BY_GRADES,
  VIEW_CANDIDATE,
  VIEW_CANDIDATE_RECEIVING_SOURCE,
  VIEW_CANDIDATE_SPECIALIZATION,
  VIEW_CANDIDATE_STATUS,
  VIEW_CANDIDATE_TECHNOLOGY,
  VIEW_CLIENT,
  VIEW_COMPANY_POSITION,
  VIEW_COMPETENCE,
  VIEW_COMPETENCE_TYPE,
  VIEW_CURRENCY,
  VIEW_DEPARTMENT,
  VIEW_EMPLOYEES_AND_ISSUE_TYPE,
  VIEW_EMPLOYEE_GROUP,
  VIEW_EMPLOYEE_REPORT,
  VIEW_EMPLOYEE_TIMESHEET_REPORT,
  VIEW_EVENT,
  VIEW_EVENT_EXTENDED,
  VIEW_EVENT_TYPE,
  VIEW_EXPENSE_TYPE,
  VIEW_FINANCE_PLAN,
  VIEW_FINANCE_PROJECT,
  VIEW_FOREIGN_LANGUAGE,
  VIEW_GRADE,
  VIEW_INCOMES_EXPENSES_REPORT,
  VIEW_INCOME_TYPE,
  VIEW_LANGUAGE_LEVEL,
  VIEW_MAIL_SETTING,
  VIEW_NOTIFICATION,
  VIEW_NOTIFICATION_TEMPLATE,
  VIEW_OFFICE,
  VIEW_PERSONAL_ACTIVITY,
  VIEW_POLICY,
  VIEW_POLL,
  VIEW_POLL_EXTENDED,
  VIEW_PORTAL_BRANDING,
  VIEW_PROJECT,
  VIEW_PROJECT_GROUP,
  VIEW_PROJECT_REPORT,
  VIEW_PROJECT_TYPE,
  VIEW_RECOGNITION_EXTENDED,
  VIEW_RECOGNITION_TYPE,
  VIEW_RESOURCE,
  VIEW_RESOURCE_LINKS_MENU,
  VIEW_RESUME,
  VIEW_SALARY,
  VIEW_SKILL_LEVEL,
  VIEW_SKILL_QUESTION,
  VIEW_SPECIALIZATION,
  VIEW_SPECIALIZATION_COMPETENCE,
  VIEW_SUPPLIER,
  VIEW_TECHNICAL_SKILL,
  VIEW_TEMPLATE,
  VIEW_TRANSACTION,
  VIEW_USERS_HOURS_ABSENCE_REPORT,
  VIEW_USER_EXTENDED,
  VIEW_USER_HOUR,
} from './policies.constants';

const r = {
  root: '/',
  signIn: '/signIn',
  branding: '/branding',
  users: '/members',
  activity: '/activity',
  offices: '/offices',
  grades: '/grades',
  specializations: '/specializations',
  companyPositions: '/company-positions',
  departments: '/departments',
  recognitionTypes: '/recognition-types',
  recognitions: '/recognitions',
  competenciesTypes: '/competencies-types',
  projects: '/projects',
  eventTypes: '/event-types',
  technicalSkills: '/technical-skills',
  competencies: '/competencies',
  newCompetence: '/competencies/new',
  editCompetence: '/competencies/edit/:id',
  competenceInfo: '/competencies/information/:id',
  assessments: '/assessments',
  assessmentsInfo: '/assessments/information/:id',
  skillQuestions: '/questions-for-skill',
  questions: '/questions-for-skill/:id',
  newSkillQuestions: '/questions-for-skill/new',
  editSkillQuestions: '/questions-for-skill/edit/:id',
  specializationsCompetencies: '/specializations-competencies',
  skillLevels: '/skill-levels',
  polls: '/questionnaires/polls',
  myPolls: '/my-polls',
  pollsInfo: '/questionnaires/information/:id/:isAdmin',
  pollCategories: '/poll-categories',
  pollTemplates: '/poll-templates',
  newPollTemplate: '/poll-templates/new',
  editPollTemplate: '/poll-templates/edit/:id',
  languageLevels: '/language-levels',
  foreignLanguages: '/foreign-languages',
  projectGroups: '/project-groups',
  resources: '/resources',
  projectHours: '/project-hours',
  userHours: '/user-hours',
  activeEmployees: '/active-employees',
  resumesManagement: '/resumes-management',
  eventInformation: '/schedule/event-information/:id',
  bonusesByGrades: '/bonuses-by-grades',
  resumeInfo: '/resume/information/:id',
  dashboardManagement: '/dashboard-management',
  mailerSettings: '/mailer-settings',
  employeeGroups: '/member-groups',
  followUps: '/follow-ups',
  schedule: '/schedule',
  projectsReport: '/projects-report',
  incomeTypes: '/income-types',
  currencies: '/currencies',
  clients: '/clients',
  suppliers: '/suppliers',
  projectTypes: '/project-types',
  expenseTypes: '/expense-types',
  financeProjects: '/finance-projects',
  salaries: '/salaries',
  incomesExpensesReport: '/incomes-expenses-report',
  planning: '/planning',
  projectPlan: '/planning/project-plan/:id',
  newProjectPlan: '/planning/project-plan/new-plan',
  transactions: '/transactions',
  absencePeriods: '/absencePeriods',
  employeesReport: '/employees-report',
  employeesTimesheet: '/employees-timesheet',
  employeesIssueTypes: '/employees-issue-types',
  usersHoursAbsences: '/users-hours-absences',
  activeAndInactiveHours: '/active-and-inactive-hours',
  daysUsedLimit: '/daysUsedLimit',
  taskTrackerSoftware: '/task-tracker-software',
  notificationTemplates: '/notification-templates',
  importTransactions: '/transactions/import',
  candidatesStatuses: '/candidates/statuses',
  candidatesSpecializations: '/candidates/specializations',
  candidatesTechnologies: '/candidates/technologies',
  candidatesReceivingSources: '/candidates/receiving-sources',
  candidates: '/candidates/management',
  permissions: '/permissions',
  plannedExpense: '/planned-expense',
};

export const getAppNavigation = (userPolicies: any) => {
  return [
    checkPolicies([VIEW_EVENT_EXTENDED, VIEW_EVENT, VIEW_PUBLIC_EVENT], userPolicies) && {
      url: r.schedule,
      iconName: 'calendar',
    },
    checkPolicies([VIEW_USER_EXTENDED], userPolicies) && {
      url: r.users,
      iconName: 'users',
    },
    checkPolicies([VIEW_ALL_EMPLOYEE_ACTIVITY, VIEW_PERSONAL_ACTIVITY], userPolicies) && {
      url: r.activity,
      iconName: 'stopwatch',
    },
    checkPolicies([VIEW_POLL], userPolicies) && {
      url: r.myPolls,
      iconName: 'comments',
      badges: true,
    },
  ].filter(item => item);
};

export const getMenuNavigation = (userPolicies: any) => {
  return [
    {
      groupName: 'Reports',
      iconName: 'file',
      sections: [
        checkPolicies([VIEW_PROJECT_REPORT], userPolicies) && {
          name: 'Project',
          url: r.projectsReport,
        },
        checkPolicies([VIEW_EMPLOYEE_REPORT], userPolicies) && {
          name: 'Members',
          url: r.employeesReport,
        },
        checkPolicies([VIEW_EMPLOYEE_TIMESHEET_REPORT], userPolicies) && {
          name: 'Members Timesheet',
          url: r.employeesTimesheet,
        },
        checkPolicies([VIEW_EMPLOYEES_AND_ISSUE_TYPE], userPolicies) && {
          name: 'Members and Issue Types',
          url: r.employeesIssueTypes,
        },
        checkPolicies([VIEW_ACTIVE_INACTIVE_HOURS_REPORT], userPolicies) && {
          name: 'Active and Inactive Hours',
          url: r.activeAndInactiveHours,
        },
        checkPolicies([VIEW_USERS_HOURS_ABSENCE_REPORT], userPolicies) && {
          name: 'Users, Hours, Absences',
          url: r.usersHoursAbsences,
        },
        checkPolicies([VIEW_ABSENCE_PERIOD], userPolicies) && {
          name: 'Absence Periods',
          url: r.absencePeriods,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Questionnaires',
      iconName: 'comments',
      sections: [
        checkPolicies([VIEW_POLL_EXTENDED], userPolicies) && {
          name: 'Polls',
          url: r.polls,
        },
        checkPolicies([VIEW_TEMPLATE], userPolicies) && {
          name: 'Poll Templates',
          url: r.pollTemplates,
        },
        checkPolicies([VIEW_TEMPLATE_CATEGORY], userPolicies) && {
          name: 'Poll Categories',
          url: r.pollCategories,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Recognitions',
      iconName: 'thumbs-up',
      sections: [
        checkPolicies([VIEW_RECOGNITION_TYPE], userPolicies) && {
          name: 'Recognition Types',
          url: r.recognitionTypes,
        },
        checkPolicies([VIEW_RECOGNITION_EXTENDED], userPolicies) && {
          name: 'Recognitions',
          url: r.recognitions,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Schedule Administration',
      iconName: 'calendar',
      sections: [
        checkPolicies([VIEW_EVENT_TYPE], userPolicies) && {
          name: 'Event Types',
          url: r.eventTypes,
        },
        checkPolicies([VIEW_ABSENCE_MANUAL_INFO], userPolicies) && {
          name: 'Days (Used / Limit)',
          url: r.daysUsedLimit,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Planning',
      iconName: 'user-time',
      sections: [
        checkPolicies([VIEW_RESOURCE], userPolicies) && {
          name: 'Resources',
          url: r.resources,
        },
        checkPolicies([VIEW_USER_HOUR], userPolicies) && {
          name: 'User Hours (+ Details)',
          url: r.userHours,
        },
        checkPolicies([VIEW_USER_HOUR], userPolicies) && {
          name: 'Project Hours (+ Details)',
          url: r.projectHours,
        },
        checkPolicies([VIEW_PROJECT_GROUP], userPolicies) && {
          name: 'Project Groups',
          url: r.projectGroups,
        },
        checkPolicies([VIEW_ACTIVE_EMPLOYEE], userPolicies) && {
          name: 'Active Members',
          url: r.activeEmployees,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Candidates',
      iconName: 'candidates',
      sections: [
        checkPolicies([VIEW_CANDIDATE], userPolicies) && {
          name: 'Candidates Management',
          url: r.candidates,
        },
        checkPolicies([VIEW_CANDIDATE_STATUS], userPolicies) && {
          name: 'Statuses',
          url: r.candidatesStatuses,
        },
        checkPolicies([VIEW_CANDIDATE_SPECIALIZATION], userPolicies) && {
          name: 'Specializations',
          url: r.candidatesSpecializations,
        },
        checkPolicies([VIEW_CANDIDATE_TECHNOLOGY], userPolicies) && {
          name: 'Technologies',
          url: r.candidatesTechnologies,
        },
        checkPolicies([VIEW_CANDIDATE_RECEIVING_SOURCE], userPolicies) && {
          name: 'Receiving Sources',
          url: r.candidatesReceivingSources,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Resumes',
      iconName: 'id-badge',
      sections: [
        checkPolicies([VIEW_TECHNICAL_SKILL], userPolicies) && {
          name: 'Technical Skills',
          url: r.technicalSkills,
        },
        checkPolicies([VIEW_SKILL_LEVEL], userPolicies) && {
          name: 'Skill Levels',
          url: r.skillLevels,
        },
        checkPolicies([VIEW_PROJECT], userPolicies) && {
          name: 'Projects',
          url: r.projects,
        },
        checkPolicies([VIEW_LANGUAGE_LEVEL], userPolicies) && {
          name: 'Language Levels',
          url: r.languageLevels,
        },
        checkPolicies([VIEW_FOREIGN_LANGUAGE], userPolicies) && {
          name: 'Foreign Languages',
          url: r.foreignLanguages,
        },
        checkPolicies([VIEW_RESUME], userPolicies) && {
          name: 'Resumes Management',
          url: r.resumesManagement,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Competencies',
      iconName: 'label-icon',
      sections: [
        checkPolicies([VIEW_COMPETENCE], userPolicies) && {
          name: 'Competencies',
          url: r.competencies,
        },
        checkPolicies([VIEW_COMPETENCE_TYPE], userPolicies) && {
          name: 'Competencies Types',
          url: r.competenciesTypes,
        },
        checkPolicies([VIEW_SKILL_QUESTION], userPolicies) && {
          name: 'Questions for Skill',
          url: r.skillQuestions,
        },
        checkPolicies([VIEW_SPECIALIZATION_COMPETENCE], userPolicies) && {
          name: 'Specializations',
          url: r.specializationsCompetencies,
        },
        checkPolicies([VIEW_ASSESSMENT], userPolicies) && {
          name: 'Assessments',
          url: r.assessments,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Bonus and Grades',
      iconName: 'trophy',
      sections: [
        checkPolicies([VIEW_BONUS_BY_GRADES], userPolicies) && {
          name: 'Bonuses by Grades',
          url: r.bonusesByGrades,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Libraries',
      iconName: 'e-learning',
      sections: [
        checkPolicies([VIEW_OFFICE], userPolicies) && {
          name: 'Offices',
          url: r.offices,
        },
        checkPolicies([VIEW_DEPARTMENT], userPolicies) && {
          name: 'Departments',
          url: r.departments,
        },
        checkPolicies([VIEW_COMPANY_POSITION], userPolicies) && {
          name: 'Company Positions',
          url: r.companyPositions,
        },
        checkPolicies([VIEW_SPECIALIZATION], userPolicies) && {
          name: 'Specializations',
          url: r.specializations,
        },
        checkPolicies([VIEW_GRADE], userPolicies) && {
          name: 'Grades',
          url: r.grades,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Mail (Notifications)',
      iconName: 'bell',
      sections: [
        checkPolicies([VIEW_MAIL_SETTING], userPolicies) && {
          name: 'Mailer Settings',
          url: r.mailerSettings,
        },
        checkPolicies([VIEW_EMPLOYEE_GROUP], userPolicies) && {
          name: 'Member Groups',
          url: r.employeeGroups,
        },
        checkPolicies([VIEW_NOTIFICATION], userPolicies) && {
          name: 'Follow-Ups',
          url: r.followUps,
        },
        checkPolicies([VIEW_NOTIFICATION_TEMPLATE], userPolicies) && {
          name: 'Notification Templates',
          url: r.notificationTemplates,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Finance',
      iconName: 'money',
      sections: [
        checkPolicies([VIEW_INCOMES_EXPENSES_REPORT], userPolicies) && {
          name: 'Incomes/Expenses Report',
          url: r.incomesExpensesReport,
        },
        checkPolicies([VIEW_TRANSACTION], userPolicies) && {
          name: 'Transactions',
          url: r.transactions,
        },
        checkPolicies([VIEW_SALARY], userPolicies) && {
          name: 'Salaries',
          url: r.salaries,
        },
        checkPolicies([VIEW_FINANCE_PLAN], userPolicies) && {
          name: 'Planning',
          url: r.planning,
        },
        checkPolicies([VIEW_PLANNED_EXPENSE], userPolicies) && {
          name: 'Planned Expense',
          url: r.plannedExpense,
        },
        checkPolicies([VIEW_CLIENT], userPolicies) && {
          name: 'Clients',
          url: r.clients,
        },
        checkPolicies([VIEW_FINANCE_PROJECT], userPolicies) && {
          name: 'Projects',
          url: r.financeProjects,
        },
        checkPolicies([VIEW_SUPPLIER], userPolicies) && {
          name: 'Suppliers',
          url: r.suppliers,
        },
        checkPolicies([VIEW_CURRENCY], userPolicies) && {
          name: 'Currencies',
          url: r.currencies,
        },
        checkPolicies([VIEW_EXPENSE_TYPE], userPolicies) && {
          name: 'Expense Types',
          url: r.expenseTypes,
        },
        checkPolicies([VIEW_INCOME_TYPE], userPolicies) && {
          name: 'Income Types',
          url: r.incomeTypes,
        },
        checkPolicies([VIEW_PROJECT_TYPE], userPolicies) && {
          name: 'Project Types',
          url: r.projectTypes,
        },
      ].filter(item => item),
    },
    {
      groupName: 'Settings',
      iconName: 'settings',
      sections: [
        checkPolicies([VIEW_POLICY], userPolicies) && {
          name: 'Permissions',
          url: r.permissions,
        },
        checkPolicies([VIEW_RESOURCE_LINKS_MENU], userPolicies) && {
          name: 'Navigation',
          url: r.dashboardManagement,
        },
        checkPolicies([VIEW_PORTAL_BRANDING], userPolicies) && {
          name: 'Branding',
          url: r.branding,
        },
      ].filter(item => item),
    },
  ].filter(item => item.sections.length);
};

export default r;
