import React, { useContext } from 'react';
import BrandingContext from '../../BrandingContext';
import Icon from '../Icon';

function Logo() {
  const branding = useContext(BrandingContext);
  return branding?.isLogoUrl && branding.logoUrl ? (
    <img src={branding.logoUrl} className="header__logo-img" />
  ) : !branding?.isLogoUrl && branding?.logoFile ? (
    <img src={branding.logoFile.url} className="header__logo-img" />
  ) : (
    <Icon iconName="logo" externalClass="header__logo-img" />
  );
}

export default Logo;
