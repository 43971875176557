import React, { useEffect } from 'react';
import * as competenciesActions from '../../../actions/competencies.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import EditSkillQuestionsForm from '../../../components/SkillQuestions/Forms/EditSkillQuestionsForm';
import r from '../../../constants/routes.constants';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { TRouteParamId } from '../../../enums/common.enum';

function EditSkillQuestions({
  isLoading,
  skillQuestionsDataError,
  editSkillQuestions,
  skillQuestionsData,
  getSkillQuestions,
  cleanSkillQuestionsData,
}: ConnectedProps<typeof connector>) {
  const { id } = useParams<TRouteParamId>();
  useEffect(() => {
    getSkillQuestions({ data: id });
    return () => {
      cleanSkillQuestionsData();
    };
  }, []);

  const history = useHistory();

  const editSkillQuestionsCallback = () => {
    history.push(r.skillQuestions);
  };

  const onSubmitHandler = (data: any) => {
    editSkillQuestions({ data, callback: editSkillQuestionsCallback, skillId: id });
  };
  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper fixed-container">
          <h1 className="page__title">
            <FormattedMessage {...messages.editQuestionsForSkillsTitle} />
          </h1>
        </div>
      </div>
      <div className="page__main-content">
        <div className="page__wrapper fixed-container">
          <EditSkillQuestionsForm
            SkillQuestions={skillQuestionsData}
            skillQuestionsDataError={skillQuestionsDataError}
            isLoading={isLoading}
            onSubmit={onSubmitHandler}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ competencies }: RootState) => ({
  skillQuestionsDataError: competencies.errors.skillQuestionsDataError,
  skillQuestionsData: competencies.skillQuestionsData,
  isLoading: competencies.loading.editSkillQuestions,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  editSkillQuestions: (data: any) => dispatch(competenciesActions.editSkillQuestions(data)),
  getSkillQuestions: (id: any) => dispatch(competenciesActions.getSkillQuestions(id)),
  cleanSkillQuestionsData: () => dispatch(competenciesActions.cleanSkillQuestionsData()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EditSkillQuestions);
