import { defineMessages } from 'react-intl';
import {
  exportToXLSLabel,
  departmentsLabel,
  employeesLabel,
  officesLabel,
  filtersLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  exportToXLSLabel,
  officesLabel,
  departmentsLabel,
  employeesLabel,
  filtersLabel,
  pageTitle: {
    id: 'activity.title.activity',
    defaultMessage: 'Activity',
  },
  taskTrackerButton: {
    id: 'activity.btn.get.task.tracker.software',
    defaultMessage: 'Get TaskTracker Software',
  },
});
