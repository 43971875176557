import React from 'react';
import { useIntl } from 'react-intl';
import Button from '../Button';
import Icon from '../Icon';
import messages from './messages';

type FilterClearButtonProps = { onClear: () => void };

const FilterClearButton = ({ onClear }: FilterClearButtonProps) => {
  const intl = useIntl();

  return (
    <Button color="grey" externalClass="clear-filter-button" onClick={onClear}>
      <Icon iconName="clear" />
      <span>{intl.formatMessage(messages.clearButton)}</span>
    </Button>
  );
};

export default FilterClearButton;
