import get from 'lodash-es/get';
import moment from 'moment';
import * as yup from 'yup';
import { PhotoCropSetting, UserInfo, UserPhoto } from './users.enum';

export class Resume {
  approvedStatus: boolean;
  deletePhotoFlag: boolean;
  employee: UserInfo; // change to  UserPreviewInfo when SA_ITP-240 will merged
  companyPosition: { displayName: string; gradeId: string; gradeName: string; id: string; priority: number };
  education: string;
  experienceDays: number;
  id: string;
  interestsAndHobbies: string;
  languages: {
    foreignLanguage: {
      id: string;
      name: string;
    };
    foreignLanguageId: string;
    languageLevel: {
      id: string;
      name: string;
      priority: number;
    };
    languageLevelId: string;
  }[];
  lastUpdate: string;
  personalHighlights: string;
  photo: UserPhoto;
  projects: { description: string; id: string; name: string }[];
  summary: string;
  technicalSkills: {
    skillGroupId: string;
    skillGroupName: string;
    skillGroupPriority: number;
    skillIds: string[];
    skills: { id: string; skill: string }[];
  }[];
  updater: UserInfo; // change to  UserPreviewInfo when SA_ITP-240 will merged
  cropSetting: PhotoCropSetting;
  photoFile: File;

  constructor(resume?: unknown, employee?: unknown, updater?: unknown) {
    this.id = get(resume, 'id', 0);
    this.employee = new UserInfo(get(resume, 'employee', new UserInfo(employee)));
    this.companyPosition = get(resume, 'companyPosition', { displayName: '', id: null });
    this.education = get(resume, 'education', '');
    this.experienceDays = get(resume, 'experienceDays', 0);
    this.interestsAndHobbies = get(resume, 'interestsAndHobbies', '');
    this.languages = get(resume, 'languages', []);
    this.lastUpdate = get(resume, 'lastUpdate', '');
    this.personalHighlights = get(resume, 'personalHighlights', '');
    this.photo = get(resume, 'photo', { url: '' });
    this.projects = get(resume, 'projects', []);
    this.summary = get(resume, 'summary', '');
    this.technicalSkills = get(resume, 'technicalSkills', []);
    this.updater = new UserInfo(get(resume, 'updater', new UserInfo(updater)));
    this.approvedStatus = get(resume, 'approvedStatus', false);
    this.deletePhotoFlag = get(resume, 'deletePhotoFlag', false);
    this.cropSetting = get(resume, 'cropSetting', null);
    this.photoFile = get(resume, 'photoFile', null);
  }

  get experienceYears() {
    return moment.duration(this.experienceDays, 'days').years();
  }

  get experienceMonths() {
    return moment.duration(this.experienceDays, 'days').months();
  }

  get userExperienceMask() {
    return `${this.experienceYears.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })}y ${this.experienceMonths.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })}m`;
  }

  get educationFirstString() {
    return this.education?.split('\n')[0];
  }

  get educationSecondString() {
    const education = this.education?.split('\n');
    education?.shift();
    return education?.join(' ');
  }

  get employeeInfo() {
    return {
      id: this.employee.id,
      firstName: this.employee.firstName,
      secondName: this.employee.secondName,
      photo: this.photo,
    };
  }
}

export type DisplayingResumeSections = {
  includePhoto?: boolean;
  includeName?: boolean;
  includePosition?: boolean;
  includeExperience?: boolean;
  includeEducation?: boolean;
  includeLanguages?: boolean;
  includeSummary?: boolean;
  includePersonalHighlights?: boolean;
  includeInterestsAndHobbies?: boolean;
  includeTechnicalSkills?: boolean;
  includeProjects?: boolean;
};

export const EDIT_RESUME_TABS = {
  ABOUT: 'ABOUT',
  SKILLS: 'SKILLS',
  PROJECTS: 'PROJECTS',
};

export const EXPERIENCE_FILTER_SCHEMA = yup.object().shape({
  experienceFrom: yup.number().positive('Only positive numbers').integer('Only integer').nullable(true),
  experienceTo: yup.number().positive('Only positive numbers').integer('Only integer').nullable(true),
});
