import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import Filter, { FilterOptionType } from '../../Filter';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { getFiltersValue, useFiltersListValue } from '../../../utils/hooks.utils';
import {
  TransactionsParams,
  TRANSACTIONS_ORIGINAL_AMOUNT_FILTER_SCHEMA,
  TRANSACTIONS_UNIFIED_AMOUNT_FILTER_SCHEMA,
} from '../../../enums/params/finance.params';
import {
  expenseIncomeTypesGroup,
  ExpenseIncomeTypesGroupEnum,
  ExpenseIncomeTypesOptionsType,
  operationTypesOptions,
  payerRecipientGroup,
  PayerRecipientGroupEnum,
  PayerRecipientOptionsType,
} from '../../../pages/Transactions/utils';
import { emptyFilterCurrencyValue } from '../utils';
import DropdownForm from '../../DropdownForm';
import Icon from '../../Icon';
import Button from '../../Button';
import { useFormik } from 'formik';
import CurrencyInput from '../../CurrencyInput';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import { CurrencyFormatter } from '../../../enums/finance/finance.enum';
import Select from '../../Select';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import SortSelect from '../../SortSelect';
import { transactionsUnsavedParams } from '../../../constants/finance.constants';
import { SortParams } from '../../../enums/params.enum';
import { scrollToError } from '../../../utils';

type FiltersType = {
  expenseTypes: ExpenseIncomeTypesOptionsType[];
  incomeTypes: ExpenseIncomeTypesOptionsType[];
  offices: PayerRecipientOptionsType[];
  users: PayerRecipientOptionsType[];
  clients: PayerRecipientOptionsType[];
  suppliers: PayerRecipientOptionsType[];
  currencies: FilterOptionType[];
};

type TransactionsFiltersProps = {
  filters: FiltersType;
  officesOptions: FilterOptionType[];
  values: TransactionsParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  setTransactionsParams: (data: Partial<TransactionsParams>) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  policyOfficiesIds: string[] | undefined;
  isOfficeSpecific: boolean;
  handleSort: (sortBy: string, direction: string) => void;
  showClearButton: boolean;
};

function TransactionsFilter({
  filters,
  values,
  handleMultiParamsChange,
  setTransactionsParams,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  policyOfficiesIds,
  isOfficeSpecific,
  handleSort,
  showClearButton,
}: TransactionsFiltersProps) {
  const intl = useIntl();

  const [isOpenOriginalAmountFilter, setIsOpenOriginalAmountFilter] = useState(false);
  const [isOpenUnifiedAmountFilter, setIsOpenUnifiedAmountFilter] = useState(false);
  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const closeOriginalAmountFilter = useCallback(() => {
    setIsOpenOriginalAmountFilter(false);
  }, []);

  const closeUnifiedAmountFilter = useCallback(() => {
    setIsOpenUnifiedAmountFilter(false);
  }, []);

  const {
    values: originalAmountValues,
    setFieldValue: setOriginalAmountFieldValue,
    handleSubmit: handleSubmitOriginalAmount,
    errors: originalAmountErrors,
  } = useFormik({
    initialValues: {
      originalAmountFrom: new CurrencyFormatter({
        value: values.originalAmountFrom?.toString() || '',
        formatted: '',
        float: values.originalAmountFrom || null,
      }),
      originalAmountTo: new CurrencyFormatter({
        value: values.originalAmountTo?.toString() || '',
        formatted: '',
        float: values.originalAmountTo || null,
      }),
      currencyIds: values.currencyIds,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: scrollToError,
    validationSchema: TRANSACTIONS_ORIGINAL_AMOUNT_FILTER_SCHEMA,
    onSubmit: data => {
      closeOriginalAmountFilter();
      setTransactionsParams({
        originalAmountFrom: data.originalAmountFrom.float,
        originalAmountTo: data.originalAmountTo.float,
        currencyIds: data.currencyIds,
        page: 0,
      });
    },
  });

  const {
    values: unifiedAmountValues,
    setFieldValue: setUnifiedAmounFieldValue,
    handleSubmit: handleSubmitUnifiedAmount,
    errors: unifiedAmounErrors,
  } = useFormik({
    initialValues: {
      unifiedAmountFrom: new CurrencyFormatter({
        value: values.unifiedAmountFrom?.toString() || '',
        formatted: '',
        float: values.unifiedAmountFrom || null,
      }),
      unifiedAmountTo: new CurrencyFormatter({
        value: values.unifiedAmountTo?.toString() || '',
        formatted: '',
        float: values.unifiedAmountTo || null,
      }),
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: scrollToError,
    validationSchema: TRANSACTIONS_UNIFIED_AMOUNT_FILTER_SCHEMA,
    onSubmit: data => {
      setTransactionsParams({
        unifiedAmountFrom: data.unifiedAmountFrom.float,
        unifiedAmountTo: data.unifiedAmountTo.float,
        page: 0,
      });
      closeUnifiedAmountFilter();
    },
  });

  const clearOriginalAmountForm = () => {
    setOriginalAmountFieldValue('originalAmountFrom', emptyFilterCurrencyValue);
    setOriginalAmountFieldValue('originalAmountTo', emptyFilterCurrencyValue);
    setOriginalAmountFieldValue('currencyIds', []);
  };

  const clearUnifiedAmountForm = () => {
    setUnifiedAmounFieldValue('unifiedAmountFrom', emptyFilterCurrencyValue);
    setUnifiedAmounFieldValue('unifiedAmountTo', emptyFilterCurrencyValue);
  };

  const expenseIncomeTypesOptions = useMemo(() => {
    if (values.types.length === 1) {
      return values.types[0] === ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES ? filters.expenseTypes : filters.incomeTypes;
    } else {
      return expenseIncomeTypesGroup.map(({ type, name }) => ({
        label: name,
        type,
        options: type === ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES ? filters.expenseTypes : filters.incomeTypes,
      }));
    }
  }, [values.types, filters.expenseTypes, filters.incomeTypes]);

  const payerRecipientOptions = useMemo(() => {
    const payerRecipientFilter = [filters.clients, filters.suppliers, filters.offices, filters.users];
    return payerRecipientGroup.map(group => ({
      label: group.name,
      type: group.type,
      options: payerRecipientFilter.find(filter => filter[0]?.type === group.type) || [],
    }));
  }, [filters.clients, filters.suppliers, filters.offices, filters.users]);

  const officesValues = useFiltersListValue(filters.offices, values.officeIds)?.filter(
    el => !isOfficeSpecific || policyOfficiesIds?.some(id => id === el.value),
  );

  const operationTypesValues = useMemo(() => getFiltersValue(operationTypesOptions, values.types), [
    values.types,
    operationTypesOptions,
  ]);

  const expenseIncomeTypesValues = useMemo(() => {
    const expenseValues = getFiltersValue(filters.expenseTypes, values.expenseTypeIds);
    const incomeValues = getFiltersValue(filters.incomeTypes, values.incomeTypeIds);
    return [...expenseValues, ...incomeValues];
  }, [filters.expenseTypes, filters.incomeTypes, values.expenseTypeIds, values.incomeTypeIds]);

  const payerRecipientValues = useMemo(() => {
    const usersValues = getFiltersValue(filters.users, values.employeePayerRecipientIds);
    const clientsValues = getFiltersValue(filters.clients, values.clientPayerRecipientIds);
    const officesValues = getFiltersValue(filters.offices, values.officePayerRecipientIds);
    const suppliersValues = getFiltersValue(filters.suppliers, values.supplierPayerRecipientIds);

    return [...usersValues, ...clientsValues, ...officesValues, ...suppliersValues];
  }, [
    filters.users,
    filters.clients,
    filters.offices,
    filters.suppliers,
    values.employeePayerRecipientIds,
    values.clientPayerRecipientIds,
    values.officePayerRecipientIds,
    values.supplierPayerRecipientIds,
  ]);

  const originalAmountCurrencyValues = useFiltersListValue(filters.currencies, originalAmountValues.currencyIds);

  const handleChangeExpenseIncomeTypes = useCallback((values: ExpenseIncomeTypesOptionsType[]) => {
    const expenseTypeIds = values
      .filter(opt => opt.type === ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES)
      .map(({ value }: Record<string, any>) => value.id);
    const incomeTypeIds: string[] = values
      .filter(opt => opt.type === ExpenseIncomeTypesGroupEnum.INCOME_TYPES)
      //@ts-ignore
      .map(({ value }: string) => value);

    setTransactionsParams({
      expenseTypeIds,
      incomeTypeIds,
      page: 0,
    });
  }, []);

  const handleOriginalAmountFromChange = useCallback((data: CurrencyInputOnChangeValues) => {
    setOriginalAmountFieldValue('originalAmountFrom', data);
  }, []);

  const handleOriginalAmountToChange = useCallback((data: CurrencyInputOnChangeValues) => {
    setOriginalAmountFieldValue('originalAmountTo', data);
  }, []);

  const handleUnifiedAmountFromChange = useCallback((data: CurrencyInputOnChangeValues) => {
    setUnifiedAmounFieldValue('unifiedAmountFrom', data);
  }, []);

  const handleUnifiedAmountToChange = useCallback((data: CurrencyInputOnChangeValues) => {
    setUnifiedAmounFieldValue('unifiedAmountTo', data);
  }, []);

  const handleChangePayerRecipient = useCallback((values: PayerRecipientOptionsType[]) => {
    const employeePayerRecipientIds: string[] = [];
    const clientPayerRecipientIds: string[] = [];
    const supplierPayerRecipientIds: string[] = [];
    const officePayerRecipientIds: string[] = [];

    values.forEach(({ value, type }) => {
      switch (type) {
        case PayerRecipientGroupEnum.CLIENTS:
          //@ts-ignore
          clientPayerRecipientIds.push(value);
          break;
        case PayerRecipientGroupEnum.OFFICES:
          //@ts-ignore
          officePayerRecipientIds.push(value);
          break;
        case PayerRecipientGroupEnum.SUPPLIERS:
          //@ts-ignore
          supplierPayerRecipientIds.push(value.id);
          break;
        case PayerRecipientGroupEnum.USERS:
          //@ts-ignore
          employeePayerRecipientIds.push(value.id);
          break;
      }
    });
    setTransactionsParams({
      employeePayerRecipientIds,
      clientPayerRecipientIds,
      supplierPayerRecipientIds,
      officePayerRecipientIds,
      page: 0,
    });
  }, []);

  const handleOriginalAmountCurrencyChange = useCallback((values: FilterOptionType[]) => {
    setOriginalAmountFieldValue(
      'currencyIds',
      values.map(({ value }) => value.id),
    );
  }, []);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <SortSelect
        sortOptions={[
          { label: intl.formatMessage(messages.dateLabel), value: 'transactionDate' },
          { label: intl.formatMessage(messages.officeLabel), value: 'office' },
          { label: intl.formatMessage(messages.projectLabel), value: 'financeProject' },
          { label: intl.formatMessage(messages.originalAmountLabel), value: 'amount' },
          { label: intl.formatMessage(messages.rateLabel), value: 'rate' },
          { label: intl.formatMessage(messages.unifiedAmountLabel), value: 'unifiedAmount' },
          { label: intl.formatMessage(messages.commentLabel), value: 'comment' },
          { label: intl.formatMessage(messages.lastUpdateColumn), value: 'lastUpdate' },
          { label: intl.formatMessage(messages.updaterColumn), value: 'updater' },
        ]}
        params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
        onSort={handleSort}
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={filters.offices?.filter(el => !isOfficeSpecific || policyOfficiesIds?.some(id => id === el.value))}
        value={officesValues}
        handleChange={handleMultiParamsChange(FilterParamsName.OFFICE_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.operationTypeFilterLabel)}
        options={operationTypesOptions}
        value={operationTypesValues}
        handleChange={handleMultiParamsChange('types')}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isGrouped={values.types.length !== 1}
        label={intl.formatMessage(messages.expenseIncomeFilterLabel)}
        options={expenseIncomeTypesOptions}
        value={expenseIncomeTypesValues}
        handleChange={handleChangeExpenseIncomeTypes}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isGrouped
        label={intl.formatMessage(messages.payerRecipientFilterLabel)}
        options={payerRecipientOptions}
        value={payerRecipientValues}
        handleChange={handleChangePayerRecipient}
        externalClass="filters__select"
      />
      <DropdownForm
        placement="bottom"
        onOpenRequest={() => setIsOpenOriginalAmountFilter(true)}
        side="left"
        dropdownToggle={
          <>
            <FormattedMessage {...messages.originalAmountLabel} />:
            <Icon iconName="filter-arrow" />
          </>
        }
        dropdownToggleClass="form__btn-add-group"
        isOpen={isOpenOriginalAmountFilter}
        dropdownClass={'experience_filter'}
      >
        <form className="form" onSubmit={handleSubmitOriginalAmount} onClick={e => e.stopPropagation()}>
          <div className="form__inputs-subwrapper">
            <CurrencyInput
              id="originalAmountFrom"
              name="originalAmountFrom"
              label={intl.formatMessage(messages.fromLabel)}
              value={originalAmountValues.originalAmountFrom.value}
              onChange={handleOriginalAmountFromChange}
            />
            <CurrencyInput
              id="originalAmountTo"
              name="originalAmountTo"
              label={intl.formatMessage(messages.toLabel)}
              value={originalAmountValues.originalAmountTo.value}
              onChange={handleOriginalAmountToChange}
              errorMessage={originalAmountErrors.originalAmountTo as string}
            />
          </div>
          <Select
            label={intl.formatMessage(messages.currenciesFilterLabel)}
            isMulti
            options={filters.currencies}
            value={originalAmountCurrencyValues}
            handleChange={handleOriginalAmountCurrencyChange}
          />
          <div className="form__buttons">
            <Button externalClass={'button--modal'} onClick={clearOriginalAmountForm} type={'button'} color="gray">
              <FormattedMessage {...messages.clearButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'}>
              <FormattedMessage {...messages.applyButton} />
            </Button>
          </div>
        </form>
      </DropdownForm>
      <DropdownForm
        placement="bottom"
        side="left"
        dropdownToggle={
          <>
            <FormattedMessage {...messages.unifiedAmountLabel} />:
            <Icon iconName="filter-arrow" />
          </>
        }
        dropdownToggleClass="form__btn-add-group"
        isOpen={isOpenUnifiedAmountFilter}
        onOpenRequest={() => setIsOpenUnifiedAmountFilter(true)}
        onCloseRequest={closeUnifiedAmountFilter}
        dropdownClass={'experience_filter'}
      >
        <form className="form" onSubmit={handleSubmitUnifiedAmount} onClick={e => e.stopPropagation()}>
          <div className="form__inputs-subwrapper">
            <CurrencyInput
              id="unifiedAmountFrom"
              name="unifiedAmountFrom"
              label={intl.formatMessage(messages.fromLabel)}
              value={unifiedAmountValues.unifiedAmountFrom.value}
              onChange={handleUnifiedAmountFromChange}
            />
            <CurrencyInput
              id="unifiedAmountTo"
              name="unifiedAmountTo"
              label={intl.formatMessage(messages.toLabel)}
              value={unifiedAmountValues.unifiedAmountTo.value}
              onChange={handleUnifiedAmountToChange}
              errorMessage={unifiedAmounErrors.unifiedAmountTo as string}
            />
          </div>
          <div className="form__buttons">
            <Button externalClass={'button--modal'} onClick={clearUnifiedAmountForm} type={'button'} color="gray">
              <FormattedMessage {...messages.clearButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'}>
              <FormattedMessage {...messages.applyButton} />
            </Button>
          </div>
        </form>
      </DropdownForm>
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.TRANSACTIONS_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={transactionsUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
    </>
  );
}

export default React.memo(TransactionsFilter);
