import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Modal from '../../../Modal';
import Button from '../../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../messages';
import { PhotoCropSetting } from '../../../../enums/users.enum';

type ModalCropPhotoProps = {
  onRequestClose: () => void;
  photoUrl: string;
  photoCropSetting?: PhotoCropSetting;
  inputRef: any;
  getPhoto: (file: File | undefined, cropSetting: PhotoCropSetting, cropFileUrl?: string) => void;
  loading?: boolean;
  image: any;
  externalCropperClass?: string;
  changeDocumentOverflowStyle?: boolean;
};

const ModalCropPhoto = ({
  onRequestClose,
  photoUrl,
  inputRef,
  getPhoto,
  loading,
  image,
  photoCropSetting,
  externalCropperClass,
  changeDocumentOverflowStyle,
}: ModalCropPhotoProps) => {
  const intl = useIntl();

  const cropperRef = useRef<any>();

  const onCrop = () => {
    getPhoto &&
      getPhoto(
        inputRef.current?.files?.[0],
        cropperRef?.current?.cropper.getData(),
        cropperRef?.current?.cropper.getCroppedCanvas().toDataURL(),
      );
  };

  return (
    <Modal
      isOpen
      size={'small'}
      title={intl.formatMessage(messages.editPhotoTitle)}
      onRequestClose={onRequestClose}
      classNameModal="crop_modal center"
      changeDocumentOverflowStyle={changeDocumentOverflowStyle}
    >
      <div className="crop_container">
        <Cropper
          style={{ height: 'auto', width: 'auto', maxHeight: 'calc(100vh - 202px)' }}
          initialAspectRatio={1}
          src={image || photoUrl}
          ref={cropperRef}
          viewMode={2}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          responsive={false}
          guides={true}
          checkCrossOrigin={false}
          zoomable={false}
          aspectRatio={1}
          data={photoCropSetting}
          crossOrigin="anonymous"
          className={externalCropperClass}
        />
      </div>
      <div className="form__buttons">
        <Button color={'gray'} externalClass={'button--modal button--cancel'} type={'button'} onClick={onRequestClose}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button externalClass={'button--modal'} type={'submit'} loading={loading} disabled={loading} onClick={onCrop}>
          <FormattedMessage {...messages.saveButton} />
        </Button>
      </div>
    </Modal>
  );
};

export default ModalCropPhoto;
