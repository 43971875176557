import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/planning.constants';
import { RejectValueErrors } from '../enums/error.enum';
import { getReducerErrors } from '../utils/reducerError.utils';
import * as paramsTypes from '../enums/params/planning.params';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

type InitialStateType = {
  resourcesTableData: any;
  currentResource: any;
  userHoursTableData: any;
  projectHoursTableData: any;
  projectGroupsTableData: any;
  activeEmployeesTableData: any;
  activeEmployeesFilterData: any;
  resourcesParams: paramsTypes.ResourcesParams;
  userTabResourceParams: paramsTypes.ResourcesParams;
  userHoursParams: paramsTypes.UserHoursParams;
  projectHoursParams: paramsTypes.ProjectHoursParams;
  projectGroupsParams: paramsTypes.ProjectGroupsParams;
  activeEmployeesParams: paramsTypes.ActiveEmployeesParams;
  errors: {
    resourcesDataError: string | RejectValueErrors[] | null;
    resourcesTableDataError: string | null;
    currentResourceError: string | null;
    userHoursDataError: string | null;
    projectHoursDataError: string | null;
    projectGroupsTableDataError: boolean | null;
    projectGroupsDataError: string | RejectValueErrors[] | null;
    activeEmployeesDataError: string | null;
  };
  loading: {
    getResourcesList: boolean;
    createResources: boolean;
    currentResource: boolean;
    getUserHoursData: boolean;
    getProjectHoursData: boolean;
    getProjectGroupsList: boolean;
    deleteProjectGroup: boolean;
    createProjectGroup: boolean;
    editProjectGroup: boolean;
    getActiveEmployees: boolean;
    editActiveEmployees: boolean;
  };
};

const initialState: InitialStateType = {
  resourcesTableData: null,
  currentResource: null,
  userHoursTableData: null,
  projectHoursTableData: null,
  projectGroupsTableData: null,
  activeEmployeesTableData: null,
  activeEmployeesFilterData: null,
  resourcesParams: new paramsTypes.ResourcesParams(),
  userTabResourceParams: new paramsTypes.ResourcesParams(),
  userHoursParams: new paramsTypes.UserHoursParams(),
  projectHoursParams: new paramsTypes.ProjectHoursParams(),
  projectGroupsParams: new paramsTypes.ProjectGroupsParams(),
  activeEmployeesParams: new paramsTypes.ActiveEmployeesParams(),
  errors: {
    resourcesDataError: null,
    resourcesTableDataError: null,
    currentResourceError: null,
    userHoursDataError: null,
    projectHoursDataError: null,
    projectGroupsTableDataError: null,
    projectGroupsDataError: null,
    activeEmployeesDataError: null,
  },
  loading: {
    getResourcesList: false,
    createResources: false,
    currentResource: false,
    getUserHoursData: false,
    getProjectHoursData: false,
    getProjectGroupsList: false,
    deleteProjectGroup: false,
    createProjectGroup: false,
    editProjectGroup: false,
    getActiveEmployees: false,
    editActiveEmployees: false,
  },
};

const planningReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_RESOURCES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getResourcesList: true },
      };

    case ActionTypes.GET_RESOURCES_LIST_SUCCESS:
      return {
        ...state,
        resourcesTableData: action.payload,
        errors: { ...state.errors, resourcesTableDataError: null },
        loading: { ...state.loading, getResourcesList: false },
      };

    case ActionTypes.GET_RESOURCES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, resourcesTableDataError: action.payload.message },
        loading: { ...state.loading, getResourcesList: false },
      };

    case ActionTypes.SET_RESOURCES_PARAMS_REQUEST: {
      return {
        ...state,
        resourcesParams: new paramsTypes.ResourcesParams({ ...state.resourcesParams, ...action.payload }),
        loading: { ...state.loading, getResourcesList: true },
      };
    }

    case ActionTypes.SET_USER_TAB_RESOURCES_PARAMS_REQUEST: {
      return {
        ...state,
        userTabResourceParams: new paramsTypes.ResourcesParams({ ...state.userTabResourceParams, ...action.payload }),
      };
    }

    case ActionTypes.POST_RESOURCES_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, resourcesDataError: null },
        loading: { ...state.loading, createResources: true },
      };

    case ActionTypes.POST_RESOURCES_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, resourcesDataError: null },
        loading: { ...state.loading, createResources: false },
      };

    case ActionTypes.POST_RESOURCES_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          resourcesDataError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createResources: false },
      };

    case ActionTypes.GET_CURRENT_RESOURCE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, currentResource: true },
      };

    case ActionTypes.GET_CURRENT_RESOURCE_SUCCESS:
      return {
        ...state,
        currentResource: action.payload,
        errors: { ...state.errors, currentResourceError: null },
        loading: { ...state.loading, currentResource: false },
      };

    case ActionTypes.GET_CURRENT_RESOURCE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, currentResourceError: action.payload.message },
        loading: { ...state.loading, currentResource: false },
      };

    case ActionTypes.SET_CURRENT_RESOURCE: {
      return {
        ...state,
        currentResource: action.payload,
      };
    }

    case ActionTypes.GET_USER_HOURS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getUserHoursData: true },
      };

    case ActionTypes.GET_USER_HOURS_LIST_SUCCESS:
      return {
        ...state,
        userHoursTableData: action.payload,
        errors: { ...state.errors, userHoursDataError: null },
        loading: { ...state.loading, getUserHoursData: false },
      };

    case ActionTypes.GET_USER_HOURS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, userHoursDataError: action.payload.message },
        loading: { ...state.loading, getUserHoursData: false },
      };

    case ActionTypes.SET_USER_HOURS_PARAMS_REQUEST:
      return {
        ...state,
        userHoursParams: new paramsTypes.UserHoursParams({ ...state.userHoursParams, ...action.payload }),
        loading: { ...state.loading, getUserHoursData: true },
      };

    case ActionTypes.GET_PROJECT_HOURS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getProjectHoursData: true },
      };

    case ActionTypes.GET_PROJECT_HOURS_LIST_SUCCESS:
      return {
        ...state,
        projectHoursTableData: action.payload,
        errors: { ...state.errors, projectHoursDataError: null },
        loading: { ...state.loading, getProjectHoursData: false },
      };

    case ActionTypes.GET_PROJECT_HOURS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, projectHoursDataError: action.payload.message },
        loading: { ...state.loading, getProjectHoursData: false },
      };

    case ActionTypes.SET_PROJECT_HOURS_PARAMS_REQUEST: {
      return {
        ...state,
        projectHoursParams: new paramsTypes.ProjectHoursParams({ ...state.projectHoursParams, ...action.payload }),
        loading: { ...state.loading, getProjectHoursData: true },
      };
    }

    case ActionTypes.GET_PROJECT_GROUPS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getProjectGroupsList: true },
      };

    case ActionTypes.GET_PROJECT_GROUPS_LIST_SUCCESS:
      return {
        ...state,
        projectGroupsTableData: { ...action.payload, content: action.payload.content },
        errors: { ...state.errors, projectGroupsTableDataError: null },
        loading: { ...state.loading, getProjectGroupsList: false },
      };

    case ActionTypes.GET_PROJECT_GROUPS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, projectGroupsTableDataError: action.payload.message },
        loading: { ...state.loading, getProjectGroupsList: false },
      };

    case ActionTypes.POST_PROJECT_GROUP_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, projectGroupsDataError: null },
        loading: { ...state.loading, createProjectGroup: true },
      };

    case ActionTypes.POST_PROJECT_GROUP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, projectGroupsDataError: null },
        loading: { ...state.loading, createProjectGroup: false },
      };

    case ActionTypes.POST_PROJECT_GROUP_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          projectGroupsDataError: getReducerErrors(action.payload),
        },
        loading: {
          ...state.loading,
          createProjectGroup: false,
        },
      };

    case ActionTypes.DELETE_PROJECT_GROUP_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, projectGroupsDataError: null },
        loading: { ...state.loading, deleteProjectGroup: true },
      };

    case ActionTypes.DELETE_PROJECT_GROUP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, projectGroupsDataError: null },
        loading: { ...state.loading, deleteProjectGroup: false },
      };

    case ActionTypes.DELETE_PROJECT_GROUP_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, projectGroupsDataError: action.payload.message },
        loading: { ...state.loading, deleteProjectGroup: false },
      };

    case ActionTypes.PUT_PROJECT_GROUP_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, projectGroupsDataError: null },
        loading: { ...state.loading, editProjectGroup: true },
      };

    case ActionTypes.PUT_PROJECT_GROUP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, projectGroupsDataError: null },
        loading: { ...state.loading, editProjectGroup: false },
      };

    case ActionTypes.PUT_PROJECT_GROUP_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          projectGroupsDataError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editProjectGroup: false },
      };

    case ActionTypes.SET_PROJECT_GROUPS_PARAMS_REQUEST:
      return {
        ...state,
        projectGroupsParams: new paramsTypes.ProjectGroupsParams({ ...state.projectGroupsParams, ...action.payload }),
        loading: { ...state.loading, getProjectGroupsList: true },
      };

    case ActionTypes.GET_ACTIVE_EMPLOYEES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getActiveEmployees: true },
      };

    case ActionTypes.GET_ACTIVE_EMPLOYEES_LIST_SUCCESS:
      const groupedUsers = groupBy(action.payload.content, users => {
        return users.department.id;
      });
      return {
        ...state,
        activeEmployeesTableData: map(groupedUsers, (user: any) => {
          return {
            departmentId: user[0].department.id,
            departmentName: user[0].department.displayName,
            employees: user,
          };
        }),
        errors: { ...state.errors, activeEmployeesDataError: null },
        loading: { ...state.loading, getActiveEmployees: false },
      };

    case ActionTypes.GET_ACTIVE_EMPLOYEES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, activeEmployeesDataError: action.payload.message },
        loading: { ...state.loading, getActiveEmployees: false },
      };

    case ActionTypes.PUT_ACTIVE_EMPLOYEES_LIST_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, activeEmployeesDataError: null },
        loading: { ...state.loading, editActiveEmployees: true },
      };

    case ActionTypes.PUT_ACTIVE_EMPLOYEES_LIST_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, activeEmployeesDataError: null },
        loading: { ...state.loading, editActiveEmployees: false },
      };

    case ActionTypes.PUT_ACTIVE_EMPLOYEES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, activeEmployeesDataError: action.payload.message },
        loading: { ...state.loading, editActiveEmployees: false },
      };

    case ActionTypes.SET_ACTIVE_EMPLOYEES_PARAMS_REQUEST:
      return {
        ...state,
        activeEmployeesParams: new paramsTypes.ActiveEmployeesParams({
          ...state.activeEmployeesParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getActiveEmployees: true },
      };

    case ActionTypes.RESET_PLANNING_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          resourcesDataError: null,
          currentResourceError: null,
          projectGroupsDataError: null,
        },
      };

    case ActionTypes.RESET_PLANNING_STATE:
      return {
        ...initialState,
        resourcesParams: state.resourcesParams,
        userHoursParams: state.userHoursParams,
        projectHoursParams: state.projectHoursParams,
        projectGroupsParams: state.projectGroupsParams,
        activeEmployeesParams: state.activeEmployeesParams,
      };

    default:
      return state;
  }
};

export default planningReducer;
