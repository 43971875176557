import { defineMessages } from 'react-intl';
import { nameColumn, editButton, deleteButton, newButton } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  pageTitle: {
    id: 'candidate.specializations.title.specializations',
    defaultMessage: 'Specializations',
  },
});
