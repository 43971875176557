import React from 'react';
import { Candidate } from '../../../../enums/candidates.enums';
import ContactItem from './ContactItem';

function Contacts({ candidate }: { candidate: Candidate }) {
  return candidate.hasContacts ? (
    <div className="page__profile-contacts">
      <ContactItem
        items={candidate.emails}
        iconName="envelope"
        linkPrefix="mailto"
        externalClass="page__profile-contact--emails"
      />
      <ContactItem
        items={candidate.phones}
        iconName="phone"
        linkPrefix="tel"
        externalClass="page__profile-contact--phones"
      />
    </div>
  ) : null;
}

export default Contacts;
