import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import { NavLink } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { QUESTIONS_FOR_SKILL_VALIDATION_SCHEMA, SkillQuestionsInfo } from '../../../enums/competencies.enum';
import Input from '../../Input';
import Icon from '../../Icon';
import Button from '../../Button';
import { QuestionType, SkillQuestionsListItemType } from '../../../pages/QuestionsForSkill';
import r from '../../../constants/routes.constants';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { scrollToError } from '../../../utils';

export type EasyCompetenceType = {
  name: string;
  id: string;
  competenceLevels: {
    name: string;
    id: string;
    skills: {
      name: string;
      id: string;
      questionsCount: number;
    }[];
  }[];
};

export type SkillQuestionsType = {
  competenceId: string;
  levelId: string;
  questions: Array<QuestionType>;
  skillId: string;
};

export type EditSkillQuestionsFormPropsType = {
  isLoading: boolean;
  onSubmit: (data: any) => void;
  SkillQuestions: SkillQuestionsListItemType;
  skillQuestionsDataError: string | RejectValueErrors[] | null;
};

function EditSkillQuestionsForm({
  isLoading,
  onSubmit,
  SkillQuestions,
  skillQuestionsDataError,
}: EditSkillQuestionsFormPropsType) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit, setValues, setFieldError } = useFormik({
    initialValues: new SkillQuestionsInfo(SkillQuestions),
    validationSchema: QUESTIONS_FOR_SKILL_VALIDATION_SCHEMA,
    validateOnChange: false,
    validate: scrollToError,
    onSubmit: data => {
      onSubmit(data.questions);
    },
  });

  useSetFieldsErrors(skillQuestionsDataError, setFieldError);

  useEffect(() => {
    SkillQuestions && setValues(SkillQuestions);
  }, [SkillQuestions]);

  const addNewQuestion = useCallback(() => {
    setFieldValue('questions', [
      ...values.questions,
      {
        question: '',
        correctAnswer: '',
      },
    ]);
  }, [values]);

  const removeQuestion = useCallback(
    index => () => {
      values.questions.splice(index, 1);
      setFieldValue('questions', values.questions);
    },
    [values],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const questionsErrors: any = useMemo(() => errors.questions, [errors.questions]);
  return (
    <form className={'form form--page'} onSubmit={handleSubmit}>
      <div className="form__inputs-wrapper">
        <div className="form__input-block">
          <Input
            name={'name'}
            id={'name'}
            hasError={hasError('name')}
            errorMessage={errors.name}
            defaultValue={values.name}
            label={intl.formatMessage(messages.skillLabel)}
            disabled
            externalClass={'disabled'}
          />
        </div>
        <div className="form__inputs-subwrapper">
          <div className={'form__input-block form__input-block--half'}>
            <Input
              id={'competenceName'}
              name={'competenceName'}
              hasError={hasError('competenceName')}
              errorMessage={errors.competenceName}
              defaultValue={values.competenceName}
              label={intl.formatMessage(messages.competenceLabel)}
              disabled
              externalClass={'disabled'}
            />
          </div>
          <div className={'form__input-block form__input-block--half'}>
            <Input
              id={'levelName'}
              name={'levelName'}
              hasError={hasError('levelName')}
              errorMessage={errors.levelName}
              defaultValue={values.levelName}
              label={intl.formatMessage(messages.levelLabel)}
              disabled
              externalClass={'disabled'}
            />
          </div>
        </div>
        <div className="form__group">
          <ReactSortable
            list={values.questions}
            setList={newState => setFieldValue(`questions`, newState)}
            animation={200}
            handle={'.form__btn-move-inputs'}
            style={{ marginBottom: '14px' }}
          >
            {values.questions.map((question, index) => (
              <div className="form__group-wrapper form__group-wrapper--bordered" key={`[${index}]`}>
                <button className="form__btn-move-inputs" type={'button'}>
                  <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
                </button>
                <div className="form__inputs-wrapper">
                  <div className="form__input-block">
                    <Input
                      id={`questions[${index}].name`}
                      name={`questions[${index}].question`}
                      label={intl.formatMessage(messages.questionLabel)}
                      defaultValue={values.questions[index].question}
                      onChange={handleChange}
                      hasError={hasError(`questions[${index}].question`)}
                      errorMessage={questionsErrors && questionsErrors[index]?.question}
                    />
                    <Input
                      id={`questions[${index}].name`}
                      name={`questions[${index}].correctAnswer`}
                      tag="textarea"
                      label={intl.formatMessage(messages.answerLabel)}
                      defaultValue={values.questions[index].correctAnswer}
                      onChange={handleChange}
                      hasError={hasError(`questions[${index}].correctAnswer`)}
                      errorMessage={questionsErrors && questionsErrors[index]?.correctAnswer}
                    />
                  </div>
                </div>
                <button className="form__btn-clean-inputs" type={'button'} onClick={removeQuestion(index)}>
                  <Icon iconName={'cross'} externalClass={'form__btn-clean'} />
                </button>
              </div>
            ))}
          </ReactSortable>
          <button className="form__btn-add-group" type={'button'} onClick={addNewQuestion}>
            <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
            <FormattedMessage {...messages.questionLabel} />
          </button>
        </div>
      </div>
      <ErrorMessage> {skillQuestionsDataError}</ErrorMessage>
      <div className="form__buttons">
        <NavLink to={r.skillQuestions}>
          <Button externalClass={'button--modal'} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
        </NavLink>
        <Button externalClass={'button--modal'} type={'submit'} disabled={isLoading} loading={isLoading}>
          <FormattedMessage {...messages.saveButton} />
        </Button>
      </div>
    </form>
  );
}

export default EditSkillQuestionsForm;
