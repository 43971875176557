import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants/date.constants';
import { ChartIncomesExpensesMonthType, ChartIncomesExpensesType } from '../../../../types/finance';

export const summaryDataSet = [
  {
    label: 'Planned Income',
    key: 'plannedIncome',
    backgroundColor: '#5D6DBE',
  },
  {
    label: 'Actual Income',
    key: 'actualIncome',
    backgroundColor: '#6A9E3F',
  },
  {
    label: 'Planned Expenses',
    key: 'plannedExpenses',
    backgroundColor: '#FF8A00',
  },
  {
    label: 'Actual Expenses',
    key: 'expenses',
    backgroundColor: '#DA5748',
  },
];

export const getSummaryChartMonth = (
  monthesIncomesExpenses: ChartIncomesExpensesMonthType[] = [],
  monthRange: string[],
) => {
  const data = {
    labels: monthRange.map(month => {
      return moment(month).format(DATE_FORMAT.MMM);
    }),
    datasets: summaryDataSet.slice(0, 4).map(el => ({
      id: el.label,
      label: el.label,
      data: monthRange.map(month => {
        const data = monthesIncomesExpenses?.find(item => item.month === month);
        if (data) {
          const filed = el.key;
          return data[filed as keyof ChartIncomesExpensesType];
        }

        return 0;
      }),
      backgroundColor: el.backgroundColor,
      borderRadius: 6,
    })),
  };

  return data;
};
