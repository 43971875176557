import { UserPolicy } from '../enums/policies.enum';

export function getUserPolicies(): UserPolicy[] {
  const userPolicies = localStorage.getItem('userPolicies');
  return userPolicies && JSON.parse(userPolicies);
}

export function setUserPolicies(userPolicies: UserPolicy[]) {
  localStorage.setItem('userPolicies', JSON.stringify(userPolicies));
}

export function removeUserPolicies() {
  localStorage.removeItem('userPolicies');
}

export const checkPolicies = (verifiablePolicies: string[], userPolicies: UserPolicy[]) => {
  return verifiablePolicies.some(polisy => userPolicies?.find(userPolicy => userPolicy?.policy?.name === polisy));
};
