import { defineMessages } from 'react-intl';
import { deleteButton, editButton, newButton, competenciesTitle, employeesLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  deleteButton,
  editButton,
  newButton,
  competenciesTitle,
  employeesLabel,
  assessmentDateTitle: {
    id: 'competencies.assessments.column.assessment.date',
    defaultMessage: 'Assessment Date',
  },
  relatedEventTitle: {
    id: 'competencies.assessments.column.related.event',
    defaultMessage: 'Related Event',
  },
  organizersTitle: {
    id: 'competencies.assessments.column.organizers',
    defaultMessage: 'Organizers',
  },
  reviewersTitle: {
    id: 'competencies.assessments.column.reviewers',
    defaultMessage: 'Reviewers',
  },
  lastUpdatesTitle: {
    id: 'competencies.assessments.column.last.updates',
    defaultMessage: 'Last Updates',
  },
  updatersTitle: {
    id: 'competencies.assessments.column.updaters',
    defaultMessage: 'Updaters',
  },
  pageTitle: {
    id: 'competencies.assessments.title.assessments',
    defaultMessage: 'Assessments',
  },
});
