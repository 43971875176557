import { get } from 'lodash-es';
import * as yup from 'yup';
import { getCurrentAbsence } from '../utils/planning.utils';
import { AbsenceEvent, UserPreviewInfo } from './users.enum';

export const PROJECT_GROUP_SCHEMA = yup.object().shape({
  fullName: yup.string().required('Required'),
  shortName: yup.string().required('Required'),
});

export const RESOURCE_CREATE_SCHEMA = yup.object().shape({
  userIds: yup.array().min(1, 'Required'),
  endDate: yup
    .date()
    .when(
      'startDate',
      (startDate: any, yup: any) => startDate && yup.min(startDate, 'End Date must be equal or later than Start Date'),
    ),
  workloads: yup
    .array()
    .of(
      yup.object().shape({
        projectGroup: yup.object().shape({
          fullName: yup.string().required('Required'),
        }),
      }),
    )
    .test('percent', 'Total percents must be equal 100', value => {
      return value?.length
        ? value?.reduce(function (accumulator, currentValue) {
            return accumulator + +currentValue?.percent;
          }, 0) === 100
        : true;
    }),
});

export type Workloads = {
  percent: number;
  projectGroup: { id: number; fullName: string; shortName: string; color: string };
};

export type TotalDataType = {
  monthPlanned: { days: number; hours: number };
  workDays: number;
  worked: { days: number; hours: number };
};

export type FactLoad = {
  factWorkedByProjects: {
    id: number;
    name: string;
    pkey: string;
    worked: { days: number; hours: number };
  }[];
  projectGroup: {
    id: number;
    fullName: string;
    shortName: 'unknown group';
    color: null;
    alertsStatus: null | string;
  };
};

export class DayWorkload {
  date: string;
  isAbsence: boolean;
  isHoliday: boolean;
  isMultiGrouped: boolean;
  isPlanned: boolean;
  workloads: Workloads[];
  absenceEvent: AbsenceEvent;
  employeeNotHired?: boolean;
  factLoads: FactLoad[];

  constructor(item?: unknown) {
    this.date = get(item, 'date', 'aaa');
    this.isAbsence = get(item, 'isAbsence', false);
    this.isHoliday = get(item, 'isHoliday', false);
    this.isMultiGrouped = get(item, 'isMultiGrouped', false);
    this.isPlanned = get(item, 'isPlanned', false);
    this.workloads = get(item, 'workloads', []);
    this.absenceEvent = new AbsenceEvent(getCurrentAbsence(get(item, 'absenceEvents', null)));
    this.employeeNotHired = get(item, 'employeeNotHired', null);
    this.factLoads = get(item, 'factLoads', []);
  }

  get absenceColor() {
    return this.absenceEvent?.eventTypeColor || '';
  }

  get absenceTranslucentColor() {
    return `${this.absenceEvent?.eventTypeColor}1A` || '';
  }

  get absenceNameLetter() {
    return this.absenceEvent?.eventTypeName?.trim()[0] || '';
  }

  get allFactLoadsProjects() {
    return this.factLoads.reduce((previousValue: any[], currentValue: FactLoad) => {
      const projects = currentValue.factWorkedByProjects.map(project => ({
        ...project,
        status: currentValue.projectGroup.alertsStatus,
        projectGroupId: currentValue.projectGroup.id,
      }));
      return [...previousValue, ...projects];
    }, []);
  }

  get isNotPlannedGroupe() {
    return this.factLoads.filter(factload =>
      this.workloads.find(workload => workload.projectGroup.id === factload.projectGroup.id),
    );
  }
}

export class UserDataType {
  dayWorkloads: DayWorkload[];
  endDate: any;
  startDate: any;
  timeTotal: TotalDataType;
  user: UserPreviewInfo;
  workloads: any;

  constructor(item?: unknown) {
    this.dayWorkloads = get(item, 'dayWorkloads', []).map((el: any) => new DayWorkload(el));
    this.endDate = get(item, 'endDate', null);
    this.startDate = get(item, 'startDate', null);
    this.timeTotal = get(item, 'timeTotal', null);
    this.user = get(item, 'user', null);
  }
}

export class ResourceComplexItem {
  departmentId: string;
  departmentName: string;
  resources: UserDataType[];
  timeTotal: TotalDataType;

  constructor(item?: unknown) {
    this.departmentId = get(item, 'departmentId', '');
    this.departmentName = get(item, 'departmentName', '');
    this.resources = get(item, 'resources', []).map((el: any) => new UserDataType(el));
    this.timeTotal = get(item, 'timeTotal', null);
  }
}

export const MULTI_GROUP_COLOR = '#facdcd';
