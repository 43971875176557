import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import { PROJECT_TYPE_SCHEMA } from '../../../enums/finance/finance.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { ProjectType } from '../../../types/finance';
import { scrollToError } from '../../../utils';

type ModalEditProjectTypeProps = {
  onCloseRequest: () => void;
  editProjectType: (data: { data: ProjectType; callback: () => void }) => void;
  projectType: ProjectType;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
};

function ModalEditProjectType({
  onCloseRequest,
  editProjectType,
  projectType,
  error,
  isLoading,
  isOpen,
}: ModalEditProjectTypeProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, setFieldError } = useFormik({
    initialValues: projectType,
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: PROJECT_TYPE_SCHEMA,
    onSubmit: data => {
      return editProjectType({ data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.editProjectTypeTitle)}
        size={'small'}
        classNameModal="center"
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages.nameColumn)}
                defaultValue={values?.name || ''}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              onClick={onCloseRequest}
              type={'button'}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalEditProjectType;
