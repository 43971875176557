import { CandidateComment, CandidateStatus, InitialCandidateType } from '../enums/candidates.enums';
import { FiltersParams } from '../enums/params.enum';
import * as paramsTypes from '../enums/params/candidates.params';
import {
  CandidateReceivingSourceType,
  CandidateSpecializationType,
  CandidateTechnologyType,
} from '../types/candidates';
import { QueryParamsType } from '../utils/params.utils';
import request from '../utils/request.utils';

export function getCandidateStatusesList(
  params?: QueryParamsType<paramsTypes.CandidateStatusParams> | QueryParamsType<FiltersParams>,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/candidateStatuses',
    params,
  };
  return request(options);
}

export function createCandidateStatus(data: CandidateStatus) {
  const options: RequestConfig = {
    method: 'post',
    url: '/candidateStatuses',
    data,
  };
  return request(options);
}

export function editCandidateStatus(data: CandidateStatus) {
  const options: RequestConfig = {
    method: 'put',
    url: `/candidateStatuses/${data.id}`,
    data,
  };
  return request(options);
}

export function deleteCandidateStatus(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/candidateStatuses/${id}`,
  };
  return request(options);
}

export function getCandidateSpecializationsList(
  params?: QueryParamsType<paramsTypes.CandidateSpecializationParams> | QueryParamsType<FiltersParams>,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/candidateSpecializations',
    params,
  };
  return request(options);
}

export function createCandidateSpecialization(data: CandidateSpecializationType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/candidateSpecializations',
    data,
  };
  return request(options);
}

export function editCandidateSpecialization(data: CandidateSpecializationType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/candidateSpecializations/${data.id}`,
    data,
  };
  return request(options);
}

export function deleteCandidateSpecialization(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/candidateSpecializations/${id}`,
  };
  return request(options);
}

export function getCandidateTechnologiesList(
  params?: QueryParamsType<paramsTypes.CandidateTechnologyParams> | QueryParamsType<FiltersParams>,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/candidateTechnologies',
    params,
  };
  return request(options);
}

export function createCandidateTechnology(data: CandidateTechnologyType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/candidateTechnologies',
    data,
  };
  return request(options);
}

export function editCandidateTechnology(data: CandidateTechnologyType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/candidateTechnologies/${data.id}`,
    data,
  };
  return request(options);
}

export function deleteCandidateTechnology(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/candidateTechnologies/${id}`,
  };
  return request(options);
}

export function getCandidateReceivingSourcesList(
  params?: QueryParamsType<paramsTypes.CandidateReceivingSourceParams> | QueryParamsType<FiltersParams>,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/receivingSources',
    params,
  };
  return request(options);
}

export function createCandidateReceivingSource(data: CandidateReceivingSourceType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/receivingSources',
    data,
  };
  return request(options);
}

export function editCandidateReceivingSource(data: CandidateReceivingSourceType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/receivingSources/${data.id}`,
    data,
  };
  return request(options);
}

export function deleteCandidateReceivingSource(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/receivingSources/${id}`,
  };
  return request(options);
}

export function getCandidateList(params?: QueryParamsType<paramsTypes.CandidateParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/candidates',
    params,
  };
  return request(options);
}

export function createCandidate(data: InitialCandidateType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/candidates',
    data,
  };
  return request(options);
}

export function editCandidate(data: InitialCandidateType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/candidates/${data.id}`,
    data,
  };
  return request(options);
}

export function deleteCandidate(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/candidates/${id}`,
  };
  return request(options);
}

export function getCandidateInfo(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/candidates/${uuid}`,
  };
  return request(options);
}

export function uploadCandidatePhoto(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'patch',
    url: `candidates/${uuid}/photo`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteCandidatePhoto(uuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `candidates/${uuid}/photo`,
  };
  return request(options);
}

export function getCandidateComments(candidateId: string, params?: any) {
  const options: RequestConfig = {
    method: 'get',
    url: `candidates/${candidateId}/comments`,
    params,
  };
  return request(options);
}

export function createCandidateComment(candidateId: string, data: CandidateComment, replyToCommentId?: string) {
  const options: RequestConfig = {
    method: 'post',
    url: `candidates/${candidateId}/comments`,
    data,
    ...(replyToCommentId && {
      params: {
        replyToCommentId,
      },
    }),
  };
  return request(options);
}

export function editCandidateComment(uuid: string, data: string) {
  const options: RequestConfig = {
    method: 'patch',
    url: `candidates/comments/${uuid}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteCandidateComment(uuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `candidates/comments/${uuid}`,
  };
  return request(options);
}
