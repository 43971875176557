import * as paramsTypes from './../enums/params/questionnaires.params';
import { QueryParamsType } from './../utils/params.utils';
import request from '../utils/request.utils';
import { FiltersParams } from '../enums/params.enum';

export function getPollTemplatesCategoryList(
  params?: QueryParamsType<paramsTypes.PollTemplatesCategoryParams> | QueryParamsType<FiltersParams>,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/questionnaires/templateCategories',
    params,
  };
  return request(options);
}

export function createNewPollTemplateCategory(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/questionnaires/templateCategories',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deletePollTemplateCategory(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/questionnaires/templateCategories/${id}`,
  };
  return request(options);
}

export function editPollTemplateCategory(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/questionnaires/templateCategories/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getPollTemplatesList(
  params?: QueryParamsType<paramsTypes.PollTemplatesParams> | QueryParamsType<FiltersParams>,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/questionnaires/templates',
    params,
  };
  return request(options);
}

export function createNewPollTemplate(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/questionnaires/templates',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deletePollTemplate(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/questionnaires/templates/${id}`,
  };
  return request(options);
}

export function editPollTemplate(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/questionnaires/templates/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function moveTemplate(data: any) {
  const options: RequestConfig = {
    method: 'patch',
    url: `/questionnaires/templates/${data.id}?destination=${data.newCategoryId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return request(options);
}

export function getCurrentPollTemplate(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/questionnaires/templates/${id}`,
  };
  return request(options);
}

export const getPollsList = (params?: QueryParamsType<paramsTypes.PollParams>) => {
  const options: RequestConfig = {
    method: 'get',
    url: '/questionnaires/polls',
    params,
  };
  return request(options);
};

export const getMyPollsList = (params?: QueryParamsType<paramsTypes.MyPollsParams>) => {
  const options: RequestConfig = {
    method: 'get',
    url: '/questionnaires/polls',
    params,
  };
  return request(options);
};

export const createNewPoll = (data: any) => {
  const options: RequestConfig = {
    method: 'post',
    url: '/questionnaires/polls',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return request(options);
};

export const editPoll = (data: any) => {
  const options: RequestConfig = {
    method: 'patch',
    url: `/questionnaires/polls/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
};

export const deletePoll = (id: string) => {
  const options: RequestConfig = {
    method: 'delete',
    url: `/questionnaires/polls/${id}`,
  };
  return request(options);
};

export const getCurrentPoll = (id: string) => {
  const options: RequestConfig = {
    method: 'get',
    url: `/questionnaires/polls/${id}`,
  };
  return request(options);
};

export const getAnswersByPollIdForAdmin = (id: string) => {
  const options: RequestConfig = {
    method: 'get',
    url: `/questionnaires/polls/${id}/answers/grouped`,
  };
  return request(options);
};

export const updateAnswersByAdmin = (dataAnswers: any) => {
  const options: RequestConfig = {
    method: 'put',
    url: `/questionnaires/polls/${dataAnswers.pollId}/answer`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: dataAnswers.data,
  };
  return request(options);
};

export const getUsersByOfficeIds = (officeIds: Array<string>) => {
  const options: RequestConfig = {
    method: 'get',
    url: `/users`,
    params: { officeIds, size: 1000, active: true },
  };
  return request(options);
};

export const getAnswersByPollAndUser = (data: any) => {
  const options: RequestConfig = {
    method: 'get',
    url: `/questionnaires/polls/${data.pollId}/answers/${data.userId}`,
  };
  return request(options);
};

export const updateOwnPollAnswers = (dataPoll: any) => {
  const { pollId, data, isIntermediateAnswers } = dataPoll;
  const options: RequestConfig = {
    method: 'put',
    url: `/questionnaires/polls/${pollId}/answers`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params: { isIntermediateAnswers },
  };
  return request(options);
};

export const getNotAnsweredPollsByUser = (data: any) => {
  const options: RequestConfig = {
    method: 'get',
    url: `/questionnaires/polls`,
    params: { ...data, size: 1000 },
  };
  return request(options);
};
