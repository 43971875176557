import React, { useMemo } from 'react';
import { formatValue } from 'react-currency-input-field';
import { useIntl } from 'react-intl';
import { IncomesExpensesReportParams } from '../../../enums/params/finance.params';
import { getMonthInRange } from '../../../utils';
import messages from '../messages';
import SummaryChart from './SummaryChart';
import { CurrencyType } from '../../../types/finance';

type ChartLayeoutProps = {
  tableData: any;
  params: IncomesExpensesReportParams;
  isShowSalaries: boolean;
  baseCurrency: CurrencyType | undefined;
};

const ChartLayout = ({ params, tableData, isShowSalaries, baseCurrency }: ChartLayeoutProps) => {
  const intl = useIntl();

  const monthRange = useMemo(() => getMonthInRange(params.monthFrom, params.monthTo), [
    params.monthFrom,
    params.monthTo,
  ]);

  const totalPlanIncomeSummary = useMemo(
    () =>
      tableData?.totalMonthIncomes.length
        ? tableData?.totalMonthIncomes.reduce(
            (sum: number, current: { planIncome: number }) => sum + current.planIncome,
            0,
          )
        : 0,
    [tableData?.totalMonthIncomes],
  );

  const totalActualIncomeSummary = useMemo(
    () =>
      tableData?.totalMonthIncomes.length
        ? tableData?.totalMonthIncomes.reduce(
            (sum: number, current: { actualIncome: number }) => sum + current.actualIncome,
            0,
          )
        : 0,
    [tableData?.totalMonthIncomes],
  );

  const totalPlanExpensesSummary = useMemo(
    () =>
      tableData?.totalMonthExpenses.length
        ? tableData?.totalMonthExpenses.reduce(
            (sum: number, current: { plannedAmount: number }) => sum + current.plannedAmount,
            0,
          )
        : 0,
    [tableData?.totalMonthExpenses],
  );

  const totalExpensesSummary = useMemo(
    () =>
      tableData?.totalMonthExpenses.length
        ? tableData?.totalMonthExpenses.reduce((sum: number, current: { amount: number }) => sum + current.amount, 0)
        : 0,
    [tableData?.totalMonthExpenses],
  );

  const incomesExpensesData = useMemo(() => {
    return tableData?.totalMonthExpenses.map((expense: { amount: number; month: string; plannedAmount: number }) => ({
      month: expense.month,
      plannedExpenses: expense.plannedAmount,
      expenses: expense.amount,
      actualIncome:
        tableData.totalMonthIncomes.find((income: { month: string }) => income.month === expense.month)?.actualIncome ||
        0,
      plannedIncome:
        tableData.totalMonthIncomes.find((income: { month: string }) => income.month === expense.month)?.planIncome ||
        0,
    }));
  }, [tableData, isShowSalaries]);

  return (
    <>
      <div className="chart-summary-block">
        <div>{`${intl.formatMessage(messages.plannedIncomeLabel)} (${formatValue({
          value: totalPlanIncomeSummary.toString(),
          suffix: ` ${baseCurrency?.name}`,
        })})`}</div>
        <div>{`${intl.formatMessage(messages.actualIncomeLabel)} (${formatValue({
          value: totalActualIncomeSummary.toString(),
          suffix: ` ${baseCurrency?.name}`,
        })})`}</div>
        <div>{`${intl.formatMessage(messages.plannedExpensesTitle)} (${formatValue({
          value: totalPlanExpensesSummary.toString(),
          suffix: ` ${baseCurrency?.name}`,
        })})`}</div>
        <div>{`${intl.formatMessage(messages.actualExpensesTitle)} (${formatValue({
          value: totalExpensesSummary.toString(),
          suffix: ` ${baseCurrency?.name}`,
        })})`}</div>
      </div>
      <SummaryChart incomesExpensesData={incomesExpensesData} monthRange={monthRange} baseCurrency={baseCurrency} />
    </>
  );
};

export default ChartLayout;
