import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { DepthLevels } from '../../../constants/tables.constants';
import { EventInfo, EventPreviewInfo } from '../../../enums/schedule.enum';
import { UserInfo } from '../../../enums/users.enum';
import { isDark } from '../../../utils/color.utils';
import { getTableCell } from '../../../utils/table.utils';
import { TableColumnType } from '../../HierarchicalTable';
import Icon from '../../Icon';
import Avatar from '../../Profile/Avatar';
import messages from '../messages';

type DepartmentsListItemType = {
  departmentId: string;
  departmentName: string;
  users: {
    user: UserInfo;
    events: EventInfo[];
  };
};

export const useDataForDepartmentsListTable = (
  dates: {
    date: string;
    isHoliday: boolean;
  }[],
  startRange: string,
  endRange: string,
  openEventModal: (event: EventPreviewInfo, e: React.MouseEvent) => void,
  openModalAbsence: (e: React.MouseEvent, user: UserInfo) => void,
) => {
  const intl = useIntl();
  const startRangeOfSearch = moment(startRange);
  const endRangeOfSearch = moment(endRange);
  const tableHeaderItems = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.departmentsMembersLabel),
        sortName: '',
        className: 'sticky',
      },
      ...dates.map(el => ({
        name: moment(el.date).format(DATE_FORMAT.MMM_DD),
        sortName: '',
        className: `table__head-column--center dates ${el.isHoliday ? 'holiday' : ''}`,
      })),
    ],
    [dates],
  );

  const tableColumnsStart: TableColumnType[] = useMemo(
    () => [
      {
        Header: '',
        id: 'nameColumn',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: DepartmentsListItemType) => <span>{row.departmentName}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: any) =>
                row.user && (
                  <>
                    <Avatar userInfo={row.user} size="tiny" tableImg isUserPageLink fileSize={36} />
                    <span className="table__user-fullName">{row.user.fullName}</span>
                    <button
                      className="table__user-absence-btn"
                      type="button"
                      onClick={e => openModalAbsence(e, row.user)}
                    >
                      <Icon iconName="info" externalClass="table__user-absence-icon" />
                    </button>
                  </>
                ),
            },
          ]);
        },
      },
    ],
    [openModalAbsence],
  );

  const tableColumnsDate: TableColumnType[] = useMemo(() => {
    return dates.map((date, index: number) => {
      return {
        Header: '',
        id: `${index}`,
        headClassName: 'data-head',
        externalColumnClass: () => 'event-data',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: { user: UserInfo; events: EventPreviewInfo[] }) => {
                const currentDate = moment(date.date);

                return (
                  <div className="event-department-wrapper">
                    {row.events
                      .sort((a, b) => {
                        return new Date(b.localEndDate).valueOf() - new Date(a.localEndDate).valueOf();
                      })
                      .sort((a, b) => {
                        return new Date(a.localStartDate).valueOf() - new Date(b.localStartDate).valueOf();
                      })
                      .map(event => {
                        const startDate = moment(event.localStartDate);
                        const endDate = moment(event.localEndDate);
                        if (!(startDate <= currentDate && endDate >= currentDate)) {
                          return null;
                        } else if (
                          moment(currentDate).subtract(1, 'day') >= startDate &&
                          !currentDate.isSame(startRangeOfSearch)
                        ) {
                          return <div key={event.id} className="event-row"></div>;
                        }
                        const eventTypeColor = event.eventTypeColor;
                        return (
                          <div
                            key={event.id}
                            className="event-row data"
                            onClick={e => openEventModal(event, e)}
                            style={{
                              backgroundColor: eventTypeColor,
                              color: isDark(eventTypeColor) ? '#fff' : '#4A4A4A',
                              width: `calc(100% * ${
                                endDate > endRangeOfSearch
                                  ? endRangeOfSearch.diff(currentDate, 'days') + 1
                                  : endDate.diff(currentDate, 'days') + 1
                              } - 20px)`,
                            }}
                          >
                            <div className="event-row-text">
                              {event.eventTitle}
                              {event.requiresApprovalEnabled
                                ? event.absencesApprove
                                  ? `, ${intl.formatMessage(messages.approvedLabel)}`
                                  : `, ${intl.formatMessage(messages.notApprovedLabel)}`
                                : null}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              },
            },
          ]);
        },
      };
    });
  }, [dates]);

  const tableColumns = useMemo(() => tableColumnsStart.concat(tableColumnsDate), [
    tableColumnsStart,
    tableColumnsDate,
    dates,
  ]);

  return {
    tableHeaderItems,
    tableColumns,
  };
};
