import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Modal from '../../Modal';
import Input from '../../Input';
import { EDIT_POSITIONS_HISTORY_SCHEMA } from '../../../enums/users.enum';
import Button from '../../Button';
import Select from '../../Select';
import Icon from '../../Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import moment from 'moment';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { DepartmentsInfoType } from '../../../types/libraries';
import { scrollToError } from '../../../utils';

type ModalEditPositionsProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  positions: { id: string; displayName: string }[];
  departments: DepartmentsInfoType[];
  loading?: boolean;
  requestError: string | RejectValueErrors[] | null;
  grades: { id: string; level: number; name: string; positionIds: any[]; positions: any[] }[];
  userHistory: {
    companyPosition: {
      displayName: string;
      gradeId: string;
      gradeName: string;
      id: string;
      priority: number;
    };
    dateFrom: string;
    companyPositionId: string;
    gradeId: string;
    id: '';
  }[];
};

function ModalEditPositions({
  isOpen,
  onClose,
  onSubmit,
  positions,
  loading,
  requestError,
  grades,
  userHistory,
}: ModalEditPositionsProps) {
  const intl = useIntl();

  const { values, setFieldValue, handleSubmit, errors, touched, resetForm, setFieldError } = useFormik({
    initialValues: {
      history: userHistory
        ? userHistory
        : [
            {
              dateFrom: moment().format(DATE_FORMAT.YYYY_MM_DD),
              companyPositionId: null,
              gradeId: null,
              id: '',
            },
          ],
    },
    enableReinitialize: true,
    validate: scrollToError,
    validationSchema: EDIT_POSITIONS_HISTORY_SCHEMA,
    onSubmit: data => onSubmit(data.history),
  });

  useSetFieldsErrors(requestError, setFieldError);

  useEffect(() => {
    if (!isOpen) resetForm();
  }, [isOpen]);

  const positionOptions = useMemo(() => positions.map(item => ({ label: item.displayName, value: item })), [positions]);

  const gradesOptions = useMemo(() => grades.map(item => ({ label: item.name, value: item })), [grades]);

  const positionValue = useCallback(
    index =>
      values.history[index].companyPositionId
        ? positionOptions.find(
            ({ value }: { value: { id: string } }) => value.id === values.history[index].companyPositionId,
          )
        : null,
    [positionOptions, values],
  );

  const gradeValue = useCallback(
    index =>
      values.history[index].gradeId
        ? gradesOptions.find(({ value }: { value: { id: string } }) => value.id === values.history[index].gradeId)
        : null,
    [gradesOptions, values],
  );

  const addPositions = useCallback(() => {
    setFieldValue('history', [
      ...values.history,
      {
        dateFrom: moment().format(DATE_FORMAT.YYYY_MM_DD),
        companyPositionId: null,
        gradeId: null,
        id: '',
      },
    ]);
  }, [values]);

  const removePosition = useCallback(
    index => {
      values.history.splice(index, 1);
      setFieldValue('history', values.history);
    },
    [values],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handlePosition = useCallback((item, index) => {
    setFieldValue(`history[${index}].companyPositionId`, item.value && item.value.id);
    setFieldValue(`history[${index}].companyPosition`, item.value);
    setFieldValue(`history[${index}].gradeId`, item.value && item.value.gradeId);
  }, []);

  const handleGrade = useCallback((item, index) => {
    setFieldValue(`history[${index}].grade`, item.value);
    setFieldValue(`history[${index}].gradeId`, item.value && item.value?.id);
  }, []);

  const handleDateFrom = useCallback((value, index) => {
    setFieldValue(`history[${index}].dateFrom`, value);
  }, []);

  const positionsErrors: any = useMemo(() => errors.history, [errors.history]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={intl.formatMessage(messages.editPositionsTitle)}
      classNameModal="modal--edit-profile edit_positions_history"
      shouldCloseOnOverlayClick={false}
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          {values.history.map((item: any, index: number) => (
            <div className="form__inputs-subwrapper position_block-wrapper" key={index}>
              <div className="form__inputs-wrapper position_block">
                <div className="form__inputs-subwrapper">
                  <div className="form__input-block form__input-block--two-third">
                    <Select
                      label={intl.formatMessage(messages.positionLabel)}
                      name={`history[${index}].companyPositionId`}
                      options={positionOptions}
                      value={positionValue(index)}
                      handleChange={value => handlePosition(value, index)}
                      hasError={hasError(`history[${index}].companyPositionId`)}
                      errorMessage={positionsErrors && positionsErrors[index]?.companyPositionId}
                      isSearchable={true}
                      iconName="id-badge"
                      isClearable
                    />
                  </div>
                  <div className="form__input-block form__input-block--near-third">
                    <Input
                      id={`history[${index}].dateFrom`}
                      type={'date'}
                      externalClass="cursor-pointer"
                      label={intl.formatMessage(messages.startDateLabel)}
                      name={`workloads[${index}].dateFrom`}
                      onChange={value => handleDateFrom(value.target.value, index)}
                      hasError={hasError(`history[${index}].dateFrom`)}
                      errorMessage={positionsErrors && positionsErrors[index]?.dateFrom}
                      defaultValue={values.history[index].dateFrom}
                    />
                  </div>
                </div>
                {grades.length >= 1 && (
                  <div className="form__inputs-subwrapper">
                    <div className="form__input-block form__input-block--two-third grade_input">
                      <Select
                        label={intl.formatMessage(messages.gredeSelect)}
                        name={`history[${index}].gradeId`}
                        options={gradesOptions}
                        value={gradeValue(index)}
                        handleChange={value => handleGrade(value, index)}
                        hasError={hasError(`history[${index}].gradeId`)}
                        errorMessage={positionsErrors && positionsErrors[index]?.gradeId}
                        isSearchable={true}
                        isClearable
                      />
                    </div>
                  </div>
                )}
              </div>
              <button className="form__btn-clean-inputs" type={'button'} onClick={() => removePosition(index)}>
                <Icon iconName={'cross'} />
              </button>
            </div>
          ))}
          <button className="form__btn-add-group" onClick={addPositions} type={'button'}>
            <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
            <FormattedMessage {...messages.addButton} />
          </button>
        </div>
        <ErrorMessage>{requestError}</ErrorMessage>
        {errors.history && typeof errors.history === 'string' && <ErrorMessage>{errors.history}</ErrorMessage>}
        <div className="form__buttons">
          <Button type="button" onClick={onClose} color="gray" externalClass="button--modal">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button type="submit" externalClass="button--modal" loading={loading} disabled={loading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditPositions;
