import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { DATE_FORMAT } from '../../../../constants/date.constants';
import { DepthLevels } from '../../../../constants/tables.constants';
import { UserInfo } from '../../../../enums/users.enum';
import { getHoursAndMinutes } from '../../../../utils/activity.utils';
import { getTableCell } from '../../../../utils/table.utils';
import TableUserAvatar from '../../../TableUserAvatar';
import messages from '../../messages';
import { countOfActivityTypeColumn, countOfTotalColumn, typeOfActivity } from '../utils';

export const useDataForTable = (dateRange: string[]) => {
  const intl = useIntl();
  const tableColumns = useMemo(() => {
    const employeeColumns = [
      {
        id: 'name',
        Header: intl.formatMessage(messages.departmentsMembersLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: any) => (
                <div>
                  <span>
                    {row?.totalItem ? <FormattedMessage {...messages.totalRow} /> : row.department.displayName}
                  </span>
                </div>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: any) => (
                <div>
                  <TableUserAvatar users={[new UserInfo(row.userPreview)]} fileSize={36} />
                </div>
              ),
            },
          ]),
      },
    ];
    const arr: any = [];

    dateRange?.forEach((activity: any) => {
      Object.values(typeOfActivity).forEach(type => {
        arr.push({
          id: `${type}-${activity}`,
          Header: <div className="day-display">{type}</div>,
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: any) => {
                  if (row.totalItem) return null;
                  const data = row.departmentActivity?.find((el: any) => el.day === activity);

                  switch (type) {
                    case typeOfActivity.Logged:
                      return getHoursAndMinutes(data?.logged) || '-';
                    case typeOfActivity.Active:
                      return getHoursAndMinutes(data?.active) || '-';
                    case typeOfActivity.Tracked:
                      return getHoursAndMinutes(data?.tracked) || '-';
                    case typeOfActivity.Submited:
                      return getHoursAndMinutes(data?.submitted) || '-';
                    default:
                      return null;
                  }
                },
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: any) => {
                  const data = row.daysActivity?.find((el: any) => el.day === activity);

                  switch (type) {
                    case typeOfActivity.Logged:
                      return getHoursAndMinutes(data?.logged) || '-';
                    case typeOfActivity.Active:
                      return getHoursAndMinutes(data?.active) || '-';
                    case typeOfActivity.Tracked:
                      return getHoursAndMinutes(data?.tracked) || '-';
                    case typeOfActivity.Submited:
                      return getHoursAndMinutes(data?.submitted) || '-';
                    default:
                      return null;
                  }
                },
              },
            ]),
        });
      });
    });

    const totalColumns = Object.values(typeOfActivity).map(type => {
      const totalClass = classNames('sticky', `total-${type.toLowerCase()}`);
      return {
        id: type,
        Header: <div className="day-display">{type}</div>,
        externalColumnClass: () => totalClass,
        headClassName: totalClass,
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: dep => dep <= DepthLevels.SECOND,
              content: (row: any) => {
                switch (type) {
                  case typeOfActivity.Logged:
                    return getHoursAndMinutes(row?.logged) || '-';
                  case typeOfActivity.Active:
                    return getHoursAndMinutes(row?.active) || '-';
                  case typeOfActivity.Tracked:
                    return getHoursAndMinutes(row?.tracked) || '-';
                  case typeOfActivity.Submited:
                    return getHoursAndMinutes(row?.submitted) || '-';
                  default:
                    return null;
                }
              },
            },
          ]),
      };
    });

    return [...employeeColumns, ...arr, ...totalColumns];
  }, [dateRange]);

  const tableHeaderItems = useMemo(() => {
    const start = [{ name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' }];
    const headers = dateRange.map((el: any) => ({
      name: moment(el).format(DATE_FORMAT.dddd_MMM_DD_YYYY),
      className: 'header__row',
      colspan: countOfActivityTypeColumn,
      sortName: '',
    }));
    const total = [
      {
        name: 'Total',
        className: 'header__row sticky total-header',
        colspan: countOfTotalColumn,
        sortName: '',
      },
    ];
    return [...start, ...headers, ...total];
  }, [dateRange]);

  return {
    tableColumns,
    tableHeaderItems,
  };
};
