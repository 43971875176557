import { defineMessages } from 'react-intl';
import { nameColumn, editButton, deleteButton, newButton } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  pageTitle: {
    id: 'finance.currencies.title.currencies',
    defaultMessage: 'Currencies',
  },
  baseCurrencylabel: {
    id: 'finance.currencies.label.base.currency',
    defaultMessage: 'Base Currency',
  },
});
