import classNames from 'classnames';
import React from 'react';
import { Candidate } from '../../enums/candidates.enums';
import { ThumbnailsSize, UserInfo, UserPreviewInfo } from '../../enums/users.enum';
import Avatar, { AvatarSize } from '../Profile/Avatar';
import { userHintId } from '../Hint/utils';

type TableAvatarProps = {
  user: UserPreviewInfo | UserInfo | Candidate;
  fileSize: ThumbnailsSize;
  isUserPageLink: boolean;
  externaltableUserClass?: string;
  showTooltip: boolean;
  isCandidate?: boolean;
  size?: AvatarSize;
  selectedTableUser?: boolean;
};

function TableAvatar({
  user,
  fileSize,
  isUserPageLink,
  externaltableUserClass,
  isCandidate,
  showTooltip,
  size = 'small',
  selectedTableUser = false,
}: TableAvatarProps) {
  const tableUserClass = classNames('table__user', externaltableUserClass, {
    'user-with-name': !showTooltip && selectedTableUser,
  });
  const avatar = (
    <Avatar
      isCandidate={isCandidate}
      userInfo={user}
      fileSize={fileSize}
      size={size}
      tableImg
      isUserPageLink={isUserPageLink}
      externalClass={showTooltip ? '' : 'avatar-with-name'}
    />
  );
  return (
    <div className={tableUserClass}>
      {showTooltip ? (
        <div data-for={userHintId} data-tip={showTooltip ? user.fullName : ''}>
          {avatar}
        </div>
      ) : (
        avatar
      )}
      {!showTooltip && <div className="table__user-name">{user.fullName}</div>}
    </div>
  );
}

export default TableAvatar;
