import { ActivityParams } from '../enums/params/activity.params';
import request from '../utils/request.utils';

export function getActivity(params: ActivityParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/activity',
    params,
  };
  return request(options);
}

export function getSoftwareLinksList() {
  const options: RequestConfig = {
    method: 'get',
    url: '/software',
    params: { size: 1000 },
  };
  return request(options);
}

export function getSoftware(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/software/${id}`,
    responseType: 'blob',
  };
  return request(options);
}

export function uploadSoftware(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: 'software',
    data,
  };
  return request(options);
}

export function deleteSoftware(uuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/software/${uuid}`,
  };
  return request(options);
}
