import { useFormik } from 'formik';
import { get } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BonusCategoryInfo, BONUS_GRADE_VALIDATION_SCHEMA, ExperienceGrades } from '../../../enums/bonuses.enums';
import { RejectValueErrors } from '../../../enums/error.enum';
import { BonusExpType } from '../../../pages/BonusesByGrades';
import { GradeInfoType } from '../../../types/libraries';
import { handleSubmitData } from '../../../utils/bonuses.utils';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import Icon from '../../Icon';
import Input from '../../Input';
import Modal from '../../Modal';
import Select from '../../Select';
import messages from '../messages';
import { scrollToError } from '../../../utils';

type ModalNewBonusProps = {
  onCloseRequest: () => void;
  editBonus: (data: any) => void;
  resetErrors: () => void;
  isOpen: boolean;
  bonusError: null | string | RejectValueErrors[];
  isLoading: boolean;
  bonusData: BonusCategoryInfo;
  bonusExpData: BonusExpType;
  gradesList: any;
};

function ModalEditBonusByGrades({
  bonusData,
  bonusExpData,
  gradesList,
  isOpen,
  bonusError,
  isLoading,
  onCloseRequest,
  editBonus,
  resetErrors,
}: ModalNewBonusProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError, resetForm } = useFormik({
    initialValues: new BonusCategoryInfo(bonusData),
    validationSchema: BONUS_GRADE_VALIDATION_SCHEMA,
    enableReinitialize: true,
    onSubmit: data => editBonus({ id: data.id, data: handleSubmitData(data), callback: resetAndExit }),
  });

  useSetFieldsErrors(bonusError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const removeBonus = (deleteBonusExpIndex: number) => {
    setFieldValue(
      `experiences.${bonusExpData.bonusExpIndex}.experienceGrades`,
      values.experiences[bonusExpData.bonusExpIndex].experienceGrades.filter(
        (el: ExperienceGrades, index) => index !== deleteBonusExpIndex,
      ),
    );
  };

  const handleGradeFrom = useCallback(
    (newValue: any, index: number) => {
      setFieldValue(
        `experiences.${bonusExpData.bonusExpIndex}.experienceGrades.${index}.gradeFromId`,
        newValue.value.id,
      );
      setFieldValue(`experiences.${bonusExpData.bonusExpIndex}.experienceGrades.${index}.gradeFrom`, newValue.value);
    },
    [values.experiences],
  );

  const handleGradeTo = useCallback((newValue: any, index: number) => {
    setFieldValue(`experiences[${bonusExpData.bonusExpIndex}].experienceGrades[${index}].gradeToId`, newValue.value.id);
    setFieldValue(`experiences[${bonusExpData.bonusExpIndex}].experienceGrades[${index}].gradeTo`, newValue.value);
  }, []);

  const addBonus = useCallback(() => {
    setFieldValue(`experiences.${bonusExpData.bonusExpIndex}.experienceGrades`, [
      ...values.experiences[bonusExpData.bonusExpIndex].experienceGrades,
      new ExperienceGrades(),
    ]);
  }, [values]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const gradesOptions = useMemo(
    () =>
      gradesList.map((grade: GradeInfoType) => {
        return {
          label: grade.name,
          value: grade,
        };
      }),
    [gradesList],
  );

  const gradeValues = useCallback((id: any) => gradesOptions.find((el: any) => el.value.id === id), [gradesOptions]);
  const bonusErrors: any = useMemo(() => errors?.experiences, [errors.experiences]);

  const modalTitle = (
    <>
      {values.name}
      <div className="bonus-exp-title">{values.experiences[bonusExpData.bonusExpIndex]?.name}</div>
    </>
  );

  return (
    <Modal isOpen={isOpen} title={modalTitle} onRequestClose={resetAndExit}>
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper edit-bonus-by-grades-wrapper">
          <div className="form__group">
            {values.experiences[bonusExpData.bonusExpIndex]?.experienceGrades.map(
              (el: ExperienceGrades, index: number) => (
                <div className="form__group-wrapper" key={index}>
                  <div className="form__input-block">
                    <div className="form__inputs-subwrapper align-start">
                      <Select
                        name={`experiences[${bonusExpData.bonusExpIndex}].experienceGrades[${index}].gradeFromId`}
                        handleChange={data => handleGradeFrom(data, index)}
                        options={gradesOptions}
                        value={gradeValues(el.gradeFromId) || null}
                        label={intl.formatMessage(messages.gradeFromLabel)}
                        hasError={hasError(
                          `experiences[${bonusExpData.bonusExpIndex}].experienceGrades[${index}].gradeFrom`,
                        )}
                        errorMessage={
                          bonusErrors && bonusErrors[bonusExpData.bonusExpIndex]?.experienceGrades[index]?.gradeFrom
                        }
                      />
                      <Select
                        name={`experiences[${bonusExpData.bonusExpIndex}].experienceGrades[${index}].gradeToId`}
                        handleChange={data => handleGradeTo(data, index)}
                        options={gradesOptions}
                        value={gradeValues(el.gradeToId) || null}
                        label={intl.formatMessage(messages.gradeToLabel)}
                        hasError={hasError(
                          `experiences[${bonusExpData.bonusExpIndex}].experienceGrades[${index}].gradeTo`,
                        )}
                        errorMessage={
                          bonusErrors && bonusErrors[bonusExpData.bonusExpIndex]?.experienceGrades[index]?.gradeTo
                        }
                      />
                    </div>
                    <Input
                      label={intl.formatMessage(messages.valueLabel)}
                      name={`experiences[${bonusExpData.bonusExpIndex}].experienceGrades[${index}].value`}
                      onChange={handleChange}
                      defaultValue={values.experiences[bonusExpData.bonusExpIndex]?.experienceGrades[index]?.value}
                      hasError={hasError(`experiences[${bonusExpData.bonusExpIndex}].experienceGrades[${index}].value`)}
                      errorMessage={
                        bonusErrors && bonusErrors[bonusExpData.bonusExpIndex]?.experienceGrades[index]?.value
                      }
                    />
                  </div>
                  <button className="form__btn-clean-inputs" type={'button'} onClick={() => removeBonus(index)}>
                    <Icon iconName={'cross'} />
                  </button>
                </div>
              ),
            )}
          </div>
          <button className="form__btn-add-group" onClick={addBonus} type={'button'}>
            <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
            <FormattedMessage {...messages.addButton} />
          </button>
        </div>

        <ErrorMessage>{!Array.isArray(bonusError) && bonusError}</ErrorMessage>
        <div className={'form__buttons'}>
          <Button color={'gray'} type="button" externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            externalClass={'button--modal'}
            type="submit"
            onClick={scrollToError}
            loading={isLoading}
            disabled={isLoading}
          >
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditBonusByGrades;
