import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  addButton,
  activeLabel,
  deleteModalWithItemNameTitle,
  jiraProjectsLabel,
  statusLabel,
  projectsLabel,
  allLabel,
  inactiveLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteButton,
  cancelButton,
  activeLabel,
  saveButton,
  addButton,
  deleteModalWithItemNameTitle,
  jiraProjectsLabel,
  statusLabel,
  projectsLabel,
  allLabel,
  inactiveLabel,
  fullNameInput: {
    id: 'planning.project.groups.modal.new.project.group.label.full.name',
    defaultMessage: 'Full Name',
  },
  shortNameInput: {
    id: 'planning.project.groups.modal.new.project.group.label.short.name',
    defaultMessage: 'Short Name',
  },
  alertsStatusInput: {
    id: 'planning.project.groups.modal.new.project.group.label.inform.about.unplanned.activity',
    defaultMessage: 'Inform about unplanned activity',
  },
  newProjectGroupTitle: {
    id: 'planning.project.groups.modal.new.project.group.title.new.project.group',
    defaultMessage: 'New Project Group',
  },
  editProjectGroupTitle: {
    id: 'planning.project.groups.modal.edit.project.group.title.edit.project.group',
    defaultMessage: 'Edit Project Group',
  },
  confirmmoveProjectTitle: {
    id:
      'planning.project.groups.modal.new.project.group.title.the.project.is.already.in.another.group.are.you.sure.you.want.to.add.it.to.this.one',
    defaultMessage: 'The project is already in another group! Are you sure you want to add it to this one?',
  },
  alertsTitle: {
    id: 'planning.project.groups.label.alerts',
    defaultMessage: 'Alerts',
  },
  shortNameTitle: {
    id: 'planning.project.groups.label.short.name',
    defaultMessage: 'Short Name',
  },
  fullNameTitle: {
    id: 'planning.project.groups.label.full.name',
    defaultMessage: 'Full name',
  },
});
