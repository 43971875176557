import React from 'react';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '../../components/Icon';
import { DELETE_NOTIFICATION_TEMPLATE, UPDATE_NOTIFICATION_TEMPLATE } from '../../constants/policies.constants';
import { NotificationTemplate, TemplateType, triggerOptions, triggerTypes } from '../../enums/notifications.enum';
import { replaceDynamicFieldsToDisplay } from '../../utils/notifications.utils';
import messages from './messages';

export const useDataForTable = (
  setNotificationTemplateClicked: (data: any) => void,
  openEditNotificationTemplateModal: () => void,
  openDeleteNotificationTemplateModal: () => void,
) => {
  const intl = useIntl();

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: NotificationTemplate) => {
          setNotificationTemplateClicked(row);
          openEditNotificationTemplateModal();
        },
        verifiablePolicies: [UPDATE_NOTIFICATION_TEMPLATE],
      },

      {
        label: (row: NotificationTemplate) =>
          !row?.isBaseTemplate ? (
            <>
              <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
              <FormattedMessage {...messages.deleteButton} />
            </>
          ) : null,
        itemClassName: 'dropdown__list-item__button',
        handler: (row: NotificationTemplate) => {
          setNotificationTemplateClicked(row);
          openDeleteNotificationTemplateModal();
        },
        verifiablePolicies: [DELETE_NOTIFICATION_TEMPLATE],
      },
    ],
    [],
  );

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.triggerColumn),
        modifier: (row: NotificationTemplate) =>
          row.templateType === TemplateType.SCHEDULE_EVENT
            ? triggerOptions.find(item => item.value === row.notificationActionType)?.label
            : triggerTypes.find(item => item.value === row.templateType)?.label,
        sortName: 'notificationActionType',
      },
      {
        name: intl.formatMessage(messages.eventTypeLabel),
        modifier: (row: NotificationTemplate) =>
          row.isBaseTemplate ? <FormattedMessage {...messages.defaultTemplateColumn} /> : row.eventType?.name,
        sortName: 'eventType.name',
      },
      {
        name: intl.formatMessage(messages.subjectColumn),
        modifier: (row: NotificationTemplate) =>
          row.subjectTemplate.length > 50
            ? replaceDynamicFieldsToDisplay(row.subjectTemplate, row.templateType)?.substring(0, 50) + '...'
            : replaceDynamicFieldsToDisplay(row.subjectTemplate, row.templateType),
        sortName: 'subjectTemplate',
      },
    ],
    [],
  );

  return {
    tableColumns,
    tableActions,
  };
};
