import { defaultTo } from 'lodash-es';
import get from 'lodash-es/get';
import * as yup from 'yup';
import { PhotoCropSetting } from './users.enum';

export class BrandingInfo {
  body: string;
  faviconFile: {
    cropSetting: PhotoCropSetting;
    fileId: string;
    url: string;
  };
  faviconUrl: string | null;
  globalAccents: string;
  headerBackground: string;
  headerForeground: string;
  headerShadow: boolean;
  isDeleteFavicon: boolean;
  isDeleteLogo: boolean;
  isFaviconUrl: boolean;
  isLogoUrl: boolean;
  logoFile: {
    cropSetting: PhotoCropSetting;
    fileId: string;
    url: string;
  };
  logoUrl: string | null;
  titles: string;

  constructor(branding?: unknown) {
    this.body = defaultTo(get(branding, 'body'), 'roboto');
    this.faviconFile = get(branding, 'faviconFile', null);
    this.faviconUrl = get(branding, 'faviconUrl', null);
    this.globalAccents = defaultTo(get(branding, 'globalAccents'), '#c1201e');
    this.headerBackground = defaultTo(get(branding, 'headerBackground'), '#ffffff');
    this.headerForeground = defaultTo(get(branding, 'headerForeground'), '#4a4a4a');
    this.headerShadow = defaultTo(get(branding, 'headerShadow'), true);
    this.isDeleteFavicon = get(branding, 'isDeleteFavicon', null);
    this.isDeleteLogo = get(branding, 'isDeleteLogo', null);
    this.isFaviconUrl = get(branding, 'isFaviconUrl', null);
    this.isLogoUrl = get(branding, 'isLogoUrl', null);
    this.logoFile = get(branding, 'logoFile', null);
    this.logoUrl = get(branding, 'logoUrl', null);
    this.titles = defaultTo(get(branding, 'titles'), 'roboto');
  }
}

export const BRANDING_SCHEMA = yup.object().shape({
  portalBrandingDto: yup.object().shape({
    body: yup.string().trim().nullable(true),
    faviconUrl: yup
      .string()
      .trim()
      .matches(
        /(^$|((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$)/,
        'Enter correct url!',
      )
      .nullable(true),
    globalAccents: yup
      .string()
      .trim()
      .matches(/^#[0-9a-f]{6}$/)
      .nullable(true),
    headerBackground: yup
      .string()
      .trim()
      .matches(/^#[0-9a-f]{6}$/)
      .nullable(true),
    headerForeground: yup
      .string()
      .trim()
      .matches(/^#[0-9a-f]{6}$/)
      .nullable(true),
    headerShadow: yup.boolean().nullable(true),
    logoUrl: yup
      .string()
      .trim()
      .matches(
        /(^$|((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$)/,
        'Enter correct url!',
      )
      .nullable(true),
    titles: yup.string().trim().nullable(true),
  }),
});

export class BrandingData {
  logo: null | File;
  favicon: null | File;
  portalBrandingDto: BrandingInfo;

  constructor(data?: any) {
    this.logo = get(data, 'logo', null);
    this.favicon = get(data, 'favicon', null);
    this.portalBrandingDto = new BrandingInfo(get(data, 'portalBrandingDto'));
  }
}

export const FONT_OPTIONS = [
  { value: 'roboto', label: 'Roboto' },
  { value: 'comic sans', label: 'Comic Sans' },
];

export class ResourceLink {
  id: string;
  defaultPosition: number;
  deletePhoto: boolean;
  displayName: string;
  file: {
    cropSetting: PhotoCropSetting;
    id: string;
    size: number;
    url: string;
  } | null;
  hidden: boolean;
  position: number;
  url: string;

  constructor(resourceLink?: unknown, defaultPosition = 0) {
    this.id = get(resourceLink, 'id', '');
    this.deletePhoto = get(resourceLink, 'deletePhoto', false);
    this.defaultPosition = get(resourceLink, 'defaultPosition', defaultPosition);
    this.displayName = get(resourceLink, 'displayName', '');
    this.file = get(resourceLink, 'file', null);
    this.hidden = get(resourceLink, 'hidden', false);
    this.position = get(resourceLink, 'position', 0);
    this.url = get(resourceLink, 'url', '');
  }
}

export const RESOURCE_LINK_SCHEMA = yup.object().shape({
  dto: yup.object().shape({
    url: yup.string().trim().required('Required'),
    displayName: yup
      .string()
      .trim()
      .test((value: any, testContext: any) => {
        const newFile = testContext.from[1].value.file;
        const existFile = testContext.from[0].value.file;
        return newFile || value || existFile
          ? true
          : testContext.createError({
              path: testContext.path,
              message: 'Label or icon must be filled',
            });
      }),
  }),
});
