import { TemplateType } from '../enums/notifications.enum';

export const replaceDynamicFieldsToDisplay = (text: string, type: string) => {
  if (type === TemplateType.SCHEDULE_EVENT) {
    return text
      .replace(/{{recipientName}}/gi, '[RecipientFullName]')
      .replace(/{{eventLink}}/gi, '[Event.Link]')
      .replace(/{{eventType}}/gi, '[Event.TypeName]')
      .replace(/{{eventTitle}}/gi, '[Event.Title]')
      .replace(/{{eventDescription}}/gi, '[Event.Description]')
      .replace(/{{dateFrom}}/gi, '[Event.StartDate]')
      .replace(/{{dateTo}}/gi, '[Event.EndDate]')
      .replace(/{{timeFrom}}/gi, '[Event.StartTime]')
      .replace(/{{timeTo}}/gi, '[Event.EndTime]')
      .replace(/{{calendarDays}}/gi, '[Event.CalendarDays]')
      .replace(/{{workDays}}/gi, '[Event.WorkDays]')
      .replace(/{{targetMember}}/gi, '[Event.TargetMember]')
      .replace(/{{eventParticipants}}/gi, '[Event.Participants]')
      .replace(/{{eventOffices}}/gi, '[Event.Offices]')
      .replace(/{{eventAddress}}/gi, '[Event.Address]')
      .replace(/{{finalDecision}}/gi, '[Event.FinalDecision]')
      .replace(/{{daysSpent}}/gi, '[Event.Counter]')
      .replace(/{{daysLimit}}/gi, '[Event.Limit]')
      .replace(/{{isApproved}}/gi, '[Event.Approved]')
      .replace(/{{excludeParticipants}}/gi, '[Event.ExcludeParticipant]');
  }

  if (type === TemplateType.CREATE_ACCOUNT || type === TemplateType.UPDATE_PASSWORD) {
    return text
      .replace(/{{employeeName}}/gi, '[Member Full Name]')
      .replace(/{{login}}/gi, '[Member Login]')
      .replace(/{{password}}/gi, '[Member Password]')
      .replace(/{{primaryEmail}}/gi, '[Member Primary E-mail]')
      .replace(/{{accountLink}}/gi, '[Link to Member Personal Profile]');
  }

  if (type === TemplateType.QUESTIONNAIRE_IN_PROGRESS) {
    return text
      .replace(/{{pollName}}/gi, '[Poll Name]')
      .replace(/{{pollDescription}}/gi, '[Poll Description]')
      .replace(/{{pollDate}}/gi, '[Poll Due Date]')
      .replace(/{{pollOrganizes}}/gi, '[Poll Orinizers]')
      .replace(/{{pollLink}}/gi, '[Personal Poll Link]');
  }

  if (type === TemplateType.QUESTIONNAIRE_ANSWERED) {
    return text
      .replace(/{{organizerName}}/gi, '[Orginizer Full Name]')
      .replace(/{{pollName}}/gi, '[Poll Name]')
      .replace(/{{pollDescription}}/gi, '[Poll Description]')
      .replace(/{{pollDate}}/gi, '[Poll Due Date]')
      .replace(/{{employeeName}}/gi, '[Responder Full Name]')
      .replace(/{{responseDateTime}}/gi, '[Response Date and Time]')
      .replace(/{{answeredResponders}}/gi, '[Answered Responders]')
      .replace(/{{allResponders}}/gi, '[All Responders]')
      .replace(/{{pollLink}}/gi, '[Poll Link]');
  }
};

export const replaceDynamicFieldsToRequest = (text: string, type: string) => {
  if (type === TemplateType.SCHEDULE_EVENT) {
    return text
      .replace(/\[RecipientFullName]/gi, '{{recipientName}}')
      .replace(/\[Event.Link]/gi, '{{eventLink}}')
      .replace(/\[Event.TypeName]/gi, '{{eventType}}')
      .replace(/\[Event.Title]/gi, '{{eventTitle}}')
      .replace(/\[Event.Description]/gi, '{{eventDescription}}')
      .replace(/\[Event.StartDate]/gi, '{{dateFrom}}')
      .replace(/\[Event.EndDate]/gi, '{{dateTo}}')
      .replace(/\[Event.StartTime]/gi, '{{timeFrom}}')
      .replace(/\[Event.EndTime]/gi, '{{timeTo}}')
      .replace(/\[Event.CalendarDays]/gi, '{{calendarDays}}')
      .replace(/\[Event.WorkDays]/gi, '{{workDays}}')
      .replace(/\[Event.TargetMember]/gi, '{{targetMember}}')
      .replace(/\[Event.Participants]/gi, '{{eventParticipants}}')
      .replace(/\[Event.Offices]/gi, '{{eventOffices}}')
      .replace(/\[Event.Address]/gi, '{{eventAddress}}')
      .replace(/\[Event.FinalDecision]/gi, '{{finalDecision}}')
      .replace(/\[Event.Counter]/gi, '{{daysSpent}}')
      .replace(/\[Event.Limit]/gi, '{{daysLimit}}')
      .replace(/\[Event.Approved]/gi, '{{isApproved}}')
      .replace(/\[Event.ExcludeParticipant]/gi, '{{excludeParticipants}}');
  }

  if (type === TemplateType.CREATE_ACCOUNT || type === TemplateType.UPDATE_PASSWORD) {
    return text
      .replace(/\[Member Full Name]/gi, '{{employeeName}}')
      .replace(/\[Member Login]/gi, '{{login}}')
      .replace(/\[Member Password]/gi, '{{password}}')
      .replace(/\[Member Primary E-mail]/gi, '{{primaryEmail}}')
      .replace(/\[Link to Member Personal Profile]/gi, '{{accountLink}}');
  }

  if (type === TemplateType.QUESTIONNAIRE_IN_PROGRESS) {
    return text
      .replace(/\[Poll Name]/gi, '{{pollName}}')
      .replace(/\[Poll Description]/gi, '{{pollDescription}}')
      .replace(/\[Poll Due Date]/gi, '{{pollDate}}')
      .replace(/\[Poll Orinizers]/gi, '{{pollOrganizes}}')
      .replace(/\[Personal Poll Link]/gi, '{{pollLink}}');
  }

  if (type === TemplateType.QUESTIONNAIRE_ANSWERED) {
    return text
      .replace(/\[Orginizer Full Name]/gi, '{{organizerName}}')
      .replace(/\[Poll Name]/gi, '{{pollName}}')
      .replace(/\[Poll Description]/gi, '{{pollDescription}}')
      .replace(/\[Poll Due Date]/gi, '{{pollDate}}')
      .replace(/\[Responder Full Name]/gi, '{{employeeName}}')
      .replace(/\[Response Date and Time]/gi, '{{responseDateTime}}')
      .replace(/\[Answered Responders]/gi, '{{answeredResponders}}')
      .replace(/\[All Responders]/gi, '{{allResponders}}')
      .replace(/\[Poll Link]/gi, '{{pollLink}}');
  }
};
