import { defineMessages } from 'react-intl';
import {
  officeLabel,
  addressLabel,
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  endDateLabel,
  startDateLabel,
  targetMemberLabel,
  addButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteModalWithItemNameTitle,
  cancelButton,
  deleteButton,
  saveButton,
  officeLabel,
  addressLabel,
  endDateLabel,
  startDateLabel,
  addButton,
  targetMemberLabel,
  editEventTitle: {
    id: 'schedule.event.types.modal.edit.event.types.title.edit.event.type',
    defaultMessage: 'Edit Event Type',
  },
  excludedParticipantsLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.exclude.members',
    defaultMessage: 'Exclude Participants',
  },
  newEventTitle: {
    id: 'schedule.event.types.modal.new.event.types.title.new.event.type',
    defaultMessage: 'New Event Type',
  },
  typeNamelabel: {
    id: 'schedule.event.types.modal.new.event.types.label.type.name',
    defaultMessage: 'Type Name',
  },
  titleLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.title',
    defaultMessage: 'Title',
  },
  datesRange: {
    id: 'schedule.event.types.modal.new.event.types.label.dates.range',
    defaultMessage: 'Dates Range',
  },
  participants: {
    id: 'schedule.event.types.modal.new.event.types.label.participants',
    defaultMessage: 'Participants',
  },
  time: {
    id: 'schedule.event.types.modal.new.event.types.label.time',
    defaultMessage: 'Time',
  },
  questionnaires: {
    id: 'schedule.event.types.modal.new.event.types.label.questionnaires',
    defaultMessage: 'Questionnaires',
  },
  questionnaireLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.questionnaire',
    defaultMessage: 'Questionnaire',
  },
  questionnairesUpper: {
    id: 'schedule.event.types.modal.new.event.types.label.questionnaires.upper',
    defaultMessage: 'QUESTIONNAIRES',
  },
  repeater: {
    id: 'schedule.event.types.modal.new.event.types.label.repeater',
    defaultMessage: 'Repeater',
  },
  reminder: {
    id: 'schedule.event.types.modal.new.event.types.label.reminder',
    defaultMessage: 'Reminder',
  },
  assessments: {
    id: 'schedule.event.types.modal.new.event.types.label.assessments',
    defaultMessage: 'Assessments',
  },
  absence: {
    id: 'schedule.event.types.modal.new.event.types.label.absence',
    defaultMessage: 'Absence',
  },
  finalDecisionOptionLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.final.decision.option',
    defaultMessage: 'Final Decision Option',
  },
  finalDecisionLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.final.decision',
    defaultMessage: 'Final Decision',
  },
  optionForExcludeDaysOfAbsenceFromOtherTypesLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.options.to.exclude.days.of.absence.from.other.event.types',
    defaultMessage: 'Options to Exclude Days of Absence from other Event Types',
  },
  requiresWorkingOffLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.requires.working.off',
    defaultMessage: 'Requires Working Off',
  },
  requiresApprovalEnabledLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.requires.approval',
    defaultMessage: 'Requires Approval',
  },
  showTotalCounterInProfileLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.show.total.counter.in.profile',
    defaultMessage: 'Show total Counter in Profile',
  },
  whoCanApproveThisTypeOfEventsLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.who.can.approve.this.type.of.events',
    defaultMessage: 'Who can approve this Type of Events',
  },
  limitDaysOfEvenForEachMemberPerOffice: {
    id: 'schedule.event.types.modal.new.event.types.label.limit.days.of.event.for.each.member.per.office',
    defaultMessage: 'Limit Days of Event for each Member per Office',
  },
  extraWorkingDays: {
    id: 'schedule.event.types.modal.new.event.types.label.extra.working.days',
    defaultMessage: 'Extra Working Days',
  },
  daysLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.days',
    defaultMessage: 'Days',
  },
  whoCanCreateThisTypeOfEvents: {
    id: 'schedule.event.types.modal.new.event.types.label.who.can.create.this.type.of.events',
    defaultMessage: 'Who can create this Type of Events',
  },
  newEventMeeting: {
    id: 'schedule.event.types.modal.new.event.types.label.new.event.meeting',
    defaultMessage: 'New Event (Meeting)',
  },
  startTimeInput: {
    id: 'schedule.event.types.modal.new.event.types.label.start.time',
    defaultMessage: 'Start Time',
  },
  endTimeInput: {
    id: 'schedule.event.types.modal.new.event.types.label.end.time',
    defaultMessage: 'End Time',
  },
  history: {
    id: 'schedule.event.types.modal.new.event.types.label.history',
    defaultMessage: 'History',
  },
  respondentsLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.respondents',
    defaultMessage: 'Respondents',
  },
  description: {
    id: 'schedule.event.types.modal.new.event.types.label.description',
    defaultMessage: 'Description',
  },
  approve: {
    id: 'schedule.event.types.modal.new.event.types.label.approve',
    defaultMessage: 'Approve',
  },
  applyToFutureEventsOnlyOption: {
    id: 'schedule.event.types.modal.new.event.types.label.apply.to.future.events.only',
    defaultMessage: 'Apply to future events only',
  },
  applyToAllEventsOption: {
    id: 'schedule.event.types.modal.new.event.types.label.apply.to.all.eventsy',
    defaultMessage: 'Apply to all events',
  },
  applyToSelect: {
    id: 'schedule.event.types.modal.new.event.types.label.apply.to',
    defaultMessage: 'Apply to',
  },
  allDayOption: {
    id: 'schedule.event.types.modal.new.event.types.label.all.day',
    defaultMessage: 'All day',
  },
  previewLabel: {
    id: 'schedule.event.types.modal.preview.day',
    defaultMessage: 'Preview',
  },
  recurrenceLabel: {
    id: 'schedule.event.types.modal.preview.label.recurrence',
    defaultMessage: 'Recurrence',
  },
  excludeDaysOfAbsenceFromOtherTypesLabel: {
    id: 'schedule.event.types.modal.preview.label.exclude.days.of.absence.from.other.types.label',
    defaultMessage: 'Exclude days of absence from other types',
  },
  calculateAvailableDaysBasedOnDaysWorkedLabel: {
    id: 'schedule.event.types.modal.new.event.types.label.calculate.available.days.based.on.days.worked',
    defaultMessage: 'Calculate available days based on days worked',
  },
  publicEventsLabel: {
    id: 'schedule.event.types.modal.event.types.label.public.events',
    defaultMessage: 'Public Events',
  },
});
