import { all } from 'redux-saga/effects';
import authSagas from './auth.sagas';
import brandingSagas from './branding.sagas';
import usersSagas from './users.sagas';
import librariesSagas from './libraries.sagas';
import recognitionSaga from './recognitions.sagas';
import competenciesSagas from './competencies.sagas';
import questionnairesSaga from './questionnaires.saga';
import calendarSagas from './calendar.sagas';
import planningSaga from './planning.saga';
import scheduleSagas from './schedule.sagas';
import resumesSaga from './resumes.saga';
import notificationsSaga from './notifications.saga';
import bonusesSaga from './bonuses.sagas';
import policiesSagas from './policies.sagas';
import reportsSaga from './reports.sagas';
import financeSaga from './finance.sagas';
import filtersSaga from './filters.sagas';
import activitySaga from './activity.sagas';
import candidatesSaga from './candidates.sagas';
import exportSaga from './export.sagas';

export default function* mainSaga() {
  yield all([
    authSagas(),
    brandingSagas(),
    usersSagas(),
    librariesSagas(),
    recognitionSaga(),
    competenciesSagas(),
    questionnairesSaga(),
    calendarSagas(),
    planningSaga(),
    scheduleSagas(),
    resumesSaga(),
    notificationsSaga(),
    bonusesSaga(),
    policiesSagas(),
    reportsSaga(),
    financeSaga(),
    filtersSaga(),
    activitySaga(),
    candidatesSaga(),
    exportSaga(),
  ]);
}
