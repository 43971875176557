import React, { ReactElement } from 'react';
import Icon from '../Icon';

type CopyToClickBoardProps = {
  text: string;
  children: ReactElement;
};

function CopyToClickBoard({ text, children }: CopyToClickBoardProps) {
  const handleCopy = (e: any) => {
    e.preventDefault();
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  return (
    <>
      <div className="copy-to-clickboard-wrapper">
        <button onClick={handleCopy} className="copy-icon">
          <Icon iconName="copy-to-clipboard" />
        </button>
        {children}
      </div>
    </>
  );
}

export default CopyToClickBoard;
