import { omit } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { OptionTypeBase } from 'react-select';
import { DATE_FORMAT } from '../constants/date.constants';
import { ReportUsersStatuses } from '../constants/reports.constants';
import { ReportsUserStatusesType } from '../types/reports';
import { EFileName } from '../constants/export.constants';

export const getForamtedTime = (time: number, isRounded?: boolean) => {
  return isRounded ? Math.ceil(time / 3600) : parseFloat((time / 3600).toFixed(2));
};

export const getProjectsReportData = (
  tableData: {
    jiraProjects: any[];
    totalTimeSpent: number | null;
  },
  isGroupByComponents: boolean,
  isGroupByEmployee: boolean,
) => {
  if (!tableData) return [];

  const transformData = (el: any) => {
    if (isGroupByComponents || isGroupByEmployee) {
      el = omit(el, ['issues', 'items']);
    }
    if (isGroupByEmployee && !isGroupByComponents) {
      el.users = el?.components[0]?.users;
    }
    return el;
  };
  const newTable = [
    ...tableData.jiraProjects.map((el: any) => transformData(el.projectDto)),
    ...(!isEmpty(tableData.jiraProjects)
      ? [
          {
            id: '',
            name: 'Total',
            timeSpent: tableData.totalTimeSpent,
          },
        ]
      : []),
  ];

  return newTable;
};

export const handleReportUsersStatuses = (
  setReportParams: (data: ReportsUserStatusesType) => void,
  data: OptionTypeBase | OptionTypeBase[],
  isAllowAlien = false,
): void => {
  const reportsUsersStatuses = isEmpty(data)
    ? {
        isAllowActive: null,
        isAllowPassive: null,
        isAllowAlien: null,
      }
    : {
        isAllowActive: data.some(({ value }: OptionTypeBase) => value === ReportUsersStatuses.ACTIVE),
        isAllowPassive: data.some(({ value }: OptionTypeBase) => value === ReportUsersStatuses.INACITVE),
        isAllowAlien: data.some(({ value }: OptionTypeBase) => value === ReportUsersStatuses.EXTERNAL),
      };
  if (!isAllowAlien) {
    delete reportsUsersStatuses.isAllowAlien;
  }
  setReportParams(reportsUsersStatuses);
};

export const getExportFileNameWithDate = (fileName: EFileName | string, dateFrom: string, dateTo: string) => {
  const startRange = moment(dateFrom.split(' ')[0]).format(DATE_FORMAT.YYYY_MM_DD_DOT);
  const endRange = moment(dateTo.split(' ')[0]).format(DATE_FORMAT.YYYY_MM_DD_DOT);
  return `${fileName} (${startRange} - ${endRange})`;
};
