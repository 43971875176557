import { defineMessages } from 'react-intl';
import { doneButton } from '../../i18n/defaultMessage';

export default defineMessages({
  doneButton,
  myProfileTitle: {
    id: 'header.navigation.duration.picker.btn.my.profile',
    defaultMessage: 'My Profile',
  },
  signOutTitle: {
    id: 'header.navigation.duration.picker.btn.sign.out',
    defaultMessage: 'Sign Out',
  },
  reorderLinksTitle: {
    id: 'header.custom.navigation.modal.reorder.links.title.reorder.link',
    defaultMessage: 'Reorder links',
  },
  reorderBtnLabel: {
    id: 'header.custom.navigation.picker.btn.reorder',
    defaultMessage: 'Reorder',
  },
});
