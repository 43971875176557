import { BrandingInfo } from './../enums/branding.enum';
import { MenuLinksParams, DashboardLinksParams } from './../enums/params/branding.params';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/branding.constants';
import { getReducerErrors } from '../utils/reducerError.utils';
import { ResourceLink } from '../enums/branding.enum';
import { getBrandingData } from '../utils/branding.utils';

const brandingDefault = getBrandingData();

const initialState = {
  brandingData: new BrandingInfo(brandingDefault),
  menuLinksTableData: [],
  dashboardLinks: [],
  errors: {
    brandingError: null,
    menuLinksListError: null,
    dashboardLinksError: null,
    menuLinkError: null,
  },
  loading: {
    getMenuLinksList: false,
    getDashboardLinks: false,
    createMenuLink: false,
    editMenuLinkByAdmin: false,
    editLinksOrder: false,
    deleteMenuLink: false,
  },
  menuLinksParams: new MenuLinksParams(),
  dashboardLinksParams: new DashboardLinksParams(),
};

const brandingReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_PORTAL_BRANDING_SUCCESS:
      return {
        ...state,
        brandingData: new BrandingInfo(action.payload),
        errors: { ...state.errors, brandingError: null },
      };

    case ActionTypes.GET_PORTAL_BRANDING_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, brandingError: action.payload.message },
      };

    case ActionTypes.POST_EDIT_PORTAL_BRANDING_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, brandingError: null },
      };

    case ActionTypes.POST_EDIT_PORTAL_BRANDING_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, brandingError: getReducerErrors(action.payload) },
      };

    case ActionTypes.GET_MENU_LINKS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getMenuLinksList: true },
        errors: { ...state.errors, menuLinksListError: null },
      };

    case ActionTypes.GET_MENU_LINKS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getMenuLinksList: false },
        menuLinksTableData: action.payload.content.map((el: any) => new ResourceLink(el)),
        errors: { ...state.errors, menuLinksListError: null },
      };

    case ActionTypes.GET_MENU_LINKS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getMenuLinksList: false },
        errors: { ...state.errors, menuLinksListError: action.payload.message },
      };

    case ActionTypes.GET_DASHBOARD_LINKS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDashboardLinks: true },
        errors: { ...state.errors, dashboardLinksError: null },
      };

    case ActionTypes.GET_DASHBOARD_LINKS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboardLinks: false },
        dashboardLinks: action.payload.content.map((el: any) => new ResourceLink(el)),
        errors: { ...state.errors, dashboardLinksError: null },
      };

    case ActionTypes.GET_DASHBOARD_LINKS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getDashboardLinks: false },
        errors: { ...state.errors, dashboardLinksError: action.payload.message },
      };

    case ActionTypes.POST_MENU_LINK_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createMenuLink: true },
      };

    case ActionTypes.POST_MENU_LINK_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createMenuLink: false },
        errors: { ...state.errors, menuLinkError: null },
      };

    case ActionTypes.POST_MENU_LINK_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createMenuLink: false },
        errors: {
          ...state.errors,
          menuLinkError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_MENU_LINK_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editMenuLinkByAdmin: true },
      };

    case ActionTypes.PUT_MENU_LINK_BY_ADMIN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editMenuLinkByAdmin: false },
        errors: { ...state.errors, menuLinkError: null },
      };

    case ActionTypes.PUT_MENU_LINK_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editMenuLinkByAdmin: false },
        errors: {
          ...state.errors,
          menuLinkError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_LINKS_ORDER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editLinksOrder: true },
      };

    case ActionTypes.PUT_LINKS_ORDER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editLinksOrder: false },
        errors: { ...state.errors, dashboardLinksError: null },
      };

    case ActionTypes.PUT_LINKS_ORDER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editLinksOrder: false },
        errors: {
          ...state.errors,
          dashboardLinksError: action.payload.message,
        },
      };

    case ActionTypes.DELETE_MENU_LINK_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteMenuLink: true },
      };

    case ActionTypes.DELETE_MENU_LINK_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteMenuLink: false },
        errors: { ...state.errors, menuLinkError: null },
      };

    case ActionTypes.DELETE_MENU_LINK_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteMenuLink: false },
        errors: {
          ...state.errors,
          menuLinkError: action.payload.message,
        },
      };

    case ActionTypes.RESET_BRANDING_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_BRANDING_STATE:
      return {
        ...initialState,
        brandingData: state.brandingData,
      };

    default:
      return state;
  }
};

export default brandingReducer;
