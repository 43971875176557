import classNames from 'classnames';
import { isNil, values } from 'lodash-es';
import React from 'react';

type PreviewTableProps = {
  parsedCsvData: Papa.ParseStepResult<any> | null;
};

function PreviewTable({ parsedCsvData }: PreviewTableProps) {
  const wrapperStyle = classNames('transactions__preview-table-wrapper', { empty: !parsedCsvData });

  return (
    <div className={wrapperStyle}>
      {!isNil(parsedCsvData) ? (
        <table className="transactions__table preview-table">
          <tr className="row-header">
            {parsedCsvData?.meta?.fields?.map((field, index) => (
              <th key={`field-name-${index}`}>
                <div className="row-header-content">{field}</div>
              </th>
            ))}
          </tr>
          {parsedCsvData?.data.map((row: any, rowIndex: number) => (
            <tr key={`row-${rowIndex}`}>
              {values(row).map((column: any, columnIndex: number) => {
                return <td key={`column-${columnIndex}`}>{column}</td>;
              })}
            </tr>
          ))}
        </table>
      ) : null}
    </div>
  );
}

export default React.memo(PreviewTable);
