import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { UserAbsencePeriods, UserInfo } from '../../../enums/users.enum';
import { useAbsecePeriods } from '../../../utils/hooks.utils';
import Dropdown from '../../Dropdown';
import Icon from '../../Icon';
import Modal from '../../Modal';
import AbsenceContent from './AbsenceContent';
import CustomLoader from '../../Loader';
import { useFloating, offset, shift, limitShift } from '@floating-ui/react-dom';

type AbsenceBlockProps = {
  userAbsencePeriods: UserAbsencePeriods[];
  userHrInfo: {
    dismissal: string;
    endTrialPeriod: string;
    hired: string;
    hrCuratorId: string;
    hrCurator: UserInfo;
  }[];
  absencePeriodsLoading: boolean;
  userId: string;
  anchor?: any;
  isUserProfile?: boolean;
  modalAbsenceIsOpen: boolean;
  onRequestClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  getUserAbsencePeriods: (data: any) => void;
  setSearchYearCustom?: (date: any) => void;
};

function AbsenceBlock({
  userId,
  userAbsencePeriods,
  userHrInfo,
  absencePeriodsLoading,
  anchor,
  isUserProfile = false,
  modalAbsenceIsOpen,
  onRequestClose,
  getUserAbsencePeriods,
  setSearchYearCustom,
}: AbsenceBlockProps) {
  const dropdownRef = useRef<any>(null);
  const { serachYear, tableActions } = useAbsecePeriods(
    userId,
    getUserAbsencePeriods,
    userHrInfo,
    null,
    setSearchYearCustom,
  );

  const { refs, floatingStyles, update } = useFloating({
    elements: {
      reference: anchor,
    },

    middleware: [
      offset(({ rects }) => {
        return isUserProfile ? -rects.reference.height / 2 - rects.floating.height / 2 : 5;
      }),
      shift({
        boundary: document.body,
        limiter: limitShift({
          offset: 5,
        }),
      }),
    ],
    open: modalAbsenceIsOpen,
    placement: isUserProfile ? undefined : 'right',
  });

  useEffect(() => {
    modalAbsenceIsOpen && update();
  }, [modalAbsenceIsOpen, absencePeriodsLoading]);

  useEffect(() => {
    document.body.style.overflowX = 'hidden';

    if (document.body.clientHeight <= window.innerHeight) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
      document.body.style.overflowX = 'auto';
    };
  }, []);

  return (
    <Modal
      isOpen={modalAbsenceIsOpen}
      dropdownRef={dropdownRef}
      modalRef={refs.setFloating}
      modalStyle={floatingStyles}
      changeDocumentOverflowStyle={false}
      title={
        <Dropdown
          dropdownClass="dropdown--no-bg"
          listRef={dropdownRef}
          dropdownToggle={
            <>
              <span className="current-year">{moment(serachYear).format(DATE_FORMAT.YYYY)}</span>
              <Icon iconName="select-arrow" externalClass="dropdown__button-main-icon" />
            </>
          }
          dropdownList={tableActions}
          dropdownInfo={null}
        />
      }
      onRequestClose={onRequestClose}
      classNameModal="absence-user-block"
      shouldCloseOnOverlayClick
    >
      {!absencePeriodsLoading ? (
        userAbsencePeriods.map((el, i) => <AbsenceContent key={`${el.absenceReason}-${i}`} absencePeriod={el} />)
      ) : (
        <CustomLoader />
      )}
    </Modal>
  );
}

export default React.memo(AbsenceBlock);
