export const GET_OFFICES_LIST_REQUEST = 'GET_OFFICES_LIST_REQUEST';
export const GET_OFFICES_LIST_SUCCESS = 'GET_OFFICES_LIST_SUCCESS';
export const GET_OFFICES_LIST_FAILURE = 'GET_OFFICES_LIST_FAILURE';

export const POST_OFFICE_REQUEST = 'POST_OFFICE_REQUEST';
export const POST_OFFICE_SUCCESS = 'POST_OFFICE_SUCCESS';
export const POST_OFFICE_FAILURE = 'POST_OFFICE_FAILURE';

export const DELETE_OFFICE_REQUEST = 'DELETE_OFFICE_REQUEST';
export const DELETE_OFFICE_SUCCESS = 'DELETE_OFFICE_SUCCESS';
export const DELETE_OFFICE_FAILURE = 'DELETE_OFFICE_FAILURE';

export const PUT_OFFICE_REQUEST = 'PUT_OFFICE_REQUEST';
export const PUT_OFFICE_SUCCESS = 'PUT_OFFICE_SUCCESS';
export const PUT_OFFICE_FAILURE = 'PUT_OFFICE_FAILURE';

export const GET_OFFICES_WORK_DAYS_REQUEST = 'GET_OFFICES_WORK_DAYS_REQUEST';
export const GET_OFFICES_WORK_DAYS_SUCCESS = 'GET_OFFICES_WORK_DAYS_SUCCESS';
export const GET_OFFICES_WORK_DAYS_FAILURE = 'GET_OFFICES_WORK_DAYS_FAILURE';

export const SET_OFFICES_PARAMS = 'SET_OFFICES_PARAMS';

export const GET_GRADES_LIST_REQUEST = 'GET_GRADES_LIST_REQUEST';
export const GET_GRADES_LIST_SUCCESS = 'GET_GRADES_LIST_SUCCESS';
export const GET_GRADES_LIST_FAILURE = 'GET_GRADES_LIST_FAILURE';

export const POST_GRADE_REQUEST = 'POST_GRADE_REQUEST';
export const POST_GRADE_SUCCESS = 'POST_GRADE_SUCCESS';
export const POST_GRADE_FAILURE = 'POST_GRADE_FAILURE';

export const DELETE_GRADE_REQUEST = 'DELETE_GRADE_REQUEST';
export const DELETE_GRADE_SUCCESS = 'DELETE_GRADE_SUCCESS';
export const DELETE_GRADE_FAILURE = 'DELETE_GRADE_FAILURE';

export const PUT_GRADE_REQUEST = 'PUT_GRADE_REQUEST';
export const PUT_GRADE_SUCCESS = 'PUT_GRADE_SUCCESS';
export const PUT_GRADE_FAILURE = 'PUT_GRADE_FAILURE';

export const GET_SPECIALIZATIONS_LIST_REQUEST = 'GET_SPECIALIZATIONS_LIST_REQUEST';
export const GET_SPECIALIZATIONS_LIST_SUCCESS = 'GET_SPECIALIZATIONS_LIST_SUCCESS';
export const GET_SPECIALIZATIONS_LIST_FAILURE = 'GET_SPECIALIZATIONS_LIST_FAILURE';

export const GET_SPECIALIZATIONS_COMPETENCIES_REQUEST = 'GET_SPECIALIZATIONS_COMPETENCIES_LIST_REQUEST';
export const GET_SPECIALIZATIONS_COMPETENCIES_SUCCESS = 'GET_SPECIALIZATIONS_COMPETENCIES_SUCCESS';
export const GET_SPECIALIZATIONS_COMPETENCIES_FAILURE = 'GET_SPECIALIZATIONS_COMPETENCIES_FAILURE';

export const POST_SPECIALIZATION_REQUEST = 'POST_SPECIALIZATION_REQUEST';
export const POST_SPECIALIZATION_SUCCESS = 'POST_SPECIALIZATION_SUCCESS';
export const POST_SPECIALIZATION_FAILURE = 'POST_SPECIALIZATION_FAILURE';

export const DELETE_SPECIALIZATION_REQUEST = 'DELETE_SPECIALIZATION_REQUEST';
export const DELETE_SPECIALIZATION_SUCCESS = 'DELETE_SPECIALIZATION_SUCCESS';
export const DELETE_SPECIALIZATION_FAILURE = 'DELETE_SPECIALIZATION_FAILURE';

export const PUT_SPECIALIZATION_REQUEST = 'PUT_SPECIALIZATION_REQUEST';
export const PUT_SPECIALIZATION_SUCCESS = 'PUT_SPECIALIZATION_SUCCESS';
export const PUT_SPECIALIZATION_FAILURE = 'PUT_SPECIALIZATION_FAILURE';

export const PUT_SPECIALIZATION_COMPETENCIES_REQUEST = 'PUT_SPECIALIZATION_COMPETENCIES_REQUEST';
export const PUT_SPECIALIZATION_COMPETENCIES_SUCCESS = 'PUT_SPECIALIZATION_COMPETENCIES_SUCCESS';
export const PUT_SPECIALIZATION_COMPETENCIES_FAILURE = 'PUT_SPECIALIZATION_COMPETENCIES_FAILURE';

export const SET_SPECIALIZATIONS_PARAMS = 'SET_SPECIALIZATIONS_PARAMS';

export const GET_COMPANY_POSITIONS_LIST_REQUEST = 'GET_COMPANY_POSITIONS_LIST_REQUEST';
export const GET_COMPANY_POSITIONS_LIST_SUCCESS = 'GET_COMPANY_POSITIONS_LIST_SUCCESS';
export const GET_COMPANY_POSITIONS_LIST_FAILURE = 'GET_COMPANY_POSITIONS_LIST_FAILURE';

export const POST_COMPANY_POSITION_REQUEST = 'POST_COMPANY_POSITION_REQUEST';
export const POST_COMPANY_POSITION_SUCCESS = 'POST_COMPANY_POSITION_SUCCESS';
export const POST_COMPANY_POSITION_FAILURE = 'POST_COMPANY_POSITION_FAILURE';

export const DELETE_COMPANY_POSITION_REQUEST = 'DELETE_COMPANY_POSITION_REQUEST';
export const DELETE_COMPANY_POSITION_SUCCESS = 'DELETE_COMPANY_POSITION_SUCCESS';
export const DELETE_COMPANY_POSITION_FAILURE = 'DELETE_COMPANY_POSITION_FAILURE';

export const PUT_COMPANY_POSITION_REQUEST = 'PUT_COMPANY_POSITION_REQUEST';
export const PUT_COMPANY_POSITION_SUCCESS = 'PUT_COMPANY_POSITION_SUCCESS';
export const PUT_COMPANY_POSITION_FAILURE = 'PUT_COMPANY_POSITION_FAILURE';

export const GET_DEPARTMENTS_LIST_REQUEST = 'GET_DEPARTMENTS_LIST_REQUEST';
export const GET_DEPARTMENTS_LIST_SUCCESS = 'GET_DEPARTMENTS_LIST_SUCCESS';
export const GET_DEPARTMENTS_LIST_FAILURE = 'GET_DEPARTMENTS_LIST_FAILURE';

export const POST_DEPARTMENT_REQUEST = 'POST_DEPARTMENT_REQUEST';
export const POST_DEPARTMENT_SUCCESS = 'POST_DEPARTMENT_SUCCESS';
export const POST_DEPARTMENT_FAILURE = 'POST_DEPARTMENT_FAILURE';

export const DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAILURE = 'DELETE_DEPARTMENT_FAILURE';

export const PUT_DEPARTMENT_REQUEST = 'PUT_DEPARTMENT_REQUEST';
export const PUT_DEPARTMENT_SUCCESS = 'PUT_DEPARTMENT_SUCCESS';
export const PUT_DEPARTMENT_FAILURE = 'PUT_DEPARTMENT_FAILURE';

export const SET_DEPARTMENTS_PARAMS = 'SET_DEPARTMENTS_PARAMS';

export const GET_PROJECTS_LIST_REQUEST = 'GET_PROJECTS_LIST_REQUEST';
export const GET_PROJECTS_LIST_SUCCESS = 'GET_PROJECTS_LIST_SUCCESS';
export const GET_PROJECTS_LIST_FAILURE = 'GET_PROJECTS_LIST_FAILURE';

export const POST_PROJECT_REQUEST = 'POST_PROJECT_REQUEST';
export const POST_PROJECT_SUCCESS = 'POST_PROJECT_SUCCESS';
export const POST_PROJECT_FAILURE = 'POST_PROJECT_FAILURE';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const PUT_PROJECT_REQUEST = 'PUT_PROJECT_REQUEST';
export const PUT_PROJECT_SUCCESS = 'PUT_PROJECT_SUCCESS';
export const PUT_PROJECT_FAILURE = 'PUT_PROJECT_FAILURE';

export const SET_PROJECTS_PARAMS = 'SET_PROJECTS_PARAMS';

export const GET_TECHNICAL_SKILLS_LIST_REQUEST = 'GET_TECHNICAL_SKILLS_LIST_REQUEST';
export const GET_TECHNICAL_SKILLS_LIST_SUCCESS = 'GET_TECHNICAL_SKILLS_LIST_SUCCESS';
export const GET_TECHNICAL_SKILLS_LIST_FAILURE = 'GET_TECHNICAL_SKILLS_LIST_FAILURE';

export const SET_TECHNICAL_SKILLS_PARAMS = 'SET_TECHNICAL_SKILLS_PARAMS';

export const GET_SKILL_GROUP_REQUEST = 'GET_SKILL_GROUP_REQUEST';
export const GET_SKILL_GROUP_SUCCESS = 'GET_SKILL_GROUP_SUCCESS';
export const GET_SKILL_GROUP_FAILURE = 'GET_SKILL_GROUP_FAILURE';

export const POST_TECHNICAL_SKILL_REQUEST = 'POST_TECHNICAL_SKILL_REQUEST';
export const POST_TECHNICAL_SKILL_SUCCESS = 'POST_TECHNICAL_SKILL_SUCCESS';
export const POST_TECHNICAL_SKILL_FAILURE = 'POST_TECHNICAL_SKILL_FAILURE';

export const DELETE_TECHNICAL_SKILL_REQUEST = 'DELETE_TECHNICAL_SKILL_REQUEST';
export const DELETE_TECHNICAL_SKILL_SUCCESS = 'DELETE_TECHNICAL_SKILL_SUCCESS';
export const DELETE_TECHNICAL_SKILL_FAILURE = 'DELETE_TECHNICAL_SKILL_FAILURE';

export const PUT_TECHNICAL_SKILL_REQUEST = 'PUT_TECHNICAL_SKILL_REQUEST';
export const PUT_TECHNICAL_SKILL_SUCCESS = 'PUT_TECHNICAL_SKILL_SUCCESS';
export const PUT_TECHNICAL_SKILL_FAILURE = 'PUT_TECHNICAL_SKILL_FAILURE';

export const GET_SKILL_LEVELS_LIST_REQUEST = 'GET_SKILL_LEVELS_LIST_REQUEST';
export const GET_SKILL_LEVELS_LIST_SUCCESS = 'GET_SKILL_LEVELS_LIST_SUCCESS';
export const GET_SKILL_LEVELS_LIST_FAILURE = 'GET_SKILL_LEVELS_LIST_FAILURE';

export const GET_SKILL_LEVEL_REQUEST = 'GET_SKILL_LEVEL_REQUEST';
export const GET_SKILL_LEVEL_SUCCESS = 'GET_SKILL_LEVEL_SUCCESS';
export const GET_SKILL_LEVEL_FAILURE = 'GET_SKILL_LEVEL_FAILURE';

export const POST_SKILL_LEVEL_REQUEST = 'POST_SKILL_LEVEL_REQUEST';
export const POST_SKILL_LEVEL_SUCCESS = 'POST_SKILL_LEVEL_SUCCESS';
export const POST_SKILL_LEVEL_FAILURE = 'POST_SKILL_LEVEL_FAILURE';

export const DELETE_SKILL_LEVEL_REQUEST = 'DELETE_SKILL_LEVEL_REQUEST';
export const DELETE_SKILL_LEVEL_SUCCESS = 'DELETE_SKILL_LEVEL_SUCCESS';
export const DELETE_SKILL_LEVEL_FAILURE = 'DELETE_SKILL_LEVEL_FAILURE';

export const PUT_SKILL_LEVEL_REQUEST = 'PUT_SKILL_LEVEL_REQUEST';
export const PUT_SKILL_LEVEL_SUCCESS = 'PUT_SKILL_LEVEL_SUCCESS';
export const PUT_SKILL_LEVEL_FAILURE = 'PUT_SKILL_LEVEL_FAILURE';

export const GET_LANGUAGE_LEVELS_LIST_REQUEST = 'GET_LANGUAGE_LEVELS_LIST_REQUEST';
export const GET_LANGUAGE_LEVELS_LIST_SUCCESS = 'GET_LANGUAGE_LEVELS_LIST_SUCCESS';
export const GET_LANGUAGE_LEVELS_LIST_FAILURE = 'GET_LANGUAGE_LEVELS_LIST_FAILURE';

export const GET_LANGUAGE_LEVEL_REQUEST = 'GET_LANGUAGE_LEVEL_REQUEST';
export const GET_LANGUAGE_LEVEL_SUCCESS = 'GET_LANGUAGE_LEVEL_SUCCESS';
export const GET_LANGUAGE_LEVEL_FAILURE = 'GET_LANGUAGE_LEVEL_FAILURE';

export const POST_LANGUAGE_LEVEL_REQUEST = 'POST_LANGUAGE_LEVEL_REQUEST';
export const POST_LANGUAGE_LEVEL_SUCCESS = 'POST_LANGUAGE_LEVEL_SUCCESS';
export const POST_LANGUAGE_LEVEL_FAILURE = 'POST_LANGUAGE_LEVEL_FAILURE';

export const DELETE_LANGUAGE_LEVEL_REQUEST = 'DELETE_LANGUAGE_LEVEL_REQUEST';
export const DELETE_LANGUAGE_LEVEL_SUCCESS = 'DELETE_LANGUAGE_LEVEL_SUCCESS';
export const DELETE_LANGUAGE_LEVEL_FAILURE = 'DELETE_LANGUAGE_LEVEL_FAILURE';

export const PUT_LANGUAGE_LEVEL_REQUEST = 'PUT_LANGUAGE_LEVEL_REQUEST';
export const PUT_LANGUAGE_LEVEL_SUCCESS = 'PUT_LANGUAGE_LEVEL_SUCCESS';
export const PUT_LANGUAGE_LEVEL_FAILURE = 'PUT_LANGUAGE_LEVEL_FAILURE';

export const GET_FOREIGN_LANGUAGES_LIST_REQUEST = 'GET_FOREIGN_LANGUAGES_LIST_REQUEST';
export const GET_FOREIGN_LANGUAGES_LIST_SUCCESS = 'GET_FOREIGN_LANGUAGES_LIST_SUCCESS';
export const GET_FOREIGN_LANGUAGES_LIST_FAILURE = 'GET_FOREIGN_LANGUAGES_LIST_FAILURE';

export const GET_FOREIGN_LANGUAGE_REQUEST = 'GET_FOREIGN_LANGUAGE_REQUEST';
export const GET_FOREIGN_LANGUAGE_SUCCESS = 'GET_FOREIGN_LANGUAGE_SUCCESS';
export const GET_FOREIGN_LANGUAGE_FAILURE = 'GET_FOREIGN_LANGUAGE_FAILURE';

export const POST_FOREIGN_LANGUAGE_REQUEST = 'POST_FOREIGN_LANGUAGE_REQUEST';
export const POST_FOREIGN_LANGUAGE_SUCCESS = 'POST_FOREIGN_LANGUAGE_SUCCESS';
export const POST_FOREIGN_LANGUAGE_FAILURE = 'POST_FOREIGN_LANGUAGE_FAILURE';

export const DELETE_FOREIGN_LANGUAGE_REQUEST = 'DELETE_FOREIGN_LANGUAGE_REQUEST';
export const DELETE_FOREIGN_LANGUAGE_SUCCESS = 'DELETE_FOREIGN_LANGUAGE_SUCCESS';
export const DELETE_FOREIGN_LANGUAGE_FAILURE = 'DELETE_FOREIGN_LANGUAGE_FAILURE';

export const PUT_FOREIGN_LANGUAGE_REQUEST = 'PUT_FOREIGN_LANGUAGE_REQUEST';
export const PUT_FOREIGN_LANGUAGE_SUCCESS = 'PUT_FOREIGN_LANGUAGE_SUCCESS';
export const PUT_FOREIGN_LANGUAGE_FAILURE = 'PUT_FOREIGN_LANGUAGE_FAILURE';

export const SET_FOREIGN_LANGUAGE_PARAMS = 'SET_FOREIGN_LANGUAGE_PARAMS';

export const DAYS_OF_WEEK = [
  { fullName: 'MONDAY', shortName: 'Mon' },
  { fullName: 'TUESDAY', shortName: 'Tue' },
  { fullName: 'WEDNESDAY', shortName: 'Wed' },
  { fullName: 'THURSDAY', shortName: 'Thu' },
  { fullName: 'FRIDAY', shortName: 'Fri' },
  { fullName: 'SATURDAY', shortName: 'Sat' },
  { fullName: 'SUNDAY', shortName: 'Sun' },
];

export const RESET_LIBRARIES_ERRORS = 'RESET_LIBRARIES_ERRORS';
export const RESET_LIBRARIES_STATE = 'RESET_LIBRARIES_STATE';
