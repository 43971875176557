import { defineMessages } from 'react-intl';

export default defineMessages({
  yearLabel: {
    id: 'candidates.candidate.modal.label.year',
    defaultMessage: 'Year',
  },
  monthLabel: {
    id: 'candidates.candidate.modal.label.month',
    defaultMessage: 'Month',
  },
});
