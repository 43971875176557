import { defineMessages } from 'react-intl';
import {
  nameColumn,
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  newDepartmentTitle: {
    id: 'libraries.departments.modal.new.department.title.new.department',
    defaultMessage: 'New Department',
  },
  editDepartmentTitle: {
    id: 'libraries.departments.modal.edit.department.title.edit.department',
    defaultMessage: 'Edit Department',
  },
});
