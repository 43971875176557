import React, { useState, useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import BrandingContext from '../../BrandingContext';
import { isEmpty, isFunction, isNil } from 'lodash-es';
import Icon from '../Icon';
import ColorContext from '../../ColorContext';
import { useClickOutsideHandler, useUpdateDropdownMenuPosition } from '../../utils/hooks.utils';
import { mergeRefs } from '../../utils';
import { useFloating, shift, limitShift, flip, offset, size, Placement } from '@floating-ui/react-dom';
import Button from '../Button';

type DropdownProps = {
  dropdownToggle: string | React.ReactNode;
  dropdownClass?: string;
  dropdownToggleClass?: string;
  dropdownList: {
    itemClassName?: ((info?: unknown) => string) | string;
    btnClassName?: string;
    wrapperClassName?: string;
    handler?: (info?: unknown, i?: number, dropdownListInfo?: any) => void;
    url?: string;
    closeOnClick?: boolean;
    label: ((info?: unknown) => string | React.ReactNode) | string | React.ReactNode | null;
    dropdownListInfo?: any;
    list?: {
      label: ((info?: unknown) => string | React.ReactNode) | string | React.ReactNode | null;
      closeOnClick?: boolean;
      handler?: (info?: unknown, i?: number, dropdownListInfo?: any) => void;
    }[];
    handleRenderDropdownItem?: (info: any) => boolean;
  }[];
  dropdownInfo?: unknown;
  dropdownIndex?: number;
  customStateOpen?: boolean;
  customBtn?: boolean;
  stopPropagation?: boolean;
  overflow?: boolean;
  isDisabled?: boolean;
  listRef?: any;
  placement?: Placement;
  onOpen?: (dropdownInfo: unknown) => void;
  onClose?: () => void;
};

function DurationPicker({
  listRef,
  dropdownToggle,
  dropdownClass,
  dropdownToggleClass,
  dropdownList,
  dropdownInfo,
  dropdownIndex,
  customStateOpen,
  customBtn = false,
  stopPropagation = false,
  overflow = true,
  isDisabled,
  placement = 'bottom-end',
  onOpen,
  onClose,
}: DropdownProps) {
  const secondListRef = useRef<any>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const branding = useContext(BrandingContext);
  const color = useContext(ColorContext);
  const { refs, floatingStyles, update } = useFloating({
    middleware: [
      offset(5),
      shift({
        boundary: document.body,
        limiter: limitShift({
          offset: 5,
        }),
      }),
      flip(),
      size({
        apply({ availableHeight, availableWidth, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 5}px`,
          });
        },
      }),
    ],
    open: isNil(customStateOpen) ? dropdownOpen : customStateOpen,
    placement,
  });

  const style = {
    ['--global-color' as string]: branding?.globalAccents,
    ['--body-font' as string]: branding?.body,
    ['--global-text-color' as string]: color,
    ...floatingStyles,
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
    customStateOpen && onClose && onClose();
  };

  const handleDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (customStateOpen) {
      onClose && onClose();
    } else {
      onOpen && onOpen(dropdownInfo);
    }
    stopPropagation && e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  useClickOutsideHandler(closeDropdown, refs.floating, refs.reference, secondListRef);
  useUpdateDropdownMenuPosition(update);

  useEffect(() => {
    if (dropdownOpen) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      // if any scroll is attempted, set this to the previous value
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
      return () => {
        window.onscroll = () => null;
      };
    }
  }, [dropdownOpen]);

  const handleDropdownBtn = (
    e: React.MouseEvent,
    handler: ((info?: unknown, i?: number | undefined, dropdownListInfo?: any) => void) | undefined,
    closeOnClick: boolean,
    dropdownListInfo?: any,
  ) => {
    handler && handler(dropdownInfo, dropdownIndex, dropdownListInfo);
    stopPropagation && e.stopPropagation();
    closeOnClick && closeDropdown();
  };

  const dropdownMenu = (
    <ul
      onClick={e => stopPropagation && e.stopPropagation()}
      style={style}
      ref={mergeRefs<HTMLUListElement>(refs.setFloating, listRef)}
      className={classNames('dropdown__list', dropdownClass, { overflow })}
    >
      {dropdownList.map(
        (
          {
            itemClassName,
            btnClassName,
            handler,
            url,
            label,
            closeOnClick = true,
            dropdownListInfo,
            list,
            handleRenderDropdownItem,
            wrapperClassName,
          },
          i: number,
        ) => {
          const isHaveList = !isEmpty(list);
          return label && (handleRenderDropdownItem ? handleRenderDropdownItem(dropdownInfo) : true) ? (
            <li
              key={i.toString()}
              className={classNames(
                'dropdown__list-item',
                isFunction(itemClassName) ? itemClassName(dropdownInfo) : itemClassName,
                { 'second-level': isHaveList },
              )}
            >
              {Boolean(handler && !url) && (
                <div
                  className={classNames('dropdown__list-item__wrapper', wrapperClassName)}
                  onClick={e => handleDropdownBtn(e, handler, closeOnClick, dropdownListInfo)}
                >
                  <button type="button" className={classNames('dropdown__list-item__button', btnClassName)}>
                    {label instanceof Function ? label(dropdownInfo) : label}
                  </button>
                </div>
              )}
              {url && !handler && (
                <NavLink
                  to={url}
                  className={classNames('dropdown__list-item__wrapper dropdown__list-item__button', btnClassName)}
                  onClick={closeDropdown}
                >
                  {label instanceof Function ? label(dropdownInfo) : label}
                </NavLink>
              )}

              {Boolean(!handler && !url) && (
                <div className="dropdown__list-item__wrapper">
                  <div className="dropdown__list-item-content">
                    {label instanceof Function ? label(dropdownInfo) : label}
                  </div>
                  {isHaveList && (
                    <>
                      <Icon iconName="filter-nested-arrow" />
                      <ul className="dropdown__second-list" ref={secondListRef}>
                        {list?.map(({ label, handler, closeOnClick }, index) => (
                          <li className="dropdown__list-item dropdown__second-item" key={`list${index}`}>
                            <div
                              className="dropdown__list-item__wrapper"
                              onClick={() => {
                                handler && handler(dropdownInfo);
                                closeOnClick && closeDropdown();
                              }}
                            >
                              {label instanceof Function ? label(dropdownInfo) : label}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              )}
            </li>
          ) : null;
        },
      )}
    </ul>
  );

  return (
    <div
      ref={refs.setReference}
      className={classNames('dropdown', dropdownClass, {
        open: isNil(customStateOpen) ? dropdownOpen : customStateOpen,
      })}
      onClick={e => !isDisabled && handleDropdownClick(e)}
    >
      {!customBtn ? (
        <button
          type="button"
          className={classNames('dropdown__button', dropdownToggleClass, { open })}
          disabled={isDisabled}
        >
          {dropdownToggle}
        </button>
      ) : (
        <Button disabled={isDisabled} externalClass={classNames('button--with-icon', dropdownToggleClass, { open })}>
          {dropdownToggle}
        </Button>
      )}
      {(dropdownOpen || customStateOpen) && ReactDOM.createPortal(dropdownMenu, document.body)}
    </div>
  );
}

export default React.memo(DurationPicker);
