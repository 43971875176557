import { defineMessages } from 'react-intl';
import {
  deleteButton,
  saveButton,
  cancelButton,
  deleteModalWithItemNameTitle,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteModalWithItemNameTitle,
  nameColumn,
  cancelButton,
  saveButton,
  deleteButton,
  newSpecializationTitle: {
    id: 'libraries.specializations.modal.new.specializations.title.new.specialization',
    defaultMessage: 'New Specialization',
  },
  editSpecializationTitle: {
    id: 'libraries.specializations.modal.edit.specializations.title.edit.specialization',
    defaultMessage: 'Edit  Specialization',
  },
});
