import { EFileExtensions } from '../../../constants/export.constants';

export const downloadFile = (exportData: any, exportInfo: { name: string; ext: EFileExtensions }, cb: () => void) => {
  const href = URL.createObjectURL(exportData);
  const fileName = `${exportInfo.name}.${exportInfo.ext}`;
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  cb();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
