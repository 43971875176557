import { get } from 'lodash-es';

export class SavedFilterParams {
  filterType: string;
  size: string;

  constructor(filter?: unknown) {
    this.filterType = get(filter, 'filterType');
    this.size = get(filter, 'size', 1000);
  }
}
