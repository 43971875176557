import { getLang, getWeekOfMonth, isBrowserLocale24h } from '../utils/index';
import moment from 'moment';
import 'moment/min/locales';
import { DATE_FORMAT } from './date.constants';

export const GET_EVENTS_LIST_REQUEST = 'GET_EVENTS_LIST_REQUEST';
export const GET_USER_SCHEDULE_TAB_EVENTS_LIST_REQUEST = 'GET_USER_SCHEDULE_TAB_EVENTS_LIST_REQUEST';
export const GET_EVENTS_LIST_SUCCESS = 'GET_EVENTS_LIST_SUCCESS';
export const GET_EVENTS_LIST_FAILURE = 'GET_EVENTS_LIST_FAILURE';

export const GET_HOLIDAYS_REQUEST = 'GET_HOLIDAYS_REQUEST';
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS';
export const GET_HOLIDAYS_FAILURE = 'GET_HOLIDAYS_FAILURE';

export const GET_FREE_DAYS_REQUEST = 'GET_FREE_DAYS_REQUEST';
export const GET_FREE_DAYS_SUCCESS = 'GET_FREE_DAYS_SUCCESS';
export const GET_FREE_DAYS_FAILURE = 'GET_FREE_DAYS_FAILURE';

export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';

export const GET_EVENT_COMMENTS_REQUEST = 'GET_EVENT_COMMENTS_REQUEST';
export const GET_EVENT_COMMENTS_SUCCESS = 'GET_EVENT_COMMENTS_SUCCESS';
export const GET_EVENT_COMMENTS_FAILURE = 'GET_EVENT_COMMENTS_FAILURE';

export const GET_EVENT_SECRET_COMMENTS_REQUEST = 'GET_EVENT_SECRET_COMMENTS_REQUEST';
export const GET_EVENT_SECRET_COMMENTS_SUCCESS = 'GET_EVENT_SECRET_COMMENTS_SUCCESS';
export const GET_EVENT_SECRET_COMMENTS_FAILURE = 'GET_EVENT_SECRET_COMMENTS_FAILURE';

export const POST_EVENT_COMMENT_REQUEST = 'POST_EVENT_COMMENT_REQUEST';
export const POST_EVENT_COMMENT_SUCCESS = 'POST_EVENT_COMMENT_SUCCESS';
export const POST_EVENT_COMMENT_FAILURE = 'POST_EVENT_COMMENT_FAILURE';

export const POST_EVENT_SECRET_COMMENT_REQUEST = 'POST_EVENT_SECRET_COMMENT_REQUEST';
export const POST_EVENT_SECRET_COMMENT_SUCCESS = 'POST_EVENT_SECRET_COMMENT_SUCCESS';
export const POST_EVENT_SECRET_COMMENT_FAILURE = 'POST_EVENT_SECRET_COMMENT_FAILURE';

export const PUT_EVENT_COMMENT_REQUEST = 'PUT_EVENT_COMMENT_REQUEST';
export const PUT_EVENT_COMMENT_SUCCESS = 'PUT_EVENT_COMMENT_SUCCESS';
export const PUT_EVENT_COMMENT_FAILURE = 'PUT_EVENT_COMMENT_FAILURE';

export const PUT_EVENT_SECRET_COMMENT_REQUEST = 'PUT_EVENT_SECRET_COMMENT_REQUEST';
export const PUT_EVENT_SECRET_COMMENT_SUCCESS = 'PUT_EVENT_SECRET_COMMENT_SUCCESS';
export const PUT_EVENT_SECRET_COMMENT_FAILURE = 'PUT_EVENT_SECRET_COMMENT_FAILURE';

export const DELETE_EVENT_COMMENT_REQUEST = 'DELETE_EVENT_COMMENT_REQUEST';
export const DELETE_EVENT_COMMENT_SUCCESS = 'DELETE_EVENT_COMMENT_SUCCESS';
export const DELETE_EVENT_COMMENT_FAILURE = 'DELETE_EVENT_COMMENT_FAILURE';

export const DELETE_EVENT_SECRET_COMMENT_REQUEST = 'DELETE_EVENT_SECRET_COMMENT_REQUEST';
export const DELETE_EVENT_SECRET_COMMENT_SUCCESS = 'DELETE_EVENT_SECRET_COMMENT_SUCCESS';
export const DELETE_EVENT_SECRET_COMMENT_FAILURE = 'DELETE_EVENT_SECRET_COMMENT_FAILURE';

export const GET_EVENT_TYPE_REQUEST = 'GET_EVENT_TYPE_REQUEST';
export const GET_EVENT_TYPE_SUCCESS = 'GET_EVENT_TYPE_SUCCESS';
export const GET_EVENT_TYPE_FAILURE = 'GET_EVENT_TYPE_FAILURE';

export const POST_EVENT_REQUEST = 'POST_EVENT_REQUEST';
export const POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS';
export const POST_EVENT_FAILURE = 'POST_EVENT_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const PUT_EVENT_REQUEST = 'PUT_EVENT_REQUEST';
export const PUT_EVENT_SUCCESS = 'PUT_EVENT_SUCCESS';
export const PUT_EVENT_FAILURE = 'PUT_EVENT_FAILURE';

export const GET_DAYS_OFF_INFO_REQUEST = 'GET_DAYS_OFF_INFO_REQUEST';
export const GET_DAYS_OFF_INFO_SUCCESS = 'GET_DAYS_OFF_INFO_SUCCESS';
export const GET_DAYS_OFF_INFO_FAILURE = 'GET_DAYS_OFF_INFO_FAILURE';

export const RESET_EVENT_TYPE = 'RESET_EVENT_TYPE';
export const RESET_EVENT = 'RESET_EVENT';
export const RESET_EVENTS = 'RESET_EVENTS';
export const RESET_EVENT_ERRORS = 'RESET_EVENT_ERRORS';
export const RESET_SCHEDULE_ERRORS = 'RESET_SCHEDULE_ERRORS';
export const SET_EVENT_PARAMS_REQUEST = 'SET_EVENT_PARAMS_REQUEST';
export const SET_USER_SCHEDULE_TAB_PARAMS_REQUEST = 'SET_USER_SCHEDULE_TAB_PARAMS_REQUEST';

export const SET_SCHEDULE_LAYOUT = 'SET_SCHEDULE_LAYOUT';
export const SET_CALENDAR_PERIOD = 'SET_CALENDAR_PERIOD';

export const RESET_SCHEDULE_STATE = 'RESET_SCHEDULE_STATE';

export const RESET_SCHEDULE_PARAMS = 'RESET_SCHEDULE_PARAMS';

type remindersValueOptions = {
  [key: string]: {
    maxValue: number;
    name: string;
  };
};

export enum RepeatType {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export enum MonthRepeatType {
  SAME_WEEK_AND_WEEK_DAY = 'SAME_WEEK_AND_WEEK_DAY',
  SAME_MONTH_DAY = 'SAME_MONTH_DAY',
}

export enum ScheduleLayouts {
  CALENDAR = 'CALENDAR',
  DEPARTMENTS = 'DEPARTMENTS',
  EVENTS = 'EVENTS',
}

export const remindersData: remindersValueOptions = {
  MINUTES: {
    maxValue: 40320,
    name: 'minutes',
  },
  HOURS: {
    maxValue: 672,
    name: 'hours',
  },
  DAYS: {
    maxValue: 28,
    name: 'days',
  },
};

export const repeaterData = {
  DAYS: {
    name: 'days',
  },
  WEEKS: {
    name: 'weeks',
  },
  MONTHS: {
    name: 'months',
  },
};
moment.locale(getLang());
export const REPEAT_DAYS_OF_WEEK = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
export const weekDays = moment.weekdays(true).map(day => day.slice(0, 1).toLocaleUpperCase());

export const repeatDaysOfMonth = (date?: string) => [
  {
    value: MonthRepeatType.SAME_MONTH_DAY,
    name: `Monthly on day ${moment(date).locale('en-US').format(DATE_FORMAT.D)}`,
  },
  {
    value: MonthRepeatType.SAME_WEEK_AND_WEEK_DAY,
    name: `Monthly on the ${getWeekOfMonth(new Date(moment(date).toDate()))} ${moment(date)
      .locale('en-US')
      .format(DATE_FORMAT.dddd)}`,
  },
];

export const repeatDays = (date?: string) => [
  {
    data: null,
    repeatType: null,
    name: 'Does not repeat',
  },
  {
    data: '1',
    repeatType: RepeatType.DAYS,
    name: 'Daily',
  },
  {
    data: moment(date).locale('en-US').format(DATE_FORMAT.dddd),
    repeatType: RepeatType.WEEKS,
    name: `Weekly on ${moment(date).locale('en-US').format(DATE_FORMAT.dddd)}`,
  },
  {
    data: 'SAME_WEEK_AND_WEEK_DAY',
    repeatType: RepeatType.MONTHS,
    name: `Monthly on the ${getWeekOfMonth(new Date(moment(date).locale('en-US').toDate()))} ${moment(date)
      .locale('en-US')
      .format(DATE_FORMAT.dddd)}`,
  },
  {
    data: null,
    repeatType: RepeatType.YEARS,
    name: `Yearly on the ${moment(date).locale('en-US').format(DATE_FORMAT.MMMM_D)}`,
  },
  {
    data: '',
    repeatType: 'CUSTOM',
    name: 'Custom...',
  },
];

export const timeOptions24h = [
  '00:00:00',
  '00:15:00',
  '00:30:00',
  '00:45:00',
  '01:00:00',
  '01:15:00',
  '01:30:00',
  '01:45:00',
  '02:00:00',
  '02:15:00',
  '02:30:00',
  '02:45:00',
  '03:00:00',
  '03:15:00',
  '03:30:00',
  '03:45:00',
  '04:00:00',
  '04:15:00',
  '04:30:00',
  '04:45:00',
  '05:00:00',
  '05:15:00',
  '05:30:00',
  '05:45:00',
  '06:00:00',
  '06:15:00',
  '06:30:00',
  '06:45:00',
  '07:00:00',
  '07:15:00',
  '07:30:00',
  '07:45:00',
  '08:00:00',
  '08:15:00',
  '08:30:00',
  '08:45:00',
  '09:00:00',
  '09:15:00',
  '09:30:00',
  '09:45:00',
  '10:00:00',
  '10:15:00',
  '10:30:00',
  '10:45:00',
  '11:00:00',
  '11:15:00',
  '11:30:00',
  '11:45:00',
  '12:00:00',
  '12:15:00',
  '12:30:00',
  '12:45:00',
  '13:00:00',
  '13:15:00',
  '13:30:00',
  '13:45:00',
  '14:00:00',
  '14:15:00',
  '14:30:00',
  '14:45:00',
  '15:00:00',
  '15:15:00',
  '15:30:00',
  '15:45:00',
  '16:00:00',
  '16:15:00',
  '16:30:00',
  '16:45:00',
  '17:00:00',
  '17:15:00',
  '17:30:00',
  '17:45:00',
  '18:00:00',
  '18:15:00',
  '18:30:00',
  '18:45:00',
  '19:00:00',
  '19:15:00',
  '19:30:00',
  '19:45:00',
  '20:00:00',
  '20:15:00',
  '20:30:00',
  '20:45:00',
  '21:00:00',
  '21:15:00',
  '21:30:00',
  '21:45:00',
  '22:00:00',
  '22:15:00',
  '22:30:00',
  '22:45:00',
  '23:00:00',
  '23:15:00',
  '23:30:00',
  '23:45:00',
];

export const timeOptions12h = [
  '12:00am',
  '12:15am',
  '12:30am',
  '12:45am',
  '1:00am',
  '1:15am',
  '1:30am',
  '1:45am',
  '2:00am',
  '2:15am',
  '2:30am',
  '2:45am',
  '3:00am',
  '3:15am',
  '3:30am',
  '3:45am',
  '4:00am',
  '4:15am',
  '4:30am',
  '4:45am',
  '5:00am',
  '5:15am',
  '5:30am',
  '5:45am',
  '6:00am',
  '6:15am',
  '6:30am',
  '6:45am',
  '7:00am',
  '7:15am',
  '7:30am',
  '7:45am',
  '8:00am',
  '8:15am',
  '8:30am',
  '8:45am',
  '9:00am',
  '9:15am',
  '9:30am',
  '9:45am',
  '10:00am',
  '10:15am',
  '10:30am',
  '10:45am',
  '11:00am',
  '11:15am',
  '11:30am',
  '11:45am',
  '12:00pm',
  '12:15pm',
  '12:30pm',
  '12:45pm',
  '1:00pm',
  '1:15pm',
  '1:30pm',
  '1:45pm',
  '2:00pm',
  '2:15pm',
  '2:30pm',
  '2:45pm',
  '3:00pm',
  '3:15pm',
  '3:30pm',
  '3:45pm',
  '4:00pm',
  '4:15pm',
  '4:30pm',
  '4:45pm',
  '5:00pm',
  '5:15pm',
  '5:30pm',
  '5:45pm',
  '6:00pm',
  '6:15pm',
  '6:30pm',
  '6:45pm',
  '7:00pm',
  '7:15pm',
  '7:30pm',
  '7:45pm',
  '8:00pm',
  '8:15pm',
  '8:30pm',
  '8:45pm',
  '9:00pm',
  '9:15pm',
  '9:30pm',
  '9:45pm',
  '10:00pm',
  '10:15pm',
  '10:30pm',
  '10:45pm',
  '11:00pm',
  '11:15pm',
  '11:30pm',
  '11:45pm',
];

export const timeOptions = isBrowserLocale24h() ? timeOptions24h : timeOptions12h;

export const COMMENT_PUBLISHED = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_YEAR: 'THIS_YEAR',
};

export const NOTIFICATION_OPTIONS = {
  ALL: 'All participants',
  ONLY_NEW: 'New participants',
};

export const GET_DAYS_USED_LIMIT_SUCCESS = 'GET_DAYS_USED_LIMIT_SUCCESS';
export const GET_DAYS_USED_LIMIT_FAILURE = 'GET_DAYS_USED_LIMIT_FAILURE';

export const SET_DAYS_USED_LIMIT_PARAMS = 'SET_DAYS_USED_LIMIT_PARAMS';

export const PUT_DAYS_LIMIT_REQUEST = 'PUT_DAYS_LIMIT_REQUEST';
export const PUT_DAYS_LIMIT_SUCCESS = 'PUT_DAYS_LIMIT_SUCCESS';
export const PUT_DAYS_LIMIT_FAILURE = 'PUT_DAYS_LIMIT_FAILURE';

export const daysLimitUnsavedParams = ['dateFrom', 'dateTo'];
export const scheduleUnsavedParams = ['dateTimeFrom', 'dateTimeTo'];
