import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as ActionTypes from '../constants/users.constants';
import * as api from '../api/users.api';
import { UsersParams } from '../enums/params/users.params';
import { getAbsencePeriods } from '../api/reports.api';
import { AbsencePeriodsParams } from '../enums/params/reports.params';

export function* getUserInfo({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getUserInfo, payload);
    if (success) {
      yield put({ type: ActionTypes.GET_USER_INFO_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_INFO_FAILURE, payload: error });
  }
}

function* getUserPersonalInfo({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getUserPersonalInfo, payload);
    if (success) {
      yield put({ type: ActionTypes.GET_USER_PERSONAL_INFO_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_PERSONAL_INFO_FAILURE, payload: error });
  }
}

function* getUserProfessionalInfo({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getUserProfessionalInfo, payload);
    if (success) {
      yield put({ type: ActionTypes.GET_USER_PROFESSIONAL_INFO_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_PROFESSIONAL_INFO_FAILURE, payload: error });
  }
}

function* getJiraUsers() {
  try {
    const { success, results } = yield call(api.getJiraUsersPreview);
    if (success) {
      yield put({ type: ActionTypes.GET_JIRA_USERS_BRIEF_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_JIRA_USERS_BRIEF_FAILURE, payload: error });
  }
}

function* getUsers() {
  try {
    const params: UsersParams = yield select((state: RootState) => state.users.params);
    const { success, results } = yield call(api.getUsers, params);
    if (success) {
      yield put({ type: ActionTypes.GET_USERS_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USERS_FAILURE, payload: error });
  }
}

function* updateUserInfo({ payload }: AnyAction) {
  const { uuid, data, cb } = payload;
  try {
    const { success } = yield call(api.updateUserInfo, uuid, data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_USER_INFO_SUCCESS,
        payload: payload.uuid,
      });
      cb && cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_USER_INFO_FAILURE, payload: error });
  }
}

function* updateUserPersonalInfo({ payload }: AnyAction) {
  const { uuid, data, cb } = payload;
  try {
    const { success, results } = yield call(api.updateUserPersonalInfo, uuid, data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_USER_PERSONAL_INFO_SUCCESS,
        payload: results,
      });
      cb && cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_USER_PERSONAL_INFO_FAILURE, payload: error });
  }
}

function* updateUserProfessionalInfo({ payload }: AnyAction) {
  const { uuid, data, cb } = payload;
  try {
    const { success, results } = yield call(api.updateUserProfessionalInfo, uuid, data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_USER_PROFESSIONAL_INFO_SUCCESS,
        payload: results,
      });
      cb && cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_USER_PROFESSIONAL_INFO_FAILURE, payload: error });
  }
}

function* createUser({ payload }: AnyAction) {
  const { data, cb } = payload.data;
  try {
    const { success, results } = yield call(api.createUser, data);
    if (success) {
      yield put({ type: ActionTypes.POST_USER_SUCCESS, payload: results });
      yield call(getUsers);
      cb && cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_USER_FAILURE, payload: error });
  }
}

function* updateUserPartialInfo({ payload }: AnyAction) {
  const { uuid, data, cb } = payload;
  try {
    const { success, results } = yield call(api.updateUserPartialInfo, uuid, data);
    if (success) {
      yield put({
        type: ActionTypes.PATCH_USER_INFO_SUCCESS,
        payload: results,
      });
      cb && cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PATCH_USER_INFO_FAILURE, payload: error });
  }
}

function* uploadUserPhoto({ payload }: AnyAction) {
  const { uuid, data } = payload;
  try {
    const { success, results } = yield call(api.uploadUserPhoto, uuid, data.file);
    if (success) {
      yield put({
        type: ActionTypes.POST_USER_PHOTO_SUCCESS,
        payload: { uuid, results },
      });
      data.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_USER_PHOTO_FAILURE, payload: error });
  }
}

function* deleteUserPhoto({ payload }: AnyAction) {
  const { uuid, callback } = payload;
  try {
    const { success, results } = yield call(api.deleteUserPhoto, uuid);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_USER_PHOTO_SUCCESS,
        payload: { uuid, results },
      });
      callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_USER_PHOTO_FAILURE, payload: error });
  }
}

function* getUserTechnicalSkills({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getUserTechnicalSkills, payload);
    if (success) {
      yield put({ type: ActionTypes.GET_USER_TECHNICAL_SKILLS_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_TECHNICAL_SKILLS_FAILURE, payload: error });
  }
}

function* updateUserTechnicalSkills({ payload }: AnyAction) {
  const { uuid, data, cb } = payload;
  try {
    const { success, results } = yield call(api.updateUserTechnicalSkills, uuid, data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_USER_TECHNICAL_SKILLS_SUCCESS,
        payload: results,
      });
      cb && cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_USER_TECHNICAL_SKILLS_FAILURE, payload: error });
  }
}

function* updateUserPassword({ payload }: AnyAction) {
  const { uuid, data, callback } = payload;
  try {
    const { success } = yield call(api.updateUserPassword, uuid, data);
    if (success) {
      yield put({
        type: ActionTypes.PATCH_UPDATE_USER_PASSWORD_SUCCESS,
      });
      callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PATCH_UPDATE_USER_PASSWORD_FAILURE, payload: error });
  }
}

function* getUserPositionsHistory({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getUserPositionsHistory, payload.uuid);
    if (success) {
      yield put({ type: ActionTypes.GET_USER_POSITION_HISTORY_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_POSITION_HISTORY_FAILURE, payload: error });
  }
}

function* editUserPositionsHistory({ payload }: AnyAction) {
  const { uuid, data, cb } = payload;
  try {
    const { success, results } = yield call(api.editUserPositionsHistory, uuid, data);
    if (success) {
      yield call(getUserInfo, { type: ActionTypes.GET_USER_INFO_REQUEST, payload: uuid });
      cb && cb();
      yield put({
        type: ActionTypes.PUT_USER_POSITION_HISTORY_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_USER_POSITION_HISTORY_FAILURE, payload: error });
  }
}

function* getUserExperiences({ payload }: AnyAction) {
  const currentUuid: string = yield select((state: RootState) => state.users.current.total.id);
  const uuid = payload.uuid;
  try {
    const { success, results } = yield call(api.getUserExperiences, uuid ? uuid : currentUuid);
    if (success) {
      yield put({ type: ActionTypes.GET_USER_EXPERIENCES_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_EXPERIENCES_FAILURE, payload: error });
  }
}

function* getUserBonuses({ payload }: AnyAction) {
  const currentUuid: string = yield select((state: RootState) => state.users.current.total.id);
  const uuid = payload.uuid;
  try {
    const { success, results } = yield call(api.getUserBonuses, uuid ? uuid : currentUuid);
    if (success) {
      yield put({ type: ActionTypes.GET_USER_BONUSES_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_BONUSES_FAILURE, payload: error });
  }
}

function* getUserAbsencePeriods({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getUserAbsencePeriods, payload.id, payload.data);

    if (success) {
      yield put({ type: ActionTypes.GET_USER_ABSENCE_PERIODS_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_ABSENCE_PERIODS_FAILURE, payload: error });
  }
}

function* getDetailedUserAbsencePeriodsInfo({ payload }: AnyAction) {
  const params = new AbsencePeriodsParams(payload);

  try {
    const { success, results } = yield call(getAbsencePeriods, params);

    if (success) {
      yield put({ type: ActionTypes.GET_DETAILED_USER_ABSENCE_PERIODS_INFO_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DETAILED_USER_ABSENCE_PERIODS_INFO_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeEvery([ActionTypes.GET_USER_INFO_REQUEST, ActionTypes.PUT_USER_INFO_SUCCESS], getUserInfo),
    takeEvery(ActionTypes.GET_USER_PERSONAL_INFO_REQUEST, getUserPersonalInfo),
    takeEvery(ActionTypes.GET_USER_PROFESSIONAL_INFO_REQUEST, getUserProfessionalInfo),
    takeEvery(ActionTypes.GET_JIRA_USERS_BRIEF_REQUEST, getJiraUsers),
    takeEvery(ActionTypes.GET_USERS_REQUEST, getUsers),
    takeEvery(ActionTypes.SET_USERS_PARAMS_REQUEST, getUsers),
    takeEvery(ActionTypes.PUT_USER_INFO_REQUEST, updateUserInfo),
    takeEvery(ActionTypes.PUT_USER_PERSONAL_INFO_REQUEST, updateUserPersonalInfo),
    takeEvery(ActionTypes.PUT_USER_PROFESSIONAL_INFO_REQUEST, updateUserProfessionalInfo),
    takeEvery(ActionTypes.POST_USER_REQUEST, createUser),
    takeEvery(ActionTypes.PATCH_USER_INFO_REQUEST, updateUserPartialInfo),
    takeEvery(ActionTypes.POST_USER_PHOTO_REQUEST, uploadUserPhoto),
    takeEvery(ActionTypes.DELETE_USER_PHOTO_REQUEST, deleteUserPhoto),
    takeEvery(ActionTypes.GET_USER_TECHNICAL_SKILLS_REQUEST, getUserTechnicalSkills),
    takeEvery(ActionTypes.PUT_USER_TECHNICAL_SKILLS_REQUEST, updateUserTechnicalSkills),
    takeEvery(ActionTypes.PATCH_UPDATE_USER_PASSWORD_REQUEST, updateUserPassword),
    takeEvery(ActionTypes.GET_USER_POSITION_HISTORY_REQUEST, getUserPositionsHistory),
    takeEvery(ActionTypes.PUT_USER_POSITION_HISTORY_REQUEST, editUserPositionsHistory),
    takeEvery(
      [ActionTypes.GET_USER_EXPERIENCES_REQUEST, ActionTypes.PUT_USER_POSITION_HISTORY_SUCCESS],
      getUserExperiences,
    ),
    takeEvery(
      [
        ActionTypes.GET_USER_BONUSES_REQUEST,
        ActionTypes.PUT_USER_POSITION_HISTORY_SUCCESS,
        ActionTypes.PUT_USER_INFO_SUCCESS,
      ],
      getUserBonuses,
    ),
    takeEvery(ActionTypes.GET_USER_ABSENCE_PERIODS_REQUEST, getUserAbsencePeriods),
    takeEvery(ActionTypes.GET_DETAILED_USER_ABSENCE_PERIODS_INFO_REQUEST, getDetailedUserAbsencePeriodsInfo),
  ]);
}
