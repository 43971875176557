import React, { ChangeEvent } from 'react';
import Select from '../../Select';
import NumberInput from '../../NumberInput/index';
import { remindersData } from '../../../constants/schedule.constants';
import Button from '../../Button';
import Icon from '../../Icon';
import { OptionTypeBase } from 'react-select';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';

type EventReminderProps = {
  handleChange: (e: ChangeEvent) => void;
  reminders: any[];
  handleCountUp: (index: number) => () => void;
  handleCountDown: (index: number) => () => void;
  addReminder: () => void;
  removeReminder: (i: number) => () => void;
  remindersOptions: any;
  handleReminderChange: (index: number) => ({}: OptionTypeBase) => void;
};

function EventReminder({
  reminders,
  remindersOptions,
  handleChange,
  handleCountUp,
  handleCountDown,
  addReminder,
  removeReminder,
  handleReminderChange,
}: EventReminderProps) {
  const intl = useIntl();

  return (
    <>
      {reminders.map((el, index) => {
        const mavValue = remindersData[`${reminders[index].unitOfTime}`].maxValue;
        return (
          <div className="form__group-wrapper" key={index}>
            <div className="form__inputs-subwrapper">
              <Select
                label={intl.formatMessage(messages.reminderButton)}
                options={remindersOptions}
                value={{ label: el.unitOfTime[0] + el.unitOfTime.slice(1).toLowerCase(), value: el.unitOfTime }}
                handleChange={handleReminderChange(index)}
              />
              <NumberInput
                name={`reminders[${index}].timeValue`}
                maxLength={10}
                onChange={handleChange}
                min={0}
                max={mavValue}
                label={intl.formatMessage(messages.valueLabel)}
                externalClass={'form__input not-empty'}
                defaultValue={`${reminders[index].timeValue}`}
                onCountUp={handleCountUp(index)}
                onCountDown={handleCountDown(index)}
              />
            </div>
            <button className="form__btn-clean-inputs" type={'button'} onClick={removeReminder(index)}>
              <Icon iconName={'cross'} externalClass={'form__btn-icon'} />
            </button>
          </div>
        );
      })}
      <Button externalClass={'form__btn-add-group'} color={'gray'} type={'button'} onClick={addReminder}>
        <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
        <FormattedMessage {...messages.reminderButton} />
      </Button>
    </>
  );
}

export default EventReminder;
