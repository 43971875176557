import React, { useContext, useMemo } from 'react';
import PoliciesContext from '../../PoliciesContext';
import { checkPolicies } from '../../utils/policies.utils';
type AccessCheckerProps = {
  children: React.ReactNode;
  verifiablePolicies: string[];
};

const AccessChecker = ({ children, verifiablePolicies }: AccessCheckerProps) => {
  const userPolicies = useContext(PoliciesContext);

  const access = useMemo(() => checkPolicies(verifiablePolicies, userPolicies), [userPolicies, verifiablePolicies]);

  return <>{access ? children : null}</>;
};

export default AccessChecker;
