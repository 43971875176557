import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  departmentLabel,
  officeLabel,
  emailLabel,
  addButton,
  phonesLabel,
  editButton,
  competenciesTitle,
  startDateLabel,
  exportButton,
  skillsColumn,
  scoreColumn,
  newButton,
  exportToPDFLabel,
  personalLabel,
  ofText,
  positionLabel,
  okButton,
  plannedLabel,
  fromLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteButton,
  cancelButton,
  saveButton,
  newButton,
  departmentLabel,
  officeLabel,
  emailLabel,
  addButton,
  phonesLabel,
  editButton,
  competenciesTitle,
  startDateLabel,
  exportButton,
  skillsColumn,
  scoreColumn,
  exportToPDFLabel,
  personalLabel,
  ofText,
  positionLabel,
  okButton,
  plannedLabel,
  fromLabel,
  modalExtensionErrorTitle: {
    id: 'profile.avatar.modal.extension.error.title.invalid.image.extension',
    defaultMessage: 'Invalid image extension',
  },
  modalSizeErrorTitle: {
    id: 'profile.avatar.modal.size.error.title.maximum.file.size.is.10MB',
    defaultMessage: 'Maximum file size is 10MB',
  },
  editPhotoTitle: {
    id: 'profile.avatar.modal.edit.photo.title.edit.photo',
    defaultMessage: 'Edit Photo',
  },
  deleteModalTitle: {
    id: 'profile.avatar.modal.delete.photo.title.are.you.sure.you.want.to.delete.photo',
    defaultMessage: 'Are you sure you want to delete photo?',
  },
  errorTitle: {
    id: 'profile.avatar.modal.size.error.title.Error',
    defaultMessage: 'Error',
  },
  firstNameInput: {
    id: 'profile.modal.profile.info.label.first.name',
    defaultMessage: 'First Name',
  },
  secondNameInput: {
    id: 'profile.modal.profile.info.label.last.name',
    defaultMessage: 'Last Name',
  },
  loginInput: {
    id: 'people.modal.profile.info.label.login',
    defaultMessage: 'Login',
  },
  jiraInput: {
    id: 'profile.modal.profile.info.label.jira.account',
    defaultMessage: 'JIRA Account',
  },
  otherEmailsInput: {
    id: 'profile.modal.profile.info.label.other.emails',
    defaultMessage: 'Other emails',
  },
  birthdayInput: {
    id: 'profile.modal.personal.info.label.birthday',
    defaultMessage: 'Birthday',
  },
  educationInput: {
    id: 'profile.modal.personal.info.label.education',
    defaultMessage: 'Education',
  },
  coursesInput: {
    id: 'profile.modal.personal.info.label.courses.trainings',
    defaultMessage: 'Courses, Trainings',
  },
  hobbiesInput: {
    id: 'profile.modal.personal.info.label.hobbies',
    defaultMessage: 'Hobbies',
  },
  booksInput: {
    id: 'profile.modal.personal.info.label.books',
    defaultMessage: 'Books',
  },
  musicInput: {
    id: 'profile.modal.personal.info.label.music',
    defaultMessage: 'Music',
  },
  lifeValuesInput: {
    id: 'profile.modal.personal.info.label.life.values',
    defaultMessage: 'Life values',
  },
  lifeMottoInput: {
    id: 'profile.modal.personal.info.label.life.motto',
    defaultMessage: 'Life motto',
  },
  likesInput: {
    id: 'profile.modal.personal.info.label.life.likes',
    defaultMessage: 'Likes',
  },
  dislikesInput: {
    id: 'profile.modal.personal.info.label.life.dislikes',
    defaultMessage: 'Dislikes',
  },
  editProfilelInfoTitle: {
    id: 'profile.modal.profile.info.title.edit.profile',
    defaultMessage: 'Edit Profile',
  },
  editPersonalInfoTitle: {
    id: 'profile.modal.personal.info.title.edit.personal.info',
    defaultMessage: 'Edit Personal Info',
  },
  editProfessionalInfoTitle: {
    id: 'profile.modal.professional.info.title.edit.professional.info',
    defaultMessage: 'Edit Professional Info',
  },
  hiredInput: {
    id: 'profile.modal.professional.info.label.hired',
    defaultMessage: 'Hired',
  },
  trialInput: {
    id: 'profile.modal.professional.info.label.trial',
    defaultMessage: 'Trial',
  },
  dismissalInput: {
    id: 'profile.modal.professional.info.label.dismissal',
    defaultMessage: 'Dismissal',
  },
  talentCuratorSelect: {
    id: 'profile.modal.professional.info.label.talent.curator',
    defaultMessage: 'Talent Curator',
  },
  addHRInfoButton: {
    id: 'profile.modal.professional.info.label.add.hr.info',
    defaultMessage: 'Add HR Info',
  },
  previousExperienceInput: {
    id: 'profile.modal.professional.info.label.previous.experience',
    defaultMessage: 'Previous Experience',
  },
  gredeSelect: {
    id: 'profile.modal.professional.info.label.grade',
    defaultMessage: 'Grade',
  },
  regulatoryHoursSelect: {
    id: 'profile.modal.professional.info.label.regulatory.hours',
    defaultMessage: 'Regulatory Hours',
  },
  addWorkHoursButton: {
    id: 'profile.modal.professional.info.label.add.work.hours',
    defaultMessage: 'Add Work Hours',
  },
  primarySpecializationSelect: {
    id: 'profile.modal.professional.info.label.primary.specialization',
    defaultMessage: 'Primary Specialization',
  },
  otherSpecializations: {
    id: 'profile.modal.professional.info.label.other.specializations',
    defaultMessage: 'Other Specializations',
  },
  allVacationsDaysTitle: {
    id: 'profile.vacation.title.all.vacations.days',
    defaultMessage: 'All Vacations Days',
  },
  previousYearTitle: {
    id: 'profile.vacation.title.previous.year',
    defaultMessage: 'Previous Year',
  },
  currentYearTitle: {
    id: 'profile.vacation.title.current.year',
    defaultMessage: 'Current Year',
  },
  spentDaysTitle: {
    id: 'profile.vacation.title.spent.days',
    defaultMessage: 'Spent Days',
  },
  daysOfVacationLeftTitle: {
    id: 'profile.vacation.title.days.of.vacation.left',
    defaultMessage: 'Days of vacation left',
  },
  fullCompensationSickLeavesTitle: {
    id: 'profile.vacation.title.full.compensation.sick.leaves',
    defaultMessage: 'Full Compensation Sick Leaves',
  },
  limitedCompensationSickLevesTitle: {
    id: 'profile.vacation.title.limited.compensation.sick.leves',
    defaultMessage: 'Limited Compensation Sick Leves',
  },
  aboutMemberTitle: {
    id: 'profile.tab.about.title.about.member',
    defaultMessage: 'About Member',
  },
  professionalTitle: {
    id: 'profile.tab.about.title.professional',
    defaultMessage: 'Professional',
  },
  recruitmentTitle: {
    id: 'profile.tab.about.label.recruitment',
    defaultMessage: 'Recruitment',
  },
  endTrialPeriodTitle: {
    id: 'profile.tab.about.label.end.trial.period',
    defaultMessage: 'End Trial Period',
  },
  recognitionsTitle: {
    id: 'profile.tab.menu.btn.recognitions',
    defaultMessage: 'Recognitions',
  },
  aboutTitle: {
    id: 'profile.tab.menu.btn.about',
    defaultMessage: 'About',
  },
  workTimeTitle: {
    id: 'profile.tab.menu.btn.work.time',
    defaultMessage: 'Work Time',
  },
  scheduleTitle: {
    id: 'profile.tab.menu.btn.schedule',
    defaultMessage: 'Schedule',
  },
  technicalSkillsTitle: {
    id: 'profile.tab.menu.btn.technicalSkillsTitle',
    defaultMessage: 'Technical Skills',
  },
  gradesAndBonusesTitle: {
    id: 'profile.tab.menu.btn.grades.and.bonuses',
    defaultMessage: 'Grades and Bonuses',
  },
  editPermissionsTitle: {
    id: 'profile.modal.edit.permissions.title.edit.permissions',
    defaultMessage: 'Edit Permissions',
  },
  pageModalColumn: {
    id: 'profile.modal.edit.permissions.column.page.modal',
    defaultMessage: 'PAGE/MODAL',
  },
  viewColumn: {
    id: 'profile.modal.edit.permissions.column.view',
    defaultMessage: 'VIEW',
  },
  updateColumn: {
    id: 'profile.modal.edit.permissions.column.update',
    defaultMessage: 'UPDATE',
  },
  deleteColumn: {
    id: 'profile.modal.edit.permissions.column.delete',
    defaultMessage: 'DELETE',
  },
  passwordTypeRandomRadioInput: {
    id: 'people.modal.change.password.label.random',
    defaultMessage: 'Random',
  },
  passwordTypeCustomRadioInput: {
    id: 'people.modal.change.password.label.custom',
    defaultMessage: 'Custom',
  },
  changePasswordTitle: {
    id: 'people.modal.change.password.title.change.password',
    defaultMessage: 'Change Password',
  },
  resetPasswordTitle: {
    id: 'people.modal.change.password.title.reset.password',
    defaultMessage: 'Reset Password',
  },
  passwordInput: {
    id: 'people.modal.change.password.label.password',
    defaultMessage: 'Password',
  },
  newPasswordInput: {
    id: 'people.modal.change.password.label.new.password',
    defaultMessage: 'New Password',
  },
  currentPasswordInput: {
    id: 'people.modal.change.password.label.current.password',
    defaultMessage: 'Current Password',
  },
  confirmPasswordInput: {
    id: 'people.modal.change.password.label.confirm.new.password',
    defaultMessage: 'Confirm New Password',
  },
  sendAccountDetailsViaEmailCheckbox: {
    id: 'people.modal.change.password.label.send.account.details.via.email',
    defaultMessage: 'Send account details via email',
  },
  editPositionsTitle: {
    id: 'profile.modal.edit.positions.title.edit.positions',
    defaultMessage: 'Edit Positions',
  },
  historyPositionsTitle: {
    id: 'profile.modal.positions.history.title.career.positions',
    defaultMessage: 'Career Positions',
  },
  currentLabel: {
    id: 'profile.modal.positions.history.label.current',
    defaultMessage: 'Current',
  },
  notificationGroupLabel: {
    id: 'profile.modal.professional.info.label.notification.group',
    defaultMessage: 'Notification Group',
  },
  primaryLabel: {
    id: 'profile.tab.competencies.label.primary',
    defaultMessage: 'Primary',
  },
  secondaryLabel: {
    id: 'profile.tab.competencies.label.secondary',
    defaultMessage: 'Secondary',
  },
  currentGradeLabel: {
    id: 'profile.tab.bonuses.label.currentGrade',
    defaultMessage: 'Current Grade',
  },
  gradeExperienceLabel: {
    id: 'profile.tab.bonuses.label.gradeExperience',
    defaultMessage: 'Grade Experience',
  },
  previousExperienceLabel: {
    id: 'profile.tab.bonuses.label.previousExperience',
    defaultMessage: 'Previous Experience',
  },
  experienceInCompanyLabel: {
    id: 'profile.tab.bonuses.label.ExperienceInCompany',
    defaultMessage: 'Experience in Company',
  },
  fullExperienceLabel: {
    id: 'profile.tab.bonuses.label.fullExperience',
    defaultMessage: 'Full Actual Experience',
  },
  socialPaymentLabel: {
    id: 'profile.tab.bonuses.label.socialPayment',
    defaultMessage: 'Social Payment / Experience',
  },
  gradesLabel: {
    id: 'profile.tab.bonuses.label.gradesLabel',
    defaultMessage: 'Grades',
  },
  scheduleNoContent: {
    id: 'profile.tab.schedule.no.content',
    defaultMessage: 'No scheduled events yet',
  },
  publicCVTitle: {
    id: 'profile.tab.menu.btn.puplic.cv',
    defaultMessage: 'Public CV',
  },
  moreLabel: {
    id: 'profile.vacation.label.more.puplic.cv',
    defaultMessage: 'more',
  },
  summaryLabel: {
    id: 'profile.tab.work.time.summary.label.summary',
    defaultMessage: 'Summary',
  },
  distributionLabel: {
    id: 'profile.tab.work.time.distribution.label.distribution',
    defaultMessage: 'Distribution',
  },
  workTimeLabel: {
    id: 'profile.tab.work.time.title.work.time',
    defaultMessage: 'Work Time',
  },
});
