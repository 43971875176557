import React from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../../components/Icon';
import { ImportErrorTypeEnum } from '../../utils';
import messages from '../../messages';
import { isString } from 'lodash-es';

type HintErrorProps = {
  error: string | { float: string };
  errorType: ImportErrorTypeEnum;
};

function HintError({ error, errorType }: HintErrorProps) {
  const errorValue = isString(error) ? error : error?.float;
  return (
    <div className="transactions__hint-content">
      <Icon iconName="alert" />
      {errorType === ImportErrorTypeEnum.PARSER_ERROR ? (
        <span>
          <FormattedMessage {...messages.parsingError} /> {errorValue}
        </span>
      ) : (
        <span>{errorValue}</span>
      )}
    </div>
  );
}

export default HintError;
