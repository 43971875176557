import React from 'react';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import TableUserAvatar from '../../components/TableUserAvatar';
import { DepthLevels } from '../../constants/tables.constants';
import { ActiveandInactiveHoursDateType, ActiveandInactiveHoursUser } from '../../enums/reports.enum';
import { UserInfo } from '../../enums/users.enum';
import { getForamtedTime } from '../../utils/reports.utils';
import { getTableCell } from '../../utils/table.utils';
import messages from './messages';

export const useDataForTable = () => {
  const intl = useIntl();
  const tableColumns = useMemo(
    () => [
      {
        id: 'name',
        Header: intl.formatMessage(messages.membersDatesColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ActiveandInactiveHoursUser) =>
                row?.totalItem ? (
                  <FormattedMessage {...messages.totalRow} />
                ) : (
                  // <div>{`${row.firstName} ${row.lastName}`}</div>

                  <TableUserAvatar
                    users={[new UserInfo({ ...row, id: row.portalId, secondName: row.lastName })]}
                    fileSize={36}
                  />
                ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ActiveandInactiveHoursDateType) => <div>{row.date}</div>,
            },
          ]),
      },
      {
        id: 'logged',
        Header: intl.formatMessage(messages.loggedColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ActiveandInactiveHoursUser) => <div>{`${getForamtedTime(row.logged)}h`}</div>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ActiveandInactiveHoursDateType) => <div>{`${getForamtedTime(row.logged)}h`}</div>,
            },
          ]),
      },
      {
        id: 'trackedActive',
        Header: intl.formatMessage(messages.trackedActiveColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ActiveandInactiveHoursUser) => <div>{`${getForamtedTime(row.trackedActive)}h`}</div>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ActiveandInactiveHoursDateType) => <div>{`${getForamtedTime(row.trackedActive)}h`}</div>,
            },
          ]),
      },
      {
        id: 'trackedInactive',
        Header: intl.formatMessage(messages.trackedInactiveColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ActiveandInactiveHoursUser) => <div>{`${getForamtedTime(row.trackedInactive)}h`}</div>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ActiveandInactiveHoursDateType) => <div>{`${getForamtedTime(row.trackedInactive)}h`}</div>,
            },
          ]),
      },

      {
        id: 'notWorkingActive',
        Header: intl.formatMessage(messages.notWorkingActiveColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ActiveandInactiveHoursUser) => <div>{`${getForamtedTime(row.notWorkingActive)}h`}</div>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ActiveandInactiveHoursDateType) => (
                <div>{`${getForamtedTime(row.notWorkingActive)}h`}</div>
              ),
            },
          ]),
      },
      {
        id: 'notWorkingInactive',
        Header: intl.formatMessage(messages.notWorkingInactiveColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ActiveandInactiveHoursUser) => <div>{`${getForamtedTime(row.notWorkingInactive)}h`}</div>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ActiveandInactiveHoursDateType) => (
                <div>{`${getForamtedTime(row.notWorkingInactive)}h`}</div>
              ),
            },
          ]),
      },
      {
        id: 'submitted',
        Header: intl.formatMessage(messages.submittedColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ActiveandInactiveHoursUser) => <div>{`${getForamtedTime(row.submitted)}h`}</div>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ActiveandInactiveHoursDateType) => <div>{`${getForamtedTime(row.submitted)}h`}</div>,
            },
          ]),
      },
    ],
    [],
  );

  return {
    tableColumns,
  };
};
