import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  newCandidateTechnologyTitle: {
    id: 'candidates.candidate.technologies.modal.new.title.new.technology',
    defaultMessage: 'New Technology',
  },
  editCandidateTechnologyTitle: {
    id: 'candidates.candidate.technologies.modal.edit.title.edit.technology',
    defaultMessage: 'Edit Technology',
  },
});
