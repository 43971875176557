export const addButton = {
  id: 'common.btn.add',
  defaultMessage: 'Add',
};

export const deleteButton = {
  id: 'common.btn.delete',
  defaultMessage: 'Delete',
};

export const editButton = {
  id: 'common.btn.edit',
  defaultMessage: 'Edit',
};

export const newButton = {
  id: 'common.btn.new',
  defaultMessage: 'New',
};

export const resetButton = {
  id: 'common.btn.reset',
  defaultMessage: 'Reset',
};

export const exportButton = {
  id: 'common.btn.export',
  defaultMessage: 'Export',
};

export const cancelButton = {
  id: 'common.btn.cancel',
  defaultMessage: 'Cancel',
};

export const saveButton = {
  id: 'common.btn.save',
  defaultMessage: 'Save',
};

export const applyButton = {
  id: 'common.btn.cancel',
  defaultMessage: 'Apply',
};

export const confirmButton = {
  id: 'common.btn.confirm',
  defaultMessage: 'Confirm',
};

export const clearButton = {
  id: 'common.btn.save',
  defaultMessage: 'Clear',
};

export const submitButton = {
  id: 'common.btn.submit',
  defaultMessage: 'Submit',
};

export const doneButton = {
  id: 'common.btn.icon',
  defaultMessage: 'Done',
};

export const cloneButton = {
  id: 'common.btn.clone',
  defaultMessage: 'Clone',
};

export const skillsColumn = {
  id: 'common.column.skills',
  defaultMessage: 'Skills',
};

export const nameColumn = {
  id: 'common.column.name',
  defaultMessage: 'Name',
};

export const authorColumn = {
  id: 'common.column.author',
  defaultMessage: 'Author',
};

export const createdColumn = {
  id: 'common.column.created',
  defaultMessage: 'Created',
};

export const maxScoreColumn = {
  id: 'common.column.max.score',
  defaultMessage: 'Max Score',
};

export const editorColumn = {
  id: 'common.column.editor',
  defaultMessage: 'Editor',
};

export const updatedColumn = {
  id: 'common.column.updated',
  defaultMessage: 'Updated',
};

export const urlLabel = {
  id: 'common.label.url',
  defaultMessage: 'URL',
};

export const questionLabel = {
  id: 'common.label.question',
  defaultMessage: 'Question',
};

export const allLabel = {
  id: 'common.label.all',
  defaultMessage: 'All',
};

export const emailLabel = {
  id: 'common.label.email',
  defaultMessage: 'Email',
};

export const activeLabel = {
  id: 'common.label.active',
  defaultMessage: 'Active',
};

export const inactiveLabel = {
  id: 'common.label.inactive',
  defaultMessage: 'Inactive',
};

export const externalLabel = {
  id: 'common.label.external',
  defaultMessage: 'External',
};

export const yesLabel = {
  id: 'common.label.yes',
  defaultMessage: 'Yes',
};

export const noLabel = {
  id: 'common.label.no',
  defaultMessage: 'No',
};

export const statusLabel = {
  id: 'common.label.status',
  defaultMessage: 'Status',
};

export const skillLabel = {
  id: 'common.label.skill',
  defaultMessage: 'Skill',
};

export const positionsLabel = {
  id: 'common.label.positions',
  defaultMessage: 'Positions',
};

export const projectsLabel = {
  id: 'common.label.projects',
  defaultMessage: 'Projects',
};

export const copyURLLabel = {
  id: 'common.label.copy.url',
  defaultMessage: 'Copy URL',
};

export const exportToPDFLabel = {
  id: 'common.label.export.to.pdf',
  defaultMessage: 'Export to PDF',
};

export const exportToXLSLabel = {
  id: 'common.label.export.to.xls',
  defaultMessage: 'Export to XLS',
};

export const officeLabel = {
  id: 'common.label.office',
  defaultMessage: 'Office',
};

export const officesLabel = {
  id: 'common.label.offices',
  defaultMessage: 'Offices',
};

export const totalRow = {
  id: 'common.row.total',
  defaultMessage: 'Total',
};

export const addressLabel = {
  id: 'common.label.address',
  defaultMessage: 'Address',
};

export const phonesLabel = {
  id: 'common.label.phones',
  defaultMessage: 'Phones',
};

export const filtersLabel = {
  id: 'common.label.filters',
  defaultMessage: 'Filters',
};

export const departmentLabel = {
  id: 'common.label.department',
  defaultMessage: 'Department',
};

export const departmentsLabel = {
  id: 'common.label.departments',
  defaultMessage: 'Departments',
};

export const memberLabel = {
  id: 'common.label.member',
  defaultMessage: 'Member',
};

export const membersLabel = {
  id: 'common.label.members',
  defaultMessage: 'Members',
};

export const employeeLabel = {
  id: 'common.label.employee',
  defaultMessage: 'Employee',
};

export const employeesLabel = {
  id: 'common.label.members',
  defaultMessage: 'Members',
};

export const endDateLabel = {
  id: 'common.label.end.date',
  defaultMessage: 'End Date',
};

export const startDateLabel = {
  id: 'common.label.start.date',
  defaultMessage: 'Start Date',
};

export const dateLabel = {
  id: 'common.label.date',
  defaultMessage: 'Date',
};

export const competenciesTitle = {
  id: 'common.modules.competencies',
  defaultMessage: 'Competencies',
};

export const deleteModalTitle = {
  id: 'common.title.are.you.sure.you.want.to.delete',
  defaultMessage: 'Are you sure you want to delete?',
};

export const deleteModalWithItemNameTitle = {
  id: 'common.title.are.you.sure.you.want.to.delete',
  defaultMessage: 'Are you sure you want to delete {name}?',
};

export const scoreColumn = {
  id: 'common.column.score',
  defaultMessage: 'Score',
};

export const valueLabel = {
  id: 'common.label.value',
  defaultMessage: 'Value',
};

export const label = {
  id: 'common.label.label',
  defaultMessage: 'Label',
};

export const iconLabel = {
  id: 'common.label.icon',
  defaultMessage: 'Icon',
};

export const dispalyNameLabel = {
  id: 'common.label.dispayName',
  defaultMessage: 'Display Name',
};

export const lastUpdateColumn = {
  id: 'common.label.last.update',
  defaultMessage: 'Last Update',
};

export const updaterColumn = {
  id: 'common.label.updater',
  defaultMessage: 'Updater',
};

export const targetMemberLabel = {
  id: 'common.label.target.member',
  defaultMessage: 'Target Member',
};

export const removeButton = {
  id: 'common.btn.remove',
  defaultMessage: 'Remove',
};

export const typeLabel = {
  id: 'common.label.type',
  defaultMessage: 'Type',
};

export const personalLabel = {
  id: 'common.label.personal',
  defaultMessage: 'Personal',
};

export const departmentsMembersLabel = {
  id: 'common.label.departments.employees',
  defaultMessage: 'Departments / Members',
};

export const taskLabel = {
  id: 'common.label.task',
  defaultMessage: 'Task',
};

export const spentLabel = {
  id: 'common.label.spent',
  defaultMessage: 'Spent',
};

export const jiraProjectsLabel = {
  id: 'common.label.jira.projects',
  defaultMessage: 'JIRA Projects',
};

export const lastUpdateLabel = {
  id: 'common.label.last.update',
  defaultMessage: 'Last Update',
};

export const ofText = {
  id: 'common.text.of',
  defaultMessage: 'of',
};

export const experienceLabel = {
  id: 'common.label.experience',
  defaultMessage: 'Experience',
};

export const positionLabel = {
  id: 'common.label.position',
  defaultMessage: 'Position',
};

export const planColumn = {
  id: 'common.column.plan',
  defaultMessage: 'Plan',
};

export const factColumn = {
  id: 'common.column.fact',
  defaultMessage: 'Fact',
};

export const assigneesLabel = {
  id: 'comon.label.assignees',
  defaultMessage: 'Assignees',
};

export const searchLabel = {
  id: 'comon.label.search',
  defaultMessage: 'Search',
};

export const activateLabel = {
  id: 'common.label.activate',
  defaultMessage: 'Activate',
};

export const deactivateLabel = {
  id: 'common.label.deactivate',
  defaultMessage: 'Deactivate',
};

export const rateLabel = {
  id: 'common.label.rate',
  defaultMessage: 'Rate',
};

export const currencyLabel = {
  id: 'common.label.currency',
  defaultMessage: 'Currency',
};

export const clientLabel = {
  id: 'common.label.client',
  defaultMessage: 'Client',
};

export const okButton = {
  id: 'common.btn.ok',
  defaultMessage: 'Ok',
};

export const eventTypeLabel = {
  id: 'common.label.currency',
  defaultMessage: 'Event Type',
};

export const eventTypesLabel = {
  id: 'common.label.currency',
  defaultMessage: 'Event Types',
};

export const projectLabel = {
  id: 'common.label.project',
  defaultMessage: 'Project',
};

export const projectTypeLabel = {
  id: 'common.label.project.type',
  defaultMessage: 'Project Type',
};

export const plannedLabel = {
  id: 'common.label.project.type',
  defaultMessage: 'Planned',
};

export const fromLabel = {
  id: 'common.label.from',
  defaultMessage: 'From',
};

export const toLabel = {
  id: 'common.label.to',
  defaultMessage: 'To',
};

export const required = {
  id: 'common.validation.required',
  defaultMessage: 'Required',
};

export const hourPriceLabel = {
  id: 'common.label.hour.price',
  defaultMessage: 'Hour price',
};

export const experienceCountLabel = {
  id: 'utils.expereince.count.label.',
  defaultMessage:
    '{hasYear, select, yes {{yearCount}y {monthCount, plural, =0 {} other {{monthCount}m}}} other {{monthCount}m}}',
};

export const moreLabel = {
  id: 'common.label.more',
  defaultMessage: 'more...',
};

export const projectGroupsLabel = {
  id: 'common.label.project.groups',
  defaultMessage: 'Project Groups',
};

export const leftPagePromptMessage = {
  id: 'common.left.page.prompt.message',
  defaultMessage: 'Are you sure you want to leave? Information you`ve entered may not be saved',
};

export const estimateLabel = {
  id: 'common.label.estimate',
  defaultMessage: 'Estimate',
};

export const commentLabel = {
  id: 'common.label.comment',
  defaultMessage: 'Comment',
};

export const expenseTypesLabel = {
  id: 'common.label.expense.types',
  defaultMessage: 'Expense Types',
};
