import { EventCommentInfo } from '../enums/schedule.enum';
import { DaysUsedLimitParams, EventsParams } from './../enums/params/schedule.params';
import { QueryParamsType } from './../utils/params.utils';
import request from '../utils/request.utils';
import { FiltersParams } from '../enums/params.enum';

export function getEventsList(params?: QueryParamsType<EventsParams> | QueryParamsType<FiltersParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: `events`,
    params,
  };
  return request(options);
}

export function getFreeDays(params?: any) {
  const options: RequestConfig = {
    method: 'get',
    url: `freeDays`,
    params,
  };
  return request(options);
}

export function getEvent(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `events/${id}`,
  };
  return request(options);
}

export function getEventComments(eventId: string, params?: any) {
  const options: RequestConfig = {
    method: 'get',
    url: `events/${eventId}/comments`,
    params,
  };
  return request(options);
}

export function createEventComment(eventId: string, data: EventCommentInfo) {
  const options: RequestConfig = {
    method: 'post',
    url: `events/${eventId}/comments`,
    data,
  };
  return request(options);
}

export function editEventComment(uuid: string, data: string, authorUuid: string) {
  const options: RequestConfig = {
    method: 'patch',
    url: `events/comments/${uuid}/${authorUuid}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteEventComment(uuid: string, authorUuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `events/comments/${uuid}/${authorUuid}`,
  };
  return request(options);
}

export function createNewEvent(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/events',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteEvent(id: string, deleteNext?: boolean, sendNotification?: boolean) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/events/${id}`,
    params: {
      sendNotification,
      deleteNext,
    },
  };
  return request(options);
}

export function editEvent(id: string, data: any, updateNext?: boolean) {
  const options: RequestConfig = {
    method: 'put',
    url: `/events/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      updateNext,
    },
    data,
  };
  return request(options);
}

export function getDaysUsedLimit(params?: QueryParamsType<DaysUsedLimitParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: 'absence/info',
    params,
  };
  return request(options);
}

export function editDaysLimit(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `absence/manual/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function createDaysLimit(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: `absence/manual`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}
