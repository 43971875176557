import React, { memo, useCallback, useContext, useMemo } from 'react';
import Question from '../../../components/Questions';
import { questionsNumber } from '../../Polls/utils';
import { UserInfo, UserPreviewInfo } from '../../../enums/users.enum';
import { NewPoll, PollTemplateGroupFieldType, PollTemplateGroupType } from '../../../enums/questionnaires.enum';
import { checkPolicies } from '../../../utils/policies.utils';
import { VIEW_POLL_EXTENDED } from '../../../constants/policies.constants';
import PoliciesContext from '../../../PoliciesContext';
import { useSelector } from 'react-redux';
import { groupBy, isArray, isEmpty } from 'lodash-es';
import { SpecializationInfo } from '../../../enums/libraries.enum';
import { CompanyPositionInfoType } from '../../../types/libraries';

interface IEventPollsContentProps {
  questions: Array<PollTemplateGroupType>;
  pollData: NewPoll;
  participantsAnswers: { user: UserPreviewInfo; answers: any[] }[];
  field: PollTemplateGroupFieldType;
  itemId: string;
  targetEmployee: UserInfo | null;
  questionAuthor: UserPreviewInfo;
  userList: UserInfo[];
  specializations: SpecializationInfo[];
  positions: CompanyPositionInfoType[];
  jiraProjectsList: [];
}

const EventPollContent = ({
  questions,
  pollData,
  participantsAnswers,
  field,
  targetEmployee,
  questionAuthor,
  userList,
  specializations,
  positions,
  jiraProjectsList,
}: IEventPollsContentProps) => {
  const questionsNum = useMemo(() => questionsNumber(questions), [questions]);
  const currentUser = useSelector((state: RootState) => state.auth.currentUserInfo);
  const userPolicies = useContext(PoliciesContext);
  const answers = useCallback(
    (responder: UserPreviewInfo) => {
      if (isArray(participantsAnswers)) {
        return participantsAnswers
          ?.find(participantAnswers => participantAnswers.user.id === responder.id)
          ?.answers?.find((participantAnswer: any) => participantAnswer.fieldId === field.id);
      } else return {};
    },
    [participantsAnswers],
  );

  const sortedAnonymousAnser = () => {
    const groupedAnswer = groupBy(participantsAnswers[0].answers, (el: any) => el.anonymousAnswerId);
    return Object.values(groupedAnswer);
  };

  return !field.isHidden ||
    (checkPolicies([VIEW_POLL_EXTENDED], userPolicies) && currentUser.id !== targetEmployee?.id) ||
    pollData?.responderIds?.some(id => id === currentUser?.id) ? (
    <div className="event-poll__content-question__group">
      <div className="polls__section--question-wrapper">
        <Question
          isAnswer={false}
          editAnswersMode={false}
          responder={questionAuthor}
          externalClass="polls__section--question event-poll--element"
          textQuestion={field.question}
          index={`${field.id}-group`}
          questionsNumber={questionsNum}
        />
      </div>
      {!pollData.anonymous
        ? pollData.responders
            .sort((a, b) => a.secondName?.localeCompare(b.secondName))
            ?.filter((responder: UserPreviewInfo) => participantsAnswers?.some(item => item.user.id === responder.id))
            .map((responder: UserPreviewInfo) => {
              return !field.isHidden ||
                responder.id === currentUser.id ||
                (checkPolicies([VIEW_POLL_EXTENDED], userPolicies) && currentUser.id !== targetEmployee?.id) ? (
                <Question
                  key={responder.id}
                  isAnswer
                  editAnswersMode={false}
                  responder={responder}
                  isAnonymous={pollData?.anonymous}
                  externalClass="polls__section--answer event-poll--element"
                  field={field}
                  selectedValues={answers(responder)}
                  index={`${responder.id}-answer`}
                  status={pollData?.status}
                  isSavingProgress={false}
                  questionsNumber={questionsNum}
                  userList={userList}
                  specializations={specializations}
                  companyPositions={positions}
                  jiraProjectsList={jiraProjectsList}
                />
              ) : (
                <div className="polls__section--answer event-poll--element no-background" />
              );
            })
        : !isEmpty(participantsAnswers) &&
          participantsAnswers[0].answers
            .filter(item => item?.fieldId === field?.id)
            ?.map((answer: any, indexAnswer: number) => (
              <div key={answer?.id}>
                <Question
                  isAnswer
                  editAnswersMode={false}
                  isAnonymous={pollData?.anonymous}
                  externalClass="polls__section--answer event-poll--element"
                  field={field}
                  status={pollData?.status}
                  questionsNumber={questionsNum}
                  selectedValues={sortedAnonymousAnser()[indexAnswer]?.find((el: any) => el.fieldId === field.id)}
                  userList={userList}
                  specializations={specializations}
                  companyPositions={positions}
                  jiraProjectsList={jiraProjectsList}
                />
              </div>
            ))}
      <div className="event-poll--last-element"></div>
    </div>
  ) : null;
};

export default memo(EventPollContent);
