import moment from 'moment';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { DATE_FORMAT } from '../../../../constants/date.constants';
import r from '../../../../constants/routes.constants';
import { EventInfo } from '../../../../enums/schedule.enum';
import { UserInfo } from '../../../../enums/users.enum';
import Dropdown from '../../../Dropdown';
import Icon from '../../../Icon';
import CustomLoader from '../../../Loader';
import Modal from '../../../Modal';
import Avatar from '../../../Profile/Avatar';
import TableUserAvatar from '../../../TableUserAvatar';
import messages from '../../messages';
import EventInfoRow from './EventInfoRow';
import Hint from '../../../Hint';
import { userHintId } from '../../../Hint/utils';

type EventPopUpModalProps = {
  popUpRef: any;
  popupDropdownRef: any;
  event: EventInfo;
  eventPopUpModalClassName: React.CSSProperties;
  isOpen: boolean;
  isLoading: boolean;
  onRequestClose: () => void;
  openEditEventModal: () => void;
  openDeleteEventModal: () => void;
  renderDropdownBtn: (event: EventInfo, isUpdateBtn: boolean) => boolean;
};

const EventPopUpModal = ({
  popUpRef,
  popupDropdownRef,
  event,
  eventPopUpModalClassName,
  isOpen,
  isLoading,
  onRequestClose,
  openDeleteEventModal,
  openEditEventModal,
  renderDropdownBtn,
}: EventPopUpModalProps) => {
  const intl = useIntl();
  const history = useHistory();

  const tableActions = useMemo(() => {
    return [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: () => {
          openEditEventModal();
          onRequestClose();
        },
        handleRenderDropdownItem: (row: EventInfo) => renderDropdownBtn(row, true),
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: () => {
          openDeleteEventModal();
          onRequestClose();
        },
        handleRenderDropdownItem: (row: EventInfo) => renderDropdownBtn(row, false),
      },
    ];
  }, [renderDropdownBtn]);

  const isRenderDropdown = renderDropdownBtn(event, true) || renderDropdownBtn(event, false);

  return (
    <Modal
      modalRef={popUpRef}
      isOpen={isOpen}
      classNameModal="modal__event"
      //@ts-ignore
      modalStyle={{ ...(!isLoading && { '--event-circle-color': event.eventType.color }), ...eventPopUpModalClassName }}
      onRequestClose={onRequestClose}
      title={!isLoading ? <span className="modal__event__title">{event.eventType.name}</span> : <CustomLoader />}
      size="small"
      modalOptions={
        !isLoading && (
          <>
            <button
              className="modal__options-btn"
              onClick={() => history.push(r.eventInformation.replace(':id', `${event.id}`))}
            >
              <Icon iconName="transfer" externalClass="modal__options-icon" />
            </button>
            {isRenderDropdown && (
              <div className="modal__options-btn">
                <Dropdown
                  dropdownClass="dropdown--no-bg"
                  dropdownToggle={<Icon iconName={'dots'} externalClass={'dropdown__button-main-icon'} />}
                  dropdownList={tableActions}
                  dropdownInfo={event}
                  listRef={popupDropdownRef}
                />
              </div>
            )}
          </>
        )
      }
    >
      {!isLoading ? (
        <>
          <div className="modal__event__details">
            <div className="modal__event__date-time-info">
              <div className="modal__event__date">{event.datePeriod}</div>

              {event.showEventTime && <div className="modal__event__time">{event.eventTime}</div>}
              {event.absences.finalDecision && (
                <div className="modal__event__label">
                  <FormattedMessage {...messages.finalDecisionLabel} />
                </div>
              )}
            </div>
            {event?.reminders.length > 0 && (
              <div className="modal__event__reminders-wrapper">
                <FormattedMessage {...messages.remindEventLabel} />
                &nbsp;
                {event?.reminders.map((reminder: any, index: number) => (
                  <span key={index} className="page__event__reminder">{`${
                    reminder.timeValue
                  } ${reminder.unitOfTime.toLowerCase()} ${intl.formatMessage(messages.beforeEventLabel)}`}</span>
                ))}
              </div>
            )}
          </div>
          {event.title && <EventInfoRow name={intl.formatMessage(messages.titleLabel)}>{event.title}</EventInfoRow>}
          {event.locations.length > 0 && (
            <EventInfoRow name={intl.formatMessage(messages.officeLabel)}>
              {event.locations.map(office => (
                <span className="page__event__town" key={office.id}>
                  {office.name}
                </span>
              ))}
            </EventInfoRow>
          )}
          {event.address && (
            <EventInfoRow name={intl.formatMessage(messages.addressLabel)}>{event.address}</EventInfoRow>
          )}
          {event.targetEmployee && (
            <EventInfoRow name={intl.formatMessage(messages.targetMemberLabel)}>
              {event.targetEmployee && (
                <>
                  <Avatar userInfo={event.targetEmployee} size="tiny" isUserPageLink fileSize={36} />
                  <span>{event.targetEmployee.fullName}</span>
                </>
              )}
            </EventInfoRow>
          )}
          {event.description && (
            <EventInfoRow name={intl.formatMessage(messages.descriptionLabel)}>{event.description}</EventInfoRow>
          )}
          {event.participants.length > 0 && <TableUserAvatar fileSize={48} users={event.participants} isUserPageLink />}
          {event.absences.approve && (
            <div className="modal__event__status">
              <div className="modal__event__status-value">
                <FormattedMessage {...messages.approvedLabel} />
              </div>
              <div className="modal__event__status-responsible">
                <TableUserAvatar users={[new UserInfo(event.absences.approvedBy)]} isUserPageLink fileSize={36} />
              </div>
              <div className="modal__event__status-date">
                {moment(event.absences.approvedByDate).format(DATE_FORMAT.ll)}
              </div>
            </div>
          )}
        </>
      ) : null}
      <Hint customId={userHintId} className="table__user-name tooltip" dependencyList={event.participants} />
    </Modal>
  );
};

export default EventPopUpModal;
