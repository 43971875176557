import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/questionnaires.constants';
import { RejectValueErrors } from '../enums/error.enum';
import { getReducerErrors } from '../utils/reducerError.utils';
import * as paramsTypes from '../enums/params/questionnaires.params';
import { NewPoll, PollTemplate } from '../enums/questionnaires.enum';
import { notStartedStatusValue } from '../pages/Polls/utils';

type InitialStateType = {
  pollTemplatesCategoryTableData: any;
  pollsTableData: any;
  myPollsTableData: any;
  pollTemplatesTableData: any;
  pollTemplatesCategoryParams: paramsTypes.PollTemplatesCategoryParams;
  pollTemplatesParams: paramsTypes.PollTemplatesParams;
  pollParams: paramsTypes.PollParams;
  myPollsParams: paramsTypes.MyPollsParams;
  currentTemplateCategory: any;
  currentTemplate: any;
  currentPollData: any;
  answersByPollIdAdmin: any;
  usersByOffices: any;
  officeIds: any;
  isOpenConfirmForSubmitAnswers: boolean;
  responderIds: any;
  answersByPollAndUser: any;
  pollAnswersByPollId: any;
  countNotAnsweredPolls: any;
  isSavingProgress: boolean;
  userPollStatus: string;
  submitted: boolean;
  eventPolls: any[];
  isFromMyPolls: boolean;
  errors: {
    pollsError: string | null;
    createError: string | null;
    editError: string | null;
    getCurrentPollError: string | null;
    pollTemplatesCategoryListError: string | null;
    pollTemplatesCategoryError: string | RejectValueErrors[] | null;
    pollTemplatesError: string | RejectValueErrors[] | null;
    pollTemplatesListError: string | null;
    getAnswersByPollIdAdminError: string | null;
    getUsersByOfficesError: string | null;
    myPollsError: string | null;
    answersByPollAndUserError: string | null;
    updateOwnAnswersError: string | null;
  };
  loading: {
    getPollTemplatesCategories: boolean;
    createPollTemplatesCategory: boolean;
    editPollTemplatesCategory: boolean;
    deletePollTemplatesCategory: boolean;
    getPollTemplates: boolean;
    createPollTemplate: boolean;
    editPollTemplate: boolean;
    deletePollTemplate: boolean;
    getCurrentPollTemplate: boolean;
    getPollsTableData: boolean;
    deletePoll: boolean;
    getMyPollsListData: boolean;
    editPoll: boolean;
    createPoll: boolean;
    getCurrentPollLoading: boolean;
    getAnswersByPollIdAdminLoading: boolean;
    getUsersByOfficesLoading: boolean;
    answersByPollAndUserLoading: boolean;
    isLoadingSavingAnswers: boolean;
  };
};

const initialState: InitialStateType = {
  pollTemplatesCategoryTableData: null,
  pollTemplatesTableData: null,
  pollsTableData: [],
  myPollsTableData: [],
  currentPollData: {},
  currentTemplateCategory: null,
  currentTemplate: null,
  pollTemplatesCategoryParams: new paramsTypes.PollTemplatesCategoryParams(),
  pollTemplatesParams: new paramsTypes.PollTemplatesParams(),
  pollParams: new paramsTypes.PollParams(),
  myPollsParams: new paramsTypes.MyPollsParams(),
  isOpenConfirmForSubmitAnswers: false,
  answersByPollIdAdmin: [],
  pollAnswersByPollId: {},
  usersByOffices: [],
  officeIds: [],
  responderIds: [],
  answersByPollAndUser: [],
  countNotAnsweredPolls: 0,
  isSavingProgress: false,
  userPollStatus: notStartedStatusValue,
  submitted: false,

  eventPolls: [],
  isFromMyPolls: true,
  errors: {
    pollTemplatesCategoryListError: null,
    pollTemplatesCategoryError: null,
    pollTemplatesError: null,
    pollTemplatesListError: null,
    pollsError: null,
    myPollsError: null,
    createError: null,
    editError: null,
    getCurrentPollError: null,
    getAnswersByPollIdAdminError: null,
    getUsersByOfficesError: null,
    answersByPollAndUserError: null,
    updateOwnAnswersError: null,
  },
  loading: {
    getPollTemplatesCategories: false,
    createPollTemplatesCategory: false,
    editPollTemplatesCategory: false,
    deletePollTemplatesCategory: false,
    getPollTemplates: false,
    createPollTemplate: false,
    editPollTemplate: false,
    deletePollTemplate: false,
    getCurrentPollTemplate: false,
    getPollsTableData: false,
    getMyPollsListData: false,
    deletePoll: false,
    editPoll: false,
    createPoll: false,
    getCurrentPollLoading: false,
    getAnswersByPollIdAdminLoading: false,
    getUsersByOfficesLoading: false,
    answersByPollAndUserLoading: false,
    isLoadingSavingAnswers: false,
  },
};

const questionnairesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_POLL_TEMPLATES_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getPollTemplatesCategories: true },
      };

    case ActionTypes.GET_POLL_TEMPLATES_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        pollTemplatesCategoryTableData: action.payload,
        errors: { ...state.errors, pollTemplatesCategoryListError: null },
        loading: { ...state.loading, getPollTemplatesCategories: false },
      };

    case ActionTypes.GET_POLL_TEMPLATES_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesCategoryListError: action.payload.message },
        loading: { ...state.loading, getPollTemplatesCategories: false },
      };

    case ActionTypes.SET_POLL_TEMPLATES_CATEGORY_PARAMS_REQUEST:
      return {
        ...state,
        pollTemplatesCategoryParams: new paramsTypes.PollTemplatesCategoryParams({
          ...state.pollTemplatesCategoryParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getPollTemplatesCategories: true },
      };

    case ActionTypes.GET_MY_POLLS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, myPollsError: action.payload.message },
        loading: { ...state.loading, getMyPollsListData: false },
      };

    case ActionTypes.GET_MY_POLLS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getMyPollsListData: true },
      };

    case ActionTypes.GET_MY_POLLS_LIST_SUCCESS:
      return {
        ...state,
        myPollsTableData: {
          ...action.payload,
          content: action.payload?.content.map((el: any) => new NewPoll(el)),
        },
        errors: { ...state.errors, myPollsError: null },
        loading: { ...state.loading, getMyPollsListData: false },
      };

    case ActionTypes.SET_MY_POLLS_PARAMS:
      return {
        ...state,
        myPollsParams: new paramsTypes.MyPollsParams({ ...state.myPollsParams, ...action.payload }),
        loading: { ...state.loading, getMyPollsListData: true },
      };

    case ActionTypes.POST_POLL_TEMPLATES_CATEGORY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createPollTemplatesCategory: true },
      };

    case ActionTypes.POST_POLL_TEMPLATES_CATEGORY_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesCategoryError: null },
        loading: { ...state.loading, createPollTemplatesCategory: false },
      };

    case ActionTypes.POST_POLL_TEMPLATES_CATEGORY_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          pollTemplatesCategoryError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createPollTemplatesCategory: false },
      };

    case ActionTypes.DELETE_POLL_TEMPLATES_CATEGORY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deletePollTemplatesCategory: true },
      };

    case ActionTypes.DELETE_POLL_TEMPLATES_CATEGORY_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesCategoryError: null },
        loading: { ...state.loading, deletePollTemplatesCategory: false },
      };

    case ActionTypes.DELETE_POLL_TEMPLATES_CATEGORY_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesCategoryError: action.payload.message },
        loading: { ...state.loading, deletePollTemplatesCategory: false },
      };

    case ActionTypes.PUT_POLL_TEMPLATES_CATEGORY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editPollTemplatesCategory: true },
      };

    case ActionTypes.PUT_POLL_TEMPLATES_CATEGORY_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesCategoryError: null },
        loading: { ...state.loading, editPollTemplatesCategory: false },
      };

    case ActionTypes.PUT_POLL_TEMPLATES_CATEGORY_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          pollTemplatesCategoryError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editPollTemplatesCategory: false },
      };

    case ActionTypes.GET_POLL_TEMPLATES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getPollTemplates: true },
      };

    case ActionTypes.GET_POLL_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        pollTemplatesTableData: {
          ...action.payload,
          content: action.payload.content.map((template: unknown) => new PollTemplate(template)),
        },
        errors: { ...state.errors, pollTemplatesListError: null },
        loading: { ...state.loading, getPollTemplates: false },
      };

    case ActionTypes.GET_POLL_TEMPLATES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesListError: action.payload.message },
        loading: { ...state.loading, getPollTemplates: false },
      };

    case ActionTypes.POST_POLL_TEMPLATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesListError: null },
        loading: { ...state.loading, createPollTemplate: false },
      };

    case ActionTypes.POST_POLL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createPollTemplate: true },
      };

    case ActionTypes.POST_POLL_TEMPLATE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          pollTemplatesError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createPollTemplate: false },
      };

    case ActionTypes.DELETE_POLL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deletePollTemplate: true },
      };

    case ActionTypes.DELETE_POLL_TEMPLATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesError: null },
        loading: { ...state.loading, deletePollTemplate: false },
      };

    case ActionTypes.DELETE_POLL_TEMPLATE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesError: action.payload.message },
        loading: { ...state.loading, deletePollTemplate: false },
      };

    case ActionTypes.PUT_POLL_TEMPLATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesError: null },
        loading: { ...state.loading, editPollTemplate: false },
      };

    case ActionTypes.PUT_POLL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editPollTemplate: true },
      };

    case ActionTypes.PUT_POLL_TEMPLATE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          pollTemplatesError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editPollTemplate: false },
      };

    case ActionTypes.SET_POLL_TEMPLATES_PARAMS_REQUEST:
      return {
        ...state,
        pollTemplatesParams: new paramsTypes.PollTemplatesParams({ ...state.pollTemplatesParams, ...action.payload }),
        loading: { ...state.loading, getPollTemplates: true },
      };

    case ActionTypes.SET_POLL_PARAMS:
      return {
        ...state,
        pollParams: new paramsTypes.PollParams({ ...state.pollParams, ...action.payload }),
        loading: { ...state.loading, getPollsTableData: true },
      };

    case ActionTypes.SET_IS_RESULT_SUBMIT:
      return {
        ...state,
        isSavingProgress: !action.payload,
      };

    case ActionTypes.GET_ANSWERS_BY_POLL_ID_ADMIN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getAnswersByPollIdLoading: true },
      };

    case ActionTypes.GET_ANSWERS_BY_POLL_ID_ADMIN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAnswersByPollIdAdminLoading: false },
        errors: { ...state.errors, getAnswersByPollIdAdminError: null },
        answersByPollIdAdmin: action.payload.results,
        pollAnswersByPollId: { ...state.pollAnswersByPollId, [action.payload.id]: action.payload.results },
      };

    case ActionTypes.GET_ANSWERS_BY_POLL_ID_ADMIN_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, getAnswersByPollIdAdminError: action.payload.message },
        loading: { ...state.loading, getAnswersByPollIdAdminLoading: false },
      };

    case ActionTypes.GET_USERS_BY_OFFICES_REQUEST: {
      return {
        ...state,
        errors: { ...state.errors, getUsersByOfficesError: null },
        loading: { ...state.loading, getUsersByOfficesLoading: true },
        officeIds: action.payload,
      };
    }

    case ActionTypes.GET_USERS_BY_OFFICES_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, getUsersByOfficesError: action.payload.message },
        loading: { ...state.loading, getUsersByOfficesLoading: false },
      };

    case ActionTypes.GET_USERS_BY_OFFICES_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, getUsersByOfficesError: null },
        loading: { ...state.loading, getUsersByOfficesLoading: false },
        usersByOffices: action.payload,
      };

    case ActionTypes.GET_ANSWERS_BY_POLL_AND_USER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, answersByPollAndUserLoading: true },
      };

    case ActionTypes.GET_ANSWERS_BY_POLL_AND_USER_SUCCESS:
      return {
        ...state,
        answersByPollAndUser: action.payload,
        errors: { ...state.errors, answersByPollAndUserError: null },
        loading: { ...state.loading, answersByPollAndUserLoading: false },
        userPollStatus: action.payload[0]?.status,
      };

    case ActionTypes.GET_ANSWERS_BY_POLL_AND_USER_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, answersByPollAndUserError: action.payload.message },
        loading: { ...state.loading, answersByPollAndUserLoading: false },
      };

    case ActionTypes.OPEN_CONFIRMATION_MODAL_FOR_OWN_ANSWERS:
      return {
        ...state,
        isOpenConfirmForSubmitAnswers: true,
      };

    case ActionTypes.CLOSE_CONFIRMATION_MODAL_FOR_OWN_ANSWERS:
      return {
        ...state,
        isOpenConfirmForSubmitAnswers: false,
      };

    case ActionTypes.UPDATE_OWN_POLL_ANSWERS_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, updateOwnAnswersError: null },
        loading: { ...state.loading, isLoadingSavingAnswers: false },
        userPollStatus: action.payload[0]?.status,
        submitted: true,
        isOpenConfirmForSubmitAnswers: false,
      };

    case ActionTypes.UPDATE_OWN_POLL_ANSWERS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, isLoadingSavingAnswers: true },
        isOpenConfirmForSubmitAnswers: false,
      };

    case ActionTypes.UPDATE_OWN_POLL_ANSWERS_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, updateOwnAnswersError: getReducerErrors(action.payload) },
        submitted: false,
      };

    case ActionTypes.PUT_ANSWERS_ADMIN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, isLoadingSavingAnswers: false },
      };

    case ActionTypes.PUT_ANSWERS_ADMIN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, isLoadingSavingAnswers: true },
      };

    case ActionTypes.GET_NOT_ANSWERED_POLLS_SUCCESS:
      return {
        ...state,
        countNotAnsweredPolls: action.payload,
        loading: { ...state.loading, getCountNotAnswererPollLoading: false },
      };

    case ActionTypes.GET_POLLS_LIST_SUCCESS:
      return {
        ...state,
        pollsTableData: {
          ...action.payload,
          content: action.payload?.content?.map((el: any) => new NewPoll(el)),
        },
        errors: { ...state.errors, pollsError: null },
        loading: { ...state.loading, getPollsTableData: false },
      };

    case ActionTypes.GET_POLLS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getPollsTableData: true },
      };

    case ActionTypes.GET_POLLS_LIST_FAILURE: {
      return {
        ...state,
        errors: { ...state.errors, pollError: action.payload.message },
        loading: { ...state.loading, getPollsTableData: false },
      };
    }

    case ActionTypes.GET_CURRENT_POLL_REQUEST: {
      return {
        ...state,
        loading: { ...state.loading, getCurrentPollLoading: true },
      };
    }

    case ActionTypes.GET_CURRENT_POLL_SUCCESS: {
      return {
        ...state,
        currentPollData: new NewPoll(action.payload),
        eventPolls: [...state.eventPolls.filter(poll => poll?.id !== action.payload.id), action.payload],
        loading: { ...state.loading, getCurrentPollLoading: false },
        errors: { ...state.errors, getCurrentPollError: null },
      };
    }

    case ActionTypes.GET_CURRENT_POLL_FAILURE: {
      return {
        ...state,
        loading: { ...state.loading, getCurrentPoll: false },
        errors: { ...state.errors, getCurrentPollError: action.payload.message },
      };
    }

    case ActionTypes.DELETE_POLL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deletePoll: true },
      };

    case ActionTypes.DELETE_POLL_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deletePoll: false },
      };

    case ActionTypes.PATCH_POLL_REQUEST: {
      return {
        ...state,
        loading: { ...state.loading, editPoll: true },
      };
    }

    case ActionTypes.PATCH_POLL_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, editPoll: false },
        errors: { ...state.errors, editError: null },
      };
    }

    case ActionTypes.POST_NEW_POLL_REQUEST: {
      return {
        ...state,
        loading: { ...state.loading, createPoll: true },
      };
    }

    case ActionTypes.POST_NEW_POLL_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, createPoll: false },
        errors: { ...state.errors, createError: null },
      };
    }

    case ActionTypes.POST_NEW_POLL_FAILURE: {
      return {
        ...state,
        errors: { ...state.errors, createError: action.payload.message },
        loading: { ...state.loading, createPoll: false },
      };
    }

    case ActionTypes.PATCH_POLL_FAILURE: {
      return {
        ...state,
        errors: { ...state.errors, editError: action.payload.message },
        loading: { ...state.loading, editPoll: false },
      };
    }

    case ActionTypes.COPY_TEMPLATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesError: null },
      };

    case ActionTypes.COPY_TEMPLATE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesError: action.payload.message },
      };

    case ActionTypes.CHANGE_TEMPLATE_CATEGORY_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesError: null },
      };

    case ActionTypes.CHANGE_TEMPLATE_CATEGORY_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          pollTemplatesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.SET_CURRENT_TEMPLATE_CATEGORY:
      return {
        ...state,
        currentTemplateCategory: action.payload,
      };

    case ActionTypes.SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.payload,
      };

    case ActionTypes.GET_CURRENT_POLL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCurrentPollTemplate: true },
      };

    case ActionTypes.GET_CURRENT_POLL_TEMPLATE_SUCCESS:
      return {
        ...state,
        currentTemplate: action.payload,
        errors: { ...state.errors, pollTemplatesError: null },
        loading: { ...state.loading, getCurrentPollTemplate: false },
      };

    case ActionTypes.GET_CURRENT_POLL_TEMPLATE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, pollTemplatesError: action.payload.message },
        loading: { ...state.loading, getCurrentPollTemplate: false },
      };

    case ActionTypes.RESET_QUESTIONNARIES_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_QUESTIONNARIES_STATE:
      return {
        ...initialState,
        countNotAnsweredPolls: state.countNotAnsweredPolls,
        pollParams: state.pollParams,
        myPollsParams: state.myPollsParams,
        pollTemplatesParams: state.pollTemplatesParams,
      };

    case ActionTypes.RESET_EVENT_POLLS: {
      return {
        ...state,
        eventPolls: [],
        pollAnswersByPollId: {},
      };
    }

    case ActionTypes.RESET_EXPORT_POLL_DATA: {
      return {
        ...state,
        answersExported: false,
        exportedData: null,
        exportRequestValues: {},
      };
    }

    case ActionTypes.SET_IS_FROM_MY_POLLS:
      return {
        ...state,
        isFromMyPolls: action.payload,
      };

    default:
      return state;
  }
};

export default questionnairesReducer;
