import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as resumesActions from '../../../actions/resumes.actions';
import * as filterActions from '../../../actions/filters.actions';
import * as usersActions from '../../../actions/users.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ModalEditResume from '../../../components/ResumesManagement/Modals/ModalEditResume';
import Avatar from '../../../components/Profile/Avatar';
import Label from '../../../components/Label';
import Dropdown from '../../../components/Dropdown';
import CustomLoader from '../../../components/Loader';
import ModalExportResumes from '../../../components/ResumesManagement/Modals/ModalExportResumes';
import CVDocument from '../../../components/ResumesManagement/CVDocument/CVDocument';
import { checkPolicies } from '../../../utils/policies.utils';
import { UPDATE_RESUME } from '../../../constants/policies.constants';
import PoliciesContext from '../../../PoliciesContext';
import { convertDateTimeToUtc } from '../../../enums/common.enum';
import r from '../../../constants/routes.constants';

function ResumeInfo({
  editUserResume,
  errors,
  loading,
  languageLevelsList,
  foreignLanguagesList,
  technicalSkillList,
  projectList,
  companyPositionList,
  currentResume,
  getResume,
  getCompanyPositionsFilter,
  getProjectsFilter,
  getTechnicalSkillsFilter,
  getForeignLanguagesFilter,
  getLanguageLevelsFilter,
  getUserPersonalInfo,
  userPersonalInfo,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const intl = useIntl();

  const [modalEditResumeIsOpen, setModalEditResumeIsOpen] = useState(false);
  const [modalExportResumesIsOpen, setModalExportResumesIsOpen] = useState(false);

  const backButtonHandler = useCallback(() => {
    history.push(r.resumesManagement);
  }, []);

  useEffect(() => {
    getResume(id);
  }, [id]);

  const updateUserResume = (resumes: { id: string; data: FormData }[]) => {
    editUserResume(resumes, () => {
      getResume(id);
      setModalEditResumeIsOpen(false);
      setModalExportResumesIsOpen(false);
    });
  };

  return (
    <>
      <div className="page__profile-panel page__cv_title">
        <div className="page__panel__wrapper fixed-container">
          <div className="page__panel__back-title">
            <Button externalClass="page__back" color="gray" onClick={backButtonHandler}>
              <FormattedMessage {...messages.pageTitle} />
            </Button>
          </div>
          <div className="page__profile-panel__label-options">
            {currentResume && (
              <>
                <div className="page__cv-updated">
                  <div className="page__cv-updated-date">{convertDateTimeToUtc(currentResume?.lastUpdate)}</div>
                  <div className="page__cv-responsible">
                    {currentResume && (
                      <Avatar
                        userInfo={currentResume?.updater}
                        externalClass="page__cv-responsible-avatar"
                        size="tiny"
                        isUserPageLink
                        fileSize={36}
                      />
                    )}
                    <span>{currentResume?.updater.fullName}</span>
                  </div>
                </div>
                <div className="page__profile-panel__label-info">
                  <Label type={currentResume?.approvedStatus ? 'approved' : 'final-decision'} size="bigger">
                    {currentResume?.approvedStatus ? 'APPROVED' : 'NOT APPROVED'}
                  </Label>
                </div>
                <Dropdown
                  dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                  dropdownList={[
                    ...(checkPolicies([UPDATE_RESUME], policies)
                      ? [
                          {
                            label: intl.formatMessage(messages.editButton),
                            handler: () => setModalEditResumeIsOpen(true),
                          },
                        ]
                      : []),
                    {
                      label: intl.formatMessage(messages.exportToPDFLabel),
                      handler: () => setModalExportResumesIsOpen(true),
                    },
                  ]}
                />
              </>
            )}
          </div>
        </div>
        <div className="page__panel__wrapper fixed-container title-wratter">
          <h1 className="page__title page__title--smaller">
            <FormattedMessage {...messages.infoPageTitle} />
          </h1>
        </div>
      </div>
      <div className="page__inner-content  page__cv_content">
        <div className="page__wrapper fixed-container">
          <div className="error_wrapper">
            {loading.getResume && <CustomLoader />}
            <div className="error_block">{!loading.getResume && errors.getResume}</div>
          </div>
          {!loading.getResume && currentResume && (
            <CVDocument
              resume={currentResume}
              displayingResumeSections={{
                includeSummary: !!(currentResume.summary !== '<p><br></p>' && currentResume.summary),
                includePersonalHighlights: !!(
                  currentResume.personalHighlights !== '<p><br></p>' && currentResume.personalHighlights
                ),
                includeInterestsAndHobbies: !!(
                  currentResume.interestsAndHobbies !== '<p><br></p>' && currentResume.interestsAndHobbies
                ),
                includeProjects: !!currentResume.projects.length,
              }}
            />
          )}
        </div>
      </div>
      {modalEditResumeIsOpen && currentResume && (
        <ModalEditResume
          isOpen
          onCloseRequest={() => setModalEditResumeIsOpen(false)}
          updateResume={updateUserResume}
          currentResume={currentResume}
          resumeError={errors.editResumeError}
          isLoading={loading.editResume}
          languageLevels={languageLevelsList}
          foreignLanguages={foreignLanguagesList}
          technicalSkills={technicalSkillList}
          projectsList={projectList}
          getForeignLanguagesFilter={getForeignLanguagesFilter}
          getProjectsFilter={getProjectsFilter}
          getTechnicalSkillsFilter={getTechnicalSkillsFilter}
          getLanguageLevelsFilter={getLanguageLevelsFilter}
          getUserPersonalInfo={getUserPersonalInfo}
          userPersonalInfo={userPersonalInfo}
        />
      )}
      {modalExportResumesIsOpen && currentResume && (
        <ModalExportResumes
          isOpen
          onCloseRequest={() => setModalExportResumesIsOpen(false)}
          updateResume={updateUserResume}
          resumeError={errors.editResumeError}
          isLoading={loading.editResume}
          resumesList={[currentResume]}
          positions={companyPositionList}
          languageLevels={languageLevelsList}
          foreignLanguages={foreignLanguagesList}
          technicalSkills={technicalSkillList}
          projectsList={projectList}
          getForeignLanguagesFilter={getForeignLanguagesFilter}
          getProjectsFilter={getProjectsFilter}
          getTechnicalSkillsFilter={getTechnicalSkillsFilter}
          getLanguageLevelsFilter={getLanguageLevelsFilter}
          getCompanyPositionsFilter={getCompanyPositionsFilter}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ resumes, filters, users }: RootState) => ({
  currentResume: resumes.currentResume,
  loading: resumes.loading,
  errors: resumes.errors,
  languageLevelsList: filters.languageLevelsFilter.levels,
  foreignLanguagesList: filters.foreignLanguagesFilter.languages,
  technicalSkillList: filters.technicalSkillsFilter.skills,
  projectList: filters.projectsFilter.projects,
  companyPositionList: filters.companyPositionsFilter.positions,
  userPersonalInfo: users.current.personal,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  editUserResume: (resumes: { id: string; data: FormData }[], callback: () => void) =>
    dispatch(resumesActions.editUserResume({ resumes, callback })),
  getLanguageLevelsFilter: () => dispatch(filterActions.getLanguageLevelsFilter()),
  getForeignLanguagesFilter: () => dispatch(filterActions.getForeignLanguagesFilter()),
  getTechnicalSkillsFilter: () => dispatch(filterActions.getTechnicalSkillsFilter()),
  getProjectsFilter: () => dispatch(filterActions.getProjectsFilter()),
  getCompanyPositionsFilter: () => dispatch(filterActions.getCompanyPositionsFilter()),
  getResume: (uuid: string) => dispatch(resumesActions.getResume(uuid)),
  getUserPersonalInfo: (uuid: string) => dispatch(usersActions.getUserPersonalInfo(uuid)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ResumeInfo);
