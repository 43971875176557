import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useFiltersListValue, useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { Candidate, CANDIDATE_VALIDATION_SCHEMA } from '../../../enums/candidates.enums';
import Input from '../../Input';
import Select, { CreatableOptionTypeBase } from '../../Select';
import * as candidateTypes from '../../../types/candidates';
import { useModalOptions } from '../useModalsData';
import ChipInput from '../../ChipInput';
import Checkbox from '../../Checkbox';
import { OptionTypeBase } from 'react-select';
import { scrollToError } from '../../../utils';

type ModalNewCandidateProps = {
  isOpen: boolean;
  isLoading: boolean;
  error: string | RejectValueErrors[] | null;
  candidateTechnologies: candidateTypes.CandidateTechnologyType[];
  candidateSpecializations: candidateTypes.CandidateSpecializationType[];
  candidateReceivingSources: candidateTypes.CandidateReceivingSourceType[];
  createCandidate: (data: { data: Candidate; callback: () => void }) => void;
  onCloseRequest: () => void;
};

function ModalNewCandidate({
  isOpen,
  isLoading,
  error,
  candidateTechnologies,
  candidateSpecializations,
  candidateReceivingSources,
  createCandidate,
  onCloseRequest,
}: ModalNewCandidateProps) {
  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    resetForm,
    setFieldError,
  } = useFormik<Candidate>({
    initialValues: new Candidate(),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: CANDIDATE_VALIDATION_SCHEMA,
    onSubmit: data => {
      createCandidate({
        data,
        callback: resetAndExit,
      });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const {
    candidateTechnologiesOptions,
    candidateSpecializationsOptions,
    candidateReceivingSourcesOptions,
    monthOptions,
    yearOptions,
  } = useModalOptions([], candidateTechnologies, candidateSpecializations, candidateReceivingSources, values.year);

  const emailsValue = useMemo(() => values.emails.map(email => ({ label: email, value: email })), [values]);

  const phonesValue = useMemo(() => values.phones.map(phone => ({ label: phone, value: phone })), [values.phones]);

  const receivingSourceValue = useMemo(
    () => candidateReceivingSourcesOptions.find(opt => opt.value === values.receivingSourceId) || null,
    [values.receivingSourceId],
  );

  const yearValue = useFiltersListValue(yearOptions, [values.year]);

  const monthValue = useFiltersListValue(monthOptions, [values.month]);

  const handleEmailsChange = useCallback(newValues => {
    setFieldValue(
      'emails',
      newValues.map(({ label }: { label: string }) => label),
    );
  }, []);

  const handlePhonesChange = useCallback(newValues => {
    setFieldValue(
      'phones',
      newValues.map(({ label }: { label: string }) => label),
    );
  }, []);

  const handleYearChange = useCallback(
    ({ value }: { value: number }) => {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      if (value == year && Number(values.month) > month) {
        setFieldValue('month', '');
      }
      setFieldValue('year', value);
    },
    [values.month],
  );

  const handleMonthChange = useCallback(({ value }: { value: number }) => {
    setFieldValue('month', value);
  }, []);

  const handleSpecializationsChange = useCallback((newValues: CreatableOptionTypeBase[]) => {
    setFieldValue(
      'specializations',
      newValues.map(({ value, label, __isNew__ }) => ({ name: label, id: __isNew__ ? null : value })),
    );
  }, []);

  const handleTechnologiesChange = useCallback((newValues: CreatableOptionTypeBase[]) => {
    setFieldValue(
      'technologies',
      newValues.map(({ value, label, __isNew__ }) => ({ name: label, id: __isNew__ ? null : value })),
    );
  }, []);

  const handleReceivingSourceChange = useCallback((newValue: OptionTypeBase) => {
    setFieldValue('receivingSourceId', newValue.value);
  }, []);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const emailsError = useMemo(() => (errors?.emails as string[])?.reduce((acc, val) => acc || val, ''), [
    errors?.emails,
  ]);

  const phonesError = useMemo(() => (errors?.phones as string[])?.reduce((acc, val) => acc || val, ''), [
    errors?.phones,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.newCandidateTitle)}
      classNameModal="center flex"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__group">
            <div className="form__inputs-subwrapper">
              <Input
                id="firstName"
                name="firstName"
                label={intl.formatMessage(messages.firstNameLabel)}
                maxLength={20}
                onChange={handleChange}
                hasError={hasError('firstName')}
                errorMessage={errors.firstName}
              />
              <Input
                id="lastName"
                name="lastName"
                label={intl.formatMessage(messages.secondNameLabel)}
                maxLength={30}
                onChange={handleChange}
                hasError={hasError('lastName')}
                errorMessage={errors.lastName}
              />
            </div>
            <Input
              id="alternativeName"
              name="alternativeName"
              label={intl.formatMessage(messages.alternativeNameLabel)}
              onChange={handleChange}
              hasError={hasError('alternativeName')}
              errorMessage={errors.alternativeName}
            />
          </div>
          <div className="form__group">
            <Input
              id="location"
              name="location"
              iconName="map-marker"
              label={intl.formatMessage(messages.locationLabel)}
              onChange={handleChange}
              hasError={hasError('location')}
              errorMessage={errors.location}
            />
          </div>
          <div className="form__group">
            <ChipInput
              label={intl.formatMessage(messages.emailsLabel)}
              iconName="envelope"
              value={emailsValue}
              handleChange={handleEmailsChange}
              errorMessage={emailsError}
              hasError={hasError('emails')}
            />
            <ChipInput
              label={intl.formatMessage(messages.phonesLabel)}
              iconName="phone"
              externalClass="page__profile-contact--phones"
              value={phonesValue}
              handleChange={handlePhonesChange}
              errorMessage={phonesError}
              hasError={hasError('phones')}
            />
          </div>
          <div className="form__group">
            <div className="form__note form__input-block">
              <FormattedMessage {...messages.careerStartLabel} />
            </div>
            <div className="form__inputs-subwrapper">
              <Select
                isClearable
                label={intl.formatMessage(messages.yearLabel)}
                options={yearOptions}
                value={yearValue}
                handleChange={handleYearChange}
                hasError={hasError('carrierStart') || hasError('year')}
                errorMessage={errors.carrierStart || errors.year}
              />
              <Select
                isClearable
                label={intl.formatMessage(messages.monthLabel)}
                options={monthOptions}
                value={monthValue}
                handleChange={handleMonthChange}
                hasError={hasError('carrierStart') || hasError('month')}
                errorMessage={errors.carrierStart || errors.month}
              />
              <div className="form__input-block align-center">
                <Checkbox
                  label={intl.formatMessage(messages.leadExpLabel)}
                  id="hasLeadExperience"
                  onChange={handleChange}
                  externalClass="form__checkbox-capitalazie-label"
                />
              </div>
            </div>
          </div>
          <div className="form__group">
            <Select
              isCreatable
              isMulti
              label={intl.formatMessage(messages.specializationsLabel)}
              options={candidateSpecializationsOptions}
              handleChange={handleSpecializationsChange}
              hasError={hasError('specializations')}
              //@ts-ignore
              errorMessage={errors.specializations}
            />
            <Select
              isCreatable
              isMulti
              label={intl.formatMessage(messages.technologiesLabel)}
              options={candidateTechnologiesOptions}
              handleChange={handleTechnologiesChange}
              hasError={hasError('technologies')}
              //@ts-ignore
              errorMessage={errors.technologies}
            />
          </div>
          <div className="form__group">
            <Input
              label={intl.formatMessage(messages.previousEmploymentLabel)}
              id="previousEmployments"
              name="previousEmployments"
              tag="textarea"
              onChange={handleChange}
              hasError={hasError('previousEmployments')}
              errorMessage={errors.previousEmployments}
            />
          </div>
          <div className="form__group">
            <Input
              label={intl.formatMessage(messages.linksLabel)}
              id="links"
              name="links"
              tag="textarea"
              onChange={handleChange}
              hasError={hasError('links')}
              errorMessage={errors.links}
            />
          </div>
          <div className="form__group">
            <Select
              isClearable
              label={intl.formatMessage(messages.receivingSourceLabel)}
              options={candidateReceivingSourcesOptions}
              value={receivingSourceValue}
              handleChange={handleReceivingSourceChange}
              hasError={hasError('receivingSourceId')}
              errorMessage={errors.receivingSourceId}
            />
          </div>
          <Input
            label={intl.formatMessage(messages.commentsLabel)}
            id="message"
            name="message"
            tag="textarea"
            onChange={handleChange}
            hasError={hasError('message')}
            errorMessage={errors.message}
          />
        </div>

        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewCandidate;
