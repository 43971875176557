import { useFormik } from 'formik';
import { get, isString } from 'lodash-es';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import Input from '../../Input';
import Modal from '../../Modal';
import messages from '../messages';
import { ResourceLink, RESOURCE_LINK_SCHEMA } from '../../../enums/branding.enum';
import FileInput from '../../FileInput';
import { createFormData } from '../../../utils/data.utils';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { RejectValueErrors } from '../../../enums/error.enum';

type ModalNewMenuLinkProps = {
  onCloseRequest: () => void;
  createNewMenuLink: (data: any) => void;
  resetErrors: () => void;
  isOpen: boolean;
  menuLinkError: null | string | RejectValueErrors[];
  isLoading: boolean;
  defaultPosition: number;
};

function ModalNewMenuLink({
  isOpen,
  defaultPosition,
  menuLinkError,
  isLoading,
  onCloseRequest,
  createNewMenuLink,
  resetErrors,
}: ModalNewMenuLinkProps) {
  const intl = useIntl();
  const fileRef = useRef(null);
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError, resetForm } = useFormik({
    initialValues: {
      file: null,
      dto: new ResourceLink(null, defaultPosition),
    },
    validationSchema: RESOURCE_LINK_SCHEMA,
    validateOnChange: false,
    onSubmit: data =>
      createNewMenuLink({ data: createFormData({ linkDto: data.dto, file: data.file }), callback: resetAndExit }),
  });

  useSetFieldsErrors(menuLinkError, setFieldError);

  const [filePath, setFilePath] = useState('');

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleReset = () => {
    if (fileRef.current) {
      //@ts-ignore
      fileRef.current.value = null;
    }
    setFieldValue('file', null);
    setFilePath('');
  };

  const handleChangeFile = useCallback(event => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue('file', file);
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = () => {
        isString(fr.result) && setFilePath(fr.result);
      };
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      title={intl.formatMessage(messages.newMenuLinkTitle)}
      onRequestClose={resetAndExit}
      shouldCloseOnOverlayClick={false}
      classNameModal="center"
    >
      <form className="modal__form form custom-menu-link" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper ">
          <div className="form__input-block">
            <span className="icon-input-title">
              <FormattedMessage {...messages.iconLabel} />
            </span>
            <FileInput
              name={'file'}
              defaultValue={filePath}
              onChange={handleChangeFile}
              externalClass={'menu-link-icon'}
              accept=".svg,.png,.jpeg,.jpg"
              fileRef={fileRef}
            />
            <div className="form__inputs-subwrapper">
              {filePath && (
                <span className="icons menu-link-icons">
                  <img src={filePath} />
                </span>
              )}
              <span onClick={handleReset} className="navigation__icon-reset">
                <FormattedMessage {...messages.removeButton} />
              </span>
            </div>
          </div>
          <div className="form__input-block">
            <Input
              id={'dto.displayName'}
              name={'dto.displayName'}
              label={intl.formatMessage(messages.label)}
              defaultValue={values.dto?.displayName}
              onChange={handleChange}
              hasError={hasError('dto.displayName')}
              errorMessage={errors.dto?.displayName}
            />
          </div>
          <div className="form__input-block">
            <Input
              id={'dto.url'}
              name={'dto.url'}
              label={intl.formatMessage(messages.urlLabel)}
              defaultValue={values.dto?.url}
              onChange={handleChange}
              hasError={hasError('dto.url')}
              errorMessage={errors.dto?.url}
            />
          </div>
        </div>

        <ErrorMessage>{menuLinkError}</ErrorMessage>
        <div className={'form__buttons'}>
          <Button color={'gray'} type="button" externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type="submit" loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewMenuLink;
