import { defineMessages } from 'react-intl';
import {
  projectsLabel,
  employeesLabel,
  activeLabel,
  inactiveLabel,
  externalLabel,
  statusLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  employeesLabel,
  projectsLabel,
  activeLabel,
  inactiveLabel,
  externalLabel,
  statusLabel,
  sumSubtaskTitle: {
    id: 'reports.projects.filters.label.sum.sum.subtask',
    defaultMessage: 'Sum Subtask',
  },
  roundTimeTitle: {
    id: 'reports.projects.filters.label.round.time.task',
    defaultMessage: 'Round Time',
  },
  groupByComponents: {
    id: 'reports.projects.filters.label.group.by.components',
    defaultMessage: 'Group by Components',
  },
  groupByMembers: {
    id: 'reports.projects.filters.label.group.by.members',
    defaultMessage: 'Group by Members',
  },
});
