import { defineMessages } from 'react-intl';
import {
  editButton,
  deleteButton,
  newButton,
  authorColumn,
  createdColumn,
  editorColumn,
  updatedColumn,
  employeesLabel,
  memberLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  authorColumn,
  createdColumn,
  editorColumn,
  updatedColumn,
  newButton,
  editButton,
  deleteButton,
  memberLabel,
  employeesLabel,
  recognitionTypeColumn: {
    id: 'recognitions.column.recognition.type',
    defaultMessage: 'Recognition Type',
  },
  messageTitle: {
    id: 'recognitions.column.message',
    defaultMessage: 'Message',
  },
  pageTitle: {
    id: 'recognitions.title.recognitions',
    defaultMessage: 'Recognitions',
  },
});
