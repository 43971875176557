import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EventPreviewInfo } from '../../../enums/schedule.enum';
import { cloneDeep, debounce } from 'lodash-es';
import { getDates, getGroupByDepartmentsTableData } from './utils';
import { useDataForDepartmentsListTable } from './useDataForTable';
import HierarchicalTable from '../../HierarchicalTable';
import { useResizeObserver } from '../../../utils/hooks.utils';
import moment from 'moment';
import { UserAbsencePeriods, UserInfo } from '../../../enums/users.enum';
import AbsenceBlock from '../../Profile/AbsenceBlock';
import { OfficesWorkDaysType } from '../../../types/libraries/libraries.Reducer.type';
import { DaysOffInfoType } from '../../../types/schedule/scheduleReducer.type';
import { getTableData } from '../../../utils/table.utils';

type DepartmentsListLayoutProps = {
  eventTableData: EventPreviewInfo[];
  isLoadingGetEventList: boolean;
  error: string | null;
  dateFrom: string;
  dateTo: string;
  userAbsencePeriods: UserAbsencePeriods[];
  userHrInfo: {
    dismissal: string;
    endTrialPeriod: string;
    hired: string;
    hrCuratorId: string;
    hrCurator: UserInfo;
  }[];
  absencePeriodsLoading: boolean;
  officesWorkDays: OfficesWorkDaysType;
  daysOffInfo: DaysOffInfoType;
  openEventModal: (event: EventPreviewInfo, e: React.MouseEvent) => void;
  getProfessionalInfo: (uuid: string) => void;
  getUserAbsencePeriods: (data: any) => void;
};

function DepartmentsListLayout({
  eventTableData,
  isLoadingGetEventList,
  error,
  dateFrom,
  dateTo,
  userAbsencePeriods,
  userHrInfo,
  absencePeriodsLoading,
  officesWorkDays,
  daysOffInfo,
  openEventModal,
  getProfessionalInfo,
  getUserAbsencePeriods,
}: DepartmentsListLayoutProps) {
  const ref: any = useRef(null);
  const tableRef: any = useRef(null);
  const horizontalScrollRef: any = useRef(null);
  const verticalScrollRef: any = useRef(null);
  const { height: tableHeight } = useResizeObserver(tableRef);
  const { width: tableOffsetWidth } = useResizeObserver(ref);
  const [columnWidth, setColumnWidth] = useState(157);
  const [tableWidth, setTableWidth] = useState(0);
  const [modalAbsenceIsOpen, setModalAbsenceIsOpen] = useState(false);
  const [userClicked, setUserClicked] = useState(new UserInfo());

  const dates = useMemo(() => getDates(dateFrom, dateTo, daysOffInfo, officesWorkDays), [
    dateFrom,
    dateTo,
    daysOffInfo,
    officesWorkDays,
  ]);

  const [anchor, setAnchor] = useState<any>(null);
  const closeModalAbsence = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const rect = (e.target as HTMLElement).getBoundingClientRect();
      const anchorRect = anchor?.getBoundingClientRect();
      if (rect.x !== anchorRect?.x && anchorRect?.y !== rect.y) {
        setUserClicked(new UserInfo());
        setModalAbsenceIsOpen(false);
      }
    },
    [anchor],
  );
  const openModalAbsence = useCallback((e: React.MouseEvent, user: UserInfo) => {
    setUserClicked(user);
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    const virtualEl = {
      getBoundingClientRect() {
        return rect;
      },
    };
    setAnchor(virtualEl);
    setModalAbsenceIsOpen(state => !state);
  }, []);

  useEffect(() => {
    if (modalAbsenceIsOpen && userClicked.id) {
      getProfessionalInfo(userClicked.id);
    }
  }, [userClicked, modalAbsenceIsOpen]);

  const { tableHeaderItems, tableColumns } = useDataForDepartmentsListTable(
    dates,
    dateFrom,
    dateTo,
    openEventModal,
    openModalAbsence,
  );
  const groupByDepartmentsTableData: any = useMemo(
    () => getGroupByDepartmentsTableData(eventTableData, dateFrom, dateTo),
    [eventTableData],
  );

  useEffect(() => {
    if (ref.current) {
      const diff = moment(dateTo).diff(moment(dateFrom), 'days') + 1;
      let curentColumnSize = (ref?.current?.offsetWidth - 300) / diff;
      if (curentColumnSize < 42) {
        curentColumnSize = 42;
      }
      setColumnWidth(curentColumnSize);
    }
  }, [dateFrom, dateTo, tableOffsetWidth]);

  const handleHorizontalScroll = (scroll: any) => {
    if (ref.current) {
      ref.current.scrollLeft = scroll.target.scrollLeft;
    }
  };

  const handleVerticalScroll = (scroll: any) => {
    if (ref.current) {
      ref.current.scrollTop = scroll.target.scrollTop;
    }
  };

  const handleScrollTable = (scroll: any) => {
    if (horizontalScrollRef.current) {
      horizontalScrollRef.current.scrollLeft = scroll.target.scrollLeft;
    }
    if (verticalScrollRef.current) {
      verticalScrollRef.current.scrollTop = scroll.target.scrollTop;
    }
  };

  useEffect(() => {
    if (ref?.current) {
      debounce(() => setTableWidth(ref?.current?.scrollWidth), 400)();
    }
  }, [eventTableData, tableOffsetWidth]);

  const table = useMemo(
    () => (
      <HierarchicalTable
        tableRef={tableRef}
        externalClass="schedule__departments-event-table table--striped"
        tableHeaderItems={tableHeaderItems}
        tableColumns={tableColumns}
        tableData={getTableData(cloneDeep(groupByDepartmentsTableData), ['users'])}
        loading={isLoadingGetEventList}
        error={error}
      />
    ),
    [tableRef, tableHeaderItems, tableColumns, groupByDepartmentsTableData, isLoadingGetEventList, error],
  );

  return (
    <>
      <div
        className="page__scrollable-table-wrapper page__schedule__event-departments-view"
        style={{
          //@ts-ignore
          '--column-width': `${columnWidth}px`,
          '--table-width': `${tableWidth}px`,
          '--table-height': `${tableHeight}px`,
          '--table-header-height': columnWidth >= 60 ? '31px' : '45px',
        }}
      >
        {ref?.current?.clientWidth < tableWidth && !isLoadingGetEventList && (
          <div className="horizontal-scroll-wrapper" ref={horizontalScrollRef} onScroll={handleHorizontalScroll}>
            <div className="horizontal-scroll"></div>
          </div>
        )}
        {!isLoadingGetEventList && ref?.current?.clientHeight < tableHeight && (
          <div className="vertical-scroll-wrapper" ref={verticalScrollRef} onScroll={handleVerticalScroll}>
            <div className="vertical-scroll"></div>
          </div>
        )}
        <div
          className="page__scrollable-table-wrapper__inner event-departments-wrapper"
          ref={ref}
          onScroll={handleScrollTable}
        >
          {table}
        </div>
      </div>
      <AbsenceBlock
        userId={userClicked.id}
        userAbsencePeriods={userAbsencePeriods}
        userHrInfo={userHrInfo}
        absencePeriodsLoading={absencePeriodsLoading}
        modalAbsenceIsOpen={modalAbsenceIsOpen}
        onRequestClose={closeModalAbsence}
        getUserAbsencePeriods={getUserAbsencePeriods}
        anchor={anchor}
      />
    </>
  );
}

export default React.memo(DepartmentsListLayout);
