import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import isNil from 'lodash-es/isNil';
import InputMask from 'react-input-mask';
import Icon from '../Icon';
import MessengerIcon from '../Profile/MessengerIcon';
import TextareaAutosize from 'react-textarea-autosize';
import ErrorMessage from '../ErrorMessage';
import { mergeRefs } from '../../utils';

const iconComponentsMap = { Icon, MessengerIcon };

const inputComponentsMap = { input: 'input', textarea: 'textarea', InputMask };

export type InputProps = {
  inputRef?: any;
  id?: string;
  name: string;
  label?: string;
  type?: string | 'text';
  tag?: 'input' | 'textarea' | 'InputMask';
  mask?: string | (string | RegExp)[];
  defaultValue?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, name?: string) => void;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  externalClass?: string;
  wrapperClass?: string;
  hasError?: boolean;
  errorMessage?: string;
  bold?: boolean;
  iconName?: string;
  iconComponent?: 'Icon' | 'MessengerIcon';
  control?: React.ReactNode;
  maxLength?: number;
  placeholder?: string;
  min?: number;
  max?: number;
  step?: string;
  overflowYAuto?: boolean;
  onKeyPress?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  textareaRef?: any;
  autocomplete?: 'on' | 'off';
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  isSetDefaultValue?: boolean;
};

function Input({
  inputRef,
  id,
  name,
  label,
  type,
  tag = 'input',
  mask = '',
  placeholder,
  defaultValue,
  onChange,
  onBlur,
  disabled,
  externalClass,
  wrapperClass,
  hasError,
  errorMessage,
  bold,
  iconName,
  iconComponent = 'Icon',
  control,
  maxLength = tag === 'input' ? 255 : undefined,
  min,
  max,
  step,
  overflowYAuto,
  onKeyPress,
  textareaRef,
  autocomplete,
  onKeyUp,
  onClick,
  isSetDefaultValue = false,
}: InputProps) {
  const [value, setValue] = useState<string | number>(defaultValue || '');

  const textRef = useRef<any>(textareaRef);

  useEffect(() => {
    textareaRef = textRef;
  }, [textRef]);

  useEffect(() => {
    if (defaultValue === 0 && type === 'number') {
      setValue(0);
    } else {
      setValue(defaultValue?.toString() || '');
    }
  }, [defaultValue]);

  const handleChange = useCallback(
    event => {
      setValue(isSetDefaultValue ? defaultValue : event.target.value);
      if (onChange) {
        onChange(event, name);
      }
    },
    [value, onChange],
  );

  const onHeightChangeHandler = useCallback(height => {
    overflowYAuto || height > 200
      ? (textRef.current.style.overflowY = 'auto')
      : (textRef.current.style.overflowY = 'hidden');
  }, []);

  const defaultClass = classNames(tag === 'textarea' ? 'form__textarea' : 'form__input', {
    [externalClass as string]: Boolean(externalClass),
    'form__input--with-icon': Boolean(iconName),
    'form__input--with-control': Boolean(control),
    'form__input--bold': bold,
    'not-empty': value !== '' && !isNil(value),
    disabled,
  });

  const defaultWrapperClass = classNames('form__input-block', wrapperClass, {
    'textarea-wrapper-block': tag === 'textarea',
  });

  const labelClass = classNames('form__label', {
    '--shrink': type === 'date',
    '--error': hasError,
  });

  const IconComponent = iconComponentsMap[iconComponent];

  const InputTag = inputComponentsMap[tag];

  return (
    <div className={defaultWrapperClass}>
      <div className="form__input-wrapper">
        {iconName && <IconComponent iconName={iconName} externalClass="form__input-icon" />}
        {control}
        {tag === 'textarea' ? (
          <TextareaAutosize
            ref={mergeRefs(textRef, textareaRef)}
            className={defaultClass}
            onChange={handleChange}
            value={value}
            id={id || name}
            name={name}
            disabled={disabled}
            cacheMeasurements
            style={{ overflowY: overflowYAuto ? 'auto' : 'hidden' }}
            onHeightChange={onHeightChangeHandler}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
            //@ts-ignore
            onBlur={onBlur}
            //@ts-ignore
            onClick={onClick}
            maxLength={maxLength}
          />
        ) : (
          <InputTag
            //@ts-ignore
            ref={inputRef}
            type={type}
            className={defaultClass}
            value={value}
            onChange={handleChange}
            onKeyUp={onKeyUp}
            onBlur={onBlur}
            id={id || name}
            name={name}
            disabled={disabled}
            mask={mask}
            maxLength={maxLength}
            placeholder={placeholder}
            min={min}
            max={max}
            step={step}
            autoComplete={autocomplete}
          />
        )}
        <label className={labelClass} htmlFor={id || name}>
          {label}
        </label>
      </div>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
}

export default React.memo(Input);
