import { defineMessages } from 'react-intl';
import {
  editButton,
  deleteButton,
  newButton,
  typeLabel,
  personalLabel,
  employeesLabel,
  totalRow,
} from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  newButton,
  typeLabel,
  personalLabel,
  employeesLabel,
  totalRow,
  pageTitle: {
    id: 'finance.salaries.title.salaries',
    defaultMessage: 'Salaries',
  },

  payLabel: {
    id: 'finance.salary.label.pay',
    defaultMessage: 'Pay',
  },
  taxLabel: {
    id: 'finance.salary.label.tax',
    defaultMessage: 'Tax',
  },
});
