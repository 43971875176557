import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { Client } from '../../enums/finance/finance.enum';
import Table, { DirectionType } from '../../components/Table';
import ModalNewClient from '../../components/Clients/Modals/ModalNewClient';
import ModalEditClient from '../../components/Clients/Modals/ModalEditClient';
import ModalDeleteClient from '../../components/Clients/Modals/ModalDeleteClient';
import { ClientsParams } from '../../enums/params/finance.params';
import { groupBy, isNil } from 'lodash-es';
import { DELETE_CLIENT, UPDATE_CLIENT } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { getCurrenciesFilter } from '../../actions/filters.actions';
import { getCurrentRateDate } from '../../utils/finance.utils';

function Clients({
  tableData,
  isLoading,
  errors,
  getCurrenciesFilters,
  getClientsList,
  createClient,
  editClient,
  deleteClient,
  resetState,
  resetErrors,
  currencyList,
  setClientsParams,
  sortParams,
  changeClientStatus,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewClientIsOpen, setModalNewClientIsOpen] = useState(false);
  const [modalEditClientIsOpen, setModalEditClientIsOpen] = useState(false);
  const [modalDeleteClientIsOpen, setModalDeleteClientIsOpen] = useState(false);
  const [clientClicked, setClientClicked] = useState<Client>(new Client());
  const [clientsTableData, setClientsTableData] = useState(tableData || []);

  useEffect(() => {
    getClientsList();
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    !isNil(tableData) && setClientsTableData(tableData);
  }, [tableData]);

  const handleSort = useCallback(
    (sortBy: string, direction: DirectionType) => {
      if (sortBy === 'rates') {
        const data: any = groupBy(
          clientsTableData?.sort((a: Client, b: Client) => {
            const aName = getCurrentRateDate(a.rates)?.currency.name;
            const bName = getCurrentRateDate(b.rates)?.currency.name;
            if (isNil(aName)) return 1;
            if (isNil(bName)) return -1;
            return aName.localeCompare(bName);
          }),
          (el: Client) => getCurrentRateDate(el.rates)?.currencyId,
        );
        const arr = Object.keys(data).reduce(function (res, v) {
          const sorted = data[v].sort((a: Client, b: Client) => {
            const aVal = Number(getCurrentRateDate(a.rates)?.rate) || 0;
            const bVal = Number(getCurrentRateDate(b.rates)?.rate) || 0;
            return direction === 'ASC' ? aVal - bVal : bVal - aVal;
          });
          return res.concat(sorted);
        }, []);
        setClientsTableData(arr);
      } else {
        setClientsParams({ sortBy, direction });
      }
    },
    [clientsTableData],
  );

  const openNewClientModal = useCallback(() => {
    setModalNewClientIsOpen(true);
  }, []);

  const closeNewClientModal = useCallback(() => {
    setModalNewClientIsOpen(false);
    resetErrors();
  }, []);

  const closeEditClientModal = useCallback(() => {
    setModalEditClientIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteClientModal = useCallback(() => {
    setModalDeleteClientIsOpen(false);
    resetErrors();
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: Client) => (
          <div className={'table__data-wrapper'}>
            <div className="name-capital-letter-block">
              <span>{row.name[0]}</span>
            </div>
            <div>{row.name}</div>
          </div>
        ),
        sortName: 'name',
        className: 'table__data--bold',
      },
      {
        name: intl.formatMessage(messages.hourPriceLabel),
        modifier: (row: Client) => row.currentRate,
        sortName: 'rates',
      },
      {
        name: intl.formatMessage(messages.lastUpdateColumn),
        modifier: (row: Client) => row.lastUpdateDate,
        sortName: 'lastUpdate',
      },
      {
        name: intl.formatMessage(messages.updaterColumn),
        modifier: (row: Client) => row.updater.fullName,
        sortName: 'updater',
      },
      {
        name: intl.formatMessage(messages.statusLabel),
        modifier: (row: Client) => (
          <span className={row.isActive ? 'active-status' : 'inactive-status'}>
            {row.isActive ? intl.formatMessage(messages.activeLabel) : intl.formatMessage(messages.inactiveLabel)}
          </span>
        ),
        sortName: 'isActive',
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Client) => {
          setClientClicked(row);
          setModalEditClientIsOpen(true);
        },
        verifiablePolicies: [UPDATE_CLIENT],
      },
      {
        label: (row: Client) => (
          <>
            <Icon iconName={row.isActive ? 'toggle-on' : 'toggle-off'} externalClass={'dropdown__list-item__icon'} />
            {row.isActive ? (
              <FormattedMessage {...messages.deactivateLabel} />
            ) : (
              <FormattedMessage {...messages.activateLabel} />
            )}
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Client) => {
          changeClientStatus(row.id, `${!row.isActive}`);
        },
        verifiablePolicies: [UPDATE_CLIENT],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Client) => {
          setClientClicked(row);
          setModalDeleteClientIsOpen(true);
        },
        verifiablePolicies: [DELETE_CLIENT],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_CLIENT]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewClientModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content active_employees_page">
        <div className="page__wrapper">
          <Table
            tableColumns={tableColumns}
            tableData={clientsTableData}
            loading={isLoading.getClientsList}
            error={errors}
            tableActions={tableActions}
            onSort={handleSort}
            params={sortParams}
          />
        </div>
      </div>
      {modalNewClientIsOpen && (
        <ModalNewClient
          isOpen
          onCloseRequest={closeNewClientModal}
          createClient={createClient}
          error={errors}
          isLoading={isLoading.createClient}
          getCurrenciesList={getCurrenciesFilters}
          currenciesList={currencyList}
        />
      )}
      {modalEditClientIsOpen && (
        <ModalEditClient
          isOpen
          onCloseRequest={closeEditClientModal}
          editClient={editClient}
          isLoading={isLoading.editClient}
          error={errors}
          client={clientClicked}
          getCurrenciesList={getCurrenciesFilters}
          currenciesList={currencyList}
        />
      )}
      {modalDeleteClientIsOpen && (
        <ModalDeleteClient
          isOpen
          onCloseRequest={closeDeleteClientModal}
          onDeleteRequest={deleteClient}
          isLoading={isLoading.deleteClient}
          error={errors}
          client={clientClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance, filters }: RootState) => ({
  errors: finance.errors.clientsError,
  isLoading: finance.loading,
  tableData: finance.clientsListData?.content,
  currencyList: filters.currenciesFilter.currencies,
  sortParams: finance.clientsParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCurrenciesFilters: () => dispatch(getCurrenciesFilter()),
  getClientsList: () => dispatch(financeActions.getClientsList()),
  createClient: (data: { data: Client; callback: () => void }) => dispatch(financeActions.createClient(data)),
  editClient: (data: { data: Client; callback: () => void }) => dispatch(financeActions.editClient(data)),
  deleteClient: (data: { id: string; callback: () => void }) => dispatch(financeActions.deleteClient(data)),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  setClientsParams: (params: Partial<ClientsParams>) => dispatch(financeActions.setClientsParams(params)),
  changeClientStatus: (id: string, isActive: string) => dispatch(financeActions.changeClientStatus(id, isActive)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Clients);
