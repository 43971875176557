import React, { useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import OverflowMenu from './OverflowMenu';

type IntersectionObserverWrapProps = {
  children: any;
  openReorderLinksModal: () => void;
};

export default function IntersectionObserverWrap({ children, openReorderLinksModal }: IntersectionObserverWrapProps) {
  const navRef = useRef<any>(null);
  const observerRef: any = useRef(null);
  const [visibilityMap, setVisibilityMap] = useState<any>({});
  const handleIntersection = (entries: any) => {
    const updatedEntries: any = {};
    entries.forEach((entry: any, i: number) => {
      const targetid = entry.target.dataset.targetid;
      if (entry.isIntersecting || entries[i + 1]?.isIntersecting) {
        updatedEntries[targetid] = true;
      } else {
        updatedEntries[targetid] = false;
      }
    });

    setVisibilityMap((prev: any) => ({
      ...prev,
      ...updatedEntries,
    }));
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 1,
    });
  }, []);

  useEffect(() => {
    Array.from(navRef.current.children).forEach((item: any) => {
      if (item.dataset.targetid) {
        observerRef.current.observe(item);
      }
    });

    return () => observerRef.current.disconnect();
  }, [children]);

  return (
    <div className="toolbarWrapper-custom" ref={navRef}>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          className: classnames(child.props.className, {
            ['visible-custom']: !!visibilityMap[child.props['data-targetid']],
            ['inVisible-custom']: !visibilityMap[child.props['data-targetid']],
          }),
        });
      })}
      <OverflowMenu
        isShowMenu={children.length > 1}
        visibilityMap={visibilityMap}
        openReorderLinksModal={openReorderLinksModal}
      >
        {children}
      </OverflowMenu>
    </div>
  );
}
