import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import ColorInput from '../../ColorInput';
import Modal from '../../Modal';
import { RECOGNITION_TYPE_VALIDATION_SCHEMA } from '../../../enums/recognitions.enum';
import FileInput from '../../FileInput';
import RecognitionIconPreview from '../../RecognitionIcon/RecognitionIconPreview';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { createFormData } from '../../../utils/data.utils';
import { scrollToError } from '../../../utils';

type ModalNewRecognitionTypeProps = {
  onCloseRequest: () => void;
  createNewRecognitionType: (data: any) => void;
  recognitionTypeError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

export type InitValuesType = {
  name: string;
  icon: null | string | File;
  backgroundColor: string;
};

function ModalNewRecognitionType({
  onCloseRequest,
  createNewRecognitionType,
  recognitionTypeError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalNewRecognitionTypeProps) {
  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldError,
  } = useFormik<InitValuesType>({
    initialValues: {
      name: '',
      icon: null,
      backgroundColor: '',
    },
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: RECOGNITION_TYPE_VALIDATION_SCHEMA,
    onSubmit: async data => {
      data.backgroundColor = data.backgroundColor.substr(-6);
      return createNewRecognitionType({
        data: createFormData({ dto: { backgroundColor: data.backgroundColor, name: data.name }, icon: data.icon }),
        callback: resetAndExit,
      });
    },
  });

  useSetFieldsErrors(recognitionTypeError, setFieldError);

  const [filePath, setFilePath] = useState('');

  const handleChangeColor = useCallback(event => {
    setFieldValue('backgroundColor', event.target.value);
  }, []);

  const handleChangeFile = useCallback(event => {
    setFieldValue('icon', event.target.files[0]);
  }, []);

  const handleReset = useCallback(() => {
    setFieldValue('icon', '');
    setFilePath('');
  }, []);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const colorInput = (
    <ColorInput
      name={'backgroundColor'}
      onChange={handleChangeColor}
      id={'color'}
      externalClass={'form__input-color'}
      defaultValue={values.backgroundColor.length ? values.backgroundColor : '#FFFFFF'}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.newRecognitionTypeTitle)}
      classNameModal="center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper recognition-type">
          <div className="form__input-block form__input-block--recognition-type-name">
            <Input
              id={'name'}
              name={'name'}
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values.name}
              onChange={handleChange}
              hasError={hasError('backgroundColor') || hasError('name')}
              errorMessage={hasError('name') ? errors.name : errors.backgroundColor}
              control={colorInput}
            />
          </div>
          <div className="form__input-block flex">
            <RecognitionIconPreview
              icon={values.icon}
              color={values.backgroundColor.length ? values.backgroundColor : '#FFFFFF'}
            />
            <div className={'recognition__file-input'}>
              <label className={'label--recognition-icon'}>
                <FormattedMessage {...messages.iconLabel} />
              </label>
              <span onClick={handleReset} className={'recognition__icon-reset'}>
                <FormattedMessage {...messages.removeButton} />
              </span>
              <FileInput
                name={'iconFile'}
                defaultValue={filePath}
                onChange={handleChangeFile}
                externalClass={'recognition-icon'}
                accept=".svg"
              />
            </div>
          </div>
        </div>
        <ErrorMessage>{recognitionTypeError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewRecognitionType;
