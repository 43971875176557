export const minColumnWidth = 85;
export const firstColumnWidth = 301;
export enum typeOfActivity {
  Logged = 'Logged',
  Active = 'Active',
  Tracked = 'Tracked',
  Submited = 'Submited',
}

export const countOfActivityTypeColumn = 4;
export const countOfTotalColumn = 4;

export const countOfActivityChartColumns = 12;
