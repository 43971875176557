import React from 'react';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import TableUserAvatar from '../../components/TableUserAvatar';
import { DepthLevels } from '../../constants/tables.constants';
import { EmployeesIssueType, EmployeesIssueTypesItem, EmployeesIssueTypesProjects } from '../../enums/reports.enum';
import { UserInfo } from '../../enums/users.enum';
import { getForamtedTime } from '../../utils/reports.utils';
import { getTableCell } from '../../utils/table.utils';
import messages from './messages';

export const useDataForTable = (isGroupByProject: boolean) => {
  const intl = useIntl();
  const tableColumns = useMemo(
    () =>
      isGroupByProject
        ? [
            {
              Header: intl.formatMessage(messages.membersProjectsColumn),
              id: 'name',
              Cell: ({ row }: any) => {
                return getTableCell(row, [
                  {
                    depth: DepthLevels.FIRST,
                    content: (row: EmployeesIssueTypesItem) => {
                      return row.totalItem ? (
                        <FormattedMessage {...messages.totalRow} />
                      ) : (
                        <TableUserAvatar users={[new UserInfo({ ...row, id: row.portalId })]} fileSize={36} />
                      );
                    },
                  },
                  {
                    depth: DepthLevels.SECOND,
                    content: (row: EmployeesIssueTypesProjects) => (
                      <div className={'table__data-wrapper'}>
                        <span className={'table_button--open-modal'}>{row.name}</span>
                      </div>
                    ),
                  },
                ]);
              },
            },
            {
              Header: intl.formatMessage(messages.issueTypeColumn),
              id: 'type',
              Cell: ({ row }: any) => {
                return getTableCell(row, [
                  {
                    depth: DepthLevels.THIRD,
                    content: (row: EmployeesIssueType) => (
                      <div className={'table__data-wrapper'}>
                        <span className={'table_button--open-modal'}>{row.pname}</span>
                      </div>
                    ),
                  },
                ]);
              },
            },
            {
              Header: intl.formatMessage(messages.issueCountColumn),
              id: 'count',
              Cell: ({ row }: any) => {
                return getTableCell(row, [
                  {
                    depth: DepthLevels.FIRST,
                    content: (row: EmployeesIssueTypesItem) => (
                      <div className={'table__data-wrapper'}>
                        <span className={'table_button--open-modal'}>{row.countOfIssues}</span>
                      </div>
                    ),
                  },
                  {
                    depth: DepthLevels.SECOND,
                    content: (row: EmployeesIssueTypesProjects) => (
                      <div className={'table__data-wrapper'}>
                        <span className={'table_button--open-modal'}>{row.countOfIssues}</span>
                      </div>
                    ),
                  },
                  {
                    depth: DepthLevels.THIRD,
                    content: (row: EmployeesIssueType) => (
                      <div className={'table__data-wrapper'}>
                        <span className={'table_button--open-modal'}>{row.countOfIssues}</span>
                      </div>
                    ),
                  },
                ]);
              },
            },
            {
              Header: intl.formatMessage(messages.workedColumn),
              id: 'worked',
              Cell: ({ row }: any) => {
                return getTableCell(row, [
                  {
                    depth: DepthLevels.FIRST,
                    content: (row: EmployeesIssueTypesItem) => (
                      <div className={'table__data-wrapper'}>{`${getForamtedTime(row.worked)}h`}</div>
                    ),
                  },
                  {
                    depth: DepthLevels.SECOND,
                    content: (row: EmployeesIssueTypesProjects) => (
                      <div className={'table__data-wrapper'}>{row.workedHours}</div>
                    ),
                  },
                  {
                    depth: DepthLevels.THIRD,
                    content: (row: EmployeesIssueType) => (
                      <div className={'table__data-wrapper'}>{row.workedHours}</div>
                    ),
                  },
                ]);
              },
            },
          ]
        : [
            {
              Header: intl.formatMessage(messages.membersProjectsColumn),
              id: 'name',
              Cell: ({ row }: any) => {
                return getTableCell(row, [
                  {
                    depth: DepthLevels.FIRST,
                    content: (row: EmployeesIssueTypesItem) =>
                      row.totalItem ? (
                        <FormattedMessage {...messages.totalRow} />
                      ) : (
                        <TableUserAvatar users={[new UserInfo({ ...row, id: row.portalId })]} fileSize={36} />
                      ),
                  },
                ]);
              },
            },
            {
              Header: intl.formatMessage(messages.issueTypeColumn),
              id: 'type',
              Cell: ({ row }: any) => {
                return getTableCell(row, [
                  {
                    depth: DepthLevels.SECOND,
                    content: (row: EmployeesIssueType) => (
                      <div className={'table__data-wrapper'}>
                        <span className={'table_button--open-modal'}>{row.pname}</span>
                      </div>
                    ),
                  },
                ]);
              },
            },
            {
              Header: intl.formatMessage(messages.issueCountColumn),
              id: 'count',
              Cell: ({ row }: any) => {
                return getTableCell(row, [
                  {
                    depth: DepthLevels.FIRST,
                    content: (row: EmployeesIssueTypesItem) => (
                      <div className={'table__data-wrapper'}>
                        <span className={'table_button--open-modal'}>{row.countOfIssues}</span>
                      </div>
                    ),
                  },
                  {
                    depth: DepthLevels.SECOND,
                    content: (row: EmployeesIssueType) => (
                      <div className={'table__data-wrapper'}>
                        <span className={'table_button--open-modal'}>{row.countOfIssues}</span>
                      </div>
                    ),
                  },
                ]);
              },
            },
            {
              Header: intl.formatMessage(messages.workedColumn),
              id: 'worked',
              Cell: ({ row }: any) => {
                return getTableCell(row, [
                  {
                    depth: DepthLevels.FIRST,
                    content: (row: EmployeesIssueTypesItem) => (
                      <div className={'table__data-wrapper'}>{`${getForamtedTime(row.worked)}h`}</div>
                    ),
                  },
                  {
                    depth: DepthLevels.SECOND,
                    content: (row: EmployeesIssueTypesProjects) => (
                      <div className={'table__data-wrapper'}>{row.workedHours}</div>
                    ),
                  },
                ]);
              },
            },
          ],
    [isGroupByProject],
  );

  return {
    tableColumns,
  };
};
