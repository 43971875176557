import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { Recognition, RECOGNITION_VALIDATION_SCHEMA } from '../../../enums/recognitions.enum';
import Select from '../../Select';
import Input from '../../Input';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { scrollToError } from '../../../utils';

type ModalNewRecognitionProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  createNewRecognition: (data: any) => void;
  recognitionError: string | RejectValueErrors[] | null;
  recognitionTypes: { id: string; name: string }[];
  users: { id: string; firstName: string; secondName: string }[];
  isLoading: boolean;
  resetErrors: () => void;
};

function ModalNewRecognition({
  onCloseRequest,
  createNewRecognition,
  recognitionError,
  recognitionTypes,
  users,
  isLoading,
  resetErrors,
  isOpen,
}: ModalNewRecognitionProps) {
  const intl = useIntl();

  const { errors, touched, setFieldValue, handleSubmit, resetForm, setFieldError, values } = useFormik({
    initialValues: new Recognition(),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: RECOGNITION_VALIDATION_SCHEMA,
    onSubmit: data => {
      return createNewRecognition({ data, callback: resetAndExit });
    },
  });

  useSetFieldsErrors(recognitionError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const recognitionTypesOptions = useMemo(() => recognitionTypes.map(({ id, name }) => ({ label: name, value: id })), [
    recognitionTypes,
  ]);

  const usersOptions = useMemo(
    () => users.map(({ id, firstName, secondName }) => ({ label: firstName + ' ' + secondName, value: id })),
    [users],
  );

  const handleChangeRecognitionType = useCallback(({ value, label }) => {
    setFieldValue('recognitionTypeId', value);
    setFieldValue('recognitionType', value && { id: value, name: label });
  }, []);

  const handleChangeAuthor = useCallback(({ label, value }) => {
    setFieldValue('author', value && { id: value, name: label });
    setFieldValue('authorId', value);
  }, []);

  const handleChangeEmployee = useCallback(({ label, value }) => {
    setFieldValue('targetUser', value && { id: value, name: label });
    setFieldValue('targetUserId', value);
  }, []);

  const handleChangeText = useCallback(event => {
    setFieldValue('text', event.target.value);
  }, []);

  const targetUserValue = useMemo(
    () => values?.targetUser && usersOptions?.find(({ value }: { value: string }) => value === values?.targetUser?.id),
    [usersOptions, values],
  );

  const autorValue = useMemo(
    () => values?.author && usersOptions?.find(({ value }: { value: string }) => value === values?.author?.id),
    [usersOptions, values],
  );

  const recognitionTypeValue = useMemo(
    () =>
      values.recognitionType &&
      recognitionTypesOptions?.find(({ value }: { value: string }) => value === values?.recognitionType?.id),
    [values, recognitionTypesOptions],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.newRecognitionTitle)}
      size={'small'}
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.memberLabel)}
              options={usersOptions}
              handleChange={handleChangeEmployee}
              hasError={hasError('targetUser')}
              errorMessage={errors?.targetUser?.id}
              value={targetUserValue}
              isClearable
            />
          </div>
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.authorColumn)}
              options={usersOptions}
              handleChange={handleChangeAuthor}
              hasError={hasError('author')}
              errorMessage={errors?.author?.id}
              value={autorValue}
              isClearable
            />
          </div>
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.recognitionTypeSelect)}
              options={recognitionTypesOptions}
              handleChange={handleChangeRecognitionType}
              hasError={hasError('recognitionTypeId')}
              errorMessage={errors?.recognitionTypeId}
              value={recognitionTypeValue}
              isClearable
            />
          </div>
          <div className="form__input-block">
            <Input
              name={'text'}
              tag={'textarea'}
              label={intl.formatMessage(messages.textInput)}
              onChange={handleChangeText}
              hasError={hasError('text')}
              errorMessage={errors?.text}
            />
          </div>
        </div>
        <ErrorMessage>{recognitionError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.submitButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewRecognition;
