import React, { useCallback, useEffect, useMemo } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { useFormik } from 'formik';
import Select from '../../Select';
import Input from '../../Input';
import Checkbox from '../../Checkbox';
import { Resume } from '../../../enums/resumes.enum';
import Avatar from '../../Profile/Avatar';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import EditResumeTabs from '../EditResumeTabs';
import { UserPersonalInfo } from '../../../enums/users.enum';
import {
  ForeignLanguageInfoType,
  LanguageLevelInfoType,
  ProjectInfoType,
  TechnicalSkillInfoType,
} from '../../../types/libraries';
import { scrollToError } from '../../../utils';

type ModalEditResumeProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  updateResume: (resumes: { id: string; data: FormData }[]) => void;
  currentResume: Resume;
  resumeError: string | null;
  isLoading: boolean;
  languageLevels: LanguageLevelInfoType[];
  foreignLanguages: ForeignLanguageInfoType[];
  technicalSkills: TechnicalSkillInfoType[];
  projectsList: ProjectInfoType[];
  getLanguageLevelsFilter: () => void;
  getForeignLanguagesFilter: () => void;
  getTechnicalSkillsFilter: () => void;
  getProjectsFilter: () => void;
  getUserPersonalInfo: (uuid: string) => void;
  userPersonalInfo: UserPersonalInfo;
};

function ModalEditResume({
  isOpen,
  onCloseRequest,
  updateResume,
  currentResume,
  resumeError,
  isLoading,
  languageLevels,
  foreignLanguages,
  technicalSkills,
  projectsList,
  getLanguageLevelsFilter,
  getForeignLanguagesFilter,
  getTechnicalSkillsFilter,
  getProjectsFilter,
  getUserPersonalInfo,
  userPersonalInfo,
}: ModalEditResumeProps) {
  const intl = useIntl();

  useEffect(() => {
    getLanguageLevelsFilter();
    getForeignLanguagesFilter();
    getProjectsFilter();
    getTechnicalSkillsFilter();
  }, []);

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: currentResume,
    enableReinitialize: true,
    validate: scrollToError,
    onSubmit: values => {
      const formData = new FormData();

      values.deletePhotoFlag &&
        formData.append(`deletePhotoFlag`, new Blob([JSON.stringify('true')], { type: 'application/json' }));

      values.cropSetting &&
        formData.append(
          'cropSetting',
          new Blob([JSON.stringify(values.photo.cropSetting)], { type: 'application/json' }),
        );

      values.photoFile && formData.append('file', values.photoFile);

      //@ts-ignore
      delete values.photoFile;
      //@ts-ignore
      delete values.photo;

      formData.append(
        'dto',
        new Blob([JSON.stringify({ ...values, languages: values.languages.filter(item => item.foreignLanguage) })], {
          type: 'application/json',
        }),
      );

      updateResume([{ data: formData, id: values.employee.id }]);
    },
  });

  useEffect(() => {
    if (currentResume && currentResume?.education === null) {
      getUserPersonalInfo(currentResume.employee.id);
    }
  }, [currentResume]);

  useEffect(() => {
    if (currentResume?.education === null && userPersonalInfo.education) {
      setFieldValue('education', userPersonalInfo.education);
    }
  }, [userPersonalInfo]);

  const onChooseSkillGroupe = (data: any) => {
    setFieldValue('technicalSkills', [
      ...values.technicalSkills,
      {
        skillGroupId: data.value,
        skillGroupName: data.label,
        skillGroupPriority: null,
        skillIds: [],
        skills: [],
      },
    ]);
  };

  const removeSkillGroupe = (index: number) => {
    values.technicalSkills.splice(index, 1);
    setFieldValue(`technicalSkills`, values.technicalSkills);
  };

  const handleChangeSkills = useCallback((data, index) => {
    setFieldValue(
      `technicalSkills[${index}].skills`,
      data.map((item: { value: number; label: string }) => ({ id: item.value, skill: item.label })),
    );
    setFieldValue(
      `technicalSkills[${index}].skillIds`,
      data.map((item: { value: number }) => item.value),
    );
  }, []);

  const addNewLanguage = () => {
    setFieldValue('languages', [
      ...values.languages,
      {
        foreignLanguage: null,
        foreignLanguageId: null,
        languageLevel: null,
        languageLevelId: null,
      },
    ]);
  };

  const removeLanguage = (index: number) => {
    const languages = [...values.languages];
    languages.splice(index, 1);
    setFieldValue(`languages`, languages);
  };

  const handleChangeLanguage = useCallback((data, index) => {
    setFieldValue(`languages[${index}].foreignLanguage`, { id: data.value, name: data.label });
    setFieldValue(`languages[${index}].foreignLanguageId`, data.value);
  }, []);

  const handleChangeLanguageLevel = useCallback((data, index) => {
    setFieldValue(`languages[${index}].languageLevel`, { id: data.value, name: data.label });
    setFieldValue(`languages[${index}].languageLevelId`, data.value);
  }, []);

  const addNewProject = () => {
    setFieldValue('projects', [
      ...values.projects,
      {
        description: null,
        id: null,
        name: null,
      },
    ]);
  };

  const removeProject = (index: number) => {
    const projects = [...values.projects];
    projects.splice(index, 1);
    setFieldValue(`projects`, projects);
  };

  const handleChangeProject = useCallback((data, index) => {
    setFieldValue(`projects[${index}].id`, data.value);
    setFieldValue(`projects[${index}].name`, data.label);
    setFieldValue(`projects[${index}].description`, data.description);
  }, []);

  const handleChangeSummary = useCallback(data => {
    setFieldValue('summary', data);
  }, []);

  const handleChangePersonalHighlights = useCallback(data => {
    setFieldValue('personalHighlights', data);
  }, []);

  const handleChangeInterestsAndHobbies = useCallback(data => {
    setFieldValue('interestsAndHobbies', data);
  }, []);

  const getUserInfo = useMemo(
    () => ({
      id: values.employee.id,
      firstName: values.employee.firstName,
      secondName: values.employee.secondName,
      photo: values.photo,
    }),
    [values, currentResume],
  );

  const deletePhoto = ({ callback }: { callback: () => void }) => {
    setFieldValue('deletePhotoFlag', true);
    callback();
    setFieldValue('photo', null);
    setFieldValue('photoFile', null);
    setFieldValue('cropSetting', null);
  };

  const getPhoto = (data: any, callback: () => void) => {
    setFieldValue('deletePhotoFlag', false);
    setFieldValue(
      'photo',
      data.file
        ? {
            url: URL.createObjectURL(data.file),
            id: values.photo?.id,
            cropSetting: data.cropSetting,
            croppedFile: { url: data.cropFileUrl },
          }
        : {
            ...values.photo,
            id: values.photo?.id,
            cropSetting: data.cropSetting,
            croppedFile: { url: data.cropFileUrl },
          },
    );
    setFieldValue('photoFile', data.file);
    setFieldValue('cropSetting', data.cropSetting);
    callback();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editModalTitle)}
      size="big"
      classNameModal={'edit_cv_modal'}
    >
      <form className="modal__form form form--cv form--tabs" onSubmit={handleSubmit}>
        <div className="form__tabs-content">
          <div className=" form__inputs-wrapper--cv active">
            <div className="form__edit-cv-top">
              <div className="form__avatar">
                <Avatar
                  viewOnly={false}
                  //  @ts-ignore
                  userInfo={getUserInfo}
                  customSize={160}
                  customFontSize={55}
                  uploadPhoto={getPhoto}
                  deleteUserPhoto={deletePhoto}
                  externalClass="square"
                  externalCropperClass="square"
                  fileSize={320}
                />
              </div>
              <div className="form__edit-cv-top-inputs">
                <div className="form__edit-cv-name">{currentResume.employee.fullName}</div>
                <div className="form__inputs-subwrapper">
                  <div className="form__input-block form__input-block--half">
                    <Select
                      label={intl.formatMessage(messages.positionLabel)}
                      isDisabled
                      value={
                        values.companyPosition?.id
                          ? {
                              value: values.companyPosition?.id,
                              label: values.companyPosition?.displayName,
                            }
                          : null
                      }
                    />
                  </div>
                  <div className="form__input-block form__input-block--half">
                    <Input
                      name={'experience'}
                      label={intl.formatMessage(messages.experienceLabel)}
                      defaultValue={values.userExperienceMask}
                      disabled
                    />
                  </div>
                </div>
                <Input
                  tag="textarea"
                  label={intl.formatMessage(messages.educationLabel)}
                  onChange={handleChange}
                  name="education"
                  defaultValue={values.education}
                />
              </div>
            </div>
            <EditResumeTabs
              resume={values}
              languageLevels={languageLevels}
              foreignLanguages={foreignLanguages}
              technicalSkills={technicalSkills}
              projectsList={projectsList}
              handleChangeLanguage={handleChangeLanguage}
              handleChangeLanguageLevel={handleChangeLanguageLevel}
              removeLanguage={removeLanguage}
              addNewLanguage={addNewLanguage}
              handleChangeSummary={handleChangeSummary}
              handleChangePersonalHighlights={handleChangePersonalHighlights}
              handleChangeInterestsAndHobbies={handleChangeInterestsAndHobbies}
              handleChangeSkills={handleChangeSkills}
              onChooseSkillGroupe={onChooseSkillGroupe}
              removeSkillGroupe={removeSkillGroupe}
              setFieldValue={setFieldValue}
              handleChangeProject={handleChangeProject}
              removeProject={removeProject}
              addNewProject={addNewProject}
              isDisabledEditProjects
            />
          </div>
        </div>
        <div className="modal__footer cv_form_footer">
          <ErrorMessage>{resumeError}</ErrorMessage>
          <Checkbox
            id={'approvedStatus'}
            name={'approvedStatus'}
            label={'APPROVE'}
            onChange={handleChange}
            checked={values.approvedStatus}
          />
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              type={'button'}
              onClick={onCloseRequest}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditResume;
