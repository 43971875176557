import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import Select from '../../../Select';
import { OptionTypeBase } from 'react-select';
import { SpecializationInfo } from '../../../../enums/libraries.enum';
import Modal from '../../../Modal';
import Button from '../../../Button';
import Input from '../../../Input';
import { SpecializationDataType } from '../../../../pages/SpecializationsCompetencies';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../messages';
import { RejectValueErrors } from '../../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../../utils/hooks.utils';
import ErrorMessage from '../../../ErrorMessage';
import { CompetenciesTypeInfoType } from '../../../../types/competencies';
import { scrollToError } from '../../../../utils';

type ModalEditSpecializationPropsType = {
  onCloseRequest: () => void;
  onSubmit: (data: any) => void;
  competencies: Array<CompetenciesTypeInfoType>;
  specializationData: SpecializationDataType | null;
  specializationError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalEditSpecializationCompetencies({
  onCloseRequest,
  isLoading,
  specializationData,
  specializationError,
  competencies,
  onSubmit,
  resetErrors,
  isOpen,
}: ModalEditSpecializationPropsType) {
  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const intl = useIntl();

  const { values, handleChange, resetForm, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: new SpecializationInfo(specializationData),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    onSubmit: data => {
      onSubmit({ data, callback: resetAndExit });
    },
  });

  useSetFieldsErrors(specializationError, setFieldError);

  const options = useMemo(
    () =>
      competencies?.map(el => ({
        value: el.id,
        label: el.name,
      })),
    [competencies],
  );

  const competenciesValues = useMemo(
    () =>
      values.competencies.map(competence => ({
        value: competence.id,
        label: competence.name,
      })),
    [values],
  );

  const handleCompetenciesChange = useCallback(newValue => {
    const newCompetenceIds = newValue.map(({ value }: OptionTypeBase) => value);
    const newCompetencies = newValue.map(({ value, label }: OptionTypeBase) => ({
      id: value,
      name: label,
    }));
    setFieldValue(`competencies`, newCompetencies);
    setFieldValue(`competenceIds`, newCompetenceIds);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editSpecializationTitle)}
      // classNameModal="modal--new-office"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <Input
            id={'name'}
            name={'name'}
            label={intl.formatMessage(messages.nameColumn)}
            defaultValue={values.name}
            onChange={handleChange}
            disabled={true}
            externalClass={'disabled'}
          />
          <div className="form__group">
            <div className="form__group">
              <Select
                isMulti
                isSearchable
                options={options}
                handleChange={handleCompetenciesChange}
                value={competenciesValues}
                label={intl.formatMessage(messages.competenciesTitle)}
              />
            </div>
          </div>
        </div>
        <ErrorMessage>{specializationError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditSpecializationCompetencies;
