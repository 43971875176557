import React, { useCallback, useState } from 'react';
import HierarchicalTable from '../../HierarchicalTable';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Hint from '../../Hint';
import { UserDataType } from '../../../enums/planning.enum';
import { useTableData } from '../../../utils/hooks.utils';
import { useResourcesTableData } from './useDataForTable';
import { DepthLevels } from '../../../constants/tables.constants';

type PlanWorkloadLayoutType = {
  tableData: any;
  isLoading: boolean;
  resourcesError: string;
  editResource: (resource: any, event: React.MouseEvent<HTMLElement>) => void;
  currentPeriod: string;
  paramsProjectGroups: number[];
};

function PlanWorkloadLayout({
  tableData,
  isLoading,
  resourcesError,
  editResource,
  currentPeriod,
  paramsProjectGroups,
}: PlanWorkloadLayoutType) {
  const intl = useIntl();

  const [hintDependency, setHintDependency] = useState<number | null>(null);

  const handleResourceClick = useCallback((row: UserDataType, index: number, event: React.MouseEvent<HTMLElement>) => {
    !row.dayWorkloads[index]?.employeeNotHired &&
      editResource(
        {
          user: row.user,
          endDate: row.dayWorkloads[index].date,
          startDate: row.dayWorkloads[index].date,
          workloads: row.dayWorkloads[index].workloads,
        },
        event,
      );
  }, []);

  const newTableData = tableData?.resourcesComplex?.slice(0);

  newTableData?.push({
    departmentId: '',
    departmentName: intl.formatMessage(messages.totalRow),
    timeTotal: tableData.total,
  });

  const { tableColumns, tableHeaderItems } = useResourcesTableData(
    tableData,
    currentPeriod,
    false,
    handleResourceClick,
    setHintDependency,
    paramsProjectGroups,
  );

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 1) {
      return row.id.split('.')[1] % 2 === 0 ? 'even' : '';
    }
    return '';
  }, []);

  return (
    <>
      <div className="page__scrollable-table-wrapper">
        <div className="page__scrollable-table-wrapper__inner resources_table_wrapper">
          <HierarchicalTable
            tableHeaderItems={tableHeaderItems}
            tableData={useTableData(newTableData, ['resources'])}
            externalClass="workload_table fixed"
            tableHeaderClassName="resources_table_header"
            externalRowClass={handleExternalRowClass}
            tableColumns={tableColumns}
            loading={isLoading}
            error={resourcesError}
            disabledDepthHover={[DepthLevels.SECOND]}
          />
        </div>
      </div>
      <Hint dependencyList={[hintDependency]} />
    </>
  );
}

export default React.memo(PlanWorkloadLayout);
