import { defineMessages } from 'react-intl';

export default defineMessages({
  aboutTabTitle: {
    id: 'candidates.profile.about.tab.title.about',
    defaultMessage: 'About',
  },
  commentsTabTitle: {
    id: 'candidates.profile.comments.tab.title.comments',
    defaultMessage: 'Comments',
  },
});
