import { get } from 'lodash-es';

export type SortParamsType = {
  sortBy: string;
  direction: 'ASC' | 'DESC';
};

const getSortParam = (sortBy: string | string[], direction: string) => `${sortBy},${direction?.toLowerCase()}`;

export class SortParams {
  sortBy: string | SortParamsType[];
  direction: 'ASC' | 'DESC';

  constructor(defaultSortBy: string | SortParamsType[], params?: unknown) {
    this.sortBy = get(params, 'sortBy', defaultSortBy);
    this.direction = get(params, 'direction', 'ASC');
  }

  get sortParam() {
    return !Array.isArray(this.sortBy)
      ? getSortParam(this.sortBy, this.direction)
      : this.sortBy.filter(sort => !!sort).map(el => getSortParam(el.sortBy, el.direction));
  }
}

export class FiltersParams extends SortParams {
  size: number;
  constructor(sortName: string) {
    super(sortName);
    this.size = 1000;
  }
}
