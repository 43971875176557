// eslint-disable-next-line max-classes-per-file
import { UserInfo } from '../users.enum';
import { UserPreviewInfo } from '../users.enum';
import { defaultTo, get, isNil } from 'lodash-es';
import * as yup from 'yup';
import { getCurrentRate, getFormattedLastUpdateDate } from '../../utils/finance.utils';
import * as financeTypes from '../../types/finance';
import { OfficeInfo } from '../libraries.enum';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';
import { DecimalSeparatorEnum, ExpenseIncomeTypesGroupEnum } from '../../pages/Transactions/utils';
import { intl } from '../../utils/intl';
import messages from './messages';
import { IncomesTotalOptions } from '../../constants/finance.constants';
import { v4 } from 'uuid';

export const INCOME_TYPE_SCHEMA = yup.object().shape({
  name: yup.string().trim().required(intl.formatMessage(messages.required)).max(150, 'Maximum 150 characters'),
});

export const CURRENCY_SCHEMA = yup.object().shape({
  name: yup.string().trim().required(intl.formatMessage(messages.required)).max(3, 'Maximum 3 characters'),
});

export const SUPPLIER_SCHEMA = yup.object().shape({
  name: yup.string().trim().required(intl.formatMessage(messages.required)).max(150, 'Maximum 150 characters'),
  currencyId: yup.string().trim().required(intl.formatMessage(messages.required)),
});

export const EXPENSE_TYPE_SCHEMA = yup.object().shape({
  name: yup.string().trim().required(intl.formatMessage(messages.required)).max(150, 'Maximum 150 characters'),
  isPersonal: yup.boolean().required(intl.formatMessage(messages.required)),
});

export const PROJECT_TYPE_SCHEMA = yup.object().shape({
  name: yup.string().trim().required(intl.formatMessage(messages.required)).max(150, 'Maximum 150 characters'),
});

export class Client {
  name: string;
  id: string;
  isActive: boolean;
  rates: financeTypes.RateType[];
  updater: UserPreviewInfo;
  lastUpdate: string;

  constructor(client?: unknown) {
    this.name = get(client, 'name', '');
    this.id = get(client, 'id', '');
    this.isActive = get(client, 'isActive', false);
    this.rates = get(client, 'rates', [
      {
        currency: { id: '', isBaseCurrency: false, name: '' },
        currencyId: '',
        rate: '',
        startDate: '',
      },
    ]);
    this.updater = new UserPreviewInfo(get(client, 'updater'));
    this.lastUpdate = get(client, 'lastUpdate');
  }

  get lastUpdateDate() {
    return getFormattedLastUpdateDate(this.lastUpdate);
  }

  get currentRate() {
    return getCurrentRate(this.rates);
  }
}

export const CLIENT_SCHEMA = yup.object().shape({
  name: yup.string().trim().required(intl.formatMessage(messages.required)).max(150, 'Maximum 150 characters'),
  rates: yup
    .array()
    .min(1, 'At least one rate are required')
    .of(
      yup.object().shape({
        currencyId: yup.string().trim().required(intl.formatMessage(messages.required)),
        rate: yup.string().trim().required(intl.formatMessage(messages.required)),
        startDate: yup.string().nullable().required(intl.formatMessage(messages.required)),
      }),
    ),
});

export class Salary {
  id: string;
  pay: CurrencyFormatter;
  tax: CurrencyFormatter;
  endDate: string;
  endYear: string;
  endMonth: string;
  startDate: string;
  startYear: string;
  startMonth: string;
  userId: string;
  user: UserInfo;

  constructor(item?: unknown) {
    this.id = get(item, 'id');
    const pay = get(item, 'pay', null);
    this.pay = new CurrencyFormatter({
      float: !isNil(pay) ? Number(pay) : null,
      value: pay,
      formatted: pay,
    });
    const tax = get(item, 'tax', null);
    this.tax = new CurrencyFormatter({
      float: !isNil(tax) ? Number(tax) : tax,
      value: tax,
      formatted: tax,
    });

    this.endDate = get(item, 'endDate', moment().format());
    this.endYear = this.endDate ? moment(this.endDate).get('y').toString() : '';
    this.endMonth = this.endDate ? moment(this.endDate).get('M').toString() : '';
    this.startDate = get(item, 'startDate', moment().format());
    this.startYear = this.startDate ? moment(this.startDate).get('y').toString() : '';
    this.startMonth = this.startDate ? moment(this.startDate).get('M').toString() : '';
    this.userId = get(item, 'userId', '');
    this.user = new UserInfo(get(item, 'user', ''));
  }
}

export const SALARY_SCHEMA = yup.object().shape({
  userId: yup.string().required(intl.formatMessage(messages.required)),
  startYear: yup.string().nullable().required(intl.formatMessage(messages.required)),
  startMonth: yup.string().nullable().required(intl.formatMessage(messages.required)),
  endYear: yup
    .string()
    .nullable()
    .required(intl.formatMessage(messages.required))
    .test('endYear', 'Must be the same year or later', (value: any, testContext: any) => {
      const salaryValue = testContext.from[0].value;
      const { startMonth, startYear, endMonth } = salaryValue;
      if (!startMonth || !startYear || !endMonth || !value) {
        return true;
      }
      return value >= startYear;
    }),
  endMonth: yup
    .string()
    .nullable()
    .required(intl.formatMessage(messages.required))
    .test('endMonth', 'Must be the same month or later', (value: any, testContext: any) => {
      const salaryValue = testContext.from[0].value;
      const { startMonth, startYear, endYear } = salaryValue;
      if (!startMonth || !startYear || !endYear || !value) {
        return true;
      }
      return startYear !== endYear || value >= startMonth;
    }),
  pay: yup.object({
    float: yup
      .number()
      .required(intl.formatMessage(messages.required))
      .test('pay', intl.formatMessage(messages.salariesPayTestError), value => {
        return !value || Math.trunc(value).toString().length <= 9;
      })
      .nullable(),
  }),
  tax: yup.object({
    float: yup
      .number()
      .required(intl.formatMessage(messages.required))
      .test('tax', intl.formatMessage(messages.salariesTaxTestError), value => {
        return !value || Math.trunc(value).toString().length <= 9;
      })
      .nullable(),
  }),
});

export class MonthReports {
  months: { month: string }[];
  total: number;
  totalPay: number;
  totalTax: number;

  constructor(item?: unknown) {
    this.months = get(item, 'months', []);
    this.total = get(item, 'total', []);
    this.totalPay = get(item, 'totalPay', 0);
    this.totalTax = get(item, 'totalTax', 0);
  }

  get monthDate() {
    return this.months[0]?.month;
  }
}

export type SeleriesMonth = {
  month: string;
  pay: number;
  tax: number;
  total: number;
  salaryId: string;
  isHired: boolean;
};

export class UserReports {
  months: SeleriesMonth[];
  total: number;
  totalPay: number;
  totalTax: number;
  userId: string;
  user: UserInfo;
  totalItem: boolean;

  constructor(item?: unknown) {
    this.months = get(item, 'months', []);
    this.total = get(item, 'total', []);
    this.totalPay = get(item, 'totalPay', 0);
    this.totalTax = get(item, 'totalTax', 0);
    this.userId = get(item, 'userId', '');
    this.user = new UserInfo(get(item, 'user', ''));
    this.totalItem = get(item, 'totalItem', false);
  }
}

export class SalaryReport {
  monthReports: MonthReports[];
  total: number;
  totalPay: number;
  totalTax: number;
  userReports: UserReports[];

  constructor(item?: unknown) {
    this.monthReports = get(item, 'monthReports', []).map((item: MonthReports) => new MonthReports(item));
    this.total = get(item, 'total', 0);
    this.totalPay = get(item, 'totalPay', 0);
    this.totalTax = get(item, 'totalTax', 0);
    this.userReports = get(item, 'userReports', []).map((item: UserReports) => new UserReports(item));
  }
}

export class FinanceProject {
  id: string;
  name: string;
  client: Client;
  clientId: string;
  isActive: boolean;
  jiraProjectIds: string[];
  jiraProjects: { id: number; name: string }[];
  lastUpdateDate: string;
  paymentType: string;
  projectType: financeTypes.ProjectType;
  projectTypeId: string;
  rates: financeTypes.RateType[];
  updater: UserPreviewInfo;
  updaterId: string;

  constructor(item?: unknown) {
    this.id = get(item, 'id');
    this.name = get(item, 'name', '');
    this.client = get(item, 'client');
    this.clientId = get(item, 'clientId');
    this.isActive = get(item, 'isActive');
    this.jiraProjectIds = get(item, 'jiraProjectIds', []);
    this.jiraProjects = get(item, 'jiraProjects', []);
    this.lastUpdateDate = get(item, 'lastUpdateDate');
    this.paymentType = get(item, 'paymentType');
    this.projectType = get(item, 'projectType');
    this.projectTypeId = get(item, 'projectTypeId');
    this.rates = get(item, 'rates', [
      {
        currency: { id: '', isBaseCurrency: false, name: '' },
        currencyId: '',
        rate: '',
        startDate: '',
      },
    ]);
    this.updater = new UserPreviewInfo(get(item, 'updater', {}));
    this.updaterId = get(item, 'updaterId');
  }

  get formattedLastUpdateDate() {
    return getFormattedLastUpdateDate(this.lastUpdateDate);
  }

  get currentRate() {
    return getCurrentRate(this.rates);
  }
}

export const PROJECT_SCHEMA = yup.object().shape({
  name: yup.string().trim().required(intl.formatMessage(messages.required)).max(150, 'Maximum 150 characters'),
  rates: yup
    .array()
    .min(1, 'At least one rate are required')
    .of(
      yup.object().shape({
        currencyId: yup.string().trim().required(intl.formatMessage(messages.required)),
        rate: yup.string().trim().required(intl.formatMessage(messages.required)),
        startDate: yup.string().nullable().required(intl.formatMessage(messages.required)),
      }),
    ),
  clientId: yup.string().trim().required(intl.formatMessage(messages.required)),
  projectTypeId: yup.string().trim().required(intl.formatMessage(messages.required)),
  paymentType: yup.string().trim().required(intl.formatMessage(messages.required)),
});

export type TransactionInitialValue = Partial<
  Omit<Transaction, 'amount' | 'unifiedAmount' | 'rate'> & {
    amount: string;
    unifiedAmount: string;
    rate: string;
  }
>;

export class Transaction {
  id: string;
  transactionDate: string;
  officeId: string;
  office: OfficeInfo;
  expenseTypeId: string;
  expenseType: financeTypes.ExpenseType;
  incomeTypeId: string;
  incomeType: financeTypes.IncomeType;
  officePayerRecipientId: string;
  officePayerRecipient: OfficeInfo;
  clientPayerRecipientId: string;
  clientPayerRecipient: Client;
  supplierPayerRecipientId: string;
  supplierPayerRecipient: financeTypes.SupplierType;
  userPayerRecipientId: string;
  userPayerRecipient: UserPreviewInfo;
  financeProjectId: string;
  financeProject: FinanceProject;
  amount: CurrencyFormatter;
  unifiedAmount: CurrencyFormatter;
  currencyId: string;
  currency: financeTypes.CurrencyType;
  rate: CurrencyFormatter;
  comment: string;
  lastUpdate: string;
  updaterId: string;
  updater: UserPreviewInfo;

  constructor(transaction?: TransactionInitialValue) {
    this.id = get(transaction, 'id', '');
    this.transactionDate = get(transaction, 'transactionDate', moment().format(DATE_FORMAT.YYYY_MM_DD));
    this.officeId = get(transaction, 'officeId', '');
    this.office = new OfficeInfo(get(transaction, 'office', {}));
    this.expenseTypeId = get(transaction, 'expenseTypeId', '');
    this.expenseType = defaultTo(get(transaction, 'expenseType'), {
      name: '',
      id: '',
      isPersonal: false,
    });
    this.incomeTypeId = get(transaction, 'incomeTypeId', '');
    this.incomeType = defaultTo(get(transaction, 'incomeType'), {
      id: '',
      name: '',
    });
    this.officePayerRecipientId = get(transaction, 'officePayerRecipientId', '');
    this.officePayerRecipient = new OfficeInfo(get(transaction, 'officePayerRecipient', {}));
    this.clientPayerRecipientId = get(transaction, 'clientPayerRecipientId', '');
    this.clientPayerRecipient = new Client(get(transaction, 'clientPayerRecipient', {}));
    this.supplierPayerRecipientId = get(transaction, 'supplierPayerRecipientId', '');
    this.supplierPayerRecipient = defaultTo(get(transaction, 'supplierPayerRecipient'), {
      name: '',
      id: '',
      currency: {
        id: '',
        isBaseCurrency: false,
        name: '',
      },
      currencyId: '',
    });
    this.userPayerRecipientId = get(transaction, 'userPayerRecipientId', '');
    this.userPayerRecipient = new UserPreviewInfo(get(transaction, 'userPayerRecipient', {}));
    this.financeProjectId = get(transaction, 'financeProjectId', '');
    this.financeProject = new FinanceProject(get(transaction, 'financeProject', {}));
    const amount = get(transaction, 'amount', '0.00');
    this.amount = new CurrencyFormatter({
      float: Number(amount),
      value: amount,
      formatted: amount,
    });
    const unifiedAmount = get(transaction, 'unifiedAmount', '0.00');
    this.unifiedAmount = new CurrencyFormatter({
      float: Number(unifiedAmount),
      value: unifiedAmount,
      formatted: unifiedAmount,
    });
    this.currencyId = get(transaction, 'currencyId', '');
    this.currency = defaultTo(get(transaction, 'currency'), {
      name: '',
      id: '',
      isBaseCurrency: false,
    });
    const rate = get(transaction, 'rate', '0.00');

    this.rate = new CurrencyFormatter({
      float: Number(rate),
      value: rate.toString() || '0.00',
      formatted: rate.toString() || '0.00',
    });
    this.comment = get(transaction, 'comment', '');
    this.lastUpdate = get(transaction, 'lastUpdate', '');
    this.updaterId = get(transaction, 'updaterId', '');
    this.updater = new UserPreviewInfo(get(transaction, 'updater', {}));
  }
}

enum DetailsOperationEnum {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

const amountValueRegExp = new RegExp(/\d+((\.|\,)\d+)?/);
const transactionsDateFormats = [
  'M/D/YYYY HH:mm',
  'D/M/YYYY HH:mm',
  'M.D.YYYY HH:mm',
  'D.M.YYYY HH:mm',
  'M-D-YYYY HH:mm',
  'D-M-YYYY HH:mm',
  'YYYY/M/D HH:mm',
  'YYYY/D/M HH:mm',
  'YYYY.M.D HH:mm',
  'YYYY.D.M HH:mm',
  'YYYY-M-D HH:mm',
  'YYYY-D-M HH:mm',
  'M/D/YYYY',
  'D/M/YYYY',
  'M.D.YYYY',
  'D.M.YYYY',
  'M-D-YYYY',
  'D-M-YYYY',
  'YYYY/M/D',
  'YYYY/D/M',
  'YYYY.M.D',
  'YYYY.D.M',
  'YYYY-M-D',
  'YYYY-D-M',
];

const getAmount = (amount: string, decimalSeparator: DecimalSeparatorEnum) => {
  if (!amount) {
    return { amount: '0.00', isValid: true };
  }
  let isValid = true;
  let formattedAmount = amount;
  if (amount) {
    switch (decimalSeparator) {
      case DecimalSeparatorEnum.DOT:
        //@ts-ignore
        if ([...formattedAmount.matchAll(/\./g)].length > 1) {
          isValid = false;
          formattedAmount = '0.00';
        } else {
          formattedAmount = formattedAmount.replaceAll(',', '').replaceAll(' ', '');
        }

        break;

      case DecimalSeparatorEnum.COMMA:
        //@ts-ignore
        if ([...formattedAmount.matchAll(/,/g)].length > 1) {
          isValid = false;
          formattedAmount = '0.00';
        } else {
          formattedAmount = formattedAmount.replaceAll('.', '').replaceAll(' ', '').replace(',', '.');
        }

        break;
    }
  }

  const amountValue = formattedAmount.match(amountValueRegExp);

  if (amountValue) {
    return { amount: Number(amountValue[0]).toFixed(2), isValid };
  }

  return { amount: '0.00', isValid: false };
};

const getOperationsType = (operationType: string) => {
  const formattedOperationType = operationType.toUpperCase().trim();

  if (!formattedOperationType) {
    return { type: null, isValid: true };
  }

  let isValid = true;
  let type = null;
  if (formattedOperationType.includes(DetailsOperationEnum.DEBIT)) {
    type = ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES;
  } else if (formattedOperationType.includes(DetailsOperationEnum.CREDIT)) {
    type = ExpenseIncomeTypesGroupEnum.INCOME_TYPES;
  } else {
    isValid = false;
  }
  return { type, isValid };
};

export class ImportTransaction extends Transaction {
  operationType: ExpenseIncomeTypesGroupEnum | null;
  importTransaction: boolean;
  transactionDateOriginalValue: string;
  isTransactionDateParsedCorrectly: boolean;
  transactionDateHasParseError: boolean;
  amountOriginalValue: string;
  isAmountParsedCorrectly: boolean;
  amountHasParseError: boolean;
  operationTypeOriginalValue: string;
  isOperationTypeParsedCorrectly: boolean;
  operationTypeHasParseError: boolean;

  constructor(
    transaction: TransactionInitialValue & Partial<{ operationType: string }>,
    decimalSeparator: DecimalSeparatorEnum,
  ) {
    super(transaction);
    this.id = get(transaction, 'id', v4());
    const date = get(transaction, 'transactionDate', '').trim();
    this.transactionDateOriginalValue = date;
    const isValidTransactionDate = moment(date, transactionsDateFormats, true).isValid();
    this.isTransactionDateParsedCorrectly = !date || isValidTransactionDate;
    this.transactionDateHasParseError = !this.isTransactionDateParsedCorrectly;
    this.transactionDate =
      date && isValidTransactionDate ? moment(date, transactionsDateFormats, true).format(DATE_FORMAT.YYYY_MM_DD) : '';

    const amountValue = get(transaction, 'amount', '');
    this.amountOriginalValue = amountValue;
    const { amount, isValid: isValidAmount } = getAmount(amountValue, decimalSeparator);
    this.amount = new CurrencyFormatter({
      float: Number(amount),
      value: amount.toString(),
      formatted: amount.toString(),
    });
    this.isAmountParsedCorrectly = isValidAmount;
    this.amountHasParseError = !this.isAmountParsedCorrectly;
    const typeValue = get(transaction, 'operationType', '');
    this.operationTypeOriginalValue = typeValue;
    const { type, isValid: isValidOperationType } = getOperationsType(typeValue);
    this.operationType = type;
    this.isOperationTypeParsedCorrectly = isValidOperationType;
    this.operationTypeHasParseError = !this.isOperationTypeParsedCorrectly;
    this.importTransaction = get(transaction, 'importTransaction', true);
  }
}

const transactionsValidateObject = {
  transactionDate: yup
    .string()
    .test('transactionDate', intl.formatMessage(messages.transactionDateIncorrect), (value: string | undefined) =>
      moment(value, true).isValid(),
    )
    .test('transactionDate', intl.formatMessage(messages.transactionDateTestError), (value: string | undefined) => {
      const date = moment(value, true);
      return date.isAfter(moment().subtract(2, 'y'), 'y');
    })
    .required(intl.formatMessage(messages.required)),
  officeId: yup.string().required(intl.formatMessage(messages.required)),
  expenseTypeId: yup
    .string()
    .test('expenseTypeId', intl.formatMessage(messages.required), (value: string | undefined, testContext: any) => {
      const incomeTypeId = testContext.from[0].value.incomeTypeId;
      return value || incomeTypeId;
    })
    .nullable(),
  officePayerRecipientId: yup
    .string()
    .test(
      'officePayerRecipientId',
      intl.formatMessage(messages.required),
      (value: string | undefined, testContext: any) => {
        const transactionValue = testContext.from[0].value;
        const clientPayerRecipientId = transactionValue.clientPayerRecipientId;
        const supplierPayerRecipientId = transactionValue.supplierPayerRecipientId;
        const userPayerRecipientId = transactionValue.userPayerRecipientId;
        return Boolean(value || clientPayerRecipientId || supplierPayerRecipientId || userPayerRecipientId);
      },
    )
    .nullable(),
  amount: yup.object({
    float: yup.number().required(intl.formatMessage(messages.required)),
  }),
  financeProjectId: yup
    .string()
    .when('clientPayerRecipientId', {
      is: (id: string) => id,
      then: yup.string().required(intl.formatMessage(messages.required)),
    })
    .nullable(),
  unifiedAmount: yup.object({
    float: yup.number().required(intl.formatMessage(messages.required)),
  }),
  rate: yup.object({
    float: yup.number().required(intl.formatMessage(messages.required)),
  }),
  currencyId: yup.string().required(intl.formatMessage(messages.required)),
};

const transactionsShape = yup.object(transactionsValidateObject);
const getPatternAfterComma = (count: number) => new RegExp(`^\\d+(\\.\\d{0,${count}})?$`);

const validateAmount = (val?: number, maxLength = 11, decimalLimit = 2) => {
  if (!val) {
    return true;
  }

  const value = val.toFixed(decimalLimit).toString();
  return getPatternAfterComma(decimalLimit).test(value) && value.length - 1 <= maxLength;
};

export const TRANSACTION_SCHEMA = transactionsShape;

export const IMPORT_TRANSACTIONS_SCHEMA = yup.object().shape({
  transactions: yup.array().of(
    yup.object().shape({
      transactionDate: yup.string().when('importTransaction', {
        is: true,
        then: () => transactionsValidateObject.transactionDate,
      }),
      officeId: yup.string().when('importTransaction', {
        is: true,
        then: () => transactionsValidateObject.officeId,
      }),
      expenseTypeId: yup.string().when('importTransaction', {
        is: true,
        then: () => transactionsValidateObject.expenseTypeId,
      }),
      officePayerRecipientId: yup.string().when('importTransaction', {
        is: true,
        then: () => transactionsValidateObject.officePayerRecipientId,
      }),
      amount: yup.object().when('importTransaction', {
        is: true,
        then: () =>
          yup.object({
            float: yup
              .number()
              .test('amount', intl.formatMessage(messages.transactionAmountTestError), val => validateAmount(val))
              .required(intl.formatMessage(messages.required)),
          }),
      }),
      financeProjectId: yup.string().when('importTransaction', {
        is: true,
        then: () => transactionsValidateObject.financeProjectId,
      }),
      unifiedAmount: yup.object().when('importTransaction', {
        is: true,
        then: () =>
          yup.object({
            float: yup
              .number()
              .test('unifiedAmount', intl.formatMessage(messages.transactionUnifiedAmountTestError), val =>
                validateAmount(val),
              )
              .required(intl.formatMessage(messages.required)),
          }),
      }),
      rate: yup.object().when('importTransaction', {
        is: true,
        then: () =>
          yup.object({
            float: yup
              .number()
              .test('rate', intl.formatMessage(messages.transactionRateTestError), val => validateAmount(val, 9, 4))
              .required(intl.formatMessage(messages.required)),
          }),
      }),
      currencyId: yup.string().when('importTransaction', {
        is: true,
        then: () => transactionsValidateObject.currencyId,
      }),
      operationType: yup
        .string()
        .nullable()
        .when('importTransaction', {
          is: true,
          then: yup.string().required(intl.formatMessage(messages.required)).nullable(),
        }),
    }),
  ),
});

export class CurrencyFormatter {
  float: number | null;
  value: string;
  formatted: string;
  constructor(value?: Partial<CurrencyFormatter>) {
    this.float = get(value, 'float', 0);
    this.value = get(value, 'value', '');
    this.formatted = get(value, 'formatted', '');
  }
}

export class FinancePlan {
  id: string;
  client: Client;
  clientId: string;
  financeProjectId: string;
  financeProject: FinanceProject;
  year: string;
  month: string;
  plannedIncome: CurrencyFormatter;
  calculatedPlannedIncome: string;
  baseRate: CurrencyFormatter;
  employees: FinancePlanUser[];
  currencyId: string;
  currency: financeTypes.CurrencyType;
  currencyRate: CurrencyFormatter;
  currencyWorkHourRate: CurrencyFormatter;
  date: string;

  constructor(item?: unknown) {
    this.id = get(item, 'id', '');
    this.client = get(item, 'client');
    this.clientId = get(item, 'clientId');
    this.financeProjectId = get(item, 'financeProjectId');
    this.financeProject = get(item, 'financeProject');
    this.date = get(item, 'month', '');
    this.year = this.date ? moment(this.date).get('y').toString() : '';
    this.month = this.date ? moment(this.date).get('M').toString() : '';
    this.calculatedPlannedIncome = get(item, 'calculatedPlannedIncome', '');

    const plannedIncome = get(item, 'plannedIncome', '0.00');
    this.plannedIncome = new CurrencyFormatter({
      float: Number(plannedIncome),
      value: plannedIncome.toString(),
      formatted: plannedIncome.toString(),
    });

    const baseRate = get(item, 'baseRate', '0.00');
    this.baseRate = new CurrencyFormatter({
      float: Number(baseRate),
      value: baseRate.toString(),
      formatted: baseRate.toString(),
    });

    this.employees = get(item, 'employees', []).map((user: FinancePlanUser) => new FinancePlanUser(user));
    this.currencyId = get(item, 'currencyId');
    this.currency = get(item, 'currency');

    const currencyRate = get(item, 'currencyRate', '0.00');
    this.currencyRate = new CurrencyFormatter({
      float: Number(currencyRate),
      value: currencyRate.toString(),
      formatted: currencyRate.toString(),
    });

    const currencyWorkHourRate = get(item, 'currencyWorkHourRate', '0.00');
    this.currencyWorkHourRate = new CurrencyFormatter({
      float: Number(currencyWorkHourRate),
      value: currencyWorkHourRate.toString(),
      formatted: currencyWorkHourRate.toString(),
    });
  }
}

export const PLAN_SCHEMA = yup.object().shape({
  financeProjectId: yup.string().trim().required('Required').nullable(),
  financeProject: yup.object({
    isActive: yup.boolean().isTrue('Finance project is not active'),
  }),
  clientId: yup.string().trim().required('Required'),
  client: yup.object({
    isActive: yup.boolean().isTrue('Client is not active'),
  }),
  month: yup.string().trim().required('Required').nullable(),
  year: yup.string().trim().required('Required').nullable(),
  currencyWorkHourRate: yup.object({
    float: yup.number().required('Required'),
  }),
  currencyRate: yup.object({
    float: yup.number().required('Required'),
  }),
  baseRate: yup.object({
    float: yup.number().required('Required'),
  }),
});

export const CLONE_PLAN_SCHEMA = PLAN_SCHEMA.shape({
  month: yup
    .string()
    .trim()
    .required('Required')
    .test('month', 'Choose another month', (value: any, testContext: any) => {
      const oldMonth = testContext.from[0].value.oldMonth;
      return value + '-01' !== oldMonth;
    })
    .nullable(),
});

export const IMPORT_RESOURCES_SCHEMA = yup.object().shape({
  month: yup.string().trim().required('Required').nullable(),
  year: yup.string().trim().required('Required').nullable(),
  projectGroupId: yup.string().required('Required'),
});

export const PLAN_USER_SCHEMA = yup.object().shape({
  userId: yup.string().trim().required('Required'),
  currentLoad: yup.object({
    rate: yup.object({
      float: yup.number().required('Required').nullable(),
    }),
    workHours: yup.object({
      float: yup.number().required('Required').nullable(),
    }),
    hoursPercent: yup.object({
      float: yup.number().required('Required').nullable(),
    }),
  }),
});

export class FinancePlanUser {
  id: string;
  userId: string;
  user: UserPreviewInfo;
  rate: CurrencyFormatter;
  allWorkHours: string;
  busyWorkHours: string;
  workHours: string;
  hoursPercent: number;

  constructor(item?: unknown) {
    this.id = get(item, 'id');
    this.userId = get(item, 'userId');
    this.user = new UserPreviewInfo(get(item, 'user', {}));
    const rate = get(item, 'rate', '0.00');
    this.rate = new CurrencyFormatter({
      float: Number(rate),
      value: rate.toString(),
      formatted: rate.toString(),
    });

    this.allWorkHours = get(item, 'allWorkHours');
    this.busyWorkHours = get(item, 'busyWorkHours');
    this.workHours = get(item, 'workHours');
    this.hoursPercent = get(item, 'hoursPercent');
  }
}

export class ProjectIncomesReportItem {
  client: Client;
  clientId: string;
  entries: { planIncome: 0; actualIncome: 0; month: '2023-04-01' }[];
  financeProject: FinanceProject;
  financeProjectId: string;

  constructor(item?: unknown) {
    this.client = get(item, 'client');
    this.clientId = get(item, 'clientId');
    this.entries = get(item, 'entries', []);
    this.financeProject = get(item, 'financeProject');
    this.financeProjectId = get(item, 'financeProjectId');
  }

  get allEntriesPlanIncome() {
    return this.entries.reduce((sum, current) => sum + current.planIncome, 0);
  }

  get allEntriesActualIncome() {
    return this.entries.reduce((sum, current) => sum + current.actualIncome, 0);
  }
}

export class IncomesReportItem {
  client: Client;
  clientId: string;
  reportParts: ProjectIncomesReportItem[];
  entries: { planIncome: 0; actualIncome: 0; month: '2023-04-01' }[];
  totalItem: IncomesTotalOptions | null;

  constructor(item?: unknown) {
    this.client = get(item, 'client');
    this.clientId = get(item, 'clientId');
    this.entries = get(item, 'entries', []);
    this.reportParts = get(item, 'reportParts', []).map(
      (item: ProjectIncomesReportItem) => new ProjectIncomesReportItem(item),
    );
    this.totalItem = get(item, 'totalItem', null);
  }

  get allEntriesPlanIncome() {
    return this.entries.reduce((sum, current) => sum + current.planIncome, 0);
  }

  get allEntriesActualIncome() {
    return this.entries.reduce((sum, current) => sum + current.actualIncome, 0);
  }
}

export class ExpensesReportItem {
  entries: { amount: number; month: string; plannedAmount: number }[];
  expenseType: financeTypes.ExpenseType;
  expenseTypeId: string;
  totalItem: boolean;

  constructor(item?: unknown) {
    this.entries = get(item, 'entries', []);
    this.expenseType = get(item, 'expenseType');
    this.expenseTypeId = get(item, 'expenseTypeId');
    this.totalItem = get(item, 'totalItem', false);
  }

  get allEntriesAmount() {
    return this.entries.reduce((sum, current) => sum + current.amount, 0);
  }

  get allEntriesPlannedAmount() {
    return this.entries.reduce((sum, current) => sum + current.plannedAmount, 0);
  }
}

export class EmployeeHours {
  allWorkHours: string;
  busyWorkHours: string;
  planLoads: financeTypes.EmployeeHoursPlanLoadType[];
  user: UserPreviewInfo;
  userId: string;

  constructor(hoursInfo?: unknown) {
    this.allWorkHours = get(hoursInfo, 'allWorkHours', '');
    this.busyWorkHours = get(hoursInfo, 'busyWorkHours', '');
    this.planLoads = get(hoursInfo, 'planLoads', []).map(
      (el: {
        workHours: string;
        hoursPercent: string;
        financePlanId: string;
        rate: string;
        financePlan: Record<string, any>;
      }) => ({
        ...el,
        workHours: new CurrencyFormatter({ value: el.workHours, float: Number(el.workHours), formatted: el.workHours }),
        hoursPercent: new CurrencyFormatter({
          value: el.hoursPercent,
          float: Number(el.hoursPercent),
          formatted: el.hoursPercent,
        }),
        financePlan: new FinancePlan(el.financePlan),
        rate: new CurrencyFormatter({
          float: Number(el.rate),
          value: el.rate.toString(),
          formatted: el.rate.toString(),
        }),
      }),
    );
    this.user = new UserPreviewInfo(get(hoursInfo, 'user', {}));
    this.userId = get(hoursInfo, 'userId', '');
  }
}

export class ChekedInfo {
  originalValue: string;
  newValue: string;
  user: UserPreviewInfo;
  constructor(info: Partial<ChekedInfo>) {
    this.originalValue = get(info, 'originalValue', '0');
    this.newValue = get(info, 'newValue', '0');
    this.user = new UserPreviewInfo(get(info, 'user', {}));
  }
}

export class PlannedExpenseClass {
  id: string;
  startDate: string;
  startYear: string;
  startMonth: string;
  endDate: string;
  endYear: string;
  endMonth: string;
  expenseTypeId: string;
  expenseType: financeTypes.ExpenseType;
  officeId: string;
  office: {
    id: string;
    name: string;
    isDeleted: boolean;
  };
  amount: CurrencyFormatter;

  constructor(item?: unknown) {
    this.id = get(item, 'id');
    const amount = get(item, 'amount', null);
    this.amount = new CurrencyFormatter({
      float: !isNil(amount) ? Number(amount) : null,
      value: amount,
      formatted: amount,
    });
    this.endDate = get(item, 'endDate', moment().format());
    this.endYear = this.endDate ? moment(this.endDate).get('y').toString() : '';
    this.endMonth = this.endDate ? moment(this.endDate).get('M').toString() : '';
    this.startDate = get(item, 'startDate', moment().format());
    this.startYear = this.startDate ? moment(this.startDate).get('y').toString() : '';
    this.startMonth = this.startDate ? moment(this.startDate).get('M').toString() : '';
    this.expenseTypeId = get(item, 'expenseTypeId', '');
    this.expenseType = get(item, 'expenseType');
    this.officeId = get(item, 'officeId', '');
    this.office = get(item, 'office');
  }
}

export type MonthReportParts = {
  amount: CurrencyFormatter;
  month: string;
  expenseId: string;
};

export class PlannedExpenseOffice {
  office: {
    id: string;
    name: string;
    isDeleted: true;
  };
  monthReportParts: MonthReportParts[];
  total: CurrencyFormatter;
  expenseType: financeTypes.ExpenseType;

  constructor(item?: unknown) {
    this.office = get(item, 'office');
    this.monthReportParts = get(item, 'monthReportParts', []).map((item: { amount: string }) => ({
      ...item,
      amount: new CurrencyFormatter({
        float: !isNil(item.amount) ? Number(item.amount) : null,
        value: item.amount,
        formatted: item.amount,
      }),
    }));
    const total = get(item, 'total', null);
    this.total = new CurrencyFormatter({
      float: !isNil(total) ? Number(total) : null,
      value: total,
      formatted: total,
    });
    this.expenseType = get(item, 'expenseType');
  }
}

export class PlannedExpenseReportItem {
  total: CurrencyFormatter;
  expenseType: financeTypes.ExpenseType;
  monthReportParts: MonthReportParts[];
  officeReportParts: PlannedExpenseOffice[];
  totalItem: boolean;

  constructor(item?: unknown) {
    this.expenseType = get(item, 'expenseType');
    const total = get(item, 'total', null);
    this.total = new CurrencyFormatter({
      float: !isNil(total) ? Number(total) : null,
      value: total,
      formatted: total,
    });
    this.monthReportParts = get(item, 'monthReportParts', []).map((item: { amount: string }) => ({
      ...item,
      amount: new CurrencyFormatter({
        float: !isNil(item.amount) ? Number(item.amount) : null,
        value: item.amount,
        formatted: item.amount,
      }),
    }));
    this.officeReportParts = get(item, 'officeReportParts', []).map(
      (item: PlannedExpenseOffice) => new PlannedExpenseOffice({ ...item, expenseType: this.expenseType }),
    );
    this.totalItem = get(item, 'totalItem', false);
  }
}

export const PLANNED_EXPENSE_SCHEMA = yup.object().shape({
  endDate: yup
    .date()
    .when(
      'startDate',
      (startDate: any, yup: any) => startDate && yup.min(startDate, 'End Date must be equal or later than Start Date'),
    )
    .nullable()
    .required(intl.formatMessage(messages.required)),
  startDate: yup.string().nullable().required(intl.formatMessage(messages.required)),
  amount: yup.object({
    float: yup
      .number()
      .typeError('Required')
      .min(0, 'Min 0')
      .max(9999999, 'Max 9999999')
      .integer('Should be integer')
      .required('Required'),
  }),
});
