import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/competencies.contsants';
import * as api from '../api/competencies.api';
import { AnyAction } from 'redux';
import * as paramsTypes from '../enums/params/competencies.params';
import { getQueryParams } from '../utils/params.utils';

function* getCompetenciesList() {
  const params: paramsTypes.CompetenciesParams = yield select(
    (state: RootState) => state.competencies.competenciesParams,
  );
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getCompetenciesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_COMPETENCIES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPETENCIES_LIST_FAILURE, payload: error });
  }
}

function* getCompetence({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getCompetence, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.GET_COMPETENCE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPETENCE_FAILURE, payload: error });
  }
}

function* createNewCompetence({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewCompetence, payload.data);
    if (success) {
      yield put({ type: ActionTypes.POST_COMPETENCE_SUCCESS });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_COMPETENCE_FAILURE, payload: error });
  }
}

function* deleteCompetence({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteCompetence, payload.data);
    if (success) {
      yield put({ type: ActionTypes.DELETE_COMPETENCE_SUCCESS });
      payload.callback();
      yield call(getCompetenciesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_COMPETENCE_FAILURE, payload: error });
  }
}

function* editCompetence({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editCompetence, payload.data);
    if (success) {
      yield put({ type: ActionTypes.PUT_COMPETENCE_SUCCESS });
      payload.callback();
      yield call(getCompetenciesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_COMPETENCE_FAILURE, payload: error });
  }
}

function* getCompetenceTypesList() {
  const params: paramsTypes.CompetenciesTypesParams = yield select(
    (state: RootState) => state.competencies.competenciesTypesParams,
  );
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getCompetenceTypesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_COMPETENCIES_TYPES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPETENCIES_TYPES_LIST_FAILURE, payload: error });
  }
}

function* createNewCompetenceType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createCompetenceType, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_COMPETENCIES_TYPE_SUCCESS });
      payload.callback();
      yield call(getCompetenceTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_COMPETENCIES_TYPE_FAILURE, payload: error });
  }
}

function* deleteCompetenceType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteCompetenceType, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_COMPETENCIES_TYPE_SUCCESS });
      payload.callback();
      yield call(getCompetenceTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_COMPETENCIES_TYPE_FAILURE, payload: error });
  }
}

function* editCompetenceType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editCompetenceType, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_COMPETENCIES_TYPE_SUCCESS });
      payload.callback();
      yield call(getCompetenceTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_COMPETENCIES_TYPE_FAILURE, payload: error });
  }
}

function* deleteAssessment({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteAssessment, payload.id);

    if (success) {
      yield put({ type: ActionTypes.DELETE_ASSESSMENT_SUCCESS });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_ASSESSMENT_FAILURE, payload: error });
  }
}

function* getAssessments() {
  try {
    const { success, results } = yield call(api.getFilteredAssessments);

    if (success) {
      yield put({
        type: ActionTypes.GET_FILTERED_ASSESSMENTS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FILTERED_ASSESSMENTS_FAILURE, payload: error });
  }
}

function* getFilteredAssessments() {
  const params: paramsTypes.AssessmentsParams = yield select(
    (state: RootState) => state.competencies.assessmentsParams,
  );
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getFilteredAssessments, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_FILTERED_ASSESSMENTS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FILTERED_ASSESSMENTS_FAILURE, payload: error });
  }
}

function* createNewAssessment({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewAssessment, payload.data);
    if (success) {
      yield put({ type: ActionTypes.POST_NEW_ASSESSMENT_SUCCESS });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_NEW_ASSESSMENT_FAILURE, payload: error });
  }
}

function* editAssessment({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editAssessment, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_ASSESSMENT_SUCCESS });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_ASSESSMENT_FAILURE, payload: error });
  }
}

function* getAssessment({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getAssessment, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.GET_ASSESSMENT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ASSESSMENT_FAILURE, payload: error });
  }
}

function* getLastAssessmentResults({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getLastAssessmentResults, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.GET_LAST_ASSESSMENT_RESULTS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_LAST_ASSESSMENT_RESULTS_FAILURE, payload: error });
  }
}

function* getSkillQuestionsList() {
  const params: paramsTypes.SkillsQuestionsParams = yield select(
    (state: RootState) => state.competencies.skillQuestionsParams,
  );
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getSkillQuestionsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_SKILLS_QUESTIONS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SKILLS_QUESTIONS_LIST_FAILURE, payload: error });
  }
}

function* getSkillQuestions({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getSkillQuestions, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.GET_SKILL_QUESTIONS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SKILL_QUESTIONS_FAILURE, payload: error });
  }
}

function* createSkillQuestions({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewSkillQuestions, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_SKILL_QUESTIONS_SUCCESS });
      payload.callback();
      yield call(getSkillQuestionsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_SKILL_QUESTIONS_FAILURE, payload: error });
  }
}

function* editSkillQuestions({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editSkillQuestions, payload.data, payload.skillId);
    if (success) {
      yield put({ type: ActionTypes.PUT_SKILL_QUESTIONS_SUCCESS });
      payload.callback();
      yield call(getSkillQuestionsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_SKILL_QUESTIONS_FAILURE, payload: error });
  }
}

function* getCompetenciesPercentage({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getCompetenciesPercentage, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_COMPETENCIES_PERCENTAGE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPETENCIES_PERCENTAGE_FAILURE, payload: error });
  }
}

function* getSpecializationsPercentage({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getSpecializationsPercentage, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_SPECIALIZATIONS_PERCENTAGE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SPECIALIZATIONS_PERCENTAGE_FAILURE, payload: error });
  }
}

function* getUserCompetenceAssessments({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getUserCompetenceAssessments, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_USER_COMPETENCE_ASSESSMENTS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_COMPETENCE_ASSESSMENTS_FAILURE, payload: error });
  }
}

function* getLevelsPercentage({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getLevelsPercentage, payload.userId, payload.competenceId);

    if (success) {
      yield put({
        type: ActionTypes.GET_COMPETENCE_LEVELS_PERCENTAGE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPETENCE_LEVELS_PERCENTAGE_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeEvery(ActionTypes.GET_COMPETENCIES_LIST_REQUEST, getCompetenciesList),
    takeEvery(ActionTypes.GET_COMPETENCE_REQUEST, getCompetence),
    takeEvery(ActionTypes.GET_COMPETENCIES_TYPES_LIST_REQUEST, getCompetenceTypesList),
    takeEvery(ActionTypes.SET_COMPETENCIES_TYPES_PARAMS, getCompetenceTypesList),
    takeEvery(ActionTypes.GET_FILTERED_ASSESSMENTS_REQUEST, getFilteredAssessments),
    takeEvery(ActionTypes.SET_ASSESSMENTS_PARAMS_REQUEST, getFilteredAssessments),
    takeEvery(ActionTypes.GET_ASSESSMENT_REQUEST, getAssessment),
    takeEvery(ActionTypes.GET_LAST_ASSESSMENT_RESULTS_REQUEST, getLastAssessmentResults),
    takeEvery(ActionTypes.GET_ASSESSMENTS_REQUEST, getAssessments),
    takeEvery(ActionTypes.POST_COMPETENCIES_TYPE_REQUEST, createNewCompetenceType),
    takeEvery(ActionTypes.POST_COMPETENCE_REQUEST, createNewCompetence),
    takeEvery(ActionTypes.POST_NEW_ASSESSMENT_REQUEST, createNewAssessment),
    takeEvery(ActionTypes.DELETE_COMPETENCIES_TYPE_REQUEST, deleteCompetenceType),
    takeEvery(ActionTypes.DELETE_ASSESSMENT_REQUEST, deleteAssessment),
    takeEvery(ActionTypes.DELETE_COMPETENCE_REQUEST, deleteCompetence),
    takeEvery(ActionTypes.PUT_COMPETENCIES_TYPE_REQUEST, editCompetenceType),
    takeEvery(ActionTypes.PUT_COMPETENCE_REQUEST, editCompetence),
    takeEvery(ActionTypes.PUT_ASSESSMENT_REQUEST, editAssessment),
    takeEvery(ActionTypes.SET_COMPETENCIES_PARAMS, getCompetenciesList),
    takeEvery(ActionTypes.GET_SKILLS_QUESTIONS_LIST_REQUEST, getSkillQuestionsList),
    takeEvery(ActionTypes.GET_SKILL_QUESTIONS_REQUEST, getSkillQuestions),
    takeEvery(ActionTypes.POST_SKILL_QUESTIONS_REQUEST, createSkillQuestions),
    takeEvery(ActionTypes.PUT_SKILL_QUESTIONS_REQUEST, editSkillQuestions),
    takeEvery(ActionTypes.SET_SKILLS_QUESTIONS_PARAMS_REQUEST, getSkillQuestionsList),
    takeEvery(ActionTypes.GET_COMPETENCIES_PERCENTAGE_REQUEST, getCompetenciesPercentage),
    takeEvery(ActionTypes.GET_SPECIALIZATIONS_PERCENTAGE_REQUEST, getSpecializationsPercentage),
    takeEvery(ActionTypes.GET_USER_COMPETENCE_ASSESSMENTS_REQUEST, getUserCompetenceAssessments),
    takeEvery(ActionTypes.GET_COMPETENCE_LEVELS_PERCENTAGE_REQUEST, getLevelsPercentage),
  ]);
}
