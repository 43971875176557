import { get } from 'lodash-es';
import { SortParams } from '../params.enum';
import * as yup from 'yup';

export class CandidateStatusParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('position', params);
    this.size = get(params, 'size', 1000);
  }
}

export class CandidateSpecializationParams extends SortParams {
  size: number;
  page: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
  }
}

export class CandidateTechnologyParams extends SortParams {
  size: number;
  page: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
  }
}

export class CandidateReceivingSourceParams extends SortParams {
  size: number;
  page: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
  }
}

export class CandidateParams extends SortParams {
  search: string;
  candidateIds: string[];
  name: string;
  statusIds: string[];
  experienceFrom: number | string;
  experienceTo: number | string;
  specializationIds: string[];
  technologyIds: string[];
  hasLeadExperience: boolean;
  receivingSourceIds: string[];
  createDateFrom: string;
  createDateTo: string;
  updateDateFrom: string;
  updateDateTo: string;
  commentDateFrom: string;
  commentDateTo: string;
  size: number;
  page: number;
  constructor(params?: unknown) {
    super('lastName', params);
    this.search = get(params, 'search', '');
    this.candidateIds = get(params, 'candidateIds', []);
    this.name = get(params, 'name', '');
    this.statusIds = get(params, 'statusIds', []);
    this.experienceFrom = get(params, 'experienceFrom', '');
    this.experienceTo = get(params, 'experienceTo', '');
    this.specializationIds = get(params, 'specializationIds', []);
    this.technologyIds = get(params, 'technologyIds', []);
    this.hasLeadExperience = get(params, 'hasLeadExperience', null);
    this.receivingSourceIds = get(params, 'receivingSourceIds', []);
    this.createDateFrom = get(params, 'createDateFrom', '');
    this.createDateTo = get(params, 'createDateTo', '');
    this.updateDateFrom = get(params, 'updateDateFrom', '');
    this.updateDateTo = get(params, 'updateDateTo', '');
    this.commentDateFrom = get(params, 'commentDateFrom', '');
    this.commentDateTo = get(params, 'commentDateTo', '');
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
  }
}

export const CANDIDATE_EXPERIENCE_FILTER_SCHEMA = yup.object().shape({
  from: yup.number().nullable(),
  to: yup
    .number()
    .test('to', 'Value must be more or equal', (value: number | undefined, testContext: any) => {
      const fromValue: number | null = testContext.from[0].value.from;
      return !value || !fromValue || value >= fromValue;
    })
    .nullable(),
});
