import React from 'react';
import { CommentInfo } from '../../enums/schedule.enum';
import Dropdown from '../Dropdown';
import Icon from '../Icon';
import Avatar from '../Profile/Avatar';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { isEmpty } from 'lodash-es';
import { CommmentDropdownInfoType } from '.';
import classNames from 'classnames';

type CommentProps<CommentT> = {
  comment: CommentT &
    Partial<{
      replyTo: any;
      replyToOriginalMessage: string;
      replyToOriginalLastModifiedDate: string;
      isDeleted: boolean;
    }>;
  tableActions?: any;
  externalClassName?: string;
  commentPage?: number;
  isReplayed?: boolean;
  isFormReplayedComment?: boolean;
  control?: React.ReactElement;
  replyToOriginalMessage?: string;
  replyToOriginalLastModifiedDate?: string;
};

function Comment<CommentT extends CommentInfo>({
  comment,
  tableActions,
  commentPage = 0,
  isReplayed,
  externalClassName,
  isFormReplayedComment,
  control,
  replyToOriginalMessage,
  replyToOriginalLastModifiedDate,
}: CommentProps<CommentT>) {
  const commmentDropdownInfo: CommmentDropdownInfoType<CommentT> = { comment, commentPage };

  const defaulClass = classNames('page__event__comment', {
    [externalClassName as string]: externalClassName,
    'replayed-comment': isReplayed,
  });

  return (
    <div className={defaulClass}>
      <div className="page__event__comment-head">
        {comment.author && (
          <div className="page__event__comment-author">
            <Avatar
              isUserPageLink
              customFontSize={10}
              userInfo={comment.author}
              externalClass="page__event__comment-author-avatar"
              size={isReplayed ? 'small' : 'medium'}
              fileSize={isReplayed ? 48 : 72}
            />
            <span className="page__event__comment-author-name">{comment.author.fullName}</span>
          </div>
        )}

        <div className="page__event__comment-head__wrapper">
          <div className="page__event__comment-published">
            <FormattedMessage
              {...messages.commentPublishedLabel}
              values={{
                commentDate: comment.commentDate,
                isEdited: comment.isCommentEdit(replyToOriginalLastModifiedDate),
              }}
            />
          </div>
          {!isEmpty(tableActions) && (
            <Dropdown
              dropdownClass="dropdown--no-bg"
              dropdownToggle={<Icon iconName={'dots'} externalClass={'dropdown__button-main-icon'} />}
              dropdownList={tableActions}
              dropdownInfo={commmentDropdownInfo}
            />
          )}
          {control}
        </div>
      </div>
      <div className="page__event__comment-content">
        {comment.replyTo && !isFormReplayedComment && (
          <Comment
            comment={comment.replyTo}
            replyToOriginalMessage={comment.replyToOriginalMessage}
            replyToOriginalLastModifiedDate={comment.replyToOriginalLastModifiedDate}
            isReplayed
            externalClassName="quoted"
          />
        )}
        <span>{replyToOriginalMessage ? replyToOriginalMessage : comment.message}</span>
      </div>
    </div>
  );
}

export default Comment;
