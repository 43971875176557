import React from 'react';

type ProfileInfoBlockType = { title: string; children: React.ReactNode };

const ProfileInfoBlock = ({ title, children }: ProfileInfoBlockType) => {
  return (
    <div className="tabs__content-item__about-info-wrapper">
      <div className="tabs__content-item__about-info">
        <div className="tabs__content-item__about-info-name">{title}</div>
        <div className="tabs__content-item__about-info-value">{children}</div>
      </div>
    </div>
  );
};

export default ProfileInfoBlock;
