import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/users.constants';
import { AbsencePeriodsParams } from '../enums/params/reports.params';
import { UsersParams } from '../enums/params/users.params';

export const getUserInfo = (uuid: string): AnyAction => ({
  type: ActionTypes.GET_USER_INFO_REQUEST,
  payload: uuid,
});

export const getUserPersonalInfo = (uuid: string): AnyAction => ({
  type: ActionTypes.GET_USER_PERSONAL_INFO_REQUEST,
  payload: uuid,
});

export const getUserProfessionalInfo = (uuid: string): AnyAction => ({
  type: ActionTypes.GET_USER_PROFESSIONAL_INFO_REQUEST,
  payload: uuid,
});

export const getJiraUsers = (): AnyAction => ({
  type: ActionTypes.GET_JIRA_USERS_BRIEF_REQUEST,
});

export const getUsers = (): AnyAction => ({
  type: ActionTypes.GET_USERS_REQUEST,
});

export const updateUserInfo = (uuid: string, data: any, cb: () => void) => ({
  type: ActionTypes.PUT_USER_INFO_REQUEST,
  payload: { uuid, data, cb },
});

export const updateUserPersonalInfo = (uuid: string, data: any, cb: () => void): AnyAction => ({
  type: ActionTypes.PUT_USER_PERSONAL_INFO_REQUEST,
  payload: { uuid, data, cb },
});

export const updateUserProfessionalInfo = (uuid: string, data: any, cb: () => void): AnyAction => ({
  type: ActionTypes.PUT_USER_PROFESSIONAL_INFO_REQUEST,
  payload: { uuid, data, cb },
});

export const createUser = (data: any): AnyAction => ({
  type: ActionTypes.POST_USER_REQUEST,
  payload: { data },
});

export const setUsersParams = (data: Partial<UsersParams>): AnyAction => ({
  type: ActionTypes.SET_USERS_PARAMS_REQUEST,
  payload: data,
});

export const resetUserErrors = (): AnyAction => ({
  type: ActionTypes.RESET_USER_ERRORS,
});

export const updateUserPartialInfo = (uuid: string, data: any, cb: () => void) => ({
  type: ActionTypes.PATCH_USER_INFO_REQUEST,
  payload: { uuid, data, cb },
});

export const uploadUserPhoto = (uuid: string, data: any): AnyAction => ({
  type: ActionTypes.POST_USER_PHOTO_REQUEST,
  payload: { uuid, data },
});

export const deleteUserPhoto = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_USER_PHOTO_REQUEST,
  payload: data,
});

export const getUserTechnicalSkills = (uuid: string): AnyAction => ({
  type: ActionTypes.GET_USER_TECHNICAL_SKILLS_REQUEST,
  payload: uuid,
});

export const updateUserTechnicalSkills = (uuid: string, data: any, cb: () => void) => ({
  type: ActionTypes.PUT_USER_TECHNICAL_SKILLS_REQUEST,
  payload: { uuid, data, cb },
});

export const updateUserPassword = (data: any): AnyAction => ({
  type: ActionTypes.PATCH_UPDATE_USER_PASSWORD_REQUEST,
  payload: data,
});

export const getUserPositionHistory = (uuid: string): AnyAction => ({
  type: ActionTypes.GET_USER_POSITION_HISTORY_REQUEST,
  payload: { uuid },
});

export const editUserPositionHistory = (data: any): AnyAction => ({
  type: ActionTypes.PUT_USER_POSITION_HISTORY_REQUEST,
  payload: data,
});

export const getUserExperiences = (uuid: string): AnyAction => ({
  type: ActionTypes.GET_USER_EXPERIENCES_REQUEST,
  payload: { uuid },
});

export const getUserBonuses = (uuid: string): AnyAction => ({
  type: ActionTypes.GET_USER_BONUSES_REQUEST,
  payload: { uuid },
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_USERS_STATE,
});

export const getUserAbsencePeriods = (data: any): AnyAction => ({
  type: ActionTypes.GET_USER_ABSENCE_PERIODS_REQUEST,
  payload: data,
});

export const getDetailedUserAbsencePeriodsInfo = (data: Partial<AbsencePeriodsParams>) => ({
  type: ActionTypes.GET_DETAILED_USER_ABSENCE_PERIODS_INFO_REQUEST,
  payload: data,
});
