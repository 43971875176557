import { defineMessages } from 'react-intl';
import {
  searchLabel,
  allLabel,
  saveButton,
  cancelButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  editButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  searchLabel,
  allLabel,
  saveButton,
  cancelButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  editButton,
  saveAsButton: {
    id: 'filter.btn.save.as',
    defaultMessage: 'Save as...',
  },
  newModalTitle: {
    id: 'filter.modal.new.filters.title.save.filters',
    defaultMessage: 'Save New Filters',
  },
  editModalTitle: {
    id: 'filter.modal.edit.filters.title.edit.filters',
    defaultMessage: 'Edit Filters',
  },
  shareWithOrganizationLabel: {
    id: 'filter.modal.edit.filters.label.share.with.organization',
    defaultMessage: 'Share with organization',
  },
  publicFiltersLabel: {
    id: 'filter.modal.edit.filters.label.public.filters',
    defaultMessage: 'Public Filters',
  },
  myFiltersLabel: {
    id: 'filter.modal.edit.filters.label.my.filters',
    defaultMessage: 'My Filters',
  },
});
