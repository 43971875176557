import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../../../constants/date.constants';
import { EventPreviewInfo } from '../../../../../enums/schedule.enum';
import { isDark } from '../../../../../utils/color.utils';
import { getIsAllDayEvent } from '../../utils';

type EventProps = {
  event: EventPreviewInfo;
  openEventModal: (event: EventPreviewInfo, e: React.MouseEvent) => void;
};

function Event({ event, openEventModal }: EventProps) {
  const eventTime =
    event.showTime && `${moment(`${event.localStartDate} ${event.localStartTime}`).format(DATE_FORMAT.HH_mm)} `;
  const isAllDayEvent = getIsAllDayEvent(event);
  return (
    <div className="fc-daygrid-event-harness" onClick={(e: any) => openEventModal(event, e)}>
      <a className={classNames('fc-daygrid-event fc-event', { 'dot-event': !isAllDayEvent })} tabIndex={0}>
        {isAllDayEvent ? (
          <div
            className="fc-event-main"
            style={{
              backgroundColor: event.eventTypeColor,
              color: isDark(event.eventTypeColor) ? '#fff' : '#4A4A4A',
            }}
          >
            <span>
              {eventTime}
              {event.calendarTitle}
            </span>
          </div>
        ) : (
          <div className="dot-event-wrapper">
            <div
              className="fc-daygrid-event-dot"
              style={{
                borderColor: event.eventTypeColor,
              }}
            ></div>
            <div className="fc-event-time">{eventTime}</div>
            <div className="fc-event-title">{event.calendarTitle}</div>
          </div>
        )}
      </a>
    </div>
  );
}

export default Event;
