import { defineMessages } from 'react-intl';
import { newButton, editButton, deleteButton, totalRow, departmentsMembersLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  newButton,
  totalRow,
  departmentsMembersLabel,
  pageTitle: {
    id: 'finance.planned.expense.title.planned.expense',
    defaultMessage: 'Planned Expense',
  },
  expensesColumn: {
    id: 'finance.planned.expense.column.expenses',
    defaultMessage: 'Expenses',
  },
});
