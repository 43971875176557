import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import { RESET_PASSWORD_VALIDATION_SCHEMA } from '../../../enums/users.enum';
import Modal from '../../Modal';
import Input from '../../Input';
import Button from '../../Button';
import Icon from '../../Icon';
import Checkbox from '../../Checkbox';
import RadioButtonGroup from '../../RadioButtonGroup';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { RejectValueErrors } from '../../../enums/error.enum';
import { scrollToError } from '../../../utils';

type ModalResetPasswordProps = {
  onClose: () => void;
  onSubmit: (data: any) => void;
  loading?: boolean;
  requestError: string | RejectValueErrors[] | null;
  isOpen: boolean;
};

function ModalResetPassword({ onClose, onSubmit, loading, requestError, isOpen }: ModalResetPasswordProps) {
  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    resetForm,
    validateForm,
    setErrors,
    setFieldError,
  } = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      sendDetailsViaEmail: true,
      isRandom: false,
    },
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: RESET_PASSWORD_VALIDATION_SCHEMA,
    onSubmit: data => {
      onSubmit(
        data.isRandom
          ? { sendDetailsViaEmail: data.sendDetailsViaEmail, isRandom: data.isRandom }
          : { sendDetailsViaEmail: data.sendDetailsViaEmail, isRandom: data.isRandom, newPassword: data.newPassword },
      );
    },
  });

  const intl = useIntl();

  useSetFieldsErrors(requestError, setFieldError);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordFillType, setPasswordFillType] = useState('custom');

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handlePasswordFillTypeChange = useCallback(
    e => {
      setPasswordFillType((e.target as HTMLElement).id);
      if ((e.target as HTMLElement).id === 'custom') {
        setFieldValue('newPassword', '');
        setFieldValue('confirmPassword', '').then(() => {
          setErrors({});
        });
        setFieldValue('isRandom', false);
      } else {
        setNewPasswordVisible(false);
        setConfirmPasswordVisible(false);
        setFieldValue('newPassword', '0000000000');
        setFieldValue('confirmPassword', '0000000000').then(() => validateForm());
        setFieldValue('isRandom', true);
        setFieldValue('sendDetailsViaEmail', true);
      }
    },
    [values],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={intl.formatMessage(messages.resetPasswordTitle)}
      size="small"
      classNameModal="change_password_modal center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <RadioButtonGroup
            groupItems={[
              { id: 'random', label: intl.formatMessage(messages.passwordTypeRandomRadioInput) },
              { id: 'custom', label: intl.formatMessage(messages.passwordTypeCustomRadioInput) },
            ]}
            name="password-type"
            onChange={handlePasswordFillTypeChange}
            checked={passwordFillType}
          />
          <Input
            disabled={passwordFillType === 'random'}
            type={newPasswordVisible ? 'text' : 'password'}
            name="newPassword"
            label={intl.formatMessage(messages.passwordInput)}
            onChange={handleChange}
            defaultValue={values.newPassword}
            hasError={hasError('newPassword')}
            errorMessage={errors?.newPassword}
            control={
              passwordFillType === 'custom' ? (
                <button onClick={() => setNewPasswordVisible(!newPasswordVisible)} type="button">
                  <Icon iconName={newPasswordVisible ? 'eye-slash' : 'eye'} externalClass={'password-icon'} />
                </button>
              ) : null
            }
          />
          <div className={passwordFillType === 'random' ? 'random_password_input' : ''}>
            <Input
              type={confirmPasswordVisible ? 'text' : 'password'}
              disabled={passwordFillType === 'random'}
              name="confirmPassword"
              label={intl.formatMessage(messages.confirmPasswordInput)}
              onChange={handleChange}
              hasError={hasError('confirmPassword')}
              errorMessage={errors?.confirmPassword}
              defaultValue={values.confirmPassword}
              control={
                <button onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} type="button">
                  <Icon iconName={confirmPasswordVisible ? 'eye-slash' : 'eye'} externalClass={'password-icon'} />
                </button>
              }
            />
          </div>
          <Checkbox
            label={intl.formatMessage(messages.sendAccountDetailsViaEmailCheckbox)}
            id="sendDetailsViaEmail"
            name="sendDetailsViaEmail"
            disabled={passwordFillType === 'random'}
            externalClass="send_details_checkbox"
            checkedValue={values.sendDetailsViaEmail}
            onChange={handleChange}
          />
        </div>
        <ErrorMessage>{requestError}</ErrorMessage>
        <div className="form__buttons">
          <Button type="button" onClick={onClose} color="gray" externalClass="button--modal">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button type="submit" externalClass="button--modal" loading={loading} disabled={loading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalResetPassword;
