import React from 'react';
import { RejectValueErrors } from '../../enums/error.enum';
import Icon from '../Icon';

type ErrorMessagePorps = {
  children: string | RejectValueErrors[] | null | React.ReactNode;
};

const ErrorMessage = ({ children }: ErrorMessagePorps) => {
  return !Array.isArray(children) && children ? (
    <div className="form__error">
      <Icon iconName="exclamation-circle" externalClass="form__input-icon" />
      <span>{children}</span>
    </div>
  ) : null;
};

export default ErrorMessage;
