import React, { useMemo } from 'react';
import { CandidateStatus } from '../../enums/candidates.enums';
import * as candidateTypes from '../../types/candidates/index';
import { monthNames } from '../CustomDateRangePicker/utils';
import { getMonthOption } from '../../utils';

const untilYear = 1983;
const getYears = () => {
  const year = new Date().getFullYear();
  return Array.from({ length: year - untilYear }, (v, i) => (year - i).toString());
};

export const useModalOptions = (
  candidateStatuses: CandidateStatus[],
  candidateTechnologies: candidateTypes.CandidateTechnologyType[],
  candidateSpecializations: candidateTypes.CandidateSpecializationType[],
  candidateReceivingSources: candidateTypes.CandidateReceivingSourceType[],
  currentYear?: string,
) => {
  const candidateStatusesOptions = useMemo(
    () =>
      candidateStatuses.map(status => ({
        label: status.name,
        value: status,
      })),
    [candidateStatuses],
  );

  const candidateTechnologiesOptions = useMemo(
    () =>
      candidateTechnologies.map(technology => ({
        label: technology.name,
        value: technology.id,
      })),
    [candidateTechnologies],
  );

  const candidateSpecializationsOptions = useMemo(
    () =>
      candidateSpecializations.map(specialization => ({
        label: specialization.name,
        value: specialization.id,
      })),
    [candidateSpecializations],
  );

  const candidateReceivingSourcesOptions = useMemo(
    () =>
      candidateReceivingSources.map(source => ({
        label: source.name,
        value: source.id,
      })),
    [candidateReceivingSources],
  );

  const monthOptions = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    if (year !== Number(currentYear)) {
      return monthNames().map((item, index) => getMonthOption(item, index));
    } else {
      return monthNames()
        .filter((item, index) => index <= month)
        .map((item, index) => getMonthOption(item, index));
    }
  }, [currentYear]);

  const yearOptions = useMemo(
    () =>
      getYears().map(year => ({
        label: year,
        value: year,
      })),
    [],
  );

  return {
    candidateStatusesOptions,
    candidateTechnologiesOptions,
    candidateSpecializationsOptions,
    candidateReceivingSourcesOptions,
    monthOptions,
    yearOptions,
  };
};

export const getFormattedStatusOptionLabel = ({ value, label }: { value: CandidateStatus | null; label: string }) => (
  <span style={value ? { color: `#${value.color}` } : undefined}>{label}</span>
);
