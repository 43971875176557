import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/libraries.constants';
import { RejectValueErrors } from '../enums/error.enum';
import { OfficeInfo } from '../enums/libraries.enum';
import { getReducerErrors } from '../utils/reducerError.utils';
import * as paramsTypes from '../enums/params/libraries.params';
import { OfficesWorkDaysType } from '../types/libraries/libraries.Reducer.type';

type InitialStateType = {
  officesTableData: any;
  officesWorkDays: OfficesWorkDaysType | null;
  gradesTableData: any;
  specializationsTableData: any;
  companyPositionsTableData: any;
  departmentsTableData: any;
  projectsTableData: any;
  technicalSkillTableData: any;
  skillLevelsTableData: any;
  languageLevelsTableData: any;
  foreignLanguagesTableData: any;
  officesParams: paramsTypes.OfficesParams;
  departmentsParams: paramsTypes.DepartmentsParams;
  gradesParams: paramsTypes.GradesParams;
  companyPositionsParams: paramsTypes.CompanyPositionsParams;
  technicalSkillsParams: paramsTypes.TechnicalSkillsParams;
  specializationsParams: paramsTypes.SpecializationsParams;
  languageLevelsParams: paramsTypes.LanguageLevelsParams;
  skillLevelsParams: paramsTypes.SkillLevelsParams;
  projectsParams: paramsTypes.ProjectsParams;
  foreignLanguagesParams: paramsTypes.ForeignLanguagesParams;
  errors: {
    officeError: string | RejectValueErrors[] | null;
    officesWorkDaysError: string | null;
    gradeError: string | RejectValueErrors[] | null;
    specializationError: string | RejectValueErrors[] | null;
    companyPositionError: string | RejectValueErrors[] | null;
    departmentError: string | RejectValueErrors[] | null;
    projectError: string | RejectValueErrors[] | null;
    technicalSkillError: string | RejectValueErrors[] | null;
    skillLevelError: string | RejectValueErrors[] | null;
    languageLevelError: string | RejectValueErrors[] | null;
    foreignLanguageError: string | RejectValueErrors[] | null;
  };
  loading: {
    getOffices: boolean;
    getOfficesWorkDays: boolean;
    createOffice: boolean;
    editOffice: boolean;
    deleteOffice: boolean;
    getGrades: boolean;
    createGrade: boolean;
    editGrade: boolean;
    deleteGrade: boolean;
    getSpecializations: boolean;
    createSpecialization: boolean;
    editSpecialization: boolean;
    editSpecializationCompetencies: boolean;
    deleteSpecialization: boolean;
    getCompanyPositions: boolean;
    createCompanyPosition: boolean;
    editCompanyPosition: boolean;
    deleteCompanyPosition: boolean;
    getDepartments: boolean;
    createDepartment: boolean;
    editDepartment: boolean;
    deleteDepartment: boolean;
    getProjects: boolean;
    createProject: boolean;
    editProject: boolean;
    deleteProject: boolean;
    getTechnicalSkills: boolean;
    createTechnicalSkill: boolean;
    editTechnicalSkill: boolean;
    deleteTechnicalSkill: boolean;
    getSkillLevels: boolean;
    createSkillLevel: boolean;
    editSkillLevel: boolean;
    deleteSkillLevel: boolean;
    getLanguageLevel: boolean;
    createLanguageLevel: boolean;
    editLanguageLevel: boolean;
    deleteLanguageLevel: boolean;
    getForeignLanguage: boolean;
    createForeignLanguage: boolean;
    editForeignLanguage: boolean;
    deleteForeignLanguage: boolean;
  };
};

const initialState: InitialStateType = {
  officesTableData: null,
  officesWorkDays: null,
  gradesTableData: null,
  specializationsTableData: null,
  companyPositionsTableData: null,
  departmentsTableData: null,
  projectsTableData: null,
  technicalSkillTableData: null,
  skillLevelsTableData: null,
  languageLevelsTableData: null,
  foreignLanguagesTableData: null,
  officesParams: new paramsTypes.OfficesParams(),
  departmentsParams: new paramsTypes.DepartmentsParams(),
  gradesParams: new paramsTypes.GradesParams(),
  companyPositionsParams: new paramsTypes.CompanyPositionsParams(),
  specializationsParams: new paramsTypes.SpecializationsParams(),
  technicalSkillsParams: new paramsTypes.TechnicalSkillsParams(),
  languageLevelsParams: new paramsTypes.LanguageLevelsParams(),
  skillLevelsParams: new paramsTypes.SkillLevelsParams(),
  projectsParams: new paramsTypes.ProjectsParams(),
  foreignLanguagesParams: new paramsTypes.ForeignLanguagesParams(),
  errors: {
    officeError: null,
    gradeError: null,
    specializationError: null,
    companyPositionError: null,
    departmentError: null,
    projectError: null,
    technicalSkillError: null,
    skillLevelError: null,
    languageLevelError: null,
    foreignLanguageError: null,
    officesWorkDaysError: null,
  },
  loading: {
    getOffices: false,
    getOfficesWorkDays: false,
    createOffice: false,
    editOffice: false,
    deleteOffice: false,
    getGrades: false,
    createGrade: false,
    editGrade: false,
    deleteGrade: false,
    getSpecializations: false,
    createSpecialization: false,
    editSpecialization: false,
    editSpecializationCompetencies: false,
    deleteSpecialization: false,
    getCompanyPositions: false,
    createCompanyPosition: false,
    editCompanyPosition: false,
    deleteCompanyPosition: false,
    getDepartments: false,
    createDepartment: false,
    editDepartment: false,
    deleteDepartment: false,
    getProjects: false,
    createProject: false,
    editProject: false,
    deleteProject: false,
    getTechnicalSkills: false,
    createTechnicalSkill: false,
    editTechnicalSkill: false,
    deleteTechnicalSkill: false,
    getSkillLevels: false,
    createSkillLevel: false,
    editSkillLevel: false,
    deleteSkillLevel: false,
    getLanguageLevel: false,
    createLanguageLevel: false,
    editLanguageLevel: false,
    deleteLanguageLevel: false,
    getForeignLanguage: false,
    createForeignLanguage: false,
    editForeignLanguage: false,
    deleteForeignLanguage: false,
  },
};

const librariesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_OFFICES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getOffices: true },
      };

    case ActionTypes.GET_OFFICES_LIST_SUCCESS:
      return {
        ...state,
        officesTableData: {
          ...action.payload,
          content: action.payload.content.map((office: unknown) => new OfficeInfo(office)),
        },
        errors: { ...state.errors, officeError: null },
        loading: { ...state.loading, getOffices: false },
      };

    case ActionTypes.GET_OFFICES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, officeError: action.payload.message },
        loading: { ...state.loading, getOffices: false },
      };

    case ActionTypes.POST_OFFICE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createOffice: true },
      };

    case ActionTypes.POST_OFFICE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, officeError: null },
        loading: { ...state.loading, createOffice: false },
      };

    case ActionTypes.POST_OFFICE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          officeError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createOffice: false },
      };

    case ActionTypes.PUT_OFFICE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editOffice: true },
      };

    case ActionTypes.PUT_OFFICE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, officeError: null },
        loading: { ...state.loading, editOffice: false },
      };

    case ActionTypes.PUT_OFFICE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          officeError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editOffice: false },
      };

    case ActionTypes.DELETE_OFFICE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteOffice: true },
      };

    case ActionTypes.DELETE_OFFICE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, officeError: null },
        loading: { ...state.loading, deleteOffice: false },
      };

    case ActionTypes.DELETE_OFFICE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, officeError: action.payload.message },
        loading: { ...state.loading, deleteOffice: false },
      };

    case ActionTypes.SET_OFFICES_PARAMS:
      return {
        ...state,
        officesParams: new paramsTypes.OfficesParams({ ...state.officesParams, ...action.payload }),
        loading: { ...state.loading, getOffices: true },
      };

    case ActionTypes.GET_OFFICES_WORK_DAYS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getOfficesWorkDays: true },
      };

    case ActionTypes.GET_OFFICES_WORK_DAYS_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, officesWorkDaysError: null },
        loading: { ...state.loading, getOfficesWorkDays: false },
        officesWorkDays: action.payload,
      };

    case ActionTypes.GET_OFFICES_WORK_DAYS_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, officesWorkDaysError: action.payload.message },
        loading: { ...state.loading, getOfficesWorkDays: false },
      };

    case ActionTypes.GET_GRADES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getGrades: true },
      };

    case ActionTypes.GET_GRADES_LIST_SUCCESS:
      return {
        ...state,
        gradesTableData: action.payload,
        errors: { ...state.errors, gradeError: null },
        loading: { ...state.loading, getGrades: false },
      };

    case ActionTypes.GET_GRADES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, gradeError: action.payload.message },
        loading: { ...state.loading, getGrades: false },
      };

    case ActionTypes.POST_GRADE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createGrade: true },
      };

    case ActionTypes.POST_GRADE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, gradeError: null },
        loading: { ...state.loading, createGrade: false },
      };

    case ActionTypes.POST_GRADE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          gradeError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createGrade: false },
      };

    case ActionTypes.PUT_GRADE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editGrade: true },
      };

    case ActionTypes.PUT_GRADE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, gradeError: null },
        loading: { ...state.loading, editGrade: false },
      };

    case ActionTypes.PUT_GRADE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          gradeError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editGrade: false },
      };

    case ActionTypes.DELETE_GRADE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteGrade: true },
      };

    case ActionTypes.DELETE_GRADE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, gradeError: null },
        loading: { ...state.loading, deleteGrade: false },
      };

    case ActionTypes.DELETE_GRADE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, gradeError: action.payload.message },
        loading: { ...state.loading, deleteGrade: false },
      };

    case ActionTypes.GET_SPECIALIZATIONS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSpecializations: true },
      };

    case ActionTypes.GET_SPECIALIZATIONS_LIST_SUCCESS:
      return {
        ...state,
        specializationsTableData: {
          ...action.payload,
          content: action.payload.content,
        },
        errors: { ...state.errors, specializationError: null },
        loading: { ...state.loading, getSpecializations: false },
      };

    case ActionTypes.GET_SPECIALIZATIONS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, specializationError: action.payload.message },
        loading: { ...state.loading, getSpecializations: false },
      };

    case ActionTypes.GET_SPECIALIZATIONS_COMPETENCIES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSpecializations: true },
      };

    case ActionTypes.GET_SPECIALIZATIONS_COMPETENCIES_SUCCESS:
      return {
        ...state,
        specializationsTableData: action.payload,
        errors: { ...state.errors, specializationError: null },
        loading: { ...state.loading, getSpecializations: false },
      };

    case ActionTypes.GET_SPECIALIZATIONS_COMPETENCIES_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, specializationError: action.payload.message },
        loading: { ...state.loading, getSpecializations: false },
      };

    case ActionTypes.POST_SPECIALIZATION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createSpecialization: true },
      };

    case ActionTypes.POST_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, specializationError: null },
        loading: { ...state.loading, createSpecialization: false },
      };

    case ActionTypes.POST_SPECIALIZATION_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          specializationError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createSpecialization: false },
      };

    case ActionTypes.PUT_SPECIALIZATION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editSpecialization: true },
      };

    case ActionTypes.PUT_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, specializationError: null },
        loading: { ...state.loading, editSpecialization: false },
      };

    case ActionTypes.PUT_SPECIALIZATION_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          specializationError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editSpecialization: false },
      };

    case ActionTypes.PUT_SPECIALIZATION_COMPETENCIES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editSpecializationCompetencies: true },
      };

    case ActionTypes.PUT_SPECIALIZATION_COMPETENCIES_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, specializationError: null },
        loading: { ...state.loading, editSpecializationCompetencies: false },
      };

    case ActionTypes.PUT_SPECIALIZATION_COMPETENCIES_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          specializationError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editSpecializationCompetencies: false },
      };

    case ActionTypes.DELETE_SPECIALIZATION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteSpecialization: true },
      };

    case ActionTypes.DELETE_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, specializationError: null },
        loading: { ...state.loading, deleteSpecialization: false },
      };

    case ActionTypes.DELETE_SPECIALIZATION_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, specializationError: action.payload.message },
        loading: { ...state.loading, deleteSpecialization: false },
      };

    case ActionTypes.SET_SPECIALIZATIONS_PARAMS:
      return {
        ...state,
        specializationsParams: new paramsTypes.SpecializationsParams({
          ...state.specializationsParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getSpecializations: true },
      };

    case ActionTypes.GET_COMPANY_POSITIONS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCompanyPositions: true },
      };

    case ActionTypes.GET_COMPANY_POSITIONS_LIST_SUCCESS:
      return {
        ...state,
        companyPositionsTableData: {
          ...action.payload,
          content: action.payload.content,
        },
        errors: { ...state.errors, companyPositionError: null },
        loading: { ...state.loading, getCompanyPositions: false },
      };

    case ActionTypes.GET_COMPANY_POSITIONS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, companyPositionError: action.payload.message },
        loading: { ...state.loading, getCompanyPositions: false },
      };

    case ActionTypes.POST_COMPANY_POSITION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createCompanyPosition: true },
      };

    case ActionTypes.POST_COMPANY_POSITION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, companyPositionError: null },
        loading: { ...state.loading, createCompanyPosition: false },
      };

    case ActionTypes.POST_COMPANY_POSITION_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          companyPositionError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createCompanyPosition: false },
      };

    case ActionTypes.PUT_COMPANY_POSITION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editCompanyPosition: true },
      };

    case ActionTypes.PUT_COMPANY_POSITION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, companyPositionError: null },
        loading: { ...state.loading, editCompanyPosition: false },
      };

    case ActionTypes.PUT_COMPANY_POSITION_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, companyPositionError: getReducerErrors(action.payload) },
        loading: { ...state.loading, editCompanyPosition: false },
      };

    case ActionTypes.DELETE_COMPANY_POSITION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCompanyPosition: true },
      };

    case ActionTypes.DELETE_COMPANY_POSITION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, companyPositionError: null },
        loading: { ...state.loading, deleteCompanyPosition: false },
      };

    case ActionTypes.DELETE_COMPANY_POSITION_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, companyPositionError: action.payload.message },
        loading: { ...state.loading, deleteOffice: false },
      };

    case ActionTypes.GET_DEPARTMENTS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDepartments: true },
      };

    case ActionTypes.GET_DEPARTMENTS_LIST_SUCCESS:
      return {
        ...state,
        departmentsTableData: {
          ...action.payload,
          content: action.payload.content,
        },
        errors: { ...state.errors, departmentError: null },
        loading: { ...state.loading, getDepartments: false },
      };

    case ActionTypes.GET_DEPARTMENTS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, departmentError: action.payload.message },
        loading: { ...state.loading, getDepartments: false },
      };

    case ActionTypes.POST_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createDepartment: true },
      };

    case ActionTypes.POST_DEPARTMENT_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, departmentError: null },
        loading: { ...state.loading, createDepartment: false },
      };

    case ActionTypes.POST_DEPARTMENT_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          departmentError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createDepartment: false },
      };

    case ActionTypes.PUT_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editDepartment: true },
      };

    case ActionTypes.PUT_DEPARTMENT_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, departmentError: null },
        loading: { ...state.loading, editDepartment: false },
      };

    case ActionTypes.PUT_DEPARTMENT_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          departmentError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editDepartment: false },
      };

    case ActionTypes.DELETE_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteDepartment: true },
      };

    case ActionTypes.DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, departmentError: null },
        loading: { ...state.loading, deleteDepartment: false },
      };

    case ActionTypes.DELETE_DEPARTMENT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, departmentError: action.payload.message },
        loading: { ...state.loading, deleteDepartment: false },
      };

    case ActionTypes.SET_DEPARTMENTS_PARAMS:
      return {
        ...state,
        departmentsParams: new paramsTypes.DepartmentsParams({ ...state.departmentsParams, ...action.payload }),
        loading: { ...state.loading, getDepartments: true },
      };

    case ActionTypes.GET_PROJECTS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getProjects: true },
      };

    case ActionTypes.GET_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        projectsTableData: {
          content: action.payload.content,
        },
        errors: { ...state.errors, projectError: null },
        loading: { ...state.loading, getProjects: false },
      };

    case ActionTypes.GET_PROJECTS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, projectError: action.payload.message },
        loading: { ...state.loading, getProjects: false },
      };

    case ActionTypes.POST_PROJECT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createProject: true },
      };

    case ActionTypes.POST_PROJECT_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, projectError: null },
        loading: { ...state.loading, createProject: false },
      };

    case ActionTypes.POST_PROJECT_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          projectError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createProject: false },
      };

    case ActionTypes.PUT_PROJECT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editProject: true },
      };

    case ActionTypes.PUT_PROJECT_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, projectError: null },
        loading: { ...state.loading, editProject: false },
      };

    case ActionTypes.PUT_PROJECT_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          projectError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editProject: false },
      };

    case ActionTypes.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteProject: true },
      };

    case ActionTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, projectError: null },
        loading: { ...state.loading, deleteProject: false },
      };

    case ActionTypes.DELETE_PROJECT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, projectError: action.payload.message },
        loading: { ...state.loading, deleteProject: false },
      };

    case ActionTypes.SET_PROJECTS_PARAMS:
      return {
        ...state,
        projectsParams: new paramsTypes.ProjectsParams({ ...state.projectsParams, ...action.payload }),
        loading: { ...state.loading, getProjects: true },
      };

    case ActionTypes.GET_SKILL_LEVELS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSkillLevels: true },
      };

    case ActionTypes.GET_SKILL_LEVELS_LIST_SUCCESS:
      return {
        ...state,
        skillLevelsTableData: {
          content: action.payload.content,
        },
        errors: { ...state.errors, skillLevelError: null },
        loading: { ...state.loading, getSkillLevels: false },
      };

    case ActionTypes.GET_SKILL_LEVELS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, skillLevelError: action.payload.message },
        loading: { ...state.loading, getSkillLevels: false },
      };

    case ActionTypes.POST_SKILL_LEVEL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createSkillLevel: true },
      };

    case ActionTypes.POST_SKILL_LEVEL_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, skillLevelError: null },
        loading: { ...state.loading, createSkillLevel: false },
      };

    case ActionTypes.POST_SKILL_LEVEL_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          skillLevelError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createSkillLevel: false },
      };

    case ActionTypes.PUT_SKILL_LEVEL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editSkillLevel: true },
      };

    case ActionTypes.PUT_SKILL_LEVEL_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, skillLevelError: null },
        loading: { ...state.loading, editSkillLevel: false },
      };

    case ActionTypes.PUT_SKILL_LEVEL_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          skillLevelError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editSkillLevel: false },
      };

    case ActionTypes.DELETE_SKILL_LEVEL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteSkillLevel: true },
      };

    case ActionTypes.DELETE_SKILL_LEVEL_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, skillLevelError: null },
        loading: { ...state.loading, deleteSkillLevel: false },
      };

    case ActionTypes.DELETE_SKILL_LEVEL_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, skillLevelError: action.payload.message },
        loading: { ...state.loading, deleteSkillLevel: false },
      };

    case ActionTypes.GET_TECHNICAL_SKILLS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getTechnicalSkills: true },
      };

    case ActionTypes.GET_TECHNICAL_SKILLS_LIST_SUCCESS:
      return {
        ...state,
        technicalSkillTableData: {
          content: action.payload.content,
          pageable: {
            ...action.payload.pageable,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements,
            numberOfElements: action.payload.numberOfElements,
          },
        },
        errors: { ...state.errors, technicalSkillError: null },
        loading: { ...state.loading, getTechnicalSkills: false },
      };

    case ActionTypes.GET_TECHNICAL_SKILLS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, technicalSkillError: action.payload.message },
        loading: { ...state.loading, getTechnicalSkill: false },
      };

    case ActionTypes.SET_TECHNICAL_SKILLS_PARAMS:
      return {
        ...state,
        technicalSkillsParams: new paramsTypes.TechnicalSkillsParams({
          ...state.technicalSkillsParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getTechnicalSkills: true },
      };

    case ActionTypes.POST_TECHNICAL_SKILL_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, technicalSkillError: action.payload.message },
        loading: { ...state.loading, createTechnicalSkill: false },
      };

    case ActionTypes.POST_TECHNICAL_SKILL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createTechnicalSkill: true },
      };

    case ActionTypes.POST_TECHNICAL_SKILL_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, technicalSkillError: null },
        loading: { ...state.loading, createTechnicalSkill: false },
      };

    case ActionTypes.POST_TECHNICAL_SKILL_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          technicalSkillError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createTechnicalSkill: false },
      };

    case ActionTypes.PUT_TECHNICAL_SKILL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editTechnicalSkill: true },
      };

    case ActionTypes.PUT_TECHNICAL_SKILL_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, technicalSkillError: null },
        loading: { ...state.loading, editTechnicalSkill: false },
      };

    case ActionTypes.PUT_TECHNICAL_SKILL_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          technicalSkillError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editTechnicalSkill: false },
      };

    case ActionTypes.DELETE_TECHNICAL_SKILL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteTechnicalSkill: true },
      };

    case ActionTypes.DELETE_TECHNICAL_SKILL_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, technicalSkillError: null },
        loading: { ...state.loading, deleteTechnicalSkill: false },
      };

    case ActionTypes.DELETE_TECHNICAL_SKILL_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, technicalSkillError: action.payload.message },
        loading: { ...state.loading, deleteTechnicalSkill: false },
      };

    case ActionTypes.GET_LANGUAGE_LEVELS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getLanguageLevel: true },
      };

    case ActionTypes.GET_LANGUAGE_LEVELS_LIST_SUCCESS:
      return {
        ...state,
        languageLevelsTableData: {
          content: action.payload.content,
        },
        errors: { ...state.errors, languageLevelError: null },
        loading: { ...state.loading, getLanguageLevel: false },
      };

    case ActionTypes.GET_LANGUAGE_LEVELS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, languageLevelError: action.payload.message },
        loading: { ...state.loading, getLanguageLevel: false },
      };

    case ActionTypes.POST_LANGUAGE_LEVEL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createLanguageLevel: true },
      };

    case ActionTypes.POST_LANGUAGE_LEVEL_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, languageLevelError: null },
        loading: { ...state.loading, createLanguageLevel: false },
      };

    case ActionTypes.POST_LANGUAGE_LEVEL_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          languageLevelError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createLanguageLevel: false },
      };

    case ActionTypes.PUT_LANGUAGE_LEVEL_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, languageLevelError: null },
        loading: { ...state.loading, editLanguageLevel: false },
      };

    case ActionTypes.PUT_LANGUAGE_LEVEL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editLanguageLevel: true },
      };

    case ActionTypes.PUT_LANGUAGE_LEVEL_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          languageLevelError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editLanguageLevel: false },
      };

    case ActionTypes.DELETE_LANGUAGE_LEVEL_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteLanguageLevel: true },
      };

    case ActionTypes.DELETE_LANGUAGE_LEVEL_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, languageLevelError: null },
        loading: { ...state.loading, deleteLanguageLevel: false },
      };

    case ActionTypes.DELETE_LANGUAGE_LEVEL_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, languageLevelError: action.payload.message },
        loading: { ...state.loading, deleteLanguageLevel: false },
      };

    case ActionTypes.GET_FOREIGN_LANGUAGES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getForeignLanguage: true },
      };

    case ActionTypes.GET_FOREIGN_LANGUAGES_LIST_SUCCESS:
      return {
        ...state,
        foreignLanguagesTableData: {
          content: action.payload.content,
        },
        errors: { ...state.errors, foreignLanguageError: null },
        loading: { ...state.loading, getForeignLanguage: false },
      };

    case ActionTypes.GET_FOREIGN_LANGUAGES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, foreignLanguageError: action.payload.message },
        loading: { ...state.loading, getForeignLanguage: false },
      };

    case ActionTypes.POST_FOREIGN_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createForeignLanguage: true },
      };

    case ActionTypes.POST_FOREIGN_LANGUAGE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, foreignLanguageError: null },
        loading: { ...state.loading, createForeignLanguage: false },
      };

    case ActionTypes.POST_FOREIGN_LANGUAGE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          foreignLanguageError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createForeignLanguage: false },
      };

    case ActionTypes.PUT_FOREIGN_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editForeignLanguage: true },
      };

    case ActionTypes.PUT_FOREIGN_LANGUAGE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, foreignLanguageError: null },
        loading: { ...state.loading, editForeignLanguage: false },
      };

    case ActionTypes.PUT_FOREIGN_LANGUAGE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          foreignLanguageError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editForeignLanguage: false },
      };

    case ActionTypes.DELETE_FOREIGN_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteForeignLanguage: true },
      };

    case ActionTypes.DELETE_FOREIGN_LANGUAGE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, foreignLanguageError: null },
        loading: { ...state.loading, deleteForeignLanguage: false },
      };

    case ActionTypes.DELETE_FOREIGN_LANGUAGE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, foreignLanguageError: action.payload.message },
        loading: { ...state.loading, deleteForeignLanguage: false },
      };

    case ActionTypes.SET_FOREIGN_LANGUAGE_PARAMS:
      return {
        ...state,
        foreignLanguagesParams: new paramsTypes.ForeignLanguagesParams({
          ...state.foreignLanguagesParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getForeignLanguage: true },
      };

    case ActionTypes.RESET_LIBRARIES_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_LIBRARIES_STATE:
      return {
        ...initialState,
        officesParams: state.officesParams,
        departmentsParams: state.departmentsParams,
        gradesParams: state.gradesParams,
        companyPositionsParams: state.companyPositionsParams,
        technicalSkillsParams: state.technicalSkillsParams,
        specializationsParams: state.specializationsParams,
        languageLevelsParams: state.languageLevelsParams,
        skillLevelsParams: state.skillLevelsParams,
        projectsParams: state.projectsParams,
        foreignLanguagesParams: state.foreignLanguagesParams,
      };

    default:
      return state;
  }
};

export default librariesReducer;
