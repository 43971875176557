import request from '../utils/request.utils';

export function getResumesList(params?: any) {
  const options: RequestConfig = {
    method: 'get',
    url: '/resumes',
    params,
  };
  return request(options);
}

export function editUserResume(id: string, data: FormData) {
  const options: RequestConfig = {
    method: 'patch',
    url: `/resumes/${id}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  };
  return request(options);
}

export function getResume(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/resumes/${uuid}`,
  };
  return request(options);
}
