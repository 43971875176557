import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as questionnairesActions from '../../actions/questionnaires.actions';
import { PollTemplate } from '../../enums/questionnaires.enum';
import Icon from '../../components/Icon';
import ModalPreviewPollTemplate from '../../components/PollTemplates/Modals/ModalPreviewPollTemplate';
import ModalDeletePollTemplate from '../../components/PollTemplates/Modals/ModalDeletePollTemplate';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { PollTemplatesParams } from '../../enums/params/questionnaires.params';
import { UPDATE_TEMPLATE } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import DurationPicker from '../../components/Dropdown';
import Table from '../../components/Table';
import { useDataForTable } from './useDataForTable';
import Pagination from '../../components/Pagination';
import { getPollTemplatesCategoryFilter } from '../../actions/filters.actions';
import { PollTemplateCategoryInfoType } from '../../types/questionnaires';
import { useHistory } from 'react-router';
import r from '../../constants/routes.constants';
import PollTemplateFilter from '../../components/PollTemplates/Filter';
import { useParamsChange } from '../../utils/hooks.utils';

function PollTemplates({
  isLoading,
  tableData,
  pollTemplatesCategoryList,
  params,
  pollTemplatesError,
  pollTemplatesListError,
  getPollTemplatesCategoryFilter,
  setCurrentTemplateCategoryId,
  getPollTemplatesList,
  deletePollTemplate,
  copyTemplate,
  resetErrors,
  setPollTemplatesParams,
  resetState,
}: ConnectedProps<typeof connector>) {
  const [modalPreviewPollTemplateIsOpen, setModalPreviewPollTemplateIsOpen] = useState(false);
  const [modalDeleteTemplateIsOpen, setModalDeletePollTemplateIsOpen] = useState(false);
  const [templateClicked, setTemplateClicked] = useState<PollTemplate>(new PollTemplate());
  const navigate = useHistory();

  const dropdownList = useMemo(
    () =>
      pollTemplatesCategoryList.map((item: PollTemplateCategoryInfoType) => ({
        label: item.name,
        handler: () => {
          setCurrentTemplateCategoryId(item.id);
          navigate.push(r.newPollTemplate);
        },
      })),

    [pollTemplatesCategoryList],
  );

  useEffect(() => {
    getPollTemplatesList();
    getPollTemplatesCategoryFilter();
    return () => {
      resetState();
    };
  }, []);

  const openPreviewPollTemplateModal = useCallback(() => {
    setModalPreviewPollTemplateIsOpen(true);
  }, []);

  const closePreviewPollTemplateModal = useCallback(() => {
    setModalPreviewPollTemplateIsOpen(false);
  }, []);

  const openDeletePollTemplateModal = useCallback(() => {
    setModalDeletePollTemplateIsOpen(true);
  }, []);

  const closeDeletePollTemplateModal = useCallback(() => {
    setModalDeletePollTemplateIsOpen(false);
  }, []);

  const { tableColumns, tableActions } = useDataForTable(
    copyTemplate,
    setTemplateClicked,
    openDeletePollTemplateModal,
    openPreviewPollTemplateModal,
  );

  const pageable = useMemo(
    () => ({
      ...tableData?.pageable,
      ...tableData?.sort,
      totalElements: tableData?.totalElements,
      numberOfElements: tableData?.numberOfElements,
      totalPages: tableData?.totalPages,
    }),
    [tableData],
  );

  const handlePageChange = useCallback(({ selected }) => {
    setPollTemplatesParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setPollTemplatesParams({ size: data, page: 0 });
  }, []);

  const handleSort = useCallback((sortBy, direction) => setPollTemplatesParams({ sortBy, direction }), []);

  const handleMultiParamsChange = useParamsChange(setPollTemplatesParams);

  const filters = useMemo(
    () => ({
      categories: pollTemplatesCategoryList,
    }),
    [pollTemplatesCategoryList],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_TEMPLATE]}>
                  <DurationPicker
                    dropdownToggle={
                      <>
                        <Icon iconName={'plus'} externalClass={'button__icon'} />
                        <span className="button__text">
                          <FormattedMessage {...messages.newButton} />
                        </span>
                      </>
                    }
                    dropdownList={dropdownList}
                    customBtn
                    placement="bottom-start"
                    dropdownClass="template_select"
                  />
                </AccessChecker>
                <PollTemplateFilter
                  handleMultiParamsChange={handleMultiParamsChange}
                  setPollTemplatesParams={setPollTemplatesParams}
                  filters={filters}
                  values={params}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            params={params}
            tableActions={tableActions}
            onSort={handleSort}
            error={pollTemplatesListError}
            loading={isLoading.getPollTemplates}
          />
          <Pagination pageable={pageable} onPageChange={handlePageChange} onPageSizeChange={handleSizeChange} />
        </div>
      </div>
      {modalPreviewPollTemplateIsOpen && (
        <ModalPreviewPollTemplate
          isOpen
          onCloseRequest={closePreviewPollTemplateModal}
          templateData={templateClicked}
          onDeleteRequest={openDeletePollTemplateModal}
        />
      )}
      {modalDeleteTemplateIsOpen && (
        <ModalDeletePollTemplate
          isOpen
          onCloseRequest={closeDeletePollTemplateModal}
          onDeleteRequest={deletePollTemplate}
          isLoading={isLoading.deletePollTemplate}
          categoryError={pollTemplatesError}
          templateData={templateClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ questionnaires, filters }: RootState) => ({
  tableData: questionnaires.pollTemplatesTableData,
  pollTemplatesCategoryList: filters.pollTemplatesCategoryFilter.categories,
  pollTemplatesListError: questionnaires.errors.pollTemplatesListError,
  pollTemplatesError: questionnaires.errors.pollTemplatesError,
  isLoading: questionnaires.loading,
  params: questionnaires.pollTemplatesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPollTemplatesList: () => dispatch(questionnairesActions.getPollTemplatesList()),
  getPollTemplatesCategoryFilter: () => dispatch(getPollTemplatesCategoryFilter()),
  setCurrentTemplateCategoryId: (id: string) => dispatch(questionnairesActions.setCurrentTemplateCategory(id)),
  deletePollTemplate: (data: Record<string, unknown>) => dispatch(questionnairesActions.deletePollTemplate(data)),
  copyTemplate: (data: Record<string, unknown>) => dispatch(questionnairesActions.copyTemplate(data)),
  resetErrors: () => dispatch(questionnairesActions.resetErrors()),
  setPollTemplatesParams: (data: Partial<PollTemplatesParams>) =>
    dispatch(questionnairesActions.setPollTemplatesParams(data)),
  resetState: () => dispatch(questionnairesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(PollTemplates);
