import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import TextArea from '../../TextArea';
import {
  createPollVariables,
  EDIT_NOTIFICATION_TEMPLATE_SCHEMA,
  eventVariables,
  NotificationTemplate,
  pollResultVariables,
  profileVariables,
  TemplateType,
  triggerOptions,
  triggerTypes,
} from '../../../enums/notifications.enum';
import { replaceDynamicFieldsToDisplay, replaceDynamicFieldsToRequest } from '../../../utils/notifications.utils';
import classNames from 'classnames';
import { scrollToError } from '../../../utils';

type ModalEditNotificationTemplateProps = {
  onCloseRequest: () => void;
  editEmailTemplate: (data: any) => void;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  notificationTemplate: NotificationTemplate;
};

function ModalEditNotificationTemplate({
  onCloseRequest,
  editEmailTemplate,
  error,
  isLoading,
  isOpen,
  notificationTemplate,
}: ModalEditNotificationTemplateProps) {
  const intl = useIntl();

  const { values, errors, touched, handleSubmit, setFieldError, setFieldValue, handleChange } = useFormik({
    initialValues: new NotificationTemplate({
      ...notificationTemplate,
      bodyTemplate: replaceDynamicFieldsToDisplay(notificationTemplate.bodyTemplate, notificationTemplate.templateType),
      subjectTemplate: replaceDynamicFieldsToDisplay(
        notificationTemplate.subjectTemplate,
        notificationTemplate.templateType,
      ),
    }),
    validationSchema: EDIT_NOTIFICATION_TEMPLATE_SCHEMA,
    validateOnChange: false,
    validate: scrollToError,
    onSubmit: data => {
      return editEmailTemplate({
        data: {
          ...data,
          bodyTemplate: replaceDynamicFieldsToRequest(data.bodyTemplate, data.templateType),
          subjectTemplate: replaceDynamicFieldsToRequest(data.subjectTemplate, data.templateType),
        },
        callback: onCloseRequest,
      });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleBodyTemplateChange = useCallback(data => {
    setFieldValue('bodyTemplate', data);
  }, []);

  const variables = useMemo(
    () =>
      notificationTemplate.templateType === TemplateType.SCHEDULE_EVENT
        ? eventVariables
        : notificationTemplate.templateType === TemplateType.QUESTIONNAIRE_IN_PROGRESS
        ? createPollVariables
        : notificationTemplate.templateType === TemplateType.QUESTIONNAIRE_ANSWERED
        ? pollResultVariables
        : profileVariables,
    [notificationTemplate.templateType],
  );

  const modalTitle = useMemo(() => {
    if (notificationTemplate.templateType === TemplateType.SCHEDULE_EVENT) {
      return notificationTemplate.isBaseTemplate
        ? triggerOptions.find(item => item.value === notificationTemplate.notificationActionType)?.label
        : `${
            triggerOptions.find(item => item.value === notificationTemplate.notificationActionType)?.label.split(' ')[1]
          } ${notificationTemplate.eventType?.name || ''}`;
    }

    return triggerTypes.find(item => item.value === notificationTemplate.templateType)?.label;
  }, [notificationTemplate]);

  const wrightToClipboard = useCallback(variable => {
    navigator.clipboard.writeText(variable);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={modalTitle}
      classNameModal="notification-template-modal center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="notification-template-wrapper">
            <div className="notification-template-form">
              <div className="form__input-block">
                <Input
                  name={`subjectTemplate`}
                  id={`subjectTemplate`}
                  onChange={handleChange}
                  defaultValue={values.subjectTemplate}
                  label={intl.formatMessage(messages.subjectTitle)}
                  tag={'textarea'}
                  hasError={hasError(`subjectTemplate`)}
                  errorMessage={errors?.subjectTemplate}
                />
                <TextArea
                  id={'bodyTemplate'}
                  placeholder={intl.formatMessage(messages.messageTitle)}
                  onChange={data => {
                    handleBodyTemplateChange(data as string);
                  }}
                  defaultValue={values.bodyTemplate}
                  bounds=".form__textarea-wrapper"
                />
                <ErrorMessage>{errors?.bodyTemplate}</ErrorMessage>
              </div>
            </div>
            <div className="notification-template-variables">
              <div className="variables-title">
                <FormattedMessage {...messages.variablesTitle} />
              </div>
              <div className="variables-note">
                <FormattedMessage {...messages.note} />
              </div>
              {variables.map(item => (
                <div
                  key={item}
                  className={classNames('variables-item', {
                    inline:
                      item === '[Event.StartDate]' ||
                      item === '[Event.EndDate]' ||
                      item === '[Event.StartTime]' ||
                      item === '[Event.EndTime]',
                  })}
                  onClick={() => wrightToClipboard(item)}
                >
                  {item === '[Event.EndDate]' || item === '[Event.EndTime]' ? (
                    <div className="inline-last">
                      <span> / </span> {item}
                    </div>
                  ) : (
                    item
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            onClick={onCloseRequest}
            type={'button'}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditNotificationTemplate;
