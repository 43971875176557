import React, { useCallback, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { getTableCell } from '../../utils/table.utils';
import { DepthLevels } from '../../constants/tables.constants';
import { AbsenceManualInfo, DaysUsedLimitItem, EventTypeAbsenceInfos } from '../../enums/schedule.enum';
import TableUserAvatar from '../../components/TableUserAvatar';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import NumberInput from '../../components/NumberInput';
import { useFormik } from 'formik';
import { useSetFieldsErrors } from '../../utils/hooks.utils';
import { RejectValueErrors } from '../../enums/error.enum';
import get from 'lodash-es/get';
import ErrorMessage from '../../components/ErrorMessage';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';
import { UPDATE_ABSENCE_MANUAL_INFO } from '../../constants/policies.constants';
import { DAYS_LIMIT_SCHEMA } from '../../enums/calendar.enum';
import { scrollToError } from '../../utils';

export const useDataForTable = (
  isLoading: boolean,
  editDaysLimit: (data: AbsenceManualInfo, cb: () => void) => void,
  currentYear: string,
  requestErrors: string | null | RejectValueErrors[],
  resetErrors: () => void,
) => {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const { values, errors, touched, handleChange, setFieldError, setFieldValue, setValues, submitForm } = useFormik({
    initialValues: {
      daysLeftInThisYearManual: 0,
      eventType: '',
      eventTypeId: '',
      id: '',
      modifiedBy: 'SYSTEM',
      user: null,
      year: null,
      open: false,
    },
    enableReinitialize: true,
    validate: scrollToError,
    validationSchema: DAYS_LIMIT_SCHEMA,
    validateOnChange: false,
    onSubmit: data => editDaysLimit({ ...data, year: data.year || currentYear }, closePopup),
  });

  useSetFieldsErrors(requestErrors, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const onCountUp = useCallback(() => {
    const value = +values.daysLeftInThisYearManual + 1;

    setFieldValue(`daysLeftInThisYearManual`, value > 999 ? 999 : value);
  }, [values.daysLeftInThisYearManual]);

  const onCountDown = useCallback(() => {
    const value = +values.daysLeftInThisYearManual - 1;
    setFieldValue(`daysLeftInThisYearManual`, value < -999 ? -999 : value);
  }, [values.daysLeftInThisYearManual]);

  const tableActions: any = useMemo(
    () => [
      {
        label: (
          <>
            <form className="modal__form form" onSubmit={e => e.preventDefault()}>
              <NumberInput
                name={'daysLeftInThisYearManual'}
                onChange={handleChange}
                defaultValue={values?.daysLeftInThisYearManual}
                onCountUp={onCountUp}
                onCountDown={onCountDown}
                hasError={hasError('daysLeftInThisYearManual')}
                errorMessage={errors?.daysLeftInThisYearManual}
              />
              <Button
                externalClass={'button--modal'}
                type={'submit'}
                loading={isLoading}
                disabled={isLoading}
                onClick={submitForm}
              >
                <FormattedMessage {...messages.saveButton} />
              </Button>
              <ErrorMessage>{!Array.isArray(requestErrors) && requestErrors}</ErrorMessage>
            </form>
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        closeOnClick: false,
      },
    ],
    [errors, values?.daysLeftInThisYearManual],
  );

  const openPopup = (data: any) => {
    setValues({ ...data.absenceManualInfoThisYear, user: data.user, userId: data.user.id });
    setFieldValue('open', !values.open);
  };

  const closePopup = () => {
    setFieldValue('open', false);
    resetErrors();
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: intl.formatMessage(messages.departmentsMembersLabel),
        id: 'members',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: DaysUsedLimitItem) => <TableUserAvatar users={[row.user]} fileSize={36} />,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: EventTypeAbsenceInfos) => <div>{row.eventType}</div>,
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.daysLeftFromPreviousYearColumn),
        id: 'daysLeftFromPreviousYear',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: EventTypeAbsenceInfos) => (
                <div>
                  {row.daysLeftFromPreviousYear === null ? (
                    <FormattedMessage {...messages.noLimitLabel} />
                  ) : (
                    row.daysLeftFromPreviousYear
                  )}
                </div>
              ),
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.daysAvailableInThisYearColumn),
        id: 'daysAvailableInThisYear',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: EventTypeAbsenceInfos) => (
                <div>
                  {row.daysAvailableInThisYear === null ? (
                    <FormattedMessage {...messages.noLimitLabel} />
                  ) : (
                    row.daysAvailableInThisYear
                  )}
                </div>
              ),
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.availableDaysBasedOnDaysWorkedColumn),
        id: 'daysAvailableBasedOnDaysWorked',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: EventTypeAbsenceInfos) => (
                <div>
                  {row.daysAvailableBasedOnDaysWorked === null ? (
                    <FormattedMessage {...messages.noLimitLabel} />
                  ) : (
                    row.daysAvailableBasedOnDaysWorked
                  )}
                </div>
              ),
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.daysUsedInThisYearColumn),
        id: 'daysUsedInThisYear',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: EventTypeAbsenceInfos) => (
                <div>
                  {row.daysUsedInThisYear === null ? (
                    <FormattedMessage {...messages.noLimitLabel} />
                  ) : (
                    row.daysUsedInThisYear
                  )}
                </div>
              ),
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.daysLeftInThisYearColumn),
        id: 'daysLeftInThisYearAuto',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: EventTypeAbsenceInfos) =>
                row.daysLeftInThisYearAuto === null ? (
                  <div className="no-limit">
                    <FormattedMessage {...messages.noLimitLabel} />
                  </div>
                ) : (
                  <div className="left-days-block">
                    <div>{row.daysLeftInThisYearAuto}</div>
                    <div className="manual-limit" onClick={event => event.preventDefault()}>
                      {checkPolicies([UPDATE_ABSENCE_MANUAL_INFO], policies) ? (
                        <Dropdown
                          dropdownClass="dropdown--no-bg manual-dropdown"
                          dropdownToggle={row.absenceManualInfoThisYear.daysLeftInThisYearManual}
                          dropdownList={tableActions}
                          dropdownInfo={row}
                          stopPropagation
                          customStateOpen={row.absenceManualInfoThisYear.id === values.id && values?.open}
                          onOpen={openPopup}
                          onClose={closePopup}
                        />
                      ) : (
                        <div>{row.absenceManualInfoThisYear.daysLeftInThisYearManual}</div>
                      )}
                    </div>
                  </div>
                ),
            },
          ]);
        },
        externalColumnClass: () => 'left-column',
      },
    ],
    [policies, errors, values.open, values.daysLeftInThisYearManual],
  );

  return {
    tableColumns,
  };
};
