import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/activity.constants';
import { SoftwareLink } from '../enums/activity.enums';
import { ActivityParams } from '../enums/params/activity.params';

export const getActivityTable = (): AnyAction => ({
  type: ActionTypes.GET_ACTIVITY_TABLE_REQUEST,
});

export const getActivityDiagram = (): AnyAction => ({
  type: ActionTypes.GET_ACTIVITY_DIAGRAM_REQUEST,
});

export const setActivityDiagramParams = (data: Partial<ActivityParams>): AnyAction => ({
  type: ActionTypes.SET_ACTIVITY_DIAGRAM_PARAMS_REQUEST,
  payload: data,
});

export const setActivityTableParams = (data: Partial<ActivityParams>): AnyAction => ({
  type: ActionTypes.SET_ACTIVITY_TABLE_PARAMS_REQUEST,
  payload: data,
});

export const setUserTabActivityParams = (data: Partial<ActivityParams>): AnyAction => ({
  type: ActionTypes.SET_USER_TAB_ACTIVITY_PARAMS_REQUEST,
  payload: data,
});

export const getSoftwareLinksList = (): AnyAction => ({
  type: ActionTypes.GET_SOFTWARE_LINKS_REQUEST,
});

export const downloadSoftware = (data: SoftwareLink): AnyAction => ({
  type: ActionTypes.GET_SOFTWARE_REQUEST,
  payload: data,
});

export const uploadSoftware = (data: any): AnyAction => ({
  type: ActionTypes.POST_SOFTWARE_REQUEST,
  payload: data,
});

export const deleteSoftware = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_SOFTWARE_REQUEST,
  payload: data,
});

export const resetActivityErrors = (): AnyAction => ({
  type: ActionTypes.RESET_ACTIVITY_ERRORS,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_ACTIVITY_STATE,
});
