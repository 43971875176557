import { get } from 'lodash-es';

export class UsersParams {
  officeIds: string[];
  departmentIds: string[];
  userIds: string[];
  hrCuratorIds: string[];
  technicalSkills: {
    skillGroupSkillId: string;
    skillLevelIds: string[];
  }[];
  mainSpecializationIds: string[];
  secondarySpecializationIds: string[];
  positionIds: string[];
  active: boolean;
  includedInPlanning?: boolean;
  size: number;

  constructor(params?: unknown) {
    this.officeIds = get(params, 'officeIds', []);
    this.departmentIds = get(params, 'departmentIds', []);
    this.userIds = get(params, 'userIds', []);
    this.hrCuratorIds = get(params, 'hrCuratorIds', []);
    this.technicalSkills = get(params, 'technicalSkills', []);
    this.mainSpecializationIds = get(params, 'mainSpecializationIds', []);
    this.secondarySpecializationIds = get(params, 'secondarySpecializationIds', []);
    this.positionIds = get(params, 'positionIds', []);
    this.active = get(params, 'active', true);
    this.includedInPlanning = get(params, 'includedInPlanning', undefined);
    this.size = get(params, 'size', 1000);
  }
}

export class UsersFilterParams extends UsersParams {
  sort: string;
  dateFrom: string;
  dateTo: string;

  constructor(params?: unknown) {
    super();
    this.sort = 'secondName,ASC';
    this.dateFrom = get(params, 'dateFrom');
    this.dateTo = get(params, 'dateTo');
  }
}

export class HrCuratorsFilterParams {
  sort: string;
  size: number;

  constructor() {
    this.sort = 'secondName,ASC';
    this.size = 1000;
  }
}
