import React from 'react';
import { UserPreviewInfo } from '../../enums/users.enum';
import TableAvatar from '../TableUserAvatar/TableAvatar';

type RecipientTableProps = {
  emails: string[];
  users: UserPreviewInfo[];
};

function RecipientTable({ emails, users }: RecipientTableProps) {
  return (
    <div className="recipient-table">
      <div className="table__data-wrapper">
        {emails.map(email => (
          <a className="recipient-email data__enum" key={email} target="blank" href={`mailto:${email}`}>
            {email}
          </a>
        ))}
        {users.slice(0, 10).map(user => (
          <TableAvatar
            key={user.id}
            user={user}
            isUserPageLink
            fileSize={36}
            externaltableUserClass="recipient-user"
            showTooltip={users.length > 1}
            selectedTableUser
          />
        ))}
        {users.length > 10 && <div className="table__more-users">{`+${users.length - 10}`}</div>}
      </div>
    </div>
  );
}

export default RecipientTable;
