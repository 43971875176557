import React, { useState, useCallback, useRef, useMemo, useContext } from 'react';
import classNames from 'classnames';
import { PhotoCropSetting, ThumbnailsSize, UserInfo, UserPreviewInfo } from '../../../enums/users.enum';
import Icon from '../../Icon';
import Loader from '../../Loader';
import r from '../../../constants/routes.constants';
import ModalCropPhot from './Modals/ModalCropPhoto';
import ModalDeletePhoto from './Modals/ModalDeletePhoto';
import ModalSizeError from './Modals/ModalSizeError';
import ModalExtensionError from './Modals/ModalExtensionError';
import { checkPolicies } from '../../../utils/policies.utils';
import {
  DELETE_ACTIVE_EMPLOYEE,
  UPDATE_USER_EXTENDED,
  VIEW_USER_EXTENDED,
} from '../../../constants/policies.constants';
import PoliciesContext from '../../../PoliciesContext';
import { Candidate } from '../../../enums/candidates.enums';
import { NavLink } from 'react-router-dom';

export type AvatarSize = 'small' | 'tiny' | 'medium' | 'large' | 'huge';

type AvatarProps = {
  userInfo: UserInfo | UserPreviewInfo | Candidate;
  isCandidate?: boolean;
  loading?: boolean;
  viewOnly?: boolean;
  tableImg?: boolean;
  externalClass?: string;
  uploadPhoto?: (
    data: { file: File | undefined; cropSetting: PhotoCropSetting; cropFileUrl?: string },
    callback: () => void,
  ) => void;
  deleteUserPhoto?: (data: { callback: () => void }) => void;
  errors?: string;
  customSize?: number;
  customFontSize?: number;
  size?: AvatarSize;
  isUserPageLink?: boolean;
  fileSize?: ThumbnailsSize;
  externalCropperClass?: string;
  changeDocumentOverflowStyle?: boolean;
};

function Avatar({
  userInfo,
  loading,
  viewOnly = true,
  isCandidate = false,
  externalClass,
  tableImg = false,
  uploadPhoto = () => null,
  deleteUserPhoto = () => null,
  errors,
  customSize,
  customFontSize,
  size,
  isUserPageLink = false,
  fileSize,
  externalCropperClass,
  changeDocumentOverflowStyle = true,
}: AvatarProps) {
  const policies = useContext(PoliciesContext);

  const user = useMemo(() => (isCandidate ? new Candidate(userInfo) : new UserInfo(userInfo)), [userInfo, isCandidate]);

  const inputRef = useRef<HTMLInputElement>(null);
  const [extensionErrorModalOpen, setExtensionErrorModalOpen] = useState(false);
  const [sizeErrorModalOpen, setSizeErrorModaOpen] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [image, setImage] = useState<any>(undefined);

  const defaultClass = classNames(`page__profile-block-avatar ${size}`, {
    loading,
    [externalClass as string]: Boolean(externalClass),
    'view-only': viewOnly,
    'user-page-link':
      checkPolicies(user.active ? [VIEW_USER_EXTENDED] : [UPDATE_USER_EXTENDED, DELETE_ACTIVE_EMPLOYEE], policies) &&
      isUserPageLink,
  });

  const handleUploadClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  const handleUpload = useCallback(() => {
    const image = inputRef.current?.files?.[0];
    if (image && /(jpg|jpeg|png|bmp)$/i.test(image.type)) {
      if (image.size < 10485760) {
        setCropModalOpen(true);
      } else {
        setSizeErrorModaOpen(true);
      }
    } else {
      setExtensionErrorModalOpen(true);
    }
  }, [inputRef]);

  const closeCropModal = () => {
    inputRef.current ? (inputRef.current.value = '') : null;
    setImage(undefined);
    setCropModalOpen(false);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const uploadUserPhoto = (file: File | undefined, cropSetting: PhotoCropSetting, cropFileUrl?: string) => {
    uploadPhoto(
      {
        file,
        cropSetting,
        cropFileUrl,
      },
      () => {
        closeCropModal();
        setImage(undefined);
        inputRef.current ? (inputRef.current.value = '') : null;
      },
    );
  };

  const deletePhoto = () => {
    deleteUserPhoto({ callback: closeDeleteModal });
  };

  const closeExtensionModal = () => {
    setExtensionErrorModalOpen(false);
    setImage(null);
  };

  const closeSizeModal = () => {
    setSizeErrorModaOpen(false);
    setImage(null);
  };

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e?.dataTransfer?.files;
    } else if (e.target) {
      files = e?.target?.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const Image = user.photoUrl(fileSize) ? (
    <img
      src={user.photoUrl(fileSize)}
      className={classNames('page__profile-avatar', { 'table__data-image': tableImg })}
      alt={user.fullName}
    />
  ) : (
    <div
      className={classNames('page__profile-avatar page__profile-avatar--no-img', {
        'table__data-no-image': tableImg,
      })}
      style={{ fontSize: `${customFontSize}px`, height: `${customSize}px`, width: `${customSize}px` }}
    >
      {user && user.initials}
    </div>
  );

  return (
    <>
      <div className={defaultClass} style={{ height: `${customSize}px`, width: `${customSize}px` }}>
        <div className="page__profile-avatar-loader-wrapper">
          <Loader externalClass="page__profile-avatar-loader" />
        </div>
        {checkPolicies(user.active ? [VIEW_USER_EXTENDED] : [UPDATE_USER_EXTENDED, DELETE_ACTIVE_EMPLOYEE], policies) &&
        viewOnly &&
        isUserPageLink ? (
          <NavLink to={`${r.users}/${user.id}`}>{Image}</NavLink>
        ) : (
          Image
        )}
        {!viewOnly && (
          <div className="page__profile-avatar-edit">
            <input
              ref={inputRef}
              type="file"
              accept={'image/jpg, image/jpeg, image/png, image/bmp'}
              style={{ display: 'none' }}
              onChange={e => {
                onChange(e);
                handleUpload();
              }}
            />
            <button
              className="page__profile-avatar-edit-btn"
              onClick={handleUploadClick}
              disabled={loading}
              type="button"
            >
              <Icon iconName="upload" />
            </button>
            {user.photoUrl() && (
              <>
                <button
                  className="page__profile-avatar-edit-btn"
                  disabled={loading}
                  onClick={() => {
                    setCropModalOpen(true);
                  }}
                  type="button"
                >
                  <Icon iconName="crop" />
                </button>
                <button
                  className="page__profile-avatar-edit-btn"
                  disabled={loading}
                  onClick={() => setDeleteModalOpen(true)}
                  type="button"
                >
                  <Icon iconName="trash" />
                </button>
              </>
            )}
          </div>
        )}
      </div>
      {extensionErrorModalOpen && (
        <ModalExtensionError
          onRequestClose={closeExtensionModal}
          changeDocumentOverflowStyle={changeDocumentOverflowStyle}
        />
      )}
      {sizeErrorModalOpen && (
        <ModalSizeError onRequestClose={closeSizeModal} changeDocumentOverflowStyle={changeDocumentOverflowStyle} />
      )}
      {cropModalOpen && (
        <ModalCropPhot
          onRequestClose={closeCropModal}
          photoUrl={user?.photo?.url || ''}
          photoCropSetting={user?.photo?.cropSetting}
          inputRef={inputRef}
          getPhoto={uploadUserPhoto}
          loading={loading}
          image={image}
          externalCropperClass={externalCropperClass}
          changeDocumentOverflowStyle={changeDocumentOverflowStyle}
        />
      )}
      {deleteModalOpen && (
        <ModalDeletePhoto
          errors={errors}
          onRequestClose={closeDeleteModal}
          deleteUserPhoto={deletePhoto}
          loading={loading}
          changeDocumentOverflowStyle={changeDocumentOverflowStyle}
        />
      )}
    </>
  );
}

export default React.memo(Avatar);
