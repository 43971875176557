import React from 'react';
import Icon from '../../../components/Icon';
import classNames from 'classnames';

type ProfileItemProps = {
  iconName?: string;
  children: React.ReactNode;
  externalClass?: string;
};

const ProfileItem = ({ iconName, externalClass, children }: ProfileItemProps) => {
  const defaultClass = classNames('page__profile-contact-info', {
    [externalClass as string]: Boolean(externalClass),
  });

  return (
    <div className={defaultClass}>
      {iconName && <Icon iconName={iconName} />}
      {children}
    </div>
  );
};

export default ProfileItem;
