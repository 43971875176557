export const GET_MAILER_SETTINGS_LIST_REQUEST = 'GET_MAILER_SETTINGS_LIST_REQUEST';
export const GET_MAILER_SETTINGS_LIST_SUCCESS = 'GET_MAILER_SETTINGS_LIST_SUCCESS';
export const GET_MAILER_SETTINGS_LIST_FAILURE = 'GET_MAILER_SETTINGS_LIST_FAILURE';

export const POST_MAILER_SETTING_REQUEST = 'POST_MAILER_SETTING_REQUEST';
export const POST_MAILER_SETTING_SUCCESS = 'POST_MAILER_SETTING_SUCCESS';
export const POST_MAILER_SETTING_FAILURE = 'POST_MAILER_SETTING_FAILURE';

export const PUT_MAILER_SETTING_REQUEST = 'PUT_MAILER_SETTING_REQUEST';
export const PUT_MAILER_SETTING_SUCCESS = 'PUT_MAILER_SETTING_SUCCESS';
export const PUT_MAILER_SETTING_FAILURE = 'PUT_MAILER_SETTING_FAILURE';

export const DELETE_MAILER_SETTING_REQUEST = 'DELETE_MAILER_SETTING_REQUEST';
export const DELETE_MAILER_SETTING_SUCCESS = 'DELETE_MAILER_SETTING_SUCCESS';
export const DELETE_MAILER_SETTING_FAILURE = 'DELETE_MAILER_SETTING_FAILURE';

export const SET_MAILER_SETTINGS_PARAMS_REQUEST = 'SET_MAILER_SETTINGS_PARAMS_REQUEST';

export const GET_EMPLOYEE_GROUPS_LIST_REQUEST = 'GET_EMPLOYEE_GROUPS_LIST_REQUEST';
export const GET_EMPLOYEE_GROUPS_LIST_SUCCESS = 'GET_EMPLOYEE_GROUPS_LIST_SUCCESS';
export const GET_EMPLOYEE_GROUPS_LIST_FAILURE = 'GET_EMPLOYEE_GROUPS_LIST_FAILURE';

export const POST_EMPLOYEE_GROUP_REQUEST = 'POST_EMPLOYEE_GROUP_REQUEST';
export const POST_EMPLOYEE_GROUP_SUCCESS = 'POST_EMPLOYEE_GROUP_SUCCESS';
export const POST_EMPLOYEE_GROUP_FAILURE = 'POST_EMPLOYEE_GROUP_FAILURE';

export const PUT_EMPLOYEE_GROUP_REQUEST = 'PUT_EMPLOYEE_GROUP_REQUEST';
export const PUT_EMPLOYEE_GROUP_SUCCESS = 'PUT_EMPLOYEE_GROUP_SUCCESS';
export const PUT_EMPLOYEE_GROUP_FAILURE = 'PUT_EMPLOYEE_GROUP_FAILURE';

export const DELETE_EMPLOYEE_GROUP_REQUEST = 'DELETE_EMPLOYEE_GROUP_REQUEST';
export const DELETE_EMPLOYEE_GROUP_SUCCESS = 'DELETE_EMPLOYEE_GROUP_SUCCESS';
export const DELETE_EMPLOYEE_GROUP_FAILURE = 'DELETE_EMPLOYEE_GROUP_FAILURE';

export const SET_EMPLOYEE_GROUPS_PARAMS_REQUEST = 'SET_EMPLOYEE_GROUPS_PARAMS_REQUEST';

export const GET_FOLLOW_UPS_LIST_REQUEST = 'GET_FOLLOW_UPS_LIST_REQUEST';
export const GET_FOLLOW_UPS_LIST_SUCCESS = 'GET_FOLLOW_UPS_LIST_SUCCESS';
export const GET_FOLLOW_UPS_LIST_FAILURE = 'GET_FOLLOW_UPS_LIST_FAILURE';

export const POST_FOLLOW_UP_REQUEST = 'POST_FOLLOW_UP_REQUEST';
export const POST_FOLLOW_UP_SUCCESS = 'POST_FOLLOW_UP_SUCCESS';
export const POST_FOLLOW_UP_FAILURE = 'POST_FOLLOW_UP_FAILURE';

export const PUT_FOLLOW_UP_REQUEST = 'PUT_FOLLOW_UP_REQUEST';
export const PUT_FOLLOW_UP_SUCCESS = 'PUT_FOLLOW_UP_SUCCESS';
export const PUT_FOLLOW_UP_FAILURE = 'PUT_FOLLOW_UP_FAILURE';

export const DELETE_FOLLOW_UP_REQUEST = 'DELETE_FOLLOW_UP_REQUEST';
export const DELETE_FOLLOW_UP_SUCCESS = 'DELETE_FOLLOW_UP_SUCCESS';
export const DELETE_FOLLOW_UP_FAILURE = 'DELETE_FOLLOW_UP_FAILURE';

export const SET_FOLLOW_UPS_PARAMS_REQUEST = 'SET_FOLLOW_UPS_PARAMS_REQUEST';

export const RESET_NOTIFICATIONS_ERRORS = 'RESET_NOTIFICATIONS_ERRORS';
export const RESET_NOTIFICATIONS_STATE = 'RESET_NOTIFICATIONS_STATE';

export const SERVRE_TYPES = [
  {
    label: 'Create account',
    value: 'CREATE_ACCOUNT',
  },
  {
    label: 'Questionaries',
    value: 'QUESTIONARIES',
  },
  {
    label: 'Schedule',
    value: 'SCHEDULE',
  },
  {
    label: 'Update password',
    value: 'UPDATE_PASSWORD',
  },
];

export const GET_EMAIL_TEMPLATES_LIST_REQUEST = 'GET_EMAIL_TEMPLATES_LIST_REQUEST';
export const GET_EMAIL_TEMPLATES_LIST_SUCCESS = 'GET_EMAIL_TEMPLATES_LIST_SUCCESS';
export const GET_EMAIL_TEMPLATES_LIST_FAILURE = 'GET_EMAIL_TEMPLATES_LIST_FAILURE';

export const POST_EMAIL_TEMPLATE_REQUEST = 'POST_EMAIL_TEMPLATE_REQUEST';
export const POST_EMAIL_TEMPLATE_SUCCESS = 'POST_EMAIL_TEMPLATE_SUCCESS';
export const POST_EMAIL_TEMPLATE_FAILURE = 'POST_EMAIL_TEMPLATE_FAILURE';

export const PUT_EMAIL_TEMPLATE_REQUEST = 'PUT_EMAIL_TEMPLATE_REQUEST';
export const PUT_EMAIL_TEMPLATE_SUCCESS = 'PUT_EMAIL_TEMPLATE_SUCCESS';
export const PUT_EMAIL_TEMPLATE_FAILURE = 'PUT_EMAIL_TEMPLATE_FAILURE';

export const DELETE_EMAIL_TEMPLATE_REQUEST = 'DELETE_EMAIL_TEMPLATE_REQUEST';
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS';
export const DELETE_EMAIL_TEMPLATE_FAILURE = 'DELETE_EMAIL_TEMPLATE_FAILURE';

export const SET_EMAIL_TEMPLATES_PARAMS = 'SET_EMAIL_TEMPLATES_PARAMS';
