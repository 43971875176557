import { defineMessages } from 'react-intl';
import {
  spentLabel,
  exportToPDFLabel,
  exportToXLSLabel,
  copyURLLabel,
  taskLabel,
  externalLabel,
  inactiveLabel,
  typeLabel,
  estimateLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  spentLabel,
  exportToPDFLabel,
  exportToXLSLabel,
  copyURLLabel,
  taskLabel,
  externalLabel,
  inactiveLabel,
  typeLabel,
  estimateLabel,
  pageTitle: {
    id: 'reports.projects.title.projects',
    defaultMessage: 'Projects',
  },
  projectsKeyColumn: {
    id: 'reports.projects.column.projects.key',
    defaultMessage: 'Projects / Key',
  },
});
