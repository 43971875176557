import React, { useCallback, useEffect } from 'react';
import * as questionnariesActions from '../../actions/questionnaires.actions';
import { connect, ConnectedProps } from 'react-redux';
import Pagination from '../../components/Pagination';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { MyPollsParams } from '../../enums/params/questionnaires.params';
import MyPollsContent from './MyPollsContent';

const MyPolls = ({
  resetState,
  userInfoId,
  pollsByUser,
  setPollParams,
  sortParams,
  isLoading,
}: ConnectedProps<typeof connector>) => {
  useEffect(() => {
    userInfoId &&
      setPollParams({
        responderIds: [userInfoId],
      });
    return () => {
      resetState();
    };
  }, [userInfoId]);

  const handlePageChange = useCallback(({ selected }) => {
    setPollParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setPollParams({ size: data, page: 0 });
  }, []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <div className="page__panel-top__wrapper--left">
              <h1 className="page__title">
                <FormattedMessage {...messages.pageTitle} />
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <MyPollsContent
            setPollParams={setPollParams}
            sortParams={sortParams}
            pollsByUser={pollsByUser}
            isLoading={isLoading}
            userInfoId={userInfoId}
          />
          <Pagination
            pageable={{
              ...pollsByUser?.pageable,
              ...pollsByUser?.sort,
              totalElements: pollsByUser?.totalElements,
              numberOfElements: pollsByUser?.numberOfElements,
              totalPages: pollsByUser?.totalPages,
            }}
            onPageChange={(data: any) => handlePageChange(data)}
            onPageSizeChange={(data: any) => handleSizeChange(data)}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ questionnaires, schedule, auth }: RootState) => ({
  events: schedule.eventTableData?.content || [],
  userInfoId: auth?.currentUserInfo?.id,
  pollsByUser: questionnaires.myPollsTableData,
  sortParams: questionnaires.myPollsParams,
  isLoading: questionnaires.loading.getMyPollsListData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetState: () => dispatch(questionnariesActions.resetState()),
  setPollParams: (data: Partial<MyPollsParams>) => dispatch(questionnariesActions.setMyPollsParams(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MyPolls);
