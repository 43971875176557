import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  newProjectTypeTitle: {
    id: 'finance.project.type.modal.new.project.type.title.new.project.type',
    defaultMessage: 'New Project Type',
  },
  editProjectTypeTitle: {
    id: 'finance.project.type.modal.edit.project.type.title.edit.project.type',
    defaultMessage: 'Edit Project Type',
  },
});
