import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { EventTypeInfo } from '../../../enums/calendar.enum';
import Select from '../../Select';
import Checkbox from '../../Checkbox';
import EventReminder from '../EventReminder/EventReminder';
import EventRepeater from '../EventRepeater/EventRepeater';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { EVENT_VALIDATION_SCHEMA, EventInfo } from '../../../enums/schedule.enum';
import { get, isEmpty } from 'lodash-es';
import {
  remindersData,
  repeatDays,
  repeatDaysOfMonth,
  repeaterData,
  timeOptions,
  timeOptions24h,
} from '../../../constants/schedule.constants';
import { OptionTypeBase } from 'react-select';
import moment from 'moment';
import CustomLoader from '../../Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { AssessmentsInfo } from '../../../enums/competencies.enum';
import ErrorMessage from '../../ErrorMessage';
import { UserInfo } from '../../../enums/users.enum';
import { NewPoll } from '../../../enums/questionnaires.enum';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { PollParams } from '../../../enums/params/questionnaires.params';
import r from '../../../constants/routes.constants';
import { AssessmentsParams } from '../../../enums/params/competencies.params';
import Switch from '../../Switch';
import { isBrowserLocale24h, scrollToError } from '../../../utils';
import { checkPolicies } from '../../../utils/policies.utils';
import { UPDATE_EVENT_EXTENDED } from '../../../constants/policies.constants';
import { UserPolicy } from '../../../enums/policies.enum';
import { DateSelectArg } from '@fullcalendar/react';
import { getInitialDate } from '../Layouts/utils';

type ModalNewEventProps = {
  calendarClikedDate?: DateSelectArg | null;
  eventError: string | null | RejectValueErrors[];
  isLoading: {
    getEventType: boolean;
    getEventList: boolean;
    getEvent: boolean;
    createEvent: boolean;
    editEvent: boolean;
    deleteEvent: boolean;
  };
  eventType: EventTypeInfo | null;
  offices: OfficeInfo[];
  users: UserInfo[];
  eventTypesData: EventTypeInfo[];
  assessmentsData: AssessmentsInfo[];
  isOpen: boolean;
  assessmentsLoading: boolean;
  polls: NewPoll[];
  authUser: UserInfo;
  currentUser?: UserInfo;
  openFromUserProfile?: boolean;
  userPolicies: UserPolicy[];
  allEventTypes: EventTypeInfo[];
  getPollsFilter: (params: Partial<PollParams>) => void;
  createNewEvent: (data: any) => void;
  onCloseRequest: () => void;
  getEventType: (id: string) => void;
  resetErrors: () => void;
  getAssessmentsFilter: (data: Partial<AssessmentsParams>) => void;
  resetAssessmentsFilter: () => void;
  cb?: () => void;
};

function ModalNewEvent({
  calendarClikedDate,
  isLoading,
  eventType,
  eventError,
  offices,
  eventTypesData,
  users,
  assessmentsData,
  isOpen,
  assessmentsLoading,
  polls,
  authUser,
  currentUser,
  openFromUserProfile,
  userPolicies,
  allEventTypes,
  getPollsFilter,
  createNewEvent,
  onCloseRequest,
  getEventType,
  getAssessmentsFilter,
  resetAssessmentsFilter,
  resetErrors,
  cb,
}: ModalNewEventProps) {
  const intl = useIntl();
  const startTimeInputRef = useRef<any>(null);
  const endTimeInputRef = useRef<any>(null);
  const selectTimeStartRef = useRef<any>(null);
  const selectTimeEndRef = useRef<any>(null);

  const { values, handleSubmit, handleChange, resetForm, setFieldValue, setFieldError, errors, touched } = useFormik({
    initialValues: new EventInfo(getInitialDate(calendarClikedDate, eventType), eventType || new EventTypeInfo()),
    validationSchema: EVENT_VALIDATION_SCHEMA,
    validateOnChange: false,
    enableReinitialize: true,
    validate: scrollToError,
    onSubmit: data => {
      if (!data.unitOfRepeat) {
        data.repeatInterval = null;
      }

      data.sendNotification = !!(data.sendNotification && isSendNotificationShown);

      if (data.showTime && !data.allDay) {
        const startUtc = moment(`${data.startDate} ${data.startTime}`).utc().format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss);

        data.startTime = startUtc.split(' ')[1];
        data.startDate = startUtc.split(' ')[0];

        const endUtc = moment(`${data.endDate} ${data.endTime}`).utc().format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss);

        data.endTime = endUtc.split(' ')[1];
        data.endDate = endUtc.split(' ')[0];
      }

      createNewEvent({
        data,
        callback: () => {
          resetAndExit();
          cb && cb();
        },
      });
    },
  });
  const [isCustomRepeater, setCustomRepeater] = useState(false);
  const [isRepeat, setRepeat] = useState(false);
  const REPEAT_DAYS = useMemo(() => repeatDays(values.startDate || moment().format()), [values.startDate]);
  const REPEAT_DAYS_OF_MONTH = useMemo(() => repeatDaysOfMonth(values.startDate || moment().format()), [
    values.startDate,
  ]);

  const canAuthUserApprove = useMemo(
    () =>
      !!values.eventType?.absencesSettings.requiresApproval.usersAbleToApprove.find(user => user.id === authUser.id),
    [authUser, values.eventType?.absencesSettings.requiresApproval.usersAbleToApprove],
  );

  useSetFieldsErrors(eventError, setFieldError, r.schedule);

  useEffect(() => {
    values.showQuestionnaires &&
      values.startDate &&
      getPollsFilter({
        deadlineFrom: moment(values.startDate).subtract(3, 'month').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
        deadlineTo: moment(values.startDate).add(3, 'month').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
        direction: 'DESC',
        isGeneral: false,
      });
  }, [values.startDate, values.showQuestionnaires]);

  useEffect(() => {
    values?.targetEmployee?.id &&
      values.startDate &&
      values.showAssessments &&
      getAssessmentsFilter({
        dateFrom: moment(values.startDate).subtract(3, 'month').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
        dateTo: moment(values.startDate).add(3, 'month').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
        employees: [values.targetEmployee.id],
      });
  }, [values.startDate, values.targetEmployee]);

  useEffect(() => {
    resetForm();
    setFieldValue('eventType', eventType);
    if (openFromUserProfile && currentUser) {
      if (eventType?.targetEmployeesEnabled) {
        setFieldValue('targetEmployee', currentUser);
        if (eventType?.titleEnabled) {
          setFieldValue('title', `${eventType?.name} - ${currentUser?.fullName}`);
        }
      } else if (eventType?.eventParticipantsEnabled) {
        setFieldValue('participants', [currentUser]);
        setFieldValue('participantsIds', [currentUser.id]);
      }
    }

    if (
      !openFromUserProfile &&
      !checkPolicies([UPDATE_EVENT_EXTENDED], userPolicies) &&
      eventType?.targetEmployeesEnabled
    ) {
      setFieldValue('targetEmployee', authUser);
    }

    setCustomRepeater(false);
    setRepeat(false);
  }, [eventType]);

  useEffect(() => {
    !isCustomRepeater &&
      values.startDate &&
      setFieldValue('repeatDays', [moment(values.startDate).locale('en-US').format(DATE_FORMAT.dddd).toUpperCase()]);
  }, [values.startDate]);

  useEffect(() => {
    const input = startTimeInputRef.current;
    if (input) {
      input.value = values.startTime.slice(0, 5);
    }
  }, [values.startTime]);

  useEffect(() => {
    const input = endTimeInputRef.current;
    if (input) {
      input.value = values.endTime.slice(0, 5);
    }
  }, [values.endTime]);

  const assessmentsWithoutEvent = useMemo(
    () =>
      values.targetEmployee?.id ? assessmentsData.filter((assessment: AssessmentsInfo) => !assessment?.eventId) : [],
    [assessmentsData, values.targetEmployee, values.eventType],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const usersOptions = useMemo(
    () =>
      users.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [users],
  );

  const usersValue = useMemo(
    () => values?.targetEmployee && usersOptions.find(({ value }) => value?.id === values?.targetEmployee?.id),
    [values, usersOptions],
  );

  const assessmentsOptions = useMemo(() => {
    return assessmentsWithoutEvent.map((el: AssessmentsInfo) => ({
      label: el.competence.name,
      value: el,
    }));
  }, [assessmentsWithoutEvent, values.assessments]);

  const assessmentsValues = useMemo(
    () => assessmentsOptions.filter(el => values.assessmentIdList.some(id => id === el.value.id)),
    [values.assessments, assessmentsOptions],
  );

  const pollsOptions = useMemo(
    () =>
      polls.map((poll: NewPoll) => ({
        label: poll.name,
        value: poll,
      })),
    [polls],
  );

  const participantsValues = useMemo(
    () =>
      values.participants.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [values.participants],
  );

  const excludedParticipantsValues = useMemo(
    () =>
      values.excludedParticipants.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [values.excludedParticipants],
  );

  const eventsTypeOptions = useMemo(
    () =>
      eventTypesData.map((eventType: EventTypeInfo) => ({
        label: eventType.name,
        value: eventType,
      })),
    [eventTypesData],
  );

  const allEventsTypeOptions = useMemo(
    () =>
      allEventTypes.map((eventType: EventTypeInfo) => ({
        label: eventType.name,
        value: eventType,
      })),
    [allEventTypes],
  );

  const eventTypeValue = useMemo(
    () => values.eventType && eventsTypeOptions.find(({ value }) => value.id === values?.eventType?.id),
    [eventsTypeOptions, values],
  );

  const excludeEventTypeValue = useMemo(
    () =>
      values.absences.excludeDaysOfAbsenceFromOtherTypes.map((otherEventType: EventTypeInfo) => ({
        label: otherEventType.name,
        value: otherEventType,
      })),
    [values.absences.excludeDaysOfAbsenceFromOtherTypes],
  );

  const officesOptions = useMemo(
    () =>
      offices.map((office: OfficeInfo) => ({
        label: office.name,
        value: office,
      })),
    [offices],
  );

  const handleOfficeChange = useCallback(newValues => {
    const value = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue('locations', value);
    setFieldValue(
      'locationsIds',
      value.map((office: OfficeInfo) => office.id),
    );
  }, []);

  const handleParticipantsChange = useCallback(newValues => {
    const value = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue('participants', value);
    setFieldValue(
      'participantsIds',
      value.map((item: UserInfo) => item.id),
    );
  }, []);

  const handleExcludedParticipantsChange = useCallback(newValues => {
    const value = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue('excludedParticipants', value);
    setFieldValue(
      'excludedParticipantsIds',
      value.map((item: any) => item.id),
    );
  }, []);

  const handleAssessmentsChange = useCallback(newValues => {
    const assessmentsId = newValues.map((el: Record<string, any>) => el.value.id);
    const assessments = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue('assessmentIdList', assessmentsId);
    setFieldValue('assessments', assessments);
  }, []);

  const handleExcludeEventType = useCallback(newValues => {
    const value = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue('absences.excludeDaysOfAbsenceFromOtherTypes', value);
  }, []);

  const handleEmployeeChange = useCallback(
    ({ value }) => {
      if (value) {
        if (isEmpty(values.title) && values.showTargetEmployee) {
          setFieldValue('title', `${values.eventType.name} - ${value?.fullName}`);
        }
        setFieldValue('targetEmployee', value);
        if (values.targetEmployee?.id !== value.id) {
          setFieldValue('assessments', []);
          setFieldValue('assessmentIdList', []);
        }
      } else {
        setFieldValue('targetEmployee', value);
        setFieldValue('assessments', []);
        setFieldValue('assessmentIdList', []);
      }
    },
    [values.targetEmployee?.id, values.title, values.eventType, values.showTargetEmployee],
  );

  const handleAllDayChange = useCallback(newValue => {
    handleChange(newValue);
    setFieldValue('startTime', moment().startOf('day').format(DATE_FORMAT.HH_mm_ss));
    setFieldValue('endTime', `${moment().endOf('day').format(DATE_FORMAT.HH_mm)}:00`);
  }, []);

  const handleEventTypeChange = useCallback(({ value }) => {
    setFieldValue('eventType', value);
    resetAssessmentsFilter();
    value && getEventType(value.id);
  }, []);

  const handlePollsChange = useCallback(newValues => {
    setFieldValue(
      'polls',
      newValues.map((el: Record<string, any>) => el.value),
    );
    setFieldValue(
      'pollIds',
      newValues.map((el: Record<string, any>) => el.value.id),
    );
  }, []);

  const handleActiveTimeScroll = (ref: any) => {
    setTimeout(() => {
      const selectedItem = ref.current?.select?.menuListRef?.querySelector('.select__option--is-selected');
      selectedItem && selectedItem.scrollIntoView({ block: 'center', inline: 'nearest' });
    }, 1);
  };

  const handleStartTimeChange = useCallback(e => {
    setFieldValue('startTime', `${e.target.value}:00`);
    handleActiveTimeScroll(selectTimeStartRef);
  }, []);

  const handleEndTimeChange = useCallback(e => {
    setFieldValue('endTime', `${e.target.value}:00`);
    handleActiveTimeScroll(selectTimeEndRef);
  }, []);

  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetForm();
    resetErrors();
    setCustomRepeater(false);
  }, []);

  const remindersOptions = useMemo(
    () =>
      Object.entries(remindersData).map(([key, value]) => ({
        label: value.name[0].toUpperCase() + value.name.slice(1),
        value: key,
      })),
    [eventTypesData],
  );

  const repeateDaysOptions = useMemo(
    () =>
      REPEAT_DAYS.map(value => ({
        label: value.name,
        value: value,
      })),
    [REPEAT_DAYS, values, isCustomRepeater],
  );

  const repeateDaysValues = useMemo(
    () =>
      repeateDaysOptions.find(({ value }) =>
        isCustomRepeater ? value.repeatType === 'CUSTOM' : value.repeatType === values.unitOfRepeat,
      ),
    [REPEAT_DAYS, values, isCustomRepeater],
  );

  const handleCountUpReminder = useCallback(
    index => () => {
      if (values.reminders[index].timeValue < remindersData[`${values.reminders[index].unitOfTime}`].maxValue) {
        setFieldValue(`reminders[${index}].timeValue`, values.reminders[index].timeValue + 1);
      }
    },
    [values],
  );
  const handleCountDownReminder = useCallback(
    index => () => {
      if (values.reminders[index].timeValue > 0) {
        setFieldValue(`reminders[${index}].timeValue`, values.reminders[index].timeValue - 1);
      }
    },
    [values],
  );

  const addReminder = useCallback(
    () => setFieldValue('reminders', [...values.reminders, { timeValue: 0, unitOfTime: 'MINUTES' }]),
    [values],
  );

  const removeReminder = useCallback(
    i => () => {
      setFieldValue(
        'reminders',
        values.reminders.filter((reminder, index) => i !== index),
      );
    },
    [values],
  );

  const handleReminderChange = useCallback(
    index => ({ value }: OptionTypeBase) => {
      setFieldValue(`reminders[${index}].unitOfTime`, value);
    },
    [],
  );

  const filRepeaterValue = (value?: any) => {
    setFieldValue('endOfRepeatDate', get(value, 'endOfRepeatDate', ''));
    setFieldValue('endOfRepeatQuantity', get(value, 'endOfRepeatQuantity', 1));
    setFieldValue('endOfRepeatType', get(value, 'endOfRepeatType', 'REPEAT_QUANTITY'));
    setFieldValue('monthRepeatType', get(value, 'monthRepeatType', null));
    setFieldValue('repeatDays', get(value, 'repeatDays', []));
    setFieldValue('repeatInterval', get(value, 'repeatInterval', 1));
    setFieldValue('unitOfRepeat', get(value, 'unitOfRepeat', null));
  };

  const handleRepeatDaysChange = useCallback(
    ({ value }: OptionTypeBase) => {
      switch (value.repeatType) {
        case null: {
          filRepeaterValue();
          setCustomRepeater(false);
          setRepeat(false);
          break;
        }
        case 'DAYS': {
          setCustomRepeater(false);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: value.repeatType,
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        case 'WEEKS': {
          setCustomRepeater(false);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: value.repeatType,
            repeatDays: [value.data.toUpperCase()],
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        case 'MONTHS': {
          setCustomRepeater(false);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: value.repeatType,
            monthRepeatType: value.data,
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        case 'YEARS': {
          setCustomRepeater(false);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: value.repeatType,
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        case 'CUSTOM': {
          setCustomRepeater(true);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: 'DAYS',
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        default: {
          setFieldValue('unitOfRepeat', value.repeatType);
          setCustomRepeater(false);
          setRepeat(false);
          break;
        }
      }
    },
    [values, REPEAT_DAYS, values.startDate],
  );

  const repeaterOptions = useMemo(
    () =>
      Object.entries(repeaterData).map(([key, value]) => ({
        label: value.name[0].toUpperCase() + value.name.slice(1),
        value: key,
      })),
    [repeaterData],
  );

  const timeOptionsForSelect = useMemo(
    () =>
      timeOptions.map((el, index) => ({
        value: timeOptions24h[index],
        label: isBrowserLocale24h() ? el.slice(0, 5) : el,
      })),
    [timeOptions],
  );

  const timeStartValue = useMemo(() => timeOptionsForSelect.find(el => el.value === values.startTime) || null, [
    timeOptionsForSelect,
    values.startTime,
  ]);

  const timeEndValue = useMemo(() => timeOptionsForSelect.find(el => el.value === values.endTime) || null, [
    timeOptionsForSelect,
    values.endTime,
  ]);

  const repeaterValue = useMemo(() => repeaterOptions.find(({ value }) => value === values.unitOfRepeat), [
    repeaterData,
    values,
  ]);

  const repeaterMonthDaysOptions = useMemo(
    () =>
      REPEAT_DAYS_OF_MONTH.map(value => ({
        label: value.name,
        value: value.value,
      })),
    [REPEAT_DAYS_OF_MONTH, values.startDate],
  );

  const repeaterMonthDaysValues = useMemo(
    () => repeaterMonthDaysOptions.find(({ value }) => value === values.monthRepeatType),
    [REPEAT_DAYS, values.monthRepeatType, values.startDate, isCustomRepeater],
  );

  const isSendNotificationShown = useMemo(
    () =>
      !isEmpty(values.participants) ||
      !isEmpty(values.locations) ||
      (values.targetEmployee && values.targetEmployee.id !== authUser.id),
    [values.participants, values.locations, values.targetEmployee, authUser],
  );

  const handleCountUpRepeatQuantity = useCallback(() => {
    setFieldValue('endOfRepeatQuantity', values.endOfRepeatQuantity + 1);
    if (values.endOfRepeatQuantity < 0) {
      setFieldValue('endOfRepeatQuantity', 1);
    }
  }, [values]);

  const handleCountDownRepeatQuantity = useCallback(() => {
    if (values.endOfRepeatQuantity > 1) {
      setFieldValue('endOfRepeatQuantity', values.endOfRepeatQuantity - 1);
    }
  }, [values]);

  const handleRepeaterChange = useCallback(
    ({ value }: OptionTypeBase) => {
      if (value !== 'WEEKS') {
        setFieldValue('repeatDays', values.repeatDays);
      }
      if (value !== 'MONTHS') {
        setFieldValue('monthRepeatType', values.monthRepeatType);
      }
      setFieldValue('unitOfRepeat', value);
    },
    [values],
  );

  const handleRepeaterMonthDaysChange = useCallback(
    ({ value }: OptionTypeBase) => {
      setFieldValue('monthRepeatType', value);
    },
    [values],
  );

  const handleCountUpInterval = useCallback(() => {
    setFieldValue('repeatInterval', values.repeatInterval + 1);
    if (values.repeatInterval < 0) {
      setFieldValue('repeatInterval', 1);
    }
  }, [values]);

  const handleCountDownInterval = useCallback(() => {
    if (values.repeatInterval > 1) {
      setFieldValue('repeatInterval', values.repeatInterval - 1);
    }
  }, [values]);

  const handleStartDateChange = useCallback(
    value => {
      const date = value.target.value;
      setFieldValue('startDate', date);

      if (moment(values.endDate).isBefore(date)) setFieldValue('endDate', date);
      if (!eventType?.dateRangesEnabled) setFieldValue('endDate', date);
    },
    [values, eventType?.dateRangesEnabled],
  );

  const handleEndDateChange = useCallback(
    value => {
      const date = value.target.value;
      setFieldValue('endDate', date);

      if (moment(values.startDate).isAfter(date)) setFieldValue('startDate', date);
    },
    [values, eventType?.dateRangesEnabled],
  );

  const handleStartTimeSelectChange = useCallback(
    ({ value }) => {
      const input = startTimeInputRef.current;
      if (input) {
        input.value = value.slice(0, 5);
      }
      setFieldValue('startTime', value);
    },
    [values],
  );

  const handleEndTimeSelectChange = useCallback(({ value }) => {
    const input = endTimeInputRef.current;
    if (input) {
      input.value = value.slice(0, 5);
    }
    setFieldValue('endTime', value);
  }, []);

  const formatPollOptionLabel = useCallback(
    ({ value }) => (
      <div className="related-poll__wrapper">
        <span className="related-poll__name">{value.name}</span>
        <span className="related-poll__date">{moment(value.deadline.split(' ')[0]).format(DATE_FORMAT.ll)}</span>
      </div>
    ),
    [],
  );

  const formatAssessmentOptionLabel = useCallback(
    ({ value }: { value: AssessmentsInfo }) => (
      <div className="related-assessment__wrapper">
        <span className="related-assessment__name">{value.competence.name}</span>
        <span className="related-assessment__date">
          {moment(value.assessmentDate.split(' ')[0]).format(DATE_FORMAT.ll)}
        </span>
      </div>
    ),
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.newModalTitle)}
      classNameModal={'modal--event'}
    >
      {!isLoading.getEventType && eventType ? (
        <>
          <form className="modal__form form" onSubmit={handleSubmit}>
            <div className="form__inputs-wrapper form__event-wrapper">
              <Select
                isSearchable
                label={intl.formatMessage(messages.eventTypeLabel)}
                options={eventsTypeOptions}
                handleChange={handleEventTypeChange}
                value={eventTypeValue}
              />
              {eventType.titleEnabled && (
                <Input
                  name={'title'}
                  label={intl.formatMessage(messages.titleLabel)}
                  onChange={handleChange}
                  defaultValue={values.title}
                  hasError={hasError('title')}
                  errorMessage={errors?.title}
                  maxLength={100}
                />
              )}
              <div className="form__inputs-subwrapper align-start">
                {eventType.dateRangesEnabled ? (
                  <>
                    <Input
                      type={'date'}
                      label={intl.formatMessage(messages.startDateLabel)}
                      name={'startDate'}
                      onChange={handleStartDateChange}
                      hasError={hasError('startDate')}
                      errorMessage={errors?.startDate}
                      defaultValue={values.startDate}
                      wrapperClass="form__input-date"
                    />
                    <Input
                      type={'date'}
                      label={intl.formatMessage(messages.endDateLabel)}
                      name={'endDate'}
                      onChange={handleEndDateChange}
                      hasError={hasError('endDate')}
                      errorMessage={errors?.endDate}
                      defaultValue={values.endDate}
                      wrapperClass="form__input-date"
                    />
                  </>
                ) : (
                  <Input
                    type={'date'}
                    label={intl.formatMessage(messages.startDateLabel)}
                    name={'startDate'}
                    onChange={handleStartDateChange}
                    hasError={hasError('startDate')}
                    errorMessage={errors?.startDate}
                    defaultValue={values.startDate}
                    wrapperClass="form__input-date"
                  />
                )}
                {eventType.timeEnabled && (
                  <div className="form__input-block form__input-block--half">
                    <Checkbox
                      id={'allDay'}
                      label={intl.formatMessage(messages.allDayLabel)}
                      externalClass="form__checkbox-capitalazie-label"
                      onChange={handleAllDayChange}
                      checkedValue={values.allDay}
                    />
                  </div>
                )}
              </div>

              {eventType.timeEnabled && !values.allDay && (
                <div className="form__inputs-subwrapper">
                  <div className="form__event-time-wrapper">
                    <Select
                      selectRef={selectTimeStartRef}
                      options={timeOptionsForSelect}
                      value={timeStartValue}
                      label={intl.formatMessage(messages.startTimeLabel)}
                      customInput={
                        <Input
                          inputRef={startTimeInputRef}
                          type={'time'}
                          name={'startTime'}
                          onChange={handleStartTimeChange}
                          defaultValue={values.startTime.slice(0, 5)}
                          disabled={values.allDay}
                        />
                      }
                      externalClass="time-select"
                      errorMessage={errors?.startTime}
                      customInputRef={startTimeInputRef}
                      handleChange={handleStartTimeSelectChange}
                      hasError={hasError('startTime')}
                    />
                  </div>
                  <div className="form__event-time-wrapper">
                    <Select
                      selectRef={selectTimeEndRef}
                      options={timeOptionsForSelect}
                      value={timeEndValue}
                      label={intl.formatMessage(messages.endTimeLabel)}
                      customInput={
                        <Input
                          inputRef={endTimeInputRef}
                          type={'time'}
                          name={'endTime'}
                          onChange={handleEndTimeChange}
                          defaultValue={values.endTime.slice(0, 5)}
                          disabled={values.allDay}
                        />
                      }
                      externalClass="time-select"
                      errorMessage={errors?.endTime}
                      customInputRef={endTimeInputRef}
                      handleChange={handleEndTimeSelectChange}
                      hasError={hasError('endTIme')}
                    />
                  </div>
                </div>
              )}

              {eventType.repeaterEnabled && (
                <>
                  <div className="form__input-block">
                    <Select
                      options={repeateDaysOptions}
                      label={intl.formatMessage(messages.recurrenceLabel)}
                      value={repeateDaysValues}
                      handleChange={handleRepeatDaysChange}
                    />
                  </div>
                  <EventRepeater
                    valuesEvent={values}
                    isCustomRepeater={isCustomRepeater}
                    isRepeat={isRepeat}
                    errors={errors}
                    repeaterOptions={repeaterOptions}
                    repeaterMonthDaysOptions={repeaterMonthDaysOptions}
                    repeaterValue={repeaterValue}
                    repeaterMonthDaysValues={repeaterMonthDaysValues}
                    handleChange={handleChange}
                    handleCountUpInterval={handleCountUpInterval}
                    handleCountDownInterval={handleCountDownInterval}
                    handleRepeaterChange={handleRepeaterChange}
                    hasError={hasError}
                    handleRepeaterMonthDaysChange={handleRepeaterMonthDaysChange}
                    handleCountUpRepeatQuantity={handleCountUpRepeatQuantity}
                    handleCountDownRepeatQuantity={handleCountDownRepeatQuantity}
                  />
                </>
              )}
              {eventType.reminderEnabled && (
                <EventReminder
                  handleChange={handleChange}
                  reminders={values.reminders}
                  handleCountUp={handleCountUpReminder}
                  handleCountDown={handleCountDownReminder}
                  addReminder={addReminder}
                  removeReminder={removeReminder}
                  remindersOptions={remindersOptions}
                  handleReminderChange={handleReminderChange}
                />
              )}
              {eventType.targetEmployeesEnabled && (
                <div className="form__inputs-subwrapper">
                  <Select
                    isSearchable
                    externalClass={'form__input-block--half'}
                    label={intl.formatMessage(messages.targetMemberLabel)}
                    options={usersOptions}
                    handleChange={handleEmployeeChange}
                    hasError={hasError('targetEmployee')}
                    value={usersValue}
                    errorMessage={errors?.targetEmployee}
                    isClearable
                    isDisabled={openFromUserProfile || !checkPolicies([UPDATE_EVENT_EXTENDED], userPolicies)}
                  />
                </div>
              )}
              {eventType.eventParticipantsEnabled && (
                <div className="form__input-block">
                  <Select
                    label={intl.formatMessage(messages.participantsLabel)}
                    options={usersOptions}
                    value={participantsValues}
                    isMulti
                    isSearchable
                    handleChange={handleParticipantsChange}
                    hasError={hasError('participants')}
                    // @ts-ignore
                    errorMessage={errors?.participants}
                  />
                </div>
              )}
              {eventType.excludedParticipantsEnabled && (
                <div className="form__input-block">
                  <Select
                    label={intl.formatMessage(messages.excludedParticipantsLabel)}
                    options={usersOptions}
                    value={excludedParticipantsValues}
                    isMulti
                    isSearchable
                    handleChange={handleExcludedParticipantsChange}
                    hasError={hasError('excludedParticipants')}
                    // @ts-ignore
                    errorMessage={errors?.excludedParticipants}
                  />
                </div>
              )}
              {eventType.questionnairesEnabled && (
                <Select
                  isMulti
                  isSearchable
                  label={intl.formatMessage(messages.questionnaireLabel)}
                  options={pollsOptions}
                  handleChange={handlePollsChange}
                  hasError={hasError('polls')}
                  formatOptionLabel={formatPollOptionLabel}
                  // @ts-ignore
                  errorMessage={errors?.polls}
                />
              )}
              {eventType.assessmentsEnabled && (
                <Select
                  isMulti
                  isSearchable
                  label={intl.formatMessage(messages.assesmentsLabel)}
                  options={assessmentsOptions}
                  value={assessmentsValues}
                  formatOptionLabel={formatAssessmentOptionLabel}
                  handleChange={handleAssessmentsChange}
                  isDisabled={assessmentsLoading}
                />
              )}
              {eventType.officesEnabled && (
                <Select
                  isSearchable
                  isMulti
                  label={intl.formatMessage(messages.officeLabel)}
                  options={officesOptions}
                  handleChange={handleOfficeChange}
                  hasError={hasError('office')}
                  // @ts-ignore
                  errorMessage={errors?.locations}
                />
              )}
              {eventType.addressEnabled && (
                <div className="form__input-block">
                  <Input
                    tag="textarea"
                    label={intl.formatMessage(messages.addressLabel)}
                    name="address"
                    onChange={handleChange}
                    hasError={hasError('address')}
                    errorMessage={errors?.address}
                  />
                </div>
              )}
              {eventType.absencesSettings.optionForExcludeDaysOfAbsenceFromOtherTypes && (
                <Select
                  isSearchable
                  label={intl.formatMessage(messages.excludeDaysOfAbsenceFromOtherTypesLabel)}
                  options={allEventsTypeOptions}
                  isMulti
                  handleChange={handleExcludeEventType}
                  value={excludeEventTypeValue}
                  hasError={hasError('absences.excludeDaysOfAbsenceFromOtherTypes')}
                  // @ts-ignore
                  errorMessage={errors?.absences?.excludeDaysOfAbsenceFromOtherTypes}
                />
              )}
              {eventType.absencesSettings.absencesEnabled &&
                eventType.absencesSettings.finalDecisionOptionsEnabled &&
                values.targetEmployee?.id === authUser.id && (
                  <Checkbox
                    id={'absences.finalDecision-new'}
                    name="absences.finalDecision"
                    label={intl.formatMessage(messages.finalDecisionLabel)}
                    externalClass={'label-bold form__checkbox-capitalazie-label absence-checkbox'}
                    onChange={handleChange}
                    checkedValue={values.absences.finalDecision}
                  />
                )}
              <Input
                tag="textarea"
                label={intl.formatMessage(messages.descriptionLabel)}
                name="description"
                onChange={handleChange}
                defaultValue={values.description}
                hasError={hasError('description')}
                errorMessage={errors?.description}
                externalClass="event-textarea"
              />
              {eventType.absencesSettings.absencesEnabled &&
                eventType.absencesSettings.requiresApproval.requiresApprovalEnabled &&
                canAuthUserApprove && (
                  <Checkbox
                    id={'absences.approve-new'}
                    name={'absences.approve'}
                    label={intl.formatMessage(messages.approveLabel)}
                    externalClass={
                      'label-bold form__checkbox-block--align-right form__checkbox-capitalazie-label absence-checkbox'
                    }
                    onChange={handleChange}
                    checkedValue={values.absences.approve}
                  />
                )}
            </div>
            <ErrorMessage>{eventError}</ErrorMessage>
            <div className="form__buttons-wrapper">
              {isSendNotificationShown && (
                <div className="switch">
                  <Switch name="sendNotification" onChange={handleChange} checked={values.sendNotification} />
                  <div className="switch__title">
                    <FormattedMessage {...messages.sendNotificationLabel} />
                  </div>
                </div>
              )}
            </div>
            <div className="form__buttons">
              <Button externalClass={'button--modal'} type={'button'} onClick={resetAndExit} color="gray">
                <FormattedMessage {...messages.cancelButton} />
              </Button>
              <Button
                externalClass={'button--modal'}
                type="submit"
                loading={isLoading.createEvent}
                disabled={isLoading.createEvent}
              >
                <FormattedMessage {...messages.saveButton} />
              </Button>
            </div>
          </form>
        </>
      ) : (
        <CustomLoader />
      )}
    </Modal>
  );
}

export default ModalNewEvent;
