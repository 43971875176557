import get from 'lodash-es/get';
import * as yup from 'yup';
import moment from 'moment';
import { UserInfo } from './users.enum';
import { DATE_FORMAT } from '../constants/date.constants';

export class Recognition {
  id: string;
  text: string;
  createdDate: string;
  lastModifiedDate: string;
  recognitionType: {
    id: string;
    name: string;
    icon?: any;
    backgroundColor: string;
  };
  recognitionTypeId: string;
  author: UserInfo;
  authorId: string;
  lastEditor: UserInfo;
  targetUser: UserInfo;
  targetUserId: string;

  constructor(recognition?: unknown) {
    this.id = get(recognition, 'id', '');
    this.text = get(recognition, 'text', '');
    this.createdDate = get(recognition, 'createdDate', '');
    this.lastModifiedDate = get(recognition, 'lastModifiedDate', '');
    this.recognitionType = get(recognition, 'recognitionType', {});
    this.recognitionTypeId = get(recognition, 'recognitionTypeId');
    this.author = new UserInfo(get(recognition, 'author', {}));
    this.authorId = get(recognition, 'authorId');
    this.lastEditor = new UserInfo(get(recognition, 'lastEditor', {}));
    this.targetUser = new UserInfo(get(recognition, 'targetUser', {}));
    this.targetUserId = get(recognition, 'targetUserId');
  }

  static getRecognitionDate(date: string) {
    return moment(date).format(DATE_FORMAT.ll);
  }
}

export const RECOGNITION_TYPE_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().required('Required'),
  backgroundColor: yup.string().trim().required('Color is required'),
});

export const RECOGNITION_VALIDATION_SCHEMA = yup.object().shape({
  recognitionTypeId: yup.string().nullable().required('Required'),
  targetUser: yup.object().shape({
    id: yup.string().typeError('Required').required('Required'),
  }),
  author: yup.object().shape({
    id: yup.string().typeError('Required').required('Required'),
  }),
  text: yup.string().required('Required').max(150, 'Maximum 150 characters'),
});
