import React, { useState } from 'react';
import { EDIT_RESUME_TABS, Resume } from '../../../enums/resumes.enum';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import classNames from 'classnames';
import About from './About';
import Skills from './Skills';
import Projects from './Projects';
import { OptionTypeBase } from 'react-select';
import { FormikErrors, FormikValues } from 'formik';
import {
  LanguageLevelInfoType,
  ForeignLanguageInfoType,
  TechnicalSkillInfoType,
  ProjectInfoType,
} from '../../../types/libraries';

type EditResumeFormProps = {
  resume: Resume;
  languageLevels: LanguageLevelInfoType[];
  foreignLanguages: ForeignLanguageInfoType[];
  technicalSkills: TechnicalSkillInfoType[];
  projectsList: ProjectInfoType[];
  handleChangeLanguage: (value: OptionTypeBase, index: number) => void;
  handleChangeLanguageLevel: (value: OptionTypeBase, index: number) => void;
  removeLanguage: (index: number) => void;
  addNewLanguage: () => void;
  handleChangeSummary: (data: unknown) => void;
  handleChangePersonalHighlights: (data: unknown) => void;
  handleChangeInterestsAndHobbies: (data: unknown) => void;
  handleChangeSkills: (value: OptionTypeBase, index: number) => void;
  onChooseSkillGroupe: (value: OptionTypeBase) => void;
  removeSkillGroupe: (index: number) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  handleChangeProject: (value: OptionTypeBase, index: number) => void;
  removeProject: (index: number) => void;
  addNewProject: () => void;
  isDisabledEditProjects?: boolean;
  handleChangeProjectDescription?: (value: unknown, index: number) => void;
};

const EditResumeTabs = ({
  resume,
  languageLevels,
  foreignLanguages,
  technicalSkills,
  projectsList,
  handleChangeLanguage,
  handleChangeLanguageLevel,
  removeLanguage,
  addNewLanguage,
  handleChangeSummary,
  handleChangePersonalHighlights,
  handleChangeInterestsAndHobbies,
  handleChangeSkills,
  onChooseSkillGroupe,
  removeSkillGroupe,
  setFieldValue,
  handleChangeProject,
  removeProject,
  addNewProject,
  isDisabledEditProjects,
  handleChangeProjectDescription,
}: EditResumeFormProps) => {
  const [activeTab, setActiveContentTab] = useState(EDIT_RESUME_TABS.ABOUT);

  return (
    <div className="form__edit-cv-tabs">
      <div className="form__edit-cv-tabs-headers">
        <button
          className={classNames('form__edit-cv-tab-header', { active: activeTab === EDIT_RESUME_TABS.ABOUT })}
          onClick={() => setActiveContentTab(EDIT_RESUME_TABS.ABOUT)}
          type="button"
        >
          <FormattedMessage {...messages.aboutButton} />
        </button>
        <button
          className={classNames('form__edit-cv-tab-header', {
            active: activeTab === EDIT_RESUME_TABS.SKILLS,
          })}
          onClick={() => setActiveContentTab(EDIT_RESUME_TABS.SKILLS)}
          type="button"
        >
          <FormattedMessage {...messages.skillsColumn} />
        </button>
        <button
          className={classNames('form__edit-cv-tab-header', { active: activeTab === EDIT_RESUME_TABS.PROJECTS })}
          onClick={() => setActiveContentTab(EDIT_RESUME_TABS.PROJECTS)}
          type="button"
        >
          <FormattedMessage {...messages.projectsLabel} />
        </button>
      </div>
      <div className="form__edit-cv-tabs-contents">
        {activeTab === EDIT_RESUME_TABS.PROJECTS && (
          <Projects
            resume={resume}
            projectsList={projectsList}
            handleChangeProject={handleChangeProject}
            removeProject={removeProject}
            addNewProject={addNewProject}
            isDisabledEditProjects={isDisabledEditProjects}
            handleChangeProjectDescription={handleChangeProjectDescription}
          />
        )}
        {activeTab === EDIT_RESUME_TABS.SKILLS && (
          <Skills
            resume={resume}
            technicalSkills={technicalSkills}
            handleChangeSkills={handleChangeSkills}
            onChooseSkillGroupe={onChooseSkillGroupe}
            removeSkillGroupe={removeSkillGroupe}
            setFieldValue={setFieldValue}
          />
        )}
        {activeTab === EDIT_RESUME_TABS.ABOUT && (
          <About
            resume={resume}
            languageLevels={languageLevels}
            foreignLanguages={foreignLanguages}
            handleChangeLanguage={handleChangeLanguage}
            handleChangeLanguageLevel={handleChangeLanguageLevel}
            removeLanguage={removeLanguage}
            addNewLanguage={addNewLanguage}
            handleChangeSummary={handleChangeSummary}
            handleChangePersonalHighlights={handleChangePersonalHighlights}
            handleChangeInterestsAndHobbies={handleChangeInterestsAndHobbies}
          />
        )}
      </div>
    </div>
  );
};

export default EditResumeTabs;
