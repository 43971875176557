import { DashboardLinksParams, MenuLinksParams } from './../enums/params/branding.params';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/branding.constants';
import * as api from '../api/branding.api';
import { AnyAction } from 'redux';
import { setBrandingData, setTextColorData } from '../utils/branding.utils';
import { getQueryParams } from '../utils/params.utils';

function* getPortalBranding() {
  try {
    const { success, results } = yield call(api.getPortalBranding);

    if (success) {
      results.globalAccents && setTextColorData(results);
      yield put({
        type: ActionTypes.GET_PORTAL_BRANDING_SUCCESS,
        payload: results,
      });
      setBrandingData(results);
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PORTAL_BRANDING_FAILURE, payload: error });
  }
}

function* editPortalBranding({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editPortalBranding, payload);

    if (success) {
      yield put({ type: ActionTypes.POST_EDIT_PORTAL_BRANDING_SUCCESS });
      yield call(getPortalBranding);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_EDIT_PORTAL_BRANDING_FAILURE, payload: error });
  }
}

function* getMenuLinksList() {
  const params: MenuLinksParams = yield select((state: RootState) => state.branding.menuLinksParams);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getMenuLinksList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_MENU_LINKS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_MENU_LINKS_LIST_FAILURE, payload: error });
  }
}

function* getDashboardLinks() {
  const params: DashboardLinksParams = yield select((state: RootState) => state.branding.dashboardLinksParams);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getMenuLinksList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_DASHBOARD_LINKS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DASHBOARD_LINKS_FAILURE, payload: error });
  }
}

function* createNewMenuLink({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewMenuLink, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.POST_MENU_LINK_SUCCESS,
      });
      payload.callback();
      yield call(getMenuLinksList);
      yield call(getDashboardLinks);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_MENU_LINK_FAILURE, payload: error });
  }
}

function* editMenuLinkByAdmin({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editMenuLinkByAdmin, payload.id, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_MENU_LINK_BY_ADMIN_SUCCESS,
      });
      payload.callback && payload.callback();
      yield call(getMenuLinksList);
      yield call(getDashboardLinks);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_MENU_LINK_BY_ADMIN_FAILURE, payload: error });
  }
}

function* editLinksOrder({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editLinksOrder, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_LINKS_ORDER_SUCCESS,
      });
      yield call(getDashboardLinks);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_LINKS_ORDER_FAILURE, payload: error });
  }
}

function* deleteMenuLink({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteMenuLink, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_MENU_LINK_SUCCESS,
      });
      payload.callback();
      yield call(getMenuLinksList);
      yield call(getDashboardLinks);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_MENU_LINK_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeEvery(ActionTypes.GET_PORTAL_BRANDING_REQUEST, getPortalBranding),
    takeEvery(ActionTypes.POST_EDIT_PORTAL_BRANDING_REQUEST, editPortalBranding),
    takeEvery(ActionTypes.GET_MENU_LINKS_LIST_REQUEST, getMenuLinksList),
    takeEvery(ActionTypes.GET_DASHBOARD_LINKS_REQUEST, getDashboardLinks),
    takeEvery(ActionTypes.POST_MENU_LINK_REQUEST, createNewMenuLink),
    takeEvery(ActionTypes.PUT_MENU_LINK_BY_ADMIN_REQUEST, editMenuLinkByAdmin),
    takeEvery(ActionTypes.PUT_LINKS_ORDER_REQUEST, editLinksOrder),
    takeEvery(ActionTypes.DELETE_MENU_LINK_REQUEST, deleteMenuLink),
  ]);
}
