import { defineMessages } from 'react-intl';
import {
  newButton,
  editButton,
  deleteButton,
  nameColumn,
  exportToXLSLabel,
  exportToPDFLabel,
  copyURLLabel,
  taskLabel,
  spentLabel,
  externalLabel,
  inactiveLabel,
  typeLabel,
  estimateLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  exportToXLSLabel,
  exportToPDFLabel,
  copyURLLabel,
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  taskLabel,
  spentLabel,
  externalLabel,
  inactiveLabel,
  typeLabel,
  estimateLabel,
  pageTitle: {
    id: 'reports.members.title.members',
    defaultMessage: 'Members',
  },
  memberKeyColumn: {
    id: 'reports.employees.column.member.key',
    defaultMessage: 'Member / Key',
  },
});
