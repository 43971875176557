import React from 'react';
import { UserPreviewInfo } from '../../../enums/users.enum';
import InfoItem from './InfoItem';

type ModifyingInfoProps = {
  author: UserPreviewInfo;
  createdDate: string;
  lastEditor: UserPreviewInfo;
  lastModifiedDate: string;
};

function ModifyingInfo({ author, createdDate, lastEditor, lastModifiedDate }: ModifyingInfoProps) {
  return (
    <div className="page__profile-vacation-info modifyinig-info">
      <InfoItem user={author} date={createdDate} />
      <InfoItem user={lastEditor} date={lastModifiedDate} isCreated={false} />
    </div>
  );
}

export default React.memo(ModifyingInfo);
