import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  typeLabel,
  personalLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  typeLabel,
  personalLabel,
  newExpenseTypeTitle: {
    id: 'finance.expense.type.modal.new.expense.type.title.new.expense.type',
    defaultMessage: 'New Expense Type',
  },
  editExpenseTypeTitle: {
    id: 'finance.expense.type.modal.edit.expense.type.title.edit.expense.type',
    defaultMessage: 'Edit Expense Type',
  },
  companyLabel: {
    id: 'finance.expense.type.modal.edit.expense.type.label.company',
    defaultMessage: 'Company',
  },
});
