import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import Button from '../../Button';
import Modal from '../../Modal';
import Icon from '../../Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Select from '../../Select';
import { UserSkillGroupType } from '../../Profile/Tabs/TechnicalSkills';
import { find, isEmpty } from 'lodash-es';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { TechnicalSkillInfoType } from '../../../types/libraries';
import { detectChanges, scrollToError } from '../../../utils';

type ModalEditUserTechnicalSkillsProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  editTechnicalSkill: (data: UserSkillGroupType[], callback: () => void) => void;
  technicalSkillData: TechnicalSkillInfoType[];
  isLoading: boolean;
  userTechnicalSkills: UserSkillGroupType[];
  requestError: string | RejectValueErrors[] | null;
  skillLevelData: { id: string; name: string; priority: number }[];
};

function ModalEditUserTechnicalSkills({
  isOpen,
  userTechnicalSkills,
  onCloseRequest,
  editTechnicalSkill,
  technicalSkillData,
  isLoading,
  requestError,
  skillLevelData,
}: ModalEditUserTechnicalSkillsProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
  }, []);

  const { values, handleSubmit, resetForm, setFieldValue, setFieldError } = useFormik({
    initialValues: { skillGroups: userTechnicalSkills },
    enableReinitialize: true,
    validate: scrollToError,
    onSubmit: data => editTechnicalSkill(data.skillGroups, resetAndExit),
  });

  useSetFieldsErrors(requestError, setFieldError);

  useEffect(() => {
    setFieldValue(
      'skillGroups',
      userTechnicalSkills?.map((group, index) => ({
        ...userTechnicalSkills[index],
        skillLevelWithSkills: skillLevelData?.map(level => {
          const currentLevel = find(group.skillLevelWithSkills, el => el.skillLevelId === level.id);
          return {
            skillIds: currentLevel?.skillIds || [],
            skillLevelId: level.id,
            skillLevelName: level.name,
            skills: currentLevel?.skills || [],
          };
        }),
      })),
    );
  }, [userTechnicalSkills, skillLevelData]);

  const removeNewSkillGroup = useCallback(
    index => () => {
      values.skillGroups.splice(index, 1);
      setFieldValue('skillGroups', values.skillGroups);
    },
    [values],
  );

  const skillGroupsOptions = useMemo(
    () =>
      detectChanges({
        prevItems: technicalSkillData,
        nextItems: values.skillGroups?.map(el => {
          return { ...el, id: el.skillGroupId };
        }),
      }).removedItems?.map(({ id, name, skills }) => ({
        label: name,
        value: id,
        skills: skills,
      })),
    [values.skillGroups, technicalSkillData],
  );

  const onChooseSkillGroupe = (group: any) => {
    setFieldValue('skillGroups', [
      ...values.skillGroups,
      {
        skillGroupId: group.value,
        skillGroupName: group.label,
        skillLevelWithSkills: skillLevelData.map(({ id, name }: { id: string; name: string }) => ({
          skillIds: [],
          skillLevelId: id,
          skillLevelName: name,
          skills: [],
        })),
      },
    ]);
  };

  const skillLevelOptions = (groupId: string) => {
    if (technicalSkillData && values.skillGroups.length) {
      const groupOptions = find(technicalSkillData, el => el.id === groupId)?.skills;
      let selectedSkills: any[] = [];
      const optionList: any[] = [];

      find(values.skillGroups, el => el.skillGroupId === groupId)?.skillLevelWithSkills.map(level => {
        selectedSkills = selectedSkills.concat(level.skills);
      });

      if (selectedSkills && groupOptions) {
        for (const str of groupOptions) {
          if (!find(selectedSkills, el => el?.id === str.id && el?.skill === str.skill)) {
            optionList.push(str);
          }
        }
      }

      return optionList?.map(({ id, skill }) => ({
        label: skill,
        value: id,
      }));
    }
  };

  const handleChangeSkillLevel = useCallback((value, groupIndex, levelIndex) => {
    setFieldValue(
      `skillGroups[${groupIndex}].skillLevelWithSkills[${levelIndex}].skills`,
      value?.map(({ label, value }: { label: string; value: number }) => ({ id: value, skill: label })),
    );
    setFieldValue(
      `skillGroups[${groupIndex}].skillLevelWithSkills[${levelIndex}].skillIds`,
      value?.map(({ value }: { value: number }) => value),
    );
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      classNameModal="user_technical_skills_modal"
      title={intl.formatMessage(messages.editTechnicalSkillTitle)}
    >
      <form className="modal__form form form--page" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          {values.skillGroups?.map((skillGroup, goupIndex) => (
            <div className="" key={`[${goupIndex}]`}>
              <div className="skill_group_name_block">
                <div className="tabs__content-item__about-column-title">{skillGroup.skillGroupName}</div>
                <button className="form__btn-clean-inputs" type={'button'} onClick={removeNewSkillGroup(goupIndex)}>
                  <Icon iconName={'cross'} externalClass={'form__btn-clean'} />
                </button>
              </div>
              <div className="form__group-subwrapper">
                {values.skillGroups[goupIndex].skillLevelWithSkills?.map((level, levelIndex) => (
                  <div key={level.skillLevelId} className="form__input-block">
                    <Select
                      label={level.skillLevelName + ' Skills'}
                      name={`skillGroups[${goupIndex}].skillLevelWithSkills[levelIndex].skills`}
                      options={skillLevelOptions(skillGroup.skillGroupId)}
                      handleChange={value => handleChangeSkillLevel(value, goupIndex, levelIndex)}
                      value={values.skillGroups[goupIndex].skillLevelWithSkills[levelIndex].skills?.map(item => ({
                        value: item.id,
                        label: item.skill,
                      }))}
                      isMulti
                      isSearchable
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
          {!isEmpty(skillGroupsOptions) && (
            <Select
              iconName={'plus'}
              options={skillGroupsOptions}
              externalClass={'skill_group_select'}
              handleChange={onChooseSkillGroupe}
              value={{ label: 'Skill Group', value: 'new' }}
              externalMenuClass={'skill_group_select_menu'}
              isSearchable={false}
            />
          )}
        </div>
        <ErrorMessage>{requestError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditUserTechnicalSkills;
