import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import capitalize from 'lodash-es/capitalize';
import { NewProfleInfo, USER_CREATE_INFO_VALIDATION_SCHEMA } from '../../../enums/users.enum';
import { MESSENGER_TYPES, PasswordFillType } from '../../../constants/users.constants';
import Modal from '../../Modal';
import Input from '../../Input';
import Button from '../../Button';
import Select from '../../Select';
import Icon from '../../Icon';
import DurationPicker from '../../Dropdown';
import MessengerIcon from '../../Profile/MessengerIcon';
import Checkbox from '../../Checkbox';
import RadioButtonGroup from '../../RadioButtonGroup';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { DepartmentsInfoType } from '../../../types/libraries';
import ChipInput from '../../ChipInput';
import { scrollToError } from '../../../utils';

type ModalInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  departments: DepartmentsInfoType[];
  offices: { id: string; name: string }[];
  jiraUsers: { id: number; username: string }[];
  loading?: boolean;
  requestError: string | RejectValueErrors[] | null;
};

function ModalNewProfile({
  isOpen,
  onClose,
  onSubmit,
  departments,
  offices,
  jiraUsers,
  loading,
  requestError,
}: ModalInfoProps) {
  const { values, handleChange, setFieldValue, handleSubmit, setFieldError, errors, touched } = useFormik({
    initialValues: new NewProfleInfo(),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: USER_CREATE_INFO_VALIDATION_SCHEMA,
    onSubmit: data => {
      const createInfo = data.randomPassword ? { ...data.userCreateInfo, password: '' } : data.userCreateInfo;
      onSubmit(createInfo);
    },
  });

  const intl = useIntl();

  useSetFieldsErrors(requestError, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleAddMsgContact = useCallback(
    messengerType => {
      setFieldValue('messengerContacts', [...values.messengerContacts, { messengerType, ref: '' }]);
    },
    [values],
  );

  const handleRemoveMsgContact = useCallback(
    i => () => {
      const newContacts = [...values.messengerContacts];
      newContacts.splice(i, 1);
      setFieldValue('messengerContacts', newContacts);
    },
    [values],
  );

  const departmentOptions = useMemo(
    () => departments.map(({ id, displayName }) => ({ label: displayName, value: id })),
    [departments],
  );

  const officeOptions = useMemo(() => offices.map(({ id, name }) => ({ label: name, value: id })), [offices]);

  const jiraAccOptions = useMemo(() => jiraUsers.map(({ id, username }) => ({ label: username, value: id })), [
    jiraUsers,
  ]);

  const messengerContactOptions = useMemo(
    () =>
      Object.keys(MESSENGER_TYPES).map(key => ({
        label: (
          <span>
            <MessengerIcon iconName={key} externalClass="dropdown__list-item__icon" />
            {capitalize(key)}
          </span>
        ),
        handler: () => handleAddMsgContact(key),
      })),
    [values],
  );

  const departmentValue = useMemo(
    () => (values.department?.id ? departmentOptions.find(({ value }) => value === values.department?.id) : null),
    [departmentOptions, values],
  );

  const officeValue = useMemo(
    () => (values.office?.id ? officeOptions.find(({ value }) => value === values.office?.id) : null),
    [officeOptions, values],
  );

  const jiraAccValue = useMemo(
    () => (values.jiraUser ? jiraAccOptions.find(({ value }) => value === values.jiraUser) : null),
    [jiraAccOptions, values],
  );

  const emailsValue = useMemo(() => values.emails.map(({ email }) => ({ label: email, value: email })), [values]);

  //@ts-ignore
  const emailsError = useMemo(() => errors?.emails?.reduce((acc, val) => acc || val?.email, null), [errors]);

  const phonesValue = useMemo(() => values.phones.map(phone => ({ label: phone, value: phone })), [values]);

  const handleDepartment = useCallback(({ label, value }) => {
    setFieldValue('department', value ? { id: value, displayName: label } : null);
  }, []);

  const handleOffice = useCallback(
    ({ label, value }) => setFieldValue('office', value ? { id: value, name: label } : null),
    [],
  );

  const handleJiraAcc = useCallback(({ label, value }) => {
    setFieldValue('jiraUser', value);
    setFieldValue('jiraUsername', label);
  }, []);

  const handleEmails = useCallback(
    newValues =>
      setFieldValue(
        'emails',
        newValues.map(({ label }: { label: string }) => ({ email: label, primary: false })),
      ),
    [],
  );

  const handlePhones = useCallback(
    newValues =>
      setFieldValue(
        'phones',
        newValues.map(({ label }: { label: string }) => label),
      ),
    [],
  );

  const handlePasswordFillTypeChange = useCallback(e => {
    if ((e.target as HTMLElement).id === PasswordFillType.RANDOM) {
      setFieldValue('password', '********');
      setFieldValue('randomPassword', true);
      setFieldValue('sendDetailsViaEmail', true);
    } else {
      setFieldValue('password', '');
      setFieldValue('randomPassword', false);
      setFieldValue('sendDetailsViaEmail', false);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} title="New Profile" classNameModal="modal--edit-profile">
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__group">
            <div className="form__grid-wrapper">
              <Input
                name="firstName"
                label={intl.formatMessage(messages.firstNameInputLabel)}
                bold
                defaultValue={values.firstName}
                onChange={handleChange}
                hasError={hasError('firstName')}
                errorMessage={errors?.firstName}
              />
              <Input
                name="secondName"
                label={intl.formatMessage(messages.secondNameInputLabel)}
                bold
                defaultValue={values.secondName}
                onChange={handleChange}
                hasError={hasError('secondName')}
                errorMessage={errors?.secondName}
              />
            </div>
          </div>
          <div className="form__group">
            <Select
              label={intl.formatMessage(messages.departmentLabel)}
              iconName="building"
              options={departmentOptions}
              value={departmentValue}
              handleChange={handleDepartment}
              hasError={hasError('department')}
              // @ts-ignore
              errorMessage={errors?.department}
              isClearable
            />
            <div className="form__grid-wrapper">
              <Select
                label={intl.formatMessage(messages.officeLabel)}
                iconName="map-marker"
                options={officeOptions}
                value={officeValue}
                handleChange={handleOffice}
                hasError={hasError('office')}
                // @ts-ignore
                errorMessage={errors?.office}
                isClearable
              />
              <Select
                isSearchable
                label={intl.formatMessage(messages.JIRAAccountSelect)}
                iconName="jira"
                iconComponent="MessengerIcon"
                options={jiraAccOptions}
                value={jiraAccValue}
                handleChange={handleJiraAcc}
                hasError={hasError('jiraUser')}
                errorMessage={errors?.jiraUser}
                isClearable
              />
              <Input
                label={intl.formatMessage(messages.emailLabel)}
                name="email"
                iconName="envelope"
                wrapperClass="page__profile-contact--email"
                defaultValue={values.email}
                onChange={handleChange}
                hasError={hasError('email')}
                errorMessage={errors?.email}
              />
              {values.messengerContacts.map(({ messengerType, ref }, i) => (
                <Input
                  key={i.toString()}
                  id={`${messengerType}-${i}`}
                  label={capitalize(messengerType)}
                  name={`messengerContacts[${i}].ref`}
                  defaultValue={ref}
                  onChange={handleChange}
                  iconName={messengerType}
                  iconComponent="MessengerIcon"
                  control={
                    <button type="button" className="form__input-control" onClick={handleRemoveMsgContact(i)}>
                      <Icon iconName="times-circle" />
                    </button>
                  }
                  hasError={hasError(`messengerContacts[${i}]`)}
                  errorMessage={
                    get(errors, ['messengerContacts', i, 'ref']) ||
                    get(errors, ['messengerContacts', i, 'messengerType'])
                  }
                />
              ))}
              <div className="form__dropdown-wrapper">
                <DurationPicker
                  dropdownToggle={
                    <>
                      <Icon iconName="plus" externalClass="form__icon-btn-add" />
                      <FormattedMessage {...messages.addButton} />
                      <Icon iconName="select-arrow" />
                    </>
                  }
                  dropdownToggleClass="form__btn-add-group"
                  dropdownList={messengerContactOptions}
                  placement="bottom"
                />
              </div>
            </div>
          </div>
          <ChipInput
            label={intl.formatMessage(messages.otherEmailsInput)}
            iconName="envelope"
            externalClass="page__profile-contact--email"
            value={emailsValue}
            handleChange={handleEmails}
            hasError={hasError('emails')}
            errorMessage={emailsError}
          />
          <ChipInput
            label={intl.formatMessage(messages.phonesLabel)}
            iconName="phone"
            externalClass="page__profile-contact--phones"
            value={phonesValue}
            handleChange={handlePhones}
            hasError={hasError('phones')}
          />
          <div className="login-details">
            <Input
              type="text"
              name="username"
              label={intl.formatMessage(messages.loginInput)}
              onChange={handleChange}
              hasError={hasError('username')}
              errorMessage={errors?.username}
            />
            <p>
              <FormattedMessage {...messages.passwordTitle} />
            </p>
            {/* TODO implement random password when api is ready */}
            <RadioButtonGroup
              groupItems={[
                { id: PasswordFillType.RANDOM, label: intl.formatMessage(messages.passwordTypeRandomRadioInput) },
                { id: PasswordFillType.CUSTOM, label: intl.formatMessage(messages.passwordTypeCustomRadioInput) },
              ]}
              name="randomPassword"
              onChange={handlePasswordFillTypeChange}
              checked={values.randomPassword ? PasswordFillType.RANDOM : PasswordFillType.CUSTOM}
            />
            <Input
              disabled={values.randomPassword}
              type={values.randomPassword ? 'password' : 'text'}
              name="password"
              label={intl.formatMessage(messages.passwordInput)}
              onChange={handleChange}
              defaultValue={values.password}
              hasError={hasError('password')}
              errorMessage={errors?.password}
            />
            <Checkbox
              label={intl.formatMessage(messages.sendAccountDetailsViaEmailCheckbox)}
              id="send-details"
              externalClass="checkbox-label"
              name="sendDetailsViaEmail"
              onChange={handleChange}
              checkedValue={values.sendDetailsViaEmail}
              disabled={values.randomPassword}
            />
          </div>
        </div>
        <ErrorMessage>{requestError}</ErrorMessage>
        <div className="form__buttons">
          <Button type="button" onClick={onClose} color="gray" externalClass="button--modal">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button type="submit" externalClass="button--modal" loading={loading} disabled={loading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewProfile;
