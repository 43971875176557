export const MESSENGER_TYPES = {
  TELEGRAM: 'TELEGRAM',
  WHATSAPP: 'WHATSAPP',
  VIBER: 'VIBER',
  FACEBOOK: 'FACEBOOK',
  // INSTAGRAM: 'INSTAGRAM',
  VK: 'VK',
  WECHAT: 'WECHAT',
  QQ: 'QQ',
  SNAPCHAT: 'SNAPCHAT',
  DISCORD: 'DISCORD',
  SKYPE: 'SKYPE',
  SLACK: 'SLACK',
  // LINKEDIN: 'LINKEDIN',
  OTHER: 'OTHER',
};

export const URI_SCHEMES = {
  [MESSENGER_TYPES.SKYPE]: 'skype:',
};

export const PROFILE_TABS = {
  ABOUT: 'ABOUT',
  WORK_TIME: 'WORK_TIME',
  SCHEDULE: 'SCHEDULE',
  COMPETENCIES: 'COMPETENCIES',
  TECHNICAL_SKILLS: 'TECHNICAL_SKILLS',
  GRADES_AND_BONUSES: 'GRADES_AND_BONUSES',
  RECOGNITIONS: 'RECOGNITIONS',
  PUBLIC_CV: 'PUBLIC_CV',
};

export enum PeopleLayouts {
  GRID = 'GRID',
  LIST = 'LIST',
}

export enum PasswordFillType {
  RANDOM = 'RANDOM',
  CUSTOM = 'CUSTOM',
}

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const GET_USER_PERSONAL_INFO_REQUEST = 'GET_USER_PERSONAL_INFO_REQUEST';
export const GET_USER_PERSONAL_INFO_SUCCESS = 'GET_USER_PERSONAL_INFO_SUCCESS';
export const GET_USER_PERSONAL_INFO_FAILURE = 'GET_USER_PERSONAL_INFO_FAILURE';

export const GET_USER_PROFESSIONAL_INFO_REQUEST = 'GET_USER_PROFESSIONAL_INFO_REQUEST';
export const GET_USER_PROFESSIONAL_INFO_SUCCESS = 'GET_USER_PROFESSIONAL_INFO_SUCCESS';
export const GET_USER_PROFESSIONAL_INFO_FAILURE = 'GET_USER_PROFESSIONAL_INFO_FAILURE';

export const GET_JIRA_USERS_BRIEF_REQUEST = 'GET_JIRA_USERS_BRIEF_REQUEST';
export const GET_JIRA_USERS_BRIEF_SUCCESS = 'GET_JIRA_USERS_BRIEF_SUCCESS';
export const GET_JIRA_USERS_BRIEF_FAILURE = 'GET_JIRA_USERS_BRIEF_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const PUT_USER_INFO_REQUEST = 'PUT_USER_INFO_REQUEST';
export const PUT_USER_INFO_SUCCESS = 'PUT_USER_INFO_SUCCESS';
export const PUT_USER_INFO_FAILURE = 'PUT_USER_INFO_FAILURE';

export const PUT_USER_PERSONAL_INFO_REQUEST = 'PUT_USER_PERSONAL_INFO_REQUEST';
export const PUT_USER_PERSONAL_INFO_SUCCESS = 'PUT_USER_PERSONAL_INFO_SUCCESS';
export const PUT_USER_PERSONAL_INFO_FAILURE = 'PUT_USER_PERSONAL_INFO_FAILURE';

export const PUT_USER_PROFESSIONAL_INFO_REQUEST = 'PUT_USER_PROFESSIONAL_INFO_REQUEST';
export const PUT_USER_PROFESSIONAL_INFO_SUCCESS = 'PUT_USER_PROFESSIONAL_INFO_SUCCESS';
export const PUT_USER_PROFESSIONAL_INFO_FAILURE = 'PUT_USER_PROFESSIONAL_INFO_FAILURE';

export const POST_USER_REQUEST = 'POST_USER_REQUEST';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILURE = 'POST_USER_FAILURE';

export const SET_USERS_PARAMS_REQUEST = 'SET_USERS_PARAMS_REQUEST';
export const RESET_USER_ERRORS = 'RESET_USER_ERRORS';

export const PATCH_USER_INFO_REQUEST = 'PATCH_USER_INFO_REQUEST';
export const PATCH_USER_INFO_SUCCESS = 'PATCH_USER_INFO_SUCCESS';
export const PATCH_USER_INFO_FAILURE = 'PATCH_USER_INFO_FAILURE';

export const POST_USER_PHOTO_REQUEST = 'POST_USER_PHOTO_REQUEST';
export const POST_USER_PHOTO_SUCCESS = 'POST_USER_PHOTO_SUCCESS';
export const POST_USER_PHOTO_FAILURE = 'POST_USER_PHOTO_FAILURE';

export const DELETE_USER_PHOTO_REQUEST = 'DELETE_USER_PHOTO_REQUEST';
export const DELETE_USER_PHOTO_SUCCESS = 'DELETE_USER_PHOTO_SUCCESS';
export const DELETE_USER_PHOTO_FAILURE = 'DELETE_USER_PHOTO_FAILURE';

export const GET_USER_TECHNICAL_SKILLS_REQUEST = 'GET_USER_TECHNICAL_SKILLS_REQUEST';
export const GET_USER_TECHNICAL_SKILLS_SUCCESS = 'GET_USER_TECHNICAL_SKILLS_SUCCESS';
export const GET_USER_TECHNICAL_SKILLS_FAILURE = 'GET_USER_TECHNICAL_SKILLS_FAILURE';

export const PUT_USER_TECHNICAL_SKILLS_REQUEST = 'PUT_USER_TECHNICAL_SKILLS_REQUEST';
export const PUT_USER_TECHNICAL_SKILLS_SUCCESS = 'PUT_USER_TECHNICAL_SKILLS_SUCCESS';
export const PUT_USER_TECHNICAL_SKILLS_FAILURE = 'PUT_USER_TECHNICAL_SKILLS_FAILURE';

export const PATCH_UPDATE_USER_PASSWORD_REQUEST = 'PATCH_UPDATE_USER_PASSWORD_REQUEST';
export const PATCH_UPDATE_USER_PASSWORD_SUCCESS = 'PATCH_UPDATE_USER_PASSWORD_SUCCESS';
export const PATCH_UPDATE_USER_PASSWORD_FAILURE = 'PATCH_UPDATE_USER_PASSWORD_FAILURE';

export const GET_USER_POSITION_HISTORY_REQUEST = 'GET_USER_POSITION_HISTORY_REQUEST';
export const GET_USER_POSITION_HISTORY_SUCCESS = 'GET_USER_POSITION_HISTORY_SUCCESS';
export const GET_USER_POSITION_HISTORY_FAILURE = 'GET_USER_POSITION_HISTORY_FAILURE';

export const PUT_USER_POSITION_HISTORY_REQUEST = 'PUT_USER_POSITION_HISTORY_REQUEST';
export const PUT_USER_POSITION_HISTORY_SUCCESS = 'PUT_USER_POSITION_HISTORY_SUCCESS';
export const PUT_USER_POSITION_HISTORY_FAILURE = 'PUT_USER_POSITION_HISTORY_FAILURE';

export const GET_USER_EXPERIENCES_REQUEST = 'GET_USER_EXPERIENCES_REQUEST';
export const GET_USER_EXPERIENCES_SUCCESS = 'GET_USER_EXPERIENCES_SUCCESS';
export const GET_USER_EXPERIENCES_FAILURE = 'GET_USER_EXPERIENCES_FAILURE';

export const GET_USER_BONUSES_REQUEST = 'GET_USER_BONUSES_REQUEST';
export const GET_USER_BONUSES_SUCCESS = 'GET_USER_BONUSES_SUCCESS';
export const GET_USER_BONUSES_FAILURE = 'GET_USER_BONUSES_FAILURE';

export const RESET_USERS_STATE = 'RESET_USERS_STATE';
export const RESET_USERS_CURRENT_STATE = 'RESET_USERS_CURRENT_STATE';

export const GET_USER_ABSENCE_PERIODS_REQUEST = 'GET_USER_ABSENCE_PERIODS_REQUEST';
export const GET_USER_ABSENCE_PERIODS_SUCCESS = 'GET_USER_ABSENCE_PERIODS_SUCCESS';
export const GET_USER_ABSENCE_PERIODS_FAILURE = 'GET_USER_ABSENCE_PERIODS_FAILURE';

export const GET_DETAILED_USER_ABSENCE_PERIODS_INFO_REQUEST = 'GET_DETAILED_USER_ABSENCE_PERIODS_INFO_REQUEST';
export const GET_DETAILED_USER_ABSENCE_PERIODS_INFO_SUCCESS = 'GET_DETAILED_USER_ABSENCE_PERIODS_INFO_SUCCESS';
export const GET_DETAILED_USER_ABSENCE_PERIODS_INFO_FAILURE = 'GET_DETAILED_USER_ABSENCE_PERIODS_INFO_FAILURE';
