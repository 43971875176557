import { groupBy, map } from 'lodash-es';
import moment, { Moment } from 'moment';
import { DATE_FORMAT, weekday } from '../../../constants/date.constants';
import { EventInfo, EventPreviewInfo } from '../../../enums/schedule.enum';
import { OfficesWorkDaysType } from '../../../types/libraries/libraries.Reducer.type';
import { DaysOffInfoType } from '../../../types/schedule/scheduleReducer.type';
import { isDark } from '../../../utils/color.utils';
import { InlineDatePickerPeriods } from '../../InlineDatePicker/constants';
import { DateSelectArg } from '@fullcalendar/react';
import { EventTypeInfo } from '../../../enums/calendar.enum';

export const getIsHoliday = (
  currentDate: Moment,
  daysOffInfo: DaysOffInfoType,
  officesWorkDays: OfficesWorkDaysType,
) => {
  const isHoliday = daysOffInfo.holidaysDates.some(
    el => currentDate >= moment(el.start) && currentDate <= moment(el.end),
  );

  const isExtraDay = daysOffInfo.extraWorkDates.some(
    el =>
      currentDate >= moment(el.start) &&
      currentDate <= moment(el.end) &&
      el.locations.length === officesWorkDays.length,
  );

  return (getMinWorkDays(officesWorkDays).every(el => el !== weekday[currentDate.day()]) && !isExtraDay) || isHoliday;
};

export function getDates(
  startDate: string,
  endDate: string,
  daysOffInfo: DaysOffInfoType,
  officesWorkDays: OfficesWorkDaysType,
) {
  const start = moment(startDate);
  const end = moment(endDate);
  const dateArray = [];
  let currentDate = start;
  while (currentDate <= end) {
    dateArray.push({
      date: currentDate.format(DATE_FORMAT.YYYY_MM_DD),
      isHoliday: getIsHoliday(currentDate, daysOffInfo, officesWorkDays),
    });
    currentDate = currentDate.add(1, 'days');
  }

  return dateArray;
}

const getMinWorkDays = (officesWorkDays: OfficesWorkDaysType): string[] => {
  const officeWithMinWorkdays = officesWorkDays.reduce(
    (prevValue: any, currentValue: any) =>
      currentValue.workdays.length < prevValue.workdays.length ? currentValue : prevValue,
    officesWorkDays[0],
  );
  officesWorkDays.forEach(el => {
    if (el.officeId !== officeWithMinWorkdays.officeId) {
      officeWithMinWorkdays.workdays.forEach((day: string) => {
        if (!el.workdays.includes(day)) {
          officeWithMinWorkdays.workdays = officeWithMinWorkdays.workdays.filter((el: string) => el !== day);
        }
      });
    }
  });

  return officeWithMinWorkdays ? officeWithMinWorkdays.workdays : [];
};

export const getGroupByDepartmentsTableData = (
  eventTableData: EventPreviewInfo[],
  dateFrom: string,
  dateTo: string,
) => {
  const grouped = groupBy(
    eventTableData.filter(
      el =>
        !!el.targetEmployee.id &&
        !!el.targetEmployee?.department.id &&
        moment(el.startDate) <= moment(dateTo) &&
        moment(el.endDate) >= moment(dateFrom),
    ),
    el => el.targetEmployee?.department?.id,
  );

  return map(grouped, (events: EventInfo[]) => {
    const department = events[0].targetEmployee?.department;
    return {
      departmentId: department?.id,
      departmentName: department?.displayName,
      users: map(
        groupBy(events, el => el.targetEmployee?.id),
        el => ({
          user: el[0].targetEmployee,
          events: el,
        }),
      ).sort((a: any, b: any) => a.user.secondName?.localeCompare(b.user.secondName)),
    };
  }).sort((a: any, b: any) => a.departmentName?.localeCompare(b.departmentName));
};

export const getIsAllDayEvent = (event: EventPreviewInfo) => {
  const isOneDayEvent = event.isOneDayEvent;
  return event.allDay || !isOneDayEvent || (isOneDayEvent && !event.showTime);
};

export const getCalendarEventList = (tableData: EventPreviewInfo[], calendarPeriod: InlineDatePickerPeriods) => {
  return tableData.map(event => {
    const allDayEvent = getIsAllDayEvent(event);
    return {
      title: event.calendarTitle,
      start: `${event.localStartDate} ${event.showTime ? event.localStartTime : ''}`.trim(),
      end: `${
        (calendarPeriod === InlineDatePickerPeriods.MONTH_PERIOD ||
          calendarPeriod === InlineDatePickerPeriods.WEEK_PERIOD) &&
        allDayEvent
          ? moment(event.localEndDate).add(1, 'd').format(DATE_FORMAT.YYYY_MM_DD)
          : event.localEndDate
      } ${event.showTime ? event.localEndTime : ''}`.trim(),

      id: event.id,
      allDay: allDayEvent,
      color: event.eventTypeColor,
      textColor: isDark(event.eventTypeColor) ? '#fff' : '#4A4A4A',
      extendedProps: {
        event,
      },
    };
  });
};

export const sortedPopoverEvents = (events: EventPreviewInfo[]) => {
  const allDayEvents = events
    .filter(event => getIsAllDayEvent(event))
    .sort(
      (a, b) =>
        moment(a.startDate).diff(moment(a.endDate), 'minute') - moment(b.startDate).diff(moment(b.endDate), 'minute'),
    );
  const oneDayEvents = events
    .filter(event => !event.allDay && event.isOneDayEvent && event.showTime)
    .sort((a, b) => moment.duration(a.startTime).asMinutes() - moment.duration(b.startTime).asMinutes());

  return allDayEvents.concat(oneDayEvents);
};

export enum CalendarViews {
  YEAR_VIEW = 'YEAR_VIEW',
  MONTH_VIEW = 'dayGridMonth',
  WEEK_VIEW = 'timeGridWeek',
  DAY_VIEW = 'timeGridDay',
}

export const handleFullCalendarView = (calendarPeriod: InlineDatePickerPeriods) => {
  switch (calendarPeriod) {
    case InlineDatePickerPeriods.YEAR_PERIOD: {
      return '';
    }
    case InlineDatePickerPeriods.MONTH_PERIOD: {
      return CalendarViews.MONTH_VIEW;
    }
    case InlineDatePickerPeriods.WEEK_PERIOD: {
      return CalendarViews.WEEK_VIEW;
    }
    case InlineDatePickerPeriods.DAY_PERIOD: {
      return CalendarViews.DAY_VIEW;
    }
  }
};

export const eventClasses = ['month-day', 'day-wrapper', 'fc-daygrid-more-link'];
export const dropdownBtnClasses = ['dropdown__list-item__button', 'dropdown__list-item__wrapper'];
export const dropdownIconClasses = ['pencil', 'trash'];

const startDayTime = '00:00:00';
const endDayTime = '23:59:59';

export const getInitialDate = (
  calendarClikedDate: DateSelectArg | null | undefined,
  eventType: EventTypeInfo | null,
) => {
  if (!calendarClikedDate || !eventType) {
    return {};
  }
  const start = moment(calendarClikedDate.start);
  const end = moment(calendarClikedDate.end);
  return {
    startDate: start.format(DATE_FORMAT.YYYY_MM_DD),
    endDate: moment(
      eventType?.dateRangesEnabled
        ? calendarClikedDate.allDay || end.clone().valueOf() === end.clone().startOf('D').valueOf()
          ? end.subtract(1, 'day')
          : calendarClikedDate.end
        : calendarClikedDate.start,
    ).format(DATE_FORMAT.YYYY_MM_DD),
    ...(!calendarClikedDate.allDay && {
      startTime: eventType.timeEnabled ? start.format(DATE_FORMAT.HH_mm_ss) : startDayTime,
      endTime: eventType.timeEnabled
        ? moment(
            (eventType?.dateRangesEnabled || end.isSame(calendarClikedDate.start, 'd')) &&
              end.clone().valueOf() !== end.clone().startOf('d').valueOf()
              ? calendarClikedDate.end
              : start.endOf('D'),
          ).format(DATE_FORMAT.HH_mm_ss)
        : endDayTime,
    }),
    allDay: calendarClikedDate.allDay,
  };
};
