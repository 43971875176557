export const GET_PORTAL_BRANDING_REQUEST = 'GET_PORTAL_BRANDING_REQUEST';
export const GET_PORTAL_BRANDING_SUCCESS = 'GET_PORTAL_BRANDING_SUCCESS';
export const GET_PORTAL_BRANDING_FAILURE = 'GET_PORTAL_BRANDING_FAILURE';

export const POST_EDIT_PORTAL_BRANDING_REQUEST = 'POST_EDIT_PORTAL_BRANDING_REQUEST';
export const POST_EDIT_PORTAL_BRANDING_SUCCESS = 'POST_EDIT_PORTAL_BRANDING_SUCCESS';
export const POST_EDIT_PORTAL_BRANDING_FAILURE = 'POST_EDIT_PORTAL_BRANDING_FAILURE';

export const GET_MENU_LINKS_LIST_REQUEST = 'GET_MENU_LINKS_LIST_REQUEST';
export const GET_MENU_LINKS_LIST_SUCCESS = 'GET_MENU_LINKS_LIST_SUCCESS';
export const GET_MENU_LINKS_LIST_FAILURE = 'GET_MENU_LINKS_LIST_FAILURE';

export const GET_DASHBOARD_LINKS_REQUEST = 'GET_DASHBOARD_LINKS_REQUEST';
export const GET_DASHBOARD_LINKS_SUCCESS = 'GET_DASHBOARD_LINKS_SUCCESS';
export const GET_DASHBOARD_LINKS_FAILURE = 'GET_DASHBOARD_LINKS_FAILURE';

export const POST_MENU_LINK_REQUEST = 'POST_MENU_LINK_REQUEST';
export const POST_MENU_LINK_SUCCESS = 'POST_MENU_LINK_SUCCESS';
export const POST_MENU_LINK_FAILURE = 'POST_MENU_LINK_FAILURE';

export const PUT_MENU_LINK_BY_ADMIN_REQUEST = 'PUT_MENU_LINK_BY_ADMIN_REQUEST';
export const PUT_MENU_LINK_BY_ADMIN_SUCCESS = 'PUT_MENU_LINK_BY_ADMIN_SUCCESS';
export const PUT_MENU_LINK_BY_ADMIN_FAILURE = 'PUT_MENU_LINK_BY_ADMIN_FAILURE';

export const PUT_LINKS_ORDER_REQUEST = 'PUT_LINKS_ORDER_REQUEST';
export const PUT_LINKS_ORDER_SUCCESS = 'PUT_LINKS_ORDER_SUCCESS';
export const PUT_LINKS_ORDER_FAILURE = 'PUT_LINKS_ORDER_FAILURE';

export const DELETE_MENU_LINK_REQUEST = 'DELETE_MENU_LINK_REQUEST';
export const DELETE_MENU_LINK_SUCCESS = 'DELETE_MENU_LINK_SUCCESS';
export const DELETE_MENU_LINK_FAILURE = 'DELETE_MENU_LINK_FAILURE';

export const RESET_BRANDING_ERRORS = 'RESET_BRANDING_ERRORS';

export const RESET_BRANDING_STATE = 'RESET_BRANDING_STATE';
