import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth.reducer';
import brandingReducer from './branding.reducer';
import usersReducer from './users.reducer';
import librariesReducer from './libraries.reducer';
import recognitionsReducer from './recognitions.reducer';
import competenciesReducer from './competencies.reducer';
import questionnairesReducer from './questionnaires.reducer';
import calendarReducer from './calendar.reducer';
import planningReducer from './planning.reducer';
import scheduleReducer from './schedule.reducer';
import resumesReducer from './resumes.reducer';
import notificationsReducer from './notifications.reducer';
import bonusesReducer from './bonuses.reducer';
import policiesReducer from './policies.reducer';
import reportsReducer from './reports.reducer';
import financeReducer from './finance.reducer';
import filterReducer from './filters.reducer';
import activityReducer from './activity.reducer';
import candidatesReducer from './candidates.reducer';
import exportReducer from './export.reducer';

export default (history: RouterHistory) => {
  return combineReducers({
    router: connectRouter(history),
    activity: activityReducer,
    auth: authReducer,
    branding: brandingReducer,
    users: usersReducer,
    libraries: librariesReducer,
    recognitions: recognitionsReducer,
    competencies: competenciesReducer,
    questionnaires: questionnairesReducer,
    calendar: calendarReducer,
    planning: planningReducer,
    schedule: scheduleReducer,
    resumes: resumesReducer,
    notifications: notificationsReducer,
    bonuses: bonusesReducer,
    policies: policiesReducer,
    reports: reportsReducer,
    finance: financeReducer,
    filters: filterReducer,
    candidates: candidatesReducer,
    exportReducer,
  });
};
