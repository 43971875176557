import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as brandingActions from '../../../actions/branding.actions';
import BrandingContext from '../../../BrandingContext';
import { ResourceLink } from '../../../enums/branding.enum';
import IntersectionObserverWrapper from './IntersectionObserverWrapper';
import ModalReorderLinks from './ModalReorderLinks';

function CustomHeaderNavigation({
  progressEvent,
  isPendingRequests,
  dashboardLinksError,
  isLoading,
  dashboardLinks,
  getDashboardLinks,
  editLinksOrder,
  resetErrors,
}: ConnectedProps<typeof connector>) {
  useEffect(() => {
    getDashboardLinks();
  }, []);

  const [modalReorderLinksIsOpen, setModalReorderLinksIsOpen] = useState(false);
  const branding = useContext(BrandingContext);

  const openReorderLinksModal = useCallback(() => {
    setModalReorderLinksIsOpen(true);
  }, []);

  const closeReorderLinksModal = useCallback(() => {
    setModalReorderLinksIsOpen(false);
  }, []);

  const navigationClassName = classNames('header__custom-nav', {
    export: progressEvent || isPendingRequests,
  });

  return dashboardLinks.length > 0 ? (
    <>
      <nav className={navigationClassName}>
        <ul className="header__custom-nav__menu">
          <IntersectionObserverWrapper openReorderLinksModal={openReorderLinksModal}>
            {dashboardLinks.map((link: ResourceLink) => {
              return (
                <li className="header__custom-nav__item" key={link.id} data-targetid={link.id}>
                  <a
                    className="header__custom-nav__link"
                    target="_blank"
                    href={link.url}
                    style={{ color: branding.headerForeground }}
                    rel="noreferrer"
                  >
                    {link.file?.url && (
                      <img
                        src={link.file.url}
                        className={classNames('link-icon', { 'icon-and-name': link.displayName })}
                      />
                    )}
                    {link.displayName}
                  </a>
                </li>
              );
            })}
          </IntersectionObserverWrapper>
        </ul>
      </nav>
      {modalReorderLinksIsOpen && (
        <ModalReorderLinks
          isOpen
          dashboardLinks={dashboardLinks}
          onCloseRequest={closeReorderLinksModal}
          editLinksOrder={editLinksOrder}
          dashboardLinksError={dashboardLinksError}
          isLoading={isLoading.editLinksOrder}
          resetErrors={resetErrors}
        />
      )}
    </>
  ) : null;
}

const mapStateToProps = ({ branding, exportReducer }: RootState) => ({
  progressEvent: exportReducer.progressEvent,
  isPendingRequests: exportReducer.isPendingRequests,
  dashboardLinks: branding.dashboardLinks,
  dashboardLinksError: branding.errors.dashboardLinksError,
  isLoading: branding.loading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getDashboardLinks: () => dispatch(brandingActions.getDashboardLinks()),
  editLinksOrder: (data: any) => dispatch(brandingActions.editLinksOrder(data)),
  resetErrors: () => dispatch(brandingActions.resetBrandingErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CustomHeaderNavigation);
