import { defineMessages } from 'react-intl';
import { cancelButton, saveButton, valueLabel, addButton } from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  valueLabel,
  addButton,
  gradeFromLabel: {
    id: 'bonuses.and.grades.bonuses.by.grades.modal.label.grade.from',
    defaultMessage: 'Grade (From)',
  },
  gradeToLabel: {
    id: 'bonuses.and.grades.bonuses.by.grades.modal.label.grade.to',
    defaultMessage: 'Grade (To)',
  },
});
