import { RejectValueErrors } from './../enums/error.enum';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/competencies.contsants';
import { CompetenceType } from '../pages/Competencies/Competencies';
import { SkillQuestionsListItemType } from '../pages/QuestionsForSkill';
import { getReducerErrors } from '../utils/reducerError.utils';
import { AssessmentsInfo } from '../enums/competencies.enum';
import * as paramsTypes from '../enums/params/competencies.params';

type InitialStateType = {
  competenciesTypesTableData: any;
  competenciesTableData: any;
  skillsQuestionsTableData: any;
  skillQuestionsData: any;
  competenceData: any;
  assessmentsTableData: any;
  lastAssessmentData: any;
  assessmentData: AssessmentsInfo;
  specializationsPercentage: any;
  competenciesPercentage: any;
  userCompetenceAssessments: any;
  competenceLevelsPercentage: any;
  competenciesTypesParams: paramsTypes.CompetenciesTypesParams;
  skillQuestionsParams: paramsTypes.SkillsQuestionsParams;
  competenciesParams: paramsTypes.CompetenciesParams;
  assessmentsParams: paramsTypes.AssessmentsParams;
  errors: {
    competenciesListError: string | null;
    createCompetenceError: string | RejectValueErrors[] | null;
    editCompetenceError: string | RejectValueErrors[] | null;
    deleteCompetenceError: string | RejectValueErrors[] | null;
    competenciesTypesError: string | RejectValueErrors[] | null;
    competenceDataError: string | null;
    assessmentsDataError: string | null;
    oneAssessmentDataError: string | null;
    editAssessmentError: string | RejectValueErrors[] | null;
    createAssessmentError: string | RejectValueErrors[] | null;
    skillsQuestionsTableDataError: string | null;
    skillQuestionsDataError: string | RejectValueErrors[] | null;
    competenciesPercentageError: string | null;
    specializationsPercentageError: string | null;
    userCompetenceAssessmentsErrors: string | null;
    competenceLevelsPercentage: string | null;
  };
  loading: {
    getCompetenciesList: boolean;
    getCompetence: boolean;
    createCompetence: boolean;
    editCompetence: boolean;
    deleteCompetence: boolean;
    getCompetenciesTypes: boolean;
    createCompetenciesType: boolean;
    editCompetenciesType: boolean;
    deleteCompetenciesType: boolean;
    getAssessments: boolean;
    getOneAssessment: boolean;
    deleteAssessment: boolean;
    createNewAssessment: boolean;
    editAssessment: boolean;
    getSkillQuestionsList: boolean;
    getSkillQuestions: boolean;
    createSkillQuestions: boolean;
    editSkillQuestions: boolean;
    competenciesPercentage: boolean;
    specializationsPercentage: boolean;
    userCompetenceAssessments: boolean;
    competenceLevelsPercentage: boolean;
  };
};

const initialState: InitialStateType = {
  competenciesTypesTableData: null,
  competenciesTableData: null,
  competenceData: null,
  assessmentsTableData: null,
  lastAssessmentData: null,
  assessmentData: new AssessmentsInfo(),
  skillQuestionsData: null,
  skillsQuestionsTableData: null,
  specializationsPercentage: null,
  competenciesPercentage: null,
  userCompetenceAssessments: null,
  competenceLevelsPercentage: null,
  skillQuestionsParams: new paramsTypes.SkillsQuestionsParams(),
  competenciesTypesParams: new paramsTypes.CompetenciesTypesParams(),
  competenciesParams: new paramsTypes.CompetenciesParams(),
  assessmentsParams: new paramsTypes.AssessmentsParams(),
  errors: {
    competenciesListError: null,
    createCompetenceError: null,
    editCompetenceError: null,
    deleteCompetenceError: null,
    competenciesTypesError: null,
    competenceDataError: null,
    assessmentsDataError: null,
    oneAssessmentDataError: null,
    editAssessmentError: null,
    createAssessmentError: null,
    skillQuestionsDataError: null,
    skillsQuestionsTableDataError: null,
    competenciesPercentageError: null,
    specializationsPercentageError: null,
    userCompetenceAssessmentsErrors: null,
    competenceLevelsPercentage: null,
  },
  loading: {
    getCompetenciesList: false,
    getAssessments: false,
    getOneAssessment: false,
    getCompetence: false,
    createCompetence: false,
    editCompetence: false,
    deleteCompetence: false,
    getCompetenciesTypes: false,
    createCompetenciesType: false,
    editCompetenciesType: false,
    deleteCompetenciesType: false,
    deleteAssessment: false,
    createNewAssessment: false,
    editAssessment: false,
    getSkillQuestionsList: false,
    getSkillQuestions: false,
    createSkillQuestions: false,
    editSkillQuestions: false,
    competenciesPercentage: false,
    specializationsPercentage: false,
    userCompetenceAssessments: false,
    competenceLevelsPercentage: false,
  },
};

const competenciesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_COMPETENCIES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCompetenciesList: true },
      };

    case ActionTypes.GET_COMPETENCIES_LIST_SUCCESS:
      return {
        ...state,
        competenciesTableData: { ...action.payload },
        errors: { ...state.errors, competenciesListError: null },
        loading: { ...state.loading, getCompetenciesList: false },
      };

    case ActionTypes.GET_COMPETENCIES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, competenciesListError: action.payload.message },
        loading: { ...state.loading, getCompetenciesList: false },
      };

    case ActionTypes.CLEAN_COMPETENCE_DATA: {
      return {
        ...state,
        competenceData: null,
      };
    }

    case ActionTypes.GET_COMPETENCE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCompetence: true },
      };

    case ActionTypes.GET_COMPETENCE_SUCCESS:
      const competenceData: CompetenceType = { ...action.payload };
      competenceData.competenceLevels
        .sort((a, b) => a.priority - b.priority)
        .forEach(level => level.skills.sort((a, b) => a.position - b.position));
      return {
        ...state,
        competenceData: competenceData,
        errors: { ...state.errors, competenceDataError: null },
        loading: { ...state.loading, getCompetence: false },
      };

    case ActionTypes.GET_COMPETENCE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, competenceDataError: action.payload.message },
        loading: { ...state.loading, getCompetence: false },
      };

    case ActionTypes.POST_COMPETENCE_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, createCompetenceError: null },
        loading: { ...state.loading, createCompetence: true },
      };

    case ActionTypes.POST_COMPETENCE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, createCompetenceError: null },
        loading: { ...state.loading, createCompetence: false },
      };

    case ActionTypes.POST_COMPETENCE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          createCompetenceError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createCompetence: false },
      };

    case ActionTypes.DELETE_COMPETENCE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCompetence: true },
      };

    case ActionTypes.DELETE_COMPETENCE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, deleteCompetenceError: null },
        loading: { ...state.loading, deleteCompetence: false },
      };

    case ActionTypes.DELETE_COMPETENCE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, deleteCompetenceError: action.payload.message },
        loading: { ...state.loading, deleteCompetence: false },
      };

    case ActionTypes.PUT_COMPETENCE_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, editCompetenceError: null },
        loading: { ...state.loading, editCompetence: true },
      };

    case ActionTypes.PUT_COMPETENCE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, editCompetenceError: null },
        loading: { ...state.loading, editCompetence: false },
      };

    case ActionTypes.PUT_COMPETENCE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          editCompetenceError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editCompetence: false },
      };

    case ActionTypes.SET_COMPETENCIES_PARAMS:
      return {
        ...state,
        competenciesParams: new paramsTypes.CompetenciesParams({ ...state.competenciesParams, ...action.payload }),
        loading: { ...state.loading, getCompetenciesList: true },
      };

    case ActionTypes.GET_COMPETENCIES_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCompetenciesTypes: true },
      };

    case ActionTypes.GET_COMPETENCIES_TYPES_LIST_SUCCESS:
      return {
        ...state,
        competenciesTypesTableData: {
          ...action.payload,
          content: action.payload.content,
        },
        errors: { ...state.errors, competenciesTypesError: null },
        loading: { ...state.loading, getCompetenciesTypes: false },
      };

    case ActionTypes.GET_COMPETENCIES_TYPES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, competenciesTypesError: action.payload.message },
        loading: { ...state.loading, getCompetenciesTypes: false },
      };

    case ActionTypes.POST_COMPETENCIES_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createCompetenciesType: true },
      };

    case ActionTypes.POST_COMPETENCIES_TYPE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, competenciesTypesError: null },
        loading: { ...state.loading, createCompetenciesType: false },
      };

    case ActionTypes.POST_COMPETENCIES_TYPE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          competenciesTypesError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createCompetenciesType: false },
      };

    case ActionTypes.PUT_COMPETENCIES_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editCompetenciesType: true },
      };

    case ActionTypes.PUT_COMPETENCIES_TYPE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, competenciesTypesError: null },
        loading: { ...state.loading, editCompetenciesType: false },
      };

    case ActionTypes.PUT_COMPETENCIES_TYPE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          competenciesTypesError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editCompetenciesType: false },
      };

    case ActionTypes.DELETE_COMPETENCIES_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCompetenciesType: true },
      };

    case ActionTypes.DELETE_COMPETENCIES_TYPE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, competenciesTypesError: null },
        loading: { ...state.loading, deleteCompetenciesType: false },
      };

    case ActionTypes.DELETE_COMPETENCIES_TYPE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, competenciesTypesError: action.payload.message },
        loading: { ...state.loading, deleteCompetenciesType: false },
      };

    case ActionTypes.SET_COMPETENCIES_TYPES_PARAMS:
      return {
        ...state,
        competenciesTypesParams: new paramsTypes.CompetenciesTypesParams({
          ...state.competenciesTypesParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getCompetenciesTypes: true },
      };

    case ActionTypes.GET_FILTERED_ASSESSMENTS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getAssessments: true },
      };

    case ActionTypes.GET_FILTERED_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        assessmentsTableData: {
          ...action.payload,
          content: action.payload.content.map((assessment: unknown) => new AssessmentsInfo(assessment)),
        },
        errors: { ...state.errors, assessmentsDataError: null },
        loading: { ...state.loading, getAssessments: false },
      };

    case ActionTypes.GET_FILTERED_ASSESSMENTS_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, assessmentsDataError: action.payload.message },
        loading: { ...state.loading, getAssessments: false },
      };

    case ActionTypes.GET_ASSESSMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getOneAssessment: true },
      };

    case ActionTypes.GET_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessmentData: new AssessmentsInfo(action.payload),
        errors: { ...state.errors, oneAssessmentDataError: null },
        loading: { ...state.loading, getOneAssessment: false },
      };

    case ActionTypes.GET_ASSESSMENT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, oneAssessmentDataError: action.payload.message },
        loading: { ...state.loading, getOneAssessment: false },
      };

    case ActionTypes.GET_LAST_ASSESSMENT_RESULTS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getOneAssessment: true },
      };

    case ActionTypes.GET_LAST_ASSESSMENT_RESULTS_SUCCESS:
      return {
        ...state,
        lastAssessmentData: action.payload,
        errors: { ...state.errors, oneAssessmentDataError: null },
        loading: { ...state.loading, getOneAssessment: false },
      };

    case ActionTypes.GET_LAST_ASSESSMENT_RESULTS_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, oneAssessmentDataError: action.payload.message },
        loading: { ...state.loading, getOneAssessment: false },
      };

    case ActionTypes.DELETE_ASSESSMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteAssessment: true },
      };

    case ActionTypes.DELETE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, assessmentsDataError: null },
        loading: { ...state.loading, deleteAssessment: false },
      };

    case ActionTypes.DELETE_ASSESSMENT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, assessmentsDataError: action.payload.message },
        loading: { ...state.loading, deleteAssessment: false },
      };

    case ActionTypes.POST_NEW_ASSESSMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createNewAssessment: true },
      };

    case ActionTypes.POST_NEW_ASSESSMENT_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, createAssessmentError: null },
        loading: { ...state.loading, createNewAssessment: false },
      };

    case ActionTypes.POST_NEW_ASSESSMENT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, createAssessmentError: getReducerErrors(action.payload) },
        loading: { ...state.loading, createNewAssessment: false },
      };

    case ActionTypes.PUT_ASSESSMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editAssessment: true },
      };

    // TODO errors handling
    case ActionTypes.PUT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editAssessment: false },
        errors: { ...state.errors, editAssessmentError: null },
      };
    case ActionTypes.PUT_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editAssessment: false },
        errors: { ...state.errors, editAssessmentError: action.payload.message },
      };

    case ActionTypes.CLEAN_ASSESSMENT_DATA:
      return {
        ...state,
        assessmentsTableData: null,
      };

    case ActionTypes.CLEAN_ONE_ASSESSMENT_DATA:
      return {
        ...state,
        assessmentData: new AssessmentsInfo(),
      };

    case ActionTypes.SET_ASSESSMENTS_PARAMS_REQUEST: {
      return {
        ...state,
        assessmentsParams: new paramsTypes.AssessmentsParams({ ...state.assessmentsParams, ...action.payload }),
        loading: {
          ...state.loading,
          getAssessments: true,
        },
      };
    }

    case ActionTypes.RESET_ASSESSMENTS:
      return {
        ...state,
        assessmentsParams: initialState.assessmentsParams,
        assessmentsTableData: initialState.assessmentsTableData,
        errors: {
          ...state.errors,
          assessmentsDataError: null,
        },
      };

    case ActionTypes.GET_SKILLS_QUESTIONS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSkillQuestionsList: true },
      };

    case ActionTypes.GET_SKILLS_QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        skillsQuestionsTableData: {
          ...action.payload,
          content: action.payload.content,
        },
        errors: {
          ...state.errors,
          skillsQuestionsTableDataError: null,
        },
        loading: {
          ...state.loading,
          getSkillQuestionsList: false,
        },
      };

    case ActionTypes.GET_SKILLS_QUESTIONS_LIST_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          skillsQuestionsTableDataError: action.payload.message,
        },
        loading: {
          ...state.loading,
          getSkillQuestionsList: false,
        },
      };

    case ActionTypes.CLEAN_SKILL_QUESTIONS_DATA:
      return {
        ...state,
        skillQuestionsData: null,
        skillsQuestionsTableDataError: null,
        skillQuestionsDataError: null,
      };

    case ActionTypes.GET_SKILL_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSkillQuestions: true },
      };

    case ActionTypes.GET_SKILL_QUESTIONS_SUCCESS:
      const skillQuestionsData: SkillQuestionsListItemType = { ...action.payload };
      return {
        ...state,
        skillQuestionsData: skillQuestionsData,
        errors: { ...state.errors, skillQuestionsDataError: null },
        loading: { ...state.loading, getSkillQuestions: false },
      };

    case ActionTypes.GET_SKILL_QUESTIONS_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, skillQuestionsDataError: action.payload.message },
        loading: { ...state.loading, getSkillQuestions: false },
      };

    case ActionTypes.POST_SKILL_QUESTIONS_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, skillQuestionsDataError: null },
        loading: { ...state.loading, createSkillQuestions: true },
      };

    case ActionTypes.POST_SKILL_QUESTIONS_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, skillQuestionsDataError: null },
        loading: { ...state.loading, createSkillQuestions: false },
      };

    case ActionTypes.POST_SKILL_QUESTIONS_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          skillQuestionsDataError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createSkillQuestions: false },
      };

    case ActionTypes.PUT_SKILL_QUESTIONS_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, skillQuestionsDataError: null },
        loading: { ...state.loading, editSkillQuestions: true },
      };

    case ActionTypes.PUT_SKILL_QUESTIONS_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, skillQuestionsDataError: null },
        loading: { ...state.loading, editSkillQuestions: false },
      };

    case ActionTypes.PUT_SKILL_QUESTIONS_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          skillQuestionsDataError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editSkillQuestions: false },
      };

    case ActionTypes.SET_SKILLS_QUESTIONS_PARAMS_REQUEST:
      return {
        ...state,
        skillQuestionsParams: new paramsTypes.SkillsQuestionsParams({
          ...state.skillQuestionsParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getSkillQuestionsList: true },
      };

    case ActionTypes.RESET_COMPETENCIES_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          competenciesListError: null,
          createCompetenceError: null,
          editCompetenceError: null,
          deleteCompetenceError: null,
          competenciesTypesError: null,
          competenceDataError: null,
          assessmentsDataError: null,
          oneAssessmentDataError: null,
          skillQuestionsDataError: null,
          editAssessmentError: null,
          createAssessmentError: null,
        },
      };

    case ActionTypes.RESET_LAST_ASSESSMENT_RESULTS:
      return {
        ...state,
        lastAssessmentData: null,
      };

    case ActionTypes.GET_COMPETENCIES_PERCENTAGE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, competenciesPercentage: true },
      };

    case ActionTypes.GET_COMPETENCIES_PERCENTAGE_SUCCESS:
      return {
        ...state,
        competenciesPercentage: action.payload,
        errors: { ...state.errors, competenciesPercentageError: null },
        loading: { ...state.loading, competenciesPercentage: false },
      };

    case ActionTypes.GET_COMPETENCIES_PERCENTAGE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, competenciesPercentageError: action.payload.message },
        loading: { ...state.loading, competenciesPercentage: false },
      };

    case ActionTypes.GET_SPECIALIZATIONS_PERCENTAGE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, specializationsPercentage: true },
      };

    case ActionTypes.GET_SPECIALIZATIONS_PERCENTAGE_SUCCESS:
      return {
        ...state,
        specializationsPercentage: action.payload,
        errors: { ...state.errors, specializationsPercentageError: null },
        loading: { ...state.loading, specializationsPercentage: false },
      };

    case ActionTypes.GET_SPECIALIZATIONS_PERCENTAGE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, specializationsPercentageError: action.payload.message },
        loading: { ...state.loading, specializationsPercentage: false },
      };

    case ActionTypes.GET_USER_COMPETENCE_ASSESSMENTS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, userCompetenceAssessments: true },
      };

    case ActionTypes.GET_USER_COMPETENCE_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        userCompetenceAssessments: action.payload,
        errors: { ...state.errors, userCompetenceAssessmentsErrors: null },
        loading: { ...state.loading, userCompetenceAssessments: false },
      };

    case ActionTypes.GET_USER_COMPETENCE_ASSESSMENTS_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, userCompetenceAssessmentsErrors: action.payload.message },
        loading: { ...state.loading, userCompetenceAssessments: false },
      };

    case ActionTypes.GET_COMPETENCE_LEVELS_PERCENTAGE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, competenceLevelsPercentage: true },
      };

    case ActionTypes.GET_COMPETENCE_LEVELS_PERCENTAGE_SUCCESS:
      return {
        ...state,
        competenceLevelsPercentage: action.payload,
        errors: { ...state.errors, competenceLevelsPercentage: null },
        loading: { ...state.loading, competenceLevelsPercentage: false },
      };

    case ActionTypes.GET_COMPETENCE_LEVELS_PERCENTAGE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, competenceLevelsPercentage: action.payload.message },
        loading: { ...state.loading, competenceLevelsPercentage: false },
      };

    case ActionTypes.RESET_USER_COMPETENCE_ASSESSMENTS:
      return {
        ...state,
        userCompetenceAssessments: [],
      };

    case ActionTypes.RESET_COMPETENCIES_STATE:
      return {
        ...initialState,
        competenciesTypesParams: state.competenciesTypesParams,
        skillQuestionsParams: state.skillQuestionsParams,
        competenciesParams: state.competenciesParams,
        assessmentsParams: state.assessmentsParams,
      };

    default:
      return state;
  }
};

export default competenciesReducer;
