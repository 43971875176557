import { BonusesParams } from './../enums/params/bonuses.params';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/bonuses.constants';
import * as api from '../api/bonuses.api';
import { AnyAction } from 'redux';
import { PUT_USER_INFO_SUCCESS } from '../constants/users.constants';

function* getBonusesList() {
  const params: BonusesParams = yield select((state: RootState) => state.bonuses.params);
  try {
    const { success, results } = yield call(api.getBonusesList, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_BONUSES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_BONUSES_LIST_FAILURE, payload: error });
  }
}

function* createNewBonus({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewBonus, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.POST_BONUS_SUCCESS,
      });
      payload.callback();
      yield call(getBonusesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_BONUS_FAILURE, payload: error });
  }
}

function* editBonus({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editBonus, payload.id, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_BONUS_SUCCESS,
      });
      payload.callback();
      yield call(getBonusesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_BONUS_FAILURE, payload: error });
  }
}

function* deleteBonus({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteBonus, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_BONUS_SUCCESS,
      });
      payload.callback();
      yield call(getBonusesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_BONUS_FAILURE, payload: error });
  }
}

function* getBonusesSettings() {
  try {
    const { success, results } = yield call(api.getBonusesSettings);

    if (success) {
      yield put({
        type: ActionTypes.GET_BONUSES_SETTINGS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_BONUSES_SETTINGS_FAILURE, payload: error });
  }
}

function* editBonusesSettings({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editBonusesSettings, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.EDIT_BONUSES_SETTINGS_SUCCESS,
      });
      payload.callback();
      yield call(getBonusesSettings);
    }
  } catch (error) {
    yield put({ type: ActionTypes.EDIT_BONUSES_SETTINGS_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([takeEvery([ActionTypes.GET_BONUSES_LIST_REQUEST, PUT_USER_INFO_SUCCESS], getBonusesList)]);
  yield all([
    takeEvery(ActionTypes.SET_BONUSES_PARAMS_REQUEST, function* () {
      yield put({
        type: ActionTypes.GET_BONUSES_LIST_REQUEST,
      });
    }),
  ]);
  yield all([takeEvery(ActionTypes.POST_BONUS_REQUEST, createNewBonus)]);
  yield all([takeEvery(ActionTypes.PUT_BONUS_REQUEST, editBonus)]);
  yield all([takeEvery(ActionTypes.DELETE_BONUS_REQUEST, deleteBonus)]);
  yield all([takeEvery(ActionTypes.GET_BONUSES_SETTINGS_REQUEST, getBonusesSettings)]);
  yield all([takeEvery(ActionTypes.EDIT_BONUSES_SETTINGS_REQUEST, editBonusesSettings)]);
}
