import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as questionnairesActions from '../../actions/questionnaires.actions';
import * as filtersActions from '../../actions/filters.actions';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Table from '../../components/Table';
import InlineDatePicker from '../../components/InlineDatePicker';
import Pagination from '../../components/Pagination';
import ModalCreateNewPoll from '../../components/Polls/Modals/ModalCreateNewPoll';
import ModalDeletePoll from '../../components/Polls/Modals/ModalDeletePoll';
import { closedValue, completedStatusValue, inProgressStatusValue, PollStatusOptions, publishedValue } from './utils';
import { useHistory } from 'react-router';
import ModalEditPoll from '../../components/Polls/Modals/ModalEditPoll';
import { NewPoll } from '../../enums/questionnaires.enum';
import { PollParams } from '../../enums/params/questionnaires.params';
import PollsFilters from '../../components/Polls/Filters/PollsFilters';
import { useDataForTable } from './useDataForTable';
import AccessChecker from '../../components/AccessChecker';
import { UPDATE_POLL_EXTENDED } from '../../constants/policies.constants';
import r from '../../constants/routes.constants';
import { useParamsChange, useUsersParamsChange } from '../../utils/hooks.utils';
import { EventsParams } from '../../enums/params/schedule.params';
import { PollTemplateCategoryInfoType } from '../../types/questionnaires';
import { OfficeInfo } from '../../enums/libraries.enum';
import ModalExportPollAnswers from '../../components/Polls/Modals/ModalExportPollAnswers';
import DurationPicker from '../../components/Dropdown';
import Icon from '../../components/Icon';
import { exportPollData } from '../../actions/export.actions';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterTypes } from '../../constants/filters.constants';
import { pollsUnsavedParams } from '../../constants/questionnaires.constants';

const Polls = ({
  tableData,
  params,
  userList,
  pollTemplatesCategoryList,
  pollTemplateList,
  officeList,
  eventList,
  isLoading,
  isLoadingDelete,
  isLoadingEditPoll,
  isLoadingCreatePoll,
  createError,
  editError,
  pollsError,
  editPoll,
  getEventsFilter,
  deletePoll,
  createNewPoll,
  setPollParams,
  resetState,
  getPollsList,
  getPollTemplatesFilter,
  getPollTemplatesCategoryFilter,
  getUsersFilter,
  getOfficesFilter,
  onExport,
  resetEventsFilter,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) => {
  const history = useHistory();
  const [isOpenModalCreatePoll, setIsOpenModalCreatePoll] = useState(false);
  const [isOpenModalDeletePoll, setIsOpenModalDeletePoll] = useState(false);
  const [isOpenModalEditPoll, setIsOpenModalEditPoll] = useState(false);
  const [clickedPoll, setClickedPoll] = useState<NewPoll>(new NewPoll({}));
  const [currentCategory, setCurrentCategory] = useState({});
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);

  const handleOpenModalEditPoll = useCallback(() => setIsOpenModalEditPoll(true), []);
  const handleOpenModalDeletePoll = useCallback(() => setIsOpenModalDeletePoll(true), []);

  const handleCloseExportModal = useCallback(() => setIsOpenExportModal(false), []);
  const handleOpenExportModal = useCallback(() => setIsOpenExportModal(true), []);

  const pollCallback = useCallback(() => {
    getPollsList();
    getPollTemplatesFilter();
    getPollTemplatesCategoryFilter();
    history.push(r.polls);
  }, []);

  const handleEditPoll = useCallback((data: any, callback?: any) => {
    const fullCallback = () => {
      callback && callback();
      pollCallback();
    };
    editPoll({ data, callback: fullCallback });
  }, []);

  const { tableColumns, tableActions } = useDataForTable(
    handleOpenModalDeletePoll,
    setClickedPoll,
    handleOpenModalEditPoll,
    createNewPoll,
    pollCallback,
    handleOpenExportModal,
    handleEditPoll,
  );

  const handleSort = useCallback((sortBy, direction) => setPollParams({ sortBy, direction }), []);

  const onDateChange = useCallback((start: string, end: string) => {
    setPollParams({ deadlineFrom: start, deadlineTo: end, page: 0 });
  }, []);

  const handleMultiParamsChange = useParamsChange(setPollParams);

  const handleUsersParamsChange = useUsersParamsChange(setPollParams);

  const handlePageChange = useCallback(({ selected }) => {
    setPollParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setPollParams({ size: data, page: 0 });
  }, []);

  const handleCreateNewPoll = useCallback((data: any, callback: any) => {
    const fullCallback = () => {
      callback();
      pollCallback();
    };
    createNewPoll({ data, isNewPoll: true, callback: fullCallback });
  }, []);

  const handleCloseModalCreatePoll = useCallback(() => setIsOpenModalCreatePoll(false), []);

  const handleCloseModalDeletePoll = useCallback(() => setIsOpenModalDeletePoll(false), []);
  const handleCloseModalEditPoll = useCallback(() => setIsOpenModalEditPoll(false), []);

  useEffect(() => {
    getPollTemplatesFilter();
    getPollTemplatesCategoryFilter();
    getUsersFilter();
    getOfficesFilter();
    getEventsFilter({ questionnairesEnabled: true, dateTimeFrom: '', dateTimeTo: '' });

    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.POLLS_FILTER);

    setPollParams(currentSavedFilter ? new PollParams(currentSavedFilter) : {});

    setSavedFiltersParams({ filterType: FilterTypes.POLLS_FILTER });

    return () => {
      resetState();
      resetEventsFilter();
    };
  }, []);

  const categoryTable = useMemo(
    () =>
      pollTemplatesCategoryList?.map((item: PollTemplateCategoryInfoType) => ({
        label: item.name,
        handler: () => {
          setCurrentCategory(item);
          setIsOpenModalCreatePoll(true);
        },
      })),
    [pollTemplatesCategoryList],
  );

  const officesList = useMemo(() => officeList?.map((office: OfficeInfo) => ({ id: office.id, name: office.name })), [
    officeList,
  ]);

  const filters = useMemo(
    () => ({
      offices: officeList,
      users: userList,
      categories: pollTemplatesCategoryList,
    }),
    [officeList, userList, pollTemplatesCategoryList],
  );

  const isPollCanNotBeEdit = useMemo(() => {
    const hasAnswers = clickedPoll?.responders.some(responder => {
      return responder.pollStatus === completedStatusValue || responder.pollStatus === inProgressStatusValue;
    });
    return clickedPoll?.status === closedValue || (clickedPoll?.status === publishedValue && hasAnswers);
  }, [clickedPoll?.status, clickedPoll?.responders]);

  const handleFiltersControlChange = useCallback(
    value => {
      setPollParams(
        new PollParams({
          ...convertSavedFieldsToParams(value.fields),
          deadlineFrom: params.deadlineFrom,
          deadlineTo: params.deadlineTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setPollParams(new PollParams({ deadlineFrom: params.deadlineFrom, deadlineTo: params.deadlineTo }));
  }, [params]);

  const showClearButton = useMemo(() => !checkParamsMatch(params, new PollParams(), pollsUnsavedParams), [params]);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <div className="page__panel-top__wrapper--left">
              <h1 className="page__title">
                <FormattedMessage {...messages.pageTitle} />
              </h1>
            </div>
            <InlineDatePicker
              onDateChange={onDateChange}
              defaultPeriodStart={params.deadlineFrom}
              defaultPeriodEnd={params.deadlineTo}
            />
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_POLL_EXTENDED]}>
                  <DurationPicker
                    dropdownToggle={
                      <>
                        <Icon iconName={'plus'} externalClass={'button__icon'} />
                        <span className="button__text">
                          <FormattedMessage {...messages.newButton} />
                        </span>
                      </>
                    }
                    dropdownList={categoryTable}
                    customBtn
                    placement="bottom-start"
                    dropdownClass="template_select"
                  />
                </AccessChecker>
                <PollsFilters
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleUsersParamsChange={handleUsersParamsChange}
                  filters={filters}
                  listStatus={PollStatusOptions || []}
                  values={params}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  handleSort={handleSort}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table--offices table--polls'}
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            tableActions={tableActions}
            dropdownClass="poll-table-dropdown"
            error={pollsError}
            loading={isLoading}
          />
          <Pagination
            pageable={{
              ...tableData?.pageable,
              ...tableData?.sort,
              totalElements: tableData?.totalElements,
              numberOfElements: tableData?.numberOfElements,
              totalPages: tableData?.totalPages,
            }}
            onPageChange={(data: any) => handlePageChange(data)}
            onPageSizeChange={(data: any) => handleSizeChange(data)}
          />
        </div>
        {isOpenModalCreatePoll && (
          <ModalCreateNewPoll
            isOpen={isOpenModalCreatePoll}
            categories={pollTemplatesCategoryList}
            onCloseRequest={handleCloseModalCreatePoll}
            responders={userList}
            templates={pollTemplateList}
            error={createError}
            isLoading={isLoadingCreatePoll}
            onSubmit={handleCreateNewPoll}
            officesList={officesList}
            eventList={eventList}
            currentCategory={currentCategory}
          />
        )}
        {isOpenModalEditPoll && (
          <ModalEditPoll
            isOpen={isOpenModalEditPoll}
            categories={pollTemplatesCategoryList}
            onCloseRequest={handleCloseModalEditPoll}
            responders={userList}
            templates={pollTemplateList}
            error={editError}
            isLoading={isLoadingEditPoll}
            onSubmit={handleEditPoll}
            officesList={officesList}
            eventList={eventList}
            poll={clickedPoll}
            isPollCanNotBeEdit={isPollCanNotBeEdit}
          />
        )}
        {isOpenModalDeletePoll && (
          <ModalDeletePoll
            isOpen={isOpenModalDeletePoll}
            onCloseRequest={handleCloseModalDeletePoll}
            onDeleteRequest={deletePoll}
            templateData={clickedPoll}
            isLoading={isLoadingDelete}
          />
        )}
        {isOpenExportModal && (
          <ModalExportPollAnswers
            onCloseRequest={handleCloseExportModal}
            isOpen
            subTitle={clickedPoll?.name}
            onExport={onExport}
            pollId={clickedPoll?.id}
            userIds={userList?.map(({ user }: any) => user?.id)}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ questionnaires, filters, auth }: RootState) => ({
  tableData: questionnaires.pollsTableData,
  params: questionnaires.pollParams,
  pollsError: questionnaires.errors.pollsError,
  createError: questionnaires.errors.createError,
  editError: questionnaires.errors.editError,
  isLoading: questionnaires.loading.getPollsTableData,
  isLoadingDelete: questionnaires.loading.deletePoll,
  isLoadingEditPoll: questionnaires.loading.editPoll,
  isLoadingCreatePoll: questionnaires.loading.createPoll,
  userList: filters.usersFilter.users,
  pollTemplatesCategoryList: filters.pollTemplatesCategoryFilter.categories,
  pollTemplateList: filters.pollTemplatesFilter.templates,
  officeList: filters.officesFilter.offices,
  eventList: filters.eventsFilter.events,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetState: () => dispatch(questionnairesActions.resetState()),
  resetEventsFilter: () => dispatch(filtersActions.resetEventsFilter()),
  setPollParams: (data: Partial<PollParams>) => dispatch(questionnairesActions.setPollParams(data)),
  getPollsList: () => dispatch(questionnairesActions.getPollsList()),
  getPollTemplatesFilter: () => dispatch(filtersActions.getPollTemplatesFilter()),
  getPollTemplatesCategoryFilter: () => dispatch(filtersActions.getPollTemplatesCategoryFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getEventsFilter: (data: Partial<EventsParams>) => dispatch(filtersActions.getEventsFilter(data)),
  createNewPoll: (data: any) => dispatch(questionnairesActions.createNewPoll(data)),
  deletePoll: (data: any) => dispatch(questionnairesActions.deletePoll(data)),
  editPoll: (data: any) => dispatch(questionnairesActions.editPoll(data)),
  onExport: (data: any) => dispatch(exportPollData(data)),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Polls);
