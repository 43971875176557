import * as paramsTypes from './../enums/params/planning.params';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/planning.constants';

export const getResourcesList = (): AnyAction => ({
  type: ActionTypes.GET_RESOURCES_LIST_REQUEST,
});

export const createNewResources = (data: any): AnyAction => ({
  type: ActionTypes.POST_RESOURCES_REQUEST,
  payload: data,
});

export const setResourcesParams = (data: Partial<paramsTypes.ResourcesParams>): AnyAction => ({
  type: ActionTypes.SET_RESOURCES_PARAMS_REQUEST,
  payload: data,
});

export const setUserTabResourcesParams = (data: Partial<paramsTypes.ResourcesParams>): AnyAction => ({
  type: ActionTypes.SET_USER_TAB_RESOURCES_PARAMS_REQUEST,
  payload: data,
});

export const getCurrentResource = (data: any): AnyAction => ({
  type: ActionTypes.GET_CURRENT_RESOURCE_REQUEST,
  payload: data,
});

export const setCurrentResource = (data: any): AnyAction => ({
  type: ActionTypes.SET_CURRENT_RESOURCE,
  payload: data,
});

export const getUserHoursList = (): AnyAction => ({
  type: ActionTypes.GET_USER_HOURS_LIST_REQUEST,
});

export const setUserHoursParams = (data: Partial<paramsTypes.UserHoursParams>): AnyAction => ({
  type: ActionTypes.SET_USER_HOURS_PARAMS_REQUEST,
  payload: data,
});

export const getProjectHoursList = (): AnyAction => ({
  type: ActionTypes.GET_PROJECT_HOURS_LIST_REQUEST,
});

export const setProjectHoursParams = (data: Partial<paramsTypes.ProjectHoursParams>): AnyAction => ({
  type: ActionTypes.SET_PROJECT_HOURS_PARAMS_REQUEST,
  payload: data,
});

export const getProjectGroups = (): AnyAction => ({
  type: ActionTypes.GET_PROJECT_GROUPS_LIST_REQUEST,
});

export const createProjectGroup = (data: any): AnyAction => ({
  type: ActionTypes.POST_PROJECT_GROUP_REQUEST,
  payload: data,
});

export const deleteProjectGroup = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_PROJECT_GROUP_REQUEST,
  payload: data,
});

export const editProjectGroup = (data: any): AnyAction => ({
  type: ActionTypes.PUT_PROJECT_GROUP_REQUEST,
  payload: data,
});

export const setProjectGroupsParams = (data: Partial<paramsTypes.ProjectGroupsParams>): AnyAction => ({
  type: ActionTypes.SET_PROJECT_GROUPS_PARAMS_REQUEST,
  payload: data,
});

export const getActiveEmployees = (): AnyAction => ({
  type: ActionTypes.GET_ACTIVE_EMPLOYEES_LIST_REQUEST,
});

export const editActiveEmployees = (data: any): AnyAction => ({
  type: ActionTypes.PUT_ACTIVE_EMPLOYEES_LIST_REQUEST,
  payload: data,
});

export const setActiveEmployeesParams = (data: Partial<paramsTypes.ActiveEmployeesParams>): AnyAction => ({
  type: ActionTypes.SET_ACTIVE_EMPLOYEES_PARAMS_REQUEST,
  payload: data,
});

export const resetPlaningError = (): AnyAction => ({
  type: ActionTypes.RESET_PLANNING_ERRORS,
});

export const resetPlaningState = (): AnyAction => ({
  type: ActionTypes.RESET_PLANNING_STATE,
});
