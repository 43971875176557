import { find } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ResultType } from '../../../enums/competencies.enum';
import { CompetenceLevelType } from '../../../pages/Competencies/Competencies';
import { getTableCell } from '../../../utils/table.utils';
import { TableColumnType } from '../../HierarchicalTable';
import NumberInput from '../../NumberInput';
import messages from '../messages';

export type ScoreError = {
  index: number;
  message: string;
};

export const useDataForTable = (
  results: ResultType[],
  lastAssessmentResults: ResultType[],
  resultsErrors: ScoreError[],
  setFieldValue: (fieldName: string, value: any) => void,
  setResultsErrors: React.Dispatch<React.SetStateAction<ScoreError[]>>,
) => {
  const intl = useIntl();

  const classes = ['table__data--trainee', 'table__data--junior', 'table__data--middle', 'table__data--senior'];

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 0 && row.id < 4) {
      return classes[row.id];
    }
    return '';
  }, []);

  const sumScores = useCallback(
    levelId => {
      const scores = results.filter(r => r.levelId === levelId && r.score && r.score > 0);
      return scores.reduce((sum, current) => sum + current.score, 0);
    },
    [results],
  );

  const getError = useCallback(
    skillId => {
      return find(resultsErrors, item => item.index === skillId);
    },
    [resultsErrors],
  );

  const resultsIndex = useCallback(
    skillId => {
      const index = results ? results.findIndex(r => r.skillId === skillId) : -1;
      return index;
    },
    [results],
  );

  const incrementScore = useCallback(
    (skillId, maxScore) => {
      const index = resultsIndex(skillId);

      const lastResultIndex = lastAssessmentResults?.findIndex(item => item.skillId === skillId);

      if (index !== -1) {
        setFieldValue(
          `results[${index}].score`,
          results[index].score >= maxScore ? maxScore : results[index].score < 0 ? 0 : results[index].score + 1,
        );

        if (lastAssessmentResults?.length && lastAssessmentResults[lastResultIndex]?.score > results[index].score + 1) {
          getError(index)
            ? (resultsErrors[resultsErrors.findIndex(item => item.index === index)] = {
                index: index,
                message: intl.formatMessage(messages.scorsWarningTitle),
              })
            : setResultsErrors((errors: ScoreError[]) => [
                ...errors,
                {
                  index: index,
                  message: intl.formatMessage(messages.scorsWarningTitle),
                },
              ]);
        } else {
          getError(index) && setResultsErrors(resultsErrors.filter(error => error.index !== index));
        }
      }
    },
    [results, lastAssessmentResults],
  );

  const decrementScore = useCallback(
    (skillId, maxScore) => {
      const index = resultsIndex(skillId);
      const lastResultIndex = lastAssessmentResults?.findIndex(item => item.skillId === skillId);

      if (index !== -1) {
        setFieldValue(
          `results[${index}].score`,
          results[index].score > maxScore ? maxScore : results[index].score <= 0 ? 0 : results[index].score - 1,
        );

        if (lastAssessmentResults?.length && lastAssessmentResults[lastResultIndex]?.score >= results[index].score) {
          getError(index)
            ? (resultsErrors[resultsErrors.findIndex(item => item.index === index)] = {
                index: index,
                message: intl.formatMessage(messages.scorsWarningTitle),
              })
            : setResultsErrors((errors: ScoreError[]) => [
                ...errors,
                {
                  index: index,
                  message: intl.formatMessage(messages.scorsWarningTitle),
                },
              ]);
        } else {
          getError(index) && setResultsErrors(resultsErrors.filter(error => error.index !== index));
        }
      }
    },
    [results, lastAssessmentResults],
  );

  const countPercentage = useCallback(
    (firstNum: number, secondNum: any) => {
      const nominator = Number.isNaN(firstNum) ? 0 : firstNum;
      const denominator = Number.isNaN(secondNum) || '' ? 0 : secondNum;
      return denominator === 0 ? 0 : Math.floor((nominator / denominator) * 100);
    },
    [results],
  );

  const setScore = useCallback(
    (value, skillId, maxScore) => {
      const index = resultsIndex(skillId);
      const lastResultIndex = lastAssessmentResults?.findIndex(item => item.skillId === skillId);

      if (index !== -1) {
        if (+value > maxScore) {
          getError(index)
            ? (resultsErrors[resultsErrors.findIndex(item => item.index === index)] = {
                index: index,
                message: `${intl.formatMessage(messages.maxTitle)} ${maxScore}`,
              })
            : setResultsErrors((errors: ScoreError[]) => [
                ...errors,
                { index: index, message: `${intl.formatMessage(messages.maxTitle)} ${maxScore}` },
              ]);
        }

        if (+value < 0) {
          getError(index)
            ? (resultsErrors[resultsErrors.findIndex(item => item.index === index)] = {
                index: index,
                message: `${intl.formatMessage(messages.minTitle)} 0`,
              })
            : setResultsErrors((errors: ScoreError[]) => [
                ...errors,
                { index: index, message: `${intl.formatMessage(messages.minTitle)} 0` },
              ]);
        }

        if (0 <= +value && +value <= maxScore) {
          if (lastAssessmentResults?.length && lastAssessmentResults[lastResultIndex]?.score > +value) {
            getError(index)
              ? (resultsErrors[resultsErrors.findIndex(item => item.index === index)] = {
                  index: index,
                  message: intl.formatMessage(messages.scorsWarningTitle),
                })
              : setResultsErrors((errors: ScoreError[]) => [
                  ...errors,
                  {
                    index: index,
                    message: intl.formatMessage(messages.scorsWarningTitle),
                  },
                ]);
          } else {
            getError(index) && setResultsErrors(resultsErrors.filter(error => error.index !== index));
          }
        }
        setFieldValue(`results[${index}].score`, +value);
      }
    },
    [results, lastAssessmentResults],
  );

  const tableColumns: TableColumnType[] = useMemo(
    () => [
      {
        Header: intl.formatMessage(messages.skillsColumn),
        accessor: 'name',
        headClassName: 'table__head-column--competence-skills',
      },
      {
        id: 'scoreColumn',
        Header: intl.formatMessage(messages.scoreColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: 0,
              content: (row: CompetenceLevelType) => (
                <span>{`${sumScores(row.id)} (${countPercentage(sumScores(row.id), row.maxScore)}%)`}</span>
              ),
            },
          ]),
        headClassName: 'table__head-column--competence-max-scope',
      },
      {
        accessor: 'maxScore',
        Header: intl.formatMessage(messages.maxTitle),
        externalColumnClass: () => 'table__data--new-assessment-max',
      },
    ],
    [results, lastAssessmentResults, resultsErrors],
  );

  return {
    tableColumns,
    numberInput: (row: any, i: number) => {
      const originalRow = row.original;
      const rowId = originalRow.id;
      const rowMaxScore = originalRow.maxScore;

      return row.depth === 1 && i === 1 ? (
        <div className="form__input-block form__input-block--new-assessment">
          <NumberInput
            name={resultsIndex(rowId) !== -1 ? `results[${resultsIndex(rowId)}].score` : ''}
            id={resultsIndex(rowId) !== -1 ? `results[${resultsIndex(rowId)}].score` : ''}
            // hasError={hasError(resultsIndex(row.id) !== -1 ? `results[${resultsIndex(row.id)}]` : '')}
            errorMessage={getError(resultsIndex(rowId))?.message}
            onChange={event => setScore(event.target.value, rowId, rowMaxScore)}
            defaultValue={resultsIndex(rowId) !== -1 ? `${results[resultsIndex(rowId)].score}` : ''}
            externalClass="form__input not-empty form__input--no-label"
            onCountUp={() => incrementScore(rowId, rowMaxScore)}
            onCountDown={() => decrementScore(rowId, rowMaxScore)}
          />
        </div>
      ) : null;
    },
    handleExternalRowClass,
  };
};
