import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { UserInfo } from '../../../enums/users.enum';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import SortSelect from '../../SortSelect';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import FilterClearButton from '../../FilterClearButton';
import { RecognitionTypeInfoType } from '../../../types/recognitions';
import { RecognitionsParams } from '../../../enums/params/recognitions.params';
import { recognitionsUnsavedParams } from '../../../constants/recognitions.constants';
import { SortParams } from '../../../enums/params.enum';

type FiltersType = {
  users: UserInfo[];
  recognitionTypeList: RecognitionTypeInfoType[];
};

type RecognitionsFilterProps = {
  filters: FiltersType;
  values: RecognitionsParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleSort: (sortBy: string, direction: string) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const RecognitionsFilter = ({
  filters,
  values,
  handleMultiParamsChange,
  handleUsersParamsChange,
  handleSort,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: RecognitionsFilterProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const usersOprtions = useMemo(
    () =>
      filters.users.map(user => ({
        value: user,
        label: user.fullName,
      })),
    [filters.users],
  );

  const recognitionTypesOprtions = useMemo(
    () =>
      filters.recognitionTypeList?.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [filters.recognitionTypeList],
  );

  const usersValues = useFiltersListValue(usersOprtions, values.userIds);

  const recognitionTypesValues = useFiltersListValue(recognitionTypesOprtions, values.recognitionTypeIds);

  const authorsValues = useFiltersListValue(usersOprtions, values.authorIds);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <SortSelect
        sortOptions={[
          { label: intl.formatMessage(messages.memberLabel), value: 'targetUser.secondName' },
          { label: intl.formatMessage(messages.recognitionTypeSelect), value: 'recognitionType.name' },
          { label: intl.formatMessage(messages.messagelabel), value: 'text' },
          { label: intl.formatMessage(messages.authorColumn), value: 'author.secondName' },
          { label: intl.formatMessage(messages.createdColumn), value: 'createdDate' },
          { label: intl.formatMessage(messages.editorColumn), value: 'lastEditor.secondName' },
          { label: intl.formatMessage(messages.updatedColumn), value: 'lastModifiedDate' },
        ]}
        params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
        onSort={handleSort}
      />
      <Filter
        options={usersOprtions}
        isMulti
        isUsersFilter
        handleChange={handleUsersParamsChange(FilterParamsName.USER_IDS)}
        value={usersValues}
        label={intl.formatMessage(messages.membersLabel)}
      />
      <Filter
        options={recognitionTypesOprtions}
        isMulti
        handleChange={handleMultiParamsChange('recognitionTypeIds')}
        value={recognitionTypesValues}
        label={intl.formatMessage(messages.recognitionTypesFilter)}
      />
      <Filter
        options={usersOprtions}
        value={authorsValues}
        isMulti
        isUsersFilter
        handleChange={handleUsersParamsChange('authorIds')}
        label={intl.formatMessage(messages.authorsFilter)}
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.RECOGNITIONS_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={recognitionsUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
    </>
  );
};

export default React.memo(RecognitionsFilter);
