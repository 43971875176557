import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/libraries.constants';
import * as paramsTypes from '../enums/params/libraries.params';

export const getOfficesList = (): AnyAction => ({
  type: ActionTypes.GET_OFFICES_LIST_REQUEST,
});

export const createNewOffice = (data: any): AnyAction => ({
  type: ActionTypes.POST_OFFICE_REQUEST,
  payload: data,
});

export const deleteOffice = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_OFFICE_REQUEST,
  payload: data,
});

export const editOffice = (data: any): AnyAction => ({
  type: ActionTypes.PUT_OFFICE_REQUEST,
  payload: data,
});

export const setOfficesParams = (data: Partial<paramsTypes.OfficesParams>): AnyAction => ({
  type: ActionTypes.SET_OFFICES_PARAMS,
  payload: data,
});

export const getOfficesWorkDays = (data: any): AnyAction => ({
  type: ActionTypes.GET_OFFICES_WORK_DAYS_REQUEST,
  payload: data,
});

export const getGradesList = (): AnyAction => ({
  type: ActionTypes.GET_GRADES_LIST_REQUEST,
});

export const createNewGrade = (data: any): AnyAction => ({
  type: ActionTypes.POST_GRADE_REQUEST,
  payload: data,
});

export const deleteGrade = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_GRADE_REQUEST,
  payload: data,
});

export const editGrade = (data: any): AnyAction => ({
  type: ActionTypes.PUT_GRADE_REQUEST,
  payload: data,
});

export const getSpecializationsList = (): AnyAction => ({
  type: ActionTypes.GET_SPECIALIZATIONS_LIST_REQUEST,
});
export const getSpecializationsCompetencies = (): AnyAction => ({
  type: ActionTypes.GET_SPECIALIZATIONS_COMPETENCIES_REQUEST,
});

export const createNewSpecialization = (data: any): AnyAction => ({
  type: ActionTypes.POST_SPECIALIZATION_REQUEST,
  payload: data,
});

export const deleteSpecialization = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_SPECIALIZATION_REQUEST,
  payload: data,
});

export const editSpecialization = (data: any): AnyAction => ({
  type: ActionTypes.PUT_SPECIALIZATION_REQUEST,
  payload: data,
});

export const editSpecializationCompetencies = (data: any): AnyAction => ({
  type: ActionTypes.PUT_SPECIALIZATION_COMPETENCIES_REQUEST,
  payload: data,
});

export const setSpecializationsParams = (data: Partial<paramsTypes.SpecializationsParams>): AnyAction => ({
  type: ActionTypes.SET_SPECIALIZATIONS_PARAMS,
  payload: data,
});

export const getCompanyPositionsList = (): AnyAction => ({
  type: ActionTypes.GET_COMPANY_POSITIONS_LIST_REQUEST,
});

export const createNewCompanyPosition = (data: any): AnyAction => ({
  type: ActionTypes.POST_COMPANY_POSITION_REQUEST,
  payload: data,
});

export const deleteCompanyPosition = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_COMPANY_POSITION_REQUEST,
  payload: data,
});

export const editCompanyPosition = (data: any): AnyAction => ({
  type: ActionTypes.PUT_COMPANY_POSITION_REQUEST,
  payload: data,
});

export const getDepartmentsList = (): AnyAction => ({
  type: ActionTypes.GET_DEPARTMENTS_LIST_REQUEST,
});

export const createNewDepartment = (data: any): AnyAction => ({
  type: ActionTypes.POST_DEPARTMENT_REQUEST,
  payload: data,
});

export const deleteDepartment = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_DEPARTMENT_REQUEST,
  payload: data,
});

export const editDepartment = (data: any): AnyAction => ({
  type: ActionTypes.PUT_DEPARTMENT_REQUEST,
  payload: data,
});

export const setDepartamentsParams = (data: Partial<paramsTypes.DepartmentsParams>): AnyAction => ({
  type: ActionTypes.SET_DEPARTMENTS_PARAMS,
  payload: data,
});

export const getProjectsList = (): AnyAction => ({
  type: ActionTypes.GET_PROJECTS_LIST_REQUEST,
});

export const createNewProject = (data: any): AnyAction => ({
  type: ActionTypes.POST_PROJECT_REQUEST,
  payload: data,
});

export const deleteProject = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_PROJECT_REQUEST,
  payload: data,
});

export const editProject = (data: any): AnyAction => ({
  type: ActionTypes.PUT_PROJECT_REQUEST,
  payload: data,
});

export const setProjectsParams = (data: Partial<paramsTypes.ProjectsParams>): AnyAction => ({
  type: ActionTypes.SET_PROJECTS_PARAMS,
  payload: data,
});

export const getTechnicalSkillsList = (data?: Partial<paramsTypes.TechnicalSkillsParams>): AnyAction => ({
  type: ActionTypes.GET_TECHNICAL_SKILLS_LIST_REQUEST,
  payload: data,
});

export const setTechnicalSkillsParams = (data: Partial<paramsTypes.TechnicalSkillsParams>): AnyAction => ({
  type: ActionTypes.SET_TECHNICAL_SKILLS_PARAMS,
  payload: data,
});

export const getSkillGroup = (data: any): AnyAction => ({
  type: ActionTypes.GET_SKILL_GROUP_REQUEST,
  payload: data,
});

export const createNewTechnicalSkill = (data: any): AnyAction => ({
  type: ActionTypes.POST_TECHNICAL_SKILL_REQUEST,
  payload: data,
});

export const deleteTechnicalSkill = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_TECHNICAL_SKILL_REQUEST,
  payload: data,
});

export const editTechnicalSkill = (data: any): AnyAction => ({
  type: ActionTypes.PUT_TECHNICAL_SKILL_REQUEST,
  payload: data,
});

export const getSkillLevelsList = (): AnyAction => ({
  type: ActionTypes.GET_SKILL_LEVELS_LIST_REQUEST,
});

export const getSkillLevels = (data: any): AnyAction => ({
  type: ActionTypes.GET_SKILL_LEVEL_REQUEST,
  payload: data,
});

export const createNewSkillLevel = (data: any): AnyAction => ({
  type: ActionTypes.POST_SKILL_LEVEL_REQUEST,
  payload: data,
});

export const deleteSkillLevel = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_SKILL_LEVEL_REQUEST,
  payload: data,
});

export const editSkillLevel = (data: any): AnyAction => ({
  type: ActionTypes.PUT_SKILL_LEVEL_REQUEST,
  payload: data,
});

export const getLanguageLevelsList = (): AnyAction => ({
  type: ActionTypes.GET_LANGUAGE_LEVELS_LIST_REQUEST,
});

export const getLanguageLevel = (data: any): AnyAction => ({
  type: ActionTypes.GET_LANGUAGE_LEVEL_REQUEST,
  payload: data,
});

export const createNewLanguageLevel = (data: any): AnyAction => ({
  type: ActionTypes.POST_LANGUAGE_LEVEL_REQUEST,
  payload: data,
});

export const deleteLanguageLevel = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_LANGUAGE_LEVEL_REQUEST,
  payload: data,
});

export const editLanguageLevel = (data: any): AnyAction => ({
  type: ActionTypes.PUT_LANGUAGE_LEVEL_REQUEST,
  payload: data,
});

export const getForeignLanguagesList = (): AnyAction => ({
  type: ActionTypes.GET_FOREIGN_LANGUAGES_LIST_REQUEST,
});

export const getForeignLanguage = (data: any): AnyAction => ({
  type: ActionTypes.GET_FOREIGN_LANGUAGE_REQUEST,
  payload: data,
});

export const createNewForeignLanguage = (data: any): AnyAction => ({
  type: ActionTypes.POST_FOREIGN_LANGUAGE_REQUEST,
  payload: data,
});

export const deleteForeignLanguage = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_FOREIGN_LANGUAGE_REQUEST,
  payload: data,
});

export const editForeignLanguage = (data: any): AnyAction => ({
  type: ActionTypes.PUT_FOREIGN_LANGUAGE_REQUEST,
  payload: data,
});

export const setForeignLanguageParams = (data: Partial<paramsTypes.ForeignLanguagesParams>): AnyAction => ({
  type: ActionTypes.SET_FOREIGN_LANGUAGE_PARAMS,
  payload: data,
});

export const resetErrors = (): AnyAction => ({
  type: ActionTypes.RESET_LIBRARIES_ERRORS,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_LIBRARIES_STATE,
});
