import React, { useCallback, useState } from 'react';
import Modal from '../../Modal/index';
import Button from '../../Button/index';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import RadioButton from '../../RadioButton';
import Switch from '../../Switch';
import classNames from 'classnames';

type ModalForRepeatableEventsProps = {
  onRequestClose: () => void;
  onSubmit: (nextEvent: boolean) => () => void;
  title: string;
  shouldCloseOnOverlayClick?: boolean;
  isLoading: boolean;
  eventError: string | RejectValueErrors[] | null;
  inputName: string;
  isOpen: boolean;
  handleSendNotification?: (event: any) => void;
  sendNotification?: boolean;
  isSendNotificationShown?: boolean;
};

function ModalForRepeatableEvents({
  onRequestClose,
  onSubmit,
  title,
  shouldCloseOnOverlayClick = false,
  isLoading,
  eventError,
  inputName,
  isOpen,
  handleSendNotification,
  sendNotification,
  isSendNotificationShown,
}: ModalForRepeatableEventsProps) {
  const intl = useIntl();

  const [nextEvent, setNextEvent] = useState(false);

  const handleNextEvent = useCallback(() => {
    setNextEvent(prev => !prev);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      size="small"
      title={title}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      classNameModal="center"
    >
      <RadioButton
        name={inputName}
        label={intl.formatMessage(messages.thisEventLabel)}
        value="false"
        onChange={handleNextEvent}
        checked={!nextEvent}
      />
      <RadioButton
        name={inputName}
        label={intl.formatMessage(messages.thisAndFollowingEventsLabel)}
        value="true"
        onChange={handleNextEvent}
        checked={nextEvent}
      />
      <div className={classNames('modal__event-type-footer', { 'with-switch': isSendNotificationShown })}>
        <div className="form__buttons-wrapper">
          {isSendNotificationShown && (
            <div className="switch">
              <Switch onChange={handleSendNotification} checked={sendNotification} />
              <div className="switch__title">
                <FormattedMessage {...messages.sendNotificationLabel} />
              </div>
            </div>
          )}
        </div>
        <div className="form__buttons">
          <ErrorMessage>{eventError}</ErrorMessage>
          <Button externalClass={'button--modal'} type={'button'} onClick={onRequestClose} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            externalClass={'button--modal'}
            onClick={onSubmit(nextEvent)}
            type="button"
            loading={isLoading}
            disabled={isLoading}
          >
            <FormattedMessage {...messages.okButton} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalForRepeatableEvents;
