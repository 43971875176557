import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import PoliciesContext from '../../PoliciesContext';
import * as candidatesActions from '../../actions/candidates.actions';
import * as filtersActions from '../../actions/filters.actions';
import { useHistory, useParams } from 'react-router';
import { TRouteParamId } from '../../enums/common.enum';
import { ConnectedProps, connect } from 'react-redux';
import Button from '../../components/Button';
import Label from '../../components/Label';
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import Avatar from '../../components/Profile/Avatar';
import ModifyingInfo from './ModifyingInfo';
import CustomLoader from '../../components/Loader';
import ProfileInfo from './ProfileInfo';
import ModalEditCandidate from '../../components/CandidateManagement/Modals/ModalEditCandidate';
import ModalDeleteCandidate from '../../components/CandidateManagement/Modals/ModalDeleteCandidate';
import { Candidate, CandidateStatus } from '../../enums/candidates.enums';
import { checkPolicies } from '../../utils/policies.utils';
import { DELETE_CANDIDATE, UPDATE_CANDIDATE } from '../../constants/policies.constants';
import CandidateProfileTabs from '../../components/CandidateProfile/Tabs/index';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { PhotoCropSetting } from '../../enums/users.enum';
import { getPhotoData } from '../../utils';
import { isEmpty } from 'lodash-es';
import AccessChecker from '../../components/AccessChecker';
import r from '../../constants/routes.constants';

enum ModalType {
  ALL = 'ALL',
  BASE_INFO = 'BASE_INFO',
  ABOUT_INFO = 'ABOUT_INFO',
}

function CandidateProfile({
  candidate,
  loading,
  candidateError,
  photoError,
  candidateStatuses,
  candidateTechnologies,
  candidateSpecializations,
  candidateReceivingSources,
  getCandidateInfo,
  getCandidateStatusesFilter,
  getCandidateTechnologiesFilter,
  getCandidateSpecializationsFilter,
  getCandidateReceivingSourcesFilter,
  editCandidateInfo,
  deleteCandidate,
  resetCandidateInfo,
  uploadCandidatePhoto,
  deleteCandidatePhoto,
  resetErrors,
}: ConnectedProps<typeof connector>) {
  const { id } = useParams<TRouteParamId>();
  const [modalEditCandidateIsOpen, setModalEditCandidateIsOpen] = useState(false);
  const [modalDeleteCandidateIsOpen, setModalDeleteCandidateIsOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ALL);
  const policies = useContext(PoliciesContext);
  const history = useHistory();

  const openEditCandidateModal = useCallback(() => {
    setModalEditCandidateIsOpen(true);
    setModalType(ModalType.ALL);
  }, []);

  const openEditBaseInfoModal = useCallback(() => {
    setModalEditCandidateIsOpen(true);
    setModalType(ModalType.BASE_INFO);
  }, []);

  const openEditAboutInfoModal = useCallback(() => {
    setModalEditCandidateIsOpen(true);
    setModalType(ModalType.ABOUT_INFO);
  }, []);

  const closeEditCandidateModal = useCallback(() => {
    setModalEditCandidateIsOpen(false);
    resetErrors();
  }, []);

  const openDeleteCandidateModal = useCallback(() => {
    setModalDeleteCandidateIsOpen(true);
  }, []);

  const closeDeleteCandidateModal = useCallback(() => {
    setModalDeleteCandidateIsOpen(false);
    resetErrors();
  }, []);

  const goBackHandler = () => {
    history.push(r.candidates);
  };

  const handleChangePhoto = useCallback(
    async (data: { file: File | undefined; cropSetting: PhotoCropSetting }, callback: () => void) => {
      const imageData = await getPhotoData(data, candidate.photo?.url || '');
      uploadCandidatePhoto({ uuid: id, data: imageData, callback });
    },
    [candidate.photo?.url],
  );

  const handleDeletePhoto = useCallback(({ callback }: { callback: () => void }) => {
    deleteCandidatePhoto({ uuid: id, callback });
  }, []);

  const candidateActions = useMemo(
    () => [
      ...(checkPolicies([UPDATE_CANDIDATE], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'info'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.statusLabel} />
                </>
              ),
              list: candidateStatuses.map((status: CandidateStatus) => ({
                label: (candidateInfo: Candidate) => (
                  <>
                    {candidateInfo.statusId === status.id && <Icon iconName="check" />}
                    <span style={{ color: `#${status.color}` }}>{status.name}</span>
                  </>
                ),
                handler: (candidateInfo: Candidate) =>
                  editCandidateInfo({ data: new Candidate({ ...candidateInfo, statusId: status.id }) }),
              })),
            },
            {
              label: (
                <>
                  <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.editButton} />
                </>
              ),
              handler: openEditCandidateModal,
            },
            //TODO
            // {
            //   label: (
            //     <>
            //       <Icon iconName={'add-member'} externalClass={'dropdown__list-item__icon'} />
            //       <FormattedMessage {...messages.createMemberBtn} />
            //     </>
            //   ),
            //   handler: () => null,
            // },
          ]
        : []),
      ...(checkPolicies([DELETE_CANDIDATE], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.deleteButton} />
                </>
              ),
              handler: openDeleteCandidateModal,
              itemClassName: 'top-line',
            },
          ]
        : []),
    ],
    [policies],
  );

  useEffect(() => {
    if (checkPolicies([UPDATE_CANDIDATE], policies)) {
      getCandidateStatusesFilter();
      getCandidateTechnologiesFilter();
      getCandidateSpecializationsFilter();
      getCandidateReceivingSourcesFilter();
    }
    getCandidateInfo(id);
    return () => {
      resetCandidateInfo();
    };
  }, [id]);

  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper">
          <Button color="gray" onClick={goBackHandler}>
            <FormattedMessage {...messages.candidatesManagementLabel} />
          </Button>
          <div className="page__profile-panel__label-options">
            <div className="page__profile-panel__label-info">
              <Label type="absence" externalClass="page__profile-panel__label" color={`#${candidate.status.color}`}>
                {candidate.status.name}
              </Label>
            </div>
            {!isEmpty(candidateActions) && (
              <Dropdown
                dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                dropdownList={candidateActions}
                dropdownInfo={candidate}
              />
            )}
          </div>
        </div>
      </div>
      <div className="page__inner-content">
        <div className="page__wrapper fixed-container">
          {!loading.candidateInfo ? (
            <>
              <div className="page__profile-info page__candidate-profile">
                <div className="page__candidate-profile__info-wrapper">
                  <Avatar
                    isCandidate
                    userInfo={candidate}
                    customSize={260}
                    customFontSize={80}
                    fileSize={520}
                    uploadPhoto={handleChangePhoto}
                    deleteUserPhoto={handleDeletePhoto}
                    loading={loading.photo}
                    errors={photoError}
                    viewOnly={!checkPolicies([UPDATE_CANDIDATE], policies)}
                  />
                  <ProfileInfo candidate={candidate} />
                  <AccessChecker verifiablePolicies={[UPDATE_CANDIDATE]}>
                    <Button color="gray" externalClass="page__profile-button" onClick={openEditBaseInfoModal}>
                      <Icon iconName="pencil" externalClass="button__icon" />
                    </Button>
                  </AccessChecker>
                </div>
                <ModifyingInfo
                  author={candidate.author}
                  createdDate={candidate.createdDate}
                  lastEditor={candidate.lastEditor}
                  lastModifiedDate={candidate.lastModifiedDate}
                />
              </div>
              <CandidateProfileTabs openEditModal={openEditAboutInfoModal} />
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
      {modalEditCandidateIsOpen && (
        <ModalEditCandidate
          candidate={candidate}
          isOpen
          isInfoModal={modalType === ModalType.BASE_INFO}
          isAboutModal={modalType === ModalType.ABOUT_INFO}
          error={candidateError}
          isLoading={loading.editCandidate}
          candidateStatuses={candidateStatuses}
          candidateSpecializations={candidateSpecializations}
          candidateReceivingSources={candidateReceivingSources}
          candidateTechnologies={candidateTechnologies}
          onCloseRequest={closeEditCandidateModal}
          editCandidate={editCandidateInfo}
        />
      )}
      {modalDeleteCandidateIsOpen && (
        <ModalDeleteCandidate
          candidate={candidate}
          isOpen
          error={candidateError}
          isLoading={loading.deleteCandidate}
          onCloseRequest={closeDeleteCandidateModal}
          onDeleteRequest={deleteCandidate}
          deleteCallback={goBackHandler}
        />
      )}
    </>
  );
}
const mapStateToProps = ({ candidates, filters }: RootState) => ({
  candidate: candidates.candidateInfo,
  candidateStatuses: filters.candidateStatusesFilter.candidateStatuses,
  candidateTechnologies: filters.candidateTechnologiesFilter.candidateTechnologies,
  candidateSpecializations: filters.candidateSpecializationsFilter.candidateSpecializations,
  candidateReceivingSources: filters.candidateReceivingSourcesFilter.candidateReceivingSources,
  loading: candidates.loading,
  candidateError: candidates.errors.candidateError,
  photoError: candidates.errors.photoError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateInfo: (uuid: string) => dispatch(candidatesActions.getCandidateInfo(uuid)),
  getCandidateStatusesFilter: () => dispatch(filtersActions.getCandidateStatusesFilter()),
  getCandidateTechnologiesFilter: () => dispatch(filtersActions.getCandidateTechnologiesFilter()),
  getCandidateSpecializationsFilter: () => dispatch(filtersActions.getCandidateSpecializationsFilter()),
  getCandidateReceivingSourcesFilter: () => dispatch(filtersActions.getCandidateReceivingSourcesFilter()),
  editCandidateInfo: (data: { data: Candidate; callback?: () => void }) =>
    dispatch(candidatesActions.editCandidateInfo(data)),
  deleteCandidate: (data: { data: string; callback: () => void }) => dispatch(candidatesActions.deleteCandidate(data)),
  resetCandidateInfo: () => dispatch(candidatesActions.resetCandidateInfo()),
  uploadCandidatePhoto: (data: { uuid: string; data: FormData; callback: () => void }) =>
    dispatch(candidatesActions.uploadCandidatePhoto(data)),
  deleteCandidatePhoto: (data: { uuid: string; callback: () => void }) =>
    dispatch(candidatesActions.deleteCandidatePhoto(data)),
  resetErrors: () => dispatch(candidatesActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(CandidateProfile);
