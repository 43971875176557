import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import { Client, FinanceProject, PROJECT_SCHEMA } from '../../../enums/finance/finance.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import Select from '../../Select';
import Icon from '../../Icon';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { ESTIMATE, FINANCE_PROJECT_CONFIRMING_MESSAGE, TIME_AND_MATERIAL } from '../../../constants/finance.constants';
import { find } from 'lodash-es';
import { CurrencyType, ProjectType } from '../../../types/finance';
import { getCurrentRateDate } from '../../../utils/finance.utils';
import { scrollToError } from '../../../utils';

type ModalEditFinanceProjectProps = {
  onCloseRequest: () => void;
  editProject: (data: { data: FinanceProject; callback: () => void; forceUpdate?: boolean }) => void;
  project: FinanceProject;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  getCurrenciesFilter: () => void;
  getClientsFilter: () => void;
  getJiraProjects: () => void;
  getProjectTypesFilter: () => void;
  currenciesList: CurrencyType[];
  clients: Client[];
  jiraProjects: { id: string; name: string }[];
  jiraProjectsErrors: string;
  projectTypes: ProjectType[];
};

function ModalEditFinanceProject({
  onCloseRequest,
  editProject,
  project,
  error,
  isLoading,
  isOpen,
  getCurrenciesFilter,
  getClientsFilter,
  getJiraProjects,
  getProjectTypesFilter,
  clients,
  currenciesList,
  jiraProjects,
  jiraProjectsErrors,
  projectTypes,
}: ModalEditFinanceProjectProps) {
  const intl = useIntl();

  useEffect(() => {
    getCurrenciesFilter();
    getClientsFilter();
    getJiraProjects();
    getProjectTypesFilter();
  }, []);

  const { values, errors, touched, handleChange, handleSubmit, setFieldError, setFieldValue } = useFormik({
    initialValues: project,
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: PROJECT_SCHEMA,
    onSubmit: data => {
      return editProject({
        //@ts-ignore
        data: { ...data, rates: data.rates.map(item => ({ ...item, rate: new Number(item.rate).toFixed(2) })) },
        callback: onCloseRequest,
      });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const [modalConfirmProjectMoveIsOpen, setModalConfirmProjectMoveIsOpen] = useState(false);

  useEffect(() => {
    if (error === FINANCE_PROJECT_CONFIRMING_MESSAGE) {
      setModalConfirmProjectMoveIsOpen(true);
    }
  }, [error]);

  const onConfirmProjectMove = useCallback(() => {
    editProject({
      //@ts-ignore
      data: { ...values, rates: values.rates.map(item => ({ ...item, rate: new Number(item.rate).toFixed(2) })) },
      callback: onCloseRequest,
      forceUpdate: true,
    });
    setModalConfirmProjectMoveIsOpen(false);
  }, [values]);

  const closeConfirmProjectMoveModal = useCallback(() => {
    setModalConfirmProjectMoveIsOpen(false);
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const currenciesOptions = useMemo(() => currenciesList?.map(item => ({ label: item.name, value: item })), [
    currenciesList,
  ]);

  const currencyValue = useCallback(
    index =>
      values.rates[index].currency &&
      currenciesOptions?.find(({ value }: { value: { id: string } }) => value.id === values.rates[index].currencyId),
    [currenciesOptions, values],
  );

  const clientsOptions = useMemo(() => clients?.map(item => ({ label: item.name, value: item })), [clients]);

  const clientValue = useCallback(
    () =>
      values.client && clientsOptions?.find(({ value }: { value: { id: string } }) => value.id === values.client.id),
    [clientsOptions, values],
  );

  const jiraProjectOptions = useMemo(
    () => jiraProjects?.map(({ id, name }: { id: string; name: string }) => ({ value: id, label: name })),
    [jiraProjects],
  );

  const projectTypesOptions = useMemo(
    () => projectTypes?.map((item: { id: string; name: string }) => ({ value: item, label: item.name })),
    [projectTypes],
  );

  const projectTypeValue = useMemo(
    () =>
      values.projectType &&
      projectTypesOptions?.find(({ value }: { value: { id: string } }) => value.id === values.projectType.id),
    [projectTypesOptions, values],
  );

  const paymentTypeOptions = useMemo(
    () => [
      {
        value: TIME_AND_MATERIAL,
        label: intl.formatMessage(messages.timeAndMaterialLabel),
      },
      {
        value: ESTIMATE,
        label: intl.formatMessage(messages.estimateLabel),
      },
    ],
    [],
  );

  const paymentTypeValue = useMemo(
    () =>
      values.paymentType && paymentTypeOptions?.find(({ value }: { value: string }) => value === values.paymentType),
    [paymentTypeOptions, values],
  );

  const addNewRate = useCallback(() => {
    setFieldValue(`rates`, [
      ...values.rates,
      {
        currency: null,
        currencyId: '',
        rate: '',
        startDate: '',
      },
    ]);
  }, [values]);

  const removeRate = useCallback(
    index => () => {
      values.rates.splice(index, 1);
      setFieldValue(`rates`, values.rates);
    },
    [values],
  );

  const handleChangeRate = useCallback((value, index) => {
    setFieldValue(`rates[${index}].rate`, value);
  }, []);

  const handleChangeCurrency = useCallback((item, index) => {
    setFieldValue(`rates[${index}].currency`, item.value);
    setFieldValue(`rates[${index}].currencyId`, item.value?.id);
  }, []);

  const handleChangeDate = useCallback(
    (value, index) => {
      const date = moment(value).format(DATE_FORMAT.YYYY_MM_DD);
      if (!values.rates[index].currencyId && !values.rates[index].rate && values.client) {
        const currentRate = getCurrentRateDate(values.client.rates, date);

        setFieldValue(`rates[${index}].currency`, currentRate?.currency);
        setFieldValue(`rates[${index}].currencyId`, currentRate?.currencyId);
        setFieldValue(`rates[${index}].rate`, currentRate?.rate);
      }
      setFieldValue(`rates[${index}].startDate`, date);
    },
    [values],
  );
  const ratesErrors: any = useMemo(() => errors.rates, [errors.rates]);

  const handleChangeClient = useCallback(item => {
    setFieldValue(`client`, item.value);
    setFieldValue(`clientId`, item.value?.id);
  }, []);

  const handleChangeJiraProjects = useCallback(value => {
    setFieldValue(
      'jiraProjects',
      value.map((item: any) => ({ id: item.value, name: item.label })),
    );
    setFieldValue(
      'jiraProjectIds',
      value.map((item: any) => item.value),
    );
  }, []);

  const handleChangeProjectType = useCallback(item => {
    setFieldValue(`projectType`, item.value);
    setFieldValue(`projectTypeId`, item.value?.id);
  }, []);

  const handleChangePaymentType = useCallback(item => {
    setFieldValue(`paymentType`, item.value);
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.editClientTitle)}
        size={'small'}
        classNameModal={'finance-project-modal'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <Input
              id={'name'}
              name={'name'}
              label={intl.formatMessage(messages.nameColumn)}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
              defaultValue={values.name}
            />
            <Select
              label={intl.formatMessage(messages.clientLabel)}
              options={clientsOptions}
              value={clientValue()}
              handleChange={handleChangeClient}
              hasError={hasError(`clientId`)}
              errorMessage={errors.clientId}
              isClearable
            />
            <Select
              label={intl.formatMessage(messages.jiraProjectsLabel)}
              name={'jiraProjects'}
              options={jiraProjectOptions}
              handleChange={handleChangeJiraProjects}
              hasError={hasError('jiraProjects')}
              errorMessage={jiraProjectsErrors}
              isMulti
              value={values.jiraProjectIds.map((id: string) => ({
                label: `${find(jiraProjectOptions, el => el.value === id)?.label}`,
                value: id,
              }))}
            />
            <div className="form__inputs-subwrapper">
              <div className={'form__input-block form__input-block--half'}>
                <Select
                  label={intl.formatMessage(messages.projectTypeLabel)}
                  name={'projectType'}
                  options={projectTypesOptions}
                  handleChange={handleChangeProjectType}
                  value={projectTypeValue}
                  hasError={hasError('projectTypeId')}
                  errorMessage={errors.projectTypeId}
                  isClearable
                />
              </div>
              <div className={'form__input-block form__input-block--half'}>
                <Select
                  label={intl.formatMessage(messages.paymentTypeLabel)}
                  name={'paymentType'}
                  options={paymentTypeOptions}
                  handleChange={handleChangePaymentType}
                  //@ts-ignore
                  value={paymentTypeValue}
                  hasError={hasError('paymentType')}
                  errorMessage={errors.paymentType}
                  isClearable
                />
              </div>
            </div>
            {values.rates.map((item: any, index: number) => (
              <div className="form__inputs-subwrapper" key={index}>
                <div className="form__inputs-subwrapper rate-block">
                  <div className="form__input-block date-wrapper">
                    <Input
                      type="date"
                      label={intl.formatMessage(messages.startDateLabel)}
                      name={`rates[${index}].startDate`}
                      defaultValue={values.rates[index].startDate}
                      onChange={event => handleChangeDate(event.target.value, index)}
                      hasError={hasError(`rates[${index}].startDate`)}
                      errorMessage={ratesErrors && ratesErrors[index]?.startDate}
                    />
                  </div>
                  <div className={'form__input-block'}>
                    <Input
                      name={`rates[${index}].rate`}
                      label={intl.formatMessage(messages.hourPriceLabel)}
                      onChange={event => {
                        handleChangeRate(event.target.value, index);
                      }}
                      defaultValue={values.rates[index]?.rate}
                      hasError={hasError(`rates[${index}].rate`)}
                      errorMessage={ratesErrors && ratesErrors[index]?.rate}
                      type="number"
                    />
                  </div>
                  <div className="form__input-block">
                    <Select
                      label={intl.formatMessage(messages.currencyLabel)}
                      options={currenciesOptions}
                      value={currencyValue(index)}
                      handleChange={value => handleChangeCurrency(value, index)}
                      hasError={hasError(`rates[${index}].currencyId`)}
                      errorMessage={ratesErrors && ratesErrors[index]?.currencyId}
                      isSearchable
                      isClearable
                    />
                  </div>
                </div>
                <button className="form__btn-clean-inputs" type={'button'} onClick={removeRate(index)}>
                  <Icon iconName={'cross'} />
                </button>
              </div>
            ))}
            <div className="form__input-block add_button_block">
              <Button type="button" externalClass="form__btn-add-group" color="gray" onClick={addNewRate}>
                <Icon iconName="plus" externalClass="form__icon-btn-add" />
                <FormattedMessage {...messages.addButton} />
              </Button>
            </div>
          </div>
          <ErrorMessage>{errors.rates}</ErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              onClick={onCloseRequest}
              type={'button'}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={modalConfirmProjectMoveIsOpen}
        onRequestClose={closeConfirmProjectMoveModal}
        title={intl.formatMessage(messages.confirmmoveProjectTitle)}
        size={'small'}
        shouldCloseOnOverlayClick
        classNameModal="center"
      >
        <div className={'form__buttons'}>
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={closeConfirmProjectMoveModal}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} onClick={onConfirmProjectMove}>
            <FormattedMessage {...messages.addButton} />
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ModalEditFinanceProject;
