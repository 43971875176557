import { get } from 'lodash-es';
import { UserPhoto, UserPreviewInfo } from './users.enum';
import { getForamtedTime } from '../utils/reports.utils';
import { getCurrentAbsence } from '../utils/planning.utils';
import { AbsenceEvent } from './users.enum';

export type EmployeesTimesheetIssue = {
  description: null;
  id: number;
  projectId: number;
  summary: string;
  timeWorked: number;
  totalTimeWorked: number;
};

export type EmployeesTimesheetProject = {
  id: number;
  issues: EmployeesTimesheetIssue[];
  name: string;
  totalWorked?: number;
  totalTimeWorked: number;
};

export class EmployeesTimesheetDay {
  date: string;
  events: null | [];
  isWeekend: boolean;
  projects: EmployeesTimesheetProject[];
  totalWorked: number;
  absenceEvent: AbsenceEvent;

  constructor(item?: unknown) {
    this.date = get(item, 'date', '');
    this.events = get(item, 'events');
    this.isWeekend = get(item, 'isWeekend', false);
    this.projects = get(item, 'projects', []);
    this.totalWorked = get(item, 'totalWorked');
    this.absenceEvent = new AbsenceEvent(getCurrentAbsence(get(item, 'events', null)));
  }

  get absenceColor() {
    return this.absenceEvent?.eventTypeColor || '';
  }

  get absenceTranslucentColor() {
    return `${this.absenceEvent?.eventTypeColor}1A` || '';
  }

  get absenceNameLetter() {
    return this.absenceEvent?.eventName
      ? this.absenceEvent?.eventName?.trim()[0]
      : this.absenceEvent?.eventTypeName?.trim()[0] || '';
  }
}

export class EmployeesTimesheetItem {
  days: EmployeesTimesheetDay[];
  displayName: string;
  firstName: string;
  id: number;
  secondName: string;
  name: string;
  projects: EmployeesTimesheetProject[];
  planned: number;
  fact: number;
  workedByIssues: any;
  workedByProjects: any;
  totalItem?: boolean;
  totalWorkedByDates?: any;
  portalId: string;
  photo: UserPhoto;

  constructor(item?: unknown) {
    this.days = get(item, 'days', []).map((item: EmployeesTimesheetDay) => new EmployeesTimesheetDay(item));
    this.firstName = get(item, 'firstName', '');
    this.displayName = get(item, 'displayName', '');
    this.id = get(item, 'id', '');
    this.secondName = get(item, 'lastName', '');
    this.name = get(item, 'name', '');
    this.workedByIssues = get(item, 'workedByIssues');
    this.workedByProjects = get(item, 'workedByProjects');
    this.projects = getAllUsersProjects(this.days, this.workedByIssues, this.workedByProjects); //,
    this.planned = get(item, 'planned');
    this.fact = get(item, 'fact');
    this.totalItem = get(item, 'totalItem', false);
    this.portalId = get(item, 'portalId');
    this.photo = new UserPhoto(get(item, 'photo', null));
  }
  get fullName() {
    return `${this.firstName} ${this.secondName}`;
  }
}

const getAllUsersProjects = (days: EmployeesTimesheetDay[], workedByIssues: any, workedByProjects: any) => {
  const allProjects = days.reduce(
    (projects: EmployeesTimesheetProject[], currentDay) => [...projects, ...currentDay.projects],
    [],
  );

  return allProjects.reduce((projects: EmployeesTimesheetProject[], currentProject) => {
    if (!projects.find(item => item.id === currentProject.id)) {
      projects.push({ ...currentProject, totalTimeWorked: workedByProjects[currentProject.id] });
    }

    return projects.map(project => ({
      ...project,
      issues: allProjects
        .reduce((issues: EmployeesTimesheetIssue[], currentProject) => {
          if (currentProject.id === project.id) {
            return [...issues, ...currentProject.issues];
          }
          return issues;
        }, [])
        .reduce((issues: EmployeesTimesheetIssue[], currentIssue) => {
          if (!issues.find(item => item.id === currentIssue.id)) {
            issues.push({ ...currentIssue, totalTimeWorked: workedByIssues[currentIssue.id] });
          }
          return issues;
        }, []),
    }));
  }, []);
};

export class EmployeesIssueType {
  countOfIssues: number;
  description: string;
  id: string;
  pname: string;
  pstyle: null;
  sequence: number;
  worked: number;

  constructor(item?: unknown) {
    this.countOfIssues = get(item, 'countOfIssues', 0);
    this.description = get(item, 'description', '');
    this.id = get(item, 'id', '');
    this.pname = get(item, 'pname', '');
    this.pstyle = get(item, 'pstyle', null);
    this.sequence = get(item, 'sequence', 0);
    this.worked = get(item, 'worked', 0);
  }

  get workedHours() {
    return `${getForamtedTime(this.worked)}h`;
  }
}

export class EmployeesIssueTypesProjects {
  id: number;
  issueTypes: EmployeesIssueType[];
  name: string;
  countOfIssues: number;
  worked: number;

  constructor(item?: unknown) {
    this.id = get(item, 'id', '');
    this.issueTypes = get(item, 'issueTypes', []).map((el: any) => new EmployeesIssueType(el));
    this.name = get(item, 'name', '');
    this.countOfIssues = get(item, 'countOfIssues', 0);
    this.worked = get(item, 'worked', 0);
  }

  get workedHours() {
    return `${getForamtedTime(this.worked)}h`;
  }
}

export class EmployeesIssueTypesItem {
  displayName: string;
  firstName: string;
  id: number;
  secondName: string;
  name: string;
  projects: EmployeesIssueTypesProjects[];
  allIssueTypes: EmployeesIssueType[];
  worked: number;
  countOfIssues: number;
  totalItem?: boolean;
  portalId: string;
  photo: UserPhoto;

  constructor(item?: unknown) {
    this.displayName = get(item, 'displayName', '');
    this.firstName = get(item, 'firstName', '');
    this.id = get(item, 'id', '');
    this.secondName = get(item, 'lastName', '');
    this.name = get(item, 'name', '');
    this.projects = get(item, 'projects', []).map((el: any) => new EmployeesIssueTypesProjects(el));
    //@ts-ignore
    this.allIssueTypes = this.projects.reduce((types, current) => [...types, ...current.issueTypes], []);
    this.worked = get(item, 'worked', 0);
    this.countOfIssues = get(item, 'countOfIssues', 0);
    this.totalItem = get(item, 'totalItem', false);
    this.portalId = get(item, 'portalId');
    this.photo = new UserPhoto(get(item, 'photo', null));
  }
}

export type AbsencePeriodsDateType = {
  allDays: number;
  calendarDays: number;
  endDate: string;
  startDate: string;
  workDays: number;
  eventTypeName: string;
};

export class AbsencePeriodsListItemType {
  dates: AbsencePeriodsDateType[];
  user: UserPreviewInfo;
  allDays: number;
  calendarDays: number;
  workDays: number;
  totalItem: boolean;

  constructor(item?: unknown) {
    this.dates = get(item, 'dates', []);
    this.user = new UserPreviewInfo(get(item, 'user'));
    this.allDays = get(item, 'allDays');
    this.calendarDays = get(item, 'calendarDays');
    this.workDays = get(item, 'workDays');
    this.totalItem = get(item, 'totalItem', false);
  }
}

export class UsersHoursAbsencesReportDay {
  date: string;
  workLogHours: number;
  isWeekend: boolean;
  absenceEvent: AbsenceEvent;

  constructor(item?: unknown) {
    this.date = get(item, 'date', '');
    this.workLogHours = get(item, 'workLogHours');
    this.isWeekend = get(item, 'isWeekend', false);
    this.absenceEvent = getCurrentAbsence(get(item, 'events', null));
  }

  get absenceColor() {
    return this.absenceEvent?.eventTypeColor || '';
  }

  get absenceTranslucentColor() {
    return `${this.absenceEvent?.eventTypeColor}1A` || '';
  }

  get absenceNameLetter() {
    return this.absenceEvent?.eventTypeName?.trim()[0] || '';
  }
}

export class UsersHoursAbsencesReportUser {
  delta: number;
  daysOf: UsersHoursAbsencesReportEvent[];
  displayName: string;
  experience: { days: number; months: number; years: number };
  factHours: number;
  firstName: string;
  id: number;
  secondName: string;
  days: UsersHoursAbsencesReportDay[];
  name: string;
  planHours: number;
  position: {
    displayName: string;
    gradeId: string;
    gradeName: string;
    id: string;
    priority: number;
  };
  workLogHoursAtWorkDays: number;
  portalId: string;
  photo: UserPhoto;

  constructor(item?: unknown) {
    this.delta = get(item, 'delta');
    this.daysOf = get(item, 'daysOf', []);
    this.displayName = get(item, 'displayName', '');
    this.experience = get(item, 'experience', '');
    this.factHours = get(item, 'factHours');
    this.firstName = get(item, 'firstName', '');
    this.id = get(item, 'id', '');
    this.secondName = get(item, 'lastName', '');
    this.days =
      get(item, 'months', [])?.[0].days.map(
        (item: UsersHoursAbsencesReportDay) => new UsersHoursAbsencesReportDay(item),
      ) || [];
    this.name = get(item, 'name', '');
    this.planHours = get(item, 'planHours');
    this.position = get(item, 'position');
    this.workLogHoursAtWorkDays = get(item, 'workLogHoursAtWorkDays');
    this.portalId = get(item, 'portalId');
    this.photo = new UserPhoto(get(item, 'photo', null));
  }

  get getUserExperiences() {
    return `${this.experience?.years || 0}y ${this.experience?.months || 0}m `;
  }
}

export class UsersHoursAbsencesReportItem {
  workLogHoursAtWorkDays: number;
  users: UsersHoursAbsencesReportUser;
  planHours: number;
  name: string;
  daysOf: UsersHoursAbsencesReportEvent[];
  delta: number;
  factHours: number;
  totalItem: boolean;

  constructor(item?: unknown) {
    this.delta = get(item, 'delta');
    this.users = get(item, 'users', []).map(
      (item: UsersHoursAbsencesReportUser) => new UsersHoursAbsencesReportUser(item),
    );
    this.daysOf = get(item, 'daysOf', []);
    this.factHours = get(item, 'factHours');
    this.name = get(item, 'name', '');
    this.planHours = get(item, 'planHours');
    this.workLogHoursAtWorkDays = get(item, 'workLogHoursAtWorkDays');
    this.totalItem = get(item, 'totalItem', false);
  }
}

export class UsersHoursAbsencesReportEvent {
  eventTypeColor: string;
  eventTypeId: string;
  eventTypeName: string;
  typeHours: number;

  constructor(item?: unknown) {
    this.eventTypeColor = get(item, 'eventTypeColor');
    this.eventTypeId = get(item, 'eventTypeId');
    this.eventTypeName = get(item, 'eventTypeName');
    this.typeHours = get(item, 'typeHours');
  }

  get absenceNameLetter() {
    return this.eventTypeName?.trim()[0] || '';
  }

  get absenceTranslucentColor() {
    return `${this?.eventTypeColor}1A` || '';
  }
}

export type ActiveandInactiveHoursDateType = {
  date: string;
  logged: number;
  notWorkingActive: number;
  notWorkingInactive: number;
  submitted: number;
  trackedActive: number;
  trackedInactive: number;
};

export type ActiveandInactiveHoursUser = {
  dates: ActiveandInactiveHoursDateType[];
  displayName: string;
  firstName: string;
  id: number;
  isFromPortal: boolean;
  isPortalActive: boolean;
  lastName: string;
  logged: number;
  name: string;
  notWorkingActive: number;
  notWorkingInactive: number;
  portalId: string;
  submitted: number;
  trackedActive: number;
  trackedInactive: number;
  totalItem?: boolean;
};
