import { defaultTo, get, isEmpty, isNil } from 'lodash-es';
import moment from 'moment';
import * as yup from 'yup';
import {
  CandidateReceivingSourceType,
  CandidateSpecializationType,
  CandidateTechnologyType,
} from '../types/candidates';
import { ThumbnailsSize, UserInfo, UserPhoto, UserPreviewInfo } from './users.enum';
import { CommentInfo } from './schedule.enum';
import { checkPolicies } from '../utils/policies.utils';
import { DELETE_CANDIDATE_COMMENT, UPDATE_CANDIDATE_COMMENT } from '../constants/policies.constants';
import { UserPolicy } from './policies.enum';

export class CandidateStatus {
  id: string;
  name: string;
  isDefault: boolean;
  color: string;
  position: number;

  constructor(candidateStatus?: Partial<CandidateStatus>) {
    this.id = get(candidateStatus, 'id', '');
    this.name = get(candidateStatus, 'name', '');
    this.isDefault = get(candidateStatus, 'isDefault', false);
    this.color = get(candidateStatus, 'color', '');
    this.position = get(candidateStatus, 'position', 0);
  }
}

export const CANDIDATE_STATUS_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().required('Required').max(150, 'Maximum 150 characters'),
  color: yup.string().required('Color is required'),
});

export const CANDIDATE_SPECIZALIZATION_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().required('Required').max(150, 'Maximum 150 characters'),
});

export const CANDIDATE_TECHNOLOGY_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().required('Required').max(150, 'Maximum 150 characters'),
});

export const CANDIDATE_RECEIVING_SOURCE_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().required('Required').max(150, 'Maximum 150 characters'),
});

export type InitialCandidateType = Omit<Candidate, 'carrierStart' | 'message' | 'month' | 'year' | 'active'> & {
  carrierStart: string | null;
};

export class Candidate {
  id: string;
  firstName: string;
  lastName: string;
  alternativeName: string;
  status: CandidateStatus;
  statusId: string | null;
  photo: UserPhoto | null;
  emails: string[];
  phones: string[];
  previousEmployments: string;
  carrierStart: string;
  month: string;
  year: string;
  experienceDays: number;
  specializations: CandidateSpecializationType[];
  hasLeadExperience: boolean;
  technologies: CandidateTechnologyType[];
  location: string;
  receivingSource: CandidateReceivingSourceType;
  receivingSourceId: string | null;
  links: string;
  message: string;
  createdDate: string;
  lastModifiedDate: string;
  author: UserPreviewInfo;
  lastEditor: UserPreviewInfo;
  lastCommentAuthor: UserPreviewInfo;
  lastCommentDate: string;
  active: boolean;

  constructor(candidate?: unknown, defaultReceivingSource = { id: '', name: '' }) {
    this.id = get(candidate, 'id', '');
    this.firstName = get(candidate, 'firstName', '');
    this.lastName = get(candidate, 'lastName', '');
    this.alternativeName = get(candidate, 'alternativeName', '');
    this.status = defaultTo(get(candidate, 'status'), new CandidateStatus());
    this.statusId = get(candidate, 'statusId', null);
    this.photo = get(candidate, 'photo', null);
    this.emails = get(candidate, 'emails', []);
    this.phones = get(candidate, 'phones', []);
    this.previousEmployments = get(candidate, 'previousEmployments', '');
    this.carrierStart = get(candidate, 'carrierStart', '');
    this.year = this.carrierStart ? moment(this.carrierStart).get('y').toString() : '';
    this.month = this.carrierStart ? moment(this.carrierStart).get('M').toString() : '';
    this.experienceDays = get(candidate, 'experienceDays', 0);
    this.specializations = get(candidate, 'specializations', []);
    this.hasLeadExperience = get(candidate, 'hasLeadExperience', false);
    this.technologies = get(candidate, 'technologies', []);
    this.location = get(candidate, 'location', '');
    this.receivingSource = defaultTo(get(candidate, 'receivingSource'), defaultReceivingSource);
    this.receivingSourceId = get(candidate, 'receivingSourceId', null);
    this.links = defaultTo(get(candidate, 'links'), '');
    this.message = '';
    this.createdDate = get(candidate, 'createdDate', '');
    this.lastModifiedDate = get(candidate, 'lastModifiedDate', '');
    this.author = new UserPreviewInfo(get(candidate, 'author'));
    this.lastEditor = new UserPreviewInfo(get(candidate, 'lastEditor'));
    this.lastCommentAuthor = new UserPreviewInfo(get(candidate, 'lastCommentAuthor'));
    this.lastCommentDate = get(candidate, 'lastCommentDate', '');
    this.active = true;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get initials() {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
  }

  get hasContacts() {
    return !isEmpty(this.phones) || !isEmpty(this.emails);
  }

  photoUrl(size?: ThumbnailsSize) {
    if (!size || this?.photo?.thumbnails?.length === 0) {
      return this.photo?.url;
    }
    const url = this.photo?.thumbnails?.find((thum: any) => thum.size === size)?.url;
    return url;
  }
}

export const CANDIDATE_VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().trim().required('Required').max(20, 'Maximum 20 characters'),
  lastName: yup.string().required('Required').max(30, 'Maximum 30 characters'),
  emails: yup.array(yup.string().trim().email('Invalid email')),
  phones: yup.array(yup.string().max(20, 'No more than 20 characters').trim()),
  year: yup
    .string()
    .nullable()
    .test('year', 'Required', (value: string | undefined | null, testContext: any) => {
      const form = testContext.from[0].value;
      return !!value || isNil(form.month);
    }),
  month: yup
    .string()
    .nullable()
    .test('month', 'Required', (value: string | undefined | null, testContext: any) => {
      const form = testContext.from[0].value;
      return !!value || isNil(form.year);
    }),
});

export class CandidateComment extends CommentInfo {
  replyTo: CandidateComment | null;
  replyToOriginalMessage: string | null;
  replyToOriginalLastModifiedDate: string | null;
  isDeleted: boolean;
  constructor(commentPage: number, comment?: Partial<CandidateComment>, author?: unknown) {
    super(commentPage, comment, author);
    const replyTo = get(comment, 'replyTo');
    this.replyTo = replyTo ? new CandidateComment(0, replyTo) : null;
    this.replyToOriginalMessage = get(comment, 'replyToOriginalMessage', null);
    this.replyToOriginalLastModifiedDate = get(comment, 'replyToOriginalLastModifiedDate', null);
    this.isDeleted = get(comment, 'isDeleted', false);
  }

  isCommentEdit(replyToOriginalLastModifiedDate?: string) {
    if (replyToOriginalLastModifiedDate) {
      return !moment(this.createdDate).isSame(moment(replyToOriginalLastModifiedDate));
    }
    return !moment(this.createdDate).isSame(moment(this.lastModifiedDate));
  }

  userAbleToManageComment(userInfo: UserInfo, userPolicies: UserPolicy[]) {
    const isAuthor = userInfo.id === this.author.id;
    const ableToReplyComment = checkPolicies([UPDATE_CANDIDATE_COMMENT], userPolicies);
    const ableToEditComment = isAuthor && ableToReplyComment;

    const ableToDeleteComment = ableToEditComment || checkPolicies([DELETE_CANDIDATE_COMMENT], userPolicies);
    return { ableToEditComment, ableToDeleteComment, ableToReplyComment };
  }
}
