import { defineMessages } from 'react-intl';
import {
  newButton,
  editButton,
  deleteButton,
  nameColumn,
  positionsLabel,
  memberLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  newButton,
  editButton,
  deleteButton,
  positionsLabel,
  memberLabel,
  pageTitle: {
    id: 'notifications.employee.groups.title.member.groups',
    defaultMessage: 'Member Groups',
  },
  employeeGroupColumn: {
    id: 'notifications.employee.groups.column.member.group',
    defaultMessage: 'Member Group',
  },
});
