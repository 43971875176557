import React, { useCallback, useEffect } from 'react';
import * as competenciesActions from '../../../actions/competencies.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import EditCompetenceForm from '../../../components/Competencies/Forms/EditCompetenceForm';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { TRouteParamId } from '../../../enums/common.enum';
import { getCompetenciesTypesFilter } from '../../../actions/filters.actions';

function EditCompetence({
  cleanCompetenceEditForm,
  competenciesTypeList,
  getCompetence,
  competenceData,
  editCompetence,
  getCompetenciesTypesFilter,
  isLoading,
  editCompetenceError,
}: ConnectedProps<typeof connector>) {
  const { id } = useParams<TRouteParamId>();
  const history = useHistory();

  const editCompetenceCallback = useCallback(() => {
    history.goBack();
  }, []);

  const onSubmitHandler = (data: any) => {
    editCompetence({ data: data, callback: editCompetenceCallback });
  };

  useEffect(() => {
    getCompetence({ data: id });
    getCompetenciesTypesFilter();
    return () => {
      cleanCompetenceEditForm();
    };
  }, [id]);

  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper fixed-container">
          <h1 className="page__title">
            <FormattedMessage {...messages.editCompetenceTitle} />
          </h1>
        </div>
      </div>
      <div className="page__main-content">
        <div className="page__wrapper fixed-container">
          <EditCompetenceForm
            competenciesTypes={competenciesTypeList}
            isLoading={isLoading}
            onSubmit={onSubmitHandler}
            competenceData={competenceData}
            competenciesError={editCompetenceError}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ competencies, filters }: RootState) => ({
  competenciesTypeList: filters.competenciesTypesFilter.competenciesTypes,
  isLoading: competencies.loading.editCompetence,
  competenceData: competencies.competenceData || undefined,
  editCompetenceError: competencies.errors.editCompetenceError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCompetenciesTypesFilter: () => dispatch(getCompetenciesTypesFilter()),
  editCompetence: (data: any) => dispatch(competenciesActions.editCompetence(data)),
  getCompetence: (data: any) => dispatch(competenciesActions.getCompetence(data)),
  cleanCompetenceEditForm: () => dispatch(competenciesActions.cleanCompetenceData()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EditCompetence);
