import { defineMessages } from 'react-intl';
import { cancelButton, deleteModalTitle, deleteButton, commentLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteModalTitle,
  deleteButton,
  commentLabel,
  selectAnswerLabel: {
    id: 'questionnaires.poll.question.select.answer.label',
    defaultMessage: 'Select Answer',
  },
  answerLabel: {
    id: 'questionnaires.poll.question.answer.label',
    defaultMessage: 'Answer',
  },
  notAnsweredLabel: {
    id: 'questionnaires.poll.question.not.answered.label',
    defaultMessage: 'Not Answered',
  },
});
