import React, { useCallback } from 'react';
import classNames from 'classnames';
import ReactCurrencyInput from 'react-currency-input-field';
import ErrorMessage from '../ErrorMessage';
import { isEmpty, isNil, isString } from 'lodash-es';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';

type CurrencyInputProps = {
  id?: string;
  name: string;
  value?: string | number;
  defaultValue?: string;
  index?: number;
  onChange?: (value: CurrencyInputOnChangeValues, index?: number) => void;
  errorMessage?: string;
  label?: string;
  hasError?: boolean;
  externalClass?: string;
  wrapperClass?: string;
  disabled?: boolean;
  allowNegativeValue?: boolean;
  alwaysShowLabel?: boolean;
  suffix?: string;
  decimalsLimit?: number;
  allowDecimals?: boolean;
};

function CurrencyInput({
  id,
  name,
  hasError,
  label,
  errorMessage,
  externalClass,
  wrapperClass,
  value,
  index,
  defaultValue,
  disabled,
  allowNegativeValue = false,
  alwaysShowLabel = false,
  allowDecimals = true,
  suffix,
  decimalsLimit,
  onChange,
}: CurrencyInputProps) {
  const labelClass = classNames('form__label', {
    '--error': hasError,
  });

  const handleValueChange = useCallback(
    (value: string | undefined, inputName: string | undefined, values: CurrencyInputOnChangeValues | undefined) => {
      if (values) {
        onChange && onChange(values, index);
      } else {
        onChange && onChange({ float: 0, value: '', formatted: '' }, index);
      }
    },
    [onChange, index],
  );

  const defaultClass = classNames('form__input', {
    [externalClass as string]: Boolean(externalClass),
    'not-empty': isString(value) ? !isEmpty(value) : !isNil(value) || alwaysShowLabel,
    disabled,
  });

  const defaultWrapperClass = classNames('form__input-block', {
    [wrapperClass as string]: Boolean(wrapperClass),
  });

  return (
    <div className={defaultWrapperClass}>
      <ReactCurrencyInput
        id={id || name}
        name={name}
        className={defaultClass}
        allowDecimals={allowDecimals}
        value={value}
        defaultValue={defaultValue}
        onValueChange={handleValueChange}
        disabled={disabled}
        allowNegativeValue={allowNegativeValue}
        suffix={suffix}
        decimalsLimit={decimalsLimit}
        disableAbbreviations
      />
      <label className={labelClass} htmlFor={id || name}>
        {label}
      </label>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
}

export default React.memo(CurrencyInput);
