import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/resumes.constants';
import * as api from '../api/resumes.api';
import { AnyAction } from 'redux';
import { store } from '..';
import { ResumesParams } from '../enums/params/resumes.params';
import { getQueryParams } from '../utils/params.utils';

function* getResumesList() {
  const params: ResumesParams = yield select((state: RootState) => state.resumes.resumesParams);
  const queryParams = getQueryParams(params);

  const skillIds = <any>[];
  queryParams.technicalSkills &&
    queryParams.technicalSkills.map((item: { skills: { id: string; skill: string }[] }) => {
      item.skills.map(({ id }: { id: string }) => skillIds.push(id));
    });

  delete queryParams.technicalSkills;

  if (queryParams.approvedStatus?.length === 2) {
    queryParams.approvedStatus = null;
  }

  const data = {
    ...queryParams,
    skillIds,
  };

  try {
    const { success, results } = yield call(api.getResumesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_RESUMES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_RESUMES_LIST_FAILURE, payload: error });
  }
}

function* editUserResume({ payload }: AnyAction) {
  Promise.all(
    payload.resumes.map((resume: { id: string; data: FormData }) => {
      return api.editUserResume(resume.id, resume.data).then(responce => responce);
    }),
  )
    .then(responce => {
      if (responce.every((item: any) => item.success === true)) {
        store.dispatch({ type: ActionTypes.PATCH_USER_RESUME_SUCCESS });
        payload.callback();
      }
    })
    .catch(error => {
      store.dispatch({ type: ActionTypes.PATCH_USER_RESUME_FAILURE, payload: error });
    });
}

function* getResume({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getResume, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_RESUME_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_RESUME_FAILURE, payload: error });
  }
}

export default function* resumesSaga() {
  yield all([
    takeEvery(ActionTypes.GET_RESUMES_LIST_REQUEST, getResumesList),
    takeEvery(ActionTypes.PATCH_USER_RESUME_REQUEST, editUserResume),
    takeEvery(ActionTypes.GET_RESUME_REQUEST, getResume),
    takeEvery(ActionTypes.SET_RESUMES_PARAMS, getResumesList),
  ]);
}
