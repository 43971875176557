import { defineMessages } from 'react-intl';
import { newButton, editButton, deleteButton, nameColumn } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  pageTitle: {
    id: 'libraries.departments.title.departments',
    defaultMessage: 'Departments',
  },
});
