import React, { useCallback } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { BonusCategoryInfo } from '../../../enums/bonuses.enums';
import { RejectValueErrors } from '../../../enums/error.enum';

type ModalDeleteBonusProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onDeleteRequest: (data: { data: string; callback: () => void }) => void;
  bonuseError: string | null | RejectValueErrors[];
  bonusData: BonusCategoryInfo;
  isLoading: boolean;
  resetErrors: () => void;
};

function ModalDeleteBonus({
  onCloseRequest,
  onDeleteRequest,
  bonuseError,
  bonusData,
  isLoading,
  resetErrors,
  isOpen,
}: ModalDeleteBonusProps) {
  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetErrors();
  }, []);

  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.deleteModalTitle)}
      size={'small'}
      shouldCloseOnOverlayClick
      classNameModal="center"
    >
      <ErrorMessage>{bonuseError}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            onDeleteRequest({ data: bonusData.id, callback: resetAndExit });
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteBonus;
