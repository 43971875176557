import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import { MonthReportParts, PlannedExpenseClass, PLANNED_EXPENSE_SCHEMA } from '../../../enums/finance/finance.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import CurrencyInput from '../../CurrencyInput';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import { CurrencyType, ExpenseType } from '../../../types/finance';
import MonthSelect from '../../MonthSelect';
import { addYearCount, lengthYearList } from '../utilts';

type ModalNewSalaryProps = {
  onCloseRequest: () => void;
  createPlannedExpense: (data: { data: PlannedExpenseClass; callback: () => void }) => void;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  officeMonthClicked: {
    office: { id: string; name: string; isDeleted: boolean };
    monthData: MonthReportParts;
    expenseType: ExpenseType;
  } | null;
  baseCurrency: CurrencyType | undefined;
};

function ModalNewPlannedExpense({
  onCloseRequest,
  createPlannedExpense,
  error,
  isLoading,
  isOpen,
  officeMonthClicked,
  baseCurrency,
}: ModalNewSalaryProps) {
  const intl = useIntl();

  const { values, errors, touched, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: new PlannedExpenseClass({
      startDate: officeMonthClicked?.monthData.month,
      endDate: officeMonthClicked?.monthData.month,
    }),
    validateOnChange: false,
    validationSchema: PLANNED_EXPENSE_SCHEMA,
    onSubmit: data => {
      return createPlannedExpense({ data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    if (officeMonthClicked) {
      setFieldValue(`office`, officeMonthClicked.office);
      setFieldValue(`officeId`, officeMonthClicked.office.id);
      setFieldValue(`expenseType`, officeMonthClicked.expenseType);
      setFieldValue(`expenseTypeId`, officeMonthClicked.expenseType.id);
      setFieldValue(`startDate`, officeMonthClicked.monthData.month);
      setFieldValue(`endDate`, officeMonthClicked.monthData.month);
    }
  }, [officeMonthClicked]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handlePayChange = useCallback((value: CurrencyInputOnChangeValues) => {
    setFieldValue('amount', value);
  }, []);

  const handleStartYearChange = useCallback(({ value }) => {
    setFieldValue('startYear', value);
  }, []);

  const handleStartMonthChange = useCallback(({ value }) => {
    setFieldValue('startMonth', value);
  }, []);

  const handleEndYearChange = useCallback(({ value }) => {
    setFieldValue('endYear', value);
  }, []);

  const handleEndMonthChange = useCallback(({ value }) => {
    setFieldValue('endMonth', value);
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.newPlannedExpenseTitle)}
        size="small"
        classNameModal="planned-expense-modal center"
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block office-block">{`${values?.expenseType?.name} / ${values.office?.name}`}</div>
            <div className="form__note form__input-block">
              <FormattedMessage {...messages.periodLabel} />
            </div>
            <div className="form__inputs-subwrapper">
              <div className="form__input-block">
                <MonthSelect
                  year={values.startYear}
                  month={values.startMonth}
                  isShortMonths
                  isClearable={false}
                  withLabel={false}
                  addYearCount={addYearCount}
                  lengthYearList={lengthYearList}
                  selectExternalClass="select__no-label"
                  errorMessageYear={errors.startYear}
                  errorMessageMonth={errors.startMonth}
                  handleYearChange={handleStartYearChange}
                  handleMonthChange={handleStartMonthChange}
                />
              </div>
              <div className="form__month-select-separator">-</div>
              <div className="form__input-block">
                <MonthSelect
                  year={values.endYear}
                  month={values.endMonth}
                  isShortMonths
                  isClearable={false}
                  withLabel={false}
                  addYearCount={addYearCount}
                  lengthYearList={lengthYearList}
                  selectExternalClass="select__no-label"
                  errorMessageYear={errors.endYear}
                  errorMessageMonth={errors.endMonth}
                  handleYearChange={handleEndYearChange}
                  handleMonthChange={handleEndMonthChange}
                />
              </div>
            </div>
            <div className="form__inputs-subwrapper">
              <>
                <CurrencyInput
                  label={intl.formatMessage(messages.payLabel)}
                  name="amount"
                  wrapperClass="form__input-block--half"
                  //@ts-ignore
                  value={values.amount.value}
                  onChange={handlePayChange}
                  hasError={hasError('amount') || hasError('amount.float')}
                  //@ts-ignore
                  errorMessage={errors?.amount?.float || errors?.amount}
                  suffix={` ${baseCurrency?.name || ''}`}
                />
              </>
            </div>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons display-center">
            <Button
              color="gray"
              externalClass="button--modal button--cancel"
              onClick={onCloseRequest}
              type="button"
              block
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass="button--modal" type="submit" loading={isLoading} disabled={isLoading} block>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalNewPlannedExpense;
