import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import TableUserAvatar from '../../components/TableUserAvatar';
import messages from './messages';
import {
  styleStatus,
  getStatusLabel,
  fileSizeForAvatar,
  notPublishedValue,
  publishedValue,
  closedValue,
  completedStatusValue,
} from './utils';
import Icon from '../../components/Icon';
import { NewPoll } from '../../enums/questionnaires.enum';
import { NavLink } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { DELETE_POLL, UPDATE_POLL_EXTENDED } from '../../constants/policies.constants';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';
import r from '../../constants/routes.constants';

export const useDataForTable = (
  handleOpenModalDeletePoll: () => void,
  setClickedPoll: (row: any) => void,
  handleOpenModalEditPoll: () => void,
  createPoll: (data: any) => void,
  pollCallback: () => void,
  handleOpenExportModal: () => void,
  handleEditPoll: (data: any) => void,
) => {
  const intl = useIntl();

  const textRepliedUsers = useCallback((pollData: any) => {
    const usersCount = pollData?.responders?.filter((responder: any) => responder.pollStatus === completedStatusValue)
      .length;

    return `${usersCount}/${pollData?.repliesNumber}`;
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: NewPoll) => (
          <NavLink className={'table__type-link'} to={r.pollsInfo.replace(':id', row.id).replace(':isAdmin', 'true')}>
            <span className={'table_button'}>{row.name}</span>
          </NavLink>
        ),
      },
      {
        name: intl.formatMessage(messages.pollResponders),
        modifier: (row: NewPoll) => {
          const updatedResponders = row.responders.filter(user => row.officeIds.every(id => id !== user.office?.id));
          return row.isGeneral ? (
            <>
              <div className="all-responders-wrapper">
                <Icon iconName="users" />
                <span className="allResponders">{intl.formatMessage(messages.allLabel)}</span>
              </div>
            </>
          ) : (
            <>
              {!isEmpty(row.offices) && (
                <div className="table__list-data">
                  {row.offices?.map((office: any) => (
                    <span key={office?.id} className="data__enum">
                      <Icon iconName="building" />
                      <span className="officeList">{office?.name}</span>
                    </span>
                  ))}
                </div>
              )}
              <TableUserAvatar users={updatedResponders} fileSize={fileSizeForAvatar} selectedTableUser />
            </>
          );
        },
      },
      {
        name: intl.formatMessage(messages.pollOrganizers),
        modifier: (row: NewPoll) => {
          return <TableUserAvatar users={row.organizers} fileSize={fileSizeForAvatar} selectedTableUser />;
        },
      },
      {
        name: intl.formatMessage(messages.pollReplied),
        modifier: (row: NewPoll) => (
          <div className={'table__data-wrapper'}>
            <span className={'table_button'}>{textRepliedUsers(row)}</span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.pollRelatiedEvent),
        modifier: (row: NewPoll) => {
          const title = row.relatedEvent.eventTableLinkTitle;
          return row.relatedEventId ? (
            row.relatedEvent.isUserHaveAccess ? (
              <NavLink className={'table__type-link'} to={r.eventInformation.replace(':id', `${row.relatedEventId}`)}>
                {title}
              </NavLink>
            ) : (
              <span>{title}</span>
            )
          ) : null;
        },
      },
      {
        name: intl.formatMessage(messages.pollDeadlines),
        modifier: (row: NewPoll) => (
          <div className={'table__data-wrapper'}>
            <span className={'table_button'}>{moment(row.deadline).format(DATE_FORMAT.ll)}</span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.statusLabel),
        modifier: (row: NewPoll) => (
          <div className={'table__data-wrapper'}>
            <span style={{ color: styleStatus(row.status), fontWeight: 500 }} className="table_button">
              {getStatusLabel(row.status)}
            </span>
          </div>
        ),
      },
    ],
    [],
  );

  const classNameStatus = 'dropdown__list-item__button status ';

  const getCurrentPollStatus = useCallback((currentStatus: string, status: string) => {
    return currentStatus === status ? `${classNameStatus} checked` : classNameStatus;
  }, []);

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button line',
        handler: (row: NewPoll) => {
          setClickedPoll(row);
          handleOpenModalEditPoll();
        },
        verifiablePolicies: [UPDATE_POLL_EXTENDED],
      },
      {
        label: (
          <>
            <Icon iconName={'copy'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.dublicateLabel} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button line',
        handler: (row: NewPoll) => {
          setClickedPoll(row);
          createPoll({ data: { ...row }, isNewPoll: false, callback: pollCallback });
        },
        verifiablePolicies: [UPDATE_POLL_EXTENDED],
      },
      {
        label: (
          <>
            <Icon iconName={'file'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.exportLabel} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button line',
        handler: (row: NewPoll) => {
          setClickedPoll(row);
          handleOpenExportModal();
        },
      },
      {
        label: (row: NewPoll) => (
          <>
            {row.status === notPublishedValue && (
              <Icon iconName={'accept'} externalClass={'dropdown__list-item__icon'} />
            )}
            <FormattedMessage {...messages.notPublishedStatusLabel} />
          </>
        ),
        itemClassName: (row: NewPoll) => getCurrentPollStatus(row.status, notPublishedValue),
        handler: (row: NewPoll) => {
          handleEditPoll({ ...row, status: notPublishedValue, isEditFromModal: false });
        },
        verifiablePolicies: [UPDATE_POLL_EXTENDED],
      },
      {
        label: (row: NewPoll) => (
          <>
            {row.status === publishedValue && <Icon iconName={'accept'} externalClass={'dropdown__list-item__icon'} />}
            <FormattedMessage {...messages.publishedStatusLabel} />
          </>
        ),
        itemClassName: (row: NewPoll) => getCurrentPollStatus(row.status, publishedValue),
        handler: (row: NewPoll) => {
          handleEditPoll({ ...row, status: publishedValue, isEditFromModal: false });
        },
        verifiablePolicies: [UPDATE_POLL_EXTENDED],
      },
      {
        label: (row: NewPoll) => (
          <>
            {row.status === closedValue && <Icon iconName={'accept'} externalClass={'dropdown__list-item__icon'} />}
            <FormattedMessage {...messages.closedStatusLabel} />
          </>
        ),
        itemClassName: (row: NewPoll) => `${getCurrentPollStatus(row.status, closedValue)} line`,
        handler: (row: NewPoll) => {
          handleEditPoll({ ...row, status: closedValue, isEditFromModal: false });
        },
        verifiablePolicies: [UPDATE_POLL_EXTENDED],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: NewPoll) => {
          setClickedPoll(row);
          handleOpenModalDeletePoll();
        },
        verifiablePolicies: [DELETE_POLL],
      },
    ],
    [],
  );

  return {
    tableColumns,
    tableActions,
  };
};
