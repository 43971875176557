import { cancelled, put, take, takeLatest, all, select, call, fork } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import axios from 'axios';
import { getExportFileNameWithDate } from '../utils/reports.utils';
import { EFileExtensions, EFileName } from '../constants/export.constants';
import { createUploaderChannel } from '../utils/request.utils';
import * as ActionTypes from '../constants/export.constants';
import * as api from '../api/export.api';
import * as reportsParamsTypes from '../enums/params/reports.params';
import { DaysUsedLimitParams } from '../enums/params/schedule.params';
import { ProjectHoursParams, UserHoursParams } from '../enums/params/planning.params';
import { getFileExtensionParamsValue } from '../utils/params.utils';
import { UsersParams } from '../enums/params/users.params';
import { Candidate } from '../enums/candidates.enums';
import { IncomesExpensesReportParams } from '../enums/params/finance.params';
import { ActivityParams } from '../enums/params/activity.params';

const CancelToken = axios.CancelToken;
let cancel: any;

const cb = (c: any) => {
  cancel = c;
};

export function* uploadProgressWatcher(channel: any): any {
  while (true) {
    try {
      const { progressEvent, response, error } = yield take(channel);

      if (progressEvent) {
        yield put({
          type: ActionTypes.CHANGE_EXPORT_PROGRESS,
          payload: progressEvent,
        });
      }
      if (response) {
        yield put({
          type: ActionTypes.GET_EXPORTED_DATA_SUCCESS,
          payload: response,
        });
      }
      if (error) {
        throw error;
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        yield put({
          type: ActionTypes.GET_EXPORTED_DATA_FAILURE,
          payload: err,
        });
      }
    } finally {
      if (yield cancelled()) {
        console.log('close chanel');
      }
    }
  }
}

function* cancelExport() {
  if (cancel) {
    yield call(cancel);
  }
}

function* exportProjectsReport(): any {
  yield call(cancelExport);
  const params: reportsParamsTypes.ProjectsReportParams = yield select(
    (state: RootState) => state.reports.projectsReportParams,
  );
  const exportProjectParams: reportsParamsTypes.ProjectsReportParams = {
    ...params,
    isFoldSubtasks: false,
  };
  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.PROJECTS, params.dateFrom, params.dateTo),
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportProjectsReport(exportProjectParams, new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportEmployeesReport(): any {
  const params: reportsParamsTypes.EmployeesReportParams = yield select(
    (state: RootState) => state.reports.employeesReportParams,
  );
  const exportEmployeeParams: reportsParamsTypes.EmployeesReportParams = {
    ...params,
    isFoldIssuesToProject: false,
    isFoldSubtasksToParentTask: false,
  };

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.EMPLOYEES, params.dateFrom, params.dateTo),
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportEmployeesReport(exportEmployeeParams, new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportEmployeesTimesheetReport(): any {
  yield call(cancelExport);
  const params: reportsParamsTypes.EmployeesTimesheetReportParams = yield select(
    (state: RootState) => state.reports.employeesTimesheetReportParams,
  );
  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.EMPLOYEES_TIMESHEET, params.dateFrom, params.dateTo),
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportEmployeesTimesheetReport(params, new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportEmployeesIssueTypesReport(): any {
  const params: reportsParamsTypes.EmployeesIssueTypesParams = yield select(
    (state: RootState) => state.reports.employeesIssueTypesParams,
  );

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.EMPLOYEES_ISSUE_TYPES, params.dateFrom, params.dateTo),
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportEmployeesIssueTypesReport(params, new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportActiveInactiveHoursReport(): any {
  const params: reportsParamsTypes.ActiveInactiveHoursReportParams = yield select(
    (state: RootState) => state.reports.activeInactiveHoursReportParams,
  );

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.ACTIVE_AND_INACTIVE_HOURS, params.dateFrom, params.dateTo),
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportActiveInactiveHoursReport(params, new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportUsersHoursAbsencesReport(): any {
  const params: reportsParamsTypes.UsersHoursAbsencesReportParams = yield select(
    (state: RootState) => state.reports.usersHoursAbsencesReportParams,
  );

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.USERS_HOURS_ABSENCES, params.dateFrom, params.dateTo),
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportUsersHoursAbsencesReport(params, new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportAbsencePeriodsReport(): any {
  const params: reportsParamsTypes.AbsencePeriodsParams = yield select(
    (state: RootState) => state.reports.absencePeriodsParams,
  );

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.ABSENCE_PERIODS, params.dateTimeFrom, params.dateTimeTo),
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(createUploaderChannel, api.exportAbsencePeriodsReport(params, new CancelToken(cb)));
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportPollData({ payload }: AnyAction): any {
  const params = { userIds: payload.userIds, extension: payload.extension.value, exportType: payload.exportType.value };
  const fileName = payload.exportType.fileName;
  const ext = payload.extension.ext;

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: fileName,
      ext,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportPollData(payload.pollId, params, new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportDaysUsedLimit(): any {
  const params: DaysUsedLimitParams = yield select((state: RootState) => state.schedule.daysUsedLimitParams);

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.DAYS_LIMIT, params.dateFrom, params.dateTo),
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(createUploaderChannel, api.exportDaysUsedLimit(params, new CancelToken(cb)));
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportUserHours({ payload }: AnyAction): any {
  const params: UserHoursParams = yield select((state: RootState) => state.planning.userHoursParams);

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.USER_HOURS, params.dateFrom, params.dateTo),
      ext: payload,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportUserHours(params, getFileExtensionParamsValue(payload), new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportProjectHours({ payload }: AnyAction): any {
  const params: ProjectHoursParams = yield select((state: RootState) => state.planning.projectHoursParams);

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.USER_HOURS, params.dateFrom, params.dateTo),
      ext: payload,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportProjectHours(params, getFileExtensionParamsValue(payload), new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportUsers(): any {
  const params: UsersParams = yield select((state: RootState) => state.users.params);

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: EFileName.MEMBERS,
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(createUploaderChannel, api.exportUsers(params, new CancelToken(cb)));
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportCandidates({ payload }: AnyAction): any {
  const params = {
    candidateIds: payload.map((item: Candidate) => item.id),
  };

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: EFileName.CANDIDATES,
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(createUploaderChannel, api.exportCandidates(params, new CancelToken(cb)));
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportIncomesExpensesReport({ payload }: AnyAction): any {
  const params: IncomesExpensesReportParams = yield select(
    (state: RootState) => state.finance.incomesExpensesReportParams,
  );

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: EFileName.INCOMES_EXPENSES,
      ext: payload,
    },
  });
  const uploadChannel = yield call(
    createUploaderChannel,
    api.exportIncomesExpensesReport(params, getFileExtensionParamsValue(payload), new CancelToken(cb)),
  );
  yield fork(uploadProgressWatcher, uploadChannel);
}

function* exportActivity(): any {
  const params: ActivityParams = yield select((state: RootState) => state.activity.params);

  yield put({
    type: ActionTypes.SET_EXPORT_INFO,
    payload: {
      name: getExportFileNameWithDate(EFileName.ACTIVITY, params.dateFrom, params.dateTo),
      ext: EFileExtensions.XLS,
    },
  });
  const uploadChannel = yield call(createUploaderChannel, api.exportActivity(params, new CancelToken(cb)));
  yield fork(uploadProgressWatcher, uploadChannel);
}

export default function* reportsSaga() {
  yield all([
    takeLatest(ActionTypes.CANCEL_EXPORT, cancelExport),
    takeLatest(ActionTypes.EXPORT_PROJECTS_REPORT_REQUEST, exportProjectsReport),
    takeLatest(ActionTypes.EXPORT_EMPLOYEES_REPORT_REQUEST, exportEmployeesReport),
    takeLatest(ActionTypes.EXPORT_EMPLOYEES_TIMESHEET_REPORT_REQUEST, exportEmployeesTimesheetReport),
    takeLatest(ActionTypes.EXPORT_EMPLOYEES_ISSUE_TYPES_REPORT_REQUEST, exportEmployeesIssueTypesReport),
    takeLatest(ActionTypes.EXPORT_ACTIVE_INACTIVE_HOURS_REPORT_REQUEST, exportActiveInactiveHoursReport),
    takeLatest(ActionTypes.EXPORT_USERS_HOURS_ABSENCES_REPORT_REQUEST, exportUsersHoursAbsencesReport),
    takeLatest(ActionTypes.EXPORT_ABSENCE_PERIODS_REPORT_REQUEST, exportAbsencePeriodsReport),
    takeLatest(ActionTypes.EXPORT_POLL_DATA_REQUEST, exportPollData),
    takeLatest(ActionTypes.EXPORT_DAYS_USED_LIMIT_REQUEST, exportDaysUsedLimit),
    takeLatest(ActionTypes.EXPORT_USER_HOURS_REQUEST, exportUserHours),
    takeLatest(ActionTypes.EXPORT_PROJECT_HOURS_REQUEST, exportProjectHours),
    takeLatest(ActionTypes.EXPORT_USERS_REQUEST, exportUsers),
    takeLatest(ActionTypes.EXPORT_CANDIDATES_REQUEST, exportCandidates),
    takeLatest(ActionTypes.EXPORT_INCOMES_EXPENSES_REPORT_REQUEST, exportIncomesExpensesReport),
    takeLatest(ActionTypes.EXPORT_ACTIVITY_REQUEST, exportActivity),
  ]);
}
