import { OfficeInfo } from './libraries.enum';
import * as yup from 'yup';
import { get, isNumber } from 'lodash-es';
import { BONUS_INTERVAL_BOUNDARIES } from '../constants/bonuses.constants';
import { v4 as uuidv4 } from 'uuid';
import { GradeInfoType } from '../types/libraries';

const validateRestrictionCount = (
  value: any,
  testContext: any,
  restrictionDirection: 'restrictionFrom' | 'restrictionTo',
) => {
  const bonusCategory = testContext.from[2].value;
  let isSet = true;
  if (
    testContext.from[1].value[restrictionDirection]?.count === null &&
    value === null &&
    (bonusCategory.fromCompanyExperience || bonusCategory.fromPreviousExperience)
  ) {
    isSet = false;
  }
  return isSet;
};

export const BONUS_CATEGORY_VALIDATION_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required('Required'),
  officeId: yup.string().required('Required'),
  experiences: yup.array(
    yup.object({
      id: yup.string(),
      name: yup.string().required('Required'),
      restrictionFrom: yup
        .object({
          count: yup
            .number()
            .integer('Must be integer')
            .test('count', 'At least one time restriction must be set', (value: any, testContext: any) =>
              validateRestrictionCount(value, testContext, 'restrictionTo'),
            )
            .nullable(),
          type: yup
            .string()
            .matches(/^(MONTH|YEAR)$/)
            .required('Required'),
        })
        .nullable(),
      restrictionTo: yup
        .object({
          count: yup
            .number()
            .integer('Must be integer')
            .test('count', 'At least one time restriction must be set', (value: any, testContext: any) =>
              validateRestrictionCount(value, testContext, 'restrictionFrom'),
            )
            .nullable(),
          type: yup
            .string()
            .matches(/^(MONTH|YEAR)$/)
            .required('Required'),
        })
        .nullable(),
    }),
  ),
});

export const BONUS_GRADE_VALIDATION_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required('Required'),
  officeId: yup.string().required('Required'),
  experiences: yup.array(
    yup.object({
      id: yup.string(),
      name: yup.string().required('Required'),
      experienceGrades: yup.array(
        yup.object({
          id: yup.string(),
          value: yup.string().required('Required'),
          gradeFrom: yup
            .object({
              id: yup.string(),
              level: yup.number(),
            })

            .required('Required')
            .nullable(),
          gradeTo: yup
            .object({
              id: yup.string(),
              level: yup.number(),
            })
            .test((value: any, testContext: any) => {
              const level = testContext.from[1].value.gradeFrom?.level;
              if (!(level <= value?.level) && isNumber(value?.level) && isNumber(level)) {
                return testContext.createError({
                  path: testContext.path,
                  message: 'must be more then grade(from) or equal',
                });
              } else {
                const expGrades = testContext.from[2].value.experienceGrades;

                const currentExpBonus: ExperienceGrades = expGrades.find(
                  (expGrade: ExperienceGrades) => expGrade.gradeTo?.id === value?.id,
                );

                let flag = true;
                expGrades.forEach((expGrade: ExperienceGrades) => {
                  const curentLevelFrom = currentExpBonus.gradeFrom?.level;
                  const curentLevelTo = currentExpBonus.gradeTo?.level;
                  const expGradeLevelFrom = expGrade.gradeFrom?.level;
                  const expGradeLevelTo = expGrade.gradeTo?.level;
                  if (
                    expGrade?.id !== currentExpBonus.id &&
                    isNumber(curentLevelFrom) &&
                    isNumber(curentLevelTo) &&
                    isNumber(expGradeLevelFrom) &&
                    isNumber(expGradeLevelTo) &&
                    !(
                      (curentLevelFrom < expGradeLevelFrom && curentLevelTo < expGradeLevelFrom) ||
                      (curentLevelFrom > expGradeLevelTo && curentLevelTo > expGradeLevelTo)
                    )
                  ) {
                    flag = false;
                  }
                });

                return flag
                  ? flag
                  : testContext.createError({
                      path: testContext.path,
                      message: 'intersection',
                    });
              }
            })
            .required('Required')
            .nullable(),
          gradeFromId: yup.string().required('Required'),
          gradeToId: yup.string().required('Required'),
        }),
      ),
    }),
  ),
});

export class BonusCategoryInfo {
  active: boolean;
  experiences: BonusExperiencesInfo[];
  fromCompanyExperience: boolean;
  fromPreviousExperience: boolean;
  id: string;
  name: string;
  office: OfficeInfo;
  officeId: string;

  constructor(bonusCategory?: unknown) {
    this.active = get(bonusCategory, 'active', true);
    this.experiences = get(bonusCategory, 'experiences', [new BonusExperiencesInfo()]).map(
      (el: any) => new BonusExperiencesInfo(el),
    );
    this.fromCompanyExperience = get(bonusCategory, 'fromCompanyExperience', false);
    this.fromPreviousExperience = get(bonusCategory, 'fromPreviousExperience', false);
    this.id = get(bonusCategory, 'id', '');
    this.name = get(bonusCategory, 'name', '');
    this.office = get(bonusCategory, 'office', new OfficeInfo());
    this.officeId = get(bonusCategory, 'officeId', '');
  }
}

export class BonusExperiencesInfo {
  experienceGrades: ExperienceGrades[];
  id: string;
  name: string;
  restrictionFrom: {
    count: number;
    type: 'MONTH' | 'YEAR';
  } | null;
  restrictionTo: {
    count: number;
    type: 'MONTH' | 'YEAR';
  } | null;

  constructor(bonus?: unknown) {
    this.experienceGrades = get(bonus, 'experienceGrades', []);
    this.id = get(bonus, 'id', uuidv4());
    this.name = get(bonus, 'name', '');
    // check if restrictionFrom is not null
    this.restrictionFrom = get(bonus, 'restrictionFrom')
      ? get(bonus, 'restrictionFrom')
      : {
          count: null,
          type: BONUS_INTERVAL_BOUNDARIES[0],
        };
    this.restrictionTo = get(bonus, 'restrictionTo')
      ? get(bonus, 'restrictionTo')
      : {
          count: null,
          type: BONUS_INTERVAL_BOUNDARIES[0],
        };
  }
}

export class ExperienceGrades {
  gradeFrom: GradeInfoType;
  gradeFromId: string;
  gradeTo: GradeInfoType;
  gradeToId: string;
  id: string;
  value: string;

  constructor(expGrades?: unknown) {
    this.gradeFrom = get(expGrades, 'gradeFrom', null);
    this.gradeFromId = get(expGrades, 'gradeFromId', '');
    this.gradeTo = get(expGrades, 'gradeTo', null);
    this.gradeToId = get(expGrades, 'gradeToId', '');
    this.id = get(expGrades, 'id', uuidv4());
    this.value = get(expGrades, 'value', '');
  }
}
