import React from 'react';
import { DecimalSeparatorEnum, MatchOptionsValues } from '../../utils';
import MatchForm from './MatchForm';
import PreviewTable from './PreviewTable';

type FilePreviewProps = {
  parsedCsvData: Papa.ParseStepResult<any> | null;
  matchFormValues: {
    matchInfo: {
      field: string;
      value: MatchOptionsValues;
    }[];
  };
  decimalSeparator: DecimalSeparatorEnum;
  setMatchField: (field: string, value: any) => void;
  setDecimalSeparator: (separator: DecimalSeparatorEnum) => void;
};

function FilePreview({
  parsedCsvData,
  matchFormValues,
  decimalSeparator,
  setMatchField,
  setDecimalSeparator,
}: FilePreviewProps) {
  return (
    <div className="transactions__file-preview">
      <PreviewTable parsedCsvData={parsedCsvData} />
      <MatchForm
        parsedCsvData={parsedCsvData}
        decimalSeparator={decimalSeparator}
        matchFormValues={matchFormValues}
        setMatchField={setMatchField}
        setDecimalSeparator={setDecimalSeparator}
      />
    </div>
  );
}

export default FilePreview;
