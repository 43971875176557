import React from 'react';
import Button from '../../Button';
import Icon from '../../Icon';
import Modal from '../../Modal';
import parse from 'html-react-parser';
import { isContainerEmpty } from '../../../utils';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import AccessChecker from '../../AccessChecker';
import { DELETE_PROJECT, UPDATE_PROJECT } from '../../../constants/policies.constants';

type ModalProjectProps = {
  externalClass?: string;
  onCloseRequest: () => void;
  data: any;
  onDeleteRequest: any;
  onEditRequest: any;
  isOpen: boolean;
};

function ModalProject({ onCloseRequest, data, onDeleteRequest, onEditRequest, isOpen }: ModalProjectProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      shouldCloseOnOverlayClick
      title={data.name}
      popover
      modalOptions={
        <>
          <AccessChecker verifiablePolicies={[UPDATE_PROJECT]}>
            <Button
              externalClass={'modal__options-btn'}
              color="transparent"
              onClick={() => {
                onEditRequest();
                onCloseRequest();
              }}
            >
              <Icon iconName={'pencil'} externalClass={'modal__options-icon'} />
            </Button>
          </AccessChecker>{' '}
          <AccessChecker verifiablePolicies={[DELETE_PROJECT]}>
            <Button
              externalClass={'modal__options-btn'}
              color="transparent"
              onClick={() => {
                onDeleteRequest(data.id);
                onCloseRequest();
              }}
            >
              <Icon iconName={'trash'} externalClass={'modal__options-icon'} />
            </Button>
          </AccessChecker>
        </>
      }
      classNameModal="center"
    >
      {isContainerEmpty(data.description) ? (
        <div className="modal__project-no-data">
          <FormattedMessage {...messages.projectNoDataNote} />
        </div>
      ) : (
        <div className="modal__project-info form__inputs-wrapper">{parse(data.description)}</div>
      )}
    </Modal>
  );
}

export default ModalProject;
