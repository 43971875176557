import { UserPolicy } from './policies.enum';
import {
  COMMENT_PUBLISHED,
  MonthRepeatType,
  remindersData,
  RepeatType,
  REPEAT_DAYS_OF_WEEK,
} from '../constants/schedule.constants';
import { OfficeInfo } from './libraries.enum';
import { EventTypeInfo } from './calendar.enum';
import * as yup from 'yup';
import get from 'lodash-es/get';
import { defaultTo } from 'lodash-es';
import { UserInfo, UserPreviewInfo } from './users.enum';
import { getDateRange, getLocalDate, getLocalizedTime } from '../utils';
import moment from 'moment';
import { getCommentDate } from '../utils/schedule.utils';
import { DATE_FORMAT } from '../constants/date.constants';
import { NewPoll } from './questionnaires.enum';
import { AssessmentInfoPreview } from './competencies.enum';
import { checkPolicies } from '../utils/policies.utils';
import {
  DELETE_COMMENT,
  DELETE_SECRET_COMMENT,
  UPDATE_COMMENT,
  UPDATE_SECRET_COMMENT,
} from '../constants/policies.constants';

export const EVENT_VALIDATION_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  sendNotification: yup.boolean(),
  notificationParticipantsGroup: yup.string().nullable(),
  address: yup.string(),
  startDate: yup.string().required('Required'),
  startTime: yup.string(),
  allDay: yup.boolean(),
  description: yup.string(),
  endDate: yup
    .string()
    .test('endDate', 'End Date must be equal or later than Start Date', (value: any, testContext: any) => {
      const startDate = moment(testContext.from[0].value.startDate);
      if (moment(value) < startDate) {
        return false;
      }
      return true;
    })
    .nullable(),
  endOfRepeatDate: yup.string().when('endOfRepeatType', {
    is: 'DATE',
    then: yup
      .string()
      .test('endOfRepeatDate', 'Date must be after start date', (value: any, testContext: any) => {
        const startDate = moment(testContext.from[0].value.startDate);
        if (moment(value) <= startDate) {
          return false;
        }
        return true;
      })
      .required('Required'),
  }),
  endOfRepeatQuantity: yup.number(),
  endOfRepeatType: yup
    .string()
    .matches(/^(DATE|REPEAT_QUANTITY|NEVER_REPEAT)$/)
    .nullable(),
  endTime: yup.string(),
  eventType: yup.object(),
  locations: yup.array().when(['showTargetEmployee', 'showLocations'], {
    is: (showTargetEmployee: boolean, showLocations: boolean) => !showTargetEmployee && showLocations,
    then: shema => shema.min(1, 'Field must have at least 1 items'),
  }),
  monthRepeatType: yup
    .string()
    .matches(/^(SAME_MONTH_DAY|SAME_WEEK_AND_WEEK_DAY)$/, { excludeEmptyString: true })
    .nullable()
    .when('unitOfRepeat', {
      is: RepeatType.MONTHS,
      then: yup.string().typeError('Required').required('Required'),
    }),
  participants: yup.array().when(['showTargetEmployee', 'showEventParticipants'], {
    is: (showTargetEmployee: boolean, showEventParticipants: boolean) => !showTargetEmployee && showEventParticipants,
    then: shema => shema.min(1, 'Field must have at least 1 items'),
  }),
  excludedParticipants: yup.array(),
  reminders: yup.array().of(
    yup.object().shape({
      timeValue: yup.number(),
      unitOfTime: yup.string().oneOf(Object.keys(remindersData)),
    }),
  ),
  repeatDays: yup
    .array()
    .of(yup.string().oneOf(REPEAT_DAYS_OF_WEEK))
    .when('unitOfRepeat', {
      is: RepeatType.WEEKS,
      then: yup.array().min(1, 'Field must have at least 1 items'),
    }),
  repeatInterval: yup.number().nullable(),
  showAbsences: yup.boolean().nullable(),
  showAddress: yup.boolean().nullable(),
  showAssessments: yup.boolean().nullable(),
  showEndDate: yup.boolean().nullable(),
  showEventParticipants: yup.boolean().nullable(),
  showExcludedParticipants: yup.boolean().nullable(),
  showLocations: yup.boolean().nullable(),
  showQuestionnaires: yup.boolean().nullable(),
  showReminder: yup.boolean().nullable(),
  showTargetEmployee: yup.boolean().nullable(),
  showTime: yup.boolean().nullable(),
  showTitle: yup.boolean().nullable(),
  targetEmployee: yup
    .object()
    .nullable()
    .when('showTargetEmployee', {
      is: true,
      then: shema => shema.required('Required'),
    }),
  title: yup.string(),
  unitOfRepeat: yup.string().nullable(),
  parentEvent: yup.object().nullable(),
});

export class EventInfo {
  id: string;
  sendNotification: boolean;
  notificationParticipantsGroup: ENotificationGroup;
  absences: {
    approve: boolean;
    excludeDaysOfAbsenceFromOtherTypes: EventTypeInfo[];
    finalDecision: boolean;
    approvedBy: UserInfo;
    approvedByDate: string;
  };
  address: string;
  allDay: boolean;
  author: UserPreviewInfo;
  assessmentIdList: string[];
  assessments: AssessmentInfoPreview[];
  description: string;
  eventType: EventTypeInfo;
  locations: OfficeInfo[];
  locationsIds: string[];
  polls: NewPoll[];
  pollIds: string[];
  monthRepeatType: MonthRepeatType | null;
  participants: UserInfo[];
  participantsIds: string[];
  excludedParticipants: UserInfo[];
  excludedParticipantsIds: string[];
  reminders: {
    timeValue: number;
    unitOfTime: 'MINUTES' | 'HOURS' | 'DAYS';
  }[];
  repeatDays: string[];
  repeatInterval: any;
  parentEvent: EventInfo | null;
  eventTypeName: string;
  extraWorkingDaysEnabled: boolean;
  finalDecisionOptionsEnabled: boolean;
  optionForExcludeDaysOfAbsenceFromOtherTypes: boolean;
  requiresApprovalEnabled: boolean;
  requiresWorkingOff: boolean;
  showAbsences: boolean;
  showAddress: boolean;
  showAssessments: boolean;
  showEndDate: boolean;
  showEventParticipants: boolean;
  showExcludedParticipants: boolean;
  showLocations: boolean;
  showQuestionnaires: boolean;
  showReminder: boolean;
  showRepeater: boolean;
  showTargetEmployee: boolean;
  showTime: boolean;
  showTitle: boolean;
  showTotalCounterInProfile: boolean;
  startDate: string;
  startTime: string;
  targetEmployee: UserInfo | null;
  endDate: string;
  endOfRepeatDate: string;
  endOfRepeatQuantity: number;
  endOfRepeatType: string | null;
  endTime: string;
  title: string;
  unitOfRepeat: RepeatType | null;
  isPublic: boolean;

  constructor(event?: unknown, eventType?: EventTypeInfo) {
    this.id = get(event, 'id', '');
    this.sendNotification = get(event, 'sendNotification', true);
    this.notificationParticipantsGroup = get(event, 'notificationParticipantsGroup', ENotificationGroup.ALL);
    this.absences = get(event, 'absences', {
      approve: false,
      excludeDaysOfAbsenceFromOtherTypes: [],
      finalDecision: false,
      approvedBy: new UserInfo(),
      approvedByDate: '',
    });
    this.startDate = get(event, 'startDate', moment().format(DATE_FORMAT.YYYY_MM_DD));
    const startTime = moment().startOf('day').format(DATE_FORMAT.HH_mm_ss);
    this.startTime = defaultTo(get(event, 'startTime', startTime), startTime);
    this.address = defaultTo(get(event, 'address', ''), '');
    this.allDay = get(event, 'allDay', false);
    this.author = new UserPreviewInfo(get(event, 'author', {}));
    this.assessmentIdList = get(event, 'assessmentIdList', []);
    this.assessments = get(event, 'assessments', []).map((assessment: any) => new AssessmentInfoPreview(assessment));
    this.description = get(event, 'description', '');
    this.endDate = get(event, 'endDate', moment().format(DATE_FORMAT.YYYY_MM_DD));
    this.endOfRepeatDate = defaultTo(get(event, 'parentEvent.endOfRepeatDate', ''), '');
    this.endOfRepeatQuantity = defaultTo(get(event, 'parentEvent.endOfRepeatQuantity', 1), 1);
    this.endOfRepeatType = get(event, 'parentEvent.endOfRepeatType', 'REPEAT_QUANTITY');
    const endTime = `${moment().endOf('day').format(DATE_FORMAT.HH_mm)}:00`;
    this.endTime = defaultTo(get(event, 'endTime', endTime), endTime);
    this.eventType = get(event, 'eventType', new EventTypeInfo());
    this.eventTypeName = get(event, 'eventTypeName', '-');
    this.locations = get(event, 'locations', []);
    this.locationsIds = get(event, 'locationsIds', []);
    this.monthRepeatType = get(event, 'parentEvent.monthRepeatType', null);
    this.participants = get(event, 'participants', []).map((user: any) => new UserInfo(user));
    this.participantsIds = get(event, 'participantsIds', []);
    this.excludedParticipants = get(event, 'excludedParticipants', []).map((user: any) => new UserInfo(user));
    this.excludedParticipantsIds = get(event, 'excludedParticipantsIds', []);
    this.reminders = get(event, 'reminders', []);
    this.repeatDays = defaultTo(get(event, 'parentEvent.repeatDays', []), []);
    this.repeatInterval = defaultTo(get(event, 'parentEvent.repeatInterval', 1), 1);
    this.showAbsences = get(event, 'showAbsences', eventType?.absencesSettings.absencesEnabled);
    this.showAddress = get(event, 'showAddress', eventType?.addressEnabled);
    this.showAssessments = get(event, 'showAssessments', eventType?.assessmentsEnabled);
    this.showEndDate = get(event, 'showEndDate', eventType?.dateRangesEnabled);
    this.showEventParticipants = get(event, 'showEventParticipants', eventType?.eventParticipantsEnabled);
    this.showExcludedParticipants = get(event, 'showExcludedParticipants', eventType?.excludedParticipantsEnabled);
    this.showLocations = get(event, 'showLocations', eventType?.officesEnabled);
    this.showQuestionnaires = get(event, 'showQuestionnaires', eventType?.questionnairesEnabled);
    this.showReminder = get(event, 'showReminder', eventType?.reminderEnabled);
    this.showTargetEmployee = get(event, 'showTargetEmployee', eventType?.targetEmployeesEnabled);
    this.showTime = get(event, 'showTime', eventType?.timeEnabled);
    this.showTitle = get(event, 'showTitle', eventType?.titleEnabled);
    this.extraWorkingDaysEnabled = get(event, 'extraWorkingDaysEnabled', eventType?.extraWorkingDaysEnabled);
    this.finalDecisionOptionsEnabled = get(
      event,
      'finalDecisionOptionsEnabled',
      eventType?.absencesSettings.finalDecisionOptionsEnabled,
    );
    this.optionForExcludeDaysOfAbsenceFromOtherTypes = get(
      event,
      'optionForExcludeDaysOfAbsenceFromOtherTypes',
      eventType?.absencesSettings.optionForExcludeDaysOfAbsenceFromOtherTypes,
    );
    this.requiresApprovalEnabled = get(
      event,
      'requiresApprovalEnabled',
      eventType?.absencesSettings.requiresApproval.requiresApprovalEnabled,
    );
    this.requiresWorkingOff = get(event, 'requiresWorkingOff', eventType?.absencesSettings.requiresWorkingOff);
    this.showRepeater = get(event, 'showRepeater', eventType?.repeaterEnabled);
    this.showTotalCounterInProfile = get(
      event,
      'showTotalCounterInProfile',
      eventType?.absencesSettings.showTotalCounterInProfile,
    );
    this.parentEvent = get(event, 'parentEvent', null);
    this.targetEmployee = get(event, 'targetEmployee', null) ? new UserInfo(get(event, 'targetEmployee')) : null;
    this.title = defaultTo(get(event, 'title', ''), '');
    this.unitOfRepeat = get(event, 'parentEvent.unitOfRepeat', null);
    this.polls = defaultTo(
      get(event, 'polls', []).map((poll: any) => new NewPoll(poll)),
      [],
    );
    this.pollIds = defaultTo(get(event, 'pollIds', []), []);
    this.isPublic = get(event, 'isPublic', false);
  }

  get datePeriod() {
    return getDateRange(
      this.showTime && !this.allDay ? this.localStartDate : this.startDate,
      this.showTime && !this.allDay ? this.localEndDate : this.endDate,
    );
  }

  get showEventTime() {
    return this.eventType.timeEnabled && !this?.allDay;
  }

  get eventTime() {
    const localizeTime = this.showTime && !this.allDay;
    return `${getLocalizedTime(
      `${localizeTime ? this.localStartDate : this.startDate} ${localizeTime ? this.localStartTime : this.startTime}`,
    )} - ${getLocalizedTime(
      `${localizeTime ? this.localEndDate : this.endDate} ${localizeTime ? this.localEndTime : this.endTime}`,
    )}`;
  }

  get eventTitle() {
    return Boolean(this.title) ? this.title : this.eventType.name;
  }

  get localStartDate() {
    return this.showTime && !this.allDay ? getLocalDate(this.startDate, this.startTime)[0] : this.startDate;
  }

  get localStartTime() {
    return this.showTime && !this.allDay ? getLocalDate(this.startDate, this.startTime)[1] : this.startTime;
  }

  get localEndDate() {
    return this.showTime && !this.allDay ? getLocalDate(this.endDate, this.endTime)[0] : this.endDate;
  }

  get localEndTime() {
    return this.showTime && !this.allDay ? getLocalDate(this.endDate, this.endTime)[1] : this.endTime;
  }
}

export class CommentInfo {
  id: string;
  author: UserPreviewInfo;
  createdDate: string;
  lastModifiedDate: string;
  message: string;
  commentPage: number;

  constructor(commentPage: number, comment?: unknown, author?: unknown) {
    this.id = get(comment, 'id', '');
    this.author = new UserPreviewInfo(get(comment, 'author', new UserPreviewInfo(author)));
    this.createdDate = get(comment, 'createdDate', '');
    this.lastModifiedDate = get(comment, 'lastModifiedDate', '');
    this.message = get(comment, 'message', '');
    this.commentPage = commentPage;
  }

  get commentDate() {
    const { commentMoment, commentPublished } = getCommentDate(this.createdDate);

    switch (commentPublished) {
      case COMMENT_PUBLISHED.TODAY: {
        return commentMoment.local().format('LT');
      }
      case COMMENT_PUBLISHED.YESTERDAY: {
        return commentMoment.local().format('[Yest] LT');
      }
      case COMMENT_PUBLISHED.THIS_YEAR: {
        return commentMoment.local().format('MMM D, LT');
      }
      default: {
        return commentMoment.local().format('YYYY MMM D, LT');
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isCommentEdit(replyToOriginalLastModifiedDate?: string) {
    return !moment(this.createdDate).isSame(moment(this.lastModifiedDate));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userAbleToManageComment(userInfo?: UserInfo, userPolicies?: UserPolicy[]) {
    return { ableToEditComment: true, ableToDeleteComment: true, ableToReplyComment: true };
  }
}

export class EventCommentInfo extends CommentInfo {
  isSecret: boolean;

  constructor(isSecret: boolean, commentPage: number, eventComment?: unknown, author?: unknown) {
    super(commentPage, eventComment, author);
    this.isSecret = get(eventComment, 'isSecret', isSecret);
  }

  userAbleToManageComment(userInfo: UserInfo, userPolicies: UserPolicy[]) {
    const isAuthor = userInfo.id === this.author.id;
    const ableToReplyComment = checkPolicies([this.isSecret ? UPDATE_SECRET_COMMENT : UPDATE_COMMENT], userPolicies);
    const ableToEditComment = isAuthor && ableToReplyComment;
    const ableToDeleteComment =
      ableToEditComment || checkPolicies([this.isSecret ? DELETE_SECRET_COMMENT : DELETE_COMMENT], userPolicies);
    return { ableToEditComment, ableToDeleteComment, ableToReplyComment };
  }
}

export enum ENotificationGroup {
  ALL = 'ALL',
  ONLY_NEW = 'ONLY_NEW',
}

export type AbsenceManualInfo = {
  daysLeftInThisYearManual: number;
  eventType: string;
  eventTypeId: string;
  id: string;
  modifiedBy: string;
  user: UserInfo | null;
  year: string;
  open: boolean;
};

export type EventTypeAbsenceInfos = {
  absenceManualInfoThisYear: AbsenceManualInfo;
  daysAvailableBasedOnDaysWorked: number;
  daysAvailableInThisYear: number;
  daysLeftFromPreviousYear: number;
  daysLeftInThisYearAuto: number;
  daysUsedInThisYear: number;
  eventType: string;
  user: UserInfo;
};

export class DaysUsedLimitItem {
  eventTypeAbsenceInfos: EventTypeAbsenceInfos[];
  user: UserInfo;

  constructor(item: unknown) {
    this.user = new UserInfo(get(item, 'user', new UserInfo(item)));
    this.eventTypeAbsenceInfos = get(item, 'eventTypeAbsenceInfos', '').map((item: EventTypeAbsenceInfos) => ({
      ...item,
      user: { id: this.user.id },
    }));
  }
}

export class EventPreviewInfo {
  id: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  allDay: boolean;
  eventTypeName: string;
  isUserHaveAccess: boolean;
  title: string;
  targetEmployee: UserPreviewInfo;
  absencesApprove: boolean;
  absencesFinalDecision: boolean;
  extraWorkingDaysEnabled: boolean;
  finalDecisionOptionsEnabled: boolean;
  participants: UserPreviewInfo[];
  requiresApprovalEnabled: boolean;
  requiresWorkingOff: boolean;
  author: UserPreviewInfo;
  eventTypeId: string;
  eventTypeColor: string;
  locations: {
    id: string;
    name: string;
    isDeleted: boolean;
  }[];
  absencesEnabled: boolean;
  isPublic: boolean;

  constructor(eventPreview?: unknown) {
    this.id = get(eventPreview, 'id', '');
    this.startDate = get(eventPreview, 'startDate', '');
    this.startTime = get(eventPreview, 'startTime', '');
    this.endDate = get(eventPreview, 'endDate', '');
    this.endTime = get(eventPreview, 'endTime', '');
    this.allDay = get(eventPreview, 'allDay', false);
    this.eventTypeName = get(eventPreview, 'eventTypeName', '') || get(eventPreview, 'eventType.name', '');
    this.isUserHaveAccess = get(eventPreview, 'isUserHaveAccess', false);
    this.title = get(eventPreview, 'title', '');
    this.targetEmployee = new UserPreviewInfo(get(eventPreview, 'targetEmployee', {}));
    this.absencesApprove = get(eventPreview, 'absencesApprove', false) || get(eventPreview, 'absences.approve', '');
    this.absencesFinalDecision =
      get(eventPreview, 'absencesFinalDecision', false) || get(eventPreview, 'absences.finalDecision', false);
    this.extraWorkingDaysEnabled = get(eventPreview, 'extraWorkingDaysEnabled', false);
    this.finalDecisionOptionsEnabled = get(eventPreview, 'finalDecisionOptionsEnabled', false);
    this.participants = defaultTo(get(eventPreview, 'participants', []), []).map(
      (user: any) => new UserPreviewInfo(user),
    );
    this.requiresApprovalEnabled = get(eventPreview, 'requiresApprovalEnabled', false);
    this.requiresWorkingOff = get(eventPreview, 'requiresWorkingOff', false);
    this.author = new UserPreviewInfo(get(eventPreview, 'author', {}));
    this.eventTypeId = get(eventPreview, 'eventTypeId', '') || get(eventPreview, 'eventType.id');
    this.eventTypeColor = get(eventPreview, 'eventTypeColor', '') || get(eventPreview, 'eventType.color');
    this.locations = defaultTo(get(eventPreview, 'locations', []), []);
    this.absencesEnabled = get(eventPreview, 'absencesEnabled', false);
    this.isPublic = get(eventPreview, 'isPublic', false);
  }

  get eventOptions() {
    const options = [];
    if (this.finalDecisionOptionsEnabled && this.absencesFinalDecision) {
      options.push('Final decision');
    }
    if (this.requiresApprovalEnabled) {
      options.push(this.absencesApprove ? 'Approved' : 'Not approved');
    }
    if (this.requiresWorkingOff) {
      options.push('Requires Working Off');
    }

    if (this.extraWorkingDaysEnabled) {
      options.push('Extra Working Days');
    }
    return options;
  }

  get isOneDayEvent() {
    return moment(this.localStartDate).isSame(this.localEndDate);
  }

  get showTime() {
    return this.startTime && this.endTime;
  }

  get calendarTitle() {
    return `${this.eventTitle}${
      this.requiresApprovalEnabled ? `, ${this.absencesApprove ? 'Approved' : 'Not approved'}` : ''
    }`.trim();
  }

  get holidaysDates() {
    return getDateRange(this.startDate, this.endDate, DATE_FORMAT.MMM_DD);
  }

  get datePeriod() {
    const showLocalizeDate = this.startTime && this.endTime && !this.allDay;
    return getDateRange(
      showLocalizeDate ? this.localStartDate : this.startDate,
      showLocalizeDate ? this.localEndDate : this.endDate,
    );
  }

  get localStartDate() {
    return this.startTime && !this.allDay ? getLocalDate(this.startDate, this.startTime)[0] : this.startDate;
  }

  get localStartTime() {
    return this.startTime && !this.allDay ? getLocalDate(this.startDate, this.startTime)[1] : this.startTime;
  }

  get localEndDate() {
    return this.endTime && !this.allDay ? getLocalDate(this.endDate, this.endTime)[0] : this.endDate;
  }

  get localEndTime() {
    return this.endTime && !this.allDay ? getLocalDate(this.endDate, this.endTime)[1] : this.endTime;
  }

  get eventTime() {
    return `${getLocalizedTime(`${this.localStartDate} ${this.localStartTime}`)} - ${getLocalizedTime(
      `${this.localEndDate} ${this.localEndTime}`,
    )}`;
  }

  get eventTableLinkTitle() {
    return this.title || this.eventTypeName;
  }

  get eventTitle() {
    return Boolean(this.title) ? this.title : this.eventTypeName;
  }

  get eventPreviewTitle() {
    const formatedTitle = this.eventTitle.slice(0, 30);
    const dates = getDateRange(this.localStartDate, this.localEndDate, DATE_FORMAT.YYYY_MM_DD_DOT);
    const formatPreviewTitle = [
      `${formatedTitle}${formatedTitle.length !== this.eventTitle.length ? '...' : ''}`,
      dates,
      ...(this.startTime && this.endTime && !this.allDay ? [this.eventTime] : []),
      ...(this.allDay ? ['All day'] : []),
    ];
    return formatPreviewTitle.join(', ');
  }
}
