import Modal from '../../Modal';
import Button from '../../Button';
import React, { useCallback } from 'react';
import { CompetenceType } from '../../../pages/Competencies/Competencies';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';

type ModalDeleteCompetenceProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onDeleteRequest: (data: any) => void;
  competenceData: CompetenceType;
  competenceError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  callback?: () => void;
  resetErrors: () => void;
};

function ModalDeleteCompetence({
  isOpen,
  onCloseRequest,
  onDeleteRequest,
  competenceData,
  competenceError,
  isLoading,
  callback,
  resetErrors,
}: ModalDeleteCompetenceProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetErrors();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.deleteModalWithItemNameTitle, {
        name: competenceData?.name,
      })}
      size={'small'}
      classNameModal="center"
    >
      <ErrorMessage>{competenceError}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            onDeleteRequest({ data: competenceData.id, callback: callback ? callback : resetAndExit });
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteCompetence;
