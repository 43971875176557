import { defineMessages } from 'react-intl';
import { newButton, editButton, deleteButton, nameColumn } from '../../i18n/defaultMessage';

export default defineMessages({
  deleteButton,
  editButton,
  newButton,
  nameColumn,
  pageTitle: {
    id: 'libraries.company.positions.title.company.positions',
    defaultMessage: 'Company Positions',
  },
  gredeColumn: {
    id: 'libraries.company.positions.column.grade',
    defaultMessage: 'Grade',
  },
});
