import React, { useMemo } from 'react';
import { Resume } from '../../../enums/resumes.enum';
import Select from '../../Select';
import Icon from '../../Icon';
import TextArea from '../../TextArea';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { OptionTypeBase } from 'react-select';
import { ProjectInfoType } from '../../../types/libraries';

type ProjectsProps = {
  resume: Resume;
  projectsList: ProjectInfoType[];
  handleChangeProject: (value: OptionTypeBase, index: number) => void;
  removeProject: (index: number) => void;
  addNewProject: () => void;
  isDisabledEditProjects?: boolean;
  handleChangeProjectDescription?: (value: unknown, index: number) => void;
};

const Projects = ({
  resume,
  projectsList,
  handleChangeProject,
  removeProject,
  addNewProject,
  isDisabledEditProjects = false,
  handleChangeProjectDescription,
}: ProjectsProps) => {
  const intl = useIntl();

  const projectsOptions = useMemo(
    () => projectsList?.map(({ id, name, description }) => ({ label: name, value: id, description })),
    [projectsList],
  );

  return (
    <div className={'form__edit-cv-tab-content form__edit-cv-tab-content--about active'}>
      {resume.projects.map((item: any, index: number) => (
        <div className="form__inputs-subwrapper language_block" key={index}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block ">
              <Select
                label={intl.formatMessage(messages.projectNameLabel)}
                name={`projects[${index}].name`}
                options={projectsOptions}
                value={{ value: resume.projects[index].id, label: resume.projects[index].name }}
                handleChange={data => handleChangeProject(data, index)}
                isSearchable={true}
                externalClass="project_select"
                isCreatable={!isDisabledEditProjects}
              />
            </div>
            <div className="form__input-block">
              <TextArea
                id={`description${index}`}
                defaultValue={resume.projects[index].description}
                label={intl.formatMessage(messages.descriptionLabel)}
                onChange={data => handleChangeProjectDescription && handleChangeProjectDescription(data, index)}
                readOnly={isDisabledEditProjects}
              />
            </div>
          </div>
          <button
            className="form__btn-clean-inputs remove_language"
            type={'button'}
            onClick={() => removeProject(index)}
          >
            <Icon iconName={'cross'} />
          </button>
        </div>
      ))}
      <div className="form__input-block add_button_block">
        <Button type="button" externalClass="form__btn-add-group" color="gray" onClick={addNewProject}>
          <Icon iconName="plus" externalClass="form__icon-btn-add" />
          <FormattedMessage {...messages.projectButton} />
        </Button>
      </div>
    </div>
  );
};

export default Projects;
