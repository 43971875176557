import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  newCurrencyTitle: {
    id: 'finance.currencies.modal.new.currency.title.new.currency',
    defaultMessage: 'New Currency',
  },
  editCurrencyTitle: {
    id: 'finance.currencies.modal.edit.currency.title.edit.currency',
    defaultMessage: 'Edit Currency',
  },
  baseCurrencylabel: {
    id: 'finance.currencies.modal.edit.currency.label.base.currency',
    defaultMessage: 'Base Currency',
  },
  note: {
    id: 'finance.currencies.modal.edit.currency.note.base.note.only.one.currency.can.be.set.as.base',
    defaultMessage:
      'Note, only one Currency can be set as a Base. Changing Base Currency may affect current transactions report.',
  },
});
