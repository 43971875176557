import React from 'react';
import { UserPreviewInfo } from '../../../enums/users.enum';
import Label from '../../../components/Label';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

type InfoItemProps = {
  date: string;
  user: UserPreviewInfo;
  isCreated?: boolean;
};

function InfoItem({ date, user, isCreated = true }: InfoItemProps) {
  return (
    <div className="data-wrapper">
      <div className="data-title">
        <Label type="text">
          {isCreated ? (
            <FormattedMessage {...messages.createdColumn} />
          ) : (
            <FormattedMessage {...messages.updatedColumn} />
          )}
        </Label>
      </div>
      <div>
        <span>{moment(date).format(DATE_FORMAT.ll)}</span>
        <span> ⋅ </span>
        <span>{user.fullName}</span>
      </div>
    </div>
  );
}

export default InfoItem;
