import React, { useCallback, useContext, useRef, useState, useMemo, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import ReactSelect, { components } from 'react-select';
import classNames from 'classnames';
import Icon from '../Icon';
import MessengerIcon from '../Profile/MessengerIcon';
import BrandingContext from '../../BrandingContext';
import ColorContext from '../../ColorContext';
import ErrorMessage from '../ErrorMessage';
import { useClickOutsideHandler } from '../../utils/hooks.utils';
import Avatar from '../Profile/Avatar';
import { isEmpty } from 'lodash-es';
import { mergeRefs } from '../../utils';
import { OptionTypeBase } from 'react-select/src/types';

const iconComponentsMap = { Icon, MessengerIcon };
const selectComponentsMap = { ReactSelect, CreatableSelect };

export type CreatableOptionTypeBase = {
  label: string;
  value: any;
  __isNew__?: boolean;
};

type SelectProps = {
  selectRef?: any;
  id?: string;
  name?: string;
  label?: string;
  control?: (val: OptionTypeBase) => React.ReactElement;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  externalClass?: string;
  options?: OptionTypeBase[];
  value?: OptionTypeBase | OptionTypeBase[] | null;
  defaultValue?: OptionTypeBase | OptionTypeBase[];
  handleChange?: (val: any, name?: string) => void;
  isMulti?: boolean;
  isGrouped?: boolean;
  isSearchable?: boolean;
  hasError?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  iconName?: string;
  iconComponent?: 'Icon' | 'MessengerIcon';
  upward?: boolean;
  isDisabled?: boolean;
  portalTarget?: HTMLElement | null;
  isCreatable?: boolean;
  isSelectWithAvatar?: boolean;
  externalMenuClass?: string;
  isClearable?: boolean;
  formatOptionLabel?: (el: any) => React.ReactNode;
  formatCreateLabel?: (el: any) => React.ReactNode;
  getOptionLabel?: (el: any) => string;
  customInput?: React.ReactNode;
  customInputRef?: any;
  onOpenMenu?: () => void;
  onCloseMenu?: () => void;
};

function Select({
  selectRef,
  customInput,
  customInputRef,
  options,
  value,
  defaultValue,
  label,
  name,
  control,
  handleChange,
  formatOptionLabel,
  formatCreateLabel,
  getOptionLabel,
  isMulti = false,
  isSearchable = true,
  isGrouped = false,
  isSelectWithAvatar = false,
  hasError,
  errorMessage,
  iconName,
  iconComponent = 'Icon',
  externalClass,
  upward,
  isDisabled,
  portalTarget = document.body,
  externalMenuClass,
  isCreatable = false,
  isClearable = false,
  isLoading = false,
  onOpenMenu,
  onCloseMenu,
}: SelectProps) {
  const ref = useRef<any>(null);
  const searchRef = useRef<any>(null);
  const branding = useContext(BrandingContext);
  const color = useContext(ColorContext);
  const defaultClass = classNames('select', { isMulti }, externalClass);
  const selectValueContainerClass = classNames({
    'select__value-container--searchable': isSearchable,
    'select__value-container--with-icon': iconName,
    'select__value-container--with-avatar': isSelectWithAvatar,
    'select__value-container--clearable': isClearable,
  });
  const IconComponent = iconComponentsMap[iconComponent];
  const SelectComponent = selectComponentsMap[isCreatable ? 'CreatableSelect' : 'ReactSelect'];

  const filterOptionCheck = useCallback(
    (option: OptionTypeBase, inputValue: string) =>
      (option.label.toString().match(new RegExp(inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'ig')) || [])
        .length > 0,
    [],
  );

  const filterOption = useCallback(
    (option: OptionTypeBase, string: any) => {
      // default search
      if (filterOptionCheck(option, string)) return true;

      // check if a group as the filter string as label
      const groupOptions =
        options?.filter(group => {
          return filterOptionCheck(group, string);
        }) || [];

      if (!isEmpty(groupOptions)) {
        for (const groupOption of groupOptions) {
          // Check if current option is in group
          if (groupOption.options.some((opt: any) => opt.value === option.value)) {
            return true;
          }
        }
      }
      return false;
    },
    [options],
  );

  const formatEmployeeOptionLabel = useCallback(({ value, label }: any) => {
    return (
      <div className="table__data-wrapper">
        <Avatar userInfo={value} size="tiny" externalClass="avatar-select" fileSize={36} />
        <span>{label}</span>
      </div>
    );
  }, []);

  const selectOptions = useMemo(() => {
    return isClearable && options ? [{ label, value: null, isClearable }, ...options] : options;
  }, [options]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutsideHandler(() => setMenuIsOpen(false), { current: ref.current?.select?.menuListRef }, wrapperRef);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (menuIsOpen) {
      onOpenMenu && onOpenMenu();
    } else {
      onCloseMenu && onCloseMenu();
    }
  }, [menuIsOpen]);

  useEffect(() => {
    setInputValue('');
    const input = searchRef.current;
    if (menuIsOpen) {
      input?.focus();
      const selectedItem = ref.current?.select?.menuListRef?.querySelector('.select__option--is-selected');
      selectedItem && selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      customInputRef && customInputRef.current?.focus();
    } else {
      input?.blur();
    }
  }, [menuIsOpen]);

  useEffect(() => {
    if (menuIsOpen) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      // if any scroll is attempted, set this to the previous value
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    }
    return () => {
      window.onscroll = () => null;
    };
  }, [menuIsOpen]);

  const selectComponents = useMemo(
    () => ({
      IndicatorSeparator: null,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ClearIndicator: ({ children, ...props }: { children: any; props: any }) => (
        // @ts-ignore
        <components.ClearIndicator {...props}>
          <Icon iconName="cross" />
        </components.ClearIndicator>
      ),
      DropdownIndicator: () => <Icon iconName="select-arrow" externalClass={'modal__options-icon'} />,
      ValueContainer: ({ children, ...props }: { children: any; props: any }) => (
        // @ts-ignore
        <components.ValueContainer className={selectValueContainerClass} {...props}>
          {iconName && <IconComponent iconName={iconName} externalClass="select__value-container-icon" />}
          {/*@ts-ignore*/}
          <components.Placeholder className={classNames({ '--error': hasError })} {...props}>
            {label}
          </components.Placeholder>
          {children}
        </components.ValueContainer>
      ),
      MultiValueRemove: (props: any) => (
        <div className="select-value-remove-wrapper">
          <components.MultiValueRemove {...props}>
            <Icon iconName="times-circle" externalClass={'modal__options-icon'} />
          </components.MultiValueRemove>
        </div>
      ),
      SelectContainer: ({ children, ...props }: { children: any; props: any }) => (
        //@ts-ignore
        <components.SelectContainer {...props}>
          {children}
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </components.SelectContainer>
      ),
      Menu: (props: any) => {
        return (
          <div
            style={{
              //@ts-ignore
              '--global-accent-color': branding?.globalAccents,
              '--body-font': branding?.body,
              '--global-text-color': color,
            }}
          >
            <components.Menu className={classNames({ 'select__menu--top': upward }, externalMenuClass)} {...props} />
          </div>
        );
      },
      Control: ({ children, ...props }: any) => {
        return (
          <div
            onClick={(e: React.MouseEvent) => {
              const btn = e.target as HTMLElement;
              const nodeName = btn.nodeName;
              if (
                (nodeName === 'use' && btn.parentElement?.classList.contains('select-arrow')) ||
                (nodeName === 'svg' && btn.classList.contains('select-arrow')) ||
                (nodeName !== 'svg' && nodeName !== 'use')
              ) {
                setMenuIsOpen(prev => {
                  if (customInput && nodeName === 'INPUT' && prev) {
                    return true;
                  } else {
                    return !prev;
                  }
                });
              }
            }}
          >
            <components.Control {...props}>{control ? control(props.selectProps.value) : children}</components.Control>
          </div>
        );
      },
      Input: (props: any) =>
        customInput ? (
          customInput
        ) : (
          <input type="text" value={props.value} onChange={e => setInputValue(e.currentTarget.value)} ref={searchRef} />
        ),
    }),
    [selectValueContainerClass, branding, color, externalMenuClass, upward, hasError, errorMessage, control],
  );

  const onSelectInputChange = useCallback(
    value => {
      setInputValue('');
      handleChange && handleChange(value, name);
      !isMulti && setMenuIsOpen(false);
    },
    [handleChange, isMulti],
  );

  const getOptionsIsDisabled = useCallback(option => {
    return option?.isDisabled || false;
  }, []);

  return (
    <>
      {menuIsOpen && (
        <div
          onClick={() => {
            setMenuIsOpen(false);
          }}
          className="select__scroll-manager"
        ></div>
      )}
      <div ref={wrapperRef} className={defaultClass}>
        {/* @ts-ignore */}
        <SelectComponent
          ref={mergeRefs(ref, selectRef)}
          classNamePrefix="select"
          blurInputOnSelect={!isMulti}
          isMulti={isMulti}
          isLoading={isLoading}
          isSearchable={isSearchable && (isCreatable || (options && (options.length > 4 || isGrouped)))}
          isCreatable={isCreatable}
          closeMenuOnSelect={!isMulti}
          menuShouldScrollIntoView={false}
          placeholder={null}
          options={selectOptions}
          value={value}
          formatOptionLabel={isSelectWithAvatar ? formatEmployeeOptionLabel : formatOptionLabel}
          formatCreateLabel={formatCreateLabel}
          getOptionLabel={getOptionLabel}
          defaultValue={defaultValue}
          filterOption={isGrouped ? filterOption : filterOptionCheck}
          isOptionDisabled={getOptionsIsDisabled}
          onChange={(value: any) => onSelectInputChange(value)}
          isDisabled={isDisabled}
          menuPortalTarget={portalTarget}
          menuIsOpen={menuIsOpen}
          menuPlacement="auto"
          inputValue={inputValue}
          //@ts-ignore
          components={selectComponents}
        />
      </div>
    </>
  );
}

export default React.memo(Select);
