import React, { useImperativeHandle, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { CommentInfo } from '../../enums/schedule.enum';
import { UserInfo } from '../../enums/users.enum';
import Input from '../Input';
import Avatar from '../Profile/Avatar';
import messages from './messages';
import Icon from '../Icon';
import { useFormik } from 'formik';
import Comment from './Comment';
import { CandidateComment } from '../../enums/candidates.enums';

type CommentFormProps<CommentT> = {
  formRef: any;
  scrollRef: any;
  initialComment: CommentT;
  commentClicked: CommentT & Partial<{ replyTo: any; replyToOriginalMessage: string; isDeleted: boolean }>;
  userInfo: UserInfo;
  postMode: boolean;
  replyMode: boolean;
  isDisabledBtn?: boolean;
  setPostMode: (isPost: boolean) => void;
  setReplyMode: (isReplay: boolean) => void;
  createComment: (data: { data: CommentT; replyToCommentId?: string; cb: () => void }) => void;
  editComment: (data: { data: CommentT; cb: () => void }) => void;
  setCommentClicked: (comment: CommentT) => void;
};

function CommentForm<CommentT extends CommentInfo | CandidateComment>({
  formRef,
  scrollRef,
  initialComment,
  commentClicked,
  userInfo,
  postMode,
  replyMode,
  isDisabledBtn,
  setPostMode,
  setReplyMode,
  setCommentClicked,
  createComment,
  editComment,
}: CommentFormProps<CommentT>) {
  const textareaRef = useRef<any>(null);
  const { values, handleSubmit, handleChange, resetForm, setValues } = useFormik({
    initialValues: initialComment,
    enableReinitialize: true,
    onSubmit: data => handleCommentManage(data),
  });
  const intl = useIntl();
  useImperativeHandle(formRef, () => ({
    setValues,
    handleTextareaFocus,
    resetForm,
  }));

  const handleCommentManage = (data: CommentT) => {
    if (postMode) {
      createComment({
        data,
        replyToCommentId: replyMode ? commentClicked.id : '',
        cb: () => {
          resetForm();
          replyMode && setReplyMode(false);
          scrollRef.current.el.scrollTop = 0;
        },
      });
    } else {
      handleEditRequest(data);
    }
  };

  const handleTextareaFocus = () => {
    const elem: HTMLElement = textareaRef.current;
    const rect = elem.getBoundingClientRect();
    const top = elem.getBoundingClientRect().top;
    const scrollY = window.scrollY;
    elem.focus();
    top - rect.height < 0 && window.scroll({ top: rect.top + scrollY - (rect.height + 20) });
  };

  const handleEditRequest = (data: CommentT) => {
    if (data.message === commentClicked.message) {
      handleCancelManage();
    } else {
      editComment({
        data,
        cb: handleCancelManage,
      });
    }
  };

  const handleCancelManage = () => {
    handleTextareaFocus();
    setCommentClicked(initialComment);
    setPostMode(true);
    setReplyMode(false);
    resetForm();
  };

  const replyControl = useMemo(
    () => (
      <span className="page__event__send-message-cancel-btn cancel-replayed" onClick={handleCancelManage}>
        <Icon iconName="cross" externalClass="page__event__send-message-cancel" />
      </span>
    ),
    [],
  );

  return (
    <form className="page__event__comment-form" onSubmit={handleSubmit}>
      <div className="page__event__comment-form__user">
        {userInfo && (
          <Avatar
            userInfo={userInfo}
            externalClass="page__event__comment-author-avatar"
            size="medium"
            fileSize={72}
            isUserPageLink
          />
        )}
      </div>
      <div className={classNames('page__event__send-message-wrapper', { edit: !postMode })}>
        {replyMode && (
          <div className="reply-message-wrapper">
            <Comment isReplayed isFormReplayedComment comment={commentClicked} control={replyControl} />
          </div>
        )}
        <div className="message__form-wrapper">
          <div className="form__input-block">
            <Input
              name="message"
              onChange={handleChange}
              externalClass="page__event__comment-form__textarea"
              placeholder={intl.formatMessage(messages.commentPlaceholder)}
              tag="textarea"
              type="submit"
              defaultValue={values.message}
              textareaRef={textareaRef}
            />
            {values.message.trim() && (
              <button type="submit" className="page__event__send-message-btn" disabled={isDisabledBtn}>
                <Icon iconName="send-message" externalClass="page__event__send-message" />
              </button>
            )}
          </div>
          {!postMode && (
            <div className="page__event__send-message-cancel-wrapper">
              <span className="page__event__send-message-cancel-btn" onClick={handleCancelManage}>
                <Icon iconName="cross" externalClass="page__event__send-message-cancel" />
              </span>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}

export default React.memo(CommentForm) as typeof CommentForm;
