import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/competencies.contsants';
import * as paramsTypes from '../enums/params/competencies.params';

export const getCompetenciesList = (): AnyAction => ({
  type: ActionTypes.GET_COMPETENCIES_LIST_REQUEST,
});

export const getAssessments = (): AnyAction => ({
  type: ActionTypes.GET_ASSESSMENTS_REQUEST,
});

export const getFilteredAssessments = (): AnyAction => ({
  type: ActionTypes.GET_FILTERED_ASSESSMENTS_REQUEST,
});

export const createNewCompetence = (data: any): AnyAction => ({
  type: ActionTypes.POST_COMPETENCE_REQUEST,
  payload: data,
});

export const setCompetenciesParams = (data: Partial<paramsTypes.CompetenciesParams>): AnyAction => ({
  type: ActionTypes.SET_COMPETENCIES_PARAMS,
  payload: data,
});

export const deleteCompetence = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_COMPETENCE_REQUEST,
  payload: data,
});

export const getCompetence = (data: any): AnyAction => ({
  type: ActionTypes.GET_COMPETENCE_REQUEST,
  payload: data,
});

export const cleanCompetenceData = (): AnyAction => ({
  type: ActionTypes.CLEAN_COMPETENCE_DATA,
});

export const editCompetence = (data: any): AnyAction => ({
  type: ActionTypes.PUT_COMPETENCE_REQUEST,
  payload: data,
});

export const getCompetenciesTypesList = (): AnyAction => ({
  type: ActionTypes.GET_COMPETENCIES_TYPES_LIST_REQUEST,
});

export const createNewCompetenciesType = (data: any): AnyAction => ({
  type: ActionTypes.POST_COMPETENCIES_TYPE_REQUEST,
  payload: data,
});

export const deleteCompetenciesType = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_COMPETENCIES_TYPE_REQUEST,
  payload: data,
});

export const editCompetenciesType = (data: any): AnyAction => ({
  type: ActionTypes.PUT_COMPETENCIES_TYPE_REQUEST,
  payload: data,
});

export const setCompetenciesTypesParams = (data: Partial<paramsTypes.CompetenciesTypesParams>): AnyAction => ({
  type: ActionTypes.SET_COMPETENCIES_TYPES_PARAMS,
  payload: data,
});

export const deleteAssessment = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_ASSESSMENT_REQUEST,
  payload: data,
});

export const createNewAssessment = (data: any): AnyAction => ({
  type: ActionTypes.POST_NEW_ASSESSMENT_REQUEST,
  payload: data,
});

export const editAssessment = (data: any): AnyAction => ({
  type: ActionTypes.PUT_ASSESSMENT_REQUEST,
  payload: data,
});

export const cleanAssessmentsData = (): AnyAction => ({
  type: ActionTypes.CLEAN_ASSESSMENT_DATA,
});

export const getAssessment = (data: any): AnyAction => ({
  type: ActionTypes.GET_ASSESSMENT_REQUEST,
  payload: data,
});

export const getLastAssessmentResults = (data: any): AnyAction => ({
  type: ActionTypes.GET_LAST_ASSESSMENT_RESULTS_REQUEST,
  payload: data,
});

export const cleanOneAssessmentData = (): AnyAction => ({
  type: ActionTypes.CLEAN_ONE_ASSESSMENT_DATA,
});

export const setAssessmentsParams = (data: Partial<paramsTypes.AssessmentsParams>): AnyAction => ({
  type: ActionTypes.SET_ASSESSMENTS_PARAMS_REQUEST,
  payload: data,
});

export const resetAssessments = (): AnyAction => ({
  type: ActionTypes.RESET_ASSESSMENTS,
});

export const createNewSkillQuestions = (data: any): AnyAction => ({
  type: ActionTypes.POST_SKILL_QUESTIONS_REQUEST,
  payload: data,
});

export const getSkillsQuestionsList = (): AnyAction => ({
  type: ActionTypes.GET_SKILLS_QUESTIONS_LIST_REQUEST,
});

export const getSkillQuestions = (data: any): AnyAction => ({
  type: ActionTypes.GET_SKILL_QUESTIONS_REQUEST,
  payload: data,
});
export const cleanSkillQuestionsData = (): AnyAction => ({
  type: ActionTypes.CLEAN_SKILL_QUESTIONS_DATA,
});

export const editSkillQuestions = (data: any): AnyAction => ({
  type: ActionTypes.PUT_SKILL_QUESTIONS_REQUEST,
  payload: data,
});

export const setSkillQuestionsParams = (data: Partial<paramsTypes.SkillsQuestionsParams>): AnyAction => ({
  type: ActionTypes.SET_SKILLS_QUESTIONS_PARAMS_REQUEST,
  payload: data,
});

export const resetErrors = (): AnyAction => ({
  type: ActionTypes.RESET_COMPETENCIES_ERRORS,
});

export const getCompetenciesPercentage = (userId: string): AnyAction => ({
  type: ActionTypes.GET_COMPETENCIES_PERCENTAGE_REQUEST,
  payload: userId,
});

export const getSpecializationsPercentage = (userId: string): AnyAction => ({
  type: ActionTypes.GET_SPECIALIZATIONS_PERCENTAGE_REQUEST,
  payload: userId,
});

export const getUserCompetenceAssessments = (data: { userId: string; competenceId: string }): AnyAction => ({
  type: ActionTypes.GET_USER_COMPETENCE_ASSESSMENTS_REQUEST,
  payload: data,
});

export const getLevelsPercentage = (userId: string, competenceId: string): AnyAction => ({
  type: ActionTypes.GET_COMPETENCE_LEVELS_PERCENTAGE_REQUEST,
  payload: { userId, competenceId },
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_COMPETENCIES_STATE,
});

export const resetLastAssessmentResults = (): AnyAction => ({
  type: ActionTypes.RESET_LAST_ASSESSMENT_RESULTS,
});
export const resetUserCompetenceAssessments = (): AnyAction => ({
  type: ActionTypes.RESET_USER_COMPETENCE_ASSESSMENTS,
});
