import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { EmployeeGroup, EMPLOYEE_GROUP_VALIDATION_SCHEMA } from '../../../enums/notifications.enum';
import { UserInfo, UserPreviewInfo } from '../../../enums/users.enum';
import Select from '../../../components/Select/index';
import Avatar from '../../Profile/Avatar';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { scrollToError } from '../../../utils';

type ModalEditEmployeeGroupProps = {
  employeeGroupData: EmployeeGroup;
  employeeGroupError: string | null | RejectValueErrors[];
  isLoading: boolean;
  isOpen: boolean;
  users: UserInfo[];
  onCloseRequest: () => void;
  editEmployeeGroup: (data: any) => void;
  resetErrors: () => void;
};

function ModalEditEmployeeGroup({
  employeeGroupData,
  employeeGroupError,
  isLoading,
  isOpen,
  users,
  onCloseRequest,
  editEmployeeGroup,
  resetErrors,
}: ModalEditEmployeeGroupProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError, resetForm } = useFormik({
    initialValues: new EmployeeGroup(employeeGroupData),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: EMPLOYEE_GROUP_VALIDATION_SCHEMA,
    onSubmit: data => {
      return editEmployeeGroup({ id: data.id, data, callback: resetAndExit });
    },
  });

  useSetFieldsErrors(employeeGroupError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const usersOptions = useMemo(() => {
    return users?.map((el: UserInfo) => ({
      value: el,
      label: el.fullName,
    }));
  }, [users, values.userIds]);

  const usersValues = useMemo(() => usersOptions.filter(({ value }) => values.userIds.some(id => id === value.id)), [
    values.userIds,
  ]);

  const handleUsersChange = useCallback(newValues => {
    const value = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue(
      'userIds',
      value.map((user: UserPreviewInfo) => user.id),
    );
    setFieldValue('users', value);
  }, []);

  const formatOptionLabel = useCallback(
    ({ value, label }: any) => (
      <div className="table__data-wrapper">
        <Avatar userInfo={value} size="tiny" externalClass="avatar-select" fileSize={36} />
        <span>{label}</span>
      </div>
    ),
    [],
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={resetAndExit} title={intl.formatMessage(messages.editMemberGroupTitle)}>
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              id={'name'}
              name={'name'}
              label={intl.formatMessage(messages.dispalyNameLabel)}
              defaultValue={values.name}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
            />
          </div>
          <div className="form__input-block">
            <Select
              isMulti
              isSearchable
              isSelectWithAvatar
              label={intl.formatMessage(messages.employeesLabel)}
              options={usersOptions}
              value={usersValues}
              handleChange={handleUsersChange}
              hasError={hasError('userIds')}
              formatOptionLabel={formatOptionLabel}
              //@ts-ignore
              errorMessage={errors?.userIds}
            />
          </div>
        </div>
        <ErrorMessage>{employeeGroupError}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} type={'button'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditEmployeeGroup;
