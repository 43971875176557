import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import Button from '../../Button';
import Modal from '../../Modal';
import { Recognition, RECOGNITION_VALIDATION_SCHEMA } from '../../../enums/recognitions.enum';
import Input from '../../Input';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { get } from 'lodash-es';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { RegonitionType } from '../../../types/recognitions/recognitions.type';
import { scrollToError } from '../../../utils';

type ModalNewRecognitionProps = {
  targetUserId: string;
  isOpen: boolean;
  onCloseRequest: () => void;
  recognitionCreated: RegonitionType | null;
  createNewRecognition: (data: any) => void;
  recognitionError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  authUserdata: { id: string };
  resetErrors: () => void;
};

function ModalNewUserRecognition({
  targetUserId,
  isOpen,
  onCloseRequest,
  recognitionCreated,
  createNewRecognition,
  recognitionError,
  isLoading,
  authUserdata,
  resetErrors,
}: ModalNewRecognitionProps) {
  const { setFieldValue, handleChange, handleSubmit, resetForm, setFieldError, errors, touched } = useFormik({
    initialValues: new Recognition(),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: RECOGNITION_VALIDATION_SCHEMA,
    onSubmit: data => {
      return createNewRecognition({ data, callback: resetAndExit });
    },
  });

  const intl = useIntl();

  useSetFieldsErrors(recognitionError, setFieldError);

  useEffect(() => {
    setFieldValue('targetUser', { id: targetUserId });
    setFieldValue('targetUserId', targetUserId);
    setFieldValue('recognitionTypeId', recognitionCreated?.id);
    setFieldValue('recognitionType', recognitionCreated);
    setFieldValue('author', { id: authUserdata.id });
    setFieldValue('authorId', authUserdata.id);
  }, [recognitionCreated]);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={resetAndExit} title={recognitionCreated?.name} size={'small'}>
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              name={'text'}
              tag={'textarea'}
              label={intl.formatMessage(messages.textInput)}
              onChange={handleChange}
              hasError={hasError('text')}
              errorMessage={errors?.text}
            />
          </div>
        </div>
        <ErrorMessage>{recognitionError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.submitButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewUserRecognition;
