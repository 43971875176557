import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/export.constants';

type InitialStateType = {
  exportData: any;
  progressEvent: ProgressEvent | null;
  exportInfo: {
    name: string;
    ext: ActionTypes.EFileExtensions | null;
  };
  isPendingRequests: boolean;
  error: null | string;
};

const initialState: InitialStateType = {
  exportData: null,
  progressEvent: null,
  exportInfo: {
    name: '',
    ext: null,
  },
  isPendingRequests: false,
  error: null,
};

const exportReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.CHANGE_EXPORT_PROGRESS:
      return {
        ...state,
        progressEvent: action.payload,
        isPendingRequests: false,
      };

    case ActionTypes.GET_EXPORTED_DATA_SUCCESS:
      return {
        ...state,
        exportData: action.payload,
      };

    case ActionTypes.GET_EXPORTED_DATA_FAILURE:
      return {
        ...initialState,
        error: action.payload,
      };

    case ActionTypes.CANCEL_EXPORT:
      return {
        ...state,
        error: action.payload,
      };

    case ActionTypes.SET_EXPORT_INFO:
      return {
        ...state,
        exportInfo: action.payload,
        isPendingRequests: true,
      };

    case ActionTypes.RESET_EXPORTED_DATA:
      return initialState;

    default:
      return state;
  }
};

export default exportReducer;
