import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import { OptionTypeBase } from 'react-select';
import Modal from '../../Modal';
import Input from '../../Input';
import { UserProfessionalInfo, USER_PROFESSIONAL_VALIDATION_SCHEMA, UserInfo } from '../../../enums/users.enum';
import Button from '../../Button';
import Select from '../../Select';
import Icon from '../../Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { find } from 'lodash-es';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { EmployeeGroup } from '../../../enums/notifications.enum';
import { useParams } from 'react-router';
import { TRouteParamId } from '../../../enums/common.enum';
import { scrollToError } from '../../../utils';

type ModalPersonalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: UserProfessionalInfo) => void;
  employeeGroupsList: EmployeeGroup[];
  professionalInfo: UserProfessionalInfo;
  hrCurators: UserInfo[];
  specializations: { id: string; name: string }[];
  loading?: boolean;
  requestError: string | RejectValueErrors[] | null;
};

function ModalProfessional({
  isOpen,
  onClose,
  onSubmit,
  professionalInfo,
  hrCurators,
  specializations,
  loading,
  requestError,
  employeeGroupsList,
}: ModalPersonalProps) {
  const { id } = useParams<TRouteParamId>();

  const { values, handleChange, setFieldValue, handleSubmit, setFieldError, errors, touched } = useFormik({
    initialValues: new UserProfessionalInfo(professionalInfo),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: USER_PROFESSIONAL_VALIDATION_SCHEMA,
    onSubmit: data => onSubmit(data),
  });

  const intl = useIntl();

  useSetFieldsErrors(requestError, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const getError = useCallback(path => get(errors, path), [errors]);

  const handleAddHrInfo = useCallback(
    () =>
      setFieldValue('hrInfo', [
        ...values.hrInfo,
        { dismissal: null, endTrialPeriod: null, hired: null, hrCuratorId: null, hrCurator: null },
      ]),
    [values],
  );

  const handleRemoveHrInfo = useCallback(
    i => () => {
      const newHrInfo = [...values.hrInfo];
      newHrInfo.splice(i, 1);
      setFieldValue('hrInfo', newHrInfo);
    },
    [values],
  );

  const handleAddHoursInfo = useCallback(
    () => setFieldValue('workHours', [...values.workHours, { hours: undefined, dateFrom: null }]),
    [values],
  );

  const handleRemoveHoursInfo = useCallback(
    i => () => {
      const newHoursInfo = [...values.workHours];
      newHoursInfo.splice(i, 1);
      setFieldValue('workHours', newHoursInfo);
    },
    [values],
  );

  const hrCuratorOptions = useMemo(
    () => hrCurators.filter(item => item.id !== id).map(curator => ({ label: curator.fullName, value: curator.id })),
    [hrCurators],
  );

  const hoursOptions = useMemo(() => Array.from({ length: 8 }, (_, i) => i + 1).map(v => ({ label: v, value: v })), []);

  const specializationOptions = useMemo(() => specializations.map(({ id, name }) => ({ label: name, value: id })), [
    specializations,
  ]);

  const employeeGroupsOptions = useMemo(() => employeeGroupsList?.map(({ id, name }) => ({ label: name, value: id })), [
    employeeGroupsList,
  ]);

  const hrCuratorValue = useCallback(
    hrCuratorId => (hrCuratorId ? hrCuratorOptions.find(({ value }) => value === hrCuratorId) : null),
    [hrCuratorOptions],
  );

  const prevExpValue = useMemo(() => professionalInfo.userPrevExpMask, [professionalInfo]);

  const hoursValue = useCallback(hours => hours && hoursOptions.find(({ value }) => value === hours), [hoursOptions]);

  const primarySpecValue = useMemo(
    () =>
      specializationOptions.find(
        ({ value }) => value === values.specializations.find(({ main }) => main)?.specializationDto.id,
      ) || null,
    [specializationOptions, values],
  );

  const otherSpecValue = useMemo(
    () =>
      values.specializations
        .filter(({ main }) => !main)
        .map(({ specializationDto }) => ({ label: specializationDto.name, value: specializationDto.id })),
    [values],
  );

  const handlePickCurator = useCallback(
    i => ({ value }: OptionTypeBase) => {
      const curator = new UserInfo(hrCurators.find(({ id }) => id === value));
      setFieldValue(`hrInfo[${i}].hrCuratorId`, curator.id);
    },
    [hrCurators],
  );

  const employeeGroupsValue = useMemo(
    () =>
      values.employeeGroups?.map((item: { id: string; name: string }) => ({
        label: `${find(employeeGroupsOptions, el => el.value === item.id)?.label}`,
        value: item.id,
      })),
    [values, employeeGroupsOptions],
  );

  const handlePrevExp = useCallback(e => {
    const value = e.target.value;
    const years = Number(value.substr(0, 2));
    const months = Number(value.substr(4, 2));
    const days = Number(value.substr(8, 2));
    setFieldValue('previousExperience', { years, months, days });
  }, []);

  const handleHours = useCallback(
    i => ({ value }: OptionTypeBase) => setFieldValue(`workHours[${i}].hours`, value),
    [],
  );

  const handlePrimarySpec = useCallback(
    ({ label, value }) => {
      if (value) {
        const otherSpec = values.specializations.filter(el => !el.main && el.specializationDto.id !== value);
        const newValue = { main: true, specializationDto: { id: value, name: label } };
        setFieldValue('specializations', [...otherSpec, newValue]);
      } else {
        const newSpecializations = [...values.specializations];
        newSpecializations.splice(
          values.specializations.findIndex(({ main }) => main),
          1,
        );
        setFieldValue('specializations', newSpecializations);
      }
    },
    [values],
  );

  const handleOtherSpec = useCallback(
    newValue => {
      const primarySpec = values.specializations.filter(
        el => el.main && newValue.every(({ value }: OptionTypeBase) => value !== el.specializationDto.id),
      );
      const otherSpec = newValue.map(({ label, value }: OptionTypeBase) => ({
        main: false,
        specializationDto: { id: value, name: label },
      }));
      setFieldValue('specializations', [...primarySpec, ...otherSpec]);
    },
    [values],
  );

  const handleEmployeeGroups = useCallback(
    value => {
      setFieldValue(
        'employeeGroups',
        value.map((item: any) => ({ name: item.label, id: item.value })),
      );
      setFieldValue(
        'employeeGroupIds',
        value.map((item: any) => item.value),
      );
    },
    [values],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={intl.formatMessage(messages.editProfessionalInfoTitle)}
      classNameModal="modal--edit-profile modal--edit-proffesional-info"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__grid-wrapper">
            <Input
              label={intl.formatMessage(messages.previousExperienceInput)}
              name="previousExperience"
              tag="InputMask"
              mask="99y 99m 99d"
              defaultValue={prevExpValue}
              onChange={handlePrevExp}
              hasError={hasError('previousExperience')}
              errorMessage={
                getError('previousExperience.years') ||
                getError('previousExperience.months') ||
                getError('previousExperience.days')
              }
            />
          </div>
          <div className="form__group">
            <div className={'tabs__modal__content-item__about-info-name'}>Experience in Company</div>
            {values.hrInfo.map(({ dismissal, endTrialPeriod, hired, hrCurator, hrCuratorId }, i) => (
              <div key={i.toString()} className="form__group-wrapper" style={{ marginBottom: '24px' }}>
                <div className="form__inputs-wrapper">
                  <div className="form__inputs-subwrapper">
                    <Input
                      type="date"
                      label={intl.formatMessage(messages.hiredInput)}
                      name={`hrInfo[${i}].hired`}
                      defaultValue={hired}
                      onChange={handleChange}
                      wrapperClass="form__input-block--third"
                      hasError={hasError(`hrInfo[${i}].hired`)}
                      errorMessage={getError(`hrInfo[${i}].hired`)}
                    />
                    <Input
                      type="date"
                      label={intl.formatMessage(messages.trialInput)}
                      name={`hrInfo[${i}].endTrialPeriod`}
                      defaultValue={endTrialPeriod}
                      onChange={handleChange}
                      wrapperClass="form__input-block--third"
                      hasError={hasError(`hrInfo[${i}].endTrialPeriod`)}
                      errorMessage={getError(`hrInfo[${i}].endTrialPeriod`)}
                    />
                    <Input
                      type="date"
                      label={intl.formatMessage(messages.dismissalInput)}
                      name={`hrInfo[${i}].dismissal`}
                      defaultValue={dismissal}
                      onChange={handleChange}
                      wrapperClass="form__input-block--third"
                      hasError={hasError(`hrInfo[${i}].dismissal`)}
                      errorMessage={getError(`hrInfo[${i}].dismissal`)}
                    />
                  </div>
                  <Select
                    label={intl.formatMessage(messages.talentCuratorSelect)}
                    options={hrCuratorOptions}
                    value={hrCuratorValue(hrCuratorId || hrCurator?.id)}
                    handleChange={handlePickCurator(i)}
                    externalClass="form__input-block--two-third"
                    hasError={hasError(`hrInfo[${i}].hrCuratorId`)}
                    errorMessage={getError(`hrInfo[${i}].hrCuratorId`)}
                    isClearable
                  />
                </div>
                <button type="button" className="form__btn-clean-inputs" onClick={handleRemoveHrInfo(i)}>
                  <Icon iconName="times" />
                </button>
              </div>
            ))}
            <div>
              <Button type="button" externalClass="form__btn-add-group" color="gray" onClick={handleAddHrInfo}>
                <Icon iconName="plus" externalClass="form__icon-btn-add" />
                <FormattedMessage {...messages.addHRInfoButton} />
              </Button>
            </div>
          </div>
          <div className="form__group" style={{ marginBottom: '30px' }}>
            <div className={'tabs__modal__content-item__about-info-name'}>Regulatory Hours</div>
            {values.workHours.map(({ hours, dateFrom }, i) => (
              <div key={i.toString()} className="form__group-wrapper" style={{ marginBottom: '24px' }}>
                <div className="form__inputs-wrapper ">
                  <div className="form__inputs-subwrapper align-start">
                    <Select
                      label={intl.formatMessage(messages.regulatoryHoursSelect)}
                      options={hoursOptions}
                      value={hoursValue(hours)}
                      handleChange={handleHours(i)}
                      externalClass="form__input-block--half"
                      hasError={hasError(`workHours[${i}].hours`)}
                      errorMessage={getError(`workHours[${i}].hours`)}
                    />
                    <Input
                      type="date"
                      label={intl.formatMessage(messages.fromLabel)}
                      name={`workHours[${i}].dateFrom`}
                      defaultValue={dateFrom}
                      onChange={handleChange}
                      wrapperClass="form__input-block--half"
                      hasError={hasError(`workHours[${i}].dateFrom`)}
                      errorMessage={getError(`workHours[${i}].dateFrom`)}
                    />
                  </div>
                </div>
                <button type="button" className="form__btn-clean-inputs" onClick={handleRemoveHoursInfo(i)}>
                  <Icon iconName="times" />
                </button>
              </div>
            ))}
            <div>
              <Button type="button" externalClass="form__btn-add-group" color="gray" onClick={handleAddHoursInfo}>
                <Icon iconName="plus" externalClass="form__icon-btn-add" />
                <FormattedMessage {...messages.addWorkHoursButton} />
              </Button>
            </div>
          </div>
          <div className="form__group">
            <Select
              label={intl.formatMessage(messages.primarySpecializationSelect)}
              options={specializationOptions}
              value={primarySpecValue}
              handleChange={handlePrimarySpec}
              isClearable
            />
            <Select
              isMulti
              isSearchable
              options={specializationOptions}
              handleChange={handleOtherSpec}
              value={otherSpecValue}
              label={intl.formatMessage(messages.otherSpecializations)}
            />
            <Select
              isMulti
              isSearchable
              options={employeeGroupsOptions}
              handleChange={handleEmployeeGroups}
              value={employeeGroupsValue}
              label={intl.formatMessage(messages.notificationGroupLabel)}
              hasError={hasError('employeeGroupIds')}
              errorMessage={getError('employeeGroupIds')}
            />
          </div>
        </div>
        <ErrorMessage>{requestError}</ErrorMessage>
        <div className="form__buttons">
          <Button type="button" onClick={onClose} color="gray" externalClass="button--modal">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button type="submit" externalClass="button--modal" loading={loading} disabled={loading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalProfessional;
