import { defineMessages } from 'react-intl';
import {
  projectsLabel,
  totalRow,
  exportToXLSLabel,
  exportToPDFLabel,
  officesLabel,
  filtersLabel,
  departmentsLabel,
  factColumn,
  planColumn,
  projectGroupsLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  totalRow,
  exportToXLSLabel,
  exportToPDFLabel,
  officesLabel,
  filtersLabel,
  departmentsLabel,
  factColumn,
  planColumn,
  projectGroupsLabel,
  pageTitle: {
    id: 'planning.project.hours.title.project.hours.details',
    defaultMessage: ' Project Hours (+ Details)',
  },
  projectGroupsEmployeesTitle: {
    id: 'planning.project.hours.column.project.groups.members',
    defaultMessage: 'Project Groups / Members',
  },
  projectsLabel,
  employeesFactPlanTitle: {
    id: 'planning.project.hours.column.members.fact.plan',
    defaultMessage: 'Members (Fact / Plan)',
  },
  planFactTitle: {
    id: 'planning.project.hours.column.plan.fact',
    defaultMessage: 'Plan / Fact (%)',
  },
});
