import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as competenciesActions from '../../../actions/competencies.actions';
import Table from '../../../components/Table';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import ModalDeleteCompetence from '../../../components/Competencies/Modals/ModalDeleteCompetence';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { CompetenciesParams } from '../../../enums/params/competencies.params';
import { DELETE_COMPETENCE, UPDATE_COMPETENCE } from '../../../constants/policies.constants';
import AccessChecker from '../../../components/AccessChecker';
import { CompetenciesTypeInfoType } from '../../../types/competencies';

export type CompetenceLevelSkillType = {
  id: string;
  maxScore: number;
  name: string;
  position: number;
  questionsCount: number;
};

export type CompetenceLevelType = {
  id: string;
  name: string;
  priority: number;
  skills: Array<CompetenceLevelSkillType>;
  maxScore: number;
  questionsCount: number;
};

export type CompetenceType = {
  maxScore: number;
  competenceLevels: Array<CompetenceLevelType>;
  name: string;
  id: string;
  competenceType: CompetenciesTypeInfoType;
  levelsCount: number;
  skillsCount: number;
};

function Competencies({
  deleteCompetence,
  setCompetenciesParams,
  getCompetenciesList,
  tableData,
  competenciesErrors,
  isLoading,
  sortParams,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  useEffect(() => {
    getCompetenciesList();
    return () => {
      resetState();
    };
  }, []);
  const [modalDeletePositionIsOpen, setModalDeletePositionIsOpen] = useState(false);
  const [clickedCompetence, setClickedCompetence] = useState<any>({});
  const handleSort = useCallback((sortBy, direction) => setCompetenciesParams({ sortBy, direction }), []);
  const setCompetenceCallback = useCallback(() => {
    setClickedCompetence({});
  }, []);
  const closeDeleteCompetenceModal = useCallback(() => {
    setModalDeletePositionIsOpen(false);
  }, []);
  const openDeleteCompetenceModal = useCallback(() => {
    setModalDeletePositionIsOpen(true);
  }, []);

  const history = useHistory();

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier(row: any) {
          return (
            <NavLink className={'table__type-link'} to={`competencies/information/${row.id}`}>
              {row.name}
            </NavLink>
          );
        },
      },
      {
        name: intl.formatMessage(messages.competenciesTypeTitle),
        sortName: 'competenceType.name',
        modifier: (row: any) => row.competenceType?.name,
      },
      {
        name: intl.formatMessage(messages.countOfLevelTitle),
        modifier: (row: any) => row.levelsCount,
      },
      {
        name: intl.formatMessage(messages.countOfSkillsTitle),
        modifier: (row: any) => row.skillsCount,
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CompetenciesTypeInfoType) => {
          history.push(`competencies/edit/${row.id}`);
        },
        verifiablePolicies: [UPDATE_COMPETENCE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CompetenciesTypeInfoType) => {
          setClickedCompetence(row);
          openDeleteCompetenceModal();
        },
        verifiablePolicies: [DELETE_COMPETENCE],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.competenciesTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper">
              <AccessChecker verifiablePolicies={[UPDATE_COMPETENCE]}>
                <NavLink to={'/competencies/new'}>
                  <Button externalClass={'button--with-icon'}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </NavLink>
              </AccessChecker>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            params={sortParams}
            loading={isLoading.getCompetenciesList}
            onSort={handleSort}
            error={competenciesErrors.competenciesListError}
            tableActions={tableActions}
          />
        </div>
      </div>
      {modalDeletePositionIsOpen && (
        <ModalDeleteCompetence
          isOpen
          onCloseRequest={closeDeleteCompetenceModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteCompetence({
              ...data,
              setCompetenceCallback,
            });
          }}
          isLoading={isLoading.deleteCompetence}
          competenceError={competenciesErrors.deleteCompetenceError}
          competenceData={clickedCompetence}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ competencies }: RootState) => ({
  competenciesErrors: competencies.errors,
  tableData: competencies.competenciesTableData,
  isLoading: competencies.loading,
  sortParams: competencies.competenciesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deleteCompetence: (data: Record<string, unknown>) => dispatch(competenciesActions.deleteCompetence(data)),
  getCompetenciesList: () => dispatch(competenciesActions.getCompetenciesList()),
  setCompetenciesParams: (params: Partial<CompetenciesParams>) =>
    dispatch(competenciesActions.setCompetenciesParams(params)),
  resetErrors: () => dispatch(competenciesActions.resetErrors()),
  resetState: () => dispatch(competenciesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Competencies);
