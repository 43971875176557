import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { LANGUAGE_LEVELS_VALIDATION_SCHEMA } from '../../../enums/libraries.enum';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { LanguageLevelInfoType } from '../../../types/libraries';
import { scrollToError } from '../../../utils';

type ModalEditLanguageLevelProps = {
  onCloseRequest: () => void;
  editLanguageLevel: (id: string, data: any) => void;
  languageLevelData: LanguageLevelInfoType;
  languageLevelError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalEditLanguageLevel({
  onCloseRequest,
  editLanguageLevel,
  languageLevelData,
  languageLevelError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalEditLanguageLevelProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldError } = useFormik({
    initialValues: languageLevelData,
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: LANGUAGE_LEVELS_VALIDATION_SCHEMA,
    onSubmit: data => {
      return editLanguageLevel(languageLevelData?.id, { data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(languageLevelError, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editLanguageLevelTitle)}
      classNameModal="skill-level-modal center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              id={'edit-name'}
              name={'name'}
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values?.name || ''}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
            />
          </div>
        </div>
        <ErrorMessage>{languageLevelError}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit} type={'button'}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditLanguageLevel;
