import classNames from 'classnames';
import React from 'react';
import { formatValue } from 'react-currency-input-field';
import { CurrencyType } from '../../types/finance';
import { getLang } from '../../utils';
import { isNil } from 'lodash-es';

type InfoBlockProps = {
  label: string;
  currencyValue?: number;
  value?: string | number;
  color?: string;
  externalWrapperClass?: string;
  baseCurrency?: CurrencyType;
};

function InfoBlock({ value, currencyValue, label, color, externalWrapperClass, baseCurrency }: InfoBlockProps) {
  const wrapperClass = classNames('info-block__wrapper', {
    [externalWrapperClass as string]: externalWrapperClass,
  });

  return (
    <div className={wrapperClass} style={{ ...(color && { color }) }}>
      <p className="info-block__value">
        {!isNil(currencyValue)
          ? formatValue({
              value: currencyValue.toString(),
              intlConfig: { locale: getLang(), currency: baseCurrency?.name },
            })
          : value}
      </p>
      <p className="info-block__label">{label}</p>
    </div>
  );
}
export default InfoBlock;
