import { isFunction } from 'lodash-es';
import { DepthLevelsType } from '../constants/tables.constants';

export function getTableCell(
  row: any,
  columnData: {
    depth: ((depth: number) => boolean) | DepthLevelsType;
    content: (row: any, index: number, rowDepth: number) => React.ReactElement | null | string;
  }[],
) {
  const data = row.original;
  const rowDepth = row.depth;
  const column = columnData.filter(el => {
    const currentDepth = el.depth;
    return isFunction(currentDepth) ? currentDepth(rowDepth) : currentDepth === rowDepth;
  });

  return column.length === 1 ? column[0].content(data, row.index, rowDepth) : null;
}

export const getTableData = (currentData: any, names: string[]) => {
  if (currentData) {
    currentData.forEach((obj: any, i: number) => {
      for (const prop in obj) {
        if (names.includes(prop)) {
          currentData[i].items = obj[prop] || [];
          getTableData(currentData[i][prop], names);
        }
      }
    });
    return currentData;
  }
  return [];
};

export const getTableCellContent = (content: string | number | null) => {
  return content ? content : '-';
};
