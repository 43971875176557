import React, { useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import { useClickOutsideHandler } from '../../../utils/hooks.utils';
import CustomDateRangePicker from '../../CustomDateRangePicker/index';

type CustomFilterProps = {
  pickerContainerClass: string;
  defaultStartDate?: string;
  defaultEndDate?: string;
  label: string;
  handleChange: (start: string, end: string) => void;
};

function PickerFilter({
  pickerContainerClass,
  label,
  defaultStartDate,
  defaultEndDate,
  handleChange,
}: CustomFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const defaultClass = classNames('filter__control', {
    'filter__control--menu-is-open': isOpen,
  });

  const valueContainerClass = classNames('filter__value-container', {
    bold: defaultStartDate || defaultEndDate,
  });

  useClickOutsideHandler(() => {
    setIsOpen(false);
  }, ref);

  const handleOpenFilter = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseFilter = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className="filter__candidate" ref={ref}>
      <div className={defaultClass}>
        <div className={valueContainerClass} onClick={handleOpenFilter}>
          <CustomDateRangePicker
            isFilter
            filterLabel={label}
            containerExternalClass={pickerContainerClass}
            parentEl={`.${pickerContainerClass}`}
            startDate={defaultStartDate}
            endDate={defaultEndDate}
            onChange={handleChange}
            handleHide={handleCloseFilter}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(PickerFilter);
