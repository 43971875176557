import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import * as usersActions from '../../../actions/users.actions';
import * as filtersActions from '../../../actions/filters.actions';
import Button from '../../Button';
import Icon from '../../Icon';
import ModalPersonal from '../Modals/ModalPersonal';
import ModalProfessional from '../Modals/ModalProfessional';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { TRouteParamId } from '../../../enums/common.enum';
import ProfileInfoBlock from '../ProfileInfoBlock';
import { UserPersonalInfo, UserProfessionalInfo } from '../../../enums/users.enum';
import Avatar from '../Avatar';
import AccessChecker from '../../AccessChecker';
import { UPDATE_USER, UPDATE_USER_EXTENDED } from '../../../constants/policies.constants';
import { checkPolicies } from '../../../utils/policies.utils';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import PoliciesContext from '../../../PoliciesContext';
import ListRecordsBackground from '../../ListRecordsBackground';
import { isEmpty } from 'lodash-es';

function ProfileAbout({
  personalInfo,
  professionalInfo,
  getPersonalInfo,
  getProfessionalInfo,
  getUsersFilter,
  getSpecializationsFilter,
  updatePersonalInfo,
  updateProfessionalInfo,
  hrCuratorList,
  specializationList,
  loadingUpdatePersonal,
  loadingUpdateProfessional,
  updatePersonalError,
  updateProfessionalError,
  resetUserErrors,
  getEmployeeGroupsFilter,
  employeeGroupList,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const { id } = useParams<TRouteParamId>();
  const [modalPersonalOpen, setModalPersonalOpen] = useState(false);
  const [modalProfessionalOpen, setModalProfessionalOpen] = useState(false);

  const intl = useIntl();

  const handleUpdatePersonalInfo = useCallback(
    data => {
      updatePersonalInfo(id, data, () => setModalPersonalOpen(false));
    },
    [id],
  );

  const handleUpdateProfessionalInfo = useCallback(
    data => {
      updateProfessionalInfo(id, data, () => setModalProfessionalOpen(false));
    },
    [id],
  );

  useEffect(() => {
    getPersonalInfo(id);
    getProfessionalInfo(id);
    checkPolicies([UPDATE_USER_EXTENDED], policies) &&
      (getEmployeeGroupsFilter(), getSpecializationsFilter(), getUsersFilter());
  }, [id, policies]);

  return (
    <div className="tabs__content-item active tabs__content-item--about">
      <div className="tabs__content-item__title">
        <FormattedMessage {...messages.aboutMemberTitle} />:
      </div>
      <div className="tabs__content-item__about-column-wrapper">
        <div className="tabs__content-item__about-column">
          <div className="tabs__content-item__about-column-title">
            <FormattedMessage {...messages.personalLabel} />
          </div>
          <ProfileInfoBlock title={intl.formatMessage(messages.birthdayInput)}>
            {personalInfo.userBirthday}
          </ProfileInfoBlock>
          <ProfileInfoBlock title={intl.formatMessage(messages.educationInput)}>
            {personalInfo.userEducation}
          </ProfileInfoBlock>
          <ProfileInfoBlock title={intl.formatMessage(messages.coursesInput)}>
            {personalInfo.userCourses}
          </ProfileInfoBlock>
          <ProfileInfoBlock title={intl.formatMessage(messages.hobbiesInput)}>
            {personalInfo.userHobbies}
          </ProfileInfoBlock>
          <ProfileInfoBlock title={intl.formatMessage(messages.booksInput)}>{personalInfo.userBooks}</ProfileInfoBlock>
          <ProfileInfoBlock title={intl.formatMessage(messages.musicInput)}>{personalInfo.userMusic}</ProfileInfoBlock>
          <ProfileInfoBlock title={intl.formatMessage(messages.lifeValuesInput)}>
            {personalInfo.userLifeValues}
          </ProfileInfoBlock>
          <ProfileInfoBlock title={intl.formatMessage(messages.lifeMottoInput)}>
            {personalInfo.userLifeMotto}
          </ProfileInfoBlock>
          <ProfileInfoBlock title={intl.formatMessage(messages.likesInput)}>{personalInfo.userLikes}</ProfileInfoBlock>
          <ProfileInfoBlock title={intl.formatMessage(messages.dislikesInput)}>
            {personalInfo.userDislikes}
          </ProfileInfoBlock>
          {id === authUserId ? (
            <AccessChecker verifiablePolicies={[UPDATE_USER, UPDATE_USER_EXTENDED]}>
              <Button color="gray" externalClass="page__profile-button" onClick={() => setModalPersonalOpen(true)}>
                <Icon iconName="pencil" externalClass="button__icon" />
              </Button>
            </AccessChecker>
          ) : (
            <AccessChecker verifiablePolicies={[UPDATE_USER_EXTENDED]}>
              <Button color="gray" externalClass="page__profile-button" onClick={() => setModalPersonalOpen(true)}>
                <Icon iconName="pencil" externalClass="button__icon" />
              </Button>
            </AccessChecker>
          )}
        </div>
        {modalPersonalOpen && (
          <ModalPersonal
            isOpen
            onClose={() => {
              setModalPersonalOpen(false);
              resetUserErrors();
            }}
            onSubmit={handleUpdatePersonalInfo}
            personalInfo={personalInfo}
            loading={loadingUpdatePersonal}
            requestError={updatePersonalError}
          />
        )}
        <div className="tabs__content-item__about-column">
          <div className="tabs__content-item__about-column-title">
            <FormattedMessage {...messages.professionalTitle} />
          </div>
          {professionalInfo.userShowHr &&
            professionalInfo.userHrInfo.map(
              // @ts-ignore
              ({ hiredDate, endTrialDate, dismissalDate, hrName, hrCurator }, i) => (
                <React.Fragment key={i.toString()}>
                  <div className="tabs__content-item__about-info-wrapper">
                    <div className="tabs__content-item__about-info">
                      <div className="tabs__content-item__about-info-name">
                        <FormattedMessage {...messages.recruitmentTitle} />
                      </div>
                      <div className="tabs__content-item__about-info-value">{hiredDate}</div>
                    </div>
                    <div className="tabs__content-item__about-info">
                      <div className="tabs__content-item__about-info-name">
                        <FormattedMessage {...messages.endTrialPeriodTitle} />
                      </div>
                      <div className="tabs__content-item__about-info-value">{endTrialDate}</div>
                    </div>
                    <div className="tabs__content-item__about-info">
                      <div className="tabs__content-item__about-info-name">
                        <FormattedMessage {...messages.talentCuratorSelect} />
                      </div>
                      <div className="tabs__content-item__about-info-value">
                        <Avatar
                          //@ts-ignore
                          userInfo={hrCurator}
                          size={'tiny'}
                          isUserPageLink
                          fileSize={36}
                        />
                        {hrName}
                      </div>
                    </div>
                  </div>
                  {dismissalDate && (
                    <div className="tabs__content-item__about-info-wrapper">
                      <div className="tabs__content-item__about-info">
                        <div className="tabs__content-item__about-info-name">
                          <FormattedMessage {...messages.dismissalInput} />
                        </div>
                        <div className="tabs__content-item__about-info-value">{dismissalDate}</div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ),
            )}
          <div className="tabs__content-item__about-info-wrapper">
            <div className="tabs__content-item__about-info">
              <div className="tabs__content-item__about-info-name">
                <FormattedMessage {...messages.regulatoryHoursSelect} />
              </div>
              {professionalInfo.workHours.map(hours => (
                <div key={hours.id} className="tabs__content-item__about-info-value">
                  {`${hours.hours} hours`}
                </div>
              ))}
            </div>
            <div className="tabs__content-item__about-info">
              <div className="tabs__content-item__about-info-name">
                <FormattedMessage {...messages.startDateLabel} />
              </div>
              {professionalInfo.workHours
                .sort((a, b) => new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime())
                .map((info, i) => (
                  <div key={i.toString()} className="tabs__content-item__about-info-value">
                    {moment(info.dateFrom).format(DATE_FORMAT.ll)}
                  </div>
                ))}
            </div>
            <div className="tabs__content-item__about-info">
              <div className="tabs__content-item__about-info-name">
                <FormattedMessage {...messages.previousExperienceInput} />
              </div>
              <div className="tabs__content-item__about-info-value">{professionalInfo.userPreviousExp}</div>
            </div>
          </div>
          <div className="tabs__content-item__about-info">
            <div className="tabs__content-item__about-info-name">
              <FormattedMessage {...messages.primarySpecializationSelect} />
            </div>
            <div className="tabs__content-item__about-info-value">{professionalInfo.userMainSpecialization}</div>
          </div>
          <div className="tabs__content-item__about-info tabs__content-item__about-info--subitems">
            <div className="tabs__content-item__about-info-name">
              <FormattedMessage {...messages.otherSpecializations} />
            </div>
            {professionalInfo.userShowOtherSpec ? (
              <ListRecordsBackground data={professionalInfo.userOtherSpecializations} />
            ) : (
              <span>-</span>
            )}
          </div>
          <div className="tabs__content-item__about-info tabs__content-item__about-info--subitems">
            <div className="tabs__content-item__about-info-name">
              <FormattedMessage {...messages.notificationGroupLabel} />
            </div>
            {!isEmpty(professionalInfo.employeeGroups) ? (
              <ListRecordsBackground data={professionalInfo.employeeGroups} />
            ) : (
              <span>-</span>
            )}
          </div>
          <AccessChecker verifiablePolicies={[UPDATE_USER_EXTENDED]}>
            <Button color="gray" externalClass="page__profile-button" onClick={() => setModalProfessionalOpen(true)}>
              <Icon iconName="pencil" externalClass="button__icon" />
            </Button>
          </AccessChecker>
        </div>
        {modalProfessionalOpen && (
          <ModalProfessional
            isOpen
            onClose={() => {
              setModalProfessionalOpen(false);
              resetUserErrors();
            }}
            onSubmit={handleUpdateProfessionalInfo}
            professionalInfo={professionalInfo}
            hrCurators={hrCuratorList}
            specializations={specializationList}
            loading={loadingUpdateProfessional}
            requestError={updateProfessionalError}
            employeeGroupsList={employeeGroupList}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ users, filters, auth }: RootState) => ({
  personalInfo: users.current.personal,
  professionalInfo: users.current.professional,
  hrCuratorList: filters.usersFilter.users,
  specializationList: filters.specializationsFilter.specializations,
  loadingUpdatePersonal: users.loading.updatePersonal,
  loadingUpdateProfessional: users.loading.updateProfessional,
  updatePersonalError: users.errors.updatePersonal,
  updateProfessionalError: users.errors.updateProfessional,
  employeeGroupList: filters.employeeGroupsFilter.employeeGroups,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPersonalInfo: (uuid: string) => dispatch(usersActions.getUserPersonalInfo(uuid)),
  getProfessionalInfo: (uuid: string) => dispatch(usersActions.getUserProfessionalInfo(uuid)),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getSpecializationsFilter: () => dispatch(filtersActions.getSpecializationsFilter()),
  updatePersonalInfo: (uuid: string, data: UserPersonalInfo, cb: () => void) =>
    dispatch(usersActions.updateUserPersonalInfo(uuid, data, cb)),
  updateProfessionalInfo: (uuid: string, data: UserProfessionalInfo, cb: () => void) =>
    dispatch(usersActions.updateUserProfessionalInfo(uuid, data, cb)),
  resetUserErrors: () => dispatch(usersActions.resetUserErrors()),
  getEmployeeGroupsFilter: () => dispatch(filtersActions.getEmployeeGroupsFilter()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfileAbout);
