import { defineMessages } from 'react-intl';
import { cancelButton, deleteButton, saveButton, dispalyNameLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  dispalyNameLabel,
  newConfigurationTitle: {
    id: 'mail.mailer.settings.modal.new.title.new.configuration',
    defaultMessage: 'New Configuration',
  },
  editConfigurationTitle: {
    id: 'mail.mailer.settings.modal.edit.title.edit.configuration',
    defaultMessage: 'Edit Configuration',
  },
  mailHostLabel: {
    id: 'mail.mailer.settings.modal.mail.host.label.host',
    defaultMessage: 'Host',
  },
  mailUsernameLabel: {
    id: 'mail.mailer.settings.modal.mail.username.label.username',
    defaultMessage: 'Username',
  },
  mailPasswordLabel: {
    id: 'mail.mailer.settings.modal.mail.password.label.password',
    defaultMessage: 'Password',
  },
  mailPortLabel: {
    id: 'mail.mailer.settings.modal.mail.port.label.port',
    defaultMessage: 'Port',
  },
  mailEncryptionLabel: {
    id: 'mail.mailer.settings.modal.mail.encryption.label.encryption',
    defaultMessage: 'Encryption',
  },
  notificationsTypesLabel: {
    id: 'mail.mailer.settings.modal.mail.notification.types.label.notification.types',
    defaultMessage: 'Notification Types',
  },
  deleteModalTitle: {
    id: 'mail.mailer.settings.modal.delete.titlt',
    defaultMessage:
      'Are you sure you want to delete? A number of service messages will no longer be able to be sent until a new configuration is set for them.',
  },
});
