import moment from 'moment';
import { DATE_FORMAT } from '../constants/date.constants';
import { UserInfo, UserPreviewInfo } from '../enums/users.enum';

export function getHoursAndMinutes(totalSeconds = 0, showMinutesIfZero = true) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = (totalSeconds % 3600) / 60;
  return showMinutesIfZero ? `${hours}h ${minutes}m` : `${hours}h ${minutes ? `${minutes}m` : ''}`.trim();
}

export const getTooltipHtmlContent = (
  projectName: string,
  user: UserPreviewInfo | UserInfo | null,
  start: Date,
  end: Date,
  today = moment().format(DATE_FORMAT.YYYY_MM_DD),
  logged = 0,
  tracked = 0,
) => {
  const startTime = moment(start);
  const endTime = moment(end);
  const durationContent = getHoursAndMinutes(endTime.diff(startTime, 's'));
  const startEndContent = `${startTime.format(DATE_FORMAT.LT)} - ${endTime.format(DATE_FORMAT.LT)}`;
  const loggedTrackedContent = `${getHoursAndMinutes(logged)} / ${getHoursAndMinutes(tracked)}`;

  return `
    <div class="tooltip">
      <div class="tooltip__header">${projectName ? projectName : 'Away (Not Working)'}</div>
      <div class="tooltip__info">
        <div class="tooltip__user">
          <span class="bold-text">${
            user ? user.fullName : moment(today).format(DATE_FORMAT.YYYY_MM_DD)
          } (${loggedTrackedContent}):</span>
          ${startEndContent}
        </div>
        <div class="tooltip__duration">
          <span class="bold-text">Duration:</span>
          ${durationContent}
        </div>
      </div>
    `;
};

export const getUserWorkTime = (data: any, title: string, isUserProfile = false): any => {
  return (
    data?.trackLogs.map((el: any) => {
      const task = el?.task ? el?.task : '';
      const startDate = new Date(el?.startDate);
      const endDate = new Date(el?.endDate);

      const startTime = isUserProfile
        ? moment().set({ hour: startDate.getHours(), minute: startDate.getMinutes(), second: 1 }).toDate()
        : moment(startDate).toDate();

      const endTime = isUserProfile
        ? moment()
            .set({
              hour: endDate.getHours(),
              minute: endDate.getMinutes(),
              second: 0,
            })
            .toDate()
        : moment(endDate).toDate();

      return [
        title,
        task,
        getTooltipHtmlContent(
          task,
          new UserPreviewInfo(data.userPreview),
          startDate,
          endDate,
          '',
          data?.logged,
          data?.tracked,
        ),
        '',
        startTime,
        endTime,
        el.task,
      ];
    }) || []
  );
};

export const getActivityChartData = (data: any) => {
  const userWorkTime = getUserWorkTime(data, 'Title');
  const date = moment(data.trackLogs[0].startDate);
  const start = date.startOf('d').toDate();
  const end = date.endOf('d').toDate();
  return [['Title', '', '', '', start, start, ''], ...userWorkTime, ['Title', '', '', '', end, end, '']];
};
