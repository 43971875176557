import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';

type CheckboxProps = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  disabled?: boolean;
  id: string;
  label?: string;
  externalClass?: string;
  name?: string;
  checkedValue?: boolean;
  iconName?: string;
};

function Checkbox({
  onChange,
  checked,
  disabled,
  id,
  label,
  externalClass,
  name,
  checkedValue,
  iconName,
}: CheckboxProps) {
  const checkboxBlockDefaultClass = classNames('label', 'form__checkbox-block', {
    [externalClass as string]: Boolean(externalClass),
  });

  return (
    <div className={checkboxBlockDefaultClass} onClick={event => event.stopPropagation()}>
      <input
        readOnly
        className={'form__checkbox'}
        type="checkbox"
        id={id}
        onChange={onChange}
        defaultChecked={checked}
        disabled={disabled}
        name={name}
        checked={checkedValue}
      />
      <label className={'form__checkbox-label'} htmlFor={id}>
        {label}
        <Icon iconName={iconName ? iconName : 'checkbox'} />
      </label>
    </div>
  );
}

export default Checkbox;
