import React, {
  memo,
  useCallback,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';
import { groupBy, isEmpty, isNil, uniqBy } from 'lodash-es';
import Icon from '../../../components/Icon';
import Question from '../../../components/Questions';
import { UserInfo } from '../../../enums/users.enum';
import classNames from 'classnames';
import {
  publishedValue,
  AllOfficesOption,
  getUserStatus,
  validatePollForm,
  completedStatusValue,
  closedValue,
} from '../utils';
import { useFormik } from 'formik';
import RadioButtonGroup from '../../../components/RadioButtonGroup';
import ModalPollResponders from '../../../components/Polls/Modals/ModalPollResponders';
import { ANSWERS_OWN_POLL_VALIDATION, PollTemplateGroupType } from '../../../enums/questionnaires.enum';
import { questionsNumber } from '../utils';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { checkPolicies } from '../../../utils/policies.utils';
import { UPDATE_POLL } from '../../../constants/policies.constants';
import PoliciesContext from '../../../PoliciesContext';
import EventPollResponders from '../../Schedule/EventPolls/EventPollResponders';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import useDrag from '../../../utils/hooks.utils';
import { CompanyPositionInfoType, SpecializationInfoType } from '../../../types/libraries';
import { TemplateDatabaseDataSource } from '../../../constants/questionnaires.constants';
import { scrollToError } from '../../../utils';

type ScrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

type QuestionsSectionProps = {
  pollData: any;
  editAnswersMode: boolean;
  questionsGroups: Array<any>;
  userList: Array<any>;
  offices: Array<any>;
  onSaveAnswers: any;
  isAdmin: boolean;
  currentUser: UserInfo | undefined;
  pollId: any;
  answersByPollAndUser: any;
  allResponders: any;
  updateOwnAnswersError: any;
  isSavingProgress: boolean;
  backButtonHandler: () => void;
  companyPositions: CompanyPositionInfoType[];
  specializations: SpecializationInfoType[];
  jiraProjectsList: { id: number; name: string }[];
  userFilterList: UserInfo[];
  isOpenConfirmForSubmitAnswers: boolean;
  getJiraProjects: () => void;
  getSpecializationsFilter: () => void;
  getCompanyPostitionsFilter: () => void;
  getNotAnsweredPoll: () => void;
  onOpenConfirmModalForOwnAnswers: () => void;
  setAnswersChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPrompt: (showPrompt: boolean) => void;
  pollGroupsScores?: {
    averageScore: number | 'NaN';
    id: string;
    maxScores: number;
    percentage: number | 'NaN';
  }[];
};

const QuestionsSection = forwardRef(
  (
    {
      pollData,
      editAnswersMode,
      questionsGroups,
      userList,
      isAdmin,
      currentUser,
      offices,
      onSaveAnswers,
      pollId,
      answersByPollAndUser,
      allResponders,
      updateOwnAnswersError,
      isSavingProgress,
      backButtonHandler,
      companyPositions,
      specializations,
      jiraProjectsList,
      userFilterList,
      isOpenConfirmForSubmitAnswers,
      getJiraProjects,
      getSpecializationsFilter,
      getCompanyPostitionsFilter,
      getNotAnsweredPoll,
      onOpenConfirmModalForOwnAnswers,
      setAnswersChanged,
      setShowPrompt,
      pollGroupsScores,
    }: QuestionsSectionProps,
    ref,
  ) => {
    const policies = useContext(PoliciesContext);
    const responderRef = useRef(null);
    const headerRef = useRef(null);
    const parentRef = useRef(null);
    const scrollRef = useRef(null);
    const intl = useIntl();
    const [checkedCity, setCheckedCity] = useState(AllOfficesOption.id);
    const [isOpenModalPollResponders, setIsOpenModalPollResponders] = useState(false);
    const [firstSubmitValidate, setFirstSubmitValidate] = useState(false);
    const { values, errors, setFieldValue, handleSubmit, isSubmitting } = useFormik({
      initialValues: {},
      validateOnChange: firstSubmitValidate,
      validate: values => {
        !firstSubmitValidate && setFirstSubmitValidate(true);
        return validatePollForm(values, questionsGroups);
      },
      onSubmit: data => {
        setShowPrompt(false);
        if (pollData.anonymous && !isOpenConfirmForSubmitAnswers) {
          onOpenConfirmModalForOwnAnswers();
        } else {
          onSaveAnswers({
            data: ANSWERS_OWN_POLL_VALIDATION.cast(data),
            pollId,
            isIntermediateAnswers: isSavingProgress,
            cb: () => {
              backButtonHandler();
              getNotAnsweredPoll();
            },
          });
        }
      },
    });

    useEffect(() => {
      if (isSubmitting) {
        scrollToError();
      }
    }, [isSubmitting]);

    const handleSetFieldValue = useCallback((field: string, value: any, answerChanged = true) => {
      if (answerChanged) {
        setShowPrompt(true);
      }
      setFieldValue(field, value);
      answerChanged && setAnswersChanged(true);
    }, []);

    useEffect(() => {
      if (editAnswersMode || pollData?.status === publishedValue || pollData?.status === closedValue) {
        let requestProjects = false;
        let requestPositions = false;

        let requestSpecializations = false;

        pollData.template.groups.map((group: PollTemplateGroupType) =>
          group.fields.map(field => {
            if (field.templateDatabaseDataSource === TemplateDatabaseDataSource.PROJECTS) {
              requestProjects = true;
            }
            if (field.templateDatabaseDataSource === TemplateDatabaseDataSource.COMPANY_POSITIONS) {
              requestPositions = true;
            }
            if (field.templateDatabaseDataSource === TemplateDatabaseDataSource.SPECIALIZATIONS) {
              requestSpecializations = true;
            }
          }),
        );

        if (requestProjects) {
          getJiraProjects();
        }
        if (requestPositions) {
          getCompanyPostitionsFilter();
        }
        if (requestSpecializations) {
          getSpecializationsFilter();
        }
      }
    }, [editAnswersMode, pollData]);

    useImperativeHandle(
      ref,
      () => ({
        handleSubmit,
        handleSaveProgress: () => {
          setShowPrompt(false);
          onSaveAnswers({
            data: ANSWERS_OWN_POLL_VALIDATION.cast(values),
            pollId,
            isIntermediateAnswers: true,
            cb: () => {
              backButtonHandler();
              getNotAnsweredPoll();
            },
          });
        },
      }),
      [values],
    );

    const questionsNum = useMemo(() => questionsNumber(questionsGroups), [questionsGroups]);
    const { dragStart, dragStop, dragMove, clicked } = useDrag();
    const handleDrag = ({ scrollContainer }: ScrollVisibilityApiType) => (ev: React.MouseEvent) => {
      ev.preventDefault();
      dragMove(ev, posDiff => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });
    };

    const getUserValues = useCallback(
      (user: any) => {
        const userValues = Object.values(values) || [];
        const uniqUserValues = uniqBy(userValues, 'id');
        const filteredUserValues = uniqUserValues?.filter((el: any) => el?.user?.id === user?.id);
        return getUserStatus(questionsNum, filteredUserValues?.length);
      },
      [values, questionsNum],
    );

    const handleChangeCity = useCallback(
      (e: any) => {
        setCheckedCity(e.target.id);
      },
      [values, questionsNum],
    );

    const questionAuthor: any = useMemo(() => {
      const user = !isEmpty(pollData?.organizers) ? new UserInfo(pollData?.organizers[0]) : new UserInfo();
      return { ...user, pollStatus: '' };
    }, [pollData?.organizers]);

    const filteredUsersByOffice = useMemo(() => {
      const usersByOffice = userList?.filter(({ user }) => user?.office?.id === checkedCity);
      if (checkedCity === AllOfficesOption.id) return userList;
      return offices?.length > 1 ? usersByOffice : userList;
    }, [checkedCity, userList, offices]);

    const formattedResponders = useMemo(() => {
      return filteredUsersByOffice?.map((el: any) => el.user) || [];
    }, [filteredUsersByOffice]);

    const getSelectedAnswersForUser = useCallback(
      (field: any) => {
        const answers = !isEmpty(answersByPollAndUser)
          ? answersByPollAndUser?.find((el: any) => el?.fieldId === field?.id)
          : { fieldId: field?.id };
        return answers;
      },
      [answersByPollAndUser],
    );

    const handleOpenModalWithResponders = useCallback(() => {
      if (!pollData?.anonymous) setIsOpenModalPollResponders(true);
    }, [pollData?.anonymous]);

    const handleCloseModalWithResponders = useCallback(() => setIsOpenModalPollResponders(false), []);

    const textRepliedUsers = useMemo(() => {
      const usersCount = pollData?.responders?.filter((responder: any) => responder.pollStatus === completedStatusValue)
        .length;

      return ` (${usersCount} OF ${pollData?.repliesNumber} ${intl.formatMessage(messages.repliedText)})`;
    }, [pollData, userList]);

    const answersForAnonymous = useMemo(() => {
      return pollData?.anonymous ? filteredUsersByOffice[0]?.answers : filteredUsersByOffice;
    }, [pollData?.anonymous, filteredUsersByOffice]);

    const handleScroll = useCallback(
      (api: any, event: any) => {
        //@ts-ignore
        if (!isNil(responderRef) && responderRef.current) responderRef.current.scrollLeft = event.target.scrollLeft;
      },
      [responderRef, responderRef.current],
    );

    const sortedAnonymousAnser = () => {
      const groupedAnswer = groupBy(answersForAnonymous, el => el.anonymousAnswerId);
      return Object.values(groupedAnswer);
    };

    const getGroupScores = useCallback(
      (groupId: string, groupName: string) => {
        const currentGroupe = pollGroupsScores?.find(item => item.id === groupId);

        return (
          !!currentGroupe?.maxScores && (
            <div className="user-score-wrapper">
              <div className="group-name">{groupName}</div>
              <div>
                {`(${currentGroupe?.averageScore === 'NaN' ? ' -' : currentGroupe?.averageScore} of ${
                  currentGroupe?.maxScores
                },  ${currentGroupe?.percentage === 'NaN' ? '- ' : currentGroupe?.percentage}%)`}
              </div>
            </div>
          )
        );
      },
      [pollGroupsScores],
    );

    const questionContent = useMemo(
      () =>
        questionsGroups?.map((group: any, indexGroup: number) => (
          <div key={group.id} className="event-poll__content__group">
            <div
              className={classNames('event-poll__content__group-name page__event__info-title', {
                'my-polls-title': !isAdmin,
                'poll-group-scores': !!pollGroupsScores?.find(item => item.id === group.id)?.maxScores,
              })}
            >
              {!pollGroupsScores?.find(item => item.id === group.id)?.maxScores && (
                <div className="group-name">{group.name}</div>
              )}
              {pollGroupsScores && (
                <div className="users-score-list">
                  {getGroupScores(group.id, group.name)}
                  {userList?.map(user => {
                    const userGroupScore = user.groupScores.find(
                      (item: { groupId: string }) => item.groupId === group.id,
                    );
                    return (
                      <div key={user.id + group.id} className="user-score-wrapper">
                        {!!userGroupScore?.maxScores &&
                          `${userGroupScore?.scores} of ${userGroupScore?.maxScores}, ${userGroupScore?.percentage}%`}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {group?.fields?.map((field: any, indexField: number) => {
              const questionElement = (
                <Question
                  isAnswer={false}
                  editAnswersMode={editAnswersMode}
                  responder={questionAuthor}
                  externalClass={classNames('polls__section--question ', {
                    'event-poll--element ': isAdmin,
                    'polls__section--question_one': !isAdmin,
                  })}
                  textQuestion={field.question}
                  index={`${indexGroup}${indexField}-group`}
                  questionsNumber={questionsNum}
                  errors={errors}
                  companyPositions={companyPositions}
                  userList={userFilterList}
                  specializations={specializations}
                  jiraProjectsList={jiraProjectsList}
                  currentValues={values}
                />
              );
              return (
                <div key={field.id} className="event-poll__content-question__group">
                  {isAdmin ? (
                    <div className="polls__section--question-wrapper">{questionElement}</div>
                  ) : (
                    questionElement
                  )}
                  {isAdmin &&
                    pollData?.anonymous &&
                    answersForAnonymous
                      ?.filter((item: any) => item?.fieldId === field?.id)
                      ?.map((answer: any, indexAnswer: any) => {
                        return (
                          <div key={answer?.id}>
                            <Question
                              isAnswer
                              editAnswersMode={editAnswersMode}
                              isAnonymous={pollData?.anonymous}
                              externalClass="polls__section--answer event-poll--element"
                              field={field}
                              setFieldValue={handleSetFieldValue}
                              index={`${indexGroup}${indexField}${indexAnswer}-userAnonymousAnswer`}
                              status={pollData?.status}
                              updateOwnAnswersError={updateOwnAnswersError}
                              questionsNumber={questionsNum}
                              selectedValues={sortedAnonymousAnser()[indexAnswer]?.find(el => el.fieldId === field.id)}
                              errors={errors}
                              companyPositions={companyPositions}
                              userList={userFilterList}
                              specializations={specializations}
                              jiraProjectsList={jiraProjectsList}
                              currentValues={values}
                            />
                          </div>
                        );
                      })}
                  {isAdmin &&
                    !pollData?.anonymous &&
                    filteredUsersByOffice?.map((responder: any, indexResponder: any) => (
                      <Question
                        isAnswer
                        editAnswersMode={editAnswersMode}
                        responder={responder.user}
                        isAnonymous={pollData?.anonymous}
                        externalClass="polls__section--answer event-poll--element"
                        field={field}
                        key={responder?.user?.id}
                        setFieldValue={handleSetFieldValue}
                        index={`${indexGroup}${indexField}${indexResponder}-userAnswer`}
                        status={pollData?.status}
                        selectedValues={responder.answers?.find((el: any) => el?.fieldId === field.id)}
                        updateOwnAnswersError={updateOwnAnswersError}
                        questionsNumber={questionsNum}
                        userPollStatus={getUserValues(responder)}
                        errors={errors}
                        companyPositions={companyPositions}
                        userList={userFilterList}
                        specializations={specializations}
                        jiraProjectsList={jiraProjectsList}
                        currentValues={values}
                      />
                    ))}
                  {!isAdmin && checkPolicies([UPDATE_POLL], policies) && (
                    <Question
                      isAnswer
                      editAnswersMode={editAnswersMode || pollData?.status === publishedValue}
                      responder={currentUser}
                      isAnonymous={false}
                      externalClass={'polls__section--answer polls__section--answer_one'}
                      field={field}
                      setFieldValue={handleSetFieldValue}
                      selectedValues={getSelectedAnswersForUser(field)}
                      index={`${indexGroup}${indexField}-answer`}
                      status={pollData?.status}
                      updateOwnAnswersError={updateOwnAnswersError}
                      isSavingProgress={isSavingProgress}
                      questionsNumber={questionsNum}
                      errors={errors}
                      companyPositions={companyPositions}
                      userList={userFilterList}
                      specializations={specializations}
                      jiraProjectsList={jiraProjectsList}
                      currentValues={values}
                    />
                  )}
                  <div className="event-poll--last-element"></div>
                </div>
              );
            })}
          </div>
        )),
      [
        questionsGroups,
        editAnswersMode,
        pollData?.status,
        pollData?.anonymous,
        questionAuthor,
        errors,
        questionsNum,
        updateOwnAnswersError,
        isSavingProgress,
        isAdmin,
        filteredUsersByOffice,
        jiraProjectsList,
        specializations,
        userFilterList,
        companyPositions,
        values,
        pollGroupsScores,
      ],
    );

    return (
      <>
        <ModalPollResponders
          onCloseRequest={handleCloseModalWithResponders}
          data={allResponders}
          isLoading={false}
          isOpen={isOpenModalPollResponders}
          answersByPollIdAdmin={userList}
          questionsNum={questionsNum}
        />
        <div className="page__event__participants" ref={parentRef}>
          <div
            className={classNames(' page__info-block--participants', {
              'page__info-block': isAdmin,
              'page__my-polls-content': !isAdmin,
            })}
          >
            {isAdmin && (
              <div className="polls__section--headers">
                <Icon iconName="users" externalClass={'page__info-block--organizers-icon'} />
                <div className="page__event__status-value poll-info">
                  {intl.formatMessage(messages.pollResponders)}
                  <span
                    onClick={handleOpenModalWithResponders}
                    className={classNames({
                      respondersLink: !pollData?.anonymous,
                    })}
                  >
                    {textRepliedUsers}
                  </span>
                </div>
                {offices.length > 1 && (
                  <RadioButtonGroup
                    groupItems={[AllOfficesOption, ...offices]}
                    name="city"
                    onChange={handleChangeCity}
                    externalClass={'switcherCity'}
                    checked={checkedCity}
                  />
                )}
              </div>
            )}
            <div className="event-poll__content  event-poll__content--open" ref={scrollRef}>
              {isAdmin && !pollData?.anonymous && (
                <div className="poll-header-wrapper">
                  <div className="poll-header poll-info" ref={responderRef}>
                    <EventPollResponders
                      responders={formattedResponders}
                      responderRef={responderRef}
                      parentRef={parentRef}
                      author={questionAuthor}
                      headerRef={headerRef}
                      showStatus
                    />
                  </div>
                </div>
              )}

              <div className="event-poll__content-questions">
                {isAdmin && <div className="event-poll__first-column-element"></div>}

                <div className="event-poll__content-question" onMouseLeave={dragStop}>
                  {isAdmin ? (
                    <ScrollMenu
                      onMouseDown={() => dragStart}
                      onMouseUp={() => dragStop}
                      onMouseMove={handleDrag}
                      onScroll={handleScroll}
                      scrollContainerClassName={classNames({ grabbing: clicked })}
                    >
                      {questionContent}
                    </ScrollMenu>
                  ) : (
                    questionContent
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default memo(QuestionsSection);
