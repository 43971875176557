import { defineMessages } from 'react-intl';
import { saveButton, cancelButton, nameColumn, competenciesTitle } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  competenciesTitle,
  cancelButton,
  saveButton,
  editSpecializationTitle: {
    id: 'competencies.specializations.modal.edit.specializations.title.edit.specialization',
    defaultMessage: 'Edit  Specialization',
  },
});
