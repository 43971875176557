import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import * as notificationsActions from '../../actions/notifications.actions';
import AccessChecker from '../../components/AccessChecker';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import ModalDeleteMailerSetting from '../../components/MailerSettings/Modals/ModalDeleteMailerSetting';
import ModalEditMailerSetting from '../../components/MailerSettings/Modals/ModalEditMailerSetting';
import ModalNewMailerSetting from '../../components/MailerSettings/Modals/ModalNewMailerSetting';
import Table from '../../components/Table';
import { SERVRE_TYPES } from '../../constants/notifications.constants';
import { DELETE_MAIL_SETTING, UPDATE_MAIL_SETTING } from '../../constants/policies.constants';
import { MailerSetting } from '../../enums/notifications.enum';
import { SortParams } from '../../enums/params.enum';
import { MailerSettingsParams } from '../../enums/params/notifications.params';
import messages from './messages';
import { isEmpty } from 'lodash-es';

function MailerSettings({
  tableData,
  isLoading,
  mailerSettingsError,
  params,
  getMailerSettingsList,
  setMailerSettingsParams,
  createNewMailerSetting,
  editMailerSetting,
  deleteMailerSetting,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const [modalNewMailerSettingIsOpen, setMoldaNewMailerSettingIsOpen] = useState(false);
  const [modalEditMailerSettingIsOpen, setMoldaEditMailerSettingIsOpen] = useState(false);
  const [modalDeleteMailerSettingIsOpen, setMoldaDeleteMailerSettingIsOpen] = useState(false);
  const [mailerSettingClicked, setMailerSettingClicked] = useState(new MailerSetting());

  const intl = useIntl();

  useEffect(() => {
    getMailerSettingsList();
    return () => {
      resetState();
    };
  }, []);

  const openNewMailerSettingModal = useCallback(() => {
    setMoldaNewMailerSettingIsOpen(true);
  }, []);

  const closeNewMailerSettingModal = useCallback(() => {
    setMoldaNewMailerSettingIsOpen(false);
  }, []);

  const openEditMailerSettingModal = useCallback(() => {
    setMoldaEditMailerSettingIsOpen(true);
  }, []);

  const closeEditMailerSettingModal = useCallback(() => {
    modalEditMailerSettingIsOpen && setMailerSettingClicked(new MailerSetting());
    setMoldaEditMailerSettingIsOpen(false);
  }, [modalEditMailerSettingIsOpen]);

  const openDeleteMailerSettingModal = useCallback(() => {
    setMoldaDeleteMailerSettingIsOpen(true);
  }, []);

  const closeDeleteMailerSettingModal = useCallback(() => {
    setMoldaDeleteMailerSettingIsOpen(false);
  }, []);

  const tableColumns = useMemo(
    (): any => [
      {
        sortName: 'displayName',
        name: intl.formatMessage(messages.dispalyNameLabel),
        modifier: (row: MailerSetting) => row.displayName,
      },
      {
        sortName: 'host',
        name: intl.formatMessage(messages.mailHostColumn),
        modifier: (row: MailerSetting) => row.host,
      },
      {
        sortName: 'username',
        name: intl.formatMessage(messages.mailUsernameColumn),
        modifier: (row: MailerSetting) => row.username,
      },
      {
        sortName: 'port',
        name: intl.formatMessage(messages.mailPortColumn),
        modifier: (row: MailerSetting) => row.port,
      },
      {
        sortName: 'encryption',
        name: intl.formatMessage(messages.mailEncryptionColumn),
        modifier: (row: MailerSetting) => row.encryption,
      },
      {
        name: intl.formatMessage(messages.mailNotificationTypesColumn),
        modifier: (row: MailerSetting) =>
          !isEmpty(row.serverTypes) && (
            <div className="table__list-data">
              {row.serverTypes.map((serverType, index) => (
                <span className="data__enum" key={index}>
                  {SERVRE_TYPES.find(el => el.value === serverType)?.label.toLocaleLowerCase()}
                </span>
              ))}
            </div>
          ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: MailerSetting) => {
          setMailerSettingClicked(row);
          openEditMailerSettingModal();
        },
        verifiablePolicies: [UPDATE_MAIL_SETTING],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: MailerSetting) => {
          setMailerSettingClicked(row);
          openDeleteMailerSettingModal();
        },
        verifiablePolicies: [DELETE_MAIL_SETTING],
      },
    ],
    [],
  );

  const sortParams = useMemo(() => new SortParams('displayName', params), [params]);

  const handleSort = useCallback((sortBy, direction) => setMailerSettingsParams({ sortBy, direction }), []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper">
              <AccessChecker verifiablePolicies={[UPDATE_MAIL_SETTING]}>
                <Button externalClass={'button--with-icon'} onClick={openNewMailerSettingModal}>
                  <Icon iconName={'plus'} externalClass={'button__icon'} />
                  <span className="button__text">
                    <FormattedMessage {...messages.newButton} />
                  </span>
                </Button>
              </AccessChecker>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass="table--mailer-settings"
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getMailerSettingsList}
            error={mailerSettingsError.mailerSettingsListError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
        </div>
      </div>
      {modalNewMailerSettingIsOpen && (
        <ModalNewMailerSetting
          isOpen
          onCloseRequest={closeNewMailerSettingModal}
          createNewMailSetting={createNewMailerSetting}
          mailerSettingError={mailerSettingsError.mailerSettingError}
          isLoading={isLoading.createMailSetting}
          resetErrors={resetErrors}
        />
      )}
      {modalEditMailerSettingIsOpen && (
        <ModalEditMailerSetting
          isOpen
          onCloseRequest={closeEditMailerSettingModal}
          editMailSetting={editMailerSetting}
          mailerSettingError={mailerSettingsError.mailerSettingError}
          mailerSettingData={mailerSettingClicked}
          isLoading={isLoading.editMailSetting}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteMailerSettingIsOpen && (
        <ModalDeleteMailerSetting
          isOpen
          onCloseRequest={closeDeleteMailerSettingModal}
          onDeleteRequest={deleteMailerSetting}
          mailerSettingError={mailerSettingsError.mailerSettingError}
          mailerSettingData={mailerSettingClicked}
          isLoading={isLoading.deleteMailSetting}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ notifications }: RootState) => ({
  tableData: notifications.mailerSettingsTable,
  isLoading: notifications.loading,
  mailerSettingsError: notifications.errors,
  params: notifications.mailerSettingsParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getMailerSettingsList: () => dispatch(notificationsActions.getMailerSettingsList()),
  setMailerSettingsParams: (data: Partial<MailerSettingsParams>) =>
    dispatch(notificationsActions.setMailerSettingsParams(data)),
  createNewMailerSetting: (data: any) => dispatch(notificationsActions.createNewMailerSetting(data)),
  editMailerSetting: (data: any) => dispatch(notificationsActions.editMailerSetting(data)),
  deleteMailerSetting: (data: any) => dispatch(notificationsActions.deleteMailerSetting(data)),
  resetErrors: () => dispatch(notificationsActions.resetErrors()),
  resetState: () => dispatch(notificationsActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MailerSettings);
