import { defineMessages } from 'react-intl';
import {
  exportToXLSLabel,
  exportToPDFLabel,
  copyURLLabel,
  memberLabel,
  factColumn,
  planColumn,
  totalRow,
} from '../../i18n/defaultMessage';

export default defineMessages({
  exportToXLSLabel,
  exportToPDFLabel,
  copyURLLabel,
  memberLabel,
  totalRow,
  factColumn,
  planColumn,
  pageTitle: {
    id: 'reports.members.timesheet.title.members.timesheet ',
    defaultMessage: 'Members Timesheet',
  },
  totalHoursColumn: {
    id: 'reports.employees.timesheet.column.total.hours.column ',
    defaultMessage: 'Total Hours',
  },
});
