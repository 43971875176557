import { defineMessages } from 'react-intl';
import { nameColumn, editButton, deleteButton, newButton } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  pageTitle: {
    id: 'candidate.statuses.title.statuses',
    defaultMessage: 'Statuses',
  },
  defaultColumnLabel: {
    id: 'candidate.statuses.column.label.default',
    defaultMessage: 'Default',
  },
});
