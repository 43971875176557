import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  officesLabel,
  addButton,
  experienceCountLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  officesLabel,
  addButton,
  experienceCountLabel,
  newFollowUpTitle: {
    id: 'mail.follow.ups.modal.new.title.new.follow.up',
    defaultMessage: 'New Follow-Up',
  },
  editFollowUpTitle: {
    id: 'mail.follow.ups.modal.edit.title.edit.follow.up',
    defaultMessage: 'Edit Follow-Up',
  },
  recipientsLabel: {
    id: 'mail.follow.ups.modal.recipients.label.recipients',
    defaultMessage: 'Recipients',
  },
  followUpsLabel: {
    id: 'mail.follow.ups.modal.follow.ups.label.follow.ups',
    defaultMessage: 'Follow-Ups',
  },
  eventLabel: {
    id: 'mail.follow.ups.modal.follow.ups.label.event',
    defaultMessage: 'Event',
  },
  remindDaysbeforeLabel: {
    id: 'mail.follow.ups.modal.follow.ups.label.reminders.days.before',
    defaultMessage: 'Remind (Days before)',
  },
});
