import * as paramsTypes from './../enums/params/planning.params';
import request from '../utils/request.utils';
import { QueryParamsType } from '../utils/params.utils';
import { FiltersParams } from '../enums/params.enum';

export function getResourcesList(params: paramsTypes.ResourcesParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/resources',
    params,
  };
  return request(options);
}

export function createNewResources(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/resources',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getCurrentResource(data: { id: string; date: string }) {
  const options: RequestConfig = {
    method: 'get',
    url: `/resources/${data.id}?date=${data.date}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return request(options);
}

export function getProjectGroupsList(
  params: QueryParamsType<paramsTypes.ProjectGroupsParams> | QueryParamsType<FiltersParams>,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/projectGroups',
    params,
  };
  return request(options);
}

export function createNewProjectGroup(data: any, params: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/projectGroups',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params,
  };
  return request(options);
}

export function deleteProjectGroup(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/projectGroups/${id}`,
  };
  return request(options);
}

export function editProjectGroup(data: any, params: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/projectGroups/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params,
  };
  return request(options);
}

export function getProjectHoursList(params: paramsTypes.ProjectHoursParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/projectHours',
    params,
  };
  return request(options);
}

export function getUserHoursList(params: paramsTypes.UserHoursParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/userHours',
    params,
  };
  return request(options);
}

export function editActiveEmployees(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/activeEmployees/${data.id}?status=${data.status}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return request(options);
}
