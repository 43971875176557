import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import Select from '../../Select';
import { FinancePlan, IMPORT_RESOURCES_SCHEMA, ChekedInfo } from '../../../enums/finance/finance.enum';
import { ProjectGroupType } from '../../../pages/ProjectGroups';
import { isEmpty } from 'lodash';
import ConfirmModal from './ConfirmModal';
import MonthSelect from '../../MonthSelect';
import { scrollToError } from '../../../utils';
import { yearImportResourcesCount } from '../utils';

type ModalImportResourcesProps = {
  onCloseRequest: () => void;
  importResources: (data: {
    data: {
      financePlanId: string;
      month: string;
      projectGroupId: string;
    };
    callback: () => void;
    closeConfirmModal: () => void;
    isConfirmModal: boolean;
  }) => void;
  getProjectGroups: () => void;
  resetImportResourcesCheckedInfo: () => void;
  importResourcesCheckedInfo: ChekedInfo[];
  projectGroups: ProjectGroupType[];
  currentPlan: FinancePlan;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
};

function ModalImportResources({
  onCloseRequest,
  getProjectGroups,
  importResources,
  resetImportResourcesCheckedInfo,
  importResourcesCheckedInfo,
  projectGroups,
  error,
  isLoading,
  isOpen,
  currentPlan,
}: ModalImportResourcesProps) {
  const intl = useIntl();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    resetImportResourcesCheckedInfo();
  };

  useEffect(() => {
    getProjectGroups();
  }, []);

  const { values, errors, touched, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      financePlanId: currentPlan.id,
      month: currentPlan.month,
      year: currentPlan.year,
      projectGroupId: '',
    },
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: IMPORT_RESOURCES_SCHEMA,
    onSubmit: data => importResources({ data, callback: onCloseRequest, isConfirmModal: false, closeConfirmModal }),
  });

  useEffect(() => {
    setIsConfirmModalOpen(!isEmpty(importResourcesCheckedInfo));
  }, [importResourcesCheckedInfo]);

  const handleConfirmModal = () => {
    importResources({
      data: values,
      isConfirmModal: true,
      callback: () => {
        onCloseRequest();
        closeConfirmModal();
      },
      closeConfirmModal,
    });
  };

  const projectGroupsOptions = useMemo(
    () =>
      projectGroups.map(projectGroup => ({
        label: projectGroup.fullName,
        value: projectGroup.id,
      })),
    [projectGroups],
  );

  const handleProjectGroupChange = useCallback(({ value }) => {
    setFieldValue('projectGroupId', value);
  }, []);

  const handleYearChange = useCallback(({ value }) => {
    setFieldValue('year', value);
  }, []);

  const handleMonthChange = useCallback(({ value }) => {
    setFieldValue('month', value);
  }, []);

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.importResourcesModalTitle)}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__inputs-subwrapper">
              <MonthSelect
                year={values.year}
                month={values.month}
                lengthYearList={yearImportResourcesCount}
                errorMessageMonth={errors.month}
                errorMessageYear={errors.year}
                handleYearChange={handleYearChange}
                handleMonthChange={handleMonthChange}
              />
            </div>
            <Select
              label={intl.formatMessage(messages.projectGroupLabel)}
              options={projectGroupsOptions}
              handleChange={handleProjectGroupChange}
              hasError={hasError('projectGroupId')}
              errorMessage={errors.projectGroupId}
            />
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              type={'button'}
              onClick={onCloseRequest}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>

      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen
          checkedInfo={importResourcesCheckedInfo}
          isLoading={isLoading}
          closeConfirmModal={closeConfirmModal}
          handleConfirmModal={handleConfirmModal}
        />
      )}
    </>
  );
}

export default ModalImportResources;
