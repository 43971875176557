import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import Icon from '../Icon';

type SearchProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearBtn?: () => void;
  iconName?: string;
  placeholder?: string;
  disabled?: boolean;
  externalClass?: string;
  defaultValue?: string;
  iconPosition?: 'left' | 'right';
  externalWrapperClass?: string;
  value?: string;
};

function Search({
  onChange,
  handleClearBtn,
  placeholder,
  disabled,
  externalClass,
  defaultValue,
  iconName = 'search',
  iconPosition = 'left',
  externalWrapperClass,
  value,
}: SearchProps) {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const ref = useRef<any>(null);
  const wrapperClass = classNames(
    'search',
    {
      clearable: handleClearBtn,
    },
    { [externalWrapperClass as string]: Boolean(externalWrapperClass) },
  );
  const defaultClass = classNames('search__input', {
    [externalClass as string]: Boolean(externalClass),
  });

  const iconClass = classNames('search__icon', {
    [externalClass as string]: Boolean(externalClass),
    'search__icon--left': iconPosition === 'left',
    'search__icon--right': iconPosition === 'right',
  });

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    setCurrentValue(e.target.value);
  };

  const handleClear = () => {
    handleClearBtn && handleClearBtn();
    ref.current.value = '';
    ref.current.focus();
    setCurrentValue('');
  };

  return (
    <div className={wrapperClass} onClick={() => ref.current.focus()}>
      <Icon iconName={iconName} externalClass={iconClass} />
      <input
        ref={ref}
        className={defaultClass}
        placeholder={placeholder}
        type="text"
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleValueChange}
        value={value}
      />
      {handleClearBtn && currentValue && (
        <span className="search__clear-btn" onClick={handleClear}>
          <Icon iconName="times-circle" />
        </span>
      )}
    </div>
  );
}

export default Search;
