import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Table from '../../../components/Table';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { QuestionType } from '../index';
import { useHistory, useParams } from 'react-router';
import * as competenciesActions from '../../../actions/competencies.actions';
import r from '../../../constants/routes.constants';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { TRouteParamId } from '../../../enums/common.enum';
import { checkPolicies } from '../../../utils/policies.utils';
import { UPDATE_SKILL_QUESTION } from '../../../constants/policies.constants';
import PoliciesContext from '../../../PoliciesContext';

function Questions({
  skillQuestionsData,
  skillQuestionsDataError,
  getSkillQuestions,
  cleanSkillQuestionsData,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const intl = useIntl();

  const history = useHistory();
  const { id } = useParams<TRouteParamId>();
  useEffect(() => {
    getSkillQuestions({ data: id });
    return () => {
      cleanSkillQuestionsData();
    };
  }, [id]);
  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.questionLabel),
        modifier: (row: QuestionType) => row.question,
      },
      {
        name: intl.formatMessage(messages.answersTitle),
        modifier: (row: QuestionType) => row.correctAnswer,
      },
    ],
    [],
  );

  const goBackHandler = useCallback(() => {
    history.push(r.skillQuestions);
  }, []);

  const onEditHandler = () => {
    history.push(r.editSkillQuestions.replace(':id', id));
  };
  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper fixed-container">
          <div className="page__panel__back-title">
            <Button externalClass="page__back" color="gray" onClick={goBackHandler}>
              <FormattedMessage {...messages.pageTitle} />
            </Button>
          </div>
          <div className="page__profile-panel__label-options">
            {checkPolicies([UPDATE_SKILL_QUESTION], policies) && (
              <Button circle color="gray" onClick={onEditHandler}>
                <Icon iconName={'pencil'} externalClass={'button__icon button__icon--bigger'} />
              </Button>
            )}
          </div>
        </div>
        <div className="page__panel__wrapper fixed-container title-wratter">
          <h1 className="page__title page__title--smaller">{skillQuestionsData?.name || ''}</h1>
        </div>
      </div>
      <div className="page__inner-content">
        <div className="page__wrapper fixed-container">
          {skillQuestionsData?.levelId && skillQuestionsData?.competenceId && (
            <div className="page__info-block page__info-block--info">
              <Icon iconName={'info'} />
              {`${skillQuestionsData.competenceName}, ${skillQuestionsData.levelName}`}
            </div>
          )}
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={skillQuestionsData?.questions || []}
            loading={false}
            error={skillQuestionsDataError}
            tableActions={[]}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ competencies }: RootState) => ({
  skillQuestionsData: competencies.skillQuestionsData,
  skillQuestionsDataError: competencies.errors.skillQuestionsDataError,
  skillsQuestionsTableDataError: competencies.errors.skillsQuestionsTableDataError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSkillQuestions: (id: any) => dispatch(competenciesActions.getSkillQuestions(id)),
  cleanSkillQuestionsData: () => dispatch(competenciesActions.cleanSkillQuestionsData()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Questions);
