import React, { memo, MutableRefObject } from 'react';
import { v4 } from 'uuid';
import { UserInfo } from '../../../../enums/users.enum';
import { useElementScroll } from '../../../../utils/hooks.utils';
import UserItem from './UserItem';

interface IEventPollRespondersProps {
  responders: UserInfo[];
  responderRef: MutableRefObject<any>;
  parentRef: MutableRefObject<any>;
  headerRef: MutableRefObject<any>;
  author: UserInfo;
  showStatus?: boolean;
}

const EventPollResponders = ({
  responders,
  author,
  responderRef,
  parentRef,
  headerRef,
  showStatus = false,
}: IEventPollRespondersProps) => {
  useElementScroll(responderRef, parentRef);

  return (
    <div ref={headerRef} id="event-poll-header" className="event-poll__content-header">
      <div className="polls__section--question-wrapper">
        <UserItem key={author.id} user={author} />
      </div>
      {responders?.map(responder => (
        <UserItem key={responder?.id || v4()} user={responder} showStatus={showStatus} />
      ))}
    </div>
  );
};

export default memo(EventPollResponders);
