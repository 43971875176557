import { ResumesParams } from './../enums/params/resumes.params';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/resumes.constants';

export const getResumesList = (): AnyAction => ({
  type: ActionTypes.GET_RESUMES_LIST_REQUEST,
});

export const editUserResume = (data: {
  resumes: { id: string; data: FormData }[];
  callback: () => void;
}): AnyAction => ({
  type: ActionTypes.PATCH_USER_RESUME_REQUEST,
  payload: data,
});

export const getResume = (uuid: string): AnyAction => ({
  type: ActionTypes.GET_RESUME_REQUEST,
  payload: uuid,
});

export const setResumesParams = (data: Partial<ResumesParams>): AnyAction => ({
  type: ActionTypes.SET_RESUMES_PARAMS,
  payload: data,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_RESUMES_STATE,
});
