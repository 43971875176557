import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import TableUserAvatar from '../../components/TableUserAvatar';
import { DATE_FORMAT } from '../../constants/date.constants';
import { DepthLevels } from '../../constants/tables.constants';
import {
  UsersHoursAbsencesReportDay,
  UsersHoursAbsencesReportEvent,
  UsersHoursAbsencesReportItem,
  UsersHoursAbsencesReportUser,
} from '../../enums/reports.enum';
import { UserInfo } from '../../enums/users.enum';
import { getForamtedTime } from '../../utils/reports.utils';
import { getTableCell, getTableCellContent } from '../../utils/table.utils';
import messages from './messages';

export const useDataForTable = (tableData: any) => {
  const intl = useIntl();
  const tableColumns = useMemo(() => {
    if (tableData?.departments.length) {
      const employeeColumns = [
        {
          id: 'name',
          Header: intl.formatMessage(messages.departmentsMembersLabel),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: UsersHoursAbsencesReportItem) => (
                  <div className={'table__data-wrapper'}>
                    <span>{row.totalItem ? <FormattedMessage {...messages.totalRow} /> : row.name}</span>
                  </div>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: UsersHoursAbsencesReportUser) => (
                  <div className={'table__data-wrapper'}>
                    <TableUserAvatar users={[new UserInfo({ ...row, id: row.portalId })]} fileSize={36} />
                  </div>
                ),
              },
            ]),
        },
        {
          id: 'position',
          Header: intl.formatMessage(messages.positionLabel),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.SECOND,
                content: (row: UsersHoursAbsencesReportUser) => (
                  <div className={'table__data-value'}>
                    <span>{getTableCellContent(row.position?.displayName)}</span>
                  </div>
                ),
              },
            ]),
        },
        {
          id: 'experience',
          Header: intl.formatMessage(messages.experienceLabel),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.SECOND,
                content: (row: UsersHoursAbsencesReportUser) => (
                  <div className={'table__data-value'}>
                    <span>{row.getUserExperiences}</span>
                  </div>
                ),
              },
            ]),
        },
        {
          id: 'planed',
          Header: intl.formatMessage(messages.plannedLabel),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: UsersHoursAbsencesReportItem) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.planHours)}h`}</span>
                  </div>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: UsersHoursAbsencesReportUser) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.planHours)}h`}</span>
                  </div>
                ),
              },
            ]),
        },
        {
          id: 'workLogHoursAtWorkDays',
          Header: intl.formatMessage(messages.submittedWorkDaysColumn),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: UsersHoursAbsencesReportItem) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.workLogHoursAtWorkDays)}h`}</span>
                  </div>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: UsersHoursAbsencesReportUser) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.workLogHoursAtWorkDays)}h`}</span>
                  </div>
                ),
              },
            ]),
        },
        {
          id: 'factHours',
          Header: intl.formatMessage(messages.submittedAllDaysColumn),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: UsersHoursAbsencesReportItem) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.factHours)}h`}</span>
                  </div>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: UsersHoursAbsencesReportUser) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.factHours)}h`}</span>
                  </div>
                ),
              },
            ]),
        },
        {
          id: 'delta',
          Header: intl.formatMessage(messages.shortageExcessColumn),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: UsersHoursAbsencesReportItem) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.delta)}h`}</span>
                  </div>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: UsersHoursAbsencesReportUser) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.delta)}h`}</span>
                  </div>
                ),
              },
            ]),
        },
      ];

      const dayColumns = tableData?.departments[0]?.users[0]?.days?.map(
        (day: UsersHoursAbsencesReportDay, dayIndex: number) => {
          return {
            id: `day-${dayIndex}`,
            Header: (
              <div className="day-display">
                {moment(day.date).format(DATE_FORMAT.D)}
                {(dayIndex === 0 || moment(day.date).startOf('month').isSame(day.date)) && (
                  <div
                    className={classNames('month-year-display', {
                      right: tableData?.departments[0]?.users[0]?.days.length - 1 === dayIndex,
                    })}
                  >
                    {moment(day.date).format(DATE_FORMAT.MMM_YYYY)}
                  </div>
                )}
              </div>
            ),
            Cell: ({ row }: any) =>
              getTableCell(row, [
                {
                  depth: DepthLevels.SECOND,
                  content: (row: UsersHoursAbsencesReportUser) => (
                    <div
                      className={classNames('table__data-value', {
                        'table__data-holiday': row.days[dayIndex].isWeekend,
                      })}
                      style={{
                        color: row.days[dayIndex].absenceColor,
                        background: row.days[dayIndex].absenceTranslucentColor,
                      }}
                    >
                      {`${getForamtedTime(row.days[dayIndex].workLogHours)}h`}
                    </div>
                  ),
                },
              ]),
          };
        },
      );

      const eventColumns = tableData?.daysOfTotal.map((event: UsersHoursAbsencesReportEvent, eventIndex: number) => ({
        id: `event-${event.eventTypeId}`,
        Header: <div className="day-display">{event.absenceNameLetter}</div>,
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UsersHoursAbsencesReportItem) => {
                const currentEvent = row.daysOf?.find(
                  (item: UsersHoursAbsencesReportEvent) => item.eventTypeId === event.eventTypeId,
                );
                return (
                  <div
                    className="table__data-value"
                    style={{
                      color: event?.eventTypeColor,
                      background: !!currentEvent?.typeHours ? event?.absenceTranslucentColor : 'null',
                    }}
                  >
                    {!!currentEvent?.typeHours ? `${getForamtedTime(currentEvent?.typeHours)}h` : '-'}
                  </div>
                );
              },
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UsersHoursAbsencesReportUser) => {
                const currentEvent = row.daysOf?.find(
                  (item: UsersHoursAbsencesReportEvent) => item.eventTypeId === event.eventTypeId,
                );
                return (
                  <div
                    className="table__data-value"
                    style={{
                      color: event?.eventTypeColor,
                      background: !!currentEvent?.typeHours ? event?.absenceTranslucentColor : 'null',
                    }}
                  >
                    {!!currentEvent?.typeHours ? `${getForamtedTime(currentEvent?.typeHours)}h` : '-'}
                  </div>
                );
              },
            },
          ]),
        externalColumnClass: () => 'event_column',
        externalColumnStyles: () => ({
          right: `${(tableData?.daysOfTotal.length - (eventIndex + 1)) * 50}px`, //
        }),
        headClassName: 'event_column',
        headerStyles: {
          right: `${(tableData?.daysOfTotal.length - (eventIndex + 1)) * 50}px`, //
          color: event?.eventTypeColor,
          background: event?.absenceTranslucentColor,
        },
      }));

      return [...employeeColumns, ...dayColumns, ...eventColumns];
    }
    return [];
  }, [tableData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: ' header_row',
      colspan: tableData?.departments[0]?.users[0].days?.length,
      sortName: '',
    },
    ...(tableData?.daysOfTotal.length
      ? [
          {
            name: intl.formatMessage(messages.absencesColumn),
            className: 'header_row header_row-event',
            colspan: tableData?.daysOfTotal.length,
            sortName: '',
            style: { width: `${tableData?.daysOfTotal.length * 50}px` },
          },
        ]
      : []),
  ];

  return {
    tableColumns,
    tableHeaderItems,
  };
};
