import { AnyAction } from 'redux';
import { OfficeInfo } from '../enums/libraries.enum';
import * as ActionTypes from '../constants/filters.constants';
import { FiltersParams } from '../enums/params.enum';
import * as librariesTypes from '../types/libraries';
import { UserInfo } from '../enums/users.enum';
import { HrCuratorsFilterParams, UsersFilterParams } from '../enums/params/users.params';
import { ProjectGroupType } from '../pages/ProjectGroups';
import { AssessmentsInfo, CompetenciesInfo } from '../enums/competencies.enum';
import { EventTypeInfo } from '../enums/calendar.enum';
import { NewPoll, PollTemplate } from '../enums/questionnaires.enum';
import { PollTemplateCategoryInfoType } from '../types/questionnaires';
import { EventPreviewInfo } from '../enums/schedule.enum';
import { RecognitionTypeInfoType } from '../types/recognitions';
import { CompetenciesTypeInfoType } from '../types/competencies';
import { Client, FinanceProject } from '../enums/finance/finance.enum';
import * as financeTypes from '../types/finance';
import { EmployeeGroup } from '../enums/notifications.enum';
import { getFilterValuesInSessionStorage } from '../utils';
import { CandidateStatus } from '../enums/candidates.enums';
import * as candidateTypes from '../types/candidates';
import { SavedFiltersDataType } from '../enums/filters.enum';
import { SavedFilterParams } from '../enums/params/filters.params';
import { getReducerErrors } from '../utils/reducerError.utils';
import { JiraProject } from '../types/jiraProjects';

type InitialStateType = {
  usersFilter: {
    users: UserInfo[];
    value: string[];
    params: UsersFilterParams;
    loading: boolean;
    errors: string | null;
  };
  officesFilter: {
    offices: OfficeInfo[];
    value: string[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  departmentsFilter: {
    departments: librariesTypes.DepartmentsInfoType[];
    value: string[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  projectGroupsFilter: {
    projectGroups: ProjectGroupType[];
    value: string[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  competenciesFilter: {
    competencies: CompetenciesInfo[];
    value: string[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  eventTypesFilter: {
    eventTypes: EventTypeInfo[];
    value: string[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  pollsFilter: {
    polls: NewPoll[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  pollTemplatesFilter: {
    templates: PollTemplate[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  pollTemplatesCategoryFilter: {
    categories: PollTemplateCategoryInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  eventsFilter: {
    events: EventPreviewInfo[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  recognitionTypesFilter: {
    recognitionTypes: RecognitionTypeInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  companyPositionsFilter: {
    positions: librariesTypes.CompanyPositionInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  languageLevelsFilter: {
    levels: librariesTypes.LanguageLevelInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  foreignLanguagesFilter: {
    languages: librariesTypes.ForeignLanguageInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  technicalSkillsFilter: {
    skills: librariesTypes.TechnicalSkillInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  projectsFilter: {
    projects: librariesTypes.ProjectInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  specializationsFilter: {
    specializations: librariesTypes.SpecializationInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  assessmentsFilter: {
    assessments: AssessmentsInfo[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  competenciesTypesFilter: {
    competenciesTypes: CompetenciesTypeInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  gradesFilter: {
    grades: librariesTypes.GradeInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  employeeGroupsFilter: {
    employeeGroups: EmployeeGroup[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  clientsFilter: {
    clients: Client[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  currenciesFilter: {
    currencies: financeTypes.CurrencyType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  projectTypesFilter: {
    projectTypes: financeTypes.ProjectType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  skillLevelsFilter: {
    skillLevels: librariesTypes.SkillLevelInfoType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  incomeTypesFilter: {
    incomeTypes: financeTypes.IncomeType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  expenseTypesFilter: {
    expenseTypes: financeTypes.ExpenseType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  suppliersFilter: {
    suppliers: financeTypes.SupplierType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  financeProjectsFilter: {
    financeProjects: FinanceProject[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  candidateStatusesFilter: {
    candidateStatuses: CandidateStatus[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  candidateTechnologiesFilter: {
    candidateTechnologies: candidateTypes.CandidateTechnologyType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  candidateSpecializationsFilter: {
    candidateSpecializations: candidateTypes.CandidateSpecializationType[];
    params: FiltersParams;
    loading: boolean;
    errors: string | null;
  };
  candidateReceivingSourcesFilter: {
    candidateReceivingSources: candidateTypes.CandidateReceivingSourceType[];
    params: FiltersParams;
  };
  hrCuratorsFilter: {
    hrCurators: UserInfo[];
    params: HrCuratorsFilterParams;
    loading: boolean;
    errors: string | null;
  };
  jiraProjectFilter: {
    jiraProject: JiraProject[];
    value: string[];
    loading: boolean;
    errors: string | null;
  };
  savedFilters: SavedFiltersDataType;
};

const initialState: InitialStateType = {
  usersFilter: {
    users: [],
    value: getFilterValuesInSessionStorage('usersFilterValue'),
    params: new UsersFilterParams(),
    loading: false,
    errors: null,
  },
  officesFilter: {
    offices: [],
    value: getFilterValuesInSessionStorage('officesFilterValue'),
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  departmentsFilter: {
    departments: [],
    value: getFilterValuesInSessionStorage('departmentsFilterValue'),
    params: new FiltersParams('displayName'),
    loading: false,
    errors: null,
  },
  projectGroupsFilter: {
    projectGroups: [],
    value: getFilterValuesInSessionStorage('projectGroupsFilterValue'),
    params: new FiltersParams('fullName'),
    loading: false,
    errors: null,
  },
  competenciesFilter: {
    competencies: [],
    value: getFilterValuesInSessionStorage('competenciesFilterValue'),
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  eventTypesFilter: {
    eventTypes: [],
    value: getFilterValuesInSessionStorage('eventTypesFilterValue'),
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  pollsFilter: {
    polls: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  pollTemplatesFilter: {
    templates: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  pollTemplatesCategoryFilter: {
    categories: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  eventsFilter: {
    events: [],
    params: new FiltersParams('startDate'),
    loading: false,
    errors: null,
  },
  recognitionTypesFilter: {
    recognitionTypes: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  companyPositionsFilter: {
    positions: [],
    params: new FiltersParams('priority'),
    loading: false,
    errors: null,
  },
  languageLevelsFilter: {
    levels: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  foreignLanguagesFilter: {
    languages: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  technicalSkillsFilter: {
    skills: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  projectsFilter: {
    projects: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  specializationsFilter: {
    specializations: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  assessmentsFilter: {
    assessments: [],
    params: new FiltersParams('assessmentDate'),
    loading: false,
    errors: null,
  },
  competenciesTypesFilter: {
    competenciesTypes: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  gradesFilter: {
    grades: [],
    params: new FiltersParams('level'),
    loading: false,
    errors: null,
  },
  employeeGroupsFilter: {
    employeeGroups: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  clientsFilter: {
    clients: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  currenciesFilter: {
    currencies: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  projectTypesFilter: {
    projectTypes: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  skillLevelsFilter: {
    skillLevels: [],
    params: new FiltersParams('priority'),
    loading: false,
    errors: null,
  },
  incomeTypesFilter: {
    incomeTypes: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  expenseTypesFilter: {
    expenseTypes: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  suppliersFilter: {
    suppliers: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  financeProjectsFilter: {
    financeProjects: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  candidateStatusesFilter: {
    candidateStatuses: [],
    params: new FiltersParams('position'),
    loading: false,
    errors: null,
  },
  candidateTechnologiesFilter: {
    candidateTechnologies: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  candidateSpecializationsFilter: {
    candidateSpecializations: [],
    params: new FiltersParams('name'),
    loading: false,
    errors: null,
  },
  candidateReceivingSourcesFilter: {
    candidateReceivingSources: [],
    params: new FiltersParams('name'),
  },
  hrCuratorsFilter: {
    hrCurators: [],
    params: new HrCuratorsFilterParams(),
    loading: false,
    errors: null,
  },
  jiraProjectFilter: {
    jiraProject: [],
    value: getFilterValuesInSessionStorage('jiraProjectsFilterValue'),
    loading: false,
    errors: null,
  },
  savedFilters: {
    filtersList: [],
    params: new SavedFilterParams(),
    loading: {
      getFiltersList: false,
      createFilter: false,
      editFilter: false,
      deleteFilter: false,
    },
    errors: null,
  },
};

const filtersReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_USERS_FILTER_REQUEST: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_USERS_FILTER_SUCCESS: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          users: action.payload.content.map((user: any) => new UserInfo(user)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_USERS_FILTER_FAILURE: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.SET_USERS_FILTER_VALUE: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          value: action.payload,
        },
      };
    }

    case ActionTypes.SET_USERS_FILTER_PARAMS: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          params: new UsersFilterParams(action.payload),
        },
      };
    }

    case ActionTypes.RESET_USERS_FILTER_PARAMS: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          params: initialState.usersFilter.params,
        },
      };
    }

    case ActionTypes.GET_OFFICES_FILTER_REQUEST: {
      return {
        ...state,
        officesFilter: {
          ...state.officesFilter,
          loading: true,
          errors: null,
        },
      };
    }
    case ActionTypes.GET_OFFICES_FILTER_SUCCESS: {
      return {
        ...state,
        officesFilter: {
          ...state.officesFilter,
          offices: action.payload.content.map((office: any) => new OfficeInfo(office)),
          loading: false,
          errors: null,
        },
      };
    }
    case ActionTypes.GET_OFFICES_FILTER_FAILURE: {
      return {
        ...state,
        officesFilter: {
          ...state.officesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.SET_OFFICES_FILTER_VALUE: {
      return {
        ...state,
        officesFilter: {
          ...state.officesFilter,
          value: action.payload,
        },
      };
    }

    case ActionTypes.GET_DEPARTMENTS_FILTER_REQUEST: {
      return {
        ...state,
        departmentsFilter: {
          ...state.departmentsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_DEPARTMENTS_FILTER_SUCCESS: {
      return {
        ...state,
        departmentsFilter: {
          ...state.departmentsFilter,
          departments: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_DEPARTMENTS_FILTER_FAILURE: {
      return {
        ...state,
        departmentsFilter: {
          ...state.departmentsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.SET_DEPARTMENTS_FILTER_VALUE: {
      return {
        ...state,
        departmentsFilter: {
          ...state.departmentsFilter,
          value: action.payload,
        },
      };
    }

    case ActionTypes.GET_PROJECT_GROUPS_FILTER_REQUEST: {
      return {
        ...state,
        projectGroupsFilter: {
          ...state.projectGroupsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_PROJECT_GROUPS_FILTER_SUCCESS: {
      return {
        ...state,
        projectGroupsFilter: {
          ...state.projectGroupsFilter,
          projectGroups: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_PROJECT_GROUPS_FILTER_FAILURE: {
      return {
        ...state,
        projectGroupsFilter: {
          ...state.projectGroupsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.SET_PROJECT_GROUPS_FILTER_VALUE: {
      return {
        ...state,
        projectGroupsFilter: {
          ...state.projectGroupsFilter,
          value: action.payload,
        },
      };
    }

    case ActionTypes.GET_COMPETENCIES_FILTER_REQUEST: {
      return {
        ...state,
        competenciesFilter: {
          ...state.competenciesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_COMPETENCIES_FILTER_SUCCESS: {
      return {
        ...state,
        competenciesFilter: {
          ...state.competenciesFilter,
          competencies: action.payload.content.map((el: any) => new CompetenciesInfo(el)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_COMPETENCIES_FILTER_FAILURE: {
      return {
        ...state,
        competenciesFilter: {
          ...state.competenciesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.SET_COMPETENCIES_FILTER_VALUE: {
      return {
        ...state,
        competenciesFilter: {
          ...state.competenciesFilter,
          value: action.payload,
        },
      };
    }

    case ActionTypes.GET_EVENT_TYPES_FILTER_REQUEST: {
      return {
        ...state,
        eventTypesFilter: {
          ...state.eventTypesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_EVENT_TYPES_FILTER_SUCCESS: {
      return {
        ...state,
        eventTypesFilter: {
          ...state.eventTypesFilter,
          eventTypes: action.payload.content.map((el: any) => new EventTypeInfo(el)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_EVENT_TYPES_FILTER_FAILURE: {
      return {
        ...state,
        eventTypesFilter: {
          ...state.eventTypesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.SET_EVENT_TYPES_FILTER_VALUE: {
      return {
        ...state,
        eventTypesFilter: {
          ...state.eventTypesFilter,
          value: action.payload,
        },
      };
    }

    case ActionTypes.GET_POLLS_FILTER_REQUEST: {
      return {
        ...state,
        pollsFilter: {
          ...state.pollsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_POLLS_FILTER_SUCCESS: {
      return {
        ...state,
        pollsFilter: {
          ...state.pollsFilter,
          polls: action.payload.content.map((el: any) => new NewPoll(el)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_POLLS_FILTER_FAILURE: {
      return {
        ...state,
        pollsFilter: {
          ...state.pollsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_POLL_TEMPLATES_FILTER_REQUEST: {
      return {
        ...state,
        pollTemplatesFilter: {
          ...state.pollTemplatesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_POLL_TEMPLATES_FILTER_SUCCESS: {
      return {
        ...state,
        pollTemplatesFilter: {
          ...state.pollTemplatesFilter,
          templates: action.payload.content.map((el: any) => new PollTemplate(el)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_POLL_TEMPLATES_FILTER_FAILURE: {
      return {
        ...state,
        pollTemplatesFilter: {
          ...state.pollTemplatesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_POLL_TEMPLATES_CATEGORY_FILTER_REQUEST: {
      return {
        ...state,
        pollTemplatesCategoryFilter: {
          ...state.pollTemplatesCategoryFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_POLL_TEMPLATES_CATEGORY_FILTER_SUCCESS: {
      return {
        ...state,
        pollTemplatesCategoryFilter: {
          ...state.pollTemplatesCategoryFilter,
          categories: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_POLL_TEMPLATES_CATEGORY_FILTER_FAILURE: {
      return {
        ...state,
        pollTemplatesCategoryFilter: {
          ...state.pollTemplatesCategoryFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_EVENTS_FILTER_REQUEST: {
      return {
        ...state,
        eventsFilter: {
          ...state.eventsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_EVENTS_FILTER_SUCCESS: {
      return {
        ...state,
        eventsFilter: {
          ...state.eventsFilter,
          events: action.payload.content.map((el: any) => new EventPreviewInfo(el)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_EVENTS_FILTER_FAILURE: {
      return {
        ...state,
        eventsFilter: {
          ...state.eventsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_RECOGNITION_TYPES_FILTER_REQUEST: {
      return {
        ...state,
        recognitionTypesFilter: {
          ...state.recognitionTypesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_RECOGNITION_TYPES_FILTER_SUCCESS: {
      return {
        ...state,
        recognitionTypesFilter: {
          ...state.recognitionTypesFilter,
          recognitionTypes: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_RECOGNITION_TYPES_FILTER_FAILURE: {
      return {
        ...state,
        recognitionTypesFilter: {
          ...state.recognitionTypesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_COMPANY_POSITIONS_FILTER_REQUEST: {
      return {
        ...state,
        companyPositionsFilter: {
          ...state.companyPositionsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_COMPANY_POSITIONS_FILTER_SUCCESS: {
      return {
        ...state,
        companyPositionsFilter: {
          ...state.companyPositionsFilter,
          positions: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_COMPANY_POSITIONS_FILTER_FAILURE: {
      return {
        ...state,
        companyPositionsFilter: {
          ...state.companyPositionsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_LANGUAGE_LEVELS_FILTER_REQUEST: {
      return {
        ...state,
        languageLevelsFilter: {
          ...state.languageLevelsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_LANGUAGE_LEVELS_FILTER_SUCCESS: {
      return {
        ...state,
        languageLevelsFilter: {
          ...state.languageLevelsFilter,
          levels: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_LANGUAGE_LEVELS_FILTER_FAILURE: {
      return {
        ...state,
        languageLevelsFilter: {
          ...state.languageLevelsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_FOREIGN_LANGUAGES_FILTER_REQUEST: {
      return {
        ...state,
        foreignLanguagesFilter: {
          ...state.foreignLanguagesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_FOREIGN_LANGUAGES_FILTER_SUCCESS: {
      return {
        ...state,
        foreignLanguagesFilter: {
          ...state.foreignLanguagesFilter,
          languages: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_FOREIGN_LANGUAGES_FILTER_FAILURE: {
      return {
        ...state,
        foreignLanguagesFilter: {
          ...state.foreignLanguagesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_TECHNICAL_SKILLS_FILTER_REQUEST: {
      return {
        ...state,
        technicalSkillsFilter: {
          ...state.technicalSkillsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_TECHNICAL_SKILLS_FILTER_SUCCESS: {
      return {
        ...state,
        technicalSkillsFilter: {
          ...state.technicalSkillsFilter,
          skills: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_TECHNICAL_SKILLS_FILTER_FAILURE: {
      return {
        ...state,
        technicalSkillsFilter: {
          ...state.technicalSkillsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_PROJECTS_FILTER_REQUEST: {
      return {
        ...state,
        projectsFilter: {
          ...state.projectsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_PROJECTS_FILTER_SUCCESS: {
      return {
        ...state,
        projectsFilter: {
          ...state.projectsFilter,
          projects: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_PROJECTS_FILTER_FAILURE: {
      return {
        ...state,
        projectsFilter: {
          ...state.projectsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_SPECIALIZATIONS_FILTER_REQUEST: {
      return {
        ...state,
        specializationsFilter: {
          ...state.specializationsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_SPECIALIZATIONS_FILTER_SUCCESS: {
      return {
        ...state,
        specializationsFilter: {
          ...state.specializationsFilter,
          specializations: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_SPECIALIZATIONS_FILTER_FAILURE: {
      return {
        ...state,
        specializationsFilter: {
          ...state.specializationsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_ASSESSMENTS_FILTER_REQUEST: {
      return {
        ...state,
        assessmentsFilter: {
          ...state.assessmentsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_ASSESSMENTS_FILTER_SUCCESS: {
      return {
        ...state,
        assessmentsFilter: {
          ...state.assessmentsFilter,
          assessments: action.payload.content.map((el: any) => new AssessmentsInfo(el)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_ASSESSMENTS_FILTER_FAILURE: {
      return {
        ...state,
        assessmentsFilter: {
          ...state.assessmentsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_COMPETENCIES_TYPES_FILTER_REQUEST: {
      return {
        ...state,
        competenciesTypesFilter: {
          ...state.competenciesTypesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_COMPETENCIES_TYPES_FILTER_SUCCESS: {
      return {
        ...state,
        competenciesTypesFilter: {
          ...state.competenciesTypesFilter,
          competenciesTypes: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_COMPETENCIES_TYPES_FILTER_FAILURE: {
      return {
        ...state,
        competenciesTypesFilter: {
          ...state.competenciesTypesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_GRADES_FILTER_REQUEST: {
      return {
        ...state,
        gradesFilter: {
          ...state.gradesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_GRADES_FILTER_SUCCESS: {
      return {
        ...state,
        gradesFilter: {
          ...state.gradesFilter,
          grades: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_GRADES_FILTER_FAILURE: {
      return {
        ...state,
        gradesFilter: {
          ...state.gradesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_EMPLOYEE_GROUPS_FILTER_REQUEST: {
      return {
        ...state,
        employeeGroupsFilter: {
          ...state.employeeGroupsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_EMPLOYEE_GROUPS_FILTER_SUCCESS: {
      return {
        ...state,
        employeeGroupsFilter: {
          ...state.employeeGroupsFilter,
          employeeGroups: action.payload.content.map((el: any) => new EmployeeGroup(el)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_EMPLOYEE_GROUPS_FILTER_FAILURE: {
      return {
        ...state,
        employeeGroupsFilter: {
          ...state.employeeGroupsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_CLIENTS_FILTER_REQUEST: {
      return {
        ...state,
        clientsFilter: {
          ...state.clientsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CLIENTS_FILTER_SUCCESS: {
      return {
        ...state,
        clientsFilter: {
          ...state.clientsFilter,
          clients: action.payload.content.map((el: any) => new Client(el)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CLIENTS_FILTER_FAILURE: {
      return {
        ...state,
        clientsFilter: {
          ...state.clientsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_CURRENCIES_FILTER_REQUEST: {
      return {
        ...state,
        currenciesFilter: {
          ...state.currenciesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CURRENCIES_FILTER_SUCCESS: {
      return {
        ...state,
        currenciesFilter: {
          ...state.currenciesFilter,
          currencies: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CURRENCIES_FILTER_FAILURE: {
      return {
        ...state,
        currenciesFilter: {
          ...state.currenciesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_PROJECT_TYPES_FILTER_REQUEST: {
      return {
        ...state,
        projectTypesFilter: {
          ...state.projectTypesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_PROJECT_TYPES_FILTER_SUCCESS: {
      return {
        ...state,
        projectTypesFilter: {
          ...state.projectTypesFilter,
          projectTypes: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_PROJECT_TYPES_FILTER_FAILURE: {
      return {
        ...state,
        projectTypesFilter: {
          ...state.projectTypesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_SKILL_LEVELS_FILTER_REQUEST: {
      return {
        ...state,
        skillLevelsFilter: {
          ...state.skillLevelsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_SKILL_LEVELS_FILTER_SUCCESS: {
      return {
        ...state,
        skillLevelsFilter: {
          ...state.skillLevelsFilter,
          skillLevels: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_SKILL_LEVELS_FILTER_FAILURE: {
      return {
        ...state,
        skillLevelsFilter: {
          ...state.skillLevelsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_INCOME_TYPES_FILTER_REQUEST: {
      return {
        ...state,
        incomeTypesFilter: {
          ...state.incomeTypesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_INCOME_TYPES_FILTER_SUCCESS: {
      return {
        ...state,
        incomeTypesFilter: {
          ...state.incomeTypesFilter,
          incomeTypes: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_INCOME_TYPES_FILTER_FAILURE: {
      return {
        ...state,
        incomeTypesFilter: {
          ...state.incomeTypesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_EXPENSE_TYPES_FILTER_REQUEST: {
      return {
        ...state,
        expenseTypesFilter: {
          ...state.expenseTypesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_EXPENSE_TYPES_FILTER_SUCCESS: {
      return {
        ...state,
        expenseTypesFilter: {
          ...state.expenseTypesFilter,
          expenseTypes: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_EXPENSE_TYPES_FILTER_FAILURE: {
      return {
        ...state,
        expenseTypesFilter: {
          ...state.expenseTypesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_SUPPLIERS_FILTER_REQUEST: {
      return {
        ...state,
        suppliersFilter: {
          ...state.suppliersFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_SUPPLIERS_FILTER_SUCCESS: {
      return {
        ...state,
        suppliersFilter: {
          ...state.suppliersFilter,
          suppliers: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_SUPPLIERS_FILTER_FAILURE: {
      return {
        ...state,
        suppliersFilter: {
          ...state.suppliersFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_FINANCE_PROJECTS_FILTER_REQUEST: {
      return {
        ...state,
        financeProjectsFilter: {
          ...state.financeProjectsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_FINANCE_PROJECTS_FILTER_SUCCESS: {
      return {
        ...state,
        financeProjectsFilter: {
          ...state.financeProjectsFilter,
          financeProjects: action.payload.content.map((project: any) => new FinanceProject(project)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_FINANCE_PROJECTS_FILTER_FAILURE: {
      return {
        ...state,
        financeProjectsFilter: {
          ...state.financeProjectsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_HR_CURATORS_FILTER_REQUEST: {
      return {
        ...state,
        hrCuratorsFilter: {
          ...state.hrCuratorsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_HR_CURATORS_FILTER_SUCCESS: {
      return {
        ...state,
        hrCuratorsFilter: {
          ...state.hrCuratorsFilter,
          hrCurators: action.payload.content.map((user: any) => new UserInfo(user)),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_HR_CURATORS_FILTER_FAILURE: {
      return {
        ...state,
        hrCuratorsFilter: {
          ...state.hrCuratorsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_STATUSES_FILTER_REQUEST: {
      return {
        ...state,
        candidateStatusesFilter: {
          ...state.candidateStatusesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_STATUSES_FILTER_SUCCESS: {
      return {
        ...state,
        candidateStatusesFilter: {
          ...state.candidateStatusesFilter,
          candidateStatuses: action.payload.content.map(
            (status: Partial<CandidateStatus>) => new CandidateStatus(status),
          ),
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_STATUSES_FILTER_FAILURE: {
      return {
        ...state,
        candidateStatusesFilter: {
          ...state.candidateStatusesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_TECHNOLOGIES_FILTER_REQUEST: {
      return {
        ...state,
        candidateTechnologiesFilter: {
          ...state.candidateTechnologiesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_TECHNOLOGIES_FILTER_SUCCESS: {
      return {
        ...state,
        candidateTechnologiesFilter: {
          ...state.candidateTechnologiesFilter,
          candidateTechnologies: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_TECHNOLOGIES_FILTER_FAILURE: {
      return {
        ...state,
        candidateTechnologiesFilter: {
          ...state.candidateTechnologiesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_FILTER_REQUEST: {
      return {
        ...state,
        candidateSpecializationsFilter: {
          ...state.candidateSpecializationsFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_FILTER_SUCCESS: {
      return {
        ...state,
        candidateSpecializationsFilter: {
          ...state.candidateSpecializationsFilter,
          candidateSpecializations: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_FILTER_FAILURE: {
      return {
        ...state,
        candidateSpecializationsFilter: {
          ...state.candidateSpecializationsFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_FILTER_REQUEST: {
      return {
        ...state,
        candidateReceivingSourcesFilter: {
          ...state.candidateReceivingSourcesFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_FILTER_SUCCESS: {
      return {
        ...state,
        candidateReceivingSourcesFilter: {
          ...state.candidateReceivingSourcesFilter,
          candidateReceivingSources: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_FILTER_FAILURE: {
      return {
        ...state,
        candidateReceivingSourcesFilter: {
          ...state.candidateReceivingSourcesFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.GET_JIRA_PROJECTS_FILTER_REQUEST: {
      return {
        ...state,
        jiraProjectFilter: {
          ...state.jiraProjectFilter,
          loading: true,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_JIRA_PROJECTS_FILTER_SUCCESS: {
      return {
        ...state,
        jiraProjectFilter: {
          ...state.jiraProjectFilter,
          jiraProject: action.payload.content,
          loading: false,
          errors: null,
        },
      };
    }

    case ActionTypes.GET_JIRA_PROJECTS_FILTER_FAILURE: {
      return {
        ...state,
        jiraProjectFilter: {
          ...state.jiraProjectFilter,
          loading: false,
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.SET_JIRA_PROJECTS_FILTER_VALUE: {
      return {
        ...state,
        jiraProjectFilter: {
          ...state.jiraProjectFilter,
          value: action.payload,
        },
      };
    }

    case ActionTypes.RESET_EVENTS_FILTER: {
      return {
        ...state,
        eventsFilter: initialState.eventsFilter,
      };
    }

    case ActionTypes.RESET_ASSESSMENTS_FILTER: {
      return {
        ...state,
        assessmentsFilter: initialState.assessmentsFilter,
      };
    }

    case ActionTypes.RESET_FILTERS_STATE: {
      return {
        ...initialState,
        usersFilter: {
          ...initialState.usersFilter,
          value: state.usersFilter.value,
        },
        officesFilter: {
          ...initialState.officesFilter,
          value: state.officesFilter.value,
        },
        departmentsFilter: {
          ...initialState.departmentsFilter,
          value: state.departmentsFilter.value,
        },
        projectGroupsFilter: {
          ...initialState.projectGroupsFilter,
          value: state.projectGroupsFilter.value,
        },
        competenciesFilter: {
          ...initialState.competenciesFilter,
          value: state.competenciesFilter.value,
        },
        eventTypesFilter: {
          ...initialState.eventTypesFilter,
          value: state.eventTypesFilter.value,
        },
      };
    }

    case ActionTypes.SET_SAVED_FILTERS_LIST_PARAMS: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          params: new SavedFilterParams(action.payload),
          filtersList: [],
          loading: { ...state.savedFilters.loading, getFiltersList: true },
          errors: null,
        },
      };
    }

    case ActionTypes.GET_SAVED_FILTERS_LIST_SUCCESS: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          filtersList: action.payload.content,
          loading: { ...state.savedFilters.loading, getFiltersList: false },
          errors: null,
        },
      };
    }

    case ActionTypes.GET_SAVED_FILTERS_LIST_FAILURE: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, getFiltersList: false },
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.CREATE_SAVED_FILTER_REQUEST: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, createFilter: true },
          errors: null,
        },
      };
    }

    case ActionTypes.CREATE_SAVED_FILTER_SUCCESS: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, createFilter: false },
          errors: null,
        },
      };
    }

    case ActionTypes.CREATE_SAVED_FILTER_FAILURE: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, createFilter: false },
          errors: getReducerErrors(action.payload),
        },
      };
    }

    case ActionTypes.EDIT_SAVED_FILTER_REQUEST: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, editFilter: true },
          errors: null,
        },
      };
    }

    case ActionTypes.EDIT_SAVED_FILTER_SUCCESS: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, editFilter: false },
          errors: null,
        },
      };
    }

    case ActionTypes.EDIT_SAVED_FILTER_FAILURE: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, editFilter: false },
          errors: getReducerErrors(action.payload),
        },
      };
    }

    case ActionTypes.DELETE_SAVED_FILTER_REQUEST: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, deleteFilter: true },
          errors: null,
        },
      };
    }

    case ActionTypes.DELETE_SAVED_FILTER_SUCCESS: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, deleteFilter: false },
          errors: null,
        },
      };
    }

    case ActionTypes.DELETE_SAVED_FILTER_FAILURE: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          loading: { ...state.savedFilters.loading, deleteFilter: false },
          errors: action.payload.message,
        },
      };
    }

    case ActionTypes.RESET_SAVED_FILTER_ERRORS: {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          errors: null,
        },
      };
    }

    default:
      return state;
  }
};

export default filtersReducer;
