import { defineMessages } from 'react-intl';
import {
  nameColumn,
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  newPositionTitle: {
    id: 'libraries.company.positions.modal.new.position.title.new.position',
    defaultMessage: 'New Position',
  },
  editPositionTitle: {
    id: 'libraries.company.positions.modal.edit.position.title.edit.position',
    defaultMessage: 'Edit Position',
  },
  gredeColumn: {
    id: 'libraries.company.positions.modal.edit.position.label.grade',
    defaultMessage: 'Grade',
  },
});
