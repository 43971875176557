import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/policies.constants';
import { PolicyGroupWithUsersLevel } from '../enums/policies.enum';
import { UserPreviewInfo } from '../enums/users.enum';
import * as paramsTypes from './../enums/params/policies.params';

const initialState = {
  policyGroup: [],
  currentUserPolicies: null,
  authUserPolicies: null,
  policyGroupWithUsers: [],
  policyModule: null,
  policyGroupParams: new paramsTypes.PolicyGroupParams(),
  policyModuleParams: new paramsTypes.PolicyModuleParams(),
  loading: {
    policyGroup: false,
    policyGroupWithUsers: false,
    getCurrentUserPolicies: false,
    updateUserPolicies: false,
    getAuthUserPolicies: false,
    updateAllUsersPolicies: false,
    policyModule: false,
  },
  errors: {
    policyGroup: null,
    policyGroupWithUsers: null,
    getCurrentUserPolicies: null,
    updateUserPolicies: null,
    getAuthUserPolicies: null,
    updateAllUsersPolicies: null,
    policyModule: null,
  },
};

const policiesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_POLICY_GROUP_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, policyGroup: true },
        errors: { ...state.errors, policyGroup: null },
      };

    case ActionTypes.GET_POLICY_GROUP_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, policyGroup: false },
        errors: { ...state.errors, policyGroup: null },
        policyGroup: action.payload,
      };

    case ActionTypes.GET_POLICY_GROUP_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, policyGroup: false },
        errors: { ...state.errors, policyGroup: action.payload.message },
      };

    case ActionTypes.GET_CURRENT_USER_POLICIES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCurrentUserPolicies: true },
        errors: { ...state.errors, getCurrentUserPolicies: null },
      };

    case ActionTypes.GET_CURRENT_USER_POLICIES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCurrentUserPolicies: false },
        errors: { ...state.errors, getCurrentUserPolicies: null },
        currentUserPolicies: action.payload,
      };

    case ActionTypes.GET_CURRENT_USER_POLICIES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getCurrentUserPolicies: false },
        errors: { ...state.errors, getCurrentUserPolicies: action.payload.message },
      };

    case ActionTypes.PUT_USER_POLICIES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, updateUserPolicies: true },
        errors: { ...state.errors, updateUserPolicies: null },
      };

    case ActionTypes.PUT_USER_POLICIES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateUserPolicies: false },
        errors: { ...state.errors, updateUserPolicies: null },
        currentUserPolicies: action.payload,
      };

    case ActionTypes.PUT_USER_POLICIES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, updateUserPolicies: false },
        errors: { ...state.errors, updateUserPolicies: action.payload.message },
      };

    case ActionTypes.RESET_POLICIES_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.GET_AUTH_USER_POLICIES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getAuthUserPolicies: true },
        errors: { ...state.errors, getAuthUserPolicies: null },
      };

    case ActionTypes.GET_AUTH_USER_POLICIES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAuthUserPolicies: false },
        errors: { ...state.errors, getAuthUserPolicies: null },
        authUserPolicies: action.payload,
      };

    case ActionTypes.GET_AUTH_USER_POLICIES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getAuthUserPolicies: false },
        errors: { ...state.errors, getAuthUserPolicies: action.payload.message },
      };

    case ActionTypes.GET_POLICY_GROUP_WITH_USERS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, policyGroupWithUsers: true },
        errors: { ...state.errors, policyGroupWithUsers: null },
      };

    case ActionTypes.GET_POLICY_GROUP_WITH_USERS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, policyGroupWithUsers: false },
        errors: { ...state.errors, policyGroupWithUsers: null },
        policyGroupWithUsers: action.payload?.content.map((item: any) => new PolicyGroupWithUsersLevel(item)),
      };

    case ActionTypes.GET_POLICY_GROUP_WITH_USERS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, policyGroupWithUsers: false },
        errors: { ...state.errors, policyGroupWithUsers: action.payload.message },
      };

    case ActionTypes.SET_POLICY_GROUP_PARAMS:
      return {
        ...state,
        policyGroupParams: new paramsTypes.PolicyGroupParams({ ...state.policyGroupParams, ...action.payload }),
        loading: { ...state.loading, policyGroup: true },
      };

    case ActionTypes.PATCH_USERS_POLICIES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, updateAllUsersPolicies: true },
        errors: { ...state.errors, updateAllUsersPolicies: null },
      };

    case ActionTypes.PATCH_USERS_POLICIES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateAllUsersPolicies: false },
        errors: { ...state.errors, updateAllUsersPolicies: null },
      };

    case ActionTypes.PATCH_USERS_POLICIES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, updateAllUsersPolicies: false },
        errors: { ...state.errors, updateAllUsersPolicies: action.payload.message },
      };

    case ActionTypes.SET_POLICY_MODULE_PARAMS:
      return {
        ...state,
        policyModuleParams: new paramsTypes.PolicyModuleParams({ ...state.policyModuleParams, ...action.payload }),
        errors: { ...state.errors, policyModule: null },
        loading: { ...state.loading, policyModule: true },
      };

    case ActionTypes.GET_POLICY_MODULE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, policyModule: false },
        errors: { ...state.errors, policyModule: null },
        policyModule: {
          ...action.payload,
          users: action.payload?.users.map((item: any) => ({
            ...item,
            userPreviewDto: new UserPreviewInfo(item.userPreviewDto),
          })),
        },
      };

    case ActionTypes.GET_POLICY_MODULE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, policyModule: false },
        errors: { ...state.errors, policyModule: action.payload.message },
      };

    case ActionTypes.RESET_POLICY_MODULE_PARAMS:
      return {
        ...state,
        policyModuleParams: initialState.policyModuleParams,
      };

    case ActionTypes.RESET_POLICY_MODULE_DATA:
      return {
        ...state,
        policyModule: initialState.policyModule,
      };

    default:
      return state;
  }
};

export default policiesReducer;
