import React, { useCallback, useEffect } from 'react';
import messages from '../messages';
import { UPDATE_CANDIDATE_COMMENT } from '../../../constants/policies.constants';
import { ConnectedProps, connect } from 'react-redux';
import Comments, { ManageCommentFuncType } from '../../Comments';
import * as candidatesActions from '../../../actions/candidates.actions';
import { initialComment } from '../utilts';
import { CandidateComment } from '../../../enums/candidates.enums';
import { TRouteParamId } from '../../../enums/common.enum';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

function ProfileComments({
  userInfo,
  comments,
  loading,
  getCandidateComments,
  createCandidateComment,
  editCandidateComment,
  deleteCandidateComment,
}: ConnectedProps<typeof connector>) {
  const { id } = useParams<TRouteParamId>();
  const intl = useIntl();

  useEffect(() => {
    getCandidateComments({
      candidateId: id,
      params: {
        page: 0,
        size: comments.size,
      },
    });
  }, [id]);

  const getEventCommentsPaginate = useCallback(() => {
    getCandidateComments({
      candidateId: id,
      params: {
        page: comments.number,
        size: comments.size,
      },
    });
  }, [comments.number, comments.size]);

  const handleCreateComment = useCallback(({ data, replyToCommentId, cb }: ManageCommentFuncType<CandidateComment>) => {
    createCandidateComment({
      candidateId: id,
      replyToCommentId,
      data,
      cb,
    });
  }, []);

  const handleEditComment = useCallback(({ data, cb }: ManageCommentFuncType<CandidateComment>) => {
    editCandidateComment({
      uuid: data.id,
      data: data.message,
      cb,
    });
  }, []);

  const handleDeleteComment = useCallback(
    ({
      data,
      loadSize,
      commentClickedPage,
      cb,
    }: {
      data: CandidateComment;
      loadSize: number;
      commentClickedPage: number;
      cb: () => void;
    }) => {
      deleteCandidateComment({
        uuid: data.id,
        candidateId: id,
        size: loadSize,
        commentPage: commentClickedPage,
        cb,
      });
    },
    [],
  );

  return (
    <div className="tabs__content-item active tabs__content-item--comments">
      <Comments
        isReplayed
        userInfo={userInfo}
        title={intl.formatMessage(messages.commentsTitle)}
        comments={comments.content}
        totalElements={comments.totalElements}
        initialComment={initialComment}
        policyToCreateComment={UPDATE_CANDIDATE_COMMENT}
        hasMore={!comments.last}
        createLoading={loading.createComment}
        editLoading={loading.editComment}
        deleteLoading={loading.deleteComment}
        loadSize={comments.size}
        scrollId="comments"
        showTitleIcon={false}
        getCommentsPaginate={getEventCommentsPaginate}
        createComment={handleCreateComment}
        editComment={handleEditComment}
        deleteComment={handleDeleteComment}
      />
    </div>
  );
}

const mapStateToProps = ({ candidates, auth }: RootState) => ({
  userInfo: auth.currentUserInfo,
  comments: candidates.comments,
  loading: candidates.loading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateComments: (data: { candidateId: string; params: any; afterDelete?: boolean }) =>
    dispatch(candidatesActions.getCandidateComments(data)),
  createCandidateComment: (data: {
    candidateId: string;
    replyToCommentId?: string;
    data: CandidateComment;
    cb: () => void;
  }) => dispatch(candidatesActions.createCandidateComment(data)),
  editCandidateComment: (data: { uuid: string; data: string; cb: () => void }) =>
    dispatch(candidatesActions.editCandidateComment(data)),
  deleteCandidateComment: (data: {
    uuid: string;
    cb: () => void;
    candidateId: string;
    size: number;
    commentPage: number;
  }) => dispatch(candidatesActions.deleteCandidateComment(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfileComments);
