import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { useFormik } from 'formik';
import Input from '../../Input';
import Select from '../../Select';
import classNames from 'classnames';
import Icon from '../../Icon';
import { ReactSortable } from 'react-sortablejs';
import Checkbox from '../../Checkbox';
import { Resume } from '../../../enums/resumes.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Avatar from '../../Profile/Avatar';
import moment from 'moment';
import CVDocument from '../CVDocument/CVDocument';
import ErrorMessage from '../../ErrorMessage';
import EditResumeTabs from '../EditResumeTabs';
import RadioButton from '../../RadioButton';
import AccessChecker from '../../AccessChecker';
import { UPDATE_RESUME } from '../../../constants/policies.constants';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ExportCVDocument from '../ExportCVDocument/ExportCVDocument';
import { EXPORT_RESUME_MODAL_TABS, FILE_EXPORT_OPTIONS } from '../../../constants/resumes.constants';
import {
  CompanyPositionInfoType,
  ForeignLanguageInfoType,
  LanguageLevelInfoType,
  ProjectInfoType,
  TechnicalSkillInfoType,
} from '../../../types/libraries';

type ModalExportResumesProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  updateResume: (resumes: { id: string; data: FormData }[]) => void;
  resumeError: string | null;
  isLoading: boolean;
  resumesList: Resume[];
  positions: CompanyPositionInfoType[];
  languageLevels: LanguageLevelInfoType[];
  foreignLanguages: ForeignLanguageInfoType[];
  technicalSkills: TechnicalSkillInfoType[];
  projectsList: ProjectInfoType[];
  getLanguageLevelsFilter: () => void;
  getForeignLanguagesFilter: () => void;
  getTechnicalSkillsFilter: () => void;
  getProjectsFilter: () => void;
  getCompanyPositionsFilter: () => void;
};

function ModalExportResumes({
  isOpen,
  onCloseRequest,
  updateResume,
  resumeError,
  isLoading,
  resumesList,
  positions,
  languageLevels,
  foreignLanguages,
  technicalSkills,
  projectsList,
  getLanguageLevelsFilter,
  getForeignLanguagesFilter,
  getTechnicalSkillsFilter,
  getProjectsFilter,
  getCompanyPositionsFilter,
}: ModalExportResumesProps) {
  const intl = useIntl();

  const pdfRefs = useRef<HTMLInputElement[]>([]);

  const [activeTab, setActiveTab] = useState(EXPORT_RESUME_MODAL_TABS.CONTENT_CV);
  const [selectedResumeNumber, setSelectedResumeNumber] = useState(0);
  const [selectedResumeId, setSelectedResumeId] = useState(resumesList[0]?.id);

  useEffect(() => {
    getLanguageLevelsFilter();
    getForeignLanguagesFilter();
    getProjectsFilter();
    getCompanyPositionsFilter();
    getTechnicalSkillsFilter();
  }, []);

  const { values, handleChange, handleSubmit, setFieldValue, submitForm } = useFormik({
    initialValues: {
      resumesList: resumesList,
      saveChanges: false,
      includePhoto: true,
      includeName: true,
      includePosition: true,
      includeExperience: true,
      includeEducation: true,
      includeLanguages: true,
      includeSummary: true,
      includePersonalHighlights: true,
      includeInterestsAndHobbies: true,
      includeTechnicalSkills: true,
      includeProjects: true,
      exportType: FILE_EXPORT_OPTIONS.ONE_FILE,
    },
    validationSchema: '',
    onSubmit: data => {
      if (data.saveChanges) {
        updateResume(
          data.resumesList.map(resume => {
            resume.projects = resume.projects.filter(item => item.id !== 'createdProject');
            const formData = new FormData();
            resume.deletePhotoFlag &&
              formData.append(`deletePhotoFlag`, new Blob([JSON.stringify('true')], { type: 'application/json' }));
            resume.cropSetting &&
              formData.append(
                'cropSetting',
                new Blob([JSON.stringify(resume.photo.cropSetting)], { type: 'application/json' }),
              );
            resume.photoFile && formData.append('file', resume.photoFile);
            //@ts-ignore
            delete resume.photoFile;
            //@ts-ignore
            delete resume.photo;

            formData.append(
              'dto',
              new Blob(
                [JSON.stringify({ ...resume, languages: resume.languages.filter(item => item.foreignLanguage) })],
                {
                  type: 'application/json',
                },
              ),
            );
            return { data: formData, id: resume.employee.id };
          }),
        );
      } else {
        onCloseRequest();
      }
    },
  });

  const displayingResumeSections = useMemo(
    () => ({
      includePhoto: values.includePhoto,
      includeName: values.includeName,
      includePosition: values.includePosition,
      includeExperience: values.includeExperience,
      includeEducation: values.includeEducation,
      includeLanguages: values.includeLanguages,
      includeSummary: values.includeSummary,
      includePersonalHighlights: values.includePersonalHighlights,
      includeInterestsAndHobbies: values.includeInterestsAndHobbies,
      includeTechnicalSkills: values.includeTechnicalSkills,
      includeProjects: values.includeProjects,
    }),
    [values],
  );

  const getUserInfo = useMemo(
    () => ({
      id: values.resumesList[selectedResumeNumber].employee.id,
      firstName: values.resumesList[selectedResumeNumber].employee.firstName,
      secondName: values.resumesList[selectedResumeNumber].employee.secondName,
      photo: values.resumesList[selectedResumeNumber].photo,
    }),
    [values.resumesList, selectedResumeNumber],
  );

  const prevExpValue = useMemo(() => resumesList[selectedResumeNumber].userExperienceMask, [
    selectedResumeNumber,
    resumesList,
    values.resumesList,
  ]);

  const positionsOptions = useMemo(() => positions?.map(({ id, displayName }) => ({ label: displayName, value: id })), [
    positions,
  ]);

  const handleChangePositions = useCallback(
    data => {
      setFieldValue(`resumesList[${selectedResumeNumber}].companyPosition`, {
        id: data.value,
        displayName: data.label,
      });
    },
    [selectedResumeNumber],
  );

  const handleUploadClick = useCallback(() => {
    pdfRefs.current.map(item => {
      item?.click();
    });
  }, [pdfRefs]);

  const handleChangeExperience = useCallback(
    e => {
      const value = e.target.value;
      const years = Number(value.substr(0, 2));
      const months = Number(value.substr(4, 2));

      setFieldValue(
        `resumesList[${selectedResumeNumber}].experienceDays`,
        moment
          .duration({
            months,
            years,
          })
          .as('days'),
      );
    },
    [selectedResumeNumber],
  );

  const removeLanguage = (index: number) => {
    values.resumesList[selectedResumeNumber].languages.splice(index, 1);
    setFieldValue(`resumesList[${selectedResumeNumber}].languages`, values.resumesList[selectedResumeNumber].languages);
  };

  const addNewLanguage = () => {
    setFieldValue(`resumesList[${selectedResumeNumber}].languages`, [
      ...values.resumesList[selectedResumeNumber].languages,
      {
        foreignLanguage: null,
        foreignLanguageId: null,
        languageLevel: null,
        languageLevelId: null,
      },
    ]);
  };

  const handleChangeLanguage = useCallback(
    (data, index) => {
      setFieldValue(`resumesList[${selectedResumeNumber}].languages[${index}].foreignLanguage`, {
        id: data.value,
        name: data.label,
      });
      setFieldValue(`resumesList[${selectedResumeNumber}].languages[${index}].foreignLanguageId`, data.value);
    },
    [selectedResumeNumber],
  );

  const handleChangeLanguageLevel = useCallback(
    (data, index) => {
      setFieldValue(`resumesList[${selectedResumeNumber}].languages[${index}].languageLevel`, {
        id: data.value,
        name: data.label,
      });
      setFieldValue(`resumesList[${selectedResumeNumber}].languages[${index}].languageLevelId`, data.value);
    },
    [selectedResumeNumber],
  );

  const handleChangeSummary = useCallback(data => {
    setFieldValue(`resumesList[${selectedResumeNumber}].summary`, data);
  }, []);

  const handleChangePersonalHighlights = useCallback(
    data => {
      setFieldValue(`resumesList[${selectedResumeNumber}].personalHighlights`, data);
    },
    [selectedResumeNumber],
  );

  const handleChangeInterestsAndHobbies = useCallback(
    data => {
      setFieldValue(`resumesList[${selectedResumeNumber}].interestsAndHobbies`, data);
    },
    [selectedResumeNumber],
  );

  const handleChangeSkills = useCallback(
    (data, index) => {
      setFieldValue(
        `resumesList[${selectedResumeNumber}].technicalSkills[${index}].skills`,
        data.map((item: { value: number; label: string }) => ({ id: item.value, skill: item.label })),
      );
      setFieldValue(
        `resumesList[${selectedResumeNumber}].technicalSkills[${index}].skillIds`,
        data.map((item: { value: number }) => item.value),
      );
    },
    [selectedResumeNumber],
  );

  const onChooseSkillGroupe = (data: any) => {
    setFieldValue(`resumesList[${selectedResumeNumber}].technicalSkills`, [
      ...values.resumesList[selectedResumeNumber].technicalSkills,
      {
        skillGroupId: data.value,
        skillGroupName: data.label,
        skillGroupPriority: null,
        skillIds: [],
        skills: [],
      },
    ]);
  };

  const removeSkillGroupe = (index: number) => {
    values.resumesList[selectedResumeNumber].technicalSkills.splice(index, 1);
    setFieldValue(
      `resumesList[${selectedResumeNumber}].technicalSkills`,
      values.resumesList[selectedResumeNumber].technicalSkills,
    );
  };

  const addNewProject = () => {
    setFieldValue(`resumesList[${selectedResumeNumber}].projects`, [
      ...values.resumesList[selectedResumeNumber].projects,
      {
        description: null,
        id: null,
        name: null,
      },
    ]);
  };

  const removeProject = (index: number) => {
    values.resumesList[selectedResumeNumber].projects.splice(index, 1);
    setFieldValue(`resumesList[${selectedResumeNumber}].projects`, values.resumesList[selectedResumeNumber].projects);
  };

  const handleChangeProject = useCallback(
    (data, index) => {
      if (data.value === data.label) {
        setFieldValue(`resumesList[${selectedResumeNumber}].projects[${index}].id`, 'createdProject');
      } else {
        setFieldValue(`resumesList[${selectedResumeNumber}].projects[${index}].id`, data.value);
      }
      setFieldValue(`resumesList[${selectedResumeNumber}].projects[${index}].name`, data.label);
      setFieldValue(`resumesList[${selectedResumeNumber}].projects[${index}].description`, data.description);
    },
    [selectedResumeNumber],
  );

  const handleChangeProjectDescription = useCallback(
    (data, index) => {
      setFieldValue(`resumesList[${selectedResumeNumber}].projects[${index}].description`, data);
    },
    [selectedResumeNumber],
  );

  const handleExportTypeChange = useCallback(e => {
    setFieldValue('exportType', (e.target as HTMLElement).id);
  }, []);

  useEffect(() => {
    setSelectedResumeNumber(values.resumesList.findIndex(item => item.id === selectedResumeId));
  }, [values.resumesList]);

  const deletePhoto = ({ callback }: { callback: () => void }) => {
    setFieldValue(`resumesList[${selectedResumeNumber}].deletePhotoFlag`, true);
    callback();
    setFieldValue(`resumesList[${selectedResumeNumber}].photo`, null);
    setFieldValue(`resumesList[${selectedResumeNumber}].photoFile`, null);
    setFieldValue(`resumesList[${selectedResumeNumber}].cropSetting`, null);
  };

  const getPhoto = (data: any, callback: () => void) => {
    setFieldValue(`resumesList[${selectedResumeNumber}].deletePhotoFlag`, false);
    setFieldValue(
      `resumesList[${selectedResumeNumber}].photo`,
      data.file
        ? {
            url: URL.createObjectURL(data.file),
            id: values.resumesList[selectedResumeNumber].photo?.id,
            cropSetting: data.cropSetting,
            croppedFile: { url: data.cropFileUrl },
          }
        : {
            ...values.resumesList[selectedResumeNumber].photo,
            id: values.resumesList[selectedResumeNumber].photo?.id,
            cropSetting: data.cropSetting,
            croppedFile: { url: data.cropFileUrl },
          },
    );
    setFieldValue(`resumesList[${selectedResumeNumber}].photoFile`, data.file);
    setFieldValue(`resumesList[${selectedResumeNumber}].cropSetting`, data.cropSetting);
    callback();
  };

  const onCancelButtonClick = useCallback(() => {
    activeTab === EXPORT_RESUME_MODAL_TABS.CONTENT_CV
      ? onCloseRequest()
      : activeTab === EXPORT_RESUME_MODAL_TABS.SETTINGS_CV
      ? setActiveTab(EXPORT_RESUME_MODAL_TABS.CONTENT_CV)
      : setActiveTab(EXPORT_RESUME_MODAL_TABS.SETTINGS_CV);
  }, [activeTab]);

  const cancelButtonLabel = useMemo(
    () =>
      activeTab === EXPORT_RESUME_MODAL_TABS.CONTENT_CV
        ? intl.formatMessage(messages.cancelButton)
        : activeTab === EXPORT_RESUME_MODAL_TABS.SETTINGS_CV
        ? '< ' + intl.formatMessage(messages.backButton)
        : '< ' + intl.formatMessage(messages.backButton),
    [activeTab],
  );

  const onExportButtonClick = useCallback(() => {
    activeTab === EXPORT_RESUME_MODAL_TABS.CONTENT_CV
      ? setActiveTab(EXPORT_RESUME_MODAL_TABS.SETTINGS_CV)
      : activeTab === EXPORT_RESUME_MODAL_TABS.SETTINGS_CV
      ? setActiveTab(EXPORT_RESUME_MODAL_TABS.FINAL_CV)
      : (submitForm(), handleUploadClick());
  }, [activeTab]);

  const exportButtonLabel = useMemo(
    () =>
      activeTab === EXPORT_RESUME_MODAL_TABS.CONTENT_CV
        ? intl.formatMessage(messages.nextButton) + ' >'
        : activeTab === EXPORT_RESUME_MODAL_TABS.SETTINGS_CV
        ? intl.formatMessage(messages.nextButton) + ' >'
        : intl.formatMessage(messages.exportButton),
    [activeTab],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.exportModalTitle)}
      classNameModal={classNames('export_cv_modal', { 'one-resume': values.resumesList.length === 1 })}
      size="large"
    >
      <form className="modal__form form form--cv form--tabs" onSubmit={handleSubmit}>
        <div className="form__tabs">
          {values.resumesList.length > 1 && activeTab !== EXPORT_RESUME_MODAL_TABS.SETTINGS_CV && (
            <div className="form__tabs-head">
              <ReactSortable
                list={values.resumesList}
                setList={newState => {
                  setFieldValue(`resumesList`, newState);
                }}
                animation={200}
                handle={activeTab === EXPORT_RESUME_MODAL_TABS.CONTENT_CV ? '.form__btn-move-inputs' : '.'}
                sorted={false}
              >
                {values.resumesList.map((resume, index) => (
                  <div
                    className={classNames('form__tabs-head-item', {
                      active: activeTab !== EXPORT_RESUME_MODAL_TABS.SETTINGS_CV && selectedResumeId === resume.id,
                    })}
                    key={index}
                    onClick={() => {
                      setSelectedResumeId(resume.id);
                      setSelectedResumeNumber(index);
                    }}
                  >
                    {activeTab === EXPORT_RESUME_MODAL_TABS.CONTENT_CV && (
                      <button className="form__btn-move-inputs" type={'button'}>
                        <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
                      </button>
                    )}
                    <div className="form__user">
                      <Avatar
                        userInfo={resume.employee}
                        externalClass="grid-employee__avatar"
                        customSize={24}
                        fileSize={48}
                      />
                      <div className="form__user-name">
                        {resume.employee.firstName + ' ' + resume.employee.secondName}
                      </div>
                    </div>
                  </div>
                ))}
              </ReactSortable>
            </div>
          )}
          <div className="form__tabs-content">
            <div
              className={classNames('form__tabs-content-cvs', {
                active: activeTab === EXPORT_RESUME_MODAL_TABS.CONTENT_CV,
              })}
            >
              <div>
                <div className="form__edit-cv-top">
                  <div className="form__avatar">
                    <Avatar
                      viewOnly={false}
                      //  @ts-ignore
                      userInfo={getUserInfo}
                      customSize={160}
                      customFontSize={55}
                      uploadPhoto={getPhoto}
                      deleteUserPhoto={deletePhoto}
                      externalClass="square"
                      externalCropperClass="square"
                      fileSize={320}
                    />
                  </div>
                  <div className="form__edit-cv-top-inputs">
                    <div className="form__edit-cv-name">
                      {values.resumesList[selectedResumeNumber].employee.firstName +
                        ' ' +
                        values.resumesList[selectedResumeNumber].employee.secondName}
                    </div>
                    <div className="form__inputs-subwrapper">
                      <div className="form__input-block form__input-block--half">
                        <Select
                          label={intl.formatMessage(messages.positionLabel)}
                          value={
                            values.resumesList[selectedResumeNumber].companyPosition?.id
                              ? {
                                  value: values.resumesList[selectedResumeNumber].companyPosition?.id,
                                  label: values.resumesList[selectedResumeNumber].companyPosition?.displayName,
                                }
                              : null
                          }
                          options={positionsOptions}
                          handleChange={data => handleChangePositions(data)}
                          isCreatable
                          isSearchable
                        />
                      </div>
                      <div className="form__input-block form__input-block--half">
                        <Input
                          name={'experience'}
                          label={intl.formatMessage(messages.experienceLabel)}
                          tag="InputMask"
                          mask={'99y 99m'}
                          onChange={handleChangeExperience}
                          defaultValue={prevExpValue}
                        />
                      </div>
                    </div>
                    <Input
                      tag="textarea"
                      label={intl.formatMessage(messages.educationLabel)}
                      name="education"
                      defaultValue={values.resumesList[selectedResumeNumber].education}
                    />
                  </div>
                </div>
                <EditResumeTabs
                  resume={values.resumesList[selectedResumeNumber]}
                  languageLevels={languageLevels}
                  foreignLanguages={foreignLanguages}
                  technicalSkills={technicalSkills}
                  projectsList={projectsList}
                  handleChangeLanguage={handleChangeLanguage}
                  handleChangeLanguageLevel={handleChangeLanguageLevel}
                  removeLanguage={removeLanguage}
                  addNewLanguage={addNewLanguage}
                  handleChangeSummary={handleChangeSummary}
                  handleChangePersonalHighlights={handleChangePersonalHighlights}
                  handleChangeInterestsAndHobbies={handleChangeInterestsAndHobbies}
                  handleChangeSkills={handleChangeSkills}
                  onChooseSkillGroupe={onChooseSkillGroupe}
                  removeSkillGroupe={removeSkillGroupe}
                  setFieldValue={setFieldValue}
                  handleChangeProject={handleChangeProject}
                  removeProject={removeProject}
                  addNewProject={addNewProject}
                  handleChangeProjectDescription={handleChangeProjectDescription}
                />
              </div>
            </div>
            <div
              className={classNames('form__tabs-settings', {
                active: activeTab === EXPORT_RESUME_MODAL_TABS.SETTINGS_CV,
              })}
            >
              <div className="form__tabs-settings-item">
                <div className="form__tabs-settings-item-title">
                  <FormattedMessage {...messages.includeDataTitle} />
                </div>
                <div className="cv_settings_checkbox_block">
                  <div className="cv_settings_checkbox_column">
                    <Checkbox
                      id={'includePhoto'}
                      name={'includePhoto'}
                      label={intl.formatMessage(messages.photoLabel)}
                      onChange={handleChange}
                      checked={values.includePhoto}
                    />
                    <Checkbox
                      id={'includeName'}
                      name={'includeName'}
                      label={intl.formatMessage(messages.nameColumn)}
                      onChange={handleChange}
                      checked={values.includeName}
                    />
                    <Checkbox
                      id={'includePosition'}
                      name={'includePosition'}
                      label={intl.formatMessage(messages.positionLabel)}
                      onChange={handleChange}
                      checked={values.includePosition}
                    />
                    <Checkbox
                      id={'includeExperience'}
                      name={'includeExperience'}
                      label={intl.formatMessage(messages.experienceLabel)}
                      onChange={handleChange}
                      checked={values.includeExperience}
                    />
                    <Checkbox
                      id={'includeEducation'}
                      name={'includeEducation'}
                      label={intl.formatMessage(messages.educationLabel)}
                      onChange={handleChange}
                      checked={values.includeEducation}
                    />
                    <Checkbox
                      id={'includeLanguages'}
                      name={'includeLanguages'}
                      label={intl.formatMessage(messages.languagesLabel)}
                      onChange={handleChange}
                      checked={values.includeLanguages}
                    />
                  </div>
                  <div className="cv_settings_checkbox_column">
                    <Checkbox
                      id={'includeSummary'}
                      name={'includeSummary'}
                      label={intl.formatMessage(messages.summaryLabel)}
                      onChange={handleChange}
                      checked={values.includeSummary}
                    />
                    <Checkbox
                      id={'includePersonalHighlights'}
                      name={'includePersonalHighlights'}
                      label={intl.formatMessage(messages.personalHighlightsLabel)}
                      onChange={handleChange}
                      checked={values.includePersonalHighlights}
                    />
                    <Checkbox
                      id={'includeInterestsAndHobbies'}
                      name={'includeInterestsAndHobbies'}
                      label={intl.formatMessage(messages.interestAndHobbiesLabel)}
                      onChange={handleChange}
                      checked={values.includeInterestsAndHobbies}
                    />
                    <Checkbox
                      id={'includeTechnicalSkills'}
                      name={'includeTechnicalSkills'}
                      label={intl.formatMessage(messages.technicalSkillsLabel)}
                      onChange={handleChange}
                      checked={values.includeTechnicalSkills}
                    />
                    <Checkbox
                      id={'includeProjects'}
                      name={'includeProjects'}
                      label={intl.formatMessage(messages.remarkableProjectsTitle)}
                      onChange={handleChange}
                      checked={values.includeProjects}
                    />
                  </div>
                </div>
              </div>
              {values.resumesList.length > 1 && (
                <div className="form__tabs-settings-item">
                  <div className="form__tabs-settings-item-title">
                    <FormattedMessage {...messages.exportAsTitle} />
                  </div>
                  <div className="radio-button-wrapper">
                    <RadioButton
                      name="exportType"
                      id="oneFile"
                      label={intl.formatMessage(messages.oneFileLabel)}
                      checked={values.exportType === FILE_EXPORT_OPTIONS.ONE_FILE}
                      externalClass="form__radio-input-repeater"
                      onChange={handleExportTypeChange}
                      value={FILE_EXPORT_OPTIONS.ONE_FILE}
                    />
                    <RadioButton
                      name="exportType"
                      id="separateFiels"
                      label={intl.formatMessage(messages.separateFilesLabel)}
                      checked={values.exportType === FILE_EXPORT_OPTIONS.SEPARATE_FILES}
                      externalClass="form__radio-input-repeater"
                      onChange={handleExportTypeChange}
                      value={FILE_EXPORT_OPTIONS.SEPARATE_FILES}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className={classNames('form__tabs-final-cvs', {
                active: activeTab === EXPORT_RESUME_MODAL_TABS.FINAL_CV,
              })}
            >
              <CVDocument
                resume={new Resume(values.resumesList[selectedResumeNumber])}
                displayingResumeSections={displayingResumeSections}
                inExportModal
              />
            </div>
          </div>
        </div>
        <div className="modal__footer cv_form_footer">
          <ErrorMessage>{resumeError}</ErrorMessage>
          <AccessChecker verifiablePolicies={[UPDATE_RESUME]}>
            <Checkbox
              id={'saveChanges'}
              label={intl.formatMessage(messages.saveChangesLabel)}
              onChange={handleChange}
              checked={values.saveChanges}
            />
          </AccessChecker>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              type="button"
              onClick={onCancelButtonClick}
            >
              {cancelButtonLabel}
            </Button>
            <Button
              externalClass={'button--modal'}
              onClick={onExportButtonClick}
              loading={isLoading}
              disabled={isLoading}
              type={'button'}
            >
              {exportButtonLabel}
            </Button>
          </div>
        </div>
      </form>
      {activeTab === EXPORT_RESUME_MODAL_TABS.FINAL_CV && values.exportType === FILE_EXPORT_OPTIONS.ONE_FILE ? (
        <PDFDownloadLink
          document={
            <ExportCVDocument
              data={values.resumesList.map(resume => new Resume(resume))}
              displayingResumeSections={displayingResumeSections}
            />
          }
          fileName={`${intl.formatMessage(messages.companyNameLabel)} CV.pdf`}
        >
          {/* @ts-ignore */}
          <div ref={el => (pdfRefs.current[0] = el)}></div>
        </PDFDownloadLink>
      ) : (
        values.resumesList.map((resume, index) => (
          <PDFDownloadLink
            document={
              <ExportCVDocument data={[new Resume(resume)]} displayingResumeSections={displayingResumeSections} />
            }
            fileName={`${
              values.includeName ? resume.employee.fullName : intl.formatMessage(messages.companyNameLabel)
            } CV.pdf`}
            key={resume.id}
          >
            {/* @ts-ignore */}
            <div ref={el => (pdfRefs.current[index] = el)}></div>
          </PDFDownloadLink>
        ))
      )}
    </Modal>
  );
}

export default ModalExportResumes;
