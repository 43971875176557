import { get } from 'lodash-es';

export class PolicyGroupParams {
  departmentIds: string[];
  officeIds: string[];
  userIds: string[];
  active: boolean;

  constructor(params?: unknown) {
    this.departmentIds = get(params, 'departmentIds', []);
    this.officeIds = get(params, 'officeIds', []);
    this.userIds = get(params, 'userIds', []);
    this.active = get(params, 'active', true);
  }
}

export class PolicyModuleParams {
  departmentIds: string[];
  officeIds: string[];
  userIds: string[];
  levelId: string;
  levelType: string;
  active: boolean;
  officesIds: string[];

  constructor(params?: unknown) {
    this.departmentIds = get(params, 'departmentIds', []);
    this.officeIds = get(params, 'officeIds', []);
    this.userIds = get(params, 'userIds', []);
    this.levelId = get(params, 'levelId');
    this.levelType = get(params, 'levelType', 'SUBMODULE');
    this.active = get(params, 'active', true);
    this.officesIds = get(params, 'officesIds', []);
  }
}
