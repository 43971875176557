import { ScheduleLayouts } from './../constants/schedule.constants';
import { useHistory, useLocation } from 'react-router';
import { RejectValueErrors } from '../enums/error.enum';
import { PlanningLayouts, ResourcesLayouts } from './../constants/planning.constants';
import { PeopleLayouts } from './../constants/users.constants';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash-es';
import moment from 'moment';
import { UserInfo } from '../enums/users.enum';
import r from '../constants/routes.constants';
import ENV from '../constants/env.constants';
import { DATE_FORMAT } from '../constants/date.constants';
import { cloneDeep } from 'lodash-es';
import { getTableData } from './table.utils';
import { IncomesExpensesReportLayouts } from '../constants/finance.constants';
import { FilterParamsName } from '../constants/filters.constants';
import * as filtersActions from '../actions/filters.actions';
import { Dispatch } from 'redux';
import { FilterOptionType } from '../components/Filter';
import { OptionTypeBase } from 'react-select';
import { setFilterValuesInSessionStorage } from './index';
import { ActivityLayouts } from '../constants/activity.constants';
import React from 'react';
import { v4 } from 'uuid';

type Layouts =
  | PeopleLayouts
  | ResourcesLayouts
  | PlanningLayouts
  | ScheduleLayouts
  | ActivityLayouts
  | IncomesExpensesReportLayouts;

export function useClickOutsideHandler(action: (e?: Event) => void, ...refs: any[]) {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (!refs.some(ref => ref?.current && ref.current.contains(event.target))) action(event);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, action]);
}

export function useSetFieldsErrors(
  errors: string | RejectValueErrors[] | null,
  setErrors: (field: string, error: string) => void,
  path?: string,
) {
  useEffect(() => {
    if (Array.isArray(errors)) {
      errors.forEach(el => {
        let field = el.field;
        if (path === r.schedule) {
          field = field.replace('create.eventDto.', '');
          field = field.replace('update.eventDto.', '');
        }

        setErrors(field, el.error);
      });
    }
  }, [errors]);
}

export function useLayout(
  layouts: Record<string, Layouts>,
  defaultLayout: Layouts,
): [Layouts, (layout: Layouts) => void] {
  const history = useHistory();
  const activeSearchLayout = new URLSearchParams(history.location.search).get('view_type')?.toUpperCase();
  const activeLayout = Object.values(layouts).find(tab => tab === activeSearchLayout) || defaultLayout;
  const [layout, setLayout] = useState<Layouts>(activeLayout || defaultLayout);

  const handleChangeLayout = (layout: Layouts) => {
    history.replace({
      search: `view_type=${layout.toLowerCase()}`,
    });
    setLayout(layout);
  };

  return [layout, handleChangeLayout];
}

export function useResizeObserver(elRef: any) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const observer = useRef(
    new ResizeObserver(
      debounce(entries => {
        // Only care about the first element, we expect one element to be watched

        const { width, height } = entries[0].contentRect;
        setWidth(width);
        setHeight(height);
      }, 50),
    ),
  );
  useEffect(() => {
    if (elRef?.current) {
      observer.current.observe(elRef.current);
    }
    return () => {
      observer.current.disconnect();
    };
  }, [elRef?.current, observer]);
  return { height, width };
}

export function useAbsecePeriods(
  userId: string,
  getUserAbsencePeriods: (data: any) => void,
  userHrInfo: {
    dismissal: string;
    endTrialPeriod: string;
    hired: string;
    hrCuratorId: string;
    hrCurator: UserInfo;
  }[],
  date?: any,
  setSearchYearCustom?: (date: any) => void,
) {
  const [serachYear, setSearchYear] = useState(date ? moment(date) : moment());

  const getCurrentAbsencePeriods = () => {
    userId &&
      getUserAbsencePeriods({
        id: userId,
        data: {
          dateTimeFrom: moment(serachYear.startOf('year')).format(),
          dateTimeTo: moment(serachYear.endOf('year')).format(),
        },
      });
  };

  useEffect(() => {
    getCurrentAbsencePeriods();
  }, [serachYear]);

  useEffect(() => {
    setSearchYear(moment());
  }, [userId]);

  const getUserYears = () => {
    const date = [];
    const hiredYear = moment(userHrInfo[userHrInfo.length - 1]?.hired);
    let currentYear = moment();
    while (currentYear >= hiredYear) {
      date.push(currentYear.format(DATE_FORMAT.YYYY_MM_DD));
      currentYear = currentYear.subtract(1, 'year');
    }
    return date;
  };

  const tableActions = useMemo(() => {
    return getUserYears().map(date => ({
      label: moment(date).format(DATE_FORMAT.YYYY),
      dropdownListInfo: date,
      handler: (row: any, index: any, info: string) => {
        const infoDate = moment(info);
        setSearchYearCustom && setSearchYearCustom(infoDate);
        setSearchYear(infoDate);
      },
    }));
  }, [userHrInfo]);

  return {
    serachYear,
    tableActions,
    setSearchYear,
  };
}

export function useTableData(tableData: any[], names: string[]) {
  const data = useMemo(() => getTableData(cloneDeep(tableData), names), [tableData, names]);
  return data;
}

const keycloakDetails = (code: string, code_verifier: string, search: string) => ({
  grant_type: ENV.KEYCLOAK_GRANT_TYPE,
  client_id: ENV.KEYCLOAK_CLIENTID,
  client_secret: ENV.KEYCLOAK_CLIENT_SECRET,
  redirect_uri: window.location.origin + r.signIn + search,
  code,
  code_verifier,
});

export function useKeycloak(
  cb: (getKeycloakToken: { data: URLSearchParams; removeKeycloakItems: () => void; redirect: () => void }) => void,
) {
  const { hash, search } = useLocation();

  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(hash.slice(1));
    const kc_item = `kc-callback-${params.get('state')}`;
    const code = params.get('code');
    const data = window.localStorage.getItem(kc_item);

    if (data && code) {
      const pkceCodeVerifier = JSON.parse(data).pkceCodeVerifier;
      cb({
        data: new URLSearchParams(keycloakDetails(code, pkceCodeVerifier, search)),
        removeKeycloakItems: () => {
          for (const key in window.localStorage) {
            if (key.includes('kc-callback')) {
              window.localStorage.removeItem(key);
            }
          }
        },
        redirect: () => history.replace(r.signIn),
      });
    }
  }, []);
}
const getValue = (item: string | Record<string, any>) => (item instanceof Object ? item.id : item);
export const getFiltersValue = (
  options: FilterOptionType[],
  values: string[] | number[] | boolean[] | Record<string, unknown>[],
) =>
  options.filter(option =>
    values.some((value: string | number | boolean | Record<string, unknown>) => {
      const optionValue = option.value;
      return getValue(optionValue) === value;
    }),
  );

export function useFiltersListValue(
  options: FilterOptionType[],
  values: string[] | number[] | boolean[] | Record<string, unknown>[],
) {
  const value = useMemo(() => getFiltersValue(options, values), [options, values]);
  return value;
}

export function useParamsChange(setParams: (data: any) => void, dispatch?: Dispatch<any> | null, isMulti = true) {
  return useCallback(
    name => (data: OptionTypeBase) => {
      if (isMulti) {
        const value = data.map(({ value }: Record<string, any>) => getValue(value));
        setParams({ [name]: value, page: 0 });
        dispatch && useFiltersValue(dispatch, name, value);
      } else {
        setParams({ [name]: getValue(data.value), page: 0 });
      }
    },
    [setParams],
  );
}

export function resetParamsChange(names: FilterParamsName[], dispatch?: Dispatch<any> | null) {
  names.map(item => dispatch && useFiltersValue(dispatch, item, []));
}

export function useUsersParamsChange(setUsersParams: (data: any) => void, dispatch?: Dispatch<any>) {
  return useCallback(
    name => (data: OptionTypeBase) => {
      const value = data.map(({ value }: { label: string; value: UserInfo }) => (value.id ? value.id : value));
      setUsersParams({ [name]: value, page: 0 });
      dispatch && useFiltersValue(dispatch, name, value);
    },
    [setUsersParams],
  );
}

export function useFiltersValue(dispatch: Dispatch<any>, filterName: FilterParamsName, value: string[]) {
  switch (filterName) {
    case FilterParamsName.TARGET_EMPLOYEES:
    case FilterParamsName.USER_IDS:
    case FilterParamsName.EMPLOYEE_IDS:
    case FilterParamsName.EMPLOYEES:
    case FilterParamsName.USERS:
    case FilterParamsName.PORTAL_USER_IDS: {
      dispatch(filtersActions.setUsersFilterValue(value));
      setFilterValuesInSessionStorage('usersFilterValue', value);
      break;
    }

    case FilterParamsName.OFFICES:
    case FilterParamsName.OFFICE_IDS: {
      dispatch(filtersActions.setOfficesFilterValue(value));
      setFilterValuesInSessionStorage('officesFilterValue', value);
      break;
    }

    case FilterParamsName.DEPARTMENTS:
    case FilterParamsName.DEPARTMENT_IDS: {
      dispatch(filtersActions.setDepartmentsFilterValue(value));
      setFilterValuesInSessionStorage('departmentsFilterValue', value);
      break;
    }

    case FilterParamsName.PROJECT_GROUP_IDS: {
      dispatch(filtersActions.setProjectGroupsFilterValue(value));
      setFilterValuesInSessionStorage('projectGroupsFilterValue', value);
      break;
    }

    case FilterParamsName.COMPETENCE_IDS:
    case FilterParamsName.COMPETENCIES: {
      dispatch(filtersActions.setCompetenciesFilterValue(value));
      setFilterValuesInSessionStorage('competenciesFilterValue', value);
      break;
    }

    case FilterParamsName.EVENT_TYPES: {
      dispatch(filtersActions.setEventTypesFilterValue(value));
      setFilterValuesInSessionStorage('eventTypesFilterValue', value);
      break;
    }

    case FilterParamsName.JIRA_PROJECTS:
    case FilterParamsName.JIRA_PROJECT_IDS: {
      dispatch(filtersActions.setJiraProjectsFilterValue(value));
      setFilterValuesInSessionStorage('jiraProjectsFilterValue', value);
      break;
    }
  }
}

export const useWorkChartNodes = (isActivityPage = true) => {
  const [myNodes, setMyNodes] = useState<React.ReactElement[]>([]);

  const chartCallback = useCallback((el: any) => {
    const container = el.chartWrapper?.getChart()?.container;
    const chartContentWrapper = container.getElementsByTagName('g');
    const child = chartContentWrapper[isActivityPage ? 5 : 3].children;
    const nodes: any = [];
    const adjustY = isActivityPage ? 16 : 6;
    const adjustX = isActivityPage ? 14 : 4;
    if (!isActivityPage) {
      Array.prototype.forEach.call(chartContentWrapper[0].children, (item: any) => {
        if (item?.tagName === 'text') {
          nodes.push(
            React.createElement(
              'span',
              {
                key: v4(),
                className: 'dist-span',
                style: {
                  top: `${parseInt(item.getAttribute('y')) - 11}px`,
                  left: '0',
                  color: '#4A4A4A',
                  position: 'absolute',
                },
              },
              item.textContent,
            ),
          );
        }
      });
    }

    Array.prototype.forEach.call(child, (item: any, index: number) => {
      if (child[index + 1]?.tagName === 'text' && item.getAttribute('width') > 10) {
        nodes.push(
          React.createElement(
            'span',
            {
              key: `dist-span-${index}`,
              className: 'dist-span',
              style: {
                width: `${item.getAttribute('width') - (isActivityPage ? 4 : adjustX)}px`,
                top: `${parseInt(item.getAttribute('y')) + adjustY}px`,
                left: `${parseInt(item.getAttribute('x')) + adjustX}px`,
                color: '#ffffff',
                position: 'absolute',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontSize: 12,
                fontWeight: 400,
                zIndex: 0,
                pointerEvents: 'none',
              },
            },
            child[index + 1].textContent,
          ),
        );
      }
    });

    setMyNodes(nodes);
  }, []);

  return { myNodes, chartCallback };
};

export function useElementScroll(element: MutableRefObject<any>, parentRef: MutableRefObject<any>) {
  useEffect(() => {
    const handleScroll = () => {
      const currentElem = element.current;
      const parentElem = parentRef.current;

      if (currentElem && parentElem) {
        const elemTop = currentElem.getBoundingClientRect().top;
        const parentRect = parentElem.getBoundingClientRect();
        const parentTop = parentRect.top;
        const elemStyle = currentElem.style;
        if (parentRef && (parentTop >= 0 || parentRect.bottom <= 120)) {
          elemStyle.position = 'relative';
          elemStyle.top = '0px';
          elemStyle.zIndex = 5;
        } else if (element && elemTop <= 60 && elemTop !== 0) {
          elemStyle.position = 'fixed';
          elemStyle.top = '60px';
          elemStyle.zIndex = 9;
        }
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [element]);
}

export default function useDrag() {
  const [clicked, setClicked] = useState(false);
  const [dragging, setDragging] = useState(false);
  const position = useRef(0);

  const dragStart = useCallback((ev: React.MouseEvent) => {
    position.current = ev.clientX;
    const elem = ev.target as HTMLElement;

    if (!elem.classList.contains('page__profile-avatar')) {
      setClicked(true);
    }
  }, []);

  const dragStop = useCallback(() => {
    // NOTE: need some delay so item under cursor won't be clicked
    window.requestAnimationFrame(() => {
      setDragging(false);
      setClicked(false);
    });
  }, []);

  const dragMove = (ev: React.MouseEvent, cb: (posDif: number) => void) => {
    const newDiff = position.current - ev.clientX;
    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setDragging(true);
    }

    if (dragging && movedEnough) {
      position.current = ev.clientX;
      cb(newDiff);
    }
  };

  return {
    dragStart,
    dragStop,
    dragMove,
    dragging,
    clicked,
    position,
    setDragging,
  };
}

export const usePrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  useEffect(() => {
    const beforeUnloadListener = (event: any) => {
      event.preventDefault();
      return (event.returnValue = '');
    };
    if (showPrompt) {
      window.addEventListener('beforeunload', beforeUnloadListener);
      return () => window.removeEventListener('beforeunload', beforeUnloadListener);
    }
  }, [showPrompt]);

  return { showPrompt, setShowPrompt };
};

export const useUpdateDropdownMenuPosition = (update: () => void) => {
  useEffect(() => {
    const debouncedRecalculatePosition = debounce(update, 100);
    window.addEventListener('resize', debouncedRecalculatePosition);
    return () => {
      debouncedRecalculatePosition.cancel();
      window.removeEventListener('resize', debouncedRecalculatePosition);
    };
  }, []);
};
