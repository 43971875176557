import { defineMessages } from 'react-intl';
import {
  newButton,
  nameColumn,
  authorColumn,
  editButton,
  deleteButton,
  createdColumn,
  editorColumn,
  updatedColumn,
  cloneButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  authorColumn,
  newButton,
  editButton,
  deleteButton,
  createdColumn,
  editorColumn,
  updatedColumn,
  cloneButton,
  newPollCategoryTitle: {
    id: 'questionnaires.poll.templates.label.new.poll.category',
    defaultMessage: 'New Poll Category',
  },
  pageTitle: {
    id: 'questionnaires.poll.templates.title.poll.templates',
    defaultMessage: 'Poll Categories',
  },
  categoryColumn: {
    id: 'questionnaires.poll.templates.column.category',
    defaultMessage: 'Category',
  },
});
