import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import TableUserAvatar from '../../components/TableUserAvatar';
import { DATE_FORMAT } from '../../constants/date.constants';
import { DepthLevels } from '../../constants/tables.constants';
import {
  EmployeesTimesheetDay,
  EmployeesTimesheetIssue,
  EmployeesTimesheetItem,
  EmployeesTimesheetProject,
} from '../../enums/reports.enum';
import { AbsenceEvent, UserInfo } from '../../enums/users.enum';
import { getDateRange } from '../../utils';
import { getForamtedTime } from '../../utils/reports.utils';
import { getTableCell } from '../../utils/table.utils';
import messages from './messages';
import { renderToStaticMarkup } from 'react-dom/server';
import { hintId } from '../../components/Hint/utils';
export const useDataForTable = (tableData: any) => {
  const onResourceMouseEnter = useCallback((absenceEvent: AbsenceEvent) => {
    let targetMember = '';
    let eventName = '';

    if (
      absenceEvent?.eventName &&
      absenceEvent?.eventName?.includes(absenceEvent?.eventTypeName) &&
      absenceEvent?.eventName.trim()?.indexOf(absenceEvent?.eventTypeName) === 0
    ) {
      const nameWithoutTypeName = absenceEvent?.eventName
        .trim()
        .slice(absenceEvent?.eventTypeName.trim().length)
        .trim();
      eventName = absenceEvent?.eventName.trim().slice(0, absenceEvent?.eventTypeName.trim().length);
      targetMember = nameWithoutTypeName[0] === '-' ? nameWithoutTypeName.slice(1) : nameWithoutTypeName;
    }
    return renderToStaticMarkup(
      <div className="hint-item">
        {absenceEvent?.eventName ? (
          eventName && targetMember ? (
            <div className="hint-item-name-wrapper">
              <div className="hint-item-name" style={{ color: absenceEvent.eventTypeColor }}>
                {eventName}
              </div>
              <div className="hint-item-name">{targetMember}</div>
            </div>
          ) : (
            <div className="hint-item-name" style={{ color: absenceEvent.eventTypeColor }}>
              {absenceEvent?.eventName}
            </div>
          )
        ) : (
          <div className="hint-item-name" style={{ color: absenceEvent.eventTypeColor }}>
            {absenceEvent?.eventTypeName}
          </div>
        )}

        <div>{getDateRange(absenceEvent?.startDate, absenceEvent?.endDate, DATE_FORMAT.MMM_D)}</div>
      </div>,
    );
  }, []);

  const intl = useIntl();
  const tableColumns = useMemo(() => {
    if (tableData?.users.length) {
      const employeeColumns = [
        {
          id: 'name',
          Header: intl.formatMessage(messages.memberLabel),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: EmployeesTimesheetItem) => (
                  <div className={'table__data-wrapper'}>
                    <span>
                      {row.totalItem ? (
                        intl.formatMessage(messages.totalRow)
                      ) : (
                        <TableUserAvatar users={[new UserInfo({ ...row, id: row.portalId })]} fileSize={36} />
                      )}
                    </span>
                  </div>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: EmployeesTimesheetProject) => (
                  <div className={'table__data-wrapper'}>
                    <span>{row.name}</span>
                  </div>
                ),
              },
              {
                depth: DepthLevels.THIRD,
                content: (row: EmployeesTimesheetIssue) => (
                  <div className={'table__data-wrapper'}>
                    <span>{row.summary}</span>
                  </div>
                ),
              },
            ]),
        },
      ];

      const dayColumns = tableData?.users[0]?.days.map((day: EmployeesTimesheetDay, dayIndex: number) => {
        let currentDay: EmployeesTimesheetDay;

        return {
          id: `day-${dayIndex}`,
          Header: (
            <div className="day-display">
              {moment(day.date).format(DATE_FORMAT.D)}
              {(dayIndex === 0 || moment(day.date).startOf('month').isSame(day.date)) && (
                <div
                  className={classNames('month-year-display', {
                    right: tableData?.users[0]?.days.length - 1 === dayIndex,
                  })}
                >
                  {moment(day.date).format(DATE_FORMAT.MMM_YYYY)}
                </div>
              )}
            </div>
          ),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: EmployeesTimesheetItem, rowIndex) => {
                  let thisDay: any;
                  if (!row.totalItem) {
                    thisDay = currentDay = tableData?.users[rowIndex]?.days[dayIndex] || null;
                  }
                  return row.totalItem ? (
                    <div className="table__data-value">
                      {`${getForamtedTime(row.totalWorkedByDates[currentDay?.date])}h`}
                    </div>
                  ) : (
                    <div
                      className={classNames('table__data-value', {
                        'not-submited': !currentDay?.isWeekend && !currentDay?.totalWorked,
                        'table__data-holiday': currentDay?.isWeekend,
                      })}
                      style={{
                        color: currentDay?.events?.length ? currentDay?.absenceColor : 'null',
                        background: currentDay?.absenceTranslucentColor,
                      }}
                      data-tip={
                        thisDay.events?.length ? onResourceMouseEnter(new AbsenceEvent(thisDay.absenceEvent)) : ''
                      }
                      data-html={true}
                      data-for={hintId}
                    >
                      {currentDay?.totalWorked
                        ? `${getForamtedTime(currentDay?.totalWorked)}h`
                        : currentDay?.absenceNameLetter || (currentDay?.isWeekend && 'HD') || '0h'}
                    </div>
                  );
                },
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: EmployeesTimesheetProject) => {
                  const currentProject = currentDay?.projects.find(item => item.id === row.id);
                  const thisDay = currentDay;
                  return (
                    <div
                      className={classNames('table__data-value', {
                        'not-submited': !currentDay?.isWeekend && !currentDay?.totalWorked,
                        'table__data-holiday': currentDay?.isWeekend,
                      })}
                      style={{
                        color: currentDay?.events?.length ? currentDay.absenceColor : 'null',
                        background: currentDay?.absenceTranslucentColor,
                      }}
                      data-tip={
                        thisDay.events?.length ? onResourceMouseEnter(new AbsenceEvent(thisDay.absenceEvent)) : ''
                      }
                      data-for={hintId}
                      data-html={true}
                    >
                      {currentProject?.totalWorked
                        ? `${getForamtedTime(currentProject?.totalWorked)}h`
                        : (thisDay.events?.length && '0h') || (currentDay?.isWeekend && ' ') || '0h'}
                    </div>
                  );
                },
              },
              {
                depth: DepthLevels.THIRD,
                content: (row: EmployeesTimesheetIssue) => {
                  const thisDay = currentDay;
                  const currentIssue = currentDay?.projects
                    .find(item => item.id === row.projectId)
                    ?.issues.find(issue => issue.id === row.id);
                  return (
                    <div
                      className={classNames('table__data-value', {
                        'not-submited': !currentDay?.isWeekend && !currentDay?.totalWorked,
                        'table__data-holiday': currentDay?.isWeekend,
                      })}
                      style={{
                        color: currentDay?.events?.length ? currentDay.absenceColor : 'null',
                        background: currentDay?.absenceTranslucentColor,
                      }}
                      data-tip={
                        thisDay.events?.length ? onResourceMouseEnter(new AbsenceEvent(thisDay.absenceEvent)) : ''
                      }
                      data-for={hintId}
                      data-html={true}
                    >
                      {currentIssue?.timeWorked
                        ? `${getForamtedTime(currentIssue?.timeWorked)}h`
                        : (thisDay.events?.length && '0h') || (currentDay?.isWeekend && ' ') || '0h'}
                    </div>
                  );
                },
              },
            ]),
        };
      });

      const totalHoursColumns = [
        {
          id: 'plan',
          Header: intl.formatMessage(messages.planColumn),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: EmployeesTimesheetItem) => (
                  <div className={'table__data-value'}>
                    <span>{row.planned && `${getForamtedTime(row.planned)}h`}</span>
                  </div>
                ),
              },
            ]),
        },
        {
          id: 'fact',
          Header: intl.formatMessage(messages.factColumn),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: EmployeesTimesheetItem) => (
                  <div className={classNames('table__data-value', { red: !row.totalItem && row.fact < row.planned })}>
                    <span>{`${getForamtedTime(row.fact)}h`}</span>
                  </div>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: EmployeesTimesheetProject) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.totalTimeWorked)}h`}</span>
                  </div>
                ),
              },
              {
                depth: DepthLevels.THIRD,
                content: (row: EmployeesTimesheetIssue) => (
                  <div className={'table__data-value'}>
                    <span>{`${getForamtedTime(row.totalTimeWorked)}h`}</span>
                  </div>
                ),
              },
            ]),
        },
      ];

      return [...employeeColumns, ...dayColumns, ...totalHoursColumns];
    }
    return [];
  }, [tableData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: 'border-bottom header_row',
      colspan: tableData?.users[0]?.days.length,
      sortName: '',
    },
    {
      name: intl.formatMessage(messages.totalHoursColumn),
      className: 'table__head-column header_row',
      colspan: 2,
      sortName: '',
    },
  ];

  return {
    tableColumns,
    tableHeaderItems,
  };
};
