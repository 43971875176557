import React, { useMemo } from 'react';
import messages from './messages';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '../../components/Icon';
import { PollTemplateCategoryInfoType } from '../../types/questionnaires';
import { DELETE_TEMPLATE_CATEGORY, UPDATE_TEMPLATE_CATEGORY } from '../../constants/policies.constants';

export const useDataForTable = (
  setCategoryClicked: (category: PollTemplateCategoryInfoType) => void,
  openEditPollCategoryModal: () => void,
  openDeletePollCategoryModal: () => void,
) => {
  const intl = useIntl();

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: PollTemplateCategoryInfoType) => row.name,
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: PollTemplateCategoryInfoType) => {
          setCategoryClicked(row);
          openEditPollCategoryModal();
        },
        verifiablePolicies: [UPDATE_TEMPLATE_CATEGORY],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: PollTemplateCategoryInfoType) => {
          setCategoryClicked(row);
          openDeletePollCategoryModal();
        },
        verifiablePolicies: [DELETE_TEMPLATE_CATEGORY],
      },
    ],
    [],
  );

  return {
    tableColumns,
    tableActions,
  };
};
