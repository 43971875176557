import { ActiveEmployeesParams } from '../enums/params/planning.params';
import { QueryParamsType } from './../utils/params.utils';
import request from '../utils/request.utils';
import { HrCuratorsFilterParams, UsersFilterParams, UsersParams } from '../enums/params/users.params';
import { omit } from 'lodash-es';

export function getUserInfo(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `users/${uuid}/profile`,
  };
  return request(options);
}

export function getUserPersonalInfo(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `users/${uuid}/profile/about`,
  };
  return request(options);
}

export function getUserProfessionalInfo(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `users/${uuid}/profile/professional`,
  };
  return request(options);
}

export function getJiraUsersPreview() {
  const options: RequestConfig = {
    method: 'get',
    url: 'jira/jiraUsers/preview',
  };
  return request(options);
}

export function getUsers(params?: QueryParamsType<ActiveEmployeesParams> | UsersParams | UsersFilterParams) {
  const data: Record<string, string> = {};
  if (params instanceof UsersParams) {
    params.technicalSkills.forEach((skill, index) => {
      data[`technicalSkills[${index}].skillGroupSkillId`] = skill.skillGroupSkillId;
      skill.skillLevelIds.forEach((skillLevel, skillLevelIndex) => {
        data[`technicalSkills[${index}].skillLevelIds[${skillLevelIndex}]`] = skillLevel;
      });
    });
  }

  const options: RequestConfig = {
    method: 'get',
    url: 'users',
    params: {
      ...omit(params, 'technicalSkills'),
      ...data,
    },
  };
  return request(options);
}

export function updateUserInfo(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `users/${uuid}/profile`,
    data,
  };
  return request(options);
}

export function updateUserPersonalInfo(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `users/${uuid}/profile/about`,
    data,
  };
  return request(options);
}

export function updateUserProfessionalInfo(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `users/${uuid}/profile/professional`,
    data,
  };
  return request(options);
}

export function createUser(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: 'users',
    data,
  };
  return request(options);
}

export function updateUserPartialInfo(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'patch',
    url: `users/${uuid}`,
    data,
  };
  return request(options);
}

export function uploadUserPhoto(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'patch',
    url: `users/${uuid}/profile/photo`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteUserPhoto(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/users/${id}/profile/photo
    `,
  };
  return request(options);
}

export function getUserTechnicalSkills(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/users/${uuid}/profile/technicalSkills`,
  };
  return request(options);
}

export function updateUserTechnicalSkills(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `users/${uuid}/profile/technicalSkills`,
    data,
  };
  return request(options);
}

export function updateUserPassword(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'patch',
    url: `users/${uuid}/profile/password`,
    data,
  };
  return request(options);
}

export function getUserPositionsHistory(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `users/${uuid}/profile/positionHistory`,
    params: { size: 1000 },
  };
  return request(options);
}

export function editUserPositionsHistory(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `users/${uuid}/profile/positionHistory`,
    data,
  };
  return request(options);
}

export function getUserExperiences(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `users/${uuid}/profile/experiences`,
  };
  return request(options);
}

export function getUserBonuses(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `users/${uuid}/bonuses`,
  };
  return request(options);
}

export function getUserAbsencePeriods(uuid: string, params?: any) {
  const options: RequestConfig = {
    method: 'get',
    url: `absence/period/${uuid}`,
    params,
  };
  return request(options);
}

export function getHrCurators(params?: HrCuratorsFilterParams) {
  const options: RequestConfig = {
    method: 'get',
    url: 'users/hrCurators',
    params,
  };
  return request(options);
}
