import { defineMessages } from 'react-intl';
import { newButton } from '../../i18n/defaultMessage';

export default defineMessages({
  newButton,
  pageTitle: {
    id: 'planning.resources.title.resources',
    defaultMessage: 'Resources',
  },
});
