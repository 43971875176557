import React, { memo, useCallback, useContext, useMemo } from 'react';

import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Avatar from '../../../components/Profile/Avatar';
import { completedClassName, getClassNameForUserStatus, getUserStatusLabel } from '../utils';
import Label from '../../../components/Label';
import Dropdown from '../../../components/Dropdown';
import { getPollStatusLabel, getPollStatusClassName, closedValue, publishedValue, notPublishedValue } from '../utils';
import { NavLink } from 'react-router-dom';
import { DATE_FORMAT } from '../../../constants/date.constants';
import moment from 'moment';
import 'moment/min/locales';
import { checkPolicies } from '../../../utils/policies.utils';
import PoliciesContext from '../../../PoliciesContext';
import { DELETE_POLL, UPDATE_POLL_ANSWER_EXTENDED, UPDATE_POLL_EXTENDED } from '../../../constants/policies.constants';
import Modal from '../../../components/Modal';
import parse from 'html-react-parser';
import { NewPoll } from '../../../enums/questionnaires.enum';
import r from '../../../constants/routes.constants';

type PollInfoHeaderProps = {
  pollData: NewPoll;
  isNotStartedOrCompletedUserPoll: boolean;
  backButtonHandler: () => void;
  loading: boolean;
  changePollStatus: any;
  onCancel: () => void;
  onSaveProgress: () => void;
  onSubmitAnswers: () => void;
  currentStatus: string;
  openEditPollModal: () => void;
  changeAnswersMode: () => void;
  openDeletePollModal: () => void;
  setIsOpenExportModal: (isOpen: boolean) => void;
  createNewPoll: (data: any) => void;
  isAdmin: boolean;
  isOpenConfirmForSubmitAnswers: boolean;
  answersChanged: boolean;
  onCloseConfirmModal: () => void;
  conditionForButtons: boolean;
  isFromMyPolls: boolean;
};

const PollInfoHeader = ({
  pollData,
  backButtonHandler,
  loading,
  changePollStatus,
  onCancel,
  onSubmitAnswers,
  onSaveProgress,
  currentStatus,
  openEditPollModal,
  changeAnswersMode,
  openDeletePollModal,
  setIsOpenExportModal,
  createNewPoll,
  isAdmin,
  isOpenConfirmForSubmitAnswers,
  answersChanged,
  onCloseConfirmModal,
  conditionForButtons,
  isFromMyPolls,
  isNotStartedOrCompletedUserPoll,
}: PollInfoHeaderProps) => {
  const policies = useContext(PoliciesContext);
  const intl = useIntl();

  const classNameStatus = 'dropdown__list-item__button status ';

  const getCurrentPollStatus = useCallback(
    (status: string) => {
      return currentStatus === status ? `${classNameStatus} checked` : classNameStatus;
    },
    [currentStatus],
  );

  const dropdownList = useMemo(
    () => [
      ...(checkPolicies([UPDATE_POLL_EXTENDED], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.editButton} />
                </>
              ),
              handler: () => openEditPollModal(),
            },
            ...(!pollData?.anonymous && checkPolicies([UPDATE_POLL_ANSWER_EXTENDED], policies)
              ? [
                  {
                    label: (
                      <>
                        <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                        <FormattedMessage {...messages.editAnswersLabel} />
                      </>
                    ),
                    itemClassName: 'line',
                    handler: () => changeAnswersMode(),
                  },
                ]
              : []),
            {
              label: (
                <>
                  <Icon iconName={'copy'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.dublicateLabel} />
                </>
              ),
              itemClassName: 'line',
              handler: () => createNewPoll({ data: pollData, isNewPoll: false, callback: backButtonHandler }),
            },
          ]
        : []),
      {
        label: (
          <>
            <Icon iconName={'file'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.exportLabel} />
          </>
        ),
        itemClassName: 'line',
        handler: () => setIsOpenExportModal(true),
      },
      ...(checkPolicies([UPDATE_POLL_EXTENDED], policies)
        ? [
            {
              label: (
                <>
                  {currentStatus === notPublishedValue && (
                    <Icon iconName={'accept'} externalClass={'dropdown__list-item__icon'} />
                  )}
                  <FormattedMessage {...messages.notPublishedStatusLabel} />
                </>
              ),
              itemClassName: getCurrentPollStatus(notPublishedValue),
              handler: () => changePollStatus(notPublishedValue),
            },
            {
              label: (
                <>
                  {currentStatus === publishedValue && (
                    <Icon iconName={'accept'} externalClass={'dropdown__list-item__icon'} />
                  )}
                  <FormattedMessage {...messages.publishedStatusLabel} />
                </>
              ),
              itemClassName: getCurrentPollStatus(publishedValue),
              handler: () => changePollStatus(publishedValue),
            },
            {
              label: (
                <>
                  {currentStatus === closedValue && (
                    <Icon iconName={'accept'} externalClass={'dropdown__list-item__icon'} />
                  )}
                  <FormattedMessage {...messages.closedStatusLabel} />
                </>
              ),

              itemClassName: `${getCurrentPollStatus(closedValue)} line`,
              handler: () => changePollStatus(closedValue),
            },
          ]
        : []),
      ...(checkPolicies([DELETE_POLL], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.deleteButton} />
                </>
              ),
              handler: () => openDeletePollModal(),
            },
          ]
        : []),
    ],
    [changePollStatus, policies, currentStatus],
  );

  const pollStatusForUser = useMemo(
    () =>
      pollData?.status === closedValue
        ? getPollStatusLabel(pollData?.status)
        : getUserStatusLabel(pollData?.userStatus),
    [pollData?.status, pollData?.userStatus],
  );

  const pollStatusClassNameForUser = useMemo(
    () => (pollData?.status === closedValue ? completedClassName : getClassNameForUserStatus(pollData?.userStatus)),
    [pollData?.status, pollData?.userStatus],
  );

  return (
    <>
      <Modal
        isOpen={isOpenConfirmForSubmitAnswers}
        onRequestClose={onCloseConfirmModal}
        title={intl.formatMessage(messages.confirmationModalTitle)}
        size={'small'}
        shouldCloseOnOverlayClick
      >
        <div className="form__inputs-wrapper" style={{ overflow: 'hidden' }}>
          {intl.formatMessage(messages.confirmationModalText)}
        </div>
        <div className={'form__buttons'}>
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCloseConfirmModal}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} onClick={onSubmitAnswers}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </Modal>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper">
          <div className="page__panel__back-title">
            <Button externalClass="page__back" color="gray" onClick={backButtonHandler}>
              {isFromMyPolls ? (
                <FormattedMessage {...messages.myPollsLabel} />
              ) : (
                <FormattedMessage {...messages.pageTitle} />
              )}
            </Button>
          </div>
          <div className="page__profile-panel__label-options">
            <div className="page__profile-panel__label-info">
              {isAdmin && (
                <Label type={getPollStatusClassName(pollData?.status)} size="bigger">
                  {getPollStatusLabel(pollData?.status)}
                </Label>
              )}
              {!isAdmin && (
                <Label type={pollStatusClassNameForUser} size="bigger">
                  {pollStatusForUser}
                </Label>
              )}
            </div>
            {isAdmin && (
              <Dropdown
                dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                dropdownList={dropdownList}
                dropdownClass="poll-control-dropdown"
              />
            )}
          </div>
        </div>
        <div className="title-wratter--pool">
          <h1 className="page__title page__title--smaller">{pollData?.name}</h1>
        </div>
      </div>
      <div className="page__inner-content poll-info">
        {pollData && !loading && (
          <div className="page__wrapper">
            <div className="page__info-block page__info-block--days">
              <Icon iconName={'calendar'} />
              <div className={'page__event__day'}>{`${intl.formatMessage(messages.deadlineLabel)} ${moment(
                pollData?.deadline,
              ).format(DATE_FORMAT.ll)}`}</div>
            </div>
            {pollData?.description && (
              <div className="page__info-block page__info-block--info">
                <Icon iconName={'info'} />
                {parse(pollData?.description.replace(/\n\n/gi, '<br><br>'))}
              </div>
            )}
            {pollData.relatedEvent?.id && (
              <div className="page__info-block page__info-block--event">
                <Icon iconName={'link'} />
                {pollData.relatedEvent.isUserHaveAccess ? (
                  <NavLink
                    className={'table__type-link'}
                    to={r.eventInformation.replace(':id', `${pollData.relatedEvent.id}`)}
                  >
                    <span>{pollData.relatedEvent.eventPreviewTitle}</span>
                  </NavLink>
                ) : (
                  <span>{pollData.relatedEvent.eventPreviewTitle}</span>
                )}
              </div>
            )}
            {isAdmin && (
              <>
                <div className="page__info-block">
                  <Icon iconName={'comments'} />
                  <span>{pollData?.categoryName}</span>
                </div>
                <div className="page__info-block">
                  <Icon iconName={'file'} />
                  <span>{pollData?.template?.name}</span>
                </div>
                <div className="page__info-block">
                  {pollData?.anonymous ? (
                    <>
                      <Icon iconName={'anonymous'} />
                      <span>
                        <FormattedMessage {...messages.anonymousLabel} />
                      </span>
                    </>
                  ) : (
                    <>
                      <Icon iconName={'users'} />
                      {pollData?.organizers?.map(user => (
                        <div key={user.id} className="page__event__participant">
                          <Avatar
                            userInfo={user}
                            size="small"
                            isUserPageLink
                            fileSize={48}
                            externalClass="avatar-with-name"
                          />
                          <p>{`${user.firstName} ${user.secondName}`}</p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </>
            )}
            <div className={'page__event__status'}>
              {isAdmin && (
                <>
                  <div className={'page__event__status-value'}>{intl.formatMessage(messages.createdColumn)}</div>
                  <div className={'page__event__status-responsible'}>
                    <Avatar
                      userInfo={pollData.author}
                      size="medium"
                      externalClass="page__event__employee-avatar"
                      isUserPageLink
                      fileSize={72}
                    />
                    <span>{`${pollData?.author?.firstName} ${pollData?.author?.secondName}`}</span>
                  </div>
                  <div className={'page__event__status-date'}>
                    {moment.utc(pollData?.createdDate).local().format(DATE_FORMAT.lll)}
                  </div>
                </>
              )}
              {conditionForButtons && (
                <>
                  <div className="buttonsSection">
                    {isAdmin && (
                      <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCancel}>
                        {intl.formatMessage(messages.cancelButton)}
                      </Button>
                    )}
                    {!pollData?.anonymous && !isAdmin && (
                      <Button
                        color={'gray'}
                        externalClass={'button--modal button--cancel buttonWidth'}
                        onClick={onSaveProgress}
                        disabled={!answersChanged}
                      >
                        {intl.formatMessage(messages.saveProgressLabel)}
                      </Button>
                    )}
                    <Button
                      externalClass={'button--modal buttonWidth'}
                      type={'submit'}
                      onClick={onSubmitAnswers}
                      disabled={(isNotStartedOrCompletedUserPoll || isAdmin) && !answersChanged}
                    >
                      {intl.formatMessage(messages.saveAnswers)}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(PollInfoHeader);
