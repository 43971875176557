import { RejectValueErrors } from './../enums/error.enum';
import { BonusCategoryInfo } from './../enums/bonuses.enums';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/bonuses.constants';
import { BonusesParams } from '../enums/params/bonuses.params';

type initialStateType = {
  bonusesTableData: any[];
  bonusesSettings: {
    isShow: boolean;
    ratio: number;
  } | null;
  loading: {
    getBonusesList: boolean;
    createBonus: boolean;
    editBonus: boolean;
    deleteBonus: boolean;
    getBonusesSettings: boolean;
    editBonusesSettings: boolean;
  };
  params: BonusesParams;
  errors: {
    bonusesListError: string | null;
    bonusesSettingsError: string | null;
    bonusError: string | RejectValueErrors[] | null;
  };
};

const initialState: initialStateType = {
  bonusesTableData: [],
  bonusesSettings: null,
  loading: {
    getBonusesList: false,
    createBonus: false,
    editBonus: false,
    deleteBonus: false,
    getBonusesSettings: false,
    editBonusesSettings: false,
  },
  params: new BonusesParams(),
  errors: {
    bonusesListError: null,
    bonusError: null,
    bonusesSettingsError: null,
  },
};

const bonusesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_BONUSES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getBonusesList: true },
        errors: { ...state.errors, bonusesListError: null },
      };

    case ActionTypes.GET_BONUSES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getBonusesList: false },
        bonusesTableData: {
          ...action.payload,
          content: action.payload.content.map((el: any) => new BonusCategoryInfo(el)),
        },
        errors: { ...state.errors, bonusesListError: null },
      };

    case ActionTypes.GET_BONUSES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getBonusesList: false },
        errors: { ...state.errors, bonusesListError: action.payload.message },
      };

    case ActionTypes.POST_BONUS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createBonus: true },
      };

    case ActionTypes.POST_BONUS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createBonus: false },
        errors: { ...state.errors, bonusError: null },
      };

    case ActionTypes.POST_BONUS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createBonus: false },
        errors: {
          ...state.errors,
          bonusError: action.payload.errors.length > 0 ? action.payload.errors : action.payload.message,
        },
      };

    case ActionTypes.PUT_BONUS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editBonus: true },
      };

    case ActionTypes.PUT_BONUS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editBonus: false },
        errors: { ...state.errors, bonusError: null },
      };

    case ActionTypes.PUT_BONUS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editBonus: false },
        errors: {
          ...state.errors,
          bonusError: action.payload.errors.length > 0 ? action.payload.errors : action.payload.message,
        },
      };

    case ActionTypes.DELETE_BONUS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteBonus: true },
      };

    case ActionTypes.DELETE_BONUS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteBonus: false },
        errors: { ...state.errors, bonusError: null },
      };

    case ActionTypes.DELETE_BONUS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteBonus: false },
        errors: {
          ...state.errors,
          bonusError: action.payload.message,
        },
      };

    case ActionTypes.GET_BONUSES_SETTINGS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getBonusesSettings: true },
      };

    case ActionTypes.GET_BONUSES_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getBonusesSettings: false },
        bonusesSettings: action.payload,
        errors: { ...state.errors, bonusesSettingsError: null },
      };

    case ActionTypes.GET_BONUSES_SETTINGS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getBonusesSettings: false },
        errors: {
          ...state.errors,
          bonusesSettingsError: action.payload.message,
        },
      };

    case ActionTypes.EDIT_BONUSES_SETTINGS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editBonusesSettings: true },
      };

    case ActionTypes.EDIT_BONUSES_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editBonusesSettings: false },
        errors: { ...state.errors, bonusesSettingsError: null },
      };

    case ActionTypes.EDIT_BONUSES_SETTINGS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editBonusesSettings: false },
        errors: {
          ...state.errors,
          bonusesSettingsError: action.payload.errors.length > 0 ? action.payload.errors : action.payload.message,
        },
      };

    case ActionTypes.SET_BONUSES_PARAMS_REQUEST:
      return {
        ...state,
        params: new BonusesParams({ ...state.params, ...action.payload }),
      };

    case ActionTypes.RESET_BONUSES_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_BONUSES_STATE:
      return {
        ...initialState,
        bonusesSettings: state.bonusesSettings,
        params: state.params,
      };

    default:
      return state;
  }
};

export default bonusesReducer;
