import { defineMessages } from 'react-intl';
import {
  deleteButton,
  skillLabel,
  saveButton,
  cancelButton,
  questionLabel,
  competenciesTitle,
  deleteModalTitle,
} from '../../i18n/defaultMessage';

export default defineMessages({
  skillLabel,
  questionLabel,
  competenciesTitle,
  deleteModalTitle,
  deleteButton,
  cancelButton,
  saveButton,
  competenceLabel: {
    id: 'competencies.questions.for.skill.form.new.questions.for.skill.label.competence',
    defaultMessage: 'Competence',
  },
  levelLabel: {
    id: 'competencies.questions.for.skill.form.new.questions.for.skill.label.level',
    defaultMessage: 'Level',
  },
  answerLabel: {
    id: 'competencies.questions.for.skill.form.new.questions.for.skill.label.answer',
    defaultMessage: 'Answer',
  },
  levelsLabel: {
    id: 'competencies.questions.for.skill.form.new.questions.for.skill.label.levels',
    defaultMessage: 'Levels',
  },
});
