import React from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';

type ModalDeletePollProps = {
  onCloseRequest: () => void;
  onDeleteRequest: (data: Record<string, unknown>) => void;
  templateData: any;
  isLoading: boolean;
  isOpen: boolean;
};

const ModalDeletePoll = ({
  onCloseRequest,
  onDeleteRequest,
  templateData,
  isLoading,
  isOpen,
}: ModalDeletePollProps) => {
  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.deleteModalWithItemNameTitle, {
        name: templateData?.name,
      })}
      size={'small'}
      shouldCloseOnOverlayClick={false}
      classNameModal="center"
    >
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCloseRequest}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            onDeleteRequest({ data: templateData?.id, callback: onCloseRequest });
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
};

export default ModalDeletePoll;
