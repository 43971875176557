import { defineMessages } from 'react-intl';
import {
  editButton,
  competenciesTitle,
  deleteButton,
  newButton,
  skillsColumn,
  questionLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  skillsColumn,
  competenciesTitle,
  questionLabel,
  newButton,
  deleteButton,
  editButton,
  levelsTitle: {
    id: 'competencies.questions.for.skill.column.levels',
    defaultMessage: 'Levels',
  },
  questionsTitle: {
    id: 'competencies.questions.for.skill.column.questions',
    defaultMessage: 'Questions',
  },
  pageTitle: {
    id: 'competencies.questions.for.skill.title.questions.for.skill',
    defaultMessage: 'Questions for Skill',
  },
  answersTitle: {
    id: 'competencies.questions.for.skill.column.answers',
    defaultMessage: 'Answers',
  },
  newQuestionsForSkillsTitle: {
    id: 'competencies.questions.for.skill.form.new.questions.for.skill.title.new.questions.for.skill',
    defaultMessage: 'New Questions for Skill',
  },
  editQuestionsForSkillsTitle: {
    id: 'competencies.questions.for.skill.form.edit.questions.for.skill.title.edit.questions.for.skill',
    defaultMessage: 'Edit Questions For Skills',
  },
});
