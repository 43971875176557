import { defineMessages } from 'react-intl';
import {
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  lastUpdateColumn,
  updaterColumn,
  statusLabel,
  activeLabel,
  inactiveLabel,
  activateLabel,
  deactivateLabel,
  hourPriceLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  lastUpdateColumn,
  updaterColumn,
  statusLabel,
  activeLabel,
  inactiveLabel,
  activateLabel,
  deactivateLabel,
  hourPriceLabel,
  pageTitle: {
    id: 'finance.clients.title.clients',
    defaultMessage: 'Clients',
  },
});
