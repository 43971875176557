import React, { useContext, useMemo } from 'react';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import moment from 'moment';
import Icon from '../../Icon';
import Button from '../../Button';
import { AssessmentsInfo, ResultType } from '../../../enums/competencies.enum';
import { UserInfo } from '../../../enums/users.enum';
import { NavLink } from 'react-router-dom';
import r from '../../../constants/routes.constants';
import TableUserAvatar from '../../TableUserAvatar';
import { checkPolicies } from '../../../utils/policies.utils';
import { UPDATE_USER_EXTENDED } from '../../../constants/policies.constants';
import { DATE_FORMAT } from '../../../constants/date.constants';
import PoliciesContext from '../../../PoliciesContext';
import Hint from '../../Hint';
import { userHintId } from '../../Hint/utils';

type ModalAssessmentDetailsProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  requestError?: string;
  assessment: AssessmentsInfo;
  onDeleteRequest: () => void;
  onEditRequest: () => void;
  modalStyle: React.CSSProperties;
};

function ModalAssessmentDetails({
  isOpen,
  onCloseRequest,
  assessment,
  onDeleteRequest,
  onEditRequest,
  modalStyle,
}: ModalAssessmentDetailsProps) {
  const intl = useIntl();

  const policies = useContext(PoliciesContext);

  const competencePercent = useMemo(() => {
    const levelsScoreSum = assessment.competence.competenceLevels.reduce(
      (sum: number, current: any) => sum + current.maxScore,
      0,
    );
    const resultsScoreSum = assessment.results.reduce((sum: number, current: ResultType) => sum + current.score, 0);
    return Math.ceil((resultsScoreSum / levelsScoreSum) * 100);
  }, [assessment.competence]);

  const reviewersValues = useMemo(
    () => <TableUserAvatar users={assessment?.reviewers?.map(user => new UserInfo(user))} fileSize={48} />,
    [assessment?.reviewers],
  );

  const organizersValues = useMemo(
    () => <TableUserAvatar users={assessment?.organizers?.map(user => new UserInfo(user))} fileSize={48} />,
    [assessment?.organizers],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.assessmentDetailsTitle)}
      classNameModal="modal--popover assessment-details-modal"
      shouldCloseOnOverlayClick
      modalStyle={modalStyle}
      modalOptions={
        checkPolicies([UPDATE_USER_EXTENDED], policies) ? (
          <>
            <Button
              externalClass={'modal__options-btn'}
              onClick={() => {
                document.body.style.overflow = 'auto';
                onCloseRequest();
                onEditRequest();
              }}
              color="white"
            >
              <Icon iconName={'pencil'} externalClass={'modal__options-icon'} />
            </Button>
            <Button
              externalClass={'modal__options-btn'}
              onClick={() => {
                onDeleteRequest();
                onCloseRequest();
              }}
              color="white"
            >
              <Icon iconName={'trash'} externalClass={'modal__options-icon'} />
            </Button>
          </>
        ) : null
      }
    >
      <div className="modal__event__organization-info">
        <div className="modal__event__date-time-info">
          <div className="modal__event__date">{moment(assessment.assessmentDate).format(DATE_FORMAT.ll)}</div>
        </div>
      </div>
      <div className="modal__wrapper">
        <div className="modal__info-row ">
          <div className="modal__info-row__name">
            <FormattedMessage {...messages.competenceTitle} />
          </div>
          <div className="modal__info-row__value">
            {assessment.competence.name} {competencePercent}%
          </div>
        </div>
      </div>
      {assessment.eventId && (
        <div className="modal__info-row">
          <div className="modal__info-row__name">
            <FormattedMessage {...messages.relatedEventSelect} />
          </div>
          {assessment.event.isUserHaveAccess ? (
            <NavLink to={r.eventInformation.replace(':id', `${assessment.eventId}`)}>
              <div className="modal__info-row__value modal__info-row__value--global">
                {assessment.event.eventPreviewTitle}
              </div>
            </NavLink>
          ) : (
            <span>{assessment.event.eventPreviewTitle}</span>
          )}
        </div>
      )}
      <div className="modal__wrapper">
        <div className="modal__info-row modal__info-row--participants modal__info-row--half">
          <div className="modal__info-row__name">
            <FormattedMessage {...messages.organizersFilter} />
          </div>
          <div className="modal__info-row__value">{organizersValues}</div>
        </div>
        <div className="modal__info-row modal__info-row--participants modal__info-row--half">
          <div className="modal__info-row__name">
            <FormattedMessage {...messages.reviewersFilter} />
          </div>
          <div className="modal__info-row__value">{reviewersValues}</div>
        </div>
      </div>
      <Hint customId={userHintId} className="table__user-name tooltip" />
    </Modal>
  );
}

export default ModalAssessmentDetails;
