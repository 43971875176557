import { defineMessages } from 'react-intl';
import { newButton, editButton, deleteButton, nameColumn } from '../../i18n/defaultMessage';

export default defineMessages({
  newButton,
  editButton,
  deleteButton,
  nameColumn,
  pageTitle: {
    id: 'resumes.language.levels.title.language.levels',
    defaultMessage: 'Language Levels',
  },
});
