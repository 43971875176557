import { CurrencyFormatter, Transaction } from '../../enums/finance/finance.enum';
import {
  ExpenseIncomeTypesGroupEnum,
  ExpenseIncomeTypesOptionsType,
  PayerRecipientGroupEnum,
} from '../../pages/Transactions/utils';

export const getDefaultCurrency = (value = 0) => {
  const formattedValue = value.toFixed(2).toString();
  return new CurrencyFormatter({
    float: value,
    value: formattedValue,
    formatted: formattedValue,
  });
};

export const emptyFilterCurrencyValue = new CurrencyFormatter({
  float: null,
  value: '',
  formatted: '',
});

export const definePayerRecipient = (transaction: Transaction) => {
  if (transaction.supplierPayerRecipientId) {
    return PayerRecipientGroupEnum.SUPPLIERS;
  }
  if (transaction.clientPayerRecipientId) {
    return PayerRecipientGroupEnum.CLIENTS;
  }
  if (transaction.officePayerRecipientId) {
    return PayerRecipientGroupEnum.OFFICES;
  }
  if (transaction.userPayerRecipientId) {
    return PayerRecipientGroupEnum.USERS;
  }

  return null;
};

export const getExpenseIncomeTypesValues = (
  expenseIncomeTypesOptions: {
    label: string;
    type: ExpenseIncomeTypesGroupEnum;
    options: ExpenseIncomeTypesOptionsType[];
  }[],
  type: ExpenseIncomeTypesGroupEnum,
  expenseIncomeTypeValue: string,
) => {
  return expenseIncomeTypesOptions
    .find(opt => opt.type === type)
    ?.options?.find(({ value }) => {
      const id = value instanceof Object ? value.id : value;
      return id === expenseIncomeTypeValue;
    });
};
