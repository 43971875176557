import { defineMessages } from 'react-intl';
import {
  deleteButton,
  saveButton,
  cancelButton,
  deleteModalWithItemNameTitle,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteModalWithItemNameTitle,
  nameColumn,
  cancelButton,
  saveButton,
  deleteButton,
  newSkillLevelTitle: {
    id: 'resumes.skill.levels.modal.new.skill.level.title.new.skill.level',
    defaultMessage: 'New Skill Level',
  },
  editSkillLevelTitle: {
    id: 'resumes.skill.levels.modal.edit.skill.level.title.edit.skill.level',
    defaultMessage: 'Edit  Skill Level',
  },
});
