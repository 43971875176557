import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/reports.constants';
import * as paramsTypes from '../enums/params/reports.params';
import { ReportsUserStatusesType } from '../types/reports';

export const getEmployeesReport = (): AnyAction => ({
  type: ActionTypes.GET_EMPLOYEES_REPORT_REQUEST,
});

export const setEmployeesReportParams = (
  data: Partial<paramsTypes.EmployeesReportParams>,
  isGetList = true,
): AnyAction => ({
  type: ActionTypes.SET_EMPLOYEES_REPORT_PARAMS_REQUEST,
  payload: {
    data,
    isGetList,
  },
});

export const setEmployeesTimesheetReportParams = (data: Partial<paramsTypes.EmployeesReportParams>): AnyAction => ({
  type: ActionTypes.SET_EMPLOYEES_TIMESHEET_REPORT_PARAMS,
  payload: data,
});

export const getProjectsReport = (): AnyAction => ({
  type: ActionTypes.GET_PROJECTS_REPORT_REQUEST,
});

export const setProjectsReportParams = (
  data: Partial<paramsTypes.ProjectsReportParams>,
  isGetList = true,
): AnyAction => ({
  type: ActionTypes.SET_PROJECTS_REPORT_PARAMS_REQUEST,
  payload: {
    data,
    isGetList,
  },
});

export const setEmployeesIssueTypesParams = (data: Partial<paramsTypes.EmployeesIssueTypesParams>): AnyAction => ({
  type: ActionTypes.SET_EMPLOYEES_ISSUE_TYPES_PARAMS_REQUEST,
  payload: data,
});

export const setUsersHoursAbsencesReportParams = (
  data: Partial<paramsTypes.UsersHoursAbsencesReportParams>,
): AnyAction => ({
  type: ActionTypes.SET_USERS_HOURS_ABSENCES_REPORT_PARAMS_REQUEST,
  payload: data,
});

export const setAbsencePeriodsParams = (data: Partial<paramsTypes.AbsencePeriodsParams>): AnyAction => ({
  type: ActionTypes.SET_ABSENCE_PERIODS_PARAMS_REQUEST,
  payload: data,
});

export const setActiveInactiveHoursReportParams = (
  data: Partial<paramsTypes.ActiveInactiveHoursReportParams>,
): AnyAction => ({
  type: ActionTypes.SET_ACTIVE_INACTIVE_HOURS_REPORT_PARAMS_REQUEST,
  payload: data,
});

export const setReportsUsersStatuses = (data: ReportsUserStatusesType): AnyAction => ({
  type: ActionTypes.SET_REPORTS_USERS_STATUSES_REQUEST,
  payload: data,
});

export const setRoundingMode = (data: ActionTypes.ReportsRoundingMode | null): AnyAction => ({
  type: ActionTypes.SET_ROUNDING_MODE_REQUEST,
  payload: data,
});

export const setFoldSubtask = (data: boolean): AnyAction => ({
  type: ActionTypes.SET_FOLD_SUBTASK_REQUEST,
  payload: data,
});
