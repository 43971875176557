import Keycloak from 'keycloak-js';
import ENV from '../constants/env.constants';

const _kc = new Keycloak({
  url: ENV.KEYCLOAK_URL,
  realm: ENV.KEYCLOAK_REALM,
  clientId: ENV.KEYCLOAK_CLIENTID,
});

export const initKeycloak = () => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      redirectUri: window.location.href,
    })
    .then(authenticated => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
    })
    .catch(console.error);
};

export const keycloakLogin = () => {
  initKeycloak();
  _kc.login();
};

export const validateKeycloakLoginErrors = (message: string) => {
  if (message.includes('Cannot find resource <User> by <email>')) {
    const email = message.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
    return `Cannot find a user with ${email} email address`;
  }
};
