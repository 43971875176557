export const GET_BONUSES_LIST_REQUEST = 'GET_BONUSES_LIST_REQUEST';
export const GET_BONUSES_LIST_SUCCESS = 'GET_BONUSES_LIST_SUCCESS';
export const GET_BONUSES_LIST_FAILURE = 'GET_BONUSES_LIST_FAILURE';

export const POST_BONUS_REQUEST = 'POST_BONUS_REQUEST';
export const POST_BONUS_SUCCESS = 'POST_BONUS_SUCCESS';
export const POST_BONUS_FAILURE = 'POST_BONUS_FAILURE';

export const PUT_BONUS_REQUEST = 'PUT_BONUS_REQUEST';
export const PUT_BONUS_SUCCESS = 'PUT_BONUS_SUCCESS';
export const PUT_BONUS_FAILURE = 'PUT_BONUS_FAILURE';

export const DELETE_BONUS_REQUEST = 'DELETE_BONUS_REQUEST';
export const DELETE_BONUS_SUCCESS = 'DELETE_BONUS_SUCCESS';
export const DELETE_BONUS_FAILURE = 'DELETE_BONUS_FAILURE';

export const GET_BONUSES_SETTINGS_REQUEST = 'GET_BONUSES_SETTINGS_REQUEST';
export const GET_BONUSES_SETTINGS_SUCCESS = 'GET_BONUSES_SETTINGS_SUCCESS';
export const GET_BONUSES_SETTINGS_FAILURE = 'GET_BONUSES_SETTINGS_FAILURE';

export const EDIT_BONUSES_SETTINGS_REQUEST = 'EDIT_BONUSES_SETTINGS_REQUEST';
export const EDIT_BONUSES_SETTINGS_SUCCESS = 'EDIT_BONUSES_SETTINGS_SUCCESS';
export const EDIT_BONUSES_SETTINGS_FAILURE = 'EDIT_BONUSES_SETTINGS_FAILURE';

export const SET_BONUSES_PARAMS_REQUEST = 'SET_BONUSES_PARAMS_REQUEST';

export const RESET_BONUSES_ERRORS = 'RESET_BONUSES_ERRORS';

export const RESET_BONUSES_STATE = 'RESET_BONUSES_STATE';

export const BONUS_INTERVAL_BOUNDARIES = ['MONTH', 'YEAR'];
