import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import Select from '../../Select';
import { AssessmentsInfo, ResultType } from '../../../enums/competencies.enum';
import { UserInfo } from '../../../enums/users.enum';
import { CompetenceLevelType, CompetenceType } from '../../../pages/Competencies/Competencies';
import { find, isEmpty } from 'lodash-es';
import { OptionTypeBase } from 'react-select';
import { ASSESSMENT_VALIDATION_SCHEMA } from '../../../enums/competencies.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { EventPreviewInfo } from '../../../enums/schedule.enum';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useFiltersListValue, useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import HierarchicalTable from '../../HierarchicalTable';
import { getTableData } from '../../../utils/table.utils';
import { ScoreError, useDataForTable } from './useDataForTable';
import { EventsParams } from '../../../enums/params/schedule.params';
import moment from 'moment';
import { scrollToError } from '../../../utils';
import { DATE_FORMAT } from '../../../constants/date.constants';

type ModalNewAssessmentProps = {
  onCloseRequest: () => void;
  lastAssessmentResults: ResultType[];
  getLastAssessmentResults: any;
  createNewAssessment: (data: { data: AssessmentsInfo; cb: () => void }) => void;
  assessmentError: string | RejectValueErrors[] | null;
  isLoading: any;
  users: any;
  competencies: CompetenceType[];
  getEventsForTargetEmployee: (data: Partial<EventsParams>) => void;
  events: EventPreviewInfo[];
  eventsLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
  resetLastAssessmentResults: () => void;
  targetEmployee?: UserInfo;
};

function ModalNewAssessment({
  onCloseRequest,
  getEventsForTargetEmployee,
  createNewAssessment,
  getLastAssessmentResults,
  lastAssessmentResults,
  assessmentError,
  isLoading,
  users,
  competencies,
  events,
  eventsLoading,
  resetErrors,
  isOpen,
  resetLastAssessmentResults,
  targetEmployee,
}: ModalNewAssessmentProps) {
  const intl = useIntl();
  const [resultsErrors, setResultsErrors] = useState<ScoreError[]>([]);

  const { values, errors, touched, handleSubmit, setFieldValue, setFieldError, handleChange } = useFormik({
    initialValues: new AssessmentsInfo(),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: ASSESSMENT_VALIDATION_SCHEMA,
    onSubmit: data => {
      !resultsErrors.filter(item => item.message !== intl.formatMessage(messages.scorsWarningTitle)).length &&
        createNewAssessment({
          data: {
            ...data,
            assessmentDate: moment(data.assessmentDate)
              .set({ hour: 11, minute: 20 })
              .format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
          },
          cb: resetAndExit,
        });
    },
  });

  useSetFieldsErrors(assessmentError, setFieldError);

  useEffect(() => {
    if (!isEmpty(lastAssessmentResults)) {
      setFieldValue('results', lastAssessmentResults);
    }
  }, [lastAssessmentResults]);

  const resetAndExit = () => {
    onCloseRequest();
    resetErrors();
    resetLastAssessmentResults();
  };

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  useEffect(() => {
    targetEmployee && setEmployee({ value: targetEmployee });
  }, [targetEmployee]);

  useEffect(() => {
    values.employee?.id &&
      values.assessmentDate &&
      values.assessmentDate.split(' ')[0].length === 10 &&
      moment(values.assessmentDate).isValid() &&
      getEventsForTargetEmployee({
        targetEmployees: [values.employee.id],
        dateTimeFrom: moment(values.assessmentDate).subtract('month', 3).format(),
        dateTimeTo: moment(values.assessmentDate).add('month', 3).format(),
        assessmentsEnabled: true,
      });
  }, [values.employee, values.assessmentDate]);

  const employeesOptions = useMemo(
    () =>
      users?.map((el: UserInfo) => ({
        value: el,
        label: el.fullName,
      })),
    [users],
  );

  const employeeValues = useMemo(
    () =>
      values.employee?.id
        ? {
            value: values.employee,
            label: values.employee.fullName,
          }
        : null,
    [values.employee],
  );

  const organizersOptions = useMemo(
    () => employeesOptions?.filter((user: UserInfo) => values.employee?.id !== user.id),
    [employeesOptions],
  );

  const competenciesOptions = useMemo(
    () =>
      competencies?.map((level: Record<string, any>) => ({
        label: level.name,
        value: level.id,
      })),
    [competencies],
  );

  const competencesValue = useMemo(
    () =>
      values.competence &&
      competenciesOptions?.find(({ value }: { value: string }) => value === values?.competence?.id),
    [values, competenciesOptions],
  );

  const eventsOptions = useMemo(
    () =>
      events
        ?.filter(el => !el.absencesEnabled)
        .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
        .map(el => ({
          label: el.title || el.eventTypeName,
          value: el,
        })),
    [events],
  );

  const eventsValue = useMemo(() => {
    return values?.event?.id
      ? {
          label: (
            <div className="related-event__wrapper">
              <span className="related-event__name">{values.event.eventTypeName}</span>
              <span className="related-event__date">{values.event.datePeriod}</span>
            </div>
          ),
          value: values.event,
        }
      : null;
  }, [values.event]);

  const organizersValues = useFiltersListValue(
    employeesOptions,
    values.organizers.map(user => user.id),
  );

  const setOrganizers = useCallback(el => {
    const newOrganizers = el.map(({ value }: OptionTypeBase) => value);
    setFieldValue('organizers', newOrganizers);
    setFieldValue(
      'organizerIds',
      newOrganizers.map((item: UserInfo) => item.id),
    );
  }, []);

  const setReviewers = useCallback(el => {
    const newReviwers = el.map(({ value }: OptionTypeBase) => value);
    setFieldValue('reviewers', newReviwers);
    setFieldValue(
      'reviewerIds',
      newReviwers.map((item: UserInfo) => item.id),
    );
  }, []);

  const setCompetence = useCallback(
    el => {
      const competence = find(competencies, item => item.id === el?.value);
      setFieldValue('competenceId', el?.value);
      setFieldValue('competence', competence);
      const results: Array<ResultType> = [];

      competence?.competenceLevels?.forEach((level: CompetenceLevelType) =>
        level?.skills?.forEach(skill =>
          results.push({ score: 0, skillId: skill.id, levelId: level.id, maxScore: skill.maxScore }),
        ),
      );

      setFieldValue('results', results);
      getLastAssessmentResults({ data: { competenceId: el?.value, userId: values.employee.id } });
    },
    [competencies, values.employee],
  );

  const setEmployee = useCallback(
    el => {
      setFieldValue(
        'organizers',
        values.organizers.filter((item: UserInfo) => item.id !== el.value.id),
      );

      setFieldValue(
        'organizerIds',
        values.organizerIds.filter((item: string) => item !== el.value.id),
      );
      setFieldValue('employee', el?.value || new UserInfo());
      setFieldValue('employeeId', el?.value?.id || '');
      setFieldValue('eventId', null);
      setFieldValue('event', {});
      values.competenceId &&
        getLastAssessmentResults({ data: { competenceId: values.competenceId, userId: el.value.id } });
    },
    [values],
  );

  const handleEventsChange = useCallback(el => {
    setFieldValue('eventId', el.value && el.value.id);
    setFieldValue('event', el.value);
  }, []);

  const { tableColumns, numberInput, handleExternalRowClass } = useDataForTable(
    values.results,
    lastAssessmentResults,
    resultsErrors,
    setFieldValue,
    setResultsErrors,
  );

  const formatEventOptionLabel = useCallback(data => {
    return data.isClearable ? (
      data.label
    ) : (
      <div className="related-event__wrapper">
        <span className="related-event__name">{data.value?.title || data.value?.eventTypeName}</span>
        <span className="related-event__date">{data.value?.datePeriod}</span>
      </div>
    );
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.newAssessmentTitle)}
      classNameModal="modal--edit-profile"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__group">
            <div className={'form__inputs-subwrapper'}>
              {!targetEmployee && (
                <div className={'form__input-block form__input-block--two-third'}>
                  <Select
                    isSelectWithAvatar
                    options={employeesOptions}
                    handleChange={setEmployee}
                    hasError={hasError('employee')}
                    errorMessage={errors.employee?.id}
                    label={intl.formatMessage(messages.memberLabel)}
                    value={employeeValues}
                    isClearable
                  />
                </div>
              )}
              <div className={'form__input-block form__input-block--third'}>
                <Input
                  id={'assessmentDate'}
                  name="assessmentDate"
                  externalClass="cursor-pointer"
                  label={intl.formatMessage(messages.dateLabel)}
                  onChange={handleChange}
                  hasError={hasError('assessmentDate')}
                  errorMessage={errors?.assessmentDate}
                  type={'date'}
                />
              </div>
            </div>
            <div className="form__input-block">
              <Select
                options={eventsOptions}
                value={eventsValue}
                handleChange={handleEventsChange}
                formatOptionLabel={formatEventOptionLabel}
                hasError={hasError('eventId')}
                errorMessage={errors.eventId}
                label={intl.formatMessage(messages.relatedEventSelect)}
                isDisabled={eventsLoading}
                isClearable
              />
            </div>
            <div className="form__input-block">
              <Select
                isMulti
                isSelectWithAvatar
                options={organizersOptions}
                handleChange={setOrganizers}
                value={organizersValues}
                label={intl.formatMessage(messages.organizersFilter)}
                hasError={hasError('organizers')}
                errorMessage={errors?.organizers as string}
              />
            </div>
            <div className="form__input-block">
              <Select
                externalClass={'table__user-avatar-select'}
                isMulti
                isSelectWithAvatar
                options={employeesOptions}
                handleChange={setReviewers}
                hasError={hasError('reviewers')}
                errorMessage={errors?.reviewers as string}
                label={intl.formatMessage(messages.reviewersFilter)}
              />
            </div>
          </div>
          <div className={'form__group'}>
            <div className="form__input-block">
              <Select
                options={competenciesOptions}
                handleChange={setCompetence}
                label={intl.formatMessage(messages.competenciesTitle)}
                hasError={hasError('competenceId')}
                errorMessage={errors?.competenceId}
                value={competencesValue}
                isClearable
              />
            </div>
          </div>
          {!isEmpty(values.results) && (
            <HierarchicalTable
              tableData={getTableData(values.competence.competenceLevels, ['skills'])}
              loading={false}
              customContent={numberInput}
              error={null}
              externalClass="table--bordered-top assessments_levels_table fixed"
              tableColumns={tableColumns}
              externalRowClass={handleExternalRowClass}
            />
          )}
        </div>
        <ErrorMessage>{assessmentError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} type={'button'} onClick={resetAndExit} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewAssessment;
