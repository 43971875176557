import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { TECHNICAL_SKILLS_VALIDATION_SCHEMA } from '../../../enums/libraries.enum';
import Icon from '../../Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { scrollToError } from '../../../utils';

type ModalNewTechnicalSkillProps = {
  onCloseRequest: () => void;
  createNewTechnicalSkill: (data: any) => void;
  technicalSkillError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalNewTechnicalSkill({
  onCloseRequest,
  createNewTechnicalSkill,
  technicalSkillError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalNewTechnicalSkillProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, resetForm, setFieldError } = useFormik<{
    name: string;
    skills: { skill: string }[];
  }>({
    initialValues: {
      name: '',
      skills: [],
    },
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: TECHNICAL_SKILLS_VALIDATION_SCHEMA,
    onSubmit: data => {
      return createNewTechnicalSkill({ data, callback: resetAndExit });
    },
  });

  useSetFieldsErrors(technicalSkillError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const addNewSkill = useCallback(() => {
    setFieldValue('skills', [...values.skills, { skill: '' }]);
  }, [values]);

  const removeSkill = useCallback(
    index => () => {
      values.skills.splice(index, 1);
      setFieldValue('competenciesLevels', values.skills);
    },
    [values],
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onCloseRequest} title={intl.formatMessage(messages.newTechnicalSkillTitle)}>
      <form className="modal__form form form--page" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper no-margin no-border">
          <div className="form__group">
            <div className="form__input-block margin-bottom-24">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages.skillGroupNameLabel)}
                defaultValue={values.name}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
            {values.skills.map((skill, index) => (
              <div className="form__group-wrapper" key={`[${index}]`}>
                <Input
                  id={`skills[${index}].skill`}
                  name={`skills[${index}].skill`}
                  label={intl.formatMessage(messages.skillLabel)}
                  defaultValue={values.skills[index].skill}
                  onChange={handleChange}
                  hasError={hasError(`skills[${index}].skill`)}
                  // @ts-ignore
                  errorMessage={errors?.skills?.[index]?.skill}
                />
                <button className="form__btn-clean-inputs" type={'button'} onClick={removeSkill(index)}>
                  <Icon iconName={'cross'} externalClass={'form__btn-clean'} />
                </button>
              </div>
            ))}
            <button className="form__btn-add-group" onClick={addNewSkill} type={'button'}>
              <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
              <FormattedMessage {...messages.skillLabel} />
            </button>
          </div>
        </div>
        <ErrorMessage>{technicalSkillError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewTechnicalSkill;
