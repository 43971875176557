import { EventTypesParams } from './../enums/params/calendar.params';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/calendar.constants';
import * as api from '../api/calendar.api';
import { AnyAction } from 'redux';
import { getQueryParams } from '../utils/params.utils';

function* getEventTypesList() {
  try {
    const params: EventTypesParams = yield select((state: RootState) => state.calendar.eventTypesParams);
    const data = getQueryParams(params);
    const { success, results } = yield call(api.getEventTypesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_EVENT_TYPES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENT_TYPES_LIST_FAILURE, payload: error });
  }
}

function* getEventTypeById({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getEventTypeById, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_EVENT_TYPE_BY_ID_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENT_TYPE_BY_ID_FAILURE, payload: error });
  }
}

function* createNewEventType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewEventType, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_EVENT_TYPE_SUCCESS });
      payload.callback();
      yield call(getEventTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_EVENT_TYPE_FAILURE, payload: error });
  }
}

function* deleteEventType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteEventType, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_EVENT_TYPE_SUCCESS });
      payload.callback();
      payload.setEventTypeCallback();
      yield call(getEventTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_EVENT_TYPE_FAILURE, payload: error });
  }
}

function* editEventType({ payload }: AnyAction) {
  try {
    if (payload.makeRequest) {
      const { success, results } = yield call(api.getEventTypeById, payload.id);

      if (success) {
        results.priority = payload.data.priority;
        results.eventTypeApplyType = 'APPLY_TO_FUTURE_EVENTS';
        const { success } = yield call(api.editEventType, results);
        if (success) {
          yield put({ type: ActionTypes.PUT_EVENT_TYPE_SUCCESS });
          yield call(getEventTypesList);
        }
      }
    } else {
      const { success } = yield call(api.editEventType, payload.data);

      if (success) {
        yield put({ type: ActionTypes.PUT_EVENT_TYPE_SUCCESS });
        payload?.callback();
        yield call(getEventTypesList);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_EVENT_TYPE_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeEvery(ActionTypes.GET_EVENT_TYPES_LIST_REQUEST, getEventTypesList),
    takeEvery(ActionTypes.GET_EVENT_TYPE_BY_ID_REQUEST, getEventTypeById),
    takeEvery(ActionTypes.POST_EVENT_TYPE_REQUEST, createNewEventType),
    takeEvery(ActionTypes.DELETE_EVENT_TYPE_REQUEST, deleteEventType),
    takeEvery(ActionTypes.PUT_EVENT_TYPE_REQUEST, editEventType),
    takeEvery(ActionTypes.SET_EVENT_TYPES_PARAMS, getEventTypesList),
  ]);
}
