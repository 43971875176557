import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Avatar from '../../components/Profile/Avatar';
import { UserInfo, UserPreviewInfo } from '../../enums/users.enum';
import { useQuestionByType } from './useQuestionByType';
import { isEmpty } from 'lodash-es';
import {
  TIME_PICKER,
  DATE_TIME_PICKER,
  SELECT_LIST_SINGLE_CHOICE,
  SELECT_LIST_MULTIPLE_CHOICE,
  DATE_PICKER,
  TemplateDataSource,
  TemplateDatabaseDataSource,
} from '../../constants/questionnaires.constants';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';
import { useIntl } from 'react-intl';
import messages from './messages';
import { CompanyPositionInfoType, SpecializationInfoType } from '../../types/libraries';
import AnonymousAvatar from '../Profile/Avatar/AnonymousAvatar';
import parse from 'html-react-parser';

type QuestionProps = {
  textQuestion?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  externalClass?: string;
  responder?: UserPreviewInfo;
  editAnswersMode?: boolean;
  isAnswer: boolean;
  field?: any;
  setFieldValue?: any;
  selectedValues?: any;
  index?: any;
  answersByPollAndUser?: any;
  status?: string;
  updateOwnAnswersError?: any;
  isSavingProgress?: boolean | undefined;
  questionsNumber: number;
  usersValues?: any;
  userPollStatus?: string;
  isAnonymous?: boolean;
  errors?: Record<string, string>;
  companyPositions?: CompanyPositionInfoType[];
  userList?: UserInfo[];
  specializations?: SpecializationInfoType[];
  jiraProjectsList?: { id: number; name: string }[];
  currentValues?: any;
};

const Question = ({
  textQuestion,
  externalClass,
  responder,
  editAnswersMode,
  isAnswer,
  field,
  setFieldValue = () => null,
  selectedValues,
  index,
  updateOwnAnswersError,
  isSavingProgress,
  userPollStatus,
  errors = { '': '' },
  isAnonymous = false,
  companyPositions,
  userList,
  specializations,
  jiraProjectsList,
  currentValues,
}: QuestionProps) => {
  const defaultClass = classNames({
    [externalClass as string]: Boolean(externalClass),
  });

  const intl = useIntl();

  const { renderQuestionByType } = useQuestionByType(
    field,
    setFieldValue,
    selectedValues,
    responder,
    index,
    updateOwnAnswersError,
    isSavingProgress,
    userPollStatus,
    errors,
    currentValues,
    jiraProjectsList,
    companyPositions,
    userList,
    specializations,
  );

  const answersType = useCallback(
    (answer: any) => {
      const { pollAnswerOptions, templateFieldType } = answer;
      switch (templateFieldType) {
        case DATE_PICKER: {
          const date = moment(pollAnswerOptions[0]?.value);
          return date.isValid() ? date.format(DATE_FORMAT.ll) : null;
        }
        case TIME_PICKER: {
          const time = moment(pollAnswerOptions[0]?.value, DATE_FORMAT.HH_mm);
          return time.isValid() ? time.format(DATE_FORMAT.LT) : null;
        }
        case DATE_TIME_PICKER: {
          const date = moment(pollAnswerOptions[0]?.value);
          return date.isValid() ? date.format(DATE_FORMAT.MMMM_D_YYYY_h_mm_A) : null;
        }
        case SELECT_LIST_SINGLE_CHOICE:
        case SELECT_LIST_MULTIPLE_CHOICE: {
          const selectValues = pollAnswerOptions?.map((option: any) => {
            if (field.dataSource === TemplateDataSource.DATABASE) {
              switch (field?.templateDatabaseDataSource) {
                case TemplateDatabaseDataSource.PROJECTS: {
                  const project = jiraProjectsList?.find(
                    (item: { id: number; name: string }) => item.id === +option.value,
                  );

                  return { id: project?.id, value: project?.name };
                }
                case TemplateDatabaseDataSource.COMPANY_POSITIONS: {
                  const companyPosition = companyPositions?.find(
                    (item: CompanyPositionInfoType) => item.id === option.value,
                  );

                  return { id: companyPosition?.id, value: companyPosition?.displayName };
                }

                case TemplateDatabaseDataSource.SPECIALIZATIONS: {
                  const specialization = specializations?.find(
                    (item: SpecializationInfoType) => item.id === option.value,
                  );

                  return { id: specialization?.id, value: specialization?.name };
                }

                case TemplateDatabaseDataSource.USERS: {
                  const user = userList?.find((item: UserInfo) => item.id === option.value);

                  return { id: user?.id, value: user?.fullName };
                }

                default: {
                  return [];
                }
              }
            }

            if (field.dataSource === TemplateDataSource.CUSTOM_LIST) {
              return field?.options?.find((optionField: any) => optionField.id === option.templateFieldOptionId);
            }
          });

          return selectValues
            ?.filter((val: any) => !!val)
            ?.map((selectValue: any) => <div key={selectValue?.id}>{selectValue?.value}</div>);
        }
        default: {
          return pollAnswerOptions[0]?.value;
        }
      }
    },
    [field, companyPositions, userList, specializations, jiraProjectsList],
  );

  const answer = useMemo(() => {
    return !isEmpty(selectedValues?.pollAnswerOptions) ? answersType(selectedValues) : '';
  }, [selectedValues, companyPositions, userList, specializations, jiraProjectsList]);

  const isNotAnswered = (!answer || isEmpty(answer)) && isAnswer && !editAnswersMode && !selectedValues?.comment;
  return (
    <div className={defaultClass}>
      {!isAnonymous ? (
        <Avatar
          userInfo={responder ?? new UserInfo()}
          size="medium"
          externalClass={classNames('polls__section--avatar', {
            'polls__section--avatar_notedit': !editAnswersMode,
          })}
          isUserPageLink
          fileSize={72}
        />
      ) : (
        <AnonymousAvatar externalClass="polls__section--avatar" />
      )}
      {!isAnswer && <p className="polls__section--description">{textQuestion}</p>}
      {editAnswersMode && isAnswer && renderQuestionByType}
      {isNotAnswered && <div className="not-answered">{intl.formatMessage(messages.notAnsweredLabel)}</div>}
      {!editAnswersMode && isAnswer && (
        <>
          <div className="polls__section--description">
            {typeof answer === 'string' ? parse(answer.replace(/\n/gi, '<br>')) : answer}
          </div>
          <div className="polls__section--description">
            {selectedValues?.comment ? parse(selectedValues?.comment?.replace(/\n/gi, '<br>')) : ''}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(Question);
