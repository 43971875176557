import React from 'react';
import Avatar from '../../../../components/Profile/Avatar';
import { userPollStatusList } from '../../../../enums/questionnaires.enum';
import { UserInfo } from '../../../../enums/users.enum';
import { styleUserStatus } from '../../../Polls/utils';

type UserItemProps = {
  user: UserInfo;
  showStatus?: boolean;
};

function UserItem({ user, showStatus = false }: UserItemProps) {
  return (
    <div key={user?.id} className="event-poll__content-header--responder event-poll--element">
      <Avatar
        userInfo={user ?? new UserInfo()}
        size="medium"
        externalClass="event-poll__content-header--responder-avatar"
        isUserPageLink
        fileSize={72}
      />
      <div className="event-poll__content-header--responder-data">
        <div className="event-poll__content-header--responder-name">{`${user?.firstName} ${user?.secondName}`}</div>
        <div
          className="event-poll__content-header--responder-position"
          style={showStatus ? { color: styleUserStatus(user?.pollStatus), opacity: 1 } : {}}
        >
          {showStatus && user.hasOwnProperty('pollStatus')
            ? userPollStatusList.find(({ value }) => value === user.pollStatus)?.label
            : user?.companyPosition?.displayName || ''}
        </div>
      </div>
    </div>
  );
}

export default UserItem;
