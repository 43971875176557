import classNames from 'classnames';
import { get } from 'lodash-es';
import React from 'react';
import Checkbox from '../../../../components/Checkbox';
import { ImportTransaction } from '../../../../enums/finance/finance.enum';
import { hintId } from '../../../../components/Hint/utils';

type TransactionImportRowProps = {
  row: ImportTransaction;
  rowIndex: number;
  tableColumns: any[];
  getError: (fieldName: string, index: number) => string | undefined;
  handleHint: (
    event: React.MouseEvent<HTMLTableCellElement>,
    column: any,
    row: ImportTransaction,
    rowIndex: number,
    error?: string,
  ) => void;
  closeHint: () => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

function TransactionImportRow({
  row,
  rowIndex,
  tableColumns,
  getError,
  handleChange,
  handleHint,
  closeHint,
}: TransactionImportRowProps) {
  return (
    <tr>
      <td>
        <Checkbox
          name={`transactions[${rowIndex}].importTransaction`}
          id={`transactions[${rowIndex}].importTransaction`}
          checkedValue={row.importTransaction}
          externalClass="checkbox-no-label"
          onChange={handleChange}
        />
      </td>
      {tableColumns.map((column, columnIndex) => {
        const parseErrorField = column?.parseErrorField;
        const error = getError(column?.fieldError, rowIndex);
        const className = classNames({
          hasError: !!error || (parseErrorField && row.importTransaction && get(row, parseErrorField)),
        });

        return (
          <td
            className={className}
            key={`td-${columnIndex}`}
            onMouseEnter={event => handleHint(event, column, row, rowIndex, error)}
            onMouseLeave={closeHint}
            data-for={hintId}
            data-tip={error ? error : ''}
          >
            {column.modifier(row, rowIndex)}
          </td>
        );
      })}
    </tr>
  );
}

export default React.memo(TransactionImportRow);
