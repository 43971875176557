import React, { useMemo } from 'react';
import Modal from '../../Modal';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Table from '../../Table';
import { styleUserStatus, userStatusLabel } from '../../../pages/Polls/utils';
import { sortResponder } from '../utils';
import TableUserAvatar from '../../TableUserAvatar';
import { UserInfo } from '../../../enums/users.enum';

type ModalPollRespondersProps = {
  onCloseRequest: () => void;
  data: any;
  isLoading: boolean;
  isOpen: boolean;
  answersByPollIdAdmin: any;
  questionsNum: number;
};

const ModalPollResponders = ({
  onCloseRequest,
  data,
  isLoading,
  isOpen,
  answersByPollIdAdmin,
  questionsNum,
}: ModalPollRespondersProps) => {
  const intl = useIntl();

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.respondersSelect),
        modifier: (row: any) => (
          <span className={'table_button'}>
            <TableUserAvatar users={[new UserInfo(row)]} fileSize={48} />
          </span>
        ),
      },
      {
        name: intl.formatMessage(messages.statusLabel),
        modifier: (row: any) => {
          const status = row?.pollStatus || row?.status;

          return (
            <span style={{ color: styleUserStatus(status) }} className={'table_button'}>
              {userStatusLabel(status)}
            </span>
          );
        },
      },
    ],
    [answersByPollIdAdmin, questionsNum],
  );

  const sortedData = useMemo(() => sortResponder(data), [data]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.respondersSelect)}
      size={'small'}
      shouldCloseOnOverlayClick
      classNameModal="center"
      popover
    >
      <div className="form__inputs-wrapper">
        <Table
          externalClass={'table custom-header-navigation poll-responders'}
          tableColumns={tableColumns}
          tableData={sortedData || []}
          loading={isLoading}
          error={''}
          tableActions={[]}
        />
      </div>
      {/* <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCloseRequest}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
      </div> */}
    </Modal>
  );
};

export default ModalPollResponders;
