import foreach from 'lodash-es/forEach';

export function createFormData(values: any) {
  const data = new FormData();

  foreach(values, (value: any, key: string) => {
    if (value instanceof Object && !(value instanceof File)) {
      data.append(
        key,
        new Blob([JSON.stringify(value)], {
          type: 'application/json',
        }),
      );
    } else {
      data.append(key, value);
    }
  });

  return data;
}
