import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/notifications.constants';
import { AnyAction } from 'redux';
import * as api from '../api/notifications.api';
import * as paramsTypes from '../enums/params/notifications.params';
import { getQueryParams } from '../utils/params.utils';

function* getMailerSettingsList() {
  const params: paramsTypes.MailerSettingsParams = yield select(
    (state: RootState) => state.notifications.mailerSettingsParams,
  );
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getMailerSettings, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_MAILER_SETTINGS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_MAILER_SETTINGS_LIST_FAILURE, payload: error });
  }
}

function* createNewMailerSetting({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewMailerSetting, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.POST_MAILER_SETTING_SUCCESS,
      });
      payload.callback();
      yield call(getMailerSettingsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_MAILER_SETTING_FAILURE, payload: error });
  }
}

function* editMailerSetting({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editMailerSetting, payload.id, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_MAILER_SETTING_SUCCESS,
      });
      payload.callback();
      yield call(getMailerSettingsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_MAILER_SETTING_FAILURE, payload: error });
  }
}

function* deleteMailerSetting({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteMailerSetting, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_MAILER_SETTING_SUCCESS,
      });
      payload.callback();
      yield call(getMailerSettingsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_MAILER_SETTING_FAILURE, payload: error });
  }
}

function* getEmployeeGroupsList() {
  const params: paramsTypes.EmployeeGroupsParams = yield select(
    (state: RootState) => state.notifications.employeeGroupsParams,
  );
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getEmployeeGroupsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_EMPLOYEE_GROUPS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMPLOYEE_GROUPS_LIST_FAILURE, payload: error });
  }
}

function* createNewEmployeeGroup({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewEmployeeGroup, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.POST_EMPLOYEE_GROUP_SUCCESS,
      });
      payload.callback();
      yield call(getEmployeeGroupsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_EMPLOYEE_GROUP_FAILURE, payload: error });
  }
}

function* editEmployeeGroup({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editEmployeeGroup, payload.id, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_EMPLOYEE_GROUP_SUCCESS,
      });
      payload.callback();
      yield call(getEmployeeGroupsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_EMPLOYEE_GROUP_FAILURE, payload: error });
  }
}

function* deleteEmployeeGroup({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteEmployeeGroup, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_EMPLOYEE_GROUP_SUCCESS,
      });
      payload.callback();
      yield call(getEmployeeGroupsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_EMPLOYEE_GROUP_FAILURE, payload: error });
  }
}

function* getFollowUpsList() {
  const params: paramsTypes.FollowUpsParams = yield select((state: RootState) => state.notifications.followUpsParams);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getFollowUpsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_FOLLOW_UPS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FOLLOW_UPS_LIST_FAILURE, payload: error });
  }
}

function* createNewFollowUp({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewFollowUp, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.POST_FOLLOW_UP_SUCCESS,
      });
      payload.callback();
      yield call(getFollowUpsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_FOLLOW_UP_FAILURE, payload: error });
  }
}

function* editFollowUp({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editFollowUp, payload.id, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_FOLLOW_UP_SUCCESS,
      });
      payload.callback();
      yield call(getFollowUpsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_FOLLOW_UP_FAILURE, payload: error });
  }
}

function* deleteFollowUp({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteFollowUp, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_FOLLOW_UP_SUCCESS,
      });
      payload.callback();
      yield call(getFollowUpsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_FOLLOW_UP_FAILURE, payload: error });
  }
}

function* getEmailTemplatesList() {
  const params: paramsTypes.EmailTemplatesParams = yield select(
    (state: RootState) => state.notifications.emailTemplatesParams,
  );

  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getEmailTemplatesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_EMAIL_TEMPLATES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMAIL_TEMPLATES_LIST_FAILURE, payload: error });
  }
}

function* createEmailTemplate({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createEmailTemplate, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.POST_EMAIL_TEMPLATE_SUCCESS,
      });
      payload.callback();
      yield call(getEmailTemplatesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_EMAIL_TEMPLATE_FAILURE, payload: error });
  }
}

function* editEmailTemplate({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editEmailTemplate, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_EMAIL_TEMPLATE_SUCCESS,
      });
      payload.callback();
      yield call(getEmailTemplatesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_EMAIL_TEMPLATE_FAILURE, payload: error });
  }
}

function* deleteEmailTemplate({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteEmailTemplate, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS,
      });
      payload.callback();
      yield call(getEmailTemplatesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_EMAIL_TEMPLATE_FAILURE, payload: error });
  }
}

export default function* notificationsSaga() {
  yield all([
    takeEvery(
      [ActionTypes.GET_MAILER_SETTINGS_LIST_REQUEST, ActionTypes.SET_MAILER_SETTINGS_PARAMS_REQUEST],
      getMailerSettingsList,
    ),
  ]);
  yield all([takeEvery(ActionTypes.POST_MAILER_SETTING_REQUEST, createNewMailerSetting)]);
  yield all([takeEvery(ActionTypes.PUT_MAILER_SETTING_REQUEST, editMailerSetting)]);
  yield all([takeEvery(ActionTypes.DELETE_MAILER_SETTING_REQUEST, deleteMailerSetting)]);
  yield all([
    takeEvery(
      [ActionTypes.GET_EMPLOYEE_GROUPS_LIST_REQUEST, ActionTypes.SET_EMPLOYEE_GROUPS_PARAMS_REQUEST],
      getEmployeeGroupsList,
    ),
  ]);
  yield all([takeEvery(ActionTypes.POST_EMPLOYEE_GROUP_REQUEST, createNewEmployeeGroup)]);
  yield all([takeEvery(ActionTypes.PUT_EMPLOYEE_GROUP_REQUEST, editEmployeeGroup)]);
  yield all([takeEvery(ActionTypes.DELETE_EMPLOYEE_GROUP_REQUEST, deleteEmployeeGroup)]);
  yield all([
    takeEvery([ActionTypes.GET_FOLLOW_UPS_LIST_REQUEST, ActionTypes.SET_FOLLOW_UPS_PARAMS_REQUEST], getFollowUpsList),
  ]);
  yield all([takeEvery(ActionTypes.POST_FOLLOW_UP_REQUEST, createNewFollowUp)]);
  yield all([takeEvery(ActionTypes.PUT_FOLLOW_UP_REQUEST, editFollowUp)]);
  yield all([takeEvery(ActionTypes.DELETE_FOLLOW_UP_REQUEST, deleteFollowUp)]);
  yield all([
    takeEvery(
      [ActionTypes.GET_EMAIL_TEMPLATES_LIST_REQUEST, ActionTypes.SET_EMAIL_TEMPLATES_PARAMS],
      getEmailTemplatesList,
    ),
  ]);
  yield all([takeEvery(ActionTypes.POST_EMAIL_TEMPLATE_REQUEST, createEmailTemplate)]);
  yield all([takeEvery(ActionTypes.PUT_EMAIL_TEMPLATE_REQUEST, editEmailTemplate)]);
  yield all([takeEvery(ActionTypes.DELETE_EMAIL_TEMPLATE_REQUEST, deleteEmailTemplate)]);
}
