import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { Recognition, RECOGNITION_VALIDATION_SCHEMA } from '../../../enums/recognitions.enum';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import Select from '../../Select';
import { get } from 'lodash-es';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { scrollToError } from '../../../utils';

type ModalEditRecognitionProps = {
  onCloseRequest: () => void;
  editRecognition: (id: string, data: any) => void;
  filterValues?: any;
  recognitionData: Recognition;
  recognitionError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
  recognitionTypes: { id: string; name: string }[];
  users: { id: string; firstName: string; secondName: string }[];
};

function ModalEditRecognition({
  isOpen,
  onCloseRequest,
  editRecognition,
  recognitionData,
  recognitionError,
  isLoading,
  resetErrors,
  recognitionTypes,
  users,
}: ModalEditRecognitionProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const { setFieldValue, handleSubmit, resetForm, setFieldError, errors, touched, values } = useFormik({
    initialValues: new Recognition(recognitionData),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: RECOGNITION_VALIDATION_SCHEMA,
    onSubmit: data => {
      return editRecognition(recognitionData?.id, { data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(recognitionError, setFieldError);

  const recognitionTypesOptions = useMemo(() => recognitionTypes.map(({ id, name }) => ({ label: name, value: id })), [
    recognitionTypes,
  ]);

  const usersOptions = useMemo(
    () => users.map(({ id, firstName, secondName }) => ({ label: `${firstName} ${secondName}`, value: id })),
    [users],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleChangeRecognitionType = useCallback(({ value, label }) => {
    setFieldValue('recognitionTypeId', value);
    setFieldValue('recognitionType', value && { id: value, name: label });
  }, []);

  const handleChangeAuthor = useCallback(({ label, value }) => {
    setFieldValue('author', value && { id: value, name: label });
    setFieldValue('authorId', value);
  }, []);

  const handleChangeEmployee = useCallback(({ label, value }) => {
    setFieldValue('targetUser', value && { id: value, name: label });
    setFieldValue('targetUserId', value);
  }, []);

  const targetUserValue = useCallback(
    () => values?.targetUser && usersOptions?.find(({ value }: { value: string }) => value === values?.targetUser?.id),
    [usersOptions, values],
  );

  const autorValue = useCallback(
    () => values?.author && usersOptions?.find(({ value }: { value: string }) => value === values?.author?.id),
    [usersOptions, values],
  );

  const handleChangeText = useCallback(event => {
    setFieldValue('text', event.target.value);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editRecognitionTitle)}
      size={'small'}
      classNameModal="center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.memberLabel)}
              options={usersOptions}
              handleChange={handleChangeEmployee}
              hasError={hasError('targetUser')}
              errorMessage={errors?.targetUser?.id}
              value={targetUserValue()}
              isClearable
            />
          </div>
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.authorColumn)}
              options={usersOptions}
              handleChange={handleChangeAuthor}
              hasError={hasError('author')}
              errorMessage={errors?.author?.id}
              value={autorValue()}
              isClearable
            />
          </div>
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.recognitionTypeSelect)}
              options={recognitionTypesOptions}
              handleChange={handleChangeRecognitionType}
              hasError={hasError('recognitionTypeId')}
              errorMessage={errors?.recognitionTypeId}
              value={values.recognitionType && { label: values.recognitionType.name, value: values.recognitionType.id }}
              isClearable
            />
          </div>
          <div className="form__input-block">
            <Input
              name={'text'}
              tag={'textarea'}
              label={intl.formatMessage(messages.textInput)}
              onChange={handleChangeText}
              hasError={hasError('text')}
              errorMessage={errors?.text}
              defaultValue={values.text}
            />
          </div>
        </div>
        <ErrorMessage>{recognitionError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.submitButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditRecognition;
