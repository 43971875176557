import { defineMessages } from 'react-intl';
import { cancelButton } from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  exportingLabel: {
    id: 'header.label.exporting',
    defaultMessage: 'Exporting...',
  },
});
