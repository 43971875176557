import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import ErrorMessage from '../ErrorMessage';

type FileInputProps = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  defaultValue?: string;
  externalClass?: string;
  name?: string;
  accept?: string;
  errorMessage?: string;
  fileRef?: any;
};

function FileInput({
  onChange,
  name,
  disabled,
  accept,
  externalClass,
  defaultValue,
  errorMessage,
  fileRef,
}: FileInputProps) {
  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const handleChange = useCallback(
    event => {
      setValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    },
    [value],
  );

  const defaultClass = classNames('form__input', 'form__input--no-label', {
    [externalClass as string]: Boolean(externalClass),
  });

  return (
    <span>
      <input
        ref={fileRef}
        name={name}
        defaultValue={value}
        type="file"
        accept={accept}
        className={defaultClass}
        onChange={handleChange}
        disabled={disabled}
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </span>
  );
}

export default FileInput;
