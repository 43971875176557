import { SortParams } from '../params.enum';
import { get } from 'lodash-es';
import { getDateParams } from '../../utils/params.utils';

export class ResourcesParams {
  dateFrom: string;
  dateTo: string;
  departmentIds: string[];
  officeIds: string[];
  projectGroupIds: number[];
  userIds: string[];
  withoutFullCaseload: boolean | undefined;
  constructor(params?: unknown) {
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false }));
    this.departmentIds = get(params, 'departmentIds', []);
    this.officeIds = get(params, 'officeIds', []);
    this.projectGroupIds = get(params, 'projectGroupIds', []);
    this.userIds = get(params, 'userIds', []);
    this.withoutFullCaseload = get(params, 'withoutFullCaseload', undefined);
  }
}

export class UserHoursParams {
  dateFrom: string;
  dateTo: string;
  departmentIds: string[];
  officeIds: string[];
  includedInPlanning: boolean | null;
  userIds: string[];
  constructor(params?: unknown) {
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false }));
    this.departmentIds = get(params, 'departmentIds', []);
    this.officeIds = get(params, 'officeIds', []);
    this.includedInPlanning = get(params, 'includedInPlanning', true);
    this.userIds = get(params, 'userIds', []);
  }
}

export class ProjectHoursParams {
  dateFrom: string;
  dateTo: string;
  departmentIds: string[];
  officeIds: string[];
  projectGroupIds: string[];
  constructor(params?: unknown) {
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false }));
    this.departmentIds = get(params, 'departmentIds', []);
    this.officeIds = get(params, 'officeIds', []);
    this.projectGroupIds = get(params, 'projectGroupIds', []);
  }
}

export class ProjectGroupsParams extends SortParams {
  size: number;
  page: number;
  jiraProjects: number[];
  status: boolean | null;
  constructor(params?: unknown) {
    super('fullName', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
    this.jiraProjects = get(params, 'jiraProjects', []);
    this.status = get(params, 'status', null);
  }
}

export class ActiveEmployeesParams extends SortParams {
  size: number;
  userIds: string[];
  officeIds: string[];
  departmentIds: string[];
  includedInPlanning?: boolean;
  constructor(params?: unknown) {
    super([
      { sortBy: 'department.displayName', direction: 'ASC' },
      { sortBy: 'secondName', direction: 'ASC' },
    ]);
    this.size = get(params, 'size', 10000);
    this.userIds = get(params, 'userIds', []);
    this.officeIds = get(params, 'officeIds', []);
    this.departmentIds = get(params, 'departmentIds', []);
    this.includedInPlanning = get(params, 'includedInPlanning', undefined);
  }
}
