import { defineMessages } from 'react-intl';
import { officesLabel, totalRow, membersLabel, expenseTypesLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  membersLabel,
  officesLabel,
  totalRow,
  expenseTypesLabel,
  clientsLabel: {
    id: 'finance.incomes.expenses.report.label.clients',
    defaultMessage: 'Clients',
  },
  incomeTypesLabel: {
    id: 'finance.incomes.expenses.report.label.income.types',
    defaultMessage: 'Income Types',
  },
  salariesLabel: {
    id: 'finance.incomes.expenses.report.label.salaries',
    defaultMessage: 'Salaries',
  },
  clientsProjectsColumn: {
    id: 'finance.incomes.expenses.report.column.clients.projects',
    defaultMessage: 'Clients / Projects',
  },
  incomesTitle: {
    id: 'finance.incomes.expenses.report.title.incomes',
    defaultMessage: 'Incomes',
  },
  expensesTitle: {
    id: 'finance.incomes.expenses.report.title.expenses',
    defaultMessage: 'Expenses',
  },
  typesColumn: {
    id: 'finance.incomes.expenses.report.label.column',
    defaultMessage: 'Types',
  },
  totalExpenseRlabel: {
    id: 'finance.incomes.expenses.report.label.total.expenses',
    defaultMessage: 'Total Expenses',
  },
  plannedIncomeLabel: {
    id: 'finance.incomes.expenses.report.label.planned.income',
    defaultMessage: 'Planned Income',
  },
  actualIncomeLabel: {
    id: 'finance.incomes.expenses.report.label.actual.income',
    defaultMessage: 'Actual Income',
  },
  plannedTotalLabel: {
    id: 'finance.incomes.expenses.report.label.planned.Total',
    defaultMessage: 'Planned Total',
  },
  actualTotalLabel: {
    id: 'finance.incomes.expenses.report.label.actual.total',
    defaultMessage: 'Actual Total',
  },
  plannedExpensesTitle: {
    id: 'finance.incomes.expenses.report.title.planned.expenses',
    defaultMessage: 'Planned Expenses',
  },
  actualExpensesTitle: {
    id: 'finance.incomes.expenses.report.title.actual.expenses',
    defaultMessage: 'Actual Expenses',
  },
});
