import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, { components, OptionTypeBase } from 'react-select';
import classNames from 'classnames';
import Icon from '../Icon';
import ErrorMessage from '../ErrorMessage';
import { useClickOutsideHandler, useUpdateDropdownMenuPosition } from '../../utils/hooks.utils';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { isArray, isEmpty, omit } from 'lodash-es';
import ModalNewSavedFilter from './Modals/ModalNewSavedFilter';
import ModalDeleteSavedFilter from './Modals/ModalDeleteSavedFilter';
import { SavedFilter, SavedFiltersDataType, StorageSavedFilterType } from '../../enums/filters.enum';
import Dropdown from '../Dropdown';
import ModalEditSavedFilter from './Modals/ModalEditSavedFilter';
import { getSavedFilters, removeSavedFilterFromStorage, setSavedFilterToStorage } from '../../utils/filters.utils';
import { FilterTypes } from '../../constants/filters.constants';
import PoliciesContext from '../../PoliciesContext';
import { DELETE_PUBLIC_FILTER, VIEW_PUBLIC_FILTER } from '../../constants/policies.constants';
import { checkPolicies } from '../../utils/policies.utils';
import { offset, useFloating, shift, size, limitShift } from '@floating-ui/react-dom';

export type FilterOptionType = {
  label: string;
  value: any;
};

type FilterProps = {
  handleChange: (val: any) => void;
  handleSaveFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  handleUpdateFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  handleDeleteFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  filterType: FilterTypes;
  params: any;
  resetSavedFilterErrors: () => void;
  unsavedParams?: string[];
  resetCurrentFilter: boolean;
  setResettFilterFlag: () => void;
};

function FiltersControl({
  params,
  handleChange,
  handleSaveFilter,
  handleUpdateFilter,
  handleDeleteFilter,
  savedFiltersData,
  authUserId,
  filterType,
  resetSavedFilterErrors,
  unsavedParams,
  resetCurrentFilter,
  setResettFilterFlag,
}: FilterProps) {
  const intl = useIntl();
  const userPolicies = useContext(PoliciesContext);
  const [modalNewSavedFilterIsOpen, setModalNewSavedFilterIsOpen] = useState(false);
  const [modalEditSavedFilterIsOpen, setModalEditSavedFilterIsOpen] = useState(false);
  const [modalDeleteSavedFilterIsOpen, setModalDeleteSavedFilterIsOpen] = useState(false);
  const [savedFilterClicked, setSavedFilterClicked] = useState<SavedFilter>(new SavedFilter());
  const [selectedFilter, setSelectedFilter] = useState<OptionTypeBase>();

  useEffect(() => {
    const currentSavedFilter = getSavedFilters()?.find((item: StorageSavedFilterType) => item.type === filterType);
    const filter = savedFiltersData?.filtersList.find(item => item.id === currentSavedFilter?.id);

    filter && setSelectedFilter({ label: filter.name, value: filter });
  }, [savedFiltersData?.filtersList]);

  useEffect(() => {
    if (resetCurrentFilter) {
      setSelectedFilter(undefined);
      removeSavedFilterFromStorage(filterType);
    }
  }, [resetCurrentFilter, filterType]);

  const openNewSavedFilterModal = useCallback(() => {
    setModalNewSavedFilterIsOpen(true);
  }, []);

  const closeNewSavedFilterModal = useCallback(() => {
    resetSavedFilterErrors();
    setModalNewSavedFilterIsOpen(false);
  }, []);

  const closeEditFilterModal = useCallback(() => {
    resetSavedFilterErrors();
    setModalEditSavedFilterIsOpen(false);
  }, []);

  const closeDeleteFilterModal = useCallback(() => {
    resetSavedFilterErrors();
    setModalDeleteSavedFilterIsOpen(false);
  }, []);

  const options = useMemo(() => {
    const myFilters = savedFiltersData?.filtersList
      .filter(filter => filter.authorId == authUserId)
      .map(filter => ({ label: filter.name, value: filter }))
      .sort((a: any, b: any) => a.label?.localeCompare(b.label));

    const publicFilters = savedFiltersData?.filtersList
      .filter(filter => filter.isPublic && filter.authorId !== authUserId)
      .map(filter => ({ label: filter.name, value: filter }))
      .sort((a: any, b: any) => a.label?.localeCompare(b.label));

    return [
      ...(myFilters.length
        ? [
            {
              label: (
                <>
                  <Icon iconName="user" />
                  <span>{intl.formatMessage(messages.myFiltersLabel)}</span>
                </>
              ),
              options: myFilters,
            },
          ]
        : []),
      ...(checkPolicies([VIEW_PUBLIC_FILTER], userPolicies) && publicFilters.length
        ? [
            {
              label: (
                <>
                  <Icon iconName="users" />
                  <span>{intl.formatMessage(messages.publicFiltersLabel)}</span>
                </>
              ),
              options: publicFilters,
            },
          ]
        : []),
    ];
  }, [savedFiltersData?.filtersList, authUserId, userPolicies]);

  const tableActions = useCallback(
    value => {
      return [
        ...(value.authorId === authUserId || checkPolicies([DELETE_PUBLIC_FILTER], userPolicies)
          ? [
              {
                label: (
                  <>
                    <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                    {intl.formatMessage(messages.editButton)}
                  </>
                ),
                itemClassName: 'dropdown__list-item__button',
                handler: (row: any) => {
                  setSavedFilterClicked(row);
                  setModalEditSavedFilterIsOpen(true);
                },
              },
            ]
          : []),
        ...(value.authorId === authUserId
          ? [
              {
                label: (
                  <>
                    <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
                    {intl.formatMessage(messages.deleteButton)}
                  </>
                ),
                itemClassName: 'dropdown__list-item__button',
                handler: (row: any) => {
                  setSavedFilterClicked(row);
                  setModalDeleteSavedFilterIsOpen(true);
                },
              },
            ]
          : []),
      ];
    },
    [authUserId, userPolicies],
  );

  const formatOptionLabel = useCallback(
    ({ label, value }) => (
      <>
        <div
          className="filter-control-item"
          onClick={e => {
            e.stopPropagation();
            setSavedFilterToStorage(filterType, value.id, value.fields);
            setSelectedFilter({ label: value.name, value: value });
            handleChange(value);
            setResettFilterFlag();
          }}
        >
          <span>{label}</span>
        </div>
        {!!tableActions(value).length && (
          <Dropdown
            dropdownClass="dropdown--no-bg"
            dropdownToggle={<Icon iconName={'dots'} externalClass={'dropdown__button-main-icon'} />}
            dropdownList={tableActions(value)}
            stopPropagation
            dropdownInfo={value}
          />
        )}
      </>
    ),
    [tableActions, authUserId, filterType, params],
  );

  const ref = useRef<any>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [customOptions, setCustomOptions] = useState(options);
  const [inputValue, setInputValue] = useState('');
  const maxHeight = useRef<any>(null);

  const { refs, floatingStyles, update } = useFloating({
    middleware: [
      offset(5),
      shift({
        boundary: document.body,
        limiter: limitShift({
          offset: 5,
          mainAxis: true,
        }),
      }),
      size({
        apply({ availableHeight, availableWidth, elements }) {
          maxHeight.current = `${availableHeight}px`;
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 5}px`,
          });
        },
      }),
    ],
    open: isOpen,
    placement: 'bottom-start',
  });

  useUpdateDropdownMenuPosition(update);

  useEffect(() => {
    if (isOpen) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    }
    return () => {
      window.onscroll = () => null;
    };
  }, [isOpen]);

  useEffect(() => {
    setCustomOptions(options);
  }, [options]);

  useEffect(() => {
    setInputValue('');
  }, [isOpen]);

  useClickOutsideHandler(
    () => {
      //if we click on dropdown item, filter will be closed. That's why we need to close filter after that using setTimout
      setTimeout(() => setIsOpen(false), 100);
    },
    { current: ref.current?.select?.menuListRef },
    refs.reference,
  );

  const filterComponents = useMemo(
    () => ({
      IndicatorSeparator: null,
      DropdownIndicator: () => null,
      Menu: ({ children, ...props }: any) => {
        const { inputValue } = props.selectProps;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onMouseDown, ...rest } = props.innerProps;
        const newProps = { ...props, innerProps: rest };
        return (
          <components.Menu {...newProps}>
            <div className="filter__menu-wrapper" ref={refs.setFloating} style={omit(floatingStyles, 'transform')}>
              {
                <>
                  {!!props.options.length && (
                    <div className="filter__search-wrapper">
                      <Icon iconName="search" />
                      <input
                        type="text"
                        className="filter__search"
                        placeholder={intl.formatMessage(messages.searchLabel)}
                        value={inputValue}
                        onMouseDown={e => {
                          // e.stopPropagation();
                          //@ts-ignore
                          e.target.focus();
                        }}
                        onTouchEnd={e => {
                          // e.stopPropagation();
                          //@ts-ignore
                          e.target.focus();
                        }}
                        autoFocus={ref.current?.state?.menuIsOpen}
                        onChange={e => setInputValue(e.currentTarget.value)}
                        onBlur={e => setTimeout(() => e.target.focus(), 1)}
                      />
                      {inputValue && (
                        <span onClick={() => setInputValue('')}>
                          <Icon iconName="times-circle" />
                        </span>
                      )}
                    </div>
                  )}
                  <ErrorMessage>{savedFiltersData.errors}</ErrorMessage>
                  <div className="filter__button-wrapper">
                    <div
                      className={`filter__option-label filter-button`}
                      onClick={() =>
                        selectedFilter && options.length && selectedFilter?.value.authorId === authUserId
                          ? editSavedFilterFields(selectedFilter?.value)
                          : openNewSavedFilterModal()
                      }
                    >
                      <span className="filter__clear-text ">
                        <FormattedMessage {...messages.saveButton} />
                      </span>
                    </div>
                    {!!selectedFilter && !!options.length && selectedFilter?.value.authorId === authUserId && (
                      <div className={`filter__option-label filter-button`} onClick={() => openNewSavedFilterModal()}>
                        <span className="filter__clear-text">
                          <FormattedMessage {...messages.saveAsButton} />
                        </span>
                      </div>
                    )}
                  </div>
                </>
              }
              {children}
            </div>
          </components.Menu>
        );
      },
      Control: ({ children, ...props }: any) => {
        return (
          <div onClick={() => setIsOpen(prev => !prev)}>
            <components.Control {...props}>{children}</components.Control>
          </div>
        );
      },
      Option: ({ children, ...props }: any) => {
        const { value } = props;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
        const newProps = { ...props, innerProps: rest };
        return (
          <components.Option {...newProps}>
            <div className={classNames('filter__option-label')}>
              {selectedFilter && value.id === selectedFilter.value.id && (
                <Icon iconName="check" externalClass="is-public-icon" />
              )}
              {children}
              {value.isPublic && value.authorId === authUserId && (
                <Icon iconName="users" externalClass="is-public-icon" />
              )}
            </div>
          </components.Option>
        );
      },
    }),
    [options, selectedFilter, params, authUserId, customOptions],
  );

  const valueContainerComponent = useMemo(() => {
    return {
      ValueContainer: ({ ...props }: any) => {
        return <components.ValueContainer {...props}>{<Icon iconName="dots" />}</components.ValueContainer>;
      },
    };
  }, []);

  const baseUnsavedParams = useMemo(() => (unsavedParams ? ['page', 'size', ...unsavedParams] : ['page', 'size']), [
    unsavedParams,
  ]);

  const createNewSavedFilter = useCallback(
    (data: { data: SavedFilter; callback: () => void }) => {
      const fields = [];

      for (const [key, value] of Object.entries(params)) {
        value !== undefined &&
          value !== null &&
          !baseUnsavedParams.find(item => item === key) &&
          (!isArray(value) || !isEmpty(value)) &&
          fields.push({ name: key, value: JSON.stringify(value) });
      }

      handleSaveFilter({ ...data, data: { ...data.data, filterType, fields } });
    },
    [params, filterType, baseUnsavedParams],
  );

  const editSavedFilterFields = useCallback(
    (data: SavedFilter) => {
      const fields = [];

      for (const [key, value] of Object.entries(params)) {
        value !== undefined &&
          value !== null &&
          !baseUnsavedParams.find(item => item === key) &&
          (!isArray(value) || !isEmpty(value)) &&
          fields.push({ name: key, value: JSON.stringify(value) });
      }

      handleUpdateFilter({ data: { ...data, fields } });
    },
    [params, baseUnsavedParams],
  );

  const deleteSavedFilterFields = useCallback(
    (data: { id: string; callback: () => void }) => {
      if (data.id === selectedFilter?.value.id) {
        setSelectedFilter(undefined);

        removeSavedFilterFromStorage(filterType);
      }

      handleDeleteFilter(data);
    },
    [selectedFilter, filterType],
  );

  return (
    <>
      <div
        ref={refs.setReference}
        //@ts-ignore
        style={{ '--data-transform': floatingStyles.transform, '--data-max-height': maxHeight.current }}
      >
        <ReactSelect
          ref={ref}
          className={'filter filter-control'}
          classNamePrefix="filter"
          isSearchable={false}
          formatOptionLabel={formatOptionLabel}
          inputValue={inputValue}
          isClearable={false}
          value={selectedFilter}
          menuIsOpen={isOpen}
          hideSelectedOptions={false}
          placeholder={null}
          options={customOptions}
          components={{ ...filterComponents, ...valueContainerComponent }}
        />
      </div>
      {modalNewSavedFilterIsOpen && (
        <ModalNewSavedFilter
          isOpen
          onCloseRequest={closeNewSavedFilterModal}
          error={savedFiltersData.errors}
          isLoading={savedFiltersData.loading.createFilter}
          createNewSavedFilter={createNewSavedFilter}
          userPolicies={userPolicies}
        />
      )}
      {modalEditSavedFilterIsOpen && (
        <ModalEditSavedFilter
          isOpen
          isLoading={savedFiltersData.loading.editFilter}
          editNewSavedFilter={handleUpdateFilter}
          onCloseRequest={closeEditFilterModal}
          error={savedFiltersData.errors}
          savedFilter={savedFilterClicked}
          authUserId={authUserId}
          userPolicies={userPolicies}
        />
      )}
      {modalDeleteSavedFilterIsOpen && (
        <ModalDeleteSavedFilter
          isOpen
          isLoading={savedFiltersData.loading.deleteFilter}
          onDeleteRequest={deleteSavedFilterFields}
          onCloseRequest={closeDeleteFilterModal}
          error={savedFiltersData.errors}
          savedFilter={savedFilterClicked}
        />
      )}
    </>
  );
}

export default React.memo(FiltersControl);
