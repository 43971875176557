import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { DATE_FORMAT } from '../../../../../constants/date.constants';
import { weekDays } from '../../../../../constants/schedule.constants';
import { OfficesWorkDaysType } from '../../../../../types/libraries/libraries.Reducer.type';
import { DaysOffInfoType } from '../../../../../types/schedule/scheduleReducer.type';
import { getIsHoliday } from '../../utils';

type MonthProps = {
  monthDays: string[];
  monthName: string;
  officesWorkDays: OfficesWorkDaysType;
  daysOffInfo: DaysOffInfoType;
  handleDayClick: (e: React.MouseEvent, day: string) => void;
};

function Month({ monthDays, monthName, officesWorkDays, daysOffInfo, handleDayClick }: MonthProps) {
  const firstMonthDay = useMemo(() => moment(monthDays[0]).weekday(), [monthDays]);
  return (
    <div className="calendar-year__month">
      <div className="month-wrapper">
        <div className="month__title">{monthName}</div>
        <div className="month__content">
          <div className="month__week-days">
            {weekDays.map((el, i) => (
              <span key={i} className="week-day">
                {el}
              </span>
            ))}
          </div>
          <div className="month__days-wrapper">
            {monthDays.map((day, i) => {
              const currentDay = moment(day);

              return (
                <div
                  onClick={e => handleDayClick(e, day)}
                  key={i}
                  style={i === 0 ? { marginLeft: `calc(36px * ${firstMonthDay})` } : {}}
                  className={classNames('day-wrapper', {
                    today: moment().isSame(currentDay, 'day'),
                    holiday: getIsHoliday(currentDay, daysOffInfo, officesWorkDays),
                  })}
                >
                  <span className="month-day">{currentDay.format(DATE_FORMAT.D)}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Month);
