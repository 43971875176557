import { get } from 'lodash-es';
import { getDateParams } from '../../utils/params.utils';
import { SortParams } from '../params.enum';

export class SkillsQuestionsParams extends SortParams {
  size: number;
  page: number;
  competenceIds: string[];
  competenceLevelIds: string[];
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
    this.competenceIds = get(params, 'competenceIds', []);
    this.competenceLevelIds = get(params, 'competenceLevelIds', []);
  }
}

export class AssessmentsParams extends SortParams {
  employees: string[];
  organizers: string[];
  reviewers: string[];
  competencies: string[];
  size: number;
  page: number;
  dateFrom: string;
  dateTo: string;
  constructor(params?: unknown) {
    super('assessmentDate', params);
    this.employees = get(params, 'employees', []);
    this.organizers = get(params, 'organizers', []);
    this.reviewers = get(params, 'reviewers', []);
    this.competencies = get(params, 'competencies', []);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
    this.dateFrom = get(params, 'dateFrom', getDateParams());
    this.dateTo = get(params, 'dateTo', getDateParams({ getFromStart: false }));
  }
}

export class CompetenciesParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class CompetenciesTypesParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}
