export const GET_RESUMES_LIST_REQUEST = 'GET_RESUMES_LIST_REQUEST';
export const GET_RESUMES_LIST_SUCCESS = 'GET_RESUMES_LIST_SUCCESS';
export const GET_RESUMES_LIST_FAILURE = 'GET_RESUMES_LIST_FAILURE';

export const PATCH_USER_RESUME_REQUEST = 'PATCH_USER_RESUME_REQUEST';
export const PATCH_USER_RESUME_SUCCESS = 'PATCH_USER_RESUME_SUCCESS';
export const PATCH_USER_RESUME_FAILURE = 'PATCH_USER_RESUME_FAILURE';

export const GET_RESUME_REQUEST = 'GET_RESUME_REQUEST';
export const GET_RESUME_SUCCESS = 'GET_RESUME_SUCCESS';
export const GET_RESUME_FAILURE = 'GET_RESUME_FAILURE';

export const SET_RESUMES_PARAMS = 'SET_RESUMES_PARAMS';

export const FILE_EXPORT_OPTIONS = { ONE_FILE: 'oneFile', SEPARATE_FILES: 'separateFiels' };

export const EXPORT_RESUME_MODAL_TABS = {
  FINAL_CV: 'finalCV',
  CONTENT_CV: 'contentCV',
  SETTINGS_CV: 'settingsCV',
};

export const RESET_RESUMES_STATE = 'RESET_RESUMES_STATE';
