import React from 'react';
import { EventPreviewInfo } from '../../../enums/schedule.enum';
import { getHolidaysData } from '../../../utils/schedule.utils';
import Modal from '../../Modal';

type ModalHolidayProps = {
  holidaysData: EventPreviewInfo[];
  onRequestClose: () => void;
};

function ModalHoliday({ holidaysData, onRequestClose }: ModalHolidayProps) {
  return (
    <Modal
      isOpen
      title="Holiday"
      onRequestClose={onRequestClose}
      classNameModal="modal-holiday"
      size="small"
      shouldCloseOnOverlayClick
    >
      <div className="holidays-wrapper">
        {holidaysData &&
          getHolidaysData(holidaysData).map((el: any) => (
            <div key={el.locationId} className="holidays-container">
              <div className="holidays-title">{el.locationName}</div>
              <ul className="holidays-list">
                {el.holidays.map((holidayEvent: EventPreviewInfo) => {
                  const title = holidayEvent.title || holidayEvent.eventTypeName;
                  return (
                    <li className="holidays-item" key={`${holidayEvent.id}-${el.id}`}>
                      {`${holidayEvent.holidaysDates} ${title ? `- ${title}` : ''}`}
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
      </div>
    </Modal>
  );
}

export default ModalHoliday;
