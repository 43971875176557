import { get } from 'lodash-es';
import { getDateParams } from '../../utils/params.utils';
import { SortParams } from '../params.enum';

export class RecognitionsParams extends SortParams {
  authorIds: string[];
  userIds: string[];
  recognitionTypeIds: string[];
  dateFrom: string;
  dateTo: string;
  size: number;
  page: number;
  constructor(params?: unknown) {
    super('createdDate', params);
    this.authorIds = get(params, 'authorIds', []);
    this.userIds = get(params, 'userIds', []);
    this.recognitionTypeIds = get(params, 'recognitionTypeIds', []);
    this.dateFrom = get(params, 'dateFrom', getDateParams());
    this.dateTo = get(params, 'dateTo', getDateParams({ getFromStart: false }));
    this.page = get(params, 'page', 0);
    this.size = get(params, 'size', 10);
  }
}

export class RecognitionTypesParams extends SortParams {
  constructor(params?: unknown) {
    super('name', params);
  }
}
