import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Chart } from 'react-google-charts';
import { activityChartColumns, workChartOptions } from '../../../constants/activity.constants';
import ENV from '../../../constants/env.constants';
import { useWorkChartNodes } from '../../../utils/hooks.utils';

type WorkChartType = {
  userWorkTime: any;
  height?: string;
  width?: string;
  isActivityPage?: boolean;
  customNode?: boolean;
};

function WorkChart({
  userWorkTime,
  isActivityPage,
  customNode = true,
  height = '400px',
  width = '100%',
}: WorkChartType) {
  const { myNodes, chartCallback } = useWorkChartNodes(isActivityPage);

  const data = useMemo(() => [activityChartColumns, ...userWorkTime], [userWorkTime]);

  const myChart = useMemo(() => {
    return (
      <div>
        <Chart
          chartType="Timeline"
          className="activity-chart"
          data={data}
          width={width}
          height={height}
          chartEvents={[
            { eventName: 'ready', callback: chartCallback },
            {
              eventName: 'select',
              callback: ({ chartWrapper }: any) => {
                const issue = userWorkTime[chartWrapper.getChart().getSelection()[0].row][6];
                if (issue) {
                  window.open(`${ENV.JIRA_BASE_URL}/browse/${issue}`);
                }
              },
            },
          ]}
          chartPackages={['corechart', 'timeline', 'controls']}
          options={workChartOptions(isActivityPage)}
        />
      </div>
    );
  }, [userWorkTime]);
  const wrapperClass = classNames('work-chart', {
    'custom-node': customNode,
  });

  return (
    <div className={wrapperClass}>
      {myChart}
      {customNode && myNodes}
    </div>
  );
}

export default React.memo(WorkChart);
