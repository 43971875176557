import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import {
  Client,
  FinancePlan,
  PLAN_SCHEMA,
  FinanceProject,
  CurrencyFormatter,
} from '../../../enums/finance/finance.enum';
import Select from '../../Select';
import { getCurrentRateDate } from '../../../utils/finance.utils';
import CurrencyInput from '../../CurrencyInput';
import { CurrencyType, DatesOfExistingPlansParams } from '../../../types/finance';
import { getBaseCurrency, getFormattedDate, scrollToError } from '../../../utils';
import MonthSelect from '../../MonthSelect';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';

type ModalNewPlanProps = {
  onCloseRequest: () => void;
  createPlan: (data: { data: FinancePlan; callback: (financePlanId?: string) => void }) => void;
  getClientsList?: () => void;
  getProjectsList?: () => void;
  getCurrenciesList: () => void;
  cb?: (financePlanId?: string) => void;
  getDatesOfExistingPlans: (data: DatesOfExistingPlansParams) => void;
  resetDatesOfExistingPlans: () => void;
  loadingDates: boolean;
  datesOfExistingPlans: string[];
  defaultPLan?: FinancePlan;
  defaultMonth?: string;
  clients?: Client[];
  projects?: FinanceProject[];
  currencies: CurrencyType[];
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
};

function ModalNewPlan({
  onCloseRequest,
  createPlan,
  cb,
  datesOfExistingPlans,
  loadingDates,
  error,
  isLoading,
  isOpen,
  clients,
  projects,
  currencies,
  defaultPLan,
  defaultMonth,
  getClientsList,
  getProjectsList,
  getCurrenciesList,
  getDatesOfExistingPlans,
  resetDatesOfExistingPlans,
}: ModalNewPlanProps) {
  const intl = useIntl();

  const { values, errors, touched, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: new FinancePlan({
      clientId: defaultPLan?.clientId,
      client: defaultPLan?.client,
      financeProjectId: defaultPLan?.financeProjectId,
      financeProject: defaultPLan?.financeProject,
      month: defaultMonth,
    }),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: PLAN_SCHEMA,
    onSubmit: data => {
      return createPlan({
        data,
        callback: (financePlanId?: string) => {
          onCloseRequest();
          resetDatesOfExistingPlans();
          cb && cb(financePlanId);
        },
      });
    },
  });

  useEffect(() => {
    getClientsList && getClientsList();
    getProjectsList && getProjectsList();
    getCurrenciesList();
  }, []);

  useEffect(() => {
    const year = values.year;
    if (values.financeProjectId && values.clientId && year) {
      const startDate = moment(year).startOf('year').format(DATE_FORMAT.YYYY_MM_DD);
      const endDate = moment(year).endOf('year').format(DATE_FORMAT.YYYY_MM_DD);
      getDatesOfExistingPlans({
        projectId: values.financeProjectId,
        clientId: values.clientId,
        startDate,
        endDate,
      });
    } else {
      resetDatesOfExistingPlans();
    }
  }, [values.financeProjectId, values.year]);

  useEffect(() => {
    if (
      values.financeProjectId &&
      datesOfExistingPlans.some(date => moment(date).get('month') === Number(values.month))
    ) {
      setFieldValue('month', null);
    }
  }, [datesOfExistingPlans]);

  const clientsOptions = useMemo(
    () => clients?.filter(item => item.isActive).map(item => ({ label: item.name, value: item })),
    [clients],
  );

  const clientValue = useMemo(() => values.client && { label: values.client.name, value: values.client }, [
    clientsOptions,
    values,
  ]);

  const handleYearChange = useCallback(({ value }) => {
    setFieldValue('year', value);
  }, []);

  const handleMonthChange = useCallback(({ value }) => {
    setFieldValue('month', value);
  }, []);

  const projectsOptions = useMemo(
    () =>
      projects
        ?.filter(item => values.clientId && item.clientId === values.clientId && item.isActive)
        .map(item => ({ label: item.name, value: item })),
    [projects, values.clientId],
  );

  const projectValue = useMemo(
    () => values.financeProject && { label: values.financeProject.name, value: values.financeProject },
    [projectsOptions, values],
  );

  const currenciesOptions = useMemo(
    () =>
      currencies.map((currency: CurrencyType) => ({
        label: currency.name,
        value: currency,
      })),
    [currencies],
  );

  const currencyValue = useMemo(
    () =>
      values.currency &&
      currenciesOptions?.find(({ value }: { value: { id: string } }) => value.id === values.currencyId),
    [currenciesOptions, values],
  );

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  useEffect(() => {
    const date = getFormattedDate(values.year, values.month);
    if (date && values.financeProject) {
      const curentProjectRate = getCurrentRateDate(values.financeProject?.rates, date);

      setFieldValue('currencyId', curentProjectRate ? curentProjectRate.currencyId : baseCurrency?.id);
      setFieldValue('currency', curentProjectRate ? curentProjectRate.currency : baseCurrency);

      const workHourRate = new CurrencyFormatter({
        float: Number(curentProjectRate?.rate) || 0,
        value: curentProjectRate?.rate.toString() || '0.00',
        formatted: curentProjectRate?.rate.toString() || '0.00',
      });

      setFieldValue('currencyWorkHourRate', workHourRate);

      const baseRate = (Number(workHourRate.float) * Number(values.currencyRate.float)).toFixed(2);
      setFieldValue(
        'baseRate',
        new CurrencyFormatter({
          float: Number(baseRate),
          value: baseRate,
          formatted: baseRate,
        }),
      );
    }
  }, [values.financeProject, values.month, values.year]);

  useEffect(() => {
    if (!values.currencyId && baseCurrency) {
      setFieldValue('currencyId', baseCurrency.id);
      setFieldValue('currency', baseCurrency);
    }
  }, [baseCurrency, values]);

  const handleChangeClient = useCallback(item => {
    setFieldValue(`client`, item.value);
    setFieldValue(`clientId`, item.value?.id);
    setFieldValue(`financeProject`, null);
    setFieldValue(`financeProjectId`, null);
  }, []);

  const handleChangeProject = useCallback(item => {
    setFieldValue(`financeProject`, item.value);
    setFieldValue(`financeProjectId`, item.value?.id);
  }, []);

  const handleCurrenciesChange = useCallback(item => {
    setFieldValue('currencyId', item.value.id);
    setFieldValue('currency', item.value);
  }, []);

  const handleCurrencyWorkHourRateChange = useCallback(
    item => {
      const workHourRate = new CurrencyFormatter({
        ...item,
        float: item.float || 0,
      });
      setFieldValue('currencyWorkHourRate', workHourRate);
      const baseRate = (Number(item.float) * Number(values.currencyRate.float)).toFixed(2);
      setFieldValue(
        'baseRate',
        new CurrencyFormatter({
          float: Number(baseRate),
          value: baseRate,
          formatted: baseRate,
        }),
      );
    },
    [values.currencyRate],
  );

  const handleCurrencyRateChange = useCallback(
    item => {
      const currencyRate = new CurrencyFormatter({
        ...item,
        float: item.float || 0,
      });
      setFieldValue('currencyRate', currencyRate);
      const baseRate = (Number(values.currencyWorkHourRate.float) * Number(item.float)).toFixed(2);
      setFieldValue(
        'baseRate',
        new CurrencyFormatter({
          float: Number(baseRate),
          value: baseRate,
          formatted: baseRate,
        }),
      );
    },
    [values.currencyWorkHourRate],
  );

  const handleBaseRateChange = useCallback(item => {
    const baseRate = new CurrencyFormatter({
      ...item,
      float: item.float || 0,
    });
    setFieldValue('baseRate', baseRate);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.newPlanTitle)}
      classNameModal="new-plan-modal"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.clientLabel)}
              options={clientsOptions}
              value={clientValue}
              handleChange={handleChangeClient}
              hasError={hasError(`clientId`)}
              errorMessage={errors.clientId}
              isClearable
              isDisabled={!!defaultPLan}
            />
            <Select
              label={intl.formatMessage(messages.projectLabel)}
              options={projectsOptions}
              //@ts-ignore
              value={projectValue}
              handleChange={handleChangeProject}
              hasError={hasError(`financeProjectId`)}
              errorMessage={errors.financeProjectId}
              isClearable
              isDisabled={!!defaultPLan}
            />
            <MonthSelect
              selectExternalClass="form__input-block--third"
              year={values.year}
              month={values.month}
              disabledMonths={datesOfExistingPlans}
              isLoadingMonths={loadingDates}
              errorMessageMonth={errors.month}
              errorMessageYear={errors.year}
              handleYearChange={handleYearChange}
              handleMonthChange={handleMonthChange}
            />
            <div className="form__inputs-subwrapper">
              <Select
                options={currenciesOptions}
                externalClass="select__no-label currency-select"
                value={currencyValue}
                handleChange={handleCurrenciesChange}
                errorMessage={errors?.currencyId}
                hasError={hasError('currencyId')}
              />
              <CurrencyInput
                name="currencyWorkHourRate"
                label={intl.formatMessage(messages.originalHourPriceLabel)}
                value={values.currencyWorkHourRate.value}
                onChange={handleCurrencyWorkHourRateChange}
                //@ts-ignore
                errorMessage={errors?.currencyWorkHourRate?.float || errors?.currencyWorkHourRate}
                hasError={hasError('currencyWorkHourRate')}
                wrapperClass={'rate-input'}
              />
              <CurrencyInput
                name="currencyRate"
                label={intl.formatMessage(messages.rateLabel)}
                value={values.currencyRate.value}
                decimalsLimit={4}
                onChange={handleCurrencyRateChange}
                //@ts-ignore
                errorMessage={errors?.currencyRate?.float || errors?.currencyRate}
                hasError={hasError('currencyRate')}
                wrapperClass={'currency-rate-input'}
              />
              <CurrencyInput
                name="baseRate"
                label={intl.formatMessage(messages.unifiedHourPriceLabel)}
                suffix={baseCurrency?.id ? ` ${baseCurrency.name}` : undefined}
                value={values.baseRate.value}
                onChange={handleBaseRateChange}
                //@ts-ignore
                errorMessage={errors?.baseRate?.float || errors?.baseRate}
                hasError={hasError('baseRate')}
                wrapperClass={'rate-input'}
              />
            </div>
          </div>
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            type={'button'}
            onClick={onCloseRequest}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            externalClass={'button--modal'}
            type={'submit'}
            loading={isLoading}
            disabled={isLoading || loadingDates}
          >
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewPlan;
