import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import parse, { attributesToProps } from 'html-react-parser';

type RecognitionIcon = {
  icon?: any;
  color: string;
  externalClass?: string;
};

function RecognitionIcon({ icon, color, externalClass }: RecognitionIcon) {
  const [svg, setSvg] = useState<string>('');
  const defaultClass = classNames('icons recognition-icons', {
    [externalClass as string]: Boolean(externalClass),
    'without-icon': !Boolean(svg),
  });

  const svgIcon = useMemo(() => (icon ? svg : ''), [icon, svg]);
  useEffect(() => {
    if (icon?.hasOwnProperty('url')) {
      fetch(icon.url)
        .then(res => res.text())
        .then(text => setSvg(text));
    } else {
      setSvg('');
    }
  }, [icon]);
  return (
    <span className={defaultClass} style={Boolean(svg) ? { color } : { backgroundColor: color }}>
      {svgIcon
        ? parse(svgIcon, {
            replace: (domNode: any) => {
              if (domNode.attribs && domNode.attribs?.fill && domNode.name === 'g') {
                const props = attributesToProps(domNode.attribs);
                // eslint-disable-next-line react/prop-types
                delete props?.fill;
                domNode.attribs = props;
                return { domNode };
              }

              if (
                domNode.attribs &&
                domNode.attribs?.fill &&
                domNode.name === 'path' &&
                domNode.attribs?.fill !== 'none'
              ) {
                const props = attributesToProps(domNode.attribs);
                props.fill = color;
                domNode.attribs = props;
                return { domNode };
              }
            },
          })
        : null}
    </span>
  );
}

export default RecognitionIcon;
