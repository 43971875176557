import { defineMessages } from 'react-intl';
import { newButton, editButton, deleteButton, nameColumn, positionsLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  newButton,
  editButton,
  deleteButton,
  positionsLabel,
  pageTitle: {
    id: 'libraries.grades.title.grades',
    defaultMessage: 'Grades',
  },
});
