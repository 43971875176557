import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/Candidates/candidates.constants';
import * as api from '../api/candidates.api';
import * as paramsTypes from '../enums/params/candidates.params';
import { AnyAction } from 'redux';
import { getQueryParams } from '../utils/params.utils';
import { Candidate, CandidateComment } from '../enums/candidates.enums';
import { getFormattedCandidate } from '../utils/candidate.utils';
import {
  GET_CANDIDATE_SPECIALIZATIONS_FILTER_REQUEST,
  GET_CANDIDATE_TECHNOLOGIES_FILTER_REQUEST,
} from '../constants/filters.constants';

function* getCandidateStatusesList() {
  const params: paramsTypes.CandidateStatusParams = yield select(
    (state: RootState) => state.candidates.candidateStatusesParams,
  );

  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getCandidateStatusesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_STATUSES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_STATUSES_LIST_FAILURE, payload: error });
  }
}

function* createCandidateStatus({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.createCandidateStatus, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.POST_CANDIDATE_STATUS_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateStatusesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_CANDIDATE_STATUS_FAILURE, payload: error });
  }
}

function* editCandidateStatus({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.editCandidateStatus, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_CANDIDATE_STATUS_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateStatusesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CANDIDATE_STATUS_FAILURE, payload: error });
  }
}

function* deleteCandidateStatus({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.deleteCandidateStatus, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_CANDIDATE_STATUS_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateStatusesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CANDIDATE_STATUS_FAILURE, payload: error });
  }
}

function* getCandidateSpecializationsList() {
  const params: paramsTypes.CandidateSpecializationParams = yield select(
    (state: RootState) => state.candidates.candidateSpecializationsParams,
  );

  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getCandidateSpecializationsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_LIST_FAILURE, payload: error });
  }
}

function* createCandidateSpecialization({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.createCandidateSpecialization, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.POST_CANDIDATE_SPECIALIZATION_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateSpecializationsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_CANDIDATE_SPECIALIZATION_FAILURE, payload: error });
  }
}

function* editCandidateSpecialization({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.editCandidateSpecialization, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_CANDIDATE_SPECIALIZATION_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateSpecializationsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CANDIDATE_SPECIALIZATION_FAILURE, payload: error });
  }
}

function* deleteCandidateSpecialization({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.deleteCandidateSpecialization, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_CANDIDATE_SPECIALIZATION_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateSpecializationsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CANDIDATE_SPECIALIZATION_FAILURE, payload: error });
  }
}

function* getCandidateTechnologiesList() {
  const params: paramsTypes.CandidateTechnologyParams = yield select(
    (state: RootState) => state.candidates.candidateTechnologiesParams,
  );

  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getCandidateTechnologiesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_TECHNOLOGIES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_TECHNOLOGIES_LIST_FAILURE, payload: error });
  }
}

function* createCandidateTechnology({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.createCandidateTechnology, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.POST_CANDIDATE_TECHNOLOGY_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateTechnologiesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_CANDIDATE_TECHNOLOGY_FAILURE, payload: error });
  }
}

function* editCandidateTechnology({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.editCandidateTechnology, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_CANDIDATE_TECHNOLOGY_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateTechnologiesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CANDIDATE_TECHNOLOGY_FAILURE, payload: error });
  }
}

function* deleteCandidateTechnology({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.deleteCandidateTechnology, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_CANDIDATE_TECHNOLOGY_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateTechnologiesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CANDIDATE_TECHNOLOGY_FAILURE, payload: error });
  }
}

function* getCandidateReceivingSourcesList() {
  const params: paramsTypes.CandidateReceivingSourceParams = yield select(
    (state: RootState) => state.candidates.candidateReceivingSourcesParams,
  );

  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getCandidateReceivingSourcesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_LIST_FAILURE, payload: error });
  }
}

function* createCandidateReceivingSource({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.createCandidateReceivingSource, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.POST_CANDIDATE_RECEIVING_SOURCE_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateReceivingSourcesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_CANDIDATE_RECEIVING_SOURCE_FAILURE, payload: error });
  }
}

function* editCandidateReceivingSource({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.editCandidateReceivingSource, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_CANDIDATE_RECEIVING_SOURCE_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateReceivingSourcesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CANDIDATE_RECEIVING_SOURCE_FAILURE, payload: error });
  }
}

function* deleteCandidateReceivingSource({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.deleteCandidateReceivingSource, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_CANDIDATE_RECEIVING_SOURCE_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateReceivingSourcesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CANDIDATE_RECEIVING_SOURCE_FAILURE, payload: error });
  }
}

function* getCandidateList() {
  const params: paramsTypes.CandidateParams = yield select((state: RootState) => state.candidates.candidateParams);

  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getCandidateList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_LIST_FAILURE, payload: error });
  }
}

function* loadNewSpecAndTech(candidate: Candidate) {
  const isAddNewSpec = candidate.specializations.some((spec: { id: string | null; name: string }) => spec.id === null);
  const isAddNewTech = candidate.technologies.some((spec: { id: string | null; name: string }) => spec.id === null);
  if (isAddNewSpec) {
    yield put({ type: GET_CANDIDATE_SPECIALIZATIONS_FILTER_REQUEST });
  }
  if (isAddNewTech) {
    yield put({ type: GET_CANDIDATE_TECHNOLOGIES_FILTER_REQUEST });
  }
}
function* handleCreateCandidate(payload: any, results: any, candidate: any) {
  yield put({
    type: ActionTypes.POST_CANDIDATE_SUCCESS,
    payload: results,
  });
  payload?.callback && payload.callback();
  yield call(getCandidateList);
  yield call(loadNewSpecAndTech, candidate);
}

function* createCandidate({ payload }: AnyAction) {
  try {
    const candidate: Candidate = payload.data;
    const { success, results } = yield call(api.createCandidate, getFormattedCandidate(candidate));

    if (success) {
      const comment = candidate.message;
      if (comment) {
        const { success: isSuccessCreateComment } = yield call(
          api.createCandidateComment,
          results.id,
          new CandidateComment(0, { message: candidate.message }),
        );
        if (isSuccessCreateComment) {
          yield call(handleCreateCandidate, payload, results, candidate);
        }
      } else {
        yield call(handleCreateCandidate, payload, results, candidate);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_CANDIDATE_FAILURE, payload: error });
  }
}

function* editCandidateInfo({ payload }: AnyAction) {
  try {
    const candidate: Candidate = payload.data;
    const { success, results } = yield call(api.editCandidate, getFormattedCandidate(candidate));
    if (success) {
      yield put({
        type: ActionTypes.PUT_CANDIDATE_INFO_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(loadNewSpecAndTech, candidate);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CANDIDATE_INFO_FAILURE, payload: error });
  }
}

function* editCandidate({ payload }: AnyAction) {
  try {
    const candidate: Candidate = payload.data;
    const { success, results } = yield call(api.editCandidate, getFormattedCandidate(candidate));
    if (success) {
      yield put({
        type: ActionTypes.PUT_CANDIDATE_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield put({ type: ActionTypes.GET_CANDIDATE_LIST_REQUEST });
      const isAddNewSpec = candidate.specializations.some(
        (spec: { id: string | null; name: string }) => spec.id === null,
      );
      const isAddNewTech = candidate.technologies.some((spec: { id: string | null; name: string }) => spec.id === null);
      if (isAddNewSpec) {
        yield put({ type: GET_CANDIDATE_SPECIALIZATIONS_FILTER_REQUEST });
      }
      if (isAddNewTech) {
        yield put({ type: GET_CANDIDATE_TECHNOLOGIES_FILTER_REQUEST });
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CANDIDATE_FAILURE, payload: error });
  }
}

function* deleteCandidate({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.deleteCandidate, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_CANDIDATE_SUCCESS,
        payload: results,
      });
      payload?.callback && payload.callback();
      yield call(getCandidateList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CANDIDATE_FAILURE, payload: error });
  }
}

function* getCandidateInfo({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getCandidateInfo, payload);
    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_INFO_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_INFO_FAILURE, payload: error });
  }
}

function* uploadCandidatePhoto({ payload }: AnyAction) {
  const { uuid, data, callback } = payload;
  try {
    const { success, results } = yield call(api.uploadCandidatePhoto, uuid, data);
    if (success) {
      yield put({
        type: ActionTypes.POST_CANDIDATE_PHOTO_SUCCESS,
        payload: { uuid, results },
      });
      callback && callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_CANDIDATE_PHOTO_FAILURE, payload: error });
  }
}

function* deleteCandidatePhoto({ payload }: AnyAction) {
  const { uuid, callback } = payload;
  try {
    const { success, results } = yield call(api.deleteCandidatePhoto, uuid);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_CANDIDATE_PHOTO_SUCCESS,
        payload: { uuid, results },
      });
      callback && callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CANDIDATE_PHOTO_FAILURE, payload: error });
  }
}

function* getCandidateComments({ payload }: AnyAction) {
  const { candidateId, params, afterDelete = false } = payload;
  const commentsParams = { ...params, isDeleted: false, sort: 'createdDate,desc' };
  try {
    const { success, results } = yield call(api.getCandidateComments, candidateId, commentsParams);
    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_COMMENTS_SUCCESS,
        payload: { results, afterDelete },
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_COMMENTS_FAILURE, payload: error });
  }
}

function* createCandidateComment({ payload }: AnyAction) {
  const { candidateId, replyToCommentId, data, cb } = payload;
  try {
    const { success, results } = yield call(api.createCandidateComment, candidateId, data, replyToCommentId);
    if (success) {
      yield put({
        type: ActionTypes.POST_CANDIDATE_COMMENT_SUCCESS,
        payload: results,
      });
      cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_CANDIDATE_COMMENT_FAILURE, payload: error });
  }
}

function* editCandidateComment({ payload }: AnyAction) {
  const { uuid, data, cb } = payload;
  try {
    const { success, results } = yield call(api.editCandidateComment, uuid, data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_CANDIDATE_COMMENT_SUCCESS,
        payload: results,
      });
      cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CANDIDATE_COMMENT_FAILURE, payload: error });
  }
}

function* deleteCandidateComment({ payload }: AnyAction) {
  const { uuid, cb, candidateId, size, commentPage } = payload;
  try {
    const { success } = yield call(api.deleteCandidateComment, uuid);
    if (success) {
      yield put({ type: ActionTypes.DELETE_CANDIDATE_COMMENT_SUCCESS, payload: uuid });
      yield put({
        type: ActionTypes.GET_CANDIDATE_COMMENTS_REQUEST,
        payload: {
          candidateId,
          afterDelete: true,
          params: {
            page: commentPage,
            size,
          },
        },
      });
      cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CANDIDATE_COMMENT_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeEvery(
      [ActionTypes.GET_CANDIDATE_STATUSES_LIST_REQUEST, ActionTypes.SET_CANDIDATE_STATUSES_PARAMS],
      getCandidateStatusesList,
    ),
    takeEvery(ActionTypes.POST_CANDIDATE_STATUS_REQUEST, createCandidateStatus),
    takeEvery(ActionTypes.PUT_CANDIDATE_STATUS_REQUEST, editCandidateStatus),
    takeEvery(ActionTypes.DELETE_CANDIDATE_STATUS_REQUEST, deleteCandidateStatus),
    takeEvery(
      [ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_LIST_REQUEST, ActionTypes.SET_CANDIDATE_SPECIALIZATION_PARAMS],
      getCandidateSpecializationsList,
    ),
    takeEvery(ActionTypes.POST_CANDIDATE_SPECIALIZATION_REQUEST, createCandidateSpecialization),
    takeEvery(ActionTypes.PUT_CANDIDATE_SPECIALIZATION_REQUEST, editCandidateSpecialization),
    takeEvery(ActionTypes.DELETE_CANDIDATE_SPECIALIZATION_REQUEST, deleteCandidateSpecialization),
    takeEvery(
      [ActionTypes.GET_CANDIDATE_TECHNOLOGIES_LIST_REQUEST, ActionTypes.SET_CANDIDATE_TECHNOLOGY_PARAMS],
      getCandidateTechnologiesList,
    ),
    takeEvery(ActionTypes.POST_CANDIDATE_TECHNOLOGY_REQUEST, createCandidateTechnology),
    takeEvery(ActionTypes.PUT_CANDIDATE_TECHNOLOGY_REQUEST, editCandidateTechnology),
    takeEvery(ActionTypes.DELETE_CANDIDATE_TECHNOLOGY_REQUEST, deleteCandidateTechnology),
    takeEvery(
      [ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_LIST_REQUEST, ActionTypes.SET_CANDIDATE_RECEIVING_SOURCE_PARAMS],
      getCandidateReceivingSourcesList,
    ),
    takeEvery(ActionTypes.POST_CANDIDATE_RECEIVING_SOURCE_REQUEST, createCandidateReceivingSource),
    takeEvery(ActionTypes.PUT_CANDIDATE_RECEIVING_SOURCE_REQUEST, editCandidateReceivingSource),
    takeEvery(ActionTypes.DELETE_CANDIDATE_RECEIVING_SOURCE_REQUEST, deleteCandidateReceivingSource),
    takeEvery([ActionTypes.GET_CANDIDATE_LIST_REQUEST, ActionTypes.SET_CANDIDATE_PARAMS], getCandidateList),
    takeEvery(ActionTypes.POST_CANDIDATE_REQUEST, createCandidate),
    takeEvery(ActionTypes.PUT_CANDIDATE_REQUEST, editCandidate),
    takeEvery(ActionTypes.PUT_CANDIDATE_INFO_REQUEST, editCandidateInfo),
    takeEvery(ActionTypes.DELETE_CANDIDATE_REQUEST, deleteCandidate),
    takeEvery(ActionTypes.GET_CANDIDATE_INFO_REQUEST, getCandidateInfo),
    takeEvery(ActionTypes.POST_CANDIDATE_PHOTO_REQUEST, uploadCandidatePhoto),
    takeEvery(ActionTypes.DELETE_CANDIDATE_PHOTO_REQUEST, deleteCandidatePhoto),
    takeEvery(ActionTypes.GET_CANDIDATE_COMMENTS_REQUEST, getCandidateComments),
    takeEvery(ActionTypes.POST_CANDIDATE_COMMENT_REQUEST, createCandidateComment),
    takeEvery(ActionTypes.PUT_CANDIDATE_COMMENT_REQUEST, editCandidateComment),
    takeEvery(ActionTypes.DELETE_CANDIDATE_COMMENT_REQUEST, deleteCandidateComment),
  ]);
}
