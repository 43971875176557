import { defineMessages } from 'react-intl';
import { editButton, competenciesTitle } from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  competenciesTitle,
  nameTitle: {
    id: 'competencies.specializations.column.specialization.competencies',
    defaultMessage: 'Specialization / Competencies',
  },
  pageTitle: {
    id: 'competencies.specializations.title.specializations',
    defaultMessage: 'Specializations',
  },
});
