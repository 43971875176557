import { EventTypesParams } from '../enums/params/calendar.params';
import { QueryParamsType } from '../utils/params.utils';
import request from '../utils/request.utils';

export function getEventTypesList(params?: QueryParamsType<EventTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/eventTypes',
    params,
  };
  return request(options);
}

export function getEventTypeById(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/eventTypes/${id}`,
  };
  return request(options);
}

export function createNewEventType(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/eventTypes',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteEventType(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/eventTypes/${id}`,
  };
  return request(options);
}

export function editEventType(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/eventTypes/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}
