import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { EventTypeInfo } from '../../../enums/calendar.enum';
import Select from '../../Select';
import Checkbox from '../../Checkbox';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { ENotificationGroup, EVENT_VALIDATION_SCHEMA, EventInfo } from '../../../enums/schedule.enum';
import get from 'lodash-es/get';
import { differenceBy, isEmpty, isNil } from 'lodash-es';
import {
  NOTIFICATION_OPTIONS,
  remindersData,
  repeatDays,
  repeatDaysOfMonth,
  repeaterData,
  timeOptions24h,
  timeOptions,
  RepeatType,
} from '../../../constants/schedule.constants';
import moment from 'moment';
import { OptionTypeBase } from 'react-select';
import EventRepeater from '../EventRepeater/EventRepeater';
import EventReminder from '../EventReminder/EventReminder';
import ModalForRepeatableEvents from './ModalForRepeatableEvents';
import CustomLoader from '../../Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { AssessmentsInfo } from '../../../enums/competencies.enum';
import ErrorMessage from '../../ErrorMessage';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { NewPoll } from '../../../enums/questionnaires.enum';
import { PollParams } from '../../../enums/params/questionnaires.params';
import { UserInfo } from '../../../enums/users.enum';
import r from '../../../constants/routes.constants';
import { AssessmentsParams } from '../../../enums/params/competencies.params';
import Switch from '../../Switch';
import { detectChanges, isBrowserLocale24h, scrollToError } from '../../../utils';
import { checkPolicies } from '../../../utils/policies.utils';
import { UPDATE_EVENT_EXTENDED } from '../../../constants/policies.constants';
import { UserPolicy } from '../../../enums/policies.enum';

type ModalEditEventProps = {
  isOpenUpdateNextModal: boolean;
  isOpen: boolean;
  eventError: string | null | RejectValueErrors[];
  eventData: EventInfo;
  eventType: EventTypeInfo | null;
  isLoading: {
    getEventType: boolean;
    getEventList: boolean;
    getEvent: boolean;
    createEvent: boolean;
    editEvent: boolean;
    deleteEvent: boolean;
  };
  offices: OfficeInfo[];
  users: UserInfo[];
  eventTypesData: EventTypeInfo[];
  assessmentsData: AssessmentsInfo[];
  assessmentsLoading: boolean;
  isGetEventAfterEdit?: boolean;
  polls: NewPoll[];
  userPolicies: UserPolicy[];
  authUser: UserInfo;
  openFromUserProfile?: boolean;
  allEventTypes: EventTypeInfo[];
  getPollsFilter: (params: Partial<PollParams>) => void;
  openUpdateNextModal: () => void;
  closeUpdateNextModal: () => void;
  onCloseRequest: () => void;
  getEventType: (id: string) => void;
  onEditRequest: (data: any, updateNext?: boolean) => void;
  handleReplaceNextEvent?: (id: string) => void;
  getAssessmentsFilter: (data: Partial<AssessmentsParams>) => void;
  resetErrors: () => void;
  cb?: () => void;
};

function ModalEditEvent({
  eventData,
  eventType,
  isOpen,
  isLoading,
  eventError,
  offices,
  eventTypesData,
  users,
  isOpenUpdateNextModal,
  assessmentsData,
  assessmentsLoading,
  isGetEventAfterEdit = false,
  polls,
  userPolicies,
  authUser,
  openFromUserProfile,
  allEventTypes,
  getPollsFilter,
  openUpdateNextModal,
  closeUpdateNextModal,
  getEventType,
  onEditRequest,
  onCloseRequest,
  handleReplaceNextEvent,
  getAssessmentsFilter,
  resetErrors,
  cb,
}: ModalEditEventProps) {
  const intl = useIntl();
  const startTimeInputRef = useRef<any>(null);
  const endTimeInputRef = useRef<any>(null);
  const selectTimeStartRef = useRef<any>(null);
  const selectTimeEndRef = useRef<any>(null);
  const [isCustomRepeater, setCustomRepeater] = useState(false);
  const [isRepeat, setRepeat] = useState(false);
  const [isSendNotificationShown, setIsSendNotificationShown] = useState<boolean>(false);
  const [isNotificationForShown, setIsNotificationForShown] = useState<boolean>(false);

  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    setFieldError,
    errors,
    touched,
  } = useFormik({
    initialValues: new EventInfo(eventData),
    validationSchema: EVENT_VALIDATION_SCHEMA,
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    onSubmit: data => {
      if (!data.unitOfRepeat) {
        data.repeatInterval = null;
      }
      if (data.sendNotification && isNil(data.notificationParticipantsGroup)) {
        data.notificationParticipantsGroup = ENotificationGroup.ALL;
      }

      if (data.showTime && !data.allDay) {
        const startUtc = moment(`${data.startDate} ${data.startTime}`).utc().format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss);

        data.startTime = startUtc.split(' ')[1];
        data.startDate = startUtc.split(' ')[0];

        const endUtc = moment(`${data.endDate} ${data.endTime}`).utc().format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss);

        data.endTime = endUtc.split(' ')[1];
        data.endDate = endUtc.split(' ')[0];
      }

      if (!eventData?.parentEvent && !isRepeat) {
        onEditRequest({
          id: eventData.id,
          data,
          isGetEvent: isGetEventAfterEdit,
          callback: () => {
            onCloseRequest();
            cb && cb();
          },
        });
      }

      if (isRepeat) {
        openUpdateNextModal();
      } else if (eventData?.parentEvent && !isRepeat) {
        data.parentEvent = null;
        onEditRequest({
          id: eventData.id,
          data,
          updateNext: true,
          isGetEvent: isGetEventAfterEdit,
          callback: () => {
            onCloseRequest();
            cb && cb();
          },
        });
      }
    },
  });

  const canAuthUserApprove = useMemo(
    () =>
      !!values.eventType?.absencesSettings.requiresApproval.usersAbleToApprove.find(user => user.id === authUser.id),
    [authUser, values.eventType?.absencesSettings.requiresApproval.usersAbleToApprove],
  );

  const isParticipantsChanged = useMemo(
    () =>
      detectChanges({
        prevItems: eventData.participants,
        nextItems: values.participants,
      }),
    [eventData, values.participants],
  );
  const isLocationsChanged = useMemo(
    () =>
      detectChanges({
        prevItems: eventData.locations,
        nextItems: values.locations,
      }),
    [eventData, values.locations],
  );
  const isTargetEmployeeChanged = useMemo(() => eventData.targetEmployee?.id !== values.targetEmployee?.id, [
    eventData,
    values.targetEmployee,
  ]);

  useEffect(() => {
    if (eventData.showTime && !eventData.allDay) {
      setFieldValue('startDate', eventData.localStartDate);
      setFieldValue('startTime', eventData.localStartTime);
      setFieldValue('endDate', eventData.localEndDate);
      setFieldValue('endTime', eventData.localEndTime);

      const endTimeInput = endTimeInputRef.current;
      if (endTimeInput) {
        endTimeInput.value = eventData.localEndTime.slice(0, 5);
      }

      const startTimeInput = startTimeInputRef.current;
      if (startTimeInput) {
        startTimeInput.value = eventData.localStartTime.slice(0, 5);
      }
    }
  }, [eventData]);

  useEffect(() => {
    const isMe = values.targetEmployee?.id === authUser.id;
    const haveParticipants = !isEmpty(values.participants);
    const haveLocations = !isEmpty(values.locations);

    const isShown = haveParticipants || haveLocations || !isMe;

    setIsSendNotificationShown(isShown);
    setFieldValue('sendNotification', isShown);
  }, [values.targetEmployee, values.participants, values.locations]);

  useEffect(() => {
    const shouldSend =
      !isEmpty(isParticipantsChanged.removedItems) ||
      !isEmpty(isParticipantsChanged.addedItems) ||
      !isEmpty(isLocationsChanged.removedItems) ||
      !isEmpty(isLocationsChanged.addedItems) ||
      isTargetEmployeeChanged;

    setIsNotificationForShown(shouldSend && values.sendNotification && isSendNotificationShown);
  }, [
    values.sendNotification,
    isSendNotificationShown,
    isParticipantsChanged,
    isLocationsChanged,
    isTargetEmployeeChanged,
  ]);

  useEffect(() => {
    isSendNotificationShown && setFieldValue('notificationParticipantsGroup', ENotificationGroup.ALL);
  }, [isSendNotificationShown]);

  useEffect(() => {
    values.showQuestionnaires &&
      values.startDate &&
      getPollsFilter({
        deadlineFrom: moment(values.startDate).subtract(3, 'month').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
        deadlineTo: moment(values.startDate).add(3, 'month').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
        direction: 'DESC',
        isGeneral: false,
      });
  }, [values.startDate, values.showQuestionnaires]);

  useSetFieldsErrors(eventError, setFieldError, r.schedule);

  const onSubmitUpdateNextCallback = useCallback(
    nextEvent => () => {
      onEditRequest(
        {
          id: eventData.id,
          data: values,
          updateNext: nextEvent,
          isGetEvent: nextEvent ? false : isGetEventAfterEdit,
          callback: () => {
            onCloseRequest();
            cb && cb();
          },
          pushToNewEvent: handleReplaceNextEvent ? handleReplaceNextEvent : null,
        },
        nextEvent,
      );
      closeUpdateNextModal();
    },
    [eventData, values],
  );

  const REPEAT_DAYS = useMemo(() => repeatDays(values.startDate || moment().format()), [values.startDate]);
  const REPEAT_DAYS_OF_MONTH = useMemo(() => repeatDaysOfMonth(values.startDate || moment().format()), [
    values.startDate,
  ]);

  const assessmentsWithoutEvent = useMemo(
    () =>
      values.targetEmployee
        ? assessmentsData.filter(
            (assessment: AssessmentsInfo) =>
              !assessment?.eventId || eventData.assessmentIdList.some(id => id === assessment.id),
          )
        : [],
    [assessmentsData, values.targetEmployee, values.assessmentIdList],
  );

  useEffect(() => {
    if (eventType && eventType.id === values.eventType.id) {
      setValues(new EventInfo());
      setFieldValue('eventType', eventType);
      setFieldValue('showAbsences', eventType.absencesSettings.absencesEnabled);
      setFieldValue('showAddress', eventType.addressEnabled);
      setFieldValue('showAssessments', eventType.assessmentsEnabled);
      const dateRangesEnabled = eventType.dateRangesEnabled;

      setFieldValue('showEndDate', dateRangesEnabled);
      setFieldValue('showEventParticipants', eventType.eventParticipantsEnabled);
      setFieldValue('showExcludedParticipants', eventType.excludedParticipantsEnabled);
      setFieldValue('showLocations', eventType.officesEnabled);
      setFieldValue('showQuestionnaires', eventType.questionnairesEnabled);
      setFieldValue('showReminder', eventType.reminderEnabled);
      setFieldValue('showTargetEmployee', eventType.targetEmployeesEnabled);
      setFieldValue('showTime', eventType.timeEnabled);
      setFieldValue('showTitle', eventType.titleEnabled);
      setFieldValue('extraWorkingDaysEnabled', eventType.extraWorkingDaysEnabled);
      setFieldValue('finalDecisionOptionsEnabled', eventType.absencesSettings.finalDecisionOptionsEnabled);
      setFieldValue(
        'optionForExcludeDaysOfAbsenceFromOtherTypes',
        eventType.absencesSettings.optionForExcludeDaysOfAbsenceFromOtherTypes,
      );
      setFieldValue('requiresApprovalEnabled', eventType.absencesSettings.requiresApproval.requiresApprovalEnabled);
      setFieldValue('requiresWorkingOff', eventType.absencesSettings.requiresWorkingOff);
      setFieldValue('showRepeater', eventType.repeaterEnabled);
      setFieldValue('showTotalCounterInProfile', eventType.absencesSettings.showTotalCounterInProfile);

      if (!dateRangesEnabled) {
        setFieldValue('endDate', eventData.startDate);
      } else {
        setFieldValue('endDate', eventData.endDate);
      }
      setFieldValue('startDate', eventData.startDate);
      eventType.addressEnabled && setFieldValue('address', eventData.address);
      eventType.targetEmployeesEnabled && setFieldValue('targetEmployee', eventData.targetEmployee);
      if (eventType.assessmentsEnabled && eventType.targetEmployeesEnabled) {
        setFieldValue('assessments', eventData.assessments);
        setFieldValue('assessmentIdList', eventData.assessmentIdList);
      }
      if (eventType.questionnairesEnabled) {
        setFieldValue('polls', eventData.polls);
        setFieldValue('pollIds', eventData.pollIds);
      }
      if (eventType.eventParticipantsEnabled) {
        setFieldValue('participants', eventData.participants);
        setFieldValue('participantsIds', eventData.participantsIds);
      }
      if (eventType.excludedParticipantsEnabled) {
        setFieldValue('excludedParticipants', eventData.excludedParticipants);
        setFieldValue('excludedParticipantsIds', eventData.excludedParticipantsIds);
      }
      if (eventType.officesEnabled) {
        setFieldValue('locations', eventData.locations);
        setFieldValue('locationsIds', eventData.locationsIds);
      }
      if (eventType.timeEnabled) {
        setFieldValue('startTime', eventData.startTime);
        setFieldValue('endTime', eventData.endTime);
      }
      eventType.titleEnabled && setFieldValue('title', eventData.title);
      eventType.absencesSettings.absencesEnabled &&
        eventType.absencesSettings.finalDecisionOptionsEnabled &&
        setFieldValue('absences.finalDecision', eventData.absences.finalDecision || false);
      eventType.absencesSettings.absencesEnabled &&
        eventType.absencesSettings.requiresApproval.requiresApprovalEnabled &&
        setFieldValue('absences.approve', eventData.absences.approve || false);
    }
    if (
      !openFromUserProfile &&
      !checkPolicies([UPDATE_EVENT_EXTENDED], userPolicies) &&
      eventType?.targetEmployeesEnabled
    ) {
      setFieldValue('targetEmployee', eventData.targetEmployee || authUser);
    }
  }, [eventType]);

  useEffect(() => {
    const types = eventData.absences.excludeDaysOfAbsenceFromOtherTypes;
    if (Boolean(types)) {
      setFieldValue('absences.excludeDaysOfAbsenceFromOtherTypes', types);
    }
  }, [eventData.absences.excludeDaysOfAbsenceFromOtherTypes]);

  useEffect(() => {
    values?.targetEmployee?.id &&
      values.startDate &&
      values.showAssessments &&
      getAssessmentsFilter({
        dateFrom: moment(values.startDate).subtract(3, 'month').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
        dateTo: moment(values.startDate).add(3, 'month').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
        employees: [values?.targetEmployee.id],
      });
  }, [values.startDate, values.targetEmployee]);

  useEffect(() => {
    if (
      values?.repeatInterval > 1 ||
      (values?.unitOfRepeat === RepeatType.MONTHS && values?.monthRepeatType === 'SAME_MONTH_DAY') ||
      (values?.unitOfRepeat === RepeatType.WEEKS &&
        values?.repeatDays[0] !== moment(values?.startDate).format(DATE_FORMAT.dddd).toUpperCase()) ||
      values?.repeatDays.length > 1
    ) {
      setCustomRepeater(true);
      setRepeat(true);
    } else {
      setCustomRepeater(false);
    }
    setRepeat(false);
    values?.unitOfRepeat && setRepeat(true);
  }, [eventData, values.eventType]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const timeOptionsForSelect = useMemo(
    () =>
      timeOptions.map((el, index) => ({
        value: timeOptions24h[index],
        label: isBrowserLocale24h() ? el.slice(0, 5) : el,
      })),
    [timeOptions],
  );

  const timeStartValue = useMemo(() => timeOptionsForSelect.find(el => el.value === values.startTime) || null, [
    timeOptionsForSelect,
    values.startTime,
  ]);

  const timeEndValue = useMemo(() => timeOptionsForSelect.find(el => el.value === values.endTime) || null, [
    timeOptionsForSelect,
    values.endTime,
  ]);

  const repeateDaysOptions = useMemo(
    () =>
      REPEAT_DAYS.map(value => ({
        label: value.name,
        value: value,
      })),
    [REPEAT_DAYS, values, isCustomRepeater],
  );

  const repeateDaysValues = useMemo(
    () =>
      repeateDaysOptions.find(({ value }) =>
        isCustomRepeater ? value.repeatType === 'CUSTOM' : value.repeatType === values.unitOfRepeat,
      ),
    [REPEAT_DAYS, values, isCustomRepeater],
  );

  const usersOptions = useMemo(
    () =>
      users.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [users],
  );

  const usersValue = useMemo(
    () => values?.targetEmployee && usersOptions.find(({ value }) => value?.id === values?.targetEmployee?.id),
    [values, usersOptions],
  );

  const eventsTypeOptions = useMemo(
    () =>
      eventTypesData.map((eventType: EventTypeInfo) => ({
        label: eventType.name,
        value: eventType,
      })),
    [eventTypesData],
  );

  const eventTypeValue = useMemo(
    () => values.eventType && eventsTypeOptions.find(({ value }) => value.id === values?.eventType?.id),
    [eventsTypeOptions, values],
  );

  const officesOptions = useMemo(() => {
    const options = differenceBy(offices, values.locations, 'id');
    return options.map((office: OfficeInfo) => ({
      label: office.name,
      value: office,
    }));
  }, [offices, values]);

  const officesValues = useMemo(
    () =>
      values.locations.map((el: OfficeInfo) => ({
        label: el.name,
        value: el,
      })),
    [values.locations],
  );

  const participantsOptions = useMemo(() => {
    const options = differenceBy(users, values.participants, 'id');
    return options.map((user: UserInfo) => ({
      label: user.fullName,
      value: user,
    }));
  }, [users, values.participants]);

  const excludedParticipantsOptions = useMemo(() => {
    const options = differenceBy(users, values.excludedParticipants, 'id');
    return options.map((user: UserInfo) => ({
      label: user.fullName,
      value: user,
    }));
  }, [users, values]);

  const participantsValues = useMemo(
    () =>
      values.participants.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [values.participants],
  );

  const notificationOptions = useMemo(
    () => [
      { label: NOTIFICATION_OPTIONS.ALL, value: Object.keys(NOTIFICATION_OPTIONS)[0] },
      { label: NOTIFICATION_OPTIONS.ONLY_NEW, value: Object.keys(NOTIFICATION_OPTIONS)[1] },
    ],
    [],
  );

  const excludedParticipantsValues = useMemo(
    () =>
      values.excludedParticipants.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [values.excludedParticipants],
  );

  const excludeEventTypeOptions = useMemo(() => {
    const options = differenceBy(allEventTypes, values?.absences?.excludeDaysOfAbsenceFromOtherTypes, 'id');
    return options.map((otherEventType: EventTypeInfo) => {
      return {
        label: otherEventType.name,
        value: otherEventType,
      };
    });
  }, [allEventTypes, values]);

  const repeaterOptions = useMemo(
    () =>
      Object.entries(repeaterData).map(([key, value]) => ({
        label: value.name[0].toUpperCase() + value.name.slice(1),
        value: key,
      })),
    [repeaterData],
  );

  const repeaterValue = useMemo(() => repeaterOptions.find(({ value }) => value === values.unitOfRepeat), [
    repeaterData,
    values,
  ]);

  const repeaterMonthDaysOptions = useMemo(
    () =>
      REPEAT_DAYS_OF_MONTH.map(value => ({
        label: value.name,
        value: value.value,
      })),
    [REPEAT_DAYS_OF_MONTH, values.startDate],
  );

  const repeaterMonthDaysValues = useMemo(
    () => repeaterMonthDaysOptions.find(({ value }) => value === values.monthRepeatType),
    [REPEAT_DAYS, values.monthRepeatType, values.startDate, isCustomRepeater],
  );

  const excludeEventTypeValues = useMemo(
    () =>
      values?.absences?.excludeDaysOfAbsenceFromOtherTypes.map((otherEventType: EventTypeInfo) => ({
        label: otherEventType.name,
        value: otherEventType,
      })),
    [values.absences.excludeDaysOfAbsenceFromOtherTypes],
  );

  const remindersOptions = useMemo(
    () =>
      Object.entries(remindersData).map(([key, value]) => ({
        label: value.name[0].toUpperCase() + value.name.slice(1),
        value: key,
      })),
    [eventTypesData],
  );

  const assessmentsOptions = useMemo(() => {
    return assessmentsWithoutEvent.map((el: AssessmentsInfo) => ({
      label: el.competence.name,
      value: el,
    }));
  }, [assessmentsWithoutEvent, values.assessments]);

  const assessmentsValues = useMemo(
    () => assessmentsOptions.filter(el => values.assessmentIdList.some(id => id === el.value.id)),
    [values.assessments, assessmentsOptions],
  );

  const pollsOptions = useMemo(() => {
    const options = differenceBy(polls, values.polls, 'id');
    return options.map((poll: NewPoll) => ({
      label: poll.name,
      value: poll,
    }));
  }, [polls, values.polls]);

  const pollsValues = useMemo(
    () =>
      values.polls.map(poll => ({
        label: (
          <div className="related-poll__wrapper">
            <span className="related-poll__name">{poll.name}</span>
            <span className="related-poll__date">{moment(poll.deadline.split(' ')[0]).format(DATE_FORMAT.ll)}</span>
          </div>
        ),
        value: poll,
      })),
    [values.polls],
  );

  const handleOfficeChange = useCallback(newValues => {
    const value = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue('locations', value);
    setFieldValue(
      'locationsIds',
      value.map((office: OfficeInfo) => office.id),
    );
  }, []);

  const handleParticipantsChange = useCallback(newValues => {
    const value = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue('participants', value);
    setFieldValue(
      'participantsIds',
      value.map((item: UserInfo) => item.id),
    );
  }, []);

  const handleNotificationChange = useCallback(forWhom => {
    setFieldValue('notificationParticipantsGroup', forWhom.value);
  }, []);

  const handleExcludedParticipantsChange = useCallback(newValues => {
    const value = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue('excludedParticipants', value);
    setFieldValue(
      'excludedParticipantsIds',
      value.map((item: any) => item.id),
    );
  }, []);

  const handleEmployeeChange = useCallback(
    ({ value }) => {
      setFieldValue('targetEmployee', value);
      if (values?.targetEmployee?.id !== value?.id) {
        setFieldValue('assessments', []);
        setFieldValue('assessmentIdList', []);
      }
    },
    [values, eventData],
  );

  const handleAllDayChange = useCallback(newValue => {
    handleChange(newValue);
    setFieldValue('startTime', moment().startOf('day').format(DATE_FORMAT.HH_mm_ss));
    setFieldValue('endTime', `${moment().endOf('day').format(DATE_FORMAT.HH_mm)}:00`);
  }, []);

  const handleActiveTimeScroll = (ref: any) => {
    setTimeout(() => {
      const selectedItem = ref.current?.select?.menuListRef?.querySelector('.select__option--is-selected');
      selectedItem && selectedItem.scrollIntoView({ block: 'center', inline: 'nearest' });
    }, 1);
  };

  const handleStartTimeChange = useCallback(e => {
    setFieldValue('startTime', `${e.target.value}:00`);
    handleActiveTimeScroll(selectTimeStartRef);
  }, []);

  const handleEndTimeChange = useCallback(e => {
    setFieldValue('endTime', `${e.target.value}:00`);
    handleActiveTimeScroll(selectTimeEndRef);
  }, []);

  const handleStartTimeSelectChange = useCallback(({ value }) => {
    const input = startTimeInputRef.current;
    if (input) {
      input.value = value.slice(0, 5);
    }
    setFieldValue('startTime', value);
  }, []);

  const handleEndTimeSelectChange = useCallback(({ value }) => {
    const input = endTimeInputRef.current;
    if (input) {
      input.value = value.slice(0, 5);
    }
    setFieldValue('endTime', value);
  }, []);

  const handleEventTypeChange = useCallback(({ value }) => {
    setFieldValue('eventType', value);
    value && getEventType(value.id);
  }, []);

  const handleExcludeEventType = useCallback(newValues => {
    const value = newValues.map((el: Record<string, any>) => el.value);
    setFieldValue('absences.excludeDaysOfAbsenceFromOtherTypes', value);
  }, []);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const filRepeaterValue = (value?: any) => {
    setFieldValue('endOfRepeatDate', get(value, 'endOfRepeatDate', ''));
    setFieldValue('endOfRepeatQuantity', get(value, 'endOfRepeatQuantity', 1));
    setFieldValue('endOfRepeatType', get(value, 'endOfRepeatType', 'REPEAT_QUANTITY'));
    setFieldValue('monthRepeatType', get(value, 'monthRepeatType', null));
    setFieldValue('repeatDays', get(value, 'repeatDays', []));
    setFieldValue('repeatInterval', get(value, 'repeatInterval', 1));
    setFieldValue('unitOfRepeat', get(value, 'unitOfRepeat', null));
  };

  const handleCountUpReminder = useCallback(
    index => () => {
      if (values.reminders[index].timeValue < remindersData[`${values.reminders[index].unitOfTime}`].maxValue) {
        setFieldValue(`reminders[${index}].timeValue`, values.reminders[index].timeValue + 1);
      }
    },
    [values],
  );
  const handleCountDownReminder = useCallback(
    index => () => {
      if (values.reminders[index].timeValue > 0) {
        setFieldValue(`reminders[${index}].timeValue`, values.reminders[index].timeValue - 1);
      }
    },
    [values],
  );

  const addReminder = useCallback(
    () => setFieldValue('reminders', [...values.reminders, { timeValue: 0, unitOfTime: 'MINUTES' }]),
    [values],
  );

  const removeReminder = useCallback(
    i => () => {
      setFieldValue(
        'reminders',
        values.reminders.filter((reminder, index) => i !== index),
      );
    },
    [values],
  );

  const handleReminderChange = useCallback(
    index => ({ value }: OptionTypeBase) => {
      setFieldValue(`reminders[${index}].unitOfTime`, value);
    },
    [],
  );

  const handlePollsChange = useCallback(newValues => {
    setFieldValue(
      'polls',
      newValues.map((el: Record<string, any>) => el.value),
    );
    setFieldValue(
      'pollIds',
      newValues.map((el: Record<string, any>) => el.value.id),
    );
  }, []);

  const handleRepeatDaysChange = useCallback(
    ({ value }: OptionTypeBase) => {
      switch (value.repeatType) {
        case null: {
          filRepeaterValue();
          setCustomRepeater(false);
          setRepeat(false);
          break;
        }
        case 'DAYS': {
          setCustomRepeater(false);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: value.repeatType,
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        case 'WEEKS': {
          setCustomRepeater(false);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: value.repeatType,
            repeatDays: [value.data.toUpperCase()],
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        case 'MONTHS': {
          setCustomRepeater(false);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: value.repeatType,
            monthRepeatType: value.data,
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        case 'YEARS': {
          setCustomRepeater(false);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: value.repeatType,
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        case 'CUSTOM': {
          setCustomRepeater(true);
          setRepeat(true);
          filRepeaterValue({
            unitOfRepeat: 'DAYS',
            endOfRepeatType: values.endOfRepeatType,
            endOfRepeatQuantity: values.endOfRepeatQuantity,
            endOfRepeatDate: values.endOfRepeatDate,
          });
          break;
        }
        default: {
          setFieldValue('unitOfRepeat', value.repeatType);
          setCustomRepeater(false);
          setRepeat(false);
          break;
        }
      }
    },
    [values, REPEAT_DAYS, values.startDate],
  );

  const handleCountUpRepeatQuantity = useCallback(() => {
    setFieldValue('endOfRepeatQuantity', values.endOfRepeatQuantity + 1);
    if (values.endOfRepeatQuantity < 0) {
      setFieldValue('endOfRepeatQuantity', 1);
    }
  }, [values]);

  const handleCountDownRepeatQuantity = useCallback(() => {
    if (values.endOfRepeatQuantity > 1) {
      setFieldValue('endOfRepeatQuantity', values.endOfRepeatQuantity - 1);
    }
  }, [values]);

  const handleRepeaterChange = useCallback(
    ({ value }: OptionTypeBase) => {
      if (value !== 'WEEKS') {
        setFieldValue('repeatDays', values.repeatDays);
      }
      if (value !== 'MONTHS') {
        setFieldValue('monthRepeatType', values.monthRepeatType);
      }
      setFieldValue('unitOfRepeat', value);
    },
    [values],
  );

  const handleRepeaterMonthDaysChange = useCallback(
    ({ value }: OptionTypeBase) => {
      setFieldValue('monthRepeatType', value);
    },
    [values],
  );

  const handleCountUpInterval = useCallback(() => {
    setFieldValue('repeatInterval', values.repeatInterval + 1);
    if (values.repeatInterval < 0) {
      setFieldValue('repeatInterval', 1);
    }
  }, [values]);

  const handleCountDownInterval = useCallback(() => {
    if (values.repeatInterval > 1) {
      setFieldValue('repeatInterval', values.repeatInterval - 1);
    }
  }, [values]);

  const handleStartDateChange = useCallback(
    value => {
      const date = value.target.value;
      setFieldValue('startDate', date);

      if (moment(values.endDate).isBefore(date)) setFieldValue('endDate', date);
      if (!eventType?.dateRangesEnabled) setFieldValue('endDate', date);
    },
    [values, eventType?.dateRangesEnabled],
  );

  const handleEndDateChange = useCallback(
    value => {
      const date = value.target.value;
      setFieldValue('endDate', date);

      if (moment(values.startDate).isAfter(date)) setFieldValue('startDate', date);
    },
    [values, eventType?.dateRangesEnabled],
  );

  const handleAssessmentsChange = useCallback(
    newValues => {
      const assessmentsId = newValues.map((el: Record<string, any>) => el.value.id);
      const assessments = newValues.map((el: Record<string, any>) => el.value);
      setFieldValue('assessmentIdList', assessmentsId);
      setFieldValue('assessments', assessments);
    },
    [values],
  );

  const formatPollOptionLabel = useCallback(
    ({ value }) => (
      <div className="related-poll__wrapper">
        <span className="related-poll__name">{value.name}</span>
        <span className="related-poll__date">{moment(value.deadline.split(' ')[0]).format(DATE_FORMAT.ll)}</span>
      </div>
    ),
    [],
  );

  const formatAssessmentOptionLabel = useCallback(
    ({ value }: { value: AssessmentsInfo }) => (
      <div className="related-assessment__wrapper">
        <span className="related-assessment__name">{value.competence.name}</span>
        <span className="related-assessment__date">
          {moment(value.assessmentDate.split(' ')[0]).format(DATE_FORMAT.ll)}
        </span>
      </div>
    ),
    [],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.editModalTitle)}
        classNameModal="modal--event"
      >
        {!isLoading.getEventType && !isLoading.getEvent ? (
          <form className="modal__form form" onSubmit={handleSubmit}>
            <div className="form__inputs-wrapper form__event-wrapper">
              <Select
                label={intl.formatMessage(messages.eventTypeLabel)}
                options={eventsTypeOptions}
                handleChange={handleEventTypeChange}
                value={eventTypeValue}
              />
              {values.showTitle && (
                <Input
                  name={'title'}
                  label={intl.formatMessage(messages.titleLabel)}
                  onChange={handleChange}
                  defaultValue={values.title}
                  hasError={hasError('title')}
                  errorMessage={errors?.title}
                  maxLength={100}
                />
              )}
              <div className="form__inputs-subwrapper align-start">
                {values.showEndDate ? (
                  <>
                    <Input
                      type={'date'}
                      label={intl.formatMessage(messages.startDateLabel)}
                      name={'startDate'}
                      onChange={handleStartDateChange}
                      hasError={hasError('startDate')}
                      errorMessage={errors.startDate}
                      defaultValue={values.startDate}
                      wrapperClass="form__input-date"
                    />
                    <Input
                      type={'date'}
                      label={intl.formatMessage(messages.endDateLabel)}
                      name={'endDate'}
                      onChange={handleEndDateChange}
                      hasError={hasError('endDate')}
                      errorMessage={errors.endDate}
                      defaultValue={values.endDate}
                      wrapperClass="form__input-date"
                    />
                  </>
                ) : (
                  <Input
                    type={'date'}
                    label={intl.formatMessage(messages.startDateLabel)}
                    name={'startDate'}
                    onChange={handleStartDateChange}
                    hasError={hasError('startDate')}
                    errorMessage={errors.startDate}
                    defaultValue={values.startDate}
                    wrapperClass="form__input-date"
                  />
                )}
                {values.showTime && (
                  <div className="form__input-block form__input-block--half">
                    <Checkbox
                      id={'allDay-edit'}
                      name="allDay"
                      label={intl.formatMessage(messages.allDayLabel)}
                      externalClass="form__checkbox-capitalazie-label"
                      onChange={handleAllDayChange}
                      checkedValue={values.allDay}
                    />
                  </div>
                )}
              </div>
              {values.showTime && !values.allDay && (
                <div className="form__inputs-subwrapper">
                  <div className="form__event-time-wrapper">
                    <Select
                      selectRef={selectTimeStartRef}
                      options={timeOptionsForSelect}
                      value={timeStartValue}
                      label={intl.formatMessage(messages.startTimeLabel)}
                      customInput={
                        <Input
                          inputRef={startTimeInputRef}
                          type={'time'}
                          name={'startTime'}
                          onChange={handleStartTimeChange}
                          defaultValue={values.startTime.slice(0, 5)}
                          disabled={values.allDay}
                        />
                      }
                      externalClass="time-select"
                      errorMessage={errors?.startTime}
                      customInputRef={startTimeInputRef}
                      handleChange={handleStartTimeSelectChange}
                      hasError={hasError('startTime')}
                    />
                  </div>
                  <div className="form__event-time-wrapper">
                    <Select
                      selectRef={selectTimeEndRef}
                      options={timeOptionsForSelect}
                      value={timeEndValue}
                      label={intl.formatMessage(messages.endTimeLabel)}
                      customInput={
                        <Input
                          inputRef={endTimeInputRef}
                          type={'time'}
                          name={'endTime'}
                          onChange={handleEndTimeChange}
                          defaultValue={values.endTime.slice(0, 5)}
                          disabled={values.allDay}
                        />
                      }
                      externalClass="time-select"
                      errorMessage={errors?.endTime}
                      customInputRef={endTimeInputRef}
                      handleChange={handleEndTimeSelectChange}
                      hasError={hasError('endTIme')}
                    />
                  </div>
                </div>
              )}
              {values.eventType.repeaterEnabled && (
                <>
                  <div className="form__input-block">
                    <Select
                      options={repeateDaysOptions}
                      label={intl.formatMessage(messages.recurrenceLabel)}
                      value={repeateDaysValues}
                      handleChange={handleRepeatDaysChange}
                    />
                  </div>
                  <EventRepeater
                    valuesEvent={values}
                    isCustomRepeater={isCustomRepeater}
                    isRepeat={isRepeat}
                    errors={errors}
                    repeaterOptions={repeaterOptions}
                    repeaterMonthDaysOptions={repeaterMonthDaysOptions}
                    repeaterValue={repeaterValue}
                    repeaterMonthDaysValues={repeaterMonthDaysValues}
                    handleChange={handleChange}
                    handleCountUpInterval={handleCountUpInterval}
                    handleCountDownInterval={handleCountDownInterval}
                    handleRepeaterChange={handleRepeaterChange}
                    hasError={hasError}
                    handleRepeaterMonthDaysChange={handleRepeaterMonthDaysChange}
                    handleCountUpRepeatQuantity={handleCountUpRepeatQuantity}
                    handleCountDownRepeatQuantity={handleCountDownRepeatQuantity}
                  />
                </>
              )}
              {values.showReminder && (
                <EventReminder
                  handleChange={handleChange}
                  reminders={values.reminders}
                  handleCountUp={handleCountUpReminder}
                  handleCountDown={handleCountDownReminder}
                  addReminder={addReminder}
                  removeReminder={removeReminder}
                  remindersOptions={remindersOptions}
                  handleReminderChange={handleReminderChange}
                />
              )}
              {values.showTargetEmployee && (
                <div className="form__inputs-subwrapper">
                  <Select
                    isClearable
                    externalClass={'form__input-block--half'}
                    label={intl.formatMessage(messages.targetMemberLabel)}
                    options={usersOptions}
                    handleChange={handleEmployeeChange}
                    value={usersValue}
                    hasError={hasError('targetEmployee')}
                    // @ts-ignore
                    errorMessage={errors?.targetEmployee}
                    isDisabled={!checkPolicies([UPDATE_EVENT_EXTENDED], userPolicies)}
                  />
                </div>
              )}
              {values.showEventParticipants && (
                <div className="form__input-block">
                  <Select
                    label={intl.formatMessage(messages.participantsLabel)}
                    options={participantsOptions}
                    value={participantsValues}
                    isMulti
                    handleChange={handleParticipantsChange}
                    hasError={hasError('participants')}
                    // @ts-ignore
                    errorMessage={errors?.participants}
                  />
                </div>
              )}
              {values.showExcludedParticipants && (
                <div className="form__input-block">
                  <Select
                    label={intl.formatMessage(messages.excludedParticipantsLabel)}
                    options={excludedParticipantsOptions}
                    value={excludedParticipantsValues}
                    isMulti
                    handleChange={handleExcludedParticipantsChange}
                    hasError={hasError('excludedParticipants')}
                    // @ts-ignore
                    errorMessage={errors?.excludedParticipants}
                  />
                </div>
              )}
              {values.showQuestionnaires && (
                <Select
                  isMulti
                  isSearchable
                  label={intl.formatMessage(messages.questionnaireLabel)}
                  options={pollsOptions}
                  value={pollsValues}
                  handleChange={handlePollsChange}
                  formatOptionLabel={formatPollOptionLabel}
                  hasError={hasError('polls')}
                  // @ts-ignore
                  errorMessage={errors?.polls}
                />
              )}
              {values.showAssessments && (
                <Select
                  isMulti
                  isSearchable
                  label={intl.formatMessage(messages.assesmentsLabel)}
                  options={assessmentsOptions}
                  formatOptionLabel={formatAssessmentOptionLabel}
                  handleChange={handleAssessmentsChange}
                  value={assessmentsValues}
                  isDisabled={assessmentsLoading}
                />
              )}
              {values.showLocations && (
                <Select
                  label={intl.formatMessage(messages.officeLabel)}
                  options={officesOptions}
                  handleChange={handleOfficeChange}
                  value={officesValues}
                  isMulti
                  hasError={hasError('locations')}
                  // @ts-ignore
                  errorMessage={errors?.locations}
                />
              )}
              {values.showAddress && (
                <div className="form__input-block">
                  <Input
                    tag="textarea"
                    label={intl.formatMessage(messages.addressLabel)}
                    name="address"
                    onChange={handleChange}
                    defaultValue={values.address}
                    hasError={hasError('address')}
                    errorMessage={errors?.address}
                  />
                </div>
              )}
              {values?.optionForExcludeDaysOfAbsenceFromOtherTypes && (
                <Select
                  label={intl.formatMessage(messages.excludeDaysOfAbsenceFromOtherTypesLabel)}
                  options={excludeEventTypeOptions}
                  isMulti
                  handleChange={handleExcludeEventType}
                  value={excludeEventTypeValues}
                  hasError={hasError('absences.excludeDaysOfAbsenceFromOtherTypes')}
                  // @ts-ignore
                  errorMessage={errors?.absences?.excludeDaysOfAbsenceFromOtherTypes}
                />
              )}
              {values?.showAbsences &&
                values?.finalDecisionOptionsEnabled &&
                values.targetEmployee?.id === authUser.id && (
                  <Checkbox
                    id={'absences.finalDecision-edit'}
                    name={'absences.finalDecision'}
                    label={intl.formatMessage(messages.finalDecisionLabel)}
                    externalClass={'label-bold form__checkbox-capitalazie-label absence-checkbox'}
                    onChange={(e: any) => setFieldValue('absences.finalDecision', e.target.checked)}
                    checkedValue={values.absences.finalDecision}
                  />
                )}
              <Input
                tag="textarea"
                label={intl.formatMessage(messages.descriptionLabel)}
                name="description"
                onChange={handleChange}
                defaultValue={values.description}
                hasError={hasError('description')}
                errorMessage={errors?.description}
                externalClass="event-textarea"
              />
              {values?.showAbsences && values?.requiresApprovalEnabled && canAuthUserApprove && (
                <Checkbox
                  id={'absences.approve'}
                  label={intl.formatMessage(messages.approveLabel)}
                  externalClass={
                    'label-bold form__checkbox-block--align-right form__checkbox-capitalazie-label absence-checkbox'
                  }
                  onChange={(e: any) => setFieldValue('absences.approve', e.target.checked)}
                  checkedValue={values.absences.approve || false}
                />
              )}
            </div>
            <ErrorMessage>{!Array.isArray(eventError) && eventError}</ErrorMessage>
            <div className="form__buttons-wrapper">
              {isSendNotificationShown && (
                <div className="switch">
                  <Switch onChange={handleChange} name={'sendNotification'} checked={values.sendNotification} />
                  <div className="switch__title">
                    <FormattedMessage {...messages.sendNotificationLabel} />
                  </div>
                </div>
              )}
              {isNotificationForShown && (
                <Select
                  label={intl.formatMessage(messages.sendNotificationOptionsLabel)}
                  options={notificationOptions}
                  value={{
                    label: NOTIFICATION_OPTIONS[values.notificationParticipantsGroup] || NOTIFICATION_OPTIONS.ALL,
                    value: values.notificationParticipantsGroup || ENotificationGroup.ALL,
                  }}
                  handleChange={handleNotificationChange}
                  hasError={hasError('notificationParticipantsGroup')}
                  // @ts-ignore
                  errorMessage={errors?.notificationParticipantsGroup}
                />
              )}
            </div>
            <div className="form__buttons">
              <Button externalClass={'button--modal'} type={'button'} onClick={resetAndExit} color="gray">
                <FormattedMessage {...messages.cancelButton} />
              </Button>
              <Button
                externalClass={'button--modal'}
                type="submit"
                loading={isLoading.editEvent}
                disabled={isLoading.editEvent}
              >
                <FormattedMessage {...messages.saveButton} />
              </Button>
            </div>
          </form>
        ) : (
          <CustomLoader />
        )}
      </Modal>
      {(eventData?.parentEvent || isRepeat) && isOpenUpdateNextModal && (
        <ModalForRepeatableEvents
          isOpen
          title="Edit recurring event"
          onRequestClose={closeUpdateNextModal}
          onSubmit={onSubmitUpdateNextCallback}
          isLoading={isLoading.editEvent}
          eventError={eventError}
          inputName="updateNextEvent"
        />
      )}
    </>
  );
}

export default ModalEditEvent;
