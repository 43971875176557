import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  newCandidateReceivingSourceTitle: {
    id: 'candidates.candidate.receiving.sourcees.modal.new.title.new.receiving.source',
    defaultMessage: 'New Receiving Source',
  },
  editCandidateReceivingSourceTitle: {
    id: 'candidates.candidate.receiving.sourcees.modal.edit.title.edit.receiving.source',
    defaultMessage: 'Edit Receiving Source',
  },
});
