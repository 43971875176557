import Modal from '../../Modal';
import Button from '../../Button';
import React, { useCallback, useMemo, useState } from 'react';
import ModalForRepeatableEvents from './ModalForRepeatableEvents';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import { isEmpty } from 'lodash-es';
import Switch from '../../Switch';
import { EventInfo } from '../../../enums/schedule.enum';
import CustomLoader from '../../Loader';

type ModalDeleteEventProps = {
  eventData: EventInfo;
  eventError: string | RejectValueErrors[] | null;
  isLoading: {
    getEventType: boolean;
    getEventList: boolean;
    getEvent: boolean;
    createEvent: boolean;
    editEvent: boolean;
    deleteEvent: boolean;
  };
  isOpen: boolean;
  cb?: () => void;
  authUserId: string;
  onCloseRequest: () => void;
  onDeleteRequest: (data: any, updateNext?: boolean) => void;
  resetErrors: () => void;
};

function ModalDeleteEvent({
  eventData,
  eventError,
  isLoading,
  isOpen,
  authUserId,
  onCloseRequest,
  onDeleteRequest,
  resetErrors,
  cb,
}: ModalDeleteEventProps) {
  const intl = useIntl();

  const [sendNotification, setSendNotification] = useState<boolean>(true);

  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetErrors();
  }, []);

  const onSubmitDeleteNextCallback = useCallback(
    nextEvent => () => {
      onDeleteRequest(
        {
          id: eventData.id,
          deleteNext: nextEvent,
          sendNotification: !!(sendNotification && isSendNotificationShown),
          callback: () => {
            resetAndExit();
            cb && cb();
          },
        },
        nextEvent,
      );
    },
    [eventData, authUserId, sendNotification],
  );

  const handleSendNotification = useCallback(e => {
    setSendNotification(e.currentTarget.checked);
  }, []);

  const isSendNotificationShown = useMemo(
    () =>
      !isEmpty(eventData.participants) ||
      !isEmpty(eventData.locations) ||
      (eventData.targetEmployee && eventData.targetEmployee.id !== authUserId),
    [eventData.participants, eventData.locations, eventData.targetEmployee, authUserId],
  );

  return !eventData.parentEvent ? (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.deleteModalTitle)}
      size={'small'}
      classNameModal="center"
    >
      {!isLoading.getEvent ? (
        <>
          <ErrorMessage>{!Array.isArray(eventError) && eventError}</ErrorMessage>
          <div className="form__buttons-wrapper">
            {isSendNotificationShown && (
              <div className="switch">
                <Switch onChange={handleSendNotification} checked={sendNotification} />
                <div className="switch__title">
                  <FormattedMessage {...messages.sendNotificationLabel} />
                </div>
              </div>
            )}
          </div>
          <div className="form__buttons">
            <Button externalClass={'button--modal'} onClick={onCloseRequest} color="gray">
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button
              externalClass={'button--modal'}
              onClick={() => {
                onDeleteRequest({
                  id: eventData.id,
                  sendNotification: !!(sendNotification && isSendNotificationShown),
                  callback: () => {
                    resetAndExit();
                    cb && cb();
                  },
                });
              }}
              loading={isLoading.deleteEvent}
              disabled={isLoading.deleteEvent}
            >
              <FormattedMessage {...messages.deleteButton} />
            </Button>
          </div>
        </>
      ) : (
        <CustomLoader />
      )}
    </Modal>
  ) : (
    <ModalForRepeatableEvents
      isOpen
      onRequestClose={onCloseRequest}
      onSubmit={onSubmitDeleteNextCallback}
      title={intl.formatMessage(messages.deleteRecurringModalTitle)}
      shouldCloseOnOverlayClick
      isLoading={isLoading.deleteEvent}
      eventError={eventError}
      inputName="deleteNextEvent"
      isSendNotificationShown={!!isSendNotificationShown}
      sendNotification={sendNotification}
      handleSendNotification={handleSendNotification}
    />
  );
}

export default ModalDeleteEvent;
