import React, { useMemo } from 'react';
import messages from './messages';
import { PollTemplate } from '../../enums/questionnaires.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import TableUserAvatar from '../../components/TableUserAvatar';
import { DATE_FORMAT } from '../../constants/date.constants';
import moment from 'moment';
import Icon from '../../components/Icon';
import { useHistory } from 'react-router';
import { DELETE_TEMPLATE, UPDATE_TEMPLATE } from '../../constants/policies.constants';
import r from '../../constants/routes.constants';

export const useDataForTable = (
  copyTemplate: (data: Record<string, unknown>) => void,
  setTemplateClicked: (template: PollTemplate) => void,
  openDeletePollTemplateModal: () => void,
  openPreviewPollTemplateModal: () => void,
) => {
  const intl = useIntl();
  const navigate = useHistory();

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: PollTemplate) =>
          row.name && (
            <div
              onClick={() => {
                setTemplateClicked(row);
                openPreviewPollTemplateModal();
              }}
              className="table_button--open-modal table__data-wrapper bold"
            >
              {row.name}
            </div>
          ),
      },
      {
        name: intl.formatMessage(messages.categoryColumn),
        sortName: 'categoryName',
        modifier: (row: PollTemplate) => row.categoryName,
      },
      {
        name: intl.formatMessage(messages.authorColumn),
        sortName: 'author.secondName',
        modifier: (row: PollTemplate) => <TableUserAvatar users={[row.author]} />,
      },
      {
        name: intl.formatMessage(messages.createdColumn),
        sortName: 'createdDate',
        modifier: (row: PollTemplate) => moment(row.createdDate).format(DATE_FORMAT.ll),
      },
      {
        name: intl.formatMessage(messages.editorColumn),
        sortName: 'lastEditor.secondName',
        modifier: (row: PollTemplate) => <TableUserAvatar users={[row.lastEditor]} />,
      },
      {
        name: intl.formatMessage(messages.updatedColumn),
        sortName: 'updatedDate',
        modifier: (row: PollTemplate) => moment(row.updatedDate).format(DATE_FORMAT.ll),
      },
      {
        name: `${intl.formatMessage(messages.pollsTitle)} #`,
        sortName: 'pollAmount',
        modifier: (row: PollTemplate) => (row.pollsNumber ? row.pollsNumber : 0),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: PollTemplate) => navigate.push(r.editPollTemplate.replace(':id', `${row.id}`)),
        verifiablePolicies: [UPDATE_TEMPLATE],
      },
      {
        label: (
          <>
            <Icon iconName={'copy'} externalClass={'dropdown__list-item__icon'} />{' '}
            <FormattedMessage {...messages.cloneButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: PollTemplate) => copyTemplate({ data: row }),
        verifiablePolicies: [UPDATE_TEMPLATE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: PollTemplate) => {
          setTemplateClicked(row);
          openDeletePollTemplateModal();
        },
        verifiablePolicies: [DELETE_TEMPLATE],
      },
    ],
    [],
  );

  return {
    tableColumns,
    tableActions,
  };
};
