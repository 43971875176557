import React, { memo, useMemo } from 'react';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { OptionTypeBase } from 'react-select';
import { FinancePlanEmployeesParams } from '../../../enums/params/finance.params';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { UserInfo } from '../../../enums/users.enum';
import { FilterParamsName } from '../../../constants/filters.constants';

type FiltersType = {
  users: UserInfo[];
};

type PlanUsersFiltersProps = {
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  filters: FiltersType;
  values: FinancePlanEmployeesParams;
};

const PlanUsersFilters = ({ handleUsersParamsChange, filters, values }: PlanUsersFiltersProps) => {
  const intl = useIntl();

  const usersOptions = useMemo(
    () =>
      filters.users.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [filters.users],
  );

  const usersValues = useFiltersListValue(usersOptions, values.employeeIds);

  return (
    <>
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.membersLabel)}
        options={usersOptions}
        value={usersValues}
        handleChange={e => handleUsersParamsChange(FilterParamsName.EMPLOYEE_IDS)(e)}
        externalClass="filters__select"
      />
    </>
  );
};

export default memo(PlanUsersFilters);
