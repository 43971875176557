import React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import TableUserAvatar from '../../components/TableUserAvatar';
import ENV from '../../constants/env.constants';
import { DepthLevels } from '../../constants/tables.constants';
import { UserInfo } from '../../enums/users.enum';
import { getForamtedTime } from '../../utils/reports.utils';
import { getTableCell } from '../../utils/table.utils';
import messages from './messages';

export const useDataForTable = (
  isWorkLogsRounded: boolean,
  isFoldIssuesToProject: boolean,
  isFoldSubtasksToParentTask: boolean,
) => {
  const intl = useIntl();
  const tableColumns = useMemo(
    () => [
      {
        Header: intl.formatMessage(messages.memberKeyColumn),
        id: 'memberKeyColumn',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: any) => (
                <div>
                  {row.isFromPortal ? (
                    <TableUserAvatar
                      users={[new UserInfo({ ...row, id: row.portalId, secondName: row.lastName })]}
                      fileSize={36}
                    />
                  ) : (
                    row.displayName
                  )}
                  {!row.isFromPortal && row.id && (
                    <span className="users-jira-report-status">
                      {` (${intl.formatMessage(messages.externalLabel)})`}
                    </span>
                  )}
                  {row.isFromPortal && row.id && !row.isPortalActive && (
                    <span className="users-jira-report-status">
                      {` (${intl.formatMessage(messages.inactiveLabel)})`}
                    </span>
                  )}
                </div>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: any) => <span>{row.name}</span>,
            },
            {
              depth: (depth: number) => depth >= DepthLevels.THIRD,
              content: (row: any) => {
                const issueKey = `${row.pkey}-${row.issueNum}`;
                return (
                  <a
                    target="_blank"
                    className="report__task-name"
                    href={`${ENV.JIRA_BASE_URL}/browse/${issueKey}`}
                    rel="noreferrer"
                  >
                    {issueKey}
                  </a>
                );
              },
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.taskLabel),
        id: 'taskLabel',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: (depth: number) => depth >= DepthLevels.THIRD,
              content: (row: any) => <span>{row.summary}</span>,
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.typeLabel),
        id: 'typeLabel',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: (depth: number) => depth >= DepthLevels.THIRD,
              content: (row: any) => <span>{row.issueType.pname}</span>,
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.estimateLabel),
        id: 'estimateLabel',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: (depth: number) => depth >= DepthLevels.THIRD,
              content: (row: any) => (
                <span>
                  {row.timeOriginalEstimate !== null ? `${getForamtedTime(row.timeOriginalEstimate, false)}h` : '-'}
                </span>
              ),
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.spentLabel),
        id: 'spentLabel',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: any) => <span>{`${getForamtedTime(row.timeSpent, isWorkLogsRounded)}h`}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: any) => <span>{`${getForamtedTime(row.timeSpent, isWorkLogsRounded)}h`}</span>,
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: any) => {
                const timeSpent = isFoldSubtasksToParentTask ? row.timeSpent : row.thisTimeSpent;
                return <span>{`${getForamtedTime(timeSpent, isWorkLogsRounded)}h`}</span>;
              },
            },
            {
              depth: (depth: number) => depth >= DepthLevels.FOURTH,
              content: (row: any) => <span>{`${getForamtedTime(row.timeSpent, isWorkLogsRounded)}h`}</span>,
            },
          ]);
        },
      },
    ],
    [isFoldSubtasksToParentTask, isFoldIssuesToProject, isWorkLogsRounded],
  );

  return {
    tableColumns,
  };
};
