import { OfficeInfo } from './libraries.enum';
import { EventTypeInfo } from './calendar.enum';
import get from 'lodash-es/get';
import * as yup from 'yup';
import { UserPreviewInfo, UserInfo } from './users.enum';

export class MailerSetting {
  id: string;
  displayName: string;
  encryption: string;
  host: string;
  password: string;
  port: number;
  serverTypes: string[];
  username: string;

  constructor(mailerSetting?: unknown) {
    this.id = get(mailerSetting, 'id', '');
    this.displayName = get(mailerSetting, 'displayName', '');
    this.encryption = get(mailerSetting, 'encryption', '');
    this.host = get(mailerSetting, 'host', '');
    this.password = get(mailerSetting, 'password', '');
    this.port = get(mailerSetting, 'port', 1);
    this.username = get(mailerSetting, 'username', '');
    this.serverTypes = get(mailerSetting, 'serverTypes', []);
  }
}

const mailerDefaultValidation = {
  displayName: yup.string().trim().max(255, 'Maximum 255 characters').required('Required'),
  encryption: yup.string().trim().max(255, 'Maximum 255 characters').required('Required'),
  host: yup.string().trim().max(255, 'Maximum 255 characters').required('Required'),
  port: yup.number().min(1, 'The minimum number is one').integer('Only integer').required('Required'),
  username: yup.string().trim().max(255, 'Maximum 255 characters').required('Required'),
};

export const NEW_MAILER_SETTING_VALIDATION_SCHEMA = yup.object().shape({
  ...mailerDefaultValidation,
  password: yup.string().trim().max(255, 'Maximum 255 characters').required('Required'),
});

export const EDIT_MAILER_SETTING_VALIDATION_SCHEMA = yup.object().shape({
  ...mailerDefaultValidation,
  password: yup.string().trim().max(255, 'Maximum 255 characters').nullable(),
});

export class EmployeeGroup {
  id: string;
  name: string;
  userIds: string[];

  constructor(employeeGroup?: unknown) {
    this.id = get(employeeGroup, 'id', '');
    this.name = get(employeeGroup, 'name', '');
    this.userIds = get(employeeGroup, 'userIds', []);
  }
}

export const EMPLOYEE_GROUP_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().max(255, 'Maximum 255 characters').required('Required'),
});

export class FollowUp {
  id: string;
  name: string;
  userIds: string[];
  users: UserPreviewInfo[];
  emails: string[];
  employeeGroups: EmployeeGroup[];
  employeeGroupIds: string[];
  eventSettings: EventNotificationSettings[];
  officeIds: string[];
  offices: OfficeInfo[];

  constructor(followUp?: unknown) {
    this.id = get(followUp, 'id', '');
    this.name = get(followUp, 'name', '');
    this.userIds = get(followUp, 'userIds', []);
    this.users = get(followUp, 'users', []).map((user: any) => new UserPreviewInfo(user));
    this.emails = get(followUp, 'emails', []);
    this.employeeGroups = get(followUp, 'employeeGroups', []).map(
      (employeeGroup: any) => new EmployeeGroup(employeeGroup),
    );
    this.employeeGroupIds = get(followUp, 'employeeGroupIds', []);
    this.eventSettings = get(followUp, 'eventSettings', []).map(
      (eventSetting: any) => new EventNotificationSettings(eventSetting),
    );
    this.officeIds = get(followUp, 'officeIds', []);
    this.offices = get(followUp, 'offices', []).map((office: any) => new OfficeInfo(office));
  }
}

export class EventNotificationSettings {
  eventType: EventTypeInfo;
  eventTypeId: string;
  remindDaysBefore: number;

  constructor(eventSetting?: unknown) {
    this.eventType = get(eventSetting, 'eventType', {});
    this.eventTypeId = get(eventSetting, 'eventTypeId', '');
    this.remindDaysBefore = get(eventSetting, 'remindDaysBefore', 1);
  }
}

export const FOLLOW_UP_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().max(255, 'Maximum 255 characters').required('Required'),
  eventSettings: yup.array(
    yup.object({
      eventTypeId: yup.string().trim().required('Required'),
    }),
  ),
  emails: yup.array(yup.string().trim().email('There are incorrect emails')),
});

export class NotificationTemplate {
  bodyTemplate: string;
  eventTypeId: string;
  eventType: EventTypeInfo;
  id: string;
  isBaseTemplate: boolean;
  notificationActionType: string;
  subjectTemplate: string;
  templateType: string;
  updateDateTime: string;
  updater: UserInfo;
  updaterId: string;

  constructor(template?: unknown) {
    this.id = get(template, 'id', '');
    this.bodyTemplate = get(template, 'bodyTemplate', '');
    this.eventTypeId = get(template, 'eventTypeId', '');
    this.eventType = get(template, 'eventType');
    this.isBaseTemplate = get(template, 'isBaseTemplate', false);
    this.notificationActionType = get(template, 'notificationActionType', '');
    this.subjectTemplate = get(template, 'subjectTemplate', '');
    this.templateType = get(template, 'templateType', 'SCHEDULE_EVENT');
    this.updateDateTime = get(template, 'updateDateTime', '');
    this.updater = get(template, 'updater');
    this.updaterId = get(template, 'updaterId', '');
  }
}

export const triggerOptions = [
  { value: 'CREATE', label: 'Event Created                          ' },
  { value: 'DELETE', label: 'Event Deleted' },
  { value: 'SOON', label: 'Upcoming event reminder' },
  { value: 'UPDATE', label: 'Event Updated' },
];

export const eventVariables = [
  '[RecipientFullName]',
  '[Event.Link]',
  '[Event.TypeName]',
  '[Event.Title]',
  '[Event.Description]',
  '[Event.StartDate]',
  '[Event.EndDate]',
  '[Event.StartTime]',
  '[Event.EndTime]',
  '[Event.CalendarDays]',
  '[Event.WorkDays]',
  '[Event.TargetMember]',
  '[Event.Participants]',
  '[Event.Offices]',
  '[Event.Address]',
  '[Event.FinalDecision]',
  '[Event.Counter]',
  '[Event.Limit]',
  '[Event.Approved]',
  '[Event.ExcludeParticipant]',
];

export const createPollVariables = [
  '[Poll Name]',
  '[Poll Description]',
  '[Poll Due Date]',
  '[Poll Orinizers]',
  '[Personal Poll Link]',
];

export const pollResultVariables = [
  '[Orginizer Full Name]',
  '[Poll Name]',
  '[Poll Description]',
  '[Poll Due Date]',
  '[Responder Full Name]',
  '[Response Date and Time]',
  '[Answered Responders]',
  '[All Responders]',
  '[Poll Link]',
];

export const profileVariables = [
  '[Member Full Name]',
  '[Member Login]',
  '[Member Password]',
  '[Member Primary E-mail]',
  '[Link to Member Personal Profile]',
];

export enum TemplateType {
  SCHEDULE_EVENT = 'SCHEDULE_EVENT',
  QUESTIONNAIRE_IN_PROGRESS = 'QUESTIONNAIRE_IN_PROGRESS',
  QUESTIONNAIRE_ANSWERED = 'QUESTIONNAIRE_ANSWERED',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
}

export const triggerTypes = [
  { value: TemplateType.QUESTIONNAIRE_ANSWERED, label: 'Saving the result of passing the survey by an employee' },
  { value: TemplateType.QUESTIONNAIRE_IN_PROGRESS, label: 'Publication of the survey' },
  { value: TemplateType.UPDATE_PASSWORD, label: `Changing a user's password by an administrator` },
  { value: TemplateType.CREATE_ACCOUNT, label: 'Create an account' },
];

export const CREATE_NOTIFICATION_TEMPLATE_SCHEMA = yup.object().shape({
  notificationActionType: yup.string().required('Required'),
  eventTypeId: yup.string().required('Required'),
  subjectTemplate: yup.string().max(1024, 'Maximum 1024 characters').required('Required'),
  bodyTemplate: yup.string().max(2048, 'Maximum 2048 characters').required('Required'),
});

export const EDIT_NOTIFICATION_TEMPLATE_SCHEMA = yup.object().shape({
  subjectTemplate: yup.string().max(1024, 'Maximum 1024 characters').required('Required'),
  bodyTemplate: yup.string().max(2048, 'Maximum 2048 characters').required('Required'),
});
