import get from 'lodash-es/get';
import { DAYS_OF_WEEK } from '../constants/libraries.constants';
import * as yup from 'yup';
import { CompetenciesTypeInfoType } from '../types/competencies';

export const OFFICES_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().min(3, 'Name must be at least 3 characters long').required('Required'),
  address: yup.string().trim().min(3, 'Address must be at least 3 characters long').required('Required'),
  contacts: yup.array().of(
    yup.object().shape({
      additionalInfo: yup.string().trim().required('Required'),
      contactName: yup.string().trim().required('Required'),
      phone: yup.string().trim().required('Required'),
      email: yup.string().trim().email('Email is invalid').required('Required'),
    }),
  ),
  workDays: yup.array().of(yup.string().trim()).min(1, 'Required').required(),
  hourPerWorkDay: yup.number().positive('Should be greater than 0').integer('Should be integer').required('Required'),
});

export class OfficeInfo {
  id: string;
  name: string;
  address: string;
  hourPerWorkDay: number;
  workDays: string[];
  contacts: {
    id: string;
    contactName: string;
    email: string;
    phone: string;
    additionalInfo: string;
  }[];

  constructor(office?: unknown) {
    this.id = get(office, 'id', '');
    this.name = get(office, 'name', '');
    this.address = get(office, 'address', '');
    this.hourPerWorkDay = get(office, 'hourPerWorkDay', 8);
    this.workDays = get(office, 'workDays', []);
    this.contacts = get(office, 'contacts', []);
  }

  get officeWorkDays() {
    return DAYS_OF_WEEK.map(day => (this.workDays.find(d => day.fullName === d) ? day.shortName : null))
      .filter(item => item)
      .join(', ');
  }

  get officeWorkDayHour() {
    return `${this.hourPerWorkDay}h/day`;
  }

  get officeContacts() {
    return this.contacts.map(({ id, contactName, email, phone, additionalInfo }) => ({
      id,
      contactName: contactName || '-',
      email: email || '-',
      phone: phone || '-',
      additionalInfo: additionalInfo || '-',
    }));
  }
}

export class SpecializationInfo {
  id: string;
  name: string;
  competencies: Array<CompetenciesTypeInfoType>;
  competenceIds: Array<string>;

  constructor(specialization?: unknown) {
    this.name = get(specialization, 'name', '');
    this.id = get(specialization, 'id', '');
    this.competencies = get(specialization, 'competencies', []);
    this.competenceIds = get(
      specialization,
      'competenciesIds',
      this.competencies.map(competence => competence.id),
    );
  }
}

export const GRADES_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().required('Required').max(150, 'Maximum 150 characters'),
});

export const SPECIALIZATIONS_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().min(3, 'Name must be at least 3 characters long').required('Required'),
});

export const COMPANY_POSITIONS_VALIDATION_SCHEMA = yup.object().shape({
  displayName: yup.string().trim().required('Required').max(150, 'Maximum 150 characters'),
});

export const DEPARTMENTS_VALIDATION_SCHEMA = yup.object().shape({
  displayName: yup.string().trim().required('Required'),
});

export const PROJECTS_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required('Required'),
  description: yup.string().nullable(true),
});

export const TECHNICAL_SKILLS_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required('Required'),
  skills: yup.array().of(
    yup.object({
      id: yup.string(),
      skill: yup.string().required('Required'),
    }),
  ),
});

export const SKILL_LEVELS_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required('Required'),
});

export const LANGUAGE_LEVELS_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required('Required'),
});

export const FOREIGN_LANGUAGES_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required('Required'),
});
