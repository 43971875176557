import React, { useContext } from 'react';
import classNames from 'classnames';
import BrandingContext from '../../BrandingContext';

type RadioButtonProps = {
  label?: string;
  id?: string;
  name: string;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  externalClass?: string;
};

function RadioButton({ label, name, id, checked, onChange, value, externalClass }: RadioButtonProps) {
  const branding = useContext(BrandingContext);
  const defaultClass = classNames('form__input-block', {
    [externalClass as string]: Boolean(externalClass),
  });
  const style: any = {
    '--radio-active-color': branding?.globalAccents,
  };

  return (
    <div className={defaultClass} style={style}>
      <label className="form__radio-input">
        {label}
        <input type="radio" checked={checked} onChange={onChange} name={name} id={id || name} value={value} />
        <span className="form__radio-input-checkmark"></span>
      </label>
    </div>
  );
}

export default RadioButton;
