import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { CANDIDATE_PROFILE_TABS, candidateProfileTabs } from '../../../constants/Candidates/candidates.constants';
import { useHistory, useLocation } from 'react-router';
import ProfileAbout from './About';
import ProfileComments from './Comments';
import PoliciesContext from '../../../PoliciesContext';
import { checkPolicies } from '../../../utils/policies.utils';
import { VIEW_CANDIDATE_COMMENT } from '../../../constants/policies.constants';

type CandidateProfileTabsProps = {
  openEditModal: () => void;
};

function CandidateProfileTabs({ openEditModal }: CandidateProfileTabsProps) {
  const { search } = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(CANDIDATE_PROFILE_TABS.ABOUT);
  const policies = useContext(PoliciesContext);

  useEffect(() => {
    const activeSearchTab = new URLSearchParams(search).get('tab_name')?.toUpperCase();
    const activeTab =
      Object.values(CANDIDATE_PROFILE_TABS).find(tab => tab === activeSearchTab) || CANDIDATE_PROFILE_TABS.ABOUT;
    setActiveTab(activeTab);
  }, []);

  const handleActiveTab = useCallback(activeTab => {
    history.replace({
      search: `tab_name=${activeTab.toLowerCase()}`,
    });
    setActiveTab(activeTab);
  }, []);

  const tabContent = useMemo(() => {
    switch (activeTab) {
      case CANDIDATE_PROFILE_TABS.ABOUT:
        return <ProfileAbout openEditModal={openEditModal} />;
      case CANDIDATE_PROFILE_TABS.COMMENTS:
        return <ProfileComments />;
      default:
        return null;
    }
  }, [activeTab, policies]);

  return (
    <div className="tabs page__profile-tabs">
      <div className="tabs__header">
        <div className="tabs__header-wrapper">
          {candidateProfileTabs
            .filter(el =>
              el.value === CANDIDATE_PROFILE_TABS.COMMENTS ? checkPolicies([VIEW_CANDIDATE_COMMENT], policies) : true,
            )
            .map(({ displayName, value }, i) => (
              <div
                key={i.toString()}
                className={classNames('tabs__header-item', { active: activeTab === value })}
                onClick={() => handleActiveTab(value)}
              >
                {displayName}
              </div>
            ))}
        </div>
      </div>
      <div className="tabs__content">{tabContent}</div>
    </div>
  );
}

export default React.memo(CandidateProfileTabs);
