import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import UserLayout from './layouts/user.layout';
import AuthLayout from './layouts/auth.layout';
import BrandingContext from './BrandingContext';
import ColorContext from './ColorContext';
import * as brandingActions from './actions/branding.actions';
import * as policiesActions from './actions/policies.actions';
import { IntlProvider } from 'react-intl';
import { getBrandingData, getTextColorData } from './utils/branding.utils';
import PoliciesContext from './PoliciesContext';
import { getUserPolicies } from './utils/policies.utils';

type AppProps = {
  history: RouterHistory;
} & ConnectedProps<typeof connector>;

function App({
  history,
  isAuth,
  getPortalBranding,
  brandingData,
  getAuthUserPolicies,
  userPolicies,
  authUserId,
}: AppProps) {
  useEffect(() => {
    isAuth && getPortalBranding();
  }, [isAuth]);

  useEffect(() => {
    authUserId && getAuthUserPolicies();
  }, [authUserId]);

  const brandingDefault = getBrandingData();
  const colorData = getTextColorData();
  const userPoliciesDefault = getUserPolicies();
  const callbackUrl = new URL(document.location.toString()).searchParams.get('callbackUrl');

  return (
    <IntlProvider messages={{}} locale="en" defaultLocale="en">
      <PoliciesContext.Provider value={userPolicies ? userPolicies : userPoliciesDefault}>
        <BrandingContext.Provider value={brandingData ? brandingData : brandingDefault}>
          <ColorContext.Provider value={colorData}>
            <ConnectedRouter history={history}>
              {isAuth ? (
                <UserLayout callbackUrl={callbackUrl} />
              ) : (
                <AuthLayout callbackUrl={document.location.pathname} />
              )}
            </ConnectedRouter>
          </ColorContext.Provider>
        </BrandingContext.Provider>
      </PoliciesContext.Provider>
    </IntlProvider>
  );
}

const mapStateToProps = ({ auth, branding, policies }: RootState) => ({
  isAuth: auth.isAuth,
  authUserId: auth.currentUserInfo.id,
  brandingData: branding.brandingData,
  userPolicies: policies.authUserPolicies,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPortalBranding: () => dispatch(brandingActions.getPortalBranding()),
  getAuthUserPolicies: () => dispatch(policiesActions.getAuthUserPolicies()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(App);
