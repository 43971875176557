import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import * as scheduleActions from '../../../actions/schedule.actions';
import * as filtersActions from '../../../actions/filters.actions';
import Dropdown from '../../../components/Dropdown';
import ModalEditEvent from '../../../components/Schedule/Modals/ModalEditEvent';
import ModalDeleteEvent from '../../../components/Schedule/Modals/ModalDeleteEvent';
import CustomLoader from '../../../components/Loader';
import Avatar from '../../../components/Profile/Avatar';
import Comments, { ManageCommentFuncType } from '../../../components/Comments';
import EventPolls from '../EventPolls';
import { UserInfo } from '../../../enums/users.enum';
import { TRouteParamId } from '../../../enums/common.enum';
import {
  UPDATE_COMMENT,
  UPDATE_SECRET_COMMENT,
  VIEW_ASSESSMENT,
  VIEW_COMMENT,
  VIEW_POLL_EXTENDED,
  VIEW_SECRET_COMMENT,
} from '../../../constants/policies.constants';
import { checkPolicies } from '../../../utils/policies.utils';
import * as types from '../../../types/schedule/scheduleAction.type';
import {
  getRepeaterLabel,
  handleRenderDropdownListBtn,
  useAbleToManageEventTypes,
} from '../../../utils/schedule.utils';
import AccessChecker from '../../../components/AccessChecker';
import PoliciesContext from '../../../PoliciesContext';
import { PollParams } from '../../../enums/params/questionnaires.params';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import r from '../../../constants/routes.constants';
import { AssessmentsParams } from '../../../enums/params/competencies.params';
import { EventInfo, EventCommentInfo } from '../../../enums/schedule.enum';
import { isEmpty } from 'lodash-es';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import TableUserAvatar from '../../../components/TableUserAvatar';
import { initialComment, initialSecretComment } from '../utils';
import { getAnswersByPollIdForAdmin, getCurrentPoll } from '../../../actions/questionnaires.actions';
import { closedValue } from '../../Polls/utils';
import { PollTemplateGroupType } from '../../../enums/questionnaires.enum';
import { TemplateDatabaseDataSource } from '../../../constants/questionnaires.constants';

function EventInformation({
  eventData,
  eventType,
  eventError,
  eventTypeList,
  loading,
  officeList,
  userList,
  userInfo,
  comments,
  secretComments,
  assessmentList,
  assessmentsLoading,
  pollList,
  specializations,
  positions,
  jiraProjectsList,
  eventPolls,
  getEvent,
  resetEvent,
  getEventType,
  editEvent,
  deleteEvent,
  resetEventErrors,
  getEventTypesFilter,
  getOfficesFilter,
  getUsersFilter,
  getEventComments,
  getEventSecretComments,
  createEventComment,
  createEventSecretComment,
  editEventComment,
  editEventSecretComment,
  deleteEventComment,
  deleteEventSecretComment,
  getAssessmentsFilter,
  getSpecializationsFilter,
  getCompanyPositionsFilter,
  getJiraProjects,
  resetErrors,
  getPollsFilter,
  getCurrentPoll,
  getAnswersByPollIdForAdmin,
}: ConnectedProps<typeof connector>) {
  const userPolicies = useContext(PoliciesContext);
  const { id } = useParams<TRouteParamId>();
  const intl = useIntl();
  const [modalEditEventIsOpen, setModalEditEventIsOpen] = useState(false);
  const [modalDeleteEventIsOpen, setModalDeleteEventIsOpen] = useState(false);
  const [updateNextModal, setUpdateNextModal] = useState(false);
  const history = useHistory();
  const style: any = {
    '--event-circle-color': eventData?.eventType.color,
  };
  const handleReplaceNextEvent = useCallback(id => {
    history.replace(r.eventInformation.replace(':id', `${id}`));
  }, []);

  const openUpdateNextModal = useCallback(() => {
    setUpdateNextModal(true);
  }, []);

  const closeUpdateNextModal = useCallback(() => {
    updateNextModal && resetEventErrors();
    setUpdateNextModal(false);
  }, [updateNextModal]);

  const openEditEventModal = useCallback(() => {
    setModalEditEventIsOpen(true);
  }, []);

  const closeEditEventModal = useCallback(() => {
    !updateNextModal && modalEditEventIsOpen && resetEventErrors();
    !updateNextModal && setModalEditEventIsOpen(false);
  }, [modalEditEventIsOpen, updateNextModal]);

  const openDeleteEventModal = useCallback(() => {
    setModalDeleteEventIsOpen(true);
  }, []);

  const closeDeleteEventModal = useCallback(() => {
    modalDeleteEventIsOpen && resetEventErrors() && resetErrors();
    setModalDeleteEventIsOpen(false);
  }, [modalDeleteEventIsOpen]);

  const backButtonHandler = useCallback(() => {
    history.goBack();
  }, []);

  const getEventCommentsPaginate = useCallback(() => {
    getEventComments({
      eventId: id,
      params: {
        page: comments.number,
        size: comments.size,
      },
    });
  }, [comments.number, comments.size]);

  const getEventSecretCommentsPaginate = useCallback(() => {
    getEventSecretComments({
      eventId: id,
      params: {
        page: secretComments.number,
        size: secretComments.size,
      },
    });
  }, [secretComments.number, secretComments.size]);

  const handleCreateComment = useCallback(({ data, cb }: ManageCommentFuncType<EventCommentInfo>) => {
    createEventComment({
      eventId: id,
      data,
      cb,
    });
  }, []);

  const handleCreateSecretComment = useCallback(({ data, cb }: ManageCommentFuncType<EventCommentInfo>) => {
    createEventSecretComment({
      eventId: id,
      data,
      cb,
    });
  }, []);

  const handleEditComment = useCallback(({ data, cb }: ManageCommentFuncType<EventCommentInfo>) => {
    editEventComment({
      uuid: data.id,
      data: data.message,
      authorUuid: data.author.id,
      cb,
    });
  }, []);

  const handleEditSecretComment = useCallback(({ data, cb }: ManageCommentFuncType<EventCommentInfo>) => {
    editEventSecretComment({
      uuid: data.id,
      data: data.message,
      authorUuid: data.author.id,
      cb,
    });
  }, []);

  const deleteComment = ({
    isSecret,
    data,
    loadSize,
    commentClickedPage,
    cb,
  }: {
    data: EventCommentInfo;
    loadSize: number;
    commentClickedPage: number;
    isSecret: boolean;
    cb: () => void;
  }) => {
    const deleteData = {
      uuid: data.id,
      eventId: id,
      authorUuid: data.author.id,
      size: loadSize,
      commentPage: commentClickedPage,
      cb,
    };
    if (isSecret) {
      deleteEventSecretComment(deleteData);
    } else {
      deleteEventComment(deleteData);
    }
  };

  const handleDeleteComment = useCallback(
    (data: { data: EventCommentInfo; loadSize: number; commentClickedPage: number; cb: () => void }) => {
      deleteComment({ ...data, isSecret: false });
    },
    [],
  );

  const handleDeleteSecretComment = useCallback(
    (data: { data: EventCommentInfo; loadSize: number; commentClickedPage: number; cb: () => void }) => {
      deleteComment({ ...data, isSecret: true });
    },
    [],
  );

  useEffect(() => {
    getEvent(id);
  }, [id]);

  useEffect(() => {
    getEventTypesFilter();
    getOfficesFilter();
    getUsersFilter();

    return () => {
      resetEvent();
    };
  }, []);

  useEffect(() => {
    eventData.pollIds.forEach(id => {
      getCurrentPoll(id);
      getAnswersByPollIdForAdmin(id);
    });
  }, [eventData.pollIds]);

  useEffect(() => {
    if (eventPolls.length === eventData.pollIds.length) {
      const availablePolls = checkPolicies([VIEW_POLL_EXTENDED], userPolicies)
        ? eventPolls
        : eventPolls.filter(poll => poll.status === closedValue);
      let requestProjects = false;
      let requestPositions = false;
      let requestSpecializations = false;

      availablePolls.forEach(poll => {
        poll.template.groups.map((group: PollTemplateGroupType) =>
          group.fields.forEach(field => {
            if (field.templateDatabaseDataSource === TemplateDatabaseDataSource.PROJECTS) {
              requestProjects = true;
            }
            if (field.templateDatabaseDataSource === TemplateDatabaseDataSource.COMPANY_POSITIONS) {
              requestPositions = true;
            }
            if (field.templateDatabaseDataSource === TemplateDatabaseDataSource.SPECIALIZATIONS) {
              requestSpecializations = true;
            }
          }),
        );
      });

      requestSpecializations && getSpecializationsFilter();
      requestPositions && getCompanyPositionsFilter();
      requestProjects && getJiraProjects();
    }
  }, [eventPolls]);

  useEffect(() => {
    checkPolicies([VIEW_SECRET_COMMENT], userPolicies) &&
      getEventSecretComments({
        eventId: id,
        params: {
          page: 0,
          size: secretComments.size,
        },
      });
    checkPolicies([VIEW_COMMENT], userPolicies) &&
      getEventComments({
        eventId: id,
        params: {
          page: 0,
          size: comments.size,
        },
      });
  }, [id]);

  const ableToManageEventTypes = useAbleToManageEventTypes(eventTypeList, userInfo);

  const renderDropdownBtn = useCallback(
    (row: EventInfo, isUpdateBtn: boolean) =>
      handleRenderDropdownListBtn(row, ableToManageEventTypes, userInfo, userPolicies, isUpdateBtn, eventTypeList),
    [ableToManageEventTypes, userInfo, eventTypeList],
  );

  const goBackHandler = useCallback(() => {
    history.push(r.schedule);
  }, []);

  const eventActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />{' '}
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: () => {
          openEditEventModal();
        },
        handleRenderDropdownItem: (row: EventInfo) => renderDropdownBtn(row, true),
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />{' '}
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: () => {
          openDeleteEventModal();
        },
        handleRenderDropdownItem: (row: EventInfo) => renderDropdownBtn(row, false),
      },
    ],
    [eventData, userPolicies, renderDropdownBtn],
  );

  const participantsValues = useMemo(
    () =>
      eventData?.participants?.map((el: any) => (
        <div key={el.id} className="page__event__participant">
          <Avatar userInfo={el} size="medium" externalClass="avatar-participants" isUserPageLink fileSize={72} />
          <span>{el.fullName}</span>
        </div>
      )),
    [eventData?.participants],
  );

  const isRenderDropdown = renderDropdownBtn(eventData, true) || renderDropdownBtn(eventData, false);

  return (
    <>
      <div className="page__profile-panel" style={style}>
        <div className="page__panel__wrapper ">
          <div className="page__panel__back-title">
            <Button externalClass="page__back" color="gray" onClick={goBackHandler}>
              <FormattedMessage {...messages.pageTitle} />
            </Button>
          </div>
          {!loading.getEvent && (
            <div className="page__profile-panel__label-options">
              {eventData.absences.finalDecision && (
                <div className="page__profile-panel__label-info">
                  <div className="page__profile-panel__label label label--final-decision">
                    <FormattedMessage {...messages.finalDecisionLabel} />
                  </div>
                </div>
              )}
              {isRenderDropdown && (
                <Dropdown
                  dropdownToggle={<Icon iconName={'dots'} externalClass={'icons dots dropdown__button-main-icon'} />}
                  dropdownList={eventActions}
                  dropdownInfo={eventData}
                />
              )}
            </div>
          )}
        </div>
        <div className="title-wratter--schedule">
          {!loading.getEvent ? (
            <h1 className="page__title page__title--smaller event-circle event-circle--after">
              {eventData.eventTitle}
            </h1>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
      {!loading.getEvent && (
        <div className="page__inner-content">
          <div className="page__wrapper ">
            {eventData.absences.approve && (
              <div className="page__event__status">
                <div className="page__event__status-value">
                  <FormattedMessage {...messages.approvedLabel} />
                </div>
                <div className="page__event__status-responsible">
                  <TableUserAvatar users={[new UserInfo(eventData.absences.approvedBy)]} isUserPageLink fileSize={36} />
                </div>
                <div className="modal__event__status-date">
                  {moment(eventData.absences.approvedByDate).format(DATE_FORMAT.ll)}
                </div>
              </div>
            )}
            <div className="page__info-block page__info-block--days">
              <div className="page__event__day">
                <Icon iconName="calendar" />
                {eventData?.datePeriod}
              </div>
              {eventData.showEventTime && <div className="page__event__time">{eventData.eventTime}</div>}
              {eventData?.showRepeater && eventData?.unitOfRepeat && (
                <div className="page__event__repeat">
                  {getRepeaterLabel(
                    eventData?.unitOfRepeat,
                    eventData?.repeatInterval,
                    eventData?.startDate,
                    eventData?.monthRepeatType,
                    eventData?.repeatDays,
                  )}
                </div>
              )}
            </div>
            {!isEmpty(eventData?.assessments) && (
              <div className="page__info-block page__info-block--event assessment-links">
                {eventData.assessments.map((assessment, index) => (
                  <div key={index} className="link-item">
                    {checkPolicies([VIEW_ASSESSMENT], userPolicies) ? (
                      <NavLink className={'table__type-link'} to={r.assessmentsInfo.replace(':id', assessment.id)}>
                        <Icon iconName="link" externalClass="assessment-link" />
                        <span>{`${assessment.competenceName}, ${moment(assessment.assessmentDate).format(
                          DATE_FORMAT.ll,
                        )}`}</span>
                      </NavLink>
                    ) : (
                      <div className={'table__type-link link-disabled'}>
                        <Icon iconName="link" externalClass="assessment-link" />
                        <span>{`${assessment.competenceName}, ${moment(assessment.assessmentDate).format(
                          DATE_FORMAT.ll,
                        )}`}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {!isEmpty(eventData?.reminders) && (
              <div className="page__info-block page__info-block--remind">
                <Icon iconName="reminder" />
                <FormattedMessage {...messages.remindLabel} />
                &nbsp;
                {eventData?.reminders.map((reminder: any, index: number) => (
                  <span key={index} className="page__event__reminder">{`${
                    reminder.timeValue
                  } ${reminder.unitOfTime.toLowerCase()} ${intl.formatMessage(messages.beforeLabel)}`}</span>
                ))}
              </div>
            )}
            {!isEmpty(eventData?.locations) && (
              <div className="page__info-block page__info-block--town">
                <Icon iconName="map-marker" />
                {eventData?.locations.map((office: any, index: number) => (
                  <span className="page__event__town no-table" key={index}>
                    {office.name}
                  </span>
                ))}
              </div>
            )}
            {eventData?.description && (
              <div className="page__info-block page__info-block--info">
                <Icon iconName="info" />
                {eventData?.description}
              </div>
            )}
            {eventData?.targetEmployee && (
              <div className="page__info-block page__info-block--target-employee">
                <Icon iconName="user" />
                <div className="page__event__info-title">
                  <FormattedMessage {...messages.targetMemberLabel} />
                </div>
                <div className="page__event__employee">
                  <Avatar
                    userInfo={new UserInfo(eventData?.targetEmployee)}
                    size="large"
                    externalClass="page__event__employee-avatar"
                    isUserPageLink
                    fileSize={120}
                  />
                  <div
                    className={'page__event__employee-name'}
                  >{`${eventData.targetEmployee?.firstName} ${eventData.targetEmployee?.secondName}`}</div>
                </div>
              </div>
            )}
            {!isEmpty(eventData?.participants) && (
              <div className="page__event__participants">
                <div className="page__info-block page__info-block--participants">
                  <Icon iconName="users" externalClass={'page__info-block--organizers-icon'} />
                  <div className="page__event__info-title">
                    <FormattedMessage {...messages.participantsLabel} /> &nbsp;
                    <span className="page__event__count">{`(${participantsValues.length})`}</span>
                  </div>
                  <div className="page__event__participants-wrapper">{participantsValues}</div>
                </div>
              </div>
            )}
            <EventPolls
              userList={userList}
              targetEmployee={eventData.targetEmployee}
              specializations={specializations}
              positions={positions}
              jiraProjectsList={jiraProjectsList}
            />
            <div className="page__event__comments">
              <AccessChecker verifiablePolicies={[VIEW_COMMENT]}>
                <Comments
                  userInfo={userInfo}
                  title={intl.formatMessage(messages.commentsTitle)}
                  comments={comments.content}
                  totalElements={comments.totalElements}
                  initialComment={initialComment}
                  policyToCreateComment={UPDATE_COMMENT}
                  getCommentsPaginate={getEventCommentsPaginate}
                  createComment={handleCreateComment}
                  editComment={handleEditComment}
                  deleteComment={handleDeleteComment}
                  hasMore={!comments.last}
                  createLoading={loading.createComments}
                  editLoading={loading.editComments}
                  deleteLoading={loading.deleteComments}
                  loadSize={comments.size}
                  scrollId={'comments'}
                />
              </AccessChecker>
              <AccessChecker verifiablePolicies={[VIEW_SECRET_COMMENT]}>
                {!(eventData?.targetEmployee?.id === userInfo.id) && (
                  <Comments
                    userInfo={userInfo}
                    title={intl.formatMessage(messages.secretCommentsTitle)}
                    comments={secretComments.content}
                    totalElements={secretComments.totalElements}
                    initialComment={initialSecretComment}
                    policyToCreateComment={UPDATE_SECRET_COMMENT}
                    getCommentsPaginate={getEventSecretCommentsPaginate}
                    createComment={handleCreateSecretComment}
                    editComment={handleEditSecretComment}
                    deleteComment={handleDeleteSecretComment}
                    hasMore={!secretComments.last}
                    createLoading={loading.createSecretComments}
                    editLoading={loading.editSecretComments}
                    deleteLoading={loading.deleteSecretComments}
                    loadSize={secretComments.size}
                    scrollId={'secretComments'}
                  />
                )}
              </AccessChecker>
            </div>
          </div>
        </div>
      )}
      {eventData && (
        <>
          {modalEditEventIsOpen && (
            <ModalEditEvent
              isGetEventAfterEdit
              isOpen={modalEditEventIsOpen}
              eventError={eventError.eventError}
              eventType={eventType}
              isLoading={loading}
              offices={officeList}
              users={userList}
              eventTypesData={ableToManageEventTypes}
              eventData={eventData}
              isOpenUpdateNextModal={updateNextModal}
              assessmentsData={assessmentList}
              assessmentsLoading={assessmentsLoading}
              authUser={userInfo}
              polls={pollList}
              userPolicies={userPolicies}
              allEventTypes={eventTypeList}
              getPollsFilter={getPollsFilter}
              onCloseRequest={closeEditEventModal}
              getEventType={getEventType}
              onEditRequest={(data: any) => {
                editEvent({
                  ...data,
                });
              }}
              openUpdateNextModal={openUpdateNextModal}
              closeUpdateNextModal={closeUpdateNextModal}
              handleReplaceNextEvent={handleReplaceNextEvent}
              getAssessmentsFilter={getAssessmentsFilter}
              resetErrors={resetErrors}
            />
          )}
          {modalDeleteEventIsOpen && (
            <ModalDeleteEvent
              isOpen={modalDeleteEventIsOpen}
              authUserId={userInfo.id}
              onCloseRequest={closeDeleteEventModal}
              onDeleteRequest={(data: any) => {
                deleteEvent({
                  ...data,
                  fromDetailedPage: true,
                  callback: () => {
                    backButtonHandler();
                  },
                });
              }}
              eventData={eventData}
              eventError={eventError.eventError}
              isLoading={loading}
              resetErrors={resetErrors}
            />
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = ({ schedule, auth, filters, questionnaires }: RootState) => ({
  eventData: schedule.eventData,
  eventType: schedule.eventType,
  eventError: schedule.errors,
  loading: schedule.loading,
  userList: filters.usersFilter.users,
  officeList: filters.officesFilter.offices,
  eventTypeList: filters.eventTypesFilter.eventTypes,
  userInfo: auth.currentUserInfo,
  assessmentList: filters.assessmentsFilter.assessments,
  assessmentsLoading: filters.assessmentsFilter.loading,
  pollList: filters.pollsFilter.polls,
  specializations: filters.specializationsFilter.specializations,
  positions: filters.companyPositionsFilter.positions,
  jiraProjectsList: filters.jiraProjectFilter.jiraProject,
  comments: schedule.eventComments.comments,
  secretComments: schedule.eventComments.secretComments,
  eventPolls: questionnaires.eventPolls,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEvent: (id: any) => dispatch(scheduleActions.getEvent(id)),
  resetEvent: () => dispatch(scheduleActions.resetEvent()),
  getEventType: (id: string) => dispatch(scheduleActions.getEventType(id)),
  deleteEvent: (data: any) => dispatch(scheduleActions.deleteEvent(data)),
  editEvent: (data: any) => dispatch(scheduleActions.editEventType(data)),
  resetEventErrors: () => dispatch(scheduleActions.resetEventErrors()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getEventTypesFilter: () => dispatch(filtersActions.getEventTypesFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getSpecializationsFilter: () => dispatch(filtersActions.getSpecializationsFilter()),
  getCompanyPositionsFilter: () => dispatch(filtersActions.getCompanyPositionsFilter()),
  getJiraProjects: () => dispatch(filtersActions.getJiraProjectsFilter()),
  getAssessmentsFilter: (data: Partial<AssessmentsParams>) => dispatch(filtersActions.getAssessmentsFilter(data)),
  getPollsFilter: (data: Partial<PollParams>) => dispatch(filtersActions.getPollsFilter(data)),
  getCurrentPoll: (id: string) => dispatch(getCurrentPoll(id)),
  getAnswersByPollIdForAdmin: (id: string) => dispatch(getAnswersByPollIdForAdmin(id)),
  getEventComments: (data: types.GetEventCommentsActionType) => dispatch(scheduleActions.getEventComments(data)),
  createEventComment: (data: types.CreateEventCommentActionType) => dispatch(scheduleActions.createEventComment(data)),
  editEventComment: (data: types.EditEventCommentActionType) => dispatch(scheduleActions.editEventComment(data)),
  deleteEventComment: (data: types.DeleteEventCommentActionType) => dispatch(scheduleActions.deleteEventComment(data)),
  getEventSecretComments: (data: types.GetEventCommentsActionType) =>
    dispatch(scheduleActions.getEventSecretComments(data)),
  createEventSecretComment: (data: types.CreateEventCommentActionType) =>
    dispatch(scheduleActions.createSecretEventComment(data)),
  editEventSecretComment: (data: types.EditEventCommentActionType) =>
    dispatch(scheduleActions.editEventSecretComment(data)),
  deleteEventSecretComment: (data: types.DeleteEventCommentActionType) =>
    dispatch(scheduleActions.deleteEventSecretComment(data)),
  resetErrors: () => dispatch(scheduleActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EventInformation);
