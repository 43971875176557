import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/recognitions.constants';
import { RejectValueErrors } from '../enums/error.enum';
import { getReducerErrors } from '../utils/reducerError.utils';
import { Recognition } from '../enums/recognitions.enum';
import * as paramsTypes from '../enums/params/recognitions.params';

type InitialStateType = {
  recognitionTypeTableData: any;
  recognitionTableData: any;
  userRecognitionTableData: any;
  errors: {
    recognitionTypeError: string | RejectValueErrors[] | null;
    recognitionError: string | RejectValueErrors[] | null;
  };
  recognitionsParams: paramsTypes.RecognitionsParams;
  recognitionTypesParams: paramsTypes.RecognitionTypesParams;
  loading: {
    getRecognitionType: boolean;
    createRecognitionType: boolean;
    editRecognitionType: boolean;
    deleteRecognitionType: boolean;
    getRecognition: boolean;
    deleteRecognition: boolean;
    createRecognition: boolean;
    editRecognition: boolean;
  };
};

const initialState: InitialStateType = {
  recognitionTypeTableData: null,
  recognitionTableData: null,
  userRecognitionTableData: null,
  errors: {
    recognitionTypeError: null,
    recognitionError: null,
  },
  recognitionsParams: new paramsTypes.RecognitionsParams(),
  recognitionTypesParams: new paramsTypes.RecognitionTypesParams(),
  loading: {
    getRecognitionType: false,
    createRecognitionType: false,
    editRecognitionType: false,
    deleteRecognitionType: false,
    getRecognition: false,
    deleteRecognition: false,
    createRecognition: false,
    editRecognition: false,
  },
};

const recognitionsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_RECOGNITION_TYPE_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getRecognitionType: true },
      };

    case ActionTypes.GET_RECOGNITION_TYPE_LIST_SUCCESS:
      return {
        ...state,
        recognitionTypeTableData: action.payload.content,
        errors: { ...state.errors, recognitionTypeError: null },
        loading: { ...state.loading, getRecognitionType: false },
      };

    case ActionTypes.GET_RECOGNITION_TYPE_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, recognitionTypeError: action.payload.message },
        loading: { ...state.loading, getRecognitionType: false },
      };

    case ActionTypes.POST_RECOGNITION_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createRecognitionType: true },
      };

    case ActionTypes.POST_RECOGNITION_TYPE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, recognitionTypeError: null },
        loading: { ...state.loading, createRecognitionType: false },
      };

    case ActionTypes.POST_RECOGNITION_TYPE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          recognitionTypeError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createRecognitionType: false },
      };

    case ActionTypes.PUT_RECOGNITION_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editRecognitionType: true },
      };

    case ActionTypes.PUT_RECOGNITION_TYPE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, recognitionTypeError: null },
        loading: { ...state.loading, editRecognitionType: false },
      };

    case ActionTypes.PUT_RECOGNITION_TYPE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          recognitionTypeError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editRecognitionType: false },
      };

    case ActionTypes.DELETE_RECOGNITION_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteRecognitionType: true },
      };

    case ActionTypes.DELETE_RECOGNITION_TYPE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, recognitionTypeError: null },
        loading: { ...state.loading, deleteRecognitionType: false },
      };

    case ActionTypes.DELETE_RECOGNITION_TYPE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, recognitionTypeError: action.payload.message },
        loading: { ...state.loading, deleteRecognitionType: false },
      };

    case ActionTypes.GET_ALL_RECOGNITION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getRecognition: true },
      };

    case ActionTypes.GET_ALL_RECOGNITION_SUCCESS:
      return {
        ...state,
        recognitionTableData: {
          ...action.payload,
          content: action.payload.content.map((recognition: any) => new Recognition(recognition)),
        },
        errors: { ...state.errors, recognitionError: null },
        loading: { ...state.loading, getRecognition: false },
      };

    case ActionTypes.GET_ALL_RECOGNITION_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, recognitionError: action.payload.message },
        loading: { ...state.loading, getRecognition: false },
      };

    case ActionTypes.DELETE_RECOGNITION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteRecognition: true },
      };

    case ActionTypes.DELETE_RECOGNITION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, recognitionError: null },
        loading: { ...state.loading, deleteRecognition: false },
      };

    case ActionTypes.DELETE_RECOGNITION_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, recognitionError: action.payload.message },
        loading: { ...state.loading, deleteRecognition: false },
      };

    case ActionTypes.PUT_RECOGNITION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editRecognition: true },
      };

    case ActionTypes.PUT_RECOGNITION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, recognitionError: null },
        loading: { ...state.loading, editRecognition: false },
      };

    case ActionTypes.PUT_RECOGNITION_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          recognitionError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editRecognition: false },
      };

    case ActionTypes.GET_RECOGNITION_BY_USER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editRecognitionType: true },
      };

    case ActionTypes.GET_RECOGNITION_BY_USER_SUCCESS:
      return {
        ...state,
        userRecognitionTableData: action.payload.content.map((recognition: unknown) => new Recognition(recognition)),
        errors: { ...state.errors, recognitionError: null },
        loading: { ...state.loading, getRecognition: false },
      };

    case ActionTypes.GET_RECOGNITION_BY_USER_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, recognitionError: action.payload.message },
        loading: { ...state.loading, getRecognition: false },
      };

    case ActionTypes.POST_RECOGNITION_USER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createRecognition: true },
      };

    case ActionTypes.POST_RECOGNITION_USER_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, recognitionError: null },
        loading: { ...state.loading, createRecognition: false },
      };

    case ActionTypes.POST_RECOGNITION_USER_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          recognitionError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createRecognition: false },
      };

    case ActionTypes.SET_RECOGNITION_PARAMS_REQUEST:
      return {
        ...state,
        recognitionsParams: new paramsTypes.RecognitionsParams({ ...state.recognitionsParams, ...action.payload }),
        loading: { ...state.loading, getRecognition: true },
      };

    case ActionTypes.SET_RECOGNITION_TYPES_PARAMS_REQUEST:
      return {
        ...state,
        recognitionTypesParams: new paramsTypes.RecognitionTypesParams({
          ...state.recognitionTypesParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getRecognitionType: true },
      };

    case ActionTypes.RESET_RECOGNITIONS_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_RECOGNITIONS_STATE:
      return {
        ...initialState,
        recognitionsParams: state.recognitionsParams,
        recognitionTypesParams: state.recognitionTypesParams,
      };

    default:
      return state;
  }
};

export default recognitionsReducer;
