import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'activity.task.tracker.title.task.tracker.software',
    defaultMessage: 'TaskTracker Software',
  },
  trackerDescription: {
    id: 'activity.task.tracker.description',
    defaultMessage:
      'This program is designed to collect information about the time that a member spends on specific tasks in the course of their work',
  },
  activityLabel: {
    id: 'activity.task.tracker.label.activity',
    defaultMessage: 'Activity',
  },
});
