import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import merge from 'lodash-es/merge';
import isEqual from 'lodash-es/isEqual';
import { RECOGNITION_TYPE_VALIDATION_SCHEMA } from '../../../enums/recognitions.enum';
import { RecognitionDataType } from '../../../pages/RecognitionTypes';
import { InitValuesType } from './ModalNewRecognitionType';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import FileInput from '../../FileInput';
import ColorInput from '../../ColorInput';
import RecognitionIconPreview from '../../RecognitionIcon/RecognitionIconPreview';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { createFormData } from '../../../utils/data.utils';
import { scrollToError } from '../../../utils';

type ModalEditRecognitionTypeProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  editRecognitionType: (id: string, data: any) => void;
  recognitionTypeData: RecognitionDataType;
  recognitionTypeError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
};

type InitValuesEditType = {
  id: string;
  isDeleteImage: boolean;
} & InitValuesType;

function ModalEditRecognitionType({
  isOpen,
  onCloseRequest,
  editRecognitionType,
  recognitionTypeData,
  recognitionTypeError,
  isLoading,
  resetErrors,
}: ModalEditRecognitionTypeProps) {
  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldError,
  } = useFormik<InitValuesEditType>({
    initialValues: { ...recognitionTypeData, isDeleteImage: false },
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: RECOGNITION_TYPE_VALIDATION_SCHEMA,
    onSubmit: async data => {
      data.backgroundColor = data.backgroundColor.substr(-6);
      const values = { dto: { backgroundColor: data.backgroundColor, name: data.name } };
      if (!isEqual(recognitionTypeData.icon, data.icon) && !data.isDeleteImage) {
        merge(values, { icon: data.icon });
      }

      if (data.isDeleteImage) {
        merge(values, { isDeleteImage: true });
      }

      return editRecognitionType(recognitionTypeData?.id, { data: createFormData(values), callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(recognitionTypeError, setFieldError);

  const [filePath, setFilePath] = useState('');

  const handleChangeColor = useCallback(event => {
    setFieldValue('backgroundColor', event.target.value);
  }, []);

  const handleChangeFile = useCallback(event => {
    setFieldValue('icon', event.target.files[0]);
    setFieldValue('isDeleteImage', false);
  }, []);

  const handleReset = useCallback(() => {
    setFieldValue('icon', '');
    setFilePath('');
    setFieldValue('isDeleteImage', true);
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const colorInput = (
    <ColorInput
      name={'backgroundColor'}
      id={'color' + values.id}
      onChange={handleChangeColor}
      externalClass={'form__input-color'}
      defaultValue={`${values.backgroundColor.length == 6 ? '#' + values.backgroundColor : values.backgroundColor}`}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editRecognitionTypeTitle)}
      classNameModal="center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper recognition-type">
          <div className="form__input-block form__input-block--recognition-type-name">
            <Input
              id={'name' + values.id}
              name={'name'}
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values?.name || ''}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
              control={colorInput}
            />
          </div>
          <div className="form__input-block flex">
            <RecognitionIconPreview
              icon={values.icon}
              color={`${values.backgroundColor.length == 6 ? '#' + values.backgroundColor : values.backgroundColor}`}
            />
            <div className={'recognition__file-input'}>
              <label className={'label--recognition-icon'}>
                <FormattedMessage {...messages.iconLabel} />
              </label>
              <span onClick={handleReset} className={'recognition__icon-reset'}>
                <FormattedMessage {...messages.removeButton} />
              </span>
              <FileInput
                name={'iconFile' + values.id}
                onChange={handleChangeFile}
                defaultValue={filePath}
                externalClass={'recognition-icon'}
                accept=".svg"
              />
            </div>
          </div>
        </div>
        <ErrorMessage>{recognitionTypeError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditRecognitionType;
