import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { GRADES_VALIDATION_SCHEMA } from '../../../enums/libraries.enum';
import Select from '../../Select';
import { find } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { scrollToError } from '../../../utils';

type ModalNewGradeProps = {
  onCloseRequest: () => void;
  createNewGrade: (data: any) => void;
  gradeError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  companyPositions: any[];
  isOpen: boolean;
  resetErrors: () => void;
};

function ModalNewGrade({
  onCloseRequest,
  createNewGrade,
  gradeError,
  isLoading,
  companyPositions,
  isOpen,
  resetErrors,
}: ModalNewGradeProps) {
  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldValue, setFieldError } = useFormik({
    initialValues: {
      name: '',
      positions: [],
      level: 0,
      positionIds: [],
    },
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: GRADES_VALIDATION_SCHEMA,
    onSubmit: data => {
      return createNewGrade({ data, callback: resetAndExit, params: { forceUpdate: false } });
    },
  });

  useSetFieldsErrors(gradeError, setFieldError);

  const [modalConfirmPositionsMoveIsOpen, setModalConfirmPositionsMoveIsOpen] = useState(false);

  useEffect(() => {
    if (gradeError === 'For every company position must be only one grade') {
      setModalConfirmPositionsMoveIsOpen(true);
    }
  }, [gradeError]);

  const onConfirmNewPositionsMove = () => {
    createNewGrade({ data: values, callback: resetAndExit, params: { forceUpdate: true } });
    setModalConfirmPositionsMoveIsOpen(false);
  };

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const companyPositionsOptions = useMemo(
    () => companyPositions?.map(({ id, displayName }) => ({ label: displayName, value: id })),
    [companyPositions],
  );

  const handleChangeCompanyPositions = useCallback(value => {
    setFieldValue(
      'positions',
      value.map((item: any) => ({ displayName: item.label, id: item.value, priority: item.priority })),
    );
    setFieldValue(
      'positionIds',
      value.map((item: any) => item.value),
    );
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.newGradeTitle)}
        size={'small'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Input
                id={'edit-name'}
                name={'name'}
                label={intl.formatMessage(messages.nameColumn)}
                defaultValue={values?.name || ''}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
            <div className="form__input-block">
              <Select
                label={intl.formatMessage(messages.positionsInput)}
                name={`positions`}
                options={companyPositionsOptions}
                handleChange={handleChangeCompanyPositions}
                isSearchable={true}
                isMulti={true}
                value={values.positions?.map((item: { id: string; displayName: string; priority: number }) => ({
                  label: `${find(companyPositionsOptions, el => el.value === item.id)?.label}`,
                  value: item.id,
                  priority: item.priority,
                }))}
              />
            </div>
          </div>
          <ErrorMessage>{gradeError}</ErrorMessage>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              onClick={resetAndExit}
              type={'button'}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={modalConfirmPositionsMoveIsOpen}
        onRequestClose={() => setModalConfirmPositionsMoveIsOpen(false)}
        title={intl.formatMessage(messages.confirmEditGradeMessage)}
        size={'small'}
      >
        <div className={'form__buttons'}>
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            onClick={() => setModalConfirmPositionsMoveIsOpen(false)}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} onClick={() => onConfirmNewPositionsMove()}>
            <FormattedMessage {...messages.addButton} />
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ModalNewGrade;
