import { nameColumn } from './../../i18n/defaultMessage';
import { defineMessages } from 'react-intl';
import { cancelButton, deleteButton, saveButton, deleteModalWithItemNameTitle } from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  saveButton,
  nameColumn,
  newForeignLanguageTitle: {
    id: 'resumes.foreign.languages.modal.new.foreign.languages.title.new.foreign.language',
    defaultMessage: 'New Foreign Language',
  },
  editForeignLanguageTitle: {
    id: 'resumes.foreign.languages.modal.edit.foreign.languages.title.edit.foreign.language',
    defaultMessage: 'Edit Foreign Language',
  },
});
