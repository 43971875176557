import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import capitalize from 'lodash-es/capitalize';
import Modal from '../../Modal';
import Input from '../../Input';
import { UserInfo, USER_INFO_VALIDATION_SCHEMA } from '../../../enums/users.enum';
import Button from '../../Button';
import Select from '../../Select';
import Icon from '../../Icon';
import DurationPicker from '../../Dropdown';
import MessengerIcon from '../MessengerIcon';
import ChipInput from '../../ChipInput';
import { MESSENGER_TYPES } from '../../../constants/users.constants';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { DepartmentsInfoType } from '../../../types/libraries';
import { scrollToError } from '../../../utils';

type ModalInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  userInfo: UserInfo;
  departments: DepartmentsInfoType[];
  offices: { id: string; name: string }[];
  jiraUsers: { id: number; username: string }[];
  loading?: boolean;
  editingOwnData: boolean;
  requestError: string | RejectValueErrors[] | null;
};

function ModalInfo({
  isOpen,
  onClose,
  onSubmit,
  userInfo,
  departments,
  offices,
  jiraUsers,
  loading,
  requestError,
  editingOwnData,
}: ModalInfoProps) {
  const intl = useIntl();

  const { values, handleChange, setFieldValue, handleSubmit, setFieldError, errors, touched } = useFormik({
    initialValues: new UserInfo(userInfo),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: USER_INFO_VALIDATION_SCHEMA,
    onSubmit: data => onSubmit(data.userUpdateInfo),
  });

  useSetFieldsErrors(requestError, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleAddMsgContact = useCallback(
    messengerType => {
      setFieldValue('messengerContacts', [...values.messengerContacts, { messengerType, ref: '' }]);
    },
    [values],
  );

  const handleRemoveMsgContact = useCallback(
    i => () => {
      const newContacts = [...values.messengerContacts];
      newContacts.splice(i, 1);
      setFieldValue('messengerContacts', newContacts);
    },
    [values],
  );

  const departmentOptions = useMemo(
    () => departments.map(({ id, displayName }) => ({ label: displayName, value: id })),
    [departments],
  );

  const officeOptions = useMemo(() => offices.map(({ id, name }) => ({ label: name, value: id })), [offices]);

  const jiraAccOptions = useMemo(() => jiraUsers.map(({ id, username }) => ({ label: username, value: id })), [
    jiraUsers,
  ]);

  const messengerContactOptions = useMemo(
    () =>
      Object.keys(MESSENGER_TYPES).map(key => ({
        label: (
          <span>
            <MessengerIcon iconName={key} externalClass="dropdown__list-item__icon" />
            {capitalize(key)}
          </span>
        ),
        handler: () => handleAddMsgContact(key),
      })),
    [values],
  );

  const departmentValue = useMemo(
    () => (values.department?.id ? departmentOptions.find(({ value }) => value === values.department?.id) : null),
    [departmentOptions, values],
  );

  const officeValue = useMemo(
    () => (values.office?.id ? officeOptions.find(({ value }) => value === values.office?.id) : null),
    [officeOptions, values],
  );

  const jiraAccValue = useMemo(
    () => (values.jiraUser ? jiraAccOptions.find(({ value }) => value === values.jiraUser) : null),
    [jiraAccOptions, values],
  );

  const emailsValue = useMemo(() => values.emails?.map(({ email }) => ({ label: email, value: email })), [values]);

  // @ts-ignore
  const emailsError = useMemo(() => errors?.emails?.reduce((acc, val) => acc || val?.email, null), [errors]);
  // @ts-ignore
  const phonesError = useMemo(() => errors?.phones?.reduce((acc, val) => acc || val, null), [errors]);

  const phonesValue = useMemo(() => values.phones?.map(phone => ({ label: phone, value: phone })), [values]);

  const handleDepartment = useCallback(
    ({ label, value }) => setFieldValue('department', value && { id: value, displayName: label }),
    [],
  );

  const handleOffice = useCallback(
    ({ label, value }) => setFieldValue('office', value && { id: value, name: label }),
    [],
  );

  const handleJiraAcc = useCallback(({ label, value }) => {
    setFieldValue('jiraUser', value);
    setFieldValue('jiraUsername', value && label);
  }, []);

  const handleEmails = useCallback(
    newValues =>
      setFieldValue(
        'emails',
        newValues.map(({ label }: { label: string }) => ({ email: label, primary: false })),
      ),
    [],
  );

  const handlePhones = useCallback(
    newValues =>
      setFieldValue(
        'phones',
        newValues.map(({ label }: { label: string }) => label),
      ),
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={intl.formatMessage(messages.editProfilelInfoTitle)}
      classNameModal="modal--edit-profile"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__group">
            <div className="form__grid-wrapper">
              <Input
                name="firstName"
                label={intl.formatMessage(messages.firstNameInput)}
                bold
                defaultValue={values.firstName}
                onChange={handleChange}
                hasError={hasError('firstName')}
                errorMessage={errors?.firstName}
              />
              <Input
                name="secondName"
                label={intl.formatMessage(messages.secondNameInput)}
                bold
                defaultValue={values.secondName}
                onChange={handleChange}
                hasError={hasError('secondName')}
                errorMessage={errors?.secondName}
              />
            </div>
          </div>
          <div className="form__group">
            <div className="form__grid-wrapper username">
              <Input
                name="username"
                label={intl.formatMessage(messages.loginInput)}
                bold
                defaultValue={values.username}
                onChange={handleChange}
                hasError={hasError('username')}
                errorMessage={errors?.username}
              />
            </div>
          </div>
          {!editingOwnData && (
            <>
              <Select
                label={intl.formatMessage(messages.departmentLabel)}
                iconName="building"
                options={departmentOptions}
                value={departmentValue}
                handleChange={handleDepartment}
                hasError={hasError('department')}
                // @ts-ignore
                errorMessage={errors?.department}
                isClearable
              />
              <Select
                label={intl.formatMessage(messages.officeLabel)}
                iconName="map-marker"
                options={officeOptions}
                value={officeValue}
                handleChange={handleOffice}
                hasError={hasError('office')}
                // @ts-ignore
                errorMessage={errors?.office}
                isClearable
              />
              <Select
                label={intl.formatMessage(messages.jiraInput)}
                iconName="jira"
                iconComponent="MessengerIcon"
                options={jiraAccOptions}
                value={jiraAccValue}
                handleChange={handleJiraAcc}
                hasError={hasError('jiraUser')}
                errorMessage={errors?.jiraUser}
                isClearable
              />
              <Input
                label={intl.formatMessage(messages.emailLabel)}
                name="email"
                iconName="envelope"
                wrapperClass="page__profile-contact--email"
                defaultValue={values.email}
                onChange={handleChange}
                hasError={hasError('email')}
                errorMessage={errors?.email}
              />
            </>
          )}
          <ChipInput
            label={intl.formatMessage(messages.otherEmailsInput)}
            iconName="envelope"
            externalClass="page__profile-contact--email"
            value={emailsValue}
            handleChange={handleEmails}
            hasError={hasError('emails')}
            errorMessage={emailsError}
          />
          <ChipInput
            label={intl.formatMessage(messages.phonesLabel)}
            iconName="phone"
            externalClass="page__profile-contact--phones"
            value={phonesValue}
            handleChange={handlePhones}
            hasError={hasError('phones')}
            errorMessage={phonesError}
          />
          <div className="form__group">
            <div className={'form__grid-wrapper'}>
              {values.messengerContacts?.map(({ messengerType, ref }, i) => (
                <Input
                  key={i.toString()}
                  id={`${messengerType}-${i}`}
                  label={capitalize(messengerType)}
                  name={`messengerContacts[${i}].ref`}
                  defaultValue={ref}
                  onChange={handleChange}
                  iconName={messengerType}
                  iconComponent="MessengerIcon"
                  control={
                    <button type="button" className="form__input-control" onClick={handleRemoveMsgContact(i)}>
                      <Icon iconName="times-circle" />
                    </button>
                  }
                  hasError={hasError(`messengerContacts[${i}]`)}
                  errorMessage={
                    get(errors, ['messengerContacts', i, 'ref']) ||
                    get(errors, ['messengerContacts', i, 'messengerType'])
                  }
                  wrapperClass="messenger-contacts"
                />
              ))}
              <div className="form__dropdown-wrapper">
                <DurationPicker
                  dropdownToggle={
                    <>
                      <Icon iconName="plus" externalClass="form__icon-btn-add" />
                      <FormattedMessage {...messages.addButton} />
                      <Icon iconName="select-arrow" />
                    </>
                  }
                  dropdownToggleClass="form__btn-add-group"
                  dropdownList={messengerContactOptions}
                  placement="bottom"
                />
              </div>
            </div>
          </div>
        </div>
        <ErrorMessage>{requestError}</ErrorMessage>
        <div className="form__buttons">
          <Button type="button" onClick={onClose} color="gray" externalClass="button--modal">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button type="submit" externalClass="button--modal" loading={loading} disabled={loading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalInfo;
