import classNames from 'classnames';
import React from 'react';
import { DecimalSeparatorEnum, MatchOptionsValues } from '../../../utils';
import FormItem from './FormItem';

type MatchFormProps = {
  parsedCsvData: Papa.ParseStepResult<any> | null;
  matchFormValues: {
    matchInfo: {
      field: string;
      value: MatchOptionsValues;
    }[];
  };
  decimalSeparator: DecimalSeparatorEnum;
  setMatchField: (field: string, value: any) => void;
  setDecimalSeparator: (separator: DecimalSeparatorEnum) => void;
};

function MatchForm({
  parsedCsvData,
  decimalSeparator,
  matchFormValues,
  setMatchField,
  setDecimalSeparator,
}: MatchFormProps) {
  const wrapperStyle = classNames('transactions__match-form-wrapper', { empty: !parsedCsvData });

  return (
    <div className={wrapperStyle}>
      {parsedCsvData &&
        parsedCsvData.meta.fields?.map((field, index) => {
          const infoValue = matchFormValues.matchInfo.find(info => info.field === field)?.value;
          return (
            <FormItem
              key={`${field}-${index}`}
              index={index}
              field={field}
              matchInfo={matchFormValues.matchInfo}
              decimalSeparator={infoValue === MatchOptionsValues.AMOUNT ? decimalSeparator : null}
              setMatchField={setMatchField}
              setDecimalSeparator={setDecimalSeparator}
            />
          );
        })}
    </div>
  );
}

export default React.memo(MatchForm);
