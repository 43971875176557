import { get } from 'lodash-es';
import { getDateParams, getDateWithTimeZoneParams } from '../../utils/params.utils';
import { ReportsRoundingMode } from '../../constants/reports.constants';

export class EmployeesReportParams {
  offices: string[];
  portalUserIds: string[];
  departmentIds: string[];
  dateFrom: string;
  roundingMode: ReportsRoundingMode | null;
  dateTo: string;
  isFoldIssuesToProject: boolean;
  isFoldSubtasksToParentTask: boolean;
  isWorkLogsRounded: boolean;
  isAllowActive: boolean | null;
  isAllowPassive: boolean | null;
  isAllowAlien: boolean | null;

  constructor(params?: unknown) {
    this.offices = get(params, 'offices', []);
    this.portalUserIds = get(params, 'portalUserIds', []);
    this.departmentIds = get(params, 'departmentIds', []);
    this.dateFrom = get(params, 'dateFrom', getDateParams());
    this.dateTo = get(params, 'dateTo', getDateParams({ getFromStart: false }));
    this.isFoldIssuesToProject = get(params, 'isFoldIssuesToProject', false);
    this.isFoldSubtasksToParentTask = get(params, 'isFoldSubtasksToParentTask', false);
    this.isWorkLogsRounded = get(params, 'isWorkLogsRounded', false);
    this.isAllowActive = get(params, 'isAllowActive', true);
    this.isAllowPassive = get(params, 'isAllowPassive', false);
    this.isAllowAlien = get(params, 'isAllowAlien', false);
    this.roundingMode = get(params, 'roundingMode', null);
  }
}

export class ProjectsReportParams {
  portalUserIds: string[];
  jiraProjectIds: string[];
  dateFrom: string;
  dateTo: string;
  roundingMode: ReportsRoundingMode | null;
  isFoldSubtasks: boolean;
  isGroupByProject: boolean;
  isGroupByComponents: boolean;
  isGroupByEmployee: boolean;
  isWorkLogsRounded: boolean;
  isAllowActive: boolean | null;
  isAllowPassive: boolean | null;
  isAllowAlien: boolean | null;

  constructor(params?: unknown) {
    this.portalUserIds = get(params, 'portalUserIds', []);
    this.jiraProjectIds = get(params, 'jiraProjectIds', []);
    this.dateFrom = get(params, 'dateFrom', getDateParams());
    this.dateTo = get(params, 'dateTo', getDateParams({ getFromStart: false }));
    this.isFoldSubtasks = get(params, 'isFoldSubtasks', false);
    this.isGroupByProject = get(params, 'isGroupByProject', true);
    this.isGroupByComponents = get(params, 'isGroupByComponents', false);
    this.isGroupByEmployee = get(params, 'isGroupByEmployee', false);
    this.isWorkLogsRounded = get(params, 'isWorkLogsRounded', false);
    this.roundingMode = get(params, 'roundingMode', null);
    this.isAllowActive = get(params, 'isAllowActive', true);
    this.isAllowPassive = get(params, 'isAllowPassive', false);
    this.isAllowAlien = get(params, 'isAllowAlien', false);
  }
}

export class EmployeesTimesheetReportParams {
  offices: string[];
  portalUserIds: string[];
  departmentIds: string[];
  dateFrom: string;
  dateTo: string;
  isAllowActive: boolean | null;
  isAllowPassive: boolean | null;
  jiraProjectIds: string[];

  constructor(params?: unknown) {
    this.offices = get(params, 'offices', []);
    this.portalUserIds = get(params, 'portalUserIds', []);
    this.departmentIds = get(params, 'departmentIds', []);
    this.dateFrom = get(params, 'dateFrom', getDateParams());
    this.dateTo = get(params, 'dateTo', getDateParams({ getFromStart: false }));
    this.isAllowPassive = get(params, 'isAllowPassive', false);
    this.isAllowActive = get(params, 'isAllowActive', true);
    this.jiraProjectIds = get(params, 'jiraProjectIds', []);
  }
}

export class EmployeesIssueTypesParams {
  dateFrom: string;
  dateTo: string;
  departmentIds: string[];
  offices: string[];
  portalUserIds: string[];
  isGroupByProject: boolean;
  isAllowActive: boolean | null;
  isAllowPassive: boolean | null;

  constructor(params?: unknown) {
    this.offices = get(params, 'offices', []);
    this.portalUserIds = get(params, 'portalUserIds', []);
    this.departmentIds = get(params, 'departmentIds', []);
    this.dateFrom = get(params, 'dateFrom', getDateParams());
    this.dateTo = get(params, 'dateTo', getDateParams({ getFromStart: false }));
    this.isGroupByProject = get(params, 'isGroupByProject', true);
    this.isAllowPassive = get(params, 'isAllowPassive', false);
    this.isAllowActive = get(params, 'isAllowActive', true);
  }
}

export class UsersHoursAbsencesReportParams {
  dateFrom: string;
  dateTo: string;
  departmentIds: string[];
  portalUserIds: string[];
  offices: string[];
  isGroupByMonths: boolean;
  isAllowActive: boolean | null;
  isAllowPassive: boolean | null;

  constructor(params?: unknown) {
    this.offices = get(params, 'offices', []);
    this.portalUserIds = get(params, 'portalUserIds', []);
    this.departmentIds = get(params, 'departmentIds', []);
    this.dateFrom = get(params, 'dateFrom', getDateParams());
    this.dateTo = get(params, 'dateTo', getDateParams({ getFromStart: false }));
    this.isGroupByMonths = get(params, 'isGroupByMonths', false);
    this.isAllowPassive = get(params, 'isAllowPassive', false);
    this.isAllowActive = get(params, 'isAllowActive', true);
  }
}

export class AbsencePeriodsParams {
  dateTimeFrom: string;
  dateTimeTo: string;
  departments: string[];
  eventTypes: string[];
  offices: string[];
  targetEmployees: string[];
  isAllowActive: boolean | null;
  isAllowPassive: boolean | null;

  constructor(params?: unknown) {
    this.offices = get(params, 'offices', []);
    this.eventTypes = get(params, 'eventTypes', []);
    this.departments = get(params, 'departments', []);
    this.dateTimeFrom = get(params, 'dateTimeFrom', getDateWithTimeZoneParams({ withTime: false }));
    this.dateTimeTo = get(params, 'dateTimeTo', getDateWithTimeZoneParams({ getFromStart: false, withTime: false }));
    this.targetEmployees = get(params, 'targetEmployees', []);
    this.isAllowPassive = get(params, 'isAllowPassive', false);
    this.isAllowActive = get(params, 'isAllowActive', true);
  }
}

export class ActiveInactiveHoursReportParams {
  dateFrom: string;
  dateTo: string;
  departmentIds: string[];
  portalUserIds: string[];
  offices: string[];
  isAllowActive: boolean | null;
  isAllowPassive: boolean | null;
  isGroupByUser: boolean;

  constructor(params?: unknown) {
    this.offices = get(params, 'offices', []);
    this.portalUserIds = get(params, 'portalUserIds', []);
    this.departmentIds = get(params, 'departmentIds', []);
    this.dateFrom = get(params, 'dateFrom', getDateParams());
    this.dateTo = get(params, 'dateTo', getDateParams({ getFromStart: false }));
    this.isAllowPassive = get(params, 'isAllowPassive', false);
    this.isAllowActive = get(params, 'isAllowActive', false);
    this.isGroupByUser = get(params, 'isGroupByUser', false);
  }
}
