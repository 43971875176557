import { QueryParamsType } from './../utils/params.utils';
import request from '../utils/request.utils';
import * as paramsTypes from '../enums/params/competencies.params';

export function createNewCompetence(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/competencies',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getCompetence(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/competencies/${id}`,
  };
  return request(options);
}

export function getCompetenciesList(params?: QueryParamsType<paramsTypes.CompetenciesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/competencies',
    params,
  };
  return request(options);
}

export function deleteCompetence(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/competencies/${id}`,
  };
  return request(options);
}

export function editCompetence(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/competencies/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getCompetenceTypesList(params?: QueryParamsType<paramsTypes.CompetenciesTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/competenceTypes',
    params,
  };
  return request(options);
}

export function createCompetenceType(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/competenceTypes',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}
export function editCompetenceType(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/competenceTypes/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteCompetenceType(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/competenceTypes/${id}`,
  };
  return request(options);
}

export function getFilteredAssessments(params?: QueryParamsType<paramsTypes.AssessmentsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/assessments',
    params,
  };
  return request(options);
}

export function createNewSkillQuestions(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/skills/questions',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteAssessment(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/assessments/${id}`,
  };
  return request(options);
}

export function getSkillQuestions(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/skills/${id}`,
  };
  return request(options);
}

export function createNewAssessment(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/assessments',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editAssessment(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/assessments/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getAssessment(data: any) {
  const options: RequestConfig = {
    method: 'get',
    url: `/assessments/${data}`,
    params: {},
  };
  return request(options);
}

export function getLastAssessmentResults(data: any) {
  const options: RequestConfig = {
    method: 'get',
    url: `/assessments/results/${data.userId}/${data.competenceId}/latest`,
    params: { size: 1000 },
  };
  return request(options);
}

export function getSkillQuestionsList(params?: QueryParamsType<paramsTypes.SkillsQuestionsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/skills',
    params,
  };
  return request(options);
}
export function getSkillQuestionsFilters() {
  const options: RequestConfig = {
    method: 'get',
    url: '/skills',
    params: {
      size: 1000,
    },
  };
  return request(options);
}

export function getAssessmentsFilters() {
  const options: RequestConfig = {
    method: 'get',
    url: `/assessments`,
    params: {
      size: 1000,
    },
  };
  return request(options);
}

export function editSkillQuestions(data: any, skillId: string) {
  const options: RequestConfig = {
    method: 'put',
    url: `/skills/${skillId}/questions`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getCompetenciesPercentage(userId: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/competencies/user/${userId}/competences`,
    params: {
      size: 1000,
      sort: 'competenceName',
    },
  };
  return request(options);
}

export function getSpecializationsPercentage(userId: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/competencies/user/${userId}/specializations`,
    params: {
      size: 1000,
      sort: 'specializationName',
    },
  };
  return request(options);
}

export function getUserCompetenceAssessments({ userId, competenceId }: { userId: string; competenceId: string }) {
  const options: RequestConfig = {
    method: 'get',
    url: `/assessments/${userId}/${competenceId}`,
    params: {
      size: 1000,
      sort: 'competenceName',
    },
  };
  return request(options);
}

export function getLevelsPercentage(userId: string, competenceId: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/competencies/user/${userId}/levels`,
    params: {
      size: 1000,
      competenceIds: [competenceId],
    },
  };
  return request(options);
}
