import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import Checkbox from '../../Checkbox';
import { SavedFilter, SAVED_FILTER_VALIDATION_SCHEMA } from '../../../enums/filters.enum';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { UPDATE_PUBLIC_FILTER } from '../../../constants/policies.constants';
import { checkPolicies } from '../../../utils/policies.utils';
import { UserPolicy } from '../../../enums/policies.enum';

type ModalEditSavedFilterProps = {
  error: string | null | RejectValueErrors[];
  isLoading: boolean;
  isOpen: boolean;
  savedFilter: SavedFilter;
  onCloseRequest: () => void;
  editNewSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  authUserId: string;
  userPolicies: UserPolicy[];
};

function ModalEditSavedFilter({
  error,
  isLoading,
  isOpen,
  savedFilter,
  onCloseRequest,
  editNewSavedFilter,
  authUserId,
  userPolicies,
}: ModalEditSavedFilterProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: savedFilter,
    validateOnChange: false,
    validationSchema: SAVED_FILTER_VALIDATION_SCHEMA,
    onSubmit: data => {
      return editNewSavedFilter({ data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleIsPublicChange = useCallback(e => {
    setFieldValue('isPublic', e.target.checked);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editModalTitle)}
      classNameModal="center"
    >
      <form className="modal__form form filter-control-modal" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <Input
            name={'name'}
            label={intl.formatMessage(messages.nameColumn)}
            defaultValue={values.name}
            onChange={handleChange}
            hasError={hasError('name')}
            errorMessage={errors?.name}
            disabled={authUserId !== values.authorId}
          />
          {checkPolicies([UPDATE_PUBLIC_FILTER], userPolicies) && (
            <Checkbox
              externalClass="form__checkbox-capitalazie-label"
              onChange={handleIsPublicChange}
              id="isPublic"
              label={intl.formatMessage(messages.shareWithOrganizationLabel)}
              checked={values.isPublic}
            />
          )}
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            type={'button'}
            onClick={onCloseRequest}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditSavedFilter;
