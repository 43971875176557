import * as paramsTypes from './../enums/params/planning.params';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/planning.constants';
import * as api from '../api/planning.api';
import * as apiUsers from '../api/users.api';
import { AnyAction } from 'redux';
import { getQueryParams } from '../utils/params.utils';
import { ActiveEmployeesParams } from '../enums/params/planning.params';

function* getResourcesList() {
  const params: paramsTypes.ResourcesParams = yield select((state: RootState) => state.planning.resourcesParams);

  try {
    const { success, results } = yield call(api.getResourcesList, params);
    if (success) {
      yield put({
        type: ActionTypes.GET_RESOURCES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_RESOURCES_LIST_FAILURE, payload: error });
  }
}

function* getUserTabResourcesList() {
  const params: paramsTypes.ResourcesParams = yield select((state: RootState) => state.planning.userTabResourceParams);
  try {
    const { success, results } = yield call(api.getResourcesList, params);
    if (success) {
      yield put({
        type: ActionTypes.GET_RESOURCES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_RESOURCES_LIST_FAILURE, payload: error });
  }
}

function* createNewResources({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewResources, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_RESOURCES_SUCCESS });
      payload.callback();
      yield call(getResourcesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_RESOURCES_FAILURE, payload: error });
  }
}

function* getCurrentResource({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getCurrentResource, payload.data);
    if (success) {
      yield put({ type: ActionTypes.GET_CURRENT_RESOURCE_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CURRENT_RESOURCE_FAILURE, payload: error });
  }
}

function* getUserHoursList() {
  const params: paramsTypes.UserHoursParams = yield select((state: RootState) => state.planning.userHoursParams);
  try {
    const { success, results } = yield call(api.getUserHoursList, params);
    if (success) {
      yield put({
        type: ActionTypes.GET_USER_HOURS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_HOURS_LIST_FAILURE, payload: error });
  }
}

function* getProjectHoursList() {
  const params: paramsTypes.ProjectHoursParams = yield select((state: RootState) => state.planning.projectHoursParams);

  try {
    const { success, results } = yield call(api.getProjectHoursList, params);
    if (success) {
      yield put({
        type: ActionTypes.GET_PROJECT_HOURS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROJECT_HOURS_LIST_FAILURE, payload: error });
  }
}

function* getProjectGroupsList() {
  const params: paramsTypes.ProjectGroupsParams = yield select(
    (state: RootState) => state.planning.projectGroupsParams,
  );
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getProjectGroupsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROJECT_GROUPS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROJECT_GROUPS_LIST_FAILURE, payload: error });
  }
}

function* createProjectGroup({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewProjectGroup, payload.data, payload.params);

    if (success) {
      yield put({ type: ActionTypes.POST_PROJECT_GROUP_SUCCESS });
      payload.callback();
      yield call(getProjectGroupsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_PROJECT_GROUP_FAILURE, payload: error });
  }
}

function* deleteProjectGroup({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteProjectGroup, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_PROJECT_GROUP_SUCCESS });
      payload.callback();
      yield call(getProjectGroupsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_PROJECT_GROUP_FAILURE, payload: error });
  }
}

function* editProjectGroup({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editProjectGroup, payload.data, payload.params);

    if (success) {
      yield put({ type: ActionTypes.PUT_PROJECT_GROUP_SUCCESS });
      payload.callback();
      yield call(getProjectGroupsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROJECT_GROUP_FAILURE, payload: error });
  }
}

function* getActiveEmployees() {
  const params: ActiveEmployeesParams = yield select((state: RootState) => state.planning.activeEmployeesParams);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(apiUsers.getUsers, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_ACTIVE_EMPLOYEES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ACTIVE_EMPLOYEES_LIST_FAILURE, payload: error });
  }
}

function* editActiveEmployees({ payload }: AnyAction) {
  try {
    const { success } = yield call(apiUsers.updateUserPartialInfo, payload.id, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_ACTIVE_EMPLOYEES_LIST_SUCCESS });
      yield call(getActiveEmployees);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_ACTIVE_EMPLOYEES_LIST_FAILURE, payload: error });
  }
}

export default function* planningSaga() {
  yield all([
    takeEvery(ActionTypes.GET_RESOURCES_LIST_REQUEST, getResourcesList),
    takeEvery(ActionTypes.POST_RESOURCES_REQUEST, createNewResources),
    takeEvery(ActionTypes.SET_RESOURCES_PARAMS_REQUEST, getResourcesList),
    takeEvery(ActionTypes.SET_USER_TAB_RESOURCES_PARAMS_REQUEST, getUserTabResourcesList),
    takeEvery(ActionTypes.GET_CURRENT_RESOURCE_REQUEST, getCurrentResource),
    takeEvery(ActionTypes.GET_USER_HOURS_LIST_REQUEST, getUserHoursList),
    takeEvery(ActionTypes.SET_USER_HOURS_PARAMS_REQUEST, getUserHoursList),
    takeEvery(ActionTypes.GET_PROJECT_HOURS_LIST_REQUEST, getProjectHoursList),
    takeEvery(ActionTypes.SET_PROJECT_HOURS_PARAMS_REQUEST, getProjectHoursList),
    takeEvery(ActionTypes.GET_PROJECT_GROUPS_LIST_REQUEST, getProjectGroupsList),
    takeEvery(ActionTypes.SET_PROJECT_GROUPS_PARAMS_REQUEST, getProjectGroupsList),
    takeEvery(ActionTypes.POST_PROJECT_GROUP_REQUEST, createProjectGroup),
    takeEvery(ActionTypes.DELETE_PROJECT_GROUP_REQUEST, deleteProjectGroup),
    takeEvery(ActionTypes.PUT_PROJECT_GROUP_REQUEST, editProjectGroup),
    takeEvery(ActionTypes.GET_ACTIVE_EMPLOYEES_LIST_REQUEST, getActiveEmployees),
    takeEvery(ActionTypes.SET_ACTIVE_EMPLOYEES_PARAMS_REQUEST, getActiveEmployees),
    takeEvery(ActionTypes.PUT_ACTIVE_EMPLOYEES_LIST_REQUEST, editActiveEmployees),
  ]);
}
