import { defineMessages } from 'react-intl';
import { nameColumn, exportToXLSLabel, exportToPDFLabel, copyURLLabel, totalRow } from '../../i18n/defaultMessage';

export default defineMessages({
  exportToXLSLabel,
  exportToPDFLabel,
  copyURLLabel,
  nameColumn,
  totalRow,
  pageTitle: {
    id: 'reports.members.issue.types.title.members.and.issue.types',
    defaultMessage: 'Members and Issue Types',
  },
  membersProjectsColumn: {
    id: 'reports.employees.issue.types.column.members.projects',
    defaultMessage: 'Members / Projects',
  },
  issueTypeColumn: {
    id: 'reports.employees.issue.types.column.issue.type',
    defaultMessage: 'Issue Type',
  },
  issueCountColumn: {
    id: 'reports.employees.issue.types.column.issue.type',
    defaultMessage: 'Issue Count',
  },
  workedColumn: {
    id: 'reports.employees.issue.types.column.worked',
    defaultMessage: 'Worked Hours',
  },
});
