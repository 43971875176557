import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  addButton,
  nameColumn,
  employeesLabel,
  startDateLabel,
  endDateLabel,
  totalRow,
  officesLabel,
  departmentsLabel,
  memberLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  addButton,
  nameColumn,
  employeesLabel,
  startDateLabel,
  endDateLabel,
  totalRow,
  officesLabel,
  departmentsLabel,
  memberLabel,
  newSalaryTitle: {
    id: 'finance.salary.modal.new.salary.title.new.salary',
    defaultMessage: 'New Salary',
  },
  editSalaryTitle: {
    id: 'finance.salary.modal.edit.salary.title.edit.salary',
    defaultMessage: 'Edit Salary',
  },
  deleteModalTitle: {
    id: 'finance.salary.modal.delete.salary.title.delete.salary',
    defaultMessage: 'Delete Salary',
  },
  payLabel: {
    id: 'finance.salary.modal.new.salary.label.pay',
    defaultMessage: 'Pay',
  },
  taxLabel: {
    id: 'finance.salary.modal.new.salary.label.tax',
    defaultMessage: 'Tax',
  },
  deleteFutureMonthsLabel: {
    id: 'finance.salary.modal.delete.salary.label.also.delete.all.future.months',
    defaultMessage: 'Also Delete all future months',
  },
  periodLabel: {
    id: 'finance.salary.modal.label.period',
    defaultMessage: 'Period:',
  },
});
