export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const KEYCLOAK_SIGN_IN_REQUEST = 'KEYCLOAK_SIGN_IN_REQUEST';
export const KEYCLOAK_SIGN_IN_SUCCESS = 'KEYCLOAK_SIGN_IN_SUCCESS';
export const KEYCLOAK_SIGN_IN_FAILURE = 'KEYCLOAK_SIGN_IN_FAILURE';

export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';

export const GET_CURRENT_USER_INFO_REQUEST = 'GET_CURRENT_USER_INFO_REQUEST';
export const GET_CURRENT_USER_INFO_SUCCESS = 'GET_CURRENT_USER_INFO_SUCCESS';
export const GET_CURRENT_USER_INFO_FAILURE = 'GET_CURRENT_USER_INFO_FAILURE';

export const REFRESH_USER_AUTH_TOKEN = 'REFRESH_USER_AUTH_TOKEN';
