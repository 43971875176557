import { defineMessages } from 'react-intl';
import {
  employeesLabel,
  memberLabel,
  competenciesTitle,
  deleteButton,
  cancelButton,
  saveButton,
  departmentsMembersLabel,
  officesLabel,
  departmentsLabel,
  activeLabel,
  inactiveLabel,
  statusLabel,
  totalRow,
} from '../../i18n/defaultMessage';

export default defineMessages({
  employeesLabel,
  memberLabel,
  competenciesTitle,
  deleteButton,
  cancelButton,
  saveButton,
  departmentsMembersLabel,
  officesLabel,
  departmentsLabel,
  activeLabel,
  inactiveLabel,
  statusLabel,
  totalRow,
  noDataText: {
    id: 'activity.table.no.data.text',
    defaultMessage: 'During this period, no data!',
  },
  notApplicableLabel: {
    id: 'activity.table.row.no.data',
    defaultMessage: 'n/a',
  },
});
