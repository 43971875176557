import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { POLL_TEMPLATES_CATEGORY_VALIDATION_SCHEMA } from '../../../enums/questionnaires.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { scrollToError } from '../../../utils';

type ModalNewPollCategoryProps = {
  onCloseRequest: () => void;
  createNewPollTemplatesCategory: (data: any) => void;
  pollTemplatesCategoryError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalNewPollCategory({
  onCloseRequest,
  createNewPollTemplatesCategory,
  pollTemplatesCategoryError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalNewPollCategoryProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldError } = useFormik({
    initialValues: {
      name: '',
    },
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: POLL_TEMPLATES_CATEGORY_VALIDATION_SCHEMA,
    onSubmit: data => {
      return createNewPollTemplatesCategory({ data, callback: resetAndExit });
    },
  });

  useSetFieldsErrors(pollTemplatesCategoryError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.newPollTemolateTitle)}
      size={'small'}
      classNameModal="center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              id={'name'}
              name={'name'}
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values.name}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
            />
          </div>
        </div>
        <ErrorMessage>{pollTemplatesCategoryError}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} type={'button'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewPollCategory;
