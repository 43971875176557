import { OfficeInfo } from './libraries.enum';
import get from 'lodash-es/get';
import { UserPreviewInfo } from './users.enum';

export type UserPolicy = {
  id: string;
  isOfficeSpecific?: boolean;
  officeIds: string[];
  policy: {
    id: string;
    name: string;
    permissions: string[];
    policyType: string;
    isOfficeSpecificImplemented?: boolean;
  };
};

export type PolicyGroupLevel = {
  id: string;
  modules: PolicyGroupModule[];
  name: string;
};

export type PolicyGroupModule = {
  id: string;
  name: string;
  submodules: PolicyGroupSubmodule[];
};

export type PolicyGroupSubmodule = {
  id: string;
  name: string;
  policies: SubmodulePolicyType[];
  offices: (OfficeInfo & { policies: SubmodulePolicyType[] })[];
};

export type SubmodulePolicyType = {
  id: string;
  name: string;
  permissions: string[];
  isOfficeSpecificImplemented: boolean;
  policyType: PolicyTypeEnum;
  offices?: any[];
};

export enum PolicyTypeEnum {
  VIEW = 'VIEW',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

type PolicyTypesCounts = { VIEW: number; UPDATE: number; DELETE: number };

export class PolicyGroupWithUsersLevel {
  id: string;
  modules: PolicyGroupWithUsersModule[];
  name: string;
  policyTypesCounts: PolicyTypesCounts;

  constructor(level?: unknown) {
    this.id = get(level, 'id');
    this.name = get(level, 'name');
    this.policyTypesCounts = get(level, 'policyTypesCounts');
    this.modules = get(level, 'modules', []).map(
      (item: any) => new PolicyGroupWithUsersModule({ ...item, levelId: this.id, levelName: this.name }),
    );
  }
}

export class PolicyGroupWithUsersModule {
  id: string;
  name: string;
  policyTypesCounts: PolicyTypesCounts;
  levelId: string;
  levelName: string;
  submodules: PolicyGroupWithUsersSubmodule[];

  constructor(module?: unknown) {
    this.id = get(module, 'id');
    this.name = get(module, 'name');
    this.policyTypesCounts = get(module, 'policyTypesCounts');
    this.levelName = get(module, 'levelName');
    this.levelId = get(module, 'levelId');
    this.submodules = get(module, 'submodules', []).map((item: any) => ({
      ...item,
      moduleId: this.id,
      moduleName: this.name,
      levelId: this.levelId,
      levelName: this.levelName,
    }));
  }
}

export type PolicyGroupWithUsersSubmodule = {
  id: string;
  name: string;
  moduleId: string;
  moduleName: string;
  levelId: string;
  levelName: string;
  policies: PolicyType[];
};

export type PolicyType = {
  id: string;
  name: string;
  permissions: string[];
  policyType: PolicyTypeEnum;
  userIds: string[];
  isOfficeSpecificImplemented: boolean;
  offices: { office: OfficeInfo; userIds: string[] }[];
};

export type PolicyModuleUserType = {
  userId: string;
  userPreviewDto: UserPreviewInfo;
  policies: { policyType: PolicyTypeEnum }[];
  firsItem?: boolean;
};

export type PolicyGroupSubmoduleWithOffice = OfficeInfo & {
  policies: PolicyType[];
  moduleId: string;
  moduleName: string;
  levelId: string;
  levelName: string;
  submoduleName: string;
  submoduleId: string;
};
