import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import { NavLink } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { QUESTIONS_FOR_SKILL_VALIDATION_SCHEMA, SkillQuestionsInfo } from '../../../enums/competencies.enum';
import Input from '../../Input';
import Icon from '../../Icon';
import Select from '../../Select';
import Button from '../../Button';
import { EasyCompetenceType } from './EditSkillQuestionsForm';
import r from '../../../constants/routes.constants';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { scrollToError } from '../../../utils';

export type NewSkillQuestionsFormPropsType = {
  competencies: Array<EasyCompetenceType>;
  isLoading: boolean;
  onSubmit: (data: any) => void;
  skillQuestionsDataError: string | RejectValueErrors[] | null;
};

function NewSkillQuestionsForm({
  competencies,
  isLoading,
  skillQuestionsDataError,
  onSubmit,
}: NewSkillQuestionsFormPropsType) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: new SkillQuestionsInfo(),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: QUESTIONS_FOR_SKILL_VALIDATION_SCHEMA,
    onSubmit: data => {
      onSubmit(QUESTIONS_FOR_SKILL_VALIDATION_SCHEMA.cast(data));
    },
  });

  useSetFieldsErrors(skillQuestionsDataError, setFieldError);

  const competenciesOptions = useMemo(() => {
    return (
      competencies?.map(competence => ({
        value: competence.id,
        label: competence.name,
      })) || []
    );
  }, [competencies]);

  const levelsOptions = useMemo(() => {
    const competence = competencies?.find(el => el.id === values.competenceId);
    return (
      competence?.competenceLevels?.map(level => ({
        value: level.id,
        label: level.name,
      })) || []
    );
  }, [competenciesOptions, values.competenceId]);

  const skillsOptions = useMemo(() => {
    const competence = competencies?.find(el => el.id === values.competenceId);
    const level = competence?.competenceLevels?.find(el => el.id === values.levelId);
    return (
      level?.skills.map(skill => ({
        value: skill.id,
        label: skill.name,
      })) || []
    );
  }, [values.levelId, values.competenceId, competencies]);

  const setCompetence = useCallback(
    (option: any) => {
      if (option.value !== values.competenceId) {
        setFieldValue('competenceName', option.value && option.label);
        setFieldValue('competenceId', option.value);
        setFieldValue('levelName', '');
        setFieldValue('levelId', 0);
        setFieldValue('name', '');
        setFieldValue('skillId', 0);
      }
    },
    [values.competenceId, values.competenceName],
  );

  const setLevel = useCallback(
    (option: any) => {
      if (option.value !== values.levelId) {
        setFieldValue('levelName', option.value && option.label);
        setFieldValue('levelId', option.value);
        setFieldValue('name', '');
        setFieldValue('skillId', 0);
      }
    },
    [values.levelId, values.levelName],
  );

  const setSkill = useCallback(
    (option: any) => {
      if (option.value !== values.skillId) {
        setFieldValue('name', option.value && option.label);
        setFieldValue('skillId', option.value);
      }
    },
    [values.levelId, values.levelName],
  );

  const addNewQuestion = useCallback(() => {
    setFieldValue('questions', [
      ...values.questions,
      {
        question: '',
        correctAnswer: '',
      },
    ]);
  }, [values]);

  const removeQuestion = useCallback(
    index => () => {
      values.questions.splice(index, 1);
      setFieldValue('questions', values.questions);
    },
    [values],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const questionsErrors: any = useMemo(() => errors.questions, [errors.questions]);
  return (
    <form className={'form form--page'} onSubmit={handleSubmit}>
      <div className="form__inputs-wrapper">
        <div className="form__input-block">
          <Select
            options={skillsOptions}
            handleChange={setSkill}
            hasError={hasError('name')}
            errorMessage={errors.name}
            value={skillsOptions.find(opt => opt.value === values.skillId) || null}
            label={intl.formatMessage(messages.skillLabel)}
            isClearable
          />
        </div>
        <div className="form__inputs-subwrapper">
          <div className={'form__input-block form__input-block--half'}>
            <Select
              options={competenciesOptions}
              handleChange={setCompetence}
              hasError={hasError('competenceName')}
              errorMessage={errors.competenceName}
              value={competenciesOptions.find(opt => opt.value === values.competenceId) || null}
              label={intl.formatMessage(messages.competenceLabel)}
              isClearable
            />
          </div>
          <div className={'form__input-block form__input-block--half'}>
            <Select
              options={levelsOptions}
              handleChange={setLevel}
              hasError={hasError('levelName')}
              errorMessage={errors.levelName}
              value={levelsOptions.find(opt => opt.value === values.levelId) || null}
              label={intl.formatMessage(messages.levelLabel)}
              isClearable
            />
          </div>
        </div>
        <div className="form__group">
          <ReactSortable
            list={values.questions}
            setList={newState => setFieldValue(`questions`, newState)}
            animation={200}
            handle={'.form__btn-move-inputs'}
            style={{ marginBottom: '14px' }}
          >
            {values.questions.map((question, index) => (
              <div className="form__group-wrapper form__group-wrapper--bordered" key={`[${index}]`}>
                <button className="form__btn-move-inputs" type={'button'}>
                  <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
                </button>
                <div className="form__inputs-wrapper">
                  <div className="form__input-block">
                    <Input
                      id={`questions[${index}].question`}
                      name={`questions[${index}].question`}
                      label={intl.formatMessage(messages.questionLabel)}
                      defaultValue={values.questions[index].question}
                      onChange={handleChange}
                      hasError={hasError(`questions[${index}].question`)}
                      errorMessage={questionsErrors && questionsErrors[index]?.question}
                    />
                    <Input
                      id={`questions[${index}].correctAnswer`}
                      tag="textarea"
                      name={`questions[${index}].correctAnswer`}
                      label={intl.formatMessage(messages.answerLabel)}
                      defaultValue={values.questions[index].correctAnswer}
                      onChange={handleChange}
                      hasError={hasError(`questions[${index}].correctAnswer`)}
                      errorMessage={questionsErrors && questionsErrors[index]?.correctAnswer}
                    />
                  </div>
                </div>
                <button className="form__btn-clean-inputs" type={'button'} onClick={removeQuestion(index)}>
                  <Icon iconName={'cross'} externalClass={'form__btn-clean'} />
                </button>
              </div>
            ))}
          </ReactSortable>
          <button className="form__btn-add-group" type={'button'} onClick={addNewQuestion}>
            <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
            <FormattedMessage {...messages.questionLabel} />
          </button>
        </div>
      </div>
      <ErrorMessage> {skillQuestionsDataError}</ErrorMessage>
      <div className="form__buttons">
        <NavLink to={r.skillQuestions}>
          <Button externalClass={'button--modal'} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
        </NavLink>
        <Button externalClass={'button--modal'} type={'submit'} disabled={isLoading} loading={isLoading}>
          <FormattedMessage {...messages.saveButton} />
        </Button>
      </div>
    </form>
  );
}

export default NewSkillQuestionsForm;
