import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/auth.constants';

export const signIn = (values: any): AnyAction => ({
  type: ActionTypes.SIGN_IN_REQUEST,
  payload: values,
});

export const keycloakSignIn = (values: any): AnyAction => ({
  type: ActionTypes.KEYCLOAK_SIGN_IN_REQUEST,
  payload: values,
});

export const signOut = (callbackUrl?: string): AnyAction => ({
  type: ActionTypes.SIGN_OUT_REQUEST,
  payload: callbackUrl,
});

export const getCurrentUserInfo = (): AnyAction => ({
  type: ActionTypes.GET_CURRENT_USER_INFO_REQUEST,
});

export const refreshUserAuthToken = (): AnyAction => ({
  type: ActionTypes.REFRESH_USER_AUTH_TOKEN,
});
