import { required } from './../../i18n/defaultMessage';
import { defineMessages } from 'react-intl';

export default defineMessages({
  required,
  recognitionTypeBackgroundColorRequired: {
    id: 'recognitionType.backgroundColor.validation.required',
    defaultMessage: 'Icon Color is a required field',
  },
  transactionDateIncorrect: {
    id: 'finance.transactions.transaction.date.validation.incorrect',
    defaultMessage: 'Incorrect date',
  },
  transactionAmountTestError: {
    id: 'finance.transactions.transaction.amount.validation.test.error',
    defaultMessage: 'Amount value must be up to 11 digits with 2 digits after decimal point',
  },
  transactionUnifiedAmountTestError: {
    id: 'finance.transactions.transaction.unified.amount.validation.test.error',
    defaultMessage: 'Unified amount value must be up to 11 digits with 2 digits after decimal point',
  },
  transactionRateTestError: {
    id: 'finance.transactions.transaction.rate.validation.test.error',
    defaultMessage: 'Rate value must be up to 9 digits with 4 digits after decimal point',
  },
  transactionDateTestError: {
    id: 'finance.transactions.transaction.rate.validation.test.error',
    defaultMessage: 'The date of the transaction must not be earlier than January 1 of the. previous year',
  },
  salariesPayTestError: {
    id: 'finance.salaries.pay.validation.test.error',
    defaultMessage: 'Pay value must be up to 11 digits with 2 digits after decimal point',
  },
  salariesTaxTestError: {
    id: 'finance.salaries.tax.validation.test.error',
    defaultMessage: 'Tax value must be up to 11 digits with 2 digits after decimal point',
  },
});
