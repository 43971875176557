import React, { memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Icon from '../../../components/Icon';
import EventPollResponders from './EventPollResponders';
import EventPollContent from './EventPollContent';
import { isEmpty, isNil } from 'lodash-es';
import {
  closedValue,
  getEventPollUserStatusLabel,
  getPollStatusClassName,
  getPollStatusLabel,
  notStartedStatusValue,
} from '../../Polls/utils';
import { useSelector } from 'react-redux';
import { NewPoll } from '../../../enums/questionnaires.enum';
import { detectChanges } from '../../../utils';
import { UserInfo } from '../../../enums/users.enum';
import Dropdown from '../../../components/Dropdown';
import messages from '../messages';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import Label from '../../../components/Label';
import AccessChecker from '../../../components/AccessChecker';
import { VIEW_POLL_EXTENDED } from '../../../constants/policies.constants';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import useDrag from '../../../utils/hooks.utils';
import PoliciesContext from '../../../PoliciesContext';
import { checkPolicies } from '../../../utils/policies.utils';
import { SpecializationInfo } from '../../../enums/libraries.enum';
import { CompanyPositionInfoType } from '../../../types/libraries';

interface IEventPollProps {
  poll: NewPoll;
  userList: UserInfo[];
  specializations: SpecializationInfo[];
  targetEmployee: UserInfo | null;
  positions: CompanyPositionInfoType[];
  jiraProjectsList: [];
}

type ScrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const EventPoll = ({
  poll,
  userList,
  targetEmployee,
  specializations,
  positions,
  jiraProjectsList,
}: IEventPollProps) => {
  const responderRef = useRef(null);
  const headerRef = useRef(null);
  const parentRef = useRef(null);
  const scrollRef = useRef(null);
  const history = useHistory();
  // @ts-ignore
  const pollAnswersByPollId = useSelector(state => state.questionnaires.pollAnswersByPollId);
  const userPolicies = useContext(PoliciesContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { dragStart, dragStop, dragMove, clicked } = useDrag();
  const handleDrag = ({ scrollContainer }: ScrollVisibilityApiType) => (ev: React.MouseEvent) => {
    ev.preventDefault();
    dragMove(ev, posDiff => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });
  };

  const formattedAnswers = useMemo(
    () =>
      pollAnswersByPollId[poll.id]?.userAnswers
        ?.map((user: any) => {
          const foundUser = userList?.find((el: any) => user?.userPreview.id === el?.id);
          const usersGroupsScores: {
            groupId: string;
            maxScores: number;
            percentage: number | undefined;
            scores: number | undefined;
          }[] = [];
          pollAnswersByPollId[poll.id]?.groups.map(
            (groupItem: {
              users: { id: string; percentage: number; scores: number }[];
              maxScores: number;
              id: string;
            }) => {
              const currentUser = groupItem.users.find(item => item.id === user?.id);

              usersGroupsScores.push({
                groupId: groupItem.id,
                maxScores: groupItem.maxScores,
                percentage: currentUser?.percentage,
                scores: currentUser?.scores,
              });
            },
          );

          return {
            user: { ...foundUser, status: user.userPreview.pollStatus },
            answers: user.answers,
            groupScores: usersGroupsScores,
          };
        })
        .sort((a: { user: { secondName: string } }, b: { user: { secondName: string } }) =>
          a.user.secondName?.localeCompare(b.user.secondName),
        ),
    [pollAnswersByPollId, userList, poll.id],
  );

  const formattedResponders = useMemo(() => {
    if (isEmpty(poll.responders)) {
      return [];
    }

    const detected = detectChanges({
      prevItems: poll.responders.filter(item => item.pollStatus !== notStartedStatusValue),
      nextItems: userList,
    });

    const formatted: UserInfo[] = [];
    poll.responders.forEach(user =>
      formatted.push(detected.unchangedItems?.find(formattedUser => formattedUser.id === user.id)),
    );

    return (formatted.filter(item => !!item) || poll.responders).sort((a: any, b: any) =>
      a.secondName.localeCompare(b.secondName),
    );
  }, [poll.responders]);

  const questionAuthor: any = useMemo(() => {
    const user = !isEmpty(poll?.organizers) ? new UserInfo(poll?.organizers[0]) : new UserInfo();
    return { ...user, pollStatus: '' };
  }, [poll?.organizers]);

  const handleScroll = useCallback((api: any, event: any) => {
    //@ts-ignore
    if (!isNil(responderRef)) responderRef.current.scrollLeft = event.target.scrollLeft;
  }, []);

  const dropdownList = useMemo(() => {
    return [
      {
        label: <FormattedMessage {...messages.viewDetailLabel} />,
        handler: () => history.push(`/questionnaires/information/${poll.id}/${true}`),
      },
    ];
  }, [poll]);

  const userCanOpenPoll = checkPolicies([VIEW_POLL_EXTENDED], userPolicies) || poll?.status === closedValue;

  const getGroupMaxScores = useCallback(
    (groupId: string) =>
      pollAnswersByPollId[poll.id]?.groups.find((item: { maxScores: number; id: string }) => item.id === groupId)
        ?.maxScores,
    [pollAnswersByPollId, poll.id],
  );

  const getGroupScores = useCallback(
    (groupId: string, groupName: string) => {
      const currentGroupe = pollAnswersByPollId[poll.id]?.groups?.find(
        (item: { maxScores: number; id: string }) => item.id === groupId,
      );

      return (
        !!currentGroupe?.maxScores && (
          <div className="user-score-wrapper">
            <div className="group-name">{groupName}</div>
            <div>
              {`(${currentGroupe?.averageScore === 'NaN' ? ' -' : currentGroupe?.averageScore} of ${
                currentGroupe?.maxScores
              },  ${currentGroupe?.percentage === 'NaN' ? '- ' : currentGroupe?.percentage}%)`}
            </div>
          </div>
        )
      );
    },
    [pollAnswersByPollId, poll.id],
  );

  return (
    <div ref={parentRef} key={poll.id} className={classNames('event-poll')}>
      <div
        className={classNames('event-poll__dropdown', { disable: !userCanOpenPoll })}
        onClick={() => {
          if (userCanOpenPoll) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <div className="event-poll__dropdown--left">
          {userCanOpenPoll && (
            <Icon iconName={isOpen ? 'chevron-down' : 'chevron-right'} externalClass="event-poll__dropdown-icon" />
          )}
          <span className="event-poll__dropdown-title">{poll.name}</span>
        </div>
        <div className="event-poll__dropdown--right">
          <Label type={getPollStatusClassName(poll?.status)} size="bigger">
            {checkPolicies([VIEW_POLL_EXTENDED], userPolicies)
              ? getPollStatusLabel(poll?.status)
              : getEventPollUserStatusLabel(poll?.status)}
          </Label>
          <AccessChecker verifiablePolicies={[VIEW_POLL_EXTENDED]}>
            <Dropdown dropdownToggle={<Icon iconName="dots" />} dropdownList={dropdownList} stopPropagation />
          </AccessChecker>
        </div>
      </div>

      <div className={classNames('event-poll__content', { 'event-poll__content--open': isOpen })} ref={scrollRef}>
        {!poll?.anonymous && (
          <div className="poll-header-wrapper">
            <div className="poll-header" ref={responderRef}>
              <EventPollResponders
                responders={formattedResponders}
                responderRef={responderRef}
                parentRef={parentRef}
                author={questionAuthor}
                headerRef={headerRef}
              />
            </div>
          </div>
        )}

        <div className="event-poll__content-questions">
          <div className="event-poll__first-column-element"></div>
          <div className="event-poll__content-question" onMouseLeave={dragStop}>
            <ScrollMenu
              onMouseDown={() => dragStart}
              onMouseUp={() => dragStop}
              onMouseMove={handleDrag}
              onScroll={handleScroll}
              scrollContainerClassName={classNames({ grabbing: clicked })}
            >
              {poll.template.groups?.map((group: any) => (
                <div key={group.id} className="event-poll__content__group">
                  {
                    <div
                      className={classNames('event-poll__content__group-name page__event__info-title', {
                        'poll-group-scores': !!getGroupMaxScores(group.id),
                      })}
                    >
                      {!getGroupMaxScores(group.id) && <div className="group-name">{group.name}</div>}
                      {!!getGroupMaxScores(group.id) && (
                        <div className="users-score-list">
                          {getGroupScores(group.id, group.name)}
                          {formattedAnswers?.map((user: any) => {
                            const userGroupScore = pollAnswersByPollId[poll.id].groups
                              ?.find((item: { id: string }) => item.id === group.id)
                              ?.users.find((item: { userPreview: { id: string } }) => {
                                return item.userPreview.id === user.user.id;
                              });

                            return (
                              <div key={user.id + group.id} className="user-score-wrapper">
                                {!!getGroupMaxScores(group.id) &&
                                  `${userGroupScore?.scores} of ${getGroupMaxScores(group.id)}, ${
                                    userGroupScore?.percentage
                                  }%`}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  }
                  {group?.fields?.map((field: any) => (
                    <EventPollContent
                      key={field.id}
                      itemId={field.id}
                      questions={poll.template.groups}
                      pollData={poll}
                      participantsAnswers={formattedAnswers}
                      targetEmployee={targetEmployee}
                      field={field}
                      questionAuthor={questionAuthor}
                      userList={userList}
                      specializations={specializations}
                      positions={positions}
                      jiraProjectsList={jiraProjectsList}
                    />
                  ))}
                </div>
              ))}
            </ScrollMenu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(EventPoll);
