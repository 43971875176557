import { defineMessages } from 'react-intl';
import {
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  statusLabel,
  experienceLabel,
  createdColumn,
  updatedColumn,
  exportButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  statusLabel,
  experienceLabel,
  createdColumn,
  updatedColumn,
  exportButton,
  pageTitle: {
    id: 'candidate.management.title.candidates.management',
    defaultMessage: 'Candidates Management',
  },
  leadLabel: {
    id: 'candidate.management.column.label.lead',
    defaultMessage: 'Lead',
  },
  specializationsTitle: {
    id: 'candidate.management.column.specializations',
    defaultMessage: 'Specializations',
  },
  technologiesTitle: {
    id: 'candidate.management.column.technologies',
    defaultMessage: 'Technologies',
  },
  locationTitle: {
    id: 'candidate.management.column.location',
    defaultMessage: 'Location',
  },
  commentsTitle: {
    id: 'candidate.management.column.comments',
    defaultMessage: 'Comments',
  },
  receivingSourceTitle: {
    id: 'candidate.management.column.receiving.source',
    defaultMessage: 'Receiving Source',
  },
  selectedLabel: {
    id: 'candidate.management.lable.selected',
    defaultMessage: 'Selected',
  },
  createMemberBtn: {
    id: 'candidate.management.btn.create.member',
    defaultMessage: 'Create Member',
  },
});
