import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Icon from '../../Icon';
import { find } from 'lodash-es';
import { TRouteParamId } from '../../../enums/common.enum';
import Select from '../../Select';
import Label from '../../Label';
import * as competenciesActions from '../../../actions/competencies.actions';
import * as filtersActions from '../../../actions/filters.actions';
import { CompetenceLevelSkillType, CompetenceLevelType } from '../../../pages/Competencies/Competencies';
import { AssessmentsInfo } from '../../../enums/competencies.enum';
import moment from 'moment';
import classNames from 'classnames';
import ModalAssessmentDetails from '../../Assessments/Modals/ModalAssessmentDetails';
import ModalDeleteAssessment from '../../Assessments/Modals/ModalDeleteAssessment';
import ModalNewAssessment from '../../Assessments/Modals/ModalNewAssessment';
import { UserInfo } from '../../../enums/users.enum';
import ModalEditAssessment from '../../Assessments/Modals/ModalEditAssessment';
import AccessChecker from '../../AccessChecker';
import { UPDATE_USER_EXTENDED } from '../../../constants/policies.constants';
import { checkPolicies } from '../../../utils/policies.utils';
import { DATE_FORMAT } from '../../../constants/date.constants';
import PoliciesContext from '../../../PoliciesContext';
import HierarchicalTable from '../../HierarchicalTable';
import { useTableData } from '../../../utils/hooks.utils';
import { getTableCell } from '../../../utils/table.utils';
import { DepthLevels } from '../../../constants/tables.constants';
import { EventsParams } from '../../../enums/params/schedule.params';

function ProfileCompetencies({
  getSpecializationsPercentage,
  getCompetenciesPercentage,
  competenciesPercentage,
  specializationsPercentage,
  getUserCompetenceAssessments,
  userCompetenceAssessments,
  deleteAssessment,
  errors,
  resetErrors,
  loading,
  createNewAssessment,
  resetEventsFilter,
  userList,
  eventList,
  eventsLoading,
  competenciesList,
  getEventsForTargetEmployee,
  getCompetenciesFilter,
  getUsersFilter,
  editAssessment,
  getAssessment,
  assessmentData,
  getLevelsPercentage,
  levelsPercentage,
  resetUserCompetenceAssessments,
  lastAssessmentResults,
  getLastAssessmentResults,
  resetLastAssessmentResults,
  resetState,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const intl = useIntl();
  const { id } = useParams<TRouteParamId>();
  const classes = ['trainee', 'junior', 'middle', 'senior'];
  const [specializationsSelectValue, setSpecializationsSelectValue] = useState<{
    label: React.ReactNode;
    value: string;
  }>();
  const [competenciesSelectValue, setCompetenciesSelectValue] = useState<{ label: string; value: string }>();
  const [modalAssessmentDetailsIsOpen, setModalAssessmentDetailsIsOpen] = useState(false);
  const [modalAssessmentDetailsStyeles, setModalAssessmentDetailsStyles] = useState({});
  const [currentAssessment, setCurrentAssessment] = useState(new AssessmentsInfo());
  const [modalDeleteAssessmentIsOpen, setModalDeleteAssessmentIsOpen] = useState(false);
  const [modalNewAssessmentIsOpen, setModalNewAssessmentIsOpen] = useState(false);
  const [modalEditAssessmentIsOpen, setModalEditAssessmentIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    getCompetenciesPercentage(id);
    getSpecializationsPercentage(id);
  }, [id]);

  useEffect(() => {
    getCompetenciesFilter();
    checkPolicies([UPDATE_USER_EXTENDED], policies) && getUsersFilter();
    return () => {
      resetEventsFilter();
    };
  }, [policies]);

  useEffect(() => {
    competenciesSelectValue && getLevelsPercentage(id, competenciesSelectValue.value);
  }, [id, competenciesSelectValue?.value]);

  useEffect(() => {
    const main = find(specializationsPercentage, item =>
      specializationsSelectValue ? item.specializationId === specializationsSelectValue?.value : item.isMain,
    );

    main &&
      setSpecializationsSelectValue({
        label: (
          <div className="specializations-value-wrapper">
            <Label type="primary">
              <FormattedMessage {...messages.primaryLabel} />
            </Label>
            <div className="specialization-name">
              {main.specializationName} {main.percentage || main.percentage === 0 ? main.percentage + '%' : ''}
            </div>
          </div>
        ),
        value: main.specializationId,
      });
  }, [specializationsPercentage, id]);

  const specializationsOptions = useMemo(
    () =>
      specializationsPercentage?.map(
        ({
          specializationId,
          specializationName,
          isMain,
          percentage,
        }: {
          specializationId: string;
          specializationName: string;
          isMain: boolean | null;
          percentage: number;
        }) => ({
          label: (
            <div className="specializations-value-wrapper">
              {isMain !== null &&
                (isMain ? (
                  <Label type="primary">
                    <FormattedMessage {...messages.primaryLabel} />
                  </Label>
                ) : (
                  <Label type="secondary">
                    <FormattedMessage {...messages.secondaryLabel} />
                  </Label>
                ))}
              <div className="specialization-name">
                {specializationName} {isMain !== null && (percentage || 0) + '%'}
              </div>
            </div>
          ),
          value: specializationId,
        }),
      ),
    [specializationsPercentage],
  );

  const competenciesOptions = useMemo(
    () =>
      competenciesPercentage
        ?.filter((item: any) => item && item?.specializationId === specializationsSelectValue?.value)
        .map((item: { competenceId: string; competenceName: string; percentage: number }) => ({
          label: `${item?.competenceName} ${item?.percentage || 0}%`,
          value: item?.competenceId,
        })),
    [competenciesPercentage, specializationsSelectValue],
  );

  useEffect(() => {
    const index = competenciesOptions?.findIndex(
      (item: { value: string }) => item.value === competenciesSelectValue?.value,
    );

    setCompetenciesSelectValue(competenciesOptions?.length ? competenciesOptions[(index !== -1 && index) || 0] : null);
  }, [competenciesOptions, competenciesSelectValue]);

  useEffect(() => {
    competenciesSelectValue?.value &&
      getUserCompetenceAssessments({ userId: id, competenceId: competenciesSelectValue.value });
  }, [competenciesSelectValue, id]);

  const openAssessmentDetailsModal = useCallback(
    (event: React.MouseEvent<HTMLElement>, index: number, right?: boolean) => {
      const assesmentButton = event.target as HTMLElement;
      const rect = assesmentButton.getBoundingClientRect();

      setModalAssessmentDetailsStyles({
        left: `${!right ? rect.left - 310 : ''}px`,
        transform: `translate(-50%, ${rect.top - 126}px)`,
      });

      setCurrentAssessment(new AssessmentsInfo(userCompetenceAssessments[index]));
      setModalAssessmentDetailsIsOpen(true);
    },
    [userCompetenceAssessments],
  );

  const closeNewAssessmentModal = useCallback(() => {
    modalNewAssessmentIsOpen && resetEventsFilter();
    setModalNewAssessmentIsOpen(false);
  }, [modalNewAssessmentIsOpen]);

  const openEditAssessmentModal = useCallback(() => {
    getAssessment({ data: currentAssessment.id });
    setModalEditAssessmentIsOpen(true);
  }, [currentAssessment]);

  const closeEditAssessmentModal = useCallback(() => {
    modalEditAssessmentIsOpen && resetEventsFilter();
    setModalEditAssessmentIsOpen(false);
  }, [modalEditAssessmentIsOpen]);

  const onDeleteAssessmentRequest = useCallback(
    assessmentId => {
      deleteAssessment({
        id: assessmentId,
        callback: () => {
          setModalDeleteAssessmentIsOpen(false);
          setCurrentAssessment(new AssessmentsInfo());
          competenciesSelectValue &&
            getUserCompetenceAssessments({ userId: id, competenceId: competenciesSelectValue.value });
          getCompetenciesPercentage(id);
          getSpecializationsPercentage(id);
          competenciesSelectValue && getLevelsPercentage(id, competenciesSelectValue.value);
        },
      });
    },
    [competenciesSelectValue, id],
  );

  const createAssessment = useCallback(
    ({ data, cb }) => {
      createNewAssessment({
        data,
        callback: () => {
          closeNewAssessmentModal();
          cb();
          competenciesSelectValue &&
            getUserCompetenceAssessments({ userId: id, competenceId: competenciesSelectValue.value });
          getCompetenciesPercentage(id);
          getSpecializationsPercentage(id);
          competenciesSelectValue && getLevelsPercentage(id, competenciesSelectValue.value);
        },
      });
    },
    [competenciesSelectValue, id],
  );

  useEffect(() => {
    !competenciesOptions?.length && resetUserCompetenceAssessments();
  }, [competenciesOptions?.length]);

  const updateAssessment = useCallback(
    ({ data, cb }) => {
      editAssessment({
        data,
        callback: () => {
          closeEditAssessmentModal();
          cb();
          competenciesSelectValue &&
            getUserCompetenceAssessments({ userId: id, competenceId: competenciesSelectValue.value });
          getCompetenciesPercentage(id);
          getSpecializationsPercentage(id);
          competenciesSelectValue && getLevelsPercentage(id, competenciesSelectValue.value);
        },
      });
    },
    [competenciesSelectValue, id],
  );

  const tableColumns = useMemo(() => {
    const columns = [
      {
        Header: intl.formatMessage(messages.skillsColumn),
        accessor: 'name',
        headClassName: 'table__head-column--competence-skills',
      },
    ];

    return userCompetenceAssessments
      ? columns.concat(
          userCompetenceAssessments
            ?.sort(
              (a: AssessmentsInfo, b: AssessmentsInfo) =>
                new Date(a.assessmentDate).getTime() - new Date(b.assessmentDate).getTime(),
            )
            .map((item: AssessmentsInfo, index: number) => ({
              id: item.id,
              Header: (
                <button onClick={event => openAssessmentDetailsModal(event, index)}>
                  {moment(item.assessmentDate).format(DATE_FORMAT.ll)}
                </button>
              ),
              Cell: ({ row }: any) =>
                getTableCell(row, [
                  {
                    depth: DepthLevels.FIRST,
                    content: (row: CompetenceLevelType) => (
                      <span>
                        {
                          // eslint-disable-next-line react/prop-types
                          userCompetenceAssessments?.length - 1 === index
                            ? `${
                                // eslint-disable-next-line react/prop-types
                                levelsPercentage.find((item: { levelId: string }) => item.levelId === row.id)
                                  ?.percentage || 0
                              }%`
                            : ''
                        }
                      </span>
                    ),
                  },
                  {
                    depth: DepthLevels.SECOND,
                    content: (row: CompetenceLevelSkillType) => (
                      <span>{`${find(item.results, value => value.skillId === row?.id)?.score}/${row.maxScore}`}</span>
                    ),
                  },
                ]),
              headClassName: 'assessment-date',
            })),
        )
      : [];
  }, [userCompetenceAssessments]);

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 0 && row.id < 4) {
      return `table__data--${classes[row.id]}`;
    }

    if (row.depth === 1) {
      return row.id.split('.')[1] % 2 === 0 ? 'even' : '';
    }

    return '';
  }, []);

  return (
    <>
      <div className="tabs__content-item active  tabs__content-item--competencies-tab">
        <div className="title-wrapper">
          <div className="tabs__content-item__title">
            <FormattedMessage {...messages.competenciesTitle} />
          </div>
        </div>
        <div className="tabs__content-item__filters">
          <Select
            options={specializationsOptions}
            value={specializationsSelectValue}
            handleChange={(data: any) => setSpecializationsSelectValue(data)}
            externalClass="form__input-block--half specializations-select"
            isSearchable
          />
          <Select
            options={competenciesOptions}
            value={competenciesSelectValue}
            handleChange={(data: any) => setCompetenciesSelectValue(data)}
            externalClass="form__input-block--half"
            isSearchable
          />
        </div>
        <div>
          <div className="tabs__content-item__wrapper tabs__content-item__wrapper--levels">
            {!!userCompetenceAssessments?.length && (
              <button
                className="add_assessment_button"
                onClick={event => openAssessmentDetailsModal(event, userCompetenceAssessments.length - 1, true)}
              >
                <Icon iconName="info" />
              </button>
            )}
            <div className="tabs__content-item__wrapper">
              {userCompetenceAssessments &&
                userCompetenceAssessments[0]?.competence.competenceLevels.map(
                  (lavel: { name: string; id: string }, index: number) => (
                    <div
                      className={classNames(
                        { [`grade-block--${classes[index]}`]: index < 4 },
                        'grade-block--level',
                        'grade-block',
                      )}
                      key={lavel.id}
                    >
                      <div className="grade-block__name">{lavel.name}</div>
                      <div className="grade-block__total">
                        {levelsPercentage.find((item: { levelId: string }) => item.levelId === lavel.id)?.percentage ||
                          0}
                        %
                      </div>
                    </div>
                  ),
                )}
            </div>
            <AccessChecker verifiablePolicies={[UPDATE_USER_EXTENDED]}>
              <button className="add_assessment_button" onClick={() => setModalNewAssessmentIsOpen(true)}>
                <Icon iconName="plus" />
              </button>
            </AccessChecker>
          </div>
        </div>
        <HierarchicalTable
          tableColumns={tableColumns}
          loading={loading.userCompetenceAssessments}
          error={errors.userCompetenceAssessmentsErrors}
          externalClass="fixed profile-competencies "
          tableData={useTableData(
            userCompetenceAssessments && userCompetenceAssessments[0]?.competence.competenceLevels,
            ['skills'],
          )}
          externalRowClass={handleExternalRowClass}
          disabledDepthHover={[DepthLevels.SECOND]}
        />

        {modalAssessmentDetailsIsOpen && (
          <ModalAssessmentDetails
            isOpen
            assessment={currentAssessment}
            onCloseRequest={() => {
              setModalAssessmentDetailsIsOpen(false);
              setModalAssessmentDetailsStyles({});
            }}
            onDeleteRequest={() => setModalDeleteAssessmentIsOpen(true)}
            onEditRequest={openEditAssessmentModal}
            modalStyle={modalAssessmentDetailsStyeles}
          />
        )}
        {modalDeleteAssessmentIsOpen && (
          <ModalDeleteAssessment
            isOpen
            onCloseRequest={() => setModalDeleteAssessmentIsOpen(false)}
            assessmentError={errors.assessmentsDataError}
            assessmentData={currentAssessment}
            onDeleteRequest={onDeleteAssessmentRequest}
            isLoading={loading.deleteAssessment}
            resetErrors={resetErrors}
          />
        )}
        {modalNewAssessmentIsOpen && (
          <ModalNewAssessment
            isOpen
            onCloseRequest={closeNewAssessmentModal}
            createNewAssessment={createAssessment}
            users={userList}
            assessmentError={errors.createAssessmentError}
            isLoading={loading.createNewAssessment}
            competencies={competenciesList}
            getEventsForTargetEmployee={getEventsForTargetEmployee}
            events={eventList}
            eventsLoading={eventsLoading}
            resetErrors={resetErrors}
            targetEmployee={userList.find((item: UserInfo) => item.id === id)}
            lastAssessmentResults={lastAssessmentResults}
            getLastAssessmentResults={getLastAssessmentResults}
            resetLastAssessmentResults={resetLastAssessmentResults}
          />
        )}
        {modalEditAssessmentIsOpen && (
          <ModalEditAssessment
            isOpen
            onCloseRequest={closeEditAssessmentModal}
            editAssessment={updateAssessment}
            users={userList}
            assessmentError={errors.editAssessmentError}
            isLoading={loading.editAssessment}
            assessment={assessmentData}
            events={eventList}
            eventsLoading={eventsLoading}
            resetErrors={resetErrors}
            hideTargetEmployee
            lastAssessmentResults={lastAssessmentResults}
            getLastAssessmentResults={getLastAssessmentResults}
            getEventsForTargetEmployee={getEventsForTargetEmployee}
            resetLastAssessmentResults={resetLastAssessmentResults}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ competencies, filters }: RootState) => ({
  competenciesPercentage: competencies.competenciesPercentage?.content,
  specializationsPercentage: competencies.specializationsPercentage?.content,
  userCompetenceAssessments: competencies.userCompetenceAssessments?.content,
  loading: competencies.loading,
  errors: competencies.errors,
  userList: filters.usersFilter.users,
  competenciesList: filters.competenciesFilter.competencies,
  eventList: filters.eventsFilter.events,
  eventsLoading: filters.eventsFilter.loading,
  assessmentData: competencies.assessmentData,
  levelsPercentage: competencies.competenceLevelsPercentage?.content,
  lastAssessmentResults: competencies.lastAssessmentData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCompetenciesPercentage: (id: string) => dispatch(competenciesActions.getCompetenciesPercentage(id)),
  getSpecializationsPercentage: (id: string) => dispatch(competenciesActions.getSpecializationsPercentage(id)),
  getUserCompetenceAssessments: (data: { userId: string; competenceId: string }) =>
    dispatch(competenciesActions.getUserCompetenceAssessments(data)),
  deleteAssessment: (data: { id: string; callback: () => void }) =>
    dispatch(competenciesActions.deleteAssessment(data)),
  resetErrors: () => dispatch(competenciesActions.resetErrors()),
  createNewAssessment: (data: any) => dispatch(competenciesActions.createNewAssessment(data)),
  editAssessment: (data: any) => dispatch(competenciesActions.editAssessment(data)),
  resetEventsFilter: () => dispatch(filtersActions.resetEventsFilter()),
  getEventsForTargetEmployee: (data: Partial<EventsParams>) => dispatch(filtersActions.getEventsFilter(data)),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getCompetenciesFilter: () => dispatch(filtersActions.getCompetenciesFilter()),
  getAssessment: (data: Record<string, unknown>) => dispatch(competenciesActions.getAssessment(data)),
  getLevelsPercentage: (userId: string, competenceId: string) =>
    dispatch(competenciesActions.getLevelsPercentage(userId, competenceId)),
  resetUserCompetenceAssessments: () => dispatch(competenciesActions.resetUserCompetenceAssessments()),
  getLastAssessmentResults: (data: any) => dispatch(competenciesActions.getLastAssessmentResults(data)),
  resetLastAssessmentResults: () => dispatch(competenciesActions.resetLastAssessmentResults()),
  resetState: () => dispatch(competenciesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfileCompetencies);
