import React, { useEffect } from 'react';
import * as questionnairesActions from '../../../actions/questionnaires.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import EditPollTemplateForm from '../../../components/PollTemplates/Forms/EditPollTemplateForm';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { TRouteParamId } from '../../../enums/common.enum';
import CustomLoader from '../../../components/Loader';
import r from '../../../constants/routes.constants';
import { getPollTemplatesCategoryFilter } from '../../../actions/filters.actions';

function EditPollTemplate({
  getPollTemplatesCategoryFilter,
  editPollTemplate,
  getCurrentTemplate,
  isLoadingGetCurrentTemplate,
  categories,
  editTemplateLoading,
  currentTemplate,
  templateError,
}: ConnectedProps<typeof connector>) {
  const { id } = useParams<TRouteParamId>();

  useEffect(() => {
    getCurrentTemplate(id);
    getPollTemplatesCategoryFilter();
  }, []);

  const history = useHistory();

  const editPollTemplateCallback = () => {
    history.push(r.pollTemplates);
  };

  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper fixed-container">
          <h1 className="page__title">
            <FormattedMessage {...messages.editPollTemplateTitle} />
          </h1>
        </div>
      </div>
      <div className="page__main-content">
        <div className="page__wrapper fixed-container">
          {currentTemplate && (
            <EditPollTemplateForm
              isLoading={editTemplateLoading}
              categories={categories}
              onSubmit={data => editPollTemplate({ data, callback: editPollTemplateCallback })}
              currentTemplate={currentTemplate}
              requestErrors={templateError}
            />
          )}
          {isLoadingGetCurrentTemplate && <CustomLoader />}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ questionnaires, filters }: RootState) => ({
  editTemplateLoading: questionnaires.loading.editPollTemplate,
  categories: filters.pollTemplatesCategoryFilter.categories,
  currentTemplate: questionnaires.currentTemplate,
  templateError: questionnaires.errors.pollTemplatesError,
  isLoadingGetCurrentTemplate: questionnaires.loading.getCurrentPollTemplate,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPollTemplatesCategoryFilter: () => dispatch(getPollTemplatesCategoryFilter()),
  getCurrentTemplate: (id: string) => dispatch(questionnairesActions.getCurrentTemplate(id)),
  editPollTemplate: (data: any) => dispatch(questionnairesActions.editPollTemplate(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EditPollTemplate);
