import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/Candidates/candidates.constants';
import { Candidate, CandidateComment, CandidateStatus } from '../enums/candidates.enums';
import * as paramsTypes from '../enums/params/candidates.params';
import {
  CandidateSpecializationType,
  CandidateTechnologyType,
  CandidateReceivingSourceType,
} from '../types/candidates';

export const getCandidateStatusesList = (): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_STATUSES_LIST_REQUEST,
});

export const createCandidateStatus = (data: { data: CandidateStatus; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_CANDIDATE_STATUS_REQUEST,
  payload: data,
});

export const editCandidateStatus = (data: { data: CandidateStatus; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_CANDIDATE_STATUS_REQUEST,
  payload: data,
});

export const deleteCandidateStatus = (data: { data: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_CANDIDATE_STATUS_REQUEST,
  payload: data,
});

export const setCandidateStatusesParams = (data: Partial<paramsTypes.CandidateStatusParams>): AnyAction => ({
  type: ActionTypes.SET_CANDIDATE_STATUSES_PARAMS,
  payload: data,
});

export const getCandidateSpecializationsList = (): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_LIST_REQUEST,
});

export const createCandidateSpecialization = (data: {
  data: CandidateSpecializationType;
  callback: () => void;
}): AnyAction => ({
  type: ActionTypes.POST_CANDIDATE_SPECIALIZATION_REQUEST,
  payload: data,
});

export const editCandidateSpecialization = (data: {
  data: CandidateSpecializationType;
  callback: () => void;
}): AnyAction => ({
  type: ActionTypes.PUT_CANDIDATE_SPECIALIZATION_REQUEST,
  payload: data,
});

export const deleteCandidateSpecialization = (data: { data: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_CANDIDATE_SPECIALIZATION_REQUEST,
  payload: data,
});

export const setCandidateSpecializationsParams = (
  data: Partial<paramsTypes.CandidateSpecializationParams>,
): AnyAction => ({
  type: ActionTypes.SET_CANDIDATE_SPECIALIZATION_PARAMS,
  payload: data,
});

export const getCandidateTechnologiesList = (): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_TECHNOLOGIES_LIST_REQUEST,
});

export const createCandidateTechnology = (data: {
  data: CandidateTechnologyType;
  callback: () => void;
}): AnyAction => ({
  type: ActionTypes.POST_CANDIDATE_TECHNOLOGY_REQUEST,
  payload: data,
});

export const editCandidateTechnology = (data: { data: CandidateTechnologyType; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_CANDIDATE_TECHNOLOGY_REQUEST,
  payload: data,
});

export const deleteCandidateTechnology = (data: { data: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_CANDIDATE_TECHNOLOGY_REQUEST,
  payload: data,
});

export const setCandidateTechnologiesParams = (data: Partial<paramsTypes.CandidateTechnologyParams>): AnyAction => ({
  type: ActionTypes.SET_CANDIDATE_TECHNOLOGY_PARAMS,
  payload: data,
});

export const getCandidateReceivingSourcesList = (): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_LIST_REQUEST,
});

export const createCandidateReceivingSource = (data: {
  data: CandidateReceivingSourceType;
  callback: () => void;
}): AnyAction => ({
  type: ActionTypes.POST_CANDIDATE_RECEIVING_SOURCE_REQUEST,
  payload: data,
});

export const editCandidateReceivingSource = (data: {
  data: CandidateReceivingSourceType;
  callback: () => void;
}): AnyAction => ({
  type: ActionTypes.PUT_CANDIDATE_RECEIVING_SOURCE_REQUEST,
  payload: data,
});

export const deleteCandidateReceivingSource = (data: { data: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_CANDIDATE_RECEIVING_SOURCE_REQUEST,
  payload: data,
});

export const setCandidateReceivingSourcesParams = (
  data: Partial<paramsTypes.CandidateReceivingSourceParams>,
): AnyAction => ({
  type: ActionTypes.SET_CANDIDATE_RECEIVING_SOURCE_PARAMS,
  payload: data,
});

export const getCandidateList = (): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_LIST_REQUEST,
});

export const createCandidate = (data: { data: Candidate; callback: () => void }) => ({
  type: ActionTypes.POST_CANDIDATE_REQUEST,
  payload: data,
});

export const editCandidate = (data: { data: Candidate; callback?: () => void }) => ({
  type: ActionTypes.PUT_CANDIDATE_REQUEST,
  payload: data,
});

export const editCandidateInfo = (data: { data: Candidate; callback?: () => void }) => ({
  type: ActionTypes.PUT_CANDIDATE_INFO_REQUEST,
  payload: data,
});

export const deleteCandidate = (data: { data: string; callback: () => void }) => ({
  type: ActionTypes.DELETE_CANDIDATE_REQUEST,
  payload: data,
});

export const setCandidateParams = (data: Partial<paramsTypes.CandidateParams>): AnyAction => ({
  type: ActionTypes.SET_CANDIDATE_PARAMS,
  payload: data,
});

export const getCandidateInfo = (uuid: string) => ({
  type: ActionTypes.GET_CANDIDATE_INFO_REQUEST,
  payload: uuid,
});

export const resetCandidateInfo = (): AnyAction => ({
  type: ActionTypes.RESET_CANDIDATE_INFO,
});

export const uploadCandidatePhoto = (data: { uuid: string; data: FormData; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_CANDIDATE_PHOTO_REQUEST,
  payload: data,
});

export const deleteCandidatePhoto = (data: { uuid: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_CANDIDATE_PHOTO_REQUEST,
  payload: data,
});

export const getCandidateComments = (data: { candidateId: string; params: any; afterDelete?: boolean }): AnyAction => ({
  type: ActionTypes.GET_CANDIDATE_COMMENTS_REQUEST,
  payload: data,
});

export const createCandidateComment = (data: {
  candidateId: string;
  replyToCommentId?: string;
  data: CandidateComment;
  cb: () => void;
}): AnyAction => ({
  type: ActionTypes.POST_CANDIDATE_COMMENT_REQUEST,
  payload: data,
});

export const editCandidateComment = (data: { uuid: string; data: string; cb: () => void }): AnyAction => ({
  type: ActionTypes.PUT_CANDIDATE_COMMENT_REQUEST,
  payload: data,
});

export const deleteCandidateComment = (data: {
  uuid: string;
  cb: () => void;
  candidateId: string;
  size: number;
  commentPage: number;
}): AnyAction => ({
  type: ActionTypes.DELETE_CANDIDATE_COMMENT_REQUEST,
  payload: data,
});

export const resetErrors = (): AnyAction => ({
  type: ActionTypes.RESET_CANDIDATES_ERRORS,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_CANDIDATES_STATE,
});
