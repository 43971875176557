import { get } from 'lodash-es';

export class BonusesParams {
  officeIds: string[];
  userId: string;
  isActive: boolean | null;
  constructor(params?: unknown) {
    this.officeIds = get(params, 'officeIds', []);
    this.userId = get(params, 'userId', '');
    this.isActive = get(params, 'isActive', null);
  }
}
