import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { COMPETENCIES_TYPES_VALIDATION_SCHEMA } from '../../../enums/competencies.enum';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';

import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { CompetenciesTypeInfoType } from '../../../types/competencies';
import { scrollToError } from '../../../utils';

type ModalEditCompetenciesTypeProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  editCompetenciesType: (id: string, data: any) => void;
  CompetenciesTypeData: CompetenciesTypeInfoType;
  CompetenciesTypeError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
};

function ModalEditCompetenciesType({
  isOpen,
  onCloseRequest,
  editCompetenciesType,
  CompetenciesTypeData,
  CompetenciesTypeError,
  isLoading,
  resetErrors,
}: ModalEditCompetenciesTypeProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldError } = useFormik({
    initialValues: CompetenciesTypeData,
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: COMPETENCIES_TYPES_VALIDATION_SCHEMA,
    onSubmit: data => {
      return editCompetenciesType(CompetenciesTypeData?.id, { data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(CompetenciesTypeError, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editCompetenciesTypeTitle)}
      size={'small'}
      classNameModal="center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              id={'edit-name'}
              name={'name'}
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values?.name || ''}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
            />
          </div>
        </div>
        <ErrorMessage>{CompetenciesTypeError}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit} type={'button'}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditCompetenciesType;
