import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Dropdown from '../../Dropdown';
import Icon from '../../Icon';
import messages from '../messages';

type OverflowMenuProps = {
  children: any;
  visibilityMap: Record<string, boolean>;
  isShowMenu: boolean;
  openReorderLinksModal: () => void;
};

export default function OverflowMenu({
  children,
  visibilityMap,
  isShowMenu,
  openReorderLinksModal,
}: OverflowMenuProps) {
  const getChildrenList = () => {
    return React.Children.toArray(children)
      .filter((el: any) => !visibilityMap[el.props['data-targetid']])
      .map((el: any) => {
        return {
          label: (
            <a className="dropdown__list-item__link" href={el.props.children.props?.href}>
              {el.props.children.props?.children}
            </a>
          ),
        };
      });
  };

  const dropdownList = useMemo(() => {
    const childrenList = getChildrenList();
    return [
      ...childrenList,
      {
        label: <FormattedMessage {...messages.reorderBtnLabel} />,
        handler: openReorderLinksModal,
        itemClassName: childrenList.length > 0 ? 'links-reorder' : '',
        btnClassName: 'btn-links-reorder',
      },
    ];
  }, [visibilityMap]);

  return isShowMenu ? (
    <Dropdown
      dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
      dropdownList={dropdownList}
    />
  ) : null;
}
