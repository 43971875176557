import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import omit from 'lodash-es/omit';
import classNames from 'classnames';
import r, { getMenuNavigation } from '../constants/routes.constants';
import { getRouteUserConfig } from '../routes';
import * as actions from '../actions/auth.actions';
import Header from '../components/Header';
import RouteWithSubRoutes from '../components/RouteWithSubRoutes';
import Menu from '../components/Menu';
import BrandingContext from '../BrandingContext';
import ColorContext from '../ColorContext';
import { checkPolicies } from '../utils/policies.utils';
import { VIEW_USER_EXTENDED } from '../constants/policies.constants';
import { setFavicon } from '../utils/branding.utils';
import { BrandingInfo } from '../enums/branding.enum';
import PoliciesContext from '../PoliciesContext';

function UserLayout({
  brandingData,
  getCurrentUserInfo,
  authUserId,
  callbackUrl,
}: ConnectedProps<typeof connector> & { callbackUrl?: string | null }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [cancelTimeout, setCancelTimeout] = useState(false);
  const [openOnHover, setOpenOnHover] = useState(false);

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  const branding = useContext(BrandingContext);
  const color = useContext(ColorContext);
  const policies = useContext(PoliciesContext);

  const faviconUpdate = (brandingData: BrandingInfo) => {
    const favicon = document.getElementById('favicon');
    setFavicon(brandingData, favicon);
  };

  useEffect(() => {
    faviconUpdate(brandingData);
  }, [brandingData]);

  useEffect(() => {
    return () => faviconUpdate(new BrandingInfo());
  }, []);

  const toggleMenu = useCallback(() => setMenuOpen(!menuOpen), [menuOpen]);

  const isDisplayMenu = useMemo(() => !!getMenuNavigation(policies).length, [policies]);

  useEffect(() => {
    if (cancelTimeout) {
      setOpenOnHover(false);
    }
  }, [cancelTimeout, openOnHover]);

  return (
    <section
      className="page"
      style={{
        // @ts-ignore
        '--titles-font': branding?.titles,
        '--body-font': branding?.body,
        '--global-text-color': color,
        '--global-color': branding?.globalAccents,
      }}
    >
      <Header menuOpen={menuOpen} toggleMenu={toggleMenu} isDisplayMenu={isDisplayMenu} />
      <main
        className={classNames('main', {
          'main--open-menu': (menuOpen && !openOnHover) || (openOnHover && !cancelTimeout),
        })}
      >
        {isDisplayMenu && (
          <Menu
            open={menuOpen}
            cancelTimeout={cancelTimeout}
            setCancelTimeout={setCancelTimeout}
            openOnHover={openOnHover}
            setOpenOnHover={setOpenOnHover}
          />
        )}
        <div className="main__content">
          {authUserId && (
            <Switch>
              {getRouteUserConfig(policies).map(route => (
                <RouteWithSubRoutes key={route?.id} {...omit(route, 'id')} />
              ))}
              <Route
                path={r.root}
                component={() => (
                  <Redirect
                    to={
                      callbackUrl
                        ? callbackUrl
                        : checkPolicies([VIEW_USER_EXTENDED], policies)
                        ? r.users
                        : `${r.users}/${authUserId}`
                    }
                  />
                )}
              />
              <Route component={() => <Redirect to={r.root} />} />
            </Switch>
          )}
        </div>
      </main>
    </section>
  );
}

const mapStateToProps = ({ auth, branding }: RootState) => ({
  authUserId: auth.currentUserInfo.id,
  brandingData: branding.brandingData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCurrentUserInfo: () => dispatch(actions.getCurrentUserInfo()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UserLayout);
