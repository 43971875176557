import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import Input from '../Input';
import NumberInput from '../NumberInput';
import Select from '../Select';
import moment from 'moment';
import {
  NOTE,
  DATE_PICKER,
  TIME_PICKER,
  DATE_TIME_PICKER,
  NUMBER,
  SELECT_LIST_SINGLE_CHOICE,
  SELECT_LIST_MULTIPLE_CHOICE,
  TEXT,
  TemplateDataSource,
  TemplateDatabaseDataSource,
} from '../../constants/questionnaires.constants';
import { UserInfo, UserPreviewInfo } from '../../enums/users.enum';
import { v4 } from 'uuid';
import { isArray, isEmpty, isNaN, isNumber } from 'lodash-es';
import { DATE_FORMAT } from '../../constants/date.constants';
import { CompanyPositionInfoType, SpecializationInfoType } from '../../types/libraries';

export const useQuestionByType = (
  field: any,
  setFieldValue: (field: string, value: any, answerChanged?: boolean) => void,
  selectedValues: any,
  responder: UserPreviewInfo | undefined,
  index: any,
  updateOwnAnswersError: any,
  isSavingProgress: boolean | undefined,
  userPollStatus: string | undefined,
  errors: Record<string, string>,
  currentValues?: any,
  jiraProjectsList?: { id: number; name: string }[],
  companyPositions?: CompanyPositionInfoType[],
  userList?: UserInfo[],
  specializations?: SpecializationInfoType[],
) => {
  const intl = useIntl();
  const { selectAnswerLabel, answerLabel, commentLabel } = messages;
  const numberDefaultValue = !isEmpty(selectedValues?.pollAnswerOptions)
    ? +selectedValues?.pollAnswerOptions[0]?.value
    : undefined;

  const [answer, setAnswer] = useState(selectedValues || { comment: '' });
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [numberAnswer, setNumberAnswer] = useState<number | undefined>(numberDefaultValue);

  const fieldError = useMemo(() => {
    const error = Object.entries(errors).find(el => el[0] === field?.id);
    if (!isEmpty(error) && error) {
      return error[1];
    } else {
      return '';
    }
  }, [errors, field]);

  const templateForNewValue = useMemo(
    () => ({
      fieldId: field?.id,
      user: responder,
      userId: responder?.id,
      status: userPollStatus,
      templateFieldType: field?.type,
      pollAnswerOptions: [{ id: v4(), value: null, templateFieldOptionId: null }],
    }),
    [responder, field, userPollStatus],
  );

  const handleChangeSelect = useCallback(
    (items: any) => {
      const fieldName = field.dataSource === TemplateDataSource.DATABASE ? 'value' : 'templateFieldOptionId';
      const selectAnswer =
        isArray(items) && !isEmpty(items)
          ? items?.map((item: any) => ({ [fieldName]: item.value }))
          : [{ [fieldName]: items.value || '' }];
      const newValue = {
        ...templateForNewValue,
        pollAnswerOptions: selectAnswer,
      };
      setAnswer((prev: any) => {
        const newAnswer = { ...prev, ...newValue, comment: prev.comment };
        if (!field?.commentingAllowed) delete newAnswer.comment;
        setFieldValue(`${index}`, newAnswer);
        return newAnswer;
      });
    },
    [responder, field, selectedValues, index],
  );

  const handleChangeInput = useCallback(
    (e, isCommet) => {
      if (isCommet) {
        setAnswer((prev: any) => {
          const newAnswer = {
            ...selectedValues,
            ...templateForNewValue,
            ...prev,

            comment: e.target.value,
          };
          setFieldValue(`${index}`, newAnswer);
          return newAnswer;
        });
      } else {
        const newAnswer = {
          ...selectedValues,
          ...templateForNewValue,
          pollAnswerOptions: [{ id: v4(), value: e.target.value, templateFieldOptionId: null }],
        };
        setFieldValue(`${index}`, newAnswer);
      }
    },
    [index, selectedValues],
  );

  const handleChangeDate = useCallback(
    (e: any) => {
      const date = e.target.value;
      const newValue = {
        ...templateForNewValue,
        pollAnswerOptions: [{ id: v4(), value: date ? date : null, templateFieldOptionId: null }],
      };

      setAnswer((prev: any) => {
        const newAnswer = { ...prev, ...newValue, comment: prev.comment };
        if (!field?.commentingAllowed) delete newAnswer.comment;
        setFieldValue(`${index}`, newAnswer);
        return newAnswer;
      });
    },
    [selectedValues, field, responder, index, errors],
  );

  const handleChangeTime = useCallback(
    (e: any) => {
      const time = e.target.value;
      const newValue = {
        ...templateForNewValue,
        pollAnswerOptions: [{ id: v4(), value: time ? `${time}:00` : null, templateFieldOptionId: null }],
      };
      setAnswer((prev: any) => {
        const newAnswer = { ...prev, ...newValue, comment: prev.comment };
        if (!field?.commentingAllowed) delete newAnswer.comment;
        setFieldValue(`${index}`, newAnswer);
        return newAnswer;
      });
    },
    [selectedValues, field, responder, index],
  );

  const handleChangeDateTime = useCallback(
    (e: any) => {
      const dateTimeValue = e.target.value;
      const formattedDate = moment(dateTimeValue).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss);
      const newValue = {
        ...templateForNewValue,
        pollAnswerOptions: [{ id: v4(), value: dateTimeValue ? formattedDate : null, templateFieldOptionId: null }],
      };

      setAnswer((prev: any) => {
        const newAnswer = { ...prev, ...newValue, comment: prev.comment };
        if (!field?.commentingAllowed) delete newAnswer.comment;
        setFieldValue(`${index}`, newAnswer);
        return newAnswer;
      });
    },
    [selectedValues, field, responder, index],
  );

  const [currentFieldValue, setCurrentFieldValue] = useState();

  useEffect(() => {
    for (const prop in currentValues) {
      if (currentValues[prop]?.fieldId === field?.id && currentValues[prop]?.userId === responder?.id) {
        setCurrentFieldValue(currentValues[prop]);
      }
    }
  }, [currentValues, field, responder]);

  const handleChangeNumber = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      let number: number | undefined = (e.target as HTMLInputElement).valueAsNumber;

      if (e.key === 'Backspace' && !number) {
        number = undefined;
        setNumberAnswer(undefined);
      }

      const newValue = {
        ...templateForNewValue,
        pollAnswerOptions: [{ id: v4(), value: number, templateFieldOptionId: null }],
      };

      setAnswer((prev: any) => {
        const newAnswer = { ...prev, ...newValue, comment: prev.comment };
        if (!field?.commentingAllowed) delete newAnswer.comment;
        setFieldValue(`${index}`, newAnswer);
        return newAnswer;
      });

      if (isNaN(number)) {
        setInvalidNumber(true);
      } else if (isNumber(number)) {
        setNumberAnswer(number);
        setInvalidNumber(false);
      }
    },
    [selectedValues, field, responder, index],
  );

  const handleDecrement = useCallback(() => {
    const count = Number(numberAnswer && numberAnswer > 0 ? numberAnswer : 1);

    setNumberAnswer(count - 1);
    setAnswer((prev: any) => {
      const newValue = {
        ...templateForNewValue,
        pollAnswerOptions: [{ id: v4(), value: count - 1, templateFieldOptionId: null }],
      };
      const newAnswer = { ...prev, ...newValue, comment: prev.comment };
      if (!field?.commentingAllowed) delete newAnswer.comment;
      setFieldValue(`${index}`, { ...prev, ...newValue, newAnswer });
      return newAnswer;
    });
  }, [selectedValues, numberAnswer, index]);

  const handleIncrement = useCallback(() => {
    const count = Number(numberAnswer && numberAnswer > 0 ? numberAnswer : 0);

    const newValue = {
      ...templateForNewValue,
      pollAnswerOptions: [{ id: v4(), value: count + 1, templateFieldOptionId: null }],
    };
    setNumberAnswer(count + 1);
    setAnswer((prev: any) => {
      const newAnswer = { ...prev, ...newValue, comment: prev.comment };
      if (!field?.commentingAllowed) delete newAnswer.comment;
      setFieldValue(`${index}`, { ...prev, ...newValue, newAnswer });
      return newAnswer;
    });

    setFieldValue(`${index}`, newValue);
  }, [selectedValues, numberAnswer, index]);

  useEffect(() => {
    index && selectedValues && setFieldValue(`${index}`, { ...templateForNewValue, ...selectedValues }, false);
  }, []);

  const dataBaseOptions = useMemo(() => {
    switch (field?.templateDatabaseDataSource) {
      case TemplateDatabaseDataSource.PROJECTS: {
        return jiraProjectsList?.map((item: { id: number; name: string }) => ({ label: item.name, value: item.id }));
      }
      case TemplateDatabaseDataSource.COMPANY_POSITIONS: {
        return companyPositions?.map((item: CompanyPositionInfoType) => ({ label: item.displayName, value: item.id }));
      }
      case TemplateDatabaseDataSource.SPECIALIZATIONS: {
        return specializations?.map((item: SpecializationInfoType) => ({ label: item.name, value: item.id }));
      }
      case TemplateDatabaseDataSource.USERS: {
        return userList?.map((item: UserInfo) => ({ label: item.fullName, value: item.id }));
      }
      default: {
        return [];
      }
    }
  }, [field?.templateDatabaseDataSource, jiraProjectsList, companyPositions, specializations, userList]);

  const renderQuestionByType = useMemo(() => {
    const questionError = !isSavingProgress && fieldError ? fieldError : '';
    switch (field?.type) {
      case NOTE: {
        const defaultValue = !isEmpty(selectedValues?.pollAnswerOptions)
          ? selectedValues?.pollAnswerOptions[0]?.value
          : '';
        return (
          <Input
            name={`answer[${index}].note`}
            id={`answer[${index}].note`}
            hasError={!!questionError}
            errorMessage={questionError}
            label={intl.formatMessage(answerLabel)}
            onChange={e => handleChangeInput(e, false)}
            defaultValue={defaultValue}
            externalClass={'pollInput'}
            maxLength={1000}
            tag={'textarea'}
          />
        );
      }
      case DATE_PICKER: {
        const defaultValue = !isEmpty(selectedValues?.pollAnswerOptions)
          ? selectedValues?.pollAnswerOptions[0]?.value
          : ' ';
        return (
          <div className="form__input-block-wrapper">
            <Input
              id={`answer[${index}].date`}
              name={`answer[${index}].date`}
              label={intl.formatMessage(selectAnswerLabel)}
              onChange={handleChangeDate}
              type={'date'}
              hasError={!!questionError}
              errorMessage={questionError}
              defaultValue={defaultValue}
              externalClass={'pollInput not-empty'}
            />
            {field.commentingAllowed && (
              <Input
                name={`comment[${v4()}]`}
                label={intl.formatMessage(commentLabel)}
                onChange={e => handleChangeInput(e, true)}
                defaultValue={selectedValues?.comment ?? ''}
                externalClass={'pollInput'}
                maxLength={1000}
                tag={'textarea'}
              />
            )}
          </div>
        );
      }
      case TIME_PICKER: {
        const defaultValue =
          !isEmpty(selectedValues?.pollAnswerOptions) && selectedValues?.pollAnswerOptions[0]?.value
            ? selectedValues?.pollAnswerOptions[0]?.value.slice(0, 5)
            : ' ';

        return (
          <div className="form__input-block-wrapper">
            <Input
              id={`answer[${index}].time`}
              name={`answer[${index}].time`}
              label={intl.formatMessage(selectAnswerLabel)}
              onChange={handleChangeTime}
              type={'time'}
              hasError={!!questionError}
              errorMessage={questionError}
              defaultValue={defaultValue}
              externalClass={'pollInput not-empty'}
            />
            {field.commentingAllowed && (
              <Input
                name={`comment[${v4()}]`}
                label={intl.formatMessage(commentLabel)}
                onChange={e => handleChangeInput(e, true)}
                defaultValue={selectedValues?.comment ?? ''}
                externalClass={'pollInput'}
                maxLength={1000}
                tag={'textarea'}
              />
            )}
          </div>
        );
      }
      case DATE_TIME_PICKER: {
        const defaultDataTimeValue = !isEmpty(selectedValues?.pollAnswerOptions)
          ? moment(selectedValues?.pollAnswerOptions[0]?.value).format(DATE_FORMAT.YYYY_MM_DDTHH_mm)
          : ' ';
        return (
          <div className="form__input-block-wrapper">
            <Input
              id={`answer[${index}].dateTime`}
              name={`answer[${index}].dateTime`}
              label={intl.formatMessage(selectAnswerLabel)}
              onChange={handleChangeDateTime}
              type={'datetime-local'}
              hasError={!!questionError}
              errorMessage={questionError}
              defaultValue={defaultDataTimeValue}
              externalClass={'pollInput not-empty'}
            />
            {field.commentingAllowed && (
              <Input
                name={`comment[${v4()}]`}
                label={intl.formatMessage(commentLabel)}
                onChange={e => handleChangeInput(e, true)}
                defaultValue={selectedValues?.comment ?? ''}
                externalClass={'pollInput'}
                maxLength={1000}
                tag={'textarea'}
              />
            )}
          </div>
        );
      }
      case NUMBER: {
        return (
          <div className="form__input-block-wrapper">
            <NumberInput
              min={1}
              name={`answer[${index}].number`}
              id={`answer[${index}].number`}
              label={intl.formatMessage(selectAnswerLabel)}
              wrapperClass="number-input"
              onCountUp={() => handleIncrement()}
              onCountDown={() => handleDecrement()}
              hasError={!!questionError}
              errorMessage={questionError}
              defaultValue={numberAnswer}
              externalClass={`pollInput ${invalidNumber ? 'not-empty' : ''}`}
              onKeyUp={handleChangeNumber}
            />
            {field.commentingAllowed && (
              <Input
                name={`comment[${v4()}]`}
                label={intl.formatMessage(commentLabel)}
                onChange={e => handleChangeInput(e, true)}
                defaultValue={selectedValues?.comment ?? ''}
                externalClass={'pollInput'}
                maxLength={1000}
                tag={'textarea'}
              />
            )}
          </div>
        );
      }
      case SELECT_LIST_SINGLE_CHOICE: {
        const options =
          field.dataSource === TemplateDataSource.DATABASE
            ? dataBaseOptions
            : field?.options?.map((option: any) => ({ ...option, label: option.value, value: option.id }));

        const value = //@ts-ignore
          currentFieldValue?.pollAnswerOptions && field.dataSource === TemplateDataSource.DATABASE
            ? field.templateDatabaseDataSource === TemplateDatabaseDataSource.PROJECTS
              ? //@ts-ignore
                +currentFieldValue?.pollAnswerOptions[0]?.value
              : //@ts-ignore
                currentFieldValue?.pollAnswerOptions[0]?.value
            : //@ts-ignore
              currentFieldValue?.pollAnswerOptions && currentFieldValue?.pollAnswerOptions[0]?.templateFieldOptionId;

        const optionBySelectedValue =
          currentFieldValue &&
          //@ts-ignore
          !isEmpty(currentFieldValue?.pollAnswerOptions) &&
          options?.find((el: any) => {
            return el.value === value;
          });

        const selectedFields = currentFieldValue
          ? {
              //@ts-ignore
              label: optionBySelectedValue?.label,
              value: optionBySelectedValue?.[field.dataSource === TemplateDataSource.DATABASE ? 'value' : 'id'],
            }
          : answer;

        return (
          selectedFields && (
            <div className="form__input-block">
              {
                <Select
                  options={options}
                  value={selectedFields.value ? selectedFields : null}
                  id={`answer[${index}].singleSelect`}
                  name={`answer[${index}].singleSelect`}
                  label={intl.formatMessage(selectAnswerLabel)}
                  handleChange={handleChangeSelect}
                  hasError={!!questionError}
                  errorMessage={questionError}
                  externalClass={'pollInput'}
                />
              }
              {field?.commentingAllowed && (
                <Input
                  name={`comment[${v4()}]`}
                  defaultValue={selectedValues?.comment ?? ''}
                  label={intl.formatMessage(commentLabel)}
                  onChange={e => handleChangeInput(e, true)}
                  externalClass={'pollInput'}
                  maxLength={1000}
                  tag={'textarea'}
                />
              )}
            </div>
          )
        );
      }
      case SELECT_LIST_MULTIPLE_CHOICE: {
        const options =
          field.dataSource === TemplateDataSource.DATABASE
            ? dataBaseOptions
            : field?.options?.map((option: any) => ({ ...option, label: option.value, value: option.id }));
        //@ts-ignores
        const selectedFields = !isEmpty(currentFieldValue?.pollAnswerOptions) //@ts-ignore
          ? currentFieldValue?.pollAnswerOptions
              ?.filter((item: any) => item?.value || item?.templateFieldOptionId)
              ?.map((item: any) => {
                const value =
                  field.templateDatabaseDataSource === TemplateDatabaseDataSource.PROJECTS
                    ? +item.value
                    : item[field.dataSource === TemplateDataSource.DATABASE ? 'value' : 'templateFieldOptionId'];
                return {
                  label: options?.find(
                    (el: any) => value === (field.dataSource === TemplateDataSource.DATABASE ? el.value : el.id),
                  )?.label,
                  value: item[field.dataSource === TemplateDataSource.DATABASE ? 'value' : 'templateFieldOptionId'],
                };
              })
          : [];

        return (
          <div className="form__input-block">
            <Select
              isMulti
              isSearchable
              options={options}
              id={`answer[${index}].multiSelect`}
              name={`answer[${index}].multiSelect`}
              label={intl.formatMessage(selectAnswerLabel)}
              handleChange={handleChangeSelect}
              hasError={!!questionError}
              errorMessage={questionError}
              value={selectedFields}
              externalClass={'pollInput'}
            />
            {field.commentingAllowed && (
              <Input
                name={`comment[${v4()}]`}
                label={intl.formatMessage(commentLabel)}
                onChange={e => handleChangeInput(e, true)}
                defaultValue={selectedValues?.comment ?? ''}
                externalClass={'pollInput'}
                maxLength={1000}
                tag={'textarea'}
              />
            )}
          </div>
        );
      }
      case TEXT: {
        const defaultValue = !isEmpty(selectedValues?.pollAnswerOptions)
          ? selectedValues?.pollAnswerOptions[0]?.value
          : '';

        return (
          <Input
            name={`answer[${index}].text`}
            id={`answer[${index}].text`}
            label={intl.formatMessage(answerLabel)}
            onChange={e => handleChangeInput(e, false)}
            hasError={!!questionError}
            errorMessage={questionError}
            defaultValue={defaultValue}
            externalClass={'pollInput'}
            maxLength={1000}
            tag={'textarea'}
          />
        );
      }
      default: {
        const defaultValue = !isEmpty(selectedValues?.pollAnswerOptions)
          ? selectedValues?.pollAnswerOptions[0]?.value
          : '';

        return (
          <Input
            name={`answer[${index}].default`}
            id={`answer[${index}].default`}
            label={intl.formatMessage(answerLabel)}
            onChange={e => handleChangeInput(e, false)}
            hasError={!!questionError}
            errorMessage={questionError}
            defaultValue={defaultValue}
            externalClass={'pollInput'}
            maxLength={1000}
            tag={'textarea'}
          />
        );
      }
    }
  }, [field, answer, selectedValues, index, fieldError, invalidNumber, dataBaseOptions, currentFieldValue]);

  return {
    renderQuestionByType,
  };
};
