import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { POLL_VALIDATION_SCHEMA, NewPoll } from '../../../enums/questionnaires.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import Input from '../../Input';
import Select from '../../Select';
import Checkbox from '../../Checkbox';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import { UserInfo } from '../../../enums/users.enum';
import { EventPreviewInfo } from '../../../enums/schedule.enum';
import { v4 } from 'uuid';
import Icon from '../../Icon';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { isEmpty } from 'lodash-es';
import { PollTemplateCategoryInfoType } from '../../../types/questionnaires';
import { scrollToError, scrollToLastError } from '../../../utils';

type ModalCreateNewPollProps = {
  onCloseRequest: () => void;
  categories: Array<any>;
  responders: UserInfo[];
  templates: Array<any>;
  error: string | null;
  isLoading: boolean;
  isOpen: boolean;
  onSubmit: (data: any, callback: any) => void;
  officesList: {
    id: string;
    name: string;
  }[];
  eventList: Array<EventPreviewInfo>;
  currentCategory: any;
};

const allId = v4();

const ModalCreateNewPoll = ({
  isOpen,
  onCloseRequest,
  categories,
  responders,
  templates,
  error,
  isLoading,
  onSubmit,
  officesList,
  eventList,
  currentCategory,
}: ModalCreateNewPollProps) => {
  const [isAnonymousChecked, setIsAnonymousChecked] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(currentCategory);

  const intl = useIntl();

  const { values, errors, touched, handleChange, resetForm, setFieldValue, handleSubmit, setValues } = useFormik({
    initialValues: new NewPoll(),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: POLL_VALIDATION_SCHEMA,
    onSubmit: data => {
      onSubmit(POLL_VALIDATION_SCHEMA.cast(data), resetAndExit);
    },
  });

  useEffect(() => setSelectedCategory({ value: currentCategory.id, label: currentCategory.name }), [currentCategory]);

  useEffect(() => {
    const newPoll = new NewPoll();
    setValues(newPoll);
  }, []);

  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetForm();
  }, []);

  const categoryOptions = useMemo(
    () =>
      categories?.map(({ name, id }: PollTemplateCategoryInfoType) => ({
        label: name,
        value: id,
      })),
    [categories],
  );

  const officeTemplate = useCallback(
    (name: string) => (
      <div className="table__data-wrapper">
        <Icon iconName="building" />
        <span style={{ marginLeft: '5px' }}>{name}</span>
      </div>
    ),
    [],
  );

  const filteredTemplates = useMemo(() => templates.filter((el: any) => el.categoryId === selectedCategory.value), [
    templates,
    selectedCategory,
  ]);

  const templatesOptions = useMemo(
    () =>
      filteredTemplates?.map((item: { id: string; name: string; categoryId: string }) => ({
        value: item.id,
        label: item.name,
        categoryId: item.categoryId,
      })),
    [filteredTemplates],
  );

  const eventOptions = useMemo(
    () =>
      eventList
        ?.sort(
          (a: EventPreviewInfo, b: EventPreviewInfo) =>
            new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
        )
        .map(event => ({
          value: event,
          label: event.title || event.eventTypeName,
        })),
    [eventList],
  );

  const listResponders = useMemo(
    () =>
      responders?.map((item: UserInfo) => ({
        ...item,
        value: item.id,
        isOffice: false,
        label: item.fullName,
      })),
    [responders],
  );

  const respondersOptions = useMemo(() => {
    const offices = officesList?.map(office => ({
      ...office,
      isOffice: true,
      value: office.id,
      label: officeTemplate(office?.name),
    }));

    const allOffices = {
      value: allId,
      id: allId,
      name: intl.formatMessage(messages.allOffices),
      label: (
        <>
          <div className="table__data-wrapper">
            <Icon iconName="globe" />
            <span style={{ marginLeft: '10px' }}>{intl.formatMessage(messages.allOffices)}</span>
          </div>
        </>
      ),
    };

    if (values.isGeneral) return [allOffices, ...offices];

    const availableResponders = listResponders?.filter(
      user => isEmpty(values.officeIds) || values.officeIds.every(id => id !== user.office?.id),
    );

    return [allOffices, ...offices, ...availableResponders];
  }, [values.isGeneral, officesList, listResponders, values.officeIds]);

  const respondersValues = useMemo(() => {
    const selectedItems = respondersOptions.filter((opt: any) => {
      if (opt.value === allId && values.isGeneral) {
        return true;
      }

      if (opt.isOffice) {
        return values.officeIds.some(id => id === opt.value);
      } else {
        return values.responderIds.some(id => id === opt.value);
      }
    });

    return selectedItems;
  }, [values.officeIds, values.responderIds, values.isGeneral]);

  const selectedTemplate = useMemo(() => {
    const selected = values?.template;
    return selected.id ? { value: selected.id, label: selected.name } : null;
  }, [values]);

  const currentEvent = useMemo(() => eventOptions?.find((event: any) => values?.relatedEventId === event.value?.id), [
    eventOptions,
    values.relatedEventId,
  ]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleChangeCategory = useCallback(
    (item: any) => {
      setFieldValue('categoryId', item.value);
      setFieldValue('categoryName', item.label);
      setFieldValue('templateId', '');
      setFieldValue('template', {});
      setSelectedCategory(item);
    },
    [values],
  );

  const handleChangeResponders = useCallback((items: any) => {
    const hasAllItem = !!items.find((el: any) => el.id === allId);
    setFieldValue('isGeneral', hasAllItem);

    const respondersWithoutOffices = items?.filter((el: any) => !el.isOffice);
    const getSelectedOfficeIds = items?.filter((el: any) => el.isOffice).map((el: any) => el.id);
    const getResponderIds = respondersWithoutOffices
      ?.filter((user: any) => getSelectedOfficeIds.every((id: string) => id !== user.office?.id))
      .map((el: any) => el.id);

    setFieldValue('responderIds', hasAllItem ? [] : getResponderIds);
    setFieldValue('officeIds', getSelectedOfficeIds);
  }, []);

  const handleChangeOrganizers = useCallback(
    (items: any) => {
      const getResponderIds = items?.map((el: any) => el.id);
      const updateItems = items.map((el: any) => ({ ...el, label: `${el.firstName} ${el.secondName}` }));
      setFieldValue('organizerIds', getResponderIds);
      setFieldValue('organizers', updateItems);
    },
    [values],
  );

  const handleChangeTemplate = useCallback(
    (item: any) => {
      const chosenTemplate = filteredTemplates.find((el: any) => el.id === item.value);
      setFieldValue('templateId', chosenTemplate?.id);
      setFieldValue('template', { ...chosenTemplate });
    },
    [values, filteredTemplates],
  );

  const handleChangePollName = useCallback(
    event => {
      setFieldValue('name', event.target.value);
    },
    [values?.name],
  );

  const handleChangeRelatedEvent = useCallback((item: any) => {
    setFieldValue('relatedEvent', item.value || null);
    setFieldValue('relatedEventId', item.value?.id || '');
  }, []);

  const handleChangeDate = useCallback(
    el => {
      if (el) {
        setFieldValue('deadline', moment(el).endOf('D').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss));
      } else {
        setFieldValue('deadline', '');
      }
    },
    [values],
  );

  const handleCheckAnonymous = useCallback(() => {
    setIsAnonymousChecked(prev => {
      setFieldValue('anonymous', !prev);
      return !prev;
    });
  }, [values]);

  const formatEventOptionLabel = useCallback(data => {
    return data.isClearable ? (
      data.label
    ) : (
      <div className="related-event__wrapper">
        <span className="related-event__name">{data.value?.title || data.value?.eventTypeName}</span>
        <span className="related-event__date">{data.value?.datePeriod}</span>
      </div>
    );
  }, []);

  useEffect(() => {
    scrollToLastError();
  }, [error]);

  const {
    categorySelect,
    templateSelect,
    pollNameInput,
    anonymousMode,
    respondersSelect,
    relatedEventSelect,
    deadlineLabel,
    organizersSelect,
    descriptionLabel,
  } = messages;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.modalTitle)}
      size={'small'}
      shouldCloseOnOverlayClick={false}
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(categorySelect)}
              name={'category'}
              options={categoryOptions}
              handleChange={handleChangeCategory}
              hasError={hasError('categoryName')}
              errorMessage={errors?.categoryName}
              value={selectedCategory}
              isSearchable
            />
            <Select
              label={intl.formatMessage(templateSelect)}
              name={'template'}
              options={templatesOptions}
              handleChange={handleChangeTemplate}
              hasError={hasError('template')}
              errorMessage={errors?.templateId}
              isSearchable
              value={selectedTemplate}
            />
            <Input
              id={'pollName'}
              name={'name'}
              label={intl.formatMessage(pollNameInput)}
              onChange={handleChangePollName}
              hasError={hasError('name')}
              errorMessage={errors?.name}
              defaultValue={values?.name}
            />
            <Checkbox
              externalClass="form__checkbox-capitalazie-label"
              onChange={handleCheckAnonymous}
              id="anonymous"
              label={intl.formatMessage(anonymousMode)}
              checked={isAnonymousChecked}
            />
            <Select
              label={intl.formatMessage(respondersSelect)}
              name={'responders'}
              options={respondersOptions}
              value={respondersValues}
              handleChange={handleChangeResponders}
              isMulti
              isSearchable
              hasError={hasError('responderIds')}
              errorMessage={errors?.responderIds as string}
            />
            <Select
              label={intl.formatMessage(organizersSelect)}
              name={'organizers'}
              options={listResponders}
              handleChange={handleChangeOrganizers}
              isMulti
              isSearchable
              hasError={hasError('organizerIds')}
              errorMessage={errors?.organizers as string}
            />
            <Select
              isClearable
              label={intl.formatMessage(relatedEventSelect)}
              name={'relatedEvent'}
              isSearchable
              options={eventOptions}
              value={currentEvent || null}
              handleChange={handleChangeRelatedEvent}
              formatOptionLabel={formatEventOptionLabel}
            />
            <div className={'form__input-block form__input-block--half'}>
              <Input
                id={'deadlineDate'}
                name="deadline"
                label={intl.formatMessage(deadlineLabel)}
                onChange={e => handleChangeDate(e.target.value)}
                hasError={hasError('deadline')}
                errorMessage={errors?.deadline}
                type={'date'}
                defaultValue={''}
              />
            </div>
            <Input
              tag="textarea"
              label={intl.formatMessage(descriptionLabel)}
              name="description"
              onChange={handleChange}
            />
          </div>
          <ErrorMessage>{error}</ErrorMessage>
        </div>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.sendButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalCreateNewPoll;
