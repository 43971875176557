import { defineMessages } from 'react-intl';
import { cancelButton, saveButton, deleteButton } from '../../i18n/defaultMessage';

export default defineMessages({
  deleteButton,
  cancelButton,
  saveButton,
  aboutTitle: {
    id: 'candidate.profile.tab.about.title.about',
    defaultMessage: 'About',
  },
  specializationsTitle: {
    id: 'candidate.profile.tab.about.title.specializations',
    defaultMessage: 'Specializations',
  },
  technologiesTitle: {
    id: 'candidate.profile.tab.about.title.technologies',
    defaultMessage: 'Technologies',
  },
  previousEmploymentTitle: {
    id: 'candidate.profile.tab.about.title.previous.employment',
    defaultMessage: 'Previous Employment',
  },
  linksTitle: {
    id: 'candidate.profile.tab.about.title.links',
    defaultMessage: 'Links',
  },
  commentsTitle: {
    id: 'candidate.profile.tab.comments.title.comments',
    defaultMessage: 'Comments',
  },
});
