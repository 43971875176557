import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import * as planningActions from '../../actions/planning.actions';
import { ResourcesLayouts, resourcesUnsavedParams } from '../../constants/planning.constants';
import PlanWorkloadLayout from '../../components/Resources/Layouts/PlanWorkload';
import moment from 'moment';
import PlanFactWorkloadLayout from '../../components/Resources/Layouts/PlanFactWorkload';
import cx from 'classnames';
import ModalNewResource from '../../components/Resources/Modal/ModalNewResource';
import ModalEditResource from '../../components/Resources/Modal/ModalEditResource';
import InlineDatePicker from '../../components/InlineDatePicker';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { resetParamsChange, useLayout, useParamsChange, useUsersParamsChange } from '../../utils/hooks.utils';
import * as filtersActions from '../../actions/filters.actions';
import AccessChecker from '../../components/AccessChecker';
import { UPDATE_RESOURCE } from '../../constants/policies.constants';
import { UserInfo } from '../../enums/users.enum';
import ModalResourceInfo from '../../components/Resources/Modal/ModalResourceInfo';
import { DATE_FORMAT } from '../../constants/date.constants';
import { ResourcesParams } from '../../enums/params/planning.params';
import { getModalStyles } from '../../utils/planning.utils';
import { DayWorkload, ResourceComplexItem } from '../../enums/planning.enum';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import ResourcesFilter from '../../components/Resources/Filter/ResourcesFilter';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';

function Resources({
  tableData,
  isLoading,
  getUsersFilter,
  getOfficesFilter,
  getDepartmentsFilter,
  getProjectGroupsFilter,
  usersFilter,
  officesFilter,
  departmentsFilter,
  projectGroupsFilter,
  setResourcesParams,
  resourcesDataError,
  createNewResources,
  params,
  getCurrentResource,
  currentResource,
  setCurrentResource,
  currentResourceError,
  isCurrentResourceLoading,
  resetErrors,
  resetState,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.RESOURCES_FILTER);

    setResourcesParams(
      currentSavedFilter
        ? new ResourcesParams(currentSavedFilter)
        : {
            officeIds: officesFilter.value,
            departmentIds: departmentsFilter.value,
            userIds: usersFilter.value,
            projectGroupIds: projectGroupsFilter.value,
          },
    );
    getUsersFilter();
    getOfficesFilter();
    getDepartmentsFilter();
    getProjectGroupsFilter();
    setSavedFiltersParams({ filterType: FilterTypes.RESOURCES_FILTER });
    return () => {
      resetState();
    };
  }, []);

  const [layout, handleChangeActiveLayout] = useLayout(ResourcesLayouts, ResourcesLayouts.PLANWORKLOAD);
  const [modalNewResourceIsOpen, setModalNewResourceIsOpen] = useState(false);
  const [modalEditResourceIsOpen, setModalEditResourceIsOpen] = useState(false);

  const [modalResourceInfoIsOpen, setModalResourceInfoIsOpen] = useState(false);
  const [modalResourceInfoStyeles, setModalResourceInfoStyles] = useState({});

  const closeNewResourceModal = useCallback(() => {
    setModalNewResourceIsOpen(false);
  }, []);

  const openNewResourceModal = useCallback(() => {
    setModalNewResourceIsOpen(true);
  }, []);

  const openEditResourceModal = useCallback(() => {
    setModalEditResourceIsOpen(true);
    setModalResourceInfoIsOpen(false);
  }, []);

  const closeEditResourceModal = useCallback(() => {
    setModalEditResourceIsOpen(false);
    setCurrentResource(null);
  }, []);

  const closeResourceInfoModal = useCallback(() => {
    setModalResourceInfoIsOpen(false);
    setModalResourceInfoStyles({});
  }, []);

  const handleMultiParamsChange = useParamsChange(setResourcesParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setResourcesParams, dispatch);

  const activeUsersOptions = useMemo(
    () =>
      usersFilter.users
        ?.filter((item: UserInfo) => item.includedInPlanning)
        .map((item: UserInfo) => ({ value: item.id, label: item.fullName })),
    [usersFilter.users],
  );

  const setCurrentResourceData = useCallback((resource: any, event: React.MouseEvent<HTMLElement>) => {
    const resourceCell = event.target as HTMLElement;

    resource.workloads.length != 0
      ? getCurrentResource({
          data: { id: resource.user.id, date: resource.startDate },
        })
      : setCurrentResource(resource);

    setModalResourceInfoStyles(getModalStyles(resourceCell.getBoundingClientRect()));
    setModalResourceInfoIsOpen(true);
  }, []);

  const onDateChange = (start: string, end: string) => {
    setResourcesParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  };

  const resources = useMemo(
    () =>
      tableData
        ? {
            ...tableData,
            resourcesComplex: tableData?.resourcesComplex?.map(
              (item: ResourceComplexItem) =>
                new ResourceComplexItem({
                  ...item,
                  resources: item.resources.map(resource => {
                    let resourceWorkload: DayWorkload[] = [];
                    if (resource.dayWorkloads.length !== moment(params.dateTo).diff(params.dateFrom, 'days') + 1) {
                      for (let i = 0; i <= moment(params.dateTo).diff(params.dateFrom, 'days'); i++) {
                        const date = moment(new Date(params.dateFrom)).add(i, 'days').format(DATE_FORMAT.YYYY_MM_DD);
                        const day = resource.dayWorkloads.find(item => item.date === date);
                        resourceWorkload.push(day ? day : new DayWorkload({ employeeNotHired: true, date: date }));
                      }
                    } else {
                      resourceWorkload = resource.dayWorkloads;
                    }

                    return {
                      ...resource,
                      dayWorkloads: resourceWorkload,
                    };
                  }),
                }),
            ),
          }
        : null,
    [tableData, params],
  );

  const filters = useMemo(
    () => ({
      users: usersFilter.users,
      departments: departmentsFilter.departments,
      projectGroups: projectGroupsFilter.projectGroups,
      offices: officesFilter.offices,
    }),
    [usersFilter.users, officesFilter.offices, departmentsFilter.departments, projectGroupsFilter.projectGroups],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      setResourcesParams(
        new ResourcesParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setResourcesParams(new ResourcesParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));
    resetParamsChange(
      [
        FilterParamsName.USER_IDS,
        FilterParamsName.DEPARTMENT_IDS,
        FilterParamsName.OFFICE_IDS,
        FilterParamsName.PROJECT_GROUP_IDS,
      ],
      dispatch,
    );
  }, [params]);

  const showClearButton = useMemo(() => {
    return (
      !checkParamsMatch(params, new ResourcesParams(), [...resourcesUnsavedParams, 'withoutFullCaseload']) ||
      !!params.withoutFullCaseload
    );
  }, [params]);

  return (
    <>
      <div className="page__panel page__panel--fixed resources_page">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <div className="page__panel-top__wrapper--left">
              <h1 className="page__title">
                <FormattedMessage {...messages.pageTitle} />
              </h1>
            </div>
            <div className="page__panel-top__control">
              <InlineDatePicker
                onDateChange={onDateChange}
                defaultPeriodStart={params.dateFrom}
                defaultPeriodEnd={params.dateTo}
              />
              <div className="page__panel-view">
                <Button
                  externalClass={cx('page__panel-view__btn', { active: layout === ResourcesLayouts.PLANWORKLOAD })}
                  onClick={() => handleChangeActiveLayout(ResourcesLayouts.PLANWORKLOAD)}
                  color="gray"
                >
                  <Icon iconName="table" />
                </Button>
                <Button
                  externalClass={cx('page__panel-view__btn', { active: layout === ResourcesLayouts.PLANFACTWORKLOAD })}
                  onClick={() => handleChangeActiveLayout(ResourcesLayouts.PLANFACTWORKLOAD)}
                  color="gray"
                >
                  <Icon iconName="grid" />
                </Button>
              </div>
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_RESOURCE]}>
                  <Button externalClass={'button--with-icon new_button'} onClick={openNewResourceModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <ResourcesFilter
                  filters={filters}
                  values={params}
                  handleUsersParamsChange={handleUsersParamsChange}
                  handleMultiParamsChange={handleMultiParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  setResourcesParams={setResourcesParams}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          {layout === ResourcesLayouts.PLANWORKLOAD ? (
            <PlanWorkloadLayout
              tableData={resources}
              isLoading={isLoading.getResourcesList}
              resourcesError={resourcesDataError}
              editResource={setCurrentResourceData}
              currentPeriod={params.dateFrom}
              paramsProjectGroups={params.projectGroupIds}
            />
          ) : (
            <PlanFactWorkloadLayout
              tableData={resources}
              isLoading={isLoading.getResourcesList}
              resourcesError={resourcesDataError}
              editResource={setCurrentResourceData}
              currentPeriod={params.dateFrom}
              paramsProjectGroups={params.projectGroupIds}
            />
          )}
        </div>
      </div>
      {modalNewResourceIsOpen && (
        <ModalNewResource
          isOpen
          createNewResources={createNewResources}
          resourcesDataError={resourcesDataError}
          isLoading={isLoading.createResources}
          onCloseRequest={closeNewResourceModal}
          projects={projectGroupsFilter.projectGroups?.filter((item: { activeStatus: boolean }) => item.activeStatus)}
          usersOptions={activeUsersOptions}
          resetErrors={resetErrors}
        />
      )}
      {modalEditResourceIsOpen && (
        <ModalEditResource
          isOpen
          createNewResources={createNewResources}
          resourcesDataError={resourcesDataError || currentResourceError}
          isLoading={isLoading.createResources}
          onCloseRequest={closeEditResourceModal}
          projects={projectGroupsFilter.projectGroups?.filter((item: { activeStatus: boolean }) => item.activeStatus)}
          usersOptions={activeUsersOptions}
          currentResource={currentResource}
          isCurrentResourceLoading={isCurrentResourceLoading}
          resetErrors={resetErrors}
        />
      )}
      {modalResourceInfoIsOpen && (
        <ModalResourceInfo
          isOpen
          resource={currentResource}
          onCloseRequest={closeResourceInfoModal}
          onEditRequest={openEditResourceModal}
          modalStyle={modalResourceInfoStyeles}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ planning, filters, auth }: RootState) => ({
  resourcesDataError: planning.errors.resourcesDataError,
  resourcesTableDataError: planning.errors.projectGroupsTableDataError,
  isLoading: planning.loading,
  tableData: planning.resourcesTableData,
  params: planning.resourcesParams,
  currentResource: planning.currentResource,
  currentResourceError: planning.errors.currentResourceError,
  isCurrentResourceLoading: planning.loading.currentResource,
  usersFilter: filters.usersFilter,
  officesFilter: filters.officesFilter,
  departmentsFilter: filters.departmentsFilter,
  projectGroupsFilter: filters.projectGroupsFilter,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  getProjectGroupsFilter: () => dispatch(filtersActions.getProjectGroupsFilter()),
  setResourcesParams: (data: Partial<ResourcesParams>) => dispatch(planningActions.setResourcesParams(data)),
  createNewResources: (data: Record<string, unknown>) => dispatch(planningActions.createNewResources(data)),
  getCurrentResource: (data: Record<string, any>) => dispatch(planningActions.getCurrentResource(data)),
  setCurrentResource: (data: Record<string, any> | null) => dispatch(planningActions.setCurrentResource(data)),
  resetErrors: () => dispatch(planningActions.resetPlaningError()),
  resetState: () => dispatch(planningActions.resetPlaningState()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Resources);
