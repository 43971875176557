import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/bonuses.constants';
import { BonusesParams } from '../enums/params/bonuses.params';
import { BonusesSettingsType } from '../components/Bonuses/Modals/ModaSettings';
import { BonusCategoryInfo } from '../enums/bonuses.enums';

export const getBonusesList = (): AnyAction => ({
  type: ActionTypes.GET_BONUSES_LIST_REQUEST,
});

export const createNewBonus = (data: { data: BonusCategoryInfo; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_BONUS_REQUEST,
  payload: data,
});

export const editBonus = (data: { id: string; data: BonusCategoryInfo; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_BONUS_REQUEST,
  payload: data,
});

export const deleteBonus = (data: { data: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_BONUS_REQUEST,
  payload: data,
});

export const getBonusesSettings = (): AnyAction => ({
  type: ActionTypes.GET_BONUSES_SETTINGS_REQUEST,
});

export const editBonusesSettings = (data: BonusesSettingsType): AnyAction => ({
  type: ActionTypes.EDIT_BONUSES_SETTINGS_REQUEST,
  payload: data,
});

export const setBonusesParams = (data: Partial<BonusesParams>): AnyAction => ({
  type: ActionTypes.SET_BONUSES_PARAMS_REQUEST,
  payload: data,
});

export const resetBonusesErrors = (): AnyAction => ({
  type: ActionTypes.RESET_BONUSES_ERRORS,
});

export const resetBonusesState = (): AnyAction => ({
  type: ActionTypes.RESET_BONUSES_STATE,
});
