import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewDepartment from '../../components/Departments/Modals/ModalNewDepartment';
import ModalEditDepartment from '../../components/Departments/Modals/ModalEditDepartment';
import ModalDeleteDepartment from '../../components/Departments/Modals/ModalDeleteDepartment';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { DepartmentsParams } from '../../enums/params/libraries.params';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_DEPARTMENT, UPDATE_DEPARTMENT } from '../../constants/policies.constants';

export type DepartmentDataType = {
  displayName: string;
  id: string;
  priority: number;
};

function Departments({
  getDepartmentsList,
  createNewDepartment,
  editDepartment,
  deleteDepartment,
  setDepartamentsParams,
  resetErrors,
  resetState,
  departmentError,
  isLoading,
  tableData,
  sortParams,
}: ConnectedProps<typeof connector>) {
  const [modalNewDepartmentIsOpen, setModalNewDepartmentIsOpen] = useState(false);
  const [modalEditDepartmentIsOpen, setModalEditDepartmentIsOpen] = useState(false);
  const [modalDeleteDepartmentIsOpen, setModalDeleteDepartmentIsOpen] = useState(false);
  const [departmentClicked, setDepartmentClicked] = useState<DepartmentDataType>({
    displayName: '',
    id: '',
    priority: 0,
  });

  useEffect(() => {
    getDepartmentsList();
    return () => {
      resetState();
    };
  }, []);

  const intl = useIntl();

  const openNewDepartmentModal = useCallback(() => {
    setModalNewDepartmentIsOpen(true);
  }, []);

  const closeNewDepartmentModal = useCallback(() => {
    setModalNewDepartmentIsOpen(false);
  }, []);

  const openEditDepartmentModal = useCallback(() => {
    setModalEditDepartmentIsOpen(true);
  }, []);

  const closeEditDepartmentModal = useCallback(() => {
    setModalEditDepartmentIsOpen(false);
  }, []);

  const openDeleteDepartmentModal = useCallback(() => {
    setModalDeleteDepartmentIsOpen(true);
  }, []);

  const closeDeleteDepartmentModal = useCallback(() => {
    setModalDeleteDepartmentIsOpen(false);
  }, []);

  const setDepartmentCallback = useCallback(() => {
    setDepartmentClicked({
      displayName: '',
      id: '',
      priority: 0,
    });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'displayName',
        modifier: (row: DepartmentDataType) => (
          <div className={'table__data-wrapper'}>
            <span>{row.displayName}</span>
          </div>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: DepartmentDataType) => {
          setDepartmentClicked(row);
          openEditDepartmentModal();
        },
        verifiablePolicies: [UPDATE_DEPARTMENT],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: DepartmentDataType) => {
          setDepartmentClicked(row);
          openDeleteDepartmentModal();
        },
        verifiablePolicies: [DELETE_DEPARTMENT],
      },
    ],
    [],
  );

  const handleSort = useCallback((sortBy, direction) => setDepartamentsParams({ sortBy, direction }), []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper">
              <AccessChecker verifiablePolicies={[UPDATE_DEPARTMENT]}>
                <Button externalClass={'button--with-icon'} onClick={openNewDepartmentModal}>
                  <Icon iconName={'plus'} externalClass={'button__icon'} />
                  <span className="button__text">
                    <FormattedMessage {...messages.newButton} />
                  </span>
                </Button>
              </AccessChecker>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getDepartments}
            error={departmentError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
        </div>
      </div>
      {modalNewDepartmentIsOpen && (
        <ModalNewDepartment
          isOpen
          onCloseRequest={closeNewDepartmentModal}
          createNewDepartment={createNewDepartment}
          departmentError={departmentError}
          isLoading={isLoading.createDepartment}
          resetErrors={resetErrors}
        />
      )}
      {modalEditDepartmentIsOpen && (
        <ModalEditDepartment
          isOpen
          onCloseRequest={closeEditDepartmentModal}
          editDepartment={editDepartment}
          departmentData={departmentClicked}
          departmentError={departmentError}
          isLoading={isLoading.editDepartment}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteDepartmentIsOpen && (
        <ModalDeleteDepartment
          isOpen
          onCloseRequest={closeDeleteDepartmentModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteDepartment({
              ...data,
              setDepartmentCallback,
            });
          }}
          isLoading={isLoading.deleteDepartment}
          departmentError={departmentError}
          departmentData={departmentClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries }: RootState) => ({
  tableData: libraries.departmentsTableData?.content,
  departmentError: libraries.errors.departmentError,
  isLoading: libraries.loading,
  sortParams: libraries.departmentsParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getDepartmentsList: () => dispatch(librariesActions.getDepartmentsList()),
  deleteDepartment: (data: Record<string, unknown>) => dispatch(librariesActions.deleteDepartment(data)),
  createNewDepartment: (data: Record<string, unknown>) => dispatch(librariesActions.createNewDepartment(data)),
  editDepartment: (id: string, data: Record<string, unknown>) =>
    dispatch(librariesActions.editDepartment({ ...data, id })),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  setDepartamentsParams: (data: Partial<DepartmentsParams>) => dispatch(librariesActions.setDepartamentsParams(data)),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Departments);
