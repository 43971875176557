import React from 'react';

type EventInfoRowProps = {
  name: string;
  children: any;
};

function EventInfoRow({ name, children }: EventInfoRowProps) {
  return (
    <div className="modal__info-row">
      <div className="modal__info-row__name">{name}</div>
      <div className="modal__info-row__value">{children}</div>
    </div>
  );
}

export default EventInfoRow;
