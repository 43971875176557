import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import ModalDeleteCurrency from '../../components/Currencies/Modals/ModalDeleteCurrency';
import ModalEditCurrency from '../../components/Currencies/Modals/ModalEditCurrency';
import ModalNewCurrency from '../../components/Currencies/Modals/ModalNewCurrency';
import Label from '../../components/Label';
import { CurrenciesParams } from '../../enums/params/finance.params';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_CURRENCY, UPDATE_CURRENCY } from '../../constants/policies.constants';
import { CurrencyType } from '../../types/finance';

function Currencies({
  tableData,
  isLoading,
  errors,
  getCurrenciesList,
  createCurrency,
  editCurrency,
  deleteCurrency,
  resetState,
  resetErrors,
  setCurrenciesParams,
  sortParams,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewCurrencyIsOpen, setModalNewCurrencyIsOpen] = useState(false);
  const [modalEditCurrencyIsOpen, setModalEditCurrencyIsOpen] = useState(false);
  const [modalDeleteCurrencyIsOpen, setModalDeleteCurrencyIsOpen] = useState(false);
  const [currencyClicked, setCurrencyClicked] = useState<CurrencyType>({
    name: '',
    id: '',
    isBaseCurrency: false,
  });

  const handleSort = useCallback((sortBy, direction) => setCurrenciesParams({ sortBy, direction }), []);

  useEffect(() => {
    getCurrenciesList();
    return () => {
      resetState();
    };
  }, []);

  const openNewCurrencyModal = useCallback(() => {
    setModalNewCurrencyIsOpen(true);
  }, []);

  const closeNewCurrencyModal = useCallback(() => {
    setModalNewCurrencyIsOpen(false);
    resetErrors();
  }, []);

  const closeEditCurrencyModal = useCallback(() => {
    setModalEditCurrencyIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteCurrencyModal = useCallback(() => {
    setModalDeleteCurrencyIsOpen(false);
    resetErrors();
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: CurrencyType) => row.name,
        sortName: 'name',
      },
      {
        name: '',
        modifier: (row: CurrencyType) => (
          <span>
            {row.isBaseCurrency && (
              <Label type="base-currency">{intl.formatMessage(messages.baseCurrencylabel).toUpperCase()}</Label>
            )}
          </span>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CurrencyType) => {
          setCurrencyClicked(row);
          setModalEditCurrencyIsOpen(true);
        },
        verifiablePolicies: [UPDATE_CURRENCY],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CurrencyType) => {
          setCurrencyClicked(row);
          setModalDeleteCurrencyIsOpen(true);
        },
        verifiablePolicies: [DELETE_CURRENCY],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_CURRENCY]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewCurrencyModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table table--half currencies-table'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getCurrenciesList}
            error={errors.currenciesError}
            tableActions={tableActions}
            onSort={handleSort}
            params={sortParams}
          />
        </div>
      </div>
      {modalNewCurrencyIsOpen && (
        <ModalNewCurrency
          isOpen
          onCloseRequest={closeNewCurrencyModal}
          createCurrency={createCurrency}
          error={errors.currenciesError}
          isLoading={isLoading.createCurrency}
        />
      )}
      {modalEditCurrencyIsOpen && (
        <ModalEditCurrency
          isOpen
          onCloseRequest={closeEditCurrencyModal}
          editCurrency={editCurrency}
          isLoading={isLoading.editCurrency}
          error={errors.currenciesError}
          currency={currencyClicked}
        />
      )}
      {modalDeleteCurrencyIsOpen && (
        <ModalDeleteCurrency
          isOpen
          onCloseRequest={closeDeleteCurrencyModal}
          onDeleteRequest={deleteCurrency}
          isLoading={isLoading.deleteCurrency}
          error={errors.currenciesError}
          currency={currencyClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance }: RootState) => ({
  errors: finance.errors,
  isLoading: finance.loading,
  tableData: finance.currenciesListData?.content,
  sortParams: finance.currenciesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCurrenciesList: () => dispatch(financeActions.getCurrenciesList()),
  createCurrency: (data: { data: CurrencyType; callback: () => void }) => dispatch(financeActions.createCurrency(data)),
  editCurrency: (data: { data: CurrencyType; callback: () => void }) => dispatch(financeActions.editCurrency(data)),
  deleteCurrency: (data: { id: string; callback: () => void }) => dispatch(financeActions.deleteCurrency(data)),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  setCurrenciesParams: (params: Partial<CurrenciesParams>) => dispatch(financeActions.setCurrenciesParams(params)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Currencies);
