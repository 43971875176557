import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import r from '../../constants/routes.constants';
import Icon from '../Icon';
// import Search from '../Search';
import HeaderNavigation from '../Navigation/HeaderNavigation';
import BrandingContext from '../../BrandingContext';
import CustomHeaderNavigation from '../Navigation/CustomHeaderNavigation';
import Logo from '../Logo';
import HeaderLoader from './HeaderLoader';

type HeaderProps = {
  menuOpen: boolean;
  toggleMenu: () => void;
  isDisplayMenu: boolean;
};

function Header({ menuOpen, toggleMenu, isDisplayMenu }: HeaderProps) {
  const branding = useContext(BrandingContext);

  return (
    <header
      style={{
        // @ts-ignore
        '--header-background-color': branding?.headerBackground,
        '--header-foreground-color': branding?.headerForeground,
        '--header-box-shadow': branding?.headerShadow ? '0 10px 20px 0 rgb(51 51 51 / 5%)' : 'none',
        '--header-bottom-border': branding?.headerShadow ? 'none' : '1px #e6e6e6 solid',
      }}
      className={classNames('header', { 'header--open-menu': menuOpen })}
    >
      <div className="header__wrapper">
        <div className="header__edge-wrapper">
          {isDisplayMenu && (
            <button className={classNames('header__menu-btn', { open: menuOpen })} onClick={toggleMenu}>
              <Icon iconName="bar" externalClass="header__menu-btn__icon" />
            </button>
          )}
          <div className="header__logo">
            <NavLink to={r.root} className="header__logo-link">
              <Logo />
            </NavLink>
          </div>
        </div>
        <HeaderLoader />
        <CustomHeaderNavigation />
        <div className="header__navigation-wrapper">
          {/* 
          TODO
          <Search placeholder="Search" /> */}
          <HeaderNavigation />
        </div>
      </div>
    </header>
  );
}

export default Header;
