import { omit } from 'lodash-es';
import { Candidate, CandidateComment, InitialCandidateType } from '../enums/candidates.enums';
import { getFormattedDate } from '.';

export const getFormattedCandidate = (candidate: Candidate): InitialCandidateType => {
  return {
    ...omit(candidate, ['message', 'month', 'year', 'active']),
    carrierStart: getFormattedDate(candidate.year, candidate.month),
  };
};

export const getCandidateCommentsContent = (comments: any, results: any, afterDelete: boolean) => {
  const prevContent = afterDelete
    ? comments.content.filter((comment: CandidateComment) => comment.commentPage < results.number)
    : comments.content;
  const newContent = results.content.map((comment: CandidateComment) => new CandidateComment(results.number, comment));
  const nextPageNumber = afterDelete ? results.number + 1 : comments.number + 1;

  return {
    ...results,
    content: [
      ...prevContent,
      ...newContent.filter(
        (comment: CandidateComment) => !prevContent.find((el: CandidateComment) => el.id === comment.id),
      ),
    ],
    number: nextPageNumber,
  };
};

export const getCandidateCommentsContentAfterPost = (comments: any, newComment: any) => {
  const updateContent = comments.content.map(
    (comment: CandidateComment, index: number) => new CandidateComment(Math.floor(index / comments.size), comment),
  );
  return {
    ...comments,
    content: [new CandidateComment(0, newComment), ...updateContent],
    number: Math.floor((comments.content.length + 1) / comments.size),
    totalElements: comments.totalElements + 1,
  };
};
