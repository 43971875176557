import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Icon from '../../Icon';
import Input from '../../Input';
import Modal from '../../Modal';
import { EventTypeInfo, EVENT_TYPES_VALIDATION_SCHEMA } from '../../../enums/calendar.enum';
import Select from '../../Select';
import Switch from '../../Switch';
import Checkbox from '../../Checkbox';
import ColorInput from '../../ColorInput';
import NumberInput from '../../NumberInput';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { OptionTypeBase } from 'react-select';
import { debounce, isEmpty } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import moment from 'moment';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { UserInfo } from '../../../enums/users.enum';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { scrollToError } from '../../../utils';

type ModalNewEventTypeProps = {
  currentUserInfo: any;
  onCloseRequest: () => void;
  createNewEventType: any;
  eventTypeError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  offices: OfficeInfo[];
  users: Record<string, any>[];
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalNewEventType({
  currentUserInfo,
  onCloseRequest,
  createNewEventType,
  eventTypeError,
  isLoading,
  offices,
  users,
  resetErrors,
  isOpen,
}: ModalNewEventTypeProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, resetForm, setFieldError } = useFormik({
    initialValues: new EventTypeInfo(),
    validationSchema: EVENT_TYPES_VALIDATION_SCHEMA,
    validateOnChange: false,
    validate: scrollToError,
    onSubmit: data => {
      data.lastEditor = currentUserInfo;
      return createNewEventType({ data, callback: resetAndExit });
    },
  });

  useSetFieldsErrors(eventTypeError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const usersOptions = useMemo(() => {
    return users.map((el: Record<string, any>) => {
      return {
        label: `${el.firstName} ${el.secondName}`,
        value: el,
      };
    });
  }, [users]);

  const handleUsersAbleToApprove = useCallback(
    newValues => {
      const value = newValues.map((el: Record<string, any>) => el.value);
      setFieldValue('absencesSettings.requiresApproval.usersAbleToApprove', value);
    },
    [values],
  );

  const handleUsersAbleToManage = useCallback(
    newValues => {
      const value = newValues.map((el: Record<string, any>) => el.value);
      setFieldValue('limitedManagerPersons', value);
      setFieldValue(
        'limitedManagerPersonsIds',
        value.map((el: UserInfo) => el.id),
      );
      setFieldValue('limitedManagerPersonEnabled', !isEmpty(value));
    },
    [values],
  );

  const addOffice = useCallback(
    () =>
      setFieldValue('limitOfEvents.limitNumberOfEventsPerOffice', [
        ...values.limitOfEvents.limitNumberOfEventsPerOffice,
        {
          limitNumberPerUser: 0,
          office: offices[0],
          startDate: '',
          calculateAvailableDaysBasedOnDaysWorked: false,
        },
      ]),
    [values],
  );

  const removeOffice = useCallback(
    i => () => {
      const newValue = [...values.limitOfEvents.limitNumberOfEventsPerOffice];
      newValue.splice(i, 1);
      setFieldValue('limitOfEvents.limitNumberOfEventsPerOffice', newValue);
    },
    [values],
  );

  const handleOfficeChange = useCallback(
    index => ({ value }: OptionTypeBase) => {
      setFieldValue(`limitOfEvents.limitNumberOfEventsPerOffice[${index}].office`, value);
    },
    [],
  );

  const handleAbsenceChange = useCallback(value => {
    if (!value) {
      setFieldValue('absencesSettings.requiresApproval.requiresApprovalEnabled', false);
      setFieldValue('absencesSettings.finalDecisionOptionsEnabled', false);
      setFieldValue('absencesSettings.optionForExcludeDaysOfAbsenceFromOtherTypes', false);
      setFieldValue('absencesSettings.requiresWorkingOff', false);
      setFieldValue('absencesSettings.showTotalCounterInProfile', false);
      setFieldValue('absencesSettings.requiresApproval.usersAbleToApprove', []);
    }
    setFieldValue('absencesSettings.absencesEnabled', value);
  }, []);

  const handleRequiresApprovalChange = useCallback(value => {
    if (!value) {
      setFieldValue('absencesSettings.requiresApproval.usersAbleToApprove', []);
    }
    setFieldValue('absencesSettings.requiresApproval.requiresApprovalEnabled', value);
  }, []);

  useEffect(() => {
    if (values.eventParticipantsEnabled || values.officesEnabled) {
      setFieldValue('excludedParticipantsEnabled', !!values.excludedParticipantsEnabled);
    } else {
      setFieldValue('excludedParticipantsEnabled', false);
    }
  }, [values.eventParticipantsEnabled, values.officesEnabled]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.newEventTitle)}
      size={'large'}
      classNameModal={'modal--new-event-type'}
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="modal__event-type-wrapper">
          <div className="modal__event-type-column form__inputs-wrapper">
            <div className="form__input-block flex">
              <Input
                name={'name'}
                label={intl.formatMessage(messages.typeNamelabel)}
                onChange={handleChange}
                defaultValue={values.name}
                hasError={hasError('name')}
                errorMessage={errors.name}
              />
              <ColorInput
                id={'color'}
                externalClass={'color-input-circle'}
                onChange={debounce(handleChange, 50)}
                defaultValue={values.color}
              />
            </div>
            <div className="form__switches-wrapper">
              <div className="switch">
                <Switch onChange={handleChange} name={'titleEnabled'} checked={values.titleEnabled} />
                <div className="switch__title">
                  <FormattedMessage {...messages.titleLabel} />
                </div>
              </div>
              <div className="switch">
                <Switch
                  onChange={handleChange}
                  name={'targetEmployeesEnabled'}
                  checked={values.targetEmployeesEnabled}
                />
                <div className="switch__title">
                  <FormattedMessage {...messages.targetMemberLabel} />
                </div>
              </div>
              <div className="switch">
                <Switch onChange={handleChange} name={'dateRangesEnabled'} checked={values.dateRangesEnabled} />
                <div className="switch__title">
                  <FormattedMessage {...messages.datesRange} />
                </div>
              </div>
              <div className="switch">
                <Switch
                  onChange={handleChange}
                  name={'excludedParticipantsEnabled'}
                  checked={values.excludedParticipantsEnabled}
                  disabled={!values.officesEnabled && !values.eventParticipantsEnabled}
                />
                <div className="switch__title">
                  <FormattedMessage {...messages.excludedParticipantsLabel} />
                </div>
              </div>
              <div className="switch">
                <Switch
                  onChange={handleChange}
                  name={'eventParticipantsEnabled'}
                  checked={values.eventParticipantsEnabled}
                  disabled={values.officesEnabled}
                />
                <div className="switch__title">
                  <FormattedMessage {...messages.participants} />
                </div>
              </div>
              <div className="switch">
                <Switch onChange={handleChange} name={'timeEnabled'} checked={values.timeEnabled} />
                <div className="switch__title">
                  <FormattedMessage {...messages.time} />
                </div>
              </div>
              <div className="switch">
                <Switch onChange={handleChange} name={'questionnairesEnabled'} checked={values.questionnairesEnabled} />
                <div className="switch__title">
                  <FormattedMessage {...messages.questionnaires} />
                </div>
              </div>
              <div className="switch">
                <Switch onChange={handleChange} name={'repeaterEnabled'} checked={values.repeaterEnabled} />
                <div className="switch__title">
                  <FormattedMessage {...messages.repeater} />
                </div>
              </div>
              <div className="switch">
                <Switch
                  onChange={handleChange}
                  name={'officesEnabled'}
                  checked={values.officesEnabled}
                  disabled={values.eventParticipantsEnabled}
                />
                <div className="switch__title">
                  <FormattedMessage {...messages.officeLabel} />
                </div>
              </div>
              <div className="switch">
                <Switch onChange={handleChange} name={'reminderEnabled'} checked={values.reminderEnabled} />
                <div className="switch__title">
                  <FormattedMessage {...messages.reminder} />
                </div>
              </div>
              <div className="switch">
                <Switch onChange={handleChange} name={'addressEnabled'} checked={values.addressEnabled} />
                <div className="switch__title">
                  <FormattedMessage {...messages.addressLabel} />
                </div>
              </div>
              <div className="switch">
                <Switch onChange={handleChange} name="isPublicEvent" checked={values.isPublicEvent} />
                <div className="switch__title">
                  <FormattedMessage {...messages.publicEventsLabel} />
                </div>
              </div>
              <div className="switch switch switch--full">
                <Switch onChange={handleChange} name={'assessmentsEnabled'} checked={values.assessmentsEnabled} />
                <div className="switch__title">
                  <FormattedMessage {...messages.assessments} />
                </div>
              </div>
              <div className="switch switch switch--full switch--parent">
                <Switch
                  onChange={e => handleAbsenceChange(e.target.checked)}
                  name={'absencesSettings.absencesEnabled'}
                  disabled={values.extraWorkingDaysEnabled}
                  checked={values.absencesSettings.absencesEnabled}
                />
                <div className="switch__title">
                  <FormattedMessage {...messages.absence} />
                </div>
              </div>
              {values.absencesSettings?.absencesEnabled && (
                <div className="absence-settings">
                  <Checkbox
                    id={'absencesSettings.finalDecisionOptionsEnabled'}
                    label={intl.formatMessage(messages.finalDecisionOptionLabel)}
                    onChange={handleChange}
                    checked={values.absencesSettings.finalDecisionOptionsEnabled}
                    externalClass="form__checkbox-capitalazie-label"
                  />
                  <Checkbox
                    id={'absencesSettings.optionForExcludeDaysOfAbsenceFromOtherTypes'}
                    label={intl.formatMessage(messages.optionForExcludeDaysOfAbsenceFromOtherTypesLabel)}
                    onChange={handleChange}
                    checked={values.absencesSettings.optionForExcludeDaysOfAbsenceFromOtherTypes}
                    externalClass="form__checkbox-capitalazie-label"
                  />
                  <Checkbox
                    id={'absencesSettings.requiresWorkingOff'}
                    label={intl.formatMessage(messages.requiresWorkingOffLabel)}
                    onChange={handleChange}
                    checked={values.absencesSettings.requiresWorkingOff}
                    externalClass="form__checkbox-capitalazie-label"
                  />
                  <Checkbox
                    id={'absencesSettings.showTotalCounterInProfile'}
                    label={intl.formatMessage(messages.showTotalCounterInProfileLabel)}
                    onChange={handleChange}
                    checked={values.absencesSettings.showTotalCounterInProfile}
                    externalClass="form__checkbox-capitalazie-label"
                  />
                  <Checkbox
                    id={'absencesSettings.requiresApproval.requiresApprovalEnabled'}
                    label={intl.formatMessage(messages.requiresApprovalEnabledLabel)}
                    onChange={e => handleRequiresApprovalChange(e.target.checked)}
                    checked={values.absencesSettings.requiresApproval.requiresApprovalEnabled}
                    externalClass="form__checkbox-capitalazie-label"
                  />
                </div>
              )}
              {values.absencesSettings.requiresApproval.requiresApprovalEnabled && (
                <Select
                  isMulti
                  externalClass="requires-approval"
                  options={usersOptions}
                  handleChange={handleUsersAbleToApprove}
                  label={intl.formatMessage(messages.whoCanApproveThisTypeOfEventsLabel)}
                />
              )}
              <div className="switch switch switch--full">
                <Switch
                  onChange={handleChange}
                  name={'extraWorkingDaysEnabled'}
                  disabled={values.absencesSettings.absencesEnabled}
                  checked={values.extraWorkingDaysEnabled}
                />
                <div className="switch__title">
                  <FormattedMessage {...messages.extraWorkingDays} />
                </div>
              </div>
              <div className="switch switch switch--full switch--parent">
                <Switch
                  onChange={handleChange}
                  name={'limitOfEvents.limitNumberOfEventsPerOfficeEnabled'}
                  checked={values.limitOfEvents.limitNumberOfEventsPerOfficeEnabled}
                />
                <div className="switch__title">
                  <FormattedMessage {...messages.limitDaysOfEvenForEachMemberPerOffice} />
                </div>
              </div>
              {values.limitOfEvents?.limitNumberOfEventsPerOfficeEnabled && (
                <div className="form__group form__group-offices">
                  {values.limitOfEvents.limitNumberOfEventsPerOffice.map((el, index) => {
                    return (
                      <div className="form__group-wrapper" key={`${index}`}>
                        <div className="form__inputs-wrapper">
                          <div className="form__inputs-subwrapper modal__offices-wrapper">
                            <Select
                              externalClass={'form__input-block form__input--semibold office-select'}
                              value={{ label: el.office.name, value: el.office }}
                              options={offices.map(office => {
                                return { label: office.name, value: office };
                              })}
                              handleChange={handleOfficeChange(index)}
                              label={intl.formatMessage(messages.officeLabel)}
                            />
                            <NumberInput
                              name={`limitOfEvents.limitNumberOfEventsPerOffice[${index}].limitNumberPerUser`}
                              wrapperClass={'days-input'}
                              label={intl.formatMessage(messages.daysLabel)}
                              onChange={handleChange}
                              defaultValue={el.limitNumberPerUser}
                              onCountUp={() => {
                                setFieldValue(
                                  `limitOfEvents.limitNumberOfEventsPerOffice[${index}].limitNumberPerUser`,
                                  values.limitOfEvents.limitNumberOfEventsPerOffice[index].limitNumberPerUser + 1,
                                );
                              }}
                              onCountDown={() => {
                                setFieldValue(
                                  `limitOfEvents.limitNumberOfEventsPerOffice[${index}].limitNumberPerUser`,
                                  values.limitOfEvents.limitNumberOfEventsPerOffice[index].limitNumberPerUser - 1,
                                );
                              }}
                              hasError={hasError(
                                `limitOfEvents.limitNumberOfEventsPerOffice[${index}].limitNumberPerUser`,
                              )}
                              errorMessage={
                                //@ts-ignore
                                errors?.limitOfEvents?.limitNumberOfEventsPerOffice[index]?.limitNumberPerUser
                              }
                            />
                            <Input
                              type={'date'}
                              name={`limitOfEvents.limitNumberOfEventsPerOffice[${index}].startDate`}
                              wrapperClass={'date-input'}
                              label={intl.formatMessage(messages.startDateLabel)}
                              onChange={handleChange}
                              defaultValue={el.startDate}
                              hasError={hasError(`limitOfEvents.limitNumberOfEventsPerOffice[${index}].startDate`)}
                              //@ts-ignore
                              errorMessage={errors?.limitOfEvents?.limitNumberOfEventsPerOffice[index]?.startDate}
                            />
                          </div>
                          <Checkbox
                            id={`limitOfEvents.limitNumberOfEventsPerOffice[${index}].calculateAvailableDaysBasedOnDaysWorked`}
                            label={intl.formatMessage(messages.calculateAvailableDaysBasedOnDaysWorkedLabel)}
                            onChange={handleChange}
                            checked={
                              values.limitOfEvents.limitNumberOfEventsPerOffice[index]
                                .calculateAvailableDaysBasedOnDaysWorked
                            }
                            externalClass="form__checkbox-capitalazie-label"
                          />
                        </div>
                        <button className="form__btn-clean-inputs" type={'button'} onClick={removeOffice(index)}>
                          <Icon iconName={'cross'} externalClass={'form__btn-icon'} />
                        </button>
                      </div>
                    );
                  })}
                  <div className="new-office-btn-wrapper">
                    <Button externalClass={'form__btn-add-group'} color={'gray'} type={'button'} onClick={addOffice}>
                      <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
                      <FormattedMessage {...messages.officeLabel} />
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <Select
              isMulti
              options={usersOptions}
              handleChange={handleUsersAbleToManage}
              label={intl.formatMessage(messages.whoCanCreateThisTypeOfEvents)}
            />
          </div>
          <div className="modal__event-type-column">
            <div className="modal__preview-wrapper">
              <div className="modal__preview form__inputs-wrapper ">
                <div className="modal__preview-flag">
                  <FormattedMessage {...messages.previewLabel} />
                </div>
                <div className="modal__head">
                  <div className="modal__title event-circle">
                    <FormattedMessage {...messages.newEventMeeting} />
                    <div className={'event-circle-preview'} style={{ backgroundColor: values.color }} />
                  </div>
                </div>
                <div className="modal__content">
                  <div className="modal__form form form__eventType-wrapper">
                    {values.titleEnabled && <Input name={'title'} label={intl.formatMessage(messages.titleLabel)} />}
                    <div className="form__inputs-subwrapper">
                      {values.dateRangesEnabled ? (
                        <>
                          <Input
                            type={'date'}
                            label={intl.formatMessage(messages.startDateLabel)}
                            name={'startDate'}
                            wrapperClass="form__input-date"
                            defaultValue={moment().format(DATE_FORMAT.YYYY_MM_DD)}
                          />
                          <Input
                            type={'date'}
                            label={intl.formatMessage(messages.endDateLabel)}
                            name={'endDate'}
                            wrapperClass="form__input-date"
                            defaultValue={moment().format(DATE_FORMAT.YYYY_MM_DD)}
                          />
                        </>
                      ) : (
                        <Input
                          type={'date'}
                          label={intl.formatMessage(messages.startDateLabel)}
                          name={'startDate'}
                          wrapperClass="form__input-date"
                          defaultValue={moment().format(DATE_FORMAT.YYYY_MM_DD)}
                        />
                      )}
                      {values.timeEnabled && (
                        <div className="form__input-block form__input-block--half">
                          <Checkbox
                            id={'allDay'}
                            label={intl.formatMessage(messages.allDayOption)}
                            externalClass="form__checkbox-capitalazie-label"
                          />
                        </div>
                      )}
                    </div>
                    {values.timeEnabled && (
                      <div className="form__inputs-subwrapper">
                        <div className="form__event-time-wrapper">
                          <Select
                            label={intl.formatMessage(messages.startTimeInput)}
                            customInput={<Input type={'time'} name={'startTime'} defaultValue="12:00" />}
                            externalClass="time-select"
                          />
                        </div>
                        <div className="form__event-time-wrapper">
                          <Select
                            label={intl.formatMessage(messages.endTimeInput)}
                            customInput={<Input type={'time'} name={'endTime'} defaultValue="13:00" />}
                            externalClass="time-select"
                          />
                        </div>
                      </div>
                    )}
                    {values.repeaterEnabled && <Select label={intl.formatMessage(messages.recurrenceLabel)} />}
                    {values.reminderEnabled && (
                      <>
                        <div className="form__group-wrapper">
                          <div className="form__inputs-subwrapper">
                            <Select label={intl.formatMessage(messages.reminder)} />
                            <NumberInput name="" defaultValue={0} label="Value" />
                          </div>
                          <button className="form__btn-clean-inputs" type={'button'}>
                            <Icon iconName={'cross'} externalClass={'form__btn-icon'} />
                          </button>
                        </div>
                        <Button externalClass={'form__btn-add-group'} color={'gray'} type={'button'}>
                          <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
                          <FormattedMessage {...messages.reminder} />
                        </Button>
                      </>
                    )}
                    {values.targetEmployeesEnabled && (
                      <div className="form__inputs-subwrapper">
                        <Select
                          externalClass={'form__input-block--half'}
                          label={intl.formatMessage(messages.targetMemberLabel)}
                        />
                      </div>
                    )}
                    {values.eventParticipantsEnabled && (
                      <div className="form__input-block">
                        <Select label={intl.formatMessage(messages.participants)} />
                      </div>
                    )}
                    {values.excludedParticipantsEnabled &&
                      (!values.officesEnabled || !values.eventParticipantsEnabled) && (
                        <div className="form__inputs-subwrapper">
                          <Select label={intl.formatMessage(messages.excludedParticipantsLabel)} />
                        </div>
                      )}
                    {values.questionnairesEnabled && <Select label={intl.formatMessage(messages.questionnaireLabel)} />}
                    {values.assessmentsEnabled && <Select label={intl.formatMessage(messages.assessments)} />}
                    {values.officesEnabled && <Select label={intl.formatMessage(messages.officeLabel)} />}
                    {values.addressEnabled && (
                      <div className="form__input-block">
                        <Input tag="textarea" label={intl.formatMessage(messages.addressLabel)} name="address" />
                      </div>
                    )}
                    {values.absencesSettings.optionForExcludeDaysOfAbsenceFromOtherTypes && (
                      <Select label={intl.formatMessage(messages.excludeDaysOfAbsenceFromOtherTypesLabel)} />
                    )}
                    {values.absencesSettings.absencesEnabled && values.absencesSettings.finalDecisionOptionsEnabled && (
                      <Checkbox
                        id={'final'}
                        label={intl.formatMessage(messages.finalDecisionLabel)}
                        externalClass={'label-bold form__checkbox-capitalazie-label absence-checkbox'}
                      />
                    )}
                    <div className="form__input-block textarea-block">
                      <textarea className="form__textarea" id="preview-event-description" />
                      <label className="form__label" htmlFor="preview-event-description">
                        <FormattedMessage {...messages.description} />
                      </label>
                    </div>
                    {values.absencesSettings.absencesEnabled &&
                      values.absencesSettings.requiresApproval.requiresApprovalEnabled && (
                        <Checkbox
                          id={'approve'}
                          label={intl.formatMessage(messages.approve)}
                          externalClass={
                            'label-bold form__checkbox-block--align-right form__checkbox-capitalazie-label absence-checkbox'
                          }
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorMessage>{eventTypeError}</ErrorMessage>
        <div className="modal__event-type-footer">
          <div className="form__buttons">
            <Button externalClass={'button--modal'} type={'button'} onClick={resetAndExit} color="gray">
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewEventType;
