import React, { useContext } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import messages from '../messages';
import { DisplayingResumeSections, Resume } from '../../../enums/resumes.enum';
import { Document, Image, Page, StyleSheet, Text, View, Font } from '@react-pdf/renderer';
import BrandingContext from '../../../BrandingContext';
import Html from 'react-pdf-html';
import logo from '../../../assets/images/Logo.png';
//@ts-ignore
import font from '../../../assets/fonts/Roboto-Bold.ttf';
import { UserInfo } from '../../../enums/users.enum';

export type ExportCVDocumentProps = {
  data: Resume[];
  displayingResumeSections: DisplayingResumeSections;
};

const ExportCVDocument = ({
  data,
  displayingResumeSections: {
    includePhoto = true,
    includeName = true,
    includePosition = true,
    includeExperience = true,
    includeEducation = true,
    includeLanguages = true,
    includeSummary = true,
    includePersonalHighlights = true,
    includeInterestsAndHobbies = true,
    includeTechnicalSkills = true,
    includeProjects = true,
  },
}: ExportCVDocumentProps) => {
  const branding = useContext(BrandingContext);

  Font.register({
    family: 'Roboto-Bold',
    format: 'truetype',
    src: font,
  });

  const styles = StyleSheet.create({
    document: { color: '#4a4a4a', padding: '20px 30px 15px 30px', position: 'relative' },
    pageBorder: {
      width: '586px',
      height: '833px',
      position: 'absolute',
      border: '1px solid #f0f0f0',
      borderRadius: '20px',
      top: '5px',
      left: '5px',
    },
    documentHead: {
      display: 'flex',
      borderBottom: '1px solid #f0f0f0',
      paddingBottom: '10px',
      marginBottom: '30px',
      fontSize: '14px',
      flexDirection: 'row',
      verticalAlign: 'middle',
    },
    documentHeadText: {
      width: '50%',
      verticalAlign: 'middle',
    },
    documentCompany: {
      fontSize: '9px',
      color: '#929292',
      fontFamily: 'Roboto-Bold',
    },
    documentType: { fontSize: '8px', color: '#929292' },
    documentLgo: {
      width: '50%',
      maxHeight: '23px',
      display: 'flex',
      alignItems: 'flex-end',
      verticalAlign: 'middle',
    },
    mainInfoWrapper: { display: 'flex', flexDirection: 'row', marginBottom: '30px' },
    documentAvatarWrap: { width: '230px' },
    documentImg: { width: '160px', height: '160px' },
    documentNoImg: {
      backgroundColor: '#f5f5f5',
      width: '160px',
      height: '160px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    documentInitials: { fontSize: '50px', color: '#929292', fontFamily: 'Roboto-Bold' },
    mainInfo: {
      display: 'flex',
      width: 'calc(100% - 160px)',
      flexDirection: 'column',
      fontSize: '14px',
      lineHeight: 1.12,
      paddingLeft: '20px',
    },
    documentName: { fontSize: '25px', lineHeight: 1, marginBottom: '10px', fontFamily: 'Roboto-Bold' },
    documentPosition: { fontSize: '16px', lineHeight: 1, marginBottom: '10px', fontFamily: 'Roboto-Bold' },
    experienceWrapper: {
      fontSize: '10px',
      lineHeight: 1,
      marginBottom: '10px',
      display: 'flex',
      flexDirection: 'row',
    },
    experienceMain: { fontFamily: 'Roboto-Bold', marginRight: '3px', marginTop: '-0.5px' },
    educationWrapper: {
      fontSize: '10px',
      lineHeight: 1,
      marginBottom: '10px',
    },
    educationMain: { lineHeight: 1.15, marginBottom: '5px', fontFamily: 'Roboto-Bold' },
    languagesWrapper: { fontSize: '10px', lineHeight: 1, marginBottom: '10px' },
    language: { fontFamily: 'Roboto-Bold', marginRight: '3px' },
    documentAdditional: { display: 'flex', flexDirection: 'row' },
    additionalColumn: { width: '50%' },
    additionalItem: { marginBottom: '30px' },
    additionalTitle: {
      fontSize: '14px',
      color: branding.globalAccents,
      fontFamily: 'Roboto-Bold',
      textTransform: 'uppercase',
    },
    additionalContent: { fontSize: '12px' },
    project: { fontSize: '14px', marginTop: '10px' },
    projectName: { fontFamily: 'Roboto-Bold' },
    skillsTable: { border: '1px solid #f0f0f0', marginBottom: '30px' },
    tableRow: { width: '100%', display: 'flex', flexDirection: 'row' },
    tableRowItem: {
      fontSize: '8px',
      padding: ' 4px 10px 2px',
      verticalAlign: 'middle',
      color: '#929292',
    },
    skill: { marginBottom: '6px', fontSize: '10px', color: '#4a4a4a' },
    logoImg: { width: '57px', height: '23px' },
  });

  return (
    <IntlProvider messages={{}} locale="en" defaultLocale="en">
      <Document>
        {data.map(resume => (
          <Page key={resume.id} style={styles.document}>
            <View style={styles.pageBorder} fixed></View>
            <View style={styles.documentHead} fixed>
              <View style={styles.documentHeadText}>
                <Text style={{ ...styles.documentCompany, fontFamily: 'Roboto-Bold' }}>
                  <FormattedMessage {...messages.companyNameLabel} />
                </Text>
                <Text style={styles.documentType}>
                  <FormattedMessage {...messages.memberResumeLabel} />
                </Text>
              </View>
              <View style={styles.documentLgo}>
                {branding?.logoUrl ? (
                  <Image
                    src={{
                      uri: branding?.logoUrl,
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                      body: '',
                    }}
                  />
                ) : (
                  <Image src={logo} style={styles.logoImg} />
                )}
              </View>
            </View>
            <View>
              <View style={styles.mainInfoWrapper}>
                {includePhoto && (
                  <View style={styles.documentAvatarWrap}>
                    {new UserInfo(resume.employeeInfo).photoUrl(320) ? (
                      <Image style={styles.documentImg} src={new UserInfo(resume.employeeInfo).photoUrl(320)} />
                    ) : (
                      <View style={styles.documentNoImg}>
                        <Text style={styles.documentInitials}>{resume.employee.initials}</Text>
                      </View>
                    )}
                  </View>
                )}
                <View style={styles.mainInfo}>
                  {includeName && <Text style={styles.documentName}>{resume.employee.fullName}</Text>}
                  {includePosition && (
                    <Text style={styles.documentPosition}>{resume.companyPosition?.displayName}</Text>
                  )}

                  {includeExperience && (
                    <View style={styles.experienceWrapper}>
                      <Text style={styles.experienceMain}>
                        {resume.experienceYears} <FormattedMessage {...messages.yearsLabel} /> {resume.experienceMonths}{' '}
                        <FormattedMessage {...messages.monthsLabel} />
                      </Text>{' '}
                      <Text>
                        <FormattedMessage {...messages.ofExperienceLabel} />
                      </Text>
                    </View>
                  )}
                  {includeEducation && (
                    <View style={styles.educationWrapper}>
                      <Text style={styles.educationMain}>{resume.educationFirstString}</Text>
                      <Text>{resume.educationSecondString}</Text>
                    </View>
                  )}
                  {includeLanguages && (
                    <View style={styles.languagesWrapper}>
                      {resume.languages.map((item, index) => (
                        <View
                          key={item.foreignLanguageId}
                          style={{
                            marginBottom: index === resume.languages.length - 1 ? '0' : '5px',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Text style={styles.language}>{item.foreignLanguage?.name}</Text>{' '}
                          <Text>{item.languageLevel?.name && ' - ' + item.languageLevel?.name}</Text>
                        </View>
                      ))}
                    </View>
                  )}
                </View>
              </View>
              {includeTechnicalSkills && !!resume.technicalSkills.length && (
                <View style={styles.skillsTable}>
                  <View style={styles.tableRow}>
                    {resume.technicalSkills.map((item, index) => (
                      <View
                        key={index}
                        style={{
                          ...styles.tableRowItem,
                          width: `calc(100% / ${resume.technicalSkills.length})`,
                          borderRight: resume.technicalSkills.length - 1 === index ? 'none' : '1px solid #f0f0f0',
                          justifyContent: 'center',
                          fontFamily: 'Roboto-Bold',
                        }}
                      >
                        <Text>{item.skillGroupName}</Text>
                      </View>
                    ))}
                  </View>
                  <View style={{ ...styles.tableRow, borderTop: '1px solid #f0f0f0' }}>
                    {resume.technicalSkills.map((item, index) => (
                      <View
                        key={index}
                        style={{
                          ...styles.tableRowItem,
                          width: `calc(100% / ${resume.technicalSkills.length})`,
                          borderRight: resume.technicalSkills.length - 1 === index ? 'none' : '1px solid #f0f0f0',
                        }}
                      >
                        <View>
                          {item.skills.map((item, index) => (
                            <Text style={styles.skill} key={index}>
                              {'• ' + item.skill}
                            </Text>
                          ))}
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              )}
              <View style={styles.documentAdditional}>
                {(includeSummary || includePersonalHighlights || includeInterestsAndHobbies) && (
                  <View style={{ ...styles.additionalColumn, paddingRight: '20px' }}>
                    {includeSummary && (
                      <View style={styles.additionalItem}>
                        <Text style={styles.additionalTitle}>
                          <FormattedMessage {...messages.summaryLabel} />
                        </Text>
                        {!!resume.summary && <Html style={styles.additionalContent}>{resume.summary}</Html>}
                      </View>
                    )}
                    {includePersonalHighlights && (
                      <View style={styles.additionalItem}>
                        <Text style={styles.additionalTitle}>
                          <FormattedMessage {...messages.personalHighlightsLabel} />
                        </Text>
                        {!!resume.personalHighlights && (
                          <Html style={styles.additionalContent}>{resume.personalHighlights}</Html>
                        )}
                      </View>
                    )}
                    {includeInterestsAndHobbies && (
                      <View style={styles.additionalItem}>
                        <Text style={styles.additionalTitle}>
                          <FormattedMessage {...messages.interestAndHobbiesLabel} />
                        </Text>
                        {!!resume.interestsAndHobbies && (
                          <Html style={styles.additionalContent}>{resume.interestsAndHobbies}</Html>
                        )}
                      </View>
                    )}
                  </View>
                )}
                <View
                  style={{
                    ...styles.additionalColumn,
                    paddingLeft: `${
                      includeSummary || includePersonalHighlights || includeInterestsAndHobbies ? 20 : 0
                    }px`,
                  }}
                >
                  {includeProjects && (
                    <View style={styles.additionalItem}>
                      <Text style={styles.additionalTitle}>
                        <FormattedMessage {...messages.remarkableProjectsTitle} />
                      </Text>
                      {resume.projects.map((project, index) => (
                        <View
                          style={{
                            ...styles.project,
                            marginBottom: `${index === resume.projects.length - 1 ? 0 : 33}px`,
                          }}
                          key={project.id}
                        >
                          <Text style={styles.projectName}>{project.name}</Text>
                          <Html style={styles.additionalContent}>{project.description}</Html>
                        </View>
                      ))}
                    </View>
                  )}
                </View>
              </View>
            </View>
          </Page>
        ))}
      </Document>
    </IntlProvider>
  );
};

export default ExportCVDocument;
