import React, { useCallback, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import TableUserAvatar from '../../components/TableUserAvatar';
import Dropdown from '../../components/Dropdown';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';
import { DELETE_SALARY, UPDATE_SALARY } from '../../constants/policies.constants';
import Icon from '../../components/Icon';
import { SeleriesMonth, UserReports } from '../../enums/finance/finance.enum';
import classNames from 'classnames';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';
import { UserInfo } from '../../enums/users.enum';

export const useDataForTable = (
  salaries: any,
  getSalary: (salaryId: string) => void,
  setModalEditSalaryIsOpen: (value: boolean) => void,
  setModalDeleteSalaryIsOpen: (value: boolean) => void,
  openNewSalaryModal: () => void,
  setUserMonthClicked: (data: { user: UserInfo; month: string }) => void,
) => {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const updatePolicy = useMemo(() => policies.find(policy => policy.policy.name === UPDATE_SALARY), [policies]);
  const deletePolicy = useMemo(() => policies.find(policy => policy.policy.name === DELETE_SALARY), [policies]);

  const getTableActions: any = useCallback(
    (isSalary: boolean, user: UserInfo) =>
      isSalary
        ? [
            ...(checkPolicies([UPDATE_SALARY], policies) &&
            (!updatePolicy?.isOfficeSpecific || updatePolicy?.officeIds.some(id => id === user?.officeId))
              ? [
                  {
                    label: (
                      <>
                        <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                        <FormattedMessage {...messages.editButton} />
                      </>
                    ),
                    itemClassName: 'dropdown__list-item__button',
                    handler: (row: SeleriesMonth) => {
                      getSalary(row.salaryId);
                      setModalEditSalaryIsOpen(true);
                    },
                  },
                ]
              : []),
            ...(checkPolicies([DELETE_SALARY], policies) &&
            (!deletePolicy?.isOfficeSpecific || deletePolicy?.officeIds.some(id => id === user?.officeId))
              ? [
                  {
                    label: (
                      <>
                        <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
                        <FormattedMessage {...messages.deleteButton} />
                      </>
                    ),
                    itemClassName: 'dropdown__list-item__button',
                    handler: (row: SeleriesMonth) => {
                      getSalary(row.salaryId);
                      setModalDeleteSalaryIsOpen(true);
                    },
                  },
                ]
              : []),
          ]
        : [
            ...(checkPolicies([UPDATE_SALARY], policies) &&
            (!updatePolicy?.isOfficeSpecific || updatePolicy?.officeIds.some(id => id === user?.officeId))
              ? [
                  {
                    label: (
                      <>
                        <Icon iconName={'plus'} externalClass={'dropdown__list-item__icon'} />
                        <FormattedMessage {...messages.newButton} />
                      </>
                    ),
                    itemClassName: 'dropdown__list-item__button',
                    handler: (row: SeleriesMonth) => {
                      openNewSalaryModal();
                      setUserMonthClicked({ user, month: row.month });
                    },
                  },
                ]
              : []),
          ],
    [policies],
  );

  const getSalaryCell = useCallback(
    (item, curentMonth) =>
      !!curentMonth?.total && (
        <div
          className={classNames('table__data-wrapper', 'salary-data ', {
            'current-month': moment(item.month).isSame(moment().startOf('month')),
          })}
        >
          <div className="total-block">{curentMonth?.total}</div>
          <div className="pay-tax-block">
            <div>{curentMonth?.pay}</div>
            <div>{curentMonth?.tax}</div>
          </div>
        </div>
      ),
    [],
  );

  const tableColumns = useMemo(() => {
    let salaryColumns = [];
    const usersColumn = [
      {
        name: intl.formatMessage(messages.employeesLabel),
        modifier: (row: UserReports) =>
          row.totalItem ? (
            <div className="totla-row-item">
              <FormattedMessage {...messages.totalRow} />
            </div>
          ) : (
            <TableUserAvatar users={[row.user]} fileSize={48} />
          ),
      },
    ];

    if (salaries) {
      salaryColumns = salaries[0]?.months.map((item: SeleriesMonth, monthIndex: number) => ({
        name: (
          <>
            <div
              className={classNames('table__data-wrapper', 'salary-data ', {
                'current-month': moment(item.month).isSame(moment().startOf('month')),
              })}
            >
              <div className="total-block">{moment(item.month).format(DATE_FORMAT.MMM)}</div>
              <div className="pay-tax-block">
                <div>
                  <FormattedMessage {...messages.payLabel} />
                </div>
                <div>
                  <FormattedMessage {...messages.taxLabel} />
                </div>
              </div>
            </div>
            {(monthIndex === 0 || moment(item.month).startOf('year').isSame(moment(item.month))) && (
              <div className="year-display">{moment(item.month).format(DATE_FORMAT.YYYY)}</div>
            )}
          </>
        ),
        modifier: (row: UserReports, rowIndex: number) => {
          const curentMonth = row.months[monthIndex];

          return row.totalItem ? (
            getSalaryCell(item, curentMonth)
          ) : !curentMonth?.isHired ? (
            <div className="employee-not-hired"></div>
          ) : (
            <div className="table__dropdown-wrapper">
              <Dropdown
                dropdownClass="dropdown--no-bg"
                dropdownToggle={getSalaryCell(item, curentMonth)}
                dropdownList={getTableActions(!!curentMonth.total, row.user)}
                dropdownInfo={curentMonth}
                dropdownIndex={rowIndex}
              />
            </div>
          );
        },
        className: 'salary-cell',
      }));

      const totalColum = [
        {
          name: (
            <div className="table__data-wrapper salary-data">
              <div className="total-block">
                <FormattedMessage {...messages.totalRow} />
              </div>
              <div className="pay-tax-block">
                <div>
                  <FormattedMessage {...messages.payLabel} />
                </div>
                <div>
                  <FormattedMessage {...messages.taxLabel} />
                </div>
              </div>
            </div>
          ),
          modifier: (row: UserReports) => (
            <div className="table__data-wrapper salary-data">
              <div className="total-block">{row?.total}</div>
              <div className="pay-tax-block">
                <div>{row?.totalPay}</div>
                <div>{row?.totalTax}</div>
              </div>
            </div>
          ),
        },
      ];

      return [...usersColumn, ...salaryColumns, ...totalColum];
    }
    return usersColumn;
  }, [salaries, getTableActions]);

  const tableHeaderItems = [
    { name: '', className: 'employees-column-head header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: 'header_row',
      colspan: (salaries && salaries[0]?.months.length) || 2,
      sortName: '',
    },
    { name: '', className: 'total-column-head header_row', colspan: 1, sortName: '' },
  ];

  return {
    tableColumns,
    tableHeaderItems,
  };
};
