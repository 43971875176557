import * as paramsTypes from '../enums/params/libraries.params';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/libraries.constants';
import * as api from '../api/libraries.api';
import { AnyAction } from 'redux';
import { getQueryParams } from '../utils/params.utils';

function* getOfficesList() {
  const params: paramsTypes.OfficesParams = yield select((state: RootState) => state.libraries.officesParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getOfficesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_OFFICES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_OFFICES_LIST_FAILURE, payload: error });
  }
}

function* createNewOffice({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewOffice, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_OFFICE_SUCCESS });
      payload.callback();
      yield call(getOfficesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_OFFICE_FAILURE, payload: error });
  }
}

function* deleteOffice({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteOffice, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_OFFICE_SUCCESS });
      payload.callback();
      payload.setOfficeCallback();
      yield call(getOfficesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_OFFICE_FAILURE, payload: error });
  }
}

function* editOffice({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editOffice, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_OFFICE_SUCCESS });
      payload.callback();
      yield call(getOfficesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_OFFICE_FAILURE, payload: error });
  }
}

function* getOfficesWorkDays({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getOfficesWorkDays, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_OFFICES_WORK_DAYS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_OFFICES_WORK_DAYS_FAILURE, payload: error });
  }
}

function* getGradesList() {
  const params: paramsTypes.GradesParams = yield select((state: RootState) => state.libraries.gradesParams);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getGradesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_GRADES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_GRADES_LIST_FAILURE, payload: error });
  }
}

function* createNewGrade({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewGrade, payload.data, payload.params);

    if (success) {
      yield put({ type: ActionTypes.POST_GRADE_SUCCESS });
      payload.callback();
      yield call(getGradesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_GRADE_FAILURE, payload: error });
  }
}

function* deleteGrade({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteGrade, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_GRADE_SUCCESS });
      payload.callback();
      payload.setGradeCallback();
      yield call(getGradesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_GRADE_FAILURE, payload: error });
  }
}

function* editGrade({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editGrade, payload.data, payload.params);

    if (success) {
      yield put({ type: ActionTypes.PUT_GRADE_SUCCESS });
      payload.callback();
      yield call(getGradesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_GRADE_FAILURE, payload: error });
  }
}

function* getSpecializationsList() {
  const params: paramsTypes.SpecializationsParams = yield select(
    (state: RootState) => state.libraries.specializationsParams,
  );
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getSpecializationsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_SPECIALIZATIONS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SPECIALIZATIONS_LIST_FAILURE, payload: error });
  }
}
function* getSpecializationsCompetencies() {
  try {
    const { success, results } = yield call(api.getSpecializationsCompetencies);
    if (success) {
      yield put({
        type: ActionTypes.GET_SPECIALIZATIONS_COMPETENCIES_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SPECIALIZATIONS_COMPETENCIES_FAILURE, payload: error });
  }
}

function* createNewSpecialization({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewSpecialization, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_SPECIALIZATION_SUCCESS });
      payload.callback();
      yield call(getSpecializationsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_SPECIALIZATION_FAILURE, payload: error });
  }
}

function* deleteSpecialization({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteSpecialization, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_SPECIALIZATION_SUCCESS });
      payload.callback();
      payload.setSpecializationCallback();
      yield call(getSpecializationsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_SPECIALIZATION_FAILURE, payload: error });
  }
}

function* editSpecialization({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editSpecialization, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_SPECIALIZATION_SUCCESS });
      payload.callback();
      yield call(getSpecializationsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_SPECIALIZATION_FAILURE, payload: error });
  }
}

function* editSpecializationCompetencies({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editSpecializationCompetencies, payload.data);
    if (success) {
      yield put({ type: ActionTypes.PUT_SPECIALIZATION_COMPETENCIES_SUCCESS });
      payload.callback();
      yield call(getSpecializationsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_SPECIALIZATION_COMPETENCIES_FAILURE, payload: error });
  }
}

function* getCompanyPositionsList() {
  const params: paramsTypes.CompanyPositionsParams = yield select(
    (state: RootState) => state.libraries.companyPositionsParams,
  );
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getCompanyPositionsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_COMPANY_POSITIONS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPANY_POSITIONS_LIST_FAILURE, payload: error });
  }
}

function* createNewCompanyPosition({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewCompanyPosition, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_COMPANY_POSITION_SUCCESS });
      payload.callback();
      yield call(getCompanyPositionsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_COMPANY_POSITION_FAILURE, payload: error });
  }
}

function* deleteCompanyPosition({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteCompanyPosition, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_COMPANY_POSITION_SUCCESS });
      payload.callback();
      payload.setCompanyPositionCallback();
      yield call(getCompanyPositionsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_COMPANY_POSITION_FAILURE, payload: error });
  }
}

function* editCompanyPosition({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editCompanyPosition, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_COMPANY_POSITION_SUCCESS });
      payload.callback();
      yield call(getCompanyPositionsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_COMPANY_POSITION_FAILURE, payload: error });
  }
}

function* getDepartmentsList() {
  const params: paramsTypes.DepartmentsParams = yield select((state: RootState) => state.libraries.departmentsParams);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getDepartmentsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_DEPARTMENTS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DEPARTMENTS_LIST_FAILURE, payload: error });
  }
}

function* createNewDepartment({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewDepartment, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_DEPARTMENT_SUCCESS });
      payload.callback();
      yield call(getDepartmentsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_DEPARTMENT_FAILURE, payload: error });
  }
}

function* deleteDepartment({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteDepartment, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_DEPARTMENT_SUCCESS });
      payload.callback();
      payload.setDepartmentCallback();
      yield call(getDepartmentsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_DEPARTMENT_FAILURE, payload: error });
  }
}

function* editDepartment({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editDepartment, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_DEPARTMENT_SUCCESS });
      payload.callback();
      yield call(getDepartmentsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_DEPARTMENT_FAILURE, payload: error });
    yield put({ type: ActionTypes.PUT_OFFICE_FAILURE, payload: error });
  }
}

function* getProjectsList() {
  try {
    const params: paramsTypes.ProjectsParams = yield select((state: RootState) => state.libraries.projectsParams);
    const data = getQueryParams(params);
    const { success, results } = yield call(api.getProjectsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROJECTS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROJECTS_LIST_FAILURE, payload: error });
  }
}

function* createNewProject({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewProject, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_PROJECT_SUCCESS });
      payload.callback();
      yield call(getProjectsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_PROJECT_FAILURE, payload: error });
  }
}

function* deleteProject({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteProject, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_PROJECT_SUCCESS });
      payload.callback();
      payload.setProjectCallback();
      yield call(getProjectsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_PROJECT_FAILURE, payload: error });
  }
}

function* editProject({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editProject, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_PROJECT_SUCCESS });
      payload.callback();
      yield call(getProjectsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROJECT_FAILURE, payload: error });
  }
}

function* getTechnicalSkillsList() {
  const params: paramsTypes.TechnicalSkillsParams = yield select(
    (state: RootState) => state.libraries.technicalSkillsParams,
  );
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getTechnicalSkillsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_TECHNICAL_SKILLS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_TECHNICAL_SKILLS_LIST_FAILURE, payload: error });
  }
}

function* createNewTechnicalSkill({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewTechnicalSkill, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_TECHNICAL_SKILL_SUCCESS });
      payload.callback();
      yield call(getTechnicalSkillsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_TECHNICAL_SKILL_FAILURE, payload: error });
  }
}

function* deleteTechnicalSkill({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteTechnicalSkill, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_TECHNICAL_SKILL_SUCCESS });
      payload.callback();
      payload.setTechnicalSkillCallback();
      yield call(getTechnicalSkillsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_TECHNICAL_SKILL_FAILURE, payload: error });
  }
}

function* editTechnicalSkill({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editTechnicalSkill, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_TECHNICAL_SKILL_SUCCESS });
      payload.callback();
      yield call(getTechnicalSkillsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_TECHNICAL_SKILL_FAILURE, payload: error });
  }
}

function* getSkillLevelsList() {
  try {
    const params: paramsTypes.SkillLevelsParams = yield select((state: RootState) => state.libraries.skillLevelsParams);
    const data = getQueryParams(params);
    const { success, results } = yield call(api.getSkillLevelsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_SKILL_LEVELS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SKILL_LEVELS_LIST_FAILURE, payload: error });
  }
}

function* createNewSkillLevel({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewSkillLevel, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_SKILL_LEVEL_SUCCESS });
      payload.callback();
      yield call(getSkillLevelsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_SKILL_LEVEL_FAILURE, payload: error });
  }
}

function* deleteSkillLevel({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteSkillLevel, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_SKILL_LEVEL_SUCCESS });
      payload.callback();
      payload.setSkillLevelCallback();
      yield call(getSkillLevelsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_SKILL_LEVEL_FAILURE, payload: error });
  }
}

function* editSkillLevel({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editSkillLevel, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_SKILL_LEVEL_SUCCESS });
      payload.callback();
      yield call(getSkillLevelsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_SKILL_LEVEL_FAILURE, payload: error });
  }
}

function* getLanguageLevelsList() {
  try {
    const params: paramsTypes.LanguageLevelsParams = yield select(
      (state: RootState) => state.libraries.languageLevelsParams,
    );
    const data = getQueryParams(params);
    const { success, results } = yield call(api.getLanguageLevelsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_LANGUAGE_LEVELS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_LANGUAGE_LEVELS_LIST_FAILURE, payload: error });
  }
}

function* createNewLanguageLevel({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewLanguageLevel, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_LANGUAGE_LEVEL_SUCCESS });
      payload.callback();
      yield call(getLanguageLevelsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_LANGUAGE_LEVEL_FAILURE, payload: error });
  }
}

function* deleteLanguageLevel({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteLanguageLevel, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_LANGUAGE_LEVEL_SUCCESS });
      payload.callback();
      payload.setLanguageLevelCallback();
      yield call(getLanguageLevelsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_LANGUAGE_LEVEL_FAILURE, payload: error });
  }
}

function* editLanguageLevel({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editLanguageLevel, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_LANGUAGE_LEVEL_SUCCESS });
      payload.callback();
      yield call(getLanguageLevelsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_LANGUAGE_LEVEL_FAILURE, payload: error });
  }
}

function* getForeignLanguagesList() {
  try {
    const params: paramsTypes.ForeignLanguagesParams = yield select(
      (state: RootState) => state.libraries.foreignLanguagesParams,
    );
    const data = getQueryParams(params);
    const { success, results } = yield call(api.getForeignLanguagesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_FOREIGN_LANGUAGES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FOREIGN_LANGUAGES_LIST_FAILURE, payload: error });
  }
}

function* createNewForeignLanguage({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewForeignLanguage, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_FOREIGN_LANGUAGE_SUCCESS });
      payload.callback();
      yield call(getForeignLanguagesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_FOREIGN_LANGUAGE_FAILURE, payload: error });
  }
}

function* deleteForeignLanguage({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteForeignLanguage, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_FOREIGN_LANGUAGE_SUCCESS });
      payload.callback();
      payload.setForeignLanguageCallback();
      yield call(getForeignLanguagesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_FOREIGN_LANGUAGE_FAILURE, payload: error });
  }
}

function* editForeignLanguage({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editForeignLanguage, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_FOREIGN_LANGUAGE_SUCCESS });
      payload.callback();
      yield call(getForeignLanguagesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_FOREIGN_LANGUAGE_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeEvery(ActionTypes.GET_OFFICES_LIST_REQUEST, getOfficesList),
    takeEvery(ActionTypes.POST_OFFICE_REQUEST, createNewOffice),
    takeEvery(ActionTypes.DELETE_OFFICE_REQUEST, deleteOffice),
    takeEvery(ActionTypes.PUT_OFFICE_REQUEST, editOffice),
    takeEvery(ActionTypes.SET_OFFICES_PARAMS, getOfficesList),
    takeEvery(ActionTypes.GET_OFFICES_WORK_DAYS_REQUEST, getOfficesWorkDays),
    takeEvery(ActionTypes.GET_GRADES_LIST_REQUEST, getGradesList),
    takeEvery(ActionTypes.POST_GRADE_REQUEST, createNewGrade),
    takeEvery(ActionTypes.DELETE_GRADE_REQUEST, deleteGrade),
    takeEvery(ActionTypes.PUT_GRADE_REQUEST, editGrade),
    takeEvery(ActionTypes.GET_SPECIALIZATIONS_LIST_REQUEST, getSpecializationsList),
    takeEvery(ActionTypes.SET_SPECIALIZATIONS_PARAMS, getSpecializationsList),
    takeEvery(ActionTypes.GET_SPECIALIZATIONS_COMPETENCIES_REQUEST, getSpecializationsCompetencies),
    takeEvery(ActionTypes.POST_SPECIALIZATION_REQUEST, createNewSpecialization),
    takeEvery(ActionTypes.DELETE_SPECIALIZATION_REQUEST, deleteSpecialization),
    takeEvery(ActionTypes.PUT_SPECIALIZATION_REQUEST, editSpecialization),
    takeEvery(ActionTypes.PUT_SPECIALIZATION_COMPETENCIES_REQUEST, editSpecializationCompetencies),
    takeEvery(ActionTypes.GET_COMPANY_POSITIONS_LIST_REQUEST, getCompanyPositionsList),
    takeEvery(ActionTypes.POST_COMPANY_POSITION_REQUEST, createNewCompanyPosition),
    takeEvery(ActionTypes.DELETE_COMPANY_POSITION_REQUEST, deleteCompanyPosition),
    takeEvery(ActionTypes.PUT_COMPANY_POSITION_REQUEST, editCompanyPosition),
    takeEvery(ActionTypes.GET_DEPARTMENTS_LIST_REQUEST, getDepartmentsList),
    takeEvery(ActionTypes.SET_DEPARTMENTS_PARAMS, getDepartmentsList),
    takeEvery(ActionTypes.POST_DEPARTMENT_REQUEST, createNewDepartment),
    takeEvery(ActionTypes.DELETE_DEPARTMENT_REQUEST, deleteDepartment),
    takeEvery(ActionTypes.PUT_DEPARTMENT_REQUEST, editDepartment),
    takeEvery(ActionTypes.GET_PROJECTS_LIST_REQUEST, getProjectsList),
    takeEvery(ActionTypes.SET_PROJECTS_PARAMS, getProjectsList),
    takeEvery(ActionTypes.POST_PROJECT_REQUEST, createNewProject),
    takeEvery(ActionTypes.DELETE_PROJECT_REQUEST, deleteProject),
    takeEvery(ActionTypes.PUT_PROJECT_REQUEST, editProject),
    takeEvery(ActionTypes.GET_TECHNICAL_SKILLS_LIST_REQUEST, getTechnicalSkillsList),
    takeEvery(ActionTypes.SET_TECHNICAL_SKILLS_PARAMS, getTechnicalSkillsList),
    takeEvery(ActionTypes.POST_TECHNICAL_SKILL_REQUEST, createNewTechnicalSkill),
    takeEvery(ActionTypes.DELETE_TECHNICAL_SKILL_REQUEST, deleteTechnicalSkill),
    takeEvery(ActionTypes.PUT_TECHNICAL_SKILL_REQUEST, editTechnicalSkill),
    takeEvery(ActionTypes.GET_SKILL_LEVELS_LIST_REQUEST, getSkillLevelsList),
    takeEvery(ActionTypes.POST_SKILL_LEVEL_REQUEST, createNewSkillLevel),
    takeEvery(ActionTypes.DELETE_SKILL_LEVEL_REQUEST, deleteSkillLevel),
    takeEvery(ActionTypes.PUT_SKILL_LEVEL_REQUEST, editSkillLevel),
    takeEvery(ActionTypes.GET_LANGUAGE_LEVELS_LIST_REQUEST, getLanguageLevelsList),
    takeEvery(ActionTypes.POST_LANGUAGE_LEVEL_REQUEST, createNewLanguageLevel),
    takeEvery(ActionTypes.DELETE_LANGUAGE_LEVEL_REQUEST, deleteLanguageLevel),
    takeEvery(ActionTypes.PUT_LANGUAGE_LEVEL_REQUEST, editLanguageLevel),
    takeEvery(ActionTypes.GET_FOREIGN_LANGUAGES_LIST_REQUEST, getForeignLanguagesList),
    takeEvery(ActionTypes.SET_FOREIGN_LANGUAGE_PARAMS, getForeignLanguagesList),
    takeEvery(ActionTypes.POST_FOREIGN_LANGUAGE_REQUEST, createNewForeignLanguage),
    takeEvery(ActionTypes.DELETE_FOREIGN_LANGUAGE_REQUEST, deleteForeignLanguage),
    takeEvery(ActionTypes.PUT_FOREIGN_LANGUAGE_REQUEST, editForeignLanguage),
  ]);
}
