import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { useIntl } from 'react-intl';
import messages from './messages';
import { monthNames } from './utils';
import ColorContext from '../../ColorContext';
import BrandingContext from '../../BrandingContext';
import { DATE_FORMAT } from '../../constants/date.constants';
import Icon from '../Icon';
import classNames from 'classnames';
import { monthPeriod, weekPeriod } from '../InlineDatePicker/constants';

type CustomDateRangePickerProps = {
  startDate?: string;
  endDate?: string;
  dateFormatStart?: any;
  dateFormatEnd?: any;
  isSingleDatePicker?: boolean;
  isFilter?: boolean;
  filterLabel?: string;
  parentEl?: string;
  containerExternalClass?: string;
  todayBtn?: boolean;
  weekBtn?: boolean;
  monthBtn?: boolean;
  onChange: (start: string, end: string) => void;
  handleHide?: () => void;
};

function CustomDateRangePicker({
  startDate,
  endDate,
  isSingleDatePicker,
  dateFormatStart,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dateFormatEnd,
  isFilter,
  filterLabel,
  parentEl = '.datepicker-container',
  containerExternalClass,
  todayBtn = true,
  weekBtn = true,
  monthBtn = true,
  onChange,
  handleHide,
}: CustomDateRangePickerProps) {
  const intl = useIntl();
  const ref = useRef<any>(null);
  const buffer = useRef<any>(null);
  const [inputValue, setInputValue] = useState({
    start: moment(startDate),
    end: moment(endDate),
  });
  const [isAllPeriod, setIsAllPeriod] = useState(isFilter && !startDate && !endDate);
  const getFilterValue = (start = inputValue.start, end = inputValue.end) =>
    !isAllPeriod
      ? !isSingleDatePicker
        ? `${start.format(DATE_FORMAT.MMM_D_YYYY)} - ${end.format(DATE_FORMAT.MMM_D_YYYY)}`
        : start.format(DATE_FORMAT.MMM_D_YYYY)
      : 'All';
  const [bufferValue, setBufferValue] = useState(getFilterValue());
  const [opensPos, setOpensPos] = useState('left');

  const branding = useContext(BrandingContext);
  const text = useContext(ColorContext);
  const style: any = {
    '--dateRangepicker-global-color': branding?.globalAccents,
    '--text-global-color': text,
  };
  const containerClass = classNames('datepicker-container', {
    [containerExternalClass as string]: containerExternalClass,
    [opensPos as string]: opensPos,
  });

  const initialSettings = useMemo(
    () => ({
      buttonClasses: 'button',
      autoApply: isSingleDatePicker,
      applyButtonClasses: 'apply--button',
      singleDatePicker: isSingleDatePicker,
      locale: {
        format: DATE_FORMAT.MMM_D_YYYY,
        applyLabel: intl.formatMessage(messages.applyButton),
        monthNames,
      },
      opens: opensPos,
      drops: 'down',
      startDate: dateFormatStart,
      parentEl,
      ...(!isSingleDatePicker && {
        ranges: {
          ...(isFilter && { [intl.formatMessage(messages.allLabel)]: ['', ''] }),
          ...(todayBtn && { [intl.formatMessage(messages.todayBtn)]: [moment(), moment()] }),
          ...(weekBtn && {
            [intl.formatMessage(messages.thisWeekBtn)]: [moment().startOf(weekPeriod), moment().endOf(weekPeriod)],
          }),
          ...(monthBtn && {
            [intl.formatMessage(messages.thisMonthBtn)]: [moment().startOf(monthPeriod), moment().endOf(monthPeriod)],
          }),
        },
      }),
    }),
    [isSingleDatePicker, parentEl, opensPos, todayBtn, weekBtn, monthBtn],
  );

  const handleDateChange = (start: string, end: string) => {
    setInputValue({ start: moment(start), end: moment(end) });
  };

  const handleApply = useCallback(
    (event, { startDate: start, endDate: end }) => {
      if (start.isValid() && end.isValid()) {
        setIsAllPeriod(false);
        onChange(start.format(), end.format());
        setInputValue({ start, end });
        setBufferValue(getFilterValue(start, end));
      } else if (isFilter) {
        setIsAllPeriod(true);

        const current = ref.current;
        if (current) {
          current.setStartDate(moment());
          current.setEndDate(moment());
        }
        onChange('', '');
      }
    },
    [onChange, setInputValue],
  );

  const handleCloseCalendar = useCallback(() => {
    handleHide && handleHide();
  }, [handleHide, startDate, endDate]);

  useEffect(() => {
    startDate && endDate && handleDateChange(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    const current = ref.current;
    if (current) {
      const start = startDate ? moment(startDate) : moment();
      const end = endDate ? moment(endDate) : moment();
      current.setStartDate(startDate ? moment(startDate) : moment());
      current.setEndDate(endDate ? moment(endDate) : moment());
      setBufferValue(getFilterValue(start, end));
    }
  }, [ref, startDate, endDate]);

  const handlePressEnter = useCallback(
    e => {
      if (e.key === 'Enter') {
        setBufferValue(e.target.value);
        //TODO
        // onChange(
        //   inputValue.start.format(dateFormatStart ? dateFormatStart : DATE_FORMAT.YYYY_MM_DD),
        //   inputValue.end.format(dateFormatEnd ? dateFormatEnd : DATE_FORMAT.YYYY_MM_DD),
        // );
      }
    },
    [inputValue],
  );

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBufferValue(e.target.value);
  };

  const handleShow = useCallback(
    e => {
      if (isFilter && isAllPeriod) {
        const rangesUl = document.querySelectorAll(`.datepicker-container.${containerExternalClass} [data-range-key]`);
        const todayClassList = rangesUl[1].classList;
        if (todayClassList.contains('active')) {
          rangesUl[0].classList.add('active');
          rangesUl[1].classList.remove('active');
        }
      }
      const x = (e.target as HTMLElement).getBoundingClientRect().x;
      const offsetWidthHalf = document.documentElement.offsetWidth / 2;
      if (x < offsetWidthHalf) {
        setOpensPos('rigth');
      } else {
        setOpensPos('left');
      }
    },
    [isAllPeriod],
  );

  useEffect(() => {
    const handleInputWidth = () => {
      const current = ref.current;
      const bufferElem = buffer.current;
      if (current && bufferElem) {
        current.ref.style.width = `${bufferElem.clientWidth}px`;
      }
    };
    handleInputWidth();
  }, [bufferValue]);

  return (
    <div className={containerClass} style={style}>
      <DateRangePicker
        ref={ref}
        initialSettings={initialSettings}
        onApply={handleApply}
        onHide={handleCloseCalendar}
        onShow={handleShow}
      >
        {isFilter ? (
          <div className="filter__datepicker-container">
            {filterLabel}:&nbsp;
            {getFilterValue()}
            <div className="filter__indicators">
              <Icon iconName="filter-arrow" />
            </div>
          </div>
        ) : (
          <input
            id="rangepicker"
            type="text"
            className={'daterangepicker-input current_period'}
            onKeyDown={handlePressEnter}
            onChange={handleChangeInput}
            onBlur={handleChangeInput}
          />
        )}
      </DateRangePicker>
      {!isFilter && (
        <div ref={buffer} className="input-buffer">
          {bufferValue}
        </div>
      )}
    </div>
  );
}

export default CustomDateRangePicker;
