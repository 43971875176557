import { defineMessages } from 'react-intl';
import {
  officesLabel,
  departmentsLabel,
  employeesLabel,
  activeLabel,
  inactiveLabel,
  statusLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  officesLabel,
  departmentsLabel,
  employeesLabel,
  activeLabel,
  inactiveLabel,
  statusLabel,
  groupByProjectTitle: {
    id: 'reports.employees.issue.types.filters.label.group.by.project',
    defaultMessage: 'Group By Project',
  },
});
