import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type InputProps = {
  id: string;
  placeholder?: string;
  defaultValue?: string | number;
  onChange?: (data: unknown) => void;
  externalClass?: string;
  label?: string;
  readOnly?: boolean;
  bounds?: string;
};

const formats = ['bold', 'italic', 'list', 'bullet', 'link', 'color'];

function TextArea({
  id,
  placeholder,
  defaultValue,
  onChange,
  externalClass,
  label,
  readOnly = false,
  bounds,
}: InputProps) {
  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const modules = useMemo(() => {
    return {
      toolbar: readOnly
        ? false
        : {
            container: `#${id}`,
          },
    };
  }, []);

  const Toolbar = useMemo(() => {
    return () => (
      <div id={id} className={'toolbar'}>
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
        </span>
        <span className="ql-formats">
          <select className="ql-color" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
        </span>
      </div>
    );
  }, []);

  const handleChange = (html: string) => {
    setValue(html);
    if (onChange) {
      onChange(html);
    }
  };

  const defaultClass = classNames('text-editor', externalClass, { 'with-label': label });

  return (
    <div className={defaultClass}>
      <p className={'form__label textarea_label'}>{label}</p>
      <div className={'form__textarea-wrapper'}>
        <ReactQuill
          value={value as string}
          onChange={handleChange}
          placeholder={placeholder}
          modules={modules}
          formats={formats}
          readOnly={readOnly}
          className={'form__textarea'}
          bounds={bounds}
        />
      </div>
      {!readOnly && <Toolbar />}
    </div>
  );
}

export default TextArea;
