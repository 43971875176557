import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  activeLabel,
  deleteModalTitle,
  deleteButton,
  label,
  urlLabel,
  resetButton,
  iconLabel,
  removeButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  deleteModalTitle,
  deleteButton,
  activeLabel,
  label,
  urlLabel,
  resetButton,
  iconLabel,
  removeButton,
  newMenuLinkTitle: {
    id: 'settings.navigation.modal.new.title.new.menu.link',
    defaultMessage: 'New Menu Link',
  },
  editMenuLinkTitle: {
    id: 'settings.navigation.modal.edit.title.edit.menu.link',
    defaultMessage: 'Edit Menu Link',
  },
});
