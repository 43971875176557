import React, { useCallback, useMemo } from 'react';
import { OptionTypeBase } from 'react-select';
import Icon from '../../../../../components/Icon';
import Select from '../../../../../components/Select';
import { DecimalSeparatorEnum, matchOptions, MatchOptionsValues, numberFormatOptions } from '../../../utils';

type FormItemProps = {
  field: string;
  index: number;
  matchInfo: {
    field: string;
    value: MatchOptionsValues;
  }[];
  decimalSeparator: DecimalSeparatorEnum | null;
  setMatchField: (field: string, value: any) => void;
  setDecimalSeparator: (separator: DecimalSeparatorEnum) => void;
};

function FormItem({ field, index, matchInfo, decimalSeparator, setMatchField, setDecimalSeparator }: FormItemProps) {
  const formControl = useCallback(
    val => (
      <div className="form__select">
        <span>{val.label}</span>
        <Icon iconName="select-arrow" />
      </div>
    ),
    [],
  );

  const selectValue = useMemo(() => {
    const matchFormValue = matchInfo.find(el => el.field === field) || null;
    return matchOptions.find(opt => opt.value === matchFormValue?.value) || matchOptions[0];
  }, [matchOptions, matchInfo]);

  const selectOptions = useMemo(() => {
    return matchOptions.map(opt => ({
      ...opt,
      isDisabled:
        matchInfo.some(info => info.value === opt.value && info.field !== field) &&
        opt.value !== MatchOptionsValues.DO_NOT_IMPORT,
    }));
  }, [matchInfo, matchOptions, field]);

  const numberFormatValue = useMemo(
    () => (decimalSeparator ? numberFormatOptions.find(opt => opt.value === decimalSeparator) : null),
    [decimalSeparator],
  );

  const handleChange = useCallback(({ value }: OptionTypeBase) => {
    setMatchField(`matchInfo[${index}]`, { field, value });
  }, []);

  const handleDecimalSeparatorChange = useCallback(({ value }: OptionTypeBase) => {
    setDecimalSeparator(value);
  }, []);

  const isSelected = selectValue.value !== MatchOptionsValues.DO_NOT_IMPORT;
  return (
    <div className={`form__item ${isSelected ? 'isSelected' : ''}`}>
      <p className="form__title">{field}</p>
      <div className="form__select-container">
        <Select
          externalMenuClass="transactions__form-select"
          options={selectOptions}
          handleChange={handleChange}
          value={selectValue}
          control={formControl}
        />
        {selectValue.value === MatchOptionsValues.AMOUNT && (
          <Select
            externalMenuClass="transactions__form-select"
            options={numberFormatOptions}
            defaultValue={numberFormatOptions[0]}
            value={numberFormatValue}
            control={formControl}
            handleChange={handleDecimalSeparatorChange}
          />
        )}
      </div>
      {isSelected && <Icon iconName="checkbox" />}
    </div>
  );
}

export default React.memo(FormItem);
