import { defineMessages } from 'react-intl';
import {
  newButton,
  editButton,
  deleteButton,
  nameColumn,
  exportToXLSLabel,
  exportToPDFLabel,
  copyURLLabel,
  totalRow,
  departmentsMembersLabel,
  experienceLabel,
  positionLabel,
  plannedLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  exportToXLSLabel,
  exportToPDFLabel,
  copyURLLabel,
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  totalRow,
  departmentsMembersLabel,
  experienceLabel,
  positionLabel,
  plannedLabel,
  pageTitle: {
    id: 'reports.users.hours.absences.title.users.hours.absences',
    defaultMessage: 'Users, Hours, Absences',
  },
  submittedWorkDaysColumn: {
    id: 'reports.users.hours.absences.column.submitted.work.days',
    defaultMessage: 'Submitted (work days)',
  },
  submittedAllDaysColumn: {
    id: 'reports.users.hours.absences.column.submitted.all.days',
    defaultMessage: 'Submitted (all days)',
  },
  shortageExcessColumn: {
    id: 'reports.users.hours.absences.column.shortage.excess',
    defaultMessage: 'Shortage / Excess',
  },
  absencesColumn: {
    id: 'reports.users.hours.absences.column.absences',
    defaultMessage: 'Absences',
  },
});
