import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { downloadFile } from './utilts';
import * as exportActions from '../../../actions/export.actions';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const Loader = () => (
  <div className="loadingio-spinner-spin-wnal38ivfa">
    <div className="ldio-puy6rw8oq6m">
      <div>
        <div></div>
      </div>
      <div>
        <div></div>
      </div>
      <div>
        <div></div>
      </div>
      <div>
        <div></div>
      </div>
      <div>
        <div></div>
      </div>
      <div>
        <div></div>
      </div>
      <div>
        <div></div>
      </div>
      <div>
        <div></div>
      </div>
    </div>
  </div>
);

function HeaderLoader({
  progressEvent,
  exportData,
  exportInfo,
  isPendingRequests,
  resetExportedData,
  cancelExport,
}: ConnectedProps<typeof connector>) {
  const cancelRequest = () => {
    cancelExport();
    resetExportedData();
  };

  useEffect(() => {
    if (exportData) {
      downloadFile(exportData, exportInfo, resetExportedData);
    }
  }, [exportData]);

  if (isPendingRequests || progressEvent) {
    const percentCompleted =
      progressEvent && progressEvent.total ? Math.floor((progressEvent.loaded / progressEvent.total) * 100) : 0;

    return (
      <div className="export-container">
        <div className="header__export-wrapper">
          {isPendingRequests ? <Loader /> : `${percentCompleted}%`}
          <span className="header__export-text">
            <FormattedMessage {...messages.exportingLabel} />
          </span>
          <button className="header__export-btn" onClick={cancelRequest}>
            <FormattedMessage {...messages.cancelButton} />
          </button>
        </div>
      </div>
    );
  }

  return null;
}

const mapStateToProps = ({ exportReducer }: RootState) => ({
  progressEvent: exportReducer.progressEvent,
  exportData: exportReducer.exportData,
  exportInfo: exportReducer.exportInfo,
  isPendingRequests: exportReducer.isPendingRequests,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  cancelExport: () => dispatch(exportActions.cancelExport()),
  resetExportedData: () => dispatch(exportActions.resetExportedData()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(HeaderLoader);
