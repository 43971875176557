import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import Select from '../../Select';
import Icon from '../../Icon';
import { RESOURCE_CREATE_SCHEMA } from '../../../enums/planning.enum';
import NumberInput from '../../NumberInput';
import classNames from 'classnames';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { find } from 'lodash-es';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { scrollToError } from '../../../utils';

type ModalEditResourceProps = {
  onCloseRequest: () => void;
  createNewResources: (data: any) => void;
  resourcesDataError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  projects: { id: string; fullName: string }[];
  usersOptions: { value: string; label: string }[];
  currentResource: {
    user: {
      id: string;
    };
    endDate: string;
    startDate: string;
    workloads: { percent: number; projectGroup: { fullName: string; id: string | null } }[];
  } | null;
  isCurrentResourceLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalEditResource({
  onCloseRequest,
  createNewResources,
  resourcesDataError,
  isLoading,
  projects,
  usersOptions,
  currentResource,
  isCurrentResourceLoading,
  resetErrors,
  isOpen,
}: ModalEditResourceProps) {
  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik({
    initialValues: {
      userIds: currentResource ? [currentResource.user.id] : [],
      endDate: currentResource ? currentResource.endDate : moment().format(DATE_FORMAT.YYYY_MM_DD),
      resourceSaveType: 'REPLACE_FILLED_DAYS',
      startDate: currentResource ? currentResource.startDate : moment().format(DATE_FORMAT.YYYY_MM_DD),
      workloads: currentResource?.workloads.length
        ? currentResource.workloads
        : [{ percent: 100, projectGroup: { fullName: '', id: null } }],
    },
    validationSchema: RESOURCE_CREATE_SCHEMA,
    validateOnChange: false,
    validate: scrollToError,
    onSubmit: data => createNewResources({ data: data, callback: resetAndExit }),
  });

  useSetFieldsErrors(resourcesDataError, setFieldError);

  useEffect(() => {
    if (currentResource)
      setValues({
        userIds: currentResource ? [currentResource.user.id] : [],
        endDate: currentResource ? currentResource.endDate : moment().format(DATE_FORMAT.YYYY_MM_DD),
        resourceSaveType: 'REPLACE_FILLED_DAYS',
        startDate: currentResource ? currentResource.startDate : moment().format(DATE_FORMAT.YYYY_MM_DD),
        workloads: currentResource?.workloads.length
          ? currentResource.workloads
          : [{ percent: 100, projectGroup: { fullName: '', id: null } }],
      });
  }, [currentResource]);

  const resourceSaveTypeOptions = [
    { value: 'DENY_REPLACE_FILLED_DAYS', label: intl.formatMessage(messages.denyReplaceFilledDaysLabel) },
    { value: 'REPLACE_FILLED_DAYS', label: intl.formatMessage(messages.replaceFilledDaysLabel) },
    { value: 'FILL_EMPTY_FIELDS', label: intl.formatMessage(messages.fillEmptyFieldsLabel) },
  ];

  const projectOptions = useMemo(() => projects.map(({ id, fullName }) => ({ label: fullName, value: id })), [
    projects,
  ]);

  const projectValue = useCallback(
    index =>
      values.workloads[index].projectGroup &&
      projectOptions?.find(({ value }: { value: string }) => value === values.workloads[index].projectGroup?.id),
    [projectOptions, values],
  );

  const resourceSaveTypeValue = useMemo(
    () => resourceSaveTypeOptions.find(({ value }) => value === values.resourceSaveType),
    [resourceSaveTypeOptions, values],
  );

  const addNewWorkloads = useCallback(() => {
    setFieldValue(`workloads`, [
      ...values.workloads,
      { percent: values.workloads.length === 0 ? 100 : 0, projectGroup: undefined },
    ]);
  }, [values]);

  const removeWorkloads = useCallback(
    index => () => {
      values.workloads.splice(index, 1);
      setFieldValue(`workloads`, values.workloads);
      if (values.workloads.length === 1) {
        setFieldValue(`workloads[0].percent`, 100);
      }
    },
    [values],
  );

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleChangeEmployees = useCallback(value => {
    setFieldValue(
      'userIds',
      value.map((item: any) => item.value),
    );
  }, []);

  const handleChangeResourceSaveType = useCallback(value => {
    setFieldValue('resourceSaveType', value.value);
  }, []);

  const handleChangePercent = useCallback(
    (value, index) => {
      let newValue = value;

      if (newValue > 100) {
        newValue = 100;
      } else if (newValue < 0) {
        newValue = 0;
      }

      setFieldValue(`workloads[${index}].percent`, newValue).then(() => {
        if (values.workloads.length === 2) {
          const otherIndex = values.workloads.findIndex((workload, i) => i !== index);
          setFieldValue(`workloads[${otherIndex}].percent`, 100 - newValue);
        }
      });
    },

    [values],
  );

  const percentPlus = useCallback(
    index => () => {
      if (+values.workloads[index].percent + 5 >= 100) {
        handleChangePercent(100, index);
      } else {
        handleChangePercent(+values.workloads[index].percent + 5, index);
      }
    },
    [values],
  );

  const percentMinus = useCallback(
    index => () => {
      if (+values.workloads[index].percent - 5 <= 0) {
        handleChangePercent(0, index);
      } else {
        handleChangePercent(+values.workloads[index].percent - 5, index);
      }
    },
    [values],
  );

  const handleChangeProject = useCallback((item, index) => {
    setFieldValue(`workloads[${index}].projectGroup`, item.value && { fullName: item.label, id: item.value });
    (document.querySelector(`[name="workloads[${index}].percent"]`) as HTMLInputElement)?.select();
  }, []);

  const workloadsErrors: any = useMemo(() => errors.workloads, [errors.workloads]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editModalTitle)}
      classNameModal="center"
    >
      <form className="modal__form form new_resource_modal" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block ">
            <Select
              label={intl.formatMessage(messages.employeesLabel)}
              name={'userIds'}
              options={usersOptions}
              handleChange={handleChangeEmployees}
              hasError={hasError('userIds')}
              errorMessage={errors?.userIds as string}
              isMulti={true}
              isSearchable={true}
              value={values.userIds.map((id: string) => ({
                label: `${find(usersOptions, el => el.value === id)?.label}`,
                value: id,
              }))}
              isDisabled={isCurrentResourceLoading}
            />
          </div>
          <div className="form__inputs-subwrapper date_block">
            <div className="form__input-block form__input-block--half">
              <Input
                id={'startDate'}
                type={'date'}
                label={intl.formatMessage(messages.startDateLabel)}
                name={'startDate'}
                onChange={handleChange}
                hasError={hasError('startDate')}
                errorMessage={errors?.startDate}
                defaultValue={values.startDate}
                disabled={isCurrentResourceLoading}
              />
            </div>
            <div className="form__input-block form__input-block--half">
              <Input
                id={'endDate'}
                name={'endDate'}
                type={'date'}
                label={intl.formatMessage(messages.endDateLabel)}
                onChange={handleChange}
                hasError={hasError('endDate')}
                errorMessage={errors?.endDate}
                defaultValue={values.endDate}
                disabled={isCurrentResourceLoading}
              />
            </div>
          </div>
          {values.workloads.map((item: any, index: number) => (
            <div className="form__inputs-subwrapper project_block" key={index}>
              <div className="form__inputs-subwrapper">
                <div className="form__input-block form__input-block--two-third" key={index}>
                  <Select
                    label={intl.formatMessage(messages.projectLabel)}
                    name={`workloads[${index}].projectGroup`}
                    options={projectOptions}
                    value={projectValue(index)}
                    handleChange={value => handleChangeProject(value, index)}
                    hasError={hasError(`workloads[${index}].projectGroup`)}
                    errorMessage={workloadsErrors && workloadsErrors[index]?.projectGroup?.fullName}
                    isSearchable={true}
                    isDisabled={isCurrentResourceLoading}
                    isClearable
                  />
                </div>
                <div
                  className={classNames('form__input-block form__input-block--third percent_block', {
                    disabled: values.workloads.length === 1,
                  })}
                >
                  <NumberInput
                    name={`workloads[${index}].percent`}
                    label={`%`}
                    onChange={event => {
                      handleChangePercent(event.target.value, index);
                    }}
                    externalClass={`percent ${
                      String(values.workloads[index].percent).length === 2
                        ? 'two_symbols'
                        : String(values.workloads[index].percent).length >= 3
                        ? 'three_symbols'
                        : ''
                    }`}
                    id={'percent'}
                    onCountUp={percentPlus(index)}
                    onCountDown={percentMinus(index)}
                    defaultValue={String(values.workloads[index].percent)}
                    disabled={values.workloads.length === 1 && isCurrentResourceLoading}
                    hasError={hasError(`workloads[${index}].percent`)}
                  />
                </div>
              </div>
              <button className="form__btn-clean-inputs" type={'button'} onClick={removeWorkloads(index)}>
                <Icon iconName={'trash'} />
              </button>
            </div>
          ))}
          <div className="form__input-block add_button_block">
            <Button
              type="button"
              externalClass="form__btn-add-group"
              color="gray"
              onClick={addNewWorkloads}
              disabled={isCurrentResourceLoading}
            >
              <Icon iconName="plus" externalClass="form__icon-btn-add" />
              <FormattedMessage {...messages.addButton} />
            </Button>
          </div>
        </div>
        <ErrorMessage>{resourcesDataError}</ErrorMessage>
        <ErrorMessage>{typeof workloadsErrors === 'string' && workloadsErrors}</ErrorMessage>
        <div className="form__buttons">
          <Select
            label={''}
            name={`resourceSaveTypet`}
            options={resourceSaveTypeOptions}
            handleChange={value => handleChangeResourceSaveType(value)}
            hasError={hasError(`resourceSaveType`)}
            errorMessage={errors?.resourceSaveType}
            defaultValue={resourceSaveTypeOptions[0]}
            value={resourceSaveTypeValue}
            externalClass={'without_label'}
            isDisabled={isCurrentResourceLoading}
          />

          <div className="form__inputs-subwrapper">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              type={'button'}
              onClick={resetAndExit}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditResource;
