import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  dispalyNameLabel,
  deleteModalWithItemNameTitle,
  employeesLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  dispalyNameLabel,
  deleteModalWithItemNameTitle,
  employeesLabel,
  newMemberGroupTitle: {
    id: 'mail.employee.group.modal.new.title.new.member.group',
    defaultMessage: 'New Member Group',
  },
  editMemberGroupTitle: {
    id: 'mail.employee.group.modal.edit.title.edit.member.group',
    defaultMessage: 'Edit Member Group',
  },
});
