import { get } from 'lodash-es';
import { closedValue, publishedValue } from '../../pages/Polls/utils';
import { getDateParams } from '../../utils/params.utils';
import { SortParams } from '../params.enum';

export class PollTemplatesParams extends SortParams {
  size: number;
  page: number;
  categoryIds: string[];
  name: string;
  constructor(params?: unknown) {
    super('name', params);
    this.page = get(params, 'page', 0);
    this.size = get(params, 'size', 10);
    this.categoryIds = get(params, 'categoryIds', []);
    this.name = get(params, 'name', '');
  }
}

export class PollTemplatesCategoryParams extends SortParams {
  size: number;
  page: number;
  constructor(params?: unknown) {
    super('name', params);
    this.page = get(params, 'page', 0);
    this.size = get(params, 'size', 10);
  }
}

export class PollParams extends SortParams {
  relatedEvent: string;
  status: string;
  size: number;
  page: number;
  templateCategoryIds: string[];
  officeIds: string[];
  responderIds: string[];
  organizerIds: string[];
  statuses: string[];
  deadlineFrom: string;
  deadlineTo: string;
  isGeneral: boolean | null;
  isSelfUserPolls: boolean;

  constructor(params?: unknown) {
    super('name', params);
    this.relatedEvent = get(params, 'relatedEvent', '');
    this.status = get(params, 'status', '');
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
    this.templateCategoryIds = get(params, 'templateCategoryIds', []);
    this.officeIds = get(params, 'officeIds', []);
    this.responderIds = get(params, 'responderIds', []);
    this.organizerIds = get(params, 'organizerIds', []);
    this.statuses = get(params, 'statuses', []);
    this.deadlineFrom = get(params, 'deadlineFrom', getDateParams());
    this.deadlineTo = get(params, 'deadlineTo', getDateParams({ getFromStart: false }));
    this.isGeneral = get(params, 'isGeneral', true);
    this.isSelfUserPolls = get(params, 'isSelfUserPolls', false);
  }
}

export class MyPollsParams extends SortParams {
  name: string;
  deadlines: string;
  relatedEvent: string;
  deadlineFrom: string;
  deadlineTo: string;
  isGeneral: boolean | null;
  responderIds: string[];
  statuses: string[];
  size: number;
  page: number;
  isSelfUserPolls: boolean;

  constructor(params?: unknown) {
    super(
      [
        { sortBy: 'userStatus', direction: 'ASC' },
        { sortBy: 'deadline', direction: 'DESC' },
      ],
      params,
    );
    this.name = get(params, 'name', '');
    this.deadlines = get(params, 'deadline', '');
    this.relatedEvent = get(params, 'relatedEvent', '');
    this.deadlineFrom = get(params, 'deadlineFrom', '');
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
    this.deadlineTo = get(params, 'deadlineTo', '');
    this.responderIds = get(params, 'responderIds', []);
    this.statuses = get(params, 'statuses', [closedValue, publishedValue]);
    this.isGeneral = get(params, 'isGeneral', true);
    this.isSelfUserPolls = get(params, 'isSelfUserPolls', true);
  }
}
