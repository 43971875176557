import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants/date.constants';
import { TActivityDailyDto, TActivityType } from '../../../../types/activity';

export const summaryDataSet = [
  {
    label: 'Logged',
    key: 'logged',
    backgroundColor: '#A3A3A3',
  },
  {
    label: 'Active',
    key: 'active',
    backgroundColor: '#DA5748',
  },
  {
    label: 'Tracked',
    key: 'tracked',
    backgroundColor: '#5D6DBE',
  },
  {
    label: 'Jira',
    key: 'submitted',
    backgroundColor: '#6A9E3F',
  },
  {
    label: 'Planned',
    key: 'planned',
    backgroundColor: '#FF8A00',
  },
  {
    label: 'Remaining',
    key: 'remaining',
    backgroundColor: '#1BACC0',
  },
];

export const getSummaryChartData = (daysActivity: TActivityDailyDto[] = [], dateRange: string[]) => {
  const data = {
    labels: dateRange.map(day => moment(day).format(DATE_FORMAT.MMM_D)),
    datasets: summaryDataSet.slice(0, 4).map(el => ({
      id: el.label,
      label: el.label,
      data: dateRange.map(day => {
        const data = daysActivity?.find(activity => activity.day === day);
        if (data) {
          const filed = el.key;
          return data[filed as keyof TActivityType] / 3600;
        }

        return 0;
      }),
      backgroundColor: el.backgroundColor,
      borderRadius: 6,
    })),
  };

  return data;
};

export const legendHeight = {
  id: 'legendHeight',
  beforeInit: function (chart: any) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 38;
    };
  },
};

export const oneRowHeight = 37.5;
export const maxRowWithoutScroll = 10;
export const summaryChartWidth = 958;
export const summaryChartHeight = 350;
