import { isEmpty } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { activityTime } from '../../../constants/activity.constants';
import { DepthLevels } from '../../../constants/tables.constants';
import { UserInfo } from '../../../enums/users.enum';
import { getHoursAndMinutes, getActivityChartData } from '../../../utils/activity.utils';
import { useTableData } from '../../../utils/hooks.utils';
import { getTableCell } from '../../../utils/table.utils';
import HierarchicalTable from '../../HierarchicalTable';
import TableUserAvatar from '../../TableUserAvatar';
import messages from '../messages';
import WorkChart from '../WorkChart';

type ActivityChartProps = {
  tableData: any;
  loading: boolean;
  error: null | string;
};

function ActivityChart({ tableData, loading, error }: ActivityChartProps) {
  const intl = useIntl();

  const tableBorders = useMemo(
    () => (
      <div className="table-border-wrapper">
        {activityTime.map((el: string) => (
          <div className="table-border" key={el}></div>
        ))}
      </div>
    ),
    [],
  );

  const tableActivityChart: any = useMemo(
    () => [
      {
        Header: () => (
          <div className="activity__time-wrapper">
            {activityTime.map((el: string) => (
              <div className="activity__time" key={el}>
                {el}
              </div>
            ))}
          </div>
        ),
        className: 'border-bottom',
        id: 'chart',
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: () => tableBorders,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: any, index) => (
                <>
                  {tableBorders}
                  {!isEmpty(row.trackLogs) ? (
                    <WorkChart
                      key={index}
                      userWorkTime={getActivityChartData(row)}
                      height="25px"
                      width="calc(100% + 7.5px)"
                    />
                  ) : (
                    <div className="activity__no-data">
                      <FormattedMessage {...messages.noDataText} />
                    </div>
                  )}
                </>
              ),
            },
          ]),
      },
    ],
    [tableBorders],
  );

  const tableUserColumns = useMemo(
    () => [
      {
        id: 'departmentName',
        Header: () => <span>{intl.formatMessage(messages.departmentsMembersLabel)}</span>,
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: any) => <span>{row.department.displayName}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: any) => {
                const loggedTrackedContent = `${getHoursAndMinutes(row.logged)} / ${getHoursAndMinutes(row.tracked)}`;
                return (
                  <>
                    <TableUserAvatar users={[new UserInfo(row.userPreview)]} fileSize={48} />
                    &nbsp; ({loggedTrackedContent})
                  </>
                );
              },
            },
          ]),
      },
    ],
    [],
  );

  const tableColumns = useMemo(() => tableUserColumns.concat(tableActivityChart), [
    tableUserColumns,
    tableActivityChart,
  ]);

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 1) {
      return row.id.split('.')[1] % 2 === 0 ? 'even' : '';
    }
    return '';
  }, []);

  return (
    <HierarchicalTable
      tableData={useTableData(tableData, ['users'])}
      externalClass="activity-chart-table fixed"
      externalRowClass={handleExternalRowClass}
      tableColumns={tableColumns}
      loading={loading}
      error={error}
      disabledDepthHover={[DepthLevels.SECOND]}
    />
  );
}

export default React.memo(ActivityChart);
