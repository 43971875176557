import React, { useMemo, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '../../components/Icon';
import { Candidate, CandidateStatus } from '../../enums/candidates.enums';
import { getExperience } from '../../utils';
import messages from './messages';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';
import Avatar from '../../components/Profile/Avatar';
import { NavLink } from 'react-router-dom';
import r from '../../constants/routes.constants';
import { DELETE_CANDIDATE, UPDATE_CANDIDATE } from '../../constants/policies.constants';
import ListRecordsBackground from '../../components/ListRecordsBackground';
import Checkbox from '../../components/Checkbox';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';

const CandidateAvatar = ({ data }: { data: Candidate }) => {
  return (
    <div className="table__user">
      <Avatar isCandidate userInfo={data} fileSize={72} size="medium" tableImg isUserPageLink={false} />
      <div>
        <div className="candidate-name">{data.fullName}</div>
        <div className="table__subitem">{data.alternativeName}</div>
      </div>
    </div>
  );
};

export const useDataForTable = (
  candidates: Candidate[],
  openEditCandidateModal: () => void,
  openDeleteCandidateModal: () => void,
  setCandidateClicked: (candidate: Candidate) => void,
  exportCandidates: (candidates: Candidate[]) => void,
  editCandidate: (data: { data: Candidate }) => void,
  candidateStatuses: CandidateStatus[],
) => {
  const intl = useIntl();
  const [candidateClickedList, setCandidateClickedList] = useState<Candidate[]>([]);

  const handleAllCheck = useCallback(() => {
    isEmpty(candidateClickedList) ? setCandidateClickedList(candidates) : setCandidateClickedList([]);
  }, [candidates, candidateClickedList]);

  const handleCheckCandidate = useCallback((candidate: Candidate, checked: boolean) => {
    setCandidateClickedList(prev => {
      if (checked) {
        return prev.concat(candidate);
      } else {
        return prev.filter(item => item.id !== candidate.id);
      }
    });
  }, []);

  const handleExport = () => {
    exportCandidates(candidateClickedList);
    setCandidateClickedList([]);
  };

  const handleRenderColumnName = (name: string) => (isEmpty(candidateClickedList) ? name : '');

  const tableColumns = useMemo(() => {
    const checkedAllCandidates = candidateClickedList.length === candidates.length;
    const checkedAtLeastOneCanidate = candidateClickedList.length >= 1;
    return [
      {
        name: (
          <div className={'table__data-wrapper'}>
            <Checkbox
              id={'checkbox'}
              onChange={handleAllCheck}
              checkedValue={!!candidateClickedList?.length}
              externalClass={classNames(
                'cv_table_checkbox',
                { 'clear-all-checkbox': checkedAtLeastOneCanidate && !checkedAllCandidates },
                'checkbox-no-label',
              )}
              iconName={checkedAtLeastOneCanidate && !checkedAllCandidates ? 'clear-all-checkbox' : undefined}
            />
          </div>
        ),
        modifier: (row: Candidate) => (
          <div className={'table__data-wrapper'}>
            <Checkbox
              id={'checkbox' + row.id}
              onChange={event => handleCheckCandidate(row, event.target.checked)}
              checkedValue={candidateClickedList?.some(item => item.id === row.id)}
            />
          </div>
        ),
      },
      {
        name: !isEmpty(candidateClickedList) ? (
          <div className={'table__data-wrapper export_button_wrapper'}>
            <div className="export_button">
              <button onClick={handleExport}>
                <Icon iconName="xls" />
                <span>
                  <FormattedMessage {...messages.exportButton} />
                </span>
              </button>
              <div className="selected-coutner">{`${candidateClickedList?.length} ${intl.formatMessage(
                messages.selectedLabel,
              )}`}</div>
            </div>
          </div>
        ) : (
          intl.formatMessage(messages.nameColumn)
        ),
        modifier: (row: Candidate) => (
          <NavLink className="table__type-link candidate-link" to={`${r.candidates}/${row.id}`}>
            <CandidateAvatar data={row} />
          </NavLink>
        ),
      },
      {
        name: handleRenderColumnName(intl.formatMessage(messages.statusLabel)),
        modifier: (row: Candidate) => <span style={{ color: `#${row.status.color}` }}>{row.status.name}</span>,
      },
      {
        name: handleRenderColumnName(intl.formatMessage(messages.experienceLabel)),
        modifier: (row: Candidate) => (
          <>
            <div>{getExperience(row.carrierStart)}</div>
            {row.hasLeadExperience && (
              <div className="table__subitem">
                <FormattedMessage {...messages.leadLabel} />
              </div>
            )}
          </>
        ),
      },
      {
        name: handleRenderColumnName(intl.formatMessage(messages.specializationsTitle)),
        modifier: (row: Candidate) =>
          !isEmpty(row.specializations) ? <ListRecordsBackground data={row.specializations} recordLimit={4} /> : null,
      },
      {
        name: handleRenderColumnName(intl.formatMessage(messages.technologiesTitle)),
        modifier: (row: Candidate) =>
          !isEmpty(row.specializations) ? <ListRecordsBackground data={row.technologies} recordLimit={4} /> : null,
      },
      {
        name: handleRenderColumnName(intl.formatMessage(messages.locationTitle)),
        modifier: (row: Candidate) => row.location,
      },
      {
        name: handleRenderColumnName(intl.formatMessage(messages.receivingSourceTitle)),
        modifier: (row: Candidate) => row.receivingSource.name,
      },
      {
        name: handleRenderColumnName(intl.formatMessage(messages.createdColumn)),
        modifier: (row: Candidate) => (
          <>
            <div>{moment(row.createdDate).format(DATE_FORMAT.ll)}</div>
            <div className="table__subitem">{row.author.fullName}</div>
          </>
        ),
      },
      {
        name: handleRenderColumnName(intl.formatMessage(messages.updatedColumn)),
        modifier: (row: Candidate) => (
          <>
            <div>{moment(row.lastModifiedDate).format(DATE_FORMAT.ll)}</div>
            <div className="table__subitem">{row.lastEditor.fullName}</div>
          </>
        ),
      },
      {
        name: handleRenderColumnName(intl.formatMessage(messages.commentsTitle)),
        modifier: (row: Candidate) =>
          row.lastCommentAuthor.id && (
            <>
              <div>{moment(row.lastCommentDate).format(DATE_FORMAT.ll)}</div>
              <div className="table__subitem">{row.lastCommentAuthor.fullName}</div>
            </>
          ),
      },
    ];
  }, [candidates, candidateClickedList, handleAllCheck]);

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'info'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.statusLabel} />
          </>
        ),
        list: candidateStatuses.map((status: CandidateStatus) => ({
          label: (candidateInfo: Candidate) => (
            <>
              {candidateInfo.statusId === status.id && <Icon iconName="check" />}
              <span style={{ color: `#${status.color}` }}>{status.name}</span>
            </>
          ),
          handler: (candidateInfo: Candidate) =>
            editCandidate({ data: new Candidate({ ...candidateInfo, statusId: status.id }) }),
          closeOnClick: true,
        })),
        verifiablePolicies: [UPDATE_CANDIDATE],
      },
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Candidate) => {
          setCandidateClicked(row);
          openEditCandidateModal();
        },
        verifiablePolicies: [UPDATE_CANDIDATE],
      },
      //TODO
      // {
      //   label: (
      //     <>
      //       <Icon iconName={'add-member'} externalClass={'dropdown__list-item__icon'} />
      //       <FormattedMessage {...messages.createMemberBtn} />
      //     </>
      //   ),
      //   handler: () => null,
      //   verifiablePolicies: [UPDATE_CANDIDATE],
      // },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button top-line',
        handler: (row: Candidate) => {
          setCandidateClicked(row);
          openDeleteCandidateModal();
        },
        verifiablePolicies: [DELETE_CANDIDATE],
      },
    ],
    [candidateStatuses],
  );

  return {
    tableColumns,
    tableActions,
  };
};
