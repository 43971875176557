import React from 'react';
import Button from '../../Button';
import Icon from '../../Icon';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import AccessChecker from '../../AccessChecker';
import { UPDATE_CANDIDATE } from '../../../constants/policies.constants';
import ListRecordsBackground from '../../ListRecordsBackground';
import { useSelector } from 'react-redux';
import { urlify } from '../../../utils';

type ProfileAboutProps = {
  openEditModal: () => void;
};

function ProfileAbout({ openEditModal }: ProfileAboutProps) {
  const candidateInfo = useSelector((state: RootState) => state.candidates.candidateInfo);
  return (
    <div className="tabs__content-item active tabs__content-item--about">
      <div className="tabs__content-item__title">
        <FormattedMessage {...messages.aboutTitle} />
        <AccessChecker verifiablePolicies={[UPDATE_CANDIDATE]}>
          <Button color="gray" externalClass="page__profile-button" onClick={openEditModal}>
            <Icon iconName="pencil" externalClass="button__icon" />
          </Button>
        </AccessChecker>
      </div>
      <div className="tabs__content-item__about-column-wrapper">
        <div className="tabs__content-item__about-column">
          <div className="tabs__content-item__about-info">
            <div className="tabs__content-item__about-column-title">
              <FormattedMessage {...messages.specializationsTitle} />
            </div>
            <ListRecordsBackground data={candidateInfo.specializations} />
          </div>

          <div className="tabs__content-item__about-info">
            <div className="tabs__content-item__about-column-title">
              <FormattedMessage {...messages.technologiesTitle} />
            </div>
            <ListRecordsBackground data={candidateInfo.technologies} />
          </div>

          <div className="tabs__content-item__about-info">
            <div className="tabs__content-item__about-column-title">
              <FormattedMessage {...messages.previousEmploymentTitle} />
            </div>
            <div className="tabs__content-item__about-info-value">{candidateInfo.previousEmployments}</div>
          </div>
        </div>

        <div className="tabs__content-item__about-column">
          <div className="tabs__content-item__about-info">
            <div className="tabs__content-item__about-column-title">
              <FormattedMessage {...messages.linksTitle} />
            </div>
            <div
              className="tabs__content-item__about-info-value links"
              dangerouslySetInnerHTML={{ __html: urlify(candidateInfo.links) }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileAbout;
