import {
  ExpensesReportItem,
  IncomesReportItem,
  FinancePlan,
  FinancePlanUser,
  FinanceProject,
  SalaryReport,
  Transaction,
  PlannedExpenseReportItem,
  EmployeeHours,
  MonthReportParts,
} from './../enums/finance/finance.enum';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/finance.constants';
import { RejectValueErrors } from '../enums/error.enum';
import { getReducerErrors } from '../utils/reducerError.utils';
import * as paramsTypes from '../enums/params/finance.params';
import { Client, ChekedInfo } from '../enums/finance/finance.enum';
import { ClonePlanCheckedInfoType, ImportResourcesCheckedInfoType } from '../types/finance';

type initialStateType = {
  incomeTypesListData: any;
  currenciesListData: any;
  clientsListData: any;
  suppliersListData: any;
  projectTypesListData: any;
  expenseTypesListData: any;
  projectsListData: any;
  salariesListData: any;
  currentSalary: any;
  plansListData: any;
  currentPlanData: FinancePlan;
  currenPlanMonth: string;
  planEmployeesListData: any;
  datesOfExistingPlans: string[];
  employeeHours: EmployeeHours;
  incomesExpensesReportListData: any;
  transactionsListData: any;
  transactionsTotal: {
    difference: number;
    expensesTotal: number;
    incomesTotal: number;
  };
  clonePlanCheckedInfo: ChekedInfo[];
  importResourcesCheckedInfo: ChekedInfo[];
  plannedExpense: {
    expenseReportParts: PlannedExpenseReportItem;
    monthReportParts: MonthReportParts;
    total: number;
  } | null;
  salariesParams: paramsTypes.SalariesParams;
  incomeTypesParams: paramsTypes.IncomeTypesParams;
  currenciesParams: paramsTypes.CurrenciesParams;
  clientsParams: paramsTypes.ClientsParams;
  suppliersParams: paramsTypes.SuppliersParams;
  projectTypesParams: paramsTypes.ProjectTypesParams;
  expenseTypesParams: paramsTypes.ExpenseTypesParams;
  projectParams: paramsTypes.FinanceProjectsParams;
  planParams: paramsTypes.FinancePlanParams;
  planEmployeesParams: paramsTypes.FinancePlanEmployeesParams;
  incomesExpensesReportParams: paramsTypes.IncomesExpensesReportParams;
  transactionsParams: paramsTypes.TransactionsParams;
  plannedExpenseParams: paramsTypes.PlannedExpenseParams;
  loading: {
    getIncomeTypesList: boolean;
    createIncomeType: boolean;
    editIncomeType: boolean;
    deleteIncomeType: boolean;
    getCurrenciesList: boolean;
    createCurrency: boolean;
    editCurrency: boolean;
    deleteCurrency: boolean;
    getClientsList: boolean;
    createClient: boolean;
    editClient: boolean;
    deleteClient: boolean;
    getSuppliersList: boolean;
    createSupplier: boolean;
    editSupplier: boolean;
    deleteSupplier: boolean;
    getProjectTypesList: boolean;
    createProjectType: boolean;
    editProjectType: boolean;
    deleteProjectType: boolean;
    getExpenseTypesList: boolean;
    createExpenseType: boolean;
    editExpenseType: boolean;
    deleteExpenseType: boolean;
    getProjectsList: boolean;
    createProject: boolean;
    editProject: boolean;
    deleteProject: boolean;
    getSalariesList: boolean;
    getSalary: boolean;
    createSalary: boolean;
    editSalary: boolean;
    deleteSalary: boolean;
    getPlansList: boolean;
    getPlan: boolean;
    createPlan: boolean;
    editPlan: boolean;
    clonePlan: boolean;
    deletePlan: boolean;
    getPlanEmployeesList: boolean;
    getEmployeeHours: boolean;
    createPlanEmployee: boolean;
    editPlanEmployee: boolean;
    deletePlanEmployee: boolean;
    getIncomesExpensesReport: boolean;
    getTransactions: boolean;
    createTransaction: boolean;
    importTransactions: boolean;
    editTransaction: boolean;
    deleteTransaction: boolean;
    importResources: boolean;
    getDatesOfExistingPlans: boolean;
    getPlannedExpense: boolean;
    createPlannedExpense: boolean;
    editPlannedExpense: boolean;
    deletePlannedExpense: boolean;
  };
  errors: {
    incomeTypesError: string | RejectValueErrors[] | null;
    currenciesError: string | RejectValueErrors[] | null;
    clientsError: string | RejectValueErrors[] | null;
    suppliersError: string | RejectValueErrors[] | null;
    projectTypesError: string | RejectValueErrors[] | null;
    expenseTypesError: string | RejectValueErrors[] | null;
    projectsError: string | RejectValueErrors[] | null;
    salariesError: string | RejectValueErrors[] | null;
    planError: string | RejectValueErrors[] | null;
    planEmployeesError: string | RejectValueErrors[] | null;
    employeeHoursError: string | RejectValueErrors[] | null;
    incomesExpensesReportError: string | RejectValueErrors[] | null;
    transactionListError: string | null;
    transactionError: string | RejectValueErrors[] | null;
    importTransationsError: string | RejectValueErrors[] | null;
    importResourcesError: string | RejectValueErrors[] | null;
    datesOfExistingPlans: string | null;
    plannedExpenseError: string | RejectValueErrors[] | null;
  };
};

const initialState: initialStateType = {
  incomeTypesListData: null,
  currenciesListData: null,
  clientsListData: null,
  suppliersListData: null,
  projectTypesListData: null,
  expenseTypesListData: null,
  projectsListData: null,
  salariesListData: null,
  currentSalary: null,
  plansListData: null,
  currentPlanData: new FinancePlan(),
  currenPlanMonth: '',
  planEmployeesListData: null,
  datesOfExistingPlans: [],
  employeeHours: new EmployeeHours(),
  incomesExpensesReportListData: null,
  transactionsListData: null,
  transactionsTotal: {
    difference: 0,
    expensesTotal: 0,
    incomesTotal: 0,
  },
  clonePlanCheckedInfo: [],
  importResourcesCheckedInfo: [],
  plannedExpense: null,
  salariesParams: new paramsTypes.SalariesParams(),
  incomeTypesParams: new paramsTypes.IncomeTypesParams(),
  currenciesParams: new paramsTypes.CurrenciesParams(),
  clientsParams: new paramsTypes.ClientsParams(),
  suppliersParams: new paramsTypes.SuppliersParams(),
  projectTypesParams: new paramsTypes.ProjectTypesParams(),
  expenseTypesParams: new paramsTypes.ExpenseTypesParams(),
  projectParams: new paramsTypes.FinanceProjectsParams(),
  planParams: new paramsTypes.FinancePlanParams(),
  planEmployeesParams: new paramsTypes.FinancePlanEmployeesParams(),
  incomesExpensesReportParams: new paramsTypes.IncomesExpensesReportParams(),
  transactionsParams: new paramsTypes.TransactionsParams(),
  plannedExpenseParams: new paramsTypes.PlannedExpenseParams(),
  loading: {
    getIncomeTypesList: false,
    createIncomeType: false,
    editIncomeType: false,
    deleteIncomeType: false,
    getCurrenciesList: false,
    createCurrency: false,
    editCurrency: false,
    deleteCurrency: false,
    getClientsList: false,
    createClient: false,
    editClient: false,
    deleteClient: false,
    getSuppliersList: false,
    createSupplier: false,
    editSupplier: false,
    deleteSupplier: false,
    getProjectTypesList: false,
    createProjectType: false,
    editProjectType: false,
    deleteProjectType: false,
    getExpenseTypesList: false,
    createExpenseType: false,
    editExpenseType: false,
    deleteExpenseType: false,
    getProjectsList: false,
    createProject: false,
    editProject: false,
    deleteProject: false,
    getSalariesList: false,
    getSalary: false,
    createSalary: false,
    editSalary: false,
    deleteSalary: false,
    getPlansList: false,
    getPlan: false,
    createPlan: false,
    editPlan: false,
    clonePlan: false,
    deletePlan: false,
    getPlanEmployeesList: false,
    getEmployeeHours: false,
    createPlanEmployee: false,
    editPlanEmployee: false,
    deletePlanEmployee: false,
    getIncomesExpensesReport: false,
    getTransactions: false,
    createTransaction: false,
    importTransactions: false,
    editTransaction: false,
    deleteTransaction: false,
    importResources: false,
    getDatesOfExistingPlans: false,
    getPlannedExpense: false,
    createPlannedExpense: false,
    editPlannedExpense: false,
    deletePlannedExpense: false,
  },
  errors: {
    incomeTypesError: null,
    currenciesError: null,
    clientsError: null,
    suppliersError: null,
    projectTypesError: null,
    expenseTypesError: null,
    projectsError: null,
    salariesError: null,
    planError: null,
    planEmployeesError: null,
    employeeHoursError: null,
    incomesExpensesReportError: null,
    transactionListError: null,
    transactionError: null,
    importTransationsError: null,
    importResourcesError: null,
    datesOfExistingPlans: null,
    plannedExpenseError: null,
  },
};

const financeReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.RESET_FINANCE_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_FINANCE_STATE:
      return {
        ...initialState,
        salariesParams: state.salariesParams,
        incomeTypesParams: state.incomeTypesParams,
        currenciesParams: state.currenciesParams,
        clientsParams: state.clientsParams,
        suppliersParams: state.suppliersParams,
        projectTypesParams: state.projectTypesParams,
        expenseTypesParams: state.expenseTypesParams,
        projectParams: state.projectParams,
        transactionsParams: state.transactionsParams,
        planParams: state.planParams,
      };

    case ActionTypes.GET_INCOME_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getIncomeTypesList: true },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.GET_INCOME_TYPES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getIncomeTypesList: false },
        incomeTypesListData: action.payload,
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.GET_INCOME_TYPES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getIncomeTypesList: false },
        errors: { ...state.errors, incomeTypesError: action.payload.message },
      };

    case ActionTypes.POST_INCOME_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: true },
      };

    case ActionTypes.POST_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: false },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.POST_INCOME_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: false },
        errors: {
          ...state.errors,
          incomeTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_INCOME_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editIncomeType: true },
      };

    case ActionTypes.PUT_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editIncomeType: false },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.PUT_INCOME_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editIncomeType: false },
        errors: {
          ...state.errors,
          incomeTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_INCOME_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteIncomeType: true },
      };

    case ActionTypes.DELETE_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteIncomeType: false },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.DELETE_INCOME_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteIncomeType: false },
        errors: {
          ...state.errors,
          incomeTypesError: action.payload.message,
        },
      };

    case ActionTypes.SET_INCOME_TYPES_PARAMS:
      return {
        ...state,
        incomeTypesParams: new paramsTypes.IncomeTypesParams({ ...state.incomeTypesParams, ...action.payload }),
        loading: { ...state.loading, getIncomeTypesList: true },
      };

    case ActionTypes.GET_CURRENCIES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCurrenciesList: true },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.GET_CURRENCIES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCurrenciesList: false },
        currenciesListData: action.payload,
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.GET_CURRENCIES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getCurrenciesList: false },
        errors: { ...state.errors, currenciesError: action.payload.message },
      };

    case ActionTypes.POST_CURRENCY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createCurrency: true },
      };

    case ActionTypes.POST_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createCurrency: false },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.POST_CURRENCY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createCurrency: false },
        errors: {
          ...state.errors,
          currenciesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_CURRENCY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editCurrency: true },
      };

    case ActionTypes.PUT_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editCurrency: false },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.PUT_CURRENCY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editCurrency: false },
        errors: {
          ...state.errors,
          currenciesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_CURRENCY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCurrency: true },
      };

    case ActionTypes.DELETE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteCurrency: false },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.DELETE_CURRENCY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteCurrency: false },
        errors: {
          ...state.errors,
          currenciesError: action.payload.message,
        },
      };

    case ActionTypes.SET_CURRENCIES_PARAMS:
      return {
        ...state,
        currenciesParams: new paramsTypes.CurrenciesParams({ ...state.currenciesParams, ...action.payload }),
        loading: { ...state.loading, getCurrenciesList: true },
      };

    case ActionTypes.GET_PROJECT_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getProjectTypesList: true },
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.GET_PROJECT_TYPES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProjectTypesList: false },
        projectTypesListData: action.payload,
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.GET_PROJECT_TYPES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getProjectTypesList: false },
        errors: { ...state.errors, projectTypesError: action.payload.message },
      };

    case ActionTypes.POST_PROJECT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createProjectType: true },
      };

    case ActionTypes.POST_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createProjectType: false },
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.POST_PROJECT_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createProjectType: false },
        errors: {
          ...state.errors,
          projectTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_PROJECT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editProjectType: true },
      };

    case ActionTypes.PUT_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editProjectType: false },
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.PUT_PROJECT_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editProjectType: false },
        errors: {
          ...state.errors,
          projectTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_PROJECT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteProjectType: true },
      };

    case ActionTypes.DELETE_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteProjectType: false },
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.DELETE_PROJECT_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteProjectType: false },
        errors: {
          ...state.errors,
          projectTypesError: action.payload.message,
        },
      };

    case ActionTypes.SET_PROJECT_TYPES_PARAMS:
      return {
        ...state,
        projectTypesParams: new paramsTypes.ProjectTypesParams({ ...state.projectTypesParams, ...action.payload }),
        loading: { ...state.loading, getProjectTypesList: true },
      };

    case ActionTypes.GET_EXPENSE_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getExpenseTypesList: true },
        errors: { ...state.errors, expenseTypesError: null },
      };

    case ActionTypes.GET_EXPENSE_TYPES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getExpenseTypesList: false },
        expenseTypesListData: action.payload,
        errors: { ...state.errors, expenseTypesError: null },
      };

    case ActionTypes.GET_EXPENSE_TYPES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getExpenseTypesList: false },
        errors: { ...state.errors, expenseTypesError: action.payload.message },
      };

    case ActionTypes.POST_EXPENSE_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: true },
      };

    case ActionTypes.POST_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: false },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.POST_EXPENSE_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: false },
        errors: {
          ...state.errors,
          incomeTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_EXPENSE_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editExpenseType: true },
      };

    case ActionTypes.PUT_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editExpenseType: false },
        errors: { ...state.errors, expenseTypesError: null },
      };

    case ActionTypes.PUT_EXPENSE_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editExpenseType: false },
        errors: {
          ...state.errors,
          expenseTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_EXPENSE_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteExpenseType: true },
      };

    case ActionTypes.DELETE_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteExpenseType: false },
        errors: { ...state.errors, expenseTypesError: null },
      };

    case ActionTypes.DELETE_EXPENSE_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteExpenseType: false },
        errors: {
          ...state.errors,
          expenseTypesError: action.payload.message,
        },
      };

    case ActionTypes.SET_EXPENSE_TYPES_PARAMS:
      return {
        ...state,
        expenseTypesParams: new paramsTypes.ExpenseTypesParams({ ...state.expenseTypesParams, ...action.payload }),
        loading: { ...state.loading, getExpenseTypesList: true },
      };

    case ActionTypes.GET_SUPPLIERS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSuppliersList: true },
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.GET_SUPPLIERS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSuppliersList: false },
        suppliersListData: action.payload,
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.GET_SUPPLIERS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getSuppliersList: false },
        errors: { ...state.errors, suppliersError: action.payload.message },
      };

    case ActionTypes.POST_SUPPLIER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createSupplier: true },
      };

    case ActionTypes.POST_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createSupplier: false },
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.POST_SUPPLIER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createSupplier: false },
        errors: {
          ...state.errors,
          suppliersError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_SUPPLIER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editSupplier: true },
      };

    case ActionTypes.PUT_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editSupplier: false },
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.PUT_SUPPLIER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editSupplier: false },
        errors: {
          ...state.errors,
          suppliersError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_SUPPLIER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteSupplier: true },
      };

    case ActionTypes.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteSupplier: false },
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.DELETE_SUPPLIER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteSupplier: false },
        errors: {
          ...state.errors,
          suppliersError: action.payload.message,
        },
      };

    case ActionTypes.SET_SUPPLIERS_PARAMS:
      return {
        ...state,
        suppliersParams: new paramsTypes.SuppliersParams({ ...state.suppliersParams, ...action.payload }),
        loading: { ...state.loading, getSuppliersList: true },
      };

    case ActionTypes.GET_CLIENTS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getClientsList: true },
        errors: { ...state.errors, clientsError: null },
      };

    case ActionTypes.GET_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getClientsList: false },
        clientsListData: {
          ...action.payload,
          content: action.payload?.content.map((item: Client) => new Client(item)),
        },
        errors: { ...state.errors, clientsError: null },
      };

    case ActionTypes.GET_CLIENTS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getClientsList: false },
        errors: { ...state.errors, clientsError: action.payload.message },
      };

    case ActionTypes.POST_CLIENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createClient: true },
      };

    case ActionTypes.POST_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createClient: false },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.POST_CLIENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createClient: false },
        errors: {
          ...state.errors,
          clientsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_CLIENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editClient: true },
      };

    case ActionTypes.PUT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editClient: false },
        errors: { ...state.errors, clientsError: null },
      };

    case ActionTypes.PUT_CLIENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editClient: false },
        errors: {
          ...state.errors,
          clientsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteClient: true },
      };

    case ActionTypes.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteClient: false },
        errors: { ...state.errors, clientsError: null },
      };

    case ActionTypes.DELETE_CLIENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteClient: false },
        errors: {
          ...state.errors,
          clientsError: action.payload.message,
        },
      };

    case ActionTypes.SET_CLIENTS_PARAMS:
      return {
        ...state,
        clientsParams: new paramsTypes.ClientsParams({ ...state.clientsParams, ...action.payload }),
        loading: { ...state.loading, getClientsList: true },
      };

    case ActionTypes.GET_SALARIES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSalariesList: false },
        salariesListData: new SalaryReport(action.payload),
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.GET_SALARIES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getSalariesList: false },
        errors: { ...state.errors, salariesError: action.payload.message },
      };

    case ActionTypes.GET_SALARY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSalary: true },
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.GET_SALARY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSalary: false },
        currentSalary: action.payload,
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.GET_SALARY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getSalary: false },
        errors: { ...state.errors, salariesError: action.payload.message },
      };

    case ActionTypes.PUT_SALARY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editSalary: true },
      };

    case ActionTypes.PUT_SALARY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editSalary: false },
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.PUT_SALARY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editSalary: false },
        errors: {
          ...state.errors,
          salariesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_SALARY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteSalary: true },
      };

    case ActionTypes.DELETE_SALARY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteSalary: false },
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.DELETE_SALARY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteSalary: false },
        errors: {
          ...state.errors,
          salariesError: action.payload.message,
        },
      };

    case ActionTypes.SET_SALARIES_PARAMS:
      return {
        ...state,
        salariesParams: new paramsTypes.SalariesParams({ ...state.salariesParams, ...action.payload }),
        loading: { ...state.loading, getSalariesList: true },
      };

    case ActionTypes.GET_FINANCE_PROJECTS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getProjectsList: true },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.GET_FINANCE_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProjectsList: false },
        projectsListData: {
          ...action.payload,
          content: action.payload.content.map((item: FinanceProject) => new FinanceProject(item)),
        },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.GET_FINANCE_PROJECTS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getProjectsList: false },
        errors: { ...state.errors, projectsError: action.payload.message },
      };

    case ActionTypes.POST_FINANCE_PROJECT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createProject: true },
      };

    case ActionTypes.POST_FINANCE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createProject: false },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.POST_FINANCE_PROJECT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createProject: false },
        errors: {
          ...state.errors,
          projectsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_FINANCE_PROJECT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editProject: true },
      };

    case ActionTypes.PUT_FINANCE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editProject: false },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.PUT_FINANCE_PROJECT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editProject: false },
        errors: {
          ...state.errors,
          projectsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_FINANCE_PROJECT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteProject: true },
      };

    case ActionTypes.DELETE_FINANCE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteProject: false },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.DELETE_FINANCE_PROJECT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteProject: false },
        errors: {
          ...state.errors,
          projectsError: action.payload.message,
        },
      };

    case ActionTypes.SET_FINANCE_PROJECTS_PARAMS:
      return {
        ...state,
        projectParams: new paramsTypes.FinanceProjectsParams({ ...state.projectParams, ...action.payload }),
        loading: { ...state.loading, getProjectsList: true },
      };

    case ActionTypes.GET_INCOMES_EXPENSES_REPORT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getIncomesExpensesReport: true },
        errors: { ...state.errors, incomesExpensesReportError: null },
      };

    case ActionTypes.GET_INCOMES_EXPENSES_REPORT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getIncomesExpensesReport: false },
        incomesExpensesReportListData: {
          ...action.payload,
          expenses: action.payload.expenses?.map((item: ExpensesReportItem) => new ExpensesReportItem(item)),
          incomes: action.payload.incomes?.map((item: IncomesReportItem) => new IncomesReportItem(item)),
        },
        errors: { ...state.errors, incomesExpensesReportError: null },
      };

    case ActionTypes.GET_INCOMES_EXPENSES_REPORT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getIncomesExpensesReport: false },
        errors: { ...state.errors, incomesExpensesReportError: action.payload.message },
      };

    case ActionTypes.SET_INCOMES_EXPENSES_REPORT_PARAMS:
      return {
        ...state,
        incomesExpensesReportParams: new paramsTypes.IncomesExpensesReportParams({
          ...state.incomesExpensesReportParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getIncomesExpensesReport: true },
        errors: { ...state.errors, incomesExpensesReportError: null },
      };

    case ActionTypes.GET_TRANSACTIONS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getTransactions: true },
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.GET_TRANSACTIONS_LIST_SUCCESS:
      const { transactions, total } = action.payload;
      return {
        ...state,
        loading: { ...state.loading, getTransactions: false },
        transactionsListData: {
          ...transactions,
          content: transactions?.content?.map((transaction: any) => new Transaction(transaction)),
        },
        transactionsTotal: total,
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.GET_TRANSACTIONS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getTransactions: false },
        errors: { ...state.errors, transactionListError: action.payload.message },
      };

    case ActionTypes.POST_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createTransaction: true },
      };

    case ActionTypes.POST_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createTransaction: false },
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.POST_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createTransaction: false },
        errors: {
          ...state.errors,
          transactionError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.IMPORT_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, importTransactions: true },
      };

    case ActionTypes.IMPORT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, importTransactions: false },
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.IMPORT_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, importTransactions: false },
        errors: {
          ...state.errors,
          importTransationsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editTransaction: true },
      };

    case ActionTypes.PUT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editTransaction: false },
        errors: { ...state.errors, transactionError: null },
      };

    case ActionTypes.PUT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editTransaction: false },
        errors: {
          ...state.errors,
          transactionError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteTransaction: true },
      };

    case ActionTypes.DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteTransaction: false },
        errors: { ...state.errors, transactionError: null },
      };

    case ActionTypes.DELETE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteTransaction: false },
        errors: {
          ...state.errors,
          transactionError: action.payload.message,
        },
      };

    case ActionTypes.SET_TRANSACTIONS_PARAMS:
      return {
        ...state,
        transactionsParams: new paramsTypes.TransactionsParams({ ...state.transactionsParams, ...action.payload }),
        loading: { ...state.loading, getTransactions: true },
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.GET_FINANCE_PLANS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getPlansList: true },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.GET_FINANCE_PLANS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPlansList: false },
        plansListData: {
          ...action.payload,
          content: action.payload.content.map((item: FinancePlan) => new FinancePlan(item)),
        },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.GET_FINANCE_PLANS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getPlansList: false },
        errors: { ...state.errors, planError: action.payload.message },
      };

    case ActionTypes.SET_FINANCE_PLANS_PARAMS:
      return {
        ...state,
        planParams: new paramsTypes.FinancePlanParams({ ...state.planParams, ...action.payload }),
      };

    case ActionTypes.GET_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getPlan: true },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.GET_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPlan: false },
        currentPlanData: new FinancePlan(action.payload),
        currenPlanMonth: action.payload.month,
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.GET_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getPlan: false },
        errors: { ...state.errors, planError: action.payload.message },
      };

    case ActionTypes.SET_FINANCE_PLAN_MONTH:
      return {
        ...state,
        currenPlanMonth: action.payload.month,
      };

    case ActionTypes.POST_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createPlan: true },
      };

    case ActionTypes.POST_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createPlan: false },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.POST_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createPlan: false },
        errors: {
          ...state.errors,
          planError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editPlan: true },
      };

    case ActionTypes.PUT_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editPlan: false },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.PUT_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editPlan: false },
        errors: {
          ...state.errors,
          planError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.CLONE_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, clonePlan: true },
      };

    case ActionTypes.CLONE_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, clonePlan: false },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.CLONE_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, clonePlan: false },
        errors: {
          ...state.errors,
          planError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.SET_CLONE_FINANCE_PLAN_CHECKED_INFO:
      return {
        ...state,
        loading: { ...state.loading, clonePlan: false },
        clonePlanCheckedInfo: action.payload.info?.map(
          (info: ClonePlanCheckedInfoType) =>
            new ChekedInfo({
              user: info.user,
              newValue: info.hoursPercent,
              originalValue: action.payload.clonnedPlan.employees.find(
                (financeUserPlan: any) => financeUserPlan.userId === info.user.id,
              )?.hoursPercent,
            }),
        ),
      };

    case ActionTypes.RESET_CLONE_FINANCE_PLAN_CHECKED_INFO:
      return {
        ...state,
        clonePlanCheckedInfo: [],
      };

    case ActionTypes.DELETE_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deletePlan: true },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deletePlan: false },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deletePlan: false },
        errors: {
          ...state.errors,
          planError: action.payload.message,
        },
      };

    case ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getPlanEmployeesList: true },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPlanEmployeesList: false },
        plansListData: {
          ...action.payload,
          content: action.payload.content.map((item: FinancePlanUser) => new FinancePlanUser(item)),
        },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getPlanEmployeesList: false },
        errors: { ...state.errors, planEmployeesError: action.payload.message },
      };

    case ActionTypes.GET_EMPLOYEE_HOURS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeHours: true },
        errors: { ...state.errors, employeeHoursError: null },
      };

    case ActionTypes.GET_EMPLOYEE_HOURS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeHours: false },
        employeeHours: new EmployeeHours(action.payload),
        errors: { ...state.errors, employeeHoursError: null },
      };

    case ActionTypes.GET_EMPLOYEE_HOURS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeHours: false },
        errors: { ...state.errors, employeeHoursError: action.payload.message },
      };

    case ActionTypes.RESET_EMPLOYEE_HOURS:
      return { ...state, employeeHours: new EmployeeHours() };

    case ActionTypes.SET_FINANCE_PLAN_EMPLOYEES_PARAMS:
      return {
        ...state,
        planEmployeesParams: new paramsTypes.FinancePlanEmployeesParams({
          ...state.planEmployeesParams,
          ...action.payload,
        }),
      };

    case ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createPlanEmployee: true },
      };

    case ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createPlanEmployee: false },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createPlanEmployee: false },
        errors: {
          ...state.errors,
          planEmployeesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editPlanEmployee: true },
      };

    case ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editPlanEmployee: false },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editPlanEmployee: false },
        errors: {
          ...state.errors,
          planEmployeesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deletePlanEmployee: true },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deletePlanEmployee: false },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deletePlanEmployee: false },
        errors: {
          ...state.errors,
          planEmployeesError: action.payload.message,
        },
      };

    case ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, importResources: true },
      };

    case ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, importResources: false },
        errors: { ...state.errors, importResourcesError: null },
      };

    case ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, importResources: false },
        errors: {
          ...state.errors,
          importResourcesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.SET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO:
      return {
        ...state,
        loading: { ...state.loading, importResources: false },
        importResourcesCheckedInfo: action.payload.map(
          (info: ImportResourcesCheckedInfoType) =>
            new ChekedInfo({
              user: info.user,
              originalValue: info.resourceLoadPercent,
              newValue: info.resultLoadPercent,
            }),
        ),
      };

    case ActionTypes.SET_PLANNED_EXPENSE_PARAMS:
      return {
        ...state,
        plannedExpenseParams: new paramsTypes.PlannedExpenseParams({
          ...state.plannedExpenseParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getPlannedExpense: true },
        errors: { ...state.errors, plannedExpenseError: null },
      };

    case ActionTypes.GET_PLANNED_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPlannedExpense: false },
        plannedExpense: {
          ...action.payload,
          expenseReportParts: action.payload.expenseReportParts.map(
            (item: PlannedExpenseReportItem) => new PlannedExpenseReportItem(item),
          ),
        },
        errors: { ...state.errors, plannedExpenseError: null },
      };

    case ActionTypes.GET_PLANNED_EXPENSE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getPlannedExpense: false },
        errors: { ...state.errors, plannedExpenseError: action.payload.message },
      };

    case ActionTypes.PUT_PLANNED_EXPENSE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editPlannedExpense: true },
      };

    case ActionTypes.PUT_PLANNED_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editPlannedExpense: false },
        errors: { ...state.errors, plannedExpenseError: null },
      };

    case ActionTypes.PUT_PLANNED_EXPENSE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editPlannedExpense: false },
        errors: {
          ...state.errors,
          plannedExpenseError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_PLANNED_EXPENSE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deletePlannedExpense: true },
      };

    case ActionTypes.DELETE_PLANNED_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deletePlannedExpense: false },
        errors: { ...state.errors, plannedExpenseError: null },
      };

    case ActionTypes.DELETE_PLANNED_EXPENSE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deletePlannedExpense: false },
        errors: {
          ...state.errors,
          plannedExpenseError: action.payload.message,
        },
      };

    case ActionTypes.RESET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO:
      return {
        ...state,
        importResourcesCheckedInfo: [],
      };

    case ActionTypes.GET_DATES_OF_EXISTING_PLANS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDatesOfExistingPlans: true },
      };

    case ActionTypes.GET_DATES_OF_EXISTING_PLANS_SUCCESS:
      return {
        ...state,
        datesOfExistingPlans: action.payload,
        loading: { ...state.loading, getDatesOfExistingPlans: false },
        errors: { ...state.errors, datesOfExistingPlans: null },
      };

    case ActionTypes.GET_DATES_OF_EXISTING_PLANS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getDatesOfExistingPlans: false },
        errors: {
          ...state.errors,
          datesOfExistingPlans: action.payload,
        },
      };

    case ActionTypes.RESET_DATES_OF_EXISTING_PLANS:
      return {
        ...state,
        datesOfExistingPlans: [],
      };

    default:
      return state;
  }
};

export default financeReducer;
