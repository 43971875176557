import React from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { CandidateSpecializationType } from '../../../types/candidates';

type ModalDeleteCandidateSpecializationProps = {
  candidateSpecialization: CandidateSpecializationType;
  isOpen: boolean;
  isLoading: boolean;
  error: string | null | RejectValueErrors[];
  onCloseRequest: () => void;
  onDeleteRequest: (data: { data: string; callback: () => void }) => void;
};

function ModalDeleteCandidateSpecialization({
  candidateSpecialization,
  isOpen,
  isLoading,
  error,
  onCloseRequest,
  onDeleteRequest,
}: ModalDeleteCandidateSpecializationProps) {
  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.deleteModalWithItemNameTitle, {
        name: candidateSpecialization.name,
      })}
      size={'small'}
      classNameModal="center"
    >
      <ErrorMessage>{error}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCloseRequest}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            onDeleteRequest({ data: candidateSpecialization.id, callback: onCloseRequest });
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteCandidateSpecialization;
