import request from '../utils/request.utils';

export function getJiraProjects() {
  const options: RequestConfig = {
    method: 'get',
    url: `/jira/jiraProjects`,
    params: {
      sort: 'name,asc',
      size: 10000,
    },
  };
  return request(options);
}
