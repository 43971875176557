import React, { useMemo } from 'react';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Icon from '../../Icon';
import Button from '../../Button';
import { UserInfo } from '../../../enums/users.enum';
import TableUserAvatar from '../../TableUserAvatar';
import AccessChecker from '../../AccessChecker';
import { UPDATE_RESOURCE } from '../../../constants/policies.constants';

type ModalResourceInfoProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  requestError?: string;
  resource: {
    user: {
      id: string;
    };
    endDate: string;
    startDate: string;
    workloads: { percent: number; projectGroup: { fullName: string; id: string | null } }[];
    includedInPlanning: boolean;
  } | null;
  onEditRequest: () => void;
  modalStyle: React.CSSProperties;
};

function ModalResourceInfo({ isOpen, onCloseRequest, resource, onEditRequest, modalStyle }: ModalResourceInfoProps) {
  const intl = useIntl();

  const modalOptions = useMemo(
    () => (
      <>
        <AccessChecker verifiablePolicies={[UPDATE_RESOURCE]}>
          <Button
            externalClass={'modal__options-btn'}
            onClick={() => {
              document.body.style.overflow = 'auto';
              onCloseRequest();
              onEditRequest();
            }}
            color="white"
          >
            <Icon iconName={'pencil'} externalClass={'modal__options-icon'} />
          </Button>
        </AccessChecker>
      </>
    ),
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.infoModalTitle)}
      classNameModal="modal--popover resource-info-modal"
      shouldCloseOnOverlayClick
      modalStyle={modalStyle}
      modalOptions={modalOptions}
    >
      <div className="modal__grid__item">
        <div className="modal__grid__item-name">
          <FormattedMessage {...messages.memberLabel} />
        </div>
        <div className="modal__grid__item-value">
          <TableUserAvatar users={[new UserInfo(resource?.user)]} fileSize={48} />
        </div>
      </div>
      <div className="modal__grid__item">
        <div className="modal__wrapper">
          <div className="modal__info-row modal__info-row--participants modal__info-row--half">
            <div className="modal__info-row__name">
              <FormattedMessage {...messages.startDateLabel} />
            </div>
            <div className="modal__info-row__value">{resource?.startDate}</div>
          </div>
          <div className="modal__info-row modal__info-row--participants modal__info-row--half">
            <div className="modal__info-row__name">
              <FormattedMessage {...messages.endDateLabel} />
            </div>
            <div className="modal__info-row__value">{resource?.endDate}</div>
          </div>
        </div>
      </div>
      <div className="modal__grid__item">
        <div className="modal__grid__item-name">
          <FormattedMessage {...messages.projectsLabel} />
        </div>
        <div className="modal__grid__item-value">
          {resource?.workloads.map(item => (
            <div key={item.projectGroup.id} className="modal__wrapper">
              <div className="modal__info-row modal__info-row--participants modal__info-row--half">
                {item.projectGroup.fullName}
              </div>
              <div className="modal__info-row modal__info-row--participants modal__info-row--half">{item.percent}%</div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default ModalResourceInfo;
