import { DaysUsedLimitParams } from './../enums/params/schedule.params';
import { AnyAction } from 'redux';
import { InlineDatePickerPeriods } from '../components/InlineDatePicker/constants';
import * as ActionTypes from '../constants/schedule.constants';
import { ScheduleLayouts } from '../constants/schedule.constants';
import { EventsParams } from '../enums/params/schedule.params';
import * as types from '../types/schedule/scheduleAction.type';
import * as sagaTypes from '../types/schedule/scheduleSaga.type';

export const getEventsList = (data?: { params: Partial<EventsParams>; cb?: () => void }): AnyAction => ({
  type: ActionTypes.GET_EVENTS_LIST_REQUEST,
  payload: data,
});

export const getEvent = (id: string, cb?: () => void): AnyAction => ({
  type: ActionTypes.GET_EVENT_REQUEST,
  payload: {
    id,
    cb,
  },
});

export const getHolidays = (data?: Partial<EventsParams>): AnyAction => ({
  type: ActionTypes.GET_HOLIDAYS_REQUEST,
  payload: data,
});

export const getAllEvents = (data?: any): AnyAction => ({
  type: ActionTypes.GET_ALL_EVENTS,
  payload: data,
});

export const getEventComments = (data: types.GetEventCommentsActionType): sagaTypes.GetEventCommentsSagaType => ({
  type: ActionTypes.GET_EVENT_COMMENTS_REQUEST,
  payload: data,
});

export const getEventSecretComments = (
  data: types.GetEventCommentsActionType,
): sagaTypes.GetEventSecretCommentsSagaType => ({
  type: ActionTypes.GET_EVENT_SECRET_COMMENTS_REQUEST,
  payload: data,
});

export const createEventComment = (data: types.CreateEventCommentActionType): sagaTypes.CreateEventCommentSagaType => ({
  type: ActionTypes.POST_EVENT_COMMENT_REQUEST,
  payload: data,
});

export const createSecretEventComment = (
  data: types.CreateEventCommentActionType,
): sagaTypes.CreateEventSecretCommentSagaType => ({
  type: ActionTypes.POST_EVENT_SECRET_COMMENT_REQUEST,
  payload: data,
});

export const editEventComment = (data: types.EditEventCommentActionType): sagaTypes.EditEventCommentSagaType => ({
  type: ActionTypes.PUT_EVENT_COMMENT_REQUEST,
  payload: data,
});

export const editEventSecretComment = (
  data: types.EditEventCommentActionType,
): sagaTypes.EditEventSecretCommentSagaType => ({
  type: ActionTypes.PUT_EVENT_SECRET_COMMENT_REQUEST,
  payload: data,
});

export const deleteEventComment = (data: types.DeleteEventCommentActionType): sagaTypes.DeleteEventCommentSagaType => ({
  type: ActionTypes.DELETE_EVENT_COMMENT_REQUEST,
  payload: data,
});

export const deleteEventSecretComment = (
  data: types.DeleteEventCommentActionType,
): sagaTypes.DeleteEventSecretCommentSagaType => ({
  type: ActionTypes.DELETE_EVENT_SECRET_COMMENT_REQUEST,
  payload: data,
});

export const resetEvent = (): AnyAction => ({
  type: ActionTypes.RESET_EVENT,
});

export const resetEvents = (): AnyAction => ({
  type: ActionTypes.RESET_EVENTS,
});

export const getEventType = (id: string): AnyAction => ({
  type: ActionTypes.GET_EVENT_TYPE_REQUEST,
  payload: id,
});

export const resetEventType = (): AnyAction => ({
  type: ActionTypes.RESET_EVENT_TYPE,
});

export const createNewEvent = (data: any): AnyAction => ({
  type: ActionTypes.POST_EVENT_REQUEST,
  payload: data,
});

export const setEventParams = (data: Partial<EventsParams>, isGetEventsList = true): AnyAction => ({
  type: ActionTypes.SET_EVENT_PARAMS_REQUEST,
  payload: {
    data,
    isGetEventsList,
  },
});

export const setUserScheduleTabParams = (data: Partial<EventsParams>): AnyAction => ({
  type: ActionTypes.SET_USER_SCHEDULE_TAB_PARAMS_REQUEST,
  payload: data,
});

export const resetEventErrors = (): AnyAction => ({
  type: ActionTypes.RESET_EVENT_ERRORS,
});

export const deleteEvent = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_EVENT_REQUEST,
  payload: data,
});

export const editEventType = (data: any): AnyAction => ({
  type: ActionTypes.PUT_EVENT_REQUEST,
  payload: data,
});

export const getOffDaysInfo = (data?: { dateFrom: string; dateTo: string; offices: string[] }): AnyAction => ({
  type: ActionTypes.GET_DAYS_OFF_INFO_REQUEST,
  payload: data,
});

export const setScheduleLayout = (data: ScheduleLayouts) => ({
  type: ActionTypes.SET_SCHEDULE_LAYOUT,
  payload: data,
});

export const setCalendarPeriod = (data: InlineDatePickerPeriods) => ({
  type: ActionTypes.SET_CALENDAR_PERIOD,
  payload: data,
});

export const resetErrors = (): AnyAction => ({
  type: ActionTypes.RESET_SCHEDULE_ERRORS,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_SCHEDULE_STATE,
});

export const setDaysUsedLimitParams = (data: Partial<DaysUsedLimitParams>): AnyAction => ({
  type: ActionTypes.SET_DAYS_USED_LIMIT_PARAMS,
  payload: data,
});

export const editDaysLimit = (data: any, cb: () => void): AnyAction => ({
  type: ActionTypes.PUT_DAYS_LIMIT_REQUEST,
  payload: { data, cb },
});
