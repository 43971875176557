import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, { components, OptionTypeBase } from 'react-select';
import classNames from 'classnames';
import Icon from '../Icon';
import ErrorMessage from '../ErrorMessage';
import Checkbox from '../Checkbox';
import { useClickOutsideHandler, useUpdateDropdownMenuPosition } from '../../utils/hooks.utils';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { isArray, isEmpty, isNil, omit } from 'lodash-es';
import { MAX_LABEL_LENGTH, MIN_OPTIONS_SIZE_FOR_SEARCH } from './utils';
import Avatar from '../Profile/Avatar';
import { useFloating, offset, shift, size, limitShift } from '@floating-ui/react-dom';
import NestedOptions from './NestedOptions';

export type FilterOptionType = {
  label: string;
  value: any;
};

export type FilterGroupedOptionType = {
  label: string;
  options: FilterOptionType[];
};

type FilterProps = {
  options: OptionTypeBase[];
  handleChange?: (val: any, paramsName?: string) => void;
  handleClearOptions?: () => void;
  formatOptionLabel?: (el: any) => React.ReactNode;
  formatSelectedOptions?: (options: OptionTypeBase[]) => OptionTypeBase[];
  getActiveNestedOptionsCount?: (value?: any) => number | undefined;
  getNestedOptions?: () => { id: string; name: string }[];
  handleNestedOptionClick?: (optionId: string, nestedOptionId: string) => void;
  handleCheckedNestedOption?: (optionId: string, nestedOptionId: string) => boolean | undefined;
  label: string;
  allLabel?: boolean;
  defaultValue?: OptionTypeBase | OptionTypeBase[];
  value?: OptionTypeBase | OptionTypeBase[];
  externalClass?: string;
  isMulti?: boolean;
  isGrouped?: boolean;
  errorMessage?: string | null;
  iconName?: string;
  isUsersFilter?: boolean;
  isNestedFilter?: boolean;
  showCheckbox?: boolean;
  paramsName?: string;
};

function Filter({
  isUsersFilter,
  isNestedFilter,
  externalClass,
  isMulti = false,
  options,
  errorMessage,
  label,
  iconName,
  value,
  defaultValue,
  allLabel = true,
  showCheckbox = true,
  isGrouped = false,
  paramsName,
  handleChange,
  handleClearOptions,
  formatOptionLabel = ({ label }) => <span>{label}</span>,
  formatSelectedOptions,
  getActiveNestedOptionsCount = () => 0,
  getNestedOptions = () => [],
  handleNestedOptionClick,
  handleCheckedNestedOption,
}: FilterProps) {
  const intl = useIntl();
  const defaultClass = classNames('filter', externalClass, {
    'nested-filter': isNestedFilter,
  });
  const ref = useRef<any>(null);
  const focusedIndexRef = useRef(0);
  const [focusedOptionRect, setFocusedOptionRect] = useState<{
    rect: { getBoundingClientRect: () => DOMRect } | null;
    data: FilterOptionType;
  }>({
    rect: null,
    data: { label: '', value: '' },
  });
  const searchRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [customOptions, setCustomOptions] = useState(options);
  const [inputValue, setInputValue] = useState('');
  const maxHeight = useRef<any>(null);
  const { refs, floatingStyles, update } = useFloating({
    middleware: [
      offset(5),
      shift({
        boundary: document.body,
        limiter: limitShift({
          offset: 5,
          mainAxis: true,
        }),
      }),
      size({
        apply({ availableHeight, availableWidth, elements }) {
          maxHeight.current = `${availableHeight}px`;
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 5}px`,
          });
        },
      }),
    ],
    open: isOpen,
    placement: 'bottom-start',
  });

  useEffect(() => {
    if (isOpen) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      // if any scroll is attempted, set this to the previous value
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    }
    return () => {
      window.onscroll = () => null;
    };
  }, [isOpen]);

  useEffect(() => {
    setCustomOptions(options);
    if (value && isArray(value)) {
      handleCustomOptions(value);
    }
  }, [options]);

  useEffect(() => {
    setInputValue('');
    update();
    if (!isOpen && isMulti) {
      handleSortOptions();
    }

    const searchInput = searchRef.current;
    if (isOpen && isMulti && searchInput) {
      searchInput.focus();
    }
  }, [isOpen]);

  const handleSelect = useCallback(
    (value: any) => {
      if (!isMulti) {
        setIsOpen(false);
      }
      handleChange && handleChange(value, paramsName);
    },
    [options, handleChange],
  );

  useEffect(() => {
    isOpen && isMulti && searchRef.current?.focus();
  }, [customOptions]);

  const handleSortOptions = (selectedValue?: FilterOptionType) => {
    const select = ref.current;
    const value = select.props.value;
    const currentValue = selectedValue ? selectedValue : value;
    if (currentValue) {
      handleCustomOptions(currentValue);
    }
  };

  const handleCustomOptions = (currentValue: OptionTypeBase[]) => {
    setCustomOptions([
      ...currentValue.sort((a: any, b: any) => {
        const aValue = a.value;
        const bValue = b.value;
        const aLabel = aValue.secondName ? a.value.secondName : aValue;
        const bLabel = bValue.secondName ? b.value.secondName : bValue;
        return !isUsersFilter ? a?.label?.localeCompare(b?.label) : aLabel.localeCompare(bLabel);
      }),
      ...(!isGrouped
        ? options.filter(el => !currentValue.some((selectEl: OptionTypeBase) => selectEl.value === el.value))
        : options.map(group => ({
            label: group.label,
            options: group.options.filter(
              (el: OptionTypeBase) => !currentValue.some((selectEl: OptionTypeBase) => selectEl.value === el.value),
            ),
          }))),
    ]);
  };

  const handleFocusedIndex = (value: number) => (focusedIndexRef.current = value);

  useClickOutsideHandler(
    () => {
      setIsOpen(false);
    },
    { current: ref.current?.select?.menuListRef },
    refs.reference,
  );

  const filterOptionCheck = useCallback(
    (option: OptionTypeBase, inputValue: string) =>
      (option.label.toString().match(new RegExp(inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'ig')) || [])
        .length > 0,
    [],
  );

  const filterOption = useCallback(
    (option: OptionTypeBase, string: any) => {
      // default search
      if (filterOptionCheck(option, string)) return true;

      // check if a group as the filter string as label
      const groupOptions = options.filter(group => {
        return filterOptionCheck(group, string);
      });

      if (!isEmpty(groupOptions)) {
        for (const groupOption of groupOptions) {
          // Check if current option is in group
          if (groupOption.options.some((opt: any) => opt.value === option.value)) {
            return true;
          }
        }
      }
      return false;
    },
    [options],
  );

  const getFilteredOptions = (options: OptionTypeBase[]) =>
    searchRef.current?.value ? options?.filter(el => filterOptionCheck(el, searchRef.current?.value)) : options;

  const handleKeyClick = useCallback(
    (e: React.KeyboardEvent | undefined) => {
      const focusedIndex = focusedIndexRef.current;
      const filteredOptions = getFilteredOptions(customOptions);

      switch (e?.code) {
        case 'ArrowUp': {
          handleFocusedIndex(focusedIndex === 0 ? filteredOptions.length - 1 : focusedIndex - 1);
          break;
        }
        case 'ArrowDown': {
          handleFocusedIndex(focusedIndex >= filteredOptions.length - 1 ? 0 : focusedIndex + 1);
          break;
        }
      }
    },
    [customOptions],
  );

  const clearOptions = useCallback((value: OptionTypeBase) => {
    if (!isEmpty(value)) {
      handleChange && handleChange([], paramsName);
      handleClearOptions && handleClearOptions();
    }
  }, []);

  const formatEmployeeOptionLabel = useCallback(({ value, label }: any) => {
    return (
      <div className="table__data-wrapper">
        <Avatar userInfo={value} size="tiny" externalClass="avatar-select" fileSize={36} />
        <span>{label}</span>
      </div>
    );
  }, []);

  const formatNestedOptionLabel = useCallback(
    data => {
      const countActiveOptions = getActiveNestedOptionsCount(data.value);

      return (
        <div className="filter__option-label-content">
          {data.label}
          <Icon iconName="filter-nested-arrow" />

          {focusedOptionRect.data.value === data.value && (
            <NestedOptions
              optionData={focusedOptionRect}
              getNestedOptions={getNestedOptions}
              handleCheckedNestedOption={handleCheckedNestedOption}
              handleNestedOptionClick={handleNestedOptionClick}
            />
          )}

          {countActiveOptions && countActiveOptions > 0 && (
            <span className="skill-levels-count">{countActiveOptions}</span>
          )}
        </div>
      );
    },
    [
      getActiveNestedOptionsCount,
      getNestedOptions,
      handleNestedOptionClick,
      handleCheckedNestedOption,
      focusedOptionRect,
    ],
  );

  useEffect(() => {
    const filteredOptions = getFilteredOptions(customOptions);
    if (filteredOptions) {
      ref.current.select.state.focusedOption = filteredOptions[focusedIndexRef.current];
    }
  }, [inputValue]);

  useUpdateDropdownMenuPosition(update);

  const filterComponents = useMemo(
    () => ({
      IndicatorSeparator: null,
      DropdownIndicator: () => <Icon iconName="filter-arrow" />,
      SelectContainer: ({ children, ...props }: any) => (
        <components.SelectContainer {...props}>
          {children}
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </components.SelectContainer>
      ),
      Menu: ({ children, ...props }: any) => {
        const { inputValue, value } = props.selectProps;
        const disableClearBtn = !value || isEmpty(value);
        const filteredOptions = getFilteredOptions(props?.options || []);
        const disableSelectAllBtn = isEmpty(filteredOptions) || props?.options?.length === value?.length;

        return (
          <components.Menu {...props}>
            <div className="filter__menu-wrapper" ref={refs.setFloating} style={omit(floatingStyles, 'transform')}>
              {isMulti ? (
                <>
                  {(props.options.length > MIN_OPTIONS_SIZE_FOR_SEARCH || isGrouped) && (
                    <div className="filter__search-wrapper">
                      <Icon iconName="search" />
                      <input
                        ref={searchRef}
                        type="text"
                        className="filter__search"
                        placeholder={intl.formatMessage(messages.searchLabel)}
                        value={inputValue}
                        onMouseDown={e => {
                          e.stopPropagation();
                          //@ts-ignore
                          e.target.focus();
                        }}
                        onTouchEnd={e => {
                          e.stopPropagation();
                          //@ts-ignore
                          e.target.focus();
                        }}
                        autoFocus={ref.current?.state?.menuIsOpen}
                        onChange={e => setInputValue(e.currentTarget.value)}
                        onBlur={e => setTimeout(() => e.target.focus(), 1)}
                      />
                      {inputValue && (
                        <span onClick={() => setInputValue('')}>
                          <Icon iconName="times-circle" />
                        </span>
                      )}
                    </div>
                  )}
                  <div
                    className="filter__button-wrapper"
                    style={{ '--hoverColor': disableClearBtn ? 'transparent' : '#f5f5f5' } as any}
                  >
                    {!isNestedFilter && !isGrouped && (
                      <div
                        className={`filter__option-label ${disableSelectAllBtn ? 'disable' : ''}`}
                        onClick={() => !disableSelectAllBtn && handleSelect(filteredOptions)}
                      >
                        <span className="filter__clear-text">
                          <FormattedMessage {...messages.selectAllLabel} />
                        </span>
                      </div>
                    )}
                    <div
                      className={`filter__option-label ${disableClearBtn ? 'disable' : ''}`}
                      onClick={() => clearOptions(value)}
                    >
                      <span className="filter__clear-text">
                        <FormattedMessage {...messages.clearAllLabel} />
                      </span>
                    </div>
                  </div>
                </>
              ) : null}
              {children}
            </div>
          </components.Menu>
        );
      },
      Control: ({ children, ...props }: any) => {
        return (
          <div onClick={() => setIsOpen(prev => !prev)}>
            <components.Control {...props}>{children}</components.Control>
          </div>
        );
      },
      Option: ({ children, ...props }: any) => {
        const { value, data } = props;
        const filteredOptions = getFilteredOptions(customOptions);

        return (
          <components.Option {...props} innerProps={props.innerProps}>
            <div
              onMouseEnter={e => {
                const btn = e.currentTarget as HTMLElement;
                setFocusedOptionRect({
                  rect: {
                    getBoundingClientRect() {
                      return btn.getBoundingClientRect();
                    },
                  },
                  data,
                });

                handleFocusedIndex(filteredOptions.findIndex((el: OptionTypeBase) => el.value === value));
              }}
              className={classNames('filter__option-label', {
                'is-focused': filteredOptions[focusedIndexRef.current]?.value === value,
              })}
              onMouseLeave={() => setFocusedOptionRect({ rect: null, data: { value: '', label: '' } })}
            >
              {isMulti && showCheckbox && <Checkbox id="" label="" checkedValue={props.isSelected} />}
              {children}
            </div>
          </components.Option>
        );
      },
    }),
    [],
  );

  const valueContainerComponent = useMemo(() => {
    return {
      ValueContainer: ({ children, ...props }: any) => {
        const values = props.selectProps.value;
        const selectedOptions = formatSelectedOptions ? formatSelectedOptions(values) : values;
        const selectedOptionsLength = selectedOptions?.length;
        const firstSelectedOptionLabel = selectedOptions && selectedOptions[0]?.label;
        const formatedLabel =
          firstSelectedOptionLabel?.length >= MAX_LABEL_LENGTH
            ? `${firstSelectedOptionLabel?.slice(0, MAX_LABEL_LENGTH)}...`
            : firstSelectedOptionLabel;
        const showFilterLabel = (selectedOptionsLength === 0 || !isMulti) && label;
        const hasValue = isMulti ? !isEmpty(values) : !isNil(values?.value);
        const selectValueContainerClass = classNames({
          'filter__value-container--with-icon': iconName,
          'bold': hasValue,
        });

        return (
          <components.ValueContainer className={selectValueContainerClass} {...props}>
            {iconName && <Icon iconName={iconName} externalClass="filter__value-container-icon" />}
            {showFilterLabel
              ? `${label}${allLabel ? ':' : ''} ${
                  !props.hasValue && allLabel ? intl.formatMessage(messages.allLabel) : ''
                }`
              : null}
            {children}
            {selectedOptionsLength > 1 ? (
              <div className="multi-select-container">{`${formatedLabel}, ${
                selectedOptionsLength - 1
              } ${intl.formatMessage(messages.moreLabel)}...`}</div>
            ) : isMulti && formatedLabel ? (
              <div className="multi-select-container">{formatedLabel}</div>
            ) : null}
          </components.ValueContainer>
        );
      },
    };
  }, [formatSelectedOptions]);

  return (
    <>
      <div
        ref={refs.setReference}
        //@ts-ignore
        style={{ '--data-transform': floatingStyles.transform, '--data-max-height': maxHeight.current }}
      >
        <ReactSelect
          ref={ref}
          className={defaultClass}
          classNamePrefix="filter"
          defaultValue={defaultValue}
          isSearchable={false}
          filterOption={isGrouped ? filterOption : filterOptionCheck}
          formatOptionLabel={
            isUsersFilter ? formatEmployeeOptionLabel : isNestedFilter ? formatNestedOptionLabel : formatOptionLabel
          }
          inputValue={inputValue}
          isClearable={false}
          value={value}
          menuIsOpen={isOpen}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          isMulti={isMulti}
          maxMenuHeight={400}
          onKeyDown={handleKeyClick}
          placeholder={null}
          options={customOptions}
          onMenuClose={() => handleFocusedIndex(0)}
          onChange={handleSelect}
          components={{ ...filterComponents, ...valueContainerComponent }}
        />
      </div>
    </>
  );
}

export default React.memo(Filter);
