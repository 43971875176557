import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import { Salary, SALARY_SCHEMA } from '../../../enums/finance/finance.enum';
import Select from '../../Select';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { UserInfo } from '../../../enums/users.enum';
import PoliciesContext from '../../../PoliciesContext';
import { UPDATE_SALARY } from '../../../constants/policies.constants';
import CurrencyInput from '../../CurrencyInput';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import MonthSelect from '../../MonthSelect';
import { addYearCount, lengthYearList } from '../utilts';

type ModalNewSalaryProps = {
  onCloseRequest: () => void;
  createSalary: (data: { data: Salary; callback: () => void }) => void;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  users: UserInfo[];
  userMonthClicked: { user: UserInfo; month: string } | null;
  setUserMonthClicked: (data: { user: UserInfo; month: string } | null) => void;
};

function ModalNewSalary({
  onCloseRequest,
  createSalary,
  error,
  isLoading,
  isOpen,
  users,
  userMonthClicked,
  setUserMonthClicked,
}: ModalNewSalaryProps) {
  const intl = useIntl();

  const policies = useContext(PoliciesContext);

  const updatePolicy = useMemo(() => policies.find(policy => policy.policy.name === UPDATE_SALARY), [policies]);

  const { values, errors, touched, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: new Salary({
      startDate: userMonthClicked?.month,
      endDate: userMonthClicked?.month,
    }),
    validateOnChange: false,
    validationSchema: SALARY_SCHEMA,
    onSubmit: data => {
      return createSalary({
        data,
        callback: onCloseRequest,
      });
    },
  });
  const [totalPay, setTotalPay] = useState<number | undefined>();

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    if (userMonthClicked) {
      setFieldValue(`user`, userMonthClicked.user);
      setFieldValue(`userId`, userMonthClicked.user.id);
    }

    return () => {
      setUserMonthClicked(null);
    };
  }, [userMonthClicked]);

  useEffect(() => {
    if (values.pay.float || values.tax.float) {
      setTotalPay((values.pay.float || 0) + (values.tax.float || 0));
    } else {
      setTotalPay(undefined);
    }
  }, [values.pay, values.tax]);

  const usersOptions = useMemo(() => {
    return users
      ?.filter(el => !updatePolicy?.isOfficeSpecific || updatePolicy?.officeIds.some(id => id === el.office?.id))
      .map(el => ({
        value: el,
        label: el.fullName,
      }));
  }, [users, updatePolicy]);

  const userValue = useMemo(
    () => values.userId && usersOptions?.find(({ value }: { value: { id: string } }) => value.id === values.userId),
    [usersOptions, values],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleChangeUser = (item: any) => {
    setFieldValue(`user`, item.value || null);
    setFieldValue(`userId`, item.value?.id || null);
  };

  const handlePayChange = useCallback((value: CurrencyInputOnChangeValues) => {
    setFieldValue('pay', value);
  }, []);

  const handleTaxChange = useCallback((value: CurrencyInputOnChangeValues) => {
    setFieldValue('tax', value);
  }, []);

  const handleStartYearChange = useCallback(({ value }) => {
    setFieldValue('startYear', value);
  }, []);

  const handleStartMonthChange = useCallback(({ value }) => {
    setFieldValue('startMonth', value);
  }, []);

  const handleEndYearChange = useCallback(({ value }) => {
    setFieldValue('endYear', value);
  }, []);

  const handleEndMonthChange = useCallback(({ value }) => {
    setFieldValue('endMonth', value);
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.newSalaryTitle)}
        size={'small'}
        classNameModal={'salary-modal center'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Select
                isSearchable
                isClearable
                label={intl.formatMessage(messages.memberLabel)}
                options={usersOptions}
                handleChange={handleChangeUser}
                hasError={hasError('userId')}
                errorMessage={errors?.userId}
                //@ts-ignore
                value={userValue}
                isDisabled={!!userMonthClicked}
              />
            </div>
            <div className="form__note form__input-block">
              <FormattedMessage {...messages.periodLabel} />
            </div>
            <div className="form__inputs-subwrapper">
              <div className="form__input-block">
                <MonthSelect
                  year={values.startYear}
                  month={values.startMonth}
                  isShortMonths
                  isClearable={false}
                  withLabel={false}
                  addYearCount={addYearCount}
                  lengthYearList={lengthYearList}
                  selectExternalClass="select__no-label"
                  errorMessageYear={errors.startYear}
                  errorMessageMonth={errors.startMonth}
                  handleYearChange={handleStartYearChange}
                  handleMonthChange={handleStartMonthChange}
                />
              </div>
              <div className="form__month-select-separator">-</div>
              <div className="form__input-block">
                <MonthSelect
                  year={values.endYear}
                  month={values.endMonth}
                  isShortMonths
                  isClearable={false}
                  withLabel={false}
                  addYearCount={addYearCount}
                  lengthYearList={lengthYearList}
                  selectExternalClass="select__no-label"
                  errorMessageYear={errors.endYear}
                  errorMessageMonth={errors.endMonth}
                  handleYearChange={handleEndYearChange}
                  handleMonthChange={handleEndMonthChange}
                />
              </div>
            </div>
            <div className="form__inputs-subwrapper">
              <>
                <CurrencyInput
                  label={intl.formatMessage(messages.payLabel)}
                  name={'pay'}
                  wrapperClass="form__input-block--third"
                  value={values.pay.value}
                  onChange={handlePayChange}
                  hasError={hasError('pay') || hasError('pay.float')}
                  //@ts-ignore
                  errorMessage={errors?.pay?.float || errors?.pay}
                />
                <CurrencyInput
                  label={intl.formatMessage(messages.taxLabel)}
                  name={'tax'}
                  wrapperClass="form__input-block--third"
                  value={values.tax.value}
                  onChange={handleTaxChange}
                  hasError={hasError('tax')}
                  //@ts-ignore
                  errorMessage={errors?.tax?.float || errors?.tax}
                />
                <CurrencyInput
                  label={intl.formatMessage(messages.totalRow)}
                  name={'total'}
                  wrapperClass="form__input-block--third"
                  value={totalPay}
                  disabled
                  externalClass={'total-input'}
                />
              </>
            </div>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons display-center">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              onClick={onCloseRequest}
              type={'button'}
              block
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading} block>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalNewSalary;
