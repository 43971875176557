import { defineMessages } from 'react-intl';
import {
  projectsLabel,
  totalRow,
  factColumn,
  planColumn,
  departmentsMembersLabel,
  officesLabel,
  departmentsLabel,
  employeesLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  projectsLabel,
  totalRow,
  factColumn,
  planColumn,
  departmentsMembersLabel,
  officesLabel,
  departmentsLabel,
  employeesLabel,
  workDaysTitle: {
    id: 'palnning.user.hours.layout.пeneralized.view.column.work.days',
    defaultMessage: 'Work Days',
  },
  workHoursTitle: {
    id: 'palnning.user.hours.layout.пeneralized.view.column.work.hours',
    defaultMessage: 'Work Hours',
  },
  hoursTitle: {
    id: 'palnning.user.hours.layout.пeneralized.view.column.hours',
    defaultMessage: 'Hours',
  },
  projectHoursTitle: {
    id: 'palnning.user.hours.layout.пeneralized.view.column.project.hours',
    defaultMessage: 'Project Hours',
  },
  allHoursTitle: {
    id: 'palnning.user.hours.layout.пeneralized.view.column.all.hours',
    defaultMessage: 'All Hours',
  },
  normativeTitle: {
    id: 'palnning.user.hours.layout.пeneralized.view.column.normative',
    defaultMessage: 'Normative',
  },
  planNormTitle: {
    id: 'palnning.user.hours.layout.пeneralized.view.column.plan.norm',
    defaultMessage: 'Plan/Norm. (%)',
  },
  allFactNormTitle: {
    id: 'palnning.user.hours.layout.пeneralized.view.column.all.fact.norm',
    defaultMessage: 'All Fact/Norm. (%)',
  },
  projectFactPlanTitle: {
    id: 'palnning.user.hours.layout.пeneralized.view.column.prj.fact.plan',
    defaultMessage: 'Prj. Fact/Plan (%)',
  },
  departmentsEmployeesProjectGroupsTitle: {
    id: 'palnning.user.hours.layout.detailed.view.column.departments.members.project.groups',
    defaultMessage: 'Departments / Members / Project Groups',
  },
  normTitle: {
    id: 'palnning.user.hours.layout.detailed.view.column.norm',
    defaultMessage: 'Norm',
  },
  planFactTitle: {
    id: 'palnning.user.hours.layout.detailed.view.column.plan.fact',
    defaultMessage: 'Plan / Fact (%)',
  },
  membersFromResourcesManagementLabel: {
    id: 'planning.user.hours.label.members.from.resources.management',
    defaultMessage: 'Members from Resources Management',
  },
});
