import { defineMessages } from 'react-intl';
import { newButton, filtersLabel, exportToXLSLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  newButton,
  filtersLabel,
  exportToXLSLabel,
  pageTitle: {
    id: 'people.title.members',
    defaultMessage: 'Members',
  },
});
