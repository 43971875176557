import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import {
  EmployeeGroup,
  EventNotificationSettings,
  FollowUp,
  FOLLOW_UP_VALIDATION_SCHEMA,
} from '../../../enums/notifications.enum';
import Select from '../../../components/Select/index';
import { UserInfo } from '../../../enums/users.enum';
import Avatar from '../../Profile/Avatar';
import { isArray, isString } from 'lodash-es';
import Icon from '../../Icon';
import { OfficeInfo } from '../../../enums/libraries.enum';
import NumberInput from '../../NumberInput';
import { EventTypeInfo } from '../../../enums/calendar.enum';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { scrollToError } from '../../../utils';

type ModalNewFollowUpProps = {
  followUpError: string | null | RejectValueErrors[];
  offices: OfficeInfo[];
  users: UserInfo[];
  employeeGroups: EmployeeGroup[];
  eventTypes: EventTypeInfo[];
  isLoading: boolean;
  isOpen: boolean;
  onCloseRequest: () => void;
  createNewFollowUp: (data: any) => void;
  resetErrors: () => void;
};

function ModalNewFollowUp({
  followUpError,
  isLoading,
  users,
  employeeGroups,
  eventTypes,
  offices,
  isOpen,
  onCloseRequest,
  createNewFollowUp,
  resetErrors,
}: ModalNewFollowUpProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError, resetForm } = useFormik({
    initialValues: new FollowUp(),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: FOLLOW_UP_VALIDATION_SCHEMA,
    onSubmit: data => {
      return createNewFollowUp({ data, callback: resetAndExit });
    },
  });

  useSetFieldsErrors(followUpError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const usersOptions = useMemo(() => {
    return users.map((el: UserInfo) => {
      return {
        label: el.fullName,
        value: el,
      };
    });
  }, [users]);

  const officesOptions = useMemo(() => {
    return offices.map((office: OfficeInfo) => {
      return {
        label: office.name,
        value: office.id,
      };
    });
  }, [offices]);

  const eventTypesOptions = useMemo(() => {
    return eventTypes.map((eventTypes: EventTypeInfo) => {
      return {
        label: eventTypes.name,
        value: eventTypes.id,
      };
    });
  }, [eventTypes]);

  const employeeGroupsOptions = useMemo(() => {
    return employeeGroups.map((employeeGroup: EmployeeGroup) => {
      return {
        label: employeeGroup.name,
        value: employeeGroup.id,
      };
    });
  }, [employeeGroups]);

  const formatOptionLabel = useCallback(({ value, label }: any) => {
    return !isString(value) ? (
      <div className="table__data-wrapper">
        <Avatar userInfo={value} size="tiny" externalClass="avatar-select" fileSize={36} />
        <span>{label}</span>
      </div>
    ) : (
      <span className="custom-value">{label}</span>
    );
  }, []);

  const formatCreateLabel = useCallback(inputValue => {
    return (
      <div className="select__create-wrapper">
        <Icon iconName="plus" externalClass="select__add-icon" />
        <span>
          <FormattedMessage {...messages.addButton} /> &nbsp;
          {inputValue}
        </span>
      </div>
    );
  }, []);

  const handleRecipientsChange = useCallback(value => {
    const emails = value.filter((data: any) => data.__isNew__);
    setFieldValue(
      'userIds',
      value.filter(({ value }: { value: UserInfo }) => value.id).map(({ value }: { value: UserInfo }) => value.id),
    );
    setFieldValue(
      'emails',
      emails.map(({ value }: { value: UserInfo }) => value),
    );
  }, []);

  const handleOfficesChange = useCallback(value => {
    setFieldValue(
      'officeIds',
      value.map(({ value }: { value: OfficeInfo }) => value),
    );
  }, []);

  const handleEmployeeGroupsChange = useCallback(value => {
    setFieldValue(
      'employeeGroupIds',
      value.map(({ value }: { value: OfficeInfo }) => value),
    );
  }, []);

  const addEventSetting = () => {
    setFieldValue('eventSettings', [...values.eventSettings, new EventNotificationSettings()]);
  };

  const handleEventTypeChange = useCallback((newValue: any, index: number) => {
    setFieldValue(`eventSettings[${index}].eventTypeId`, newValue.value);
  }, []);

  const handleCountUpRemindBefore = useCallback(
    (index: number) => {
      const count = values.eventSettings[index]?.remindDaysBefore || 0;
      setFieldValue(`eventSettings[${index}].remindDaysBefore`, count + 1);
      if (count < 0) {
        setFieldValue(`eventSettings[${index}].remindDaysBefore`, 1);
      }
    },
    [values],
  );

  const handleCountDownRemindBefore = useCallback(
    (index: number) => {
      const count = values.eventSettings[index]?.remindDaysBefore || 2;
      if (count > 1) {
        setFieldValue(`eventSettings[${index}].remindDaysBefore`, count - 1);
      }
      if (count <= 0) {
        setFieldValue(`eventSettings[${index}].remindDaysBefore`, 1);
      }
    },
    [values],
  );

  const removeEventSetting = (i: any) => {
    setFieldValue(
      'eventSettings',
      values.eventSettings.filter((el: any, index) => index !== i),
    );
  };

  const getOptionLabel = useCallback(value => {
    return value.__isNew__ ? value.value : value.label;
  }, []);

  const eventErrors: any = useMemo(() => errors.eventSettings, [errors.eventSettings]);
  const emailsErrors: any = useMemo(() => {
    if (isArray(errors.emails)) {
      return errors.emails.filter(email => !!email);
    }
  }, [errors.emails]);

  return (
    <Modal isOpen={isOpen} onRequestClose={resetAndExit} title={intl.formatMessage(messages.newFollowUpTitle)}>
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__group">
            <div className="form__input-block">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages.nameColumn)}
                defaultValue={values.name}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
            <div className="form__input-block">
              <Select
                isSearchable
                isMulti
                isCreatable
                isSelectWithAvatar
                label={intl.formatMessage(messages.recipientsLabel)}
                formatOptionLabel={formatOptionLabel}
                formatCreateLabel={formatCreateLabel}
                getOptionLabel={getOptionLabel}
                name={`userIds`}
                options={usersOptions}
                handleChange={handleRecipientsChange}
                errorMessage={emailsErrors && emailsErrors[0]}
              />
            </div>

            <div className="form__input-block">
              <Select
                isSearchable
                isMulti
                label={intl.formatMessage(messages.followUpsLabel)}
                name={`employeeGroupIds`}
                options={employeeGroupsOptions}
                handleChange={handleEmployeeGroupsChange}
              />
            </div>
            <div className="form__input-block">
              <Select
                isSearchable
                isMulti
                label={intl.formatMessage(messages.officesLabel)}
                name={`officeIds`}
                options={officesOptions}
                handleChange={handleOfficesChange}
              />
            </div>
          </div>
          <div className="form__input-block">
            {values.eventSettings.length > 0 &&
              values.eventSettings.map((event: EventNotificationSettings, i: number) => (
                <div className="form__group-wrapper" key={i}>
                  <div className="form__input-block">
                    <div className="form__inputs-subwrapper align-start">
                      <Select
                        id={`eventSettings[${i}].eventType`}
                        options={eventTypesOptions}
                        externalClass="select__no-label"
                        errorMessage={eventErrors && eventErrors[i]?.eventTypeId}
                        hasError={hasError(`eventSettings[${i}].eventTypeId`)}
                        handleChange={data => handleEventTypeChange(data, i)}
                      />
                      <NumberInput
                        name={`eventSettings[${i}].remindDaysBefore`}
                        id={`eventSettings[${i}].remindDaysBefore`}
                        defaultValue={values.eventSettings[i].remindDaysBefore}
                        label={intl.formatMessage(messages.remindDaysbeforeLabel)}
                        onChange={handleChange}
                        onCountDown={() => handleCountDownRemindBefore(i)}
                        onCountUp={() => handleCountUpRemindBefore(i)}
                        min={1}
                      />
                    </div>
                  </div>

                  <button className="form__btn-clean-inputs" type="button" onClick={() => removeEventSetting(i)}>
                    <Icon iconName={'cross'} externalClass={'form__btn-icon'} />
                  </button>
                </div>
              ))}
          </div>
          <button className="form__btn-add-group" type="button" onClick={addEventSetting}>
            <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
            <FormattedMessage {...messages.eventLabel} />
          </button>
        </div>
        <ErrorMessage>{followUpError}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} type={'button'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewFollowUp;
