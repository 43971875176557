import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteModalWithItemNameTitle,
  nameColumn,
  cancelButton,
  saveButton,
  deleteButton,
  newProjectTitle: {
    id: 'resumes.projects.modal.new.project.title.new.project',
    defaultMessage: 'New Project',
  },
  editProjectTitle: {
    id: 'resumes.projects.modal.edit.project.title.edit.project',
    defaultMessage: 'Edit Project',
  },
  descriptionTextAreaPlaceholder: {
    id: 'resumes.projects.modal.edit.project.label.description',
    defaultMessage: 'Description',
  },
  projectNoDataNote: {
    id: 'resumes.projects.modal.project.label.there.is.no.description.for.this.project',
    defaultMessage: 'There is no description for this project',
  },
});
