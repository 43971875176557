import { QueryParamsType } from './../utils/params.utils';
import * as paramsTypes from './../enums/params/notifications.params';
import request from '../utils/request.utils';
import { FiltersParams } from '../enums/params.enum';
import { NotificationTemplate } from '../enums/notifications.enum';

export function getMailerSettings(params?: QueryParamsType<paramsTypes.MailerSettingsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/mailerSettings',
    params,
  };
  return request(options);
}

export function createNewMailerSetting(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/mailerSettings',
    data,
  };
  return request(options);
}

export function editMailerSetting(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'patch',
    url: `/mailerSettings/${uuid}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteMailerSetting(uuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/mailerSettings/${uuid}`,
  };
  return request(options);
}

export function getEmployeeGroupsList(
  params?: QueryParamsType<paramsTypes.EmployeeGroupsParams> | QueryParamsType<FiltersParams>,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/employeeGroups',
    params,
  };
  return request(options);
}

export function createNewEmployeeGroup(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/employeeGroups',
    data,
  };
  return request(options);
}

export function editEmployeeGroup(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/employeeGroups/${uuid}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteEmployeeGroup(uuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/employeeGroups/${uuid}`,
  };
  return request(options);
}

export function getFollowUpsList(params?: QueryParamsType<paramsTypes.FollowUpsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/notifications',
    params,
  };
  return request(options);
}

export function createNewFollowUp(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/notifications',
    data,
  };
  return request(options);
}

export function editFollowUp(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/notifications/${uuid}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteFollowUp(uuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/notifications/${uuid}`,
  };
  return request(options);
}

export function getEmailTemplatesList(params?: QueryParamsType<paramsTypes.EmailTemplatesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/templates',
    params,
  };
  return request(options);
}

export function createEmailTemplate(data: NotificationTemplate) {
  const options: RequestConfig = {
    method: 'post',
    url: '/templates',
    data,
  };
  return request(options);
}

export function editEmailTemplate(data: NotificationTemplate) {
  const options: RequestConfig = {
    method: 'put',
    url: `/templates/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteEmailTemplate(uuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/templates/${uuid}`,
  };
  return request(options);
}
