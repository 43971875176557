import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as authActions from '../../actions/auth.actions';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { useKeycloak } from '../../utils/hooks.utils';
import { keycloakLogin } from '../../utils/keycloak.utils';
import image1 from '../../assets/images/sign-in_vector1.png';
import image2 from '../../assets/images/sign-in_vector2.png';
import Icon from '../../components/Icon';

const validationSchema = yup.object().shape({
  username: yup.string().trim().required('Username is a required field'),
  password: yup.string().trim().required('Password is a required field'),
});

function SignIn({ signIn, keycloakSignIn, isLoading, authError }: ConnectedProps<typeof connector>) {
  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async data => signIn(validationSchema.cast(data)),
  });

  const intl = useIntl();
  useKeycloak(keycloakSignIn);
  const hasError = useCallback(
    (fieldName: 'username' | 'password') => {
      return Boolean(errors?.[fieldName] && touched?.[fieldName]);
    },
    [errors, touched],
  );

  return (
    <>
      <div className="page__auth">
        <div className="page__auth__wrapper">
          <div className="page__auth__header">
            <div className="page__auth__header-logo-wrapper">
              <div className="page__auth__header-logo">
                <Icon iconName="logo" externalClass="header__logo-img" />
              </div>
            </div>
            <FormattedMessage {...messages.pageTitle} />
          </div>
          <div className="sso-btn-wrapper">
            <Button type="button" externalClass="sso-btn" color="#4A4A4A" onClick={keycloakLogin}>
              <FormattedMessage {...messages.signInSsoButton} />
            </Button>
          </div>
          <form onSubmit={handleSubmit} className="sign-in-form">
            <Input
              name="username"
              placeholder={intl.formatMessage(messages.userNameInput)}
              defaultValue={values.username}
              externalClass="form__input--no-label sign-in__username"
              onChange={handleChange}
              hasError={hasError('username')}
              errorMessage={errors?.username}
            />
            <Input
              type="password"
              name="password"
              placeholder={intl.formatMessage(messages.passwordInput)}
              defaultValue={values.password}
              externalClass="form__input--no-label sign-in__password"
              onChange={handleChange}
              hasError={hasError('password')}
              errorMessage={errors?.password}
            />

            {authError && <div className="form__req-error">{authError}</div>}
            <Button
              type="submit"
              block
              loading={isLoading}
              disabled={isLoading || !values.password || !values.username}
              color="#c1201e"
            >
              <FormattedMessage {...messages.signInButton} />
            </Button>
          </form>
        </div>
        <div className="sign-in__vector-1">
          <img src={image1} />
        </div>
        <div className="sign-in__vector-2">
          <img src={image2} />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ auth }: RootState) => ({
  isLoading: auth.loading.signin,
  authError: auth.errors.signin,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signIn: (values: any) => dispatch(authActions.signIn(values)),
  keycloakSignIn: (values?: any) => dispatch(authActions.keycloakSignIn(values)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SignIn);
