import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import * as filtersActions from '../../actions/filters.actions';

import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { IncomesExpensesReportParams } from '../../enums/params/finance.params';
import IncomesExpensesReportFilters from '../../components/IncomesExpensesReport/Filters/IncomesExpensesReportFilters';
import { resetParamsChange, useLayout, useParamsChange, useUsersParamsChange } from '../../utils/hooks.utils';
import { IncomesExpensesReportLayouts, incomesExpensesUnsavedParams } from '../../constants/finance.constants';
import classNames from 'classnames';
import TabelsLayout from '../../components/IncomesExpensesReport/Layouts/TabelsLayout';
import ChartLayout from '../../components/IncomesExpensesReport/Layouts/ChartLayout';
import InlineDatePicker from '../../components/InlineDatePicker';
import { DATE_FORMAT } from '../../constants/date.constants';
import moment from 'moment';
import DurationPicker from '../../components/Dropdown';
import { exportIncomesExpensesReport } from '../../actions/export.actions';
import { EFileExtensions } from '../../constants/export.constants';
import PoliciesContext from '../../PoliciesContext';
import { VIEW_INCOMES_EXPENSES_REPORT } from '../../constants/policies.constants';
import { getBaseCurrency } from '../../utils';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import isEqual from 'lodash-es/isEqual';

function IncomesExpensesReport({
  tableData,
  isLoading,
  params,
  errors,
  exportIncomesExpensesReport,
  resetState,
  setParams,
  getClientsFilter,
  getUsersFilter,
  getOfficesFilter,
  getExpenseTypesFilter,
  getIncomeTypesFilter,
  getCurrenciesFilter,
  usersFilter,
  officesFilter,
  clientsFilter,
  expenseTypesFilter,
  incomeTypesFilter,
  currencies,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const [layout, handleChangeActiveLayout] = useLayout(
    IncomesExpensesReportLayouts,
    IncomesExpensesReportLayouts.TABLES,
  );

  const dispatch = useDispatch();
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_INCOMES_EXPENSES_REPORT), [
    policies,
  ]);

  useEffect(() => {
    getUsersFilter();
    getClientsFilter();
    getOfficesFilter();
    getExpenseTypesFilter();
    getIncomeTypesFilter();
    getCurrenciesFilter();
    setSavedFiltersParams({ filterType: FilterTypes.INCOMES_EXPENSES_REPORT_FILTER });

    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.INCOMES_EXPENSES_REPORT_FILTER);

    if (currentSavedFilter && viewPolicy?.isOfficeSpecific) {
      currentSavedFilter.officeIds = currentSavedFilter.officeIds?.filter(
        (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
      );
    }

    setParams(
      currentSavedFilter
        ? new IncomesExpensesReportParams(currentSavedFilter)
        : {
            ...params,
            officeIds: officesFilter.value?.filter(
              (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
            ),
          },
    );
  }, [viewPolicy]);

  const onDateChange = (start: string, end: string) => {
    setParams({
      monthFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      monthTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  };

  const handleMultiParamsChange = useParamsChange(setParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setParams, dispatch);

  const filters = useMemo(
    () => ({
      clients: clientsFilter.clients,
      users: usersFilter.users,
      offices: officesFilter.offices,
      expenseTypes: expenseTypesFilter.expenseTypes,
      incomeTypes: incomeTypesFilter.incomeTypes,
    }),
    [officesFilter.offices, usersFilter.users],
  );

  const setIsShowSalaries = useCallback(
    (value: boolean) => {
      setParams({ isShowSalary: value });
    },
    [params.isShowSalary],
  );

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const handleFiltersControlChange = useCallback(
    value => {
      const fields = convertSavedFieldsToParams(value.fields);

      if (viewPolicy?.isOfficeSpecific) {
        fields.officeIds = fields.officeIds?.filter(
          (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
        );
      }

      setParams(
        new IncomesExpensesReportParams({
          ...fields,
          monthFrom: params.monthFrom,
          monthTo: params.monthTo,
        }),
      );
    },
    [viewPolicy, params],
  );

  const handleClear = useCallback(() => {
    setParams(new IncomesExpensesReportParams({ monthFrom: params.monthFrom, monthTo: params.monthTo }));

    resetParamsChange([FilterParamsName.USER_IDS, FilterParamsName.OFFICE_IDS], dispatch);
  }, [params]);

  const filteredSavedFiltersData = useMemo(
    () =>
      viewPolicy?.isOfficeSpecific
        ? {
            ...savedFiltersData,
            filtersList: savedFiltersData.filtersList
              .map((filter: SavedFilter) => {
                const fields = convertSavedFieldsToParams(filter.fields);

                if (fields.officeIds) {
                  fields.officeIds = fields.officeIds?.filter(
                    (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
                  );

                  if (isEqual(fields, { officeIds: [], extension: 'EXCEL', isShowSalary: true })) {
                    return null;
                  }
                }
                return filter;
              })
              .filter((filter: SavedFilter | null) => filter),
          }
        : savedFiltersData,
    [savedFiltersData, viewPolicy],
  );

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new IncomesExpensesReportParams(), incomesExpensesUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed incomes-expenses-page-header">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <div className="page__panel-top__wrapper--left">
              <h1 className="page__title">
                <FormattedMessage {...messages.pageTitle} />
              </h1>
            </div>
            <div className="page__panel-top">
              <InlineDatePicker
                onDateChange={onDateChange}
                defaultPeriodStart={params.monthFrom}
                defaultPeriodEnd={params.monthTo}
              />
              <div className="page__panel-view">
                <Button
                  externalClass={classNames('page__panel-view__btn', {
                    active: layout === IncomesExpensesReportLayouts.CAHRTS,
                  })}
                  onClick={() => handleChangeActiveLayout(IncomesExpensesReportLayouts.CAHRTS)}
                  color="gray"
                >
                  <Icon iconName="chart" />
                </Button>
                <Button
                  externalClass={classNames('page__panel-view__btn', {
                    active: layout === IncomesExpensesReportLayouts.TABLES,
                  })}
                  onClick={() => handleChangeActiveLayout(IncomesExpensesReportLayouts.TABLES)}
                  color="gray"
                >
                  <Icon iconName="table" />
                </Button>
              </div>
              <DurationPicker
                dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                dropdownList={[
                  {
                    label: intl.formatMessage(messages.exportToXLSLabel),
                    handler: () => exportIncomesExpensesReport(EFileExtensions.XLS),
                  },
                  {
                    label: intl.formatMessage(messages.exportToPDFLabel),
                    handler: () => exportIncomesExpensesReport(EFileExtensions.PDF),
                  },
                ]}
              />
            </div>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <IncomesExpensesReportFilters
                  filters={filters}
                  handleChangeFilterParams={handleMultiParamsChange}
                  handleUsersParamsChange={handleUsersParamsChange}
                  values={params}
                  setIsShowSalaries={setIsShowSalaries}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={filteredSavedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  policyOfficiesIds={viewPolicy?.officeIds}
                  isOfficeSpecific={!!viewPolicy?.isOfficeSpecific}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content incomes-expenses-page-content">
        <div className="page__wrapper">
          {layout === IncomesExpensesReportLayouts.TABLES ? (
            <TabelsLayout
              tableData={tableData}
              errors={errors}
              isLoading={isLoading}
              isShowSalaries={params.isShowSalary}
              baseCurrency={baseCurrency}
            />
          ) : (
            <ChartLayout
              tableData={tableData}
              isShowSalaries={params.isShowSalary}
              params={params}
              baseCurrency={baseCurrency}
            />
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ finance, filters, auth }: RootState) => ({
  errors: finance.errors.projectsError,
  isLoading: finance.loading.getIncomesExpensesReport,
  tableData: finance.incomesExpensesReportListData,
  params: finance.incomesExpensesReportParams,
  usersFilter: filters.usersFilter,
  officesFilter: filters.officesFilter,
  clientsFilter: filters.clientsFilter,
  expenseTypesFilter: filters.expenseTypesFilter,
  incomeTypesFilter: filters.incomeTypesFilter,
  currencies: filters.currenciesFilter.currencies,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setParams: (data: Partial<IncomesExpensesReportParams>) =>
    dispatch(financeActions.setIncomesExpensesReportParams(data)),
  exportIncomesExpensesReport: (extension: EFileExtensions) => dispatch(exportIncomesExpensesReport(extension)),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getClientsFilter: () => dispatch(filtersActions.getClientsFilter()),
  getIncomeTypesFilter: () => dispatch(filtersActions.getIncomeTypesFilter()),
  getExpenseTypesFilter: () => dispatch(filtersActions.getExpenseTypesFilter()),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),
  resetState: () => dispatch(financeActions.resetState()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(IncomesExpensesReport);
