import { defineMessages } from 'react-intl';
import { resetButton, urlLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  pageTitle: {
    id: 'settings.branding.title.branding',
    defaultMessage: 'Branding',
  },
  settingLogoTitle: {
    id: 'settings.branding.title.logo',
    defaultMessage: 'Logo',
  },
  resetButton,
  urlLabel,
  settingColorsTitle: {
    id: 'settings.branding.title.colors',
    defaultMessage: 'Colors',
  },
  settingGlobalAccent: {
    id: 'settings.branding.title.global.accent',
    defaultMessage: 'Global Accent',
  },
  settingGlobalAccentNote: {
    id: 'settings.branding.label.primary.buttons.links.selection.etc',
    defaultMessage: 'Primary buttons, links, selection, etc.',
  },
  settingHeaderBackground: {
    id: 'settings.branding.title.header.background',
    defaultMessage: 'Header Background',
  },
  settingHeaderForeground: {
    id: 'settings.branding.title.header.foreground',
    defaultMessage: 'Header Foreground',
  },
  settingNavigationShadow: {
    id: 'settings.branding.title.navigation.shadow',
    defaultMessage: 'Navigation Shadow',
  },
  settingFaviconTitle: {
    id: 'settings.branding.title.favicon',
    defaultMessage: 'Favicon',
  },
  settingFontsTitle: {
    id: 'settings.branding.title.fonts',
    defaultMessage: 'Fonts',
  },
  titlesInput: {
    id: 'settings.branding.label.titles',
    defaultMessage: 'Titles',
  },
  bodyInput: {
    id: 'settings.branding.label.body',
    defaultMessage: 'Body',
  },
  uploadInput: {
    id: 'settings.branding.radio.label.Upload',
    defaultMessage: 'Upload',
  },
  uploadLogoExtensionError: {
    id: 'settings.branding.upload.logo..extension.error',
    defaultMessage: 'Only png or svg files extensions',
  },
  uploadFaviconExtensionError: {
    id: 'settings.branding.upload.favicon.extension.error',
    defaultMessage: 'Only png files extension',
  },
});
