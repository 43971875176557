import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import messages from './messages';
import { connect, ConnectedProps } from 'react-redux';
import ModalNewFollowUp from '../../components/FollowUps/Modals/ModalNewFollowUp';
import * as notificationsActions from '../../actions/notifications.actions';
import * as filtersActions from '../../actions/filters.actions';
import Table from '../../components/Table';
import { FollowUp } from '../../enums/notifications.enum';
import ModalEditFollowUp from '../../components/FollowUps/Modals/ModalEditFollowUp';
import ModalDeleteFollowUp from '../../components/FollowUps/Modals/ModalDeleteFollowUp';
import { SortParams } from '../../enums/params.enum';
import { FollowUpsParams } from '../../enums/params/notifications.params';
import Pagination from '../../components/Pagination';
import RecipientTable from '../../components/FollowUps/RecipientTable';
import { isEmpty, uniqBy } from 'lodash-es';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_NOTIFICATION, UPDATE_NOTIFICATION } from '../../constants/policies.constants';

function FollowUps({
  tableData,
  params,
  officeList,
  eventTypeList,
  userList,
  employeeGroupList,
  isLoading,
  followUpError,
  followUpsListError,
  setFollowUpsParams,
  getFollowUpsList,
  getOfficesFilter,
  getEmployeeGroupsFilter,
  getUsersFilter,
  getEventTypesFilter,
  createNewFollowUp,
  editFollowUp,
  deleteFollowUp,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const [modalNewFollowUpIsOpen, setMoldaNewFollowUpIsOpen] = useState(false);
  const [modalEditFollowUpIsOpen, setMoldaEditFollowUpIsOpen] = useState(false);
  const [modalDeleteFollowUpIsOpen, setMoldaDeleteFollowUpIsOpen] = useState(false);
  const [followUpClicked, setFollowUpClicked] = useState(new FollowUp());

  const intl = useIntl();

  const openNewFollowUpModal = useCallback(() => {
    setMoldaNewFollowUpIsOpen(true);
  }, []);

  const closeNewFollowUpModal = useCallback(() => {
    setMoldaNewFollowUpIsOpen(false);
  }, []);

  const openEditFollowUpModal = useCallback(() => {
    setMoldaEditFollowUpIsOpen(true);
  }, []);

  const closeEditFollowUpModal = useCallback(() => {
    modalEditFollowUpIsOpen && setFollowUpClicked(new FollowUp());
    setMoldaEditFollowUpIsOpen(false);
  }, [modalEditFollowUpIsOpen]);

  const openDeleteFollowUpModal = useCallback(() => {
    setMoldaDeleteFollowUpIsOpen(true);
  }, []);

  const closeDeleteFollowUpModal = useCallback(() => {
    setMoldaDeleteFollowUpIsOpen(false);
  }, []);

  useEffect(() => {
    getOfficesFilter();
    getEmployeeGroupsFilter();
    getUsersFilter();
    getEventTypesFilter();
    getFollowUpsList();
    return () => {
      resetState();
    };
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        sortName: 'name',
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: FollowUp) => row.name,
      },
      {
        name: intl.formatMessage(messages.recipientColumn),
        modifier: (row: FollowUp) =>
          !isEmpty(row.emails) || !isEmpty(row.users) ? <RecipientTable emails={row.emails} users={row.users} /> : null,
      },
      {
        name: intl.formatMessage(messages.followUpColumn),
        modifier: (row: FollowUp) =>
          !isEmpty(row.employeeGroups) && (
            <div className="table__list-data">
              {row.employeeGroups.map(emoloyeeGroup => (
                <span className="data__enum" key={emoloyeeGroup.id}>
                  {emoloyeeGroup.name}
                </span>
              ))}
            </div>
          ),
      },
      {
        name: intl.formatMessage(messages.officeLabel),
        modifier: (row: FollowUp) =>
          !isEmpty(row.offices) && (
            <div className="table__list-data">
              {row.offices.map(office => (
                <span className="data__enum" key={office.id}>
                  {office.name}
                </span>
              ))}
            </div>
          ),
      },
      {
        name: intl.formatMessage(messages.eventTypeColumn),
        modifier: (row: FollowUp) =>
          !isEmpty(row.eventSettings) && (
            <div className="table__list-data">
              {uniqBy(row.eventSettings, el => el.eventType.id).map((eventSetting, index) => (
                <span className="data__enum" key={`${index}-${eventSetting.eventType.id}`}>
                  {eventSetting.eventType.name}
                </span>
              ))}
            </div>
          ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            {intl.formatMessage(messages.editButton)}
          </>
        ),
        handler: (row: FollowUp) => {
          setFollowUpClicked(row);
          openEditFollowUpModal();
        },
        verifiablePolicies: [UPDATE_NOTIFICATION],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            {intl.formatMessage(messages.deleteButton)}
          </>
        ),
        handler: (row: FollowUp) => {
          setFollowUpClicked(row);
          openDeleteFollowUpModal();
        },
        verifiablePolicies: [DELETE_NOTIFICATION],
      },
    ],
    [],
  );

  const sortParams = useMemo(() => new SortParams('name', params), [params]);

  const handleSort = useCallback((sortBy, direction) => setFollowUpsParams({ sortBy, direction }), []);

  const handlePageChange = useCallback(({ selected }) => {
    setFollowUpsParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setFollowUpsParams({ size: data, page: 0 });
  }, []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_NOTIFICATION]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewFollowUpModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table follow-ups-table'}
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getFollowUpsList}
            error={followUpsListError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
          {tableData && (
            <Pagination
              pageable={{
                ...tableData?.pageable,
                ...tableData?.sort,
                totalElements: tableData?.totalElements,
                numberOfElements: tableData?.numberOfElements,
                totalPages: tableData?.totalPages,
              }}
              onPageChange={data => handlePageChange(data)}
              onPageSizeChange={data => handleSizeChange(data)}
            />
          )}
        </div>
      </div>
      {modalNewFollowUpIsOpen && (
        <ModalNewFollowUp
          isOpen
          isLoading={isLoading.createFollowUp}
          users={userList}
          offices={officeList}
          eventTypes={eventTypeList}
          employeeGroups={employeeGroupList}
          followUpError={followUpError}
          onCloseRequest={closeNewFollowUpModal}
          createNewFollowUp={createNewFollowUp}
          resetErrors={resetErrors}
        />
      )}
      {modalEditFollowUpIsOpen && (
        <ModalEditFollowUp
          isOpen
          isLoading={isLoading.editFollowUp}
          followUpData={followUpClicked}
          users={userList}
          offices={officeList}
          eventTypes={eventTypeList}
          employeeGroups={employeeGroupList}
          followUpError={followUpError}
          onCloseRequest={closeEditFollowUpModal}
          editFollowUp={editFollowUp}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteFollowUpIsOpen && (
        <ModalDeleteFollowUp
          isOpen
          isLoading={isLoading.deleteFollowUp}
          followUpError={followUpError}
          followUpData={followUpClicked}
          onCloseRequest={closeDeleteFollowUpModal}
          onDeleteRequest={deleteFollowUp}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ filters, notifications }: RootState) => ({
  tableData: notifications.followUpsTable,
  params: notifications.followUpsParams,
  officeList: filters.officesFilter.offices,
  eventTypeList: filters.eventTypesFilter.eventTypes,
  employeeGroupList: filters.employeeGroupsFilter.employeeGroups,
  userList: filters.usersFilter.users,
  isLoading: notifications.loading,
  followUpError: notifications.errors.followUpError,
  followUpsListError: notifications.errors.followUpsListError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getFollowUpsList: () => dispatch(notificationsActions.getFollowUpsList()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  setFollowUpsParams: (params: Partial<FollowUpsParams>) => dispatch(notificationsActions.setFollowUpsParams(params)),
  getEmployeeGroupsFilter: () => dispatch(filtersActions.getEmployeeGroupsFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getEventTypesFilter: () => dispatch(filtersActions.getEventTypesFilter()),
  createNewFollowUp: (data: any) => dispatch(notificationsActions.createNewFollowUp(data)),
  editFollowUp: (data: any) => dispatch(notificationsActions.editFollowUp(data)),
  deleteFollowUp: (data: any) => dispatch(notificationsActions.deleteFollowUp(data)),
  resetErrors: () => dispatch(notificationsActions.resetErrors()),
  resetState: () => dispatch(notificationsActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FollowUps);
