import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Icon from '../../Icon';
import * as resumesActions from '../../../actions/resumes.actions';
import * as filterActions from '../../../actions/filters.actions';
import * as usersActions from '../../../actions/users.actions';
import Avatar from '../Avatar';
import moment from 'moment';
import Label from '../../Label';
import Dropdown from '../../../components/Dropdown';
import { TRouteParamId } from '../../../enums/common.enum';
import CVDocument from '../../ResumesManagement/CVDocument/CVDocument';
import ModalExportResumes from '../../ResumesManagement/Modals/ModalExportResumes';
import ModalEditResume from '../../ResumesManagement/Modals/ModalEditResume';
import { checkPolicies } from '../../../utils/policies.utils';
import { UPDATE_USER_EXTENDED } from '../../../constants/policies.constants';
import { DATE_FORMAT } from '../../../constants/date.constants';
import PoliciesContext from '../../../PoliciesContext';

function ProfilePublicCV({
  loading,
  errors,
  currentResume,
  getResume,
  editUserResume,
  getCompanyPositionsFilter,
  getProjectsFilter,
  getTechnicalSkillsFilter,
  getForeignLanguagesFilter,
  getLanguageLevelsFilter,
  languageLevelsList,
  foreignLanguagesList,
  technicalSkillList,
  projectList,
  companyPositionList,
  getUserPersonalInfo,
  userPersonalInfo,
  resetState,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const { id } = useParams<TRouteParamId>();
  const intl = useIntl();

  const [modalEditResumeIsOpen, setModalEditResumeIsOpen] = useState(false);
  const [modalExportResumesIsOpen, setModalExportResumesIsOpen] = useState(false);

  useEffect(() => {
    getResume(id);
  }, [id]);

  const updateUserResume = (resumes: { id: string; data: FormData }[]) => {
    editUserResume(resumes, () => {
      getResume(id);
      setModalEditResumeIsOpen(false);
      setModalExportResumesIsOpen(false);
    });
  };

  return (
    <>
      <div className="tabs__content-item active  tabs__content-item__wrapper--public-cv">
        <div className="title-wrapper">
          <div className="tabs__content-item__title">
            <FormattedMessage {...messages.publicCVTitle} />
          </div>
          <div className="page__profile-panel__label-options">
            {currentResume && (
              <>
                <div className="page__cv-updated">
                  <div className="page__cv-updated-date">
                    {moment.utc(currentResume?.lastUpdate).local().format(DATE_FORMAT.lll)}
                  </div>
                  <div className="page__cv-responsible">
                    {currentResume && (
                      <Avatar
                        //  @ts-ignore
                        userInfo={currentResume?.updater}
                        externalClass="page__cv-responsible-avatar"
                        size="tiny"
                        fileSize={36}
                        isUserPageLink
                      />
                    )}
                    <span>{currentResume?.updater.fullName}</span>
                  </div>
                </div>
                <div className="page__profile-panel__label-info">
                  <Label type={currentResume?.approvedStatus ? 'approved' : 'final-decision'} size="bigger">
                    {currentResume?.approvedStatus ? 'APPROVED' : 'NOT APPROVED'}
                  </Label>
                </div>
              </>
            )}
            {!!currentResume && (
              <Dropdown
                dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                dropdownList={[
                  ...(checkPolicies([UPDATE_USER_EXTENDED], policies)
                    ? [
                        {
                          label: intl.formatMessage(messages.editButton),
                          handler: () => setModalEditResumeIsOpen(true),
                        },
                      ]
                    : []),
                  {
                    label: intl.formatMessage(messages.exportToPDFLabel),
                    handler: () => setModalExportResumesIsOpen(true),
                  },
                ]}
              />
            )}
          </div>
        </div>
        <div>
          {!loading.getResume && currentResume && (
            <CVDocument
              resume={currentResume}
              displayingResumeSections={{
                includeSummary: !!(currentResume.summary !== '<p><br></p>' && currentResume.summary),
                includePersonalHighlights: !!(
                  currentResume.personalHighlights !== '<p><br></p>' && currentResume.personalHighlights
                ),
                includeInterestsAndHobbies: !!(
                  currentResume.interestsAndHobbies !== '<p><br></p>' && currentResume.interestsAndHobbies
                ),
                includeProjects: !!currentResume.projects.length,
              }}
            />
          )}
        </div>
        {modalEditResumeIsOpen && currentResume && (
          <ModalEditResume
            isOpen
            onCloseRequest={() => setModalEditResumeIsOpen(false)}
            updateResume={updateUserResume}
            currentResume={currentResume}
            resumeError={errors.editResumeError}
            isLoading={loading.editResume}
            languageLevels={languageLevelsList}
            foreignLanguages={foreignLanguagesList}
            technicalSkills={technicalSkillList}
            projectsList={projectList}
            getForeignLanguagesFilter={getForeignLanguagesFilter}
            getProjectsFilter={getProjectsFilter}
            getTechnicalSkillsFilter={getTechnicalSkillsFilter}
            getLanguageLevelsFilter={getLanguageLevelsFilter}
            getUserPersonalInfo={getUserPersonalInfo}
            userPersonalInfo={userPersonalInfo}
          />
        )}
        {modalExportResumesIsOpen && currentResume && (
          <ModalExportResumes
            isOpen
            onCloseRequest={() => setModalExportResumesIsOpen(false)}
            updateResume={updateUserResume}
            resumeError={errors.editResumeError}
            isLoading={loading.editResume}
            resumesList={[currentResume]}
            positions={companyPositionList}
            languageLevels={languageLevelsList}
            foreignLanguages={foreignLanguagesList}
            technicalSkills={technicalSkillList}
            projectsList={projectList}
            getForeignLanguagesFilter={getForeignLanguagesFilter}
            getProjectsFilter={getProjectsFilter}
            getTechnicalSkillsFilter={getTechnicalSkillsFilter}
            getLanguageLevelsFilter={getLanguageLevelsFilter}
            getCompanyPositionsFilter={getCompanyPositionsFilter}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ resumes, filters, users }: RootState) => ({
  currentResume: resumes.currentResume,
  loading: resumes.loading,
  errors: resumes.errors,
  languageLevelsList: filters.languageLevelsFilter.levels,
  foreignLanguagesList: filters.foreignLanguagesFilter.languages,
  technicalSkillList: filters.technicalSkillsFilter.skills,
  projectList: filters.projectsFilter.projects,
  companyPositionList: filters.companyPositionsFilter.positions,
  userPersonalInfo: users.current.personal,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getResume: (uuid: string) => dispatch(resumesActions.getResume(uuid)),
  editUserResume: (resumes: { id: string; data: FormData }[], callback: () => void) =>
    dispatch(resumesActions.editUserResume({ resumes, callback })),
  getLanguageLevelsFilter: () => dispatch(filterActions.getLanguageLevelsFilter()),
  getForeignLanguagesFilter: () => dispatch(filterActions.getForeignLanguagesFilter()),
  getTechnicalSkillsFilter: () => dispatch(filterActions.getTechnicalSkillsFilter()),
  getProjectsFilter: () => dispatch(filterActions.getProjectsFilter()),
  getCompanyPositionsFilter: () => dispatch(filterActions.getCompanyPositionsFilter()),
  getUserPersonalInfo: (uuid: string) => dispatch(usersActions.getUserPersonalInfo(uuid)),
  resetState: () => dispatch(resumesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfilePublicCV);
