import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import omit from 'lodash-es/omit';

import r from '../constants/routes.constants';
import { routeAuthConfig } from '../routes';

import RouteWithSubRoutes from '../components/RouteWithSubRoutes';

type AuthLayoutProps = {
  callbackUrl?: string;
};

function AuthLayout({ callbackUrl }: AuthLayoutProps) {
  return (
    <section className="page">
      <Switch>
        {routeAuthConfig.map(route => (
          <RouteWithSubRoutes key={route.id} {...omit(route, 'id')} />
        ))}
        <Route component={() => <Redirect to={`${r.signIn}?callbackUrl=${callbackUrl}`} />} />
      </Switch>
    </section>
  );
}

export default AuthLayout;
