import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Modal from '../../Modal';
import Input from '../../Input';
import { UserPersonalInfo, USER_PERSONAL_VALIDATION_SCHEMA } from '../../../enums/users.enum';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { scrollToError } from '../../../utils';

type ModalPersonalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  personalInfo: UserPersonalInfo;
  loading?: boolean;
  requestError: string | RejectValueErrors[] | null;
};

function ModalPersonal({ isOpen, onClose, onSubmit, personalInfo, loading, requestError }: ModalPersonalProps) {
  const { values, handleChange, handleSubmit, setFieldError, errors, touched } = useFormik({
    initialValues: new UserPersonalInfo(personalInfo),
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: USER_PERSONAL_VALIDATION_SCHEMA,
    onSubmit: data => onSubmit(USER_PERSONAL_VALIDATION_SCHEMA.cast(data)),
  });

  useSetFieldsErrors(requestError, setFieldError);

  const intl = useIntl();

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={intl.formatMessage(messages.editPersonalInfoTitle)}
      classNameModal="modal--edit-profile"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <Input
            label={intl.formatMessage(messages.birthdayInput)}
            name="birthday"
            type="date"
            defaultValue={values.birthday}
            onChange={handleChange}
            hasError={hasError('birthday')}
            errorMessage={errors?.birthday}
          />
          <Input
            tag="textarea"
            label={intl.formatMessage(messages.educationInput)}
            name="education"
            defaultValue={values.education}
            onChange={handleChange}
            hasError={hasError('education')}
            errorMessage={errors?.education}
          />
          <Input
            tag="textarea"
            label={intl.formatMessage(messages.coursesInput)}
            name="courses"
            defaultValue={values.courses}
            onChange={handleChange}
            hasError={hasError('courses')}
            errorMessage={errors?.courses}
          />
          <Input
            tag="textarea"
            label={intl.formatMessage(messages.hobbiesInput)}
            name="hobbies"
            defaultValue={values.hobbies}
            onChange={handleChange}
            hasError={hasError('hobbies')}
            errorMessage={errors?.hobbies}
          />
          <Input
            tag="textarea"
            label={intl.formatMessage(messages.booksInput)}
            name="booksAndLiterature"
            defaultValue={values.booksAndLiterature}
            onChange={handleChange}
            hasError={hasError('booksAndLiterature')}
            errorMessage={errors?.booksAndLiterature}
          />
          <Input
            tag="textarea"
            label={intl.formatMessage(messages.musicInput)}
            name="music"
            defaultValue={values.music}
            onChange={handleChange}
            hasError={hasError('music')}
            errorMessage={errors?.music}
          />
          <Input
            tag="textarea"
            label={intl.formatMessage(messages.lifeValuesInput)}
            name="lifeValues"
            defaultValue={values.lifeValues}
            onChange={handleChange}
            hasError={hasError('lifeValues')}
            errorMessage={errors?.lifeValues}
          />
          <Input
            tag="textarea"
            label={intl.formatMessage(messages.lifeMottoInput)}
            name="lifeMotto"
            defaultValue={values.lifeMotto}
            onChange={handleChange}
            hasError={hasError('lifeMotto')}
            errorMessage={errors?.lifeMotto}
          />
          <Input
            tag="textarea"
            label={intl.formatMessage(messages.likesInput)}
            name="likes"
            defaultValue={values.likes}
            onChange={handleChange}
            hasError={hasError('likes')}
            errorMessage={errors?.likes}
          />
          <Input
            tag="textarea"
            label={intl.formatMessage(messages.dislikesInput)}
            name="dislikes"
            defaultValue={values.dislikes}
            onChange={handleChange}
            hasError={hasError('dislikes')}
            errorMessage={errors?.dislikes}
          />
        </div>
        <ErrorMessage>{requestError}</ErrorMessage>
        <div className="form__buttons">
          <Button type="button" onClick={onClose} color="gray" externalClass="button--modal">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button type="submit" externalClass="button--modal" loading={loading} disabled={loading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalPersonal;
