import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  startDateLabel,
  hourPriceLabel,
  currencyLabel,
  jiraProjectsLabel,
  clientLabel,
  projectTypeLabel,
  estimateLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  startDateLabel,
  hourPriceLabel,
  currencyLabel,
  jiraProjectsLabel,
  clientLabel,
  projectTypeLabel,
  estimateLabel,
  newClientTitle: {
    id: 'finance.projects.modal.new.project.title.new.project',
    defaultMessage: 'New Project',
  },
  editClientTitle: {
    id: 'finance.projects.modal.edit.project.title.edit.project',
    defaultMessage: 'Edit Project',
  },
  paymentTypeLabel: {
    id: 'finance.project.modal.new.projectc.label.payment.type',
    defaultMessage: 'Payment Type',
  },
  timeAndMaterialLabel: {
    id: 'finance.project.modal.new.projectc.label.time.and.material',
    defaultMessage: 'Time and Material',
  },
  confirmmoveProjectTitle: {
    id:
      'planning.project.groups.modal.new.project.group.title.the.project.is.already.in.another.finance.project.are.you.sure.you.want.to.add.it.to.this.one',
    defaultMessage: 'The project is already in another finance project! Are you sure you want to add it to this one?',
  },
});
