import { defineMessages } from 'react-intl';
import {
  newButton,
  editButton,
  deleteButton,
  nameColumn,
  positionsLabel,
  dispalyNameLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  newButton,
  editButton,
  deleteButton,
  positionsLabel,
  dispalyNameLabel,
  pageTitle: {
    id: 'notifications.mailer.settings.title.mailer.settings',
    defaultMessage: 'Mailer Settings',
  },
  mailHostColumn: {
    id: 'notifications.mailer.settings.mail.host.column.column.host',
    defaultMessage: 'Host',
  },
  mailUsernameColumn: {
    id: 'notifications.mailer.settings.mail.username.column.column.username',
    defaultMessage: 'Username',
  },
  mailPortColumn: {
    id: 'notifications.mailer.settings.mail.port.column.column.port',
    defaultMessage: 'Port',
  },
  mailEncryptionColumn: {
    id: 'notifications.mailer.settings.mail.encryption.column.column.encryption',
    defaultMessage: 'Encryption',
  },
  mailNotificationTypesColumn: {
    id: 'notifications.mailer.settings.mail.notification.types.column.column.notification.types',
    defaultMessage: 'Notification Types',
  },
});
