import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar, { DateSelectArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventPreviewInfo } from '../../../../enums/schedule.enum';
import momentPlugin from '@fullcalendar/moment';
import { InlineDatePickerPeriods } from '../../../InlineDatePicker/constants';
import {
  dropdownBtnClasses,
  dropdownIconClasses,
  eventClasses,
  getCalendarEventList,
  getIsHoliday,
  handleFullCalendarView,
} from '../utils';
import { CalendarApi } from '@fullcalendar/react';
import classNames from 'classnames';
import { OfficesWorkDaysType } from '../../../../types/libraries/libraries.Reducer.type';
import { DaysOffInfoType } from '../../../../types/schedule/scheduleReducer.type';
import moment from 'moment';
import { ScheduleLayouts } from '../../../../constants/schedule.constants';
import CalendarYearView from './CalendarYearView';
import { EventsParams } from '../../../../enums/params/schedule.params';
import Popover from './Popover';
import { handleEventsPopUpChangePosition } from '../../../../utils/schedule.utils';
import { DATE_FORMAT } from '../../../../constants/date.constants';
import { getLang, isBrowserLocale24h } from '../../../../utils';
import PoliciesContext from '../../../../PoliciesContext';
import { checkPolicies } from '../../../../utils/policies.utils';
import { UPDATE_EVENT, UPDATE_EVENT_EXTENDED } from '../../../../constants/policies.constants';

type CalendarLayoutProps = {
  eventTableData: EventPreviewInfo[];
  calendarPeriod: InlineDatePickerPeriods;
  dateFrom: string;
  dateTo: string;
  officesWorkDays: OfficesWorkDaysType;
  daysOffInfo: DaysOffInfoType;
  popUpRef: any;
  isLoadingGetEventList: boolean;
  openEventModal: (event: EventPreviewInfo, e: React.MouseEvent, view?: ScheduleLayouts) => void;
  handleMoveToDayView: (date: string) => void;
  getEventList: (data: { params: Partial<EventsParams>; cb?: () => void }) => void;
  openNewEventModal: (prop: DateSelectArg) => void;
};

function CalendarLayout({
  eventTableData,
  calendarPeriod,
  dateFrom,
  dateTo,
  daysOffInfo,
  officesWorkDays,
  popUpRef,
  isLoadingGetEventList,
  openEventModal,
  handleMoveToDayView,
  getEventList,
  openNewEventModal,
}: CalendarLayoutProps) {
  const userPolicies = useContext(PoliciesContext);
  const calendarRef = useRef(null);
  const popoverRef = useRef<any>(null);
  const [popoverCurrentDate, setPopoverCurrentDate] = useState('');
  const [popoverStyles, setPopoverStyles] = useState<React.CSSProperties>({});
  const [popoverEvents, setPopoverEvents] = useState<EventPreviewInfo[]>([]);

  const handleCloseDayPopover = useCallback(
    (e: any) => {
      const elem = e.target as HTMLElement;
      let isEventClicked = false;
      let isDropdownBtnClicked = false;

      elem.classList.forEach((elemClass: string) => {
        if (eventClasses.includes(elemClass)) {
          isEventClicked = true;
        }
        if (dropdownBtnClasses.includes(elemClass) || dropdownIconClasses.includes(elemClass)) {
          isDropdownBtnClicked = true;
        }
      });

      if (
        popUpRef.current &&
        popUpRef.current.contains(elem) &&
        !isDropdownBtnClicked &&
        !dropdownIconClasses.some(el => elem.parentElement?.classList.contains(el))
      ) {
        return null;
      }
      if (!isEventClicked) {
        setPopoverCurrentDate('');
        setPopoverEvents([]);
      }
    },
    [popUpRef.current],
  );

  const resetEvents = useCallback(() => {
    setPopoverEvents([]);
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      //@ts-ignore
      const api: CalendarApi = calendarRef.current.getApi();
      api.changeView(handleFullCalendarView(calendarPeriod));
    }
  }, [calendarPeriod]);

  useEffect(() => {
    if (calendarRef.current) {
      //@ts-ignore
      const api: CalendarApi = calendarRef.current.getApi();
      api.gotoDate(dateFrom);
    }
  }, [dateFrom, dateTo, calendarRef.current]);

  const calendarEventTable = useMemo(() => getCalendarEventList(eventTableData, calendarPeriod), [
    eventTableData,
    calendarPeriod,
  ]);

  const handleMoreLinkClick = useCallback((info: any) => {
    setPopoverCurrentDate(info.date);
    setPopoverEvents(info.allSegs.map((el: any) => el.event.extendedProps.event));
    setTimeout(() => handleEventsPopUpChangePosition(info.jsEvent, setPopoverStyles, popoverRef), 1);
  }, []);

  return (
    <div className="page__schedule__calendar-view">
      {calendarPeriod !== InlineDatePickerPeriods.YEAR_PERIOD ? (
        <FullCalendar
          viewClassNames={classNames({
            'month-view': calendarPeriod === InlineDatePickerPeriods.MONTH_PERIOD,
            'week-view': calendarPeriod === InlineDatePickerPeriods.WEEK_PERIOD,
            'day-view': calendarPeriod === InlineDatePickerPeriods.DAY_PERIOD,
          })}
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, momentPlugin, interactionPlugin]}
          navLinks
          selectable={checkPolicies([UPDATE_EVENT_EXTENDED, UPDATE_EVENT], userPolicies)}
          select={openNewEventModal}
          moreLinkClick={handleMoreLinkClick}
          navLinkDayClick={date => handleMoveToDayView(date.toString())}
          height="70vh"
          eventClick={(e: any) => openEventModal(e.event.extendedProps.event, e.jsEvent, ScheduleLayouts.CALENDAR)}
          moreLinkContent={(content: any) => `${content.text}...`}
          slotDuration={{ hour: 1 }}
          nowIndicator
          dayPopoverFormat={{
            day: 'numeric',
            weekday: 'short',
          }}
          locale={getLang()}
          slotLabelFormat={{
            hour: isBrowserLocale24h() ? '2-digit' : 'numeric',
            minute: isBrowserLocale24h() ? '2-digit' : undefined,
          }}
          eventTimeFormat={{
            hour: isBrowserLocale24h() ? '2-digit' : 'numeric',
            minute: '2-digit',
          }}
          dayMaxEvents
          dayHeaderContent={(cellInfo: any) => (
            <span
              className={classNames('date', {
                holiday: getIsHoliday(moment(cellInfo.date), daysOffInfo, officesWorkDays),
              })}
            >
              {cellInfo.text}
            </span>
          )}
          dayCellContent={(cellInfo: any) =>
            calendarPeriod === InlineDatePickerPeriods.MONTH_PERIOD && (
              <span
                className={classNames('date', {
                  holiday: getIsHoliday(moment(cellInfo.date), daysOffInfo, officesWorkDays),
                })}
              >
                {cellInfo.dayNumberText}
              </span>
            )
          }
          firstDay={moment().localeData().firstDayOfWeek()}
          views={{
            timeGridWeek: {
              dayMaxEvents: 3,
              dayHeaderFormat: DATE_FORMAT.ddd_D,
              navLinks: false,
              dayHeaderContent: (info: any) => (
                <span
                  className={classNames('week-number date', {
                    holiday: getIsHoliday(moment(info.date), daysOffInfo, officesWorkDays),
                  })}
                  onClick={() => handleMoveToDayView(info.date)}
                >
                  {info.text}
                </span>
              ),
            },
            dayGridMonth: {
              dayHeaderFormat: { weekday: 'long' },
              dayHeaderContent: (info: any) => <span>{info.text}</span>,
            },
            timeGridDay: {
              dayMaxEvents: 3,
              dayHeaderFormat: DATE_FORMAT.MMM_DD,
            },
          }}
          headerToolbar={false}
          eventOverlap={false}
          events={calendarEventTable}
        />
      ) : (
        <CalendarYearView
          popoverRef={popoverRef}
          popoverCurrentDate={popoverCurrentDate}
          dateFrom={dateFrom}
          daysOffInfo={daysOffInfo}
          officesWorkDays={officesWorkDays}
          setPopoverStyles={setPopoverStyles}
          setPopoverCurrentDate={setPopoverCurrentDate}
          getEventList={getEventList}
          handleMoveToDayView={handleMoveToDayView}
        />
      )}
      {popoverCurrentDate && (
        <Popover
          popoverRef={popoverRef}
          styles={popoverStyles}
          currentDate={popoverCurrentDate}
          events={calendarPeriod === InlineDatePickerPeriods.YEAR_PERIOD ? eventTableData : popoverEvents}
          isLoadingGetEventList={isLoadingGetEventList}
          handleCloseDayPopover={handleCloseDayPopover}
          openEventModal={openEventModal}
          handleMoveToDayView={handleMoveToDayView}
          setPopoverCurrentDate={setPopoverCurrentDate}
          resetEvents={resetEvents}
        />
      )}
    </div>
  );
}

export default React.memo(CalendarLayout);
