import { defineMessages } from 'react-intl';
import {
  officesLabel,
  deleteModalTitle,
  cancelButton,
  deleteButton,
  startDateLabel,
  endDateLabel,
  expenseTypesLabel,
  saveButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  officesLabel,
  deleteModalTitle,
  cancelButton,
  deleteButton,
  startDateLabel,
  endDateLabel,
  expenseTypesLabel,
  saveButton,
  payLabel: {
    id: 'finance.planned.expense.modal.new.planned.expense.label.pay',
    defaultMessage: 'Pay',
  },
  newPlannedExpenseTitle: {
    id: 'finance.planned.expense.modal.new.planned.expense.title.new.planned.expense',
    defaultMessage: 'New Planned Expense',
  },
  editPlannedExpenseTitle: {
    id: 'finance.planned.expense.modal.edit.planned.expense.title.edit.planned.expense',
    defaultMessage: 'Edit Planned Expense',
  },
  deleteFutureExpensesLabel: {
    id: 'finance.planned.expense.modal.Delete.planned.expense.label.delete.future.expenses',
    defaultMessage: 'Delete future expenses',
  },
  periodLabel: {
    id: 'finance.planned.expense.modal.label.period',
    defaultMessage: 'Period:',
  },
});
