import classNames from 'classnames';
import React, { DependencyList, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { hintId } from './utils';

type HintProps = {
  className?: string;
  dependencyList?: DependencyList;
  children?: React.ReactChild | null;
  customId?: string;
};

const Hint = ({ className, customId, dependencyList, children }: HintProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dependencyList, children]);
  return (
    <ReactTooltip
      id={customId ? customId : hintId}
      place="bottom"
      className={classNames('hint', className)}
      backgroundColor="#fff"
      arrowColor="#fff"
      textColor="#000"
      clickable
    >
      {children}
    </ReactTooltip>
  );
};

export default Hint;
