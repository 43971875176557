import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as activityActions from '../../actions/activity.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { DELETE_SOFTWARE, UPDATE_SOFTWARE } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { createFormData } from '../../utils/data.utils';
import ModalDeleteSoftware from '../../components/TaskTrackerSoftware/Modals/ModalDeleteSoftware';
import { SoftwareLink } from '../../enums/activity.enums';
import ModalExtensionError from '../../components/TaskTrackerSoftware/Modals/ModalExtensionError';
import ModalSizeError from '../../components/TaskTrackerSoftware/Modals/ModalSizeError';
import CustomLoader from '../../components/Loader';
import { FILE_SIZE_LIMIT } from '../../constants/activity.constants';
import r from '../../constants/routes.constants';

function TaskTrackerSoftware({
  softwareLinksList,
  getSoftwareLinksList,
  downloadSoftware,
  uploadSoftware,
  deleteSoftware,
  resetErrors,
  isLoading,
  errors,
  exportedFiles,
}: ConnectedProps<typeof connector>) {
  const inputRef = useRef<HTMLInputElement>(null);

  const history = useHistory();

  const [currentSoftwareLink, setCurrentSoftwareLink] = useState({ displayName: '', id: '' });

  const [modalDeleteSoftwareIsOpen, setModalDeleteSoftwareIsOpen] = useState(false);
  const [extensionErrorModalOpen, setExtensionErrorModalOpen] = useState(false);
  const [sizeErrorModalOpen, setSizeErrorModaOpen] = useState(false);

  const closeDeleteSoftwareModal = useCallback(() => {
    setModalDeleteSoftwareIsOpen(false);
  }, []);

  const backButtonHandler = useCallback(() => {
    history.push(r.activity);
  }, []);

  useEffect(() => {
    getSoftwareLinksList();
  }, []);

  const handleUpload = useCallback(() => {
    const file = inputRef.current?.files?.[0];

    if (file && /(.exe|.deb|.dmg|.msi)$/i.test(file.name)) {
      if (file.size < FILE_SIZE_LIMIT) {
        uploadSoftware(createFormData({ file: file }));
      } else {
        setSizeErrorModaOpen(true);
      }
    } else {
      setExtensionErrorModalOpen(true);
    }
  }, [inputRef]);

  const handleUploadClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  const onDeleteButtonClick = useCallback(
    item => {
      setCurrentSoftwareLink(item);
      setModalDeleteSoftwareIsOpen(true);
    },
    [inputRef],
  );

  const closeExtensionModal = () => {
    setExtensionErrorModalOpen(false);
  };

  const closeSizeModal = () => {
    setSizeErrorModaOpen(false);
  };

  return (
    <>
      <div className="page__profile-panel task-tracker-software-page-panel">
        <div className="page__panel__wrapper fixed-container">
          <div className="page__panel__back-title">
            <Button externalClass="page__back" color="gray" onClick={backButtonHandler}>
              <FormattedMessage {...messages.activityLabel} />
            </Button>
          </div>
          <div className="page__profile-panel__label-options">
            <AccessChecker verifiablePolicies={[UPDATE_SOFTWARE]}>
              <Button
                circle
                color="gray"
                onClick={handleUploadClick}
                disabled={isLoading.postSoftware}
                loading={isLoading.postSoftware}
              >
                <Icon iconName={'upload'} externalClass={'button__icon button__icon--bigger'} />
              </Button>
              <input
                ref={inputRef}
                type="file"
                accept={'.exe, .deb, .dmg, .msi'}
                style={{ display: 'none' }}
                onChange={() => {
                  handleUpload();
                }}
              />
            </AccessChecker>
          </div>
        </div>
        <div className="page__panel__wrapper fixed-container title-wratter">
          <h1 className="page__title page__title--smaller">
            <FormattedMessage {...messages.pageTitle} />
          </h1>
        </div>
      </div>
      <div className="page__inner-content task-tracker-software-page">
        <div className="page__wrapper fixed-container">
          <div className="tracker-description">
            <FormattedMessage {...messages.trackerDescription} />
          </div>
          <div className="error_wrapper">
            {isLoading.getSoftwareLinksList && <CustomLoader />}
            <div className="error_block">{!isLoading.getSoftwareLinksList && errors.softwareError}</div>
          </div>
          <div className={'software-links-block'}>
            {softwareLinksList?.map((item: SoftwareLink) => (
              <div
                key={item.id}
                className={'software-link'}
                onClick={() => !exportedFiles.find((id: string) => id === item.id) && downloadSoftware(item)}
              >
                <AccessChecker verifiablePolicies={[DELETE_SOFTWARE]}>
                  {!exportedFiles.find(id => id === item.id) && (
                    <button
                      className="delete-software-button"
                      onClick={e => {
                        e.stopPropagation();
                        onDeleteButtonClick(item);
                      }}
                    >
                      <Icon iconName={'cross'} />
                    </button>
                  )}
                </AccessChecker>
                {exportedFiles.find(id => id === item.id) ? (
                  <CustomLoader />
                ) : item.displayName.includes('.deb') ? (
                  <Icon iconName={'linux'} />
                ) : item.displayName.includes('.dmg') ? (
                  <Icon iconName={'apple'} />
                ) : item.displayName.includes('.exe') || item.displayName.includes('.msi') ? (
                  <Icon iconName={'windows'} />
                ) : null}
                <div>{item.displayName}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {modalDeleteSoftwareIsOpen && (
        <ModalDeleteSoftware
          isOpen
          onCloseRequest={closeDeleteSoftwareModal}
          onDeleteRequest={(data: any) => {
            deleteSoftware(data);
          }}
          isLoading={isLoading.deleteSoftware}
          error={errors.softwareError}
          softwareLink={currentSoftwareLink}
          resetErrors={resetErrors}
        />
      )}
      {extensionErrorModalOpen && (
        <ModalExtensionError onRequestClose={closeExtensionModal} changeDocumentOverflowStyle={true} />
      )}
      {sizeErrorModalOpen && <ModalSizeError onRequestClose={closeSizeModal} changeDocumentOverflowStyle={true} />}
    </>
  );
}

const mapStateToProps = ({ activity }: RootState) => ({
  isLoading: activity.loading,
  softwareLinksList: activity.softwareLinksList?.content,
  errors: activity.errors,
  exportedFiles: activity.exportedFiles,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSoftwareLinksList: () => dispatch(activityActions.getSoftwareLinksList()),
  downloadSoftware: (data: SoftwareLink) => dispatch(activityActions.downloadSoftware(data)),
  uploadSoftware: (data: any) => dispatch(activityActions.uploadSoftware(data)),
  deleteSoftware: (uuid: any) => dispatch(activityActions.deleteSoftware(uuid)),
  resetErrors: () => dispatch(activityActions.resetActivityErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(TaskTrackerSoftware);
