import { cloneDeep } from 'lodash-es';
import { BonusCategoryInfo } from '../enums/bonuses.enums';

export const handleSubmitData = (data: BonusCategoryInfo) => {
  const newData = cloneDeep(data);
  newData.experiences = newData.experiences.map((gradeExp: any) => {
    if (gradeExp.restrictionFrom?.count === null) {
      gradeExp.restrictionFrom = null;
    }
    if (gradeExp.restrictionTo?.count === null) {
      gradeExp.restrictionTo = null;
    }
    return gradeExp;
  });
  return newData;
};
