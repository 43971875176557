import { RejectValueErrors } from './../enums/error.enum';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/calendar.constants';
import { EventTypeInfo } from '../enums/calendar.enum';
import { getReducerErrors } from '../utils/reducerError.utils';
import { EventTypesParams } from '../enums/params/calendar.params';

type InitialStateType = {
  eventType: EventTypeInfo | null;
  eventTypesTableData: any;
  eventTypesParams: EventTypesParams;
  errors: {
    eventTypeError: string | RejectValueErrors[] | null;
  };
  loading: {
    getEventTypes: boolean;
    createEventType: boolean;
    editEventType: boolean;
    deleteEventType: boolean;
  };
};

const initialState: InitialStateType = {
  eventType: null,
  eventTypesTableData: null,
  eventTypesParams: new EventTypesParams(),
  errors: {
    eventTypeError: null,
  },
  loading: {
    getEventTypes: false,
    createEventType: false,
    editEventType: false,
    deleteEventType: false,
  },
};

const calendarReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_EVENT_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getEventTypes: true },
      };

    case ActionTypes.GET_EVENT_TYPES_LIST_SUCCESS:
      return {
        ...state,
        eventTypesTableData: {
          ...action.payload,
          content: action.payload.content.map((eventType: any) => new EventTypeInfo(eventType)),
        },
        errors: { ...state.errors, eventTypeError: null },
        loading: { ...state.loading, getEventTypes: false },
      };

    case ActionTypes.GET_EVENT_TYPES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, eventTypeError: action.payload.message },
        loading: { ...state.loading, getEventTypes: false },
      };

    case ActionTypes.GET_EVENT_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
        eventType: new EventTypeInfo(action.payload),
        errors: { ...state.errors, eventTypeError: null },
        loading: { ...state.loading, getEventTypes: false },
      };

    case ActionTypes.RESET_EVENT_TYPE_IN_CALENDAR:
      return {
        ...state,
        eventType: null,
      };

    case ActionTypes.POST_EVENT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createEventType: true },
      };

    case ActionTypes.POST_EVENT_TYPE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, eventTypeError: null },
        loading: { ...state.loading, createEventType: false },
      };

    case ActionTypes.POST_EVENT_TYPE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          eventTypeError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createEventType: false },
      };

    case ActionTypes.PUT_EVENT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editEventType: true },
      };

    case ActionTypes.PUT_EVENT_TYPE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, eventTypeError: null },
        loading: { ...state.loading, editEventType: false },
      };

    case ActionTypes.PUT_EVENT_TYPE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          eventTypeError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editEventType: false },
      };

    case ActionTypes.DELETE_EVENT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteEventType: true },
      };

    case ActionTypes.DELETE_EVENT_TYPE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, eventTypeError: null },
        loading: { ...state.loading, deleteEventType: false },
      };

    case ActionTypes.DELETE_EVENT_TYPE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, eventTypeError: action.payload.message },
        loading: { ...state.loading, deleteEventType: false },
      };

    case ActionTypes.SET_EVENT_TYPES_PARAMS:
      return {
        ...state,
        eventTypesParams: new EventTypesParams({ ...state.eventTypesParams, ...action.payload }),
        loading: { ...state.loading, getEventTypes: true },
      };

    case ActionTypes.RESET_CALENDAR_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_CALENDAR_STATE:
      return {
        ...initialState,
        eventTypesParams: state.eventTypesParams,
      };

    default:
      return state;
  }
};

export default calendarReducer;
