import {
  PollParams,
  PollTemplatesParams,
  MyPollsParams,
  PollTemplatesCategoryParams,
} from './../enums/params/questionnaires.params';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/questionnaires.constants';

export const getPollTemplatesCategoryList = (): AnyAction => ({
  type: ActionTypes.GET_POLL_TEMPLATES_CATEGORY_LIST_REQUEST,
});

export const createNewPollTemplatesCategory = (data: any): AnyAction => ({
  type: ActionTypes.POST_POLL_TEMPLATES_CATEGORY_REQUEST,
  payload: data,
});

export const deletePollTemplatesCategory = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_POLL_TEMPLATES_CATEGORY_REQUEST,
  payload: data,
});

export const editPollTemplatesCategory = (data: any): AnyAction => ({
  type: ActionTypes.PUT_POLL_TEMPLATES_CATEGORY_REQUEST,
  payload: data,
});

export const setPollTemplatesCategoryParams = (data: Partial<PollTemplatesCategoryParams>): AnyAction => ({
  type: ActionTypes.SET_POLL_TEMPLATES_CATEGORY_PARAMS_REQUEST,
  payload: data,
});

export const getPollTemplatesList = (): AnyAction => ({
  type: ActionTypes.GET_POLL_TEMPLATES_LIST_REQUEST,
});

export const createNewPollTemplate = (data: any): AnyAction => ({
  type: ActionTypes.POST_POLL_TEMPLATE_REQUEST,
  payload: data,
});

export const deletePollTemplate = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_POLL_TEMPLATE_REQUEST,
  payload: data,
});

export const editPollTemplate = (data: any): AnyAction => ({
  type: ActionTypes.PUT_POLL_TEMPLATE_REQUEST,
  payload: data,
});

export const setPollTemplatesParams = (data: Partial<PollTemplatesParams>): AnyAction => ({
  type: ActionTypes.SET_POLL_TEMPLATES_PARAMS_REQUEST,
  payload: data,
});

export const setCurrentTemplateCategory = (data: string): AnyAction => ({
  type: ActionTypes.SET_CURRENT_TEMPLATE_CATEGORY,
  payload: data,
});

export const getCurrentTemplate = (data: any): AnyAction => ({
  type: ActionTypes.GET_CURRENT_POLL_TEMPLATE_REQUEST,
  payload: data,
});

export const copyTemplate = (data: any): AnyAction => ({
  type: ActionTypes.COPY_TEMPLATE_REQUEST,
  payload: data,
});

export const moveTemplate = (data: any): AnyAction => ({
  type: ActionTypes.CHANGE_TEMPLATE_CATEGORY_REQUEST,
  payload: data,
});

export const resetErrors = (): AnyAction => ({
  type: ActionTypes.RESET_QUESTIONNARIES_ERRORS,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_QUESTIONNARIES_STATE,
});

export const setPollParams = (data: Partial<PollParams>): AnyAction => ({
  type: ActionTypes.SET_POLL_PARAMS,
  payload: data,
});

export const getPollsList = (): AnyAction => ({
  type: ActionTypes.GET_POLLS_LIST_REQUEST,
});

export const createNewPoll = (data: any): AnyAction => ({
  type: ActionTypes.POST_NEW_POLL_REQUEST,
  payload: data,
});

export const deletePoll = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_POLL_REQUEST,
  payload: data,
});

export const editPoll = (data: any): AnyAction => ({
  type: ActionTypes.PATCH_POLL_REQUEST,
  payload: data,
});

export const getCurrentPoll = (id: any): AnyAction => ({
  type: ActionTypes.GET_CURRENT_POLL_REQUEST,
  payload: id,
});

export const getAnswersByPollIdForAdmin = (id: any): AnyAction => ({
  type: ActionTypes.GET_ANSWERS_BY_POLL_ID_ADMIN_REQUEST,
  payload: id,
});

export const updateAnswersByAdmin = (data: any): AnyAction => ({
  type: ActionTypes.PUT_ANSWERS_ADMIN_REQUEST,
  payload: data,
});

export const getUsersByOffices = (officeIds: Array<any>): AnyAction => ({
  type: ActionTypes.GET_USERS_BY_OFFICES_REQUEST,
  payload: officeIds,
});

export const updateOwnPollAnswers = (data: any): AnyAction => ({
  type: ActionTypes.UPDATE_OWN_POLL_ANSWERS_REQUEST,
  payload: data,
});

export const getAnswersByPollAndUser = (data: any): AnyAction => ({
  type: ActionTypes.GET_ANSWERS_BY_POLL_AND_USER_REQUEST,
  payload: data,
});

export const getNotAnsweredPollsByUser = (data: any): AnyAction => ({
  type: ActionTypes.GET_NOT_ANSWERED_POLLS_REQUEST,
  payload: data,
});

export const setIsSubmitResultAnswers = (isResultAnswers: boolean) => ({
  type: ActionTypes.SET_IS_RESULT_SUBMIT,
  payload: isResultAnswers,
});

export const getMyPollsList = (data: any) => ({
  type: ActionTypes.GET_MY_POLLS_LIST_REQUEST,
  payload: data,
});

export const setMyPollsParams = (data: Partial<MyPollsParams>) => ({
  type: ActionTypes.SET_MY_POLLS_PARAMS,
  payload: data,
});

export const openConfirmModalForOwnAnswers = () => ({
  type: ActionTypes.OPEN_CONFIRMATION_MODAL_FOR_OWN_ANSWERS,
});

export const onCloseConfirmModal = () => ({
  type: ActionTypes.CLOSE_CONFIRMATION_MODAL_FOR_OWN_ANSWERS,
});

export const resetEventPolls = (): AnyAction => ({
  type: ActionTypes.RESET_EVENT_POLLS,
});

export const resetPollData = () => ({
  type: ActionTypes.RESET_EXPORT_POLL_DATA,
});

export const setIsFromMyPolls = (data: boolean) => ({
  type: ActionTypes.SET_IS_FROM_MY_POLLS,
  payload: data,
});
