import React from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import { FinancePlanUser } from '../../../enums/finance/finance.enum';

type ModalDeleteEmployeeProps = {
  onCloseRequest: () => void;
  onDeleteRequest: (employeeId: string) => void;
  planUser: FinancePlanUser;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
};

function ModalDeleteEmployee({
  onCloseRequest,
  onDeleteRequest,
  planUser,
  error,
  isLoading,
  isOpen,
}: ModalDeleteEmployeeProps) {
  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.deleteModalWithItemNameTitle, {
        name: planUser.user.fullName,
      })}
      size={'small'}
      shouldCloseOnOverlayClick
    >
      <ErrorMessage>{error}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCloseRequest}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            onDeleteRequest(planUser.id);
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteEmployee;
