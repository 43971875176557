import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { formatValue } from 'react-currency-input-field';
import { ExpensesReportItem } from '../../../enums/finance/finance.enum';
import { CurrencyType } from '../../../types/finance';
import classNames from 'classnames';

export const useDataForExpensesTable = (expenses: ExpensesReportItem[], baseCurrency: CurrencyType | undefined) => {
  const intl = useIntl();

  const expensesTableColumns = useMemo(() => {
    let expenseColumns = [];
    const typeColumn = [
      {
        name: (
          <div className="column-name">
            <FormattedMessage {...messages.typesColumn} />
          </div>
        ),
        modifier: (row: ExpensesReportItem) =>
          row.totalItem ? intl.formatMessage(messages.totalExpenseRlabel) : row.expenseType.name,
        className: 'table__data--bold',
      },
    ];

    if (expenses.length) {
      expenseColumns = expenses[0]?.entries.map((item: { amount: number; month: string }, monthIndex: number) => ({
        name: (
          <>
            {moment(item.month).format(DATE_FORMAT.MMM)}
            {(monthIndex === 0 || moment(item.month).startOf('year').isSame(moment(item.month))) && (
              <div className="year-display">{moment(item.month).format(DATE_FORMAT.YYYY)}</div>
            )}
          </>
        ),
        modifier: (row: ExpensesReportItem) => (
          <div className="income-block">
            <div className="planed-income">
              {formatValue({
                value: row.entries[monthIndex]?.plannedAmount?.toString(),
                suffix: ` ${baseCurrency?.name}`,
              })}
            </div>
            <div className="actual-income">
              {formatValue({
                value: row.entries[monthIndex]?.amount?.toString(),
                suffix: ` ${baseCurrency?.name}`,
              })}
            </div>
          </div>
        ),
      }));

      const totalColum = [
        {
          name: (
            <div className="column-name">
              <FormattedMessage {...messages.totalRow} />
            </div>
          ),
          modifier: (row: ExpensesReportItem) => (
            <div
              className={classNames('income-block', { 'without-planned': Number.isNaN(row.allEntriesPlannedAmount) })}
            >
              <div className="planed-income">
                {!Number.isNaN(row.allEntriesPlannedAmount)
                  ? formatValue({
                      value: row.allEntriesPlannedAmount.toString(),
                      suffix: ` ${baseCurrency?.name}`,
                    })
                  : ''}
              </div>
              <div className="actual-income">
                {formatValue({
                  value: row.allEntriesAmount.toString(),
                  suffix: ` ${baseCurrency?.name}`,
                })}
              </div>
            </div>
          ),
        },
      ];

      return [...typeColumn, ...expenseColumns, ...totalColum];
    }
    return typeColumn;
  }, [expenses]);

  const expensesHeaderItems = [
    { name: '', className: 'employees-column-head header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: 'header_row',
      colspan: (expenses && expenses[0]?.entries.length) || 2,
      sortName: '',
    },
    { name: '', className: 'total-column-head header_row', colspan: 1, sortName: '' },
  ];

  return {
    expensesTableColumns,
    expensesHeaderItems,
  };
};
