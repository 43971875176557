import { UserPreviewInfo } from './users.enum';
import { get } from 'lodash-es';
import { RejectValueErrors } from './error.enum';
import { SavedFilterParams } from './params/filters.params';
import * as yup from 'yup';

export type FilterFieldType = { name: string; value: string };
export type StorageSavedFilterType = { id: string; type: string; fields: FilterFieldType[] };

export class SavedFilter {
  id: string;
  authorId: string;
  author: UserPreviewInfo;
  filterType: string;
  name: string;
  isPublic: boolean;
  fields: FilterFieldType[];

  constructor(filter?: unknown) {
    this.id = get(filter, 'id');
    this.authorId = get(filter, 'authorId');
    this.author = get(filter, 'author');
    this.filterType = get(filter, 'filterType');
    this.name = get(filter, 'name');
    this.isPublic = get(filter, 'isPublic', false);
    this.fields = get(filter, 'fields', []);
  }
}

export const SAVED_FILTER_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().max(255, 'Maximum 255 characters').required('Required'),
});

export type SavedFiltersDataType = {
  filtersList: SavedFilter[];
  params: SavedFilterParams;
  loading: {
    getFiltersList: boolean;
    createFilter: boolean;
    editFilter: boolean;
    deleteFilter: boolean;
  };
  errors: string | RejectValueErrors[] | null;
};
