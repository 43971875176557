export const GET_EVENT_TYPES_LIST_REQUEST = 'GET_EVENT_TYPES_LIST_REQUEST';
export const GET_EVENT_TYPES_LIST_SUCCESS = 'GET_EVENT_TYPES_LIST_SUCCESS';
export const GET_EVENT_TYPES_LIST_FAILURE = 'GET_EVENT_TYPES_LIST_FAILURE';

export const GET_EVENT_TYPE_BY_ID_REQUEST = 'GET_EVENT_TYPE_BY_ID_REQUEST';
export const GET_EVENT_TYPE_BY_ID_SUCCESS = 'GET_EVENT_TYPE_BY_ID_SUCCESS';
export const GET_EVENT_TYPE_BY_ID_FAILURE = 'GET_EVENT_TYPE_BY_ID_FAILURE';

export const POST_EVENT_TYPE_REQUEST = 'POST_EVENT_TYPE_REQUEST';
export const POST_EVENT_TYPE_SUCCESS = 'POST_EVENT_TYPE_SUCCESS';
export const POST_EVENT_TYPE_FAILURE = 'POST_EVENT_TYPE_FAILURE';

export const DELETE_EVENT_TYPE_REQUEST = 'DELETE_EVENT_TYPE_REQUEST';
export const DELETE_EVENT_TYPE_SUCCESS = 'DELETE_EVENT_TYPE_SUCCESS';
export const DELETE_EVENT_TYPE_FAILURE = 'DELETE_EVENT_TYPE_FAILURE';

export const PUT_EVENT_TYPE_REQUEST = 'PUT_EVENT_TYPE_REQUEST';
export const PUT_EVENT_TYPE_SUCCESS = 'PUT_EVENT_TYPE_SUCCESS';
export const PUT_EVENT_TYPE_FAILURE = 'PUT_EVENT_TYPE_FAILURE';

export const SET_EVENT_TYPES_PARAMS = 'SET_EVENT_TYPES_PARAMS';

export const RESET_CALENDAR_ERRORS = 'RESET_CALENDAR_ERRORS';

export const RESET_CALENDAR_STATE = 'RESET_CALENDAR_STATE';

export const RESET_EVENT_TYPE_IN_CALENDAR = 'RESET_EVENT_TYPE_IN_CALENDAR';
