import React from 'react';
import RecognitionCard from './RecognitionCard';
import Icon from '../../Icon';
import { RegonitionType } from '../../../types/recognitions/recognitions.type';
import classNames from 'classnames';

type RecognitionColumn = {
  recognitionType: RegonitionType;
  recognitions: any[];
  openNewRecognitionModal: any;
  openDeleteRecognitionModal: any;
  openEditRecognitionModal: any;
  authUserdata: { id: string };
  isNotAuthUser: boolean;
};

function RecognitionColumn({
  recognitionType,
  recognitions,
  openNewRecognitionModal,
  openDeleteRecognitionModal,
  openEditRecognitionModal,
  authUserdata,
  isNotAuthUser,
}: RecognitionColumn) {
  const recognitionsCards = recognitions?.map((recognition: any) => {
    if (recognitionType.id == recognition.recognitionType?.id) {
      return (
        <RecognitionCard
          key={recognition.id}
          recognition={recognition}
          openDeleteRecognitionModal={openDeleteRecognitionModal}
          openEditRecognitionModal={openEditRecognitionModal}
          authUserdata={authUserdata}
        />
      );
    }
  });

  const color = '#' + recognitionType.backgroundColor;
  return (
    <div className="recognition-column-container">
      <div className="recognition-type-name-container">
        <span
          onClick={() => isNotAuthUser && openNewRecognitionModal(recognitionType)}
          className={classNames('recognition-type-name', { disabled: !isNotAuthUser })}
          style={{ color: color, backgroundColor: color + '14' }}
        >
          {isNotAuthUser && <Icon iconName={'plus'} />} {recognitionType.name}
        </span>
        <div className="recognition-column"> {recognitionsCards} </div>
      </div>
    </div>
  );
}

export default RecognitionColumn;
