import React, { useCallback, useRef, useEffect, useMemo, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../components/Button';
import DurationPicker from '../../components/Dropdown';
import cx from 'classnames';
import InlineDatePicker from '../../components/InlineDatePicker';
import messages from './messages';
import Icon from '../../components/Icon';
import { resetParamsChange, useLayout, useParamsChange, useUsersParamsChange } from '../../utils/hooks.utils';
import { ActivityLayouts, activityUnsavedParams } from '../../constants/activity.constants';
import ActivityChart from '../../components/Activity/Layouts/ActivityChart';
import ActivityTable from '../../components/Activity/Layouts/ActivityTable';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { ActivityParams } from '../../enums/params/activity.params';
import * as activityActions from '../../actions/activity.actions';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';
import ActivityFilters from '../../components/Activity/Filters';
import * as filtersActions from '../../actions/filters.actions';
import { handleReportUsersStatuses } from '../../utils/reports.utils';
import classNames from 'classnames';
import { VIEW_ALL_EMPLOYEE_ACTIVITY, VIEW_SOFTWARE } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';
import { useHistory } from 'react-router';
import r from '../../constants/routes.constants';
import { exportActivity } from '../../actions/export.actions';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { weekPeriod } from '../../components/InlineDatePicker/constants';

function Activity({
  activityDiagramTableData,
  activityTableData,
  params,
  officesFilter,
  departmentsFilter,
  usersFilter,
  logading,
  errors,
  authUserId,
  setActivityDiagramParams,
  setActivityTableParams,
  getUsersFilter,
  getOfficesFilter,
  getDepartmentsFilter,
  exportActivity,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);
  const intl = useIntl();
  const dispatch = useDispatch();
  const tablePickerRef = useRef<any>(null);
  const diagramPickerRef = useRef<any>(null);
  const history = useHistory();
  const [layout, handleChangeActiveLayout] = useLayout(ActivityLayouts, ActivityLayouts.ACTIVITY_CHART);
  const [diagramDate, setDiagramDate] = useState(
    moment().isBetween(moment(params.dateFrom), moment(params.dateTo)) ? moment : moment(params.dateFrom),
  );

  const handleLayoutChangeParams = (params: Partial<ActivityParams>) => {
    if (layout === ActivityLayouts.ACTIVITY_CHART) {
      setActivityDiagramParams(params);
    } else {
      setActivityTableParams(params);
    }
  };

  const pageClassName = classNames('page__panel page__panel--fixed activity_page', layout.toLocaleLowerCase());

  const getStartDiagramDate = () => diagramDate.startOf('d').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss);
  const getEndDiagramDate = () => diagramDate.endOf('d').format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss);

  const onDiagramDateChange = useCallback(
    (start: string, end: string) => {
      setActivityDiagramParams({
        dateFrom: start,
        dateTo: end,
      });
      setDiagramDate(moment(start));
    },
    [layout],
  );

  const onTableDateChange = useCallback(
    (start: string, end: string) => {
      setActivityTableParams({
        dateFrom: start,
        dateTo: end,
      });
      const startDate = moment(start);
      if (!diagramDate.isBetween(startDate, moment(end))) {
        setDiagramDate(startDate);
      }
    },
    [layout],
  );

  const handleMultiParamsChange = useParamsChange(handleLayoutChangeParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(handleLayoutChangeParams, dispatch);

  const handleUsersStatuses = useCallback(data => handleReportUsersStatuses(handleLayoutChangeParams, data), [
    handleLayoutChangeParams,
  ]);

  useEffect(() => {
    const isChartLayout = layout === ActivityLayouts.ACTIVITY_CHART;
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.ACTIVITY_FILTER);

    if (checkPolicies([VIEW_ALL_EMPLOYEE_ACTIVITY], policies)) {
      handleLayoutChangeParams(
        currentSavedFilter
          ? new ActivityParams({
              ...currentSavedFilter,
              dateFrom: isChartLayout ? getStartDiagramDate() : params.dateFrom,
              dateTo: isChartLayout ? getEndDiagramDate() : params.dateTo,
              isTable: !isChartLayout,
            })
          : {
              portalUserIds: usersFilter.value,
              departmentIds: departmentsFilter.value,
              offices: officesFilter.value,
              dateFrom: isChartLayout ? getStartDiagramDate() : params.dateFrom,
              dateTo: isChartLayout ? getEndDiagramDate() : params.dateTo,
              isTable: !isChartLayout,
            },
      );

      getOfficesFilter();
      getDepartmentsFilter();
      getUsersFilter();
    } else {
      handleLayoutChangeParams({
        portalUserIds: [authUserId],
        departmentIds: [],
        offices: [],
        dateFrom: isChartLayout ? getStartDiagramDate() : params.dateFrom,
        dateTo: isChartLayout ? getEndDiagramDate() : params.dateTo,
        isTable: !isChartLayout,
      });
    }

    setSavedFiltersParams({ filterType: FilterTypes.ACTIVITY_FILTER });
  }, []);

  const filters = useMemo(
    () => ({
      departments: departmentsFilter.departments,
      offices: officesFilter.offices,
      users: usersFilter.users,
    }),
    [departmentsFilter.departments, officesFilter.offices, usersFilter.users],
  );

  const taskTrackerButtonHandler = useCallback(() => {
    history.push(r.taskTrackerSoftware);
  }, []);

  const handleFiltersControlChange = useCallback(
    value => {
      handleLayoutChangeParams(
        new ActivityParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    handleLayoutChangeParams(new ActivityParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));

    resetParamsChange(
      [FilterParamsName.PORTAL_USER_IDS, FilterParamsName.DEPARTMENT_IDS, FilterParamsName.OFFICES],
      dispatch,
    );
  }, [params]);

  const showClearButton = useMemo(() => !checkParamsMatch(params, new ActivityParams(), activityUnsavedParams), [
    params,
  ]);

  return (
    <>
      <div className={pageClassName}>
        <div className="page__wrapper">
          <div className="page__panel-top">
            <div className="title-block">
              <h1 className="page__title">
                <FormattedMessage {...messages.pageTitle} />
              </h1>
              <AccessChecker verifiablePolicies={[VIEW_SOFTWARE]}>
                <button onClick={taskTrackerButtonHandler} className="task-tracker-button">
                  <FormattedMessage {...messages.taskTrackerButton} />
                </button>
              </AccessChecker>
            </div>
            <div className="page__panel-top__control">
              {layout === ActivityLayouts.ACTIVITY_CHART ? (
                <InlineDatePicker
                  pickerRef={diagramPickerRef}
                  key="activity-chart"
                  onDateChange={onDiagramDateChange}
                  isSingleDatePicker
                  defaultPeriodStart={getStartDiagramDate()}
                  defaultPeriodEnd={getEndDiagramDate()}
                />
              ) : (
                <InlineDatePicker
                  key="activity-table"
                  pickerRef={tablePickerRef}
                  onDateChange={onTableDateChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                />
              )}

              <div className="page__panel-view">
                <Button
                  externalClass={cx('page__panel-view__btn', { active: layout === ActivityLayouts.ACTIVITY_CHART })}
                  onClick={() => {
                    setActivityDiagramParams({
                      dateFrom: getStartDiagramDate(),
                      dateTo: getEndDiagramDate(),
                      isTable: false,
                    });
                    handleChangeActiveLayout(ActivityLayouts.ACTIVITY_CHART);
                  }}
                  color="gray"
                >
                  <Icon iconName="stream" />
                </Button>
                <Button
                  externalClass={cx('page__panel-view__btn', { active: layout === ActivityLayouts.ACTIVITY_TABLE })}
                  onClick={() => {
                    setActivityTableParams({
                      dateFrom: moment(params.dateFrom).startOf(weekPeriod).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
                      dateTo: moment(params.dateFrom).endOf(weekPeriod).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss),
                      isTable: true,
                    });
                    handleChangeActiveLayout(ActivityLayouts.ACTIVITY_TABLE);
                  }}
                  color="gray"
                >
                  <Icon iconName="grid" />
                </Button>
              </div>
              <DurationPicker
                dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                dropdownList={[{ label: intl.formatMessage(messages.exportToXLSLabel), handler: exportActivity }]}
              />
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[VIEW_ALL_EMPLOYEE_ACTIVITY]}>
                  <ActivityFilters
                    values={params}
                    filters={filters}
                    handleMultiParamsChange={handleMultiParamsChange}
                    handleUsersParamsChange={handleUsersParamsChange}
                    handleUsersStatuses={handleUsersStatuses}
                    createNewSavedFilter={createNewSavedFilter}
                    savedFiltersData={savedFiltersData}
                    authUserId={authUserId}
                    deleteSavedFilter={deleteSavedFilter}
                    editSavedFilter={editSavedFilter}
                    handleFiltersControlChange={handleFiltersControlChange}
                    handleClear={handleClear}
                    resetSavedFilterErrors={resetSavedFilterErrors}
                    showClearButton={showClearButton}
                  />
                </AccessChecker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content activity_page">
        <div className="page__wrapper">
          {layout === ActivityLayouts.ACTIVITY_CHART ? (
            <ActivityChart
              tableData={activityDiagramTableData}
              loading={logading.getactivityDiagram}
              error={errors.activityDiagramError}
            />
          ) : (
            <ActivityTable
              tableData={activityTableData}
              loading={logading.getActivityTable}
              error={errors.activityTableError}
              params={params}
            />
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ activity, filters, auth }: RootState) => ({
  activityDiagramTableData: activity.activityDiagram,
  activityTableData: activity.activityTable,
  logading: activity.loading,
  errors: activity.errors,
  params: activity.params,
  usersFilter: filters.usersFilter,
  officesFilter: filters.officesFilter,
  departmentsFilter: filters.departmentsFilter,
  authUserId: auth.currentUserInfo.id,
  savedFiltersData: filters.savedFilters,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setActivityDiagramParams: (data: Partial<ActivityParams>) => dispatch(activityActions.setActivityDiagramParams(data)),
  setActivityTableParams: (data: Partial<ActivityParams>) => dispatch(activityActions.setActivityTableParams(data)),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  exportActivity: () => dispatch(exportActivity()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Activity);
