import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { UserAbsencePeriods, UserInfo } from '../../../enums/users.enum';
import { useAbsecePeriods } from '../../../utils/hooks.utils';
import Dropdown from '../../Dropdown';
import Icon from '../../Icon';
import CustomLoader from '../../Loader';
import AbsenceBlock from '../AbsenceBlock';
import AbsenceContent from '../AbsenceBlock/AbsenceContent';
import messages from '../messages';

type ProfileVacationProps = {
  userAbsencePeriods: UserAbsencePeriods[];
  id: string;
  userHrInfo: {
    dismissal: string;
    endTrialPeriod: string;
    hired: string;
    hrCuratorId: string;
    hrCurator: UserInfo;
  }[];
  loading: boolean;
  getAbsencePeriods: (data: any) => void;
};

function ProfileVacation({ userHrInfo, id, userAbsencePeriods, loading, getAbsencePeriods }: ProfileVacationProps) {
  const intl = useIntl();
  const [modalAbsenceIsOpen, setModalAbsenceIsOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const { serachYear, tableActions, setSearchYear } = useAbsecePeriods(id, getAbsencePeriods, userHrInfo);

  const openModalAbsence = useCallback(() => {
    setModalAbsenceIsOpen(true);
  }, []);

  const closeModalAbsence = useCallback(() => {
    setModalAbsenceIsOpen(false);
  }, []);

  const getUserAbsencePeriods = () => {
    const currentDate = moment();
    getAbsencePeriods({
      id,
      data: {
        dateTimeFrom: currentDate.startOf('year').format(),
        dateTimeTo: currentDate.endOf('year').format(),
      },
    });
  };

  useEffect(() => {
    getUserAbsencePeriods();
  }, [id]);

  return (
    <>
      <div className="page__profile-vacation-info" ref={setAnchor}>
        <div className="page__profile-vacation__head">
          {
            <Dropdown
              dropdownClass="dropdown--no-bg current-year-dropdown"
              dropdownToggle={
                <>
                  <span className="current-year">{moment(serachYear).format(DATE_FORMAT.YYYY)}</span>
                  <Icon iconName={'select-arrow'} externalClass={'dropdown__button-main-icon'} />
                </>
              }
              dropdownList={tableActions}
              dropdownInfo={null}
            />
          }
        </div>
        <div className="page__profile-vacation__content">
          {!loading ? (
            userAbsencePeriods
              .slice(0, 7)
              .map((el, i) => <AbsenceContent key={`${el.absenceReason}-${i}`} absencePeriod={el} />)
          ) : (
            <CustomLoader />
          )}
          {!loading && userAbsencePeriods.length > 7 && (
            <div className="absence-block__item">
              <div onClick={openModalAbsence} className="absence-block__name more">{`${
                userAbsencePeriods.length - 7
              } ${intl.formatMessage(messages.moreLabel)}...`}</div>
            </div>
          )}
        </div>
        <AbsenceBlock
          userId={id}
          modalAbsenceIsOpen={modalAbsenceIsOpen}
          userAbsencePeriods={userAbsencePeriods}
          userHrInfo={userHrInfo}
          absencePeriodsLoading={loading}
          anchor={anchor}
          isUserProfile
          onRequestClose={closeModalAbsence}
          getUserAbsencePeriods={getAbsencePeriods}
          setSearchYearCustom={setSearchYear}
        />
      </div>
    </>
  );
}

export default ProfileVacation;
