import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import BrandingContext from '../../BrandingContext';
import { useFloating, offset, shift, limitShift, size, flip } from '@floating-ui/react-dom';
import { useClickOutsideHandler, useUpdateDropdownMenuPosition } from '../../utils/hooks.utils';

type DropdownProps = {
  dropdownToggle: string | React.ReactNode;
  children: React.ReactNode;
  dropdownClass?: string;
  dropdownToggleClass?: string;
  placement?: 'bottom' | 'top';
  side?: 'right' | 'left';
  isOpen: boolean;
  onCloseRequest?: () => void;
  onOpenRequest?: () => void;
};

function DropdownForm({
  dropdownToggle,
  dropdownClass,
  dropdownToggleClass,
  placement = 'bottom',
  side = 'right',
  children,
  onCloseRequest,
  onOpenRequest,
  isOpen,
}: DropdownProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const branding = useContext(BrandingContext);
  const style: any = {
    '--dropdown-active-color': branding?.globalAccents,
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    setDropdownOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (dropdownOpen) {
      onOpenRequest && onOpenRequest();
    } else {
      onCloseRequest && onCloseRequest();
    }
  }, [dropdownOpen]);

  const { refs, floatingStyles, update } = useFloating({
    middleware: [
      offset(5),
      shift({
        boundary: document.body,
        limiter: limitShift({
          offset: 5,
        }),
      }),
      flip(),
      size({
        apply({ availableHeight, availableWidth, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 5}px`,
          });
        },
      }),
    ],
    open: dropdownOpen,
    placement,
  });

  useClickOutsideHandler(closeDropdown, refs.reference, refs.floating);

  useUpdateDropdownMenuPosition(update);

  return (
    <div ref={refs.setReference} className={classNames('dropdown dropdown_form', { open: dropdownOpen })} style={style}>
      <button
        type="button"
        className={classNames('dropdown__button', dropdownToggleClass)}
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
          onCloseRequest && onCloseRequest();
        }}
      >
        {dropdownToggle}
      </button>
      {dropdownOpen &&
        ReactDOM.createPortal(
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={classNames('dropdown__list', dropdownClass, placement, side)}
          >
            {children}
          </div>,
          document.body,
        )}
    </div>
  );
}

export default DropdownForm;
