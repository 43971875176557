import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';
import cloneDeep from 'lodash-es/cloneDeep';
import isEmpty from 'lodash-es/isEmpty';

import { eventChannel, END } from 'redux-saga';
import { authToken, getAuthData, setAuthData } from './auth.utils';
import * as authActions from '../actions/auth.actions';
import { store } from '../index';
import * as api from '../api/auth.api';
import ENV from '../constants/env.constants';
axios.defaults.baseURL = ENV.APP_API;

declare global {
  interface RequestConfig extends AxiosRequestConfig {
    url: string;
  }

  interface ResponseConfig {
    success: boolean;
    results?: any;
    code: number;
    message: string;
  }
}

axios.interceptors.request.use(
  req => {
    const token = authToken();
    if (token) {
      req.headers.Authorization = token;
    }
    return req;
  },
  error => Promise.reject(error),
);

let refreshTokenPromise: any;
let logoutFlag = false;

const logOut = (callbackUrl?: string) => {
  if (!logoutFlag) {
    logoutFlag = true;
    store.dispatch(authActions.signOut(callbackUrl));
  }
};

axios.interceptors.response.use(
  response => {
    logoutFlag = false;
    refreshTokenPromise = null;
    return response;
  },
  err => {
    if (axios.isCancel(err)) {
      return Promise.reject(err);
    }
    const authData = getAuthData();
    const isRefreshTokenRequest = err.response.config.url.includes('auth/refresh');
    if (
      err.response.data.message === 'REFRESH_TOKEN_IS_NOT_VALID' ||
      err.response.data === 'TOKEN_IS_BLOCKED' ||
      ((err.response.data === 'Unauthorized' || err.response.status === 401) && isRefreshTokenRequest)
    ) {
      logOut(window.location.pathname);
      return Promise.reject(err);
    }

    if (err.response.status === 401) {
      try {
        if (!refreshTokenPromise) {
          refreshTokenPromise = api.refreshAccessToken(authData.refreshToken);
        }
        return refreshTokenPromise.then((resp: any) => {
          setAuthData(resp.results);
          return axios(err.config);
        });
      } catch (error) {
        logOut(window.location.pathname);
      }
    }

    return Promise.reject(err);
  },
);

const fetch = (options: RequestConfig) => {
  // eslint-disable-next-line prefer-const
  let { method = 'get', data, url, params, headers, responseType, cancelToken, onDownloadProgress } = options;
  headers = headers || {};

  const cloneData = data instanceof FormData ? data : cloneDeep(data);

  if (!isEmpty(params)) {
    url = `${url}?${queryString.stringify(params)}`;
  }

  switch (method.toLowerCase()) {
    case 'get':
      return axios.get(url, {
        params: cloneData,
        headers,
        responseType,
        cancelToken,
        onDownloadProgress,
      });
    case 'delete':
      return axios.delete(url, {
        data: cloneData,
        headers,
      });
    case 'post': {
      return axios.post(url, cloneData, { headers });
    }
    case 'put':
      return axios.put(url, cloneData, { headers });
    case 'patch':
      return axios.patch(url, cloneData, { headers });
    default:
      return axios(options);
  }
};

export function createUploaderChannel(options: RequestConfig) {
  return eventChannel(emit => {
    const onProgress = (progressEvent: ProgressEvent) => {
      emit({ progressEvent });
    };

    fetch({ ...options, onDownloadProgress: onProgress })
      .then(({ data }) => {
        emit({ response: data });
        emit(END);
      })
      .catch(err => {
        emit({ error: err });
        emit(END);
      });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const unsubscribe = () => {};
    return unsubscribe;
  });
}

const handleSuccess = (response: AxiosResponse<any>) => {
  const { statusText, status, data } = response;
  return Promise.resolve({
    success: true,
    results: data,
    code: status,
    message: statusText,
  });
};

const handleError = (error: any) => {
  const { response } = error;
  let code;
  let message;
  let errors;
  if (response && response instanceof Object) {
    const { data, statusText, status } = response;
    code = status;
    message = data.message || statusText;
    errors = data.errors || [];
  } else {
    code = 600;
    message = error.message || 'Network Error';
  }

  return Promise.reject({ success: false, code, message, errors });
};

export default function request(options: RequestConfig): Promise<ResponseConfig> {
  return fetch(options).then(handleSuccess).catch(handleError);
}
