import { defineMessages } from 'react-intl';
import {
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  typeLabel,
  personalLabel,
  projectsLabel,
  jiraProjectsLabel,
  lastUpdateLabel,
  statusLabel,
  activeLabel,
  inactiveLabel,
  updaterColumn,
  activateLabel,
  deactivateLabel,
  hourPriceLabel,
  clientLabel,
  projectTypeLabel,
  estimateLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  typeLabel,
  personalLabel,
  projectsLabel,
  jiraProjectsLabel,
  lastUpdateLabel,
  statusLabel,
  activeLabel,
  inactiveLabel,
  updaterColumn,
  activateLabel,
  deactivateLabel,
  hourPriceLabel,
  clientLabel,
  projectTypeLabel,
  estimateLabel,
  paymentTypeColumn: {
    id: 'finance.project.column.payment.type',
    defaultMessage: 'Payment Type',
  },
  timeAndMaterialLabel: {
    id: 'finance.project.label.time.and.material',
    defaultMessage: 'Time and Material',
  },
});
