import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Icon from '../../Icon';
import Input from '../../Input';
import Modal from '../../Modal';
import { DAYS_OF_WEEK } from '../../../constants/libraries.constants';
import { OfficeInfo, OFFICES_VALIDATION_SCHEMA } from '../../../enums/libraries.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { isArray, isEmpty } from 'lodash-es';
import { scrollToError } from '../../../utils';

type ModalNewOfficeProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  createNewOffice: any;
  officeError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
};

function ModalNewOffice({
  onCloseRequest,
  createNewOffice,
  officeError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalNewOfficeProps) {
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, resetForm, setFieldError } = useFormik({
    initialValues: new OfficeInfo(),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: OFFICES_VALIDATION_SCHEMA,
    onSubmit: data => {
      return createNewOffice({ data: OFFICES_VALIDATION_SCHEMA.cast(data), callback: resetAndExit });
    },
  });

  const intl = useIntl();

  useSetFieldsErrors(officeError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const addNewContact = useCallback(() => {
    setFieldValue('contacts', [...values.contacts, { additionalInfo: '', contactName: '', phone: '', email: '' }]);
  }, [values]);

  const removeContact = useCallback(
    index => () => {
      values.contacts.splice(index, 1);
      setFieldValue('contacts', values.contacts);
      const contactsErrors = errors.contacts;
      if (!isEmpty(contactsErrors) && isArray(contactsErrors)) {
        contactsErrors.splice(index, 1);
        //@ts-ignore
        setFieldError('contacts', contactsErrors);
      }
    },
    [values.contacts, errors.contacts],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const contactsErrors: any = useMemo(() => errors.contacts, [errors.contacts]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.newOfficeTitle)}
      size={'big'}
      classNameModal="modal--new-office"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block form__input-block--half">
            <Input
              id={'name'}
              name="name"
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values.name}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
            />
          </div>
          <div className="form__input-block">
            <Input
              id={'address'}
              name="address"
              label={intl.formatMessage(messages.addressLabel)}
              defaultValue={values.address}
              onChange={handleChange}
              hasError={hasError('address')}
              errorMessage={errors?.address}
            />
          </div>
          <div className="form__group">
            <div className="form__info-label">
              <FormattedMessage {...messages.workDaysTitle} />
            </div>
            <div className="form__inputs-subwrapper">
              <div className="form__week-wrapper">
                <div className="form__week-checkboxes-wrapper">
                  {DAYS_OF_WEEK.map(({ fullName, shortName }, index) => (
                    <div key={`${index}`} className="form__week-checkbox-block">
                      <input
                        className="form__week-checkbox"
                        type="checkbox"
                        name="workDays"
                        id={`${fullName}`}
                        onChange={handleChange}
                        checked={values.workDays.includes(fullName)}
                        value={fullName}
                      />
                      <label className="form__week-label" htmlFor={`${fullName}`}>
                        {shortName}
                      </label>
                    </div>
                  ))}
                </div>
                <ErrorMessage>{errors.workDays}</ErrorMessage>
              </div>
              <div className="form__input-block form__input-block--fourth">
                <Input
                  type={'number'}
                  id={'hourPerWorkDay'}
                  name={'hourPerWorkDay'}
                  label={intl.formatMessage(messages.hoursPerWorkDayInput)}
                  defaultValue={values.hourPerWorkDay}
                  onChange={handleChange}
                  hasError={hasError('hourPerWorkDay')}
                  errorMessage={errors?.hourPerWorkDay}
                />
              </div>
            </div>
          </div>
          {values.contacts.map((data, index) => (
            <div className="form__group" key={`${index}`}>
              <div className="form__group-wrapper">
                <div className="form__inputs-wrapper">
                  <div className="form__inputs-subwrapper">
                    <div className="form__input-block form__input-block--half">
                      <Input
                        id={`contactName-${index}`}
                        name={`contacts[${index}].contactName`}
                        label={intl.formatMessage(messages.contactInput)}
                        defaultValue={values.contacts[index].contactName}
                        onChange={handleChange}
                        hasError={hasError(`contacts.${index}.contactName`)}
                        errorMessage={contactsErrors && contactsErrors[index]?.contactName}
                      />
                    </div>
                    <div className="form__input-block form__input-block--half">
                      <Input
                        id={`phone-${index}`}
                        name={`contacts[${index}].phone`}
                        label={intl.formatMessage(messages.phonesLabel)}
                        defaultValue={values.contacts[index].phone}
                        onChange={handleChange}
                        hasError={hasError(`contacts.${index}.phone`)}
                        errorMessage={contactsErrors && contactsErrors[index]?.phone}
                      />
                    </div>
                  </div>
                  <div className="form__inputs-subwrapper">
                    <div className="form__input-block form__input-block--half">
                      <Input
                        id={`email-${index}`}
                        name={`contacts[${index}].email`}
                        label={intl.formatMessage(messages.emailLabel)}
                        defaultValue={values.contacts[index].email}
                        onChange={handleChange}
                        hasError={hasError(`contacts.${index}.email`)}
                        errorMessage={contactsErrors && contactsErrors[index]?.email}
                      />
                    </div>
                    <div className="form__input-block form__input-block--half">
                      <Input
                        id={`additionalInfo-${index}`}
                        name={`contacts[${index}].additionalInfo`}
                        label={intl.formatMessage(messages.additionalInfoInput)}
                        defaultValue={values.contacts[index].additionalInfo}
                        onChange={handleChange}
                        hasError={hasError(`contacts.${index}.additionalInfo`)}
                        errorMessage={contactsErrors && contactsErrors[index]?.additionalInfo}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  externalClass={'form__btn-clean-inputs'}
                  type={'button'}
                  onClick={removeContact(index)}
                  color="gray"
                >
                  <Icon iconName={'cross'} externalClass={'form__btn-icon disabled-icon'} />
                </Button>
              </div>
            </div>
          ))}
          <Button externalClass={'form__btn-add-group'} type={'button'} onClick={addNewContact} color="gray">
            <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
            <FormattedMessage {...messages.addButton} />
          </Button>
        </div>
        <ErrorMessage>{officeError}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewOffice;
