import { defineMessages } from 'react-intl';
import {
  memberLabel,
  editButton,
  statusLabel,
  exportToPDFLabel,
  lastUpdateColumn,
  updaterColumn,
  experienceLabel,
  positionLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  memberLabel,
  editButton,
  statusLabel,
  exportToPDFLabel,
  lastUpdateColumn,
  updaterColumn,
  experienceLabel,
  positionLabel,
  pageTitle: {
    id: 'resumes.management.title.resumes.management',
    defaultMessage: 'Resumes Management',
  },
  approvedLabel: {
    id: 'resumes.management.label.approved',
    defaultMessage: 'Approved',
  },
  notApprovedLabel: {
    id: 'resumes.management.label.not.approved',
    defaultMessage: 'Not Approved',
  },
  yearsLabel: {
    id: 'resumes.management.label.years',
    defaultMessage: 'Years',
  },
  monthsLabel: {
    id: 'resumes.management.label.months',
    defaultMessage: 'Months',
  },
  infoPageTitle: {
    id: 'resumes.management.resume.info.title.public.cv',
    defaultMessage: 'Public CV',
  },
  companyNameLabel: {
    id: 'resumes.management.resume.info.lable.softarex.technologies',
    defaultMessage: 'Softarex Technologies',
  },
  ofExperienceLabel: {
    id: 'resumes.management.resume.info.lable.of.experience',
    defaultMessage: 'of Experience',
  },
  summaryTitle: {
    id: 'resumes.management.resume.info.title.summary',
    defaultMessage: 'Summary',
  },
  personalHighlightsTitle: {
    id: 'resumes.management.resume.info.title.personal.Highlights',
    defaultMessage: 'Personal Highlights',
  },
  interestsAndHobbiesTitle: {
    id: 'resumes.management.resume.info.title.interests.and.hobbies',
    defaultMessage: 'Interests And Hobbies',
  },
  remarkableProjectsTitle: {
    id: 'resumes.management.resume.info.title.remarkable.projects',
    defaultMessage: 'Remarkable Projects',
  },
  exportButton: {
    id: 'resumes.management.btn.export',
    defaultMessage: 'Export',
  },
  selectedLabel: {
    id: 'resumes.management.resume.info.lable.selected',
    defaultMessage: 'Selected',
  },
});
