import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as planningActions from '../../actions/planning.actions';
import cx from 'classnames';
import moment from 'moment';
import DurationPicker from '../../components/Dropdown';
import Icon from '../../components/Icon';
import GeneralizedViewLayout from '../../components/UserHours/Layouts/GeneralizedViewLayout';
import { PlanningLayouts, userHoursUnsavedParams } from '../../constants/planning.constants';
import Button from '../../components/Button';
import DetailedViewLayout from '../../components/UserHours/Layouts/DetailedViewLayout';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import InlineDatePicker from '../../components/InlineDatePicker';
import { resetParamsChange, useLayout, useParamsChange, useUsersParamsChange } from '../../utils/hooks.utils';
import * as filtersActions from '../../actions/filters.actions';
import { UserHoursParams } from '../../enums/params/planning.params';
import { DATE_FORMAT } from '../../constants/date.constants';
import { exportUserHours } from '../../actions/export.actions';
import { EFileExtensions } from '../../constants/export.constants';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import UserHoursFilter from '../../components/UserHours/Filter/UserHoursFilter';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';

export type UserHoursDataType = {
  departmentId: string;
  departmentName: string;
  total: TotalDataType;
  usersData: UserDataType[];
};

export type ProjectGroupType = {
  hours: {
    factHours: number;
    planFact: number;
    planHours: number;
  };
  projectGroup: {
    fullName: string;
    id: string;
    shortName: string;
  };
};

export type TotalDataType = {
  allFactNormative: number;
  fact: {
    allHours: number;
    projectHours: number;
  };
  normative: {
    days: number;
    hours: number;
  };
  plan: {
    hours: number;
    projectGroupsCount: number;
  };
  planNormative: number;
  projectFactPlan: number;
};

export type UserDataType = {
  projectGroups: ProjectGroupType;
  total: TotalDataType;
  user: {
    firstName: string;
    secondName: string;
    id: string;
    photo: {
      fileId: string;
      url: string;
    } | null;
  };
};

function UserHours({
  getUsersFilter,
  getOfficesFilter,
  exportUserHours,
  getDepartmentsFilter,
  usersFilter,
  officesFilter,
  departmentsFilter,
  setUserHoursParams,
  resetState,
  tableData,
  isLoading,
  userHoursDataError,
  params,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.USER_HOURS_DETAILS_FILTER);

    setUserHoursParams(
      currentSavedFilter
        ? new UserHoursParams(currentSavedFilter)
        : {
            officeIds: officesFilter.value,
            departmentIds: departmentsFilter.value,
            userIds: usersFilter.value,
          },
    );
    getUsersFilter();
    getOfficesFilter();
    getDepartmentsFilter();
    setSavedFiltersParams({ filterType: FilterTypes.USER_HOURS_DETAILS_FILTER });

    return () => {
      resetState();
    };
  }, []);

  const [layout, handleChangeActiveLayout] = useLayout(PlanningLayouts, PlanningLayouts.GENERALIZED);

  const handleMultiParamsChange = useParamsChange(setUserHoursParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setUserHoursParams, dispatch);

  const onDateChange = (start: string, end: string) => {
    setUserHoursParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  };
  const filters = useMemo(
    () => ({
      users: usersFilter.users,
      departments: departmentsFilter.departments,
      offices: officesFilter.offices,
    }),
    [usersFilter.users, officesFilter.offices, departmentsFilter.departments],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      setUserHoursParams(
        new UserHoursParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setUserHoursParams(new UserHoursParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));
    resetParamsChange(
      [FilterParamsName.USER_IDS, FilterParamsName.DEPARTMENT_IDS, FilterParamsName.OFFICE_IDS],
      dispatch,
    );
  }, [params]);

  const dropdownList = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.exportToXLSLabel),
        handler: () => exportUserHours(EFileExtensions.XLS),
      },
      {
        label: intl.formatMessage(messages.exportToPDFLabel),
        handler: () => exportUserHours(EFileExtensions.PDF),
      },
    ],
    [],
  );

  const showClearButton = useMemo(() => !checkParamsMatch(params, new UserHoursParams(), userHoursUnsavedParams), [
    params,
  ]);

  return (
    <>
      <div className="page__panel page__panel--fixed user_hours_details_page">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <div className="page__panel-top__wrapper--left">
              <h1 className="page__title">
                <FormattedMessage {...messages.pageTitle} />
              </h1>
            </div>
            <div className="page__panel-top__control">
              <InlineDatePicker
                onDateChange={onDateChange}
                defaultPeriodStart={params.dateFrom}
                defaultPeriodEnd={params.dateTo}
              />
              <div className="page__panel-view">
                <Button
                  externalClass={cx('page__panel-view__btn', { active: layout === PlanningLayouts.GENERALIZED })}
                  onClick={() => handleChangeActiveLayout(PlanningLayouts.GENERALIZED)}
                  color="gray"
                >
                  <Icon iconName="table" />
                </Button>
                <Button
                  externalClass={cx('page__panel-view__btn', { active: layout === PlanningLayouts.DETAILED })}
                  onClick={() => handleChangeActiveLayout(PlanningLayouts.DETAILED)}
                  color="gray"
                >
                  <Icon iconName="grid" />
                </Button>
              </div>
              <DurationPicker
                dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                dropdownList={dropdownList}
              />
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <UserHoursFilter
                  filters={filters}
                  values={params}
                  handleUsersParamsChange={handleUsersParamsChange}
                  handleMultiParamsChange={handleMultiParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  setUserHoursParams={setUserHoursParams}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          {layout === PlanningLayouts.GENERALIZED ? (
            <GeneralizedViewLayout
              tableData={tableData}
              isLoading={isLoading.getUserHoursData}
              userHoursDataError={userHoursDataError}
            />
          ) : (
            <DetailedViewLayout
              tableData={tableData}
              isLoading={isLoading.getUserHoursData}
              userHoursDataError={userHoursDataError}
            />
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ planning, filters, auth }: RootState) => ({
  userHoursDataError: planning.errors.userHoursDataError,
  isLoading: planning.loading,
  tableData: planning.userHoursTableData,
  params: planning.userHoursParams,
  usersFilter: filters.usersFilter,
  officesFilter: filters.officesFilter,
  departmentsFilter: filters.departmentsFilter,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  setUserHoursParams: (data: Partial<UserHoursParams>) => dispatch(planningActions.setUserHoursParams(data)),
  exportUserHours: (ext: EFileExtensions) => dispatch(exportUserHours(ext)),
  resetState: () => dispatch(planningActions.resetPlaningState()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UserHours);
