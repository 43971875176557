import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Icon from '../../components/Icon';
import { FinancePlan } from '../../enums/finance/finance.enum';
import { NavLink, useHistory } from 'react-router-dom';
import r from '../../constants/routes.constants';
import { DELETE_FINANCE_PLAN, UPDATE_FINANCE_PLAN } from '../../constants/policies.constants';
import { formatValue } from 'react-currency-input-field';
import classNames from 'classnames';

export const useDataForTable = (
  setPlanClicked: (row: FinancePlan) => void,
  setModalEditPlanIsOpen: (value: boolean) => void,
  setModalDeletePlanIsOpen: (value: boolean) => void,
  setModalClonePlanIsOpen: (value: boolean) => void,
) => {
  const intl = useIntl();
  const history = useHistory();

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.clientLabel),
        sortName: 'client.name',
        modifier: (row: FinancePlan) => (
          <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
            <div className={'table__data-wrapper'}>
              <div className="name-capital-letter-block">
                <span>{row.client?.name[0]}</span>
              </div>
              <div>{row.client?.name}</div>
            </div>
          </NavLink>
        ),
        className: 'table__data--bold',
      },
      {
        name: intl.formatMessage(messages.projectLabel),
        modifier: (row: FinancePlan) => (
          <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
            <span className={'table_button'}>{row.financeProject?.name}</span>
          </NavLink>
        ),
      },
      {
        name: intl.formatMessage(messages.membersLabel),
        modifier: (row: FinancePlan) => (
          <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
            {row.employees?.length}
          </NavLink>
        ),
      },
      {
        name: intl.formatMessage(messages.hourPriceLabel),
        sortName: 'baseRate',
        modifier: (row: FinancePlan) => (
          <NavLink className={'table__type-link text-right'} to={`/planning/project-plan/${row.id}`}>
            {`${formatValue({
              value: row.currencyWorkHourRate?.value,
            })} ${row.currency?.name}`}
          </NavLink>
        ),
      },
      {
        name: intl.formatMessage(messages.plannedLabel),
        sortName: 'plannedIncome',
        modifier: (row: FinancePlan) => (
          <NavLink className={'table__type-link text-right'} to={`/planning/project-plan/${row.id}`}>
            <div className={classNames({ 'global-color': +row?.plannedIncome?.value <= 0 })}>
              {`${formatValue({
                value: row.plannedIncome?.value,
              })}  ${row.currency?.name}`}
            </div>
          </NavLink>
        ),
      },
      {
        name: intl.formatMessage(messages.calculatedColumn),
        sortName: 'calculatedPlannedIncome',
        modifier: (row: FinancePlan) => (
          <NavLink className={'table__type-link text-right'} to={`/planning/project-plan/${row.id}`}>{`${
            formatValue({
              value: row.calculatedPlannedIncome?.toString(),
            }) || 0
          }  ${row.currency?.name}`}</NavLink>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlan) => {
          setPlanClicked(row);
          setModalEditPlanIsOpen(true);
        },
        verifiablePolicies: [UPDATE_FINANCE_PLAN],
      },
      {
        label: (
          <>
            <Icon iconName={'users'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.membersLabel} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlan) => {
          setPlanClicked(row);
          history.push(r.projectPlan.replace(':id', row.id));
        },
      },
      {
        label: (
          <>
            <Icon iconName={'copy'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.cloneButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlan) => {
          setPlanClicked(row);
          setModalClonePlanIsOpen(true);
        },
        verifiablePolicies: [UPDATE_FINANCE_PLAN],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlan) => {
          setPlanClicked(row);
          setModalDeletePlanIsOpen(true);
        },
        verifiablePolicies: [DELETE_FINANCE_PLAN],
      },
    ],
    [],
  );

  return {
    tableColumns,
    tableActions,
  };
};
