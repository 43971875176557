import React, { useCallback, useMemo } from 'react';
import { UserHoursDataType, UserDataType } from '../../../pages/UserHours/index';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { UserInfo } from '../../../enums/users.enum';
import TableUserAvatar from '../../TableUserAvatar';
import HierarchicalTable from '../../HierarchicalTable';
import { useTableData } from '../../../utils/hooks.utils';
import { getTableCell } from '../../../utils/table.utils';
import { getTime } from '../../../utils/planning.utils';
import { DepthLevels } from '../../../constants/tables.constants';

type GeneralizedViewLayoutType = {
  tableData: any;
  isLoading: boolean;
  userHoursDataError: string;
};

function GeneralizedViewLayout({ tableData, isLoading, userHoursDataError }: GeneralizedViewLayoutType) {
  const intl = useIntl();

  const tableColumns = useMemo(
    () => [
      {
        id: 'departmentName',
        Header: () => <span>{intl.formatMessage(messages.departmentsMembersLabel)}</span>,
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => {
                const departmentName = row.departmentName;
                return (
                  <>
                    {row.departmentId ? (
                      <span className={'table_button--open-modal'}>{departmentName}</span>
                    ) : (
                      departmentName
                    )}
                  </>
                );
              },
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <TableUserAvatar users={[new UserInfo(row.user)]} fileSize={48} />,
            },
          ]),
      },
      {
        id: 'workDaysTitle',
        Header: intl.formatMessage(messages.workDaysTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => <span className="weight-normal">{row.total.normative.days}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <span>{row.total.normative.days}</span>,
            },
          ]),
      },
      {
        id: 'workHoursTitle',
        Header: intl.formatMessage(messages.workHoursTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => (
                <span className="weight-normal">{getTime(row.total.normative.hours)}</span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <span>{getTime(row.total.normative.hours)}</span>,
            },
          ]),
      },
      {
        id: 'total.plan.projectsLabel',
        Header: intl.formatMessage(messages.projectsLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => (
                <span className="weight-normal">{row.total.plan.projectGroupsCount}</span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <span>{row.total.plan.projectGroupsCount}</span>,
            },
          ]),
      },
      {
        id: 'hoursTitle',
        Header: intl.formatMessage(messages.hoursTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => (
                <span className="weight-normal">{getTime(row.total.plan.hours)}</span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <span>{getTime(row.total.plan.hours)}</span>,
            },
          ]),
      },
      {
        id: 'projectHoursTitle',
        Header: intl.formatMessage(messages.projectHoursTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => (
                <span className={`weight-normal ${row.total.fact.projectHours < row.total.plan.hours ? 'global' : ''}`}>
                  {getTime(row.total.fact.projectHours)}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => (
                <span className={`${row.total.fact.projectHours < row.total.plan.hours ? 'global' : ''}`}>
                  {getTime(row.total.fact.projectHours)}
                </span>
              ),
            },
          ]),
      },
      {
        id: 'allHoursTitle',
        Header: intl.formatMessage(messages.allHoursTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => (
                <span className={`${row.total.fact.allHours < row.total.plan.hours ? 'global' : ''}`}>
                  {getTime(row.total.fact.allHours)}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => (
                <span className={`${row.total.fact.allHours < row.total.plan.hours ? 'global' : ''}`}>
                  {getTime(row.total.fact.allHours)}
                </span>
              ),
            },
          ]),
      },
      {
        Header: '',
        accessor: 'total.planNormative',
      },
      {
        id: 'allFact.normal',
        Header: '',
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => (
                <span className={`${row.total.allFactNormative < row.total.planNormative ? 'global' : ''}`}>
                  {row.total.allFactNormative}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => (
                <span className={`${row.total.allFactNormative < row.total.planNormative ? 'global' : ''}`}>
                  {row.total.allFactNormative}
                </span>
              ),
            },
          ]),
      },
      {
        id: 'prjFact.plan',
        Header: '',
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => (
                <span className={`${row.total.projectFactPlan < row.total.planNormative ? 'global' : ''}`}>
                  {row.total.projectFactPlan}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => (
                <span className={`${row.total.projectFactPlan < row.total.planNormative ? 'global' : ''}`}>
                  {row.total.projectFactPlan}
                </span>
              ),
            },
          ]),
      },
    ],
    [],
  );

  const tableHeaderItems = [
    { name: '', className: '', colspan: 1, sortName: '' },
    { name: intl.formatMessage(messages.normativeTitle), className: 'border-bottom', colspan: 2, sortName: '' },
    { name: intl.formatMessage(messages.planColumn), className: 'border-bottom', colspan: 2, sortName: '' },
    { name: intl.formatMessage(messages.factColumn), className: 'border-bottom', colspan: 2, sortName: '' },
    { name: intl.formatMessage(messages.planNormTitle), className: '', colspan: 1, sortName: '' },
    { name: intl.formatMessage(messages.allFactNormTitle), className: '', colspan: 1, sortName: '' },
    { name: intl.formatMessage(messages.projectFactPlanTitle), className: '', colspan: 1, sortName: '' },
  ];

  const newTableData = tableData?.complex?.slice(0);

  newTableData?.push({
    departmentId: '',
    departmentName: intl.formatMessage(messages.totalRow),
    total: tableData.total,
  });

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 1) {
      return row.id.split('.')[1] % 2 === 0 ? 'even' : '';
    }
    return '';
  }, []);

  return (
    <HierarchicalTable
      tableHeaderItems={tableHeaderItems}
      tableData={useTableData(newTableData, ['usersData'])}
      externalClass="user_hours_table_generalized_view"
      tableHeaderClassName="user_hours_header"
      externalRowClass={handleExternalRowClass}
      tableColumns={tableColumns}
      loading={isLoading}
      error={userHoursDataError}
      disabledDepthHover={[DepthLevels.SECOND]}
    />
  );
}

export default GeneralizedViewLayout;
