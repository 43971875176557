import { defineMessages } from 'react-intl';
import {
  editButton,
  deleteButton,
  newButton,
  cloneButton,
  clientLabel,
  membersLabel,
  projectLabel,
  plannedLabel,
  hourPriceLabel,
  planColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  newButton,
  cloneButton,
  clientLabel,
  membersLabel,
  projectLabel,
  plannedLabel,
  hourPriceLabel,
  planColumn,
  pageTitle: {
    id: 'finance.planning.title.planning',
    defaultMessage: 'Planning',
  },
  calculatedColumn: {
    id: 'finance.planning.column.calculated',
    defaultMessage: 'Calculated',
  },
  plannedHoursColumn: {
    id: 'finance.planning.column.planned.hours',
    defaultMessage: 'Planed Hours',
  },
  percentOfRegulatoryColumn: {
    id: 'finance.planning.column.percent.of.regulatory',
    defaultMessage: '% of regulatory',
  },
  plannedIncomeColumn: {
    id: 'finance.planning.Column.planned.income',
    defaultMessage: 'Planned Income',
  },
  calculatedIncomeLabel: {
    id: 'finance.planning.label.calculated.income',
    defaultMessage: 'Calculated Income',
  },
  baseRateLabel: {
    id: 'finance.planning.label.base.rate',
    defaultMessage: 'Base Hour price',
  },
  importResourcesBtn: {
    id: 'finance.planning.import.resources.btn.resources',
    defaultMessage: 'Resources',
  },
});
