import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  newLanguageLevelTitle: {
    id: 'resumes.language.levels.modal.new.language.level.title.new.language.level',
    defaultMessage: 'New Language Level',
  },
  editLanguageLevelTitle: {
    id: 'resumes.language.levels.modal.edit.language.level.title.edit.language.level',
    defaultMessage: 'Edit Language Level',
  },
});
