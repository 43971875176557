import { useFormik } from 'formik';
import { get } from 'lodash-es';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UPDATE_BONUS_SETTINGS } from '../../../constants/policies.constants';
import PoliciesContext from '../../../PoliciesContext';
import { checkPolicies } from '../../../utils/policies.utils';
import AccessChecker from '../../AccessChecker';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import Input from '../../Input';
import Modal from '../../Modal';
import Switch from '../../Switch';
import messages from '../messages';
import { scrollToError } from '../../../utils';

export type BonusesSettingsType = {
  ratio: number;
  isShow: boolean;
};

type ModalSettingsProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  editBonusesSettings: (data: any) => void;
  resetErrors: () => void;
  bonusesErrors: null | string;
  bonusesSettings: BonusesSettingsType;
  isLoading: boolean;
};

function ModalSettings({
  isOpen,
  bonusesErrors,
  bonusesSettings,
  isLoading,
  onCloseRequest,
  editBonusesSettings,
  resetErrors,
}: ModalSettingsProps) {
  const intl = useIntl();

  const policies = useContext(PoliciesContext);

  const { values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: bonusesSettings || {},
    validate: scrollToError,
    onSubmit: data => {
      return editBonusesSettings({ data: { ...data, ratio: data.ratio || '0' }, callback: resetAndExit });
    },
  });

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      size="small"
      title={intl.formatMessage(messages.bonusesSettingsTitle)}
      onRequestClose={resetAndExit}
      shouldCloseOnOverlayClick
      classNameModal="center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__inputs-subwrapper bonuses-ratio-wrapper">
            <span className="bonuses-ratio-label">
              <FormattedMessage {...messages.bonusesSettingsShowBonusLabel} />
            </span>
            <Switch
              checked={values.isShow}
              name="isShow"
              onChange={handleChange}
              disabled={!checkPolicies([UPDATE_BONUS_SETTINGS], policies)}
            />
          </div>
          <div className="form__inputs-subwrapper bonuses-ratio-wrapper">
            <span className="bonuses-ratio-label">
              <FormattedMessage {...messages.bonusesSettingsRatioLabel} />
            </span>
            <Input
              id={'ratio'}
              name={'ratio'}
              min={0}
              max={1}
              step=".01"
              type="number"
              defaultValue={values.ratio}
              onChange={handleChange}
              hasError={hasError('displayName')}
              externalClass="form__input--no-label"
              wrapperClass="bonuses-ratio"
              errorMessage={errors?.ratio}
              disabled={!checkPolicies([UPDATE_BONUS_SETTINGS], policies)}
            />
          </div>
        </div>
        <ErrorMessage>{bonusesErrors}</ErrorMessage>
        <AccessChecker verifiablePolicies={[UPDATE_BONUS_SETTINGS]}>
          <div className={'form__buttons'}>
            <Button color={'gray'} type="button" externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type="submit" loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </AccessChecker>
      </form>
    </Modal>
  );
}

export default ModalSettings;
