import React from 'react';
import Icon from '../../Icon';
import { Recognition } from '../../../enums/recognitions.enum';
import moment from 'moment';
import Avatar from '../../Profile/Avatar';
import { DATE_FORMAT } from '../../../constants/date.constants';

type RecognitionCard = {
  recognition: Recognition;
  openDeleteRecognitionModal: any;
  openEditRecognitionModal: any;
  authUserdata: { id: string };
};

function RecognitionCard({
  recognition,
  openDeleteRecognitionModal,
  openEditRecognitionModal,
  authUserdata,
}: RecognitionCard) {
  const date = moment(new Date(recognition.lastModifiedDate)).valueOf();
  const currentDate = moment().valueOf();
  const dateDifference = (currentDate - date) / (1000 * 60 * 60) - moment(date).utcOffset() / 60;
  return (
    <div className="recognition" style={{ backgroundColor: '#' + recognition.recognitionType.backgroundColor }}>
      <img className="recognition-image" src={recognition.recognitionType.icon?.url} />
      <div className="recognition-type">{recognition.recognitionType.name}</div>
      {dateDifference <= 24 && authUserdata.id === recognition.authorId && (
        <div className="recognition-actions">
          <span className="cursor-pointer" onClick={() => openEditRecognitionModal(recognition)}>
            <Icon iconName={'pencil'} />
          </span>
          <span className="cursor-pointer" onClick={() => openDeleteRecognitionModal(recognition)}>
            <Icon iconName={'trash'} />
          </span>
        </div>
      )}
      <div className="text">{recognition.text}</div>
      <div className="author">
        <Avatar userInfo={recognition.author} externalClass="image" isUserPageLink fileSize={48} />
        <span className="name">
          {recognition.author.fullName} <br />
          {moment(date).format(DATE_FORMAT.LL)}
        </span>
      </div>
    </div>
  );
}

export default RecognitionCard;
