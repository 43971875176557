import { SortParams } from '../params.enum';
import { get } from 'lodash-es';

export class TechnicalSkillsParams extends SortParams {
  size: number;
  page: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
  }
}

export class CompanyPositionsParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('priority', params);
    this.size = get(params, 'size', 1000);
  }
}

export class OfficesParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class DepartmentsParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('displayName', params);
    this.size = get(params, 'size', 1000);
  }
}

export class GradesParams extends SortParams {
  constructor(params?: unknown) {
    super('level', params);
  }
}

export class SpecializationsParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class LanguageLevelsParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('priority', params);
    this.size = get(params, 'size', 1000);
  }
}

export class SkillLevelsParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('priority', params);
    this.size = get(params, 'size', 1000);
  }
}

export class ProjectsParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class ForeignLanguagesParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}
