import React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Icon from '../../components/Icon';
import TableUserAvatar from '../../components/TableUserAvatar';
import { DepthLevels } from '../../constants/tables.constants';
import { OfficeInfo } from '../../enums/libraries.enum';
import {
  PolicyGroupSubmoduleWithOffice,
  PolicyGroupWithUsersLevel,
  PolicyGroupWithUsersModule,
  PolicyGroupWithUsersSubmodule,
  PolicyType,
  PolicyTypeEnum,
} from '../../enums/policies.enum';
import { UserInfo } from '../../enums/users.enum';
import { getTableCell } from '../../utils/table.utils';
import messages from './messages';

export const useDataForTable = (usersFilter: UserInfo[], openEditPermissionsModal: (submodule: any) => void) => {
  const intl = useIntl();

  const tableColumns = useMemo(
    () => [
      {
        Header: intl.formatMessage(messages.pageModalColumn),
        accessor: 'name',
      },
      {
        id: 'viewColumn',
        Header: intl.formatMessage(messages.viewColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: PolicyGroupWithUsersLevel) => row.policyTypesCounts.VIEW.toString(),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: PolicyGroupWithUsersModule) => row.policyTypesCounts.VIEW.toString(),
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: PolicyGroupWithUsersSubmodule) => {
                const policy = row.policies.find(item => item.policyType === PolicyTypeEnum.VIEW);
                return policy ? (
                  usersFilter.length ? (
                    policy.userIds?.length ? (
                      <TableUserAvatar users={policy.userIds} fileSize={48} allUsersList={usersFilter} />
                    ) : (
                      '-'
                    )
                  ) : (
                    policy.userIds.length.toString()
                  )
                ) : (
                  '-'
                );
              },
            },
            {
              depth: DepthLevels.FOURTH,
              content: (row: OfficeInfo & { policies: PolicyType[] }) => {
                const office = row.policies
                  .find((item: any) => item.policyType === PolicyTypeEnum.VIEW)
                  ?.offices?.find(item => item.office.id === row.id);

                return office ? (
                  usersFilter.length ? (
                    office.userIds?.length ? (
                      <TableUserAvatar users={office.userIds} fileSize={48} allUsersList={usersFilter} />
                    ) : null
                  ) : (
                    office.userIds.length.toString()
                  )
                ) : (
                  '0'
                );
              },
            },
          ]),
      },
      {
        id: 'updateColumn',
        Header: intl.formatMessage(messages.updateColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: PolicyGroupWithUsersLevel) => row.policyTypesCounts.UPDATE.toString(),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: PolicyGroupWithUsersModule) => row.policyTypesCounts.UPDATE.toString(),
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: PolicyGroupWithUsersSubmodule) => {
                const policy = row.policies.find(item => item.policyType === PolicyTypeEnum.UPDATE);
                return policy ? (
                  usersFilter.length ? (
                    policy.userIds?.length ? (
                      <TableUserAvatar users={policy.userIds} fileSize={48} allUsersList={usersFilter} />
                    ) : (
                      '-'
                    )
                  ) : (
                    policy.userIds.length.toString()
                  )
                ) : (
                  '-'
                );
              },
            },
            {
              depth: DepthLevels.FOURTH,
              content: (row: OfficeInfo & { policies: PolicyType[] }) => {
                const office = row.policies
                  .find((item: any) => item.policyType === PolicyTypeEnum.UPDATE)
                  ?.offices?.find(item => item.office.id === row.id);

                return office ? (
                  usersFilter.length ? (
                    office.userIds?.length ? (
                      <TableUserAvatar users={office.userIds} fileSize={48} allUsersList={usersFilter} />
                    ) : null
                  ) : (
                    office.userIds.length.toString()
                  )
                ) : (
                  '0'
                );
              },
            },
          ]),
      },
      {
        id: 'deleteColumn',
        Header: intl.formatMessage(messages.deleteColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: PolicyGroupWithUsersLevel) => row.policyTypesCounts.DELETE.toString(),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: PolicyGroupWithUsersModule) => row.policyTypesCounts.DELETE.toString(),
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: PolicyGroupWithUsersSubmodule) => {
                const policy = row.policies.find(item => item.policyType === PolicyTypeEnum.DELETE);
                return policy ? (
                  usersFilter.length ? (
                    policy.userIds?.length ? (
                      <TableUserAvatar users={policy.userIds} fileSize={48} allUsersList={usersFilter} />
                    ) : (
                      '-'
                    )
                  ) : (
                    policy.userIds.length.toString()
                  )
                ) : (
                  '-'
                );
              },
            },
            {
              depth: DepthLevels.FOURTH,
              content: (row: PolicyGroupSubmoduleWithOffice) => {
                const office = row.policies
                  .find((item: any) => item.policyType === PolicyTypeEnum.DELETE)
                  ?.offices?.find(item => item.office.id === row.id);

                return office ? (
                  usersFilter.length ? (
                    office.userIds?.length ? (
                      <TableUserAvatar users={office.userIds} fileSize={48} allUsersList={usersFilter} />
                    ) : null
                  ) : (
                    office.userIds.length.toString()
                  )
                ) : (
                  '0'
                );
              },
            },
          ]),
      },
      {
        id: 'action',
        Header: '',
        headClassName: 'action-column',
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.THIRD,
              content: (row: PolicyGroupWithUsersSubmodule) =>
                row.policies.some(item => item.isOfficeSpecificImplemented) ? null : (
                  <div className="table__dropdown-wrapper">
                    <button onClick={() => openEditPermissionsModal(row)}>
                      <Icon iconName="pencil" />
                    </button>
                  </div>
                ),
            },
            {
              depth: DepthLevels.FOURTH,
              content: (row: PolicyGroupSubmoduleWithOffice) => (
                <div className="table__dropdown-wrapper">
                  <button onClick={() => openEditPermissionsModal(row)}>
                    <Icon iconName="pencil" />
                  </button>
                </div>
              ),
            },
          ]),
      },
    ],
    [usersFilter],
  );

  return {
    tableColumns,
  };
};
