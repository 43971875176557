import React, { memo, useCallback, useMemo, useState, useRef, useContext } from 'react';
import QuestionsSection from './QuestionsSection';
import { UserInfo } from '../../../enums/users.enum';
import ModalExportPollAnswers from '../../../components/Polls/Modals/ModalExportPollAnswers';
import PollInfoHeader from './PollInfoHeader';
import { completedStatusValue, messageWithNoQuestions, notStartedStatusValue, publishedValue } from '../utils';
import { CompanyPositionInfoType, SpecializationInfoType } from '../../../types/libraries';
import Button from '../../../components/Button';
import messages from '../messages';
import { useIntl } from 'react-intl';
import PoliciesContext from '../../../PoliciesContext';
import { checkPolicies } from '../../../utils/policies.utils';
import { UPDATE_POLL } from '../../../constants/policies.constants';

type PollInfoContentProps = {
  pollData: any;
  backButtonHandler: () => void;
  changePollStatus: (status: string) => void;
  openDeletePollModal: () => void;
  openEditPollModal: () => void;
  createNewPoll: (data: any) => void;
  loading: boolean;
  currentStatus: string;
  questions: any;
  userList?: any;
  offices: any;
  currentUser: UserInfo;
  updateOwnPollAnswers: any;
  answersByPollAndUser: any;
  allResponders: any;
  updateOwnAnswersError: string | null;
  setIsSubmitResultAnswers: any;
  isSavingProgress: boolean;
  onExport: any;
  isAdmin: boolean;
  pollId: string;
  hasQuestions: boolean;
  onOpenConfirmModalForOwnAnswers: () => void;
  isOpenConfirmForSubmitAnswers: boolean;
  onCloseConfirmModal: () => void;
  companyPositions: CompanyPositionInfoType[];
  specializations: SpecializationInfoType[];
  jiraProjectsList: { id: number; name: string }[];
  getJiraProjects: () => void;
  getSpecializationsFilter: () => void;
  getCompanyPostitionsFilter: () => void;
  userFilterList: UserInfo[];
  getNotAnsweredPoll: () => void;
  isFromMyPolls: boolean;
  setShowPrompt: (showPrompt: boolean) => void;
  pollGroupsScores: {
    averageScore: number;
    id: string;
    maxScores: number;
    percentage: number;
  }[];
};

const PollInfoContent = ({
  pollData,
  backButtonHandler,
  changePollStatus,
  openDeletePollModal,
  openEditPollModal,
  loading,
  currentStatus,
  createNewPoll,
  questions,
  userList,
  offices,
  currentUser,
  updateOwnPollAnswers,
  answersByPollAndUser,
  allResponders,
  updateOwnAnswersError,
  setIsSubmitResultAnswers,
  isSavingProgress,
  onExport,
  isAdmin,
  pollId,
  hasQuestions,
  onOpenConfirmModalForOwnAnswers,
  isOpenConfirmForSubmitAnswers,
  onCloseConfirmModal,
  companyPositions,
  specializations,
  jiraProjectsList,
  getJiraProjects,
  getSpecializationsFilter,
  getCompanyPostitionsFilter,
  userFilterList,
  getNotAnsweredPoll,
  isFromMyPolls,
  setShowPrompt,
  pollGroupsScores,
}: PollInfoContentProps) => {
  const questionRef = useRef<any>(null);
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const [editAnswersMode, setEditAnswersMode] = useState(false);
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [answersChanged, setAnswersChanged] = useState(false);

  const changeAnswersMode = useCallback(
    () =>
      setEditAnswersMode(prev => {
        setAnswersChanged(false);
        return !prev;
      }),
    [],
  );
  const handleCancel = useCallback(() => setEditAnswersMode(false), []);
  const closeExportModal = useCallback(() => setIsOpenExportModal(false), []);

  const submit = useCallback((isSubmitAnswers: boolean) => {
    const formRef = questionRef.current;
    if (isSubmitAnswers && formRef?.handleSubmit) {
      questionRef?.current?.handleSubmit();
    } else if (formRef.handleSaveProgress) {
      questionRef?.current?.handleSaveProgress();
    }
    setIsSubmitResultAnswers(isSubmitAnswers);
  }, []);

  const handleSaveProgress = useCallback(() => submit(false), []);
  const handleSubmitAnswers = useCallback(() => {
    submit(true);
    handleCancel();
  }, []);

  const officesList = useMemo(() => offices?.map((office: any) => ({ ...office, label: office.name })), [offices]);

  const conditionForButtons = useMemo(() => {
    return (
      editAnswersMode || (!isAdmin && pollData?.status === publishedValue && checkPolicies([UPDATE_POLL], policies))
    );
  }, [editAnswersMode, isAdmin, pollData, policies]);

  const isNotStartedOrCompletedUserPoll = useMemo(() => {
    return pollData?.userStatus === notStartedStatusValue || pollData?.userStatus === completedStatusValue;
  }, [pollData?.userStatus, currentUser]);

  return (
    <>
      {isOpenExportModal && (
        <ModalExportPollAnswers
          onCloseRequest={closeExportModal}
          isOpen={isOpenExportModal}
          subTitle={pollData.name}
          onExport={onExport}
          pollId={pollData?.id}
          userIds={userList?.map(({ user }: any) => user.id)}
        />
      )}
      <PollInfoHeader
        pollData={pollData}
        backButtonHandler={backButtonHandler}
        changePollStatus={changePollStatus}
        loading={loading}
        onCancel={handleCancel}
        onSaveProgress={handleSaveProgress}
        onSubmitAnswers={handleSubmitAnswers}
        currentStatus={currentStatus}
        openEditPollModal={openEditPollModal}
        changeAnswersMode={changeAnswersMode}
        openDeletePollModal={openDeletePollModal}
        setIsOpenExportModal={setIsOpenExportModal}
        createNewPoll={createNewPoll}
        isAdmin={isAdmin}
        isOpenConfirmForSubmitAnswers={isOpenConfirmForSubmitAnswers}
        onCloseConfirmModal={onCloseConfirmModal}
        answersChanged={answersChanged}
        conditionForButtons={conditionForButtons}
        isNotStartedOrCompletedUserPoll={isNotStartedOrCompletedUserPoll}
        isFromMyPolls={isFromMyPolls}
      />
      <div className="page__inner-content">
        {pollData && !loading && (
          <div className="page__wrapper" style={{ marginTop: '20px' }}>
            <QuestionsSection
              pollData={pollData}
              userList={userList}
              questionsGroups={questions?.groups}
              editAnswersMode={editAnswersMode}
              offices={officesList}
              onSaveAnswers={updateOwnPollAnswers}
              isAdmin={isAdmin}
              currentUser={currentUser}
              ref={questionRef}
              pollId={pollId}
              answersByPollAndUser={answersByPollAndUser}
              allResponders={allResponders}
              updateOwnAnswersError={updateOwnAnswersError}
              isSavingProgress={isSavingProgress}
              backButtonHandler={backButtonHandler}
              companyPositions={companyPositions}
              specializations={specializations}
              jiraProjectsList={jiraProjectsList}
              getJiraProjects={getJiraProjects}
              getSpecializationsFilter={getSpecializationsFilter}
              getCompanyPostitionsFilter={getCompanyPostitionsFilter}
              userFilterList={userFilterList}
              onOpenConfirmModalForOwnAnswers={onOpenConfirmModalForOwnAnswers}
              isOpenConfirmForSubmitAnswers={isOpenConfirmForSubmitAnswers}
              getNotAnsweredPoll={getNotAnsweredPoll}
              setAnswersChanged={setAnswersChanged}
              setShowPrompt={setShowPrompt}
              pollGroupsScores={pollGroupsScores}
            />
            {conditionForButtons && (
              <>
                <div className="buttonsSection bottom">
                  {isAdmin && (
                    <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={handleCancel}>
                      {intl.formatMessage(messages.cancelButton)}
                    </Button>
                  )}
                  {!pollData?.anonymous && !isAdmin && (
                    <Button
                      color={'gray'}
                      externalClass={'button--modal button--cancel buttonWidth'}
                      onClick={handleSaveProgress}
                      disabled={!answersChanged}
                    >
                      {intl.formatMessage(messages.saveProgressLabel)}
                    </Button>
                  )}
                  <Button
                    externalClass={'button--modal buttonWidth'}
                    type={'submit'}
                    onClick={handleSubmitAnswers}
                    disabled={(isNotStartedOrCompletedUserPoll || isAdmin) && !answersChanged}
                  >
                    {intl.formatMessage(messages.saveAnswers)}
                  </Button>
                </div>
              </>
            )}
            {hasQuestions && <div className="noquestions">{messageWithNoQuestions}</div>}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(PollInfoContent);
