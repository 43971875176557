import React, { useMemo } from 'react';
import Select from '../../Select';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { Resume } from '../../../enums/resumes.enum';
import Icon from '../../Icon';
import TextArea from '../../TextArea';
import Button from '../../Button';
import { OptionTypeBase } from 'react-select';
import { ForeignLanguageInfoType, LanguageLevelInfoType } from '../../../types/libraries';

type AboutProps = {
  resume: Resume;
  languageLevels: LanguageLevelInfoType[];
  foreignLanguages: ForeignLanguageInfoType[];
  handleChangeLanguage: (value: OptionTypeBase, index: number) => void;
  handleChangeLanguageLevel: (value: OptionTypeBase, index: number) => void;
  removeLanguage: (index: number) => void;
  addNewLanguage: () => void;
  handleChangeSummary: (data: unknown) => void;
  handleChangePersonalHighlights: (data: unknown) => void;
  handleChangeInterestsAndHobbies: (data: unknown) => void;
};

const About = ({
  resume,
  languageLevels,
  foreignLanguages,
  handleChangeLanguage,
  handleChangeLanguageLevel,
  removeLanguage,
  addNewLanguage,
  handleChangeSummary,
  handleChangePersonalHighlights,
  handleChangeInterestsAndHobbies,
}: AboutProps) => {
  const intl = useIntl();

  const languageLevelsOptions = useMemo(() => languageLevels?.map(({ id, name }) => ({ label: name, value: id })), [
    languageLevels,
  ]);

  const foreignLanguagesOptions = useMemo(() => foreignLanguages?.map(({ id, name }) => ({ label: name, value: id })), [
    foreignLanguages,
  ]);

  return (
    <div className={'form__edit-cv-tab-content form__edit-cv-tab-content--about active'}>
      <div className="form__group">
        {resume.languages.map((item: any, index: number) => (
          <div className="form__inputs-subwrapper language_block" key={index}>
            <div className="form__inputs-subwrapper">
              <div className="form__input-block form__input-block--half">
                <Select
                  label={intl.formatMessage(messages.languageLabel)}
                  name={`languages[${index}].foreignLanguageId`}
                  options={foreignLanguagesOptions}
                  value={{
                    value: resume.languages[index].foreignLanguage?.id,
                    label: resume.languages[index].foreignLanguage?.name,
                  }}
                  handleChange={value => handleChangeLanguage(value, index)}
                  isSearchable={true}
                />
              </div>
              <div className="form__input-block form__input-block--half">
                <Select
                  label={intl.formatMessage(messages.languageLevelLabel)}
                  name={`languages[${index}].languageLevelId`}
                  options={languageLevelsOptions}
                  value={{
                    value: resume.languages[index].languageLevel?.id,
                    label: resume.languages[index].languageLevel?.name,
                  }}
                  handleChange={value => handleChangeLanguageLevel(value, index)}
                  isSearchable={true}
                  isDisabled={!resume.languages[index].foreignLanguage?.id}
                />
              </div>
            </div>
            <button
              className="form__btn-clean-inputs remove_language"
              type={'button'}
              onClick={() => removeLanguage(index)}
            >
              <Icon iconName={'cross'} />
            </button>
          </div>
        ))}
        <div className="form__input-block add_button_block">
          <Button
            type="button"
            externalClass="form__btn-add-group add_language_btn"
            color="gray"
            onClick={addNewLanguage}
          >
            <Icon iconName="plus" externalClass="form__icon-btn-add" />
            <FormattedMessage {...messages.languageLabel} />
          </Button>
        </div>
      </div>
      <div className="form__input-block">
        <TextArea
          id={'summary'}
          defaultValue={resume.summary}
          label={intl.formatMessage(messages.summaryLabel)}
          onChange={data => handleChangeSummary(data)}
        />
      </div>
      <div className="form__input-block">
        <TextArea
          id={'personalHighlights'}
          defaultValue={resume.personalHighlights}
          label={intl.formatMessage(messages.personalHighlightsLabel)}
          onChange={data => handleChangePersonalHighlights(data)}
        />
      </div>
      <div className="form__input-block">
        <TextArea
          id={'interestsAndHobbies'}
          defaultValue={resume.interestsAndHobbies}
          label={intl.formatMessage(messages.interestAndHobbiesLabel)}
          onChange={data => handleChangeInterestsAndHobbies(data)}
        />
      </div>
    </div>
  );
};

export default About;
