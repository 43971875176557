import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/policies.constants';
import * as api from '../api/policies.api';
import { setUserPolicies } from '../utils/policies.utils';
import * as paramsTypes from './../enums/params/policies.params';

function* getPolicyGroup() {
  try {
    const { success, results } = yield call(api.getPolicyGroup);
    if (success) {
      yield put({ type: ActionTypes.GET_POLICY_GROUP_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_POLICY_GROUP_FAILURE, payload: error });
  }
}

function* getUserPolicies() {
  const userId: string = yield select((state: RootState) => state.users.current.total.id);

  try {
    const { success, results } = yield call(api.getUserPolicies, userId);

    if (success) {
      yield put({ type: ActionTypes.GET_CURRENT_USER_POLICIES_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CURRENT_USER_POLICIES_FAILURE, payload: error });
  }
}

function* editUserPolicies({ payload }: AnyAction) {
  const { policyIds, callback } = payload;
  const userId: string = yield select((state: RootState) => state.users.current.total.id);

  try {
    const { success } = yield call(api.editUserPolicies, userId, policyIds);
    if (success) {
      yield put({
        type: ActionTypes.PUT_USER_POLICIES_SUCCESS,
      });
      callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_USER_POLICIES_FAILURE, payload: error });
  }
}

function* getAuthUserPolicies() {
  const userId: string = yield select((state: RootState) => state.auth.currentUserInfo.id);

  try {
    const { success, results } = yield call(api.getUserPolicies, userId);

    if (success) {
      setUserPolicies(results?.content);
      yield put({ type: ActionTypes.GET_AUTH_USER_POLICIES_SUCCESS, payload: results?.content });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_AUTH_USER_POLICIES_FAILURE, payload: error });
  }
}

function* getPolicyGroupWithUsers() {
  const params: paramsTypes.PolicyGroupParams = yield select((state: RootState) => state.policies.policyGroupParams);

  try {
    const { success, results } = yield call(api.getPolicyGroupWithUsers, params);
    if (success) {
      yield put({ type: ActionTypes.GET_POLICY_GROUP_WITH_USERS_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_POLICY_GROUP_WITH_USERS_FAILURE, payload: error });
  }
}

function* editUsersPolicies({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editUsersPolicies, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.PATCH_USERS_POLICIES_SUCCESS,
      });
      payload.callback();
      yield call(getPolicyGroupWithUsers);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PATCH_USERS_POLICIES_FAILURE, payload: error });
  }
}

function* getPolicyModule() {
  const params: paramsTypes.PolicyModuleParams = yield select((state: RootState) => state.policies.policyModuleParams);

  try {
    const { success, results } = yield call(api.getPolicyModule, params);
    if (success) {
      yield put({ type: ActionTypes.GET_POLICY_MODULE_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_POLICY_MODULE_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeEvery(ActionTypes.GET_POLICY_GROUP_REQUEST, getPolicyGroup),
    takeEvery(ActionTypes.GET_CURRENT_USER_POLICIES_REQUEST, getUserPolicies),
    takeEvery(ActionTypes.PUT_USER_POLICIES_REQUEST, editUserPolicies),
    takeEvery(ActionTypes.GET_AUTH_USER_POLICIES_REQUEST, getAuthUserPolicies),
    takeEvery(
      [ActionTypes.GET_POLICY_GROUP_WITH_USERS_REQUEST, ActionTypes.SET_POLICY_GROUP_PARAMS],
      getPolicyGroupWithUsers,
    ),
    takeEvery(ActionTypes.PATCH_USERS_POLICIES_REQUEST, editUsersPolicies),
    takeEvery(ActionTypes.SET_POLICY_MODULE_PARAMS, getPolicyModule),
  ]);
}
