import isEqual from 'lodash-es/isEqual';
import { FilterTypes } from '../constants/filters.constants';
import { FilterFieldType, StorageSavedFilterType } from '../enums/filters.enum';

export function getSavedFilters() {
  const savedFilters = localStorage.getItem('savedFilters');
  return savedFilters && JSON.parse(savedFilters);
}

export function setSavedFilterToStorage(
  filterType: FilterTypes,
  filterId: string,
  fields: { value: string; name: string }[],
) {
  const storageFilters = localStorage.getItem('savedFilters');
  const parsedStorageFilters = storageFilters ? JSON.parse(storageFilters) : [];
  const filterIndex = parsedStorageFilters.findIndex((item: { type: FilterTypes }) => item.type === filterType);

  filterIndex !== -1
    ? parsedStorageFilters.splice(filterIndex, 1, { type: filterType, id: filterId, fields })
    : parsedStorageFilters.push({ type: filterType, id: filterId, fields });

  localStorage.setItem('savedFilters', JSON.stringify(parsedStorageFilters));
}

export function removeSavedFilterFromStorage(filterType: FilterTypes) {
  const storageFilters = localStorage.getItem('savedFilters');
  const parsedStorageFilters = storageFilters ? JSON.parse(storageFilters) : [];

  const filterIndex = parsedStorageFilters.findIndex((item: { type: FilterTypes }) => item.type === filterType);

  filterIndex !== -1 && parsedStorageFilters.splice(filterIndex, 1);

  localStorage.setItem('savedFilters', JSON.stringify(parsedStorageFilters));
}

export function convertSavedFieldsToParams(fields: FilterFieldType[]) {
  const params: any = {};
  fields.map(item => (params[item.name] = JSON.parse(item.value)));
  return params;
}

export function getSavedFilterParams(savedFilters: StorageSavedFilterType[], filterType: FilterTypes) {
  const filterFields = savedFilters?.find(item => item.type === filterType)?.fields;
  return filterFields && convertSavedFieldsToParams(filterFields);
}

export function checkParamsMatch(checkedParams: any, defaultParams: any, unchecedValues?: string[]) {
  const newDefaultParams = { ...defaultParams };
  const newCheckedParams = { ...checkedParams };
  unchecedValues &&
    ['page', 'size', ...unchecedValues].map(item => {
      delete newCheckedParams[item];
      delete newDefaultParams[item];
    });

  return isEqual(newCheckedParams, newDefaultParams);
}
