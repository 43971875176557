import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  nameColumn,
  deleteButton,
  deleteModalWithItemNameTitle,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteButton,
  cancelButton,
  nameColumn,
  saveButton,
  deleteModalWithItemNameTitle,
  newPollTemolateTitle: {
    id: 'questionnaires.poll.templates.modal.new.poll.category.title.new.poll.category',
    defaultMessage: 'New Poll Category',
  },
  editPollTemolateTitle: {
    id: 'questionnaires.poll.templates.modal.edit.poll.category.title.edit.poll.category',
    defaultMessage: 'Edit Poll Category',
  },
});
