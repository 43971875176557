import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import * as usersActions from '../../actions/users.actions';
import * as filtersActions from '../../actions/filters.actions';
import Dropdown from '../../components/Dropdown';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Label from '../../components/Label';
import Avatar from '../../components/Profile/Avatar';
import ProfileVacation from '../../components/Profile/Vacation';
import ProfileContacts from '../../components/Profile/Contacts';
import ProfileTabs from '../../components/Profile/Tabs';
import ModalInfo from '../../components/Profile/Modals/ModalInfo';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import ModalEditPermissions from '../../components/Profile/Modals/ModalEditPermissions';
import ModalResetPassword from '../../components/Profile/Modals/ModalResetPassword';
import ModalChangePassword from '../../components/Profile/Modals/ModalChangePassword';
import ModalEditPositions from '../../components/Profile/Modals/ModalEditPositions';
import ModalPositionsHistory from '../../components/Profile/Modals/ModalPositionsHistory';
import { TRouteParamId } from '../../enums/common.enum';
import { getBonusesSettings } from '../../actions/bonuses.actions';
import { PhotoCropSetting } from '../../enums/users.enum';
import * as policiesActions from '../../actions/policies.actions';
import AccessChecker from '../../components/AccessChecker';
import {
  DELETE_ACTIVE_EMPLOYEE,
  UPDATE_USER,
  UPDATE_USER_EXTENDED,
  VIEW_CONTACT_INFORMATION,
  VIEW_POLICY,
} from '../../constants/policies.constants';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';
import r from '../../constants/routes.constants';
import { getPhotoData } from '../../utils';
import PortalNotification from '../../components/PortalNotification';

function UserProfile({
  getUserInfo,
  userInfo,
  updateUserInfo,
  getCompanyPostitionsFilter,
  getDepartmentsFilter,
  getOfficesFilter,
  getJiraUsers,
  companyPositionList,
  departmentList,
  officeList,
  jiraUsers,
  resetUserErrors,
  updateUserPartialInfo,
  authUserInfo,
  uploadUserPhoto,
  deleteUserPhoto,
  updateUserPassword,
  getUserPositionHistory,
  editUserPositionHistory,
  companyPositionHistory,
  getGradesFilter,
  gradeList,
  getUserPolicies,
  userPolicies,
  loading,
  errors,
  editUserPolicies,
  bonusesSettings,
  getBonusesSettings,
  getPolicyGroup,
  policyGroup,
  policyErrors,
  policiesLoading,
  resetPoliciesErrors,
  userAbsencePeriods,
  userHrInfo,
  getUserAbsencePeriods,
  resetState,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const intl = useIntl();
  const { id } = useParams<TRouteParamId>();
  const [modalInfoOpen, setModalInfoOpen] = useState(false);
  const [modalEditPermissionsOpen, setModalEditPermissionsOpen] = useState(false);
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const [modalResetPassword, setModalResetPassword] = useState(false);
  const [modaEditPositionsOpen, setModaEditPositionsOpen] = useState(false);
  const [modaPositionsHistoryOpen, setModaPositionsHistoryOpen] = useState(false);
  const history = useHistory();
  const [passwordNotificationDisplay, setPasswordNotificationDisplay] = useState(false);

  const handleUpdateUserInfo = useCallback(
    data => {
      updateUserInfo(id, data, () => setModalInfoOpen(false));
    },
    [id],
  );

  const handleChangeUserPassword = useCallback(
    data => {
      updateUserPassword({
        uuid: id,
        data,
        callback: () => {
          setModalChangePassword(false);
          setPasswordNotificationDisplay(true);
        },
      });
    },
    [id],
  );

  const handleResetUserPassword = useCallback(
    data => {
      updateUserPassword({ uuid: id, data, callback: () => setModalResetPassword(false) });
    },
    [id],
  );

  const changeActiveStatus = (active: boolean) => {
    const data = active ? { active: active } : { active: active, includedInPlanning: false };
    updateUserPartialInfo(id, data, () => getUserInfo(id));
  };

  useEffect(() => {
    checkPolicies([UPDATE_USER_EXTENDED], policies) &&
      (getCompanyPostitionsFilter(), getJiraUsers(), getDepartmentsFilter(), getOfficesFilter());
    getUserInfo(id);
    getUserPositionHistory(id);
    getBonusesSettings();
    getGradesFilter();

    return () => {
      resetState();
    };
  }, [id, policies]);

  const isCurrentUser = useMemo(() => id === authUserInfo.id, [id, authUserInfo]);
  const userActions = useMemo(
    () => [
      ...(userInfo.id !== authUserInfo.id && checkPolicies([UPDATE_USER_EXTENDED], policies)
        ? [
            {
              label: `${intl.formatMessage(messages.changePasswordButton)}`,
              handler: () => setModalResetPassword(true),
            },
          ]
        : []),
      ...(userInfo.id === authUserInfo.id && checkPolicies([UPDATE_USER_EXTENDED, UPDATE_USER], policies)
        ? [
            {
              label: `${intl.formatMessage(messages.changePasswordButton)}`,
              handler: () => setModalChangePassword(true),
            },
          ]
        : []),
      ...(!isCurrentUser && checkPolicies([VIEW_POLICY], policies)
        ? [
            {
              label: intl.formatMessage(messages.editPermissionsButton),
              handler: () => setModalEditPermissionsOpen(true),
            },
          ]
        : []),
      ...(!isCurrentUser && checkPolicies([DELETE_ACTIVE_EMPLOYEE], policies)
        ? [
            {
              label: `${
                userInfo.active
                  ? intl.formatMessage(messages.deactivateAccountLabel)
                  : intl.formatMessage(messages.activateAccountLabel)
              }`,
              handler: () => changeActiveStatus(!userInfo.active),
            },
          ]
        : []),
    ],
    [isCurrentUser, userInfo, policies],
  );

  const updateUserPhoto = useCallback(
    async (data: { file: File | undefined; cropSetting: PhotoCropSetting }, callback: () => void) => {
      const imageData = await getPhotoData(data, userInfo.photo.url);
      uploadUserPhoto(id, { file: imageData, callback });
    },
    [userInfo.photo.url],
  );

  const removeUserPhoto = (data: any) => {
    deleteUserPhoto({ uuid: id, callback: data.callback });
  };

  const goBackHandler = () => {
    history.push(r.users);
  };

  const editPositionHistory = (data: any) => {
    editUserPositionHistory({
      uuid: id,
      data: data,
      cb: () => {
        setModaEditPositionsOpen(false);
        resetUserErrors();
      },
    });
  };

  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper">
          <Button color="gray" onClick={goBackHandler}>
            <FormattedMessage {...messages.membersLabel} />
          </Button>
          <div className="page__profile-panel__label-options">
            {userInfo.showAbsences && (
              <div className="page__profile-panel__label-info">
                <div className="page__profile-panel__label-duration">{userInfo.absencePeriod}</div>
                <Label
                  type="absence"
                  externalClass="page__profile-panel__label"
                  color={userInfo.absence.eventTypeColor}
                >
                  {userInfo.absence.eventTypeName}
                </Label>
              </div>
            )}
            {!userInfo.active && (
              <div className="page__profile-panel__label-info">
                <Label type="inactive-account" size="bigger">
                  {'INACTIVE ACCOUNT'}
                </Label>
              </div>
            )}
            {!!userActions.length && (
              <Dropdown
                dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                dropdownList={userActions}
              />
            )}
          </div>
        </div>
      </div>
      <div className="page__inner-content">
        <div className="page__wrapper fixed-container">
          <div className="page__profile-info">
            <Avatar
              userInfo={userInfo}
              uploadPhoto={updateUserPhoto}
              deleteUserPhoto={removeUserPhoto}
              loading={loading.photo}
              errors={errors.photo}
              customSize={260}
              customFontSize={80}
              viewOnly={
                !(
                  checkPolicies([UPDATE_USER_EXTENDED], policies) ||
                  (checkPolicies([UPDATE_USER], policies) && authUserInfo.id === userInfo.id)
                )
              }
              fileSize={520}
            />
            <div className="page__profile-contacts-info">
              <h1 className="page__profile-name">
                {userInfo.fullName}
                {userInfo.showRecCount && (
                  <Label type="recognitions" size="bigger">
                    <Icon iconName="thumbs-up" />
                    {userInfo.recognitionsCount}
                  </Label>
                )}
              </h1>
              <div className="page__profile-post">
                <Icon iconName="id-badge" />
                {userInfo.positionName}
                <Dropdown
                  dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                  dropdownList={[
                    ...(checkPolicies([UPDATE_USER_EXTENDED], policies)
                      ? [
                          {
                            label: intl.formatMessage(messages.editPositionsLabel),
                            handler: () => setModaEditPositionsOpen(true),
                          },
                        ]
                      : []),
                    {
                      label: intl.formatMessage(messages.positionsHistoryLabel),
                      handler: () => setModaPositionsHistoryOpen(true),
                    },
                  ]}
                />
              </div>
              <div className="page__profile-department">
                <Icon iconName="building" />
                {userInfo.departmentName}
              </div>
              <div className="page__profile-office">
                <Icon iconName="map-marker" />
                {userInfo.officeName}
              </div>
              <AccessChecker verifiablePolicies={[VIEW_CONTACT_INFORMATION]}>
                <ProfileContacts userInfo={userInfo} />
              </AccessChecker>
              {id === authUserInfo.id ? (
                <AccessChecker verifiablePolicies={[UPDATE_USER, UPDATE_USER_EXTENDED]}>
                  <Button color="gray" externalClass="page__profile-button" onClick={() => setModalInfoOpen(true)}>
                    <Icon iconName="pencil" externalClass="button__icon" />
                  </Button>
                </AccessChecker>
              ) : (
                <AccessChecker verifiablePolicies={[UPDATE_USER_EXTENDED]}>
                  <Button color="gray" externalClass="page__profile-button" onClick={() => setModalInfoOpen(true)}>
                    <Icon iconName="pencil" externalClass="button__icon" />
                  </Button>
                </AccessChecker>
              )}
            </div>
            <ProfileVacation
              getAbsencePeriods={getUserAbsencePeriods}
              id={id}
              userAbsencePeriods={userAbsencePeriods}
              userHrInfo={userHrInfo}
              loading={loading.absencePeriods}
            />
          </div>
          <ProfileTabs userInfo={userInfo} bonusesSettings={bonusesSettings} />
        </div>
      </div>
      {modalInfoOpen && (
        <ModalInfo
          isOpen={modalInfoOpen}
          onClose={() => {
            setModalInfoOpen(false);
            resetUserErrors();
          }}
          onSubmit={handleUpdateUserInfo}
          userInfo={userInfo}
          departments={departmentList}
          offices={officeList}
          jiraUsers={jiraUsers}
          loading={loading.update}
          requestError={errors.update}
          editingOwnData={id === authUserInfo.id}
        />
      )}
      {modalResetPassword && (
        <ModalResetPassword
          isOpen
          loading={loading.changePassword}
          requestError={errors.changePassword}
          onClose={() => {
            setModalResetPassword(false);
            resetUserErrors();
          }}
          onSubmit={handleResetUserPassword}
        />
      )}
      {modalChangePassword && (
        <ModalChangePassword
          isOpen
          loading={loading.changePassword}
          requestError={errors.changePassword}
          onClose={() => {
            setModalChangePassword(false);
            resetUserErrors();
          }}
          onSubmit={handleChangeUserPassword}
        />
      )}
      {modaEditPositionsOpen && (
        <ModalEditPositions
          isOpen
          onClose={() => {
            setModaEditPositionsOpen(false);
            resetUserErrors();
          }}
          onSubmit={editPositionHistory}
          positions={companyPositionList}
          departments={departmentList}
          loading={loading.positionsHistory}
          requestError={errors.positionsHistory}
          grades={gradeList}
          userHistory={companyPositionHistory}
        />
      )}
      {modaPositionsHistoryOpen && (
        <ModalPositionsHistory
          isOpen={modaPositionsHistoryOpen}
          onClose={() => {
            setModaPositionsHistoryOpen(false);
            resetUserErrors();
          }}
          requestError={errors.positionsHistory}
          userHistory={companyPositionHistory}
          loading={loading.positionsHistory}
        />
      )}
      {modalEditPermissionsOpen && (
        <ModalEditPermissions
          isOpen
          onClose={() => {
            setModalEditPermissionsOpen(false);
            resetPoliciesErrors();
          }}
          getUserPolicies={getUserPolicies}
          userPolicies={userPolicies}
          loading={policiesLoading.getCurrentUserPolicies || policiesLoading.updateUserPolicies}
          editUserPolicies={editUserPolicies}
          tableError={policyErrors.getCurrentUserPolicies || policyErrors.policyGroup}
          updateError={policyErrors.updateUserPolicies}
          getPolicyGroup={getPolicyGroup}
          policyGroup={policyGroup}
          currentUserId={userInfo.id}
          officeList={officeList}
          getOfficesFilter={getOfficesFilter}
        />
      )}
      {passwordNotificationDisplay && (
        <PortalNotification
          notificationText={intl.formatMessage(messages.passwordSuccessfullyChangedText)}
          iconName="successful-notification"
          onClose={() => setPasswordNotificationDisplay(false)}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ users, auth, bonuses, policies, filters }: RootState) => ({
  userInfo: users.current.total,
  companyPositionList: filters.companyPositionsFilter.positions,
  departmentList: filters.departmentsFilter.departments,
  officeList: filters.officesFilter.offices,
  jiraUsers: users.jiraUsers,
  authUserInfo: auth.currentUserInfo,
  companyPositionHistory: users.current.companyPositionHistory,
  gradeList: filters.gradesFilter.grades,
  userPolicies: policies.currentUserPolicies?.content,
  loading: users.loading,
  errors: users.errors,
  bonusesSettings: bonuses.bonusesSettings,
  policyGroup: policies.policyGroup?.content,
  policyErrors: policies.errors,
  policiesLoading: policies.loading,
  userAbsencePeriods: users.current.absencePeriods,
  userHrInfo: users.current.professional.hrInfo,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUserInfo: (uuid: string) => dispatch(usersActions.getUserInfo(uuid)),
  getCompanyPostitionsFilter: () => dispatch(filtersActions.getCompanyPositionsFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getJiraUsers: () => dispatch(usersActions.getJiraUsers()),
  updateUserInfo: (uuid: string, data: any, cb: () => void) => dispatch(usersActions.updateUserInfo(uuid, data, cb)),
  resetUserErrors: () => dispatch(usersActions.resetUserErrors()),
  updateUserPartialInfo: (uuid: string, data: any, cb: () => void) =>
    dispatch(usersActions.updateUserPartialInfo(uuid, data, cb)),
  uploadUserPhoto: (uuid: string, data: any) => dispatch(usersActions.uploadUserPhoto(uuid, data)),
  deleteUserPhoto: (data: { uuid: string; callback: () => void }) => dispatch(usersActions.deleteUserPhoto(data)),
  updateUserPassword: (data: any) => dispatch(usersActions.updateUserPassword(data)),
  getUserPositionHistory: (id: string) => dispatch(usersActions.getUserPositionHistory(id)),
  editUserPositionHistory: (data: any) => dispatch(usersActions.editUserPositionHistory(data)),
  getGradesFilter: () => dispatch(filtersActions.getGradesFilter()),
  getUserPolicies: () => dispatch(policiesActions.getCurrentUserPolicies()),
  editUserPolicies: (policyIds: string[], callback: () => void) =>
    dispatch(policiesActions.editUserPolicies(policyIds, callback)),
  getBonusesSettings: () => dispatch(getBonusesSettings()),
  getPolicyGroup: () => dispatch(policiesActions.getPolicyGroup()),
  resetPoliciesErrors: () => dispatch(policiesActions.resetPoliciesErrors()),
  getUserAbsencePeriods: (data: any) => dispatch(usersActions.getUserAbsencePeriods(data)),
  resetState: () => dispatch(usersActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UserProfile);
