import React, { useMemo } from 'react';
import Select from '../Select';
import { useIntl } from 'react-intl';
import messages from './messages';
import { monthNames } from '../CustomDateRangePicker/utils';
import { getMonthOption, getYears } from '../../utils';
import { useFiltersListValue } from '../../utils/hooks.utils';
import moment from 'moment';

type MonthSelectInputProps = {
  year: string;
  month: string;
  addYearCount?: number;
  lengthYearList?: number;
  errorMessageYear?: string;
  errorMessageMonth?: string;
  disabledMonths?: string[];
  isLoadingMonths?: boolean;
  selectExternalClass?: string;
  isShortMonths?: boolean;
  isClearable?: boolean;
  withLabel?: boolean;
  isDisabled?: boolean;
  handleYearChange?: ({ value }: { value: number }) => void;
  handleMonthChange?: ({ value }: { value: number }) => void;
};

function MonthSelect({
  year,
  month,
  errorMessageYear,
  addYearCount = 1,
  lengthYearList = 2,
  errorMessageMonth,
  disabledMonths = [],
  isLoadingMonths,
  selectExternalClass,
  isShortMonths = false,
  isClearable = true,
  withLabel = true,
  isDisabled,
  handleYearChange,
  handleMonthChange,
}: MonthSelectInputProps) {
  const intl = useIntl();

  const yearOptions = useMemo(
    () =>
      getYears(addYearCount, lengthYearList).map(year => ({
        label: year,
        value: year,
      })),
    [addYearCount, lengthYearList],
  );

  const monthOptions = useMemo(
    () =>
      monthNames(isShortMonths).map((month, index) => {
        return getMonthOption(
          month,
          index,
          disabledMonths.some(month => moment(month).get('month') === index),
        );
      }),
    [disabledMonths],
  );

  const yearValue = useFiltersListValue(yearOptions, [year]);

  const monthValue = useFiltersListValue(monthOptions, [month]);
  return (
    <div className="form__inputs-subwrapper">
      <Select
        isClearable={isClearable}
        label={withLabel ? intl.formatMessage(messages.yearLabel) : ''}
        options={yearOptions}
        value={yearValue}
        isDisabled={isDisabled}
        handleChange={handleYearChange}
        hasError={!!errorMessageYear}
        errorMessage={errorMessageYear}
        externalClass={selectExternalClass}
      />
      <Select
        isClearable={isClearable}
        label={withLabel ? intl.formatMessage(messages.monthLabel) : ''}
        options={monthOptions}
        value={monthValue}
        handleChange={handleMonthChange}
        hasError={!!errorMessageMonth}
        errorMessage={errorMessageMonth}
        isLoading={isLoadingMonths}
        isDisabled={isLoadingMonths || isDisabled}
        externalClass={selectExternalClass}
      />
    </div>
  );
}

export default MonthSelect;
