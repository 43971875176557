export const GET_POLL_TEMPLATES_CATEGORY_LIST_REQUEST = 'GET_POLL_TEMPLATES_CATEGORY_LIST_REQUEST';
export const GET_POLL_TEMPLATES_CATEGORY_LIST_SUCCESS = 'GET_POLL_TEMPLATES_CATEGORY_LIST_SUCCESS';
export const GET_POLL_TEMPLATES_CATEGORY_LIST_FAILURE = 'GET_POLL_TEMPLATES_CATEGORY_LIST_FAILURE';

export const POST_POLL_TEMPLATES_CATEGORY_REQUEST = 'POST_POLL_TEMPLATES_CATEGORY__REQUEST';
export const POST_POLL_TEMPLATES_CATEGORY_SUCCESS = 'POST_POLL_TEMPLATES_CATEGORY_SUCCESS';
export const POST_POLL_TEMPLATES_CATEGORY_FAILURE = 'POST_POLL_TEMPLATES_CATEGORY_FAILURE';

export const DELETE_POLL_TEMPLATES_CATEGORY_REQUEST = 'DELETE_POLL_TEMPLATES_CATEGORY_REQUEST';
export const DELETE_POLL_TEMPLATES_CATEGORY_SUCCESS = 'DELETE_POLL_TEMPLATES_CATEGORY_SUCCESS';
export const DELETE_POLL_TEMPLATES_CATEGORY_FAILURE = 'DELETE_POLL_TEMPLATES_CATEGORY_FAILURE';

export const PUT_POLL_TEMPLATES_CATEGORY_REQUEST = 'PUT_POLL_TEMPLATES_CATEGORY_REQUEST';
export const PUT_POLL_TEMPLATES_CATEGORY_SUCCESS = 'PUT_POLL_TEMPLATES_CATEGORY_SUCCESS';
export const PUT_POLL_TEMPLATES_CATEGORY_FAILURE = 'PUT_POLL_TEMPLATES_CATEGORY_FAILURE';

export const SET_POLL_TEMPLATES_CATEGORY_PARAMS_REQUEST = 'SET_POLL_TEMPLATES_CATEGORY_PARAMS_REQUEST';

export const GET_POLL_TEMPLATES_LIST_REQUEST = 'GET_POLL_TEMPLATES_LIST_REQUEST';
export const GET_POLL_TEMPLATES_LIST_SUCCESS = 'GET_POLL_TEMPLATES_LIST_SUCCESS';
export const GET_POLL_TEMPLATES_LIST_FAILURE = 'GET_POLL_TEMPLATES_LIST_FAILURE';

export const POST_POLL_TEMPLATE_REQUEST = 'POST_POLL_TEMPLATE_REQUEST';
export const POST_POLL_TEMPLATE_SUCCESS = 'POST_POLL_TEMPLATE_SUCCESS';
export const POST_POLL_TEMPLATE_FAILURE = 'POST_POLL_TEMPLATE_FAILURE';

export const DELETE_POLL_TEMPLATE_REQUEST = 'DELETE_POLL_TEMPLATE_REQUEST';
export const DELETE_POLL_TEMPLATE_SUCCESS = 'DELETE_POLL_TEMPLATE_SUCCESS';
export const DELETE_POLL_TEMPLATE_FAILURE = 'DELETE_POLL_TEMPLATE_FAILURE';

export const PUT_POLL_TEMPLATE_REQUEST = 'PUT_POLL_TEMPLATE_REQUEST';
export const PUT_POLL_TEMPLATE_SUCCESS = 'PUT_POLL_TEMPLATE_SUCCESS';
export const PUT_POLL_TEMPLATE_FAILURE = 'PUT_POLL_TEMPLATE_FAILURE';

export const SET_POLL_TEMPLATES_PARAMS_REQUEST = 'SET_POLL_TEMPLATES_PARAMS_REQUEST';

export const COPY_TEMPLATE_REQUEST = 'COPY_TEMPLATE_REQUEST';
export const COPY_TEMPLATE_SUCCESS = 'COPY_TEMPLATE_SUCCESS';
export const COPY_TEMPLATE_FAILURE = 'COPY_TEMPLATE_FAILURE';

export const CHANGE_TEMPLATE_CATEGORY_REQUEST = 'CHANGE_TEMPLATE_CATEGORY_REQUEST';
export const CHANGE_TEMPLATE_CATEGORY_SUCCESS = 'CHANGE_TEMPLATE_CATEGORY_SUCCESS';
export const CHANGE_TEMPLATE_CATEGORY_FAILURE = 'CHANGE_TEMPLATE_CATEGORY_FAILURE';

export const GET_CURRENT_POLL_TEMPLATE_REQUEST = 'GET_CURRENT_POLL_TEMPLATE_REQUEST';
export const GET_CURRENT_POLL_TEMPLATE_SUCCESS = 'GET_CURRENT_POLL_TEMPLATE_SUCCESS';
export const GET_CURRENT_POLL_TEMPLATE_FAILURE = 'GET_CURRENT_POLL_TEMPLATE_FAILURE';

export const GET_CURRENT_POLL_REQUEST = 'GET_CURRENT_POLL_REQUEST';
export const GET_CURRENT_POLL_SUCCESS = 'GET_CURRENT_POLL_SUCCESS';
export const GET_CURRENT_POLL_FAILURE = 'GET_CURRENT_POLL_FAILURE';

export const GET_POLLS_LIST_REQUEST = 'GET_POLLS_LIST_REQUEST';
export const GET_POLLS_LIST_SUCCESS = 'GET_POLLS_LIST_SUCCESS';
export const GET_POLLS_LIST_FAILURE = 'GET_POLLS_LIST_FAILURE';

export const GET_MY_POLLS_LIST_REQUEST = 'GET_MY_POLLS_LIST_REQUEST';
export const GET_MY_POLLS_LIST_SUCCESS = 'GET_MY_POLLS_LIST_SUCCESS';
export const GET_MY_POLLS_LIST_FAILURE = 'GET_MY_POLLS_LIST_FAILURE';

export const SET_MY_POLLS_PARAMS = 'SET_MY_POLLS_PARAMS';

export const POST_NEW_POLL_REQUEST = 'POST_NEW_POLL_REQUEST';
export const POST_NEW_POLL_SUCCESS = 'POST_NEW_POLL_SUCCESS';
export const POST_NEW_POLL_FAILURE = 'POST_NEW_POLL_FAILURE';

export const PATCH_POLL_REQUEST = 'PATCH_POLL_REQUEST';
export const PATCH_POLL_SUCCESS = 'PATCH_POLL_SUCCESS';
export const PATCH_POLL_FAILURE = 'PATCH_POLL_FAILURE';

export const DELETE_POLL_REQUEST = 'DELETE_POLL_REQUEST';
export const DELETE_POLL_SUCCESS = 'DELETE_POLL_SUCCESS';
export const DELETE_POLL_FAILURE = 'DELETE_POLL_FAILURE';

export const SET_POLL_PARAMS = 'SET_POLL_PARAMS';

export const OPEN_CONFIRMATION_MODAL_FOR_OWN_ANSWERS = 'OPEN_CONFIRMATION_MODAL_FOR_OWN_ANSWERS';
export const CLOSE_CONFIRMATION_MODAL_FOR_OWN_ANSWERS = 'CLOSE_CONFIRMATION_MODAL_FOR_OWN_ANSWERS';

export const SET_CURRENT_TEMPLATE_CATEGORY = 'SET_CURRENT_TEMPLATE_CATEGORY';
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';

export const RESET_QUESTIONNARIES_STATE = 'RESET_QUESTIONNARIES_STATE';

export const GET_NOT_ANSWERED_POLLS_REQUEST = 'GET_NOT_ANSWERED_POLLS_REQUEST';
export const GET_NOT_ANSWERED_POLLS_SUCCESS = 'GET_NOT_ANSWERED_POLLS_SUCCESS';
export const GET_NOT_ANSWERED_POLLS_FAILURE = 'GET_NOT_ANSWERED_POLLS_FAILURE';

export const GET_ANSWERS_BY_POLL_ID_ADMIN_REQUEST = 'GET_ANSWERS_BY_POLL_ID_ADMIN_REQUEST';
export const GET_ANSWERS_BY_POLL_ID_ADMIN_SUCCESS = 'GET_ANSWERS_BY_POLL_ID_ADMIN_SUCCESS';
export const GET_ANSWERS_BY_POLL_ID_ADMIN_FAILURE = 'GET_ANSWERS_BY_POLL_ID_ADMIN_FAILURE';

export const PUT_ANSWERS_ADMIN_REQUEST = 'PUT_ANSWERS_ADMIN_REQUEST';
export const PUT_ANSWERS_ADMIN_SUCCESS = 'PUT_ANSWERS_ADMIN_SUCCESS';
export const PUT_ANSWERS_ADMIN_FAILURE = 'PUT_ANSWERS_ADMIN_FAILURE';

export const GET_USERS_BY_OFFICES_REQUEST = 'GET_USERS_BY_OFFICES_REQUEST';
export const GET_USERS_BY_OFFICES_SUCCESS = 'GET_USERS_BY_OFFICES_SUCCESS';
export const GET_USERS_BY_OFFICES_FAILURE = 'GET_USERS_BY_OFFICES_FAILURE';

export const UPDATE_OWN_POLL_ANSWERS_REQUEST = 'UPDATE_OWN_POLL_ANSWERS_REQUEST';
export const UPDATE_OWN_POLL_ANSWERS_SUCCESS = 'UPDATE_OWN_POLL_ANSWERS_SUCCESS';
export const UPDATE_OWN_POLL_ANSWERS_FAILURE = 'UPDATE_OWN_POLL_ANSWERS_FAILURE';

export const GET_ANSWERS_BY_POLL_AND_USER_REQUEST = 'GET_ANSWERS_BY_POLL_AND_USER_REQUEST';
export const GET_ANSWERS_BY_POLL_AND_USER_SUCCESS = 'GET_ANSWERS_BY_POLL_AND_USER_SUCCESS';
export const GET_ANSWERS_BY_POLL_AND_USER_FAILURE = 'GET_ANSWERS_BY_POLL_AND_USER_FAILURE';

export const SET_IS_RESULT_SUBMIT = 'SET_IS_RESULT_SUBMIT';

export const NOTE = 'NOTE';
export const DATE_PICKER = 'DATE_PICKER';
export const TIME_PICKER = 'TIME_PICKER';
export const DATE_TIME_PICKER = 'DATE_TIME_PICKER';
export const NUMBER = 'NUMBER';
export const SELECT_LIST_SINGLE_CHOICE = 'SELECT_LIST_SINGLE_CHOICE';
export const SELECT_LIST_MULTIPLE_CHOICE = 'SELECT_LIST_MULTIPLE_CHOICE';
export const TEXT = 'TEXT';

export enum TemplateDatabaseDataSource {
  USERS = 'USERS',
  SPECIALIZATIONS = 'SPECIALIZATIONS',
  PROJECTS = 'PROJECTS',
  COMPANY_POSITIONS = 'COMPANY_POSITIONS',
}

export enum TemplateDataSource {
  DATABASE = 'DATABASE',
  CUSTOM_LIST = 'CUSTOM_LIST',
}

export const PollTemplateFieldTypeOptions = [
  { label: 'Date Picker', value: DATE_PICKER },
  { label: 'Time Picker', value: TIME_PICKER },
  { label: 'Date + Time Picker', value: DATE_TIME_PICKER },
  { label: 'Number Field', value: NUMBER },
  { label: 'Single Select', value: SELECT_LIST_SINGLE_CHOICE },
  { label: 'Multiple Select', value: SELECT_LIST_MULTIPLE_CHOICE },
  { label: 'Free Text', value: TEXT },
];

export const PollTemplateListTypeOptions = [
  { label: 'Data From Database', value: TemplateDataSource.DATABASE },
  { label: 'Custom List', value: TemplateDataSource.CUSTOM_LIST },
];

export const SourcesInformationOptions = [
  { label: 'Projects', value: TemplateDatabaseDataSource.PROJECTS },
  { label: 'People', value: TemplateDatabaseDataSource.USERS },
  { label: 'Company Positions', value: TemplateDatabaseDataSource.COMPANY_POSITIONS },
  { label: 'Specializations', value: TemplateDatabaseDataSource.SPECIALIZATIONS },
];

export const RESET_QUESTIONNARIES_ERRORS = 'RESET_QUESTIONNARIES_ERRORS';

export const RESET_EVENT_POLLS = 'RESET_EVENT_POLLS';

export const RESET_EXPORT_POLL_DATA = 'RESET_EXPORT_POLL_DATA';

export const SET_IS_FROM_MY_POLLS = 'SET_IS_FROM_MY_POLLS';

export const pollsUnsavedParams = ['deadlineFrom', 'deadlineTo'];
