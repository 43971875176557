import { isEmpty } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { ActivityParams } from '../../../../enums/params/activity.params';
import { getDatesInRange } from '../../../../utils';
import { useResizeObserver, useTableData } from '../../../../utils/hooks.utils';
import HierarchicalTable from '../../../HierarchicalTable';
import { useDataForTable } from './useDataForTable';
import { countOfActivityTypeColumn, countOfTotalColumn, firstColumnWidth, minColumnWidth } from '../utils';
import { TActivityDto } from '../../../../types/activity';
import { DepthLevels } from '../../../../constants/tables.constants';

type ActivityTableProps = {
  tableData: null | TActivityDto;
  loading: boolean;
  error: null | string;
  params: ActivityParams;
};

function ActivityTable({ tableData, loading, error, params }: ActivityTableProps) {
  const ref: any = useRef(null);
  const horizontalScrollRef: any = useRef(null);
  const tableRef: any = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);
  const { width: tableOffsetWidth } = useResizeObserver(tableRef);
  const dateRange = useMemo(() => getDatesInRange(params.dateFrom, params.dateTo), [params.dateFrom, params.dateTo]);

  const tableDataWithTotal = useMemo(() => {
    const newData: any = tableData?.departments;
    if (!isEmpty(newData)) {
      newData?.push({
        totalItem: true,
        logged: tableData?.logged,
        active: tableData?.active,
        tracked: tableData?.tracked,
        submitted: tableData?.submitted,
      });
    }

    return newData;
  }, [tableData]);

  const { tableColumns, tableHeaderItems } = useDataForTable(dateRange);
  let hScroll = false;
  let tScroll = false;

  const handleHorizontalScroll = (scroll: any) => {
    const tableWrapper = ref.current;
    if (tScroll) {
      tScroll = false;
      return;
    }

    hScroll = true;

    if (tableWrapper) {
      tableWrapper.scrollLeft = scroll.target.scrollLeft;
    }
  };

  const handleScrollTable = (scroll: any) => {
    const horizontalScrollWrapper = horizontalScrollRef.current;
    if (hScroll) {
      hScroll = false;
      return;
    }

    tScroll = true;

    if (horizontalScrollWrapper) {
      horizontalScrollWrapper.scrollLeft = scroll.target.scrollLeft;
    }
  };

  const getColumnWidth = () => {
    const allColumn = dateRange.length * countOfActivityTypeColumn + countOfTotalColumn;
    const wrapperWidth = ref?.current?.clientWidth - firstColumnWidth;
    const columnWidth = wrapperWidth / allColumn;
    return columnWidth < minColumnWidth ? minColumnWidth : columnWidth;
  };

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 1) {
      return row.id.split('.')[1] % 2 === 0 ? 'even' : '';
    }
    return '';
  }, []);

  useEffect(() => {
    const scrollWrapper = ref?.current;
    if (scrollWrapper) {
      setTableWidth(scrollWrapper.scrollWidth);
    }
  }, [tableData, tableOffsetWidth]);

  return (
    <div
      className="page__scrollable-table-wrapper page_activity-table_view"
      style={{
        //@ts-ignore
        '--table-width': `${tableWidth}px`,
        '--column-width': `${getColumnWidth()}px`,
      }}
    >
      {ref?.current?.clientWidth < tableWidth && !loading && (
        <div className="horizontal-scroll-wrapper" ref={horizontalScrollRef} onScroll={handleHorizontalScroll}>
          <div className="horizontal-scroll"></div>
        </div>
      )}
      <div
        className="page__scrollable-table-wrapper__inner activity-table-wrapper"
        ref={ref}
        onScroll={handleScrollTable}
      >
        <HierarchicalTable
          tableRef={tableRef}
          tableData={useTableData(tableDataWithTotal, ['users'])}
          tableColumns={tableColumns}
          tableHeaderItems={tableHeaderItems}
          externalClass="fixed table--hierarchical table--striped activity-table"
          loading={loading}
          error={error}
          externalRowClass={handleExternalRowClass}
          disabledDepthHover={[DepthLevels.SECOND]}
        />
      </div>
    </div>
  );
}

export default ActivityTable;
