import { isEmpty } from 'lodash-es';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DATE_FORMAT } from '../../../../constants/date.constants';
import { UserInfo } from '../../../../enums/users.enum';
import { TActivityUserDto } from '../../../../types/activity';
import { getHoursAndMinutes, getUserWorkTime } from '../../../../utils/activity.utils';
import WorkChart from '../../../Activity/WorkChart';
import messages from '../../messages';
import { maxRowWithoutScroll, oneRowHeight } from './utils';

type DistributionChartProps = {
  activityData: TActivityUserDto | null;
  dateRange: string[];
  currentUser: UserInfo;
};

function DistributionChart({ activityData, dateRange, currentUser }: DistributionChartProps) {
  const userWorkTimeData = useMemo(() => {
    const today = moment();
    const start = today.startOf('d').toDate();
    const end = today.endOf('d').toDate();
    const workTimeData: any = [];
    const daysActivity = activityData?.daysActivity?.map(el => ({
      ...el,
      trackLogs: el.trackLogs.filter(trackLog => trackLog.logLength >= 1),
    }));

    dateRange.forEach((day, index) => {
      const activity = daysActivity?.find(el => el.day === day);
      const loggedTrackedContent = `(${getHoursAndMinutes(activity?.logged)})`;
      const title = `${moment(day).format(DATE_FORMAT.MMM_DD_YYYY)} ${loggedTrackedContent}`;
      const userWorkTime = getUserWorkTime(activity, title, true);

      if (index === 0) {
        userWorkTime.push([title, '', '', 'opacity: 0', start, start, '']);
      }
      if (index === dateRange.length - 1) {
        userWorkTime.push([title, '', '', 'opacity: 0', end, end, '']);
      }
      if (!isEmpty(activity?.trackLogs)) {
        workTimeData.push(...userWorkTime);
      } else {
        workTimeData.push([title, '', '', 'opacity: 0', start, end, '']);
      }
    });

    return workTimeData;
  }, [activityData, dateRange, currentUser]);

  return (
    <div className="tabs__content-work-wrapper">
      <p className="tabs__content-item__title">
        <FormattedMessage {...messages.distributionLabel} />
      </p>
      <div
        className="tabs__content-distribution-wrapper"
        style={{
          //@ts-ignore
          '--under-line-top': `${dateRange.length * oneRowHeight}px`,
        }}
      >
        <WorkChart
          userWorkTime={userWorkTimeData}
          isActivityPage={false}
          customNode={dateRange.length < maxRowWithoutScroll}
        />
      </div>
    </div>
  );
}

export default React.memo(DistributionChart);
