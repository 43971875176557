import React, { useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IncomesTotalOptions } from '../../../constants/finance.constants';
import { ExpensesReportItem, IncomesReportItem } from '../../../enums/finance/finance.enum';
import { useTableData } from '../../../utils/hooks.utils';
import HierarchicalTable from '../../HierarchicalTable';
import Table from '../../Table';
import messages from '../messages';
import { useDataForExpensesTable } from './useDataForExpensesTable';
import { useDataForIncomesTable } from './useDataForIncomesTable';
import { CurrencyType } from '../../../types/finance';
import { DepthLevels } from '../../../constants/tables.constants';

type TabelsLayoutProps = {
  tableData: any;
  errors: string | null;
  isLoading: boolean;
  isShowSalaries: boolean;
  baseCurrency: CurrencyType | undefined;
};

const TabelsLayout = ({ tableData, errors, isLoading, isShowSalaries, baseCurrency }: TabelsLayoutProps) => {
  const intl = useIntl();

  const incomes = useMemo(() => {
    if (tableData?.incomes.length) {
      const newData = [...tableData.incomes];

      if (tableData?.incomes.length) {
        newData.push(
          new IncomesReportItem({
            entries: tableData.totalMonthIncomes,
            totalItem: IncomesTotalOptions.PLANNED_TOTAL,
          }),
        );

        newData.push(
          new IncomesReportItem({
            entries: tableData.totalMonthIncomes,
            totalItem: IncomesTotalOptions.ACTUAL_TOTAL,
          }),
        );
      }

      return newData;
    }
    return [];
  }, [tableData]);

  const { incomesTableColumns, incomesHeaderItems } = useDataForIncomesTable(incomes, baseCurrency);

  const expenses = useMemo(() => {
    if (tableData?.expenses.length || tableData?.salaries.length) {
      const newData = [...tableData.expenses];

      if (isShowSalaries && tableData.salaries.length) {
        newData.unshift(
          new ExpensesReportItem({
            entries: tableData.salaries.map((salary: { month: string; total: number }) => ({
              amount: salary.total,
              month: salary.month,
            })),
            expenseType: { name: intl.formatMessage(messages.salariesLabel) },
          }),
        );

        newData.push(
          new ExpensesReportItem({
            entries: tableData.totalMonthExpenses.length
              ? tableData.totalMonthExpenses.map((item: { amount: number; month: string }) => item)
              : tableData.salaries.map((salary: { month: string; total: number }) => ({
                  amount: salary.total,
                  month: salary.month,
                })),
            totalItem: true,
          }),
        );
      } else {
        tableData?.expenses.length &&
          newData.push(
            new ExpensesReportItem({
              entries: tableData.totalMonthExpenses,
              totalItem: true,
            }),
          );
      }

      return newData;
    }
    return [];
  }, [tableData, isShowSalaries]);

  const { expensesTableColumns, expensesHeaderItems } = useDataForExpensesTable(expenses, baseCurrency);

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 1) {
      return row.id.split('.')[1] % 2 === 0 ? 'even' : '';
    }
    return '';
  }, []);

  return (
    <div>
      <div>
        <h2 className="tabel-title">
          <FormattedMessage {...messages.incomesTitle} />
        </h2>
        <div className="page__scrollable-table-wrapper">
          <div className="page__scrollable-table-wrapper__inner expense-report-table-wrapper">
            <HierarchicalTable
              tableColumns={incomesTableColumns}
              loading={isLoading}
              error={errors}
              tableData={useTableData(incomes, ['reportParts'])}
              externalClass={'tablel expense-report-table incomes-report-table'}
              tableHeaderItems={incomesHeaderItems}
              externalRowClass={handleExternalRowClass}
              disabledDepthHover={[DepthLevels.SECOND]}
            />
          </div>
        </div>
      </div>
      <div>
        <h2 className="tabel-title expenses">
          <FormattedMessage {...messages.expensesTitle} />
        </h2>
        <div className="page__scrollable-table-wrapper">
          <div className="page__scrollable-table-wrapper__inner expense-report-table-wrapper">
            <Table
              externalClass={'tablel expense-report-table'}
              tableColumns={expenses.length ? expensesTableColumns : []}
              tableData={expenses}
              loading={isLoading}
              error={errors}
              tableActions={[]}
              tableHeaderItems={expenses.length ? expensesHeaderItems : []}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabelsLayout;
