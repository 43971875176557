import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useFiltersListValue, useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { Candidate, CandidateStatus, CANDIDATE_VALIDATION_SCHEMA } from '../../../enums/candidates.enums';
import Input from '../../Input';
import Select, { CreatableOptionTypeBase } from '../../Select';
import * as candidateTypes from '../../../types/candidates';
import { getFormattedStatusOptionLabel, useModalOptions } from '../useModalsData';
import ChipInput from '../../ChipInput';
import Checkbox from '../../Checkbox';
import { OptionTypeBase } from 'react-select';
import { scrollToError } from '../../../utils';

type ModalEditCandidateProps = {
  candidate: Candidate;
  isOpen: boolean;
  isInfoModal?: boolean;
  isAboutModal?: boolean;
  isLoading: boolean;
  error: string | RejectValueErrors[] | null;
  candidateStatuses: CandidateStatus[];
  candidateTechnologies: candidateTypes.CandidateTechnologyType[];
  candidateSpecializations: candidateTypes.CandidateSpecializationType[];
  candidateReceivingSources: candidateTypes.CandidateReceivingSourceType[];
  editCandidate: (data: { data: Candidate; callback: () => void }) => void;
  onCloseRequest: () => void;
};

function ModalEditCandidate({
  candidate,
  isOpen,
  isLoading,
  isInfoModal = false,
  isAboutModal = false,
  error,
  candidateStatuses,
  candidateTechnologies,
  candidateSpecializations,
  candidateReceivingSources,
  editCandidate,
  onCloseRequest,
}: ModalEditCandidateProps) {
  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    resetForm,
    setFieldError,
  } = useFormik<Candidate>({
    initialValues: new Candidate(candidate),
    validateOnChange: false,
    enableReinitialize: true,
    validate: scrollToError,
    validationSchema: CANDIDATE_VALIDATION_SCHEMA,
    onSubmit: data => {
      editCandidate({
        data,
        callback: resetAndExit,
      });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const {
    candidateStatusesOptions,
    candidateTechnologiesOptions,
    candidateSpecializationsOptions,
    candidateReceivingSourcesOptions,
    monthOptions,
    yearOptions,
  } = useModalOptions(
    candidateStatuses,
    candidateTechnologies,
    candidateSpecializations,
    candidateReceivingSources,
    values.year,
  );

  const emailsValue = useMemo(() => values.emails.map(email => ({ label: email, value: email })), [values]);

  const phonesValue = useMemo(() => values.phones.map(phone => ({ label: phone, value: phone })), [values.phones]);

  const receivingSourceValue = useMemo(
    () => candidateReceivingSourcesOptions.find(opt => opt.value === values.receivingSourceId) || null,
    [values.receivingSourceId],
  );

  const statusValue = useMemo(() => candidateStatusesOptions.find(opt => opt.value.id === values.statusId) || null, [
    values.statusId,
  ]);

  const yearValue = useFiltersListValue(yearOptions, [values.year]);

  const monthValue = useFiltersListValue(monthOptions, [values.month]);

  const specializationsValue = useFiltersListValue(
    candidateSpecializationsOptions,
    values.specializations.map((item: { id: string | null; name: string }) => item.id || ''),
  );

  const technologiesValue = useFiltersListValue(
    candidateTechnologiesOptions,
    values.technologies.map((item: { id: string | null; name: string }) => item.id || ''),
  );

  const handleStatusChange = useCallback((newValue: OptionTypeBase) => {
    setFieldValue('statusId', newValue.value?.id || null);
  }, []);

  const handleEmailsChange = useCallback(newValues => {
    setFieldValue(
      'emails',
      newValues.map(({ label }: { label: string }) => label),
    );
  }, []);

  const handlePhonesChange = useCallback(newValues => {
    setFieldValue(
      'phones',
      newValues.map(({ label }: { label: string }) => label),
    );
  }, []);

  const handleYearChange = useCallback(
    ({ value }: { value: number }) => {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      if (value == year && Number(values.month) > month) {
        setFieldValue('month', '');
      }
      setFieldValue('year', value);
    },
    [values.month],
  );

  const handleMonthChange = useCallback(({ value }: { value: number }) => {
    setFieldValue('month', value);
  }, []);

  const handleSpecializationsChange = useCallback((newValues: CreatableOptionTypeBase[]) => {
    setFieldValue(
      'specializations',
      newValues.map(({ value, label, __isNew__ }) => ({ name: label, id: __isNew__ ? null : value })),
    );
  }, []);

  const handleTechnologiesChange = useCallback((newValues: CreatableOptionTypeBase[]) => {
    setFieldValue(
      'technologies',
      newValues.map(({ value, label, __isNew__ }) => ({ name: label, id: __isNew__ ? null : value })),
    );
  }, []);

  const handleReceivingSourceChange = useCallback((newValue: OptionTypeBase) => {
    setFieldValue('receivingSourceId', newValue.value);
  }, []);

  const formatStatusOptionLabel = useCallback(getFormattedStatusOptionLabel, []);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const emailsError = useMemo(() => (errors?.emails as string[])?.reduce((acc, val) => acc || val, ''), [
    errors?.emails,
  ]);

  const phonesError = useMemo(() => (errors?.phones as string[])?.reduce((acc, val) => acc || val, ''), [
    errors?.phones,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.editCandidateTitle)}
      classNameModal="center flex"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          {!isInfoModal && !isAboutModal && (
            <div className="form__group">
              <Select
                isClearable
                label={intl.formatMessage(messages.statusLabel)}
                options={candidateStatusesOptions}
                value={statusValue}
                handleChange={handleStatusChange}
                formatOptionLabel={formatStatusOptionLabel}
              />
            </div>
          )}
          {!isAboutModal && (
            <>
              <div className="form__group">
                <div className="form__inputs-subwrapper">
                  <Input
                    id="firstName"
                    name="firstName"
                    label={intl.formatMessage(messages.firstNameLabel)}
                    defaultValue={values.firstName}
                    maxLength={20}
                    onChange={handleChange}
                    hasError={hasError('firstName')}
                    errorMessage={errors.firstName}
                  />
                  <Input
                    id="lastName"
                    name="lastName"
                    label={intl.formatMessage(messages.secondNameLabel)}
                    defaultValue={values.lastName}
                    maxLength={30}
                    onChange={handleChange}
                    hasError={hasError('lastName')}
                    errorMessage={errors.lastName}
                  />
                </div>
                <Input
                  id="alternativeName"
                  name="alternativeName"
                  label={intl.formatMessage(messages.alternativeNameLabel)}
                  defaultValue={values.alternativeName}
                  onChange={handleChange}
                  hasError={hasError('alternativeName')}
                  errorMessage={errors.alternativeName}
                />
              </div>
              <div className="form__group">
                <Input
                  id="location"
                  name="location"
                  label={intl.formatMessage(messages.locationLabel)}
                  iconName="map-marker"
                  defaultValue={values.location}
                  onChange={handleChange}
                  hasError={hasError('location')}
                  errorMessage={errors.location}
                />
              </div>
              <div className="form__group">
                <ChipInput
                  label={intl.formatMessage(messages.emailsLabel)}
                  iconName="envelope"
                  value={emailsValue}
                  handleChange={handleEmailsChange}
                  errorMessage={emailsError}
                  hasError={hasError('emails')}
                />
                <ChipInput
                  label={intl.formatMessage(messages.phonesLabel)}
                  iconName="phone"
                  externalClass="page__profile-contact--phones"
                  value={phonesValue}
                  handleChange={handlePhonesChange}
                  errorMessage={phonesError}
                  hasError={hasError('phones')}
                />
              </div>
              <div className="form__group">
                <div className="form__note form__input-block">
                  <FormattedMessage {...messages.careerStartLabel} />
                </div>
                <div className="form__inputs-subwrapper">
                  <Select
                    isClearable
                    label={intl.formatMessage(messages.yearLabel)}
                    options={yearOptions}
                    value={yearValue}
                    handleChange={handleYearChange}
                    hasError={hasError('carrierStart') || hasError('year')}
                    errorMessage={errors.carrierStart || errors.year}
                  />
                  <Select
                    isClearable
                    label={intl.formatMessage(messages.monthLabel)}
                    options={monthOptions}
                    value={monthValue}
                    handleChange={handleMonthChange}
                    hasError={hasError('carrierStart') || hasError('month')}
                    errorMessage={errors.carrierStart || errors.month}
                  />
                  <div className="form__input-block align-center">
                    <Checkbox
                      label={intl.formatMessage(messages.leadExpLabel)}
                      id="hasLeadExperience"
                      checked={values.hasLeadExperience}
                      onChange={handleChange}
                      externalClass="form__checkbox-capitalazie-label"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {!isInfoModal && (
            <>
              <div className="form__group">
                <Select
                  isCreatable
                  isMulti
                  label={intl.formatMessage(messages.specializationsLabel)}
                  options={candidateSpecializationsOptions}
                  defaultValue={specializationsValue}
                  handleChange={handleSpecializationsChange}
                  hasError={hasError('specializations')}
                  //@ts-ignore
                  errorMessage={errors.specializations}
                />
                <Select
                  isCreatable
                  isMulti
                  label={intl.formatMessage(messages.technologiesLabel)}
                  options={candidateTechnologiesOptions}
                  defaultValue={technologiesValue}
                  handleChange={handleTechnologiesChange}
                  hasError={hasError('technologies')}
                  //@ts-ignore
                  errorMessage={errors.technologies}
                />
              </div>
              <div className="form__group">
                <Input
                  label={intl.formatMessage(messages.previousEmploymentLabel)}
                  id="previousEmployments"
                  name="previousEmployments"
                  tag="textarea"
                  defaultValue={values.previousEmployments}
                  onChange={handleChange}
                  hasError={hasError('previousEmployments')}
                  errorMessage={errors.previousEmployments}
                />
              </div>
              <div className="form__group">
                <Input
                  label={intl.formatMessage(messages.linksLabel)}
                  id="links"
                  name="links"
                  tag="textarea"
                  defaultValue={values.links}
                  onChange={handleChange}
                  hasError={hasError('links')}
                  errorMessage={errors.links}
                />
              </div>
            </>
          )}
          {!isAboutModal && (
            <div className="form__group">
              <Select
                isClearable
                label={intl.formatMessage(messages.receivingSourceLabel)}
                options={candidateReceivingSourcesOptions}
                value={receivingSourceValue}
                handleChange={handleReceivingSourceChange}
                hasError={hasError('receivingSourceId')}
                errorMessage={errors.receivingSourceId}
              />
            </div>
          )}
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditCandidate;
