import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import DurationPicker from '../../Dropdown';
import Icon from '../../Icon';
import InlineDatePicker from '../../InlineDatePicker';
import messages from '../messages';
import tableTitleMessages from '../../../pages/Schedule/messages';
import * as filtersActions from '../../../actions/filters.actions';
import * as scheduleActions from '../../../actions/schedule.actions';
import { getUserAbsencePeriods, getUserInfo } from '../../../actions/users.actions';
import { handleRenderDropdownListBtn, useAbleToManageEventTypes } from '../../../utils/schedule.utils';
import { EventTypeInfo } from '../../../enums/calendar.enum';
import { EventPreviewInfo } from '../../../enums/schedule.enum';
import EventsListLayout from '../../Schedule/Layouts/EventsList';
import { NavLink, useParams } from 'react-router-dom';
import r from '../../../constants/routes.constants';
import ModalNewEvent from '../../Schedule/Modals/ModalNewEvent';
import ModalEditEvent from '../../Schedule/Modals/ModalEditEvent';
import ModalDeleteEvent from '../../Schedule/Modals/ModalDeleteEvent';
import Filter from '../../Filter/index';
import { SortParams } from '../../../enums/params.enum';
import { AssessmentsParams } from '../../../enums/params/competencies.params';
import { EventsParams } from '../../../enums/params/schedule.params';
import { TRouteParamId } from '../../../enums/common.enum';
import AccessChecker from '../../AccessChecker';
import { UPDATE_EVENT, UPDATE_EVENT_EXTENDED, UPDATE_PUBLIC_EVENT } from '../../../constants/policies.constants';
import { PollParams } from '../../../enums/params/questionnaires.params';
import { useFiltersListValue, useParamsChange } from '../../../utils/hooks.utils';
import { FilterParamsName } from '../../../constants/filters.constants';
import PoliciesContext from '../../../PoliciesContext';
import { checkPolicies } from '../../../utils/policies.utils';
import moment from 'moment';
import { isEmpty } from 'lodash-es';

function ProfileSchedule({
  eventTypesFilter,
  eventTableData,
  eventError,
  loading,
  currentUserInfo,
  userInfo,
  userList,
  officeList,
  eventType,
  params,
  assessmentList,
  assessmentsLoading,
  pollList,
  eventData,
  getEventTypesFilter,
  getEventsList,
  getUsersFilter,
  resetEventErrors,
  resetEventType,
  setEventParams,
  getEventType,
  createNewEvent,
  editEvent,
  deleteEvent,
  getAssessmentsFilter,
  resetAssessmentsFilter,
  resetErrors,
  getUserInfo,
  resetState,
  getPollsFilter,
  getEvent,
  resetEvent,
  getUserAbsencePeriods,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { id } = useParams<TRouteParamId>();
  const userPolicies = useContext(PoliciesContext);

  const [modalNewEventIsOpen, setModalNewEventIsOpen] = useState(false);
  const [modalEditEventIsOpen, setModalEditEventIsOpen] = useState(false);
  const [modalDeleteEventIsOpen, setModalDeleteEventIsOpen] = useState(false);
  const [updateNextModal, setUpdateNextModal] = useState(false);

  const openUpdateNextModal = useCallback(() => {
    setUpdateNextModal(true);
  }, []);

  const closeUpdateNextModal = useCallback(() => {
    updateNextModal && resetEventErrors();
    setUpdateNextModal(false);
  }, [updateNextModal]);

  const handleSort = useCallback((sortBy, direction) => setEventParams({ sortBy, direction }), []);

  const openNewEventModal = useCallback(() => {
    setModalNewEventIsOpen(true);
  }, []);

  const closeNewEventModal = useCallback(() => {
    modalNewEventIsOpen && resetEventType() && resetEventErrors() && resetAssessmentsFilter();
    setModalNewEventIsOpen(false);
  }, [modalNewEventIsOpen]);

  const openEditEventModal = useCallback(() => {
    modalEditEventIsOpen;
    setModalEditEventIsOpen(true);
  }, [modalEditEventIsOpen]);

  const closeEditEventModal = useCallback(() => {
    !updateNextModal && modalEditEventIsOpen && resetEventErrors() && resetAssessmentsFilter() && resetEvent();
    !updateNextModal && setModalEditEventIsOpen(false);
  }, [modalEditEventIsOpen, updateNextModal]);

  const openDeleteEventModal = useCallback(() => {
    setModalDeleteEventIsOpen(true);
  }, []);

  const closeDeleteEventModal = useCallback(() => {
    modalDeleteEventIsOpen && resetEventErrors() && resetEvent();
    setModalDeleteEventIsOpen(false);
  }, [modalDeleteEventIsOpen]);

  const handlePageChange = useCallback(({ selected }) => {
    setEventParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setEventParams({ size: data, page: 0 });
  }, []);

  const handleMultiParamsChange = useParamsChange(setEventParams, dispatch);

  useEffect(() => {
    setEventParams({ eventTypes: eventTypesFilter.value, isAllowPreviews: true, publicAllowed: false });
    getEventTypesFilter();
    getUsersFilter();
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    params.users[0] !== id &&
      setEventParams({
        users: [id],
        page: 0,
      });
  }, [id]);

  const ableToEditEventTypes = useAbleToManageEventTypes(eventTypesFilter.eventTypes, userInfo);

  const ableToCreateEventTypes = useAbleToManageEventTypes(eventTypesFilter.eventTypes, userInfo, true);

  const renderDropdownBtn = useCallback(
    (row: EventPreviewInfo, isUpdateBtn: boolean) =>
      handleRenderDropdownListBtn(
        row,
        ableToEditEventTypes,
        userInfo,
        userPolicies,
        isUpdateBtn,
        eventTypesFilter.eventTypes,
      ),
    [ableToEditEventTypes, userInfo, eventTypesFilter.eventTypes],
  );

  const newEventAction = useMemo(() => {
    const eventTypes =
      checkPolicies([UPDATE_EVENT_EXTENDED], userPolicies) || userInfo.id === currentUserInfo.id
        ? ableToEditEventTypes
        : ableToCreateEventTypes;

    return eventTypes.map(({ id, name }: Pick<EventTypeInfo, 'id' | 'name'>) => ({
      id,
      label: name,
      handler: () => {
        openNewEventModal();
        getEventType(id);
      },
    }));
  }, [ableToCreateEventTypes, ableToEditEventTypes, userInfo, currentUserInfo]);

  const eventTypesOptions = useMemo(
    () =>
      eventTypesFilter.eventTypes?.map((eventType: EventTypeInfo) => ({
        label: eventType.name,
        value: eventType.id,
      })),
    [eventTypesFilter.eventTypes],
  );

  const eventTypesValues = useFiltersListValue(eventTypesOptions, params.eventTypes);

  const handleDataChange = useCallback(
    (start: string, end: string) => {
      currentUserInfo.id &&
        setEventParams({
          dateTimeFrom: moment(start).format(),
          dateTimeTo: moment(end).format(),
          users: [currentUserInfo.id],
          page: 0,
        });
    },
    [currentUserInfo.id],
  );

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(tableTitleMessages.typeLabel),
        modifier: (row: EventPreviewInfo) => {
          const linkContent = (
            <div className={'table__data-wrapper'}>
              <div className={'table__data-no-image'} style={{ backgroundColor: row.eventTypeColor }} />
              <span className={'table_button'}>{row.eventTypeName}</span>
            </div>
          );
          return row.isUserHaveAccess ? (
            <NavLink className={'table__type-link'} to={r.eventInformation.replace(':id', `${row.id}`)}>
              {linkContent}
            </NavLink>
          ) : (
            <div className={'table__type-link'}>{linkContent}</div>
          );
        },
      },
      {
        name: intl.formatMessage(tableTitleMessages.titleLabel),
        modifier: (row: EventPreviewInfo) => row.title,
      },
      {
        name: intl.formatMessage(tableTitleMessages.datesLabel),
        sortName: 'startDate',
        modifier: (row: EventPreviewInfo) => row.datePeriod,
      },
      {
        name: intl.formatMessage(tableTitleMessages.optionsLabel),
        modifier: (row: EventPreviewInfo) =>
          !isEmpty(row.eventOptions) && (
            <div className="page__event__option-wrapper">
              {row.eventOptions.map((option, index) => (
                <span className="page__event__option" key={index}>
                  {option}
                </span>
              ))}
            </div>
          ),
      },
    ],
    [],
  );

  const sortParams = useMemo(() => new SortParams('startDate', params), [params]);

  const getAbsencePeriods = useCallback(() => {
    const currentDate = moment();
    getUserAbsencePeriods({
      id,
      data: {
        dateTimeFrom: currentDate.startOf('year').format(),
        dateTimeTo: currentDate.endOf('year').format(),
      },
    });
  }, [id]);

  return (
    <>
      <div className="tabs__content-item active tabs__content-item__wrapper--schedule">
        <div className="tabs__content-item__title">
          <FormattedMessage {...messages.scheduleTitle} />
        </div>
        <div className="page__panel__filters tabs__content-item__filters">
          <AccessChecker verifiablePolicies={[UPDATE_EVENT_EXTENDED, UPDATE_EVENT, UPDATE_PUBLIC_EVENT]}>
            {!!newEventAction.length && (
              <DurationPicker
                dropdownToggle={
                  <>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                    <Icon iconName={'select-arrow'} externalClass={'button__icon select-arrow'} />
                  </>
                }
                dropdownList={newEventAction}
                placement="bottom-start"
                customBtn
                dropdownToggleClass="btn-select"
              />
            )}
          </AccessChecker>
          <Filter
            isMulti
            label={intl.formatMessage(tableTitleMessages.eventTypesLabel)}
            options={eventTypesOptions}
            value={eventTypesValues}
            handleChange={handleMultiParamsChange(FilterParamsName.EVENT_TYPES)}
            externalClass="filters__select"
          />
        </div>
        <div className="pagination tabs__top-right-block">
          <InlineDatePicker
            onDateChange={handleDataChange}
            defaultPeriodStart={params.dateTimeFrom}
            defaultPeriodEnd={params.dateTimeTo}
          />
        </div>
        {((eventTableData && !eventTableData?.empty) || loading.getEventList) && (
          <EventsListLayout
            isPaginated
            eventTableData={eventTableData}
            getEvent={getEvent}
            openEditEventModal={openEditEventModal}
            openDeleteEventModal={openDeleteEventModal}
            handlePageChange={handlePageChange}
            handleSizeChange={handleSizeChange}
            handleSort={handleSort}
            renderDropdownBtn={renderDropdownBtn}
            sortParams={sortParams}
            loading={loading.getEventList}
            error={eventError.eventListError}
            ableToManageEventTypes={ableToEditEventTypes}
            tableColumns={tableColumns}
            userInfo={userInfo}
          />
        )}
        {eventTableData && eventTableData.empty && !loading.getEventList && (
          <div className="tabs__no-content">
            <p className="tabs__no-content-title">
              <FormattedMessage {...messages.scheduleNoContent} />
            </p>
          </div>
        )}
      </div>
      {modalNewEventIsOpen && (
        <ModalNewEvent
          isOpen
          openFromUserProfile
          eventError={eventError.eventError}
          isLoading={loading}
          offices={officeList}
          users={userList}
          assessmentsData={assessmentList}
          eventType={eventType}
          eventTypesData={userInfo.id === currentUserInfo.id ? ableToEditEventTypes : ableToCreateEventTypes}
          assessmentsLoading={assessmentsLoading}
          polls={pollList}
          authUser={userInfo}
          currentUser={currentUserInfo}
          userPolicies={userPolicies}
          allEventTypes={eventTypesFilter.eventTypes}
          getPollsFilter={getPollsFilter}
          onCloseRequest={closeNewEventModal}
          getEventType={getEventType}
          resetErrors={resetErrors}
          getAssessmentsFilter={getAssessmentsFilter}
          resetAssessmentsFilter={resetAssessmentsFilter}
          createNewEvent={(data: any) => createNewEvent({ ...data, isTabRequest: true })}
          cb={() => {
            getUserInfo(id);
            setEventParams({
              users: [currentUserInfo.id],
              page: 0,
            });
            getAbsencePeriods();
          }}
        />
      )}
      {modalEditEventIsOpen && (
        <ModalEditEvent
          isOpen
          openFromUserProfile
          eventError={eventError.eventError}
          eventType={eventType}
          isLoading={loading}
          offices={officeList}
          users={userList}
          eventTypesData={ableToEditEventTypes}
          eventData={eventData}
          isOpenUpdateNextModal={updateNextModal}
          assessmentsData={assessmentList}
          assessmentsLoading={assessmentsLoading}
          polls={pollList}
          authUser={userInfo}
          userPolicies={userPolicies}
          allEventTypes={eventTypesFilter.eventTypes}
          getPollsFilter={getPollsFilter}
          getAssessmentsFilter={getAssessmentsFilter}
          getEventType={getEventType}
          openUpdateNextModal={openUpdateNextModal}
          closeUpdateNextModal={closeUpdateNextModal}
          resetErrors={resetErrors}
          onCloseRequest={closeEditEventModal}
          onEditRequest={(data: any) => {
            editEvent({
              ...data,
              getEventsList,
            });
          }}
          cb={() => {
            getUserInfo(id);
            setEventParams({
              users: [currentUserInfo.id],
              page: 0,
            });
            getAbsencePeriods();
          }}
        />
      )}
      {modalDeleteEventIsOpen && (
        <ModalDeleteEvent
          isOpen
          eventData={eventData}
          eventError={eventError.eventError}
          isLoading={loading}
          authUserId={userInfo.id}
          onCloseRequest={closeDeleteEventModal}
          onDeleteRequest={(data: any) => {
            deleteEvent({
              ...data,
              getEventsList: getEventsList,
            });
          }}
          resetErrors={resetErrors}
          cb={() => {
            getUserInfo(id);
            setEventParams({
              users: [currentUserInfo.id],
              page: 0,
            });
            getAbsencePeriods();
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ filters, users, schedule, auth }: RootState) => ({
  eventTypesFilter: filters.eventTypesFilter,
  eventTableData: schedule.eventTableData,
  eventData: schedule.eventData,
  eventType: schedule.eventType,
  currentUserInfo: users.current.total,
  userInfo: auth.currentUserInfo,
  eventError: schedule.errors,
  loading: schedule.loading,
  userList: filters.usersFilter.users,
  officeList: filters.officesFilter.offices,
  params: schedule.userScheduleTabParams,
  assessmentList: filters.assessmentsFilter.assessments,
  assessmentsLoading: filters.assessmentsFilter.loading,
  pollList: filters.pollsFilter.polls,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEventTypesFilter: () => dispatch(filtersActions.getEventTypesFilter()),
  getEventsList: () => dispatch(scheduleActions.getEventsList()),
  getEvent: (id: any) => dispatch(scheduleActions.getEvent(id)),
  resetEvent: () => dispatch(scheduleActions.resetEvent()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  resetEventErrors: () => dispatch(scheduleActions.resetEventErrors()),
  resetEventType: () => dispatch(scheduleActions.resetEventType()),
  setEventParams: (data: Partial<EventsParams>) => dispatch(scheduleActions.setUserScheduleTabParams(data)),
  getEventType: (id: string) => dispatch(scheduleActions.getEventType(id)),
  getAssessmentsFilter: (data: Partial<AssessmentsParams>) => dispatch(filtersActions.getAssessmentsFilter(data)),
  resetAssessmentsFilter: () => dispatch(filtersActions.resetAssessmentsFilter()),
  createNewEvent: (data: any) => dispatch(scheduleActions.createNewEvent(data)),
  deleteEvent: (data: any) => dispatch(scheduleActions.deleteEvent(data)),
  editEvent: (data: any) => dispatch(scheduleActions.editEventType(data)),
  getUserInfo: (uuid: string) => dispatch(getUserInfo(uuid)),
  getPollsFilter: (data: Partial<PollParams>) => dispatch(filtersActions.getPollsFilter(data)),
  getUserAbsencePeriods: (data: any) => dispatch(getUserAbsencePeriods(data)),
  resetErrors: () => dispatch(scheduleActions.resetErrors()),
  resetState: () => dispatch(scheduleActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfileSchedule);
