import React, { useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Icon from '../Icon';
import CustomLoader from '../Loader';
import Select from '../Select';
import BrandingContext from '../../BrandingContext';
import { isNil } from 'lodash-es';
import { useIntl } from 'react-intl';
import messages from './messages';

type PaginationProps = {
  pageable: {
    offset: number;
    empty: boolean;
    totalPages: number;
    totalElements: number;
    numberOfElements: number;
    pageSize?: number;
    pageNumber?: number;
  };
  onPageChange: (data: { selected: number }) => void;
  onPageSizeChange: (value: number) => void;
  isLoading?: boolean;
};

const PAGE_SIZES = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
];

const minTotalElementsForPagination = 10;

function Pagination({ pageable, onPageChange, onPageSizeChange, isLoading }: PaginationProps) {
  const intl = useIntl();
  const { offset, empty, totalPages, totalElements, numberOfElements, pageSize } = pageable;
  const [pageNumberPagination, setPageNumberPagination] = useState(pageable.pageNumber);
  const branding = useContext(BrandingContext);
  useEffect(() => {
    setPageNumberPagination(pageable.pageNumber);
  }, [pageable.pageNumber]);

  return !empty &&
    !isNil(offset) &&
    !isNil(numberOfElements) &&
    !isNil(totalElements) &&
    totalElements > minTotalElementsForPagination ? (
    <div
      className="table__pagination"
      style={{
        // @ts-ignore
        '--global-color': branding?.globalAccents,
      }}
    >
      <div className="table__pagination-pages">
        {isLoading ? (
          <CustomLoader />
        ) : (
          `${offset + 1}-${offset + numberOfElements} ${intl.formatMessage(messages.ofText)} ${totalElements}`
        )}
      </div>
      {totalPages > 1 && (
        <ReactPaginate
          marginPagesDisplayed={1}
          pageCount={totalPages}
          pageRangeDisplayed={3}
          previousLabel={<Icon iconName="angle-left" />}
          nextLabel={<Icon iconName="angle-right" />}
          breakClassName="table__pagination-item"
          forcePage={pageNumberPagination}
          breakLinkClassName="table__pagination-item-link"
          containerClassName="table__pagination-items"
          pageClassName="table__pagination-item"
          pageLinkClassName="table__pagination-item-link"
          activeClassName="active"
          activeLinkClassName="active"
          previousClassName="table__pagination-item table__pagination-item-prev"
          previousLinkClassName="table__pagination-item-link"
          nextClassName="table__pagination-item table__pagination-item-next"
          nextLinkClassName="table__pagination-item-link"
          disabledClassName="table__pagination-item-link--disabled"
          onPageChange={onPageChange}
        />
      )}
      <Select
        handleChange={values => {
          onPageSizeChange(values.value);
        }}
        options={PAGE_SIZES}
        value={pageSize ? { label: pageSize, value: pageSize } : PAGE_SIZES[0]}
        externalMenuClass="pagination-menu"
      />
    </div>
  ) : null;
}

export default React.memo(Pagination);
