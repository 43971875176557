import { defineMessages } from 'react-intl';
import { searchLabel, allLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  searchLabel,
  allLabel,
  clearAllLabel: {
    id: 'filter.clear.all.label',
    defaultMessage: 'Clear All',
  },
  selectAllLabel: {
    id: 'filter.select.all.label',
    defaultMessage: 'Select All',
  },
  moreLabel: {
    id: 'filter.more.label',
    defaultMessage: 'more',
  },
});
