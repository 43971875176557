import { all, takeEvery, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';

import r from '../constants/routes.constants';
import * as ActionTypes from '../constants/auth.constants';
import * as api from '../api/auth.api';
import { setAuthData, removeAuthData, encodePassword } from '../utils/auth.utils';
import { removeUserPolicies } from '../utils/policies.utils';
import { getUserInfo } from '../api/users.api';

function* signIn({ payload }: AnyAction) {
  try {
    const { results: salt } = yield call(api.getSalt, payload.username);
    const password: string = yield call(encodePassword, payload.password, salt);
    const { success, results } = yield call(api.signIn, { ...payload, password });
    if (success) {
      yield put({
        type: ActionTypes.SIGN_IN_SUCCESS,
        payload: results,
      });

      setAuthData(results);
    }
  } catch (error) {
    yield put({ type: ActionTypes.SIGN_IN_FAILURE, payload: error });
  }
}

function* keycloakSignIn({ payload }: AnyAction) {
  const { data } = yield call(api.keyCloakSignIn, payload.data);

  try {
    if (data) {
      const response: {
        data: any;
      } = yield call(api.signInByKeycloakToken, {
        accessToken: `Bearer ${data.access_token}`,
      });

      yield put({
        type: ActionTypes.SIGN_IN_SUCCESS,
        payload: response.data,
      });

      setAuthData(response.data);
    }
  } catch (error) {
    payload.redirect();
    yield put({ type: ActionTypes.SIGN_IN_FAILURE, payload: error });
  } finally {
    payload.removeKeycloakItems();
  }
}

function* signOut({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.signOut);
    if (success) {
      yield put({ type: ActionTypes.SIGN_OUT_SUCCESS });
    }
  } catch (error) {
    yield put({ type: ActionTypes.SIGN_OUT_FAILURE, payload: error });
  } finally {
    removeAuthData();
    removeUserPolicies();
    yield put(push(payload ? `${r.signIn}?callbackUrl=${payload}` : r.signIn));
  }
}

function* getCurrentUserInfo() {
  try {
    const { results: authUser } = yield call(api.getCurrentUserInfo);
    const { success, results } = yield call(getUserInfo, authUser?.id);
    if (success) {
      yield put({ type: ActionTypes.GET_CURRENT_USER_INFO_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CURRENT_USER_INFO_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeEvery(ActionTypes.SIGN_IN_REQUEST, signIn),
    takeEvery(ActionTypes.KEYCLOAK_SIGN_IN_REQUEST, keycloakSignIn),
    takeEvery(ActionTypes.SIGN_OUT_REQUEST, signOut),
    takeEvery(ActionTypes.GET_CURRENT_USER_INFO_REQUEST, getCurrentUserInfo),
  ]);
}
