import React, { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import Table, { DirectionType, TableColType } from '../../components/Table';
import { NavLink } from 'react-router-dom';
import {
  styleStatus,
  normalizeDateFormat,
  getStatusLabel,
  userStatusLabel,
  styleUserStatus,
  notStartedStatusValue,
  closedValue,
  completedStatusValue,
} from '../Polls/utils';
import { DATE_FORMAT } from '../../constants/date.constants';
import { MyPollsParams, PollParams } from '../../enums/params/questionnaires.params';
import r from '../../constants/routes.constants';
import { NewPoll } from '../../enums/questionnaires.enum';

type MyPollsContentProps = {
  setPollParams: (data: Partial<PollParams>) => void;
  sortParams: MyPollsParams;
  pollsByUser: any;
  isLoading: boolean;
  userInfoId: string;
};

const MyPollsContent = ({ pollsByUser, sortParams, setPollParams, isLoading, userInfoId }: MyPollsContentProps) => {
  const intl = useIntl();

  const handleSort = useCallback(
    (sortBy, direction, aditionalSort) => setPollParams({ sortBy: [{ sortBy, direction }, aditionalSort] }),
    [],
  );

  const statusMyPolls = useCallback((adminStatus: string, userStatus: string) => {
    return (
      <span className="table_button">
        {adminStatus === closedValue && (
          <span style={{ color: styleStatus(adminStatus) }}>{getStatusLabel(adminStatus)}</span>
        )}
        {adminStatus !== closedValue && (
          <span style={{ color: styleUserStatus(userStatus ?? notStartedStatusValue) }}>
            {userStatusLabel(userStatus ?? notStartedStatusValue)}
          </span>
        )}
      </span>
    );
  }, []);

  const tableColumns: TableColType<NewPoll>[] = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: NewPoll) => {
          const currentResponder = row?.responders?.find((responder: any) => responder?.id === userInfoId)?.pollStatus;
          if (
            row?.anonymous &&
            Boolean(currentResponder) &&
            (currentResponder === completedStatusValue || row?.status === closedValue)
          )
            return <span className={'table_button'}>{row.name}</span>;
          return (
            <NavLink className={'table__type-link'} to={`/questionnaires/information/${row.id}/${false}`}>
              <span className={'table_button'}>{row.name}</span>
            </NavLink>
          );
        },
      },
      {
        name: intl.formatMessage(messages.pollRelatiedEvent),
        sortName: 'relatedEvent',
        modifier: (row: NewPoll) => {
          const title = row.relatedEvent.eventTableLinkTitle;
          return row.relatedEventId ? (
            row.relatedEvent.isUserHaveAccess ? (
              <NavLink className={'table__type-link'} to={r.eventInformation.replace(':id', `${row.relatedEventId}`)}>
                {title}
              </NavLink>
            ) : (
              <span>{title}</span>
            )
          ) : null;
        },
      },
      {
        name: intl.formatMessage(messages.pollDeadlines),
        sortName: 'deadline',
        modifier: (row: NewPoll) => (
          <div className={'table__data-wrapper'}>
            <span className={'table_button'}>{normalizeDateFormat(row.deadline, DATE_FORMAT.ll)}</span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.statusLabel),
        sortName: 'userStatus',
        aditionalSort: (sortName: string, dir: DirectionType) => ({
          sortBy: 'deadline',
          direction: dir === 'ASC' ? 'DESC' : 'ASC',
        }),
        modifier: (row: NewPoll) => {
          return (
            <div style={{ fontWeight: 500 }} className={'table__data-wrapper'}>
              {statusMyPolls(row?.status, row?.userStatus)}
            </div>
          );
        },
      },
    ],
    [userInfoId],
  );

  return (
    <Table
      externalClass={'table--offices'}
      tableColumns={tableColumns}
      tableData={pollsByUser?.content || []}
      params={sortParams}
      tableActions={[]}
      onSort={handleSort}
      error={''}
      loading={isLoading}
    />
  );
};

export default memo(MyPollsContent);
