import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as candidatesActions from '../../actions/candidates.actions';
import * as filtersActions from '../../actions/filters.actions';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import { UPDATE_CANDIDATE } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { Candidate } from '../../enums/candidates.enums';
import ModalNewCandidate from '../../components/CandidateManagement/Modals/ModalNewCandidate';
import { CandidateParams } from '../../enums/params/candidates.params';
import { useDataForTable } from './useDataForTable';
import ModalEditCandidate from '../../components/CandidateManagement/Modals/ModalEditCandidate';
import ModalDeleteCandidate from '../../components/CandidateManagement/Modals/ModalDeleteCandidate';
import { useParamsChange } from '../../utils/hooks.utils';
import CandidatesFilter from '../../components/CandidateManagement/Filters/index';
import Pagination from '../../components/Pagination';
import { exportCandidates } from '../../actions/export.actions';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import { convertSavedFieldsToParams, getSavedFilterParams, getSavedFilters } from '../../utils/filters.utils';
import { FilterTypes } from '../../constants/filters.constants';
import { isEqual } from 'lodash-es';

function CandidateManagement({
  tableData,
  isLoading,
  candidateListError,
  candidateError,
  params,
  candidateStatuses,
  candidateTechnologies,
  candidateSpecializations,
  candidateReceivingSources,
  getCandidateList,
  setCandidatesParams,
  createCandidate,
  editCandidate,
  deleteCandidate,
  getCandidateStatusesFilter,
  getCandidateTechnologiesFilter,
  getCandidateSpecializationsFilter,
  getCandidateReceivingSourcesFilter,
  exportCandidates,
  resetState,
  resetErrors,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const [modalNewCandidateIsOpen, setModalNewCandidateIsOpen] = useState(false);
  const [modalEditCandidateIsOpen, setModalEditCandidateIsOpen] = useState(false);
  const [modalDeleteCandidateIsOpen, setModalDeleteCandidateIsOpen] = useState(false);
  const [candidateClicked, setCandidateClicked] = useState(new Candidate());

  useEffect(() => {
    getCandidateStatusesFilter();
    getCandidateTechnologiesFilter();
    getCandidateSpecializationsFilter();
    getCandidateReceivingSourcesFilter();

    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.CANDIDATES_FILTER);

    currentSavedFilter ? setCandidatesParams(new CandidateParams(currentSavedFilter)) : getCandidateList();

    setSavedFiltersParams({ filterType: FilterTypes.CANDIDATES_FILTER });

    return () => {
      resetState();
    };
  }, []);

  const openNewCandidateModal = useCallback(() => {
    setModalNewCandidateIsOpen(true);
  }, []);

  const closeNewCandidateModal = useCallback(() => {
    setModalNewCandidateIsOpen(false);
    resetErrors();
  }, []);

  const openEditCandidateModal = useCallback(() => {
    setModalEditCandidateIsOpen(true);
  }, []);

  const closeEditCandidateModal = useCallback(() => {
    setModalEditCandidateIsOpen(false);
    resetErrors();
  }, []);

  const openDeleteCandidateModal = useCallback(() => {
    setModalDeleteCandidateIsOpen(true);
  }, []);

  const closeDeleteCandidateModal = useCallback(() => {
    setModalDeleteCandidateIsOpen(false);
    resetErrors();
  }, []);

  const { tableColumns, tableActions } = useDataForTable(
    tableData?.content || [],
    openEditCandidateModal,
    openDeleteCandidateModal,
    setCandidateClicked,
    exportCandidates,
    editCandidate,
    candidateStatuses,
  );

  const handleMultiParamsChange = useParamsChange(setCandidatesParams);

  const filters = useMemo(
    () => ({
      candidateStatuses,
      candidateTechnologies,
      candidateSpecializations,
      candidateReceivingSources,
    }),
    [candidateStatuses, candidateTechnologies, candidateSpecializations, candidateReceivingSources],
  );

  const pageable = useMemo(
    () => ({
      ...tableData?.pageable,
      ...tableData?.sort,
      totalElements: tableData?.totalElements,
      numberOfElements: tableData?.numberOfElements,
      totalPages: tableData?.totalPages,
    }),
    [tableData],
  );

  const handlePageChange = useCallback(({ selected }) => {
    setCandidatesParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setCandidatesParams({ size: data, page: 0 });
  }, []);

  const handleSort = useCallback((sortBy, direction) => setCandidatesParams({ sortBy, direction }), []);

  const handleFiltersControlChange = useCallback(value => {
    setCandidatesParams(new CandidateParams(convertSavedFieldsToParams(value.fields)));
  }, []);

  const handleClear = useCallback(() => {
    setCandidatesParams(new CandidateParams());
  }, []);

  const showClearButton = useMemo(() => !isEqual(params, new CandidateParams()), [params]);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_CANDIDATE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewCandidateModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <CandidatesFilter
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  setCandidatesParams={setCandidatesParams}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  handleSort={handleSort}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            externalClass="table-candidate cv_table"
            loading={isLoading.getCandidateList}
            error={candidateListError}
            tableActions={tableActions}
            overflowDropdown={false}
          />
          <Pagination pageable={pageable} onPageChange={handlePageChange} onPageSizeChange={handleSizeChange} />
        </div>
      </div>
      {modalNewCandidateIsOpen && (
        <ModalNewCandidate
          isOpen
          error={candidateError}
          isLoading={isLoading.createCandidate}
          candidateSpecializations={candidateSpecializations}
          candidateReceivingSources={candidateReceivingSources}
          candidateTechnologies={candidateTechnologies}
          onCloseRequest={closeNewCandidateModal}
          createCandidate={createCandidate}
        />
      )}
      {modalEditCandidateIsOpen && (
        <ModalEditCandidate
          candidate={candidateClicked}
          isOpen
          error={candidateError}
          isLoading={isLoading.editCandidate}
          candidateStatuses={candidateStatuses}
          candidateSpecializations={candidateSpecializations}
          candidateReceivingSources={candidateReceivingSources}
          candidateTechnologies={candidateTechnologies}
          onCloseRequest={closeEditCandidateModal}
          editCandidate={editCandidate}
        />
      )}
      {modalDeleteCandidateIsOpen && (
        <ModalDeleteCandidate
          candidate={candidateClicked}
          isOpen
          error={candidateError}
          isLoading={isLoading.deleteCandidate}
          onCloseRequest={closeDeleteCandidateModal}
          onDeleteRequest={deleteCandidate}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ candidates, filters, auth }: RootState) => ({
  tableData: candidates.candidateList,
  candidateStatuses: filters.candidateStatusesFilter.candidateStatuses,
  candidateTechnologies: filters.candidateTechnologiesFilter.candidateTechnologies,
  candidateSpecializations: filters.candidateSpecializationsFilter.candidateSpecializations,
  candidateReceivingSources: filters.candidateReceivingSourcesFilter.candidateReceivingSources,
  isLoading: candidates.loading,
  candidateListError: candidates.errors.candidateListError,
  candidateError: candidates.errors.candidateError,
  params: candidates.candidateParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateList: () => dispatch(candidatesActions.getCandidateList()),
  getCandidateStatusesFilter: () => dispatch(filtersActions.getCandidateStatusesFilter()),
  getCandidateTechnologiesFilter: () => dispatch(filtersActions.getCandidateTechnologiesFilter()),
  getCandidateSpecializationsFilter: () => dispatch(filtersActions.getCandidateSpecializationsFilter()),
  getCandidateReceivingSourcesFilter: () => dispatch(filtersActions.getCandidateReceivingSourcesFilter()),
  setCandidatesParams: (params: Partial<CandidateParams>) => dispatch(candidatesActions.setCandidateParams(params)),
  createCandidate: (data: { data: Candidate; callback: () => void }) =>
    dispatch(candidatesActions.createCandidate(data)),
  editCandidate: (data: { data: Candidate; callback?: () => void }) => dispatch(candidatesActions.editCandidate(data)),
  deleteCandidate: (data: { data: string; callback: () => void }) => dispatch(candidatesActions.deleteCandidate(data)),
  exportCandidates: (data: Candidate[]) => dispatch(exportCandidates(data)),
  resetState: () => dispatch(candidatesActions.resetState()),
  resetErrors: () => dispatch(candidatesActions.resetErrors()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CandidateManagement);
