import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/filters.constants';
import * as librariesApi from '../api/libraries.api';
import * as planningApi from '../api/planning.api';
import * as competenciesApi from '../api/competencies.api';
import * as questionnairesApi from '../api/questionnaires.api';
import * as financeApi from '../api/finance.api';
import * as candidateApi from '../api/candidates.api';
import * as filtersApi from '../api/filters.api';
import { getQueryParams } from '../utils/params.utils';
import { FiltersParams } from '../enums/params.enum';
import { getHrCurators, getUsers } from '../api/users.api';
import { HrCuratorsFilterParams, UsersFilterParams } from '../enums/params/users.params';
import { getEventTypesList } from '../api/calendar.api';
import { getEventsList } from '../api/schedule.api';
import { getRecognitionTypeList } from '../api/recognitions.api';
import { AnyAction } from 'redux';
import { EventsParams } from '../enums/params/schedule.params';
import { AssessmentsParams } from '../enums/params/competencies.params';
import { PollParams } from '../enums/params/questionnaires.params';
import { getEmployeeGroupsList } from '../api/notifications.api';
import { FinanceProjectsParams } from '../enums/params/finance.params';
import { SavedFilterParams } from '../enums/params/filters.params';
import { getJiraProjects } from '../api/jira.api';
import { setSavedFilterToStorage } from '../utils/filters.utils';

function* getUsersFilter() {
  const params: UsersFilterParams = yield select((state: RootState) => state.filters.usersFilter.params);
  try {
    const { success, results } = yield call(getUsers, params);
    if (success) {
      yield put({
        type: ActionTypes.GET_USERS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USERS_FILTER_FAILURE, payload: error });
  }
}

function* getOfficesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.officesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getOfficesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_OFFICES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_OFFICES_FILTER_FAILURE, payload: error });
  }
}

function* getDepartmentsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.departmentsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getDepartmentsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_DEPARTMENTS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DEPARTMENTS_FILTER_FAILURE, payload: error });
  }
}

function* getProjectGroupsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.projectGroupsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(planningApi.getProjectGroupsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_PROJECT_GROUPS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROJECT_GROUPS_FILTER_FAILURE, payload: error });
  }
}

function* getСompetenciesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.competenciesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(competenciesApi.getCompetenciesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_COMPETENCIES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPETENCIES_FILTER_FAILURE, payload: error });
  }
}

function* getEventTypesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.eventTypesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(getEventTypesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_EVENT_TYPES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENT_TYPES_FILTER_FAILURE, payload: error });
  }
}

function* getPollsFilter({ payload }: AnyAction) {
  const params: FiltersParams = yield select((state: RootState) => state.filters.pollsFilter.params);
  const pollParams = new PollParams({ ...params, ...payload });
  const data = getQueryParams(pollParams);
  try {
    const { success, results } = yield call(questionnairesApi.getPollsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_POLLS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_POLLS_FILTER_FAILURE, payload: error });
  }
}

function* getPollTemplatesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.pollTemplatesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(questionnairesApi.getPollTemplatesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_POLL_TEMPLATES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_POLL_TEMPLATES_FILTER_FAILURE, payload: error });
  }
}

function* getPollTemplatesCategoryFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.pollTemplatesCategoryFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(questionnairesApi.getPollTemplatesCategoryList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_POLL_TEMPLATES_CATEGORY_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_POLL_TEMPLATES_CATEGORY_FILTER_FAILURE, payload: error });
  }
}

function* getEventsFilter({ payload }: AnyAction) {
  const params: FiltersParams = yield select((state: RootState) => state.filters.eventsFilter.params);
  const eventsParams = new EventsParams({ ...params, ...payload });
  const data = getQueryParams(eventsParams);
  try {
    const { success, results } = yield call(getEventsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_EVENTS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENTS_FILTER_FAILURE, payload: error });
  }
}

function* getRecognitionTypesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.recognitionTypesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(getRecognitionTypeList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_RECOGNITION_TYPES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_RECOGNITION_TYPES_FILTER_FAILURE, payload: error });
  }
}

function* getCompanyPositionsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.companyPositionsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getCompanyPositionsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_COMPANY_POSITIONS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPANY_POSITIONS_FILTER_FAILURE, payload: error });
  }
}

function* getLanguageLevelsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.languageLevelsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getLanguageLevelsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_LANGUAGE_LEVELS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_LANGUAGE_LEVELS_FILTER_FAILURE, payload: error });
  }
}

function* getForeignLanguagesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.foreignLanguagesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getForeignLanguagesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_FOREIGN_LANGUAGES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FOREIGN_LANGUAGES_FILTER_FAILURE, payload: error });
  }
}

function* getTechnicalSkillsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.technicalSkillsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getTechnicalSkillsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_TECHNICAL_SKILLS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_TECHNICAL_SKILLS_FILTER_FAILURE, payload: error });
  }
}

function* getProjectsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.projectsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getProjectsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_PROJECTS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROJECTS_FILTER_FAILURE, payload: error });
  }
}

function* getSpecializationsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.specializationsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getSpecializationsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_SPECIALIZATIONS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SPECIALIZATIONS_FILTER_FAILURE, payload: error });
  }
}

function* getAssessmentsFilter({ payload }: AnyAction) {
  const params: FiltersParams = yield select((state: RootState) => state.filters.assessmentsFilter.params);
  const assessmentsParams = new AssessmentsParams({ ...params, ...payload });
  const data = getQueryParams(assessmentsParams);
  try {
    const { success, results } = yield call(competenciesApi.getFilteredAssessments, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_ASSESSMENTS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ASSESSMENTS_FILTER_FAILURE, payload: error });
  }
}

function* getCompetenciesTypesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.competenciesTypesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(competenciesApi.getCompetenceTypesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_COMPETENCIES_TYPES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPETENCIES_TYPES_FILTER_FAILURE, payload: error });
  }
}

function* getGradesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.gradesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getGradesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_GRADES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_GRADES_FILTER_FAILURE, payload: error });
  }
}

function* getEmployeeGroupsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.employeeGroupsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(getEmployeeGroupsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_EMPLOYEE_GROUPS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMPLOYEE_GROUPS_FILTER_FAILURE, payload: error });
  }
}

function* getClientsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.clientsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(financeApi.getClientsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_CLIENTS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CLIENTS_FILTER_FAILURE, payload: error });
  }
}

function* getCurrenciesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.currenciesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(financeApi.getCurrenciesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_CURRENCIES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CURRENCIES_FILTER_FAILURE, payload: error });
  }
}

function* getProjectTypesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.projectTypesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(financeApi.getProjectTypesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_PROJECT_TYPES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROJECT_TYPES_FILTER_FAILURE, payload: error });
  }
}

function* getSkillLevelsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.skillLevelsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(librariesApi.getSkillLevelsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_SKILL_LEVELS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SKILL_LEVELS_FILTER_FAILURE, payload: error });
  }
}

function* getIncomeTypesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.incomeTypesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(financeApi.getIncomeTypesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_INCOME_TYPES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_INCOME_TYPES_FILTER_FAILURE, payload: error });
  }
}

function* getExpenseTypesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.expenseTypesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(financeApi.getExpenseTypesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_EXPENSE_TYPES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EXPENSE_TYPES_FILTER_FAILURE, payload: error });
  }
}

function* getSuppliersFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.suppliersFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(financeApi.getSuppliersList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_SUPPLIERS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SUPPLIERS_FILTER_FAILURE, payload: error });
  }
}

function* getFinanceProjectsFilter({ payload }: AnyAction) {
  const params: FiltersParams = yield select((state: RootState) => state.filters.financeProjectsFilter.params);
  const financeProjectsParams = new FinanceProjectsParams({ ...params, ...payload });
  const data = getQueryParams(financeProjectsParams);
  try {
    const { success, results } = yield call(financeApi.getProjectsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_FINANCE_PROJECTS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FINANCE_PROJECTS_FILTER_FAILURE, payload: error });
  }
}

function* getCandidateStatusesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.candidateStatusesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(candidateApi.getCandidateStatusesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_STATUSES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_STATUSES_FILTER_FAILURE, payload: error });
  }
}

function* getCandidateTechnologiesFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.candidateTechnologiesFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(candidateApi.getCandidateTechnologiesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_TECHNOLOGIES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_TECHNOLOGIES_FILTER_FAILURE, payload: error });
  }
}

function* getCandidateSpecializationsFilter() {
  const params: FiltersParams = yield select((state: RootState) => state.filters.candidateSpecializationsFilter.params);
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(candidateApi.getCandidateSpecializationsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_FILTER_FAILURE, payload: error });
  }
}

function* getCandidateReceivingSourcesFilter() {
  const params: FiltersParams = yield select(
    (state: RootState) => state.filters.candidateReceivingSourcesFilter.params,
  );
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(candidateApi.getCandidateReceivingSourcesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_FILTER_FAILURE, payload: error });
  }
}

function* getHrCuratorsFilter() {
  const params: HrCuratorsFilterParams = yield select((state: RootState) => state.filters.hrCuratorsFilter.params);
  try {
    const { success, results } = yield call(getHrCurators, params);
    if (success) {
      yield put({
        type: ActionTypes.GET_HR_CURATORS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_HR_CURATORS_FILTER_FAILURE, payload: error });
  }
}

function* getJiraProjectsFilter() {
  try {
    const { success, results } = yield call(getJiraProjects);
    if (success) {
      yield put({
        type: ActionTypes.GET_JIRA_PROJECTS_FILTER_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_JIRA_PROJECTS_FILTER_FAILURE, payload: error });
  }
}

function* getSavedFilters() {
  const params: SavedFilterParams = yield select((state: RootState) => state.filters.savedFilters.params);
  try {
    const { success, results } = yield call(filtersApi.getSavedFiltersList, params);
    if (success) {
      yield put({
        type: ActionTypes.GET_SAVED_FILTERS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SAVED_FILTERS_LIST_FAILURE, payload: error });
  }
}

function* createSavedFilter({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(filtersApi.createSavedFilter, payload.data);

    if (success) {
      yield setSavedFilterToStorage(results.filterType, results.id, results.fields);
      yield put({ type: ActionTypes.CREATE_SAVED_FILTER_SUCCESS });
      payload.callback();
      yield call(getSavedFilters);
    }
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_SAVED_FILTER_FAILURE, payload: error });
  }
}

function* editSavedFilter({ payload }: AnyAction) {
  const authUserId: SavedFilterParams = yield select((state: RootState) => state.auth.currentUserInfo.id);

  try {
    const { success } = yield payload.data.authorId === authUserId
      ? call(filtersApi.editSavedFilter, payload.data)
      : call(filtersApi.editFilterPublicStatus, payload.data);

    if (success) {
      yield put({ type: ActionTypes.EDIT_SAVED_FILTER_SUCCESS });
      payload.callback && payload.callback();
      yield call(getSavedFilters);
    }
  } catch (error) {
    yield put({ type: ActionTypes.EDIT_SAVED_FILTER_FAILURE, payload: error });
  }
}

function* deleteSavedFilter({ payload }: AnyAction) {
  try {
    const { success } = yield call(filtersApi.deleteSavedFilter, payload.id);

    if (success) {
      yield put({ type: ActionTypes.DELETE_SAVED_FILTER_SUCCESS });
      payload.callback();
      yield call(getSavedFilters);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_SAVED_FILTER_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeEvery([ActionTypes.GET_USERS_FILTER_REQUEST, ActionTypes.SET_USERS_FILTER_PARAMS], getUsersFilter),
    takeEvery(ActionTypes.GET_OFFICES_FILTER_REQUEST, getOfficesFilter),
    takeEvery(ActionTypes.GET_DEPARTMENTS_FILTER_REQUEST, getDepartmentsFilter),
    takeEvery(ActionTypes.GET_PROJECT_GROUPS_FILTER_REQUEST, getProjectGroupsFilter),
    takeEvery(ActionTypes.GET_COMPETENCIES_FILTER_REQUEST, getСompetenciesFilter),
    takeEvery(ActionTypes.GET_EVENT_TYPES_FILTER_REQUEST, getEventTypesFilter),
    takeEvery(ActionTypes.GET_POLLS_FILTER_REQUEST, getPollsFilter),
    takeEvery(ActionTypes.GET_POLL_TEMPLATES_FILTER_REQUEST, getPollTemplatesFilter),
    takeEvery(ActionTypes.GET_POLL_TEMPLATES_CATEGORY_FILTER_REQUEST, getPollTemplatesCategoryFilter),
    takeEvery(ActionTypes.GET_EVENTS_FILTER_REQUEST, getEventsFilter),
    takeEvery(ActionTypes.GET_RECOGNITION_TYPES_FILTER_REQUEST, getRecognitionTypesFilter),
    takeEvery(ActionTypes.GET_COMPANY_POSITIONS_FILTER_REQUEST, getCompanyPositionsFilter),
    takeEvery(ActionTypes.GET_LANGUAGE_LEVELS_FILTER_REQUEST, getLanguageLevelsFilter),
    takeEvery(ActionTypes.GET_FOREIGN_LANGUAGES_FILTER_REQUEST, getForeignLanguagesFilter),
    takeEvery(ActionTypes.GET_TECHNICAL_SKILLS_FILTER_REQUEST, getTechnicalSkillsFilter),
    takeEvery(ActionTypes.GET_PROJECTS_FILTER_REQUEST, getProjectsFilter),
    takeEvery(ActionTypes.GET_SPECIALIZATIONS_FILTER_REQUEST, getSpecializationsFilter),
    takeEvery(ActionTypes.GET_ASSESSMENTS_FILTER_REQUEST, getAssessmentsFilter),
    takeEvery(ActionTypes.GET_COMPETENCIES_TYPES_FILTER_REQUEST, getCompetenciesTypesFilter),
    takeEvery(ActionTypes.GET_GRADES_FILTER_REQUEST, getGradesFilter),
    takeEvery(ActionTypes.GET_EMPLOYEE_GROUPS_FILTER_REQUEST, getEmployeeGroupsFilter),
    takeEvery(ActionTypes.GET_CLIENTS_FILTER_REQUEST, getClientsFilter),
    takeEvery(ActionTypes.GET_CURRENCIES_FILTER_REQUEST, getCurrenciesFilter),
    takeEvery(ActionTypes.GET_PROJECT_TYPES_FILTER_REQUEST, getProjectTypesFilter),
    takeEvery(ActionTypes.GET_SKILL_LEVELS_FILTER_REQUEST, getSkillLevelsFilter),
    takeEvery(ActionTypes.GET_INCOME_TYPES_FILTER_REQUEST, getIncomeTypesFilter),
    takeEvery(ActionTypes.GET_EXPENSE_TYPES_FILTER_REQUEST, getExpenseTypesFilter),
    takeEvery(ActionTypes.GET_EXPENSE_TYPES_FILTER_REQUEST, getExpenseTypesFilter),
    takeEvery(ActionTypes.GET_SUPPLIERS_FILTER_REQUEST, getSuppliersFilter),
    takeEvery(ActionTypes.GET_FINANCE_PROJECTS_FILTER_REQUEST, getFinanceProjectsFilter),
    takeEvery(ActionTypes.GET_CANDIDATE_STATUSES_FILTER_REQUEST, getCandidateStatusesFilter),
    takeEvery(ActionTypes.GET_CANDIDATE_TECHNOLOGIES_FILTER_REQUEST, getCandidateTechnologiesFilter),
    takeEvery(ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_FILTER_REQUEST, getCandidateSpecializationsFilter),
    takeEvery(ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_FILTER_REQUEST, getCandidateReceivingSourcesFilter),
    takeEvery(ActionTypes.GET_HR_CURATORS_FILTER_REQUEST, getHrCuratorsFilter),
    takeEvery(ActionTypes.SET_SAVED_FILTERS_LIST_PARAMS, getSavedFilters),
    takeEvery(ActionTypes.CREATE_SAVED_FILTER_REQUEST, createSavedFilter),
    takeEvery(ActionTypes.EDIT_SAVED_FILTER_REQUEST, editSavedFilter),
    takeEvery(ActionTypes.DELETE_SAVED_FILTER_REQUEST, deleteSavedFilter),
    takeEvery(ActionTypes.GET_JIRA_PROJECTS_FILTER_REQUEST, getJiraProjectsFilter),
  ]);
}
