import { defineMessages } from 'react-intl';
import { newButton, nameColumn, editButton, deleteButton } from '../../i18n/defaultMessage';

export default defineMessages({
  newButton,
  nameColumn,
  editButton,
  deleteButton,
  pageTitle: {
    id: 'libraries.specializations.title.specializations',
    defaultMessage: 'Specializations',
  },
});
