import request from '../utils/request.utils';
import * as paramsTypes from './../enums/params/policies.params';

export function getPolicyGroup() {
  const options: RequestConfig = {
    method: 'get',
    url: `policyGroup`,
  };

  return request(options);
}

export function getUserPolicies(uuid: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `userPolicies/${uuid}`,
    params: { size: 1000 },
  };
  return request(options);
}

export function editUserPolicies(uuid: string, data: string[]) {
  const options: RequestConfig = {
    method: 'put',
    url: `userPolicies/${uuid}`,
    data,
  };
  return request(options);
}

export function getPolicyGroupWithUsers(params: paramsTypes.PolicyGroupParams) {
  const options: RequestConfig = {
    method: 'get',
    url: `policyGroup/users`,
    params,
  };

  return request(options);
}

export function editUsersPolicies(data: string[]) {
  const options: RequestConfig = {
    method: 'patch',
    url: `policies/level`,
    data,
  };
  return request(options);
}

export function getPolicyModule(params: paramsTypes.PolicyModuleParams) {
  const options: RequestConfig = {
    method: 'get',
    url: `policies/level`,
    params,
  };

  return request(options);
}
