import { SortParams } from '../params.enum';

export class MenuLinksParams extends SortParams {
  constructor(params?: unknown) {
    super('defaultPosition', params);
  }
}

export class DashboardLinksParams extends SortParams {
  constructor(params?: unknown) {
    super(
      [
        { sortBy: 'position', direction: 'ASC' },
        { sortBy: 'defaultPosition', direction: 'ASC' },
      ],
      params,
    );
  }
}
