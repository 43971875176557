import Modal from '../../Modal';
import Button from '../../Button';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';

type ModalDeleteEventTypeProps = {
  onCloseRequest: () => void;
  onDeleteRequest: (data: any) => void;
  eventTypeData: any;
  eventTypeError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalDeleteEventType({
  onCloseRequest,
  onDeleteRequest,
  eventTypeData,
  eventTypeError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalDeleteEventTypeProps) {
  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetErrors();
  }, []);

  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.deleteModalWithItemNameTitle, {
        name: eventTypeData.name,
      })}
      size={'small'}
      classNameModal="center"
    >
      <div className={'form__buttons'}>
        <ErrorMessage>{eventTypeError}</ErrorMessage>
        <Button externalClass={'button--modal'} onClick={resetAndExit} color="gray">
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            onDeleteRequest({ data: eventTypeData.id, callback: resetAndExit });
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteEventType;
