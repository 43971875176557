import { defineMessages } from 'react-intl';
import { newButton, editButton, deleteButton, nameColumn, addressLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  newButton,
  editButton,
  deleteButton,
  nameColumn,
  addressLabel,
  pageTitle: {
    id: 'libraries.offices.title.offices',
    defaultMessage: 'Offices',
  },
});
