import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  newGradeTitle: {
    id: 'libraries.grades.modal.new.grade.title.new.grade',
    defaultMessage: 'New Grade',
  },
  editGradeTitle: {
    id: 'libraries.grades.modal.edit.grade.title.edit.grade',
    defaultMessage: 'Edit Grade',
  },
  positionsInput: {
    id: 'libraries.grades.modal.new.grade.label.positions',
    defaultMessage: 'Positions',
  },
  confirmEditGradeMessage: {
    id:
      'libraries.grades.modal.new.grade.title.the.position.is.already.in.another.grade.are.you.sure.you.want.to.add.it.to.this.one',
    defaultMessage: 'The position is already in another grade! Are you sure you want to add it to this one?',
  },
});
