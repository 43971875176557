import { defineMessages } from 'react-intl';
import { departmentsMembersLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  departmentsMembersLabel,
  includedInPlanningTitle: {
    id: 'planning.active.employees.column.included.in.planning',
    defaultMessage: 'Included in Planning',
  },
  pageTitle: {
    id: 'planning.active.employees.column.active.members',
    defaultMessage: 'Active Members',
  },
});
