import { RejectValueErrors } from './../enums/error.enum';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/notifications.constants';
import { EmployeeGroup, FollowUp, MailerSetting } from '../enums/notifications.enum';
import * as paramsTypes from '../enums/params/notifications.params';
import { getReducerErrors } from '../utils/reducerError.utils';

type InitialStateType = {
  mailerSettingsTable: MailerSetting[];
  employeeGroupsTable: any;
  followUpsTable: any;
  emailTemplatesList: any;
  errors: {
    mailerSettingsListError: null | string;
    mailerSettingError: null | string | RejectValueErrors[];
    employeeGroupsListError: null | string;
    employeeGroupError: null | string | RejectValueErrors[];
    followUpsListError: null | string;
    followUpError: null | string | RejectValueErrors[];
    emailTemplatesListError: null | string;
  };
  loading: {
    getMailerSettingsList: boolean;
    createMailSetting: boolean;
    editMailSetting: boolean;
    deleteMailSetting: boolean;
    getEmployeeGroupsList: boolean;
    createEmployeeGroup: boolean;
    editEmployeeGroup: boolean;
    deleteEmployeeGroup: boolean;
    getFollowUpsList: boolean;
    createFollowUp: boolean;
    editFollowUp: boolean;
    deleteFollowUp: boolean;
    getEmailTemplatesList: boolean;
    createEmailTemplate: boolean;
    editEmailTemplate: boolean;
    deleteEmailTemplate: boolean;
  };
  mailerSettingsParams: paramsTypes.MailerSettingsParams;
  employeeGroupsParams: paramsTypes.EmployeeGroupsParams;
  followUpsParams: paramsTypes.FollowUpsParams;
  emailTemplatesParams: paramsTypes.EmailTemplatesParams;
};

const initialState: InitialStateType = {
  mailerSettingsTable: [],
  employeeGroupsTable: [],
  followUpsTable: [],
  emailTemplatesList: [],
  errors: {
    mailerSettingsListError: null,
    mailerSettingError: null,
    employeeGroupsListError: null,
    employeeGroupError: null,
    followUpsListError: null,
    followUpError: null,
    emailTemplatesListError: null,
  },
  loading: {
    getMailerSettingsList: false,
    createMailSetting: false,
    editMailSetting: false,
    deleteMailSetting: false,
    getEmployeeGroupsList: false,
    createEmployeeGroup: false,
    editEmployeeGroup: false,
    deleteEmployeeGroup: false,
    getFollowUpsList: false,
    createFollowUp: false,
    editFollowUp: false,
    deleteFollowUp: false,
    getEmailTemplatesList: false,
    createEmailTemplate: false,
    editEmailTemplate: false,
    deleteEmailTemplate: false,
  },
  mailerSettingsParams: new paramsTypes.MailerSettingsParams(),
  employeeGroupsParams: new paramsTypes.EmployeeGroupsParams(),
  followUpsParams: new paramsTypes.FollowUpsParams(),
  emailTemplatesParams: new paramsTypes.EmailTemplatesParams(),
};

const notificationsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_MAILER_SETTINGS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getMailerSettingsList: true },
      };

    case ActionTypes.GET_MAILER_SETTINGS_LIST_SUCCESS:
      return {
        ...state,
        mailerSettingsTable: action.payload.content.map((mailerSetting: any) => new MailerSetting(mailerSetting)),
        errors: { ...state.errors, mailerSettingsListError: null },
        loading: { ...state.loading, getMailerSettingsList: false },
      };

    case ActionTypes.GET_MAILER_SETTINGS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, mailerSettingsListError: action.payload.message },
        loading: { ...state.loading, getMailerSettingsList: false },
      };

    case ActionTypes.POST_MAILER_SETTING_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, mailerSettingError: null },
        loading: { ...state.loading, createMailSetting: true },
      };

    case ActionTypes.POST_MAILER_SETTING_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, mailerSettingError: null },
        loading: { ...state.loading, createMailSetting: false },
      };

    case ActionTypes.POST_MAILER_SETTING_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, mailerSettingError: getReducerErrors(action.payload) },
        loading: { ...state.loading, createMailSetting: false },
      };

    case ActionTypes.PUT_MAILER_SETTING_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, mailerSettingError: null },
        loading: { ...state.loading, editMailSetting: true },
      };

    case ActionTypes.PUT_MAILER_SETTING_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, mailerSettingError: null },
        loading: { ...state.loading, editMailSetting: false },
      };

    case ActionTypes.PUT_MAILER_SETTING_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, mailerSettingError: getReducerErrors(action.payload) },
        loading: { ...state.loading, editMailSetting: false },
      };

    case ActionTypes.DELETE_MAILER_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteMailSetting: true },
      };

    case ActionTypes.DELETE_MAILER_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteMailSetting: false },
        errors: { ...state.errors, mailerSettingError: null },
      };

    case ActionTypes.DELETE_MAILER_SETTING_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteMailSetting: false },
        errors: {
          ...state.errors,
          mailerSettingError: action.payload.message,
        },
      };

    case ActionTypes.SET_MAILER_SETTINGS_PARAMS_REQUEST: {
      return {
        ...state,
        mailerSettingsParams: new paramsTypes.MailerSettingsParams({
          ...state.mailerSettingsParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getMailerSettingsList: true },
      };
    }

    case ActionTypes.GET_EMPLOYEE_GROUPS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeGroupsList: true },
      };

    case ActionTypes.GET_EMPLOYEE_GROUPS_LIST_SUCCESS:
      return {
        ...state,
        employeeGroupsTable: {
          ...action.payload,
          content: action.payload.content.map((employeeGroup: any) => new EmployeeGroup(employeeGroup)),
        },
        errors: { ...state.errors, employeeGroupsListError: null },
        loading: { ...state.loading, getEmployeeGroupsList: false },
      };

    case ActionTypes.GET_EMPLOYEE_GROUPS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupsListError: action.payload.message },
        loading: { ...state.loading, getEmployeeGroupsList: false },
      };

    case ActionTypes.POST_EMPLOYEE_GROUP_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupError: null },
        loading: { ...state.loading, createEmployeeGroup: true },
      };

    case ActionTypes.POST_EMPLOYEE_GROUP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupError: null },
        loading: { ...state.loading, createEmployeeGroup: false },
      };

    case ActionTypes.POST_EMPLOYEE_GROUP_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupError: getReducerErrors(action.payload) },
        loading: { ...state.loading, createEmployeeGroup: false },
      };

    case ActionTypes.PUT_EMPLOYEE_GROUP_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupError: null },
        loading: { ...state.loading, editEmployeeGroup: true },
      };

    case ActionTypes.PUT_EMPLOYEE_GROUP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupError: null },
        loading: { ...state.loading, editEmployeeGroup: false },
      };

    case ActionTypes.PUT_EMPLOYEE_GROUP_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupError: getReducerErrors(action.payload) },
        loading: { ...state.loading, editEmployeeGroup: false },
      };

    case ActionTypes.DELETE_EMPLOYEE_GROUP_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupError: null },
        loading: { ...state.loading, deleteEmployeeGroup: true },
      };

    case ActionTypes.DELETE_EMPLOYEE_GROUP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupError: null },
        loading: { ...state.loading, deleteEmployeeGroup: false },
      };

    case ActionTypes.DELETE_EMPLOYEE_GROUP_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, employeeGroupError: action.payload.message },
        loading: { ...state.loading, deleteEmployeeGroup: false },
      };

    case ActionTypes.SET_EMPLOYEE_GROUPS_PARAMS_REQUEST: {
      return {
        ...state,
        employeeGroupsParams: new paramsTypes.EmployeeGroupsParams({
          ...state.employeeGroupsParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getEmployeeGroupsList: true },
      };
    }

    case ActionTypes.GET_FOLLOW_UPS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getFollowUpsList: true },
      };

    case ActionTypes.GET_FOLLOW_UPS_LIST_SUCCESS:
      return {
        ...state,
        followUpsTable: {
          ...action.payload,
          content: action.payload.content.map((followUp: any) => new FollowUp(followUp)),
        },
        errors: { ...state.errors, followUpsListError: null },
        loading: { ...state.loading, getFollowUpsList: false },
      };

    case ActionTypes.GET_FOLLOW_UPS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, followUpsListError: action.payload.message },
        loading: { ...state.loading, getFollowUpsList: false },
      };

    case ActionTypes.POST_FOLLOW_UP_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, followUpError: null },
        loading: { ...state.loading, createFollowUp: true },
      };

    case ActionTypes.POST_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, followUpError: null },
        loading: { ...state.loading, createFollowUp: false },
      };

    case ActionTypes.POST_FOLLOW_UP_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, followUpError: getReducerErrors(action.payload) },
        loading: { ...state.loading, createFollowUp: false },
      };

    case ActionTypes.PUT_FOLLOW_UP_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, followUpError: null },
        loading: { ...state.loading, editFollowUp: true },
      };

    case ActionTypes.PUT_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, followUpError: null },
        loading: { ...state.loading, editFollowUp: false },
      };

    case ActionTypes.PUT_FOLLOW_UP_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, followUpError: getReducerErrors(action.payload) },
        loading: { ...state.loading, editFollowUp: false },
      };

    case ActionTypes.DELETE_FOLLOW_UP_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, followUpError: null },
        loading: { ...state.loading, deleteFollowUp: true },
      };

    case ActionTypes.DELETE_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, followUpError: null },
        loading: { ...state.loading, deleteFollowUp: false },
      };

    case ActionTypes.DELETE_FOLLOW_UP_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, followUpError: action.payload.message },
        loading: { ...state.loading, deleteFollowUp: false },
      };

    case ActionTypes.SET_FOLLOW_UPS_PARAMS_REQUEST: {
      return {
        ...state,
        followUpsParams: new paramsTypes.FollowUpsParams({
          ...state.followUpsParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getFollowUpsList: true },
      };
    }

    case ActionTypes.RESET_NOTIFICATIONS_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_NOTIFICATIONS_STATE:
      return {
        ...initialState,
        mailerSettingsParams: state.mailerSettingsParams,
        employeeGroupsParams: state.employeeGroupsParams,
        followUpsParams: state.followUpsParams,
      };

    case ActionTypes.GET_EMAIL_TEMPLATES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getEmailTemplatesList: true },
      };

    case ActionTypes.GET_EMAIL_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        emailTemplatesList: action.payload,
        errors: { ...state.errors, emailTemplatesListError: null },
        loading: { ...state.loading, getEmailTemplatesList: false },
      };

    case ActionTypes.GET_EMAIL_TEMPLATES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: action.payload.message },
        loading: { ...state.loading, getEmailTemplatesList: false },
      };

    case ActionTypes.SET_EMAIL_TEMPLATES_PARAMS: {
      return {
        ...state,
        emailTemplatesParams: new paramsTypes.EmailTemplatesParams({
          ...state.emailTemplatesParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getEmailTemplatesList: true },
      };
    }

    case ActionTypes.PUT_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: null },
        loading: { ...state.loading, editEmailTemplate: true },
      };

    case ActionTypes.PUT_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: null },
        loading: { ...state.loading, editEmailTemplate: false },
      };

    case ActionTypes.PUT_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: getReducerErrors(action.payload) },
        loading: { ...state.loading, editEmailTemplate: false },
      };

    case ActionTypes.DELETE_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: null },
        loading: { ...state.loading, deleteEmailTemplate: true },
      };

    case ActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: null },
        loading: { ...state.loading, deleteEmailTemplate: false },
      };

    case ActionTypes.DELETE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: action.payload.message },
        loading: { ...state.loading, deleteEmailTemplate: false },
      };

    case ActionTypes.POST_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: null },
        loading: { ...state.loading, createEmailTemplate: true },
      };

    case ActionTypes.POST_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: null },
        loading: { ...state.loading, createEmailTemplate: false },
      };

    case ActionTypes.POST_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, emailTemplatesListError: getReducerErrors(action.payload) },
        loading: { ...state.loading, createEmailTemplate: false },
      };

    default:
      return state;
  }
};

export default notificationsReducer;
