import React, { memo, useContext, useEffect } from 'react';
import { UserInfo } from '../../../enums/users.enum';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { NewPoll } from '../../../enums/questionnaires.enum';
import EventPoll from './EventPoll';
import { resetEventPolls } from '../../../actions/questionnaires.actions';
import { checkPolicies } from '../../../utils/policies.utils';
import { VIEW_POLL_EXTENDED } from '../../../constants/policies.constants';
import { notPublishedValue } from '../../Polls/utils';
import PoliciesContext from '../../../PoliciesContext';
import { SpecializationInfo } from '../../../enums/libraries.enum';
import { CompanyPositionInfoType } from '../../../types/libraries';

interface IEventPollsProps {
  userList: UserInfo[];
  specializations: SpecializationInfo[];
  positions: CompanyPositionInfoType[];
  jiraProjectsList: [];
  targetEmployee: UserInfo | null;
}

const EventPolls = ({ userList, targetEmployee, specializations, positions, jiraProjectsList }: IEventPollsProps) => {
  const dispatch = useDispatch();
  const eventPolls = useSelector((state: RootState) => state.questionnaires.eventPolls);
  const userPolicies = useContext(PoliciesContext);

  useEffect(() => {
    return () => {
      dispatch(resetEventPolls());
    };
  }, []);

  return (
    <>
      {eventPolls?.map((poll: NewPoll) => {
        if (!checkPolicies([VIEW_POLL_EXTENDED], userPolicies) && poll.status === notPublishedValue) {
          return null;
        }
        return (
          !isEmpty(poll?.template.groups) && (
            <EventPoll
              userList={userList}
              poll={poll}
              key={poll.id}
              targetEmployee={targetEmployee}
              specializations={specializations}
              positions={positions}
              jiraProjectsList={jiraProjectsList}
            />
          )
        );
      })}
    </>
  );
};

export default memo(EventPolls);
