import { defineMessages } from 'react-intl';
import {
  cancelButton,
  addButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  emailLabel,
  addressLabel,
  phonesLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  addButton,
  deleteButton,
  saveButton,
  emailLabel,
  nameColumn,
  deleteModalWithItemNameTitle,
  addressLabel,
  phonesLabel,
  newOfficeTitle: {
    id: 'libraries.offices.modal.new.office.title.new.office',
    defaultMessage: 'New Office',
  },
  editOfficeTitle: {
    id: 'libraries.offices.modal.edit.office.title.edit.office',
    defaultMessage: 'Edit Office',
  },
  workDaysTitle: {
    id: 'libraries.offices.modal.new.office.label.work.days',
    defaultMessage: 'Work Days',
  },
  hoursPerWorkDayInput: {
    id: 'libraries.offices.modal.new.office.label.hours.per.work.day',
    defaultMessage: 'Hours per Work Day',
  },
  contactInput: {
    id: 'libraries.offices.modal.new.office.label.contact',
    defaultMessage: 'Contact',
  },
  additionalInfoInput: {
    id: 'libraries.offices.modal.new.office.label.additional.info',
    defaultMessage: 'Additional info',
  },
});
