import React, { useCallback, useMemo } from 'react';
import { OptionTypeBase } from 'react-select';
import Select from '../../../../components/Select';
import { ImportTransaction } from '../../../../enums/finance/finance.enum';

type TransactionsSelectProps = {
  row: ImportTransaction;
  index: number;
  isGrouped?: boolean;
  disabled?: boolean;
  options?: OptionTypeBase[];
  handleSelectChange: (value: any, row: ImportTransaction, index: number) => void;
  getOptions?: (row: ImportTransaction) => OptionTypeBase[] | OptionTypeBase[];
  getValues: (row: ImportTransaction, options: OptionTypeBase[]) => OptionTypeBase | undefined;
  onCloseMenu?: () => void;
};

function TransactionsSelect({
  row,
  isGrouped,
  disabled,
  index,
  options,
  getOptions,
  getValues,
  handleSelectChange,
  onCloseMenu,
}: TransactionsSelectProps) {
  const selectOptions = useMemo(() => (getOptions ? getOptions(row) : options) || [], [row, getOptions, options]);
  const selectValue = useMemo(() => getValues(row, selectOptions) || null, [row, selectOptions]);

  const handleChange = useCallback(
    values => {
      handleSelectChange(values, row, index);
    },
    [row, index, handleSelectChange],
  );

  return (
    <Select
      externalMenuClass="transactions__form-select"
      isDisabled={disabled}
      isGrouped={isGrouped}
      options={selectOptions}
      value={selectValue}
      handleChange={handleChange}
      onCloseMenu={onCloseMenu}
    />
  );
}

export default React.memo(TransactionsSelect);
