import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, LinearScale, Title, CategoryScale, Tooltip, Legend, TimeScale } from 'chart.js';
import { summaryOptions } from '../../../../../constants/activity.constants';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';
import { getSummaryChartData, legendHeight, summaryChartHeight, summaryChartWidth } from '../utils';
import SummaryInfo from './SummaryInfo';
import { TActivityUserDto } from '../../../../../types/activity';

declare module 'chart.js' {
  // Extend tooltip positioner map
  interface TooltipPositionerMap {
    cursor: TooltipPositionerFunction<ChartType>;
  }
}

type SummaryChartProps = {
  activityData: TActivityUserDto | null;
  dateRange: string[];
};

function SummaryChart({ activityData, dateRange }: SummaryChartProps) {
  Tooltip.positioners.cursor = function (chartElements, coordinates) {
    return coordinates;
  };

  const chartData = useMemo(() => getSummaryChartData(activityData?.daysActivity, dateRange), [
    activityData?.daysActivity,
    dateRange,
  ]);

  ChartJS.register(BarElement, LinearScale, Title, CategoryScale, TimeScale, Tooltip, Legend, legendHeight);

  return (
    <div className="tabs__content-work-wrapper summary">
      <div className="tabs__content-item__title summary-titile">
        <p>
          <FormattedMessage {...messages.summaryLabel} />
        </p>
        <SummaryInfo activityData={activityData} plannedHour={activityData?.planned || 0} />
      </div>

      <Bar data={chartData} options={summaryOptions} height={summaryChartHeight} width={summaryChartWidth} />
    </div>
  );
}

export default React.memo(SummaryChart);
