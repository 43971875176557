import {
  officeLabel,
  newButton,
  editButton,
  deleteButton,
  activeLabel,
  inactiveLabel,
} from './../../i18n/defaultMessage';
import { defineMessages } from 'react-intl';

export default defineMessages({
  officeLabel,
  newButton,
  editButton,
  deleteButton,
  activeLabel,
  inactiveLabel,
  pageTitle: {
    id: 'bonuses.and.grades.bonuses.by.grades.title.bonuses.by.grades',
    defaultMessage: 'Bonuses by Grades',
  },
  gradesLabel: {
    id: 'bonuses.and.grades.label.grades',
    defaultMessage: 'Grades',
  },
  socialPaymentLabel: {
    id: 'bonuses.and.grades.social.paymanet.label.social.paymanet',
    defaultMessage: 'Social Payment / Experience',
  },
});
