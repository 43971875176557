import React from 'react';
import { Route } from 'react-router-dom';

const RouteWithSubRoutes = (route: {
  path: string;
  component: React.FunctionComponent<any>;
  routes?: Record<string, unknown>;
}) => {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
};

export default RouteWithSubRoutes;
