import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as competenciesActions from '../../../actions/competencies.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import moment from 'moment';
import { CompetenceLevelSkillType, CompetenceLevelType } from '../../Competencies/Competencies';
import ModalEditAssessment from '../../../components/Assessments/Modals/ModalEditAssessment';
import * as filtersActions from '../../../actions/filters.actions';
import ModalDeleteAssessment from '../../../components/Assessments/Modals/ModalDeleteAssessment';
import { find } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { TRouteParamId } from '../../../enums/common.enum';
import { UserInfo } from '../../../enums/users.enum';
import { ResultType } from '../../../enums/competencies.enum';
import Avatar from '../../../components/Profile/Avatar';
import { DATE_FORMAT } from '../../../constants/date.constants';
import PoliciesContext from '../../../PoliciesContext';
import { checkPolicies } from '../../../utils/policies.utils';
import { DELETE_ASSESSMENT, UPDATE_ASSESSMENT } from '../../../constants/policies.constants';
import HierarchicalTable from '../../../components/HierarchicalTable';
import { useTableData } from '../../../utils/hooks.utils';
import { getTableCell } from '../../../utils/table.utils';
import { DepthLevels } from '../../../constants/tables.constants';
import { NavLink } from 'react-router-dom';
import { EventsParams } from '../../../enums/params/schedule.params';
import r from '../../../constants/routes.constants';

function AssessmentsInfo({
  assessmentData,
  getAssessment,
  loading,
  editAssessment,
  userList,
  eventList,
  eventsLoading,
  getUsersFilters,
  assessmentDataError,
  cleanAssessmentData,
  deleteAssessment,
  getEventsForTargetEmployee,
  resetErrors,
  lastAssessmentResults,
  getLastAssessmentResults,
  resetLastAssessmentResults,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const intl = useIntl();

  const { id } = useParams<TRouteParamId>();
  const history = useHistory();
  const classes = ['table__data--trainee', 'table__data--junior', 'table__data--middle', 'table__data--senior'];

  const [modalEditAssessmentIsOpen, setModalEditAssessmentIsOpen] = useState(false);
  const [modalDeleteAssessmentIsOpen, setModalDeleteAssessmentIsOpen] = useState(false);

  useEffect(() => {
    getAssessment({ data: id });
    getUsersFilters();
    return () => {
      cleanAssessmentData();
    };
  }, [id]);

  const openEditAssessmentModal = useCallback(() => {
    setModalEditAssessmentIsOpen(true);
  }, []);

  const closeEditAssessmentModal = useCallback(() => {
    setModalEditAssessmentIsOpen(false);
  }, []);

  const openDeleteAssessmentModal = useCallback(() => {
    setModalDeleteAssessmentIsOpen(true);
  }, []);

  const closeDeleteAssessmentModal = useCallback(() => {
    setModalDeleteAssessmentIsOpen(false);
  }, []);

  const onEditHandler = useCallback(() => {
    openEditAssessmentModal();
  }, []);

  const backButtonHandler = useCallback(() => {
    history.push(r.assessments);
  }, []);

  const updateAssessment = useCallback(
    data => {
      editAssessment({
        data: data,
        callback: () => {
          closeEditAssessmentModal();
          getAssessment({ data: id });
        },
      });
    },
    [id],
  );

  const onDeleteAssessmentRequest = useCallback(id => {
    deleteAssessment({
      id,
      callback: () => {
        history.push('/assessments');
      },
    });
  }, []);

  const reviewersValues = useMemo(
    () =>
      assessmentData?.reviewers?.map((el: UserInfo) => (
        <div key={el.id} className="page__event__participant">
          <Avatar userInfo={el} size="medium" externalClass="avatar-participants" isUserPageLink fileSize={72} />
          <span>{el.fullName}</span>
        </div>
      )),
    [assessmentData?.reviewers],
  );

  const organizersValues = useMemo(
    () =>
      assessmentData?.organizers?.map((el: UserInfo) => (
        <div key={el.id} className="page__event__participant">
          <Avatar userInfo={el} size="medium" externalClass="avatar-participants" isUserPageLink fileSize={72} />
          <span>{el.fullName}</span>
        </div>
      )),
    [assessmentData?.organizers],
  );

  const normalizeDateFormat = (date: any, format: string) => {
    return moment(date).isValid() ? `${moment.utc(date).local().format(format)}` : '-';
  };

  const sumScores = useCallback(
    levelId => {
      const scores = assessmentData.results.filter((r: ResultType) => r.levelId === levelId && r.score && r.score > 0);
      return scores.reduce((sum: number, current: ResultType) => sum + current.score, 0);
    },
    [assessmentData?.results],
  );

  const sumMaxScores = useCallback(
    levelId => {
      const index =
        assessmentData.competence && assessmentData.competence.competenceLevels
          ? assessmentData.competence.competenceLevels.findIndex((el: CompetenceLevelType) => el.id === levelId)
          : -1;
      return index !== -1
        ? assessmentData.competence.competenceLevels[index]?.skills.reduce(
            (sum: number, current: CompetenceLevelSkillType) => sum + current.maxScore,
            0,
          )
        : 0;
    },
    [assessmentData],
  );

  const countPercentage = useCallback(
    (firstNum: number, secondNum) => {
      const nominator = Number.isNaN(firstNum) ? 0 : firstNum;
      const denominator = Number.isNaN(secondNum) || '' ? 0 : secondNum;
      return denominator === 0 ? 0 : Math.floor((nominator / denominator) * 100);
    },
    [assessmentData?.results],
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: intl.formatMessage(messages.skillsColumn),
        accessor: 'name',
        headClassName: 'table__head-column--competence-skills',
      },
      {
        id: 'scoreColumn',
        Header: intl.formatMessage(messages.scoreColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: CompetenceLevelType) => (
                <span>{`${sumScores(row.id)} (${countPercentage(sumScores(row.id), sumMaxScores(row.id))}%)`}</span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: CompetenceLevelSkillType) => (
                <div>{`${
                  /* eslint-disable react/prop-types */
                  find(assessmentData.results, value => value.skillId === row?.id)?.score
                }/${row.maxScore}`}</div>
              ),
            },
          ]),

        headClassName: 'table__head-column--competence-max-scope',
      },
    ],
    [assessmentData],
  );

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 0 && row.id < 4) {
      return classes[row.id];
    }
    return '';
  }, []);

  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper fixed-container">
          <div className="page__panel__back-title">
            <Button externalClass="page__back" color="gray" onClick={backButtonHandler}>
              <FormattedMessage {...messages.assessmentsLabel} />
            </Button>
          </div>
          <div className="page__profile-panel__label-options">
            {checkPolicies([UPDATE_ASSESSMENT], policies) && (
              <Button circle color="gray" onClick={onEditHandler}>
                <Icon iconName={'pencil'} externalClass={'button__icon button__icon--bigger'} />
              </Button>
            )}
            {checkPolicies([DELETE_ASSESSMENT], policies) && (
              <Button circle color="gray" onClick={openDeleteAssessmentModal}>
                <Icon iconName={'trash'} externalClass={'button__icon button__icon--bigger'} />
              </Button>
            )}
          </div>
        </div>
        <div className="page__panel__wrapper fixed-container title-wratter">
          <h1 className="page__title page__title--smaller">
            <FormattedMessage {...messages.pageTitle} />
          </h1>
        </div>
      </div>
      <div className="page__inner-content assessments-info">
        {assessmentData && (
          <div className="page__wrapper fixed-container">
            <div className="page__info-block page__info-block--days">
              <Icon iconName={'calendar'} />
              <div className={'page__event__day'}>
                {normalizeDateFormat(assessmentData.assessmentDate, DATE_FORMAT.ll)}
              </div>
            </div>
            <div className="page__info-block page__info-block--info">
              <Icon iconName={'info'} />
              {assessmentData.competence.name}
            </div>
            {assessmentData.eventId && (
              <div className="page__info-block page__info-block--event">
                <Icon iconName={'link'} />
                {assessmentData.event.isUserHaveAccess ? (
                  <NavLink
                    className={'table__type-link'}
                    to={r.eventInformation.replace(':id', `${assessmentData.eventId}`)}
                  >
                    <span>{assessmentData.event.eventPreviewTitle}</span>
                  </NavLink>
                ) : (
                  <span>{assessmentData.event.eventPreviewTitle}</span>
                )}
              </div>
            )}

            <div className="page__info-block page__info-block--target-employee">
              <Icon iconName={'user'} />
              <div className={'page__event__info-title'}>
                <FormattedMessage {...messages.targetMemberLabel} />
              </div>
              <div className={'page__event__employee'}>
                <Avatar
                  userInfo={assessmentData.employee}
                  size="large"
                  externalClass="page__event__employee-avatar"
                  isUserPageLink
                  fileSize={120}
                />
                <div className={'page__event__employee-name'}>{assessmentData.employee.fullName}</div>
              </div>
            </div>
            <div className="page__event__comments">
              <div className="page__info-block page__info-block--organizers">
                <Icon iconName={'users'} externalClass={'page__info-block--organizers-icon'} />
                <div className={'page__event__info-title'}>
                  <FormattedMessage {...messages.organizersTitle} />
                  <span className={'page__event__count'}>{`(${organizersValues.length})`}</span>
                </div>
                <div className={'page__event__participants-wrapper'}>{organizersValues}</div>
              </div>
              <div className="page__info-block page__info-block--organizers">
                <Icon iconName={'users'} externalClass={'page__info-block--organizers-icon'} />
                <div className={'page__event__info-title'}>
                  <FormattedMessage {...messages.reviewersTitle} />
                  <span className={'page__event__count'}>{`(${reviewersValues.length})`}</span>
                </div>
                <div className={'page__event__participants-wrapper'}>{reviewersValues}</div>
              </div>
            </div>
            <HierarchicalTable
              tableData={useTableData(assessmentData.competence.competenceLevels, ['skills'])}
              loading={loading.getOneAssessment}
              error={assessmentDataError}
              externalClass="table--bordered-top fixed"
              tableColumns={tableColumns}
              externalRowClass={handleExternalRowClass}
            />
            <div className={'page__event__status'}>
              <div className={'page__event__status-value'}>Last update</div>
              <div className={'page__event__status-responsible'}>
                <Avatar
                  userInfo={assessmentData.updater}
                  size="small"
                  externalClass="page__event__employee-avatar"
                  isUserPageLink
                  fileSize={48}
                />
                <span> {assessmentData.updater.fullName}</span>
              </div>
              <div className={'page__event__status-date'}>
                {normalizeDateFormat(assessmentData?.lastUpdateDate, 'lll')}
              </div>
            </div>
          </div>
        )}

        {modalEditAssessmentIsOpen && (
          <ModalEditAssessment
            isOpen
            onCloseRequest={closeEditAssessmentModal}
            editAssessment={updateAssessment}
            users={userList}
            assessmentError={assessmentDataError}
            isLoading={loading.editAssessment}
            assessment={assessmentData}
            eventsLoading={eventsLoading}
            events={eventList}
            resetErrors={resetErrors}
            lastAssessmentResults={lastAssessmentResults}
            getLastAssessmentResults={getLastAssessmentResults}
            resetLastAssessmentResults={resetLastAssessmentResults}
            getEventsForTargetEmployee={getEventsForTargetEmployee}
          />
        )}
        {modalDeleteAssessmentIsOpen && (
          <ModalDeleteAssessment
            isOpen
            onCloseRequest={() => closeDeleteAssessmentModal()}
            assessmentError={assessmentDataError}
            assessmentData={assessmentData}
            onDeleteRequest={onDeleteAssessmentRequest}
            isLoading={loading.deleteAssessment}
            resetErrors={resetErrors}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ competencies, filters }: RootState) => ({
  assessmentData: competencies.assessmentData,
  assessmentDataError: competencies.errors?.oneAssessmentDataError,
  loading: competencies.loading,
  userList: filters.usersFilter.users,
  eventList: filters.eventsFilter.events,
  eventsLoading: filters.eventsFilter.loading,
  lastAssessmentResults: competencies.lastAssessmentData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getAssessment: (data: Record<string, unknown>) => dispatch(competenciesActions.getAssessment(data)),
  getUsersFilters: () => dispatch(filtersActions.getUsersFilter()),
  editAssessment: (data: any) => dispatch(competenciesActions.editAssessment(data)),
  deleteAssessment: (data: { id: string; callback: () => void }) =>
    dispatch(competenciesActions.deleteAssessment(data)),
  cleanAssessmentData: () => dispatch(competenciesActions.cleanOneAssessmentData()),
  getEventsForTargetEmployee: (data: Partial<EventsParams>) => dispatch(filtersActions.getEventsFilter(data)),
  resetErrors: () => dispatch(competenciesActions.resetErrors()),
  getLastAssessmentResults: (data: any) => dispatch(competenciesActions.getLastAssessmentResults(data)),
  resetLastAssessmentResults: () => dispatch(competenciesActions.resetLastAssessmentResults()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AssessmentsInfo);
