import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ReactSortable, ItemInterface } from 'react-sortablejs';
import isEmpty from 'lodash-es/isEmpty';
import Icon from '../Icon';
import Dropdown from '../Dropdown';
import { SortParams, SortParamsType } from '../../enums/params.enum';
import { isArray, isFunction } from 'lodash-es';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';
import { userHintId } from '../Hint/utils';
import Hint from '../Hint';

export type DirectionType = 'DESC' | 'ASC';

export type ParamsProps = {
  sortBy: string;
  direction: DirectionType;
};

export type TableColType<DataType> = {
  name: React.ReactNode;
  sortName?: string;
  aditionalSort?: (sortField: string, direction: DirectionType) => SortParamsType | SortParamsType[];
  modifier: (row: DataType, rowIndex?: number) => any;
  blockModifier?: boolean;
  className?: string | ((rowData: DataType, rowIndex: number, dataArr: DataType[]) => any);
};

type TableProps<DataType> = {
  tableColumns: TableColType<DataType>[];
  tableData: (DataType & ItemInterface)[];
  externalClass?: string;
  dropdownClass?: string;
  params?: SortParams;
  onSort?: (field: string, direction: DirectionType, aditionalSort?: SortParamsType | SortParamsType[]) => void;
  sortable?: boolean;
  onDragSort?: (data: any) => void;
  tableActions: any[];
  loading: boolean;
  error: string | null;
  hideHead?: boolean;
  overflowDropdown?: boolean;
  renderDropdown?: (row: any) => boolean;
  tableHeaderItems?: { name: string; className: string; colspan?: number; rowspan?: number; sortName: string }[];
  hasRowHover?: boolean;
};

function Table<DataType>({
  externalClass,
  dropdownClass,
  tableColumns,
  tableData,
  params,
  onSort,
  tableActions,
  loading,
  error,
  onDragSort,
  sortable,
  hideHead,
  overflowDropdown = true,
  hasRowHover = true,
  renderDropdown,
  tableHeaderItems,
}: TableProps<DataType>) {
  const policies = useContext(PoliciesContext);
  const [sortField, setSortField] = useState(isArray(params?.sortBy) ? params?.sortBy[0].sortBy : params?.sortBy);
  const [sortDir, setSortDir] = useState(isArray(params?.sortBy) ? params?.sortBy[0].direction : params?.direction);
  const [list, setList] = useState(tableData || []);

  useEffect(() => {
    setList(tableData);
  }, [tableData]);

  function handleSort(
    sortName?: string,
    aditionalSort?: (sortField: string, direction: DirectionType) => SortParamsType | SortParamsType[],
  ) {
    if (!sortName || !onSort) return;
    const dir = sortDir === 'ASC' && sortName === sortField ? 'DESC' : 'ASC';
    setSortField(sortName);
    setSortDir(dir);
    onSort(sortName, dir, aditionalSort ? aditionalSort(sortName, dir) : undefined);
  }

  const defaultClass = classNames('table', {
    [externalClass as string]: Boolean(externalClass),
    loading: loading,
  });

  const rowClass = classNames('table__row', {
    hasHover: hasRowHover,
  });

  const iconClass = classNames({
    'ascending-order': sortDir === 'ASC',
  });

  const captionClass = classNames('table__no-data', {
    'error': !!error,
    'loading': loading,
    'no-data': isEmpty(tableData),
    'hidden': !isEmpty(tableData),
  });

  const ConditionalWrapper = useCallback(
    ({ condition, children, list }) =>
      condition ? (
        <ReactSortable
          tag={'tbody'}
          list={list}
          setList={setList}
          onSort={onDragSort}
          className={'table__body sortable-table'}
          handle={'.grip-vertical'}
          animation={200}
        >
          {children}
        </ReactSortable>
      ) : (
        <tbody className={'table__body'}>{children}</tbody>
      ),
    [onDragSort],
  );

  const tableActionsList = useMemo(
    () =>
      tableActions.filter(item => (item.verifiablePolicies ? checkPolicies(item.verifiablePolicies, policies) : item)),
    [tableActions, policies],
  );

  return (
    <>
      {!isEmpty(tableData) && (
        <table className={defaultClass}>
          {!hideHead && (
            <thead className="table__head">
              {tableHeaderItems ? (
                <tr className={'table__row table__row-head'}>
                  {sortable && <th className="table__head-column--grip" />}
                  {tableHeaderItems?.map(({ name, colspan, rowspan, sortName, className }, rowIndex) => (
                    <th
                      className={classNames(
                        'table__head-column',
                        { table__sort: sortName, active: sortName === sortField },
                        className,
                      )}
                      key={rowIndex + rowIndex}
                      onClick={() => handleSort(sortName)}
                      colSpan={colspan}
                      rowSpan={rowspan}
                    >
                      {name}
                      {sortName && sortField === sortName && <Icon iconName={'table-sort'} externalClass={iconClass} />}
                    </th>
                  ))}
                </tr>
              ) : null}
              <tr className={rowClass}>
                {sortable && <th className={'table__head-column--grip'} />}
                {tableColumns.map(({ name, sortName, aditionalSort }, rowIndex) => (
                  <th
                    className={classNames('table__head-column', { table__sort: sortName })}
                    key={`${rowIndex}`}
                    onClick={() => handleSort(sortName, aditionalSort)}
                  >
                    {name}
                    {sortName && sortField === sortName && <Icon iconName={'table-sort'} externalClass={iconClass} />}
                  </th>
                ))}
                {!!tableActionsList.length && <th className={'table__head-column--dropdown'} />}
              </tr>
            </thead>
          )}
          <ConditionalWrapper condition={sortable} list={list}>
            {list.map((row, rowIndex) => (
              <tr className={rowClass} key={`tr-${rowIndex}`}>
                {sortable && (
                  <td className={'table__data table__sort'}>
                    <Icon iconName={'grip-vertical'} />
                  </td>
                )}
                {tableColumns.map(({ modifier, className }, tableDataIndex) => {
                  const cellContent = modifier(row, rowIndex);
                  return (
                    <td
                      className={classNames(
                        isFunction(className) ? className(row, rowIndex, list) : className,
                        'table__data',
                      )}
                      key={`${tableDataIndex}`}
                    >
                      {cellContent ? cellContent : '-'}
                    </td>
                  );
                })}
                {!!tableActionsList.filter(item => (isFunction(item.label) ? item.label(row) !== null : true)).length &&
                  (!renderDropdown || (renderDropdown && renderDropdown(row))) && (
                    <td className={'table__data table__actions-block'}>
                      {}
                      <Dropdown
                        dropdownClass={classNames('dropdown--no-bg', dropdownClass)}
                        dropdownToggle={<Icon iconName={'dots'} externalClass={'dropdown__button-main-icon'} />}
                        dropdownList={tableActionsList.filter(item =>
                          isFunction(item.label) ? item.label(row) !== null : true,
                        )}
                        dropdownInfo={row}
                        overflow={overflowDropdown}
                      />
                    </td>
                  )}
              </tr>
            ))}
          </ConditionalWrapper>
        </table>
      )}
      <caption className={captionClass} />
      <Hint customId={userHintId} dependencyList={[tableColumns]} className="table__user-name tooltip" />
    </>
  );
}

export default React.memo(Table) as typeof Table;
