import React, { useState, useCallback, useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import * as brandingActions from '../../actions/branding.actions';
import Input from '../../components/Input';
import FileInput from '../../components/FileInput';
import ColorInput from '../../components/ColorInput';
import Switch from '../../components/Switch';
import Select from '../../components/Select';
import classNames from 'classnames';
import BrandingContext from '../../BrandingContext';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { BRANDING_SCHEMA, FONT_OPTIONS, BrandingData } from '../../enums/branding.enum';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import Icon from '../../components/Icon';
import { createFormData } from '../../utils/data.utils';
import { checkPolicies } from '../../utils/policies.utils';
import { UPDATE_PORTAL_BRANDING } from '../../constants/policies.constants';
import { useSetFieldsErrors } from '../../utils/hooks.utils';
import PoliciesContext from '../../PoliciesContext';
import { scrollToError } from '../../utils';

function Branding({ data, brandingErrors, editPortalBranding }: ConnectedProps<typeof connector>) {
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
  const branding = useContext(BrandingContext);
  const policies = useContext(PoliciesContext);
  const intl = useIntl();

  const { values, setFieldValue, handleChange, submitForm, errors, touched, setFieldError, setTouched } = useFormik({
    initialValues: new BrandingData({ logo: null, favicon: null, portalBrandingDto: data }),
    validationSchema: BRANDING_SCHEMA,
    validateOnChange: true,
    enableReinitialize: true,
    validate: scrollToError,
    onSubmit: data => {
      const brandingData = createFormData({ portalBrandingDto: data.portalBrandingDto });
      data.logo && brandingData.append('logo', data.logo);
      data.favicon && brandingData.append('favicon', data.favicon);
      editPortalBranding(brandingData);
    },
  });

  useSetFieldsErrors(brandingErrors, setFieldError);

  const resetLogo = useCallback(() => {
    if (values.portalBrandingDto.isLogoUrl) {
      setFieldValue('portalBrandingDto.logoUrl', '');
    } else {
      setFieldValue('portalBrandingDto.logoFile', null);
      setFieldValue('portalBrandingDto.isDeleteLogo', true);
    }
    sumbitFormAfterValidate();
  }, [values.portalBrandingDto.isLogoUrl]);

  const resetRightColumn = useCallback(() => {
    if (values.portalBrandingDto.isFaviconUrl) {
      setFieldValue('portalBrandingDto.faviconUrl', '');
    } else {
      setFieldValue('portalBrandingDto.faviconFile', null);
      setFieldValue('portalBrandingDto.isDeleteFavicon', true);
    }
    sumbitFormAfterValidate();
  }, [values.portalBrandingDto.isFaviconUrl]);

  const handleFontTitles = useCallback(e => setFieldValue('portalBrandingDto.titles', e.value), []);
  const handleBodyTitles = useCallback(e => setFieldValue('portalBrandingDto.body', e.value), []);

  const titlesValue = useMemo(() => FONT_OPTIONS.find(({ value }) => value === values.portalBrandingDto?.titles), [
    FONT_OPTIONS,
    values,
  ]);
  const bodyValue = useMemo(() => FONT_OPTIONS.find(({ value }) => value === values.portalBrandingDto?.body), [
    FONT_OPTIONS,
    values,
  ]);
  const sumbitFormAfterValidate = useCallback(() => {
    BRANDING_SCHEMA.isValid(values).then(valid => {
      if (valid) {
        submitForm();
      }
    });
  }, [values]);

  const handleFileUpload = useCallback((event, path: 'logo' | 'favicon') => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file?.type;
      let isCorrectExt = true;
      if (path === 'logo' && !fileType?.includes('image/png') && !fileType?.includes('image/svg+xml')) {
        isCorrectExt = false;
      }
      if (path === 'favicon' && !fileType?.includes('image/png')) {
        isCorrectExt = false;
      }
      if (!isCorrectExt && path === 'logo') {
        setFieldError('logo', intl.formatMessage(messages.uploadLogoExtensionError));
      }

      if (!isCorrectExt && path === 'favicon') {
        setFieldError('favicon', intl.formatMessage(messages.uploadFaviconExtensionError));
      }

      if (isCorrectExt) {
        const fr = new FileReader();
        fr?.readAsDataURL(file);
        fr.onload = () => {
          setFieldValue(
            path === 'logo' ? 'portalBrandingDto.logoFile.url' : 'portalBrandingDto.faviconFile.url',
            fr.result,
          );
        };
        setFieldValue(path, file);
        sumbitFormAfterValidate();
      }
    }
  }, []);

  const handleUploadFaviconTypeChange = useCallback(e => {
    if (e === 'favicon-url') {
      setFieldValue('portalBrandingDto.isFaviconUrl', true);
    } else {
      setFieldValue('portalBrandingDto.isFaviconUrl', false);
    }
    sumbitFormAfterValidate();
  }, []);

  const handleUploadLogoTypeChange = useCallback(e => {
    if (e === 'logo-url') {
      setFieldValue('portalBrandingDto.isLogoUrl', true);
    } else {
      setFieldValue('portalBrandingDto.isLogoUrl', false);
    }
    sumbitFormAfterValidate();
  }, []);

  const accessUpdate = useMemo(() => checkPolicies([UPDATE_PORTAL_BRANDING], policies), [policies]);

  return (
    <>
      <div className="page__panel">
        <div className="page__wrapper fixed-container">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper page__wrapper--horizontal fixed-container">
          <div className="page__setting-column">
            <div className="page__setting-item">
              <div className="page__setting-title">
                <FormattedMessage {...messages.settingColorsTitle} />
              </div>
              <div className="page__setting-wrapper">
                <table className="page__setting-colors-table">
                  <tbody className="page__setting-colors-table__body">
                    <tr className="page__setting-colors-item">
                      <td className="page__setting-colors-name">
                        <FormattedMessage {...messages.settingGlobalAccent} />:
                      </td>
                      <td className="page__setting-colors-value">
                        <div className="form__color-block">
                          <ColorInput
                            name={'portalBrandingDto.globalAccents'}
                            id={'portalBrandingDto.globalAccents'}
                            onChange={e => {
                              handleChange(e);
                              if (timeoutId) clearTimeout(timeoutId);
                              setTimeoutId(
                                setTimeout(() => {
                                  submitForm();
                                  setTouched({ ...touched });
                                }, 1000),
                              );
                            }}
                            defaultValue={values.portalBrandingDto?.globalAccents || ''}
                            disabled={!accessUpdate}
                          />
                        </div>
                        <div className="page__setting-colors-note">
                          <FormattedMessage {...messages.settingGlobalAccentNote} />
                        </div>
                      </td>
                    </tr>
                    <tr className="page__setting-colors-item">
                      <td className="page__setting-colors-name">
                        <FormattedMessage {...messages.settingHeaderBackground} />:
                      </td>
                      <td className="page__setting-colors-value">
                        <div className="form__color-block">
                          <ColorInput
                            name={'portalBrandingDto.headerBackground'}
                            id={'portalBrandingDto.headerBackground'}
                            onChange={e => {
                              handleChange(e);
                              if (timeoutId) clearTimeout(timeoutId);
                              setTimeoutId(
                                setTimeout(() => {
                                  submitForm();
                                  setTouched({ ...touched });
                                }, 1000),
                              );
                            }}
                            defaultValue={values.portalBrandingDto?.headerBackground || ''}
                            disabled={!accessUpdate}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="page__setting-colors-item">
                      <td className="page__setting-colors-name">
                        <FormattedMessage {...messages.settingHeaderForeground} />:
                      </td>
                      <td className="page__setting-colors-value">
                        <div className="form__color-block">
                          <ColorInput
                            name={'portalBrandingDto.headerForeground'}
                            id={'portalBrandingDto.headerForeground'}
                            onChange={e => {
                              handleChange(e);
                              if (timeoutId) clearTimeout(timeoutId);
                              setTimeoutId(
                                setTimeout(() => {
                                  submitForm();
                                  setTouched({ ...touched });
                                }, 1000),
                              );
                            }}
                            defaultValue={values.portalBrandingDto?.headerForeground || ''}
                            disabled={!accessUpdate}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="page__setting-colors-item">
                      <td className="page__setting-colors-name">
                        <FormattedMessage {...messages.settingNavigationShadow} />:
                      </td>
                      <td className="page__setting-colors-value">
                        <Switch
                          onChange={e => {
                            setFieldValue('portalBrandingDto.headerShadow', e.target.checked);
                            submitForm();
                            setTouched({ ...touched });
                          }}
                          checked={values.portalBrandingDto?.headerShadow || false}
                          name={'portalBrandingDto.headerShadow'}
                          disabled={!accessUpdate}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="page__setting-item">
              <div className="page__setting-title">
                <FormattedMessage {...messages.settingFontsTitle} />
              </div>
              <div className="page__setting-wrapper">
                <div className="form__input-block">
                  <Select
                    id={'portalBrandingDto.titles'}
                    options={FONT_OPTIONS}
                    label={intl.formatMessage(messages.titlesInput)}
                    value={titlesValue}
                    handleChange={e => {
                      handleFontTitles(e);
                      submitForm();
                      setTouched({ ...touched });
                    }}
                    isDisabled={!accessUpdate}
                  />
                </div>
                <div className="form__input-block">
                  <Select
                    id={'portalBrandingDto.body'}
                    options={FONT_OPTIONS}
                    label={intl.formatMessage(messages.bodyInput)}
                    value={bodyValue}
                    handleChange={e => {
                      handleBodyTitles(e);
                      submitForm();
                      setTouched({ ...touched });
                    }}
                    isDisabled={!accessUpdate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="page__setting-column">
            <div className="page__setting-item">
              <div className="page__setting-title">
                <FormattedMessage {...messages.settingLogoTitle} />
                <button
                  className="page__setting-reset-btn"
                  style={{ color: branding.globalAccents }}
                  onClick={resetLogo}
                  disabled={!accessUpdate}
                >
                  <FormattedMessage {...messages.resetButton} />
                </button>
              </div>
              <div className="page__setting-wrapper">
                <RadioButtonGroup
                  groupItems={[
                    {
                      id: 'logo-upload',
                      label: (
                        <>
                          <Icon iconName={'upload'} externalClass={'form__radio-icon'} />
                          <FormattedMessage {...messages.uploadInput} />
                        </>
                      ),
                    },
                    {
                      id: 'logo-url',
                      label: (
                        <>
                          <Icon iconName={'link'} externalClass={'form__radio-icon'} />
                          <FormattedMessage {...messages.urlLabel} />
                        </>
                      ),
                    },
                  ]}
                  name="logo-radio"
                  onChange={e => handleUploadLogoTypeChange((e.target as HTMLElement).id)}
                  checked={values.portalBrandingDto.isLogoUrl ? 'logo-url' : 'logo-upload'}
                  disabled={!accessUpdate}
                />
                {values.portalBrandingDto?.logoUrl && values.portalBrandingDto.isLogoUrl && (
                  <img src={values.portalBrandingDto.logoUrl} />
                )}
                {values.portalBrandingDto?.logoFile?.url && !values.portalBrandingDto.isLogoUrl && (
                  <img src={values.portalBrandingDto.logoFile.url} />
                )}
              </div>
              <div className={classNames({ hidden: !values.portalBrandingDto.isLogoUrl })}>
                <Input
                  id={'portalBrandingDto.logoUrl'}
                  name={'portalBrandingDto.logoUrl'}
                  label={intl.formatMessage(messages.urlLabel)}
                  onChange={e => {
                    handleChange(e);
                    BRANDING_SCHEMA.isValid(values).then(valid => {
                      if (valid) {
                        submitForm();
                        setTouched({ ...touched, portalBrandingDto: { ...touched.portalBrandingDto, logoUrl: true } });
                      }
                    });
                  }}
                  defaultValue={values.portalBrandingDto?.logoUrl || ''}
                  hasError={Boolean(errors?.portalBrandingDto?.logoUrl && touched.portalBrandingDto?.logoUrl)}
                  //@ts-ignore
                  errorMessage={errors?.portalBrandingDto?.logoUrl}
                  disabled={!accessUpdate}
                />
              </div>

              <div className={classNames('form__input-block', { hidden: values.portalBrandingDto.isLogoUrl })}>
                <FileInput
                  name={'logo'}
                  onChange={e => handleFileUpload(e, 'logo')}
                  accept=".svg, image/png"
                  errorMessage={errors?.logo}
                  disabled={!accessUpdate}
                />
              </div>
            </div>
            <div className="page__setting-item">
              <div className="page__setting-title">
                <FormattedMessage {...messages.settingFaviconTitle} />
                <button
                  className="page__setting-reset-btn"
                  style={{ color: branding.globalAccents }}
                  onClick={resetRightColumn}
                  disabled={!accessUpdate}
                >
                  <FormattedMessage {...messages.resetButton} />
                </button>
              </div>
              <div className="page__setting-wrapper">
                <RadioButtonGroup
                  groupItems={[
                    {
                      id: 'favicon-upload',
                      label: (
                        <>
                          <Icon iconName={'upload'} externalClass={'form__radio-icon'} />
                          <FormattedMessage {...messages.uploadInput} />
                        </>
                      ),
                    },
                    {
                      id: 'favicon-url',
                      label: (
                        <>
                          <Icon iconName={'link'} externalClass={'form__radio-icon'} />
                          <FormattedMessage {...messages.urlLabel} />
                        </>
                      ),
                    },
                  ]}
                  name="favicon-radio"
                  onChange={e => handleUploadFaviconTypeChange((e.target as HTMLElement).id)}
                  checked={values.portalBrandingDto.isFaviconUrl ? 'favicon-url' : 'favicon-upload'}
                  disabled={!accessUpdate}
                />
                {values.portalBrandingDto?.faviconUrl && values.portalBrandingDto.isFaviconUrl && (
                  <img src={values.portalBrandingDto.faviconUrl} />
                )}
                {values.portalBrandingDto?.faviconFile?.url && !values.portalBrandingDto.isFaviconUrl && (
                  <img src={values.portalBrandingDto.faviconFile.url} />
                )}
              </div>
              <div className={classNames({ hidden: !values.portalBrandingDto.isFaviconUrl })}>
                <Input
                  id={'portalBrandingDto.faviconUrl'}
                  name={'portalBrandingDto.faviconUrl'}
                  label={intl.formatMessage(messages.urlLabel)}
                  onChange={e => {
                    handleChange(e);
                    BRANDING_SCHEMA.isValid(values).then(valid => {
                      if (valid) {
                        submitForm();
                        setTouched({
                          ...touched,
                          portalBrandingDto: { ...touched.portalBrandingDto, faviconUrl: true },
                        });
                      }
                    });
                  }}
                  defaultValue={values.portalBrandingDto?.faviconUrl || ''}
                  hasError={Boolean(errors.portalBrandingDto?.faviconUrl && touched.portalBrandingDto?.faviconUrl)}
                  //@ts-ignore
                  errorMessage={errors?.portalBrandingDto?.faviconUrl}
                  disabled={!accessUpdate}
                />
              </div>
              <div className={classNames('form__input-block', { hidden: values.portalBrandingDto.isFaviconUrl })}>
                <FileInput
                  name={'favicon'}
                  errorMessage={errors?.favicon}
                  onChange={e => handleFileUpload(e, 'favicon')}
                  accept="image/png"
                  disabled={!accessUpdate}
                />
              </div>
            </div>
            {/* <div className="page__setting-item">
              <div className="page__setting-title">
                <FormattedMessage {...messages.settingFontsTitle} />
              </div>
              <div className="page__setting-wrapper">
                <div className="form__input-block">
                  <Select
                    id={'portalBrandingDto.titles'}
                    options={FONT_OPTIONS}
                    label={intl.formatMessage(messages.titlesInput)}
                    value={titlesValue}
                    handleChange={e => {
                      handleFontTitles(e);
                      submitForm();
                      setTouched({ ...touched });
                    }}
                    isDisabled={!accessUpdate}
                  />
                </div>
                <div className="form__input-block">
                  <Select
                    id={'portalBrandingDto.body'}
                    options={FONT_OPTIONS}
                    label={intl.formatMessage(messages.bodyInput)}
                    value={bodyValue}
                    handleChange={e => {
                      handleBodyTitles(e);
                      submitForm();
                      setTouched({ ...touched });
                    }}
                    isDisabled={!accessUpdate}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ branding }: RootState) => ({
  data: branding.brandingData,
  brandingErrors: branding.errors.brandingError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  editPortalBranding: (data: any) => dispatch(brandingActions.editPortalBranding(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Branding);
