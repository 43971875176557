import { getDateRange } from './../utils/index';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import pick from 'lodash-es/pick';
import moment from 'moment';
import * as yup from 'yup';
import { MESSENGER_TYPES } from '../constants/users.constants';
import { head, isNumber } from 'lodash-es';
import { DATE_FORMAT } from '../constants/date.constants';
import { DepartmentsInfoType } from '../types/libraries';
import { EUserPollStatus } from './questionnaires.enum';

export const USER_INFO_VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().trim().required('Required'),
  secondName: yup.string().trim().required('Required'),
  department: yup
    .object({
      id: yup.string(),
      displayName: yup.string().trim(),
    })
    .typeError('Required')
    .required('Required'),
  office: yup
    .object({
      id: yup.string(),
      name: yup.string().trim(),
    })
    .typeError('Required')
    .required('Required'),
  jiraUser: yup.number().nullable(),
  email: yup.string().trim().email('Invalid email').required('Required'),
  messengerContacts: yup.array(
    yup.object({
      messengerType: yup.string().oneOf(Object.keys(MESSENGER_TYPES)),
      ref: yup.string().trim().max(40, 'No more than 40 characters').required('Required'),
    }),
  ),
  emails: yup.array(yup.object({ email: yup.string().trim().email('Invalid email'), primary: yup.boolean() })),
  phones: yup.array(yup.string().max(20, 'No more than 20 characters').trim()),
  companyPositionHistory: yup.array().notRequired(),
  username: yup.string().trim().required('Required'),
});

export const USER_CREATE_INFO_VALIDATION_SCHEMA = yup.object().shape({
  ...USER_INFO_VALIDATION_SCHEMA.fields,
  password: yup.string().trim().required('Required'),
  username: yup.string().trim().required('Required'),
});

const getCurrentAbsence = (absences: any[] | null) => {
  return absences?.reduce(
    (previousValue: any, currentValue: any) =>
      moment(previousValue.startDate) <= moment(currentValue.startDate) ? previousValue : currentValue,
    absences[0],
  );
};

export type PhotoCropSetting = { x: number; y: number; width: number; height: number };

export class UserPreviewInfo {
  active: boolean;
  firstName: string;
  id: string;
  photo: UserPhoto;
  secondName: string;
  department: DepartmentsInfoType;
  pollStatus: EUserPollStatus;
  office: {
    id: string;
    name: string;
    isDelete: boolean;
  };
  officeId: string;

  constructor(user?: unknown) {
    this.active = get(user, 'active', true);
    this.firstName = get(user, 'firstName', '');
    this.id = get(user, 'id', '');
    this.photo = new UserPhoto(get(user, 'photo', null));
    this.secondName = get(user, 'secondName', '');
    this.department = get(user, 'department', {
      id: '',
      displayName: '',
    });
    this.pollStatus = get(user, 'pollStatus', EUserPollStatus.NOT_STARTED);
    this.office = get(user, 'office', {
      id: '',
      name: '',
    });
    this.officeId = get(user, 'officeId');
  }

  get fullName() {
    return `${this.firstName} ${this.secondName}`;
  }

  photoUrl(size?: ThumbnailsSize) {
    if (!size || this.photo.thumbnails.length === 0) {
      return this.photo.URL;
    }
    const url = this.photo.thumbnails.find((thum: any) => thum.size === size)?.url;
    return url;
  }
}

export class UserInfo extends UserPreviewInfo {
  absence: AbsenceEvent;
  recognitionsCount: number;
  email: string;
  password: string;
  username: string;
  emails: {
    email: string;
    primary: boolean;
  }[];
  phones: string[];
  messengerContacts: {
    messengerType: string;
    ref: string;
  }[];
  permissions: string[];
  jiraUser: number;
  jiraUsername: string;
  includedInPlanning: boolean;
  companyPosition: {
    id: string;
    displayName: string;
  };
  department: {
    id: string;
    displayName: string;
  };
  // TODO implement full description
  companyPositionHistory: {
    companyPositionId: string;
  }[];
  // TODO change when user events will be implemented
  event: {
    duration: string;
    type: string;
  };

  constructor(user?: unknown) {
    super(user);
    this.absence = getCurrentAbsence(get(user, 'absences', null));
    this.recognitionsCount = get(user, 'recognitionsCount', 0);
    this.email = get(user, 'email', '');
    this.password = get(user, 'password', '');
    this.username = get(user, 'username', '');
    this.emails = get(user, 'emails', []);
    this.phones = get(user, 'phones', []);
    this.messengerContacts = get(user, 'messengerContacts', []);
    this.permissions = get(user, 'permissions', []);
    this.jiraUser = get(user, 'jiraUser', null);
    this.jiraUsername = get(user, 'jiraUsername', '');
    this.companyPosition = get(user, 'companyPosition', null);
    this.department = get(user, 'department', null);
    this.office = get(user, 'office', null);
    this.companyPositionHistory = get(user, 'companyPositionHistory', []);
    this.includedInPlanning = get(user, 'includedInPlanning', null);
    this.event = get(user, 'event', null);
  }

  get initials() {
    return `${this.firstName.charAt(0)}${this.secondName.charAt(0)}`;
  }

  get showRecCount() {
    return Boolean(this.recognitionsCount);
  }

  get showRecCountInUserTab() {
    return isNumber(this.recognitionsCount);
  }

  get showAbsences() {
    return Boolean(this.absence);
  }

  get positionName() {
    return this.companyPosition?.displayName || '-';
  }

  get departmentName() {
    return this.department?.displayName || '-';
  }

  get officeName() {
    return this.office?.name || '-';
  }

  get renderPhones() {
    return !isEmpty(this.phones);
  }

  get secondaryEmails() {
    return this.emails?.filter(email => !email.primary);
  }

  get renderSecondaryEmails() {
    return !isEmpty(this.secondaryEmails);
  }

  get otherContacts() {
    return sortBy(this.messengerContacts, ({ messengerType }) => messengerType === MESSENGER_TYPES.OTHER);
  }

  get skypeContact() {
    return this.messengerContacts?.find(el => el.messengerType === MESSENGER_TYPES.SKYPE)?.ref || '-';
  }

  get jiraName() {
    return this.jiraUsername || '-';
  }

  get phoneNumber() {
    return head(this.phones) || '-';
  }

  get absencePeriod() {
    return getDateRange(this.absence.startDate, this.absence.endDate);
  }

  get userUpdateInfo() {
    return {
      ...pick(this, [
        'active',
        'firstName',
        'secondName',
        'jiraUser',
        'email',
        'messengerContacts',
        'emails',
        'phones',
        'username',
      ]),
      departmentId: this.department.id,
      officeId: this.office.id,
    };
  }
}

export const USER_PERSONAL_VALIDATION_SCHEMA = yup.object({
  birthday: yup.string().nullable(),
  education: yup.string().trim().nullable(),
  courses: yup.string().trim().nullable(),
  hobbies: yup.string().trim().nullable(),
  booksAndLiterature: yup.string().trim().nullable(),
  music: yup.string().trim().nullable(),
  lifeValues: yup.string().trim().nullable(),
  lifeMotto: yup.string().trim().nullable(),
  likes: yup.string().trim().nullable(),
  dislikes: yup.string().trim().nullable(),
});

export class UserPersonalInfo {
  birthday: string;
  booksAndLiterature: string;
  courses: string;
  dislikes: string;
  education: string;
  hobbies: string;
  lifeMotto: string;
  lifeValues: string;
  likes: string;
  music: string;

  constructor(user?: unknown) {
    this.birthday = get(user, 'birthday', null);
    this.booksAndLiterature = get(user, 'booksAndLiterature', '');
    this.courses = get(user, 'courses', '');
    this.dislikes = get(user, 'dislikes', '');
    this.education = get(user, 'education', '');
    this.hobbies = get(user, 'hobbies', '');
    this.lifeMotto = get(user, 'lifeMotto', '');
    this.lifeValues = get(user, 'lifeValues', '');
    this.likes = get(user, 'likes', '');
    this.music = get(user, 'music', '');
  }

  get userBirthday() {
    const date = moment(this.birthday);
    return date.isValid() ? `${date.format(DATE_FORMAT.ll)} (${moment().diff(date, 'years')})` : '-';
  }

  get userEducation() {
    return this.education || '-';
  }

  get userCourses() {
    return this.courses || '-';
  }

  get userHobbies() {
    return this.hobbies || '-';
  }

  get userBooks() {
    return this.booksAndLiterature || '-';
  }

  get userMusic() {
    return this.music || '-';
  }

  get userLifeValues() {
    return this.lifeValues || '-';
  }

  get userLifeMotto() {
    return this.lifeMotto || '-';
  }

  get userLikes() {
    return this.likes || '-';
  }

  get userDislikes() {
    return this.dislikes || '-';
  }
}

export const USER_PROFESSIONAL_VALIDATION_SCHEMA = yup.object({
  hrInfo: yup.array(
    yup.object({
      hired: yup.string().nullable().required('Required'),
      endTrialPeriod: yup.string().nullable().required('Required'),
      dismissal: yup.string().nullable(),
      hrCuratorId: yup.string().nullable().required('Required'),
    }),
  ),
  previousExperience: yup
    .object({
      years: yup.number().typeError('Invalid period'),
      months: yup.number().typeError('Invalid period'),
      days: yup.number().typeError('Invalid period'),
    })
    .nullable(),
  workHours: yup.array(
    yup.object({
      dateFrom: yup
        .string()
        .nullable()
        .required('Required')
        .test((value: any, testContext: any) => {
          const hrInfo = testContext.from[1].value.hrInfo;
          const currentHrInfo = hrInfo.reduce(
            (prev: any, current: any) => (moment(prev.hired) > moment(current.hired) ? prev : current),
            hrInfo[0],
          );
          const date = moment(value);
          if (date < moment(currentHrInfo.hired)) {
            return testContext.createError({
              path: testContext.path,
              message: 'Date must be after hired date',
            });
          }
          if (date > moment(currentHrInfo.dismissal)) {
            return testContext.createError({
              path: testContext.path,
              message: 'Date must be before dismissal date',
            });
          }
          return true;
        }),
      hours: yup.number().required('Required'),
    }),
  ),
  specializations: yup.array(),
  employeeGroupIds: yup.array(),
});

export class UserProfessionalInfo {
  grade: {
    id: string;
    name: string;
    level: number;
  };
  hrInfo: {
    dismissal: string;
    endTrialPeriod: string;
    hired: string;
    hrCuratorId: string;
    hrCurator: UserInfo;
  }[];
  previousExperience: {
    days: number;
    months: number;
    years: number;
  };
  specializations: {
    main: boolean;
    specializationDto: {
      id: string;
      name: string;
    };
  }[];
  workHours: {
    dateFrom: string;
    hours: number;
    id: string;
  }[];
  employeeGroups: { id: string; name: string; userIds: string[] }[];
  employeeGroupIds: string[];

  constructor(user?: unknown) {
    this.grade = get(user, 'grade', null);
    this.hrInfo = get(user, 'hrInfo', []).map((hrInfo: any) => ({
      ...hrInfo,
      hrCurator: new UserInfo(hrInfo.hrCurator),
    }));
    this.previousExperience = get(user, 'previousExperience', null);
    this.specializations = get(user, 'specializations', []);
    this.workHours = get(user, 'workHours', []);
    this.employeeGroups = get(user, 'employeeGroups', []);
    this.employeeGroupIds = get(user, 'employeeGroupIds', []);
  }

  get userShowHr() {
    return !isEmpty(this.hrInfo);
  }

  get userHrInfo() {
    return this.hrInfo.map(({ hired, endTrialPeriod, dismissal, hrCurator }) => ({
      hiredDate: moment(hired).format(DATE_FORMAT.ll),
      endTrialDate: moment(endTrialPeriod).format(DATE_FORMAT.ll),
      dismissalDate: moment(dismissal).isValid() ? moment(dismissal).format(DATE_FORMAT.ll) : null,
      hrPhoto: hrCurator.photoUrl,
      hrInitials: hrCurator.initials,
      hrName: hrCurator.fullName,
      hrCurator: hrCurator,
    }));
  }

  get userWorkHours() {
    return !isEmpty(this.workHours)
      ? this.workHours
          .sort((a, b) => new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime())
          .map(({ dateFrom, hours, id }) => ({
            id: id,
            value: `${moment(dateFrom).format(DATE_FORMAT.ll)} - ${hours} hr`,
          }))
      : [{ id: '', value: '-' }];
  }

  get userGrade() {
    return this.grade?.name || this.grade?.level || '-';
  }

  get userPreviousExp() {
    return !isEmpty(this.previousExperience)
      ? `${this.previousExperience.years}y ${this.previousExperience.months}m ${this.previousExperience.days}d`
      : '-';
  }

  get userPrevExpMask() {
    return !isEmpty(this.previousExperience)
      ? `${this.previousExperience.years.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
        })}y ${this.previousExperience.months.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
        })}m ${this.previousExperience.days.toLocaleString('en-US', { minimumIntegerDigits: 2 })}d`
      : '';
  }

  get userMainSpecialization() {
    const mainSpecializations = this.specializations.filter(({ main }) => main);
    return !isEmpty(mainSpecializations)
      ? mainSpecializations.map(({ specializationDto }) => specializationDto.name).join(', ')
      : '-';
  }

  get userShowOtherSpec() {
    return !isEmpty(this.specializations.filter(({ main }) => !main));
  }

  get userOtherSpecializations() {
    return this.specializations
      .filter(({ main }) => !main)
      .map(({ specializationDto }) => ({ name: specializationDto.name, id: specializationDto.id }));
  }
}

export const RESET_PASSWORD_VALIDATION_SCHEMA = yup.object().shape({
  newPassword: yup
    .string()
    .trim()
    .required('Required')
    .min(5, 'Password must be at least 6 characters long')
    .oneOf([yup.ref('confirmPassword'), null], 'Passwords must match'),
  confirmPassword: yup
    .string()
    .trim()
    .required('Required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export const CHANGE_PASSWORD_VALIDATION_SCHEMA = yup.object().shape({
  ...RESET_PASSWORD_VALIDATION_SCHEMA.fields,
  currentPassword: yup.string().trim().required('Required'),
});

export const EDIT_POSITIONS_HISTORY_SCHEMA = yup.object().shape({
  history: yup.array().of(
    yup.object().shape({
      dateFrom: yup
        .string()
        .required('Required.')
        .test('dateFrom', `Already exists`, (value: any, yup: any) => {
          const positionNumber = yup.path.slice(8, 9);
          return !yup.from[1].value.history.some((item: any, index: number) => {
            return +positionNumber === index ? false : item.dateFrom === value;
          });
        }),
      companyPositionId: yup.string().nullable().required('Required'),
    }),
  ),
});

export type ThumbnailsSize = 36 | 48 | 72 | 120 | 240 | 320 | 520;

export class UserPhoto {
  url: string;
  id: string;
  croppedFile: { cropSetting: PhotoCropSetting; url: string; fileId: string };
  cropSetting: PhotoCropSetting;
  thumbnails: {
    cropSetting: PhotoCropSetting;
    croppedFile: { url: string; fileId: string };
    id: string;
    size: ThumbnailsSize;
    thumbnails: [];
    url: string;
  }[];

  constructor(photo?: unknown) {
    this.url = get(photo, 'url', null);
    this.id = get(photo, 'id', null);
    this.croppedFile = get(photo, 'croppedFile', null);
    this.cropSetting = get(photo, 'cropSetting', {});
    this.thumbnails = get(photo, 'thumbnails', []);
  }

  get URL() {
    return this.croppedFile?.url || this.url;
  }
}

export class UserAbsencePeriods {
  absenceReason: string;
  maxNumber: number | null;
  usedNumber: number;
  scheduledNumber: number;
  daysAvailableThisYear: number;

  constructor(absence?: unknown) {
    this.absenceReason = get(absence, 'absenceReason', '');
    this.maxNumber = get(absence, 'maxNumber', null);
    this.usedNumber = get(absence, 'usedNumber', 0);
    this.scheduledNumber = get(absence, 'scheduledNumber', 0);
    this.daysAvailableThisYear = get(absence, 'daysAvailableThisYear', 0);
  }
}

export class AbsenceEvent {
  endDate: string;
  eventId: string;
  eventName: string;
  eventTypeColor: string;
  eventTypeId: string;
  eventTypeName: string;
  finalDecision: boolean;
  finalDecisionEnabled: boolean;
  isPersonal: boolean;
  startDate: string;

  constructor(photo?: unknown) {
    this.endDate = get(photo, 'endDate', null);
    this.eventId = get(photo, 'eventId', null);
    this.eventName = get(photo, 'eventName', null);
    this.eventTypeColor = get(photo, 'eventTypeColor', null);
    this.eventTypeId = get(photo, 'eventTypeId', null);
    this.eventTypeName = get(photo, 'eventTypeName', null);
    this.finalDecision = get(photo, 'finalDecision', null);
    this.finalDecisionEnabled = get(photo, 'finalDecisionEnabled', null);
    this.isPersonal = get(photo, 'isPersonal', null);
    this.startDate = get(photo, 'startDate', null);
  }

  get typeNameLetter() {
    return this.eventTypeName?.trim()[0] || '';
  }
}

export class NewProfleInfo extends UserInfo {
  randomPassword: boolean;
  sendDetailsViaEmail: boolean;
  constructor(user?: unknown) {
    super(user);
    this.randomPassword = false;
    this.sendDetailsViaEmail = false;
  }

  get userCreateInfo() {
    return {
      ...pick(this, [
        'firstName',
        'jiraUser',
        'email',
        'password',
        'username',
        'messengerContacts',
        'emails',
        'phones',
        'companyPositionHistory',
        'randomPassword',
        'sendDetailsViaEmail',
      ]),
      departmentId: this.department.id,
      officeId: this.office.id,
      lastName: this.secondName,
    };
  }
}
