import {
  Client,
  Transaction,
  TransactionInitialValue,
  FinancePlan,
  PlannedExpenseClass,
} from '../enums/finance/finance.enum';
import * as financeTypes from '../types/finance';
import request from '../utils/request.utils';
import * as paramsTypes from '../enums/params/finance.params';
import { QueryParamsType } from './../utils/params.utils';

export function getIncomeTypesList(params: QueryParamsType<paramsTypes.IncomeTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/incomeTypes',
    params,
  };
  return request(options);
}

export function createIncomeType(data: financeTypes.IncomeType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/incomeTypes',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editIncomeType(data: financeTypes.IncomeType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/incomeTypes/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteIncomeType(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/incomeTypes/${id}`,
  };
  return request(options);
}

export function getCurrenciesList(params: QueryParamsType<paramsTypes.CurrenciesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/currencies',
    params,
  };
  return request(options);
}

export function createCurrency(data: financeTypes.CurrencyType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/currencies',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editCurrency(data: financeTypes.CurrencyType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/currencies/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteCurrency(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/currencies/${id}`,
  };
  return request(options);
}

export function getSuppliersList(params: QueryParamsType<paramsTypes.SuppliersParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/suppliers',
    params,
  };
  return request(options);
}

export function createSupplier(data: financeTypes.SupplierType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/suppliers',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editSupplier(data: financeTypes.SupplierType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/suppliers/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteSupplier(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/suppliers/${id}`,
  };
  return request(options);
}

export function getProjectTypesList(params: QueryParamsType<paramsTypes.ProjectTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/projectTypes',
    params,
  };
  return request(options);
}

export function createProjectType(data: financeTypes.ProjectType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/projectTypes',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editProjectType(data: financeTypes.ProjectType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/projectTypes/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteProjectType(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/projectTypes/${id}`,
  };
  return request(options);
}

export function getExpenseTypesList(params: QueryParamsType<paramsTypes.ExpenseTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/expenseTypes',
    params,
  };
  return request(options);
}

export function createExpenseType(data: financeTypes.ExpenseType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/expenseTypes',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editExpenseType(data: financeTypes.ExpenseType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/expenseTypes/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteExpenseType(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/expenseTypes/${id}`,
  };
  return request(options);
}

export function getClientsList(params: QueryParamsType<paramsTypes.ClientsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/clients',
    params,
  };
  return request(options);
}

export function createClient(data: Client) {
  const options: RequestConfig = {
    method: 'post',
    url: '/clients',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editClient(data: Client) {
  const options: RequestConfig = {
    method: 'put',
    url: `/clients/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteClient(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/clients/${id}`,
  };
  return request(options);
}

export function changeClientStatus(id: string, isActive: string) {
  const options: RequestConfig = {
    method: 'patch',
    url: `/clients/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: isActive,
  };
  return request(options);
}

export function getSalariesList(params: paramsTypes.SalariesParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/salaries/report',
    params,
  };
  return request(options);
}

export function getSalary(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/salaries/${id}`,
  };
  return request(options);
}

export function editSalary(data: financeTypes.SalaryType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/salaries`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteSalary(data: { id: string; deleteFutureSalaries: boolean }) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/salaries/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: `${data.deleteFutureSalaries}`,
  };
  return request(options);
}

export function getProjectsList(params: QueryParamsType<paramsTypes.FinanceProjectsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/financeProjects',
    params: params,
  };
  return request(options);
}

export function createProject(data: financeTypes.ProjectType, forceUpdate?: boolean) {
  const options: RequestConfig = {
    method: 'post',
    url: '/financeProjects',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params: { forceUpdate: forceUpdate },
  };
  return request(options);
}

export function editProject(data: financeTypes.ProjectType, forceUpdate?: boolean) {
  const options: RequestConfig = {
    method: 'put',
    url: `/financeProjects/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params: { forceUpdate: forceUpdate },
  };
  return request(options);
}

export function deleteProject(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/financeProjects/${id}`,
  };
  return request(options);
}

export function changeProjectStatus(id: string, isActive: string) {
  const options: RequestConfig = {
    method: 'patch',
    url: `/financeProjects/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: isActive,
  };
  return request(options);
}

export function getTransactionsList(params: QueryParamsType<paramsTypes.TransactionsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/transactions',
    params: params,
  };
  return request(options);
}

export function getTransactionsTotal(params: QueryParamsType<Partial<paramsTypes.TransactionsParams>>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/transactions/total',
    params: params,
  };
  return request(options);
}

export function createTransactions(data: Transaction) {
  const options: RequestConfig = {
    method: 'post',
    url: '/transactions',
    data,
  };
  return request(options);
}

export function batchCreateTransactions(data: { transactions: TransactionInitialValue[] }) {
  const options: RequestConfig = {
    method: 'post',
    url: '/transactions/batch',
    data,
  };
  return request(options);
}

export function editTransaction(data: Transaction) {
  const options: RequestConfig = {
    method: 'put',
    url: `/transactions/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteTransaction(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/transactions/${id}`,
  };
  return request(options);
}

export function getPlans(params: QueryParamsType<paramsTypes.FinancePlanParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/financePlan',
    params: params,
  };
  return request(options);
}

export function getCurrentPlan(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/financePlan/${id}`,
  };
  return request(options);
}

export function createPlan(data: FinancePlan) {
  const options: RequestConfig = {
    method: 'post',
    url: '/financePlan',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editPlan(data: FinancePlan) {
  const options: RequestConfig = {
    method: 'put',
    url: `/financePlan/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function clonePlan(data: FinancePlan) {
  const options: RequestConfig = {
    method: 'post',
    url: `/financePlan/${data.id}/clone`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function checkClonePlan(id: string, plannedMonth: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/financePlan/${id}/clone`,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      plannedMonth,
    },
  };
  return request(options);
}

export function getDatesOfExistingPlans(params: financeTypes.DatesOfExistingPlansParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/financePlan/exists',
    params,
  };
  return request(options);
}

export function deletePlan(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/financePlan/${id}`,
  };
  return request(options);
}

export function getPlanEmployees(id: string, params: QueryParamsType<paramsTypes.FinancePlanEmployeesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: `/financePlan/${id}/employees`,
    params: params,
  };
  return request(options);
}

export function getEmployeeHours(id: string, month: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/financePlan/users/${id}/userPlansLoad`,
    params: {
      month,
    },
  };
  return request(options);
}

export function createPlanEmployee(id: string, data: financeTypes.EmployeeHoursApiType) {
  const options: RequestConfig = {
    method: 'post',
    url: `/financePlan/${id}/employees/loadChanges`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editPlanEmployee(id: string, data: financeTypes.EmployeeHoursApiType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/financePlan/${id}/employees/${data.userId}/loadChanges`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deletePlanEmployee(planId: string, employeeId: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/financePlan/${planId}/employees/${employeeId}`,
  };
  return request(options);
}

export function getIncomesExpensesReport(params: paramsTypes.IncomesExpensesReportParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/incomesExpensesReports',
    params: params,
  };
  return request(options);
}

export function importResources(financePlanId: string, month: string, projectGroupId: string) {
  const options: RequestConfig = {
    method: 'post',
    url: `/financePlan/${financePlanId}/import`,
    data: {
      month,
      projectGroupId,
    },
  };
  return request(options);
}

export function checkImportResources(
  id: string,
  params: {
    month: string;
    projectGroupId: string;
  },
) {
  const options: RequestConfig = {
    method: 'post',
    url: `/financePlan/${id}/calculateDifference`,
    params,
  };
  return request(options);
}

export function getPlannedExpense(params: paramsTypes.PlannedExpenseParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/plannedExpenses/report',
    params: params,
  };
  return request(options);
}

export function editPlannedExpense(data: PlannedExpenseClass) {
  const options: RequestConfig = {
    method: 'put',
    url: '/plannedExpenses',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deletePlannedExpense(id: PlannedExpenseClass, deleteFutureExpenses: boolean) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/plannedExpenses/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: `${deleteFutureExpenses}`,
  };
  return request(options);
}
