import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../constants/reports.constants';
import * as api from '../api/reports.api';
import * as paramsTypes from '../enums/params/reports.params';
import { AnyAction } from 'redux';

function* getEmployeesReport() {
  try {
    const params: paramsTypes.EmployeesReportParams = yield select(
      (state: RootState) => state.reports.employeesReportParams,
    );
    const employeeParams: paramsTypes.EmployeesReportParams = {
      ...params,
      isFoldIssuesToProject: false,
      isFoldSubtasksToParentTask: false,
    };

    const { success, results } = yield call(api.getEmployeesReport, employeeParams);

    if (success) {
      yield put({
        type: ActionTypes.GET_EMPLOYEES_REPORT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMPLOYEES_REPORT_FAILURE, payload: error });
  }
}

function* setEmployeesReportParams({ payload }: AnyAction) {
  if (payload.isGetList) {
    yield put({ type: ActionTypes.GET_EMPLOYEES_REPORT_REQUEST });
  }
}

function* getUsersHoursAbsencesReport() {
  try {
    const params: paramsTypes.UsersHoursAbsencesReportParams = yield select(
      (state: RootState) => state.reports.usersHoursAbsencesReportParams,
    );

    const { success, results } = yield call(api.getUsersHoursAbsencesReport, params);
    if (success) {
      yield put({
        type: ActionTypes.GET_USERS_HOURS_ABSENCES_REPORT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USERS_HOURS_ABSENCES_REPORT_FAILURE, payload: error });
  }
}

function* employeesTimesheetReport() {
  try {
    const params: paramsTypes.EmployeesTimesheetReportParams = yield select(
      (state: RootState) => state.reports.employeesTimesheetReportParams,
    );

    const { success, results } = yield call(api.employeesTimesheetReport, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_EMPLOYEES_TIMESHEET_REPORT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMPLOYEES_TIMESHEET_REPORT_FAILURE, payload: error });
  }
}

function* setEmployeesTimesheetReportParams() {
  yield put({ type: ActionTypes.GET_EMPLOYEES_TIMESHEET_REPORT_REQUEST });
}

function* getProjectsReport() {
  try {
    const params: paramsTypes.ProjectsReportParams = yield select(
      (state: RootState) => state.reports.projectsReportParams,
    );

    const { success, results } = yield call(api.getProjectsReport, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROJECTS_REPORT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROJECTS_REPORT_FAILURE, payload: error });
  }
}

function* setProjectsReportParams({ payload }: AnyAction) {
  if (payload.isGetList) {
    yield put({ type: ActionTypes.GET_PROJECTS_REPORT_REQUEST });
  }
}

function* getEmployeesIssueTypes() {
  try {
    const params: paramsTypes.EmployeesIssueTypesParams = yield select(
      (state: RootState) => state.reports.employeesIssueTypesParams,
    );

    const { success, results } = yield call(api.getEmployeesIssueTypes, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_EMPLOYEES_ISSUE_TYPES_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMPLOYEES_ISSUE_TYPES_FAILURE, payload: error });
  }
}

function* getAbsencePeriods() {
  try {
    const params: paramsTypes.AbsencePeriodsParams = yield select(
      (state: RootState) => state.reports.absencePeriodsParams,
    );

    const { success, results } = yield call(api.getAbsencePeriods, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_ABSENCE_PERIODS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ABSENCE_PERIODS_FAILURE, payload: error });
  }
}

function* getActiveInactiveHoursReport() {
  try {
    const params: paramsTypes.ActiveInactiveHoursReportParams = yield select(
      (state: RootState) => state.reports.activeInactiveHoursReportParams,
    );

    const { success, results } = yield call(api.getActiveInactiveHoursReport, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_ACTIVE_INACTIVE_HOURS_REPORT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ACTIVE_INACTIVE_HOURS_REPORT_FAILURE, payload: error });
  }
}

export default function* reportsSaga() {
  yield all([
    takeLatest(ActionTypes.GET_EMPLOYEES_REPORT_REQUEST, getEmployeesReport),
    takeLatest(ActionTypes.SET_EMPLOYEES_REPORT_PARAMS_REQUEST, setEmployeesReportParams),
    takeLatest(ActionTypes.SET_ABSENCE_PERIODS_PARAMS_REQUEST, getAbsencePeriods),
    takeLatest(ActionTypes.SET_USERS_HOURS_ABSENCES_REPORT_PARAMS_REQUEST, getUsersHoursAbsencesReport),
    takeLatest(ActionTypes.SET_EMPLOYEES_ISSUE_TYPES_PARAMS_REQUEST, getEmployeesIssueTypes),
    takeLatest(ActionTypes.SET_EMPLOYEES_TIMESHEET_REPORT_PARAMS, setEmployeesTimesheetReportParams),
    takeLatest(ActionTypes.GET_EMPLOYEES_TIMESHEET_REPORT_REQUEST, employeesTimesheetReport),
    takeLatest(ActionTypes.GET_PROJECTS_REPORT_REQUEST, getProjectsReport),
    takeLatest(ActionTypes.SET_PROJECTS_REPORT_PARAMS_REQUEST, setProjectsReportParams),
    takeLatest(ActionTypes.SET_ACTIVE_INACTIVE_HOURS_REPORT_PARAMS_REQUEST, getActiveInactiveHoursReport),
  ]);
}
