import { intl } from '../../utils/intl';
import messages from './messages';

export const RESET_CANDIDATES_ERRORS = 'RESET_CANDIDATES_ERRORS';
export const RESET_CANDIDATES_STATE = 'RESET_CANDIDATES_STATE';

export const GET_CANDIDATE_STATUSES_LIST_REQUEST = 'GET_CANDIDATE_STATUSES_LIST_REQUEST';
export const GET_CANDIDATE_STATUSES_LIST_SUCCESS = 'GET_CANDIDATE_STATUSES_LIST_SUCCESS';
export const GET_CANDIDATE_STATUSES_LIST_FAILURE = 'GET_CANDIDATE_STATUSES_LIST_FAILURE';

export const POST_CANDIDATE_STATUS_REQUEST = 'POST_CANDIDATE_STATUS_REQUEST';
export const POST_CANDIDATE_STATUS_SUCCESS = 'POST_CANDIDATE_STATUS_SUCCESS';
export const POST_CANDIDATE_STATUS_FAILURE = 'POST_CANDIDATE_STATUS_FAILURE';

export const PUT_CANDIDATE_STATUS_REQUEST = 'PUT_CANDIDATE_STATUS_REQUEST';
export const PUT_CANDIDATE_STATUS_SUCCESS = 'PUT_CANDIDATE_STATUS_SUCCESS';
export const PUT_CANDIDATE_STATUS_FAILURE = 'PUT_CANDIDATE_STATUS_FAILURE';

export const DELETE_CANDIDATE_STATUS_REQUEST = 'DELETE_CANDIDATE_STATUS_REQUEST';
export const DELETE_CANDIDATE_STATUS_SUCCESS = 'DELETE_CANDIDATE_STATUS_SUCCESS';
export const DELETE_CANDIDATE_STATUS_FAILURE = 'DELETE_CANDIDATE_STATUS_FAILURE';

export const SET_CANDIDATE_STATUSES_PARAMS = 'SET_CANDIDATE_STATUSES_PARAMS';

export const GET_CANDIDATE_SPECIALIZATIONS_LIST_REQUEST = 'GET_CANDIDATE_SPECIALIZATIONS_LIST_REQUEST';
export const GET_CANDIDATE_SPECIALIZATIONS_LIST_SUCCESS = 'GET_CANDIDATE_SPECIALIZATIONS_LIST_SUCCESS';
export const GET_CANDIDATE_SPECIALIZATIONS_LIST_FAILURE = 'GET_CANDIDATE_SPECIALIZATIONS_LIST_FAILURE';

export const POST_CANDIDATE_SPECIALIZATION_REQUEST = 'POST_CANDIDATE_SPECIALIZATION_REQUEST';
export const POST_CANDIDATE_SPECIALIZATION_SUCCESS = 'POST_CANDIDATE_SPECIALIZATION_SUCCESS';
export const POST_CANDIDATE_SPECIALIZATION_FAILURE = 'POST_CANDIDATE_SPECIALIZATION_FAILURE';

export const PUT_CANDIDATE_SPECIALIZATION_REQUEST = 'PUT_CANDIDATE_SPECIALIZATION_REQUEST';
export const PUT_CANDIDATE_SPECIALIZATION_SUCCESS = 'PUT_CANDIDATE_SPECIALIZATION_SUCCESS';
export const PUT_CANDIDATE_SPECIALIZATION_FAILURE = 'PUT_CANDIDATE_SPECIALIZATION_FAILURE';

export const DELETE_CANDIDATE_SPECIALIZATION_REQUEST = 'DELETE_CANDIDATE_SPECIALIZATION_REQUEST';
export const DELETE_CANDIDATE_SPECIALIZATION_SUCCESS = 'DELETE_CANDIDATE_SPECIALIZATION_SUCCESS';
export const DELETE_CANDIDATE_SPECIALIZATION_FAILURE = 'DELETE_CANDIDATE_SPECIALIZATION_FAILURE';

export const SET_CANDIDATE_SPECIALIZATION_PARAMS = 'SET_CANDIDATE_SPECIALIZATION_PARAMS';

export const GET_CANDIDATE_TECHNOLOGIES_LIST_REQUEST = 'GET_CANDIDATE_TECHNOLOGIES_LIST_REQUEST';
export const GET_CANDIDATE_TECHNOLOGIES_LIST_SUCCESS = 'GET_CANDIDATE_TECHNOLOGIES_LIST_SUCCESS';
export const GET_CANDIDATE_TECHNOLOGIES_LIST_FAILURE = 'GET_CANDIDATE_TECHNOLOGIES_LIST_FAILURE';

export const POST_CANDIDATE_TECHNOLOGY_REQUEST = 'POST_CANDIDATE_TECHNOLOGY_REQUEST';
export const POST_CANDIDATE_TECHNOLOGY_SUCCESS = 'POST_CANDIDATE_TECHNOLOGY_SUCCESS';
export const POST_CANDIDATE_TECHNOLOGY_FAILURE = 'POST_CANDIDATE_TECHNOLOGY_FAILURE';

export const PUT_CANDIDATE_TECHNOLOGY_REQUEST = 'PUT_CANDIDATE_TECHNOLOGY_REQUEST';
export const PUT_CANDIDATE_TECHNOLOGY_SUCCESS = 'PUT_CANDIDATE_TECHNOLOGY_SUCCESS';
export const PUT_CANDIDATE_TECHNOLOGY_FAILURE = 'PUT_CANDIDATE_TECHNOLOGY_FAILURE';

export const DELETE_CANDIDATE_TECHNOLOGY_REQUEST = 'DELETE_CANDIDATE_TECHNOLOGY_REQUEST';
export const DELETE_CANDIDATE_TECHNOLOGY_SUCCESS = 'DELETE_CANDIDATE_TECHNOLOGY_SUCCESS';
export const DELETE_CANDIDATE_TECHNOLOGY_FAILURE = 'DELETE_CANDIDATE_TECHNOLOGY_FAILURE';

export const SET_CANDIDATE_TECHNOLOGY_PARAMS = 'SET_CANDIDATE_TECHNOLOGY_PARAMS';

export const GET_CANDIDATE_RECEIVING_SOURCES_LIST_REQUEST = 'GET_CANDIDATE_RECEIVING_SOURCES_LIST_REQUEST';
export const GET_CANDIDATE_RECEIVING_SOURCES_LIST_SUCCESS = 'GET_CANDIDATE_RECEIVING_SOURCES_LIST_SUCCESS';
export const GET_CANDIDATE_RECEIVING_SOURCES_LIST_FAILURE = 'GET_CANDIDATE_RECEIVING_SOURCES_LIST_FAILURE';

export const POST_CANDIDATE_RECEIVING_SOURCE_REQUEST = 'POST_CANDIDATE_RECEIVING_SOURCE_REQUEST';
export const POST_CANDIDATE_RECEIVING_SOURCE_SUCCESS = 'POST_CANDIDATE_RECEIVING_SOURCE_SUCCESS';
export const POST_CANDIDATE_RECEIVING_SOURCE_FAILURE = 'POST_CANDIDATE_RECEIVING_SOURCE_FAILURE';

export const PUT_CANDIDATE_RECEIVING_SOURCE_REQUEST = 'PUT_CANDIDATE_RECEIVING_SOURCE_REQUEST';
export const PUT_CANDIDATE_RECEIVING_SOURCE_SUCCESS = 'PUT_CANDIDATE_RECEIVING_SOURCE_SUCCESS';
export const PUT_CANDIDATE_RECEIVING_SOURCE_FAILURE = 'PUT_CANDIDATE_RECEIVING_SOURCE_FAILURE';

export const DELETE_CANDIDATE_RECEIVING_SOURCE_REQUEST = 'DELETE_CANDIDATE_RECEIVING_SOURCE_REQUEST';
export const DELETE_CANDIDATE_RECEIVING_SOURCE_SUCCESS = 'DELETE_CANDIDATE_RECEIVING_SOURCE_SUCCESS';
export const DELETE_CANDIDATE_RECEIVING_SOURCE_FAILURE = 'DELETE_CANDIDATE_RECEIVING_SOURCE_FAILURE';

export const SET_CANDIDATE_RECEIVING_SOURCE_PARAMS = 'SET_CANDIDATE_RECEIVING_SOURCE_PARAMS';

export const GET_CANDIDATE_LIST_REQUEST = 'GET_CANDIDATE_LIST_REQUEST';
export const GET_CANDIDATE_LIST_SUCCESS = 'GET_CANDIDATE_LIST_SUCCESS';
export const GET_CANDIDATE_LIST_FAILURE = 'GET_CANDIDATE_LIST_FAILURE';

export const POST_CANDIDATE_REQUEST = 'POST_CANDIDATE_REQUEST';
export const POST_CANDIDATE_SUCCESS = 'POST_CANDIDATE_SUCCESS';
export const POST_CANDIDATE_FAILURE = 'POST_CANDIDATE_FAILURE';

export const PUT_CANDIDATE_REQUEST = 'PUT_CANDIDATE_REQUEST';
export const PUT_CANDIDATE_SUCCESS = 'PUT_CANDIDATE_SUCCESS';
export const PUT_CANDIDATE_FAILURE = 'PUT_CANDIDATE_FAILURE';

export const PUT_CANDIDATE_INFO_REQUEST = 'PUT_CANDIDATE_INFO_REQUEST';
export const PUT_CANDIDATE_INFO_SUCCESS = 'PUT_CANDIDATE_INFO_SUCCESS';
export const PUT_CANDIDATE_INFO_FAILURE = 'PUT_CANDIDATE_INFO_FAILURE';

export const DELETE_CANDIDATE_REQUEST = 'DELETE_CANDIDATE_REQUEST';
export const DELETE_CANDIDATE_SUCCESS = 'DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_FAILURE = 'DELETE_CANDIDATE_FAILURE';

export const SET_CANDIDATE_PARAMS = 'SET_CANDIDATE_PARAMS';

export const GET_CANDIDATE_INFO_REQUEST = 'GET_CANDIDATE_INFO_REQUEST';
export const GET_CANDIDATE_INFO_SUCCESS = 'GET_CANDIDATE_INFO_SUCCESS';
export const GET_CANDIDATE_INFO_FAILURE = 'GET_CANDIDATE_INFO_FAILURE';

export const RESET_CANDIDATE_INFO = 'RESET_CANDIDATE_INFO';

export const POST_CANDIDATE_PHOTO_REQUEST = 'POST_CANDIDATE_PHOTO_REQUEST';
export const POST_CANDIDATE_PHOTO_SUCCESS = 'POST_CANDIDATE_PHOTO_SUCCESS';
export const POST_CANDIDATE_PHOTO_FAILURE = 'POST_CANDIDATE_PHOTO_FAILURE';

export const DELETE_CANDIDATE_PHOTO_REQUEST = 'DELETE_CANDIDATE_PHOTO_REQUEST';
export const DELETE_CANDIDATE_PHOTO_SUCCESS = 'DELETE_CANDIDATE_PHOTO_SUCCESS';
export const DELETE_CANDIDATE_PHOTO_FAILURE = 'DELETE_CANDIDATE_PHOTO_FAILURE';

export const GET_CANDIDATE_COMMENTS_REQUEST = 'GET_CANDIDATE_COMMENTS_REQUEST';
export const GET_CANDIDATE_COMMENTS_SUCCESS = 'GET_CANDIDATE_COMMENTS_SUCCESS';
export const GET_CANDIDATE_COMMENTS_FAILURE = 'GET_CANDIDATE_COMMENTS_FAILURE';

export const POST_CANDIDATE_COMMENT_REQUEST = 'POST_CANDIDATE_COMMENT_REQUEST';
export const POST_CANDIDATE_COMMENT_SUCCESS = 'POST_CANDIDATE_COMMENT_SUCCESS';
export const POST_CANDIDATE_COMMENT_FAILURE = 'POST_CANDIDATE_COMMENT_FAILURE';

export const PUT_CANDIDATE_COMMENT_REQUEST = 'PUT_CANDIDATE_COMMENT_REQUEST';
export const PUT_CANDIDATE_COMMENT_SUCCESS = 'PUT_CANDIDATE_COMMENT_SUCCESS';
export const PUT_CANDIDATE_COMMENT_FAILURE = 'PUT_CANDIDATE_COMMENT_FAILURE';

export const DELETE_CANDIDATE_COMMENT_REQUEST = 'DELETE_CANDIDATE_COMMENT_REQUEST';
export const DELETE_CANDIDATE_COMMENT_SUCCESS = 'DELETE_CANDIDATE_COMMENT_SUCCESS';
export const DELETE_CANDIDATE_COMMENT_FAILURE = 'DELETE_CANDIDATE_COMMENT_FAILURE';

export enum CANDIDATE_PROFILE_TABS {
  ABOUT = 'ABOUT',
  COMMENTS = 'COMMENTS',
}

export const candidateProfileTabs = [
  {
    displayName: intl.formatMessage(messages.aboutTabTitle),
    value: CANDIDATE_PROFILE_TABS.ABOUT,
  },
  {
    displayName: intl.formatMessage(messages.commentsTabTitle),
    value: CANDIDATE_PROFILE_TABS.COMMENTS,
  },
];
