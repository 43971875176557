import { defineMessages } from 'react-intl';
import { nameColumn, editButton, deleteButton, newButton, typeLabel, personalLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  typeLabel,
  personalLabel,
  pageTitle: {
    id: 'finance.expense.types.title.expense.types',
    defaultMessage: 'Expense Types',
  },
  companyLabel: {
    id: 'finance.expense.types.label.company',
    defaultMessage: 'Company',
  },
});
