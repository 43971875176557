import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as resumesActions from '../../actions/resumes.actions';
import * as filterActions from '../../actions/filters.actions';
import * as usersActions from '../../actions/users.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import ModalEditResume from '../../components/ResumesManagement/Modals/ModalEditResume';
import ModalExportResumes from '../../components/ResumesManagement/Modals/ModalExportResumes';
import ResumesManagementFilter from '../../components/ResumesManagement/Filter/index';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import moment from 'moment';
import Pagination from '../../components/Pagination';
import { Resume } from '../../enums/resumes.enum';
import Checkbox from '../../components/Checkbox';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import TableUserAvatar from '../../components/TableUserAvatar';
import { UPDATE_RESUME } from '../../constants/policies.constants';
import { DATE_FORMAT } from '../../constants/date.constants';

function ResumesManagement({
  tableData,
  getResumesList,
  editUserResume,
  errors,
  isLoading,
  languageLevelsList,
  getLanguageLevelsFilter,
  foreignLanguagesList,
  getForeignLanguagesFilter,
  technicalSkillList,
  getTechnicalSkillsFilter,
  projectList,
  getProjectsFilter,
  setResumesParams,
  getOfficesFilter,
  getDepartmentsFilter,
  getUsersFilter,
  getCompanyPositionsFilter,
  companyPositionList,
  getUserPersonalInfo,
  userPersonalInfo,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalExportResumesIsOpen, setModalExportResumesIsOpen] = useState(false);
  const [modalEditResumeIsOpen, setModalEditResumeIsOpen] = useState(false);
  const [resumeClicked, setResumeClicked] = useState<Resume>(new Resume());
  const [resumeClickedList, setResumeClickedList] = useState<Resume[]>([]);

  useEffect(() => {
    getProjectsFilter();
    getCompanyPositionsFilter();
    getOfficesFilter();
    getDepartmentsFilter();
    getUsersFilter();
    getTechnicalSkillsFilter();
  }, []);

  useEffect(() => {
    !modalExportResumesIsOpen && setResumeClickedList([]);
  }, [modalExportResumesIsOpen]);

  const handlePageChange = useCallback(({ selected }) => {
    setResumesParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setResumesParams({ size: data, page: 0 });
  }, []);

  const changeResumeExportList = (row: Resume, checked: boolean) => {
    setResumeClickedList(prevState => {
      if (checked) {
        return prevState.concat(row);
      } else {
        prevState.splice(
          resumeClickedList.findIndex(item => item.id === row.id),
          1,
        );
        return [...prevState];
      }
    });
  };

  const updateUserResume = (resumes: { id: string; data: FormData }[]) => {
    editUserResume(resumes, () => {
      setModalEditResumeIsOpen(false);
      setModalExportResumesIsOpen(false);
      getResumesList();
    });
  };

  const checkSelectRow = useCallback(
    id => {
      if (!!resumeClickedList?.find(item => item.id === id)) return 'select-row';
    },
    [resumeClickedList],
  );

  const tableColumns = useMemo(
    () => [
      {
        name: (
          <div className={'table__data-wrapper'}>
            <Checkbox
              id={'checkbox'}
              onChange={() =>
                resumeClickedList?.length ? setResumeClickedList([]) : setResumeClickedList([...tableData?.content])
              }
              checkedValue={!!resumeClickedList?.length}
              externalClass="cv_table_checkbox main_checkbox clear-all-checkbox"
              iconName={'clear-all-checkbox'}
            />
          </div>
        ),
        modifier: (row: Resume) => (
          <div className={'table__data-wrapper'}>
            <Checkbox
              id={'checkbox' + row.id}
              onChange={event => changeResumeExportList(row, event.target.checked)}
              checkedValue={!!resumeClickedList?.find(item => item.id === row.id)}
              externalClass="cv_table_checkbox"
            />
          </div>
        ),
        className: (row: Resume) => checkSelectRow(row.id),
      },
      {
        name: resumeClickedList?.length ? (
          <div className={'table__data-wrapper export_button_wrapper'}>
            <div className="export_button">
              <button onClick={() => setModalExportResumesIsOpen(true)}>
                <Icon iconName="pdf" />
                <span>
                  <FormattedMessage {...messages.exportButton} />
                </span>
              </button>
              <div className="selected-coutner">{`${resumeClickedList?.length} ${intl.formatMessage(
                messages.selectedLabel,
              )}`}</div>
            </div>
          </div>
        ) : (
          intl.formatMessage(messages.memberLabel)
        ),
        modifier: (row: Resume) => (
          <div className={'table__data-wrapper'}>
            <NavLink className={'table__type-link resume-info-link'} to={`resume/information/${row.employee.id}`}>
              <TableUserAvatar users={[row.employee]} isUserPageLink={false} fileSize={36} />
            </NavLink>
          </div>
        ),
        className: (row: Resume) => checkSelectRow(row.id),
      },
      {
        name: resumeClickedList?.length ? '' : intl.formatMessage(messages.positionLabel),
        modifier: (row: Resume) => row.companyPosition?.displayName,
        className: (row: Resume) => checkSelectRow(row.id),
      },
      {
        name: resumeClickedList?.length ? '' : intl.formatMessage(messages.experienceLabel),
        modifier: (row: Resume) => row.userExperienceMask,
        className: (row: Resume) => checkSelectRow(row.id),
      },
      {
        name: resumeClickedList?.length ? '' : intl.formatMessage(messages.lastUpdateColumn),
        modifier: (row: Resume) => moment.utc(row.lastUpdate).local().format(DATE_FORMAT.lll),
        className: (row: Resume) => checkSelectRow(row.id),
      },
      {
        name: resumeClickedList?.length ? '' : intl.formatMessage(messages.updaterColumn),
        modifier: (row: Resume) => (
          <div className={'table__data-wrapper'}>
            <TableUserAvatar users={[row.updater]} fileSize={48} />
          </div>
        ),
        className: (row: Resume) => checkSelectRow(row.id),
      },
      {
        name: resumeClickedList?.length ? '' : intl.formatMessage(messages.statusLabel),
        modifier: (row: Resume) =>
          row.approvedStatus ? (
            <div className="table__status--approved">{intl.formatMessage(messages.approvedLabel)}</div>
          ) : (
            <div> {intl.formatMessage(messages.notApprovedLabel)}</div>
          ),
        className: (row: Resume) => checkSelectRow(row.id),
        sortName: 'approvedStatus',
      },
    ],
    [resumeClickedList, tableData],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Resume) => {
          setResumeClicked(row);
          setModalEditResumeIsOpen(true);
        },
        verifiablePolicies: [UPDATE_RESUME],
      },
      {
        label: (
          <>
            <Icon iconName={'pdf'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.exportToPDFLabel} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Resume) => {
          setResumeClickedList([row]);
          setModalExportResumesIsOpen(true);
        },
      },
    ],
    [],
  );

  const tableClasses = useMemo(
    () =>
      classNames('table', 'cv_table', {
        choose_resume: !!resumeClickedList.length,
      }),
    [resumeClickedList],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <ResumesManagementFilter />
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={tableClasses}
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getResumesList}
            error={errors.resumesListError}
            tableActions={tableActions}
          />
          {!!tableData?.content.length && (
            <Pagination
              pageable={{
                ...tableData?.pageable,
                ...tableData?.sort,
                totalElements: tableData?.totalElements,
                numberOfElements: tableData?.numberOfElements,
                totalPages: tableData?.totalPages,
              }}
              onPageChange={data => handlePageChange(data)}
              onPageSizeChange={data => handleSizeChange(data)}
            />
          )}
        </div>
      </div>
      {modalEditResumeIsOpen && (
        <ModalEditResume
          isOpen
          onCloseRequest={() => setModalEditResumeIsOpen(false)}
          updateResume={updateUserResume}
          currentResume={resumeClicked}
          resumeError={errors.editResumeError}
          isLoading={isLoading.editResume}
          languageLevels={languageLevelsList}
          foreignLanguages={foreignLanguagesList}
          technicalSkills={technicalSkillList}
          projectsList={projectList}
          getForeignLanguagesFilter={getForeignLanguagesFilter}
          getProjectsFilter={getProjectsFilter}
          getTechnicalSkillsFilter={getTechnicalSkillsFilter}
          getLanguageLevelsFilter={getLanguageLevelsFilter}
          userPersonalInfo={userPersonalInfo}
          getUserPersonalInfo={getUserPersonalInfo}
        />
      )}
      {modalExportResumesIsOpen && (
        <ModalExportResumes
          isOpen
          onCloseRequest={() => setModalExportResumesIsOpen(false)}
          updateResume={updateUserResume}
          resumeError={errors.editResumeError}
          isLoading={isLoading.editResume}
          resumesList={resumeClickedList}
          positions={companyPositionList}
          languageLevels={languageLevelsList}
          foreignLanguages={foreignLanguagesList}
          technicalSkills={technicalSkillList}
          projectsList={projectList}
          getForeignLanguagesFilter={getForeignLanguagesFilter}
          getProjectsFilter={getProjectsFilter}
          getTechnicalSkillsFilter={getTechnicalSkillsFilter}
          getLanguageLevelsFilter={getLanguageLevelsFilter}
          getCompanyPositionsFilter={getCompanyPositionsFilter}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ resumes, users, filters }: RootState) => ({
  tableData: resumes.resumesTableData,
  isLoading: resumes.loading,
  errors: resumes.errors,
  languageLevelsList: filters.languageLevelsFilter.levels,
  foreignLanguagesList: filters.foreignLanguagesFilter.languages,
  technicalSkillList: filters.technicalSkillsFilter.skills,
  projectList: filters.projectsFilter.projects,
  companyPositionList: filters.companyPositionsFilter.positions,
  userPersonalInfo: users.current.personal,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getResumesList: () => dispatch(resumesActions.getResumesList()),
  editUserResume: (resumes: { id: string; data: FormData }[], callback: () => void) =>
    dispatch(resumesActions.editUserResume({ resumes, callback })),
  getLanguageLevelsFilter: () => dispatch(filterActions.getLanguageLevelsFilter()),
  getForeignLanguagesFilter: () => dispatch(filterActions.getForeignLanguagesFilter()),
  getTechnicalSkillsFilter: () => dispatch(filterActions.getTechnicalSkillsFilter()),
  getProjectsFilter: () => dispatch(filterActions.getProjectsFilter()),
  setResumesParams: (data: any) => dispatch(resumesActions.setResumesParams(data)),
  getOfficesFilter: () => dispatch(filterActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filterActions.getDepartmentsFilter()),
  getUsersFilter: () => dispatch(filterActions.getUsersFilter()),
  getCompanyPositionsFilter: () => dispatch(filterActions.getCompanyPositionsFilter()),
  getUserPersonalInfo: (uuid: string) => dispatch(usersActions.getUserPersonalInfo(uuid)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ResumesManagement);
