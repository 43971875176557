import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

type RecognitionIcon = {
  icon?: any;
  color: string;
  externalClass?: string;
};

function RecognitionIconPreview({ icon, color, externalClass }: RecognitionIcon) {
  const [svg, setSvg] = useState<string>('');
  const defaultClass = classNames('icons recognition-icons-preview', {
    [externalClass as string]: Boolean(externalClass),
  });

  useEffect(() => {
    if (icon) {
      if (icon.hasOwnProperty('url')) {
        fetch(icon.url)
          .then(res => res.text())
          .then(text => setSvg(text));
      } else {
        fetch(URL.createObjectURL(icon))
          .then(res => res.text())
          .then(text => setSvg(text));
      }
    } else {
      setSvg('');
    }
  }, [icon]);

  return (
    <span className={defaultClass} style={{ color }}>
      {parse(svg)}
    </span>
  );
}

export default RecognitionIconPreview;
