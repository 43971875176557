import { defineMessages } from 'react-intl';
import {
  saveButton,
  cancelButton,
  skillLabel,
  deleteModalTitle,
  deleteButton,
  okButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteModalTitle,
  skillLabel,
  cancelButton,
  saveButton,
  deleteButton,
  okButton,
  errorTitle: {
    id: 'profile.avatar.modal.size.error.title.Error',
    defaultMessage: 'Error',
  },
  modalSizeErrorTitle: {
    id: 'activity.modal.size.error.title.maximum.file.size.is.100MB',
    defaultMessage: 'Maximum file size is 100MB',
  },
  modalExtensionErrorTitle: {
    id: 'activity.modal.extension.error.title.only.exe.deb.dmg.msi.extension',
    defaultMessage: 'Only .exe, .deb, .dmg, .msi extension',
  },
});
