import React, { useMemo } from 'react';
import { OptionTypeBase } from 'react-select';
import { isEmpty } from 'lodash-es';
import Filter from '../../Filter';
import { CompetenciesInfo } from '../../../enums/competencies.enum';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { SkillsQuestionsParams } from '../../../enums/params/competencies.params';
import { FilterParamsName } from '../../../constants/filters.constants';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import FilterClearButton from '../../FilterClearButton';

type FiltersType = {
  competencies: CompetenciesInfo[];
};

type SkillQuestionsFiltersProps = {
  filters: FiltersType;
  values: SkillsQuestionsParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleClear: () => void;
  showClearButton: boolean;
};

function SkillQuestionsFilter({
  filters,
  values,
  handleMultiParamsChange,
  handleClear,
  showClearButton,
}: SkillQuestionsFiltersProps) {
  const intl = useIntl();

  const competenciesOptions = useMemo(
    () =>
      filters.competencies.map((competence: Record<string, any>) => ({
        label: competence.name,
        value: competence.id,
      })),
    [filters.competencies],
  );

  const competenciesValues = useFiltersListValue(competenciesOptions, values.competenceIds);

  const levelOptions = useMemo(() => {
    const newLevels: Array<{ id: string; name: string }> = [];
    const filteredCompetencies = !isEmpty(values.competenceIds)
      ? filters.competencies?.filter(item => values.competenceIds.find((el: any) => item.id === el))
      : filters.competencies || [];
    filteredCompetencies?.forEach(item => {
      item.competenceLevels?.forEach((competenceLevel: any) => {
        newLevels.findIndex(el => el.id === competenceLevel.id) === -1 &&
          newLevels.push({ id: competenceLevel.id, name: `${competenceLevel.name} (${item.name})` });
      });
    });
    return newLevels?.map((level: Record<string, any>) => ({
      label: level.name,
      value: level.id,
    }));
  }, [filters.competencies, values.competenceIds]);

  const levelsValues = useFiltersListValue(levelOptions, values.competenceLevelIds);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.competenciesTitle)}
        options={competenciesOptions}
        value={competenciesValues}
        handleChange={e => {
          handleMultiParamsChange(FilterParamsName.COMPETENCE_IDS)(e);
        }}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.levelsLabel)}
        options={levelOptions}
        value={levelsValues}
        handleChange={handleMultiParamsChange('competenceLevelIds')}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={handleClear} />}
    </>
  );
}

export default React.memo(SkillQuestionsFilter);
