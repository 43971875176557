import classNames from 'classnames';
import React from 'react';

type AnonymousAvatarProps = {
  size?: 'small' | 'tiny' | 'medium' | 'large' | 'huge';
  externalClass?: string;
};

function AnonymousAvatar({ size = 'medium', externalClass }: AnonymousAvatarProps) {
  const defaultClass = classNames(`page__profile-block-avatar anonymous ${size}`, {
    [externalClass as string]: Boolean(externalClass),
  });
  return (
    <div className={defaultClass}>
      <div className="page__profile-avatar page__profile-avatar--no-img table__data-no-image">?</div>
    </div>
  );
}

export default AnonymousAvatar;
