import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/notifications.constants';
import * as paramsTypes from '../enums/params/notifications.params';

export const getMailerSettingsList = (): AnyAction => ({
  type: ActionTypes.GET_MAILER_SETTINGS_LIST_REQUEST,
});

export const createNewMailerSetting = (data: any): AnyAction => ({
  type: ActionTypes.POST_MAILER_SETTING_REQUEST,
  payload: data,
});

export const editMailerSetting = (data: any): AnyAction => ({
  type: ActionTypes.PUT_MAILER_SETTING_REQUEST,
  payload: data,
});

export const deleteMailerSetting = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_MAILER_SETTING_REQUEST,
  payload: data,
});

export const setMailerSettingsParams = (data: Partial<paramsTypes.MailerSettingsParams>): AnyAction => ({
  type: ActionTypes.SET_MAILER_SETTINGS_PARAMS_REQUEST,
  payload: data,
});

export const getEmployeeGroupsList = (): AnyAction => ({
  type: ActionTypes.GET_EMPLOYEE_GROUPS_LIST_REQUEST,
});

export const createNewEmployeeGroup = (data: any): AnyAction => ({
  type: ActionTypes.POST_EMPLOYEE_GROUP_REQUEST,
  payload: data,
});

export const editEmployeeGroup = (data: any): AnyAction => ({
  type: ActionTypes.PUT_EMPLOYEE_GROUP_REQUEST,
  payload: data,
});

export const deleteEmployeeGroup = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_EMPLOYEE_GROUP_REQUEST,
  payload: data,
});

export const setEmployeeGroupsParams = (data: Partial<paramsTypes.EmployeeGroupsParams>): AnyAction => ({
  type: ActionTypes.SET_EMPLOYEE_GROUPS_PARAMS_REQUEST,
  payload: data,
});

export const getFollowUpsList = (): AnyAction => ({
  type: ActionTypes.GET_FOLLOW_UPS_LIST_REQUEST,
});

export const createNewFollowUp = (data: any): AnyAction => ({
  type: ActionTypes.POST_FOLLOW_UP_REQUEST,
  payload: data,
});

export const editFollowUp = (data: any): AnyAction => ({
  type: ActionTypes.PUT_FOLLOW_UP_REQUEST,
  payload: data,
});

export const deleteFollowUp = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_FOLLOW_UP_REQUEST,
  payload: data,
});

export const setFollowUpsParams = (data: Partial<paramsTypes.FollowUpsParams>): AnyAction => ({
  type: ActionTypes.SET_FOLLOW_UPS_PARAMS_REQUEST,
  payload: data,
});

export const resetErrors = (): AnyAction => ({
  type: ActionTypes.RESET_NOTIFICATIONS_ERRORS,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_NOTIFICATIONS_STATE,
});

export const getEmailTemplatesList = (): AnyAction => ({
  type: ActionTypes.GET_EMAIL_TEMPLATES_LIST_REQUEST,
});

export const createEmailTemplate = (data: any): AnyAction => ({
  type: ActionTypes.POST_EMAIL_TEMPLATE_REQUEST,
  payload: data,
});

export const editEmailTemplate = (data: any): AnyAction => ({
  type: ActionTypes.PUT_EMAIL_TEMPLATE_REQUEST,
  payload: data,
});

export const deleteEmailTemplate = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_EMAIL_TEMPLATE_REQUEST,
  payload: data,
});

export const setEmailTemplatesParams = (data: Partial<paramsTypes.EmailTemplatesParams>): AnyAction => ({
  type: ActionTypes.SET_EMAIL_TEMPLATES_PARAMS,
  payload: data,
});
