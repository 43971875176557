import request from '../utils/request.utils';
import { SavedFilter } from '../enums/filters.enum';
import { SavedFilterParams } from '../enums/params/filters.params';

export function getSavedFiltersList(params: SavedFilterParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/filters',
    params,
  };
  return request(options);
}

export function createSavedFilter(data: SavedFilter) {
  const options: RequestConfig = {
    method: 'post',
    url: '/filters',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editSavedFilter(data: SavedFilter) {
  const options: RequestConfig = {
    method: 'put',
    url: `/filters/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editFilterPublicStatus(data: SavedFilter) {
  const options: RequestConfig = {
    method: 'patch',
    url: `/filters/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { isPublic: data.isPublic },
  };
  return request(options);
}

export function deleteSavedFilter(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/filters/${id}`,
  };
  return request(options);
}
