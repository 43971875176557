import * as paramsTypes from './../enums/params/recognitions.params';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/recognitions.constants';

export const getRecognitionTypeList = (): AnyAction => ({
  type: ActionTypes.GET_RECOGNITION_TYPE_LIST_REQUEST,
});

export const createNewRecognitionType = (data: any): AnyAction => ({
  type: ActionTypes.POST_RECOGNITION_TYPE_REQUEST,
  payload: data,
});

export const deleteRecognitionType = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_RECOGNITION_TYPE_REQUEST,
  payload: data,
});

export const editRecognitionType = (data: any): AnyAction => ({
  type: ActionTypes.PUT_RECOGNITION_TYPE_REQUEST,
  payload: data,
});

export const getAllRecognitions = (): AnyAction => ({
  type: ActionTypes.GET_ALL_RECOGNITION_REQUEST,
});

export const deleteRecognition = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_RECOGNITION_REQUEST,
  payload: data,
});

export const editRecognition = (data: any): AnyAction => ({
  type: ActionTypes.PUT_RECOGNITION_REQUEST,
  payload: data,
});

export const getRecognitionByUser = (id: string): AnyAction => ({
  type: ActionTypes.GET_RECOGNITION_BY_USER_REQUEST,
  payload: id,
});

export const createUserRecognition = (data: any): AnyAction => ({
  type: ActionTypes.POST_RECOGNITION_USER_REQUEST,
  payload: data,
});

export const setRecognitionParams = (data: Partial<paramsTypes.RecognitionsParams>): AnyAction => ({
  type: ActionTypes.SET_RECOGNITION_PARAMS_REQUEST,
  payload: data,
});

export const setRecognitionTypesParams = (data: Partial<paramsTypes.RecognitionTypesParams>): AnyAction => ({
  type: ActionTypes.SET_RECOGNITION_TYPES_PARAMS_REQUEST,
  payload: data,
});

export const resetErrors = (): AnyAction => ({
  type: ActionTypes.RESET_RECOGNITIONS_ERRORS,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_RECOGNITIONS_STATE,
});
