import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  newCandidateStatusTitle: {
    id: 'candidates.candidate.statuses.modal.new.title.new.status',
    defaultMessage: 'New Status',
  },
  editCandidateStatusTitle: {
    id: 'candidates.candidate.statuses.modal.edit.title.edit.status',
    defaultMessage: 'Edit Status',
  },
  modalOneDefaultStatusLabel: {
    id: 'candidates.candidate.statuses.modal.label.note.that.only.one.status.can.be.set.as.default',
    defaultMessage: 'Note, that only one Status can be set as Default.',
  },
  modalDefaultLabel: {
    id: 'candidates.candidate.statuses.modal.label.default',
    defaultMessage: 'Default',
  },
});
