import { useFormik } from 'formik';
import { get, isString } from 'lodash-es';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import Input from '../../Input';
import Modal from '../../Modal';
import messages from '../messages';
import { ResourceLink, RESOURCE_LINK_SCHEMA } from '../../../enums/branding.enum';
import FileInput from '../../FileInput';
import { createFormData } from '../../../utils/data.utils';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';

type ModalEditMenuLinkProps = {
  onCloseRequest: () => void;
  editMenuLinkByAdmin: (data: any) => void;
  resetErrors: () => void;
  isOpen: boolean;
  menuLinkData: ResourceLink;
  menuLinkError: null | string | RejectValueErrors[];
  isLoading: boolean;
};

function ModalEditMenuLink({
  menuLinkData,
  isOpen,
  menuLinkError,
  isLoading,
  onCloseRequest,
  editMenuLinkByAdmin,
  resetErrors,
}: ModalEditMenuLinkProps) {
  const intl = useIntl();
  const fileRef = useRef(null);
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError, resetForm } = useFormik({
    initialValues: {
      file: null,
      dto: new ResourceLink(menuLinkData),
    },
    validationSchema: RESOURCE_LINK_SCHEMA,
    validateOnChange: false,
    onSubmit: data => {
      editMenuLinkByAdmin({
        id: data.dto.id,
        data: createFormData({ linkDto: data.dto, file: data.file }),
        callback: resetAndExit,
      });
    },
  });

  useSetFieldsErrors(menuLinkError, setFieldError);

  const [filePath, setFilePath] = useState('');

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleReset = useCallback(() => {
    if (fileRef.current) {
      //@ts-ignore
      fileRef.current.value = null;
    }
    setFieldValue('dto.deletePhoto', true);
    setFieldValue('file', null);
    setFieldValue('dto.file', null);
    setFilePath('');
  }, []);

  const handleChangeFile = useCallback(event => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue('file', file);
      setFieldValue('dto.file', file);
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = () => {
        setFieldValue('dto.deletePhoto', false);
        isString(fr.result) && setFilePath(fr.result);
      };
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      title={intl.formatMessage(messages.editMenuLinkTitle)}
      onRequestClose={resetAndExit}
      shouldCloseOnOverlayClick={false}
      classNameModal="center"
    >
      <form className="modal__form form custom-menu-link" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper ">
          <div className="form__input-block">
            <span className="icon-input-title">
              <FormattedMessage {...messages.iconLabel} />
            </span>
            <FileInput
              fileRef={fileRef}
              name={'file'}
              defaultValue={filePath}
              onChange={handleChangeFile}
              externalClass={'menu-link-icon'}
              accept=".svg,.png,.jpeg,.jpg"
            />
            <div className="form__inputs-subwrapper">
              {filePath || values.dto.file?.url ? (
                <span className="icons menu-link-icons">
                  {filePath ? <img src={filePath} /> : values.dto.file?.url && <img src={values.dto.file?.url} />}
                </span>
              ) : null}
              <span onClick={handleReset} className="navigation__icon-reset">
                <FormattedMessage {...messages.removeButton} />
              </span>
            </div>
          </div>
          <div className="form__input-block">
            <Input
              id={'dto.displayName'}
              name={'dto.displayName'}
              label={intl.formatMessage(messages.label)}
              defaultValue={values.dto?.displayName}
              onChange={handleChange}
              hasError={hasError('dto.displayName')}
              errorMessage={errors.dto?.displayName}
            />
          </div>
          <div className="form__input-block">
            <Input
              id={'dto.url'}
              name={'dto.url'}
              label={intl.formatMessage(messages.urlLabel)}
              defaultValue={values.dto?.url}
              onChange={handleChange}
              hasError={hasError('dto.url')}
              errorMessage={errors.dto?.url}
            />
          </div>
        </div>

        <ErrorMessage>{menuLinkError}</ErrorMessage>
        <div className={'form__buttons'}>
          <Button color={'gray'} type="button" externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type="submit" loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditMenuLink;
