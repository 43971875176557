import * as yup from 'yup';
import get from 'lodash-es/get';
import { CompetenceLevelType } from '../pages/Competencies/Competencies';
import { QuestionType } from '../pages/QuestionsForSkill';
import { EventPreviewInfo } from './schedule.enum';
import { UserInfo } from './users.enum';

export const COMPETENCIES_TYPES_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().required('Required').max(150, 'Maximum 150 characters'),
});
export const COMPETENCIES_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().required('Required').max(150, 'Maximum 150 characters'),
  competenceLevels: yup
    .array()
    .min(1, 'At least one level and skill are required')
    .of(
      yup.object().shape({
        name: yup.string().trim().required('Required').max(150, 'Maximum 150 characters'),
        skills: yup
          .array()
          .min(1, 'At least one level and skill are required')
          .of(
            yup.object().shape({
              name: yup.string().trim().required('Required'),
              maxScore: yup.number().min(0, "Shouldn't be negative").integer('Should be integer').required('Required'),
            }),
          ),
      }),
    ),
  competenceType: yup.object().shape({
    name: yup.string().trim().required('Required'),
  }),
});

export class CompetenciesInfo {
  competenceLevels: Array<CompetenceLevelType>;
  name: string;
  id: string;
  competenceType: {
    name: string;
    id: string;
  };
  skillsCount: number;
  levelsCount: number;
  competenceTypeId: string;

  constructor(competence?: unknown) {
    this.id = get(competence, 'id', '');
    this.name = get(competence, 'name', '');
    this.competenceLevels = get(competence, 'competenceLevels', [
      {
        id: '',
        name: '',
        priority: 0,
        skills: [
          {
            id: '',
            maxScore: 0,
            name: '',
            position: 0,
          },
        ],
      },
    ]);
    this.competenceType = get(competence, 'competenceType', {
      name: '',
      id: '',
    });
    this.levelsCount = 0;
    this.skillsCount = 0;
    this.competenceTypeId = get(competence, 'competenceTypeId', '');
  }
}

export const ASSESSMENT_VALIDATION_SCHEMA = yup.object().shape({
  employee: yup.object().shape({
    id: yup.string().required('Required'),
  }),
  // TODO assessmentDate is a string of format YYYY-MM-DD HH:mm:ss
  assessmentDate: yup.string().required('Required'),
  // TODO implement relatedEvent when it's ready on the back
  // relatedEvent: yup.mixed().nullable().notRequired(),
  organizers: yup.array().ensure(),
  reviewers: yup.array().min(1, 'Required'),
  competenceId: yup.string().nullable().required('Required'),
});

export type ResultType = {
  score: number;
  skillId: string;
  levelId: string;
  maxScore: number;
};

export class AssessmentInfoPreview {
  id: string;
  assessmentDate: string;
  competenceTypeName: string;
  competenceName: string;

  constructor(assessment?: unknown) {
    this.id = get(assessment, 'id', '');
    this.assessmentDate = get(assessment, 'assessmentDate', '');
    this.competenceTypeName = get(assessment, 'competenceTypeName', '');
    this.competenceName = get(assessment, 'competenceName', '');
  }
}

export class AssessmentsInfo {
  assessmentDate: string;
  competence: any;
  competenceId: string;
  employee: UserInfo;
  employeeId: string;
  id: string;
  event: EventPreviewInfo;
  eventId: string | null;
  lastUpdateDate: string;
  organizers: Array<UserInfo>;
  organizerIds: Array<string>;
  results: Array<ResultType>;
  reviewers: Array<UserInfo>;
  reviewerIds: Array<string>;
  updater: UserInfo;

  constructor(assessment?: unknown) {
    this.id = get(assessment, 'id', '');
    this.assessmentDate = get(assessment, 'assessmentDate', '');
    this.competence = new CompetenciesInfo(get(assessment, 'competence', {}));
    this.competenceId = get(assessment, 'competenceId', null);
    this.employee = new UserInfo(get(assessment, 'employee', {}));
    this.employeeId = get(assessment, 'employeeId', '');
    this.lastUpdateDate = get(assessment, 'lastUpdateDate', '');
    this.event = new EventPreviewInfo(get(assessment, 'event', {}));
    this.eventId = get(assessment, 'eventId', null);
    this.organizers = get(assessment, 'organizers', []).map((organizer: unknown) => new UserInfo(organizer));
    this.organizerIds = get(assessment, 'organizerIds', []);
    this.results = get(assessment, 'results', []);
    this.reviewers = get(assessment, 'reviewers', []).map((reviewer: unknown) => new UserInfo(reviewer));
    this.reviewerIds = get(assessment, 'reviewerIds', []);
    this.updater = new UserInfo(get(assessment, 'updater', {}));
  }
}

export const QUESTIONS_FOR_SKILL_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().nullable().required('Required'),
  levelName: yup.string().trim().nullable().required('Required'),
  competenceName: yup.string().trim().nullable().required('Required'),
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().trim().required('Required'),
      correctAnswer: yup.string().trim().required('Required'),
    }),
  ),
});

export class SkillQuestionsInfo {
  skillId: string;
  name: string;
  competenceId: string;
  competenceName: string;
  levelId: string;
  levelName: string;
  questionsCount: number;
  questions: Array<QuestionType>;

  constructor(skillQuestions?: unknown) {
    this.skillId = get(skillQuestions, 'skillId', '');
    this.name = get(skillQuestions, 'name', '');
    this.competenceId = get(skillQuestions, 'competenceId', '');
    this.competenceName = get(skillQuestions, 'competenceName', '');
    this.levelId = get(skillQuestions, 'levelId', '');
    this.levelName = get(skillQuestions, 'levelName', '');
    this.questionsCount = get(skillQuestions, 'questionsCount', 0);
    this.questions = get(skillQuestions, 'questions', [
      {
        id: '',
        question: '',
        correctAnswer: '',
      },
    ]);
  }
}
