export const GET_RECOGNITION_TYPE_LIST_REQUEST = 'GET_RECOGNITION_TYPE_LIST_REQUEST';
export const GET_RECOGNITION_TYPE_LIST_SUCCESS = 'GET_RECOGNITION_TYPE_LIST_SUCCESS';
export const GET_RECOGNITION_TYPE_LIST_FAILURE = 'GET_RECOGNITION_TYPE_LIST_FAILURE';

export const POST_RECOGNITION_TYPE_REQUEST = 'POST_RECOGNITION_TYPE_REQUEST';
export const POST_RECOGNITION_TYPE_SUCCESS = 'POST_RECOGNITION_TYPE_SUCCESS';
export const POST_RECOGNITION_TYPE_FAILURE = 'POST_RECOGNITION_TYPE_FAILURE';

export const DELETE_RECOGNITION_TYPE_REQUEST = 'DELETE_RECOGNITION_TYPE_REQUEST';
export const DELETE_RECOGNITION_TYPE_SUCCESS = 'DELETE_RECOGNITION_TYPE_SUCCESS';
export const DELETE_RECOGNITION_TYPE_FAILURE = 'DELETE_RECOGNITION_TYPE_FAILURE';

export const PUT_RECOGNITION_TYPE_REQUEST = 'PUT_RECOGNITION_TYPE_REQUEST';
export const PUT_RECOGNITION_TYPE_SUCCESS = 'PUT_RECOGNITION_TYPE_SUCCESS';
export const PUT_RECOGNITION_TYPE_FAILURE = 'PUT_RECOGNITION_TYPE_FAILURE';

export const GET_ALL_RECOGNITION_REQUEST = 'GET_ALL_RECOGNITION_REQUEST';
export const GET_ALL_RECOGNITION_SUCCESS = 'GET_ALL_RECOGNITION_SUCCESS';
export const GET_ALL_RECOGNITION_FAILURE = 'GET_ALL_RECOGNITION_FAILURE';

export const DELETE_RECOGNITION_REQUEST = 'DELETE_RECOGNITION_REQUEST';
export const DELETE_RECOGNITION_SUCCESS = 'DELETE_RECOGNITION_SUCCESS';
export const DELETE_RECOGNITION_FAILURE = 'DELETE_RECOGNITION_FAILURE';

export const PUT_RECOGNITION_REQUEST = 'PUT_RECOGNITION_REQUEST';
export const PUT_RECOGNITION_SUCCESS = 'PUT_RECOGNITION_SUCCESS';
export const PUT_RECOGNITION_FAILURE = 'PUT_RECOGNITION_FAILURE';

export const GET_RECOGNITION_BY_USER_REQUEST = 'GET_RECOGNITION_BY_USER_REQUEST';
export const GET_RECOGNITION_BY_USER_SUCCESS = 'GET_RECOGNITION_BY_USER_SUCCESS';
export const GET_RECOGNITION_BY_USER_FAILURE = 'GET_RECOGNITION_BY_USER_FAILURE';

export const POST_RECOGNITION_USER_REQUEST = 'POST_RECOGNITION_USER_REQUEST';
export const POST_RECOGNITION_USER_SUCCESS = 'POST_RECOGNITION_USER_SUCCESS';
export const POST_RECOGNITION_USER_FAILURE = 'POST_RECOGNITION_USER_FAILURE';

export const SET_RECOGNITION_PARAMS_REQUEST = 'SET_RECOGNITION_PARAMS_REQUEST';
export const SET_RECOGNITION_TYPES_PARAMS_REQUEST = 'SET_RECOGNITION_TYPES_PARAMS_REQUEST';

export const RESET_RECOGNITIONS_ERRORS = 'RESET_RECOGNITIONS_ERRORS';
export const RESET_RECOGNITIONS_STATE = 'RESET_RECOGNITIONS_STATE';

export const recognitionsUnsavedParams = ['dateFrom', 'dateTo'];
