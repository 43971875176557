import React, { useEffect } from 'react';
import * as competenciesActions from '../../../actions/competencies.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import NewSkillQuestionsForm from '../../../components/SkillQuestions/Forms/NewSkillQuestionsForm';
import r from '../../../constants/routes.constants';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { getCompetenciesFilter } from '../../../actions/filters.actions';

function NewSkillQuestions({
  competenciesList,
  isLoading,
  skillQuestionsDataError,
  getCompetenciesFilters,
  createNewSkillQuestions,
}: ConnectedProps<typeof connector>) {
  useEffect(() => {
    getCompetenciesFilters();
  }, []);

  const history = useHistory();

  const createSkillQuestionsCallback = () => {
    history.push(r.skillQuestions);
  };

  const onSubmitHandler = (data: any) => {
    createNewSkillQuestions({ data, callback: createSkillQuestionsCallback });
  };

  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper fixed-container">
          <h1 className="page__title">
            <FormattedMessage {...messages.newQuestionsForSkillsTitle} />
          </h1>
        </div>
      </div>
      <div className="page__main-content">
        <div className="page__wrapper fixed-container">
          <NewSkillQuestionsForm
            skillQuestionsDataError={skillQuestionsDataError}
            isLoading={isLoading}
            competencies={competenciesList}
            onSubmit={onSubmitHandler}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ competencies, filters }: RootState) => ({
  skillQuestionsDataError: competencies.errors.skillQuestionsDataError,
  competenciesList: filters.competenciesFilter.competencies,
  isLoading: competencies.loading.createCompetence,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createNewSkillQuestions: (data: any) => dispatch(competenciesActions.createNewSkillQuestions(data)),
  getCompetenciesFilters: () => dispatch(getCompetenciesFilter()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NewSkillQuestions);
