import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as competenciesActions from '../../../actions/competencies.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Icon from '../../../components/Icon';
import ModalDeleteCompetence from '../../../components/Competencies/Modals/ModalDeleteCompetence';
import Button from '../../../components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { TRouteParamId } from '../../../enums/common.enum';
import PoliciesContext from '../../../PoliciesContext';
import { checkPolicies } from '../../../utils/policies.utils';
import { DELETE_COMPETENCE, UPDATE_COMPETENCE } from '../../../constants/policies.constants';
import HierarchicalTable from '../../../components/HierarchicalTable';
import { useTableData } from '../../../utils/hooks.utils';
import r from '../../../constants/routes.constants';

function CompetenceInfo({
  cleanCompetenceData,
  competenciesErrors,
  getCompetence,
  competenceData,
  isLoading,
  deleteCompetence,
  resetErrors,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const intl = useIntl();

  const { id } = useParams<TRouteParamId>();
  const history = useHistory();
  const classes = ['table__data--trainee', 'table__data--junior', 'table__data--middle', 'table__data--senior'];

  const [modalDeletePositionIsOpen, setModalDeletePositionIsOpen] = useState(false);

  const onEditHandler = useCallback(() => {
    history.push(`/competencies/edit/${id}`);
  }, []);

  const openDeleteCompetenceModal = useCallback(() => {
    setModalDeletePositionIsOpen(true);
  }, []);

  const closeDeleteCompetenceModal = useCallback(() => {
    setModalDeletePositionIsOpen(false);
  }, []);

  const setCompetenceCallback = useCallback(() => {
    history.push(r.competencies);
  }, []);

  const backButtonHandler = useCallback(() => {
    history.push(r.competencies);
  }, []);

  useEffect(() => {
    getCompetence({ data: id });
    return () => {
      cleanCompetenceData();
    };
  }, [id]);

  const tableColumns = useMemo(
    () => [
      {
        Header: intl.formatMessage(messages.skillsColumn),
        accessor: 'name',
      },
      {
        Header: intl.formatMessage(messages.maxScoreColumn),
        accessor: 'maxScore',
        headClassName: 'competence-max-score',
      },
      {
        Header: intl.formatMessage(messages.questionsTitle),
        accessor: 'questionsCount',
        headClassName: 'competence-questions',
      },
    ],
    [],
  );

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 0 && row.id < 4) {
      return classes[row.id];
    }
    return '';
  }, []);

  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper fixed-container">
          <div className="page__panel__back-title">
            <Button externalClass="page__back" color="gray" onClick={backButtonHandler}>
              <FormattedMessage {...messages.competenciesTitle} />
            </Button>
          </div>
          <div className="page__profile-panel__label-options">
            {checkPolicies([UPDATE_COMPETENCE], policies) && (
              <Button circle color="gray" onClick={onEditHandler}>
                <Icon iconName={'pencil'} externalClass={'button__icon button__icon--bigger'} />
              </Button>
            )}
            {checkPolicies([DELETE_COMPETENCE], policies) && (
              <Button circle color="gray" onClick={openDeleteCompetenceModal}>
                <Icon iconName={'trash'} externalClass={'button__icon button__icon--bigger'} />
              </Button>
            )}
          </div>
        </div>
        <div className="page__panel__wrapper fixed-container title-wratter">
          <h1 className="page__title page__title--smaller">{competenceData?.name || ''}</h1>
        </div>
      </div>
      <div className="page__inner-content">
        <div className="page__wrapper fixed-container">
          {competenceData?.competenceType?.name && (
            <div className="page__info-block page__info-block--info">
              <Icon iconName={'info'} />
              {competenceData.competenceType.name}
            </div>
          )}

          <HierarchicalTable
            tableColumns={tableColumns}
            loading={isLoading.getCompetence}
            error={competenciesErrors.competenceDataError}
            externalClass="fixed"
            tableData={useTableData(competenceData?.competenceLevels, ['skills'])}
            externalRowClass={handleExternalRowClass}
          />
        </div>
      </div>
      {modalDeletePositionIsOpen && (
        <ModalDeleteCompetence
          isOpen
          onCloseRequest={closeDeleteCompetenceModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteCompetence({
              ...data,
            });
          }}
          isLoading={isLoading.deleteCompetence}
          competenceError={competenciesErrors.deleteCompetenceError}
          competenceData={competenceData}
          callback={setCompetenceCallback}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ competencies }: RootState) => ({
  isLoading: competencies.loading,
  competenceData: competencies.competenceData || undefined,
  competenciesErrors: competencies.errors,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  editCompetence: (data: any) => dispatch(competenciesActions.editCompetence(data)),
  getCompetence: (data: any) => dispatch(competenciesActions.getCompetence(data)),
  cleanCompetenceData: () => dispatch(competenciesActions.cleanCompetenceData()),
  deleteCompetence: (data: Record<string, unknown>) => dispatch(competenciesActions.deleteCompetence(data)),
  resetErrors: () => dispatch(competenciesActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CompetenceInfo);
