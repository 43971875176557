import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/activity.constants';
import * as api from '../api/activity.api';
import { ActivityParams } from '../enums/params/activity.params';
import { AnyAction } from 'redux';

function* getActivityTable() {
  try {
    const params: ActivityParams = yield select((state: RootState) => state.activity.params);
    const { success, results } = yield call(api.getActivity, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_ACTIVITY_TABLE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ACTIVITY_TABLE_FAILURE, payload: error });
  }
}

function* getUserTabActivity() {
  try {
    const params: ActivityParams = yield select((state: RootState) => state.activity.userActivityTabParams);
    const { success, results } = yield call(api.getActivity, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_ACTIVITY_TABLE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ACTIVITY_TABLE_FAILURE, payload: error });
  }
}

function* getActivityDiagram() {
  try {
    const params: ActivityParams = yield select((state: RootState) => state.activity.params);
    const { success, results } = yield call(api.getActivity, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_ACTIVITY_DIAGRAM_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ACTIVITY_DIAGRAM_FAILURE, payload: error });
  }
}

function* setActivityDiagramParams() {
  yield put({ type: ActionTypes.GET_ACTIVITY_DIAGRAM_REQUEST });
}

function* setActivityTableParams() {
  yield put({ type: ActionTypes.GET_ACTIVITY_TABLE_REQUEST });
}

function* getSoftwareLinksList() {
  try {
    const { success, results } = yield call(api.getSoftwareLinksList);

    if (success) {
      yield put({
        type: ActionTypes.GET_SOFTWARE_LINKS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SOFTWARE_LINKS_FAILURE, payload: error });
  }
}

function* downloadSoftware({ payload }: AnyAction) {
  try {
    const { results, success } = yield call(api.getSoftware, payload.id);
    if (success) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(results);
      link.download = payload.displayName;
      link.click();
      yield put({
        type: ActionTypes.GET_SOFTWARE_SUCCESS,
        payload: { fileId: payload.id },
      });
    }
  } catch (error) {
    //@ts-ignore
    yield put({ type: ActionTypes.GET_SOFTWARE_FAILURE, payload: { ...error, fileId: payload.id } });
  }
}

function* uploadSoftware({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.uploadSoftware, payload);

    if (success) {
      yield put({
        type: ActionTypes.POST_SOFTWARE_SUCCESS,
        payload: results,
      });
      yield call(getSoftwareLinksList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_SOFTWARE_FAILURE, payload: error });
  }
}

function* deleteSoftware({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.deleteSoftware, payload.uuid);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_SOFTWARE_SUCCESS,
        payload: results,
      });
      yield call(getSoftwareLinksList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_SOFTWARE_FAILURE, payload: error });
  }

  yield payload.callback();
}

export default function* mainSaga() {
  yield all([
    takeEvery(ActionTypes.GET_ACTIVITY_TABLE_REQUEST, getActivityTable),
    takeEvery(ActionTypes.GET_ACTIVITY_DIAGRAM_REQUEST, getActivityDiagram),
    takeEvery(ActionTypes.SET_ACTIVITY_TABLE_PARAMS_REQUEST, setActivityTableParams),
    takeEvery(ActionTypes.SET_ACTIVITY_DIAGRAM_PARAMS_REQUEST, setActivityDiagramParams),
    takeEvery(ActionTypes.SET_USER_TAB_ACTIVITY_PARAMS_REQUEST, getUserTabActivity),
    takeEvery(ActionTypes.GET_SOFTWARE_LINKS_REQUEST, getSoftwareLinksList),
    takeEvery(ActionTypes.GET_SOFTWARE_REQUEST, downloadSoftware),
    takeEvery(ActionTypes.POST_SOFTWARE_REQUEST, uploadSoftware),
    takeEvery(ActionTypes.DELETE_SOFTWARE_REQUEST, deleteSoftware),
  ]);
}
