import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Table from '../../components/Table';
import ModalDeleteOffice from '../../components/Offices/Modals/ModalDeleteOffice';
import ModalOffice from '../../components/Offices/Modals/ModalOffice';
import ModalNewOffice from '../../components/Offices/Modals/ModalNewOffice';
import ModalEditOffice from '../../components/Offices/Modals/ModalEditOffice';
import * as librariesActions from '../../actions/libraries.actions';
import { OfficeInfo } from '../../enums/libraries.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { OfficesParams } from '../../enums/params/libraries.params';
import { DELETE_OFFICE, UPDATE_OFFICE } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';

function Offices({
  getOfficesList,
  deleteOffice,
  createNewOffice,
  editOffice,
  setOfficesParams,
  tableData,
  sortParams,
  officeError,
  isLoading,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const [modalNewOfficeIsOpen, setModalNewOfficeIsOpen] = useState(false);
  const [modalEditOfficeIsOpen, setModalEditOfficeIsOpen] = useState(false);
  const [modalDeleteOfficeIsOpen, setModalDeleteOfficeIsOpen] = useState(false);
  const [modalOfficeIsOpen, setModalOfficeIsOpen] = useState(false);
  const [officeClicked, setOfficeClicked] = useState(new OfficeInfo());

  useEffect(() => {
    getOfficesList();
    return () => {
      resetState();
    };
  }, []);

  const intl = useIntl();

  const openNewOfficeModal = useCallback(() => {
    setModalNewOfficeIsOpen(true);
  }, []);

  const closeNewOfficeModal = useCallback(() => {
    setModalNewOfficeIsOpen(false);
  }, []);

  const openOfficeModal = useCallback((office: OfficeInfo) => {
    setOfficeClicked(office);
    setModalOfficeIsOpen(true);
  }, []);

  const closeOfficeModal = useCallback(() => {
    setModalOfficeIsOpen(false);
  }, []);

  const openEditOfficeModal = useCallback(() => {
    setModalEditOfficeIsOpen(true);
    setModalOfficeIsOpen(false);
  }, []);

  const closeEditOfficeModal = useCallback(() => {
    setModalEditOfficeIsOpen(false);
  }, []);

  const openDeleteOfficeModal = useCallback(() => {
    setModalDeleteOfficeIsOpen(true);
  }, []);

  const closeDeleteOfficeModal = useCallback(() => {
    setModalDeleteOfficeIsOpen(false);
  }, []);

  const handleSort = useCallback((sortBy, direction) => setOfficesParams({ sortBy, direction }), []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: OfficeInfo) => (
          <span className={'table_button--open-modal'} onClick={() => openOfficeModal(row)}>
            {row.name}
          </span>
        ),
      },
      {
        name: intl.formatMessage(messages.addressLabel),
        sortName: 'address',
        modifier: (row: OfficeInfo) => row.address,
        className: 'table__options',
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: OfficeInfo) => {
          setOfficeClicked(row);
          openEditOfficeModal();
        },
        verifiablePolicies: [UPDATE_OFFICE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: OfficeInfo) => {
          setOfficeClicked(row);
          openDeleteOfficeModal();
        },
        verifiablePolicies: [DELETE_OFFICE],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper">
              <AccessChecker verifiablePolicies={[UPDATE_OFFICE]}>
                <Button externalClass={'button--with-icon'} onClick={openNewOfficeModal}>
                  <Icon iconName={'plus'} externalClass={'button__icon'} />
                  <span className="button__text">
                    <FormattedMessage {...messages.newButton} />
                  </span>
                </Button>
              </AccessChecker>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table--offices'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            params={sortParams}
            tableActions={tableActions}
            onSort={handleSort}
            error={officeError}
            loading={isLoading.getOffices}
          />
        </div>
      </div>
      {modalNewOfficeIsOpen && (
        <ModalNewOffice
          isOpen
          onCloseRequest={closeNewOfficeModal}
          createNewOffice={createNewOffice}
          officeError={officeError}
          isLoading={isLoading.createOffice}
          resetErrors={resetErrors}
        />
      )}
      {modalEditOfficeIsOpen && (
        <ModalEditOffice
          isOpen
          onCloseRequest={closeEditOfficeModal}
          officeData={officeClicked}
          editOffice={editOffice}
          officeError={officeError}
          isLoading={isLoading.editOffice}
          resetErrors={resetErrors}
        />
      )}
      {modalOfficeIsOpen && (
        <ModalOffice
          isOpen
          onCloseRequest={closeOfficeModal}
          data={officeClicked}
          onDeleteRequest={openDeleteOfficeModal}
          onEditRequest={openEditOfficeModal}
        />
      )}
      {modalDeleteOfficeIsOpen && (
        <ModalDeleteOffice
          isOpen
          onCloseRequest={closeDeleteOfficeModal}
          onDeleteRequest={(data: any) => {
            deleteOffice({
              ...data,
              setOfficeCallback: () => {
                setOfficeClicked(new OfficeInfo());
              },
            });
          }}
          officeData={officeClicked}
          officeError={officeError}
          isLoading={isLoading.deleteOffice}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries }: RootState) => ({
  sortParams: libraries.officesParams,
  tableData: libraries.officesTableData?.content,
  officeError: libraries.errors.officeError,
  isLoading: libraries.loading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesList: () => dispatch(librariesActions.getOfficesList()),
  deleteOffice: (data: any) => dispatch(librariesActions.deleteOffice(data)),
  createNewOffice: (data: any) => dispatch(librariesActions.createNewOffice(data)),
  editOffice: (id: string, data: any) => dispatch(librariesActions.editOffice({ ...data, id })),
  setOfficesParams: (params: Partial<OfficesParams>) => dispatch(librariesActions.setOfficesParams(params)),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Offices);
