import React, { useState, useCallback } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { MonthReportParts, PlannedExpenseClass } from '../../../enums/finance/finance.enum';
import CurrencyInput from '../../CurrencyInput';
import { CurrencyType, ExpenseType } from '../../../types/finance';
import Checkbox from '../../Checkbox';
import MonthSelect from '../../MonthSelect';

type ModalDeletePlannedExpenseProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onDeleteRequest: (data: { id: string; deleteFutureExpenses: boolean; callback: () => void }) => void;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  officeMonthClicked: {
    office: { id: string; name: string; isDeleted: boolean };
    monthData: MonthReportParts;
    expenseType: ExpenseType;
  } | null;
  baseCurrency: CurrencyType | undefined;
};

function ModalDeletePlannedExpense({
  isOpen,
  onCloseRequest,
  onDeleteRequest,
  error,
  isLoading,
  officeMonthClicked,
  baseCurrency,
}: ModalDeletePlannedExpenseProps) {
  const intl = useIntl();

  const [deleteFutureExpenses, setDeleteFutureExpenses] = useState(false);

  const onDelete = useCallback(() => {
    officeMonthClicked &&
      onDeleteRequest({
        id: officeMonthClicked?.monthData.expenseId,
        deleteFutureExpenses,
        callback: onCloseRequest,
      });
  }, [deleteFutureExpenses, officeMonthClicked]);

  const plannedExpense = new PlannedExpenseClass({
    startDate: officeMonthClicked?.monthData.month,
    endDate: officeMonthClicked?.monthData.month,
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.deleteModalTitle)}
      size="small"
      shouldCloseOnOverlayClick
      classNameModal="planned-expense-modal center"
    >
      <div className="form__inputs-wrapper">
        <div className="form__input-block office-block">
          {officeMonthClicked ? `${officeMonthClicked?.expenseType?.name} / ${officeMonthClicked?.office?.name}` : ''}
        </div>
        <div className="form__inputs-subwrapper">
          <div className="form__input-block">
            <MonthSelect
              year={plannedExpense.startYear}
              month={plannedExpense.startMonth}
              isShortMonths
              withLabel={false}
              isDisabled
              selectExternalClass="select__no-label"
            />
          </div>
          <div className="form__month-select-separator">-</div>
          <div className="form__input-block">
            <MonthSelect
              year={plannedExpense.endYear}
              month={plannedExpense.endMonth}
              isShortMonths
              withLabel={false}
              isDisabled
              selectExternalClass="select__no-label"
            />
          </div>
        </div>
        <div className="form__inputs-subwrapper">
          <>
            <CurrencyInput
              label={intl.formatMessage(messages.payLabel)}
              name="amount"
              wrapperClass="form__input-block--half"
              //@ts-ignore
              value={officeMonthClicked?.monthData?.amount?.value || ''}
              disabled
              suffix={` ${baseCurrency?.name || ''}`}
            />
          </>
        </div>
        <Checkbox
          externalClass="form__checkbox-capitalazie-label"
          onChange={event => setDeleteFutureExpenses(event.target.checked)}
          id="fromPreviousExperience"
          label={intl.formatMessage(messages.deleteFutureExpensesLabel)}
          checked={deleteFutureExpenses}
        />
      </div>
      <ErrorMessage>{error}</ErrorMessage>
      <div className="form__buttons">
        <Button color="gray" externalClass="button--modal button--cancel" onClick={onCloseRequest} block>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button externalClass="button--modal" onClick={onDelete} loading={isLoading} disabled={isLoading} block>
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeletePlannedExpense;
