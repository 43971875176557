import { defineMessages } from 'react-intl';

import { newButton, editButton, deleteButton, nameColumn, editorColumn, allLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editorColumn,
  allLabel,
  deleteButton,
  editButton,
  newButton,
  modifiedTitle: {
    id: 'schedule.event.types.column.modified',
    defaultMessage: 'Modified',
  },
  eventsTitle: {
    id: 'schedule.event.types.column.events',
    defaultMessage: 'Events',
  },
  whoCanManageTitle: {
    id: 'schedule.event.types.column.who.can.manage',
    defaultMessage: 'Who can manage',
  },
  pageTitle: {
    id: 'schedule.event.types.title.event.types',
    defaultMessage: 'Event Types',
  },
});
