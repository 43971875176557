import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import InlineDatePicker from '../../components/InlineDatePicker';
import DurationPicker from '../../components/Dropdown';
import Icon from '../../components/Icon';
import messages from './messages';
import * as filtersActions from '../../actions/filters.actions';
import * as reportsActions from '../../actions/reports.actions';
import { EmployeesTimesheetReportParams } from '../../enums/params/reports.params';
import HierarchicalTable from '../../components/HierarchicalTable';
import EmployeesTimesheetFilter from '../../components/EmployeesTimesheet/Filter/EmployeesTimesheetFilter';
import { resetParamsChange, useParamsChange, useTableData, useUsersParamsChange } from '../../utils/hooks.utils';
import { useDataForTable } from './useDataForTable';
import Hint from '../../components/Hint';
import { handleReportUsersStatuses } from '../../utils/reports.utils';
import { ReportsUserStatusesType } from '../../types/reports';
import { exportEmployeesTimesheetReport } from '../../actions/export.actions';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { employeesTimesheetUnsavedParams } from '../../constants/reports.constants';

function EmployeesTimesheet({
  tableData,
  departmentsFilter,
  officesFilter,
  usersFilter,
  isLoading,
  errors,
  params,
  reportsUsersStatuses,
  jiraProjectFilter,
  setEmployeesTimesheetReportParams,
  getOfficesFilter,
  getDepartmentsFilter,
  getUsersFilter,
  getJiraProjectsFilter,
  exportEmployeesTimesheetReport,
  setReportsUsersStatuses,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleMultiParamsChange = useParamsChange(setEmployeesTimesheetReportParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setEmployeesTimesheetReportParams, dispatch);

  const handleDataChange = useCallback((start: string, end: string) => {
    setEmployeesTimesheetReportParams({ dateFrom: start, dateTo: end });
  }, []);

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.MEMBERS_TIMESHEET_REPORT_FILTER);

    setEmployeesTimesheetReportParams(
      currentSavedFilter
        ? new EmployeesTimesheetReportParams(currentSavedFilter)
        : {
            offices: officesFilter.value,
            departmentIds: departmentsFilter.value,
            portalUserIds: usersFilter.value,
            isAllowActive: reportsUsersStatuses.isAllowActive,
            isAllowPassive: reportsUsersStatuses.isAllowPassive,
            jiraProjectIds: jiraProjectFilter.value,
          },
    );
    getOfficesFilter();
    getDepartmentsFilter();
    getUsersFilter();
    getJiraProjectsFilter();
    setSavedFiltersParams({ filterType: FilterTypes.MEMBERS_TIMESHEET_REPORT_FILTER });
  }, []);

  const tableDataWithTotal = useMemo(() => {
    if (tableData?.users.length && !tableData?.users[tableData.users.length - 1].totalItem) {
      const newData = tableData?.users;
      newData?.push({
        totalItem: true,
        totalWorkedByDates: tableData.totalWorkedByDates,
        fact: tableData.totalWorked,
        planned: tableData.totalPlanned,
      });

      return newData;
    }
    return tableData?.users;
  }, [tableData]);

  const { tableColumns, tableHeaderItems } = useDataForTable(tableData);

  const filters = useMemo(
    () => ({
      departments: departmentsFilter.departments,
      offices: officesFilter.offices,
      users: usersFilter.users,
      jiraProjectsList: jiraProjectFilter.jiraProject,
    }),
    [departmentsFilter.departments, officesFilter.offices, usersFilter.users],
  );

  const handleUsersStatuses = useCallback(
    data =>
      handleReportUsersStatuses((reportsUsersStatuses: ReportsUserStatusesType) => {
        setEmployeesTimesheetReportParams(reportsUsersStatuses);
        setReportsUsersStatuses(reportsUsersStatuses);
      }, data),
    [],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      setEmployeesTimesheetReportParams(
        new EmployeesTimesheetReportParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setEmployeesTimesheetReportParams(
      new EmployeesTimesheetReportParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }),
    );

    resetParamsChange(
      [
        FilterParamsName.PORTAL_USER_IDS,
        FilterParamsName.DEPARTMENT_IDS,
        FilterParamsName.OFFICES,
        FilterParamsName.JIRA_PROJECT_IDS,
      ],
      dispatch,
    );
  }, [params]);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new EmployeesTimesheetReportParams(), employeesTimesheetUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed employees-report-page">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                />
                <DurationPicker
                  dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                  dropdownList={[
                    {
                      label: intl.formatMessage(messages.exportToXLSLabel),
                      handler: exportEmployeesTimesheetReport,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <EmployeesTimesheetFilter
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleUsersStatuses={handleUsersStatuses}
                  handleUsersParamsChange={handleUsersParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content employees-timesheet-page">
        <div className="page__wrapper">
          <div className="page__scrollable-table-wrapper">
            <div className="page__scrollable-table-wrapper__inner employees-timesheet-wrapper">
              <HierarchicalTable
                tableData={useTableData(tableDataWithTotal, ['projects', 'issues'])}
                tableColumns={tableColumns}
                loading={isLoading}
                error={errors}
                tableHeaderItems={tableHeaderItems}
              />
            </div>
          </div>
        </div>
      </div>
      <Hint dependencyList={tableColumns} className="employees-timesheet-hint" />
    </>
  );
}

const mapStateToProps = ({ filters, reports, auth }: RootState) => ({
  tableData: reports.employeesTimesheetReportData,
  departmentsFilter: filters.departmentsFilter,
  officesFilter: filters.officesFilter,
  usersFilter: filters.usersFilter,
  jiraProjectFilter: filters.jiraProjectFilter,
  isLoading: reports.loading.employeesTimesheetReport,
  errors: reports.errors.employeesReportErrors,
  params: reports.employeesTimesheetReportParams,
  reportsUsersStatuses: reports.reportsUserStatuses,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  getJiraProjectsFilter: () => dispatch(filtersActions.getJiraProjectsFilter()),
  setEmployeesTimesheetReportParams: (data: Partial<EmployeesTimesheetReportParams>) =>
    dispatch(reportsActions.setEmployeesTimesheetReportParams(data)),
  exportEmployeesTimesheetReport: () => dispatch(exportEmployeesTimesheetReport()),
  setReportsUsersStatuses: (data: ReportsUserStatusesType) => dispatch(reportsActions.setReportsUsersStatuses(data)),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EmployeesTimesheet);
