import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/activity.constants';
import { ActivityParams } from '../enums/params/activity.params';
import { TActivityDto } from '../types/activity';

type InitialStateType = {
  activityTable: null | TActivityDto;
  activityDiagram: [];
  params: ActivityParams;
  userActivityTabParams: ActivityParams;
  softwareLinksList: any;
  exportedFiles: any[];
  errors: {
    activityTableError: string | null;
    activityDiagramError: string | null;
    softwareError: string | null;
  };
  loading: {
    getActivityTable: boolean;
    getactivityDiagram: boolean;
    getSoftwareLinksList: boolean;
    getSoftware: boolean;
    postSoftware: boolean;
    deleteSoftware: boolean;
  };
};

const initialState: InitialStateType = {
  activityTable: null,
  activityDiagram: [],
  params: new ActivityParams(),
  userActivityTabParams: new ActivityParams({ isTable: true }),
  softwareLinksList: null,
  exportedFiles: [],
  errors: {
    activityTableError: null,
    activityDiagramError: null,
    softwareError: null,
  },
  loading: {
    getActivityTable: false,
    getactivityDiagram: false,
    getSoftwareLinksList: false,
    getSoftware: false,
    postSoftware: false,
    deleteSoftware: false,
  },
};

const activityReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_ACTIVITY_TABLE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getActivityTable: true },
      };

    case ActionTypes.GET_ACTIVITY_TABLE_SUCCESS:
      return {
        ...state,
        activityTable: action.payload,
        errors: { ...state.errors, activityTableError: null },
        loading: { ...state.loading, getActivityTable: false },
      };

    case ActionTypes.GET_ACTIVITY_TABLE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, activityTableError: action.payload.message },
        loading: { ...state.loading, getActivityTable: false },
      };

    case ActionTypes.GET_ACTIVITY_DIAGRAM_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getactivityDiagram: true },
      };

    case ActionTypes.GET_ACTIVITY_DIAGRAM_SUCCESS:
      return {
        ...state,
        activityDiagram: action.payload.departments || [],
        errors: { ...state.errors, getActivityTable: null },
        loading: { ...state.loading, getactivityDiagram: false },
      };

    case ActionTypes.GET_ACTIVITY_DIAGRAM_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, getActivityTable: action.payload.message },
        loading: { ...state.loading, getactivityDiagram: false },
      };

    case ActionTypes.SET_ACTIVITY_DIAGRAM_PARAMS_REQUEST:
    case ActionTypes.SET_ACTIVITY_TABLE_PARAMS_REQUEST:
      return {
        ...state,
        params: new ActivityParams({ ...state.params, ...action.payload }),
      };

    case ActionTypes.SET_USER_TAB_ACTIVITY_PARAMS_REQUEST:
      return {
        ...state,
        userActivityTabParams: new ActivityParams({ ...state.userActivityTabParams, ...action.payload }),
      };

    case ActionTypes.GET_SOFTWARE_LINKS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSoftwareLinksList: true },
      };

    case ActionTypes.GET_SOFTWARE_LINKS_SUCCESS:
      return {
        ...state,
        softwareLinksList: action.payload,
        errors: { ...state.errors, softwareError: null },
        loading: { ...state.loading, getSoftwareLinksList: false },
      };

    case ActionTypes.GET_SOFTWARE_LINKS_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, softwareError: action.payload.message },
        loading: { ...state.loading, getSoftwareLinksList: false },
      };

    case ActionTypes.GET_SOFTWARE_REQUEST:
      const exportedFiles = [...state.exportedFiles];
      exportedFiles.push(action.payload.id);

      return {
        ...state,
        exportedFiles: exportedFiles,
        loading: { ...state.loading, getSoftware: true },
      };

    case ActionTypes.GET_SOFTWARE_SUCCESS:
      return {
        ...state,
        exportedFiles: [...state.exportedFiles].filter(item => item !== action.payload.fileId),
        errors: { ...state.errors, softwareError: null },
        loading: { ...state.loading, getSoftware: false },
      };

    case ActionTypes.GET_SOFTWARE_FAILURE:
      return {
        ...state,
        exportedFiles: [...state.exportedFiles].filter(item => item !== action.payload.fileId),
        errors: { ...state.errors, softwareError: action.payload.message },
        loading: { ...state.loading, getSoftware: false },
      };

    case ActionTypes.POST_SOFTWARE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, postSoftware: true },
      };

    case ActionTypes.POST_SOFTWARE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, softwareError: null },
        loading: { ...state.loading, postSoftware: false },
      };

    case ActionTypes.POST_SOFTWARE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, softwareError: action.payload.message },
        loading: { ...state.loading, postSoftware: false },
      };

    case ActionTypes.DELETE_SOFTWARE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteSoftware: true },
      };

    case ActionTypes.DELETE_SOFTWARE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, softwareError: null },
        loading: { ...state.loading, deleteSoftware: false },
      };

    case ActionTypes.DELETE_SOFTWARE_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, softwareError: action.payload.message },
        loading: { ...state.loading, deleteSoftware: false },
      };

    case ActionTypes.RESET_ACTIVITY_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_ACTIVITY_STATE:
      return {
        ...initialState,
        params: state.params,
      };

    default:
      return state;
  }
};

export default activityReducer;
