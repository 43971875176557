import { get } from 'lodash-es';
import { getDateParams, getDateWithTimeZoneParams } from '../../utils/params.utils';
import { SortParams } from '../params.enum';

export class EventsParams extends SortParams {
  offices: string[];
  departments: string[];
  targetEmployees: string[];
  users: string[];
  eventTypes: string[];
  page: number;
  size: number;
  dateTimeFrom: string;
  dateTimeTo: string;
  holiday: boolean;
  extraDay: boolean;
  questionnairesEnabled: boolean | null;
  isSearchByAuthor: boolean;
  assessmentsEnabled: boolean | null;
  isPublicEvent: boolean | null;
  isAllowPreviews?: boolean | null;
  publicAllowed?: boolean | null;
  constructor(params?: Partial<EventsParams>) {
    super('startDate', params);
    this.offices = get(params, 'offices', []);
    this.departments = get(params, 'departments', []);
    this.targetEmployees = get(params, 'targetEmployees', []);
    this.users = get(params, 'users', []);
    this.eventTypes = get(params, 'eventTypes', []);
    this.page = get(params, 'page', 0);
    this.size = get(params, 'size', 10);
    this.dateTimeFrom = get(params, 'dateTimeFrom', getDateWithTimeZoneParams({ withTime: false }));
    this.dateTimeTo = get(params, 'dateTimeTo', getDateWithTimeZoneParams({ withTime: false, getFromStart: false }));
    this.holiday = get(params, 'holiday', false);
    this.extraDay = get(params, 'extraDay', false);
    this.questionnairesEnabled = get(params, 'questionnairesEnabled', null);
    this.assessmentsEnabled = get(params, 'assessmentsEnabled', null);
    this.isSearchByAuthor = get(params, 'isSearchByAuthor', false);
    this.isPublicEvent = get(params, 'isPublicEvent', null);
    this.isAllowPreviews = get(params, 'isAllowPreviews', null);
    this.publicAllowed = get(params, 'publicAllowed', false);
  }
}

export class DaysUsedLimitParams extends SortParams {
  dateFrom: string;
  dateTo: string;
  offices: string[];
  departments: string[];
  users: string[];
  eventTypes: string[];
  size: number;

  constructor(params?: Partial<DaysUsedLimitParams>) {
    super('secondName', params);
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false, period: 'year' }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false, period: 'year' }));
    this.offices = get(params, 'offices', []);
    this.departments = get(params, 'departments', []);
    this.users = get(params, 'users', []);
    this.eventTypes = get(params, 'eventTypes', []);
    this.size = get(params, 'size', 1000);
  }
}
