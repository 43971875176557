import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Icon from '../../../components/Icon';
import { FinancePlanUser } from '../../../enums/finance/finance.enum';
import TableUserAvatar from '../../../components/TableUserAvatar';
import { DELETE_FINANCE_PLAN, UPDATE_FINANCE_PLAN } from '../../../constants/policies.constants';
import { getForamtedNumber } from '../../../utils/finance.utils';
import { formatValue } from 'react-currency-input-field';
import { CurrencyType } from '../../../types/finance';

export const useDataForTable = (
  setEmployeeClicked: (row: FinancePlanUser) => void,
  setModalEditEmployeesIsOpen: (value: boolean) => void,
  setModalDeleteEmployeeIsOpen: (value: boolean) => void,
  currentPlanCurrency: CurrencyType,
) => {
  const intl = useIntl();

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.membersLabel),
        sortName: 'user',
        modifier: (row: FinancePlanUser) => row?.user && <TableUserAvatar users={[row?.user]} fileSize={48} />,
        className: 'table__data--bold',
      },
      {
        name: intl.formatMessage(messages.hourPriceLabel),
        sortName: 'rate',
        modifier: (row: FinancePlanUser) =>
          `${formatValue({
            value: row.rate?.value,
          })} ${currentPlanCurrency?.name}`,
        className: 'table__data--right',
      },
      {
        name: intl.formatMessage(messages.plannedHoursColumn),
        sortName: 'workHours',
        modifier: (row: FinancePlanUser) => (
          <span style={{ ...(!(Number(row.workHours) > 0) && { color: 'red' }) }}>
            {getForamtedNumber(row.workHours)}
          </span>
        ),
        className: 'table__data--right',
      },
      {
        name: intl.formatMessage(messages.percentOfRegulatoryColumn),
        sortName: 'hoursPercent',
        modifier: (row: FinancePlanUser) => (
          <span style={{ ...(!(Number(row.hoursPercent) > 0) && { color: 'red' }) }}>
            {`${getForamtedNumber(row.hoursPercent)}%`}
          </span>
        ),
        className: 'table__data--right',
      },
      {
        name: intl.formatMessage(messages.plannedIncomeColumn),
        modifier: (row: FinancePlanUser) =>
          `${
            formatValue({
              value: (+row.rate?.value * +row.workHours).toFixed(4)?.toString(),
            }) || 0
          } ${currentPlanCurrency?.name}`,
        className: 'table__data--right',
      },
    ],
    [currentPlanCurrency],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlanUser) => {
          setEmployeeClicked(row);
          setModalEditEmployeesIsOpen(true);
        },
        verifiablePolicies: [UPDATE_FINANCE_PLAN],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlanUser) => {
          setEmployeeClicked(row);
          setModalDeleteEmployeeIsOpen(true);
        },
        verifiablePolicies: [DELETE_FINANCE_PLAN],
      },
    ],
    [],
  );

  return {
    tableColumns,
    tableActions,
  };
};
