import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import * as filtersActions from '../../actions/filters.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { Client, FinanceProject, Transaction } from '../../enums/finance/finance.enum';
import Table from '../../components/Table';
import { FinanceProjectsParams, TransactionsParams } from '../../enums/params/finance.params';
import { useDataForTable } from './useDataForTable';
import ModalNewTransaction from '../../components/Transactions/Modals/ModalNewTransaction';
import { OfficeInfo } from '../../enums/libraries.enum';
import { ExpenseIncomeTypesGroupEnum, PayerRecipientGroupEnum } from './utils';
import { CurrencyType, ExpenseType, IncomeType, SupplierType } from '../../types/finance';
import { UserInfo } from '../../enums/users.enum';
import ModalEditTransaction from '../../components/Transactions/Modals/ModalEditTransaction';
import ModalDeleteTransaction from '../../components/Transactions/Modals/ModalDeleteTransaction';
import InlineDatePicker from '../../components/InlineDatePicker';
import AccessChecker from '../../components/AccessChecker';
import { UPDATE_TRANSACTION, VIEW_TRANSACTION } from '../../constants/policies.constants';
import TransactionsFilter from '../../components/Transactions/Filter';
import { resetParamsChange, useParamsChange } from '../../utils/hooks.utils';
import Pagination from '../../components/Pagination';
import { NavLink } from 'react-router-dom';
import r from '../../constants/routes.constants';
import PoliciesContext from '../../PoliciesContext';
import InfoBlock from '../../components/InfoBlock';
import { getBaseCurrency } from '../../utils';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import isEqual from 'lodash-es/isEqual';
import { transactionsUnsavedParams } from '../../constants/finance.constants';

function Transactions({
  tableData,
  transactionsTotal,
  userInfo,
  officesFilter,
  expenseTypesFilter,
  incomeTypesFilter,
  clientsFilter,
  suppliersFilter,
  usersFilter,
  financeProjectsFilter,
  currencies,
  isLoading,
  transactionError,
  transactionListError,
  createTransactions,
  editTransaction,
  deleteTransaction,
  setTransactionsParams,
  getOfficesFilter,
  getExpenseTypesFilter,
  getIncomeTypesFilter,
  getClientsFilter,
  getSuppliersFilter,
  getUsersFilter,
  getFinanceProjectsFilter,
  getCurrenciesFilter,
  resetErrors,
  resetState,
  params,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();
  const [modalNewTransactionIsOpen, setModalNewTransactionIsOpen] = useState(false);
  const [modalEditTransactionIsOpen, setModalEditTransactionIsOpen] = useState(false);
  const [modalDeleteTransactionIsOpen, setModalDeleteTransactionIsOpen] = useState(false);
  const [transactionClicked, setTransactionClicked] = useState<Transaction>(new Transaction());
  const dispatch = useDispatch();

  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_TRANSACTION), [policies]);

  useEffect(() => {
    getOfficesFilter();
    getExpenseTypesFilter();
    getIncomeTypesFilter();
    getClientsFilter();
    getSuppliersFilter();
    getUsersFilter();
    getCurrenciesFilter();
    setSavedFiltersParams({ filterType: FilterTypes.TRANSACTIONS_FILTER });

    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.TRANSACTIONS_FILTER);

    if (currentSavedFilter && viewPolicy?.isOfficeSpecific) {
      currentSavedFilter.officeIds = currentSavedFilter.officeIds?.filter(
        (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
      );
    }

    setTransactionsParams(
      currentSavedFilter
        ? new TransactionsParams(currentSavedFilter)
        : {
            officeIds: officesFilter.value?.filter(
              (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy.officeIds?.some(id => id === el),
            ),
          },
    );
  }, [viewPolicy]);

  const expenseTypesOptions = useMemo(
    () =>
      expenseTypesFilter.expenseTypes.map((el: ExpenseType) => ({
        label: el.name,
        value: el,
        type: ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES,
      })),
    [expenseTypesFilter.expenseTypes],
  );

  const incomeTypesOptions = useMemo(
    () =>
      incomeTypesFilter.incomeTypes.map((el: IncomeType) => ({
        label: el.name,
        value: el.id,
        type: ExpenseIncomeTypesGroupEnum.INCOME_TYPES,
      })),
    [incomeTypesFilter.incomeTypes],
  );

  const officesOptions = useMemo(
    () =>
      officesFilter.offices.map((office: OfficeInfo) => {
        return {
          label: office.name,
          value: office.id,
          type: PayerRecipientGroupEnum.OFFICES,
        };
      }),
    [officesFilter.offices],
  );

  const usersOptions = useMemo(
    () =>
      usersFilter.users.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
        type: PayerRecipientGroupEnum.USERS,
      })),
    [usersFilter.users],
  );

  const clientsOptions = useMemo(
    () =>
      clientsFilter.clients.map((client: Client) => ({
        label: client.name,
        value: client.id,
        type: PayerRecipientGroupEnum.CLIENTS,
      })),
    [clientsFilter.clients],
  );

  const suppliersOptions = useMemo(
    () =>
      suppliersFilter.suppliers.map((supplier: SupplierType) => ({
        label: supplier.name,
        value: supplier,
        type: PayerRecipientGroupEnum.SUPPLIERS,
      })),
    [suppliersFilter.suppliers],
  );

  const financeProjectsOptions = useMemo(
    () =>
      financeProjectsFilter.financeProjects.map((financeProject: FinanceProject) => ({
        label: financeProject.name,
        value: financeProject,
      })),
    [financeProjectsFilter.financeProjects],
  );

  const currenciesOptions = useMemo(
    () =>
      currencies.map((currency: CurrencyType) => ({
        label: currency.name,
        value: currency,
      })),
    [currencies],
  );

  const openNewTransactionModal = useCallback(() => {
    setModalNewTransactionIsOpen(true);
  }, []);

  const closeNewTransactionModal = useCallback(() => {
    setModalNewTransactionIsOpen(false);
    resetErrors();
  }, []);

  const closeEditTransactionModal = useCallback(() => {
    setModalEditTransactionIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteTransactionModal = useCallback(() => {
    setModalDeleteTransactionIsOpen(false);
    resetErrors();
  }, []);

  const handleSort = useCallback((sortBy, direction) => setTransactionsParams({ sortBy, direction }), []);

  const onDateChange = (start: string, end: string) => {
    setTransactionsParams({ dateFrom: start.split(' ')[0], dateTo: end.split(' ')[0] });
  };

  const handleMultiParamsChange = useParamsChange(setTransactionsParams, dispatch);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const filters = useMemo(
    () => ({
      expenseTypes: expenseTypesOptions,
      incomeTypes: incomeTypesOptions,
      offices: officesOptions,
      users: usersOptions,
      clients: clientsOptions,
      suppliers: suppliersOptions,
      currencies: currenciesOptions,
    }),
    [
      expenseTypesOptions,
      incomeTypesOptions,
      officesOptions,
      usersOptions,
      clientsOptions,
      suppliersOptions,
      currenciesOptions,
    ],
  );

  const pageable = useMemo(
    () => ({
      ...tableData?.pageable,
      ...tableData?.sort,
      totalElements: tableData?.totalElements,
      numberOfElements: tableData?.numberOfElements,
      totalPages: tableData?.totalPages,
    }),
    [tableData],
  );

  const { tableColumns, tableActions } = useDataForTable(
    baseCurrency,
    pageable,
    setTransactionClicked,
    setModalEditTransactionIsOpen,
    setModalDeleteTransactionIsOpen,
  );

  const handlePageChange = useCallback(({ selected }) => {
    setTransactionsParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setTransactionsParams({ size: data, page: 0 });
  }, []);

  const handleFiltersControlChange = useCallback(
    value => {
      const fields = convertSavedFieldsToParams(value?.fields);

      if (viewPolicy?.isOfficeSpecific) {
        fields.officeIds = fields.officeIds?.filter(
          (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
        );
      }

      setTransactionsParams(
        new TransactionsParams({
          ...fields,
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [viewPolicy, params],
  );

  const handleClear = useCallback(() => {
    setTransactionsParams(new TransactionsParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));
    resetParamsChange([FilterParamsName.OFFICE_IDS], dispatch);
  }, [params]);

  const filteredSavedFiltersData = useMemo(
    () =>
      viewPolicy?.isOfficeSpecific
        ? {
            ...savedFiltersData,
            filtersList: savedFiltersData.filtersList
              .map((filter: SavedFilter) => {
                const fields = convertSavedFieldsToParams(filter.fields);

                if (fields.officeIds) {
                  fields.officeIds = fields.officeIds.filter(
                    (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
                  );

                  if (isEqual(fields, { officeIds: [], sortBy: 'transactionDate', direction: 'ASC' })) {
                    return null;
                  }
                }
                return filter;
              })
              .filter((filter: SavedFilter | null) => filter),
          }
        : savedFiltersData,
    [savedFiltersData, viewPolicy],
  );

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new TransactionsParams(), transactionsUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed transactions__page-panel">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="transactions__total-wrapper">
              <InfoBlock
                label={intl.formatMessage(messages.incomeLabel)}
                currencyValue={transactionsTotal.incomesTotal}
                externalWrapperClass="text-center green"
                baseCurrency={baseCurrency}
              />
              <InfoBlock
                label={intl.formatMessage(messages.expensesLabel)}
                currencyValue={transactionsTotal.expensesTotal}
                externalWrapperClass="text-center red"
                baseCurrency={baseCurrency}
              />
              <InfoBlock
                label={intl.formatMessage(messages.differenceLabel)}
                currencyValue={transactionsTotal.difference}
                externalWrapperClass="text-center blue"
                baseCurrency={baseCurrency}
              />
            </div>
            <InlineDatePicker
              onDateChange={onDateChange}
              defaultPeriodStart={params.dateFrom}
              defaultPeriodEnd={params.dateTo}
            />
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_TRANSACTION]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewTransactionModal}>
                    <Icon iconName="plus" externalClass="button__icon" />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                  <div className="import-button">
                    <NavLink to={r.importTransactions}>
                      <Button externalClass="button--circle">
                        <Icon iconName="upload" externalClass="button__icon" />
                      </Button>
                    </NavLink>
                  </div>
                </AccessChecker>
                <TransactionsFilter
                  handleMultiParamsChange={handleMultiParamsChange}
                  setTransactionsParams={setTransactionsParams}
                  filters={filters}
                  officesOptions={officesOptions}
                  values={params}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={filteredSavedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  policyOfficiesIds={viewPolicy?.officeIds}
                  isOfficeSpecific={!!viewPolicy?.isOfficeSpecific}
                  handleSort={handleSort}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table expense-types-table'}
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getTransactions}
            error={transactionListError}
            tableActions={tableActions}
          />
          <Pagination pageable={pageable} onPageChange={handlePageChange} onPageSizeChange={handleSizeChange} />
        </div>
      </div>
      {modalNewTransactionIsOpen && (
        <ModalNewTransaction
          isOpen
          error={transactionError}
          userInfo={userInfo}
          isLoading={isLoading.createTransaction}
          isFinanceProjectsFilterLoading={financeProjectsFilter.loading}
          incomeTypesOptions={incomeTypesOptions}
          expenseTypesOptions={expenseTypesOptions}
          officesOptions={officesOptions}
          usersOptions={usersOptions}
          clientsOptions={clientsOptions}
          suppliersOptions={suppliersOptions}
          financeProjectsOptions={financeProjectsOptions}
          currenciesOptions={currenciesOptions}
          baseCurrency={baseCurrency}
          createTransaction={createTransactions}
          getFinanceProjectsFilter={getFinanceProjectsFilter}
          onCloseRequest={closeNewTransactionModal}
        />
      )}
      {modalEditTransactionIsOpen && (
        <ModalEditTransaction
          isOpen
          transaction={transactionClicked}
          error={transactionError}
          isLoading={isLoading.editTransaction}
          isFinanceProjectsFilterLoading={financeProjectsFilter.loading}
          incomeTypesOptions={incomeTypesOptions}
          expenseTypesOptions={expenseTypesOptions}
          officesOptions={officesOptions}
          usersOptions={usersOptions}
          clientsOptions={clientsOptions}
          suppliersOptions={suppliersOptions}
          financeProjectsOptions={financeProjectsOptions}
          currenciesOptions={currenciesOptions}
          baseCurrency={baseCurrency}
          editTransaction={editTransaction}
          getFinanceProjectsFilter={getFinanceProjectsFilter}
          onCloseRequest={closeEditTransactionModal}
        />
      )}

      {modalDeleteTransactionIsOpen && (
        <ModalDeleteTransaction
          isOpen
          onCloseRequest={closeDeleteTransactionModal}
          onDeleteRequest={deleteTransaction}
          isLoading={isLoading.deleteTransaction}
          error={transactionError}
          transaction={transactionClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance, filters, auth }: RootState) => ({
  transactionError: finance.errors.transactionError,
  transactionListError: finance.errors.transactionListError,
  isLoading: finance.loading,
  tableData: finance.transactionsListData,
  transactionsTotal: finance.transactionsTotal,
  officesFilter: filters.officesFilter,
  expenseTypesFilter: filters.expenseTypesFilter,
  incomeTypesFilter: filters.incomeTypesFilter,
  clientsFilter: filters.clientsFilter,
  suppliersFilter: filters.suppliersFilter,
  usersFilter: filters.usersFilter,
  financeProjectsFilter: filters.financeProjectsFilter,
  currencies: filters.currenciesFilter.currencies,
  params: finance.transactionsParams,
  userInfo: auth.currentUserInfo,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createTransactions: (data: { data: Transaction; callback: () => void }) =>
    dispatch(financeActions.createTransactions(data)),
  editTransaction: (data: { data: Transaction; callback: () => void }) =>
    dispatch(financeActions.editTransaction(data)),
  deleteTransaction: (data: { id: string; callback: () => void }) => dispatch(financeActions.deleteTransaction(data)),
  setTransactionsParams: (data: Partial<TransactionsParams>) => dispatch(financeActions.setTransactionsParams(data)),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getExpenseTypesFilter: () => dispatch(filtersActions.getExpenseTypesFilter()),
  getIncomeTypesFilter: () => dispatch(filtersActions.getIncomeTypesFilter()),
  getClientsFilter: () => dispatch(filtersActions.getClientsFilter()),
  getSuppliersFilter: () => dispatch(filtersActions.getSuppliersFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getFinanceProjectsFilter: (data: Partial<FinanceProjectsParams>) =>
    dispatch(filtersActions.getFinanceProjectsFilter(data)),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Transactions);
