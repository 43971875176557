import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import {
  MULTIPLE_CHOICE_CUSTOM_ANSWERS_VALIDATION_SCHEMA,
  SINGLE_CHOICE_CUSTOM_ANSWERS_VALIDATION_SCHEMA,
} from '../../../enums/questionnaires.enum';
import Checkbox from '../../Checkbox';
import { ReactSortable } from 'react-sortablejs';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Icon from '../../Icon';
import ErrorMessage from '../../ErrorMessage';

type ModalNewPollCategoryProps = {
  onCloseRequest: () => void;
  setCustomAnsers: (data: any) => void;
  currentOptionsAnswers: { answers: any[] };
  isOpen: boolean;
  isMultiple: boolean;
};

function ModalCustomAnswers({
  onCloseRequest,
  setCustomAnsers,
  currentOptionsAnswers,
  isOpen,
  isMultiple,
}: ModalNewPollCategoryProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues: {
      answers:
        currentOptionsAnswers.answers.length > 0
          ? currentOptionsAnswers.answers
          : [{ value: '', isRightAnswer: false }],
    },
    validateOnChange: false,
    validationSchema: () =>
      isMultiple ? MULTIPLE_CHOICE_CUSTOM_ANSWERS_VALIDATION_SCHEMA : SINGLE_CHOICE_CUSTOM_ANSWERS_VALIDATION_SCHEMA,
    onSubmit: data => {
      setCustomAnsers({
        data: data.answers.map((item: { score: number | '' }) => ({
          ...item,
          score: item.score === '' ? null : item.score,
        })),
        callback: resetAndExit,
      });
    },
  });

  useEffect(() => {
    setFieldValue('answers', [
      ...currentOptionsAnswers.answers.map((item: { score: number | null }) => ({
        ...item,
        score: item.score === null ? '' : item.score,
      })),
    ]);
  }, [currentOptionsAnswers]);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const addNewAnwser = useCallback(() => {
    setFieldValue('answers', [
      ...values.answers,
      {
        value: '',
        isRightAnswer: false,
        score: '',
      },
    ]);
  }, [values]);

  const removeAnwser = useCallback(
    index => () => {
      values.answers.splice(index, 1);
      setFieldValue('answers', values.answers);
    },
    [values],
  );

  const setRightAnwser = useCallback(
    (value, index) => {
      setFieldValue(`answers[${index}].isRightAnswer`, value);
    },
    [values],
  );

  const answersErrors: any = useMemo(() => errors.answers, [errors.answers]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.customAnswersTitle)}
      size={'big'}
      shouldCloseOnOverlayClick={false}
    >
      <form className="modal__form form custom_anwser_form poll_template_form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <ReactSortable
            list={values.answers}
            setList={newState => setFieldValue(`answers`, newState)}
            animation={200}
            handle={'.form__btn-move-inputs'}
            style={{ marginBottom: '14px', borderBottom: '1px solid #f0f0f0' }}
          >
            {values.answers.map((item, index) => (
              <div className="form__group-wrapper" key={index}>
                <button className="form__btn-move-inputs" type={'button'}>
                  <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
                </button>
                <p className="anwser_number">{index + 1 + '.'} </p>
                <div className="form__inputs-subwrapper">
                  <Input
                    id={`answers[${index}].value`}
                    name={`answers[${index}].value`}
                    label={intl.formatMessage(messages.labelAnswer)}
                    defaultValue={values.answers[index].value}
                    onChange={handleChange}
                    hasError={hasError(`answers[${index}].value`) || hasError(`answers[${index}].isRightAnswer`)}
                    errorMessage={answersErrors && (answersErrors[index]?.value || answersErrors[index]?.isRightAnswer)}
                    tag="textarea"
                    wrapperClass="form__input-block--five-sixth"
                  />
                  <Input
                    id={`answers[${index}].score`}
                    name={`answers[${index}].score`}
                    type="number"
                    label={intl.formatMessage(messages.scoreColumn)}
                    defaultValue={values.answers[index].score}
                    onChange={handleChange}
                    hasError={hasError(`answers[${index}].score`) || hasError(`answers[${index}].score`)}
                    errorMessage={answersErrors && (answersErrors[index]?.score || answersErrors[index]?.score)}
                    wrapperClass="form__input-block--sixth"
                  />
                  <Checkbox
                    label={intl.formatMessage(messages.labelCorrect)}
                    name={`answers[${index}].isRightAnswer`}
                    id={`answers[${index}].isRightAnswer`}
                    externalClass="checkbox_template_form right_anwser form__input-block--sixth"
                    checkedValue={values.answers[index].isRightAnswer}
                    onChange={event => setRightAnwser(event.target.checked, index)}
                  />
                </div>
                <button className="form__btn-clean-inputs" type={'button'} onClick={removeAnwser(index)}>
                  <Icon iconName={'cross'} />
                </button>
              </div>
            ))}
          </ReactSortable>
          <button className="form__btn-add-group" onClick={addNewAnwser} type={'button'}>
            <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
            <FormattedMessage {...messages.labelAnswer} />
          </button>
        </div>
        <ErrorMessage>{typeof answersErrors === 'string' && answersErrors}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} type={'button'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'}>
            <FormattedMessage {...messages.doneButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalCustomAnswers;
