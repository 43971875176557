import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  newCandidateSpecializationTitle: {
    id: 'candidates.candidate.specializations.modal.new.title.new.specialization',
    defaultMessage: 'New Specialization',
  },
  editCandidateSpecializationTitle: {
    id: 'candidates.candidate.specializations.modal.edit.title.edit.specialization',
    defaultMessage: 'Edit Specialization',
  },
});
