import classNames from 'classnames';
import React from 'react';

type GradeBlockProps = {
  label: string | React.ReactNode;
  externalClass?: string;
  gradeValue?: number | null;
  exprerinces?: { years: number; months: number; days: number } | null;
};
function GradeBlock({ label, externalClass, gradeValue, exprerinces }: GradeBlockProps) {
  const defaultClass = classNames('grade-block', {
    [externalClass as string]: Boolean(externalClass),
  });
  return (
    <div className={defaultClass}>
      <div className="grade-block__total">
        {exprerinces && !gradeValue && `${exprerinces.years}y ${exprerinces.months}m`}
        {gradeValue && !exprerinces && gradeValue}
        {!gradeValue && !exprerinces && 'Not set'}
      </div>
      <div className="grade-block__name">{label}</div>
    </div>
  );
}

export default GradeBlock;
