import { QueryParamsType } from './../utils/params.utils';
import { MenuLinksParams } from './../enums/params/branding.params';
import request from '../utils/request.utils';

export function getPortalBranding() {
  const options: RequestConfig = {
    method: 'get',
    url: '/branding',
  };
  return request(options);
}

export function editPortalBranding(data: any) {
  const options: RequestConfig = {
    method: 'patch',
    url: '/branding',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getMenuLinksList(params?: QueryParamsType<MenuLinksParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/resourceLinks',
    params: {
      size: 1000,
      ...params,
    },
  };
  return request(options);
}

export function createNewMenuLink(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/resourceLinks',
    data,
  };
  return request(options);
}

export function editMenuLinkByAdmin(uuid: string, data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/resourceLinks/${uuid}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editLinksOrder(data: any) {
  const options: RequestConfig = {
    method: 'patch',
    url: '/resourceLinks',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteMenuLink(uuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/resourceLinks/${uuid}`,
  };
  return request(options);
}
