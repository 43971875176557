import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, { components, OptionTypeBase } from 'react-select';
import classNames from 'classnames';
import Icon from '../Icon';
import { useClickOutsideHandler } from '../../utils/hooks.utils';
import { DirectionType } from '../Table';
import { SortParams } from '../../enums/params.enum';

type SortSelectProps = {
  sortOptions: OptionTypeBase[];
  handleChange?: (val: any, paramsName?: string) => void;
  formatOptionLabel?: (el: any) => React.ReactNode;

  onSort: (field: string, direction: DirectionType) => void;
  params: SortParams;
};

function SortSelect({
  sortOptions,
  formatOptionLabel = ({ label }) => <span>{label}</span>,

  params,
  onSort,
}: SortSelectProps) {
  const wrapperRef = useRef<any>(null);
  const ref = useRef<any>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [customOptions, setCustomOptions] = useState(sortOptions);

  const currentValue = useMemo(() => sortOptions.find(item => item.value === params.sortBy), [params, sortOptions]);

  useEffect(() => {
    if (isOpen) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      // if any scroll is attempted, set this to the previous value
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    }
    return () => {
      window.onscroll = () => null;
    };
  }, [isOpen]);

  useEffect(() => {
    setCustomOptions(sortOptions);
  }, [sortOptions]);

  const handleSelect = useCallback(
    (data: any) => {
      setIsOpen(false);
      onSort(data.value, params?.direction);
    },
    [sortOptions, params?.direction],
  );

  const handleDirectionChange = useCallback(() => {
    //@ts-ignore
    onSort(params.sortBy, params?.direction === 'ASC' ? 'DESC' : 'ASC');
  }, [params]);

  useClickOutsideHandler(
    () => {
      setIsOpen(false);
    },
    { current: ref.current?.select?.menuListRef },
    wrapperRef,
  );

  const selectComponents = useMemo(
    () => ({
      IndicatorSeparator: null,
      DropdownIndicator: null,
      Control: ({ children, ...props }: any) => {
        return (
          <div onClick={() => setIsOpen(prev => !prev)}>
            <components.Control {...props}>{children}</components.Control>
          </div>
        );
      },
      Option: ({ children, ...props }: any) => {
        const { value } = props;

        return (
          <components.Option {...props} innerProps={props.innerProps}>
            <div>
              {value === params.sortBy && <Icon iconName="check" />}
              {children}
            </div>
          </components.Option>
        );
      },
      ValueContainer: ({ children, ...props }: any) => {
        return (
          <components.ValueContainer className={'selectValueContainerClass'} {...props}>
            {children}
            <button
              onClick={e => {
                e.stopPropagation();
                handleDirectionChange();
              }}
              className="direction-button"
            >
              <Icon
                iconName="table-sort"
                externalClass={classNames({ 'ascending-order': params.direction === 'ASC' })}
              />
            </button>
          </components.ValueContainer>
        );
      },
    }),
    [params],
  );

  return (
    <div ref={wrapperRef}>
      <ReactSelect
        ref={ref}
        isSearchable={false}
        className={'filter sort-select'}
        classNamePrefix="filter"
        formatOptionLabel={formatOptionLabel}
        isClearable={false}
        value={currentValue}
        menuIsOpen={isOpen}
        hideSelectedOptions={false}
        placeholder={null}
        options={customOptions}
        onChange={handleSelect}
        components={selectComponents}
      />
    </div>
  );
}

export default React.memo(SortSelect);
