import { defineMessages } from 'react-intl';
import {
  officesLabel,
  departmentsLabel,
  employeesLabel,
  activeLabel,
  inactiveLabel,
  statusLabel,
  eventTypesLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  officesLabel,
  departmentsLabel,
  employeesLabel,
  activeLabel,
  inactiveLabel,
  statusLabel,
  eventTypesLabel,
});
