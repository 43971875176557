import { defineMessages } from 'react-intl';
import {
  saveButton,
  cancelButton,
  skillLabel,
  deleteModalWithItemNameTitle,
  deleteButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteModalWithItemNameTitle,
  skillLabel,
  cancelButton,
  saveButton,
  deleteButton,
  newTechnicalSkillTitle: {
    id: 'resumes.technical.skills.modal.new.technical.skill.title.new.technical.skill',
    defaultMessage: 'New Technical Skill',
  },
  editTechnicalSkillTitle: {
    id: 'resumes.technical.skills.modal.edit.technical.skill.title.edit.technical.skill',
    defaultMessage: 'Edit Technical Skill',
  },
  skillGroupNameLabel: {
    id: 'resumes.technical.skills.modal.edit.technical.skill.label.skill.group.name',
    defaultMessage: 'Skill Group Name',
  },
});
