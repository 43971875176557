import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/branding.constants';

export const getPortalBranding = (): AnyAction => ({
  type: ActionTypes.GET_PORTAL_BRANDING_REQUEST,
});

export const editPortalBranding = (data: any): AnyAction => ({
  type: ActionTypes.POST_EDIT_PORTAL_BRANDING_REQUEST,
  payload: data,
});

export const getMenuLinksList = (): AnyAction => ({
  type: ActionTypes.GET_MENU_LINKS_LIST_REQUEST,
});

export const getDashboardLinks = (): AnyAction => ({
  type: ActionTypes.GET_DASHBOARD_LINKS_REQUEST,
});

export const createNewMenuLink = (data: any): AnyAction => ({
  type: ActionTypes.POST_MENU_LINK_REQUEST,
  payload: data,
});

export const editMenuLinkByAdmin = (data: any): AnyAction => ({
  type: ActionTypes.PUT_MENU_LINK_BY_ADMIN_REQUEST,
  payload: data,
});

export const editLinksOrder = (data: any): AnyAction => ({
  type: ActionTypes.PUT_LINKS_ORDER_REQUEST,
  payload: data,
});

export const deleteMenuLink = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_MENU_LINK_REQUEST,
  payload: data,
});

export const resetBrandingErrors = (): AnyAction => ({
  type: ActionTypes.RESET_BRANDING_ERRORS,
});

export const resetBrandingState = (): AnyAction => ({
  type: ActionTypes.RESET_BRANDING_STATE,
});
