import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  currencyLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  currencyLabel,
  newSupplierTitle: {
    id: 'finance.suppliers.modal.new.supplier.title.new.supplier',
    defaultMessage: 'New Supplier',
  },
  editSupplierTitle: {
    id: 'finance.suppliers.modal.edit.supplier.title.edit.supplier',
    defaultMessage: 'Edit Supplier',
  },
});
