export const dateFormatStart = 'YYYY-MM-DD 00:00:00';
export const dateFormatEnd = 'YYYY-MM-DD 23:59:59';
export const yearPeriod = 'year';
export const monthPeriod = 'month';
export const weekPeriod = 'week';
export const dayPeriod = 'day';

export enum InlineDatePickerPeriods {
  YEAR_PERIOD = 'year',
  MONTH_PERIOD = 'month',
  WEEK_PERIOD = 'week',
  DAY_PERIOD = 'day',
}
