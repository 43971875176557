import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  nameColumn,
  questionLabel,
  deleteButton,
  deleteModalWithItemNameTitle,
  authorColumn,
  editorColumn,
  createdColumn,
  updatedColumn,
  commentLabel,
  searchLabel,
  scoreColumn,
  doneButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  nameColumn,
  questionLabel,
  deleteModalWithItemNameTitle,
  deleteButton,
  authorColumn,
  editorColumn,
  createdColumn,
  updatedColumn,
  commentLabel,
  searchLabel,
  scoreColumn,
  doneButton,
  categorySelect: {
    id: 'questionnaires.poll.templates.form.new.poll.templates.label.category',
    defaultMessage: 'Category',
  },
  groupNameSelect: {
    id: 'questionnaires.poll.templates.form.new.poll.templates.label.group.name',
    defaultMessage: 'Group Name',
  },
  labelInput: {
    id: 'questionnaires.poll.templates.form.new.poll.templates.label.note',
    defaultMessage: 'Note',
  },
  editAnswersButton: {
    id: 'questionnaires.poll.templates.form.new.poll.templates.btn.edit.answers',
    defaultMessage: 'Edit Answers',
  },
  databaseSourceSelect: {
    id: 'questionnaires.poll.templates.form.new.poll.templates.label.database.source',
    defaultMessage: 'Database Source',
  },
  mandatoryCheckbox: {
    id: 'questionnaires.poll.templates.form.new.poll.templates.label.mandatory',
    defaultMessage: 'Mandatory',
  },
  isHiddenCheckbox: {
    id: 'questionnaires.poll.templates.form.new.poll.templates.label.isHidden',
    defaultMessage: 'Hide',
  },
  groupButton: {
    id: 'questionnaires.poll.templates.form.new.poll.templates.btn.group',
    defaultMessage: 'Group',
  },
  pollsTitle: {
    id: 'questionnaires.poll.templates.modal.new.poll.templates.label.polls',
    defaultMessage: 'Polls',
  },
  answerOptionsTitle: {
    id: 'questionnaires.poll.templates.modal.new.poll.templates.label.answer.options',
    defaultMessage: 'Answer options',
  },
  comments: {
    id: 'questionnaires.poll.templates.modal.new.poll.templates.label.comments',
    defaultMessage: '+ Comments',
  },
  customAnswersTitle: {
    id: 'questionnaires.poll.templates.modal.new.poll.templates.title.custom.answers',
    defaultMessage: 'Custom Answers',
  },
  labelAnswer: {
    id: 'questionnaires.poll.templates.modal.new.poll.templates.label.answer',
    defaultMessage: 'Answer',
  },
  labelCorrect: {
    id: 'questionnaires.poll.templates.modal.new.poll.templates.label.correct',
    defaultMessage: 'Correct',
  },
  categorisLabel: {
    id: 'questionnaires.poll.templates.filter.label.categories',
    defaultMessage: 'Categories',
  },
});
