import React from 'react';
import Modal from '../../../Modal';
import Button from '../../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../messages';
import ErrorMessage from '../../../ErrorMessage';

type ModalDeletePhotoProps = {
  onRequestClose: () => void;
  loading: boolean | undefined;
  errors: string | undefined;
  deleteUserPhoto: (data: { callback: () => void }) => void;
  changeDocumentOverflowStyle?: boolean;
};

const ModalDeletePhoto = ({
  onRequestClose,
  loading,
  deleteUserPhoto,
  errors,
  changeDocumentOverflowStyle,
}: ModalDeletePhotoProps) => {
  const intl = useIntl();

  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      title={intl.formatMessage(messages.deleteModalTitle)}
      size={'small'}
      changeDocumentOverflowStyle={changeDocumentOverflowStyle}
    >
      <ErrorMessage>{errors}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onRequestClose}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            deleteUserPhoto({ callback: onRequestClose });
          }}
          loading={loading}
          disabled={loading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
};

export default ModalDeletePhoto;
