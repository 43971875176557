import { defineMessages } from 'react-intl';
import { newButton, officeLabel, editButton, deleteButton, nameColumn } from './../../i18n/defaultMessage';

export default defineMessages({
  newButton,
  officeLabel,
  editButton,
  nameColumn,
  deleteButton,
  pageTitle: {
    id: 'notifications.follow.ups.title.follow.ups',
    defaultMessage: 'Follow-Ups',
  },
  recipientColumn: {
    id: 'notifications.follow.ups.column.recipient',
    defaultMessage: 'Recipient',
  },
  followUpColumn: {
    id: 'notifications.follow.ups.column.follow.up',
    defaultMessage: 'Follow-Up',
  },
  eventTypeColumn: {
    id: 'notifications.follow.ups.column.event.type',
    defaultMessage: 'Event type',
  },
});
