import { get } from 'lodash-es';
import { SortParams } from '../params.enum';

export class MailerSettingsParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('displayName', params);
    this.size = get(params, 'size', 1000);
  }
}

export class EmployeeGroupsParams extends SortParams {
  page: number;
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
  }
}

export class FollowUpsParams extends SortParams {
  page: number;
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
  }
}

export class EmailTemplatesParams extends SortParams {
  page: number;
  size: number;
  types: string[];
  eventTypeIds: string[];

  constructor(params?: unknown) {
    super('notificationActionType', params);
    this.size = get(params, 'size', 1000);
    this.page = get(params, 'page', 0);
    this.types = get(params, 'types', []);
    this.eventTypeIds = get(params, 'eventTypeIds', []);
  }
}
