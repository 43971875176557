import Papa from 'papaparse';
import { FilterOptionType } from '../../components/Filter';
import { ExpenseType } from '../../types/finance';

export const encodingPapaparse = 'UTF-8';

export enum ExpenseIncomeTypesGroupEnum {
  EXPENSE_TYPES = 'EXPENSES',
  INCOME_TYPES = 'INCOMES',
}

export enum PayerRecipientGroupEnum {
  CLIENTS = 'CLIENTS',
  SUPPLIERS = 'SUPPLIERS',
  OFFICES = 'OFFICES',
  USERS = 'USERS',
}

export type PayerRecipientOptionsType = {
  label: string;
  value: string | Record<string, any>;
  type: PayerRecipientGroupEnum;
};

export type ExpenseIncomeTypesOptionsType = {
  label: string;
  value: string | ExpenseType;
  type: ExpenseIncomeTypesGroupEnum;
};

export const expenseIncomeTypesGroup = [
  {
    name: 'Expense Types',
    type: ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES,
  },
  {
    name: 'Income Types',
    type: ExpenseIncomeTypesGroupEnum.INCOME_TYPES,
  },
];

export const payerRecipientGroup = [
  {
    name: 'Clients',
    type: PayerRecipientGroupEnum.CLIENTS,
  },
  {
    name: 'Suppliers',
    type: PayerRecipientGroupEnum.SUPPLIERS,
  },
  {
    name: 'Offices',
    type: PayerRecipientGroupEnum.OFFICES,
  },
  {
    name: 'Members',
    type: PayerRecipientGroupEnum.USERS,
  },
];

export const getPayerRecipientOptions = (
  type: PayerRecipientGroupEnum,
  options: {
    clientsOptions: FilterOptionType[];
    suppliersOptions: FilterOptionType[];
    officesOptions: FilterOptionType[];
    usersOptions?: FilterOptionType[];
  },
) => {
  switch (type) {
    case PayerRecipientGroupEnum.CLIENTS:
      return options.clientsOptions;
    case PayerRecipientGroupEnum.SUPPLIERS:
      return options.suppliersOptions;
    case PayerRecipientGroupEnum.OFFICES:
      return options.officesOptions;
    case PayerRecipientGroupEnum.USERS:
      return options.usersOptions;
  }
};

export const delimitersToGuess = [',', '\t', '|', ';', '.', Papa.RECORD_SEP, Papa.UNIT_SEP];

export enum MatchOptionsValues {
  DO_NOT_IMPORT = 'DO_NOT_IMPORT',
  DATE = 'DATE',
  AMOUNT = 'AMOUNT',
  COMMENT = 'COMMENT',
  OPERATION_TYPE = 'OPERATION_TYPE',
}

export const matchOptions = [
  {
    label: 'Do not import',
    value: MatchOptionsValues.DO_NOT_IMPORT,
  },
  {
    label: 'Date',
    value: MatchOptionsValues.DATE,
  },
  {
    label: 'Original amount',
    value: MatchOptionsValues.AMOUNT,
  },
  {
    label: 'Comment',
    value: MatchOptionsValues.COMMENT,
  },
  {
    label: 'Operation Type',
    value: MatchOptionsValues.OPERATION_TYPE,
  },
];

export enum DecimalSeparatorEnum {
  DOT = 'DOT',
  COMMA = 'COMMA',
}

export const numberFormatOptions = [
  {
    label: 'Format 1.23',
    value: DecimalSeparatorEnum.DOT,
  },
  {
    label: 'Format 1,23',
    value: DecimalSeparatorEnum.COMMA,
  },
];

export const operationTypesOptions = [
  {
    label: 'Expenses',
    value: ExpenseIncomeTypesGroupEnum.EXPENSE_TYPES,
  },
  {
    label: 'Incomes',
    value: ExpenseIncomeTypesGroupEnum.INCOME_TYPES,
  },
];

export const getButtonContinueIsDisabled = (
  matchForm: {
    field: string;
    value: MatchOptionsValues;
  }[],
) => {
  return matchForm.some(el => el.value !== MatchOptionsValues.DO_NOT_IMPORT);
};

export const getMatchField = (
  matchForm: {
    field: string;
    value: MatchOptionsValues;
  }[],
) => {
  const transactionMatchValues = {
    transactionsDate: '',
    originalAmount: '',
    comment: '',
    operationType: '',
  };

  matchForm.forEach(({ value, field }) => {
    switch (value) {
      case MatchOptionsValues.DATE: {
        transactionMatchValues.transactionsDate = field;
        break;
      }
      case MatchOptionsValues.AMOUNT: {
        transactionMatchValues.originalAmount = field;
        break;
      }
      case MatchOptionsValues.COMMENT: {
        transactionMatchValues.comment = field;
        break;
      }
      case MatchOptionsValues.OPERATION_TYPE: {
        transactionMatchValues.operationType = field;
        break;
      }
    }
  });
  return transactionMatchValues;
};

export enum ImportTableColumnsEnum {
  DATE = 'DATE',
  AMOUNT = 'DATE',
  COMMENT = 'DATE',
  OPERATION_TYPE = 'DATE',
  EXPENSE_INCOMEE = 'S',
  PAYER_RECIPIENT = 'DATE',
  PROJECT = 'DATE',
  RATE = 'DATE',
}

export const setTransactionsFieldValue = (
  setField: (field: string, value: any, shouldValidate?: boolean) => void,
  field: string,
  index: number,
  value: any,
  shouldValidate?: boolean,
) => {
  setField(`transactions[${index}].${field}`, value, shouldValidate);
};

export enum ImportErrorTypeEnum {
  PARSER_ERROR = 'PARSER_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}
