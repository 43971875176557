import { totalRow, membersLabel, exportToXLSLabel, eventTypeLabel } from '../../i18n/defaultMessage';
import { defineMessages } from 'react-intl';

export default defineMessages({
  membersLabel,
  totalRow,
  exportToXLSLabel,
  eventTypeLabel,
  startColumn: {
    id: 'reports.absence.periods.column.start',
    defaultMessage: 'Start',
  },
  endColumn: {
    id: 'reports.absence.periods.column.end',
    defaultMessage: 'End',
  },
  allDaysColumn: {
    id: 'reports.absence.periods.column.all.days',
    defaultMessage: 'All days',
  },
  calendarColumn: {
    id: 'reports.absence.periods.column.calendar',
    defaultMessage: 'Calendar',
  },
  workColumn: {
    id: 'reports.absence.periods.column.work',
    defaultMessage: 'Work',
  },
  pageTitle: {
    id: 'reports.absence.periods.page.title.absence.periods',
    defaultMessage: 'Absence Periods',
  },
});
