import { defineMessages } from 'react-intl';
import {
  nameColumn,
  newButton,
  editButton,
  statusLabel,
  cloneButton,
  deleteButton,
  employeesLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  newButton,
  editButton,
  statusLabel,
  cloneButton,
  deleteButton,
  employeesLabel,
  pageTitle: {
    id: 'questionnaires.polls.title',
    defaultMessage: 'My Polls',
  },
  pollResponders: { id: 'questionnaires.polls.poll.responders.column', defaultMessage: 'Responders' },
  pollOrganizers: { id: 'questionnaires.polls.poll.organizers.column', defaultMessage: 'Organizers' },
  pollOffices: { id: 'questionnaires.polls.poll.Offices.column', defaultMessage: 'Offices' },
  pollReplied: { id: 'questionnaires.polls.poll.replied.column', defaultMessage: 'Replied' },
  pollRelatiedEvent: { id: 'questionnaires.polls.poll.related.event.column', defaultMessage: 'Related Event' },
  pollDeadlines: { id: 'questionnaires.polls.poll.deadlins.column', defaultMessage: 'Deadline' },
  categoriesLabels: { id: 'questionnaires.polls.categories.labels', defaultMessage: 'Categories' },
  locationLabels: { id: 'questionnaires.polls.location.labels', defaultMessage: 'Location' },
  publishedStatusLabel: { id: 'questionnaires.polls.status.label.published', defaultMessage: 'Published' },
  notPublishedStatusLabel: { id: 'questionnaires.polls.status.label.not.published', defaultMessage: 'Not Published' },
  closedStatusLabel: { id: 'questionnaires.polls.status.label.closed', defaultMessage: 'Closed' },
});
