import { defineMessages } from 'react-intl';
import {
  nameColumn,
  cancelButton,
  deleteButton,
  saveButton,
  skillLabel,
  deleteModalWithItemNameTitle,
  typeLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  nameColumn,
  saveButton,
  skillLabel,
  deleteModalWithItemNameTitle,
  typeLabel,
  levelLabel: {
    id: 'competencies.form.new.competence.label.level',
    defaultMessage: 'Level',
  },
  maxScorelabel: {
    id: 'competencies.form.new.competence.label.max.score',
    defaultMessage: 'Max Score',
  },
});
