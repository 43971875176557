import React, { useCallback, useState } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Icon from '../../Icon';
import Select from '../../Select';
import { typeFileOptions, exportTypeOptions } from '../../../pages/Polls/utils';

type ModalExportPollAnswersProps = {
  onCloseRequest: () => void;
  isOpen: boolean;
  onExport: (data: any) => void;
  subTitle: string;
  pollId: string;
  userIds: Array<string>;
};

const ModalExportPollAnswers = ({
  onCloseRequest,
  isOpen,
  onExport,
  subTitle,
  pollId,
  userIds,
}: ModalExportPollAnswersProps) => {
  const intl = useIntl();
  const [exportData, setExportData] = useState({ extension: typeFileOptions[0], exportType: exportTypeOptions[0] });

  const handleChangeFileType = useCallback(
    (e: any) => {
      const chosenFileType = typeFileOptions.find((el: any) => el.value === e.value);
      setExportData((prev: any) => ({ ...prev, extension: chosenFileType }));
    },
    [exportData],
  );

  const handleChangeExportType = useCallback(
    (e: any) => {
      const chosenExportType = exportTypeOptions.find((el: any) => el.value === e.value);
      setExportData((prev: any) => ({ ...prev, exportType: chosenExportType }));
    },
    [exportData],
  );

  const handleExport = useCallback(() => {
    onExport({ ...exportData, pollId, userIds });
    onCloseRequest();
  }, [exportData, pollId, userIds]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.exportTitle)}
      size={'small'}
      shouldCloseOnOverlayClick={false}
      classNameModal="center export-poll-modal"
    >
      <div className="form__inputs-wrapper">
        <div className="page__info-block">
          <Icon iconName={'file'} />
          <span>{subTitle?.length > 300 ? `${subTitle?.substr(1, 299)}...` : subTitle}</span>
        </div>
        <Select
          label={intl.formatMessage(messages.fileTypeLabel)}
          name={'fileType'}
          options={typeFileOptions}
          handleChange={handleChangeFileType}
          value={exportData.extension}
        />
        <Select
          label={intl.formatMessage(messages.exportTypeLabel)}
          name={'exportType'}
          options={exportTypeOptions}
          handleChange={handleChangeExportType}
          value={exportData.exportType}
        />
      </div>
      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={onCloseRequest}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button color={'red'} externalClass={'button--modal'} onClick={handleExport}>
          <FormattedMessage {...messages.exportButton} />
        </Button>
      </div>
    </Modal>
  );
};

export default ModalExportPollAnswers;
