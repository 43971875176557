import React from 'react';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import moment from 'moment';
import classNames from 'classnames';
import Label from '../../Label';
import Icon from '../../Icon';
import { isEmpty } from 'lodash-es';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { DATE_FORMAT } from '../../../constants/date.constants';

type ModalPositionsHistoryProps = {
  isOpen: boolean;
  onClose: () => void;
  requestError?: string | RejectValueErrors[] | null;
  userHistory: {
    companyPosition: {
      displayName: string;
      gradeId: string;
      gradeName: string;
      id: string;
      priority: number;
    };
    dateFrom: string;
    companyPositionId: string;
    gradeId: string;
    id: '';
  }[];
  loading?: boolean;
};

function ModalPositionsHistory({ isOpen, onClose, requestError, userHistory, loading }: ModalPositionsHistoryProps) {
  const intl = useIntl();

  const captionClass = classNames('table__no-data', {
    'error': !!requestError,
    'loading': loading,
    'no-data': isEmpty(userHistory),
    'hidden': !isEmpty(userHistory),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={intl.formatMessage(messages.historyPositionsTitle)}
      classNameModal="modal--popover modal--post-history center"
      shouldCloseOnOverlayClick
    >
      <div className="modal__post-history-list">
        <table>
          <tbody>
            {userHistory &&
              userHistory
                .sort(
                  (a: { dateFrom: string }, b: { dateFrom: string }) =>
                    new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime(),
                )
                .map((item, index) =>
                  (moment(item.dateFrom).isBefore(moment()) &&
                    moment(userHistory[index - 1]?.dateFrom).isAfter(moment())) ||
                  (moment(item.dateFrom).isBefore(moment()) && index === 0) ? (
                    <tr className="modal__post-history-item modal__post-history-item--current">
                      <td className="modal__post-history-item-title">{item.companyPosition.displayName}</td>
                      <td className="modal__post-history-item-label">
                        <Label type="current">
                          <FormattedMessage {...messages.currentLabel} />
                        </Label>
                      </td>
                      <td className="modal__post-history-item-date">{moment(item.dateFrom).format(DATE_FORMAT.ll)}</td>
                    </tr>
                  ) : moment(item.dateFrom).isSameOrBefore(moment(), 'day') ? (
                    <tr className="modal__post-history-item modal__post-history-item--passed">
                      <td className="modal__post-history-item-title">{item.companyPosition.displayName}</td>
                      <td className="modal__post-history-item-label">
                        <Icon iconName="check" />
                      </td>
                      <td className="modal__post-history-item-date">{moment(item.dateFrom).format(DATE_FORMAT.ll)}</td>
                    </tr>
                  ) : (
                    <tr className="modal__post-history-item">
                      <td className="modal__post-history-item-title">{item.companyPosition.displayName}</td>
                      <td className="modal__post-history-item-label">
                        <Label type="planned">
                          <FormattedMessage {...messages.plannedLabel} />
                        </Label>
                      </td>
                      <td className="modal__post-history-item-date">{moment(item.dateFrom).format(DATE_FORMAT.ll)}</td>
                    </tr>
                  ),
                )}
          </tbody>
          <caption className={captionClass} />
        </table>
      </div>
      <ErrorMessage>{requestError}</ErrorMessage>
    </Modal>
  );
}

export default ModalPositionsHistory;
