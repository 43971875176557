import { defineMessages } from 'react-intl';
import {
  endDateLabel,
  addButton,
  deleteButton,
  startDateLabel,
  saveButton,
  officeLabel,
  employeesLabel,
  addressLabel,
  cancelButton,
  editButton,
  targetMemberLabel,
  departmentsMembersLabel,
  officesLabel,
  departmentsLabel,
  okButton,
  eventTypeLabel,
  eventTypesLabel,
  typeLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  endDateLabel,
  addButton,
  deleteButton,
  startDateLabel,
  saveButton,
  officeLabel,
  officesLabel,
  departmentsLabel,
  employeesLabel,
  addressLabel,
  cancelButton,
  editButton,
  targetMemberLabel,
  departmentsMembersLabel,
  okButton,
  eventTypeLabel,
  eventTypesLabel,
  typeLabel,
  questionnaireLabel: {
    id: 'schedule.event.questionnaires.label.questionnaire',
    defaultMessage: 'Questionnaire',
  },
  excludedParticipantsLabel: {
    id: 'schedule.event.questionnaires.label.exclude.members',
    defaultMessage: 'Exclude Participants',
  },
  assesmentsLabel: {
    id: 'shedule.event.assesments.label.assesments',
    defaultMessage: 'Assesments',
  },
  respondentsLabel: {
    id: 'schedule.event.questionnaires.label.respondents',
    defaultMessage: 'Respondents',
  },
  valueLabel: {
    id: 'schedule.event.reminder.label.value',
    defaultMessage: 'Value',
  },
  reminderButton: {
    id: 'schedule.event.reminder.label.reminder',
    defaultMessage: 'Reminder',
  },
  titleLabel: {
    id: 'schedule.layout.label.title',
    defaultMessage: 'Title',
  },
  participantsLabel: {
    id: 'schedule.layout.label.participants',
    defaultMessage: 'Participants',
  },
  deleteModalTitle: {
    id: 'schedule.modal.delete.event.title.are.you.sure.you.want.to.delete.this.event',
    defaultMessage: 'Are you sure you want to delete this event?',
  },
  deleteRecurringModalTitle: {
    id: 'schedule.modal.delete.event.title.delete.recurring.event',
    defaultMessage: 'Delete recurring event',
  },
  editModalTitle: {
    id: 'schedule.modal.edit.event.title.edit.event',
    defaultMessage: 'Edit Event',
  },
  newModalTitle: {
    id: 'schedule.modal.new.event.title.new.event',
    defaultMessage: 'New Event',
  },
  allDayLabel: {
    id: 'schedule.modal.new.event.label.all.day',
    defaultMessage: 'All day',
  },
  startTimeLabel: {
    id: 'schedule.modal.new.event.label.start.time',
    defaultMessage: 'Start Time',
  },
  endTimeLabel: {
    id: 'schedule.modal.new.event.label.end.time',
    defaultMessage: 'End Time',
  },
  recurrenceLabel: {
    id: 'schedule.modal.new.event.label.recurrence',
    defaultMessage: 'Recurrence',
  },
  historyButton: {
    id: 'schedule.modal.new.event.btn.history',
    defaultMessage: 'History',
  },
  questionnairesLabel: {
    id: 'schedule.modal.new.event.label.questionnaires.upper',
    defaultMessage: 'QUESTIONNAIRES',
  },
  excludeDaysOfAbsenceFromOtherTypesLabel: {
    id: 'schedule.modal.new.event.label.exclude.days.of.absence.from.other.types',
    defaultMessage: 'Exclude days of absence from other types',
  },
  finalDecisionLabel: {
    id: 'schedule.modal.new.event.label.final.decision',
    defaultMessage: 'Final Decision',
  },
  descriptionLabel: {
    id: 'schedule.modal.new.event.label.description',
    defaultMessage: 'Description',
  },
  approveLabel: {
    id: 'schedule.modal.new.event.label.approve',
    defaultMessage: 'Approve',
  },
  thisAndFollowingEventsLabel: {
    id: 'schedule.modal.new.event.label.this.and.following.events',
    defaultMessage: 'This and following events',
  },
  thisEventLabel: {
    id: 'schedule.modal.new.event.label.this.event',
    defaultMessage: 'This event',
  },
  eventRepeatTitle: {
    id: 'schedule.modal.new.event.label.event.repeat',
    defaultMessage: 'Event repeat',
  },
  repeatEveryTitle: {
    id: 'schedule.modal.new.event.label.repeat.every',
    defaultMessage: 'Repeat every',
  },
  repeatTypeTitle: {
    id: 'schedule.modal.new.event.label.repeat.type',
    defaultMessage: 'Repeat type',
  },
  endRepeatDateTitle: {
    id: 'schedule.modal.new.event.label.end.repeat.date',
    defaultMessage: 'End repeat date',
  },
  repeatsTitle: {
    id: 'schedule.modal.new.event.label.repeats',
    defaultMessage: 'Repeats',
  },
  repeatEndTitle: {
    id: 'schedule.modal.new.event.label.repeat.end',
    defaultMessage: 'Repeat end',
  },
  dateTitle: {
    id: 'schedule.modal.new.event.label.date',
    defaultMessage: 'Date',
  },
  afterTitle: {
    id: 'schedule.modal.new.event.label.after',
    defaultMessage: 'After',
  },
  approvedLabel: {
    id: 'schedule.departments.layout.label.approved',
    defaultMessage: 'Approved',
  },
  notApprovedLabel: {
    id: 'schedule.departments.layout.label.not.approved',
    defaultMessage: 'Not approved',
  },
  remindEventLabel: {
    id: 'schedule.modal.event.label.remind',
    defaultMessage: 'Remind',
  },
  beforeEventLabel: {
    id: 'schedule.modal.event.label.before',
    defaultMessage: 'before',
  },
  sendNotificationLabel: {
    id: 'schedule.event.modal.label.send.notification',
    defaultMessage: 'Send notification',
  },
  sendNotificationOptionsLabel: {
    id: 'schedule.event.modal.label.send.notification.options',
    defaultMessage: 'For whom this notification',
  },
  noEventsLabel: {
    id: 'schedule.event.popover.label.no.events',
    defaultMessage: 'No events',
  },
  datesLabel: {
    id: 'schedule.layout.label.dates',
    defaultMessage: 'Dates',
  },
});
