import React from 'react';
import { UserAbsencePeriods } from '../../../enums/users.enum';

type AbsenceContentProps = {
  absencePeriod: UserAbsencePeriods;
};

function AbsenceContent({ absencePeriod }: AbsenceContentProps) {
  return (
    <div className="absence-block__item">
      <div className="absence-block__name">{absencePeriod.absenceReason}</div>
      <div className="absence-block__number-wrapper">
        {absencePeriod.daysAvailableThisYear ? (
          <div className="absence-block__number">{`${Math.round(
            absencePeriod.daysAvailableThisYear -
              (Math.round(absencePeriod.usedNumber) - absencePeriod.scheduledNumber),
          )} of ${Math.round(absencePeriod.daysAvailableThisYear)} left`}</div>
        ) : (
          <div className="absence-block__number">{`${
            absencePeriod.usedNumber - absencePeriod.scheduledNumber
          } used`}</div>
        )}
        {!!absencePeriod.scheduledNumber && (
          <div className="absence-block__number scheduled">{`${absencePeriod.scheduledNumber} scheduled`}</div>
        )}
      </div>
    </div>
  );
}

export default React.memo(AbsenceContent);
