import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewTechnicalSkill from '../../components/TechnicalSkills/Modals/ModalNewTechnicalSkill';
import ModalEditTechnicalSkill from '../../components/TechnicalSkills/Modals/ModalEditTechnicalSkill';
import ModalDeleteTechnicalSkill from '../../components/TechnicalSkills/Modals/ModalDeleteTechnicalSkill';
import Pagination from '../../components/Pagination';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { TechnicalSkillsParams } from '../../enums/params/libraries.params';
import { DELETE_TECHNICAL_SKILL, UPDATE_TECHNICAL_SKILL } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import HierarchicalTable from '../../components/HierarchicalTable';
import { useTableData } from '../../utils/hooks.utils';
import { getTableCell } from '../../utils/table.utils';
import Dropdown from '../../components/Dropdown';
import { DepthLevels } from '../../constants/tables.constants';
import PoliciesContext from '../../PoliciesContext';
import { checkPolicies } from '../../utils/policies.utils';
import { TechnicalSkillInfoType } from '../../types/libraries';

function TechnicalSkills({
  getTechnicalSkillsList,
  setTechnicalSkillsParams,
  createNewTechnicalSkill,
  editTechnicalSkill,
  deleteTechnicalSkill,
  technicalSkillError,
  isLoading,
  tableData,
  pageData,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const [modalNewTechnicalSkillIsOpen, setModalNewTechnicalSkillIsOpen] = useState(false);
  const [modalEditTechnicalSkillIsOpen, setModalEditTechnicalSkillIsOpen] = useState(false);
  const [modalDeleteTechnicalSkillIsOpen, setModalDeleteTechnicalSkillIsOpen] = useState(false);
  const [technicalSkillClicked, setTechnicalSkillClicked] = useState<TechnicalSkillInfoType>({
    name: '',
    id: '',
    skills: [],
  });

  const intl = useIntl();

  useEffect(() => {
    getTechnicalSkillsList();
    return () => {
      resetState();
    };
  }, []);

  const handlePageChange = useCallback(({ selected }) => {
    setTechnicalSkillsParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setTechnicalSkillsParams({ size: data, page: 0 });
  }, []);

  const openNewTechnicalSkillModal = useCallback(() => {
    setModalNewTechnicalSkillIsOpen(true);
  }, []);

  const closeNewTechnicalSkillModal = useCallback(() => {
    setModalNewTechnicalSkillIsOpen(false);
  }, []);

  const openEditTechnicalSkillModal = useCallback(() => {
    setModalEditTechnicalSkillIsOpen(true);
  }, []);

  const closeEditTechnicalSkillModal = useCallback(() => {
    setModalEditTechnicalSkillIsOpen(false);
  }, []);

  const openDeleteTechnicalSkillModal = useCallback(() => {
    setModalDeleteTechnicalSkillIsOpen(true);
  }, []);

  const closeDeleteTechnicalSkillModal = useCallback(() => {
    setModalDeleteTechnicalSkillIsOpen(false);
  }, []);

  const openTechnicalSkillModal = useCallback((technicalSkill: TechnicalSkillInfoType) => {
    setTechnicalSkillClicked(technicalSkill);
  }, []);

  const setTechnicalSkillCallback = useCallback(() => {
    setTechnicalSkillClicked({
      name: '',
      id: '',
      skills: [],
    });
  }, []);

  const tableActions: any = useMemo(
    () => [
      ...(checkPolicies([UPDATE_TECHNICAL_SKILL], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.editButton} />
                </>
              ),
              itemClassName: 'dropdown__list-item__button',
              handler: (row: TechnicalSkillInfoType) => {
                setTechnicalSkillClicked(row);
                openEditTechnicalSkillModal();
              },
            },
          ]
        : []),
      ...(checkPolicies([DELETE_TECHNICAL_SKILL], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.deleteButton} />
                </>
              ),
              itemClassName: 'dropdown__list-item__button',
              handler: (row: TechnicalSkillInfoType) => {
                setTechnicalSkillClicked(row);
                openDeleteTechnicalSkillModal();
              },
            },
          ]
        : []),
    ],
    [policies],
  );

  const tableColumns = useMemo(
    () => [
      {
        id: 'nameTitle',
        Header: intl.formatMessage(messages.nameTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: TechnicalSkillInfoType) => (
                <span onClick={() => openTechnicalSkillModal(row)}>{row.name}</span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: { skill: string }) => <span>{row.skill}</span>,
            },
          ]),
      },
      ...(tableActions.length
        ? [
            {
              Header: '',
              id: 'dropdown',
              Cell: ({ row }: any) =>
                getTableCell(row, [
                  {
                    depth: DepthLevels.FIRST,
                    content: (row: TechnicalSkillInfoType, index: number) => (
                      <div className="table__dropdown-wrapper">
                        <Dropdown
                          dropdownClass="dropdown--no-bg"
                          dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                          dropdownList={tableActions}
                          dropdownInfo={row}
                          dropdownIndex={index}
                          stopPropagation
                        />
                      </div>
                    ),
                  },
                ]),
            },
          ]
        : []),
    ],
    [tableActions.length],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper">
              <AccessChecker verifiablePolicies={[UPDATE_TECHNICAL_SKILL]}>
                <Button externalClass={'button--with-icon'} onClick={openNewTechnicalSkillModal}>
                  <Icon iconName={'plus'} externalClass={'button__icon'} />
                  <span className="button__text">
                    <FormattedMessage {...messages.newButton} />
                  </span>
                </Button>
              </AccessChecker>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content page__content--s">
        <div className="page__wrapper">
          <HierarchicalTable
            tableData={useTableData(tableData, ['skills'])}
            tableColumns={tableColumns}
            loading={isLoading.getTechnicalSkills}
            error={technicalSkillError}
            externalClass="table--bordered-top technical-skills table--half"
          />
          <Pagination
            pageable={pageData || []}
            onPageChange={data => handlePageChange(data)}
            onPageSizeChange={data => handleSizeChange(data)}
          />
        </div>
      </div>
      {modalNewTechnicalSkillIsOpen && (
        <ModalNewTechnicalSkill
          isOpen
          onCloseRequest={closeNewTechnicalSkillModal}
          createNewTechnicalSkill={createNewTechnicalSkill}
          technicalSkillError={technicalSkillError}
          isLoading={isLoading.createTechnicalSkill}
          resetErrors={resetErrors}
        />
      )}
      {modalEditTechnicalSkillIsOpen && (
        <ModalEditTechnicalSkill
          isOpen
          onCloseRequest={closeEditTechnicalSkillModal}
          editTechnicalSkill={editTechnicalSkill}
          technicalSkillData={technicalSkillClicked}
          technicalSkillError={technicalSkillError}
          isLoading={isLoading.editTechnicalSkill}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteTechnicalSkillIsOpen && (
        <ModalDeleteTechnicalSkill
          isOpen
          onCloseRequest={closeDeleteTechnicalSkillModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteTechnicalSkill({
              ...data,
              setTechnicalSkillCallback,
            });
          }}
          isLoading={isLoading.deleteTechnicalSkill}
          technicalSkillError={technicalSkillError}
          technicalSkillData={technicalSkillClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries }: RootState) => ({
  tableData: libraries.technicalSkillTableData?.content,
  pageData: libraries.technicalSkillTableData?.pageable,
  technicalSkillError: libraries.errors.technicalSkillError,
  isLoading: libraries.loading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTechnicalSkillsList: () => dispatch(librariesActions.getTechnicalSkillsList()),
  setTechnicalSkillsParams: (params: Partial<TechnicalSkillsParams>) =>
    dispatch(librariesActions.setTechnicalSkillsParams(params)),
  deleteTechnicalSkill: (data: Record<string, unknown>) => dispatch(librariesActions.deleteTechnicalSkill(data)),
  createNewTechnicalSkill: (data: Record<string, unknown>) => dispatch(librariesActions.createNewTechnicalSkill(data)),
  editTechnicalSkill: (id: string, data: Record<string, unknown>) =>
    dispatch(librariesActions.editTechnicalSkill({ ...data, id })),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(TechnicalSkills);
