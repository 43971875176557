import { defineMessages } from 'react-intl';
import {
  newButton,
  editButton,
  deleteButton,
  nameColumn,
  competenciesTitle,
  skillsColumn,
  maxScoreColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteButton,
  editButton,
  competenciesTitle,
  newButton,
  skillsColumn,
  maxScoreColumn,
  nameColumn,
  newCompetenceTitle: {
    id: 'competencies.form.new.competencies.title.new.competence',
    defaultMessage: 'New Competence',
  },
  competenciesTypeTitle: {
    id: 'competencies.column.new.competencies.type',
    defaultMessage: 'Competencies Type',
  },
  countOfLevelTitle: {
    id: 'competencies.column.new.ccount.of.level',
    defaultMessage: 'Count Of Level',
  },
  countOfSkillsTitle: {
    id: 'competencies.column.new.ccount.of.skills',
    defaultMessage: 'Count Of Skills',
  },
  editCompetenceTitle: {
    id: 'competencies.form.new.competencies.title.new.competence',
    defaultMessage: 'Edit Competence',
  },
  questionsTitle: {
    id: 'competencies.info.column.questions',
    defaultMessage: 'Questions',
  },
});
