import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  statusLabel,
  createdColumn,
  updatedColumn,
  experienceLabel,
  applyButton,
  clearButton,
  allLabel,
  fromLabel,
  toLabel,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  deleteButton,
  statusLabel,
  createdColumn,
  updatedColumn,
  experienceLabel,
  applyButton,
  clearButton,
  allLabel,
  fromLabel,
  toLabel,
  nameColumn,
  newCandidateTitle: {
    id: 'candidates.management.modal.new.title.new.candidate',
    defaultMessage: 'New Candidate',
  },
  editCandidateTitle: {
    id: 'candidates.candidate.modal.edit.title.edit.candidate',
    defaultMessage: 'Edit Candidate',
  },
  deleteModalTitle: {
    id: 'candidates.candidate.modal.delete.title.delete.candidate',
    defaultMessage: 'Are you sure you want to delete this candidate?',
  },
  firstNameLabel: {
    id: 'candidates.candidate.modal.label.first.name',
    defaultMessage: 'First Name*',
  },
  secondNameLabel: {
    id: 'candidates.candidate.modal.label.second.name',
    defaultMessage: 'Second Name*',
  },
  alternativeNameLabel: {
    id: 'candidates.candidate.modal.label.alternative.name',
    defaultMessage: 'Alternative Name',
  },
  locationLabel: {
    id: 'candidates.candidate.modal.label.location',
    defaultMessage: 'Location',
  },
  emailsLabel: {
    id: 'candidates.candidate.modal.label.emails',
    defaultMessage: 'Emails',
  },
  phonesLabel: {
    id: 'candidates.candidate.modal.label.phones',
    defaultMessage: 'Phones',
  },
  careerStartLabel: {
    id: 'candidates.candidate.modal.label.career.start',
    defaultMessage: 'Career Start',
  },
  yearLabel: {
    id: 'candidates.candidate.modal.label.year',
    defaultMessage: 'Year',
  },
  monthLabel: {
    id: 'candidates.candidate.modal.label.month',
    defaultMessage: 'Month',
  },
  leadExpLabel: {
    id: 'candidates.candidate.modal.label.lead.exp',
    defaultMessage: 'Lead Exp.',
  },
  specializationsLabel: {
    id: 'candidates.candidate.modal.label.specializations',
    defaultMessage: 'Specializations',
  },
  technologiesLabel: {
    id: 'candidates.candidate.modal.label.technologies',
    defaultMessage: 'Technologies',
  },
  previousEmploymentLabel: {
    id: 'candidates.candidate.modal.label.previous.employment',
    defaultMessage: 'Previous Employment',
  },
  linksLabel: {
    id: 'candidates.candidate.modal.label.links',
    defaultMessage: 'Links',
  },
  receivingSourceLabel: {
    id: 'candidates.candidate.modal.label.receiving.source',
    defaultMessage: 'Receiving Source',
  },
  receivingSourcesLabel: {
    id: 'candidates.candidate.filter.label.receiving.sources',
    defaultMessage: 'Receiving Sources',
  },
  summaryLabel: {
    id: 'candidates.candidate.filter.label.summary',
    defaultMessage: 'Summary',
  },
  commentsLabel: {
    id: 'candidates.candidate.modal.label.comments',
    defaultMessage: 'Comments',
  },
  locationTitle: {
    id: 'candidate.management.label.location',
    defaultMessage: 'Location',
  },
  receivingSourceTitle: {
    id: 'candidate.management.label.receiving.source',
    defaultMessage: 'Receiving Source',
  },
});
