import { useFloating, shift, flip } from '@floating-ui/react-dom';
import React from 'react';
import Checkbox from '../Checkbox';
import { FilterOptionType } from '.';

type NestedOptionsProps = {
  optionData: {
    rect: { getBoundingClientRect: () => DOMRect } | null;
    data: FilterOptionType;
  };
  getNestedOptions: () => { id: string; name: string }[];
  handleNestedOptionClick?: (optionId: string, nestedOptionId: string) => void;
  handleCheckedNestedOption?: (optionId: string, nestedOptionId: string) => boolean | undefined;
};

function NestedOptions({
  optionData,
  getNestedOptions,
  handleCheckedNestedOption,
  handleNestedOptionClick,
}: NestedOptionsProps) {
  const nestedOptions = getNestedOptions();
  const { refs, floatingStyles } = useFloating({
    elements: {
      reference: optionData.rect,
    },

    middleware: [flip(), shift()],
    open: true,
    strategy: 'fixed',
    placement: 'right-start',
  });

  return (
    <div className="skill-levels-wrapper" style={floatingStyles} ref={refs.setFloating}>
      <div className="skill-levels">
        {nestedOptions.map(option => (
          <div
            key={`${optionData.data.value}-${option.id}`}
            className="list-item"
            onClick={() => handleNestedOptionClick && handleNestedOptionClick(optionData.data.value, option.id)}
          >
            <Checkbox
              id={option.id}
              checkedValue={
                handleCheckedNestedOption ? handleCheckedNestedOption(optionData.data.value, option.id) : undefined
              }
            />
            <span className="skill-level">{option.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NestedOptions;
