import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { PROJECTS_VALIDATION_SCHEMA } from '../../../enums/libraries.enum';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import TextArea from '../../TextArea';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { ProjectInfoType } from '../../../types/libraries';
import { scrollToError } from '../../../utils';

type ModalEditProjectProps = {
  onCloseRequest: () => void;
  editProject: (id: string, data: any) => void;
  projectData: ProjectInfoType;
  projectError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalEditProject({
  onCloseRequest,
  editProject,
  projectData,
  projectError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalEditProjectProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const [descriptionValue, setDescriptionValue] = useState(projectData.description || '');
  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldError } = useFormik({
    initialValues: projectData,
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: PROJECTS_VALIDATION_SCHEMA,
    onSubmit: data => {
      data.description = descriptionValue;
      return editProject(projectData?.id, { data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(projectError, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editProjectTitle)}
      size={'big'}
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              id={'edit-name'}
              name={'name'}
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values?.name || ''}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
            />
          </div>
          <div className="form__input-block">
            <TextArea
              id={'edit'}
              placeholder={intl.formatMessage(messages.descriptionTextAreaPlaceholder)}
              defaultValue={projectData.description || ''}
              onChange={data => {
                setDescriptionValue(data as string);
              }}
            />
          </div>
        </div>
        <ErrorMessage>{projectError}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit} type={'button'}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditProject;
