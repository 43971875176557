import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { MailerSetting, EDIT_MAILER_SETTING_VALIDATION_SCHEMA } from '../../../enums/notifications.enum';
import Select from '../../Select';
import { SERVRE_TYPES } from '../../../constants/notifications.constants';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { aesEcbEncryption } from '../../../utils/encryption.utils';
import { scrollToError } from '../../../utils';

type ModalEditMailerSettingProps = {
  mailerSettingError: string | null | RejectValueErrors[];
  isLoading: boolean;
  mailerSettingData: MailerSetting;
  isOpen: boolean;
  onCloseRequest: () => void;
  editMailSetting: (data: any) => void;
  resetErrors: () => void;
};

function ModalEditMailerSetting({
  mailerSettingError,
  isLoading,
  mailerSettingData,
  isOpen,
  onCloseRequest,
  editMailSetting,
  resetErrors,
}: ModalEditMailerSettingProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError, resetForm } = useFormik({
    initialValues: new MailerSetting(mailerSettingData),
    validateOnChange: false,
    enableReinitialize: true,
    validate: scrollToError,
    validationSchema: EDIT_MAILER_SETTING_VALIDATION_SCHEMA,
    onSubmit: data => {
      return editMailSetting({
        id: data.id,
        data: { ...data, password: data.password ? aesEcbEncryption(data.password) : null },
        callback: resetAndExit,
      });
    },
  });

  useSetFieldsErrors(mailerSettingError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const notificationTypesOptions = useMemo(() => {
    return SERVRE_TYPES.map(({ value, label }: any) => {
      return {
        label,
        value,
      };
    });
  }, []);

  const notificationTypesValues = useMemo(() => {
    return notificationTypesOptions.filter(el => values.serverTypes.includes(el.value));
  }, [values.serverTypes]);

  const handleNotificationTypesChange = useCallback(data => {
    setFieldValue(
      'serverTypes',
      data.map((serverType: any) => serverType.value),
    );
  }, []);

  return (
    <Modal isOpen={isOpen} onRequestClose={resetAndExit} title={intl.formatMessage(messages.editConfigurationTitle)}>
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              id="displayName"
              name="displayName"
              label={intl.formatMessage(messages.dispalyNameLabel)}
              defaultValue={values.displayName}
              onChange={handleChange}
              hasError={hasError('displayName')}
              errorMessage={errors?.displayName}
            />
          </div>
          <div className="form__input-block">
            <Input
              id="host"
              name="host"
              label={intl.formatMessage(messages.mailHostLabel)}
              defaultValue={values.host}
              onChange={handleChange}
              hasError={hasError('host')}
              errorMessage={errors?.host}
            />
          </div>
          <div className="form__input-block">
            <Input
              id="username"
              name="username"
              label={intl.formatMessage(messages.mailUsernameLabel)}
              defaultValue={values.username}
              onChange={handleChange}
              hasError={hasError('username')}
              errorMessage={errors?.username}
            />
          </div>
          <div className="form__input-block">
            <Input
              type="password"
              id="password"
              name="password"
              label={intl.formatMessage(messages.mailPasswordLabel)}
              defaultValue={values.password}
              onChange={handleChange}
              hasError={hasError('password')}
              errorMessage={errors?.password}
              autocomplete="off"
            />
          </div>
          <div className="form__group">
            <div className="form__inputs-subwrapper align-start">
              <Input
                id="port"
                name="port"
                label={intl.formatMessage(messages.mailPortLabel)}
                defaultValue={values.port}
                onChange={handleChange}
                hasError={hasError('port')}
                errorMessage={errors?.port}
                type="number"
                min={1}
              />
              <Input
                id="encryption"
                name="encryption"
                label={intl.formatMessage(messages.mailEncryptionLabel)}
                defaultValue={values.encryption}
                onChange={handleChange}
                hasError={hasError('encryption')}
                errorMessage={errors?.encryption}
              />
            </div>
          </div>
          <div className="form__input-block">
            <Select
              isMulti
              label={intl.formatMessage(messages.notificationsTypesLabel)}
              handleChange={handleNotificationTypesChange}
              options={notificationTypesOptions}
              value={notificationTypesValues}
            />
          </div>
        </div>
        <ErrorMessage>{mailerSettingError}</ErrorMessage>
        <div className="form__buttons">
          <Button color="gray" externalClass="button--modal button--cancel" type="button" onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass="button--modal" type="submit" loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditMailerSetting;
