import { isNull } from 'lodash-es';

export const GET_ACTIVITY_TABLE_REQUEST = 'GET_ACTIVITY_TABLE_REQUEST';
export const GET_ACTIVITY_TABLE_SUCCESS = 'GET_ACTIVITY_TABLE_SUCCESS';
export const GET_ACTIVITY_TABLE_FAILURE = 'GET_ACTIVITY_TABLE_FAILURE';

export const GET_ACTIVITY_DIAGRAM_REQUEST = 'GET_ACTIVITY_DIAGRAM_REQUEST';
export const GET_ACTIVITY_DIAGRAM_SUCCESS = 'GET_ACTIVITY_DIAGRAM_SUCCESS';
export const GET_ACTIVITY_DIAGRAM_FAILURE = 'GET_ACTIVITY_DIAGRAM_FAILURE';

export const SET_ACTIVITY_DIAGRAM_PARAMS_REQUEST = 'SET_ACTIVITY_DIAGRAM_PARAMS_REQUEST';
export const SET_ACTIVITY_TABLE_PARAMS_REQUEST = 'SET_ACTIVITY_TABLE_PARAMS_REQUEST';
export const SET_USER_TAB_ACTIVITY_PARAMS_REQUEST = 'SET_USER_TAB_ACTIVITY_PARAMS_REQUEST';

export enum ActivityLayouts {
  ACTIVITY_CHART = 'ACTIVITY_CHART',
  ACTIVITY_TABLE = 'ACTIVITY_TABLE',
}

export const activityTime = [
  '12 AM',
  '2 AM',
  '4 AM',
  '6 AM',
  '8 AM',
  '10 AM',
  '12 PM',
  '2 PM',
  '4 PM',
  '6 PM',
  '8 PM',
  '10 PM',
];

export const activityChartColumns = [
  { type: 'string', id: 'Date' },
  { type: 'string', id: 'ProjectName' },
  { type: 'string', role: 'tooltip', p: { html: true } },
  { type: 'string', role: 'style' },
  { type: 'date', id: 'Start' },
  { type: 'date', id: 'End' },
  { type: 'string', role: 'data', id: 'issue' },
];

export const workChartOptions = (isActivityPage = true) => ({
  timeline: {
    groupByRowLabel: true,
    showRowLabels: !isActivityPage,
    showBarLabels: true,
    rowLabelStyle: {
      fontName: 'var(--body-font)',
      fontSize: 14,
      color: '#4A4A4A',
    },
    barLabelStyle: {
      fontName: 'var(--body-font)',
      fontSize: 11,
    },
  },
  chartArea: {
    width: '50%',
    left: 2000,
    top: 2000,
  },
  avoidOverlappingGridLines: false,
  tooltip: { isHtml: true },
});

export const summaryOptions = {
  responsive: true,
  type: 'bar',
  categoryPercentage: 0.45,
  interaction: {
    mode: 'index' as const,
    position: 'cursor',
  },
  borderSkipped: false,
  barPercentage: 0.93,
  plugins: {
    tooltip: {
      usePointStyle: true,
      boxPadding: 5,
      yAlign: 'top' as const,
      padding: {
        right: 10,
        left: 10,
        top: 10,
        bottom: 10,
      },
      callbacks: {
        label: (context: any) => {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          const yValue = context.parsed.y;

          if (!isNull(yValue)) {
            const activityTime = yValue * 3600000;
            const date = new Date(activityTime).toISOString().slice(11, 16).split(':');
            label += `${date[0]}h ${date[1]}m`;
          }
          return label;
        },
      },
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: 'rgba(74, 74, 74, 0.5)',
      },
    },
    y: {
      min: 0,
      suggestedMax: 8,
      ticks: {
        stepSize: 2,
        backdropPadding: 100,
        backdropColor: 'black',
        callback: function (value: any) {
          return value + ' hours';
        },
        color: 'rgba(74, 74, 74, 0.5)',
        opacity: 0.5,
        beginAtZero: true,
      },
      grid: {
        drawBorder: false,
        color: '#F0F0F0',
      },
    },
  },
};

export const GET_SOFTWARE_LINKS_REQUEST = 'GET_SOFTWARE_LINKS_REQUEST';
export const GET_SOFTWARE_LINKS_SUCCESS = 'GET_SOFTWARE_LINKS_SUCCESS';
export const GET_SOFTWARE_LINKS_FAILURE = 'GET_SOFTWARE_LINKS_FAILURE';

export const GET_SOFTWARE_REQUEST = 'GET_SOFTWARE_REQUEST';
export const GET_SOFTWARE_SUCCESS = 'GET_SOFTWARE_SUCCESS';
export const GET_SOFTWARE_FAILURE = 'GET_SOFTWARE_FAILURE';

export const POST_SOFTWARE_REQUEST = 'POST_SOFTWARE_REQUEST';
export const POST_SOFTWARE_SUCCESS = 'POST_SOFTWARE_SUCCESS';
export const POST_SOFTWARE_FAILURE = 'POST_SOFTWARE_FAILURE';

export const DELETE_SOFTWARE_REQUEST = 'DELETE_SOFTWARE_REQUEST';
export const DELETE_SOFTWARE_SUCCESS = 'DELETE_SOFTWARE_SUCCESS';
export const DELETE_SOFTWARE_FAILURE = 'DELETE_SOFTWARE_FAILURE';

export const RESET_ACTIVITY_ERRORS = 'RESET_ACTIVITY_ERRORS';

export const FILE_SIZE_LIMIT = 104857600;

export const activityUnsavedParams = ['dateFrom', 'dateTo', 'isTable', 'zonedDateTime'];

export const RESET_ACTIVITY_STATE = 'RESET_ACTIVITY_STATE';
