import { isEmpty, isNaN } from 'lodash-es';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';
import {
  DATE_PICKER,
  DATE_TIME_PICKER,
  SELECT_LIST_MULTIPLE_CHOICE,
  SELECT_LIST_SINGLE_CHOICE,
  NUMBER,
} from '../../constants/questionnaires.constants';
import { PollTemplateGroupType } from '../../enums/questionnaires.enum';
import { EFileExtensions } from '../../constants/export.constants';

type PollStatusType = typeof closedValue | typeof publishedValue | typeof notPublishedValue;

const completedColor = '#139619';
const inProgressColor = 'orange';
const notStartedColor = '#4a4a4a80';

export const oneAnswerView = 1;
export const twoAnswerView = 2;
export const threeAnswerView = 3;

export const fileSizeForAvatar = 48;

export const minWidthFirstColumn = 250;
export const windowPadding = 96;

export const closedValue = 'CLOSED';
export const publishedValue = 'PUBLISHED';
export const notPublishedValue = 'NOT_PUBLISHED';

export const completedStatusValue = 'COMPLETED';
export const inProgressStatusValue = 'IN_PROGRESS';
export const notStartedStatusValue = 'NOT_STARTED';

export const completedClassName = 'approved';
export const inProgressClassName = 'in-progress';
export const notStartedClassName = 'not-started';

export const AllOfficesOption = { label: 'All Offices', id: 'All Offices' };

export const PollStatusOptions = [
  { label: 'Closed', value: closedValue },
  { label: 'Published', value: publishedValue },
  { label: 'Not Published', value: notPublishedValue },
];

export const UserStatusOptions = [
  { label: 'Completed', value: completedStatusValue },
  { label: 'In Progress', value: inProgressStatusValue },
  { label: 'Not Started', value: notStartedStatusValue },
];

//@ts-ignore
export const getUserStatusLabel = status => {
  switch (status) {
    case completedStatusValue:
      return UserStatusOptions[0].label.toUpperCase();
    case inProgressStatusValue:
      return UserStatusOptions[1].label.toUpperCase();
    case notStartedStatusValue:
      return UserStatusOptions[2].label.toUpperCase();
    default:
      return UserStatusOptions[2].label.toUpperCase();
  }
};

export const getEventPollUserStatusLabel = (status: PollStatusType) => {
  switch (status) {
    case publishedValue:
      return UserStatusOptions[1].label.toUpperCase();

    case closedValue:
      return UserStatusOptions[0].label.toUpperCase();

    default:
      return UserStatusOptions[2].label.toUpperCase();
  }
};

//@ts-ignore
export const styleStatus = status => {
  switch (status) {
    case closedValue || completedStatusValue:
      return completedColor;
    case publishedValue || inProgressStatusValue:
      return inProgressColor;
    case notPublishedValue || notStartedStatusValue:
      return notStartedColor;
    default:
      return notStartedColor;
  }
};

//@ts-ignore
export const styleUserStatus = status => {
  switch (status) {
    case completedStatusValue:
      return completedColor;
    case inProgressStatusValue:
      return inProgressColor;
    case notStartedStatusValue:
      return notStartedColor;
    default:
      return notStartedColor;
  }
};

//@ts-ignore
export const getPollStatusLabel = status => {
  switch (status) {
    case closedValue:
      return PollStatusOptions[0].label.toUpperCase();
    case publishedValue:
      return PollStatusOptions[1].label.toUpperCase();
    case notPublishedValue:
      return PollStatusOptions[2].label.toUpperCase();
    default:
      return PollStatusOptions[2].label.toUpperCase();
  }
};

//@ts-ignore
export const getPollStatusClassName = status => {
  switch (status) {
    case closedValue || completedStatusValue:
      return completedClassName;
    case publishedValue || inProgressStatusValue:
      return inProgressClassName;
    case notPublishedValue || notStartedStatusValue:
      return notStartedClassName;
    default:
      return notStartedClassName;
  }
};

//@ts-ignore
export const getClassNameForUserStatus = status => {
  switch (status) {
    case completedStatusValue:
      return completedClassName;
    case inProgressStatusValue:
      return inProgressClassName;
    case notStartedStatusValue:
      return notStartedClassName;
    default:
      return notStartedClassName;
  }
};

export const getStatusLabel = (status: any) => {
  return PollStatusOptions.find((el: any) => status === el.value)?.label;
};

export const userStatusLabel = (status: any) => {
  return UserStatusOptions.find((el: any) => status === el.value)?.label;
};

export const normalizeDateFormat = (date: any, format: string) => {
  return moment(date).isValid() ? `${moment(date).format(format)}` : '-';
};

export const typeFileOptions = [
  { label: 'Excel', value: 'EXCEL', ext: EFileExtensions.XLS },
  { label: 'PDF', value: 'PDF', ext: EFileExtensions.PDF },
];

export const exportTypeOptions = [
  { label: 'Questions and Answers', value: 'WITH_COMMENT', fileName: 'QuestionsAndAnswers' },
  { label: 'Correct and Incorrect Stats', value: 'WITH_CALCULATING_ANSWERS', fileName: 'CorrectAndIncorrectStats' },
];

export const getUserStatus = (questionsNum: number, answersNum: number) => {
  if (answersNum === 0) return notStartedStatusValue;
  if (answersNum < questionsNum && answersNum !== 0) return inProgressStatusValue;
  if (answersNum === questionsNum || answersNum > questionsNum) return completedStatusValue;
};

export const questionsNumber = (questionsGroups: any) => {
  const qNum = questionsGroups?.map((group: any) => group?.fields?.length);
  const sum = qNum?.reduce((a: number, b: number) => a + b);
  return sum ?? 0;
};

export const messageWithNoQuestions = 'There is no such template.';
export const messageClosedPoll = '"Closed" Poll cannot be changed';
export const messagePublishedPoll = '"Published" Poll with answers cannot be changed';
const requiredFieldErrorMessage = 'Required Field';
const invalidDateErrorMessage = 'Invalid date';

export const validatePollForm = (values: any, questionsGroups: PollTemplateGroupType[]) => {
  const errors: any = {};

  questionsGroups.forEach(group => {
    group.fields.forEach(field => {
      let error = '';
      const hasValue = Object.values(values).some((el: any) => {
        const options = el.pollAnswerOptions;
        if (el?.fieldId === field.id && !isEmpty(options)) {
          const fieldType = field.type;
          const fieldValue = options[0].value;
          switch (fieldType) {
            case SELECT_LIST_MULTIPLE_CHOICE:
            case SELECT_LIST_SINGLE_CHOICE:
              return options.every(
                (option: { id?: string; templateFieldOptionId?: string; value?: string }) =>
                  option?.templateFieldOptionId || option?.value,
              );

            case DATE_PICKER: {
              if (!moment(fieldValue, DATE_FORMAT.YYYY_MM_DD, true).isValid() && field.required) {
                error = invalidDateErrorMessage;
              }
              return !!fieldValue;
            }

            case DATE_TIME_PICKER: {
              if (!moment(fieldValue, DATE_FORMAT.YYYY_MM_DD_HH_mm_ss, true).isValid() && field.required) {
                error = invalidDateErrorMessage;
              }
              return !!fieldValue;
            }

            case NUMBER: {
              if (isNaN(fieldValue)) {
                error = 'Invalid number';
                return true;
              }

              return !!fieldValue || fieldValue === 0;
            }

            default:
              return !!fieldValue || fieldValue === 0;
          }
        }
      });
      if (field.required && !hasValue) {
        errors[field.id] = requiredFieldErrorMessage;
      } else if (error) {
        errors[field.id] = error;
      }
    });
  });

  return errors;
};
