import axios from 'axios';
import request from '../utils/request.utils';
import ENV from '../constants/env.constants';

export function getSalt(username: string) {
  const options: RequestConfig = {
    method: 'get',
    url: 'auth/login/salt',
    params: { username },
  };
  return request(options);
}

export function signIn(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: 'auth/login',
    data,
  };
  return request(options);
}

export function keyCloakSignIn(params: URLSearchParams) {
  const uninterceptedAxiosInstance = axios.create();
  return uninterceptedAxiosInstance.post(ENV.KEYCLOAK_SIGNIN_URL, params);
}

export function signInByKeycloakToken(data: { accessToken: string }) {
  const uninterceptedAxiosInstance = axios.create();
  const options: RequestConfig = {
    method: 'post',
    url: 'auth/keycloak/login',
    data,
  };
  return uninterceptedAxiosInstance(options);
}

export function signOut() {
  const options: RequestConfig = {
    method: 'post',
    url: 'auth/logout',
  };
  return request(options);
}

export function getCurrentUserInfo() {
  const options: RequestConfig = {
    method: 'get',
    url: 'auth',
  };
  return request(options);
}

export function refreshAccessToken(refreshToken: string) {
  const options: RequestConfig = {
    method: 'post',
    url: 'auth/refresh',
    params: { refreshToken },
  };
  return request(options);
}
