import { defineMessages } from 'react-intl';
import {
  spentLabel,
  exportToPDFLabel,
  exportToXLSLabel,
  copyURLLabel,
  taskLabel,
  externalLabel,
  inactiveLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  spentLabel,
  exportToPDFLabel,
  exportToXLSLabel,
  copyURLLabel,
  taskLabel,
  externalLabel,
  inactiveLabel,
  pageTitle: {
    id: 'settings.permissions.title.permissions',
    defaultMessage: 'Permissions',
  },
  pageModalColumn: {
    id: 'settings.permissions.column.page.modal',
    defaultMessage: 'PAGE/MODAL',
  },
  viewColumn: {
    id: 'settings.permissions.column.view',
    defaultMessage: 'VIEW',
  },
  updateColumn: {
    id: 'settings.permissions.column.update',
    defaultMessage: 'UPDATE',
  },
  deleteColumn: {
    id: 'settings.permissions.column.delete',
    defaultMessage: 'DELETE',
  },
});
