export const GET_COMPETENCIES_TYPES_LIST_REQUEST = 'GET_COMPETENCIES_TYPES_LIST_REQUEST';
export const GET_COMPETENCIES_TYPES_LIST_SUCCESS = 'GET_COMPETENCIES_TYPES_LIST_SUCCESS';
export const GET_COMPETENCIES_TYPES_LIST_FAILURE = 'GET_COMPETENCIES_TYPES_LIST_FAILURE';

export const POST_COMPETENCIES_TYPE_REQUEST = 'POST_COMPETENCIES_TYPE_REQUEST';
export const POST_COMPETENCIES_TYPE_SUCCESS = 'POST_COMPETENCIES_TYPE_SUCCESS';
export const POST_COMPETENCIES_TYPE_FAILURE = 'POST_COMPETENCIES_TYPE_FAILURE';

export const DELETE_COMPETENCIES_TYPE_REQUEST = 'DELETE_COMPETENCIES_TYPE_REQUEST';
export const DELETE_COMPETENCIES_TYPE_SUCCESS = 'DELETE_COMPETENCIES_TYPE_SUCCESS';
export const DELETE_COMPETENCIES_TYPE_FAILURE = 'DELETE_COMPETENCIES_TYPE_FAILURE';

export const PUT_COMPETENCIES_TYPE_REQUEST = 'PUT_COMPETENCIES_TYPE_REQUEST';
export const PUT_COMPETENCIES_TYPE_SUCCESS = 'PUT_COMPETENCIES_TYPE_SUCCESS';
export const PUT_COMPETENCIES_TYPE_FAILURE = 'PUT_COMPETENCIES_TYPE_FAILURE';

export const SET_COMPETENCIES_TYPES_PARAMS = 'SET_COMPETENCIES_TYPES_PARAMS';

export const GET_COMPETENCIES_LIST_REQUEST = 'GET_COMPETENCIES_LIST_REQUEST';
export const GET_COMPETENCIES_LIST_SUCCESS = 'GET_COMPETENCIES_LIST_SUCCESS';
export const GET_COMPETENCIES_LIST_FAILURE = 'GET_COMPETENCIES_LIST_FAILURE';

export const POST_COMPETENCE_REQUEST = 'POST_COMPETENCIES_REQUEST';
export const POST_COMPETENCE_SUCCESS = 'POST_COMPETENCIES_SUCCESS';
export const POST_COMPETENCE_FAILURE = 'POST_COMPETENCIES_FAILURE';

export const DELETE_COMPETENCE_REQUEST = 'DELETE_COMPETENCIES_REQUEST';
export const DELETE_COMPETENCE_SUCCESS = 'DELETE_COMPETENCIES_SUCCESS';
export const DELETE_COMPETENCE_FAILURE = 'DELETE_COMPETENCIES_FAILURE';

export const PUT_COMPETENCE_REQUEST = 'PUT_COMPETENCIES_REQUEST';
export const PUT_COMPETENCE_SUCCESS = 'PUT_COMPETENCIES_SUCCESS';
export const PUT_COMPETENCE_FAILURE = 'PUT_COMPETENCIES_FAILURE';

export const GET_COMPETENCE_REQUEST = 'GET_COMPETENCE_REQUEST';
export const GET_COMPETENCE_SUCCESS = 'GET_COMPETENCE_SUCCESS';
export const GET_COMPETENCE_FAILURE = 'GET_COMPETENCE_FAILURE';

export const CLEAN_COMPETENCE_DATA = 'CLEAN_COMPETENCE_DATA';

export const SET_COMPETENCIES_PARAMS = 'SET_COMPETENCIES_PARAMS';

export const GET_ASSESSMENTS_REQUEST = 'GET_ASSESSMENTS_REQUEST';

export const GET_FILTERED_ASSESSMENTS_REQUEST = 'GET_FILTERED_ASSESSMENTS_REQUEST';
export const GET_FILTERED_ASSESSMENTS_SUCCESS = 'GET_FILTERED_ASSESSMENTS_SUCCESS';
export const GET_FILTERED_ASSESSMENTS_FAILURE = 'GET_FILTERED_ASSESSMENTS_FAILURE';

export const POST_NEW_ASSESSMENT_REQUEST = 'POST_NEW_ASSESSMENT_REQUEST';
export const POST_NEW_ASSESSMENT_SUCCESS = 'POST_NEW_ASSESSMENT_SUCCESS';
export const POST_NEW_ASSESSMENT_FAILURE = 'POST_NEW_ASSESSMENT_FAILURE';

export const PUT_ASSESSMENT_REQUEST = 'PUT_ASSESSMENT_REQUEST';
export const PUT_ASSESSMENT_SUCCESS = 'PUT_ASSESSMENT_SUCCESS';
export const PUT_ASSESSMENT_FAILURE = 'PUT_ASSESSMENT_FAILURE';

export const DELETE_ASSESSMENT_REQUEST = 'DELETE_ASSESSMENT_REQUEST';
export const DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_FAILURE = 'DELETE_ASSESSMENT_FAILURE';

export const GET_ASSESSMENT_REQUEST = 'GET_ASSESSMENT_REQUEST';
export const GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS';
export const GET_ASSESSMENT_FAILURE = 'GET_ASSESSMENT_FAILURE';

export const GET_LAST_ASSESSMENT_RESULTS_REQUEST = 'GET_LAST_ASSESSMENT_RESULTS_REQUEST';
export const GET_LAST_ASSESSMENT_RESULTS_SUCCESS = 'GET_LAST_ASSESSMENT_RESULTS_SUCCESS';
export const GET_LAST_ASSESSMENT_RESULTS_FAILURE = 'GET_LAST_ASSESSMENT_RESULTS_FAILURE';

export const CLEAN_ASSESSMENT_DATA = 'CLEAN_ASSESSMENT_DATA';
export const CLEAN_ONE_ASSESSMENT_DATA = 'CLEAN_ONE_ASSESSMENT_DATA';

export const SET_ASSESSMENTS_PARAMS_REQUEST = 'SET_ASSESSMENTS_PARAMS_REQUEST';

export const RESET_ASSESSMENTS = 'RESET_ASSESSMENTS';

export const GET_SKILLS_QUESTIONS_LIST_REQUEST = 'GET_SKILLS_QUESTIONS_LIST_REQUEST';
export const GET_SKILLS_QUESTIONS_LIST_SUCCESS = 'GET_SKILLS_QUESTIONS_LIST_SUCCESS';
export const GET_SKILLS_QUESTIONS_LIST_FAILURE = 'GET_SKILLS_QUESTIONS_LIST_FAILURE';

export const GET_SKILLS_QUESTIONS_FILTERS_REQUEST = 'GET_SKILLS_QUESTIONS_FILTERS_REQUEST';
export const GET_SKILLS_QUESTIONS_FILTERS_SUCCESS = 'GET_SKILLS_QUESTIONS_FILTERS_SUCCESS';
export const GET_SKILLS_QUESTIONS_FILTERS_FAILURE = 'GET_SKILLS_QUESTIONS_FILTERS_FAILURE';

export const POST_SKILL_QUESTIONS_REQUEST = 'POST_SKILL_QUESTIONS_REQUEST';
export const POST_SKILL_QUESTIONS_SUCCESS = 'POST_SKILL_QUESTIONS_SUCCESS';
export const POST_SKILL_QUESTIONS_FAILURE = 'POST_SKILL_QUESTIONS_FAILURE';

export const PUT_SKILL_QUESTIONS_REQUEST = 'PUT_SKILL_QUESTIONS_REQUEST';
export const PUT_SKILL_QUESTIONS_SUCCESS = 'PUT_SKILL_QUESTIONS_SUCCESS';
export const PUT_SKILL_QUESTIONS_FAILURE = 'PUT_SKILL_QUESTIONS_FAILURE';

export const GET_SKILL_QUESTIONS_REQUEST = 'GET_SKILL_QUESTIONS_REQUEST';
export const GET_SKILL_QUESTIONS_SUCCESS = 'GET_SKILL_QUESTIONS_SUCCESS';
export const GET_SKILL_QUESTIONS_FAILURE = 'GET_SKILL_QUESTIONS_FAILURE';

export const CLEAN_SKILL_QUESTIONS_DATA = 'CLEAN_SKILL_QUESTIONS_DATA';

export const SET_SKILLS_QUESTIONS_PARAMS_REQUEST = 'SET_SKILLS_QUESTIONS_PARAMS_REQUEST';

export const RESET_COMPETENCIES_ERRORS = 'RESET_COMPETENCIES_ERRORS';

export const RESET_COMPETENCIES_STATE = 'RESET_COMPETENCIES_STATE';

export const RESET_LAST_ASSESSMENT_RESULTS = 'RESET_LAST_ASSESSMENT_RESULTS';

export const GET_COMPETENCIES_PERCENTAGE_REQUEST = 'GET_COMPETENCIES_PERCENTAGE_REQUEST';
export const GET_COMPETENCIES_PERCENTAGE_SUCCESS = 'GET_COMPETENCIES_PERCENTAGE_SUCCESS';
export const GET_COMPETENCIES_PERCENTAGE_FAILURE = 'GET_COMPETENCIES_PERCENTAGE_FAILURE';

export const GET_SPECIALIZATIONS_PERCENTAGE_REQUEST = 'GET_SPECIALIZATIONS_PERCENTAGE_REQUEST';
export const GET_SPECIALIZATIONS_PERCENTAGE_SUCCESS = 'GET_SPECIALIZATIONS_PERCENTAGE_SUCCESS';
export const GET_SPECIALIZATIONS_PERCENTAGE_FAILURE = 'GET_SPECIALIZATIONS_PERCENTAGE_FAILURE';

export const GET_USER_COMPETENCE_ASSESSMENTS_REQUEST = 'GET_USER_COMPETENCE_ASSESSMENTS_REQUEST';
export const GET_USER_COMPETENCE_ASSESSMENTS_SUCCESS = 'GET_USER_COMPETENCE_ASSESSMENTS_SUCCESS';
export const GET_USER_COMPETENCE_ASSESSMENTS_FAILURE = 'GET_USER_COMPETENCE_ASSESSMENTS_FAILURE';

export const GET_COMPETENCE_LEVELS_PERCENTAGE_REQUEST = 'GET_COMPETENCE_LEVELS_PERCENTAGE_REQUEST';
export const GET_COMPETENCE_LEVELS_PERCENTAGE_SUCCESS = 'GET_COMPETENCE_LEVELS_PERCENTAGE_SUCCESS';
export const GET_COMPETENCE_LEVELS_PERCENTAGE_FAILURE = 'GET_COMPETENCE_LEVELS_PERCENTAGE_FAILURE';

export const RESET_USER_COMPETENCE_ASSESSMENTS = 'RESET_USER_COMPETENCE_ASSESSMENTS';

export const assessmentsUnsavedParams = ['dateFrom', 'dateTo'];
