import React, { useEffect, useMemo, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import r, { getAppNavigation } from '../../constants/routes.constants';
import * as authActions from '../../actions/auth.actions';
import * as questionnariesActions from '../../actions/questionnaires.actions';
import Icon from '../Icon';
import DurationPicker from '../Dropdown';
import { useIntl } from 'react-intl';
import messages from './messages';
import Avatar from '../Profile/Avatar';
import PoliciesContext from '../../PoliciesContext';
import { publishedValue } from '../../pages/Polls/utils';

function HeaderNavigation({
  signOut,
  userInfo,
  countNotAnsweredPolls,
  getNotAnsweredPollByUser,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const policies = useContext(PoliciesContext);

  useEffect(() => {
    userInfo.id &&
      getNotAnsweredPollByUser({
        responderIds: userInfo?.id,
        isAnswered: false,
        isGeneral: true,
        isSelfUserPolls: true,
        statuses: [publishedValue],
      });
  }, [userInfo.id]);

  const profileActions = useMemo(
    () => [
      {
        wrapperClassName: 'no-icon',
        itemClassName: 'dropdown__list-item--user no-icon',
        label: (
          <>
            <div className="dropdown__list-item--user-name">{userInfo.fullName}</div>
            <div className="dropdown__list-item--user-email">{userInfo.email}</div>
          </>
        ),
      },
      {
        btnClassName: 'no-icon',
        url: `${r.users}/${userInfo.id}`,
        label: intl.formatMessage(messages.myProfileTitle),
      },
      {
        handler: signOut,
        wrapperClassName: 'no-icon',
        btnClassName: 'dropdown__list-item__button--global-color',
        label: intl.formatMessage(messages.signOutTitle),
      },
    ],
    [userInfo],
  );

  return (
    <nav className="header__nav">
      <ul className="header__nav__menu">
        {getAppNavigation(policies).map((e, i) => (
          <li key={i.toString()} className="header__nav__item">
            {/* @ts-ignore */}
            <NavLink to={e.url} className="header__nav__link" activeClassName="active">
              {/* @ts-ignore */}
              <Icon iconName={e.iconName} />
              {/* @ts-ignore */}
              {e.badges && countNotAnsweredPolls !== 0 && (
                <div className="header__polls-count">{countNotAnsweredPolls}</div>
              )}
            </NavLink>
          </li>
        ))}
        <li className="header__nav__item--account">
          <DurationPicker
            dropdownToggle={<Avatar userInfo={userInfo} size="medium" fileSize={72} />}
            dropdownToggleClass="header__nav__item-avatar"
            dropdownList={profileActions}
          />
        </li>
      </ul>
    </nav>
  );
}

const mapStateToProps = ({ auth, questionnaires }: RootState) => ({
  userInfo: auth.currentUserInfo,
  countNotAnsweredPolls: questionnaires.countNotAnsweredPolls,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signOut: () => dispatch(authActions.signOut()),
  getNotAnsweredPollByUser: (data: any) => dispatch(questionnariesActions.getNotAnsweredPollsByUser(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(HeaderNavigation);
