import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import * as questionnariesActions from '../../../actions/questionnaires.actions';
import { connect, ConnectedProps } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router';
import ModalDeletePoll from '../../../components/Polls/Modals/ModalDeletePoll';
import PollInfoContent from './PollInfoContent';
import ModalEditPoll from '../../../components/Polls/Modals/ModalEditPoll';
import * as filtersActions from '../../../actions/filters.actions';
import { isEmpty, isNil, uniqBy } from 'lodash-es';
import {
  closedValue,
  notStartedStatusValue,
  publishedValue,
  completedStatusValue,
  inProgressStatusValue,
} from '../utils';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { EventsParams } from '../../../enums/params/schedule.params';
import PoliciesContext from '../../../PoliciesContext';
import { VIEW_POLL_EXTENDED } from '../../../constants/policies.constants';
import { checkPolicies } from '../../../utils/policies.utils';
import r from '../../../constants/routes.constants';
import { UserPreviewInfo } from '../../../enums/users.enum';
import { exportPollData } from '../../../actions/export.actions';
import messages from '../messages';
import { useIntl } from 'react-intl';
import { usePrompt } from '../../../utils/hooks.utils';

const PollInfo = ({
  pollData,
  getCurrentPoll,
  loading,
  editPoll,
  resetState,
  deletePoll,
  editError,
  isLoadingEditPoll,
  categoryTableData,
  userList,
  templateList,
  eventList,
  offices,
  getEventsFilter,
  getUsersFilter,
  getOfficesFilter,
  getPollTemplatesFilter,
  getPollTemplatesCategoryFilter,
  createNewPoll,
  getAnswersByPollIdForAdmin,
  getUsersByOffices,
  userInfo,
  usersByOffices,
  updateOwnPollAnswers,
  answersByPollIdAdmin,
  updateAnswersByAdmin,
  getAnswersByPollAndUser,
  answersByPollAndUser,
  updateOwnAnswersError,
  setIsSubmitResultAnswers,
  isSavingProgress,
  onExport,
  isOpenConfirmForSubmitAnswers,
  onCloseConfirmModal,
  openConfirmModalForOwnAnswers,
  companyPositions,
  specializations,
  jiraProjectsList,
  getJiraProjectsFilter,
  getSpecializationsFilter,
  getCompanyPostitionsFilter,
  getNotAnsweredPollByUser,
  isFromMyPolls,
  setIsFromMyPolls,
}: ConnectedProps<typeof connector>) => {
  const { id, isAdmin } = useParams<any>();
  const userPolicies = useContext(PoliciesContext);

  const intl = useIntl();
  const { showPrompt, setShowPrompt } = usePrompt();

  useEffect(() => {
    if (!checkPolicies([VIEW_POLL_EXTENDED], userPolicies)) {
      history.replace(r.pollsInfo.replace(':id', id).replace(':isAdmin', 'false'));
    }
  }, [userPolicies]);

  const isAdminConverted = isAdmin === 'true';
  const history = useHistory();

  const [modalDeletePollIsOpen, setModalDeletePollIsOpen] = useState(false);
  const [modalEditPollIsOpen, setModalEditPollIsOpen] = useState(false);

  useEffect(() => {
    getCurrentPoll(id);
    getUsersFilter();
    if (isAdminConverted) {
      getEventsFilter({ questionnairesEnabled: true, dateTimeFrom: '', dateTimeTo: '' });
      getOfficesFilter();
      getPollTemplatesFilter();
      getPollTemplatesCategoryFilter();
    }
    return () => {
      resetState();
    };
  }, [id]);

  useEffect(() => {
    id && userInfo.id && getAnswersByPollAndUser({ pollId: id, userId: userInfo?.id });
  }, [userInfo.id, id]);

  useEffect(() => {
    if (!isEmpty(pollData) && isAdminConverted) getUsersByOffices(pollData?.officeIds);
  }, [pollData, pollData?.officeIds, isAdminConverted]);

  useEffect(() => {
    if (isAdminConverted && id) {
      getAnswersByPollIdForAdmin(id);
      setIsFromMyPolls(false);
    }
  }, [isAdminConverted, id]);

  useEffect(() => {
    return () => {
      setIsFromMyPolls(true);
    };
  }, []);

  const openDeletePollModal = useCallback(() => {
    setModalDeletePollIsOpen(true);
  }, []);

  const openEditPollModal = useCallback(() => {
    setModalEditPollIsOpen(true);
  }, []);

  const handleCloseModalEditPoll = useCallback(() => {
    setModalEditPollIsOpen(false);
  }, []);

  const backButtonHandler = useCallback(() => {
    history.push(isFromMyPolls ? r.myPolls : r.polls);
  }, [isFromMyPolls]);

  const closeDeletePollModal = useCallback(() => {
    setModalDeletePollIsOpen(false);
    backButtonHandler();
  }, [backButtonHandler]);

  const handleEditPoll = useCallback((data: any) => editPoll({ data, callback: backButtonHandler }), [
    backButtonHandler,
  ]);

  const getNotAnsweredPoll = useCallback(() => {
    userInfo?.id &&
      getNotAnsweredPollByUser({
        responderIds: userInfo?.id,
        isAnswered: false,
        isGeneral: true,
        isSelfUserPolls: true,
        statuses: [publishedValue],
      });
  }, [userInfo?.id]);

  const changePollStatus = useCallback(
    (status: string) => {
      const newPollData = { ...pollData, status: status, isEditFromModal: false };
      editPoll({
        data: newPollData,
        callback: () => {
          backButtonHandler();
          getNotAnsweredPoll();
        },
      });
    },
    [pollData],
  );

  const conditionGeneralPoll = pollData?.isGeneral;
  const conditionResponders = !pollData?.isGeneral && isEmpty(pollData.offices);
  const conditionOffices = !pollData?.isGeneral && isEmpty(pollData.responders);

  const allResponders = useMemo(() => {
    if (conditionGeneralPoll) return userList;
    if (conditionResponders) return pollData?.responders;
    if (conditionOffices) return usersByOffices;
    return uniqBy([...pollData?.responders, ...usersByOffices], 'id');
  }, [usersByOffices, pollData, userList]);

  const formattedAnswers = useMemo(() => {
    const users = answersByPollIdAdmin?.userAnswers?.map((answer: any) => answer?.userPreview);
    const userListUpdated = users
      ?.map((user: any) => {
        const foundUser = userList?.find((el: any) => user?.id === el?.id);
        return { ...user, ...foundUser };
      })
      .sort((a: { secondName: string }, b: { secondName: string }) => a.secondName.localeCompare(b.secondName));

    return userListUpdated?.map((user: { id: string }) => {
      const answersFilterByUser = answersByPollIdAdmin?.userAnswers?.filter(
        (answ: any) => answ?.userPreview?.id === user?.id,
      )[0];
      const answersByResponder = answersByPollIdAdmin?.userAnswers.find(
        (answ: any) => answ?.userPreview?.id === user?.id,
      );

      const pollStatus = answersByResponder ? answersByResponder.userPreview.pollStatus : notStartedStatusValue;
      const userWithUser = { ...user, pollStatus };

      const usersGroupsScores: {
        groupId: string;
        maxScores: number;
        percentage: number | undefined;
        scores: number | undefined;
      }[] = [];
      answersByPollIdAdmin.groups.map(
        (groupItem: {
          users: { userPreview: { id: string }; percentage: number; scores: number }[];
          maxScores: number;
          id: string;
        }) => {
          const currentUser = groupItem.users.find(item => item.userPreview.id === user?.id);
          usersGroupsScores.push({
            groupId: groupItem.id,
            maxScores: groupItem.maxScores,
            percentage: currentUser?.percentage,
            scores: currentUser?.scores,
          });
        },
      );
      return { user: userWithUser, answers: answersFilterByUser.answers, groupScores: usersGroupsScores };
    });
  }, [answersByPollIdAdmin, userList]);

  const officesOfResponders = useMemo(() => {
    const selectedOffices = formattedAnswers?.map((answer: any) => ({
      id: answer?.user?.office?.id,
      name: answer?.user?.office?.name,
    }));
    return uniqBy(selectedOffices, 'id');
  }, [formattedAnswers]);

  const allResponderWithPollStatus = useMemo(() => {
    return allResponders?.map((resp: any) => {
      const answersByResponder = answersByPollIdAdmin?.userAnswers?.find(
        (answ: { userPreview: { id: string } }) => answ?.userPreview?.id === resp?.id,
      );
      const pollStatus = answersByResponder ? answersByResponder.status : notStartedStatusValue;
      return { ...resp, status: pollStatus };
    });
  }, [allResponders, answersByPollIdAdmin]);

  const isPollCanNotBeEdit = useMemo(() => {
    const hasAnswers = pollData?.responders?.some((responder: UserPreviewInfo) => {
      return responder.pollStatus === completedStatusValue || responder.pollStatus === inProgressStatusValue;
    });
    return pollData?.status === closedValue || (pollData?.status === publishedValue && hasAnswers);
  }, [pollData?.status, pollData?.responders]);

  return (
    <>
      <Prompt when={showPrompt} message={intl.formatMessage(messages.leftPagePromptMessage)} />
      <PollInfoContent
        setShowPrompt={setShowPrompt}
        pollData={pollData}
        userList={formattedAnswers}
        allResponders={allResponderWithPollStatus}
        backButtonHandler={backButtonHandler}
        changePollStatus={changePollStatus}
        openDeletePollModal={openDeletePollModal}
        openEditPollModal={openEditPollModal}
        createNewPoll={createNewPoll}
        loading={loading}
        currentStatus={pollData?.status}
        questions={pollData?.template}
        offices={officesOfResponders}
        currentUser={userInfo}
        updateOwnPollAnswers={isAdminConverted ? updateAnswersByAdmin : updateOwnPollAnswers}
        answersByPollAndUser={answersByPollAndUser}
        updateOwnAnswersError={updateOwnAnswersError}
        setIsSubmitResultAnswers={setIsSubmitResultAnswers}
        isSavingProgress={isSavingProgress}
        onExport={onExport}
        isAdmin={isAdminConverted}
        pollId={id}
        hasQuestions={isNil(pollData?.template)}
        onOpenConfirmModalForOwnAnswers={openConfirmModalForOwnAnswers}
        isOpenConfirmForSubmitAnswers={isOpenConfirmForSubmitAnswers}
        onCloseConfirmModal={onCloseConfirmModal}
        companyPositions={companyPositions}
        specializations={specializations}
        jiraProjectsList={jiraProjectsList}
        getJiraProjects={getJiraProjectsFilter}
        getSpecializationsFilter={getSpecializationsFilter}
        getCompanyPostitionsFilter={getCompanyPostitionsFilter}
        userFilterList={userList}
        getNotAnsweredPoll={getNotAnsweredPoll}
        isFromMyPolls={isFromMyPolls}
        pollGroupsScores={answersByPollIdAdmin.groups}
      />
      {modalDeletePollIsOpen && (
        <ModalDeletePoll
          isOpen={modalDeletePollIsOpen}
          onCloseRequest={closeDeletePollModal}
          onDeleteRequest={deletePoll}
          templateData={pollData}
          isLoading={false}
        />
      )}
      {modalEditPollIsOpen && (
        <ModalEditPoll
          isOpen={modalEditPollIsOpen}
          categories={categoryTableData}
          onCloseRequest={handleCloseModalEditPoll}
          responders={userList}
          templates={templateList}
          error={editError}
          isLoading={isLoadingEditPoll}
          onSubmit={handleEditPoll}
          officesList={offices}
          eventList={eventList}
          poll={pollData}
          isPollCanNotBeEdit={isPollCanNotBeEdit}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ questionnaires, filters, auth }: RootState) => ({
  pollData: questionnaires.currentPollData || {},
  loading: questionnaires.loading.getCurrentPollLoading || questionnaires?.loading?.answersByPollAndUserLoading,
  editError: questionnaires.errors.editError,
  isLoadingEditPoll: questionnaires.loading.editPoll,
  categoryTableData: filters.pollTemplatesCategoryFilter.categories,
  userList: filters.usersFilter.users,
  templateList: filters.pollTemplatesFilter.templates,
  eventList: filters.eventsFilter.events,
  offices: filters.officesFilter.offices.map((office: OfficeInfo) => ({ id: office.id, name: office.name })),
  answersByPollIdAdmin: questionnaires.answersByPollIdAdmin || [],
  usersByOffices: questionnaires.usersByOffices?.content || [],
  userInfo: auth?.currentUserInfo,
  answersByPollAndUser: questionnaires?.answersByPollAndUser,
  updateOwnAnswersError: questionnaires?.errors.updateOwnAnswersError,
  isSavingProgress: questionnaires?.isSavingProgress,
  isOpenConfirmForSubmitAnswers: questionnaires.isOpenConfirmForSubmitAnswers,
  companyPositions: filters.companyPositionsFilter.positions,
  specializations: filters.specializationsFilter.specializations,
  jiraProjectsList: filters.jiraProjectFilter.jiraProject,
  isFromMyPolls: questionnaires.isFromMyPolls,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCurrentPoll: (id: string) => dispatch(questionnariesActions.getCurrentPoll(id)),
  editPoll: (data: any) => dispatch(questionnariesActions.editPoll(data)),
  deletePoll: (data: Record<string, unknown>) => dispatch(questionnariesActions.deletePoll(data)),
  resetState: () => dispatch(questionnariesActions.resetState()),
  getEventsFilter: (data: Partial<EventsParams>) => dispatch(filtersActions.getEventsFilter(data)),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getPollTemplatesFilter: () => dispatch(filtersActions.getPollTemplatesFilter()),
  getPollTemplatesCategoryFilter: () => dispatch(filtersActions.getPollTemplatesCategoryFilter()),
  createNewPoll: (data: any) => dispatch(questionnariesActions.createNewPoll(data)),
  getAnswersByPollIdForAdmin: (id: any) => dispatch(questionnariesActions.getAnswersByPollIdForAdmin(id)),
  updateAnswersByAdmin: (data: any) => dispatch(questionnariesActions.updateAnswersByAdmin(data)),
  getUsersByOffices: (officeIds: any) => dispatch(questionnariesActions.getUsersByOffices(officeIds)),
  updateOwnPollAnswers: (data: any) => dispatch(questionnariesActions.updateOwnPollAnswers(data)),
  getAnswersByPollAndUser: (data: any) => dispatch(questionnariesActions.getAnswersByPollAndUser(data)),
  setIsSubmitResultAnswers: (isResultAnswers: boolean) =>
    dispatch(questionnariesActions.setIsSubmitResultAnswers(isResultAnswers)),
  onExport: (data: any) => dispatch(exportPollData(data)),
  openConfirmModalForOwnAnswers: () => dispatch(questionnariesActions.openConfirmModalForOwnAnswers()),
  onCloseConfirmModal: () => dispatch(questionnariesActions.onCloseConfirmModal()),
  getJiraProjectsFilter: () => dispatch(filtersActions.getJiraProjectsFilter()),
  getSpecializationsFilter: () => dispatch(filtersActions.getSpecializationsFilter()),
  getCompanyPostitionsFilter: () => dispatch(filtersActions.getCompanyPositionsFilter()),
  getNotAnsweredPollByUser: (data: any) => dispatch(questionnariesActions.getNotAnsweredPollsByUser(data)),
  setIsFromMyPolls: (value: boolean) => dispatch(questionnariesActions.setIsFromMyPolls(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PollInfo);
