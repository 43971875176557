import { defineMessages } from 'react-intl';
import {
  deleteButton,
  saveButton,
  cancelButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  eventTypeLabel,
  eventTypesLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteModalWithItemNameTitle,
  nameColumn,
  cancelButton,
  saveButton,
  deleteButton,
  eventTypeLabel,
  eventTypesLabel,
  customNotificationTitle: {
    id: 'notifications.templates.modal.new.notification.template.title.custom.notification',
    defaultMessage: 'Custom Notification',
  },
  variablesTitle: {
    id: 'notifications.templates.modal.new.notification.template.title.variables',
    defaultMessage: 'Variables',
  },
  subjectTitle: {
    id: 'notifications.templates.modal.new.notification.template.label.subject',
    defaultMessage: 'Subject',
  },
  messageTitle: {
    id: 'notifications.templates.modal.new.notification.template.label.message',
    defaultMessage: 'Message',
  },
  triggerTitle: {
    id: 'notifications.templates.modal.new.notification.template.label.trigger',
    defaultMessage: 'Trigger',
  },
  note: {
    id: 'notifications.templates.modal.new.notification.template.click.to.copy.to.clipboard',
    defaultMessage: 'Click to copy to clipboard',
  },
});
