import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

type ColorInputProps = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  name?: string;
  defaultValue?: string;
  externalClass?: string;
  id: string;
};

function ColorInput({ onChange, disabled, id, defaultValue, externalClass, name }: ColorInputProps) {
  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = useCallback(
    event => {
      setValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    },
    [value],
  );

  const defaultClass = classNames('form__color-block', {
    [externalClass as string]: Boolean(externalClass),
  });

  return (
    <div className={defaultClass}>
      <input
        className="form__color-input"
        type="color"
        value={value}
        id={id}
        disabled={disabled}
        name={name}
        onChange={handleChange}
      />
      <label
        className="form__color-label"
        htmlFor={id || name}
        style={{ backgroundColor: `${value || defaultValue}` }}
      />
    </div>
  );
}

export default ColorInput;
