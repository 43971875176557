import { defineMessages } from 'react-intl';
import { nameColumn, editButton, deleteButton, newButton, currencyLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  currencyLabel,
  pageTitle: {
    id: 'finance.suppliers.title.suppliers',
    defaultMessage: 'Suppliers',
  },
});
