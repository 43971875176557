import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { DEPARTMENTS_VALIDATION_SCHEMA } from '../../../enums/libraries.enum';
import { DepartmentDataType } from '../../../pages/Departments';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { scrollToError } from '../../../utils';

type ModalEditDepartmentProps = {
  onCloseRequest: () => void;
  editDepartment: (id: string, data: any) => void;
  departmentData: DepartmentDataType;
  departmentError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalEditDepartment({
  onCloseRequest,
  editDepartment,
  departmentData,
  departmentError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalEditDepartmentProps) {
  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldError } = useFormik({
    initialValues: departmentData,
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: DEPARTMENTS_VALIDATION_SCHEMA,
    onSubmit: data => {
      return editDepartment(departmentData?.id, { data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(departmentError, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editDepartmentTitle)}
      size={'small'}
      classNameModal="center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              id={'edit-displayName'}
              name={'displayName'}
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values?.displayName || ''}
              onChange={handleChange}
              hasError={hasError('displayName')}
              errorMessage={errors?.displayName}
            />
          </div>
        </div>
        <ErrorMessage>{departmentError}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit} type={'button'}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditDepartment;
