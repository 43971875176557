import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import Comment from './Comment';
import { CommentInfo } from '../../enums/schedule.enum';
import { UserInfo } from '../../enums/users.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import InfiniteScroll from '../InfiniteScroll';
import Icon from '../Icon';
import Modal from '../Modal';
import Button from '../Button';
import CommentForm from './CommentForm';
import AccessChecker from '../AccessChecker';
import PoliciesContext from '../../PoliciesContext';

export type CommmentDropdownInfoType<CommentT> = {
  comment: CommentT;
  commentPage: number;
};

export type ManageCommentFuncType<CommentT> = {
  data: CommentT;
  replyToCommentId?: string;
  cb: () => void;
};

type CommentsProps<CommentT> = {
  userInfo: UserInfo;
  comments: (CommentT & Partial<{ replyTo: any; replyToOriginalMessage: string; isDeleted: boolean }>)[];
  title: string;
  initialComment: CommentT;
  hasMore: boolean;
  deleteLoading: boolean;
  createLoading: boolean;
  editLoading: boolean;
  loadSize: number;
  scrollId: string;
  policyToCreateComment: string;
  isReplayed?: boolean;
  showTitleIcon?: boolean;
  totalElements: number;
  getCommentsPaginate: () => void;
  createComment: (data: ManageCommentFuncType<CommentT>) => void;
  deleteComment: (data: { data: CommentT; loadSize: number; commentClickedPage: number; cb: () => void }) => void;
  editComment: (data: ManageCommentFuncType<CommentT>) => void;
};

function Comments<CommentT extends CommentInfo>({
  userInfo,
  comments,
  title,
  hasMore,
  deleteLoading,
  createLoading,
  editLoading,
  loadSize,
  scrollId,
  initialComment,
  policyToCreateComment,
  isReplayed,
  showTitleIcon = true,
  getCommentsPaginate,
  totalElements = 0,
  createComment,
  editComment,
  deleteComment,
}: CommentsProps<CommentT>) {
  const policies = useContext(PoliciesContext);
  const intl = useIntl();
  const scrollRef = useRef<any>(null);
  const formRef = useRef<any>(null);
  const [postMode, setPostMode] = useState(true);
  const [replyMode, setReplyMode] = useState(false);
  const [modalDeleteCommentIsOpen, setModalDeleteCommentIsOpen] = useState(false);
  const [commentClicked, setCommentClicked] = useState(initialComment);
  const [commentClickedPage, setCommentClickedPage] = useState(0);

  const openDeleteCommentModal = useCallback(() => {
    setModalDeleteCommentIsOpen(true);
  }, []);

  const closeDeleteCommentModal = useCallback(() => {
    setModalDeleteCommentIsOpen(false);
  }, []);

  const handleDeleteRequest = () => {
    deleteComment({
      data: commentClicked,
      loadSize,
      commentClickedPage,
      cb: () => {
        setCommentClicked(initialComment);
        closeDeleteCommentModal();
        setPostMode(true);
        const currentForm = formRef.current;
        if (currentForm) {
          currentForm.resetForm();
        }
      },
    });
  };

  const tableActions = useCallback(
    ({
      ableToEditComment,
      ableToDeleteComment,
      ableToReplyComment,
    }: {
      ableToEditComment: boolean;
      ableToDeleteComment: boolean;
      ableToReplyComment: boolean;
    }) => {
      return [
        ...(ableToReplyComment && isReplayed
          ? [
              {
                label: (
                  <>
                    <Icon iconName={'reply'} externalClass={'dropdown__list-item__icon'} />
                    <FormattedMessage {...messages.replyBtn} />
                  </>
                ),
                itemClassName: 'dropdown__list-item__button',
                handler: (row: CommmentDropdownInfoType<CommentT>) => {
                  setCommentClicked(row.comment);
                  setReplyMode(true);
                  setPostMode(true);
                  const currentForm = formRef.current;

                  if (currentForm) {
                    currentForm.setValues(initialComment);
                    currentForm.handleTextareaFocus();
                  }
                },
              },
            ]
          : []),
        ...(ableToEditComment
          ? [
              {
                label: (
                  <>
                    <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                    <FormattedMessage {...messages.editButton} />
                  </>
                ),
                itemClassName: 'dropdown__list-item__button',
                handler: (row: CommmentDropdownInfoType<CommentT>) => {
                  setCommentClicked(row.comment);
                  setPostMode(false);
                  setReplyMode(false);
                  const currentForm = formRef.current;

                  if (currentForm) {
                    currentForm.setValues(row.comment);
                    currentForm.handleTextareaFocus();
                  }
                },
              },
            ]
          : []),
        ...(ableToDeleteComment
          ? [
              {
                label: (
                  <>
                    <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
                    <FormattedMessage {...messages.deleteButton} />
                  </>
                ),
                itemClassName: 'dropdown__list-item__button',
                handler: (row: CommmentDropdownInfoType<CommentT>) => {
                  setCommentClicked(row.comment);
                  setCommentClickedPage(row.commentPage);
                  openDeleteCommentModal();
                },
              },
            ]
          : []),
      ];
    },
    [policies],
  );

  const commentsContent = useMemo(
    () => (
      <InfiniteScroll
        dataLength={comments.length}
        fetchData={getCommentsPaginate}
        hasMore={hasMore}
        scrollRef={scrollRef}
        id={scrollId}
      >
        {comments.map((comment, index: number) => (
          <Comment
            key={comment.id}
            comment={comment}
            tableActions={tableActions(comment.userAbleToManageComment(userInfo, policies))}
            commentPage={Math.floor(index / loadSize)}
          />
        ))}
      </InfiniteScroll>
    ),
    [comments, hasMore, userInfo, loadSize, getCommentsPaginate, tableActions],
  );

  return (
    <div className="page__info-block page__info-block--comments">
      <div className="page__event__info-title">
        {showTitleIcon && <Icon iconName="comments" externalClass={'page__info-block--organizers-icon'} />}
        {title} &nbsp;
        <span className="page__event__count">({totalElements})</span>
      </div>
      <AccessChecker verifiablePolicies={[policyToCreateComment]}>
        <CommentForm
          formRef={formRef}
          commentClicked={commentClicked}
          scrollRef={scrollRef}
          userInfo={userInfo}
          postMode={postMode}
          replyMode={replyMode}
          initialComment={initialComment}
          isDisabledBtn={createLoading || editLoading}
          setCommentClicked={setCommentClicked}
          setPostMode={setPostMode}
          setReplyMode={setReplyMode}
          createComment={createComment}
          editComment={editComment}
        />
      </AccessChecker>
      {comments.length > 0 ? <div className="page__event__comments-wrapper">{commentsContent}</div> : null}
      {modalDeleteCommentIsOpen && (
        <Modal
          isOpen
          title={intl.formatMessage(messages.deleteModalTitle)}
          onRequestClose={closeDeleteCommentModal}
          size={'small'}
          classNameModal="center"
        >
          <div className={'form__buttons'}>
            <Button externalClass={'button--modal'} onClick={closeDeleteCommentModal} color="gray">
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button
              externalClass={'button--modal'}
              onClick={handleDeleteRequest}
              loading={deleteLoading}
              disabled={deleteLoading}
            >
              <FormattedMessage {...messages.deleteButton} />
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default React.memo(Comments) as typeof Comments;
