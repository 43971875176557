import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/policies.constants';
import { PolicyGroupParams, PolicyModuleParams } from '../enums/params/policies.params';

export const getPolicyGroup = (): AnyAction => ({
  type: ActionTypes.GET_POLICY_GROUP_REQUEST,
});

export const getCurrentUserPolicies = (): AnyAction => ({
  type: ActionTypes.GET_CURRENT_USER_POLICIES_REQUEST,
});

export const editUserPolicies = (policyIds: string[], callback: () => void): AnyAction => ({
  type: ActionTypes.PUT_USER_POLICIES_REQUEST,
  payload: { policyIds, callback },
});

export const resetPoliciesErrors = (): AnyAction => ({
  type: ActionTypes.RESET_POLICIES_ERRORS,
});

export const getAuthUserPolicies = (): AnyAction => ({
  type: ActionTypes.GET_AUTH_USER_POLICIES_REQUEST,
});

export const getPolicyGroupWithUsers = (): AnyAction => ({
  type: ActionTypes.GET_POLICY_GROUP_WITH_USERS_REQUEST,
});

export const setPolicyGroupParams = (data: Partial<PolicyGroupParams>): AnyAction => ({
  type: ActionTypes.SET_POLICY_GROUP_PARAMS,
  payload: data,
});

export const editAllUsersPolicies = (payload: { data: any; callback: () => void }): AnyAction => ({
  type: ActionTypes.PATCH_USERS_POLICIES_REQUEST,
  payload,
});

export const setPolicyModuleParams = (data: Partial<PolicyModuleParams>): AnyAction => ({
  type: ActionTypes.SET_POLICY_MODULE_PARAMS,
  payload: data,
});

export const resetPolicyModuleParams = (): AnyAction => ({
  type: ActionTypes.RESET_POLICY_MODULE_PARAMS,
});

export const resetPolicyModule = (): AnyAction => ({
  type: ActionTypes.RESET_POLICY_MODULE_DATA,
});
