import React, { useCallback, useMemo, useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import BrandingContext from '../../BrandingContext';
import ColorContext from '../../ColorContext';

type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  isAccentText?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  externalClass?: string;
  type?: 'button' | 'submit';
  block?: boolean;
  loading?: boolean;
  color?: string;
  circle?: boolean;
  href?: string;
  icon?: boolean;
};

function Button({
  onClick,
  isAccentText,
  children,
  disabled,
  externalClass,
  type,
  block,
  loading,
  color,
  circle,
  href,
  icon,
}: ButtonProps) {
  const branding = useContext(BrandingContext);
  const textColor = useContext(ColorContext);
  const defaultClass = classNames('button', color ? `button--${color}` : false, {
    'button--block': block,
    'button--circle': circle,
    'button--with-icon': icon,
    'button--accent-text': isAccentText,
    [externalClass as string]: Boolean(externalClass),
  });

  const buttonChildren = useMemo(
    () =>
      loading ? (
        <>
          <Loader externalClass="button__loader" />
          <span className="button--loading-children">{children}</span>
        </>
      ) : (
        children
      ),
    [loading, children],
  );

  const ButtonComponent = useCallback(
    props =>
      href ? (
        <Link to={href} {...props} />
      ) : (
        <button
          style={{
            'backgroundColor': color || isAccentText ? false : branding?.globalAccents,
            '--button-active-background-color': branding?.globalAccents,
            '--button-text-color': isAccentText ? branding?.globalAccents : textColor,
          }}
          {...props}
        />
      ),
    [href, branding],
  );

  return (
    <ButtonComponent className={defaultClass} onClick={onClick} disabled={disabled} type={type}>
      {buttonChildren}
    </ButtonComponent>
  );
}

export default Button;
