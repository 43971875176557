import { CancelToken } from 'axios';
import * as reportParamsTypes from './../enums/params/reports.params';
import { DaysUsedLimitParams } from '../enums/params/schedule.params';
import { ProjectHoursParams, UserHoursParams } from '../enums/params/planning.params';
import { ExtensionParamsValue } from '../constants/export.constants';
import { UsersParams } from '../enums/params/users.params';
import { CandidateParams } from '../enums/params/candidates.params';
import { IncomesExpensesReportParams } from '../enums/params/finance.params';
import { omit } from 'lodash-es';
import { ActivityParams } from '../enums/params/activity.params';

export function exportProjectsReport(params: reportParamsTypes.ProjectsReportParams, cancelToken: CancelToken) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/projects/export/stream',
    params: { ...params, 'enabledFields.issueType': true, 'enabledFields.timeOriginalEstimate': true },
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportEmployeesReport(params: reportParamsTypes.EmployeesReportParams, cancelToken: CancelToken) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/employees/export/stream',
    params: { ...params, 'enabledFields.issueType': true, 'enabledFields.timeOriginalEstimate': true },
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportEmployeesTimesheetReport(
  params: reportParamsTypes.EmployeesTimesheetReportParams,
  cancelToken: CancelToken,
) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/timesheets/export/stream',
    params,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportEmployeesIssueTypesReport(
  params: reportParamsTypes.EmployeesIssueTypesParams,
  cancelToken: CancelToken,
) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/employeesIssueTypes/export/stream',
    params,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportActiveInactiveHoursReport(
  params: reportParamsTypes.ActiveInactiveHoursReportParams,
  cancelToken: CancelToken,
) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/activeInactiveHoursReport/export/stream',
    params,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportUsersHoursAbsencesReport(
  params: reportParamsTypes.UsersHoursAbsencesReportParams,
  cancelToken: CancelToken,
) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/usersHoursAbsencesReport/export/stream',
    params,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportAbsencePeriodsReport(params: reportParamsTypes.AbsencePeriodsParams, cancelToken: CancelToken) {
  const options: RequestConfig = {
    method: 'get',
    url: 'absence/export/stream',
    params,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export const exportPollData = (pollId: string, params: any, cancelToken: CancelToken) => {
  const options: RequestConfig = {
    method: 'get',
    url: `/questionnaires/polls/${pollId}/answers/export/stream`,
    params,
    responseType: 'blob',
    cancelToken,
  };
  return options;
};

export function exportDaysUsedLimit(params: DaysUsedLimitParams, cancelToken: CancelToken) {
  const options: RequestConfig = {
    method: 'get',
    url: 'absence/info/export/stream',
    params,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportUserHours(params: UserHoursParams, extension: ExtensionParamsValue, cancelToken: CancelToken) {
  const options: RequestConfig = {
    method: 'get',
    url: 'userHours/export/stream',
    params: {
      ...params,
      extension,
    },
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportProjectHours(
  params: ProjectHoursParams,
  extension: ExtensionParamsValue,
  cancelToken: CancelToken,
) {
  const options: RequestConfig = {
    method: 'get',
    url: 'projectHours/export/stream',
    params: {
      ...params,
      extension,
    },
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportUsers(params: UsersParams, cancelToken: CancelToken) {
  const data: Record<string, string> = {};

  params.technicalSkills.forEach((skill, index) => {
    data[`technicalSkills[${index}].skillGroupSkillId`] = skill.skillGroupSkillId;
    skill.skillLevelIds.forEach((skillLevel, skillLevelIndex) => {
      data[`technicalSkills[${index}].skillLevelIds[${skillLevelIndex}]`] = skillLevel;
    });
  });

  const options: RequestConfig = {
    method: 'get',
    url: 'users/export/stream',
    responseType: 'blob',
    cancelToken,
    params: {
      ...omit(params, 'technicalSkills'),
      ...data,
    },
  };
  return options;
}

export function exportCandidates(params: Partial<CandidateParams>, cancelToken: CancelToken) {
  const options: RequestConfig = {
    method: 'get',
    url: 'candidates/export/stream',
    params,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportIncomesExpensesReport(
  params: IncomesExpensesReportParams,
  extension: ExtensionParamsValue,
  cancelToken: CancelToken,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/incomesExpensesReports/report/stream',
    params: {
      ...params,
      extension,
    },
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function exportActivity(params: ActivityParams, cancelToken: CancelToken) {
  const options: RequestConfig = {
    method: 'get',
    url: '/activity/export/stream',
    params,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}
