import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewSkillLevel from '../../components/SkillLevels/Modals/ModalNewSkillLevel';
import ModalEditSkillLevel from '../../components/SkillLevels/Modals/ModalEditSkillLevel';
import ModalDeleteSkillLevel from '../../components/SkillLevels/Modals/ModalDeleteSkillLevel';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { checkPolicies } from '../../utils/policies.utils';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_SKILL_LEVEL, UPDATE_SKILL_LEVEL } from '../../constants/policies.constants';
import PoliciesContext from '../../PoliciesContext';
import { SkillLevelInfoType } from '../../types/libraries';

function SkillLevels({
  getSkillLevelsList,
  createNewSkillLevel,
  editSkillLevel,
  deleteSkillLevel,
  skillLevelError,
  isLoading,
  tableData,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const [modalNewSkillLevelIsOpen, setModalNewSkillLevelIsOpen] = useState(false);
  const [modalEditSkillLevelIsOpen, setModalEditSkillLevelIsOpen] = useState(false);
  const [modalDeleteSkillLevelIsOpen, setModalDeleteSkillLevelIsOpen] = useState(false);
  const [skillLevelClicked, setSkillLevelClicked] = useState<SkillLevelInfoType>({
    name: '',
    id: '',
    priority: 0,
  });

  useEffect(() => {
    getSkillLevelsList();
    return () => {
      resetState();
    };
  }, []);

  const intl = useIntl();

  const openNewSkillLevelModal = useCallback(() => {
    setModalNewSkillLevelIsOpen(true);
  }, []);

  const closeNewSkillLevelModal = useCallback(() => {
    setModalNewSkillLevelIsOpen(false);
  }, []);

  const openEditSkillLevelModal = useCallback(() => {
    setModalEditSkillLevelIsOpen(true);
  }, []);

  const closeEditSkillLevelModal = useCallback(() => {
    setModalEditSkillLevelIsOpen(false);
  }, []);

  const openDeleteSkillLevelModal = useCallback(() => {
    setModalDeleteSkillLevelIsOpen(true);
  }, []);

  const closeDeleteSkillLevelModal = useCallback(() => {
    setModalDeleteSkillLevelIsOpen(false);
  }, []);

  const setSkillLevelCallback = useCallback(() => {
    setSkillLevelClicked({
      name: '',
      id: '',
      priority: 0,
    });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: SkillLevelInfoType) => row.name,
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: SkillLevelInfoType) => {
          setSkillLevelClicked(row);
          openEditSkillLevelModal();
        },
        verifiablePolicies: [UPDATE_SKILL_LEVEL],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: SkillLevelInfoType) => {
          setSkillLevelClicked(row);
          openDeleteSkillLevelModal();
        },
        verifiablePolicies: [DELETE_SKILL_LEVEL],
      },
    ],
    [],
  );

  const submitTable = useCallback(
    (data: Record<string, unknown>) => {
      const skillLevel = tableData[data.oldIndex as number];
      skillLevel.priority = data.newIndex;

      editSkillLevel(skillLevel.id, {
        data: {
          priority: skillLevel.priority,
          name: skillLevel.name,
          id: skillLevel.id,
        },
        callback: () => null,
      });
    },
    [tableData],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper">
              <AccessChecker verifiablePolicies={[UPDATE_SKILL_LEVEL]}>
                <Button externalClass={'button--with-icon'} onClick={openNewSkillLevelModal}>
                  <Icon iconName={'plus'} externalClass={'button__icon'} />
                  <span className="button__text">
                    <FormattedMessage {...messages.newButton} />
                  </span>
                </Button>
              </AccessChecker>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table  table--half'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getSkillLevels}
            error={skillLevelError}
            tableActions={tableActions}
            onDragSort={submitTable}
            sortable={checkPolicies([UPDATE_SKILL_LEVEL], policies)}
          />
        </div>
      </div>
      {modalNewSkillLevelIsOpen && (
        <ModalNewSkillLevel
          isOpen
          onCloseRequest={closeNewSkillLevelModal}
          createNewSkillLevel={createNewSkillLevel}
          skillLevelError={skillLevelError}
          isLoading={isLoading.createSkillLevel}
          resetErrors={resetErrors}
        />
      )}
      {modalEditSkillLevelIsOpen && (
        <ModalEditSkillLevel
          isOpen
          onCloseRequest={closeEditSkillLevelModal}
          editSkillLevel={editSkillLevel}
          skillLevelData={skillLevelClicked}
          skillLevelError={skillLevelError}
          isLoading={isLoading.editSkillLevel}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteSkillLevelIsOpen && (
        <ModalDeleteSkillLevel
          isOpen
          onCloseRequest={closeDeleteSkillLevelModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteSkillLevel({
              ...data,
              setSkillLevelCallback,
            });
          }}
          isLoading={isLoading.deleteSkillLevel}
          skillLevelError={skillLevelError}
          skillLevelData={skillLevelClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries }: RootState) => ({
  tableData: libraries.skillLevelsTableData?.content,
  skillLevelError: libraries.errors.skillLevelError,
  isLoading: libraries.loading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSkillLevelsList: () => dispatch(librariesActions.getSkillLevelsList()),
  deleteSkillLevel: (data: Record<string, unknown>) => dispatch(librariesActions.deleteSkillLevel(data)),
  createNewSkillLevel: (data: Record<string, unknown>) => dispatch(librariesActions.createNewSkillLevel(data)),
  editSkillLevel: (id: string, data: Record<string, unknown>) =>
    dispatch(librariesActions.editSkillLevel({ ...data, id })),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SkillLevels);
