import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import * as recognitionActions from '../../../actions/recognitions.actions';
import RecognitionColumn from '../../Recognitions/RecognitionColumn';
import ModalNewUserRecognition from '../../Recognitions/Modals/ModalNewUserRecognition';
import ModalDeleteRecognition from '../../Recognitions/Modals/ModalDeleteRecognition';

import ModalEditUserRecognition from '../../Recognitions/Modals/ModalEditUserRecognition';

import { Recognition } from '../../../enums/recognitions.enum';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { TRouteParamId } from '../../../enums/common.enum';
import { RegonitionType } from '../../../types/recognitions/recognitions.type';
import { getRecognitionTypesFilter } from '../../../actions/filters.actions';
import { RecognitionTypeInfoType } from '../../../types/recognitions';

function ProfileRecognitions({
  getRecognitionTypesFilters,
  getRecognitionList,
  recognitionTypeList,
  recognitions,
  recognitionError,
  isLoading,
  deleteRecognition,
  createUserRecognition,
  editRecognition,
  resetErrors,
  authUserdata,
  currentUserId,
  resetState,
}: ConnectedProps<typeof connector>) {
  const { id } = useParams<TRouteParamId>();
  const [modalNewRecognitionIsOpen, setModalNewRecognitionIsOpen] = useState(false);
  const [modalDeleteRecognitionIsOpen, setModalDeleteRecognitionIsOpen] = useState(false);
  const [modalEditRecognitionIsOpen, setModalEditRecognitionIsOpen] = useState(false);
  const [recognitionTypeClicked, setRecognitionTypeClicked] = useState<RegonitionType | null>(null);
  const [recognitionClicked, setRecognitionClicked] = useState<Recognition>(new Recognition());

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const openNewRecognitionModal = useCallback(recognitionType => {
    setModalNewRecognitionIsOpen(true);
    setRecognitionTypeClicked(recognitionType);
  }, []);

  const closeNewRecognitionModal = useCallback(() => {
    setModalNewRecognitionIsOpen(false);
  }, []);

  const openDeleteRecognitionModal = useCallback(recognition => {
    setModalDeleteRecognitionIsOpen(true);
    setRecognitionClicked(recognition);
  }, []);

  const closeDeleteRecognitionModal = useCallback(() => {
    setModalDeleteRecognitionIsOpen(false);
  }, []);

  const openEditRecognitionModal = useCallback(recognition => {
    setModalEditRecognitionIsOpen(true);
    setRecognitionClicked(recognition);
  }, []);

  const closeEditRecognitionModal = useCallback(() => {
    setModalEditRecognitionIsOpen(false);
  }, []);

  const setRecognitionCallback = useCallback(() => {
    setRecognitionClicked(new Recognition());
  }, []);

  useEffect(() => {
    getRecognitionTypesFilters();
    getRecognitionList(id);
  }, [id]);

  const isNotAuthUser = useMemo(() => currentUserId !== authUserdata.id, [currentUserId, authUserdata.id]);

  const recognitionColumns = recognitionTypeList?.map((recognitionType: RecognitionTypeInfoType) => (
    <RecognitionColumn
      openNewRecognitionModal={openNewRecognitionModal}
      openDeleteRecognitionModal={openDeleteRecognitionModal}
      openEditRecognitionModal={openEditRecognitionModal}
      key={recognitionType.id}
      recognitionType={recognitionType}
      recognitions={recognitions}
      authUserdata={authUserdata}
      isNotAuthUser={isNotAuthUser}
    />
  ));

  return (
    <>
      <div className="tabs__content-item active tabs__content-item__wrapper--recognitions">
        <div className="tabs__content-item__title">
          <FormattedMessage {...messages.recognitionsTitle} />
        </div>
        <div className="recognition-columns">{recognitionColumns}</div>
      </div>
      {modalDeleteRecognitionIsOpen && (
        <ModalDeleteRecognition
          isOpen
          onCloseRequest={closeDeleteRecognitionModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteRecognition({
              ...data,
              setRecognitionCallback,
            });
          }}
          isLoading={isLoading.deleteRecognition}
          recognitionError={recognitionError}
          recognitionData={recognitionClicked}
          resetErrors={resetErrors}
        />
      )}
      {modalEditRecognitionIsOpen && (
        <ModalEditUserRecognition
          isOpen
          onCloseRequest={closeEditRecognitionModal}
          editRecognition={editRecognition}
          recognitionData={recognitionClicked}
          recognitionError={recognitionError}
          isLoading={isLoading.editRecognition}
          resetErrors={resetErrors}
        />
      )}
      {modalNewRecognitionIsOpen && (
        <ModalNewUserRecognition
          isOpen
          onCloseRequest={closeNewRecognitionModal}
          recognitionCreated={recognitionTypeClicked}
          targetUserId={id}
          createNewRecognition={createUserRecognition}
          recognitionError={recognitionError}
          isLoading={isLoading.createRecognition}
          authUserdata={authUserdata}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ recognitions, auth, filters, users }: RootState) => ({
  recognitionTypeList: filters.recognitionTypesFilter.recognitionTypes,
  recognitions: recognitions.userRecognitionTableData,
  recognitionError: recognitions.errors.recognitionError,
  isLoading: recognitions.loading,
  authUserdata: auth.currentUserInfo,
  currentUserId: users.current.total.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getRecognitionTypesFilters: () => dispatch(getRecognitionTypesFilter()),
  getRecognitionList: (id: string) => dispatch(recognitionActions.getRecognitionByUser(id)),
  deleteRecognition: (data: Record<string, unknown>) => dispatch(recognitionActions.deleteRecognition(data)),
  createUserRecognition: (data: Record<string, unknown>) => dispatch(recognitionActions.createUserRecognition(data)),
  editRecognition: (id: string, data: Record<string, unknown>) =>
    dispatch(recognitionActions.editRecognition({ ...data, id: id })),
  resetErrors: () => dispatch(recognitionActions.resetErrors()),
  resetState: () => dispatch(recognitionActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfileRecognitions);
