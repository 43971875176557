import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { getUsersFilter } from '../../actions/filters.actions';
import * as notificationsActions from '../../actions/notifications.actions';
import AccessChecker from '../../components/AccessChecker';
import Button from '../../components/Button';
import ModalDeleteEmployeeGroup from '../../components/EmployeeGroups/Modals/ModalDeleteEmployeeGroup';
import ModalEditEmployeeGroup from '../../components/EmployeeGroups/Modals/ModalEditEmployeeGroup';
import ModalNewEmployeeGroup from '../../components/EmployeeGroups/Modals/ModalNewEmployeeGroup';
import Icon from '../../components/Icon';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import TableUserAvatar from '../../components/TableUserAvatar';
import { DELETE_EMPLOYEE_GROUP, UPDATE_EMPLOYEE_GROUP } from '../../constants/policies.constants';
import { EmployeeGroup } from '../../enums/notifications.enum';
import { SortParams } from '../../enums/params.enum';
import { EmployeeGroupsParams } from '../../enums/params/notifications.params';
import messages from './messages';
import { isEmpty } from 'lodash-es';

function EmployeeGroups({
  tableData,
  isLoading,
  employeeGroupError,
  params,
  userList,
  getUsersFilter,
  setEmployeeGroupsParams,
  createNewEmployeeGroup,
  editEmployeeGroup,
  deleteEmployeeGroup,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const [modalNewEmployeeGroupIsOpen, setMoldaNewEmployeeGroupIsOpen] = useState(false);
  const [modalEditEmployeeGroupIsOpen, setMoldaEditEmployeeGroupIsOpen] = useState(false);
  const [modalDeleteEmployeeGroupIsOpen, setMoldaDeleteEmployeeGroupIsOpen] = useState(false);
  const [employeeGroupClicked, setEmployeeGroupClicked] = useState(new EmployeeGroup());

  const intl = useIntl();

  useEffect(() => {
    setEmployeeGroupsParams({ size: 10 });
    getUsersFilter();
    return () => {
      resetState();
    };
  }, []);

  const openNewEmployeeGroupModal = useCallback(() => {
    setMoldaNewEmployeeGroupIsOpen(true);
  }, []);

  const closeNewEmployeeGroupModal = useCallback(() => {
    setMoldaNewEmployeeGroupIsOpen(false);
  }, []);

  const openEditEmployeeGroupModal = useCallback(() => {
    setMoldaEditEmployeeGroupIsOpen(true);
  }, []);

  const closeEditEmployeeGroupModal = useCallback(() => {
    modalEditEmployeeGroupIsOpen && setEmployeeGroupClicked(new EmployeeGroup());
    setMoldaEditEmployeeGroupIsOpen(false);
  }, [modalEditEmployeeGroupIsOpen]);

  const openDeleteEmployeeGroupModal = useCallback(() => {
    setMoldaDeleteEmployeeGroupIsOpen(true);
  }, []);

  const closeDeleteEmployeeGroupModal = useCallback(() => {
    setMoldaDeleteEmployeeGroupIsOpen(false);
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        sortName: 'name',
        name: intl.formatMessage(messages.employeeGroupColumn),
        modifier: (row: EmployeeGroup) => row.name,
      },
      {
        name: intl.formatMessage(messages.memberLabel),
        modifier: (row: EmployeeGroup) =>
          !isEmpty(row.userIds) && (
            <TableUserAvatar fileSize={48} users={row.userIds} allUsersList={userList} selectedTableUser />
          ),
      },
    ],
    [userList],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: EmployeeGroup) => {
          setEmployeeGroupClicked(row);
          openEditEmployeeGroupModal();
        },
        verifiablePolicies: [UPDATE_EMPLOYEE_GROUP],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: EmployeeGroup) => {
          setEmployeeGroupClicked(row);
          openDeleteEmployeeGroupModal();
        },
        verifiablePolicies: [DELETE_EMPLOYEE_GROUP],
      },
    ],
    [],
  );

  const sortParams = useMemo(() => new SortParams('name', params), [params]);

  const handleSort = useCallback((sortBy, direction) => setEmployeeGroupsParams({ sortBy, direction }), []);

  const handlePageChange = useCallback(({ selected }) => {
    setEmployeeGroupsParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setEmployeeGroupsParams({ size: data, page: 0 });
  }, []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper">
              <AccessChecker verifiablePolicies={[UPDATE_EMPLOYEE_GROUP]}>
                <Button externalClass={'button--with-icon'} onClick={openNewEmployeeGroupModal}>
                  <Icon iconName={'plus'} externalClass={'button__icon'} />
                  <span className="button__text">
                    <FormattedMessage {...messages.newButton} />
                  </span>
                </Button>
              </AccessChecker>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass="table--employee-groups"
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getEmployeeGroupsList}
            error={employeeGroupError.employeeGroupsListError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
          {tableData && (
            <Pagination
              pageable={{
                ...tableData?.pageable,
                ...tableData?.sort,
                totalElements: tableData?.totalElements,
                numberOfElements: tableData?.numberOfElements,
                totalPages: tableData?.totalPages,
              }}
              onPageChange={data => handlePageChange(data)}
              onPageSizeChange={data => handleSizeChange(data)}
            />
          )}
        </div>
      </div>
      {modalNewEmployeeGroupIsOpen && (
        <ModalNewEmployeeGroup
          isOpen
          employeeGroupError={employeeGroupError.employeeGroupError}
          isLoading={isLoading.createEmployeeGroup}
          users={userList}
          createNewEmployeeGroup={createNewEmployeeGroup}
          onCloseRequest={closeNewEmployeeGroupModal}
          resetErrors={resetErrors}
        />
      )}
      {modalEditEmployeeGroupIsOpen && (
        <ModalEditEmployeeGroup
          isOpen
          employeeGroupError={employeeGroupError.employeeGroupError}
          employeeGroupData={employeeGroupClicked}
          isLoading={isLoading.editEmployeeGroup}
          users={userList}
          editEmployeeGroup={editEmployeeGroup}
          onCloseRequest={closeEditEmployeeGroupModal}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteEmployeeGroupIsOpen && (
        <ModalDeleteEmployeeGroup
          isOpen
          onCloseRequest={closeDeleteEmployeeGroupModal}
          onDeleteRequest={deleteEmployeeGroup}
          employeeGroupError={employeeGroupError.employeeGroupError}
          employeeGroupData={employeeGroupClicked}
          isLoading={isLoading.deleteEmployeeGroup}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ notifications, filters }: RootState) => ({
  tableData: notifications.employeeGroupsTable,
  isLoading: notifications.loading,
  employeeGroupError: notifications.errors,
  params: notifications.employeeGroupsParams,
  userList: filters.usersFilter.users,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUsersFilter: () => dispatch(getUsersFilter()),
  getEmployeeGroupsList: () => dispatch(notificationsActions.getEmployeeGroupsList()),
  setEmployeeGroupsParams: (data: Partial<EmployeeGroupsParams>) =>
    dispatch(notificationsActions.setEmployeeGroupsParams(data)),
  createNewEmployeeGroup: (data: any) => dispatch(notificationsActions.createNewEmployeeGroup(data)),
  editEmployeeGroup: (data: any) => dispatch(notificationsActions.editEmployeeGroup(data)),
  deleteEmployeeGroup: (data: any) => dispatch(notificationsActions.deleteEmployeeGroup(data)),
  resetErrors: () => dispatch(notificationsActions.resetErrors()),
  resetState: () => dispatch(notificationsActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EmployeeGroups);
