import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalTitle,
  addButton,
  nameColumn,
  startDateLabel,
  hourPriceLabel,
  currencyLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalTitle,
  addButton,
  nameColumn,
  startDateLabel,
  hourPriceLabel,
  currencyLabel,
  newClientTitle: {
    id: 'finance.clients.modal.new.client.title.new.client',
    defaultMessage: 'New Client',
  },
  editClientTitle: {
    id: 'finance.clients.modal.edit.client.title.edit.client',
    defaultMessage: 'Edit Client',
  },
});
