import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  employeesLabel,
  startDateLabel,
  endDateLabel,
  addButton,
  totalRow,
  memberLabel,
  projectsLabel,
  departmentsMembersLabel,
  projectLabel,
  officesLabel,
  departmentsLabel,
  projectGroupsLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  employeesLabel,
  startDateLabel,
  endDateLabel,
  saveButton,
  cancelButton,
  addButton,
  totalRow,
  memberLabel,
  projectsLabel,
  departmentsMembersLabel,
  projectLabel,
  officesLabel,
  departmentsLabel,
  projectGroupsLabel,
  denyReplaceFilledDaysLabel: {
    id: 'palnning.resources.modal.new.resourse.label.deny.replace.filled.days',
    defaultMessage: 'Deny replace filled days',
  },
  replaceFilledDaysLabel: {
    id: 'palnning.resources.modal.new.resourse.label.replace.filled.days',
    defaultMessage: 'Replace filled days',
  },
  fillEmptyFieldsLabel: {
    id: 'palnning.resources.modal.new.resourse.label.fill.empty.fields',
    defaultMessage: 'Fill empty fields',
  },
  editModalTitle: {
    id: 'palnning.resources.modal.edit.resourse.title.edit.resource',
    defaultMessage: 'Edit Resource',
  },
  newModalTitle: {
    id: 'palnning.resources.modal.new.resourse.title.new.resource',
    defaultMessage: 'New Resource',
  },
  daysLabel: {
    id: 'palnning.resources.layout.plan.workload.column.days',
    defaultMessage: 'Days',
  },
  hoursLabel: {
    id: 'palnning.resources.layout.plan.workload.column.hours',
    defaultMessage: 'Hours',
  },
  plannedByMonthLabel: {
    id: 'palnning.resources.layout.plan.workload.column.planned.by.month',
    defaultMessage: 'Planned by Month',
  },
  workedLabel: {
    id: 'palnning.resources.layout.plan.workload.column.worked',
    defaultMessage: 'Worked',
  },
  totalLabel: {
    id: 'palnning.resources.layout.plan.workload.column.total',
    defaultMessage: 'Total',
  },
  infoModalTitle: {
    id: 'palnning.resources.modal.resourse.info.title.resource',
    defaultMessage: 'Resource',
  },
  withoutFullCaseloadLabel: {
    id: 'planning.resources.label.without.full.caseload',
    defaultMessage: 'Without full caseload',
  },
});
