import { get } from 'lodash-es';
import { SortParams } from '../params.enum';

export class EventTypesParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}
