import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  iconLabel,
  removeButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  iconLabel,
  removeButton,
  newRecognitionTypeTitle: {
    id: 'recognitions.recognition.type.modal.new.recognition.type.title.new.recognition.type',
    defaultMessage: 'New Recognition Type',
  },
  editRecognitionTypeTitle: {
    id: 'recognitions.recognition.type.modal.edit.recognition.type.title.edit.recognition.type',
    defaultMessage: 'Edit  Recognition Type',
  },
});
