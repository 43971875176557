import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { UserInfo } from '../../../enums/users.enum';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { DepartmentsInfoType } from '../../../types/libraries';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { ActivityParams } from '../../../enums/params/activity.params';
import { ReportUsersStatuses } from '../../../constants/reports.constants';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import FilterClearButton from '../../FilterClearButton';
import { activityUnsavedParams } from '../../../constants/activity.constants';

type FiltersType = {
  offices: OfficeInfo[];
  users: UserInfo[];
  departments: DepartmentsInfoType[];
};

type ActivityFiltersProps = {
  filters: FiltersType;
  values: ActivityParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersStatuses: (data: OptionTypeBase | OptionTypeBase[]) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

function ActivityFilters({
  filters,
  values,
  handleMultiParamsChange,
  handleUsersParamsChange,
  handleUsersStatuses,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: ActivityFiltersProps) {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const departmentsOptions = useMemo(
    () =>
      filters.departments.map((department: DepartmentsInfoType) => ({
        label: department.displayName,
        value: department.id,
      })),
    [filters.departments],
  );

  const officesOptions = useMemo(
    () =>
      filters.offices.map((offices: OfficeInfo) => ({
        label: offices.name,
        value: offices.id,
      })),
    [filters.offices],
  );

  const usersOptions = useMemo(
    () =>
      filters.users.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [filters.users],
  );

  const usersStatusesOptions = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.activeLabel),
        value: ReportUsersStatuses.ACTIVE,
      },
      {
        label: intl.formatMessage(messages.inactiveLabel),
        value: ReportUsersStatuses.INACITVE,
      },
    ],
    [],
  );

  const departmentValues = useFiltersListValue(departmentsOptions, values.departmentIds);

  const officeValues = useFiltersListValue(officesOptions, values.offices);

  const usersValues = useFiltersListValue(usersOptions, values.portalUserIds);

  const statusesValues = useMemo(
    () =>
      usersStatusesOptions.filter(el => {
        switch (el.value) {
          case ReportUsersStatuses.ACTIVE: {
            return values.isAllowActive;
          }
          case ReportUsersStatuses.INACITVE: {
            return values.isAllowPassive;
          }
        }
      }),
    [values.isAllowActive, values.isAllowPassive],
  );

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officeValues}
        handleChange={handleMultiParamsChange(FilterParamsName.OFFICES)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentsOptions}
        value={departmentValues}
        handleChange={handleMultiParamsChange(FilterParamsName.DEPARTMENT_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.employeesLabel)}
        options={usersOptions}
        value={usersValues}
        handleChange={handleUsersParamsChange(FilterParamsName.PORTAL_USER_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.statusLabel)}
        options={usersStatusesOptions}
        defaultValue={[usersStatusesOptions[0]]}
        value={statusesValues}
        handleChange={handleUsersStatuses}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.ACTIVITY_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={activityUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
    </>
  );
}

export default React.memo(ActivityFilters);
