import React, { useMemo } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { EventTypeInfo } from '../../../enums/calendar.enum';
import { triggerOptions } from '../../../enums/notifications.enum';
import { EmailTemplatesParams } from '../../../enums/params/notifications.params';
import FilterClearButton from '../../FilterClearButton';

type FiltersType = {
  eventTypes: EventTypeInfo[];
};

type NotificationTemplatesFilterProps = {
  filters: FiltersType;
  values: EmailTemplatesParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleClear: () => void;
  showClearButton: boolean;
};

const NotificationTemplatesFilter = ({
  filters,
  values,
  handleMultiParamsChange,
  handleClear,
  showClearButton,
}: NotificationTemplatesFilterProps) => {
  const intl = useIntl();

  const eventTypesOptions = useMemo(
    () =>
      filters.eventTypes?.map((office: EventTypeInfo) => ({
        label: office.name,
        value: office.id,
      })),
    [filters.eventTypes],
  );

  const triggerValues = useFiltersListValue(
    [...triggerOptions, { value: 'NONE', label: 'System actions' }],
    values.types,
  );

  const eventTypesValues = useFiltersListValue(eventTypesOptions, values.eventTypeIds);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.triggerTitle)}
        options={[...triggerOptions, { value: 'NONE', label: 'System actions' }]}
        handleChange={e => handleMultiParamsChange('types')(e)}
        value={triggerValues}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.eventTypesLabel)}
        options={eventTypesOptions}
        value={eventTypesValues}
        handleChange={e => handleMultiParamsChange('eventTypeIds')(e)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={handleClear} />}
    </>
  );
};

export default React.memo(NotificationTemplatesFilter);
