import { defineMessages } from 'react-intl';
import {
  officesLabel,
  departmentsLabel,
  employeesLabel,
  activeLabel,
  inactiveLabel,
  statusLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  officesLabel,
  departmentsLabel,
  employeesLabel,
  activeLabel,
  inactiveLabel,
  statusLabel,
  sumSubtaskTitle: {
    id: 'reports.employees.filters.label.sum.sum.subtask',
    defaultMessage: 'Sum Subtask',
  },
  sumTaskTitle: {
    id: 'reports.employees.filters.label.sum.sum.task',
    defaultMessage: 'Sum Task',
  },
  roundTimeTitle: {
    id: 'reports.employees.filters.label.round.time.task',
    defaultMessage: 'Round Time',
  },
});
