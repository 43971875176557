import { defineMessages } from 'react-intl';

export default defineMessages({
  repeatEveryLabel: {
    id: 'schedule.event.repeater.label.repeat.every',
    defaultMessage: 'Repeat every',
  },
  repeatOnLabel: {
    id: 'schedule.event.repeater.label.repeat.on',
    defaultMessage: 'Repeat on',
  },
  endsLabel: {
    id: 'schedule.event.repeater.label.ends',
    defaultMessage: 'Ends',
  },
  onLabel: {
    id: 'schedule.event.repeater.label.on',
    defaultMessage: 'On',
  },
  afterLabel: {
    id: 'schedule.event.repeater.label.after',
    defaultMessage: 'After',
  },
  neverLabel: {
    id: 'schedule.event.repeater.label.never',
    defaultMessage: 'Never',
  },
});
