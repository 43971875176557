import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  memberLabel,
  officesLabel,
  departmentsLabel,
  employeesLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  memberLabel,
  officesLabel,
  departmentsLabel,
  employeesLabel,
  viewColumn: {
    id: 'settings.permissions.modal.edit.permissions.column.view',
    defaultMessage: 'VIEW',
  },
  updateColumn: {
    id: 'settings.permissions.modal.edit.permissions.column.update',
    defaultMessage: 'UPDATE',
  },
  deleteColumn: {
    id: 'settings.permissions.modal.edit.permissions.column.delete',
    defaultMessage: 'DELETE',
  },
});
