import React, { useMemo } from 'react';
import { UserHoursDataType, UserDataType, ProjectGroupType } from '../../../pages/UserHours/index';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { UserInfo } from '../../../enums/users.enum';
import TableUserAvatar from '../../TableUserAvatar';
import HierarchicalTable from '../../HierarchicalTable';
import { useTableData } from '../../../utils/hooks.utils';
import { getTableCell } from '../../../utils/table.utils';
import { getTime } from '../../../utils/planning.utils';
import { DepthLevels } from '../../../constants/tables.constants';

type DetailedViewLayoutType = {
  tableData: any;
  isLoading: boolean;
  userHoursDataError: string;
};

function DetailedViewLayout({ tableData, isLoading, userHoursDataError }: DetailedViewLayoutType) {
  const intl = useIntl();

  const tableColumns = useMemo(
    () => [
      {
        id: 'departmentName',
        Header: () => <span>{intl.formatMessage(messages.departmentsEmployeesProjectGroupsTitle)}</span>,
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: UserHoursDataType) => (
                <span className={'table_button--open-modal'}>{row.departmentName}</span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <TableUserAvatar users={[new UserInfo(row.user)]} fileSize={48} />,
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: ProjectGroupType) => <span>{row.projectGroup.fullName}</span>,
            },
          ]),
      },
      {
        id: 'normTitle',
        Header: intl.formatMessage(messages.normTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <span>{getTime(row.total.normative.hours)}</span>,
            },
          ]),
      },
      {
        id: 'planTitle',
        Header: intl.formatMessage(messages.planColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <span>{getTime(row.total.plan.hours)}</span>,
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: ProjectGroupType) => <span>{getTime(row.hours.planHours)}</span>,
            },
          ]),
      },
      {
        id: 'factTitle',
        Header: intl.formatMessage(messages.factColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <span>{getTime(row.total.fact.allHours)}</span>,
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: ProjectGroupType) => <span>{getTime(row.hours.factHours)}</span>,
            },
          ]),
      },
      {
        id: 'planFactTitle',
        Header: intl.formatMessage(messages.planFactTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <span>{row.total.projectFactPlan}</span>,
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: ProjectGroupType) => <span>{row.hours.planFact}</span>,
            },
          ]),
      },
    ],
    [],
  );

  return (
    <HierarchicalTable
      tableData={useTableData(tableData?.complex, ['usersData', 'projectGroups'])}
      tableHeaderClassName="user_hours_header"
      tableColumns={tableColumns}
      externalClass="fixed"
      loading={isLoading}
      error={userHoursDataError}
    />
  );
}

export default DetailedViewLayout;
