import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import HierarchicalTable from '../../components/HierarchicalTable';
import * as policiesActions from '../../actions/policies.actions';
import * as filtersActions from '../../actions/filters.actions';
import messages from './messages';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { resetParamsChange, useParamsChange, useTableData, useUsersParamsChange } from '../../utils/hooks.utils';
import PermissionsFilter from '../../components/Permissions/Filters/PermissionsFilter';
import { PolicyGroupParams, PolicyModuleParams } from '../../enums/params/policies.params';
import { useDataForTable } from './useDataForTable';
import { PolicyGroupWithUsersLevel } from '../../enums/policies.enum';
import Search from '../../components/Search';
import ModaEditUsersPermissions from '../../components/Permissions/Modals/ModaEditUsersPermissions';
import { OptionTypeBase } from 'react-select';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import { convertSavedFieldsToParams, getSavedFilterParams, getSavedFilters } from '../../utils/filters.utils';
import { OfficeInfo } from '../../enums/libraries.enum';
import { isEqual } from 'lodash-es';

function Permissions({
  tableData,
  params,
  departmentsFilter,
  officesFilter,
  usersFilter,
  errors,
  loading,
  getPolicyGroupWithUsers,
  setPolicyGroupParams,
  getDepartmentsFilter,
  getOfficesFilter,
  getUsersFilter,
  authUserId,
  editAllUsersPolicies,
  resetErrors,
  setPolicyModuleParams,
  policyModule,
  moduleParams,
  resetPolicyModuleParams,
  resetPolicyModule,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();

  useEffect(() => {
    getPolicyGroupWithUsers();
    getDepartmentsFilter();
    getOfficesFilter();
    getUsersFilter();

    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.PERMISSIONS_FILTER);

    currentSavedFilter ? setPolicyGroupParams(new PolicyGroupParams(currentSavedFilter)) : getPolicyGroupWithUsers();
    setSavedFiltersParams({ filterType: FilterTypes.PERMISSIONS_FILTER });

    return () => {
      resetPolicyModuleParams();
      resetPolicyModule();
    };
  }, []);

  const [searchValue, setSearchValue] = useState('');
  const [modalEditPermissionsIsOpen, setModalEditPermissionsIsOpen] = useState(false);
  const [submoduleClicked, setSubmoduleClicked] = useState<any>(null);

  const openEditPermissionsModal = useCallback(submodule => {
    setModalEditPermissionsIsOpen(true);
    setSubmoduleClicked(submodule);
  }, []);

  const closeEditPermissionsModal = useCallback(() => {
    setModalEditPermissionsIsOpen(false);
    resetErrors();
    resetPolicyModuleParams();
    resetPolicyModule();
  }, []);

  const filters = useMemo(
    () => ({
      departments: departmentsFilter.departments,
      offices: officesFilter.offices,
      users: usersFilter.users,
    }),
    [departmentsFilter.departments, officesFilter.offices, usersFilter.users],
  );

  const handleMultiParamsChange = useParamsChange(setPolicyGroupParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setPolicyGroupParams, dispatch);

  const { tableColumns } = useDataForTable(usersFilter.users, openEditPermissionsModal);

  const policyGroupWithOffices = useMemo(
    () =>
      tableData?.map((level: PolicyGroupWithUsersLevel) => ({
        ...level,
        modules: level.modules.map(module => ({
          ...module,
          submodules: module.submodules.map(submodule => ({
            ...submodule,
            offices: submodule.policies.some(policy => policy.isOfficeSpecificImplemented)
              ? officesFilter.offices.map((office: OfficeInfo) => ({
                  ...office,
                  policies: submodule.policies,
                  moduleId: submodule.moduleId,
                  moduleName: submodule.moduleName,
                  levelId: submodule.levelId,
                  levelName: submodule.levelName,
                  submoduleName: submodule.name,
                  submoduleId: submodule.id,
                }))
              : [],
          })),
        })),
      })),
    [tableData, officesFilter.offices],
  );

  const permissionTableData = useMemo(() => {
    return searchValue
      ? policyGroupWithOffices
          ?.map((level: PolicyGroupWithUsersLevel) => {
            if (level.name.toLowerCase().includes(searchValue.toLowerCase())) {
              return level;
            } else {
              const newPages = level.modules
                .map(module => {
                  if (module.name.toLowerCase().includes(searchValue.toLowerCase())) {
                    return module;
                  } else {
                    const newPageModules = module.submodules
                      .map(submodule => {
                        if (submodule.name.toLowerCase().includes(searchValue.toLowerCase())) {
                          return submodule;
                        }
                      })
                      .filter(item => item);

                    if (newPageModules.length) {
                      return { ...module, submodules: newPageModules };
                    }
                  }
                })
                .filter((item: any) => item);

              if (newPages.length) {
                return { ...level, modules: newPages };
              }
            }
          })
          .filter((item: PolicyGroupWithUsersLevel) => item)
      : policyGroupWithOffices;
  }, [searchValue, policyGroupWithOffices]);

  const handleModuleParamsChange = useCallback(
    name => (data: OptionTypeBase) => {
      setPolicyModuleParams({
        [name]: data.map((item: any) => (name === FilterParamsName.USER_IDS ? item.value.id : item.value)),
      });
    },
    [],
  );

  const handleFiltersControlChange = useCallback(value => {
    setPolicyGroupParams(new PolicyGroupParams(convertSavedFieldsToParams(value.fields)));
  }, []);

  const handleClear = useCallback(() => {
    setPolicyGroupParams(new PolicyGroupParams());
    resetParamsChange(
      [FilterParamsName.USER_IDS, FilterParamsName.DEPARTMENT_IDS, FilterParamsName.OFFICE_IDS],
      dispatch,
    );
  }, []);

  const showClearButton = useMemo(() => !isEqual(params, new PolicyGroupParams()), [params]);

  return (
    <>
      <div className="page__panel page__panel--fixed employees-report-page">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <Search onChange={event => setSearchValue(event.target.value)} placeholder={'Search'} />
                <PermissionsFilter
                  filters={filters}
                  values={params}
                  handleUsersParamsChange={handleUsersParamsChange}
                  handleChangeFilterParams={handleMultiParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <HierarchicalTable
            loading={loading.policyGroupWithUsers}
            error={errors.policyGroupWithUsers}
            externalClass={'table--bordered-top permissions-users-table fixed'}
            tableData={useTableData(permissionTableData, ['modules', 'submodules', 'offices'])}
            tableColumns={tableColumns}
            hasUserTooltop
          />
        </div>
      </div>
      {modalEditPermissionsIsOpen && (
        <ModaEditUsersPermissions
          isOpen
          userList={usersFilter.users}
          authUserId={authUserId}
          onClose={closeEditPermissionsModal}
          error={errors.updateAllUsersPolicies}
          loading={loading.updateAllUsersPolicies}
          currentSubmodule={submoduleClicked}
          tableError={errors.policyModule}
          tableLoading={loading.policyModule}
          editAllUsersPolicies={editAllUsersPolicies}
          setPolicyModuleParams={setPolicyModuleParams}
          submoduleData={policyModule}
          handleChangeFilterParams={handleModuleParamsChange}
          params={moduleParams}
          departments={departmentsFilter.departments}
          offices={officesFilter.offices}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ filters, policies, auth }: RootState) => ({
  tableData: policies.policyGroupWithUsers,
  errors: policies.errors,
  loading: policies.loading,
  usersFilter: filters.usersFilter,
  officesFilter: filters.officesFilter,
  departmentsFilter: filters.departmentsFilter,
  params: policies.policyGroupParams,
  authUserId: auth.currentUserInfo.id,
  moduleParams: policies.policyModuleParams,
  policyModule: policies.policyModule,
  savedFiltersData: filters.savedFilters,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPolicyGroupWithUsers: () => dispatch(policiesActions.getPolicyGroupWithUsers()),
  setPolicyGroupParams: (data: Partial<PolicyGroupParams>) => dispatch(policiesActions.setPolicyGroupParams(data)),
  editAllUsersPolicies: (payload: { data: any; callback: () => void }) =>
    dispatch(policiesActions.editAllUsersPolicies(payload)),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  resetErrors: () => dispatch(policiesActions.resetPoliciesErrors()),
  setPolicyModuleParams: (data: Partial<PolicyModuleParams>) => dispatch(policiesActions.setPolicyModuleParams(data)),
  resetPolicyModuleParams: () => dispatch(policiesActions.resetPolicyModuleParams()),
  resetPolicyModule: () => dispatch(policiesActions.resetPolicyModule()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Permissions);
