import * as paramsTypes from './../enums/params/reports.params';
import request from '../utils/request.utils';

export function getEmployeesReport(params: paramsTypes.EmployeesReportParams) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/employees',
    params,
  };
  return request(options);
}

export function employeesTimesheetReport(params: paramsTypes.EmployeesTimesheetReportParams) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/timesheets',
    params,
  };
  return request(options);
}

export function getProjectsReport(params: paramsTypes.ProjectsReportParams) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/projects',
    params,
  };
  return request(options);
}

export function getEmployeesIssueTypes(params: paramsTypes.EmployeesIssueTypesParams) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/employeesIssueTypes',
    params,
  };
  return request(options);
}

export function getUsersHoursAbsencesReport(params: paramsTypes.UsersHoursAbsencesReportParams) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/usersHoursAbsencesReport',
    params,
  };
  return request(options);
}

export function getAbsencePeriods(params?: paramsTypes.AbsencePeriodsParams) {
  const options: RequestConfig = {
    method: 'get',
    url: 'absence/period',
    params,
  };
  return request(options);
}

export function getActiveInactiveHoursReport(params?: paramsTypes.ActiveInactiveHoursReportParams) {
  const options: RequestConfig = {
    method: 'get',
    url: 'reports/activeInactiveHoursReport',
    params,
  };
  return request(options);
}
