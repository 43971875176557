import { defineMessages } from 'react-intl';
import { newButton, eventTypeLabel, deleteButton, editButton } from '../../i18n/defaultMessage';

export default defineMessages({
  newButton,
  eventTypeLabel,
  deleteButton,
  editButton,
  pageTitle: {
    id: 'notification.templates.title.notifications.templates',
    defaultMessage: 'Notification Templates',
  },
  triggerColumn: {
    id: 'notification.templates.column.trigger',
    defaultMessage: 'Trigger',
  },
  subjectColumn: {
    id: 'notification.templates.column.subject',
    defaultMessage: 'Subject',
  },
  defaultTemplateColumn: {
    id: 'notification.templates.label.default.template',
    defaultMessage: 'Default template',
  },
});
