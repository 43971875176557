import * as paramsTypes from './../enums/params/questionnaires.params';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/questionnaires.constants';
import * as api from '../api/questionnaires.api';
import { AnyAction } from 'redux';
import { getQueryParams } from '../utils/params.utils';
import { isEmpty, isNil, uniqBy } from 'lodash-es';
import { allOfficesItem, allRespondersItem } from '../components/Polls/utils';
import { NewPoll } from '../enums/questionnaires.enum';
import { closedValue, inProgressStatusValue, notStartedStatusValue } from '../pages/Polls/utils';

function* getPollTemplatesCategoryList() {
  const params: paramsTypes.PollTemplatesParams = yield select(
    (state: RootState) => state.questionnaires.pollTemplatesCategoryParams,
  );
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getPollTemplatesCategoryList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_POLL_TEMPLATES_CATEGORY_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_POLL_TEMPLATES_CATEGORY_LIST_FAILURE, payload: error });
  }
}

function* createNewPollTemplatesCategory({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewPollTemplateCategory, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_POLL_TEMPLATES_CATEGORY_SUCCESS });
      payload.callback();
      yield call(getPollTemplatesCategoryList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_POLL_TEMPLATES_CATEGORY_FAILURE, payload: error });
  }
}

function* deletePollTemplatesCategory({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deletePollTemplateCategory, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_POLL_TEMPLATES_CATEGORY_SUCCESS });
      payload.callback();
      yield call(getPollTemplatesCategoryList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_POLL_TEMPLATES_CATEGORY_FAILURE, payload: error });
  }
}

function* editPollTemplatesCategory({ payload }: AnyAction) {
  try {
    if (payload.data.icon instanceof File) {
      payload.data.icon = '';
    }
    const { success } = yield call(api.editPollTemplateCategory, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_POLL_TEMPLATES_CATEGORY_SUCCESS });
      payload.callback();
      yield call(getPollTemplatesCategoryList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_POLL_TEMPLATES_CATEGORY_FAILURE, payload: error });
  }
}

function* getPollTemplatesList() {
  const params: paramsTypes.PollTemplatesParams = yield select(
    (state: RootState) => state.questionnaires.pollTemplatesParams,
  );
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getPollTemplatesList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_POLL_TEMPLATES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_POLL_TEMPLATES_LIST_FAILURE, payload: error });
  }
}

function* createNewPollTemplate({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewPollTemplate, payload.data);
    if (success) {
      yield put({ type: ActionTypes.POST_POLL_TEMPLATE_SUCCESS });
      payload.callback && payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_POLL_TEMPLATE_FAILURE, payload: error });
  }
}

function* deletePollTemplate({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deletePollTemplate, payload.data);
    if (success) {
      yield put({ type: ActionTypes.DELETE_POLL_TEMPLATE_SUCCESS });
      payload.callback();
      yield call(getPollTemplatesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_POLL_TEMPLATE_FAILURE, payload: error });
  }
}

function* editPollTemplate({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editPollTemplate, payload.data);
    if (success) {
      yield put({ type: ActionTypes.PUT_POLL_TEMPLATE_SUCCESS });
      yield put({ type: ActionTypes.SET_CURRENT_TEMPLATE, payload: null });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_POLL_TEMPLATE_FAILURE, payload: error });
  }
}

function* copyTemplate({ payload }: AnyAction) {
  const params: paramsTypes.PollTemplatesParams = yield select(
    (state: RootState) => state.questionnaires.pollTemplatesParams,
  );
  const data = getQueryParams(params);
  try {
    const { success, results } = yield call(api.getPollTemplatesList, data);
    if (success) {
      const copyTemplate = results.content.find((item: any) => {
        if (item.id === payload.data.id) return item;
      });

      copyTemplate.name += ' copy';
      yield put({ type: ActionTypes.POST_POLL_TEMPLATE_REQUEST, payload: { data: copyTemplate } });
      yield put({ type: ActionTypes.COPY_TEMPLATE_SUCCESS, payload: null });
      yield call(getPollTemplatesCategoryList);
      yield call(getPollTemplatesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.COPY_TEMPLATE_FAILURE, payload: error });
  }
}

function* moveTemplate({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.moveTemplate, payload.data);
    if (success) {
      yield put({ type: ActionTypes.CHANGE_TEMPLATE_CATEGORY_SUCCESS });
      yield call(getPollTemplatesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.CHANGE_TEMPLATE_CATEGORY_FAILURE, payload: error });
  }
}

function* getCurrentPollTemplate({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getCurrentPollTemplate, payload);
    if (success) {
      yield put({
        type: ActionTypes.GET_CURRENT_POLL_TEMPLATE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CURRENT_POLL_TEMPLATE_FAILURE, payload: error });
  }
}

function* getPollsList() {
  const params: paramsTypes.PollParams = yield select((state: RootState) => state.questionnaires.pollParams);
  let data = getQueryParams(params);
  const hasAllOffices = params?.officeIds?.find((officeId: any) => officeId === allOfficesItem.value);
  const hasAllResponders = params?.responderIds?.find((responderId: any) => responderId === allRespondersItem.value);
  if (hasAllOffices) data = { ...data, isGeneral: true, officeIds: [] };
  if (hasAllResponders) data = { ...data, isGeneral: true, responderIds: [] };
  if (isNil(hasAllResponders) && isNil(hasAllOffices)) data = { ...data, isGeneral: false };

  try {
    const { success, results } = yield call(api.getPollsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_POLLS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_POLLS_LIST_FAILURE, payload: error });
  }
}

function* getMyPollsList() {
  const params: paramsTypes.MyPollsParams = yield select((state: RootState) => state.questionnaires.myPollsParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getMyPollsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_MY_POLLS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_MY_POLLS_LIST_FAILURE, payload: error });
  }
}

function* createNewPoll({ payload }: AnyAction) {
  const isNewPoll = payload.isNewPoll;
  const copyData = { ...payload.data, name: `${payload.data.name} copy` };
  const payloadData = isNewPoll ? payload.data : copyData;
  try {
    const { success } = yield call(api.createNewPoll, payloadData);
    if (success) {
      yield put({
        type: ActionTypes.POST_NEW_POLL_SUCCESS,
      });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_NEW_POLL_FAILURE, payload: error });
  }
}

function* editPoll({ payload }: AnyAction) {
  try {
    if (payload?.data?.isEditFromModal) delete payload?.data?.status;
    const { success } = yield call(api.editPoll, payload?.data);
    if (success) {
      yield put({
        type: ActionTypes.PATCH_POLL_SUCCESS,
      });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PATCH_POLL_FAILURE, payload: error });
  }
}

function* deletePoll({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deletePoll, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_POLL_SUCCESS,
      });
      payload.callback();
      yield call(getPollTemplatesCategoryList);
      yield call(getPollTemplatesList);
      yield call(getPollsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_POLL_FAILURE, payload: error });
  }
}

function* getCurrentPoll({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getCurrentPoll, payload);
    if (success) {
      yield put({
        type: ActionTypes.GET_CURRENT_POLL_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CURRENT_POLL_FAILURE, payload: error });
  }
}

function* getAnswersByPollId({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getAnswersByPollIdForAdmin, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_ANSWERS_BY_POLL_ID_ADMIN_SUCCESS,
        payload: { results, id: payload },
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ANSWERS_BY_POLL_ID_ADMIN_FAILURE, payload: error });
  }
}

function* updateAnswersByAdmin({ payload }: AnyAction) {
  try {
    const correctData = { ...payload, data: uniqBy(Object.values(payload?.data), 'id') };
    const { success } = yield call(api.updateAnswersByAdmin, correctData);

    if (success) {
      yield put({ type: ActionTypes.PUT_ANSWERS_ADMIN_SUCCESS });
      //@ts-ignore
      yield call(getAnswersByPollId, { payload: payload.pollId });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_ANSWERS_ADMIN_FAILURE, payload: error });
  }
}

function* getUsersByOffices({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getUsersByOfficeIds, payload);
    if (success) {
      yield put({ type: ActionTypes.GET_USERS_BY_OFFICES_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USERS_BY_OFFICES_FAILURE, payload: error });
  }
}

function* updateOwnPollAnswers({ payload }: AnyAction) {
  try {
    const payloadData = Object.values(payload?.data);
    const savingProgressData = {
      ...payload,
      data: payloadData
        ?.filter((item: any) => !isEmpty(item?.pollAnswerOptions) || item?.comment)
        .map((el: any) => {
          if (el.templateFieldType === 'NUMBER' && !el.pollAnswerOptions[0].value) {
            el.pollAnswerOptions[0].value = 0;
          }
          return el;
        }),
    };
    const submittedData = { ...payload, data: payloadData };
    const correctData = payload?.isIntermediateAnswers ? savingProgressData : submittedData;

    const { success, results } = yield call(api.updateOwnPollAnswers, correctData);
    if (success) {
      yield put({ type: ActionTypes.UPDATE_OWN_POLL_ANSWERS_SUCCESS, payload: results });
      payload.cb && payload.cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_OWN_POLL_ANSWERS_FAILURE, payload: error });
  }
}

function* getAnswersByPollAndUser({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getAnswersByPollAndUser, payload);
    if (success) {
      yield put({ type: ActionTypes.GET_ANSWERS_BY_POLL_AND_USER_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ANSWERS_BY_POLL_AND_USER_FAILURE, payload: error });
  }
}

function* getNotAnsweredPollsByUser({ payload }: AnyAction): any {
  try {
    const userId = yield select((state: RootState) => state.auth.currentUserInfo.id);

    const { success, results } = yield call(api.getNotAnsweredPollsByUser, payload);
    if (success) {
      const notAnsweredPolls: any[] = results.content.filter((poll: NewPoll) => {
        if (poll?.status === closedValue) return false;
        if (!poll?.responders?.length) return true;
        const curenntResponders = poll?.responders?.find((resp: any) => resp?.id === userId)?.pollStatus;
        return (
          !curenntResponders ||
          curenntResponders === inProgressStatusValue ||
          curenntResponders === notStartedStatusValue
        );
      });

      yield put({ type: ActionTypes.GET_NOT_ANSWERED_POLLS_SUCCESS, payload: notAnsweredPolls.length });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_NOT_ANSWERED_POLLS_FAILURE, payload: error });
  }
}

export default function* questionnairesSaga() {
  yield all([
    takeEvery(
      [ActionTypes.GET_POLL_TEMPLATES_CATEGORY_LIST_REQUEST, ActionTypes.SET_POLL_TEMPLATES_CATEGORY_PARAMS_REQUEST],
      getPollTemplatesCategoryList,
    ),
    takeEvery(ActionTypes.POST_POLL_TEMPLATES_CATEGORY_REQUEST, createNewPollTemplatesCategory),
    takeEvery(ActionTypes.DELETE_POLL_TEMPLATES_CATEGORY_REQUEST, deletePollTemplatesCategory),
    takeEvery(ActionTypes.PUT_POLL_TEMPLATES_CATEGORY_REQUEST, editPollTemplatesCategory),
    takeEvery(ActionTypes.GET_POLL_TEMPLATES_LIST_REQUEST, getPollTemplatesList),
    takeEvery(ActionTypes.SET_POLL_TEMPLATES_PARAMS_REQUEST, getPollTemplatesList),
    takeEvery(ActionTypes.POST_POLL_TEMPLATE_REQUEST, createNewPollTemplate),
    takeEvery(ActionTypes.DELETE_POLL_TEMPLATE_REQUEST, deletePollTemplate),
    takeEvery(ActionTypes.PUT_POLL_TEMPLATE_REQUEST, editPollTemplate),
    takeEvery(ActionTypes.COPY_TEMPLATE_REQUEST, copyTemplate),
    takeEvery(ActionTypes.CHANGE_TEMPLATE_CATEGORY_REQUEST, moveTemplate),
    takeEvery(ActionTypes.GET_CURRENT_POLL_TEMPLATE_REQUEST, getCurrentPollTemplate),
    takeEvery(ActionTypes.SET_POLL_PARAMS, getPollsList),
    takeEvery(ActionTypes.GET_POLLS_LIST_REQUEST, getPollsList),
    takeEvery(ActionTypes.POST_NEW_POLL_REQUEST, createNewPoll),
    takeEvery(ActionTypes.PATCH_POLL_REQUEST, editPoll),
    takeEvery(ActionTypes.DELETE_POLL_REQUEST, deletePoll),
    takeEvery(ActionTypes.GET_CURRENT_POLL_REQUEST, getCurrentPoll),
    takeEvery(ActionTypes.GET_ANSWERS_BY_POLL_ID_ADMIN_REQUEST, getAnswersByPollId),
    takeEvery(ActionTypes.PUT_ANSWERS_ADMIN_REQUEST, updateAnswersByAdmin),
    takeEvery(ActionTypes.GET_USERS_BY_OFFICES_REQUEST, getUsersByOffices),
    takeEvery(ActionTypes.UPDATE_OWN_POLL_ANSWERS_REQUEST, updateOwnPollAnswers),
    takeEvery(ActionTypes.GET_ANSWERS_BY_POLL_AND_USER_REQUEST, getAnswersByPollAndUser),
    takeEvery(ActionTypes.GET_NOT_ANSWERED_POLLS_REQUEST, getNotAnsweredPollsByUser),
    takeEvery(ActionTypes.GET_MY_POLLS_LIST_REQUEST, getMyPollsList),
    takeEvery(ActionTypes.SET_MY_POLLS_PARAMS, getMyPollsList),
  ]);
}
