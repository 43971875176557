export const GET_EMPLOYEES_REPORT_REQUEST = 'GET_EMPLOYEES_REPORT_REQUEST';
export const GET_EMPLOYEES_REPORT_SUCCESS = 'GET_EMPLOYEES_REPORT_SUCCESS';
export const GET_EMPLOYEES_REPORT_FAILURE = 'GET_EMPLOYEES_REPORT_FAILURE';

export const SET_EMPLOYEES_REPORT_PARAMS_REQUEST = 'SET_EMPLOYEES_REPORT_PARAMS_REQUEST';

export const SET_EMPLOYEES_TIMESHEET_REPORT_PARAMS = 'SET_EMPLOYEES_TIMESHEET_REPORT_PARAMS';

export const GET_EMPLOYEES_TIMESHEET_REPORT_REQUEST = 'GET_EMPLOYEES_TIMESHEET_REPORT_REQUEST';
export const GET_EMPLOYEES_TIMESHEET_REPORT_SUCCESS = 'GET_EMPLOYEES_TIMESHEET_REPORT_SUCCESS';
export const GET_EMPLOYEES_TIMESHEET_REPORT_FAILURE = 'GET_EMPLOYEES_TIMESHEET_REPORT_FAILURE';

export const GET_PROJECTS_REPORT_REQUEST = 'GET_PROJECTS_REPORT_REQUEST';
export const GET_PROJECTS_REPORT_SUCCESS = 'GET_PROJECTS_REPORT_SUCCESS';
export const GET_PROJECTS_REPORT_FAILURE = 'GET_PROJECTS_REPORT_FAILURE';

export const SET_PROJECTS_REPORT_PARAMS_REQUEST = 'SET_PROJECTS_REPORT_PARAMS_REQUEST';

export const SET_REPORTS_USERS_STATUSES_REQUEST = 'SET_REPORTS_USERS_STATUSES_REQUEST';

export const SET_ROUNDING_MODE_REQUEST = 'SET_ROUNDING_MODE_REQUEST';

export const SET_FOLD_SUBTASK_REQUEST = 'SET_FOLD_SUBTASK_REQUEST';

export enum ProjectsGroupBy {
  GROUP_BY_COMPONENTS = 'GROUP_BY_COMPONENTS',
  GROUP_BY_MEMBERS = 'GROUP_BY_MEMBERS',
}

export enum ReportsRoundingMode {
  HALF_UP = 'HALF_UP',
  UP = 'UP',
  DOWN = 'DOWN',
}

export const roundingModeOptions = [
  {
    label: 'No rounding',
    value: null,
  },
  {
    label: 'Round half up',
    value: ReportsRoundingMode.HALF_UP,
  },
  {
    label: 'Round up',
    value: ReportsRoundingMode.UP,
  },
  {
    label: 'Round down',
    value: ReportsRoundingMode.DOWN,
  },
];

export enum ReportUsersStatuses {
  ACTIVE = 'ACTIVE',
  INACITVE = 'INACITVE',
  EXTERNAL = 'EXTERNAL',
}

export const GET_EMPLOYEES_ISSUE_TYPES_SUCCESS = 'GET_EMPLOYEES_ISSUE_TYPES_SUCCESS';
export const GET_EMPLOYEES_ISSUE_TYPES_FAILURE = 'GET_EMPLOYEES_ISSUE_TYPES_FAILURE';

export const SET_EMPLOYEES_ISSUE_TYPES_PARAMS_REQUEST = 'SET_EMPLOYEES_ISSUE_TYPES_PARAMS_REQUEST';

export const GET_USERS_HOURS_ABSENCES_REPORT_SUCCESS = 'GET_USERS_HOURS_ABSENCES_REPORT_SUCCESS';
export const GET_USERS_HOURS_ABSENCES_REPORT_FAILURE = 'GET_USERS_HOURS_ABSENCES_REPORT_FAILURE';

export const SET_USERS_HOURS_ABSENCES_REPORT_PARAMS_REQUEST = 'SET_USERS_HOURS_ABSENCES_REPORT_PARAMS_REQUEST';

export const SET_EMPLOYEES_REPORT_PARAMS_WITHOUT_LOADING_REQUEST =
  'SET_EMPLOYEES_REPORT_PARAMS_WITHOUT_LOADING_REQUEST';

export const GET_ABSENCE_PERIODS_SUCCESS = 'GET_ABSENCE_PERIODS_SUCCESS';
export const GET_ABSENCE_PERIODS_FAILURE = 'GET_ABSENCE_PERIODS_FAILURE';

export const SET_ABSENCE_PERIODS_PARAMS_REQUEST = 'SET_ABSENCE_PERIODS_PARAMS_REQUEST';

export const GET_ACTIVE_INACTIVE_HOURS_REPORT_SUCCESS = 'GET_ACTIVE_INACTIVE_HOURS_REPORT_SUCCESS';
export const GET_ACTIVE_INACTIVE_HOURS_REPORT_FAILURE = 'GET_ACTIVE_INACTIVE_HOURS_REPORT_FAILURE';

export const SET_ACTIVE_INACTIVE_HOURS_REPORT_PARAMS_REQUEST = 'SET_ACTIVE_INACTIVE_HOURS_REPORT_PARAMS_REQUEST';

export const absencePeriodsUnsavedParams = ['dateTimeFrom', 'dateTimeTo'];
export const activeInactiveHoursUnsavedParams = ['dateFrom', 'dateTo'];
export const employeesIssueTypesUnsavedParams = ['dateFrom', 'dateTo'];
export const employeesReportUnsavedParams = ['dateFrom', 'dateTo'];
export const employeesTimesheetUnsavedParams = ['dateFrom', 'dateTo'];
export const projectsReportUnsavedParams = ['dateFrom', 'dateTo'];
export const usersHoursAbsencesUnsavedParams = ['dateFrom', 'dateTo'];
