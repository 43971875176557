import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import { NavLink } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import {
  PollTemplateGroupFieldType,
  PollTemplateGroupType,
  POLL_TEMPLATES_VALIDATION_SCHEMA,
} from '../../../enums/questionnaires.enum';
import Input from '../../Input';
import Icon from '../../Icon';
import Select from '../../Select';
import Button from '../../Button';
import { PollTemplate } from '../../../enums/questionnaires.enum';
import {
  DATE_PICKER,
  PollTemplateFieldTypeOptions,
  PollTemplateListTypeOptions,
  SELECT_LIST_MULTIPLE_CHOICE,
  SELECT_LIST_SINGLE_CHOICE,
  SourcesInformationOptions,
  TemplateDataSource,
  TEXT,
} from '../../../constants/questionnaires.constants';
import Checkbox from '../../Checkbox';
import ModalCustomAnswers from '../Modals/CustomAnswers';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import r from '../../../constants/routes.constants';
import { isEmpty } from 'lodash-es';
import { PollTemplateCategoryInfoType } from '../../../types/questionnaires';
import { scrollToError } from '../../../utils';

type EditPollTemplateFormProps = {
  categories: PollTemplateCategoryInfoType[];
  isLoading: boolean;
  onSubmit: (data: any) => void;
  currentTemplate: PollTemplate;
  requestErrors: string | RejectValueErrors[] | null;
};

function EditPollTemplateForm({
  categories,
  isLoading,
  onSubmit,
  currentTemplate,
  requestErrors,
}: EditPollTemplateFormProps) {
  const intl = useIntl();

  const categoryOptions = useMemo(
    () =>
      categories?.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [categories],
  );

  const [modalCustomAnswersIsOpen, setModalCustomAnswersIsOpen] = useState(false);
  const [currentAnswerInfo, setCurrentAnswerInfo] = useState<{ options: any; isMiltipleSelect: boolean }>({
    options: null,
    isMiltipleSelect: false,
  });

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit, setValues, setFieldError } = useFormik({
    initialValues: new PollTemplate(currentTemplate),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: POLL_TEMPLATES_VALIDATION_SCHEMA,
    onSubmit: data => {
      onSubmit(POLL_TEMPLATES_VALIDATION_SCHEMA.cast(data));
    },
  });

  useSetFieldsErrors(requestErrors, setFieldError);

  const categoryValue = useMemo(
    () =>
      values.categoryId ? categoryOptions?.find(({ value }: { value: string }) => value === values.categoryId) : null,
    [categoryOptions, values.categoryId],
  );

  useEffect(() => {
    if (currentTemplate) {
      setValues(currentTemplate);
    }
  }, [currentTemplate]);

  const addNewGroup = useCallback(() => {
    setFieldValue('groups', [
      ...values.groups,
      {
        id: '',
        name: '',
        position: values.groups.length,
        fields: [
          {
            commentingAllowed: true,
            dataSource: null,
            id: '',
            options: [],
            position: 0,
            question: '',
            required: true,
            type: DATE_PICKER,
          },
        ],
      },
    ]);
  }, [values]);

  const addNewField = useCallback(
    (index: number) => {
      const groups = values.groups[index];
      setFieldValue(`groups[${index}].fields`, [
        ...groups.fields,
        {
          commentingAllowed: true,
          dataSource: null,
          id: '',
          options: [],
          position: values.groups[index].fields.length,
          question: '',
          required: true,
          type: DATE_PICKER,
        },
      ]);
    },
    [values],
  );
  const addNewNote = useCallback(
    (index: number) => {
      const groups = values.groups[index];
      setFieldValue(`groups[${index}].fields`, [
        ...groups.fields,
        {
          commentingAllowed: null,
          id: '',
          position: values.groups[index].fields.length,
          question: '',
          required: null,
          type: 'NOTE',
        },
      ]);
    },
    [values],
  );

  const setPollCategory = useCallback(
    (type: any) => {
      setFieldValue('categoryId', type.value);
    },
    [values],
  );

  const setAnswerType = useCallback(
    (type: any, index: number, fieldIndex: number) => {
      if (type.value === SELECT_LIST_SINGLE_CHOICE || type.value === SELECT_LIST_MULTIPLE_CHOICE) {
        setFieldValue(`groups[${index}].fields[${fieldIndex}].dataSource`, TemplateDataSource.DATABASE);
        setFieldValue(`groups[${index}].fields[${fieldIndex}].templateDatabaseDataSource`, null);
      } else {
        setFieldValue(`groups[${index}].fields[${fieldIndex}].templateDatabaseDataSource`, null);
        setFieldValue(`groups[${index}].fields[${fieldIndex}].dataSource`, null);
        setFieldValue(`groups[${index}].fields[${fieldIndex}].options`, []);
      }
      if (type.value === TEXT) {
        setFieldValue(`groups[${index}].fields[${fieldIndex}].commentingAllowed`, false);
      } else {
        setFieldValue(`groups[${index}].fields[${fieldIndex}].commentingAllowed`, true);
      }
      setFieldValue(`groups[${index}].fields[${fieldIndex}].type`, type.value);
    },
    [values],
  );

  const setDataSourcesType = useCallback(
    (item: any, index: number, fieldIndex: number) => {
      if (item.value === TemplateDataSource.CUSTOM_LIST) {
        setFieldValue(`groups[${index}].fields[${fieldIndex}].templateDatabaseDataSource`, null);
      }
      setFieldValue(`groups[${index}].fields[${fieldIndex}].dataSource`, item.value);
      setFieldValue(`groups[${index}].fields[${fieldIndex}].options`, []);
    },
    [values],
  );

  const setDataBaseCategory = useCallback(
    (type: any, index: number, fieldIndex: number) => {
      type.value
        ? setFieldValue(`groups[${index}].fields[${fieldIndex}].templateDatabaseDataSource`, type.value)
        : setFieldValue(`groups[${index}].fields[${fieldIndex}].options`, []);
    },
    [values],
  );

  const setRequired = useCallback(
    (type: any, index: number, fieldIndex: number) => {
      setFieldValue(`groups[${index}].fields[${fieldIndex}].required`, type.target.checked);
    },
    [values],
  );

  const setCommentingAllowed = useCallback(
    (type: any, index: number, fieldIndex: number) => {
      setFieldValue(`groups[${index}].fields[${fieldIndex}].commentingAllowed`, type.target.checked);
    },
    [values],
  );

  const setCustomAnswers = ({ data, callback }: { data: any; callback: () => void }) => {
    setFieldValue(
      `groups[${currentAnswerInfo.options.index}].fields[${currentAnswerInfo.options.fieldIndex}].options`,
      data,
    ).then(() => {
      callback();
      setCurrentAnswerInfo({
        options: null,
        isMiltipleSelect: false,
      });
    });
  };

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const removeGroupe = useCallback(
    index => () => {
      values.groups.splice(index, 1);
      setFieldValue('groups', values.groups);
      for (let i = 0; i < values.groups.length; i++) {
        setFieldValue(`groups[${i}].position`, i);
      }
    },
    [values],
  );

  const setIsHidden = useCallback(
    (type: any, index: number, fieldIndex: number) => {
      setFieldValue(`groups[${index}].fields[${fieldIndex}].isHidden`, type.target.checked);
    },
    [values],
  );

  const removeField = useCallback(
    (groupIndex, fieldIndex) => () => {
      values.groups[groupIndex].fields.splice(fieldIndex, 1);
      setFieldValue(`groups[${groupIndex}].fields`, values.groups[groupIndex].fields);
      for (let i = 0; i < values.groups[groupIndex].fields.length; i++) {
        setFieldValue(`groups[${groupIndex}].fields[${i}].position`, i);
      }
      const groupsErrors = errors.groups;

      if (!isEmpty(groupsErrors) && groupsErrors) {
        //@ts-ignore
        groupsErrors[groupIndex]?.fields.splice(fieldIndex, 1);
        //@ts-ignore
        setFieldValue(`errors[${groupIndex}].fields`, groupsErrors[groupIndex]?.fields);
      }
    },
    [values, errors.groups],
  );

  const copyField = useCallback(
    (groupIndex, fieldIndex) => () => {
      const currentItem = values.groups[groupIndex].fields[fieldIndex];
      const newGroup = values.groups[groupIndex].fields.map(item =>
        item.position > currentItem.position ? { ...item, position: item.position + 1 } : item,
      );
      newGroup.splice(fieldIndex + 1, 0, { ...currentItem, position: currentItem.position + 1 });

      setFieldValue(`groups[${groupIndex}].fields`, newGroup);
    },
    [values],
  );

  const closeCustomAnswersModal = useCallback(() => {
    setModalCustomAnswersIsOpen(false);
  }, []);

  const openCustomAnswersModal = (index: number, fieldIndex: number) => {
    setCurrentAnswerInfo({
      options: { answers: values.groups[index].fields[fieldIndex].options, index: index, fieldIndex: fieldIndex },
      isMiltipleSelect: values.groups[index].fields[fieldIndex].type === SELECT_LIST_MULTIPLE_CHOICE ? true : false,
    });
    setModalCustomAnswersIsOpen(true);
  };

  const getTemplateFieldsError = (errors: any, index: number, fieldIndex: number) => {
    return errors && errors[index]?.fields && errors[index].fields[fieldIndex];
  };

  const templateErrors: any = useMemo(() => errors.groups, [errors.groups]);

  return (
    <>
      <form className={'form form--page poll_template_form'} onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__inputs-subwrapper">
            <div className="form__input-block form__input-block--half">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages.nameColumn)}
                defaultValue={values.name}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
            <div className="form__input-block form__input-block--half">
              <Select
                id={'categoryId'}
                name={'categoryId'}
                options={categoryOptions}
                handleChange={setPollCategory}
                value={categoryValue}
                label={intl.formatMessage(messages.categorySelect)}
                hasError={hasError('categoryId')}
                errorMessage={errors?.categoryId}
                isClearable
              />
            </div>
          </div>
          <div className="form__group">
            <ReactSortable
              list={values.groups}
              setList={newState =>
                setFieldValue(
                  `groups`,
                  newState.map((group: PollTemplateGroupType, index: number) => ({ ...group, position: index })),
                )
              }
              animation={200}
              handle={'.form__btn-move-inputs'}
              style={{ marginBottom: '14px', borderBottom: '1px solid #f0f0f0' }}
            >
              {values.groups.map((group, index) => (
                <div className="form__group-wrapper form__group-wrapper--bordered-top" key={`[${index}]`}>
                  <button className="form__btn-move-inputs" type={'button'}>
                    <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
                  </button>
                  <div className="form__inputs-wrapper">
                    <div className="form__input-block">
                      <Input
                        id={`groups[${index}].name`}
                        name={`groups[${index}].name`}
                        label={intl.formatMessage(messages.groupNameSelect)}
                        defaultValue={values.groups[index].name}
                        onChange={handleChange}
                        hasError={hasError(`groups[${index}].name`)}
                        errorMessage={templateErrors && templateErrors[index]?.name}
                      />
                    </div>
                    <div className="form__group">
                      <ReactSortable
                        list={values.groups[index].fields}
                        setList={newState =>
                          setFieldValue(
                            `groups[${index}].fields`,
                            newState.map((fields: PollTemplateGroupFieldType, index: number) => ({
                              ...fields,
                              position: index,
                            })),
                          )
                        }
                        animation={200}
                        handle={'.form__btn-move-inputs'}
                      >
                        {group.fields.map((field, fieldIndex) => (
                          <div className="form__group-wrapper" key={fieldIndex}>
                            <button className="form__btn-move-inputs" type={'button'}>
                              <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
                            </button>
                            <p className=" question_number">{fieldIndex + 1 + '.'} </p>
                            <div className="form__inputs-wrapper">
                              {field.type === 'NOTE' ? (
                                <div className="form__inputs-subwrapper form__input-block">
                                  <Input
                                    name={`groups[${index}].fields[${fieldIndex}].question`}
                                    id={`groups[${index}].fields[${fieldIndex}].question`}
                                    onChange={handleChange}
                                    defaultValue={field.question}
                                    label={intl.formatMessage(messages.labelInput)}
                                    tag={'textarea'}
                                    hasError={hasError(`groups[${index}].fields[${fieldIndex}].question`)}
                                    errorMessage={getTemplateFieldsError(templateErrors, index, fieldIndex)?.question}
                                  />
                                </div>
                              ) : (
                                <div className="form__inputs-wrapper">
                                  <div className="form__inputs-subwrapper">
                                    <div className="form__input-block form__input-block--near-third question_input">
                                      <Input
                                        name={`groups[${index}].fields[${fieldIndex}].question`}
                                        id={`groups[${index}].fields[${fieldIndex}].question`}
                                        onChange={handleChange}
                                        defaultValue={field.question}
                                        label={intl.formatMessage(messages.questionLabel)}
                                        tag={'textarea'}
                                        externalClass={'question_input'}
                                        hasError={hasError(`groups[${index}].fields[${fieldIndex}].question`)}
                                        errorMessage={
                                          getTemplateFieldsError(templateErrors, index, fieldIndex)?.question
                                        }
                                        overflowYAuto={true}
                                      />
                                    </div>
                                    <div className="form__input-block form__input-block--fourth">
                                      <div className="form__input-block answer_type_select">
                                        <Select
                                          name={`groups[${index}].fields[${fieldIndex}].type`}
                                          id={`groups[${index}].fields[${fieldIndex}].type`}
                                          options={PollTemplateFieldTypeOptions}
                                          handleChange={type => setAnswerType(type, index, fieldIndex)}
                                          defaultValue={PollTemplateFieldTypeOptions[0]}
                                          value={PollTemplateFieldTypeOptions.find((item: any) => {
                                            return item.value === field.type;
                                          })}
                                          hasError={hasError(`groups[${index}].fields[${fieldIndex}].type`)}
                                          errorMessage={getTemplateFieldsError(templateErrors, index, fieldIndex)?.type}
                                        />
                                      </div>
                                      {field.type === SELECT_LIST_MULTIPLE_CHOICE ||
                                      field.type === SELECT_LIST_SINGLE_CHOICE ? (
                                        <div className="form__input-block answer_type_select">
                                          <Select
                                            name={`groups[${index}].fields[${fieldIndex}].dataSource`}
                                            id={`groups[${index}].fields[${fieldIndex}].dataSource`}
                                            options={PollTemplateListTypeOptions}
                                            handleChange={type => setDataSourcesType(type, index, fieldIndex)}
                                            value={PollTemplateListTypeOptions.find((item: any) => {
                                              return item.value === field.dataSource;
                                            })}
                                            defaultValue={PollTemplateListTypeOptions[0]}
                                            hasError={hasError(`groups[${index}].fields[${fieldIndex}].dataSource`)}
                                            errorMessage={
                                              getTemplateFieldsError(templateErrors, index, fieldIndex)?.dataSource
                                            }
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="form__input-block form__input-block--near-third answer_block">
                                      {field.dataSource === TemplateDataSource.CUSTOM_LIST &&
                                      (field.type === SELECT_LIST_MULTIPLE_CHOICE ||
                                        field.type === SELECT_LIST_SINGLE_CHOICE) ? (
                                        <>
                                          <Button
                                            externalClass={`button--modal custom_answer_button ${
                                              getTemplateFieldsError(templateErrors, index, fieldIndex)?.options
                                                ? 'error'
                                                : ''
                                            } `}
                                            type={'button'}
                                            color="gray"
                                            onClick={() => openCustomAnswersModal(index, fieldIndex)}
                                          >
                                            <FormattedMessage {...messages.editAnswersButton} />
                                          </Button>
                                          <ErrorMessage>
                                            {getTemplateFieldsError(templateErrors, index, fieldIndex)?.options}
                                          </ErrorMessage>
                                        </>
                                      ) : null}
                                      {field.dataSource === TemplateDataSource.DATABASE &&
                                      (field.type === SELECT_LIST_MULTIPLE_CHOICE ||
                                        field.type === SELECT_LIST_SINGLE_CHOICE) ? (
                                        <Select
                                          name={`groups[${index}].fields[${fieldIndex}].options`}
                                          id={`groups[${index}].fields[${fieldIndex}].options`}
                                          options={SourcesInformationOptions}
                                          handleChange={type => setDataBaseCategory(type, index, fieldIndex)}
                                          label={intl.formatMessage(messages.databaseSourceSelect)}
                                          value={
                                            field.templateDatabaseDataSource
                                              ? SourcesInformationOptions.find((item: any) => {
                                                  return item.value === field.templateDatabaseDataSource;
                                                })
                                              : null
                                          }
                                          hasError={
                                            hasError(`groups[${index}].fields[${fieldIndex}].options`) ||
                                            hasError(
                                              `groups[${index}].fields[${fieldIndex}].templateDatabaseDataSource`,
                                            )
                                          }
                                          errorMessage={
                                            templateErrors &&
                                            templateErrors[index]?.fields &&
                                            (templateErrors[index].fields[fieldIndex]?.options ||
                                              templateErrors[index].fields[fieldIndex]?.templateDatabaseDataSource)
                                          }
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="form__inputs-subwrapper">
                                    <Checkbox
                                      label={intl.formatMessage(messages.mandatoryCheckbox)}
                                      name={`groups[${index}].fields[${fieldIndex}].required`}
                                      id={`groups[${index}].fields[${fieldIndex}].required`}
                                      externalClass="checkbox_template_form"
                                      checkedValue={field.required}
                                      onChange={type => setRequired(type, index, fieldIndex)}
                                    />
                                    {field.type !== TEXT && (
                                      <Checkbox
                                        label={intl.formatMessage(messages.commentLabel)}
                                        name={`groups[${index}].fields[${fieldIndex}].commentingAllowed`}
                                        id={`groups[${index}].fields[${fieldIndex}].commentingAllowed`}
                                        externalClass="checkbox_template_form"
                                        checkedValue={field.commentingAllowed}
                                        onChange={type => setCommentingAllowed(type, index, fieldIndex)}
                                      />
                                    )}
                                    <Checkbox
                                      label={intl.formatMessage(messages.isHiddenCheckbox)}
                                      name={`groups[${index}].fields[${fieldIndex}].isHidden`}
                                      id={`groups[${index}].fields[${fieldIndex}].isHidden`}
                                      externalClass="checkbox_template_form"
                                      checkedValue={field.isHidden}
                                      onChange={type => setIsHidden(type, index, fieldIndex)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <button
                                className="form__btn-clean-inputs"
                                type={'button'}
                                onClick={removeField(index, fieldIndex)}
                              >
                                <Icon iconName={'cross'} />
                              </button>
                              <button
                                className="form__btn-clean-inputs"
                                type={'button'}
                                onClick={copyField(index, fieldIndex)}
                              >
                                <Icon iconName={'copy'} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </ReactSortable>
                      <div className="form__inputs-subwrapper">
                        <button className="form__btn-add-group" type={'button'} onClick={() => addNewField(index)}>
                          <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
                          <FormattedMessage {...messages.questionLabel} />
                        </button>
                        <button className="form__btn-add-group" type={'button'} onClick={() => addNewNote(index)}>
                          <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
                          <FormattedMessage {...messages.labelInput} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <button className="form__btn-clean-inputs" type={'button'} onClick={removeGroupe(index)}>
                    <Icon iconName={'cross'} externalClass={'form__btn-clean'} />
                  </button>
                </div>
              ))}
            </ReactSortable>
            <button className="form__btn-add-group" onClick={addNewGroup} type={'button'}>
              <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
              <FormattedMessage {...messages.groupButton} />
            </button>
          </div>
        </div>
        <ErrorMessage>{requestErrors}</ErrorMessage>
        <div className="form__buttons">
          <NavLink to={r.pollTemplates}>
            <Button externalClass={'button--modal'} type={'button'} color="gray">
              <FormattedMessage {...messages.cancelButton} />
            </Button>
          </NavLink>
          <Button externalClass={'button--modal'} type={'submit'} disabled={isLoading} loading={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
      {modalCustomAnswersIsOpen && (
        <ModalCustomAnswers
          isOpen
          onCloseRequest={closeCustomAnswersModal}
          setCustomAnsers={setCustomAnswers}
          currentOptionsAnswers={currentAnswerInfo.options}
          isMultiple={currentAnswerInfo.isMiltipleSelect}
        />
      )}
    </>
  );
}

export default EditPollTemplateForm;
