import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import FilterClearButton from '../../FilterClearButton';
import { DepartmentsInfoType } from '../../../types/libraries';
import { ProjectHoursParams } from '../../../enums/params/planning.params';
import { projectHoursUnsavedParams } from '../../../constants/planning.constants';

type FiltersType = {
  departments: DepartmentsInfoType[];
  offices: { id: string; name: string }[];
  projectGroups: { id: string; fullName: string }[];
};

type ProjectHoursFilterProps = {
  filters: FiltersType;
  values: ProjectHoursParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const ProjectHoursFilter = ({
  filters,
  values,
  handleMultiParamsChange,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: ProjectHoursFilterProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const projectGroupsOptions = useMemo(
    () =>
      filters.projectGroups.map(({ id, fullName }: { id: string; fullName: string }) => ({
        value: id,
        label: fullName,
      })),
    [filters.projectGroups],
  );

  const officesOptions = useMemo(
    () =>
      filters.offices.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [filters.offices],
  );

  const departmentsOptions = useMemo(
    () =>
      filters.departments.map(({ id, displayName }: DepartmentsInfoType) => ({
        value: id,
        label: displayName,
      })),
    [filters.departments],
  );

  const projectGroupsValues = useFiltersListValue(projectGroupsOptions, values.projectGroupIds);

  const officesValues = useFiltersListValue(officesOptions, values.officeIds);

  const departmentsValues = useFiltersListValue(departmentsOptions, values.departmentIds);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.projectGroupsLabel)}
        options={projectGroupsOptions}
        value={projectGroupsValues}
        handleChange={handleMultiParamsChange(FilterParamsName.PROJECT_GROUP_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officesValues}
        handleChange={handleMultiParamsChange(FilterParamsName.OFFICE_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentsOptions}
        value={departmentsValues}
        handleChange={handleMultiParamsChange(FilterParamsName.DEPARTMENT_IDS)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.PROJECT_HOURS_DETAILS_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={projectHoursUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
    </>
  );
};

export default React.memo(ProjectHoursFilter);
