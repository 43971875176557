import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import Select from '../../Select';
import Input from '../../Input';
import { PROJECT_GROUP_SCHEMA } from '../../../enums/planning.enum';
import ColorInput from '../../ColorInput/index';
import Checkbox from '../../Checkbox/index';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { scrollToError } from '../../../utils';

type ModalNewProjectGroupProps = {
  onCloseRequest: () => void;
  createProjectGroup: (data: any) => void;
  projectGroupError: string | RejectValueErrors[] | null;
  jiraProjectOptions: { value: number; label: string }[];
  isLoading: boolean;
  jiraProjectsListErrors: string;
  resetError: () => void;
  isOpen: boolean;
};

function ModalNewProjectGroup({
  onCloseRequest,
  createProjectGroup,
  projectGroupError,
  jiraProjectOptions,
  isLoading,
  jiraProjectsListErrors,
  resetError,
  isOpen,
}: ModalNewProjectGroupProps) {
  const intl = useIntl();

  const { values, errors, touched, handleSubmit, setFieldValue, resetForm, setFieldError } = useFormik({
    initialValues: {
      fullName: '',
      shortName: '',
      alertsStatus: true,
      activeStatus: true,
      color: 'ff0000',
      jiraProjects: [],
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: scrollToError,
    validationSchema: PROJECT_GROUP_SCHEMA,
    onSubmit: data => {
      return createProjectGroup({ data: data, callback: resetAndExit });
    },
  });

  useSetFieldsErrors(projectGroupError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetError();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const [modalConfirmProjectMoveIsOpen, setModalConfirmProjectMoveIsOpen] = useState(false);

  const handleChangeJiraProjects = useCallback(value => {
    setFieldValue(
      'jiraProjects',
      value.map((item: any) => item.value),
    );
  }, []);

  const onConfirmProjectMove = () => {
    createProjectGroup({ data: values, callback: resetAndExit, params: { forceUpdate: true } });
    setModalConfirmProjectMoveIsOpen(false);
  };

  useEffect(() => {
    if (projectGroupError === 'Project is already in another group') {
      setModalConfirmProjectMoveIsOpen(true);
    }
  }, [projectGroupError]);

  const closeConfirmProjectMoveModal = useCallback(() => {
    setModalConfirmProjectMoveIsOpen(false);
  }, []);

  const handleChangeAlertsStatus = useCallback(value => {
    setFieldValue('alertsStatus', value.target.checked);
  }, []);

  const handleChangeActiveStatus = useCallback(value => {
    setFieldValue('activeStatus', value.target.checked);
  }, []);

  const handleChangeShortName = useCallback(event => {
    setFieldValue('shortName', event.target.value);
  }, []);

  const handleChangeFullName = useCallback(event => {
    setFieldValue('fullName', event.target.value);
  }, []);

  const handleChangeNewColor = useCallback(event => {
    setFieldValue('color', event.target.value.substring(1));
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={resetAndExit}
        title={intl.formatMessage(messages.newProjectGroupTitle)}
        size={'small'}
      >
        <form className="modal__form form modal_project_group" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__inputs-subwrapper">
              <div className="form__input-block form__input-block--two-third">
                <Input
                  name={'fullName'}
                  tag={'input'}
                  label={intl.formatMessage(messages.fullNameInput)}
                  defaultValue={values.fullName}
                  onChange={handleChangeFullName}
                  hasError={hasError('fullName')}
                  errorMessage={errors?.fullName}
                />
                <ColorInput
                  name={'color'}
                  id={'newColor'}
                  onChange={handleChangeNewColor}
                  externalClass={'form__input-color'}
                  defaultValue={`#${values.color}`}
                />
              </div>
              <div className="form__input-block form__input-block--near-third">
                <Input
                  name={'shortName'}
                  tag={'input'}
                  label={intl.formatMessage(messages.shortNameInput)}
                  defaultValue={values.shortName}
                  onChange={handleChangeShortName}
                  hasError={hasError('shortName')}
                  errorMessage={errors?.shortName}
                />
              </div>
            </div>
            <div className="form__input-block">
              <Select
                label={intl.formatMessage(messages.jiraProjectsLabel)}
                name={'jiraProjects'}
                options={jiraProjectOptions}
                handleChange={handleChangeJiraProjects}
                hasError={hasError('jiraProjects')}
                errorMessage={jiraProjectsListErrors}
                isMulti={true}
                isSearchable={true}
              />
            </div>
            <div className="form__inputs-subwrapper">
              <div className="form__input-block ">
                <Checkbox
                  name={'alertsStatus'}
                  label={intl.formatMessage(messages.alertsStatusInput)}
                  id={'alertsStatus'}
                  externalClass={'checkbox-label'}
                  onChange={handleChangeAlertsStatus}
                  checkedValue={values.alertsStatus}
                />
              </div>
              <div className="form__input-block form__input-block--near-third">
                <Checkbox
                  name={'activeStatus'}
                  label={intl.formatMessage(messages.activeLabel)}
                  id={'activeStatus'}
                  externalClass={'checkbox-label'}
                  onChange={handleChangeActiveStatus}
                  checkedValue={values.activeStatus}
                />
              </div>
            </div>
          </div>
          <ErrorMessage>{projectGroupError}</ErrorMessage>
          <div className="form__buttons">
            <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={modalConfirmProjectMoveIsOpen}
        onRequestClose={closeConfirmProjectMoveModal}
        title={intl.formatMessage(messages.confirmmoveProjectTitle)}
        size={'small'}
        classNameModal="center"
      >
        <div className={'form__buttons'}>
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={closeConfirmProjectMoveModal}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} onClick={onConfirmProjectMove}>
            <FormattedMessage {...messages.addButton} />
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ModalNewProjectGroup;
