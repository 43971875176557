import React, { useCallback, useMemo } from 'react';
import { EventPreviewInfo } from '../../../enums/schedule.enum';
import Icon from '../../Icon';
import Pagination from '../../Pagination';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import Table from '../../Table';
import { UserInfo } from '../../../enums/users.enum';
import { SortParams } from '../../../enums/params.enum';

type EventsListLayoutProps = {
  eventTableData: any;
  loading: boolean;
  error: string | null;
  ableToManageEventTypes: any;
  tableColumns: any;
  isPaginated?: boolean;
  userInfo: UserInfo;
  getEvent: (eventId: string) => void;
  openEditEventModal: () => void;
  openDeleteEventModal: () => void;
  handlePageChange: (data: any) => void;
  handleSizeChange: (data: any) => void;
  renderDropdownBtn: (event: EventPreviewInfo, isUpdateBtn: boolean) => boolean;
  handleSort?: (sortBy: string, direction: string) => void;
  sortParams?: SortParams;
};

function EventsListLayout({
  eventTableData,
  loading,
  error,
  ableToManageEventTypes,
  tableColumns,
  userInfo,
  isPaginated = false,
  getEvent,
  openEditEventModal,
  openDeleteEventModal,
  handlePageChange,
  handleSizeChange,
  renderDropdownBtn,
  sortParams,
  handleSort,
}: EventsListLayoutProps) {
  const renderDropdown = useCallback(
    (event: EventPreviewInfo) => renderDropdownBtn(event, true) || renderDropdownBtn(event, false),
    [renderDropdownBtn],
  );

  const tableActions = useMemo(() => {
    return [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: EventPreviewInfo) => {
          getEvent(row.id);
          openEditEventModal();
        },
        handleRenderDropdownItem: (row: EventPreviewInfo) => renderDropdownBtn(row, true),
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: EventPreviewInfo) => {
          getEvent(row.id);
          openDeleteEventModal();
        },
        handleRenderDropdownItem: (row: EventPreviewInfo) => renderDropdownBtn(row, false),
      },
    ];
  }, [ableToManageEventTypes, userInfo, renderDropdownBtn]);

  return (
    <>
      <Table
        externalClass={'table--offices'}
        tableColumns={tableColumns}
        tableData={eventTableData?.content || []}
        tableActions={tableActions}
        error={error}
        loading={loading}
        renderDropdown={renderDropdown}
        onSort={handleSort}
        params={sortParams}
      />
      {eventTableData && isPaginated && (
        <Pagination
          pageable={{
            ...eventTableData?.pageable,
            ...eventTableData?.sort,
            totalElements: eventTableData?.totalElements,
            numberOfElements: eventTableData?.numberOfElements,
            totalPages: eventTableData?.totalPages,
          }}
          onPageChange={data => handlePageChange(data)}
          onPageSizeChange={data => handleSizeChange(data)}
        />
      )}
    </>
  );
}

export default EventsListLayout;
