import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  activeLabel,
  deleteModalTitle,
  deleteButton,
  fromLabel,
  toLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  deleteModalTitle,
  deleteButton,
  activeLabel,
  fromLabel,
  toLabel,
  newBonusTitle: {
    id: 'bonuses.and.grades.bonuses.modal.new.title.new.bonus',
    defaultMessage: 'New Bonus',
  },
  editBonusTitle: {
    id: 'bonuses.and.grades.bonuses.modal.edit.title.edit.bonus',
    defaultMessage: 'Edit Bonus',
  },
  bonusCategoryLabel: {
    id: 'bonuses.and.grades.bonuses.modal.bonus.category.label.bonus.category',
    defaultMessage: 'Bonus Category',
  },
  bonusExpLabel: {
    id: 'bonuses.and.grades.bonuses.modal.bonus.exp.label.bonus.experience',
    defaultMessage: 'Bonus(Experience)',
  },
  addBonusExpLabel: {
    id: 'bonuses.and.grades.bonuses.modal.add.bonus.exp.label.bonus.experience',
    defaultMessage: 'Bonus Experience',
  },
  bonusDependsOnExpLabel: {
    id: 'bonuses.and.grades.bonuses.modal.bonus.depends.on.exp.label.depends.on.experience',
    defaultMessage: 'Depends on Experience',
  },
  bonusPrevExpLabel: {
    id: 'bonuses.and.grades.bonuses.modal.bonus.prev.exp.label.previous.experience',
    defaultMessage: 'Previous Experience',
  },
  bonusExpInCompnayLabel: {
    id: 'bonuses.and.grades.bonuses.modal.bonus.exp.in.company.label.experience.in.company',
    defaultMessage: 'Experience in Company',
  },
  bonusesSettingsTitle: {
    id: 'bonuses.and.grades.bonuses.modal.settings.title.bonuses.settings',
    defaultMessage: 'Bonuses Settings',
  },
  bonusesSettingsShowBonusLabel: {
    id: 'bonuses.and.grades.bonuses.modal.settings.show.bonus.label',
    defaultMessage: 'Show Bonus and Grades for Members',
  },
  bonusesSettingsRatioLabel: {
    id: 'bonuses.and.grades.bonuses.modal.settings.ratio.label',
    defaultMessage: 'Ratio for Calculating previous Experience',
  },
});
