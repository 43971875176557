import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/users.constants';
import { UsersParams } from '../enums/params/users.params';
import { UserAbsencePeriods, UserInfo, UserPersonalInfo, UserProfessionalInfo } from '../enums/users.enum';
import { getReducerErrors } from '../utils/reducerError.utils';

const initialState = {
  users: [],
  jiraUsers: [],
  current: {
    total: new UserInfo(),
    personal: new UserPersonalInfo(),
    professional: new UserProfessionalInfo(),
    technicalSkills: null,
    companyPositionHistory: null,
    experiences: null,
    bonuses: null,
    absencePeriods: [],
    detailedAbsencePeriodsInfo: [],
  },
  params: new UsersParams(),
  loading: {
    total: false,
    personal: false,
    professional: false,
    update: false,
    updatePersonal: false,
    updateProfessional: false,
    jira: false,
    users: false,
    photo: false,
    technicalSkills: false,
    updateTechnicalSkills: false,
    changePassword: false,
    positionsHistory: false,
    experiences: false,
    bonuses: false,
    absencePeriods: false,
    detailedAbsencePeriodsInfo: false,
  },
  errors: {
    total: null,
    personal: null,
    professional: null,
    update: null,
    updatePersonal: null,
    updateProfessional: null,
    jira: null,
    users: null,
    photo: null,
    technicalSkills: null,
    updateTechnicalSkills: null,
    changePassword: null,
    positionsHistory: null,
    experiences: null,
    bonuses: null,
    absencePeriods: null,
    detailedAbsencePeriodsInfo: null,
  },
};

const usersReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_USER_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, total: true },
        errors: { ...state.errors, total: null },
      };

    case ActionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, total: false },
        errors: { ...state.errors, total: null },
        current: { ...state.current, total: new UserInfo(action.payload) },
      };

    case ActionTypes.GET_USER_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, total: false },
        errors: { ...state.errors, total: action.payload.message },
      };

    case ActionTypes.GET_USER_PERSONAL_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, personal: true },
        errors: { ...state.errors, personal: null },
      };

    case ActionTypes.GET_USER_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, personal: false },
        errors: { ...state.errors, personal: null },
        current: { ...state.current, personal: new UserPersonalInfo(action.payload) },
      };

    case ActionTypes.GET_USER_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, personal: false },
        errors: { ...state.errors, personal: action.payload.message },
      };

    case ActionTypes.GET_USER_PROFESSIONAL_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, professional: true },
        errors: { ...state.errors, professional: null },
      };

    case ActionTypes.GET_USER_PROFESSIONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, professional: false },
        errors: { ...state.errors, professional: null },
        current: {
          ...state.current,
          professional: new UserProfessionalInfo(action.payload),
        },
      };

    case ActionTypes.GET_USER_PROFESSIONAL_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, professional: false },
        errors: { ...state.errors, professional: action.payload.message },
      };

    case ActionTypes.GET_JIRA_USERS_BRIEF_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, jira: true },
        errors: { ...state.errors, jira: null },
      };

    case ActionTypes.GET_JIRA_USERS_BRIEF_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, jira: false },
        errors: { ...state.errors, jira: null },
        jiraUsers: action.payload,
      };

    case ActionTypes.GET_JIRA_USERS_BRIEF_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, jira: false },
        errors: { ...state.errors, jira: action.payload.message },
      };

    case ActionTypes.GET_USERS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, users: true },
        errors: { ...state.errors, users: null },
      };

    case ActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.content.map((user: UserInfo) => new UserInfo(user)),
        loading: { ...state.loading, users: false },
        errors: { ...state.errors, users: null },
      };

    case ActionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, users: false },
        errors: { ...state.errors, users: action.payload.message },
      };

    case ActionTypes.POST_USER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, users: true },
        errors: { ...state.errors, users: null },
      };

    case ActionTypes.POST_USER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, users: false },
        errors: { ...state.errors, users: null },
      };

    case ActionTypes.POST_USER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, users: false },
        errors: {
          ...state.errors,
          users: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_USER_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, update: true },
        errors: { ...state.errors, update: null },
      };

    case ActionTypes.PUT_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, update: false },
        errors: { ...state.errors, update: null },
      };

    case ActionTypes.PUT_USER_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, update: false },
        errors: {
          ...state.errors,
          update: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_USER_PERSONAL_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, updatePersonal: true },
        errors: { ...state.errors, updatePersonal: null },
      };

    case ActionTypes.PUT_USER_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updatePersonal: false },
        errors: { ...state.errors, updatePersonal: null },
        current: {
          ...state.current,
          personal: new UserPersonalInfo(action.payload),
        },
      };

    case ActionTypes.PUT_USER_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, updatePersonal: false },
        errors: { ...state.errors, updatePersonal: action.payload.message },
      };

    case ActionTypes.PUT_USER_PROFESSIONAL_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, updateProfessional: true },
        errors: { ...state.errors, updateProfessional: null },
      };

    case ActionTypes.PUT_USER_PROFESSIONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateProfessional: false },
        errors: { ...state.errors, updateProfessional: null },
        current: {
          ...state.current,
          professional: new UserProfessionalInfo(action.payload),
        },
      };

    case ActionTypes.PUT_USER_PROFESSIONAL_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, updateProfessional: false },
        errors: { ...state.errors, updateProfessional: action.payload.message },
      };

    case ActionTypes.SET_USERS_PARAMS_REQUEST:
      return {
        ...state,
        params: new UsersParams({ ...state.params, ...action.payload }),
        loading: { ...state.loading, users: true },
      };

    case ActionTypes.RESET_USER_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.PATCH_USER_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, update: true },
        errors: { ...state.errors, update: null },
      };

    case ActionTypes.PATCH_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, update: false },
        errors: { ...state.errors, update: null },
      };

    case ActionTypes.PATCH_USER_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, update: false },
        errors: { ...state.errors, update: action.payload.message },
      };

    case ActionTypes.POST_USER_PHOTO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, photo: true },
        errors: { ...state.errors, photo: null },
      };

    case ActionTypes.POST_USER_PHOTO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, photo: false },
        errors: { ...state.errors, photo: null },
        current: {
          ...state.current,
          total: new UserInfo({ ...state.current.total, photo: action.payload.results }),
        },
      };

    case ActionTypes.POST_USER_PHOTO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, photo: false },
        errors: { ...state.errors, photo: action.payload.message },
      };

    case ActionTypes.DELETE_USER_PHOTO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, photo: true },
        errors: { ...state.errors, photo: null },
      };

    case ActionTypes.DELETE_USER_PHOTO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, photo: false },
        errors: { ...state.errors, photo: null },
        current: { ...state.current, total: new UserInfo({ ...state.current.total, photo: null }) },
      };

    case ActionTypes.DELETE_USER_PHOTO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, photo: false },
        errors: { ...state.errors, photo: action.payload.message },
      };

    case ActionTypes.GET_USER_TECHNICAL_SKILLS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, technicalSkills: true },
        errors: { ...state.errors, technicalSkills: null },
      };

    case ActionTypes.GET_USER_TECHNICAL_SKILLS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, technicalSkills: false },
        errors: { ...state.errors, technicalSkills: null },
        current: { ...state.current, technicalSkills: action.payload },
      };

    case ActionTypes.GET_USER_TECHNICAL_SKILLS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, technicalSkills: false },
        errors: { ...state.errors, technicalSkills: action.payload.message },
      };

    case ActionTypes.PUT_USER_TECHNICAL_SKILLS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, updateTechnicalSkills: true },
        errors: { ...state.errors, updateTechnicalSkills: null },
      };

    case ActionTypes.PUT_USER_TECHNICAL_SKILLS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateTechnicalSkills: false },
        errors: { ...state.errors, updateTechnicalSkills: null },
        current: { ...state.current, technicalSkills: action.payload },
      };

    case ActionTypes.PUT_USER_TECHNICAL_SKILLS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, updateTechnicalSkills: false },
        errors: { ...state.errors, updateTechnicalSkills: action.payload.message },
      };

    case ActionTypes.PATCH_UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, changePassword: true },
        errors: { ...state.errors, changePassword: null },
      };

    case ActionTypes.PATCH_UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, changePassword: false },
        errors: { ...state.errors, changePassword: null },
      };

    case ActionTypes.PATCH_UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, changePassword: false },
        errors: { ...state.errors, changePassword: getReducerErrors(action.payload) },
      };

    case ActionTypes.GET_USER_POSITION_HISTORY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, positionsHistory: true },
        errors: { ...state.errors, positionsHistory: null },
      };

    case ActionTypes.GET_USER_POSITION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, positionsHistory: false },
        errors: { ...state.errors, positionsHistory: null },
        current: { ...state.current, companyPositionHistory: action.payload.content },
      };

    case ActionTypes.GET_USER_POSITION_HISTORY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, positionsHistory: false },
        errors: { ...state.errors, positionsHistory: action.payload.message },
      };

    case ActionTypes.PUT_USER_POSITION_HISTORY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, positionsHistory: true },
        errors: { ...state.errors, positionsHistory: null },
      };

    case ActionTypes.PUT_USER_POSITION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, positionsHistory: false },
        errors: { ...state.errors, positionsHistory: null },
        current: {
          ...state.current,
          companyPositionHistory: action.payload,
        },
      };

    case ActionTypes.PUT_USER_POSITION_HISTORY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, positionsHistory: false },
        errors: { ...state.errors, positionsHistory: getReducerErrors(action.payload) },
      };

    case ActionTypes.GET_USER_EXPERIENCES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, experiences: true },
        errors: { ...state.errors, experiences: null },
      };

    case ActionTypes.GET_USER_EXPERIENCES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, experiences: false },
        errors: { ...state.errors, experiences: null },
        current: {
          ...state.current,
          experiences: action.payload,
        },
      };

    case ActionTypes.GET_USER_EXPERIENCES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, experiences: false },
        errors: { ...state.errors, experiences: action.payload.message },
      };

    case ActionTypes.GET_USER_BONUSES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, bonuses: true },
        errors: { ...state.errors, bonuses: null },
      };

    case ActionTypes.GET_USER_BONUSES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, bonuses: false },
        errors: { ...state.errors, bonuses: null },
        current: {
          ...state.current,
          bonuses: action.payload,
        },
      };

    case ActionTypes.GET_USER_BONUSES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, bonuses: false },
        errors: { ...state.errors, bonuses: action.payload.message },
      };

    case ActionTypes.GET_USER_ABSENCE_PERIODS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, absencePeriods: true },
        errors: { ...state.errors, absencePeriods: null },
      };

    case ActionTypes.GET_USER_ABSENCE_PERIODS_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          absencePeriods: action.payload.map((el: any) => new UserAbsencePeriods(el)),
        },
        loading: { ...state.loading, absencePeriods: false },
        errors: { ...state.errors, absencePeriods: null },
      };

    case ActionTypes.GET_USER_ABSENCE_PERIODS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, absencePeriods: false },
        errors: { ...state.errors, absencePeriods: action.payload.message },
      };

    case ActionTypes.GET_DETAILED_USER_ABSENCE_PERIODS_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, detailedAbsencePeriodsInfo: true },
        errors: { ...state.errors, detailedAbsencePeriodsInfo: null },
      };

    case ActionTypes.GET_DETAILED_USER_ABSENCE_PERIODS_INFO_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          detailedAbsencePeriodsInfo: action.payload.content,
        },
        loading: { ...state.loading, detailedAbsencePeriodsInfo: false },
        errors: { ...state.errors, detailedAbsencePeriodsInfo: null },
      };

    case ActionTypes.GET_DETAILED_USER_ABSENCE_PERIODS_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, detailedAbsencePeriodsInfo: false },
        errors: { ...state.errors, detailedAbsencePeriodsInfo: action.payload.message },
      };

    case ActionTypes.RESET_USERS_CURRENT_STATE:
      return {
        ...state,
        current: initialState.current,
      };

    case ActionTypes.RESET_USERS_STATE:
      return {
        ...initialState,
        params: state.params,
      };

    default:
      return state;
  }
};

export default usersReducer;
