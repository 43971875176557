import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/calendar.constants';
import { EventTypesParams } from '../enums/params/calendar.params';

export const getEventTypesList = (): AnyAction => ({
  type: ActionTypes.GET_EVENT_TYPES_LIST_REQUEST,
});

export const getEventTypeById = (id: string): AnyAction => ({
  type: ActionTypes.GET_EVENT_TYPE_BY_ID_REQUEST,
  payload: id,
});

export const createNewEventType = (data: any): AnyAction => ({
  type: ActionTypes.POST_EVENT_TYPE_REQUEST,
  payload: data,
});

export const deleteEventType = (data: any): AnyAction => ({
  type: ActionTypes.DELETE_EVENT_TYPE_REQUEST,
  payload: data,
});

export const editEventType = (data: any): AnyAction => ({
  type: ActionTypes.PUT_EVENT_TYPE_REQUEST,
  payload: data,
});

export const setEventTypesParams = (data: Partial<EventTypesParams>): AnyAction => ({
  type: ActionTypes.SET_EVENT_TYPES_PARAMS,
  payload: data,
});

export const resetCalendarErrors = (): AnyAction => ({
  type: ActionTypes.RESET_CALENDAR_ERRORS,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_CALENDAR_STATE,
});

export const resetEventType = (): AnyAction => ({
  type: ActionTypes.GET_EVENT_TYPE_BY_ID_SUCCESS,
});
