import React from 'react';
import { UserInfo } from '../../../enums/users.enum';
import Icon from '../../Icon';
import { URI_SCHEMES } from '../../../constants/users.constants';
import MessengerIcon from '../MessengerIcon';
import CopyToClickBoard from '../../CopyToClickboard/CopyToClickboard';

type ProfileContactsProps = {
  userInfo: UserInfo;
};

function ProfileContacts({ userInfo }: ProfileContactsProps) {
  return (
    <div className="page__profile-contacts">
      <CopyToClickBoard text={userInfo.email}>
        <a
          href={`mailto:${userInfo.email}`}
          className="page__profile-contact page__profile-contact--email text-to-copy"
        >
          <Icon iconName="envelope" />
          {userInfo.email}
        </a>
      </CopyToClickBoard>
      {userInfo.renderSecondaryEmails && (
        <div className="page__profile-contact page__profile-contact--emails">
          <Icon iconName="envelope" />
          <Icon iconName="envelope" />
          {userInfo.secondaryEmails.map(({ email }, i) => (
            <CopyToClickBoard text={email} key={i.toString()}>
              <a href={`mailto:${email}`} className="page__profile-contact text-to-copy">
                {email}
              </a>
            </CopyToClickBoard>
          ))}
        </div>
      )}
      {userInfo.renderPhones && (
        <div className="page__profile-contact page__profile-contact--phones">
          <Icon iconName="phone" />
          {userInfo.phones.map((phone, i) => (
            <CopyToClickBoard key={i.toString()} text={phone}>
              <a href={`tel:${phone}`} className="page__profile-contact text-to-copy">
                {phone}
              </a>
            </CopyToClickBoard>
          ))}
        </div>
      )}
      {userInfo.jiraUser && (
        <CopyToClickBoard text={userInfo.jiraUsername}>
          <div className="page__profile-contact">
            <MessengerIcon iconName="jira" />
            {userInfo.jiraUsername}
          </div>
        </CopyToClickBoard>
      )}
      {userInfo.otherContacts.map(({ messengerType, ref }, i) =>
        URI_SCHEMES[messengerType] ? (
          <CopyToClickBoard key={i.toString()} text={ref}>
            <a href={`${URI_SCHEMES[messengerType]}${ref}`} className="page__profile-contact text-to-copy">
              <MessengerIcon iconName={messengerType.toLowerCase()} />
              {ref}
            </a>
          </CopyToClickBoard>
        ) : (
          <CopyToClickBoard key={i.toString()} text={ref}>
            <div className="page__profile-contact">
              <MessengerIcon iconName={messengerType.toLowerCase()} />
              {ref}
            </div>
          </CopyToClickBoard>
        ),
      )}
    </div>
  );
}

export default ProfileContacts;
