import { defineMessages } from 'react-intl';
import {
  cancelButton,
  addButton,
  saveButton,
  nameColumn,
  emailLabel,
  phonesLabel,
  memberLabel,
  statusLabel,
  officeLabel,
  departmentLabel,
  employeesLabel,
  allLabel,
  activeLabel,
  inactiveLabel,
  departmentsLabel,
  positionsLabel,
  projectsLabel,
  lastUpdateColumn,
  updaterColumn,
  experienceLabel,
  positionLabel,
  officesLabel,
  skillsColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  memberLabel,
  statusLabel,
  phonesLabel,
  nameColumn,
  officeLabel,
  departmentLabel,
  employeesLabel,
  allLabel,
  activeLabel,
  inactiveLabel,
  departmentsLabel,
  positionsLabel,
  projectsLabel,
  emailLabel,
  addButton,
  cancelButton,
  saveButton,
  lastUpdateColumn,
  updaterColumn,
  experienceLabel,
  positionLabel,
  officesLabel,
  skillsColumn,
  labelsTitle: {
    id: 'people.layouts.label.labels',
    defaultMessage: 'Labels',
  },
  locationTitle: {
    id: 'people.layouts.label.location',
    defaultMessage: 'Location',
  },
  contactsTitle: {
    id: 'people.layouts.label.contacts',
    defaultMessage: 'Contacts',
  },
  gridTalentCuratorsFilter: {
    id: 'people.filter.label.talent.curators',
    defaultMessage: 'Talent Curators',
  },
  specializationsFilterLabel: {
    id: 'people.filter.label.specializations.',
    defaultMessage: 'Specializations',
  },
  cvLocationsSelect: {
    id: 'people.filter.label.locations',
    defaultMessage: 'Locations',
  },
  cvTechnicalSkillsSelect: {
    id: 'people.filter.label.technical.skills',
    defaultMessage: 'Technical Skills',
  },
  cvStatusesSelect: {
    id: 'people.filter.label.statuses',
    defaultMessage: 'Statuses',
  },
  firstNameInputLabel: {
    id: 'people.modal.new.profile.label.first.name',
    defaultMessage: 'First Name',
  },
  secondNameInputLabel: {
    id: 'people.modal.new.profile.label.last.name',
    defaultMessage: 'Last Name',
  },
  JIRAAccountSelect: {
    id: 'people.modal.new.profile.label.jira.account',
    defaultMessage: 'JIRA Account',
  },
  otherEmailsInput: {
    id: 'people.modal.new.profile.label.other.emails',
    defaultMessage: 'Other emails',
  },
  loginInput: {
    id: 'people.modal.new.profile.label.login',
    defaultMessage: 'Login',
  },
  passwordTitle: {
    id: 'people.modal.new.profile.label.password.upper',
    defaultMessage: 'PASSWORD*',
  },
  passwordTypeRandomRadioInput: {
    id: 'people.modal.new.profile.label.random',
    defaultMessage: 'Random',
  },
  passwordTypeCustomRadioInput: {
    id: 'people.modal.new.profile.label.custom',
    defaultMessage: 'Custom',
  },
  passwordInput: {
    id: 'people.modal.new.profile.label.password',
    defaultMessage: 'Password',
  },
  sendAccountDetailsViaEmailCheckbox: {
    id: 'people.modal.new.profile.label.send.account.details.via.email',
    defaultMessage: 'Send account details via email',
  },
  viewUserProfileButton: {
    id: 'people.tableActions.label.view.user.profile.',
    defaultMessage: 'View',
  },
  activateUserAccountButton: {
    id: 'people.tableActions.label.activate.user.account.',
    defaultMessage: 'Activate Account',
  },
  deactivateUserAccountButton: {
    id: 'people.tableActions.label.deactivate.user.account.',
    defaultMessage: 'Deactivate Account',
  },
});
