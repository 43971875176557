import React from 'react';
import Icon from '../../../../components/Icon';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import CopyToClickBoard from '../../../../components/CopyToClickboard/CopyToClickboard';

type ContactItemProps = {
  items: string[];
  iconName: string;
  linkPrefix: 'mailto' | 'tel';
  externalClass?: string;
};

const ContactItem = ({ items, iconName, linkPrefix, externalClass }: ContactItemProps) => {
  const defaultClass = classNames('page__profile-contact', {
    [externalClass as string]: Boolean(externalClass),
  });

  return !isEmpty(items) ? (
    <div className={defaultClass}>
      <Icon iconName={iconName} />
      {items.map((item, i) => (
        <CopyToClickBoard text={item} key={i.toString()}>
          <a href={`${linkPrefix}:${item}`} className="page__profile-contact">
            {item}
          </a>
        </CopyToClickBoard>
      ))}
    </div>
  ) : null;
};

export default ContactItem;
