import { defineMessages } from 'react-intl';
import { exportToXLSLabel, exportToPDFLabel, copyURLLabel, totalRow } from '../../i18n/defaultMessage';

export default defineMessages({
  exportToXLSLabel,
  exportToPDFLabel,
  copyURLLabel,
  totalRow,
  pageTitle: {
    id: 'reports.active.and.inactive.hours.title.active.and.inactive.hours',
    defaultMessage: 'Active and Inactive Hours',
  },

  membersDatesColumn: {
    id: 'reports.active.and.inactive.hours.column.members.dates',
    defaultMessage: 'Mambers / Dates',
  },
  loggedColumn: {
    id: 'reports.active.and.inactive.hours.column.logged',
    defaultMessage: 'Logged',
  },

  trackedActiveColumn: {
    id: 'reports.active.and.inactive.hours.column.tracked.active',
    defaultMessage: 'Tracked (active)',
  },
  trackedInactiveColumn: {
    id: 'reports.active.and.inactive.hours.column.tracked.inactive',
    defaultMessage: 'Tracked (inactive)',
  },
  notWorkingActiveColumn: {
    id: 'reports.active.and.inactive.hours.column.not.working.inactive',
    defaultMessage: 'Not working (active)',
  },
  notWorkingInactiveColumn: {
    id: 'reports.active.and.inactive.hours.column.not.working.inactive',
    defaultMessage: 'Not working (inactive)',
  },
  submittedColumn: {
    id: 'reports.active.and.inactive.hours.column.submitted',
    defaultMessage: 'Submitted',
  },
});
