import { BonusesSettingsType } from '../components/Bonuses/Modals/ModaSettings';
import { BonusCategoryInfo } from '../enums/bonuses.enums';
import { BonusesParams } from '../enums/params/bonuses.params';
import request from '../utils/request.utils';

export function getBonusesList(params?: BonusesParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/bonuses',
    params: {
      ...params,
      sort: 'name,asc',
    },
  };
  return request(options);
}

export function createNewBonus(data: BonusCategoryInfo) {
  const options: RequestConfig = {
    method: 'post',
    url: '/bonuses',
    data,
  };
  return request(options);
}

export function editBonus(uuid: string, data: BonusCategoryInfo) {
  const options: RequestConfig = {
    method: 'put',
    url: `/bonuses/${uuid}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteBonus(uuid: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/bonuses/${uuid}`,
  };
  return request(options);
}

export function getBonusesSettings() {
  const options: RequestConfig = {
    method: 'get',
    url: '/bonuses/settings',
  };
  return request(options);
}

export function editBonusesSettings(data: BonusesSettingsType) {
  const options: RequestConfig = {
    method: 'put',
    url: '/bonuses/settings',
    data,
  };
  return request(options);
}
