import React, { useCallback, useEffect, useMemo } from 'react';
import InlineDatePicker from '../../../InlineDatePicker';
import SummaryChart from './SummaryChart';
import DistributionChart from './DistributionChart';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { ActivityParams } from '../../../../enums/params/activity.params';
import { resetState, setUserTabActivityParams } from '../../../../actions/activity.actions';
import { getDatesInRange } from '../../../../utils';
import { getOfficesWorkDays } from '../../../../actions/libraries.actions';

function ProfileWorkTime({
  params,
  activityData,
  currentUser,
  setUserTabActivityParams,
  resetState,
}: ConnectedProps<typeof connector>) {
  const { id } = useParams<{ id: string }>();
  const dateRange = useMemo(() => getDatesInRange(params.dateFrom, params.dateTo), [params.dateFrom, params.dateTo]);

  const handleDataChange = useCallback((start: string, end: string) => {
    setUserTabActivityParams({
      dateFrom: start,
      dateTo: end,
    });
  }, []);

  useEffect(() => {
    if (params.portalUserIds[0] !== id && params) {
      setUserTabActivityParams({
        portalUserIds: [id],
      });
    }
  }, [id]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    const officeId = currentUser.office?.id;

    officeId && getOfficesWorkDays({ offices: officeId });
  }, [currentUser.id]);

  return (
    <div className="tabs__content-item active tabs__content-item__wrapper--work-time">
      <div className="tabs__content-item__title">
        <FormattedMessage {...messages.workTimeLabel} />
      </div>
      <div className="pagination tabs__top-right-block">
        <InlineDatePicker
          onDateChange={handleDataChange}
          defaultPeriodStart={params.dateFrom}
          defaultPeriodEnd={params.dateTo}
        />
      </div>
      <SummaryChart activityData={activityData} dateRange={dateRange} />
      <DistributionChart activityData={activityData} dateRange={dateRange} currentUser={currentUser} />
    </div>
  );
}

const mapStateToProps = ({ activity, users }: RootState) => ({
  params: activity.userActivityTabParams,
  activityData: activity.activityTable?.departments[0]?.users[0],
  currentUser: users.current.total,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setUserTabActivityParams: (data: Partial<ActivityParams>) => dispatch(setUserTabActivityParams(data)),
  resetState: () => dispatch(resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfileWorkTime);
