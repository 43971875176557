import React, { useCallback } from 'react';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import CurrencyInput from '../../../../components/CurrencyInput';
import { ImportTransaction } from '../../../../enums/finance/finance.enum';

type TransactionsCurrencyInputProps = {
  row: ImportTransaction;
  index: number;
  name: string;
  defaultValue?: string;
  value?: string;
  allowNegativeValue?: boolean;
  decimalsLimit?: number;
  handleChange: (value: CurrencyInputOnChangeValues, row: ImportTransaction, index?: number) => void;
};

function TransactionsCurrencyInput({
  value,
  defaultValue,
  row,
  name,
  index,
  allowNegativeValue,
  decimalsLimit,
  handleChange,
}: TransactionsCurrencyInputProps) {
  const handleInputChange = useCallback(
    (values: CurrencyInputOnChangeValues) => {
      handleChange(values, row, index);
    },
    [row, index],
  );

  return (
    <CurrencyInput
      name={name}
      allowNegativeValue={allowNegativeValue}
      defaultValue={defaultValue}
      value={value}
      decimalsLimit={decimalsLimit}
      onChange={handleInputChange}
    />
  );
}

export default React.memo(TransactionsCurrencyInput);
