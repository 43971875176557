import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { DepthLevels } from '../../../constants/tables.constants';
import {
  DayWorkload,
  MULTI_GROUP_COLOR,
  ResourceComplexItem,
  UserDataType,
  Workloads,
} from '../../../enums/planning.enum';
import { UserInfo } from '../../../enums/users.enum';
import { getTime } from '../../../utils/planning.utils';
import { getTableCell } from '../../../utils/table.utils';
import { TableColumnType } from '../../HierarchicalTable';
import Icon from '../../Icon';
import TableUserAvatar from '../../TableUserAvatar';
import messages from '../messages';
import { renderToStaticMarkup } from 'react-dom/server';
import { hintId } from '../../Hint/utils';

export const useResourcesTableData = (
  tableData: any,
  currentPeriod: string,
  isFactWorkload: boolean,
  handleResourceClick: (row: UserDataType, index: number, event: React.MouseEvent<HTMLElement>) => void,
  setHintDependency: (data: number) => void,
  paramsProjectGroups: number[],
) => {
  const intl = useIntl();

  const checkProjectGroup = useCallback(
    workloads =>
      paramsProjectGroups?.length
        ? !workloads.some((item: Workloads) => paramsProjectGroups.find(group => group === item.projectGroup.id))
        : false,
    [paramsProjectGroups],
  );

  const checkFactloadProjectGroup = useCallback(
    (dayWorkload: DayWorkload) =>
      !!dayWorkload.factLoads.length &&
      dayWorkload.factLoads.every(factLoad =>
        dayWorkload.workloads.find(workload => workload.projectGroup.id === factLoad.projectGroup.id),
      ),
    [paramsProjectGroups],
  );

  const getEventToHint = useCallback(
    (row: UserDataType, index: number) => (
      <div className="hint-item">
        <div className="hint-item-name">{row.dayWorkloads[index].absenceEvent?.eventTypeName}</div>
        <div>
          {moment(row.dayWorkloads[index].absenceEvent?.startDate).format(DATE_FORMAT.MMM_D) +
            ' - ' +
            moment(row.dayWorkloads[index].absenceEvent?.endDate).format(DATE_FORMAT.MMM_D)}
        </div>
      </div>
    ),
    [],
  );

  const onResourceMouseEnter = useCallback(
    (row: UserDataType, index: number) => {
      return renderToStaticMarkup(
        isFactWorkload ? (
          <>
            {row.dayWorkloads[index]?.isAbsence && getEventToHint(row, index)}
            {row.dayWorkloads[index].allFactLoadsProjects.map(project => (
              <div key={project.id} className="hint-item">
                <div className="hint-item-name">{project.name}</div>
                <div
                  className={classNames({
                    'project-alert':
                      !row.dayWorkloads[index]?.workloads.find(
                        workload => workload.projectGroup.id === project.projectGroupId,
                      ) && project.status !== 'false',
                  })}
                >{`${project.worked.hours}h`}</div>
              </div>
            ))}
          </>
        ) : row.dayWorkloads[index]?.isAbsence ? (
          getEventToHint(row, index)
        ) : (
          <>
            {row.dayWorkloads[index]?.workloads?.map(item => (
              <div key={item.projectGroup.id} className="hint-item">
                <div className="hint-item-name">{item.projectGroup.fullName}</div>
                <div>{`${item.percent}%`}</div>
              </div>
            ))}
          </>
        ),
      );
    },
    [isFactWorkload],
  );

  const emploeeTableDate = useMemo(() => {
    if (tableData) {
      return tableData?.resourcesComplex[0]?.resources[0]?.dayWorkloads.map((item: DayWorkload, index: number) => {
        return {
          id: `${item.date}-${index}`,
          Header: (
            <div className="day-display">
              {moment(item.date).format(DATE_FORMAT.D)}
              {(index === 0 || moment(item.date).startOf('month').isSame(item.date)) && (
                <div className="month-year-display">{moment(item.date).format(DATE_FORMAT.MMM_YYYY)}</div>
              )}
            </div>
          ),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.SECOND,
                content: (row: UserDataType) =>
                  row.dayWorkloads[index]?.employeeNotHired ? (
                    <div className="table__data-employee-not-hired"></div>
                  ) : row.dayWorkloads[index]?.isHoliday ? (
                    <div
                      className="table__data-holiday table__data-value"
                      onClick={event => handleResourceClick(row, index, event)}
                    >
                      <span>HD</span>
                    </div>
                  ) : (
                    <div
                      className="workload-wrapper"
                      onClick={event => handleResourceClick(row, index, event)}
                      data-html={true}
                      data-tip={
                        isFactWorkload
                          ? row.dayWorkloads[index]?.isAbsence || row.dayWorkloads[index].allFactLoadsProjects.length
                            ? onResourceMouseEnter(row, index)
                            : ''
                          : row.dayWorkloads[index]?.isAbsence || row.dayWorkloads[index]?.workloads.length
                          ? onResourceMouseEnter(row, index)
                          : ''
                      }
                      data-for={hintId}
                      onMouseEnter={() => setHintDependency(index)}
                    >
                      <div
                        className={classNames(
                          'table__data-value',
                          {
                            'workload-disabled':
                              !row.dayWorkloads[index]?.isAbsence &&
                              row.dayWorkloads[index]?.workloads.length &&
                              checkProjectGroup(row.dayWorkloads[index]?.workloads),
                          },
                          {
                            'workload-full-height':
                              isFactWorkload &&
                              (row.dayWorkloads[index]?.isAbsence ||
                                checkFactloadProjectGroup(row.dayWorkloads[index])),
                          },
                          {
                            'workload-bold':
                              isFactWorkload &&
                              row.dayWorkloads[index]?.isAbsence &&
                              row.dayWorkloads[index].allFactLoadsProjects.length,
                          },
                        )}
                        style={{
                          background: row.dayWorkloads[index]?.isAbsence
                            ? row.dayWorkloads[index]?.absenceTranslucentColor
                            : row.dayWorkloads[index]?.isMultiGrouped
                            ? MULTI_GROUP_COLOR
                            : row.dayWorkloads[index]?.isPlanned
                            ? `#${row.dayWorkloads[index]?.workloads[0]?.projectGroup.color}90`
                            : 'none',
                          fontSize:
                            !row.dayWorkloads[index]?.isMultiGrouped &&
                            row.dayWorkloads[index]?.workloads[0]?.projectGroup?.shortName.length > 2
                              ? '12px'
                              : 'inherit',
                        }}
                      >
                        {row.dayWorkloads[index]?.isAbsence ? (
                          <span style={{ color: row.dayWorkloads[index]?.absenceColor }}>
                            {row.dayWorkloads[index]?.absenceNameLetter}
                          </span>
                        ) : row.dayWorkloads[index]?.isMultiGrouped ? (
                          <span>MG</span>
                        ) : (
                          <span>{row.dayWorkloads[index]?.workloads[0]?.projectGroup?.shortName}</span>
                        )}
                      </div>
                      {!isFactWorkload ? null : !row.dayWorkloads[index].allFactLoadsProjects.every(
                          item =>
                            row.dayWorkloads[index].workloads.some(
                              workload => workload.projectGroup.id === item.projectGroupId,
                            ) || item.status === 'false',
                        ) ? (
                        <div className="table__data-value table__data-alert">
                          <span>
                            <Icon iconName="alert" />
                          </span>
                        </div>
                      ) : row.dayWorkloads[index].allFactLoadsProjects.length > 1 ? (
                        <div className="table__data-value table__data-multi-group">
                          <span>MG</span>
                        </div>
                      ) : (
                        <div
                          className="table__data-value"
                          style={{ background: `#${row.dayWorkloads[index]?.factLoads[0]?.projectGroup.color}90` }}
                        >
                          <span>{row.dayWorkloads[index]?.factLoads[0]?.projectGroup?.shortName}</span>
                        </div>
                      )}
                    </div>
                  ),
              },
            ]),

          headClassName: `${
            index + 1 === +moment().format(DATE_FORMAT.DD) && moment().isSame(currentPeriod, 'month') ? 'thisDay' : ''
          }`,
          externalColumnClass: (row: any) => {
            if (row.depth === 1) {
              return `dayWorkloads ${row.original.dayWorkloads[index]?.employeeNotHired ? 'employee-not-hired' : ''}`;
            }
            return '';
          },
        };
      });
    } else {
      const arr = [];
      for (let i = 1; i <= moment(currentPeriod).daysInMonth(); i++) {
        arr.push({
          Header: `${i}`,
          id: i.toString(),
        });
      }
      return arr;
    }
  }, [tableData, isFactWorkload]);

  const tableColumnsStart: TableColumnType[] = useMemo(
    () => [
      {
        id: 'departmentsEmployees',
        Header: intl.formatMessage(messages.departmentsMembersLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ResourceComplexItem) => <span>{row.departmentName}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <TableUserAvatar users={[new UserInfo(row.user)]} fileSize={48} />,
            },
          ]),
        headClassName: 'table__head-column--fixed-left employees_column',
      },
    ],
    [],
  );

  const tableColumnsTotal: TableColumnType[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'timeTotal.workDays',
        headClassName: 'table__head-column--fixed-right work_days_colum',
        externalColumnClass: () => 'table__head-column--fixed-right work_days_colum',
      },
      {
        id: 'daysPlanned',
        Header: intl.formatMessage(messages.daysLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ResourceComplexItem) => <span>{row.timeTotal.monthPlanned.days}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => (
                <span className={`${row.timeTotal.monthPlanned.days < row.timeTotal.workDays ? 'red' : ''}`}>
                  {row.timeTotal.monthPlanned.days}
                </span>
              ),
            },
          ]),
        headClassName: 'table__head-column--fixed-right planned_days_column',
        externalColumnClass: () => 'table__head-column--fixed-right planned_days_column',
      },
      {
        Header: intl.formatMessage(messages.hoursLabel),
        accessor: 'timeTotal.monthPlanned.hours',
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ResourceComplexItem) => <span>{getTime(row.timeTotal.monthPlanned.hours)}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => <span>{getTime(row.timeTotal.monthPlanned.hours)}</span>,
            },
          ]),
        headClassName: 'table__head-column--fixed-right planned_hours_column',
        externalColumnClass: () => 'table__head-column--fixed-right planned_hours_column',
      },
      {
        id: 'daysWorked',
        Header: intl.formatMessage(messages.daysLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ResourceComplexItem) => <span>{row.timeTotal.worked.days}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => (
                <span className={`${row.timeTotal.worked.days < row.timeTotal.monthPlanned.days ? 'red' : ''}`}>
                  {row.timeTotal.worked.days}
                </span>
              ),
            },
          ]),
        headClassName: 'table__head-column--fixed-right worked_days_column',
        externalColumnClass: () => 'table__head-column--fixed-right worked_days_column',
      },
      {
        id: 'hours',
        Header: intl.formatMessage(messages.hoursLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ResourceComplexItem) => <span>{getTime(row.timeTotal.worked.hours)}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserDataType) => (
                <span className={`${row.timeTotal.worked.hours < row.timeTotal.monthPlanned.hours ? 'red' : ''}`}>
                  {getTime(row.timeTotal.worked.hours)}
                </span>
              ),
            },
          ]),

        headClassName: 'table__head-column--fixed-right worked_hours_column',
        externalColumnClass: () => 'table__head-column--fixed-right worked_hours_column',
      },
    ],
    [],
  );

  const tableColumns = tableColumnsStart.concat(emploeeTableDate, tableColumnsTotal);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column--fixed-left employees_column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: 'border-bottom header_row',
      colspan: tableData?.resourcesComplex[0]?.resources[0]?.dayWorkloads.length,
      sortName: '',
    },
    {
      name: '',
      className: 'word-wrap table__head-column--fixed-right work_days_colum header_row',
      colspan: 1,
      sortName: '',
    },
    {
      name: intl.formatMessage(messages.plannedByMonthLabel),
      className: 'border-bottom table__head-column--fixed-right planned_month_header header_row',
      colspan: 2,
      sortName: '',
    },
    {
      name: intl.formatMessage(messages.workedLabel),
      className: 'border-bottom table__head-column--fixed-right worked_hours_header header_row',
      colspan: 2,
      sortName: '',
    },
  ];

  return {
    tableColumns,
    tableHeaderItems,
  };
};
