import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import * as competenciesActions from '../../actions/competencies.actions';
import SkillQuestionsFilter from '../../components/SkillQuestions/Filter';
import Pagination from '../../components/Pagination';
import r from '../../constants/routes.constants';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { SortParams } from '../../enums/params.enum';
import { SkillsQuestionsParams } from '../../enums/params/competencies.params';
import AccessChecker from '../../components/AccessChecker';
import { UPDATE_SKILL_QUESTION } from '../../constants/policies.constants';
import { getCompetenciesFilter } from '../../actions/filters.actions';
import { useParamsChange } from '../../utils/hooks.utils';
import { isEqual } from 'lodash-es';

export type QuestionType = {
  id: string;
  question: string;
  correctAnswer: string;
};

export type SkillQuestionsListItemType = {
  id: string;
  skillId: string;
  name: string;
  competenceId: string;
  competenceName: string;
  levelId: string;
  levelName: string;
  questionsCount: number;
  questions: Array<QuestionType>;
};

function SkillQuestions({
  tableData,
  isLoading,
  skillsQuestionsTableDataError,
  params,
  setSkillQuestionsParams,
  competenciesFilter,
  getCompetenciesFilter,
  resetState,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    setSkillQuestionsParams({ competenceIds: competenciesFilter.value });
    getCompetenciesFilter();
    return () => {
      resetState();
    };
  }, []);

  const history = useHistory();

  const handleMultiParamsChange = useParamsChange(setSkillQuestionsParams, dispatch);

  const handlePageChange = useCallback(({ selected }) => {
    setSkillQuestionsParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setSkillQuestionsParams({ size: data, page: 0 });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.skillsColumn),
        sortName: 'name',
        modifier(row: SkillQuestionsListItemType) {
          return (
            <NavLink className={'table__type-link'} to={r.questions.replace(':id', `${row.id}`)}>
              {row.name}
            </NavLink>
          );
        },
      },
      {
        name: intl.formatMessage(messages.competenciesTitle),
        modifier: (row: SkillQuestionsListItemType) => row.competenceName,
      },
      {
        name: intl.formatMessage(messages.levelsTitle),
        modifier: (row: SkillQuestionsListItemType) => row.levelName,
      },
      {
        name: intl.formatMessage(messages.questionsTitle),
        modifier: (row: SkillQuestionsListItemType) => row.questionsCount,
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: SkillQuestionsListItemType) => history.push(r.editSkillQuestions.replace(':id', `${row.id}`)),
        verifiablePolicies: [UPDATE_SKILL_QUESTION],
      },
    ],
    [],
  );

  const sortParams = useMemo(() => new SortParams('name', params), [params]);

  const handleSort = useCallback((sortBy, direction) => setSkillQuestionsParams({ sortBy, direction }), []);

  const filters = useMemo(() => ({ competencies: competenciesFilter.competencies }), [competenciesFilter.competencies]);

  const handleClear = useCallback(() => {
    setSkillQuestionsParams(new SkillsQuestionsParams());
  }, []);

  const showClearButton = useMemo(() => !isEqual(params, new SkillsQuestionsParams()), [params]);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_SKILL_QUESTION]}>
                  <NavLink to={r.newSkillQuestions}>
                    <Button externalClass={'button--with-icon'}>
                      <Icon iconName={'plus'} externalClass={'button__icon'} />
                      <span className="button__text">
                        <FormattedMessage {...messages.newButton} />
                      </span>
                    </Button>
                  </NavLink>
                </AccessChecker>
                <SkillQuestionsFilter
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleClear={handleClear}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getSkillQuestionsList}
            error={skillsQuestionsTableDataError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
          {tableData && (
            <Pagination
              pageable={{
                ...tableData?.pageable,
                ...tableData?.sort,
                totalElements: tableData?.totalElements,
                numberOfElements: tableData?.numberOfElements,
                totalPages: tableData?.totalPages,
              }}
              onPageChange={data => handlePageChange(data)}
              onPageSizeChange={data => handleSizeChange(data)}
            />
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ competencies, filters }: RootState) => ({
  skillsQuestionsTableDataError: competencies.errors.skillsQuestionsTableDataError,
  isLoading: competencies.loading,
  tableData: competencies.skillsQuestionsTableData,
  params: competencies.skillQuestionsParams,
  competenciesFilter: filters.competenciesFilter,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCompetenciesFilter: () => dispatch(getCompetenciesFilter()),
  setSkillQuestionsParams: (data: Partial<SkillsQuestionsParams>) =>
    dispatch(competenciesActions.setSkillQuestionsParams(data)),
  resetState: () => dispatch(competenciesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SkillQuestions);
