import { defineMessages } from 'react-intl';
import {
  cancelButton,
  submitButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  memberLabel,
  membersLabel,
  authorColumn,
  createdColumn,
  editorColumn,
  updatedColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  submitButton,
  deleteButton,
  memberLabel,
  membersLabel,
  authorColumn,
  createdColumn,
  editorColumn,
  updatedColumn,
  deleteModalWithItemNameTitle,
  newRecognitionTitle: {
    id: 'recognitions.modal.new.recognition.title.new.recognition',
    defaultMessage: 'New Recognition',
  },
  editRecognitionTitle: {
    id: 'recognitions.modal.edit.recognition.title.edit.recognition',
    defaultMessage: 'Edit Recognition',
  },
  textInput: {
    id: 'recognitions.modal.edit.recognition.label.text',
    defaultMessage: 'Text',
  },
  recognitionTypeSelect: {
    id: 'recognitions.modal.new.recognition.label.recognition.type',
    defaultMessage: 'Recognition Type',
  },
  recognitionTypesFilter: {
    id: 'recognitions.label.recognition.types',
    defaultMessage: 'Recognition Types',
  },
  authorsFilter: {
    id: 'recognitions.label.authors',
    defaultMessage: 'Authors',
  },
  messagelabel: {
    id: 'recognitions.label.message',
    defaultMessage: 'Message',
  },
});
