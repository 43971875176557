import React, { useContext } from 'react';
import Button from '../../Button';
import Icon from '../../Icon';
import Modal from '../../Modal';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { checkPolicies } from '../../../utils/policies.utils';
import PoliciesContext from '../../../PoliciesContext';
import { DELETE_OFFICE, UPDATE_OFFICE } from '../../../constants/policies.constants';

type ModalOfficeProps = {
  externalClass?: string;
  onCloseRequest: () => void;
  data: OfficeInfo;
  onDeleteRequest: any;
  onEditRequest: any;
  isOpen: boolean;
};

function ModalOffice({ onCloseRequest, data, onDeleteRequest, onEditRequest, isOpen }: ModalOfficeProps) {
  const policies = useContext(PoliciesContext);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      shouldCloseOnOverlayClick={true}
      title={data.name}
      popover
      modalOptions={
        <>
          {checkPolicies([UPDATE_OFFICE], policies) && (
            <Button externalClass={'modal__options-btn'} color="transparent" onClick={onEditRequest}>
              <Icon iconName={'pencil'} externalClass={'modal__options-icon'} />
            </Button>
          )}
          {checkPolicies([DELETE_OFFICE], policies) && (
            <Button
              externalClass={'modal__options-btn'}
              color="transparent"
              onClick={() => {
                onDeleteRequest(data.id);
                onCloseRequest();
              }}
            >
              <Icon iconName={'trash'} externalClass={'modal__options-icon'} />
            </Button>
          )}
        </>
      }
      classNameModal="center"
    >
      <div className="modal__office-info">
        <div className="modal__office-address">{data.address}</div>
        <div className="modal__office-work-time">
          <div className="modal__office-work-days">{data.officeWorkDays}</div>
          <div className="modal__office-work-hours">{data.officeWorkDayHour}</div>
        </div>
        <div className="modal__office-contacts-wrapper">
          {data.officeContacts.map((contact: any, index: number) => {
            return (
              <OfficeContact
                key={`${index}`}
                name={contact.contactName}
                email={contact.email}
                tels={contact.phone}
                description={contact.additionalInfo}
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

type OfficeContactProps = {
  name: string;
  email: string;
  tels: string[];
  description: string;
};

function OfficeContact({ name, email, tels, description }: OfficeContactProps) {
  return (
    <div className="modal__office-contact">
      <div className="modal__office-contact-name">{name}</div>
      <a href={`mailto:${email}`} className="modal__office-contact-email">
        {email}
      </a>
      <div className="modal__office-contact-tels">
        <a href={`tel:${tels}`} className="modal__office-contact-tel">
          {tels}
        </a>
      </div>
      <div className="modal__office-contact-descr">{description}</div>
    </div>
  );
}

export default ModalOffice;
