import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { SKILL_LEVELS_VALIDATION_SCHEMA } from '../../../enums/libraries.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { scrollToError } from '../../../utils';

type ModalNewSkillLevelProps = {
  onCloseRequest: () => void;
  createNewSkillLevel: (data: any) => void;
  skillLevelError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  isOpen: boolean;
};

function ModalNewSkillLevel({
  onCloseRequest,
  createNewSkillLevel,
  skillLevelError,
  isLoading,
  resetErrors,
  isOpen,
}: ModalNewSkillLevelProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldError } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: SKILL_LEVELS_VALIDATION_SCHEMA,
    validateOnChange: false,
    validate: scrollToError,
    onSubmit: data => {
      return createNewSkillLevel({ data: data, callback: resetAndExit });
    },
  });

  useSetFieldsErrors(skillLevelError, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.newSkillLevelTitle)}
      classNameModal="skill-level-modal center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              id={'name'}
              name={'name'}
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values.name}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
            />
          </div>
        </div>
        <ErrorMessage>{skillLevelError}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} type={'button'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewSkillLevel;
