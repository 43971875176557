import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import Button from '../../Button';
import Icon from '../../Icon';
import ModalEditUserTechnicalSkills from '../../../components/TechnicalSkills/Modals/ModalEditUserTechnicalSkills';
import * as usersActions from '../../../actions/users.actions';
import { find } from 'lodash-es';
import { TRouteParamId } from '../../../enums/common.enum';
import { UPDATE_USER, UPDATE_USER_EXTENDED } from '../../../constants/policies.constants';
import { getTechnicalSkillsFilter } from '../../../actions/filters.actions';
import { getSkillLevelsList } from '../../../actions/libraries.actions';
import { checkPolicies } from '../../../utils/policies.utils';
import PoliciesContext from '../../../PoliciesContext';

type SkillType = { id: string; skill: string };

type skillLevelWithSkillsType = {
  skillIds: string[];
  skillLevelId: string;
  skillLevelName: string;
  skills: SkillType[];
};

export type UserSkillGroupType = {
  skillGroupId: string;
  skillGroupName: string;
  skillLevelWithSkills: skillLevelWithSkillsType[];
};

function ProfileTechnicalSkills({
  getTechnicalSkillsFilter,
  technicalSkillList,
  getUserTechnicalSkills,
  userTechnicalSkills,
  updateUserTechnicalSkills,
  skillLevelData,
  getSkillLevelsList,
  loading,
  requestError,
  currentUserId,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const { id } = useParams<TRouteParamId>();
  const [modalEditTechnicalSkillsOpen, setEditTechnicalSkillsOpen] = useState(false);
  const policies = useContext(PoliciesContext);

  useEffect(() => {
    getUserTechnicalSkills(id);
  }, [id]);

  useEffect(() => {
    getSkillLevelsList();
    getTechnicalSkillsFilter();
  }, []);

  const closeModalEditTechnicalSkills = () => {
    setEditTechnicalSkillsOpen(false);
  };

  const openModalEditTechnicalSkills = () => {
    getUserTechnicalSkills(id);
    setEditTechnicalSkillsOpen(true);
  };

  const editUserTechnicalSkills = (data: UserSkillGroupType[], callback: () => void) => {
    updateUserTechnicalSkills(id, data, callback);
  };

  return (
    <>
      <div className="tabs__content-item active  tabs__content-item__wrapper--technical-skills">
        <div className="title-wrapper">
          <div className="tabs__content-item__title">
            <FormattedMessage {...messages.technicalSkillsTitle} />
          </div>
          {(checkPolicies([UPDATE_USER], policies) && currentUserId === authUserId) ||
          checkPolicies([UPDATE_USER_EXTENDED], policies) ? (
            <Button color="gray" icon onClick={openModalEditTechnicalSkills}>
              <Icon iconName="pencil" externalClass="button__icon" />
              <span className="button__text">
                <FormattedMessage {...messages.editButton} />
              </span>
            </Button>
          ) : null}
        </div>
        <div>
          {userTechnicalSkills?.map((group: UserSkillGroupType) => (
            <div className="technologies" key={group.skillGroupId}>
              <div className="technologies__title">{group.skillGroupName}</div>
              <div className="technologies__levels">
                {skillLevelData?.map((level: { id: string; name: string; priority: number }) => {
                  const currentLevel = find(group.skillLevelWithSkills, el => el.skillLevelId === level.id);
                  return (
                    <div className="technologies__level" key={currentLevel?.skillLevelId}>
                      <div className="technologies__level-title">{level?.name}</div>
                      <div className="technologies__level-stack">
                        {currentLevel?.skills.map((item: { id: string; skill: string }) => (
                          <div className="technologies__level-item" key={item.id}>
                            <Icon iconName="checkbox" /> {item.skill}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        {modalEditTechnicalSkillsOpen && (
          <ModalEditUserTechnicalSkills
            isOpen
            onCloseRequest={closeModalEditTechnicalSkills}
            userTechnicalSkills={userTechnicalSkills}
            editTechnicalSkill={editUserTechnicalSkills}
            technicalSkillData={technicalSkillList}
            isLoading={loading}
            requestError={requestError}
            skillLevelData={skillLevelData}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ libraries, users, filters, auth }: RootState) => ({
  technicalSkillList: filters.technicalSkillsFilter.skills,
  userTechnicalSkills: users.current.technicalSkills,
  skillLevelData: libraries.skillLevelsTableData?.content,
  loading: users.loading.updateTechnicalSkills,
  requestError: users.errors.updateTechnicalSkills,
  authUserId: auth.currentUserInfo.id,
  currentUserId: users.current.total.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTechnicalSkillsFilter: () => dispatch(getTechnicalSkillsFilter()),
  getSkillLevelsList: () => dispatch(getSkillLevelsList()),
  getUserTechnicalSkills: (id: string) => dispatch(usersActions.getUserTechnicalSkills(id)),
  updateUserTechnicalSkills: (uuid: string, data: UserSkillGroupType[], cb: () => void) =>
    dispatch(usersActions.updateUserTechnicalSkills(uuid, data, cb)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfileTechnicalSkills);
