import { defineMessages } from 'react-intl';

export default defineMessages({
  todayButton: {
    id: 'inline.date.picker.btn.today',
    defaultMessage: 'Today',
  },
  yearLabel: {
    id: 'inline.date.picker.label.year',
    defaultMessage: 'Year',
  },
  monthLabel: {
    id: 'inline.date.picker.label.month',
    defaultMessage: 'Month',
  },
  weekLabel: {
    id: 'inline.date.picker.label.week',
    defaultMessage: 'Week',
  },
  dayLabel: {
    id: 'inline.date.picker.label.day',
    defaultMessage: 'Day',
  },
});
