import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import Filter from '../../Filter';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import DropdownForm from '../../DropdownForm';
import Icon from '../../Icon';
import Button from '../../Button';
import { useFormik } from 'formik';
import { CandidateStatus } from '../../../enums/candidates.enums';
import * as candidateTypes from '../../../types/candidates';
import { CANDIDATE_EXPERIENCE_FILTER_SCHEMA, CandidateParams } from '../../../enums/params/candidates.params';
import { getFormattedStatusOptionLabel, useModalOptions } from '../useModalsData';
import PickerFilter from './PickerFilter';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import CurrencyInput from '../../CurrencyInput';
import { debounce, isEmpty, isNil } from 'lodash-es';
import Search from '../../Search';
import { experienceOptions } from './utils';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { FilterTypes } from '../../../constants/filters.constants';
import SortSelect from '../../SortSelect';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import { SortParams } from '../../../enums/params.enum';
import { scrollToError } from '../../../utils';

type FiltersType = {
  candidateStatuses: CandidateStatus[];
  candidateSpecializations: candidateTypes.CandidateSpecializationType[];
  candidateTechnologies: candidateTypes.CandidateTechnologyType[];
  candidateReceivingSources: candidateTypes.CandidateReceivingSourceType[];
};

type CandidatesFilterProps = {
  filters: FiltersType;
  values: CandidateParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  setCandidatesParams: (data: Partial<CandidateParams>) => void;
  handleSort: (sortBy: string, direction: string) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

function CandidatesFilter({
  filters,
  values,
  handleMultiParamsChange,
  setCandidatesParams,
  handleSort,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: CandidatesFilterProps) {
  const intl = useIntl();
  const [isOpenExpFilter, setIsOpenExpFilter] = useState(false);
  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const openExpFilter = useCallback(() => {
    setIsOpenExpFilter(true);
  }, []);

  const closeExpFilter = useCallback(() => {
    setIsOpenExpFilter(false);
    setErrors({});
  }, [values.experienceFrom, values.experienceTo]);

  const { values: expValues, setFieldValue, handleSubmit, errors, setErrors } = useFormik<{
    from: string | number;
    to: string | number;
  }>({
    initialValues: {
      from: values.experienceFrom,
      to: values.experienceTo,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: scrollToError,
    validationSchema: CANDIDATE_EXPERIENCE_FILTER_SCHEMA,
    onSubmit: ({ from, to }) => {
      setCandidatesParams({
        experienceFrom: from,
        experienceTo: to,
        page: 0,
      });
      closeExpFilter();
    },
  });

  const {
    candidateStatusesOptions,
    candidateTechnologiesOptions,
    candidateSpecializationsOptions,
    candidateReceivingSourcesOptions,
  } = useModalOptions(
    filters.candidateStatuses,
    filters.candidateTechnologies,
    filters.candidateSpecializations,
    filters.candidateReceivingSources,
  );

  const formatStatusOptionLabel = useCallback(getFormattedStatusOptionLabel, []);

  const statusesValues = useFiltersListValue(candidateStatusesOptions, values.statusIds);

  const specializationsValues = useFiltersListValue(candidateSpecializationsOptions, values.specializationIds);

  const technologiesValues = useFiltersListValue(candidateTechnologiesOptions, values.technologyIds);

  const sourcesValues = useFiltersListValue(candidateReceivingSourcesOptions, values.receivingSourceIds);

  const leadExpValues = useFiltersListValue(experienceOptions, [values.hasLeadExperience]);

  const handleLeadExpChange = useCallback(({ value }) => {
    setCandidatesParams({ hasLeadExperience: value, page: 0 });
  }, []);

  const handleParamsChange = useCallback(
    (value: OptionTypeBase, name = '') => handleMultiParamsChange(name)(value),
    [],
  );

  const handleCreatedDateChange = useCallback((start: string, end: string) => {
    setCandidatesParams({
      createDateFrom: start ? moment(start).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss) : '',
      createDateTo: end ? moment(end).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss) : '',
      page: 0,
    });
  }, []);

  const handleUpdatedDateChange = useCallback((start: string, end: string) => {
    setCandidatesParams({
      updateDateFrom: start ? moment(start).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss) : '',
      updateDateTo: end ? moment(end).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss) : '',
      page: 0,
    });
  }, []);

  const handleCommentsDateChange = useCallback((start: string, end: string) => {
    setCandidatesParams({
      commentDateFrom: start ? moment(start).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss) : '',
      commentDateTo: end ? moment(end).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss) : '',
      page: 0,
    });
  }, []);

  const clearExpForm = () => {
    setFieldValue('from', '');
    setFieldValue('to', '');
  };

  const expFromChange = (val: any) => {
    setFieldValue('from', !isNil(val.float) ? val.float : '');
  };

  const expToChange = (val: any) => {
    setFieldValue('to', !isNil(val.float) ? val.float : '');
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCandidatesParams({ search: e.target.value, page: 0 });
  };

  const handleClearSearch = () => {
    setCandidatesParams({ search: '', page: 0 });
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleSearchChange, 500), []);

  const dropdownExpToggle = useMemo(() => {
    const isExpFromEmpty = isEmpty(values.experienceFrom.toString());
    const isExpToEmpty = isEmpty(values.experienceTo.toString());
    const oneEmptyVal = (isExpFromEmpty && !isExpToEmpty) || (isExpToEmpty && !isExpFromEmpty);
    const valueContent =
      isExpFromEmpty && isExpToEmpty ? (
        <FormattedMessage {...messages.allLabel} />
      ) : oneEmptyVal ? (
        values.experienceFrom.toString() || values.experienceTo.toString()
      ) : (
        `${values.experienceFrom} - ${values.experienceTo}`
      );

    return (
      <span className={`${!isExpFromEmpty || !isExpToEmpty ? 'bold' : ''}`}>
        <FormattedMessage {...messages.experienceLabel} />
        :&nbsp;
        <span>{valueContent}</span>
        <Icon iconName="filter-arrow" />
      </span>
    );
  }, [values.experienceFrom, values.experienceTo]);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, []);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Search
        placeholder={intl.formatMessage(messages.summaryLabel)}
        defaultValue={values.search}
        onChange={debouncedChangeHandler}
        handleClearBtn={handleClearSearch}
      />
      <SortSelect
        sortOptions={[
          { label: intl.formatMessage(messages.nameColumn), value: 'lastName' },
          { label: intl.formatMessage(messages.statusLabel), value: 'status' },
          { label: intl.formatMessage(messages.experienceLabel), value: 'carrierStart' },
          { label: intl.formatMessage(messages.locationTitle), value: 'location' },
          { label: intl.formatMessage(messages.receivingSourceTitle), value: 'receivingSource' },
          { label: intl.formatMessage(messages.createdColumn), value: 'createdDate' },
          { label: intl.formatMessage(messages.updatedColumn), value: 'lastModifiedDate' },
        ]}
        params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
        onSort={handleSort}
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.statusLabel)}
        options={candidateStatusesOptions}
        value={statusesValues}
        paramsName="statusIds"
        handleChange={handleParamsChange}
        formatOptionLabel={formatStatusOptionLabel}
        externalClass="filters__select"
      />
      <DropdownForm
        placement="bottom"
        side="left"
        dropdownToggle={dropdownExpToggle}
        dropdownToggleClass="form__btn-add-group"
        isOpen={isOpenExpFilter}
        onOpenRequest={openExpFilter}
        onCloseRequest={closeExpFilter}
        dropdownClass={'experience_filter'}
      >
        <form className="form" onSubmit={handleSubmit} onClick={e => e.stopPropagation()}>
          <div className="form__inputs-subwrapper">
            <CurrencyInput
              id="from"
              name="from"
              label={intl.formatMessage(messages.fromLabel)}
              value={expValues.from}
              allowDecimals={false}
              onChange={expFromChange}
            />
            <CurrencyInput
              id="to"
              name="to"
              label={intl.formatMessage(messages.toLabel)}
              value={expValues.to}
              allowDecimals={false}
              onChange={expToChange}
              errorMessage={errors.to}
              hasError={!!errors.to}
            />
          </div>
          <div className="form__buttons">
            <Button externalClass={'button--modal'} onClick={clearExpForm} type={'button'} color="gray">
              <FormattedMessage {...messages.clearButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'}>
              <FormattedMessage {...messages.applyButton} />
            </Button>
          </div>
        </form>
      </DropdownForm>
      <Filter
        label={intl.formatMessage(messages.leadExpLabel)}
        options={experienceOptions}
        value={leadExpValues[0]}
        paramsName="hasLeadExperience"
        handleChange={handleLeadExpChange}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.specializationsLabel)}
        options={candidateSpecializationsOptions}
        value={specializationsValues}
        paramsName="specializationIds"
        handleChange={handleParamsChange}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.technologiesLabel)}
        options={candidateTechnologiesOptions}
        value={technologiesValues}
        paramsName="technologyIds"
        handleChange={handleParamsChange}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.receivingSourcesLabel)}
        options={candidateReceivingSourcesOptions}
        value={sourcesValues}
        paramsName="receivingSourceIds"
        handleChange={handleParamsChange}
        externalClass="filters__select"
      />
      <PickerFilter
        pickerContainerClass="created-picker"
        defaultStartDate={values.createDateFrom.split(' ')[0]}
        defaultEndDate={values.createDateTo.split(' ')[0]}
        handleChange={handleCreatedDateChange}
        label={intl.formatMessage(messages.createdColumn)}
      />
      <PickerFilter
        pickerContainerClass="updated-picker"
        defaultStartDate={values.updateDateFrom.split(' ')[0]}
        defaultEndDate={values.updateDateTo.split(' ')[0]}
        handleChange={handleUpdatedDateChange}
        label={intl.formatMessage(messages.updatedColumn)}
      />
      <PickerFilter
        pickerContainerClass="comments-picker"
        defaultStartDate={values.commentDateFrom.split(' ')[0]}
        defaultEndDate={values.commentDateTo.split(' ')[0]}
        handleChange={handleCommentsDateChange}
        label={intl.formatMessage(messages.commentsLabel)}
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.CANDIDATES_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
    </>
  );
}

export default CandidatesFilter;
