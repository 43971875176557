import React, { useCallback, useMemo } from 'react';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { ResourceLink } from '../../../enums/branding.enum';
import Table from '../../Table';

type ModalNewOfficeProps = {
  isOpen: boolean;
  dashboardLinks: ResourceLink[];
  dashboardLinksError: string | null;
  isLoading: boolean;
  editLinksOrder: (data: any) => void;
  onCloseRequest: () => void;
  resetErrors: () => void;
};

function ModalReorderLinks({
  isOpen,
  dashboardLinks,
  dashboardLinksError,
  isLoading,
  editLinksOrder,
  onCloseRequest,
  resetErrors,
}: ModalNewOfficeProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetErrors();
  }, []);

  const getTableColumns = () => {
    const tableColumns: any = [
      {
        name: '',
        modifier: (row: ResourceLink) => <span className="custom-link">{row.displayName}</span>,
      },
    ];

    if (dashboardLinks.filter(el => el.file).length > 0) {
      tableColumns.unshift({
        name: '',
        modifier: (row: ResourceLink) =>
          row.file?.url && (
            <div className="icon-wrapper">
              <img src={row.file?.url} />
            </div>
          ),
        className: 'link-icon',
      });
    }
    return tableColumns;
  };
  const tableColumns = useMemo(() => getTableColumns(), []);

  const sortMenuLinks = (menuLinks: ResourceLink[], newIndex: number, oldIndex: number, menuId: string) => {
    menuLinks.forEach(el => {
      if (el.id === menuId) {
        return null;
      }
      if (newIndex > oldIndex) {
        if (el.position > oldIndex && el.position <= newIndex) {
          el.position--;
        }
      } else if (newIndex < oldIndex) {
        if (el.position < oldIndex && el.position >= newIndex) {
          el.position++;
        }
      }
    });
    return menuLinks;
  };

  const submitTable = useCallback(
    (data: Record<string, unknown>) => {
      const menuLink = dashboardLinks[data.oldIndex as number];
      const sortedLinks = dashboardLinks.map((link, i) => {
        link.position = i;
        return link;
      });
      const otherLinks = sortMenuLinks(sortedLinks, Number(data.newIndex), Number(data.oldIndex), menuLink.id);
      menuLink.position = data.newIndex as number;
      editLinksOrder({
        data: otherLinks,
      });
    },
    [dashboardLinks],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.reorderLinksTitle)}
      classNameModal="modal--reorder-links center"
      size="small"
    >
      <div className="form__inputs-wrapper">
        <Table
          externalClass={'table custom-header-navigation'}
          tableColumns={tableColumns}
          tableData={dashboardLinks || []}
          loading={false}
          error={dashboardLinksError}
          tableActions={[]}
          onDragSort={submitTable}
          sortable
          hideHead
        />
      </div>
      <div className="form__buttons">
        <Button
          externalClass={'button--modal'}
          onClick={resetAndExit}
          type={'button'}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.doneButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalReorderLinks;
