import React from 'react';
import classNames from 'classnames';
import { ThumbnailsSize, UserInfo, UserPreviewInfo } from '../../enums/users.enum';
import TableAvatar from './TableAvatar';

type TableUserAvatarProps = {
  users: UserInfo[] | UserPreviewInfo[] | string[];
  fileSize?: ThumbnailsSize;
  isUserPageLink?: boolean;
  externalClassName?: string;
  allUsersList?: UserInfo[] | UserPreviewInfo[];
  selectedTableUser?: boolean;
};

function TableUserAvatar({
  users,
  fileSize,
  externalClassName,
  isUserPageLink = true,
  allUsersList,
  selectedTableUser = false,
}: TableUserAvatarProps) {
  if (users?.length === 0) {
    return null;
  }

  const className = classNames('table__data-wrapper', externalClassName);
  const showTooltip = users.length > 1;
  return (
    <div className={className}>
      {allUsersList?.length && typeof users[0] === 'string'
        ? //@ts-ignore
          users?.slice(0, 10).map(id => {
            const user = allUsersList.find(item => item.id === id);

            return (
              user && (
                <TableAvatar
                  key={user.id}
                  user={user}
                  fileSize={fileSize || 48}
                  isUserPageLink={isUserPageLink}
                  externaltableUserClass={users.length === 0 ? 'user-solo' : ''}
                  showTooltip={showTooltip}
                  selectedTableUser={selectedTableUser}
                />
              )
            );
          })
        : users
            ?.slice(0, 10)
            .map(
              (user: UserInfo | UserPreviewInfo | string) =>
                typeof user !== 'string' && (
                  <TableAvatar
                    key={user.id}
                    user={user}
                    fileSize={fileSize || 48}
                    isUserPageLink={isUserPageLink}
                    externaltableUserClass={users?.length === 0 ? 'user-solo' : ''}
                    showTooltip={showTooltip}
                    selectedTableUser={selectedTableUser}
                  />
                ),
            )}
      {users.length > 10 && <div className="table__more-users">{`+${users.length - 10}`}</div>}
    </div>
  );
}

export default TableUserAvatar;
