import { defineMessages } from 'react-intl';
import {
  employeesLabel,
  memberLabel,
  competenciesTitle,
  deleteButton,
  cancelButton,
  saveButton,
  skillsColumn,
  scoreColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  employeesLabel,
  memberLabel,
  competenciesTitle,
  deleteButton,
  cancelButton,
  saveButton,
  reviewersFilter: {
    id: 'competencies.assessments.filter.label.reviewers',
    defaultMessage: 'Reviewers',
  },
  organizersFilter: {
    id: 'competencies.assessments.filter.label.organizers',
    defaultMessage: 'Organizers',
  },
  deleteModalTitle: {
    id: 'competencies.assessments.modal.delete.assessment.title.are.you.sure.you.want.to.delete.assessment.for',
    defaultMessage: 'Are you sure you want to delete assessment for',
  },
  editAssessmentTitle: {
    id: 'competencies.assessments.modal.edit.assessment.title.edit.assessment',
    defaultMessage: 'Edit Assessment',
  },
  newAssessmentTitle: {
    id: 'competencies.assessments.modal.new.assessment.title.new.assessment',
    defaultMessage: 'New Assessment',
  },
  skillsColumn,
  scoreColumn,
  maxTitle: {
    id: 'competencies.assessments.modal.new.assessment.column.max',
    defaultMessage: 'Max',
  },
  dateLabel: {
    id: 'competencies.assessments.modal.new.assessment.label.date',
    defaultMessage: 'Date',
  },
  specializationSelect: {
    id: 'competencies.assessments.modal.new.assessment.label.specialization',
    defaultMessage: 'Specialization',
  },
  relatedEventSelect: {
    id: 'competencies.assessments.modal.new.assessment.label.related.event',
    defaultMessage: 'Related Event',
  },
  minTitle: {
    id: 'competencies.assessments.modal.new.assessment.validation.min',
    defaultMessage: 'Min',
  },
  scorsWarningTitle: {
    id: 'competencies.assessments.modal.new.assessment.validation.less.than.in.previous.assessment',
    defaultMessage: 'Less than in previous assessment',
  },
  assessmentDetailsTitle: {
    id: 'profile.tab.competencies.modal.assessment.details.title.assessment.details',
    defaultMessage: 'Assessment Details',
  },
  competenceTitle: {
    id: 'profile.tab.competencies.modal.assessment.details.title.competence',
    defaultMessage: 'Competence',
  },
  lastUpdatesTitle: {
    id: 'competencies.assessments.label.last.updates',
    defaultMessage: 'Last Updates',
  },
  updatersTitle: {
    id: 'competencies.assessments.label.updaters',
    defaultMessage: 'Updaters',
  },
  assessmentDateTitle: {
    id: 'competencies.assessments.label.assessment.date',
    defaultMessage: 'Assessment Date',
  },
});
