import { defineMessages } from 'react-intl';
import { editButton, deleteButton, deleteModalTitle, cancelButton } from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  deleteModalTitle,
  cancelButton,
  commentPlaceholder: {
    id: 'schedule.event.comment.placeholder',
    defaultMessage: 'Write a comment...',
  },
  commentPublishedLabel: {
    id: 'schedule.event.comment.label.comment.published',
    defaultMessage: '{isEdited,select, true {{commentDate} (edited)} other {{commentDate}}}',
  },
  replyBtn: {
    id: 'comments.btn.reply',
    defaultMessage: 'Reply',
  },
});
