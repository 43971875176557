import { createIntl, createIntlCache } from 'react-intl';

const cache = createIntlCache();

const defaultLocale = 'en';

export const intl = createIntl(
  {
    locale: defaultLocale,
    defaultLocale,
    messages: {},
  },
  cache,
);
