import moment from 'moment';
import { AbsenceEvent } from '../enums/users.enum';

export const getTime = (hours: number) => {
  return `${hours}h`;
};

export const getModalStyles = (rect: DOMRect) => {
  return {
    left: `${rect.left - 560 > 0 ? rect.left - 260 : rect.right + 260}px`,
    transform: `translate(-50%, ${
      document.documentElement.clientHeight - 300 - rect.top > 0 ? rect.top - 126 : rect.top - 300
    }px)`,
  };
};

export const getCurrentAbsence = (absences: AbsenceEvent[] | null) => {
  const noPersonalAbsences = absences?.filter(item => !item.isPersonal);

  const currentAbsences = noPersonalAbsences?.length ? noPersonalAbsences : absences;

  return currentAbsences?.reduce(
    (previousValue: any, currentValue: any) =>
      moment(previousValue.startDate) <= moment(currentValue.startDate) ? previousValue : currentValue,
    currentAbsences[0],
  );
};
