import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import { Client, CLIENT_SCHEMA } from '../../../enums/finance/finance.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import Icon from '../../Icon';
import Select from '../../Select';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { CurrencyType } from '../../../types/finance';
import { scrollToError } from '../../../utils';

type ModalNewClientProps = {
  onCloseRequest: () => void;
  createClient: (data: { data: Client; callback: () => void }) => void;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  getCurrenciesList: () => void;
  currenciesList: CurrencyType[];
};

function ModalNewClient({
  onCloseRequest,
  createClient,
  error,
  isLoading,
  isOpen,
  getCurrenciesList,
  currenciesList,
}: ModalNewClientProps) {
  const intl = useIntl();

  useEffect(() => {
    getCurrenciesList();
  }, []);

  const { errors, touched, handleChange, handleSubmit, setFieldError, values, setFieldValue } = useFormik({
    initialValues: new Client(),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: CLIENT_SCHEMA,
    onSubmit: data => {
      return createClient({
        //@ts-ignore
        data: { ...data, rates: data.rates.map(item => ({ ...item, rate: new Number(item.rate).toFixed(2) })) },
        callback: onCloseRequest,
      });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const currenciesOptions = useMemo(() => currenciesList?.map(item => ({ label: item.name, value: item })), [
    currenciesList,
  ]);

  const currencyValue = useCallback(
    index =>
      values.rates[index].currency &&
      currenciesOptions?.find(({ value }: { value: { id: string } }) => value.id === values.rates[index].currencyId),
    [currenciesOptions, values],
  );

  const addNewRate = useCallback(() => {
    setFieldValue(`rates`, [
      ...values.rates,
      {
        currency: null,
        currencyId: '',
        rate: '',
        startDate: '',
      },
    ]);
  }, [values]);

  const removeRate = useCallback(
    index => () => {
      values.rates.splice(index, 1);
      setFieldValue(`rates`, values.rates);
    },
    [values],
  );

  const handleChangeRate = useCallback((value, index) => {
    setFieldValue(`rates[${index}].rate`, value);
  }, []);

  const handleChangeCurrency = useCallback((item, index) => {
    setFieldValue(`rates[${index}].currency`, item.value);
    setFieldValue(`rates[${index}].currencyId`, item.value?.id);
  }, []);

  const handleChangeDate = useCallback((value, index) => {
    setFieldValue(`rates[${index}].startDate`, moment(value).format(DATE_FORMAT.YYYY_MM_DD));
  }, []);

  const ratesErrors: any = useMemo(() => errors.rates, [errors.rates]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.newClientTitle)}
        size={'small'}
        classNameModal={'client-modal'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages.nameColumn)}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
            {values.rates.map((item: any, index: number) => (
              <div className="form__inputs-subwrapper" key={item.id}>
                <div className="form__inputs-subwrapper">
                  <div className={'form__input-block rate-input'}>
                    <Input
                      name={`rates[${index}].rate`}
                      label={intl.formatMessage(messages.hourPriceLabel)}
                      onChange={event => {
                        handleChangeRate(event.target.value, index);
                      }}
                      defaultValue={values.rates[index]?.rate}
                      hasError={hasError(`rates[${index}].rate`)}
                      errorMessage={ratesErrors && ratesErrors[index]?.rate}
                      type="number"
                    />
                  </div>
                  <div className="form__input-block">
                    <Select
                      label={intl.formatMessage(messages.currencyLabel)}
                      options={currenciesOptions}
                      value={currencyValue(index)}
                      handleChange={value => handleChangeCurrency(value, index)}
                      hasError={hasError(`rates[${index}].currencyId`)}
                      errorMessage={ratesErrors && ratesErrors[index]?.currencyId}
                      isSearchable
                      isClearable
                    />
                  </div>
                  <div className="form__input-block date-wrapper">
                    <Input
                      type="date"
                      label={intl.formatMessage(messages.startDateLabel)}
                      name={`rates[${index}].startDate`}
                      defaultValue={values.rates[index].startDate}
                      onChange={event => handleChangeDate(event.target.value, index)}
                      hasError={hasError(`rates[${index}].startDate`)}
                      errorMessage={ratesErrors && ratesErrors[index]?.startDate}
                    />
                  </div>
                </div>
                <button className="form__btn-clean-inputs" type={'button'} onClick={removeRate(index)}>
                  <Icon iconName={'cross'} />
                </button>
              </div>
            ))}
            <div className="form__input-block add_button_block">
              <Button type="button" externalClass="form__btn-add-group" color="gray" onClick={addNewRate}>
                <Icon iconName="plus" externalClass="form__icon-btn-add" />
                <FormattedMessage {...messages.addButton} />
              </Button>
            </div>
          </div>
          <ErrorMessage>{errors.rates}</ErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              onClick={onCloseRequest}
              type={'button'}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalNewClient;
