import React, { useMemo, useState } from 'react';
import Creatable from 'react-select/creatable';
import { OptionTypeBase, components } from 'react-select';
import Icon from '../Icon';
import classNames from 'classnames';
import MessengerIcon from '../Profile/MessengerIcon';
import ErrorMessage from '../ErrorMessage';

const iconComponentsMap = { Icon, MessengerIcon };

type ChipInputProps = {
  value?: OptionTypeBase[];
  defaultValue?: OptionTypeBase[];
  label?: string;
  handleChange: (val: OptionTypeBase) => void;
  hasError?: boolean;
  errorMessage?: string;
  iconName?: string;
  iconComponent?: 'Icon' | 'MessengerIcon';
  externalClass?: string;
};

function ChipInput({
  value,
  defaultValue,
  handleChange,
  label,
  hasError,
  errorMessage,
  iconName,
  iconComponent = 'Icon',
  externalClass,
}: ChipInputProps) {
  const defaultClass = classNames('select creatable chip-input isMulti', externalClass);

  const [inputValue, setInputValue] = useState<string>();
  const selectValueContainerClass = classNames({
    'select__value-container--with-icon': iconName,
  });
  const IconComponent = iconComponentsMap[iconComponent];

  const handleBlur = () => {
    const label = inputValue?.trim() || '';
    const option = { label, value: label };

    handleChange(option.label ? [...(value || []), option] : [...(value || [])]);
    setInputValue('');
  };

  const onInputChange = (textInput: string, { action }: any) => {
    if (action === 'input-change') {
      setInputValue(textInput);
    }
    if (action === 'input-blur') {
      handleBlur();
    }
  };

  const onChange = (selected: any) => {
    handleChange(selected);
    setInputValue('');
  };

  const selectComponents = useMemo(
    () => ({
      DropdownIndicator: null,
      MultiValueRemove: (props: any) => (
        <components.MultiValueRemove {...props}>
          <Icon iconName="times-circle" />
        </components.MultiValueRemove>
      ),
      ValueContainer: ({ children, ...props }: any) => (
        <components.ValueContainer className={selectValueContainerClass} {...props}>
          {iconName && <IconComponent iconName={iconName} externalClass="select__value-container-icon" />}
          <components.Placeholder className={classNames({ '--error': hasError })} {...props}>
            {label}
          </components.Placeholder>
          {children}
        </components.ValueContainer>
      ),
    }),
    [selectValueContainerClass, hasError],
  );

  return (
    <div className="chip-input-wrapper">
      <div className="chip-input-value-wrapper">
        <Creatable
          classNamePrefix="select"
          className={defaultClass}
          isMulti
          placeholder={null}
          isClearable={false}
          value={value}
          inputValue={inputValue}
          defaultValue={defaultValue}
          onChange={onChange}
          onInputChange={onInputChange}
          components={selectComponents}
        />
      </div>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
}

export default ChipInput;
