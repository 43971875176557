import get from 'lodash-es/get';
import { clientVersion } from '../utils';

const ENV_VARIABLES = {
  ENVIRONMENT: get(process.env, 'NODE_ENV'),
  APP_API: get(process.env, 'REACT_APP_API', ''),
  KEYCLOAK_URL: get(process.env, 'REACT_APP_KEYCLOAK_URL', ''),
  KEYCLOAK_REALM: get(process.env, 'REACT_APP_KEYCLOAK_REALM', ''),
  KEYCLOAK_CLIENTID: get(process.env, 'REACT_APP_KEYCLOAK_CLIENTID', ''),
  KEYCLOAK_GRANT_TYPE: get(process.env, 'REACT_APP_KEYCLOAK_GRANT_TYPE', ''),
  KEYCLOAK_CLIENT_SECRET: get(process.env, 'REACT_APP_KEYCLOAK_CLIENT_SECRET', ''),
  KEYCLOAK_SIGNIN_URL: get(process.env, 'REACT_APP_KEYCLOAK_SIGNIN_URL', ''),
  ENCRYPTION_KEY: get(process.env, 'REACT_APP_ENCRYPTION_KEY', ''),
  JIRA_BASE_URL: get(process.env, 'REACT_APP_JIRA_BASE_URL', ''),
};

const ENV = {
  ...ENV_VARIABLES,
  IS_PRODUCTION: process.env.NODE_ENV === 'production',
  IS_DEVELOPMENT: process.env.NODE_ENV === 'development',
  IS_STAGING: process.env.REACT_APP_STAGING === 'true',
  IS_TEST: process.env.NODE_ENV === 'test',
  VERSION: clientVersion,
};

if (ENV.IS_DEVELOPMENT || ENV.IS_STAGING) {
  console.log(ENV);
}

export default ENV;
