import React, { useCallback } from 'react';
import { OfficeInfo } from '../../../enums/libraries.enum';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';

type ModalDeleteOfficeProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onDeleteRequest: (data: any) => void;
  officeData: OfficeInfo;
  officeError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
};

function ModalDeleteOffice({
  isOpen,
  onCloseRequest,
  onDeleteRequest,
  officeData,
  officeError,
  isLoading,
  resetErrors,
}: ModalDeleteOfficeProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetErrors();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.deleteModalWithItemNameTitle, {
        name: officeData.name,
      })}
      size={'small'}
      classNameModal="center"
    >
      <ErrorMessage>{officeError}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button externalClass={'button--modal'} onClick={resetAndExit} color="gray">
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            onDeleteRequest({ data: officeData.id, callback: resetAndExit });
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteOffice;
