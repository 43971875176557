import { defineMessages } from 'react-intl';
import {
  saveButton,
  cancelButton,
  deleteButton,
  dateLabel,
  officeLabel,
  officesLabel,
  projectLabel,
  rateLabel,
  applyButton,
  clearButton,
  fromLabel,
  toLabel,
  updaterColumn,
  lastUpdateColumn,
  commentLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  saveButton,
  cancelButton,
  deleteButton,
  dateLabel,
  officeLabel,
  officesLabel,
  projectLabel,
  rateLabel,
  applyButton,
  clearButton,
  fromLabel,
  toLabel,
  updaterColumn,
  lastUpdateColumn,
  commentLabel,
  newTransactionTitle: {
    id: 'finance.transactions.modal.new.transaction.title.new.transaction',
    defaultMessage: 'New Transaction',
  },
  editTransactionTitle: {
    id: 'finance.transactions.modal.edit.transaction.title.edit.transaction',
    defaultMessage: 'Edit Transaction',
  },
  expenseIncomeTypeLabel: {
    id: 'finance.transactions.modal.label.expense.income.type',
    defaultMessage: 'Expense / Income Type',
  },
  payerRecipientLabel: {
    id: 'finance.transactions.modal.label.payer.recipient.type',
    defaultMessage: 'Payer / Recipient',
  },
  originalAmountLabel: {
    id: 'finance.transactions.modal.label.original.amount',
    defaultMessage: 'Original Amount',
  },
  unifiedAmountLabel: {
    id: 'finance.transactions.modal.label.unified.amount',
    defaultMessage: 'Unified Amount',
  },
  deleteTransactionModalTitle: {
    id: 'finance.transactions.modal.delete.title',
    defaultMessage: 'Are you sure you want to delete this transaction? ',
  },
  operationTypeFilterLabel: {
    id: 'finance.transactions.filter.label.operation.type',
    defaultMessage: 'Operation Type ',
  },
  expenseIncomeFilterLabel: {
    id: 'finance.transactions.filter.label.expense.income',
    defaultMessage: 'Expense/Income',
  },
  payerRecipientFilterLabel: {
    id: 'finance.transactions.filter.label.payer.recipient',
    defaultMessage: 'Payer/Recipient',
  },
  currenciesFilterLabel: {
    id: 'finance.transactions.filter.label.currencies',
    defaultMessage: 'Currencies',
  },
});
