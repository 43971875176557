import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/auth.constants';
import { isAuthenticated } from '../utils/auth.utils';
import { UserInfo } from '../enums/users.enum';
import { DELETE_USER_PHOTO_SUCCESS, POST_USER_PHOTO_SUCCESS } from '../constants/users.constants';
import { validateKeycloakLoginErrors } from '../utils/keycloak.utils';

const initialState = {
  isAuth: isAuthenticated(),
  currentUserInfo: new UserInfo(),
  loading: {
    signin: false,
    signout: false,
    info: false,
  },
  errors: {
    signin: null,
    info: null,
  },
};

const authReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, signin: true },
        errors: { ...state.errors, signin: null },
      };

    case ActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, signin: false },
        errors: { ...state.errors, signin: null },
        isAuth: true,
      };

    case ActionTypes.SIGN_IN_FAILURE: {
      //it may appear when requesting a token via keycloak
      const errorData = action.payload?.response?.data?.message;
      return {
        ...state,
        loading: { ...state.loading, signin: false },
        errors: {
          ...state.errors,
          signin: errorData ? validateKeycloakLoginErrors(errorData) : action.payload.message,
        },
        isAuth: false,
      };
    }

    case ActionTypes.SIGN_OUT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, signout: true },
        errors: { ...state.errors, signout: null },
      };

    case ActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, signout: false },
        errors: { ...state.errors, signout: null },
        isAuth: false,
      };

    case ActionTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, signout: false },
        errors: { ...state.errors, signout: action.payload.message },
        isAuth: false,
      };

    case ActionTypes.GET_CURRENT_USER_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, info: true },
        errors: { ...state.errors, info: null },
      };

    case ActionTypes.GET_CURRENT_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, info: false },
        errors: { ...state.errors, info: null },
        currentUserInfo: new UserInfo(action.payload),
      };

    case ActionTypes.GET_CURRENT_USER_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, info: false },
        errors: { ...state.errors, info: action.payload.message },
      };

    case DELETE_USER_PHOTO_SUCCESS:
      return action.payload.uuid === state.currentUserInfo.id
        ? {
            ...state,
            currentUserInfo: new UserInfo({ ...state.currentUserInfo, photo: null }),
          }
        : state;

    case POST_USER_PHOTO_SUCCESS:
      return action.payload.uuid === state.currentUserInfo.id
        ? {
            ...state,
            currentUserInfo: new UserInfo({ ...state.currentUserInfo, photo: action.payload.results }),
          }
        : state;

    default:
      return state;
  }
};

export default authReducer;
