import { defineMessages } from 'react-intl';
import { newButton, editButton, iconLabel, label, urlLabel, deleteButton } from '../../i18n/defaultMessage';

export default defineMessages({
  newButton,
  iconLabel,
  label,
  urlLabel,
  editButton,
  deleteButton,
  pageTitle: {
    id: 'settings.navigation.title.custom.navigation',
    defaultMessage: 'Custom Navigation',
  },
});
