export const GET_PROJECT_GROUPS_LIST_REQUEST = 'GET_PROJECT_GROUPS_LIST_REQUEST';
export const GET_PROJECT_GROUPS_LIST_SUCCESS = 'GET_PROJECT_GROUPS_LIST_SUCCESS';
export const GET_PROJECT_GROUPS_LIST_FAILURE = 'GET_PROJECT_GROUPS_LIST_FAILURE';

export const POST_PROJECT_GROUP_REQUEST = 'POST_PROJECT_GROUP_REQUEST';
export const POST_PROJECT_GROUP_SUCCESS = 'POST_PROJECT_GROUP_SUCCESS';
export const POST_PROJECT_GROUP_FAILURE = 'POST_PROJECT_GROUP_FAILURE';

export const DELETE_PROJECT_GROUP_REQUEST = 'DELETE_PROJECT_GROUP_REQUEST';
export const DELETE_PROJECT_GROUP_SUCCESS = 'DELETE_PROJECT_GROUP_SUCCESS';
export const DELETE_PROJECT_GROUP_FAILURE = 'DELETE_PROJECT_GROUP_FAILURE';

export const PUT_PROJECT_GROUP_REQUEST = 'PUT_PROJECT_GROUP_REQUEST';
export const PUT_PROJECT_GROUP_SUCCESS = 'PUT_PROJECT_GROUP_SUCCESS';
export const PUT_PROJECT_GROUP_FAILURE = 'PUT_PROJECT_GROUP_FAILURE';

export const SET_PROJECT_GROUPS_PARAMS_REQUEST = 'SET_PROJECT_GROUPS_PARAMS_REQUEST';

export const GET_RESOURCES_LIST_REQUEST = 'GET_RESOURCES_LIST_REQUEST';
export const GET_RESOURCES_LIST_SUCCESS = 'GET_RESOURCES_LIST_SUCCESS';
export const GET_RESOURCES_LIST_FAILURE = 'GET_RESOURCES_LIST_FAILURE';

export const POST_RESOURCES_REQUEST = 'POST_RESOURCES_REQUEST';
export const POST_RESOURCES_SUCCESS = 'POST_RESOURCES_SUCCESS';
export const POST_RESOURCES_FAILURE = 'POST_RESOURCES_FAILURE';

export const SET_RESOURCES_PARAMS_REQUEST = 'SET_RESOURCES_PARAMS_REQUEST';
export const SET_USER_TAB_RESOURCES_PARAMS_REQUEST = 'SET_USER_TAB_RESOURCES_PARAMS_REQUEST';

export const GET_CURRENT_RESOURCE_REQUEST = 'GET_CURRENT_RESOURCE_REQUEST';
export const GET_CURRENT_RESOURCE_SUCCESS = 'GET_CURRENT_RESOURCE_SUCCESS';
export const GET_CURRENT_RESOURCE_FAILURE = 'GET_CURRENT_RESOURCE_FAILURE';

export enum ResourcesLayouts {
  PLANWORKLOAD = 'PLANWORKLOAD',
  PLANFACTWORKLOAD = 'PLANFACTWORKLOAD',
}

export const SET_CURRENT_RESOURCE = 'SET_RESOURCES_PARAMS';

export const GET_USER_HOURS_LIST_REQUEST = 'GET_USER_HOURS_LIST_REQUEST';
export const GET_USER_HOURS_LIST_SUCCESS = 'GET_USER_HOURS_LIST_SUCCESS';
export const GET_USER_HOURS_LIST_FAILURE = 'GET_USER_HOURS_LIST_FAILURE';

export const SET_USER_HOURS_PARAMS_REQUEST = 'SET_USER_HOURS_PARAMS_REQUEST';

export enum PlanningLayouts {
  GENERALIZED = 'GENERALIZED',
  DETAILED = 'DETAILED',
}

export const GET_PROJECT_HOURS_LIST_REQUEST = 'GET_PROJECT_HOURS_LIST_REQUEST';
export const GET_PROJECT_HOURS_LIST_SUCCESS = 'GET_PROJECT_HOURS_LIST_SUCCESS';
export const GET_PROJECT_HOURS_LIST_FAILURE = 'GET_PROJECT_HOURS_LIST_FAILURE';

export const SET_PROJECT_HOURS_PARAMS_REQUEST = 'SET_PROJECT_HOURS_PARAMS_REQUEST';

export const GET_ACTIVE_EMPLOYEES_LIST_REQUEST = 'GET_ACTIVE_EMPLOYEES_LIST_REQUEST';
export const GET_ACTIVE_EMPLOYEES_LIST_SUCCESS = 'GET_ACTIVE_EMPLOYEES_LIST_SUCCESS';
export const GET_ACTIVE_EMPLOYEES_LIST_FAILURE = 'GET_ACTIVE_EMPLOYEES_LIST_FAILURE';

export const PUT_ACTIVE_EMPLOYEES_LIST_REQUEST = 'PUT_ACTIVE_EMPLOYEES_LIST_REQUEST';
export const PUT_ACTIVE_EMPLOYEES_LIST_SUCCESS = 'PUT_ACTIVE_EMPLOYEES_LIST_SUCCESS';
export const PUT_ACTIVE_EMPLOYEES_LIST_FAILURE = 'PUT_ACTIVE_EMPLOYEES_LIST_FAILURE';

export const SET_ACTIVE_EMPLOYEES_PARAMS_REQUEST = 'SET_ACTIVE_EMPLOYEES_PARAMS_REQUEST';

export const RESET_PLANNING_ERRORS = 'RESET_PLANNING_ERRORS';

export const RESET_PLANNING_STATE = 'RESET_PLANNING_STATE';

export const projectHoursUnsavedParams = ['dateFrom', 'dateTo'];
export const resourcesUnsavedParams = ['dateFrom', 'dateTo'];
export const userHoursUnsavedParams = ['dateFrom', 'dateTo'];
