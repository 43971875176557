import { groupBy, last, map, upperFirst } from 'lodash-es';
import { CSSProperties, useContext, useMemo } from 'react';
import { EventInfo, EventPreviewInfo } from '../enums/schedule.enum';
import { isArray } from 'lodash-es';
import moment from 'moment';
import {
  COMMENT_PUBLISHED,
  MonthRepeatType,
  RepeatType,
  REPEAT_DAYS_OF_WEEK,
  ScheduleLayouts,
} from '../constants/schedule.constants';
import { EventTypeInfo } from '../enums/calendar.enum';
import { EventCommentInfo } from '../enums/schedule.enum';
import { UserInfo } from '../enums/users.enum';
import { DATE_FORMAT } from '../constants/date.constants';
import PoliciesContext from '../PoliciesContext';
import {
  DELETE_EVENT,
  DELETE_EVENT_EXTENDED,
  DELETE_PUBLIC_EVENT,
  UPDATE_EVENT,
  UPDATE_EVENT_EXTENDED,
  UPDATE_PUBLIC_EVENT,
} from '../constants/policies.constants';
import { checkPolicies } from './policies.utils';
import { getWeekOfMonth } from '.';
import { UserPolicy } from '../enums/policies.enum';

export const getCommentsContent = (isSecret: boolean, comments: any, results: any, afterDelete: boolean) => {
  const prevContent = afterDelete
    ? comments.content.filter((comment: EventCommentInfo) => comment.commentPage < results.number)
    : comments.content;
  const newContent = results.content.map(
    (comment: EventCommentInfo) => new EventCommentInfo(isSecret, results.number, comment),
  );
  const nextPageNumber = afterDelete ? results.number + 1 : comments.number + 1;

  return {
    ...results,
    content: [
      ...prevContent,
      ...newContent.filter(
        (comment: EventCommentInfo) => !prevContent.find((el: EventCommentInfo) => el.id === comment.id),
      ),
    ],
    number: nextPageNumber,
  };
};

export const getCommentsContentAfterPost = (isSecret: boolean, comments: any, newComments: any) => {
  const updateContent = comments.content.map(
    (comment: EventCommentInfo, index: number) =>
      new EventCommentInfo(isSecret, Math.floor(index / comments.size), comment),
  );
  return {
    ...comments,
    content: [new EventCommentInfo(isSecret, 0, newComments), ...updateContent],
    number: Math.floor((comments.content.length + 1) / comments.size),
    totalElements: comments.totalElements + 1,
  };
};

export const getCommentDate = (date: string) => {
  const today = moment();
  const commentMoment = moment.utc(date);
  const diff = today.diff(commentMoment, 'd');
  const sameDay = today.isSame(commentMoment, 'day');

  let commentPublished = '';
  if (sameDay) {
    commentPublished = COMMENT_PUBLISHED.TODAY;
  } else if (diff === 1 || (diff === 0 && !sameDay)) {
    commentPublished = COMMENT_PUBLISHED.YESTERDAY;
  } else if (today.isSame(commentMoment, 'year')) {
    commentPublished = COMMENT_PUBLISHED.THIS_YEAR;
  }
  return { commentMoment, commentPublished };
};

export const checkAbleToManageEventType = (eventType: EventTypeInfo | undefined, userInfo: UserInfo) =>
  !eventType?.limitedManagerPersonEnabled ||
  eventType?.limitedManagerPersonsIds.some((userId: string) => userId === userInfo.id);

export const useAbleToManageEventTypes = (
  eventTypesData: EventTypeInfo[] = [],
  userInfo: UserInfo,
  fromScheduleUserTab = false,
): EventTypeInfo[] | [] => {
  const userPolicies = useContext(PoliciesContext);

  const canManageAllEventType = checkPolicies([UPDATE_EVENT_EXTENDED, DELETE_EVENT_EXTENDED], userPolicies);
  const types: EventTypeInfo[] = [];
  if (!canManageAllEventType && checkPolicies([UPDATE_EVENT, DELETE_EVENT], userPolicies)) {
    eventTypesData
      .filter(type => !type.isPublicEvent)
      .forEach(eventType => {
        if (fromScheduleUserTab && eventType.targetEmployeesEnabled) {
          return false;
        } else if (checkAbleToManageEventType(eventType, userInfo)) {
          types.push(eventType);
        }
      });
  }
  if (!canManageAllEventType && checkPolicies([UPDATE_PUBLIC_EVENT, DELETE_PUBLIC_EVENT], userPolicies)) {
    eventTypesData
      .filter(type => type.isPublicEvent)
      .forEach(eventType => {
        if (fromScheduleUserTab && eventType.targetEmployeesEnabled) {
          return false;
        } else if (checkAbleToManageEventType(eventType, userInfo)) {
          types.push(eventType);
        }
      });
  }

  return useMemo(() => (canManageAllEventType ? eventTypesData : types), [eventTypesData, userInfo]);
};

const handleAbleManageEvent = (
  event: EventPreviewInfo | EventInfo,
  ableToManageEventTypes: EventTypeInfo[],
  userInfo: UserInfo,
) =>
  ableToManageEventTypes.some(
    (eventType: EventTypeInfo) =>
      (event instanceof EventInfo ? event.eventType.id : event.eventTypeId) === eventType.id,
  ) &&
  (event.author.id === userInfo.id || event.targetEmployee?.id === userInfo.id);

export const handleRenderDropdownListBtn = (
  row: EventPreviewInfo | EventInfo,
  ableToManageEventTypes: EventTypeInfo[],
  userInfo: UserInfo,
  policies: UserPolicy[],
  isUpdateBtn: boolean,
  eventTypes: EventTypeInfo[],
) => {
  if (checkPolicies([isUpdateBtn ? UPDATE_EVENT_EXTENDED : DELETE_EVENT_EXTENDED], policies)) {
    return true;
  }

  if (
    row.isPublic &&
    checkPolicies([isUpdateBtn ? UPDATE_PUBLIC_EVENT : DELETE_PUBLIC_EVENT], policies) &&
    checkAbleToManageEventType(
      //@ts-ignore
      row?.eventType ? row.eventType : eventTypes.find(type => type.id === row.eventTypeId),
      userInfo,
    ) &&
    (row.author.id === userInfo.id || row.targetEmployee?.id === userInfo.id)
  ) {
    return true;
  }

  if (
    checkPolicies([isUpdateBtn ? UPDATE_EVENT : DELETE_EVENT], policies) &&
    handleAbleManageEvent(row, ableToManageEventTypes, userInfo)
  ) {
    return true;
  }
  return false;
};

export const handleEventPopUpChangePosition = (
  e: React.MouseEvent,
  cb: (css: CSSProperties) => void,
  popUpRef: any,
  view?: ScheduleLayouts,
) => {
  const popUp = popUpRef.current;

  if (popUp) {
    const DEFAULT_POP_UP_SPACING = 5;
    const popUpHeight = popUp.clientHeight;
    const popUpWidth = popUp.clientWidth;

    let popUpMarginLeft = e.clientX;
    let popUpMarginTop = e.clientY;

    const btn = e.target as HTMLElement;
    const rect = btn.getBoundingClientRect();
    const rectLeft = rect.left;
    const rectRigth = rect.right;
    const rectHeight = rect.height;
    const scrollWidth = document.documentElement.scrollWidth;

    const scrollerWrapper = last(document.querySelectorAll('.fc-scroller'));

    let rectTop = rect.top;
    //@ts-ignore
    if (view === ScheduleLayouts.CALENDAR && scrollerWrapper && scrollerWrapper?.clientHeight < rectHeight) {
      rectTop += scrollerWrapper.scrollTop;
    } else {
      rectTop += window.scrollY;
    }

    if (rect.left > popUpWidth + DEFAULT_POP_UP_SPACING) {
      popUpMarginLeft = rectLeft - popUpWidth - DEFAULT_POP_UP_SPACING;
    } else if (rectRigth + popUpWidth + DEFAULT_POP_UP_SPACING < scrollWidth) {
      popUpMarginLeft = rect.right + DEFAULT_POP_UP_SPACING;
    } else {
      popUpMarginLeft = rectLeft + rect.width / 2 - popUpWidth / 2;
    }

    const isBigEvent =
      rectLeft - DEFAULT_POP_UP_SPACING < popUpWidth && rectRigth + DEFAULT_POP_UP_SPACING + popUpWidth > scrollWidth;

    if (rectTop > popUpHeight / 2 && rectTop + popUpHeight / 2 < window.innerHeight && !isBigEvent) {
      popUpMarginTop = rectTop - popUpHeight / 2;
    } else if (rectTop + rectHeight + popUpHeight < window.innerHeight) {
      popUpMarginTop = isBigEvent ? rectTop + DEFAULT_POP_UP_SPACING + rectHeight : rectTop - rect.height;
    } else if (rectTop - DEFAULT_POP_UP_SPACING > popUpHeight) {
      const top = rectTop - popUpHeight;
      popUpMarginTop = isBigEvent ? top : top + DEFAULT_POP_UP_SPACING;
    } else {
      popUpMarginTop = window.scrollY + 5;
    }

    cb({ marginLeft: popUpMarginLeft, marginTop: popUpMarginTop });
  }
};

export const handleEventsPopUpChangePosition = (
  e: React.MouseEvent,
  cb: (css: CSSProperties) => void,
  popUpRef: any,
) => {
  const popUp = popUpRef.current;

  if (popUp) {
    const btn = e.target as HTMLElement;
    const DEFAULT_POP_UP_SPACING = 5;

    const popUpHeight = popUp.clientHeight;
    const popUpWidth = popUp.clientWidth;

    const rect = btn.getBoundingClientRect();

    const rectLeft = rect.left;
    const rectRigth = rect.right;
    const windowHalfSize = document.documentElement.scrollWidth / 2;

    let popUpMarginLeft = rectLeft;
    let popUpMarginTop = rect.top;

    if (rectRigth < windowHalfSize) {
      popUpMarginLeft = rectRigth + DEFAULT_POP_UP_SPACING;
    } else if (rectLeft - popUpWidth - DEFAULT_POP_UP_SPACING > 0) {
      popUpMarginLeft = rectLeft - popUpWidth - DEFAULT_POP_UP_SPACING;
    } else {
      popUpMarginLeft = (rectRigth + rectLeft) / 2 - popUpWidth / 2;
      popUpMarginTop += rect.height + DEFAULT_POP_UP_SPACING;
    }

    if (popUpMarginTop + popUpHeight + DEFAULT_POP_UP_SPACING > window.innerHeight) {
      popUpMarginTop = window.innerHeight - popUpHeight - DEFAULT_POP_UP_SPACING * 5;
    }

    cb({ marginLeft: popUpMarginLeft, marginTop: popUpMarginTop + window.scrollY });
  }
};

export function getMonths(date: string) {
  const year = moment(date).year();
  const months = [];

  for (let i = 0; i <= 11; i++) {
    months.push(getDaysArrayByMonth(year, i));
  }
  return months;
}

function getDaysArrayByMonth(year: number, month: number) {
  const currentMonth = moment().set({ year, month });
  let daysInMonth = currentMonth.daysInMonth();
  const monthDays = [];

  while (daysInMonth) {
    const current = currentMonth.date(daysInMonth).format(DATE_FORMAT.YYYY_MM_DD);
    monthDays.push(current);
    daysInMonth--;
  }

  return {
    monthDays: monthDays.reverse(),
    monthName: currentMonth.format(DATE_FORMAT.MMMM),
  };
}

export const getHolidaysData = (holidaysEvent: EventPreviewInfo[]) => {
  const arr: any = [];
  holidaysEvent.forEach(holidayEvent => {
    holidayEvent.locations.forEach(holidayLocation => {
      const event: any = { ...holidayEvent };
      event.locations = holidayLocation;
      arr.push(event);
    });
  });

  const test = groupBy(arr, el => el.locations.id);
  const holidaysData = map(test, el => {
    return {
      locationName: el[0].locations.name,
      locationId: el[0].locations.id,
      holidays: el.map(event => new EventPreviewInfo(event)),
    };
  });
  return holidaysData;
};

export const getDaysOffContent = (daysOffInfo: { extraWorkEvents: any; holidaysEvents: any }) => {
  return {
    extraWorkDates: daysOffInfo.extraWorkEvents.map((el: EventPreviewInfo) => ({
      start: el.startDate,
      end: el.endDate,
      locations: el.locations,
    })),
    holidaysDates: daysOffInfo.holidaysEvents.map((el: EventPreviewInfo) => ({
      start: el.startDate,
      end: el.endDate,
      locations: el.locations,
    })),
  };
};

export const validateEventErrors = (error: string | string[]) => {
  if (isArray(error)) {
    return error;
  } else {
    if (error.includes('Office')) {
      return `${error.split('by')[0]} by another event`;
    }
    return error;
  }
};

export const getRepeaterLabel = (
  unitOfRepeat: RepeatType | null,
  repeatInterval: number,
  startDate: string,
  monthRepeatType: MonthRepeatType | null,
  repeatDaysOfWeek: string[],
) => {
  switch (unitOfRepeat) {
    case RepeatType.DAYS: {
      if (repeatInterval === 1) {
        return 'Daily';
      } else {
        return `Every ${repeatInterval} days`;
      }
    }

    case RepeatType.WEEKS: {
      const repeatDays = REPEAT_DAYS_OF_WEEK.filter(day => repeatDaysOfWeek.some((el: string) => el === day))
        .map(day => upperFirst(day.toLocaleLowerCase()))
        .join(', ');
      if (repeatInterval === 1) {
        return `Weekly on ${repeatDays}`;
      } else {
        return `Every ${repeatInterval} weeks on ${repeatDays}`;
      }
    }

    case RepeatType.MONTHS: {
      if (monthRepeatType === MonthRepeatType.SAME_WEEK_AND_WEEK_DAY) {
        const weekOfMonth = `${getWeekOfMonth(new Date(moment(startDate).locale('en-US').toDate()))} ${moment(startDate)
          .locale('en-US')
          .format(DATE_FORMAT.dddd)}`;
        if (repeatInterval === 1) {
          return `Monthly on the ${weekOfMonth}`;
        } else {
          return `Every ${repeatInterval} months on the ${weekOfMonth}`;
        }
      } else {
        const weekDay = moment(startDate).locale('en-US').format(DATE_FORMAT.D);
        if (repeatInterval === 1) {
          return `Montly on day ${weekDay}`;
        } else {
          return `Every ${repeatInterval} months on day ${weekDay}`;
        }
      }
    }

    case RepeatType.YEARS: {
      return `Yearly on the ${moment(startDate).locale('en-US').format(DATE_FORMAT.MMMM_D)}`;
    }

    default: {
      return 'Daily';
    }
  }
};
