import { defineMessages } from 'react-intl';
import { skillsColumn, scoreColumn, targetMemberLabel } from '../../../i18n/defaultMessage';

export default defineMessages({
  skillsColumn,
  scoreColumn,
  targetMemberLabel,
  pageTitle: {
    id: 'competencies.assessments.info.title.assessment',
    defaultMessage: 'Assessment',
  },
  organizersTitle: {
    id: 'competencies.assessments.info.title.organizers',
    defaultMessage: 'Organizers',
  },
  reviewersTitle: {
    id: 'competencies.assessments.info.title.reviewers',
    defaultMessage: 'Reviewers',
  },

  assessmentsLabel: {
    id: 'competencies.assessments.info.label.assessments',
    defaultMessage: 'Assessments',
  },
});
