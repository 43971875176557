import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { isEmpty } from 'lodash-es';

type ListRecordsBackgroundProps = {
  data: { id: string; name: string }[];
  recordLimit?: number;
};

const ListRecordsBackground = ({ data, recordLimit }: ListRecordsBackgroundProps) => {
  const visibleValue = recordLimit ? data.slice(0, recordLimit) : data;
  const hasMoreValue = recordLimit ? data.length > recordLimit : false;
  return !isEmpty(data) ? (
    <>
      <div className="tabs__content-item__about-info-value">
        {visibleValue.map(item => (
          <div key={item.id} className="tabs__content-item__about-info-value__subitem">
            {item.name}
          </div>
        ))}
        {hasMoreValue && (
          <div className="tabs__content-item__about-info-value__subitem">
            {data.length - 4} <FormattedMessage {...messages.moreLabel} />
          </div>
        )}
      </div>
    </>
  ) : null;
};

export default ListRecordsBackground;
