import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as filtersActions from '../../actions/filters.actions';
import * as scheduleActions from '../../actions/schedule.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { useDataForTable } from './useDataForTable';
import DaysUsedLimitFilter from '../../components/DaysUsedLimit/Filter/DaysUsedLimitFilter';
import { DaysUsedLimitParams } from '../../enums/params/schedule.params';
import InlineDatePicker from '../../components/InlineDatePicker';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';
import { resetParamsChange, useParamsChange, useTableData } from '../../utils/hooks.utils';
import HierarchicalTable from '../../components/HierarchicalTable';
import { InlineDatePickerPeriods } from '../../components/InlineDatePicker/constants';
import DurationPicker from '../../components/Dropdown';
import Icon from '../../components/Icon';
import { exportDaysUsedLimit } from '../../actions/export.actions';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { daysLimitUnsavedParams } from '../../constants/schedule.constants';

function DaysUsedLimit({
  tableData,
  isLoading,
  errors,
  getEventTypesFilter,
  getOfficesFilter,
  getUsersFilter,
  getDepartmentsFilter,
  resetState,
  resetErrors,
  params,
  departmentsFilter,
  eventTypesFilter,
  officesFilter,
  usersFilter,
  setParams,
  editDaysLimit,
  exportDaysUsedLimit,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const handleMultiParamsChange = useParamsChange(setParams, dispatch);
  const intl = useIntl();

  const filters = useMemo(
    () => ({
      departments: departmentsFilter.departments,
      eventTypes: eventTypesFilter.eventTypes,
      offices: officesFilter.offices,
      users: usersFilter.users,
    }),
    [departmentsFilter.departments, eventTypesFilter.eventTypes, officesFilter.offices, usersFilter.users],
  );

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.DAYS_USED_LIMIT_FILTER);

    setParams(
      currentSavedFilter
        ? new DaysUsedLimitParams(currentSavedFilter)
        : {
            offices: officesFilter.value,
            departments: departmentsFilter.value,
            users: usersFilter.value,
            eventTypes: eventTypesFilter.value,
          },
    );
    getEventTypesFilter();
    getOfficesFilter();
    getUsersFilter();
    getDepartmentsFilter();
    setSavedFiltersParams({ filterType: FilterTypes.DAYS_USED_LIMIT_FILTER });

    return () => {
      resetState();
    };
  }, []);

  const onDateChange = (start: string, end: string) => {
    setParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  };

  const { tableColumns } = useDataForTable(
    isLoading.putDaysUsedLimit,
    editDaysLimit,
    params.dateFrom,
    errors,
    resetErrors,
  );

  const pageHeader = document.getElementById('days-used-limit-page-header');
  const pageHeaderHeight = pageHeader?.offsetHeight;

  const handleFiltersControlChange = useCallback(
    value => {
      setParams(
        new DaysUsedLimitParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setParams(new DaysUsedLimitParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));

    resetParamsChange(
      [FilterParamsName.EVENT_TYPES, FilterParamsName.USERS, FilterParamsName.DEPARTMENTS, FilterParamsName.OFFICES],
      dispatch,
    );
  }, [params]);

  const showClearButton = useMemo(() => !checkParamsMatch(params, new DaysUsedLimitParams(), daysLimitUnsavedParams), [
    params,
  ]);

  return (
    <>
      <div className="page__panel page__panel--fixed" id="days-used-limit-page-header">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={onDateChange}
                  defaultPeriod={InlineDatePickerPeriods.YEAR_PERIOD}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                  isCustomDatePicker={false}
                  hidePeriodSelector
                  hideTodayButton
                />
                <DurationPicker
                  dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                  dropdownList={[
                    { label: intl.formatMessage(messages.exportToXLSLabel), handler: exportDaysUsedLimit },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <DaysUsedLimitFilter
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ maxHeight: `calc(100vh - (${pageHeaderHeight}px + 95px))` }}>
        <div className="page__wrapper">
          <HierarchicalTable
            tableColumns={tableColumns}
            tableData={useTableData(tableData, ['eventTypeAbsenceInfos'])}
            loading={isLoading.getDaysUsedLimit}
            error={errors}
            externalClass={'days-used-limit-table'}
            tbodyStyle={{ height: `calc(100vh - (${pageHeaderHeight}px + 151px))` }}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ schedule, filters, auth }: RootState) => ({
  errors: schedule.errors.daysUsedLimitError,
  isLoading: schedule.loading,
  tableData: schedule.daysUsedLimitList,
  params: schedule.daysUsedLimitParams,
  eventTypesFilter: filters.eventTypesFilter,
  officesFilter: filters.officesFilter,
  usersFilter: filters?.usersFilter,
  departmentsFilter: filters.departmentsFilter,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  editDaysLimit: (data: any, cb: () => void) => dispatch(scheduleActions.editDaysLimit(data, cb)),
  setParams: (data: Partial<DaysUsedLimitParams>) => dispatch(scheduleActions.setDaysUsedLimitParams(data)),
  resetState: () => dispatch(scheduleActions.resetState()),
  resetErrors: () => dispatch(scheduleActions.resetErrors()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  getEventTypesFilter: () => dispatch(filtersActions.getEventTypesFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  exportDaysUsedLimit: () => dispatch(exportDaysUsedLimit()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DaysUsedLimit);
