import { getUserPolicies } from './../utils/policies.utils';
import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../constants/schedule.constants';
import * as api from '../api/schedule.api';
import { getEventTypeById } from '../api/calendar.api';
import { AnyAction } from 'redux';
import * as types from '../types/schedule/scheduleSaga.type';
import { DaysUsedLimitParams, EventsParams } from '../enums/params/schedule.params';
import { getQueryParams } from '../utils/params.utils';
import { checkPolicies } from '../utils/policies.utils';
import { VIEW_EVENT_EXTENDED } from '../constants/policies.constants';
import moment from 'moment';
import { EventPreviewInfo } from '../enums/schedule.enum';
import { UserPolicy } from '../enums/policies.enum';

function* getEventsList(searchParams?: any) {
  const payload: { params: Partial<EventsParams>; cb?: () => void } = searchParams?.payload;
  const stateParams: EventsParams = yield select((state: RootState) => state.schedule.params);
  const params: EventsParams = payload?.params ? new EventsParams({ ...stateParams, ...payload.params }) : stateParams;
  const authUserId: string = yield select((state: RootState) => state.auth.currentUserInfo.id);
  const policies: UserPolicy[] = yield select((state: RootState) => state.policies.authUserPolicies) ||
    getUserPolicies();

  const data = getQueryParams(params);
  const canViewAllEvents = checkPolicies([VIEW_EVENT_EXTENDED], policies);

  if (!canViewAllEvents) {
    data.users = [...data.users, authUserId];
    data.isSearchByAuthor = true;
  }

  try {
    const { success, results } = yield call(api.getEventsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_EVENTS_LIST_SUCCESS,
        payload: results,
      });
      payload?.cb && payload.cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENTS_LIST_FAILURE, payload: error });
  }
}

function* getUserScheduleTabEventsList({ payload }: AnyAction) {
  const params: EventsParams = yield select((state: RootState) => state.schedule.userScheduleTabParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getEventsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_EVENTS_LIST_SUCCESS,
        payload: results,
      });
      payload?.cb && payload.cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENTS_LIST_FAILURE, payload: error });
  }
}

function* getHolidays(searchParams?: any) {
  const payload = searchParams?.payload;
  const params = new EventsParams(
    payload
      ? payload
      : {
          dateTimeFrom: moment().startOf('year').format(),
          dateTimeTo: moment().endOf('year').format(),
          size: 1000,
          holiday: true,
        },
  );
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getEventsList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_HOLIDAYS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_HOLIDAYS_FAILURE, payload: error });
  }
}

function* getDaysOffInfo() {
  const params: EventsParams = yield select((state: RootState) => state.schedule.params);
  const offDaysParams = { dateTimeFrom: params.dateTimeFrom, dateTimeTo: params.dateTimeTo, offices: params.offices };
  const holidayData = getQueryParams(new EventsParams({ ...offDaysParams, holiday: true, size: 1000 }));
  const extraWorkData = getQueryParams(new EventsParams({ ...offDaysParams, extraDay: true, size: 1000 }));

  try {
    const { success: getHolidaysSuccess, results: holidaysEvents } = yield call(api.getEventsList, holidayData);
    const { success: getExtraWorkSuccess, results: extraWorkEvents } = yield call(api.getEventsList, extraWorkData);
    if (getHolidaysSuccess && getExtraWorkSuccess) {
      yield put({
        type: ActionTypes.GET_DAYS_OFF_INFO_SUCCESS,
        payload: {
          holidaysEvents: holidaysEvents?.content?.map((event: any) => new EventPreviewInfo(event)),
          extraWorkEvents: extraWorkEvents?.content?.map((event: any) => new EventPreviewInfo(event)),
        },
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DAYS_OFF_INFO_FAILURE, payload: error });
  }
}

function* getAllEvents({ payload }: AnyAction) {
  try {
    const params = { dateTimeFrom: '', dateTimeTo: '', ...payload };
    const { success, results } = yield call(api.getEventsList, params);
    if (success) {
      yield put({
        type: ActionTypes.GET_EVENTS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENTS_LIST_FAILURE, payload: error });
  }
}

function* getEvent({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getEvent, payload.id || payload);
    if (success) {
      yield put({
        type: ActionTypes.GET_EVENT_SUCCESS,
        payload: results,
      });
      payload?.cb && payload.cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENT_FAILURE, payload: error });
  }
}

function* getEventType({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(getEventTypeById, payload);
    if (success) {
      yield put({
        type: ActionTypes.GET_EVENT_TYPE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENT_TYPE_FAILURE, payload: error });
  }
}

function* createNewEvent({ payload }: AnyAction): any {
  try {
    const ap = yield call(api.createNewEvent, payload.data);

    if (ap.success) {
      yield put({ type: ActionTypes.POST_EVENT_SUCCESS });
      payload.callback();
      if (!payload.isTabRequest) {
        yield call(getEventsList);
      }
      yield call(getHolidays);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_EVENT_FAILURE, payload: error });
  }
}

function* editEvent({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.editEvent, payload.id, payload.data, payload.updateNext);
    let updatedEvent: any = null;
    const { isGetEventsList, isGetEvent, pushToNewEvent } = payload;
    if (!isGetEventsList && !isGetEvent && !pushToNewEvent) {
      //@ts-ignore
      updatedEvent = yield call(api.getEvent, payload.id);
    }

    if (success) {
      yield put({
        type: ActionTypes.PUT_EVENT_SUCCESS,
        payload: updatedEvent ? updatedEvent.results : null,
      });
      yield call(getHolidays);
      payload.callback();
      if (isGetEvent) {
        yield put({ type: ActionTypes.GET_EVENT_REQUEST, payload: results.id });
      }
      if (isGetEventsList) {
        yield call(getEventsList);
      }
      payload.updateNext && pushToNewEvent && pushToNewEvent(results.id);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_EVENT_FAILURE, payload: error });
  }
}

function* deleteEvent({ payload }: AnyAction) {
  try {
    const fromDetailedPage = payload.fromDetailedPage;
    const { success } = yield call(api.deleteEvent, payload.id, payload.deleteNext, payload.sendNotification);
    if (success) {
      yield put({ type: ActionTypes.DELETE_EVENT_SUCCESS, payload: fromDetailedPage ? null : payload.id });
      payload.callback();
      yield call(getHolidays);
      if (payload.isGetEventsList) {
        yield call(getEventsList);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_EVENT_FAILURE, payload: error });
  }
}

function* setEventsParams({ payload }: AnyAction) {
  //delay to avoid loading every request with a quick change of parameters

  if (payload.isGetEventsList) {
    yield delay(500);
    yield put({ type: ActionTypes.GET_EVENTS_LIST_REQUEST });
  }
}

function* getEventComments({ payload }: types.GetEventCommentsSagaType) {
  const { eventId, params, afterDelete = false } = payload;
  const commentsParams = { ...params, isSecret: false, sort: 'createdDate,desc' };
  try {
    const { success, results } = yield call(api.getEventComments, eventId, commentsParams);
    if (success) {
      yield put({
        type: ActionTypes.GET_EVENT_COMMENTS_SUCCESS,
        payload: { results, afterDelete },
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENT_COMMENTS_FAILURE, payload: error });
  }
}

function* getEventSecretComments({ payload }: types.GetEventSecretCommentsSagaType) {
  const { eventId, params, afterDelete = false } = payload;
  const commentsParams = { ...params, isSecret: true, sort: 'createdDate,desc' };
  try {
    const { success, results } = yield call(api.getEventComments, eventId, commentsParams);
    if (success) {
      yield put({
        type: ActionTypes.GET_EVENT_SECRET_COMMENTS_SUCCESS,
        payload: { results, afterDelete },
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EVENT_SECRET_COMMENTS_FAILURE, payload: error });
  }
}

function* createEventComment({ payload }: types.CreateEventCommentSagaType) {
  const { eventId, data, cb } = payload;

  try {
    const { success, results } = yield call(api.createEventComment, eventId, data);
    if (success) {
      yield put({
        type: ActionTypes.POST_EVENT_COMMENT_SUCCESS,
        payload: results,
      });

      cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_EVENT_COMMENT_FAILURE, payload: error });
  }
}

function* createEventSecretComment({ payload }: types.CreateEventSecretCommentSagaType) {
  const { eventId, data, cb } = payload;

  try {
    const { success, results } = yield call(api.createEventComment, eventId, data);
    if (success) {
      yield put({
        type: ActionTypes.POST_EVENT_SECRET_COMMENT_SUCCESS,
        payload: results,
      });

      cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_EVENT_SECRET_COMMENT_FAILURE, payload: error });
  }
}

function* editEventComment({ payload }: types.EditEventCommentSagaType) {
  const { uuid, data, authorUuid, cb } = payload;
  try {
    const { success, results } = yield call(api.editEventComment, uuid, data, authorUuid);
    if (success) {
      yield put({
        type: ActionTypes.PUT_EVENT_COMMENT_SUCCESS,
        payload: results,
      });

      cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_EVENT_COMMENT_FAILURE, payload: error });
  }
}

function* editEventSecretComment({ payload }: types.EditEventSecretCommentSagaType) {
  const { uuid, data, authorUuid, cb } = payload;
  try {
    const { success, results } = yield call(api.editEventComment, uuid, data, authorUuid);
    if (success) {
      yield put({
        type: ActionTypes.PUT_EVENT_SECRET_COMMENT_SUCCESS,
        payload: results,
      });

      cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_EVENT_SECRET_COMMENT_FAILURE, payload: error });
  }
}

function* deleteEventComment({ payload }: types.DeleteEventCommentSagaType) {
  const { uuid, cb, eventId, authorUuid, size, commentPage } = payload;
  try {
    const { success } = yield call(api.deleteEventComment, uuid, authorUuid);
    if (success) {
      yield put({ type: ActionTypes.DELETE_EVENT_COMMENT_SUCCESS, payload: uuid });
      yield put({
        type: ActionTypes.GET_EVENT_COMMENTS_REQUEST,
        payload: {
          eventId,
          afterDelete: true,
          params: {
            page: commentPage,
            size,
          },
        },
      });
      cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_EVENT_COMMENT_FAILURE, payload: error });
  }
}

function* deleteEventSecretComment({ payload }: types.DeleteEventSecretCommentSagaType) {
  const { uuid, cb, eventId, authorUuid, size, commentPage } = payload;

  try {
    const { success } = yield call(api.deleteEventComment, uuid, authorUuid);
    if (success) {
      yield put({ type: ActionTypes.DELETE_EVENT_SECRET_COMMENT_SUCCESS, payload: uuid });
      yield put({
        type: ActionTypes.GET_EVENT_SECRET_COMMENTS_REQUEST,
        payload: {
          eventId,
          afterDelete: true,
          params: {
            page: commentPage,
            size,
          },
        },
      });
      cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_EVENT_SECRET_COMMENT_FAILURE, payload: error });
  }
}

function* getDaysUsedLimit() {
  const params: DaysUsedLimitParams = yield select((state: RootState) => state.schedule.daysUsedLimitParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getDaysUsedLimit, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_DAYS_USED_LIMIT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DAYS_USED_LIMIT_FAILURE, payload: error });
  }
}

function* editDaysUsedLimit({ payload }: any) {
  try {
    const { data, cb } = payload;
    const { success, results } = yield call(data.modifiedBy === 'AUTO' ? api.createDaysLimit : api.editDaysLimit, data);
    if (success) {
      yield put({ type: ActionTypes.PUT_DAYS_LIMIT_SUCCESS, payload: results });
      yield call(getDaysUsedLimit);
      cb && cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_DAYS_LIMIT_FAILURE, payload: error });
  }
}

export default function* mainSaga() {
  yield all([
    takeLatest(ActionTypes.GET_EVENTS_LIST_REQUEST, getEventsList),
    takeLatest([ActionTypes.GET_HOLIDAYS_REQUEST], getHolidays),
    takeLatest(
      [ActionTypes.GET_DAYS_OFF_INFO_REQUEST, ActionTypes.POST_EVENT_SUCCESS, ActionTypes.PUT_EVENT_SUCCESS],
      getDaysOffInfo,
    ),
    takeEvery(ActionTypes.GET_ALL_EVENTS, getAllEvents),
    takeEvery(ActionTypes.GET_EVENT_REQUEST, getEvent),
    takeLatest(ActionTypes.SET_EVENT_PARAMS_REQUEST, setEventsParams),
    takeLatest(ActionTypes.GET_USER_SCHEDULE_TAB_EVENTS_LIST_REQUEST, getUserScheduleTabEventsList),
    takeLatest(ActionTypes.SET_USER_SCHEDULE_TAB_PARAMS_REQUEST, getUserScheduleTabEventsList),
    takeEvery(ActionTypes.GET_EVENT_TYPE_REQUEST, getEventType),
    takeEvery(ActionTypes.POST_EVENT_REQUEST, createNewEvent),
    takeEvery(ActionTypes.DELETE_EVENT_REQUEST, deleteEvent),
    takeEvery(ActionTypes.PUT_EVENT_REQUEST, editEvent),
    takeEvery(ActionTypes.GET_EVENT_COMMENTS_REQUEST, getEventComments),
    takeEvery(ActionTypes.GET_EVENT_SECRET_COMMENTS_REQUEST, getEventSecretComments),
    takeEvery(ActionTypes.POST_EVENT_COMMENT_REQUEST, createEventComment),
    takeEvery(ActionTypes.POST_EVENT_SECRET_COMMENT_REQUEST, createEventSecretComment),
    takeEvery(ActionTypes.PUT_EVENT_COMMENT_REQUEST, editEventComment),
    takeEvery(ActionTypes.PUT_EVENT_SECRET_COMMENT_REQUEST, editEventSecretComment),
    takeEvery(ActionTypes.DELETE_EVENT_COMMENT_REQUEST, deleteEventComment),
    takeEvery(ActionTypes.DELETE_EVENT_SECRET_COMMENT_REQUEST, deleteEventSecretComment),
    takeEvery(ActionTypes.SET_DAYS_USED_LIMIT_PARAMS, getDaysUsedLimit),
    takeEvery(ActionTypes.PUT_DAYS_LIMIT_REQUEST, editDaysUsedLimit),
  ]);
}
