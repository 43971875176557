import { defineMessages } from 'react-intl';
import { nameColumn, editButton, deleteButton, newButton, projectsLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  projectsLabel,
});
