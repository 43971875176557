import * as paramsTypes from './../enums/params/recognitions.params';
import { QueryParamsType } from '../utils/params.utils';
import request from '../utils/request.utils';

export function getRecognitionTypeList(params?: QueryParamsType<paramsTypes.RecognitionTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/recognitionTypes',
    params: {
      size: 1000,
      ...params,
    },
  };
  return request(options);
}

export function createNewRecognitionType(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/recognitionTypes',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  };
  return request(options);
}

export function deleteRecognitionType(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/recognitionTypes/${id}`,
  };
  return request(options);
}

export function editRecognitionType(id: string, data: any) {
  const options: RequestConfig = {
    method: 'patch',
    url: `/recognitionTypes/${id}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  };
  return request(options);
}

export function getAllRecognitions(params?: QueryParamsType<paramsTypes.RecognitionsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/recognitions',
    params,
  };
  return request(options);
}

export function deleteRecognition(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/recognitions/${id}`,
  };
  return request(options);
}

export function editRecognition(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/recognitions/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getRecognitionByUser(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/recognitions/${id}`,
  };
  return request(options);
}

export function createUserRecognition(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: `/recognitions`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}
