import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import ErrorMessage from '../../ErrorMessage';
import { EXPENSE_TYPE_SCHEMA } from '../../../enums/finance/finance.enum';
import Select from '../../Select';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { ExpenseType } from '../../../types/finance';
import { scrollToError } from '../../../utils';

type ModalNewExpenseTypeProps = {
  onCloseRequest: () => void;
  createExpenseType: (data: { data: ExpenseType; callback: () => void }) => void;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
};

function ModalNewExpenseType({
  onCloseRequest,
  createExpenseType,
  error,
  isLoading,
  isOpen,
}: ModalNewExpenseTypeProps) {
  const intl = useIntl();

  const expenseTypeOptions = [
    {
      label: intl.formatMessage(messages.personalLabel),
      value: true,
    },
    {
      label: intl.formatMessage(messages.companyLabel),
      value: false,
    },
  ];

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: {
      name: '',
      id: '',
      isPersonal: false,
    },
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: EXPENSE_TYPE_SCHEMA,
    onSubmit: data => {
      return createExpenseType({ data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleTypeChange = useCallback(el => {
    setFieldValue('isPersonal', el.value);
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.newExpenseTypeTitle)}
        size={'small'}
        classNameModal="center"
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages.nameColumn)}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
            <div className="form__input-block form__input-block--half">
              <Select
                name="isPersonal"
                options={expenseTypeOptions}
                handleChange={handleTypeChange}
                label={intl.formatMessage(messages.typeLabel)}
                value={expenseTypeOptions.find(item => item.value === values.isPersonal)}
              />
            </div>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              onClick={onCloseRequest}
              type={'button'}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalNewExpenseType;
