import { defineMessages } from 'react-intl';
import {
  cancelButton,
  deleteModalWithItemNameTitle,
  deleteButton,
  exportButton,
  employeesLabel,
  statusLabel,
  officesLabel,
  nameColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteModalWithItemNameTitle,
  deleteButton,
  exportButton,
  employeesLabel,
  statusLabel,
  officesLabel,
  nameColumn,
  organizersSelect: {
    id: 'questionnaires.poll.form.new.poll.label.category',
    defaultMessage: 'Organizers',
  },
  categorySelect: {
    id: 'questionnaires.poll.form.new.poll.label.category',
    defaultMessage: 'Category',
  },
  templateSelect: {
    id: 'questionnaires.poll.form.new.poll.label.template',
    defaultMessage: 'Template',
  },
  pollNameInput: {
    id: 'questionnaires.poll.form.new.poll.name.input',
    defaultMessage: 'Poll Name',
  },
  anonymousMode: {
    id: 'questionnaires.poll.form.poll.anonymous.mode',
    defaultMessage: 'Anonymous Mode (hide responders in results)',
  },
  generalMode: {
    id: 'questionnaires.poll.form.poll.general.mode',
    defaultMessage: 'General Mode',
  },
  respondersSelect: {
    id: 'questionnaires.poll.form.new.polls.responders.select',
    defaultMessage: 'Responders',
  },
  relatedEventSelect: {
    id: 'questionnaires.poll.form.new.poll.related.event',
    defaultMessage: 'Related Event',
  },
  sendButton: {
    id: 'questionnaires.poll.form.new.poll.send.button',
    defaultMessage: 'Send',
  },
  modalTitle: {
    id: 'questionnaires.poll.form.new.poll.title',
    defaultMessage: 'New Poll',
  },
  modalEditTitle: {
    id: 'questionnaires.poll.form.edit.poll.title',
    defaultMessage: 'Edit Poll',
  },
  filterCategoriesLabel: {
    id: 'questionnaires.filter.categories.label',
    defaultMessage: 'Categories',
  },
  filterLocationsLabel: {
    id: 'questionnaires.filter.locations.label',
    defaultMessage: 'Locations',
  },
  descriptionLabel: {
    id: 'questionnaires.description.label',
    defaultMessage: 'Description',
  },
  deadlineLabel: {
    id: 'questionaires.deadline.label',
    defaultMessage: 'Deadline',
  },
  allOffices: {
    id: 'questionaires.poll.form.polls.offices.all',
    defaultMessage: 'All Offices',
  },
  allResponders: {
    id: 'questionaires.poll.form.polls.responders.all',
    defaultMessage: 'All Responders',
  },
  exportTitle: {
    id: 'questionaires.poll.export.title',
    defaultMessage: 'Export',
  },
  fileTypeLabel: {
    id: 'questionaires.poll.export.file.type.label',
    defaultMessage: 'File Type',
  },
  exportTypeLabel: {
    id: 'questionaires.poll.export.export.type.label',
    defaultMessage: 'Export Type',
  },
  changeTemplateLabel: {
    id: 'questionaires.poll.change.template.label',
    defaultMessage: 'Template for this poll was changed, select another one',
  },
});
