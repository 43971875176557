import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { isEmpty, isFunction } from 'lodash-es';
import { ReactSortable } from 'react-sortablejs';
import { useTable, useExpanded } from 'react-table';
import { SortParams } from '../../enums/params.enum';
import Icon from '../Icon';
import { DepthLevelsType } from '../../constants/tables.constants';
import { userHintId } from '../Hint/utils';
import Hint from '../Hint';

export type TableColumnType = {
  Header: any;
  id?: string;
  accessor?: string;
  headClassName?: string;
  sortName?: string;
  Cell?: (row: any) => React.ReactElement | null | string;
  externalColumnClass?: () => string;
  externalColumnStyles?: () => React.CSSProperties;
  headerStyles?: React.CSSProperties;
};

type HierarchicalTableProps = {
  tableData: any;
  tableColumns: TableColumnType[];
  externalClass?: string;
  loading: boolean;
  error: string | null;
  params?: SortParams;
  sortable?: boolean;
  dragAndDropsDepth?: number[];
  tableHeaderItems?: {
    name: string;
    className: string;
    colspan?: number;
    rowspan?: number;
    sortName: string;
    style?: React.CSSProperties;
  }[];
  tableHeaderClassName?: string;
  externalRowClass?: ((row: any) => string) | string;
  tableRef?: any;
  hasRowHover?: boolean;
  disabledDepthHover?: DepthLevelsType[];
  customContent?: (row: any, i: number) => React.ReactElement | null;
  onSort?: (field: string, direction: string) => void;
  onDragSort?: (data: any, row: any, tableData: any) => void;
  onDragMove?: (data: any) => boolean | void;
  customColumn?: (row?: any) => React.ReactElement | null;
  tbodyStyle?: React.CSSProperties;
  hasUserTooltop?: boolean;
};

function HierarchicalTable({
  tableData,
  tableColumns,
  externalClass,
  loading,
  error,
  params,
  sortable,
  dragAndDropsDepth,
  tableHeaderItems,
  tableHeaderClassName,
  tableRef,
  hasRowHover = true,
  disabledDepthHover,
  customContent,
  customColumn,
  externalRowClass,
  onSort,
  onDragSort = () => null,
  onDragMove = () => false,
  tbodyStyle,
  hasUserTooltop,
}: HierarchicalTableProps) {
  const [sortDir, setSortDir] = useState(params?.direction);
  const [sortField, setSortField] = useState(params?.sortBy);
  const defaultClass = classNames('table table--hierarchical', {
    loading: loading,
    [externalClass as string]: Boolean(externalClass),
  });

  const captionClass = classNames('table__no-data', {
    'error': !!error,
    'loading': loading,
    'no-data': isEmpty(tableData),
    'hidden': !isEmpty(tableData),
  });

  const iconClass = classNames({
    'ascending-order': sortDir === 'ASC',
  });

  function handleSort(sortName?: string) {
    if (!sortName || !onSort) return;
    const dir = sortDir === 'ASC' && sortName === sortField ? 'DESC' : 'ASC';
    setSortField(sortName);
    setSortDir(dir);
    onSort(sortName, dir);
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      getSubRows: (row: any) => row?.items,
      //@ts-ignore
      autoResetExpanded: false,
    },
    useExpanded,
  );

  return (
    <>
      {tableData.length > 0 ? (
        <table {...getTableProps()} className={defaultClass} ref={tableRef}>
          <thead className="table__head">
            {tableHeaderItems ? (
              <tr className={`table__row table__row-head ${tableHeaderClassName}`}>
                {sortable && <th className="table__head-column--grip" />}
                {tableHeaderItems?.map(({ name, colspan, rowspan, sortName, className, style }, rowIndex) => (
                  <th
                    className={classNames(
                      'table__head-column',
                      { table__sort: sortName, active: sortName === sortField },
                      className,
                    )}
                    key={rowIndex + rowIndex}
                    onClick={() => handleSort(sortName)}
                    colSpan={colspan}
                    rowSpan={rowspan}
                    style={style}
                  >
                    {name}
                    {sortName && sortField === sortName && <Icon iconName={'table-sort'} externalClass={iconClass} />}
                  </th>
                ))}
              </tr>
            ) : null}
            {headerGroups.map((headerGroup: any) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()} className="table__row table__row-head">
                {sortable && <th className="table__head-column--grip" />}
                {headerGroup.headers.map((column: any) => {
                  const sortName = column?.sortName;
                  const colspan = column?.colspan;
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <th
                      {...column.getHeaderProps()}
                      className={classNames(
                        'table__head-column',
                        { table__sort: sortName, active: sortName === sortField },
                        column.headClassName,
                      )}
                      onClick={() => handleSort(sortName)}
                      style={column?.headerStyles}
                      colSpan={colspan}
                    >
                      {column.render('Header')}
                      {sortName && sortField === sortName && <Icon iconName="table-sort" externalClass={iconClass} />}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <ReactSortable
            {...getTableBodyProps()}
            list={rows}
            setList={() => null}
            tag="tbody"
            className="table__body"
            group="group-name"
            onEnd={(data: any) => {
              onDragSort(data, rows[data.oldIndex], rows);
            }}
            onMove={(data: any) => onDragMove(data)}
            disabled={!dragAndDropsDepth}
            style={tbodyStyle}
          >
            {rows.map((row: any, i: number) => {
              const canExpand = row.original.items?.length > 0;
              prepareRow(row);
              const rowDepth = row.depth;

              return (
                <tr
                  key={row.id}
                  onMouseDown={e => {
                    dragAndDropsDepth && !dragAndDropsDepth.includes(rowDepth) && e.preventDefault();
                  }}
                  {...(row.getRowProps(), canExpand && row.getToggleRowExpandedProps())}
                  title=""
                  className={classNames(
                    'table__row',
                    isFunction(externalRowClass) ? externalRowClass(row) : externalRowClass,
                    {
                      'table__row--opener': canExpand,
                      'hasHover':
                        hasRowHover && (!disabledDepthHover || disabledDepthHover?.every(depth => depth !== rowDepth)),
                      'open': row.isExpanded,
                      'show-last-devider':
                        i === rows.length - 1 &&
                        ((rowDepth !== 0 && rows.find(el => el.id === row.id.split('.')[0])) ||
                          (rowDepth === 0 && row.isExpanded)),
                      'table__sub-row': rowDepth === 1,
                      'show-divider': rows.some((el: any) => {
                        const elDepth = el.depth;

                        return (
                          el.index === row.index - 1 &&
                          elDepth === rowDepth &&
                          el.isExpanded &&
                          el.canExpand &&
                          el.subRows &&
                          (row.id.slice(0, elDepth + 1) === el.id.slice(0, el.depth + 1) || elDepth === 0)
                        );
                      }),
                    },
                  )}
                >
                  {!customColumn &&
                    row.cells.map((cell: any, i: number) => {
                      const content = (
                        <div className="table__data-wrapper">
                          {cell.render('Cell')}
                          {customContent && customContent(row, i)}
                        </div>
                      );
                      const style = {
                        ...(i === 0 && { '--column-padding-left': `${row.depth * 32}px` }),
                        ...(cell.column?.externalColumnStyles && cell.column.externalColumnStyles(row)),
                      };

                      return (
                        <td
                          key={`${row.id}-${cell.id}`}
                          {...cell.getCellProps()}
                          className={classNames(
                            'table__data',
                            cell.column?.externalColumnClass && cell.column.externalColumnClass(row),
                            {
                              'first-column': i === 0,
                            },
                          )}
                          style={style}
                        >
                          {i === 0 ? (
                            <div className="table__row-title">
                              {canExpand && <Icon iconName="arrow-open" externalClass="icon table__open-icon" />}
                              {content}
                            </div>
                          ) : (
                            content
                          )}
                        </td>
                      );
                    })}
                  {customColumn && customColumn(row)}
                </tr>
              );
            })}
          </ReactSortable>
        </table>
      ) : null}
      <caption key={'caption'} className={captionClass} />
      {hasUserTooltop && <Hint customId={userHintId} className="table__user-name tooltip" dependencyList={[state]} />}
    </>
  );
}

export default React.memo(HierarchicalTable);
