import React from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';

type ModalSizeErrorProps = {
  onRequestClose: () => void;
  changeDocumentOverflowStyle?: boolean;
};

const ModalSizeError = ({ changeDocumentOverflowStyle, onRequestClose }: ModalSizeErrorProps) => {
  const intl = useIntl();

  return (
    <Modal
      isOpen
      title={intl.formatMessage(messages.errorTitle)}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      classNameModal={'image_error_modal center'}
      changeDocumentOverflowStyle={changeDocumentOverflowStyle}
    >
      <div>
        <FormattedMessage {...messages.modalSizeErrorTitle} />
      </div>
      <div className="form__buttons">
        <Button onClick={onRequestClose}>
          <FormattedMessage {...messages.okButton} />
        </Button>
      </div>
    </Modal>
  );
};

export default ModalSizeError;
