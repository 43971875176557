import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/reports.constants';
import { RejectValueErrors } from '../enums/error.enum';
import * as paramsTypes from '../enums/params/reports.params';
import {
  AbsencePeriodsListItemType,
  EmployeesIssueTypesItem,
  EmployeesTimesheetItem,
  UsersHoursAbsencesReportEvent,
  UsersHoursAbsencesReportItem,
} from '../enums/reports.enum';
import { ReportsUserStatusesType } from '../types/reports';

type InitialStateType = {
  employeesReportData: {
    jiraUsers: any;
    totalTimeSpent: number | null;
  };

  projectsReportData: {
    jiraProjects: any;
    totalTimeSpent: number | null;
    usersPreviews?: any;
  };
  projectsReportParams: paramsTypes.ProjectsReportParams;
  employeesReportParams: paramsTypes.EmployeesReportParams;
  employeesTimesheetReportData: any;
  employeesTimesheetReportParams: paramsTypes.EmployeesTimesheetReportParams;
  employeesIssueTypesData: any;
  employeesIssueTypesParams: paramsTypes.EmployeesIssueTypesParams;
  usersHoursAbsencesReportData: any;
  usersHoursAbsencesReportParams: paramsTypes.UsersHoursAbsencesReportParams;
  absencePeriodsData: any;
  absencePeriodsParams: paramsTypes.AbsencePeriodsParams;
  activeInactiveHoursReportData: any;
  activeInactiveHoursReportParams: paramsTypes.ActiveInactiveHoursReportParams;
  reportsUserStatuses: ReportsUserStatusesType;
  roundingMode: ActionTypes.ReportsRoundingMode | null;
  isFoldSubtasks: boolean;
  errors: {
    employeesReportErrors: string | RejectValueErrors[] | null;
    projectsReportErrors: string | RejectValueErrors[] | null;
    employeesTimesheetReportErrors: string | RejectValueErrors[] | null;
    employeesIssueTypesErrors: string | RejectValueErrors[] | null;
    usersHoursAbsencesReportErrors: string | RejectValueErrors[] | null;
    absencePeriodsErrors: string | RejectValueErrors[] | null;
    activeInactiveHoursReportErrors: string | RejectValueErrors[] | null;
  };
  loading: {
    employeesReport: boolean;
    projectsReport: boolean;
    employeesTimesheetReport: boolean;
    employeesIssueTypes: boolean;
    usersHoursAbsencesReport: boolean;
    absencePeriods: boolean;
    activeInactiveHoursReport: boolean;
  };
};

const initialState: InitialStateType = {
  employeesReportData: {
    jiraUsers: [],
    totalTimeSpent: null,
  },
  projectsReportData: {
    jiraProjects: [],
    totalTimeSpent: null,
    usersPreviews: null,
  },
  employeesReportParams: new paramsTypes.EmployeesReportParams(),
  projectsReportParams: new paramsTypes.ProjectsReportParams(),
  employeesTimesheetReportData: null,
  employeesTimesheetReportParams: new paramsTypes.EmployeesTimesheetReportParams(),
  employeesIssueTypesData: null,
  employeesIssueTypesParams: new paramsTypes.EmployeesIssueTypesParams(),
  usersHoursAbsencesReportData: null,
  usersHoursAbsencesReportParams: new paramsTypes.UsersHoursAbsencesReportParams(),
  absencePeriodsData: null,
  absencePeriodsParams: new paramsTypes.AbsencePeriodsParams(),
  activeInactiveHoursReportData: null,
  activeInactiveHoursReportParams: new paramsTypes.ActiveInactiveHoursReportParams(),
  reportsUserStatuses: {
    isAllowActive: true,
    isAllowPassive: false,
    isAllowAlien: false,
  },
  roundingMode: null,
  isFoldSubtasks: false,
  errors: {
    employeesReportErrors: null,
    projectsReportErrors: null,
    employeesTimesheetReportErrors: null,
    employeesIssueTypesErrors: null,
    usersHoursAbsencesReportErrors: null,
    absencePeriodsErrors: null,
    activeInactiveHoursReportErrors: null,
  },
  loading: {
    employeesReport: false,
    projectsReport: false,
    employeesTimesheetReport: false,
    employeesIssueTypes: false,
    usersHoursAbsencesReport: false,
    absencePeriods: false,
    activeInactiveHoursReport: false,
  },
};

const reportsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_EMPLOYEES_REPORT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, employeesReport: true },
      };

    case ActionTypes.GET_EMPLOYEES_REPORT_SUCCESS:
      return {
        ...state,
        employeesReportData: {
          jiraUsers: action.payload.jiraUsers,
          totalTimeSpent: action.payload.totalTimeSpent,
        },
        errors: { ...state.errors, employeesReportErrors: null },
        loading: { ...state.loading, employeesReport: false },
      };

    case ActionTypes.GET_EMPLOYEES_REPORT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, employeesReportErrors: action.payload.message },
        loading: { ...state.loading, employeesReport: false },
      };

    case ActionTypes.SET_EMPLOYEES_REPORT_PARAMS_REQUEST:
      return {
        ...state,
        employeesReportParams: new paramsTypes.EmployeesReportParams({
          ...state.employeesReportParams,
          ...action.payload.data,
        }),
      };

    case ActionTypes.GET_EMPLOYEES_TIMESHEET_REPORT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, employeesTimesheetReport: true },
      };

    case ActionTypes.GET_EMPLOYEES_TIMESHEET_REPORT_SUCCESS:
      return {
        ...state,
        employeesTimesheetReportData: {
          ...action.payload,
          users: action.payload.users.map((item: EmployeesTimesheetItem) => new EmployeesTimesheetItem(item)),
        },
        errors: { ...state.errors, employeesTimesheetReportErrors: null },
        loading: { ...state.loading, employeesTimesheetReport: false },
      };

    case ActionTypes.GET_EMPLOYEES_TIMESHEET_REPORT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, employeesTimesheetReportErrors: action.payload.message },
        loading: { ...state.loading, employeesTimesheetReport: false },
      };

    case ActionTypes.SET_EMPLOYEES_TIMESHEET_REPORT_PARAMS:
      return {
        ...state,
        employeesTimesheetReportParams: new paramsTypes.EmployeesTimesheetReportParams({
          ...state.employeesTimesheetReportParams,
          ...action.payload,
        }),
        loading: { ...state.loading, employeesTimesheetReport: true },
      };

    case ActionTypes.GET_PROJECTS_REPORT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, projectsReport: true },
      };

    case ActionTypes.GET_PROJECTS_REPORT_SUCCESS:
      return {
        ...state,
        projectsReportData: {
          jiraProjects: action.payload.jiraProjectDtoList || [],
          totalTimeSpent: action.payload.timeSpent,
          usersPreviews: action.payload.usersPreviews,
        },
        errors: { ...state.errors, projectsReportErrors: null },
        loading: { ...state.loading, projectsReport: false },
      };

    case ActionTypes.GET_PROJECTS_REPORT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, projectsReportErrors: action.payload.message },
        loading: { ...state.loading, projectsReport: false },
      };

    case ActionTypes.SET_PROJECTS_REPORT_PARAMS_REQUEST:
      return {
        ...state,
        projectsReportParams: new paramsTypes.ProjectsReportParams({
          ...state.projectsReportParams,
          ...action.payload.data,
        }),
      };

    case ActionTypes.GET_EMPLOYEES_ISSUE_TYPES_SUCCESS:
      return {
        ...state,
        employeesIssueTypesData: {
          ...action.payload,
          users: action.payload.users.map((item: EmployeesIssueTypesItem) => new EmployeesIssueTypesItem(item)),
        },
        errors: { ...state.errors, employeesIssueTypesErrors: null },
        loading: { ...state.loading, employeesIssueTypes: false },
      };

    case ActionTypes.GET_EMPLOYEES_ISSUE_TYPES_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, employeesIssueTypesErrors: action.payload.message },
        loading: { ...state.loading, employeesIssueTypes: false },
      };

    case ActionTypes.SET_EMPLOYEES_ISSUE_TYPES_PARAMS_REQUEST:
      return {
        ...state,
        employeesIssueTypesParams: new paramsTypes.EmployeesIssueTypesParams({
          ...state.employeesIssueTypesParams,
          ...action.payload,
        }),
        loading: { ...state.loading, employeesIssueTypes: true },
      };

    case ActionTypes.GET_USERS_HOURS_ABSENCES_REPORT_SUCCESS:
      return {
        ...state,
        usersHoursAbsencesReportData: {
          ...action.payload,
          departments: action.payload.departments.map(
            (item: UsersHoursAbsencesReportItem) => new UsersHoursAbsencesReportItem(item),
          ),
          daysOfTotal: action.payload.daysOfTotal.map(
            (item: UsersHoursAbsencesReportEvent) => new UsersHoursAbsencesReportEvent(item),
          ),
        },
        errors: { ...state.errors, usersHoursAbsencesReportErrors: null },
        loading: { ...state.loading, usersHoursAbsencesReport: false },
      };

    case ActionTypes.GET_USERS_HOURS_ABSENCES_REPORT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, usersHoursAbsencesReportErrors: action.payload.message },
        loading: { ...state.loading, usersHoursAbsencesReport: false },
      };

    case ActionTypes.SET_USERS_HOURS_ABSENCES_REPORT_PARAMS_REQUEST:
      return {
        ...state,
        usersHoursAbsencesReportParams: new paramsTypes.UsersHoursAbsencesReportParams({
          ...state.usersHoursAbsencesReportParams,
          ...action.payload,
        }),
        loading: { ...state.loading, usersHoursAbsencesReport: true },
      };

    case ActionTypes.SET_ABSENCE_PERIODS_PARAMS_REQUEST:
      return {
        ...state,
        absencePeriodsParams: new paramsTypes.AbsencePeriodsParams({
          ...state.absencePeriodsParams,
          ...action.payload,
        }),
        loading: { ...state.loading, absencePeriods: true },
      };

    case ActionTypes.GET_ABSENCE_PERIODS_SUCCESS:
      return {
        ...state,
        absencePeriodsData: {
          ...action.payload,
          userList: action.payload.userList.map(
            (item: AbsencePeriodsListItemType) => new AbsencePeriodsListItemType(item),
          ),
        },
        errors: { ...state.errors, absencePeriodsErrors: null },
        loading: { ...state.loading, absencePeriods: false },
      };

    case ActionTypes.GET_ABSENCE_PERIODS_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, absencePeriodsErrors: action.payload.message },
        loading: { ...state.loading, absencePeriods: false },
      };

    case ActionTypes.SET_ACTIVE_INACTIVE_HOURS_REPORT_PARAMS_REQUEST:
      return {
        ...state,
        activeInactiveHoursReportParams: new paramsTypes.ActiveInactiveHoursReportParams({
          ...state.activeInactiveHoursReportParams,
          ...action.payload,
        }),
        loading: { ...state.loading, activeInactiveHoursReport: true },
        errors: { ...state.errors, activeInactiveHoursReportErrors: null },
      };

    case ActionTypes.GET_ACTIVE_INACTIVE_HOURS_REPORT_SUCCESS:
      return {
        ...state,
        activeInactiveHoursReportData: action.payload,
        errors: { ...state.errors, activeInactiveHoursReportErrors: null },
        loading: { ...state.loading, activeInactiveHoursReport: false },
      };

    case ActionTypes.GET_ACTIVE_INACTIVE_HOURS_REPORT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, activeInactiveHoursReportErrors: action.payload.message },
        loading: { ...state.loading, activeInactiveHoursReport: false },
      };

    case ActionTypes.SET_REPORTS_USERS_STATUSES_REQUEST:
      return {
        ...state,
        reportsUserStatuses: {
          ...state.reportsUserStatuses,
          ...action.payload,
        },
      };

    case ActionTypes.SET_ROUNDING_MODE_REQUEST:
      return {
        ...state,
        roundingMode: action.payload,
      };

    case ActionTypes.SET_FOLD_SUBTASK_REQUEST:
      return {
        ...state,
        isFoldSubtasks: action.payload,
      };

    default:
      return state;
  }
};

export default reportsReducer;
