import React, { useContext } from 'react';
import classNames from 'classnames';
import BrandingContext from '../../BrandingContext';

type SwitchProps = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  disabled?: boolean;
  externalClass?: string;
  name?: string;
};

function Switch({ onChange, checked, disabled, externalClass, name }: SwitchProps) {
  const defaultClass = classNames('switch__block', {
    [externalClass as string]: Boolean(externalClass),
  });
  const branding = useContext(BrandingContext);
  const style: any = {
    '--switch-active-color': branding?.globalAccents,
  };

  return (
    <label className={defaultClass} style={style}>
      <input
        className="switch__input"
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="switch__slider" />
    </label>
  );
}

export default Switch;
