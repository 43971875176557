import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { formatValue } from 'react-currency-input-field';
import { getTableCell } from '../../../utils/table.utils';
import { DepthLevels } from '../../../constants/tables.constants';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { IncomesTotalOptions } from '../../../constants/finance.constants';
import { IncomesReportItem, ProjectIncomesReportItem } from '../../../enums/finance/finance.enum';
import { CurrencyType } from '../../../types/finance';

export const useDataForIncomesTable = (incomes: IncomesReportItem[], baseCurrency: CurrencyType | undefined) => {
  const intl = useIntl();

  const incomesTableColumns = useMemo(() => {
    let incomeColumns = [];
    const clientColumn = [
      {
        id: 'clientProject',
        Header: (
          <div className="column-name">
            <FormattedMessage {...messages.clientsProjectsColumn} />
          </div>
        ),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: IncomesReportItem) =>
                row.totalItem === IncomesTotalOptions.PLANNED_TOTAL ? (
                  intl.formatMessage(messages.plannedTotalLabel)
                ) : row.totalItem === IncomesTotalOptions.ACTUAL_TOTAL ? (
                  intl.formatMessage(messages.actualTotalLabel)
                ) : (
                  <div className={'table__data-wrapper'}>
                    <div className="name-capital-letter-block">
                      <span>{row.client?.name[0]}</span>
                    </div>
                    <div>{row.client?.name}</div>
                  </div>
                ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ProjectIncomesReportItem) => row.financeProject.name,
            },
          ]),
      },
    ];

    if (incomes.length) {
      incomeColumns = incomes[0]?.entries.map((item: { month: string }, monthIndex: number) => ({
        id: `month-${monthIndex}`,
        Header: (
          <>
            {moment(item.month).format(DATE_FORMAT.MMM)}
            {(monthIndex === 0 || moment(item.month).startOf('year').isSame(moment(item.month))) && (
              <div className="year-display">{moment(item.month).format(DATE_FORMAT.YYYY)}</div>
            )}
          </>
        ),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: IncomesReportItem) =>
                row.totalItem === IncomesTotalOptions.PLANNED_TOTAL ? (
                  <div className="income-block">
                    {formatValue({
                      value: row.entries[monthIndex]?.planIncome.toString(),
                      suffix: ` ${baseCurrency?.name}`,
                    })}
                  </div>
                ) : row.totalItem === IncomesTotalOptions.ACTUAL_TOTAL ? (
                  <div className="income-block">
                    {formatValue({
                      value: row.entries[monthIndex]?.actualIncome.toString(),
                      suffix: ` ${baseCurrency?.name}`,
                    })}
                  </div>
                ) : (
                  <div className="income-block">
                    <div className="planed-income">
                      {formatValue({
                        value: row.entries[monthIndex]?.planIncome.toString(),
                        suffix: ` ${baseCurrency?.name}`,
                      })}
                    </div>
                    <div className="actual-income">
                      {formatValue({
                        value: row.entries[monthIndex]?.actualIncome.toString(),
                        suffix: ` ${baseCurrency?.name}`,
                      })}
                    </div>
                  </div>
                ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: ProjectIncomesReportItem) => (
                <div className="income-block">
                  <div className="planed-income">
                    {formatValue({
                      value: row.entries[monthIndex]?.planIncome.toString(),
                      suffix: ` ${baseCurrency?.name}`,
                    })}
                  </div>
                  <div className="actual-income">
                    {formatValue({
                      value: row.entries[monthIndex]?.actualIncome.toString(),
                      suffix: ` ${baseCurrency?.name}`,
                    })}
                  </div>
                </div>
              ),
            },
          ]),
      }));

      const totalColum = [
        {
          id: 'total',
          Header: (
            <div className="column-name">
              <FormattedMessage {...messages.totalRow} />
            </div>
          ),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: IncomesReportItem) =>
                  row.totalItem === IncomesTotalOptions.PLANNED_TOTAL ? (
                    <div className="income-block">
                      {formatValue({
                        value: row.allEntriesPlanIncome.toFixed(4).toString(),
                        suffix: ` ${baseCurrency?.name}`,
                      })}
                    </div>
                  ) : row.totalItem === IncomesTotalOptions.ACTUAL_TOTAL ? (
                    <div className="income-block">
                      {formatValue({
                        value: row.allEntriesActualIncome.toFixed(4).toString(),
                        suffix: ` ${baseCurrency?.name}`,
                      })}
                    </div>
                  ) : (
                    <div className="income-block">
                      <div className="planed-income">
                        {formatValue({
                          value: row.allEntriesPlanIncome.toString(),
                          suffix: ` ${baseCurrency?.name}`,
                        })}
                      </div>
                      <div className="actual-income">
                        {formatValue({
                          value: row.allEntriesActualIncome.toString(),
                          suffix: ` ${baseCurrency?.name}`,
                        })}
                      </div>
                    </div>
                  ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: ProjectIncomesReportItem) => (
                  <div className="income-block">
                    <div className="planed-income">
                      {formatValue({
                        value: row.allEntriesPlanIncome.toString(),
                        suffix: ` ${baseCurrency?.name}`,
                      })}
                    </div>
                    <div className="actual-income">
                      {formatValue({
                        value: row.allEntriesActualIncome.toString(),
                        suffix: ` ${baseCurrency?.name}`,
                      })}
                    </div>
                  </div>
                ),
              },
            ]),
        },
      ];

      return [...clientColumn, ...incomeColumns, ...totalColum];
    }
    return clientColumn;
  }, [incomes]);

  const incomesHeaderItems = [
    { name: '', className: 'employees-column-head header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: 'header_row',
      colspan: (incomes && incomes[0]?.entries.length) || 2,
      sortName: '',
    },
    { name: '', className: 'total-column-head header_row', colspan: 1, sortName: '' },
  ];

  return {
    incomesTableColumns,
    incomesHeaderItems,
  };
};
