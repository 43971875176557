import React, { useEffect } from 'react';
import * as questionnairesActions from '../../../actions/questionnaires.actions';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import NewPollTemplateForm from '../../../components/PollTemplates/Forms/CreateNewPollTemplateForm';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import r from '../../../constants/routes.constants';
import { getPollTemplatesCategoryFilter } from '../../../actions/filters.actions';

function NewPollTemplate({
  getPollTemplatesCategoryFilter,
  createNewPollTemplate,
  categories,
  isLoading,
  currentTemplateCategory,
  templateError,
}: ConnectedProps<typeof connector>) {
  useEffect(() => {
    getPollTemplatesCategoryFilter();
  }, []);

  const history = useHistory();

  const createNewPollTemplateCallback = () => {
    history.push(r.pollTemplates);
  };

  return (
    <>
      <div className="page__profile-panel">
        <div className="page__panel__wrapper fixed-container">
          <h1 className="page__title">
            <FormattedMessage {...messages.newPollTemplateTitle} />
          </h1>
        </div>
      </div>
      <div className="page__main-content">
        <div className="page__wrapper fixed-container">
          <NewPollTemplateForm
            isLoading={isLoading}
            categories={categories}
            currentTemplateCategory={currentTemplateCategory}
            onSubmit={data => createNewPollTemplate({ data, callback: createNewPollTemplateCallback })}
            requestErrors={templateError}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ questionnaires, filters }: RootState) => ({
  isLoading: questionnaires.loading.createPollTemplate,
  categories: filters.pollTemplatesCategoryFilter.categories,
  currentTemplateCategory: questionnaires.currentTemplateCategory,
  templateError: questionnaires.errors.pollTemplatesError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPollTemplatesCategoryFilter: () => dispatch(getPollTemplatesCategoryFilter()),
  createNewPollTemplate: (data: any) => dispatch(questionnairesActions.createNewPollTemplate(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NewPollTemplate);
