import Modal from '../../Modal';
import Button from '../../Button';
import React, { useCallback } from 'react';
import { Recognition } from '../../../enums/recognitions.enum';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';

type ModalDeleteRecognitionProps = {
  isOpen: boolean;
  onCloseRequest: () => void;
  onDeleteRequest: (data: any) => void;
  filterValues?: any;
  recognitionData: Recognition;
  recognitionError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
};

function ModalDeleteRecognition({
  isOpen,
  onCloseRequest,
  onDeleteRequest,
  recognitionData,
  recognitionError,
  isLoading,
  filterValues,
  resetErrors,
}: ModalDeleteRecognitionProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    onCloseRequest();
    resetErrors();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.deleteModalWithItemNameTitle, {
        name: recognitionData?.text,
      })}
      size={'small'}
      classNameModal="center"
    >
      <ErrorMessage>{recognitionError}</ErrorMessage>
      <div className={'form__buttons'}>
        <Button externalClass={'button--modal button--cancel'} color="gray" onClick={resetAndExit}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button
          externalClass={'button--modal'}
          onClick={() => {
            onDeleteRequest({
              data: { recognitionId: recognitionData.id, targetUserId: recognitionData.targetUserId },
              filterValues: filterValues,
              callback: resetAndExit,
            });
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage {...messages.deleteButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteRecognition;
