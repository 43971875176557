import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import {
  CREATE_NOTIFICATION_TEMPLATE_SCHEMA,
  eventVariables,
  NotificationTemplate,
  triggerOptions,
} from '../../../enums/notifications.enum';
import { EventTypeInfo } from '../../../enums/calendar.enum';
import Select from '../../Select';
import TextArea from '../../TextArea';
import { replaceDynamicFieldsToRequest } from '../../../utils/notifications.utils';
import classNames from 'classnames';
import { scrollToError } from '../../../utils';

type ModalNewNotificationTemplateProps = {
  onCloseRequest: () => void;
  createEmailTemplate: (data: any) => void;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  getEventTypes: () => void;
  eventTypes: EventTypeInfo[];
  notificationTemplates: NotificationTemplate[];
};

function ModalNewNotificationTemplate({
  onCloseRequest,
  createEmailTemplate,
  error,
  isLoading,
  isOpen,
  getEventTypes,
  eventTypes,
  notificationTemplates,
}: ModalNewNotificationTemplateProps) {
  const intl = useIntl();

  useEffect(() => {
    getEventTypes();
  }, []);

  const { values, errors, touched, handleSubmit, setFieldError, setFieldValue, handleChange } = useFormik({
    initialValues: new NotificationTemplate(),
    validationSchema: CREATE_NOTIFICATION_TEMPLATE_SCHEMA,
    validateOnChange: false,
    validate: scrollToError,
    onSubmit: data => {
      return createEmailTemplate({
        data: {
          ...data,
          bodyTemplate: replaceDynamicFieldsToRequest(data.bodyTemplate, data.templateType),
          subjectTemplate: replaceDynamicFieldsToRequest(data.subjectTemplate, data.templateType),
        },
        callback: onCloseRequest,
      });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const eventsTypeOptions = useMemo(
    () =>
      eventTypes.map((eventType: EventTypeInfo) => ({
        label: eventType.name,
        value: eventType,
      })),
    [eventTypes],
  );

  const eventTypeValue = useMemo(
    () => values.eventType && eventsTypeOptions.find(({ value }) => value.id === values?.eventType?.id),
    [eventsTypeOptions, values],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleEventTypeChange = useCallback(({ value }) => {
    setFieldValue('eventType', value);
    setFieldValue('eventTypeId', value.id);
    setFieldValue('notificationActionType', null);
  }, []);

  const handleeBbodyTemplateChange = useCallback(data => {
    setFieldValue('bodyTemplate', data);
  }, []);

  const handleNotificationActionTypeChange = useCallback(({ value }) => {
    setFieldValue('notificationActionType', value);
  }, []);

  const triggerFiltredOptions = useMemo(() => {
    if (values.eventTypeId && notificationTemplates) {
      const notificationWithSameEventType = notificationTemplates.filter(
        item => item.eventTypeId === values.eventTypeId,
      );

      return triggerOptions.filter(
        option => !notificationWithSameEventType.some(item => item.notificationActionType === option.value),
      );
    }

    return [];
  }, [triggerOptions, values.eventTypeId, notificationTemplates]);

  const triggerValue = useMemo(
    () => values.notificationActionType && triggerOptions.find(({ value }) => value === values?.notificationActionType),
    [triggerOptions, values],
  );

  const wrightToClipboard = useCallback(variable => {
    navigator.clipboard.writeText(variable);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.customNotificationTitle)}
      classNameModal="notification-template-modal center new-modal"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="notification-template-wrapper">
            <div className="notification-template-form">
              <div className="form__input-block">
                <Select
                  isSearchable
                  label={intl.formatMessage(messages.eventTypeLabel)}
                  options={eventsTypeOptions}
                  handleChange={handleEventTypeChange}
                  value={eventTypeValue}
                  hasError={hasError('eventTypeId')}
                  errorMessage={errors?.eventTypeId}
                />
                <Select
                  isSearchable
                  label={intl.formatMessage(messages.triggerTitle)}
                  options={triggerFiltredOptions}
                  handleChange={handleNotificationActionTypeChange}
                  //@ts-ignore
                  value={triggerValue}
                  hasError={hasError('notificationActionType')}
                  errorMessage={errors?.notificationActionType}
                />
                <Input
                  name={`subjectTemplate`}
                  id={`subjectTemplate`}
                  onChange={handleChange}
                  label={intl.formatMessage(messages.subjectTitle)}
                  tag={'textarea'}
                  hasError={hasError(`subjectTemplate`)}
                  errorMessage={errors?.subjectTemplate}
                  defaultValue={values.subjectTemplate}
                />
                <TextArea
                  id={'bodyTemplate'}
                  placeholder={intl.formatMessage(messages.messageTitle)}
                  onChange={data => {
                    handleeBbodyTemplateChange(data as string);
                  }}
                  defaultValue={values.bodyTemplate}
                  bounds=".form__textarea-wrapper"
                />
                <ErrorMessage>{errors?.bodyTemplate}</ErrorMessage>
              </div>
            </div>
            <div className="notification-template-variables">
              <div className="variables-title">
                <FormattedMessage {...messages.variablesTitle} />
              </div>
              <div className="variables-note">
                <FormattedMessage {...messages.note} />
              </div>
              {eventVariables.map(item => (
                <div
                  key={item}
                  className={classNames('variables-item', {
                    inline:
                      item === '[Event.StartDate]' ||
                      item === '[Event.EndDate]' ||
                      item === '[Event.StartTime]' ||
                      item === '[Event.EndTime]',
                  })}
                  onClick={() => wrightToClipboard(item)}
                >
                  {item === '[Event.EndDate]' || item === '[Event.EndTime]' ? (
                    <div className="inline-last">
                      <span> / </span> {item}
                    </div>
                  ) : (
                    item
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            type={'button'}
            onClick={onCloseRequest}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewNotificationTemplate;
