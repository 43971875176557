import React from 'react';
import { Candidate } from '../../../enums/candidates.enums';
import Contacts from './Contacts';
import ProfileItem from './InfoItem';
import Label from '../../../components/Label';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { getExperience } from '../../../utils';

const TextWithlabel = ({ label, text }: { label: string; text: string }) => (
  <div>
    <Label type="text">{label}</Label>
    <p>{text}</p>
  </div>
);

type ProfileInfoProps = {
  candidate: Candidate;
};
const ProfileInfo = ({ candidate }: ProfileInfoProps) => {
  const intl = useIntl();
  return (
    <div className="page__profile-contacts-info">
      <h1 className="page__profile-name">
        {candidate.fullName}
        {candidate.alternativeName && (
          <span className="page__profile-alternative-name">{candidate.alternativeName}</span>
        )}
      </h1>
      {candidate.location && (
        <ProfileItem iconName="map-marker" externalClass="page__profile-post">
          {candidate.location}
        </ProfileItem>
      )}
      <Contacts candidate={candidate} />
      <ProfileItem externalClass="page__profile-contact-text">
        <div className="page__profile-contacts">
          <TextWithlabel
            label={intl.formatMessage(messages.experienceLabel)}
            text={getExperience(candidate.carrierStart)}
          />
          <TextWithlabel
            label={intl.formatMessage(messages.leadExpLabel)}
            text={intl.formatMessage(messages.hasExpLabel, { hasExp: candidate.hasLeadExperience })}
          />
        </div>
        {candidate.receivingSource?.id && (
          <TextWithlabel label={intl.formatMessage(messages.sourceLabel)} text={candidate.receivingSource.name} />
        )}
      </ProfileItem>
    </div>
  );
};

export default React.memo(ProfileInfo);
