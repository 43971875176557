import * as yup from 'yup';
import get from 'lodash-es/get';
import { OfficeInfo } from './libraries.enum';
import { UserInfo } from './users.enum';
import { defaultTo } from 'lodash-es';

export const EVENT_TYPES_VALIDATION_SCHEMA = yup.object().shape({
  id: yup.string().nullable(true),
  name: yup.string().trim().required('Required'),
  titleEnabled: yup.boolean(),
  targetEmployeesEnabled: yup.boolean(),
  dateRangesEnabled: yup.boolean(),
  eventParticipantsEnabled: yup.boolean(),
  timeEnabled: yup.boolean(),
  questionnairesEnabled: yup.boolean(),
  excludedParticipantsEnabled: yup.boolean(),
  repeaterEnabled: yup.boolean(),
  officesEnabled: yup.boolean(),
  reminderEnabled: yup.boolean(),
  addressEnabled: yup.boolean(),
  extraWorkingDaysEnabled: yup.boolean(),
  assessmentsEnabled: yup.boolean(),
  eventTypeApplyType: yup.string().matches(/^(APPLY_TO_FUTURE_EVENTS|APPLY_TO_ALL_EVENTS)$/),
  color: yup
    .string()
    .matches(/^#[0-9a-f]{6}$/)
    .required(),
  absencesSettings: yup.object().shape({
    absencesEnabled: yup.boolean(),
    finalDecisionOptionsEnabled: yup.boolean(),
    optionForExcludeDaysOfAbsenceFromOtherTypes: yup.boolean(),
    requiresWorkingOff: yup.boolean(),
    showTotalCounterInProfile: yup.boolean(),
    requiresApproval: yup.object().shape({
      requiresApprovalEnabled: yup.boolean(),
      usersAbleToApprove: yup.array(),
    }),
  }),
  limitOfEvents: yup.object().shape({
    limitNumberOfEventsPerOffice: yup.array().of(
      yup.object().shape({
        limitNumberPerUser: yup.number().required('Required'),
        office: yup.object(),
        startDate: yup.string().required('Required'),
      }),
    ),
    limitNumberOfEventsPerOfficeEnabled: yup.boolean(),
  }),
  limitedManagerPersonEnabled: yup.boolean(),
  lastEditor: yup.object(),
});

export class EventTypeInfo {
  id: string;
  name: string;
  titleEnabled: boolean;
  targetEmployeesEnabled: boolean;
  dateRangesEnabled: boolean;
  eventParticipantsEnabled: boolean;
  excludedParticipantsEnabled: boolean;
  timeEnabled: boolean;
  questionnairesEnabled: boolean;
  repeaterEnabled: boolean;
  officesEnabled: boolean;
  reminderEnabled: boolean;
  addressEnabled: boolean;
  extraWorkingDaysEnabled: boolean;
  assessmentsEnabled: boolean;
  eventTypeApplyType: 'APPLY_TO_FUTURE_EVENTS' | 'APPLY_TO_ALL_EVENTS';
  color: string;
  lastModifiedDate: string;
  eventsQuantity: number;
  absencesSettings: {
    absencesEnabled: boolean;
    finalDecisionOptionsEnabled: boolean;
    optionForExcludeDaysOfAbsenceFromOtherTypes: boolean;
    requiresWorkingOff: boolean;
    showTotalCounterInProfile: boolean;
    requiresApproval: {
      requiresApprovalEnabled: boolean;
      usersAbleToApprove: Record<string, unknown>[];
    };
  };
  limitOfEvents: {
    limitNumberOfEventsPerOfficeEnabled: boolean;
    limitNumberOfEventsPerOffice: {
      id: string;
      limitNumberPerUser: number;
      office: OfficeInfo;
      startDate: string;
      calculateAvailableDaysBasedOnDaysWorked: boolean;
    }[];
  };
  limitedManagerPersonEnabled: boolean;
  limitedManagerPersons: UserInfo[];
  limitedManagerPersonsIds: string[];
  lastEditor: Record<string, unknown>;
  isPublicEvent: boolean;

  constructor(eventType?: unknown) {
    this.id = get(eventType, 'id', '');
    this.name = get(eventType, 'name', '');
    this.titleEnabled = get(eventType, 'titleEnabled', false);
    this.targetEmployeesEnabled = get(eventType, 'targetEmployeesEnabled', false);
    this.dateRangesEnabled = get(eventType, 'dateRangesEnabled', false);
    this.eventParticipantsEnabled = get(eventType, 'eventParticipantsEnabled', false);
    this.excludedParticipantsEnabled = defaultTo(get(eventType, 'excludedParticipantsEnabled'), false);
    this.timeEnabled = get(eventType, 'timeEnabled', false);
    this.questionnairesEnabled = get(eventType, 'questionnairesEnabled', false);
    this.repeaterEnabled = get(eventType, 'repeaterEnabled', false);
    this.officesEnabled = get(eventType, 'officesEnabled', false);
    this.reminderEnabled = get(eventType, 'reminderEnabled', false);
    this.addressEnabled = get(eventType, 'addressEnabled', false);
    this.extraWorkingDaysEnabled = get(eventType, 'extraWorkingDaysEnabled', false);
    this.assessmentsEnabled = get(eventType, 'assessmentsEnabled', false);
    this.eventTypeApplyType = get(eventType, 'eventTypeApplyType', 'APPLY_TO_FUTURE_EVENTS');
    this.color = get(eventType, 'color', '#c1201e');
    this.lastModifiedDate = get(eventType, 'lastModifiedDate', '');
    this.eventsQuantity = get(eventType, 'eventsQuantity', 0);
    this.absencesSettings = get(eventType, 'absencesSettings', {
      absencesEnabled: false,
      finalDecisionOptionsEnabled: false,
      optionForExcludeDaysOfAbsenceFromOtherTypes: false,
      requiresWorkingOff: false,
      showTotalCounterInProfile: false,
      requiresApproval: {
        requiresApprovalEnabled: false,
        usersAbleToApprove: [],
      },
    });
    this.limitOfEvents = get(eventType, 'limitOfEvents', {
      limitNumberOfEventsPerOfficeEnabled: false,
      limitNumberOfEventsPerOffice: [],
    });
    this.limitedManagerPersonEnabled = get(eventType, 'limitedManagerPersonEnabled', false);
    this.limitedManagerPersons = get(eventType, 'limitedManagerPersons', []).map((el: any) => new UserInfo(el));
    this.limitedManagerPersonsIds = get(eventType, 'limitedManagerPersonsIds', []);
    this.lastEditor = get(eventType, 'lastEditor', {});
    this.isPublicEvent = get(eventType, 'isPublicEvent', false);
  }

  initialize() {
    this.eventTypeApplyType = 'APPLY_TO_FUTURE_EVENTS';
    return this;
  }
}

export const DAYS_LIMIT_SCHEMA = yup.object().shape({
  daysLeftInThisYearManual: yup
    .number()
    .min(-999, 'Min -999')
    .max(999, 'Max 999')
    .integer('Should be integer')
    .required('Required'),
});
