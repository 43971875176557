import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { CANDIDATE_TECHNOLOGY_VALIDATION_SCHEMA } from '../../../enums/candidates.enums';
import { CandidateTechnologyType } from '../../../types/candidates';
import { scrollToError } from '../../../utils';

type ModalNewCandidateTechnologyProps = {
  isOpen: boolean;
  isLoading: boolean;
  error: string | RejectValueErrors[] | null;
  createCandidateTechnology: (data: { data: CandidateTechnologyType; callback: () => void }) => void;
  onCloseRequest: () => void;
};

function ModalNewCandidateTechnology({
  isOpen,
  isLoading,
  error,
  createCandidateTechnology,
  onCloseRequest,
}: ModalNewCandidateTechnologyProps) {
  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldError,
  } = useFormik<CandidateTechnologyType>({
    initialValues: { id: '', name: '' },
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: CANDIDATE_TECHNOLOGY_VALIDATION_SCHEMA,
    onSubmit: data =>
      createCandidateTechnology({
        data,
        callback: resetAndExit,
      }),
  });

  useSetFieldsErrors(error, setFieldError);

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
  }, []);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetAndExit}
      title={intl.formatMessage(messages.newCandidateTechnologyTitle)}
      classNameModal="center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <Input
            id={'name'}
            name={'name'}
            label={intl.formatMessage(messages.nameColumn)}
            defaultValue={values.name}
            maxLength={150}
            onChange={handleChange}
            hasError={hasError('name')}
            errorMessage={errors.name}
          />
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button externalClass={'button--modal'} onClick={resetAndExit} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewCandidateTechnology;
