import { defineMessages } from 'react-intl';
import {
  nameColumn,
  editButton,
  deleteButton,
  createdColumn,
  updatedColumn,
  experienceLabel,
  statusLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  createdColumn,
  updatedColumn,
  experienceLabel,
  statusLabel,
  pageTitle: {
    id: 'candidate.receiving.sources.title.receiving.sources',
    defaultMessage: 'Receiving Sources',
  },
  leadExpLabel: {
    id: 'candidate.info.label.lead.exp',
    defaultMessage: 'Lead exp.',
  },
  hasExpLabel: {
    id: 'candidate.info.label.has.exp',
    defaultMessage: '{hasExp, select, true {Yes} false {No} other {}}',
  },
  sourceLabel: {
    id: 'candidate.info.label.source',
    defaultMessage: 'Source',
  },
  createMemberBtn: {
    id: 'candidate.info.btn.create.member',
    defaultMessage: 'Create Member',
  },
  candidatesManagementLabel: {
    id: 'candidate.info.label.candidates.management',
    defaultMessage: 'Candidates Management',
  },
});
