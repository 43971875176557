import { defaultTo, isEmpty } from 'lodash-es';
import get from 'lodash-es/get';
import moment from 'moment';
import * as yup from 'yup';
import { DATE_FORMAT } from '../constants/date.constants';
import { DATE_PICKER, TemplateDatabaseDataSource, TemplateDataSource } from '../constants/questionnaires.constants';
import { notPublishedValue } from '../pages/Polls/utils';
import { OfficeInfo } from './libraries.enum';
import { EventPreviewInfo } from './schedule.enum';
import { UserInfo, UserPreviewInfo } from './users.enum';

export enum EUserPollStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

export const userPollStatusList = [
  { value: EUserPollStatus.COMPLETED, label: 'Completed' },
  { value: EUserPollStatus.IN_PROGRESS, label: 'In Progress' },
  { value: EUserPollStatus.NOT_STARTED, label: 'Not Started' },
];

enum EPollStatus {
  CLOSED = 'CLOSED',
  PUBLISHED = 'PUBLISHED',
  NOT_PUBLISHED = 'NOT_PUBLISHED',
}

export type PollTemplateGroupType = {
  id: string;
  name: string;
  position: number;
  fields: Array<PollTemplateGroupFieldType>;
};

export type PollTemplateGroupFieldType = {
  commentingAllowed: boolean;
  isHidden: boolean;
  dataSource: string;
  id: string;
  options: [{ id: ''; isRightAnswer: boolean | null; value: string; score: number | null }];
  position: number;
  question: string;
  required: boolean;
  templateDatabaseDataSource: TemplateDatabaseDataSource;
  type: string;
};

export class PollTemplate {
  id: string;
  name: string;
  author: UserInfo;
  categoryId: string;
  categoryName: string;
  createdDate: string;
  lastEditor: UserInfo;
  updatedDate: string;
  groups: Array<PollTemplateGroupType>;
  pollsNumber: number;

  constructor(pollTemplate?: unknown) {
    this.id = get(pollTemplate, 'id', '');
    this.name = get(pollTemplate, 'name', '');
    this.createdDate = get(pollTemplate, 'createdDate', '');
    this.categoryId = get(pollTemplate, 'categoryId', { id: null });
    this.categoryName = get(pollTemplate, 'categoryName', '');
    this.author = new UserInfo(get(pollTemplate, 'author', {}));
    this.lastEditor = new UserInfo(get(pollTemplate, 'lastEditor', {}));
    this.updatedDate = get(pollTemplate, 'updatedDate', '');
    this.pollsNumber = get(pollTemplate, 'pollsNumber', 0);
    this.groups = get(pollTemplate, 'groups', [
      {
        fields: [
          {
            commentingAllowed: true,
            isHidden: false,
            dataSource: null,
            id: '',
            options: [],
            position: 0,
            question: '',
            required: true,
            type: DATE_PICKER,
            templateDatabaseDataSource: null,
          },
        ],
        id: '',
        name: '',
        position: 0,
      },
    ]);
  }

  static getPollDate(date: string) {
    return moment(date).format(DATE_FORMAT.ll);
  }
}

export class NewPoll {
  anonymous: boolean;
  author: UserPreviewInfo;
  categoryName: string;
  createdDate: string;
  deadline: string;
  description: string;
  id: string;
  isGeneral: boolean;
  name: string;
  officeIds: Array<string>;
  offices: Array<OfficeInfo>;
  relatedEvent: EventPreviewInfo;
  relatedEventId: string;
  repliesNumber: number;
  responderIds: Array<string>;
  responders: Array<UserPreviewInfo>;
  status: EPollStatus;
  template: PollTemplate;
  templateId: string;
  updatedDate: string;
  updater: UserPreviewInfo;
  organizerIds: string[];
  organizers: Array<UserPreviewInfo>;
  userStatus: EUserPollStatus;

  constructor(newPoll?: unknown) {
    this.id = get(newPoll, 'id', '');
    this.name = get(newPoll, 'name', '');
    this.author = new UserPreviewInfo(get(newPoll, 'author', {}));
    this.createdDate = get(newPoll, 'createdDate', '');
    this.categoryName = get(newPoll, 'categoryName', '');
    this.deadline = get(newPoll, 'deadline', '');
    this.description = get(newPoll, 'description', '');
    this.officeIds = defaultTo(get(newPoll, 'officeIds', []), []);
    this.offices = get(newPoll, 'offices', []);
    this.relatedEvent = new EventPreviewInfo(get(newPoll, 'relatedEvent', {}));
    this.relatedEventId = get(newPoll, 'relatedEventId', '');
    this.responderIds = defaultTo(get(newPoll, 'responderIds', []), []);
    this.responders = defaultTo(get(newPoll, 'responders', []), []).map((user: any) => new UserPreviewInfo(user));
    this.status = get(newPoll, 'status', notPublishedValue);
    this.anonymous = get(newPoll, 'anonymous', false);
    this.isGeneral = get(newPoll, 'isGeneral', false);
    this.repliesNumber = get(newPoll, 'repliesNumber', 0);
    this.templateId = get(newPoll, 'templateId', '');
    this.updatedDate = get(newPoll, 'updatedDate', '');
    this.organizerIds = get(newPoll, 'organizerIds', []);
    this.organizers = get(newPoll, 'organizers', []).map((user: any) => new UserPreviewInfo(user));
    this.updater = new UserPreviewInfo(get(newPoll, 'updater', {}));
    this.template = new PollTemplate(get(newPoll, 'template', {}));
    this.userStatus = get(newPoll, 'userStatus', EUserPollStatus.NOT_STARTED);
  }

  static getPollDate(date: string) {
    return moment(date).format(DATE_FORMAT.ll);
  }
}

export const POLL_VALIDATION_SCHEMA = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required('Required'),
  isGeneral: yup.boolean(),
  deadline: yup.string().required('Required'),
  description: yup.string(),
  template: yup.object({
    name: yup.string().required('Required').typeError('Required'),
    categoryId: yup.string().required('Required').typeError('Required'),
  }),
  templateId: yup.string().required('Required'),
  responderIds: yup
    .array()
    .test('responderIds', 'At least one organizer is required', (value: any, testContext: any) => {
      const formValue = testContext.from[0].value;
      const officeIds = formValue.officeIds;
      const isGeneral = formValue.isGeneral;
      return !(isEmpty(value) && isEmpty(officeIds)) || isGeneral;
    }),
  organizers: yup.array().min(1, 'At least one organizer is required').required(),
  organizerIds: yup.array().min(1).required('Required'),
});

// In edit mode we don't have to check responders field
export const POLL_EDIT_VALIDATION_SCHEMA = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required('Required'),
  isGeneral: yup.boolean(),
  deadline: yup.string().required('Required'),
  description: yup.string(),
  template: yup.object({
    name: yup.string().required('Required').typeError('Required'),
    categoryId: yup.string().required('Required').typeError('Required'),
  }),
  templateId: yup.string().required('Required'),
  organizers: yup.array().min(1, 'At least one organizer is required').required(),
  organizerIds: yup.array().min(1).required('Required'),
});

export const POLL_TEMPLATES_CATEGORY_VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().required('Required'),
});

export const POLL_TEMPLATES_VALIDATION_SCHEMA = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required('Required'),
  categoryId: yup.string().required('Required').typeError('Required'),
  groups: yup.array(
    yup.object({
      name: yup.string().trim().required('Required'),
      position: yup.number(),
      fields: yup.array(
        yup.object({
          isHidden: yup.boolean(),
          position: yup.number(),
          question: yup.string().trim().required('Required'),
          options: yup.array().when('dataSource', {
            is: (dataSource: TemplateDataSource) => dataSource === TemplateDataSource.CUSTOM_LIST,
            then: yup.array().min(1, 'Required'),
          }),
          templateDatabaseDataSource: yup
            .string()
            .nullable()
            .when('dataSource', {
              is: (dataSource: TemplateDataSource) => dataSource === TemplateDataSource.DATABASE,
              then: yup.string().required('Required').nullable(),
            }),
          type: yup.string(),
        }),
      ),
    }),
  ),
});

export const SINGLE_CHOICE_CUSTOM_ANSWERS_VALIDATION_SCHEMA = yup.object().shape({
  answers: yup
    .array()
    .min(1, 'At least one answer are required')
    .of(
      yup.object().shape({
        value: yup.string().trim().required('Required'),
        score: yup.number().max(999, 'The maximum number is 999').min(0, 'The minimum number is 0'),
        isRightAnswer: yup.boolean().test((value: any, testContext: any) => {
          return testContext.from[1].value.answers.filter((ans: any) => ans.isRightAnswer).length > 1 && value
            ? testContext.createError({
                path: testContext.path,
                message: 'Must be one right question',
              })
            : true;
        }),
      }),
    ),
});

export const MULTIPLE_CHOICE_CUSTOM_ANSWERS_VALIDATION_SCHEMA = yup.object().shape({
  answers: yup
    .array()
    .min(1, 'At least one answer are required')
    .of(
      yup.object().shape({
        value: yup.string().trim().required('Required'),
        score: yup.number().max(999, 'The maximum number is 999').min(0, 'The minimum number is 0'),
      }),
    ),
});

export const ANSWERS_OWN_POLL_VALIDATION = yup.object();
