import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../components/Button';
import * as brandingAction from '../../actions/branding.actions';
import Icon from '../../components/Icon';
import messages from './messages';
import { connect, ConnectedProps } from 'react-redux';
import { ResourceLink } from '../../enums/branding.enum';
import ModalNewMenuLink from '../../components/DashboardManagement/Modals/ModalNewMenuLink';
import Table from '../../components/Table';
import ModalEditMenuLink from '../../components/DashboardManagement/Modals/ModalEditMenuLink';
import ModalDeleteMenuLink from '../../components/DashboardManagement/Modals/ModalDeleteMenuLink';
import { createFormData } from '../../utils/data.utils';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_RESOURCE_LINK, UPDATE_RESOURCE_LINK } from '../../constants/policies.constants';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';

function DashboardManagement({
  tableData,
  isLoading,
  menuLinksTableError,
  menuLinkError,
  getMenuLinksList,
  createNewMenuLink,
  editMenuLinkByAdmin,
  deleteMenuLink,
  resetErrors,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const [modalNewMenuLinkIsOpen, setMoldaNewMenuLinkIsOpen] = useState(false);
  const [modalEditMenuLinkIsOpen, setMoldaEditMenuLinkIsOpen] = useState(false);
  const [modalDeleteMenuLinkIsOpen, setMoldaDeleteMenuLinkIsOpen] = useState(false);
  const [menuLinkClicked, setMenuLinkClicked] = useState(new ResourceLink());

  const intl = useIntl();

  useEffect(() => {
    getMenuLinksList();
  }, []);

  const openNewMenuLinkModal = useCallback(() => {
    setMoldaNewMenuLinkIsOpen(true);
  }, []);

  const closeNewMenuLinkModal = useCallback(() => {
    setMoldaNewMenuLinkIsOpen(false);
  }, []);

  const openEditMenuLinkModal = useCallback(() => {
    setMoldaEditMenuLinkIsOpen(true);
  }, []);

  const closeEditMenuLinkModal = useCallback(() => {
    modalEditMenuLinkIsOpen && setMenuLinkClicked(new ResourceLink());
    setMoldaEditMenuLinkIsOpen(false);
  }, [modalEditMenuLinkIsOpen]);

  const openDeleteMenuLinkModal = useCallback(() => {
    setMoldaDeleteMenuLinkIsOpen(true);
  }, []);

  const closeDeleteMenuLinkModal = useCallback(() => {
    setMoldaDeleteMenuLinkIsOpen(false);
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.iconLabel),
        modifier: (row: ResourceLink) =>
          row.file?.url && (
            <div className="icon-wrapper">
              <img src={row.file?.url} />
            </div>
          ),
        className: 'link-icon',
      },
      {
        name: intl.formatMessage(messages.label),
        modifier: (row: ResourceLink) => row.displayName,
        className: 'link-name',
      },
      {
        name: intl.formatMessage(messages.urlLabel),
        modifier: (row: ResourceLink) => (
          <a className="custom-menu-link" href={row.url} target="_blank" rel="noreferrer">
            {row.url}
          </a>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            {intl.formatMessage(messages.editButton)}
          </>
        ),
        handler: (row: ResourceLink) => {
          setMenuLinkClicked(row);
          openEditMenuLinkModal();
        },
        verifiablePolicies: [UPDATE_RESOURCE_LINK],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            {intl.formatMessage(messages.deleteButton)}
          </>
        ),
        handler: (row: ResourceLink) => {
          setMenuLinkClicked(row);
          openDeleteMenuLinkModal();
        },
        verifiablePolicies: [DELETE_RESOURCE_LINK],
      },
    ],
    [],
  );

  const submitTable = useCallback(
    (data: Record<string, unknown>) => {
      const menuLink = tableData[data.oldIndex as number];
      menuLink.defaultPosition = data.newIndex;
      editMenuLinkByAdmin({
        id: menuLink.id,
        data: createFormData({ linkDto: menuLink }),
      });
    },
    [tableData],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_RESOURCE_LINK]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewMenuLinkModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table custom-navigation'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getMenuLinksList}
            error={menuLinksTableError}
            tableActions={tableActions}
            onDragSort={submitTable}
            sortable={checkPolicies([UPDATE_RESOURCE_LINK], policies)}
          />
        </div>
      </div>
      {modalNewMenuLinkIsOpen && (
        <ModalNewMenuLink
          isOpen
          onCloseRequest={closeNewMenuLinkModal}
          createNewMenuLink={createNewMenuLink}
          resetErrors={resetErrors}
          isLoading={isLoading.createMenuLink}
          menuLinkError={menuLinkError}
          defaultPosition={tableData.length}
        />
      )}
      {modalEditMenuLinkIsOpen && (
        <ModalEditMenuLink
          isOpen
          onCloseRequest={closeEditMenuLinkModal}
          editMenuLinkByAdmin={editMenuLinkByAdmin}
          resetErrors={resetErrors}
          menuLinkData={menuLinkClicked}
          isLoading={isLoading.editMenuLinkByAdmin}
          menuLinkError={menuLinkError}
        />
      )}
      {modalDeleteMenuLinkIsOpen && (
        <ModalDeleteMenuLink
          isOpen
          onCloseRequest={closeDeleteMenuLinkModal}
          onDeleteRequest={deleteMenuLink}
          resetErrors={resetErrors}
          menuLinkData={menuLinkClicked}
          isLoading={isLoading.deleteMenuLink}
          menuLinkError={menuLinkError}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ branding }: RootState) => ({
  tableData: branding.menuLinksTableData,
  isLoading: branding.loading,
  menuLinkError: branding.errors.menuLinkError,
  menuLinksTableError: branding.errors.menuLinksListError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getMenuLinksList: () => dispatch(brandingAction.getMenuLinksList()),
  createNewMenuLink: (data: any) => dispatch(brandingAction.createNewMenuLink(data)),
  editMenuLinkByAdmin: (data: any) => dispatch(brandingAction.editMenuLinkByAdmin(data)),
  deleteMenuLink: (data: any) => dispatch(brandingAction.deleteMenuLink(data)),
  resetErrors: () => dispatch(brandingAction.resetBrandingErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DashboardManagement);
