import { useFormik } from 'formik';
import { get } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BONUS_INTERVAL_BOUNDARIES } from '../../../constants/bonuses.constants';
import {
  BonusCategoryInfo,
  BonusExperiencesInfo,
  BONUS_CATEGORY_VALIDATION_SCHEMA,
} from '../../../enums/bonuses.enums';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import ErrorMessage from '../../ErrorMessage';
import Icon from '../../Icon';
import Input from '../../Input';
import Modal from '../../Modal';
import NumberInput from '../../NumberInput';
import Select from '../../Select';
import Switch from '../../Switch';
import messages from '../messages';
import { handleSubmitData } from '../../../utils/bonuses.utils';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { scrollToError } from '../../../utils';
import { ReactSortable } from 'react-sortablejs';

type ModalNewBonusProps = {
  onCloseRequest: () => void;
  createNewBonus: (data: { data: BonusCategoryInfo; callback: () => void }) => void;
  resetErrors: () => void;
  isOpen: boolean;
  bonusError: null | string | RejectValueErrors[];
  isLoading: boolean;
  officesOptions: any;
};

function ModalNewBonus({
  isOpen,
  officesOptions,
  bonusError,
  isLoading,
  onCloseRequest,
  createNewBonus,
  resetErrors,
}: ModalNewBonusProps) {
  const intl = useIntl();
  const [dependsOnExp, setDependsOnExp] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError, resetForm } = useFormik({
    initialValues: new BonusCategoryInfo(),
    validationSchema: BONUS_CATEGORY_VALIDATION_SCHEMA,
    validate: scrollToError,
    validateOnChange: false,
    onSubmit: data => createNewBonus({ data: handleSubmitData(data), callback: resetAndExit }),
  });

  useSetFieldsErrors(bonusError, setFieldError);

  const bonusInterval = useMemo(
    () =>
      BONUS_INTERVAL_BOUNDARIES.map(value => {
        return {
          label: value[0] + value.slice(1).toLowerCase(),
          value: value,
        };
      }),
    [],
  );

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const removeBonus = (deleteBonusIndex: any) => {
    setFieldValue(
      'experiences',
      values.experiences.filter((el: any, index) => index !== deleteBonusIndex),
    );
  };

  const addBonus = useCallback(() => {
    setFieldValue('experiences', [...values.experiences, new BonusExperiencesInfo()]);
  }, [values]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleDependsOnExp = useCallback(() => {
    if (dependsOnExp) {
      setFieldValue('fromCompanyExperience', false);
      setFieldValue('fromPreviousExperience', false);
      values.experiences.forEach((exp, index) => {
        setFieldValue(`experiences[${index}].restrictionFrom`, null);
        setFieldValue(`experiences[${index}].restrictionTo`, null);
      });
    } else {
      setFieldValue('fromPreviousExperience', true);
    }
    setDependsOnExp(!dependsOnExp);
  }, [dependsOnExp, values]);

  const handleOfficeChange = useCallback((newValue: any) => {
    setFieldValue('officeId', newValue.value);
  }, []);

  const handleRestrictionFromTypeChange = useCallback((newValue: any, index: number) => {
    setFieldValue(`experiences[${index}].restrictionFrom.type`, newValue.value);
  }, []);

  const handleRestrictionToTypeChange = useCallback((newValue: any, index: number) => {
    setFieldValue(`experiences[${index}].restrictionTo.type`, newValue.value);
  }, []);

  const handleCountUpRestrictionFrom = useCallback(
    (index: number) => {
      const count = values.experiences[index]?.restrictionFrom?.count || 0;
      setFieldValue(`experiences[${index}].restrictionFrom.count`, count + 1);
      if (count < 0) {
        setFieldValue(`experiences[${index}].restrictionFrom.count`, 1);
      }
    },
    [values],
  );

  const handleCountDownRestrictionFrom = useCallback(
    (index: number) => {
      const count = values.experiences[index]?.restrictionFrom?.count || 2;
      if (count > 1) {
        setFieldValue(`experiences[${index}].restrictionFrom.count`, count - 1);
      }
    },
    [values],
  );

  const handleCountUpRestrictionTo = useCallback(
    (index: number) => {
      const count = values.experiences[index]?.restrictionTo?.count || 0;
      setFieldValue(`experiences[${index}].restrictionTo.count`, count + 1);
      if (count < 0) {
        setFieldValue(`experiences[${index}].restrictionTo.count`, 1);
      }
    },
    [values],
  );

  const handleCountDownRestrictionTo = useCallback(
    (index: number) => {
      const count = values.experiences[index]?.restrictionTo?.count || 2;
      if (count > 1) {
        setFieldValue(`experiences[${index}].restrictionTo.count`, count - 1);
      }
    },
    [values],
  );

  const handleRestrictionFromCountChange = useCallback(
    (e: any, index: number) => {
      if (!e.target.value && !values.experiences[index]?.restrictionFrom?.type) {
        setFieldValue(`experiences[${index}].restrictionFrom`, null);
        return null;
      }
      if (!e.target.value) {
        setFieldValue(`experiences[${index}].restrictionFrom.count`, null);
      } else if (e.target.value < 1) {
        setFieldValue(`experiences[${index}].restrictionFrom.count`, 1);
      } else {
        handleChange(e);
      }
    },
    [values],
  );

  const handleRestrictionToCountChange = useCallback(
    (e: any, index: number) => {
      if (!e.target.value && !values.experiences[index]?.restrictionTo?.type) {
        setFieldValue(`experiences[${index}].restrictionTo`, null);
        return null;
      }
      if (!e.target.value) {
        setFieldValue(`experiences[${index}].restrictionTo.count`, null);
      } else if (e.target.value < 1) {
        setFieldValue(`experiences[${index}].restrictionTo.count`, 1);
      } else {
        handleChange(e);
      }
    },
    [values],
  );

  const bonusErrors: any = useMemo(() => errors.experiences, [errors.experiences]);

  return (
    <Modal isOpen={isOpen} title={intl.formatMessage(messages.newBonusTitle)} onRequestClose={resetAndExit}>
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper new-bonus-wrapper">
          <div className="form__group">
            <Input
              name="name"
              label={intl.formatMessage(messages.bonusCategoryLabel)}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
            />
            <Select
              label="Office"
              name="officeId"
              handleChange={handleOfficeChange}
              hasError={hasError('officeId')}
              errorMessage={errors?.officeId}
              options={officesOptions}
            />
          </div>

          <div className="form__inputs-subwrapper depends-of-experience">
            <Switch onChange={handleDependsOnExp} checked={dependsOnExp} />
            <div className="switch__title">
              <FormattedMessage {...messages.bonusDependsOnExpLabel} />
            </div>
          </div>
          {dependsOnExp && (
            <div className="form__group">
              <div className="form__input-block">
                <Checkbox
                  externalClass="form__checkbox-capitalazie-label"
                  onChange={handleChange}
                  id="fromPreviousExperience"
                  label={intl.formatMessage(messages.bonusPrevExpLabel)}
                  checked={values.fromPreviousExperience}
                  disabled={!values.fromCompanyExperience}
                />
              </div>
              <div className="form__input-block">
                <Checkbox
                  externalClass="form__checkbox-capitalazie-label"
                  onChange={handleChange}
                  id="fromCompanyExperience"
                  label={intl.formatMessage(messages.bonusExpInCompnayLabel)}
                  checked={values.fromCompanyExperience}
                  disabled={!values.fromPreviousExperience}
                />
              </div>
            </div>
          )}
          <div className="form__group">
            <ReactSortable
              list={values.experiences}
              animation={200}
              handle={'.form__btn-move-inputs'}
              style={{ marginBottom: '14px' }}
              setList={newState => setFieldValue(`experiences`, newState)}
            >
              {values.experiences.map((bonus: any, index) => {
                return (
                  <div className="form__group-wrapper" key={index}>
                    <button className="form__btn-move-inputs" type={'button'}>
                      <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
                    </button>
                    <div className="form__input-block">
                      <Input
                        label={intl.formatMessage(messages.bonusExpLabel)}
                        name={`experiences[${index}].name`}
                        onChange={handleChange}
                        defaultValue={values.experiences[index].name}
                        hasError={hasError(`experiences[${index}].name`)}
                        errorMessage={bonusErrors && bonusErrors[index]?.name}
                      />
                      {dependsOnExp && (
                        <div className="form__inputs-bonuses-exp-value">
                          <div className="form__input-block">
                            <div className="form__inputs-subwrapper align-start">
                              <NumberInput
                                min={1}
                                name={`experiences[${index}].restrictionFrom.count`}
                                label={intl.formatMessage(messages.fromLabel)}
                                onChange={e => handleRestrictionFromCountChange(e, index)}
                                wrapperClass="number-input"
                                defaultValue={values.experiences[index]?.restrictionFrom?.count}
                                hasError={
                                  hasError(`experiences[${index}].restrictionFrom.count`) ||
                                  hasError(`experiences[${index}].restrictionFrom`)
                                }
                                errorMessage={
                                  bonusErrors &&
                                  (bonusErrors[index]?.restrictionFrom?.count || bonusErrors[index]?.restrictionFrom)
                                }
                                onCountUp={() => handleCountUpRestrictionFrom(index)}
                                onCountDown={() => handleCountDownRestrictionFrom(index)}
                              />
                              <Select
                                name={`experiences[${index}].restrictionFrom.type`}
                                handleChange={data => handleRestrictionFromTypeChange(data, index)}
                                options={bonusInterval}
                                defaultValue={bonusInterval[0]}
                                externalClass="select__no-label"
                                hasError={hasError(`experiences[${index}].restrictionFrom.type`)}
                                errorMessage={bonusErrors && bonusErrors[index]?.restrictionFrom?.type}
                              />
                            </div>
                          </div>

                          <div className="form__input-block">
                            <div className="form__inputs-subwrapper align-start">
                              <NumberInput
                                min={1}
                                name={`experiences[${index}].restrictionTo.count`}
                                label={intl.formatMessage(messages.toLabel)}
                                wrapperClass="number-input"
                                onChange={e => handleRestrictionToCountChange(e, index)}
                                defaultValue={values.experiences[index]?.restrictionTo?.count}
                                hasError={
                                  hasError(`experiences[${index}].restrictionTo.count`) ||
                                  hasError(`experiences[${index}].restrictionTo`)
                                }
                                errorMessage={
                                  bonusErrors &&
                                  (bonusErrors[index]?.restrictionTo?.count || bonusErrors[index]?.restrictionTo)
                                }
                                onCountUp={() => handleCountUpRestrictionTo(index)}
                                onCountDown={() => handleCountDownRestrictionTo(index)}
                              />
                              <Select
                                name={`experiences[${index}].restrictionTo.type`}
                                options={bonusInterval}
                                handleChange={data => handleRestrictionToTypeChange(data, index)}
                                defaultValue={bonusInterval[0]}
                                externalClass="select__no-label"
                                hasError={hasError(`experiences[${index}].restrictionTo.type`)}
                                errorMessage={bonusErrors && bonusErrors[index]?.restrictionTo?.type}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <button className="form__btn-clean-inputs" type={'button'} onClick={() => removeBonus(index)}>
                      <Icon iconName={'cross'} />
                    </button>
                  </div>
                );
              })}
            </ReactSortable>
          </div>
          <button className="form__btn-add-group" onClick={addBonus} type={'button'}>
            <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
            <FormattedMessage {...messages.addBonusExpLabel} />
          </button>
        </div>

        <ErrorMessage>{!Array.isArray(bonusError) && bonusError}</ErrorMessage>
        <div className={'form__buttons'}>
          <Button color={'gray'} type="button" externalClass={'button--modal button--cancel'} onClick={resetAndExit}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type="submit" loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalNewBonus;
