import React from 'react';
import classNames from 'classnames';

type LabelProps = {
  type: string;
  size?: string;
  externalClass?: string;
  children: string | React.ReactNode;
  color?: string;
};

function Label({ type, externalClass, children, size, color }: LabelProps) {
  const defaultClass = classNames(
    'label',
    externalClass,
    size ? `label--${size}` : false,
    type ? `label--${type}` : false,
  );
  return (
    <div
      className={defaultClass}
      style={
        //@ts-ignore
        { backgroundColor: color }
      }
    >
      {children}
    </div>
  );
}

export default Label;
