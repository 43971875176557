import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'auth.sign.in.title.it.portal',
    defaultMessage: 'ITPortal!',
  },
  userNameInput: {
    id: 'auth.sign.in.label.username',
    defaultMessage: 'Username',
  },
  passwordInput: {
    id: 'auth.sign.in.label.password',
    defaultMessage: 'Password',
  },
  signInButton: {
    id: 'auth.sign.in.btn.sign.in',
    defaultMessage: 'Sign In',
  },
  signInSsoButton: {
    id: 'auth.sign.in.btn.sign.in.with.sso',
    defaultMessage: 'Sign In with SSO',
  },
});
