import {
  getCommentsContent,
  getCommentsContentAfterPost,
  getDaysOffContent,
  validateEventErrors,
} from '../utils/schedule.utils';
import { DaysUsedLimitItem, EventCommentInfo, EventInfo, EventPreviewInfo } from '../enums/schedule.enum';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/schedule.constants';
import { EventTypeInfo } from '../enums/calendar.enum';
import { DaysUsedLimitParams, EventsParams } from '../enums/params/schedule.params';
import { RejectValueErrors } from '../enums/error.enum';
import { getReducerErrors } from '../utils/reducerError.utils';
import { DaysOffInfoType } from '../types/schedule/scheduleReducer.type';
import { ScheduleLayouts } from '../constants/schedule.constants';
import { InlineDatePickerPeriods } from '../components/InlineDatePicker/constants';

type InitialStateType = {
  eventType: EventTypeInfo | null;
  eventTableData: any;
  eventData: EventInfo;
  holidaysData: EventPreviewInfo[];
  daysOffInfo: DaysOffInfoType;
  eventComments: {
    comments: {
      content: EventCommentInfo[];
      number: number;
      last: false;
      size: number;
    };
    secretComments: {
      content: EventCommentInfo[];
      number: number;
      last: false;
      size: number;
    };
  };
  daysUsedLimitList: any;
  daysUsedLimitParams: DaysUsedLimitParams;
  errors: {
    eventTypeError: string | null;
    eventListError: string | null;
    eventError: string | null | RejectValueErrors[];
    commentsError: string | null;
    holidaysError: string | null;
    daysOffInfoError: string | null;
    secretCommentsError: string | null;
    daysUsedLimitError: string | null | RejectValueErrors[];
  };
  loading: {
    getEventType: boolean;
    getEventList: boolean;
    getEvent: boolean;
    createEvent: boolean;
    editEvent: boolean;
    deleteEvent: boolean;
    getComments: boolean;
    getSecretComments: boolean;
    createComments: boolean;
    createSecretComments: boolean;
    editComments: boolean;
    editSecretComments: boolean;
    deleteComments: boolean;
    deleteSecretComments: boolean;
    getHolidays: boolean;
    getDaysOffInfo: boolean;
    getDaysUsedLimit: boolean;
    putDaysUsedLimit: boolean;
  };
  params: EventsParams;
  userScheduleTabParams: EventsParams;
  layout: ScheduleLayouts;
  calendarPeriod: InlineDatePickerPeriods;
};

const initialState: InitialStateType = {
  eventType: null,
  eventTableData: [],
  holidaysData: [],
  eventData: new EventInfo(),
  daysOffInfo: {
    holidaysDates: [],
    extraWorkDates: [],
  },
  eventComments: {
    comments: {
      content: [],
      number: 0,
      last: false,
      size: 15,
    },
    secretComments: {
      content: [],
      number: 0,
      last: false,
      size: 15,
    },
  },
  daysUsedLimitList: null,
  daysUsedLimitParams: new DaysUsedLimitParams(),
  errors: {
    eventTypeError: null,
    eventListError: null,
    eventError: null,
    commentsError: null,
    secretCommentsError: null,
    holidaysError: null,
    daysOffInfoError: null,
    daysUsedLimitError: null,
  },
  loading: {
    getEventType: false,
    getEvent: false,
    getEventList: false,
    createEvent: false,
    editEvent: false,
    deleteEvent: false,
    getComments: false,
    getSecretComments: false,
    createComments: false,
    createSecretComments: false,
    editComments: false,
    editSecretComments: false,
    deleteComments: false,
    deleteSecretComments: false,
    getHolidays: false,
    getDaysOffInfo: false,
    getDaysUsedLimit: false,
    putDaysUsedLimit: false,
  },
  params: new EventsParams(),
  userScheduleTabParams: new EventsParams(),
  layout: ScheduleLayouts.CALENDAR,
  calendarPeriod: InlineDatePickerPeriods.MONTH_PERIOD,
};

const scheduleReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_EVENT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getEventType: true },
        errors: { ...state.errors, eventTypeError: null },
      };

    case ActionTypes.GET_EVENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEventType: false },
        errors: { ...state.errors, eventTypeError: null },
        eventType: new EventTypeInfo(action.payload),
      };

    case ActionTypes.GET_EVENT_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getEventType: false },
        errors: { ...state.errors, eventTypeError: action.payload.message },
      };

    case ActionTypes.GET_EVENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getEvent: true },
        errors: { ...state.errors, eventError: null },
      };

    case ActionTypes.GET_EVENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEvent: false },
        errors: { ...state.errors, eventError: null },
        eventData: new EventInfo(action.payload),
      };

    case ActionTypes.GET_EVENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getEvent: false },
        errors: { ...state.errors, eventError: action.payload.message },
      };

    case ActionTypes.GET_HOLIDAYS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getHolidays: true },
        errors: { ...state.errors, holidaysError: null },
      };

    case ActionTypes.GET_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getHolidays: false },
        errors: { ...state.errors, holidaysError: null },
        holidaysData: action.payload.content.map((event: any) => new EventPreviewInfo(event)),
      };

    case ActionTypes.GET_HOLIDAYS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getHolidays: false },
        errors: { ...state.errors, holidaysError: action.payload.message },
      };

    case ActionTypes.GET_DAYS_OFF_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDaysOffInfo: true },
        errors: { ...state.errors, daysOffInfoError: null },
      };

    case ActionTypes.GET_DAYS_OFF_INFO_SUCCESS:
      const data = getDaysOffContent(action.payload);

      return {
        ...state,
        loading: { ...state.loading, getDaysOffInfo: false },
        errors: { ...state.errors, daysOffInfoError: null },
        daysOffInfo: data,
      };

    case ActionTypes.GET_DAYS_OFF_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getDaysOffInfo: false },
        errors: { ...state.errors, daysOffInfoError: action.payload.message },
      };

    case ActionTypes.GET_EVENT_COMMENTS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getComments: true },
        errors: { ...state.errors, commentsError: null },
      };

    case ActionTypes.GET_EVENT_COMMENTS_SUCCESS: {
      const { results, afterDelete } = action.payload;
      const comments = state.eventComments.comments;
      const newCommentsContent = getCommentsContent(false, comments, results, afterDelete);
      return {
        ...state,
        loading: { ...state.loading, getComments: false },
        errors: { ...state.errors, commentsError: null },
        eventComments: {
          ...state.eventComments,
          comments: newCommentsContent,
        },
      };
    }

    case ActionTypes.GET_EVENT_COMMENTS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getComments: false },
        errors: { ...state.errors, commentsError: action.payload.message },
      };

    case ActionTypes.GET_EVENT_SECRET_COMMENTS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSecretComments: true },
        errors: { ...state.errors, commentsError: null },
      };

    case ActionTypes.GET_EVENT_SECRET_COMMENTS_SUCCESS: {
      const { results, afterDelete } = action.payload;
      const comments = state.eventComments.secretComments;
      const newCommentsContent = getCommentsContent(true, comments, results, afterDelete);
      return {
        ...state,
        loading: { ...state.loading, getComments: false },
        errors: { ...state.errors, commentsError: null },
        eventComments: {
          ...state.eventComments,
          secretComments: newCommentsContent,
        },
      };
    }

    case ActionTypes.GET_EVENT_SECRET_COMMENTS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getComments: false },
        errors: { ...state.errors, secretCommentsError: action.payload.message },
      };

    case ActionTypes.POST_EVENT_COMMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createComments: true },
      };

    case ActionTypes.POST_EVENT_COMMENT_SUCCESS: {
      const comments = state.eventComments.comments;
      const newCommentsContent = getCommentsContentAfterPost(false, comments, action.payload);
      return {
        ...state,
        loading: { ...state.loading, createComments: false },
        eventComments: {
          ...state.eventComments,
          comments: newCommentsContent,
        },
        errors: { ...state.errors, commentsError: null },
      };
    }

    case ActionTypes.POST_EVENT_COMMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createComments: false },
        errors: {
          ...state.errors,
          commentsError: action.payload.message,
        },
      };

    case ActionTypes.POST_EVENT_SECRET_COMMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createSecretComments: true },
      };

    case ActionTypes.POST_EVENT_SECRET_COMMENT_SUCCESS: {
      const comments = state.eventComments.secretComments;
      const newCommentsContent = getCommentsContentAfterPost(true, comments, action.payload);
      return {
        ...state,
        loading: { ...state.loading, createSecretComments: false },
        eventComments: {
          ...state.eventComments,
          secretComments: newCommentsContent,
        },
        errors: { ...state.errors, secretCommentsError: null },
      };
    }

    case ActionTypes.POST_EVENT_SECRET_COMMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createSecretComments: false },
        errors: {
          ...state.errors,
          secretCommentsError: action.payload.message,
        },
      };

    case ActionTypes.PUT_EVENT_COMMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editComments: true },
      };

    case ActionTypes.PUT_EVENT_COMMENT_SUCCESS: {
      const comments = state.eventComments.comments;
      return {
        ...state,
        loading: { ...state.loading, editComments: false },
        eventComments: {
          ...state.eventComments,
          comments: {
            ...comments,
            content: comments.content.map((comment: EventCommentInfo) =>
              comment.id === action.payload.id
                ? new EventCommentInfo(false, comment.commentPage, action.payload)
                : comment,
            ),
          },
        },
        errors: { ...state.errors, commentsError: null },
      };
    }

    case ActionTypes.PUT_EVENT_COMMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editComments: false },
        errors: { ...state.errors, commentsError: action.payload.message },
      };

    case ActionTypes.PUT_EVENT_SECRET_COMMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editSecretComments: true },
      };

    case ActionTypes.PUT_EVENT_SECRET_COMMENT_SUCCESS: {
      const comments = state.eventComments.secretComments;
      return {
        ...state,
        loading: { ...state.loading, editSecretComments: false },
        eventComments: {
          ...state.eventComments,
          secretComments: {
            ...comments,
            content: comments.content.map((comment: EventCommentInfo) =>
              comment.id === action.payload.id
                ? new EventCommentInfo(true, comment.commentPage, action.payload)
                : comment,
            ),
          },
        },
        errors: { ...state.errors, secretCommentsError: null },
      };
    }

    case ActionTypes.PUT_EVENT_SECRET_COMMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editSecretComments: false },
        errors: { ...state.errors, secretCommentsError: action.payload.message },
      };

    case ActionTypes.DELETE_EVENT_COMMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteComments: true },
      };

    case ActionTypes.DELETE_EVENT_COMMENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteComments: false },
        errors: { ...state.errors, commentsError: null },
      };

    case ActionTypes.DELETE_EVENT_COMMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteComments: false },
        errors: {
          ...state.errors,
          commentsError: action.payload.message,
        },
      };

    case ActionTypes.DELETE_EVENT_SECRET_COMMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteSecretComments: true },
      };

    case ActionTypes.DELETE_EVENT_SECRET_COMMENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteSecretComments: false },
        errors: { ...state.errors, secretCommentsError: null },
      };

    case ActionTypes.DELETE_EVENT_COMMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteSecretComments: false },
        errors: {
          ...state.errors,
          secretCommentsError: action.payload.message,
        },
      };

    case ActionTypes.RESET_EVENT_TYPE:
      return {
        ...state,
        eventType: null,
      };

    case ActionTypes.RESET_EVENTS:
      return {
        ...state,
        eventTableData: [],
        errors: { ...state.errors, eventListError: null },
      };

    case ActionTypes.RESET_EVENT:
      return {
        ...state,
        eventData: new EventInfo(),
        errors: { ...state.errors, eventError: null },
        eventComments: initialState.eventComments,
      };

    case ActionTypes.RESET_EVENT_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.GET_EVENTS_LIST_REQUEST:
    case ActionTypes.GET_USER_SCHEDULE_TAB_EVENTS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getEventList: true },
        errors: { ...state.errors, eventListError: null },
      };

    case ActionTypes.GET_ALL_EVENTS: {
      return {
        ...state,
        loading: { ...state.loading, getEventList: true },
        errors: { ...state.errors, eventListError: null },
        eventTableData: {
          ...action.payload,
          content: action.payload?.content?.map((event: any) => new EventPreviewInfo(event)),
        },
      };
    }

    case ActionTypes.GET_EVENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEventList: false },
        errors: { ...state.errors, eventListError: null },
        eventTableData: {
          ...action.payload,
          content: action.payload.content?.map((event: any) => new EventPreviewInfo(event)),
        },
      };

    case ActionTypes.GET_EVENTS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getEventList: false },
        errors: { ...state.errors, eventListError: action.payload.message },
      };

    case ActionTypes.SET_EVENT_PARAMS_REQUEST:
      return {
        ...state,
        params: new EventsParams({ ...state.params, ...action.payload.data }),
      };

    case ActionTypes.SET_USER_SCHEDULE_TAB_PARAMS_REQUEST:
      return {
        ...state,
        userScheduleTabParams: new EventsParams({ ...state.userScheduleTabParams, ...action.payload }),
        loading: { ...state.loading, getEventList: true },
      };

    case ActionTypes.POST_EVENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createEvent: true },
      };

    case ActionTypes.POST_EVENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createEvent: false },
        errors: { ...state.errors, eventError: null },
      };

    case ActionTypes.POST_EVENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createEvent: false },
        errors: {
          ...state.errors,
          eventError: validateEventErrors(getReducerErrors(action.payload)),
        },
      };

    case ActionTypes.DELETE_EVENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteEvent: true },
      };

    case ActionTypes.DELETE_EVENT_SUCCESS: {
      const eventTableData = state.eventTableData;
      const deletedEventId = action.payload;

      return {
        ...state,
        loading: { ...state.loading, deleteEvent: false },
        errors: { ...state.errors, eventError: null },
        eventTableData: deletedEventId
          ? {
              ...eventTableData,
              content: [...eventTableData.content.filter((el: EventPreviewInfo) => el.id !== deletedEventId)],
            }
          : eventTableData,
      };
    }

    case ActionTypes.DELETE_EVENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteEvent: false },
        errors: { ...state.errors, eventError: action.payload.message },
      };

    case ActionTypes.PUT_EVENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editEvent: true },
      };

    case ActionTypes.PUT_EVENT_SUCCESS: {
      const eventTableData = state.eventTableData;
      const updateEvent = action.payload;

      return {
        ...state,
        loading: { ...state.loading, editEvent: false },
        errors: { ...state.errors, eventError: null },
        eventTableData: updateEvent
          ? {
              ...eventTableData,
              content: [
                ...eventTableData.content.filter((el: EventPreviewInfo) => el.id !== updateEvent.id),
                new EventPreviewInfo(updateEvent),
              ],
            }
          : eventTableData,
      };
    }

    case ActionTypes.PUT_EVENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editEvent: false },
        errors: {
          ...state.errors,
          eventError: validateEventErrors(getReducerErrors(action.payload)),
        },
      };

    case ActionTypes.SET_SCHEDULE_LAYOUT:
      return {
        ...state,
        layout: action.payload,
      };

    case ActionTypes.SET_CALENDAR_PERIOD:
      return {
        ...state,
        calendarPeriod: action.payload,
      };

    case ActionTypes.RESET_SCHEDULE_STATE:
      return {
        ...initialState,
        params: state.params,
        userScheduleTabParams: state.userScheduleTabParams,
        layout: state.layout,
        calendarPeriod: state.calendarPeriod,
      };

    case ActionTypes.GET_DAYS_USED_LIMIT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDaysUsedLimit: false },
        errors: { ...state.errors, daysUsedLimitError: null },
        daysUsedLimitList: action.payload?.map((item: DaysUsedLimitItem) => new DaysUsedLimitItem(item)),
      };

    case ActionTypes.GET_DAYS_USED_LIMIT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getDaysUsedLimit: false },
        errors: { ...state.errors, daysUsedLimitError: action.payload.message },
        daysUsedLimitList: null,
      };

    case ActionTypes.SET_DAYS_USED_LIMIT_PARAMS:
      return {
        ...state,
        loading: { ...state.loading, getDaysUsedLimit: true },
        errors: { ...state.errors, daysUsedLimitError: null },
        daysUsedLimitParams: new DaysUsedLimitParams({ ...state.daysUsedLimitParams, ...action.payload }),
      };

    case ActionTypes.PUT_DAYS_LIMIT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, putDaysUsedLimit: true },
      };

    case ActionTypes.PUT_DAYS_LIMIT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, putDaysUsedLimit: false },
        errors: { ...state.errors, daysUsedLimitError: null },
      };

    case ActionTypes.PUT_DAYS_LIMIT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, putDaysUsedLimit: false },
        errors: {
          ...state.errors,
          daysUsedLimitError: getReducerErrors(action.payload),
        },
      };

    default:
      return state;
  }
};

export default scheduleReducer;
