import { defineMessages } from 'react-intl';
import {
  saveButton,
  cancelButton,
  officesLabel,
  departmentsLabel,
  employeesLabel,
  positionsLabel,
  filtersLabel,
  projectsLabel,
  skillsColumn,
  nameColumn,
  allLabel,
  experienceLabel,
  positionLabel,
  applyButton,
  clearButton,
  fromLabel,
  toLabel,
  statusLabel,
  updaterColumn,
  lastUpdateColumn,
  memberLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  saveButton,
  officesLabel,
  departmentsLabel,
  employeesLabel,
  positionsLabel,
  filtersLabel,
  projectsLabel,
  skillsColumn,
  nameColumn,
  allLabel,
  experienceLabel,
  positionLabel,
  applyButton,
  clearButton,
  fromLabel,
  toLabel,
  statusLabel,
  updaterColumn,
  lastUpdateColumn,
  memberLabel,
  technicalSkillsLabel: {
    id: 'resumes.management.filter.label.technical.skills',
    defaultMessage: 'Technical Skills',
  },
  levelLabel: {
    id: 'resumes.management.filter.label.level',
    defaultMessage: 'Level',
  },
  statusesLabel: {
    id: 'resumes.management.filter.label.statuses',
    defaultMessage: 'Statuses',
  },
  editModalTitle: {
    id: 'resumes.management.modal.edit.public.cv.title.edit.public.cv',
    defaultMessage: 'Edit Public CV',
  },
  educationLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.education',
    defaultMessage: 'Education',
  },
  aboutButton: {
    id: 'resumes.management.modal.edit.public.cv.btn.about',
    defaultMessage: 'About',
  },
  languageLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.language',
    defaultMessage: 'Language',
  },
  languageLevelLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.language.level',
    defaultMessage: 'Language Level',
  },
  summaryLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.summary',
    defaultMessage: 'Summary',
  },
  personalHighlightsLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.personal.highlights',
    defaultMessage: 'Personal Highlights',
  },
  interestAndHobbiesLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.interest.and.hobbies',
    defaultMessage: 'Interest and Hobbies',
  },
  skillGroupButton: {
    id: 'resumes.management.modal.edit.public.cv.btn.skill.group',
    defaultMessage: 'Skill Group',
  },
  projectNameLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.project.name',
    defaultMessage: ' Project Name',
  },
  descriptionLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.description',
    defaultMessage: ' Description',
  },
  projectButton: {
    id: 'resumes.management.modal.edit.public.cv.btn.project',
    defaultMessage: ' Project',
  },
  approvedLabel: {
    id: 'resumes.management.filter.approved',
    defaultMessage: 'Approved',
  },
  notApprovedLabel: {
    id: 'resumes.management.filter.not.approved',
    defaultMessage: 'Not Approved',
  },
  ofExperienceLabel: {
    id: 'resumes.management.modal.export.public.cv.lable.of.experience',
    defaultMessage: 'of Experience',
  },
  yearsLabel: {
    id: 'resumes.management.modal.export.public.cv.lable.years',
    defaultMessage: 'Years',
  },
  monthsLabel: {
    id: 'resumes.management.modal.export.public.cv.lable.months',
    defaultMessage: 'Months',
  },
  remarkableProjectsTitle: {
    id: 'resumes.management.modal.export.public.cv.title.remarkable.projects',
    defaultMessage: 'Remarkable Projects',
  },
  memberResumeLabel: {
    id: 'resumes.management.modal.export.public.cv.lable.member.resume',
    defaultMessage: 'Member Resume',
  },
  companyNameLabel: {
    id: 'resumes.management.modal.export.public.cv.lable.softarex.technologies',
    defaultMessage: 'Softarex Technologies',
  },
  nextButton: {
    id: 'resumes.management.modal.export.public.cv.btn.next',
    defaultMessage: 'Next',
  },
  exportButton: {
    id: 'resumes.management.modal.export.public.cv.btn.export',
    defaultMessage: 'Export',
  },
  backButton: {
    id: 'resumes.management.modal.export.public.cv.btn.back',
    defaultMessage: 'Back',
  },
  saveChangesLabel: {
    id: 'resumes.management.modal.export.public.cv.label.save.changes',
    defaultMessage: ' Save Changes',
  },
  photoLabel: {
    id: 'resumes.management.modal.export.public.cv.lable.photo',
    defaultMessage: 'Photo',
  },
  languagesLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.languages',
    defaultMessage: 'Languages',
  },
  exportAsTitle: {
    id: 'resumes.management.modal.edit.public.cv.title.export.as',
    defaultMessage: 'Export as',
  },
  oneFileLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.one.file',
    defaultMessage: 'One File',
  },
  separateFilesLabel: {
    id: 'resumes.management.modal.edit.public.cv.label.separate.files',
    defaultMessage: 'Separate Files',
  },
  includeDataTitle: {
    id: 'resumes.management.modal.edit.public.cv.title.include.data',
    defaultMessage: 'Include Data',
  },
  exportModalTitle: {
    id: 'resumes.management.modal.edit.public.cv.title.export.public.cv',
    defaultMessage: 'Export Public CV',
  },
});
