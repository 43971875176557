import { defineMessages } from 'react-intl';
import { editButton, deleteButton, newButton, nameColumn } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  pageTitle: {
    id: 'recognitions.recognition.type.title.recognition.type',
    defaultMessage: 'Recognition Types',
  },
});
