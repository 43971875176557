import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/export.constants';
import { Candidate } from '../enums/candidates.enums';

export const cancelExport = (): AnyAction => ({
  type: ActionTypes.CANCEL_EXPORT,
});

export const resetExportedData = (): AnyAction => ({
  type: ActionTypes.RESET_EXPORTED_DATA,
});

export const exportProjectsReport = (): AnyAction => ({
  type: ActionTypes.EXPORT_PROJECTS_REPORT_REQUEST,
});

export const exportEmployeesReport = (): AnyAction => ({
  type: ActionTypes.EXPORT_EMPLOYEES_REPORT_REQUEST,
});

export const exportEmployeesTimesheetReport = (): AnyAction => ({
  type: ActionTypes.EXPORT_EMPLOYEES_TIMESHEET_REPORT_REQUEST,
});

export const exportEmployeesIssueTypesReport = (): AnyAction => ({
  type: ActionTypes.EXPORT_EMPLOYEES_ISSUE_TYPES_REPORT_REQUEST,
});

export const exportActiveInactiveHoursReport = (): AnyAction => ({
  type: ActionTypes.EXPORT_ACTIVE_INACTIVE_HOURS_REPORT_REQUEST,
});

export const exportUsersHoursAbsencesReport = (): AnyAction => ({
  type: ActionTypes.EXPORT_USERS_HOURS_ABSENCES_REPORT_REQUEST,
});

export const exportAbsencePeriodsReport = (): AnyAction => ({
  type: ActionTypes.EXPORT_ABSENCE_PERIODS_REPORT_REQUEST,
});

export const exportPollData = (exportData: any): AnyAction => ({
  type: ActionTypes.EXPORT_POLL_DATA_REQUEST,
  payload: exportData,
});

export const exportDaysUsedLimit = (): AnyAction => ({
  type: ActionTypes.EXPORT_DAYS_USED_LIMIT_REQUEST,
});

export const exportUserHours = (ext: ActionTypes.EFileExtensions): AnyAction => ({
  type: ActionTypes.EXPORT_USER_HOURS_REQUEST,
  payload: ext,
});

export const exportProjectHours = (ext: ActionTypes.EFileExtensions): AnyAction => ({
  type: ActionTypes.EXPORT_PROJECT_HOURS_REQUEST,
  payload: ext,
});

export const exportUsers = (): AnyAction => ({
  type: ActionTypes.EXPORT_USERS_REQUEST,
});

export const exportCandidates = (data: Candidate[]): AnyAction => ({
  type: ActionTypes.EXPORT_CANDIDATES_REQUEST,
  payload: data,
});

export const exportIncomesExpensesReport = (ext: ActionTypes.EFileExtensions): AnyAction => ({
  type: ActionTypes.EXPORT_INCOMES_EXPENSES_REPORT_REQUEST,
  payload: ext,
});

export const exportActivity = () => ({
  type: ActionTypes.EXPORT_ACTIVITY_REQUEST,
});
