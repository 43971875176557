import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as planningActions from '../../actions/planning.actions';
import ActiveEmployeesFilters from '../../components/ActiveEmployees/Filter/index';
import Switch from '../../components/Switch';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import TableUserAvatar from '../../components/TableUserAvatar';
import { UserInfo } from '../../enums/users.enum';
import * as filtersActions from '../../actions/filters.actions';
import { ActiveEmployeesParams } from '../../enums/params/planning.params';
import { checkPolicies } from '../../utils/policies.utils';
import { UPDATE_ACTIVE_EMPLOYEE } from '../../constants/policies.constants';
import PoliciesContext from '../../PoliciesContext';
import HierarchicalTable from '../../components/HierarchicalTable';
import { resetParamsChange, useParamsChange, useTableData, useUsersParamsChange } from '../../utils/hooks.utils';
import { getTableCell } from '../../utils/table.utils';
import { DepthLevels } from '../../constants/tables.constants';
import { SavedFilter } from '../../enums/filters.enum';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { convertSavedFieldsToParams, getSavedFilterParams, getSavedFilters } from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { isEqual } from 'lodash-es';

export type ActiveEmployeesListItemType = {
  departmentId: string;
  departmentName: string;
  employees: UserInfo[];
};

function ActiveEmployees({
  params,
  tableData,
  isLoading,
  activeEmployeesDataError,
  setActiveEmployeesParams,
  editActiveEmployees,
  resetState,
  getUsersFilter,
  getOfficesFilter,
  getDepartmentsFilter,
  usersFilter,
  officesFilter,
  departmentsFilter,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.ACTIVE_MEMBERS_FILTER);

    setActiveEmployeesParams(
      currentSavedFilter
        ? new ActiveEmployeesParams(currentSavedFilter)
        : {
            officeIds: officesFilter.value,
            departmentIds: departmentsFilter.value,
            userIds: usersFilter.value,
          },
    );
    getUsersFilter();
    getOfficesFilter();
    getDepartmentsFilter();
    setSavedFiltersParams({ filterType: FilterTypes.ACTIVE_MEMBERS_FILTER });

    return () => {
      resetState();
    };
  }, []);

  const handleMultiParamsChange = useParamsChange(setActiveEmployeesParams, dispatch);

  const handleParamsChange = useParamsChange(setActiveEmployeesParams, null, false);

  const handleUsersParamsChange = useUsersParamsChange(setActiveEmployeesParams, dispatch);

  const tableColumns = useMemo(
    () => [
      {
        id: 'employeesTitle',
        Header: intl.formatMessage(messages.departmentsMembersLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ActiveEmployeesListItemType) => <span>{row.departmentName}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: UserInfo) => <TableUserAvatar users={[new UserInfo(row)]} fileSize={48} />,
            },
          ]),
      },
      {
        id: 'includedInPlanningTitle',
        Header: intl.formatMessage(messages.includedInPlanningTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: UserInfo) => (
                <Switch
                  onChange={e => {
                    editActiveEmployees({ id: row.id, data: { includedInPlanning: e.target.checked } });
                  }}
                  checked={row.includedInPlanning}
                  disabled={!checkPolicies([UPDATE_ACTIVE_EMPLOYEE], policies)}
                />
              ),
            },
          ]),
      },
    ],
    [policies],
  );

  const filters = useMemo(
    () => ({
      offices: officesFilter.offices,
      departments: departmentsFilter.departments,
      users: usersFilter.users,
    }),
    [officesFilter.offices, departmentsFilter.departments, usersFilter.users, ,],
  );

  const handleFiltersControlChange = useCallback(value => {
    setActiveEmployeesParams(new ActiveEmployeesParams(convertSavedFieldsToParams(value.fields)));
  }, []);

  const handleClear = useCallback(() => {
    setActiveEmployeesParams(new ActiveEmployeesParams());

    resetParamsChange(
      [FilterParamsName.OFFICE_IDS, FilterParamsName.USER_IDS, FilterParamsName.DEPARTMENT_IDS],
      dispatch,
    );
  }, []);

  const showClearButton = useMemo(() => !isEqual(params, new ActiveEmployeesParams()), [params]);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <ActiveEmployeesFilters
                  handleParamsChange={handleParamsChange}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleUsersParamsChange={handleUsersParamsChange}
                  values={params}
                  filters={filters}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content active_employees_page">
        <div className="page__wrapper">
          <HierarchicalTable
            tableData={useTableData(tableData, ['employees'])}
            tableHeaderClassName="user_hours_header"
            tableColumns={tableColumns}
            loading={isLoading.getActiveEmployees}
            error={activeEmployeesDataError}
            externalClass={'active_employees_table'}
            disabledDepthHover={[DepthLevels.SECOND]}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ planning, filters, auth }: RootState) => ({
  activeEmployeesDataError: planning.errors.activeEmployeesDataError,
  isLoading: planning.loading,
  tableData: planning.activeEmployeesTableData,
  officesFilter: filters.officesFilter,
  departmentsFilter: filters.departmentsFilter,
  usersFilter: filters.usersFilter,
  params: planning.activeEmployeesParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  setActiveEmployeesParams: (data: Partial<ActiveEmployeesParams>) =>
    dispatch(planningActions.setActiveEmployeesParams(data)),
  editActiveEmployees: (data: Record<string, any>) => dispatch(planningActions.editActiveEmployees(data)),
  resetState: () => dispatch(planningActions.resetPlaningState()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ActiveEmployees);
