import React from 'react';
import Scroll from 'react-infinite-scroll-component';
import CustomLoader from '../Loader';

type InfiniteScrollProps = {
  children: React.ReactNode;
  dataLength: number;
  fetchData: () => void;
  hasMore: boolean;
  height?: number;
  scrollRef?: any;
  id: string;
};

const InfiniteScroll = ({ children, scrollRef, dataLength, hasMore, id, fetchData }: InfiniteScrollProps) => {
  return (
    <div className="infinite-scroll-wrappper" id={id}>
      <Scroll
        dataLength={dataLength}
        next={fetchData}
        hasMore={hasMore}
        loader={<CustomLoader externalClass="infinite-scroll-loader" type="ThreeDots" />}
        scrollableTarget={id}
        ref={scrollRef}
      >
        {children}
      </Scroll>
    </div>
  );
};

export default InfiniteScroll;
