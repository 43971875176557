import React from 'react';
import Button from '../../Button';
import Modal from '../../Modal';
import { PollTemplate, PollTemplateGroupFieldType, PollTemplateGroupType } from '../../../enums/questionnaires.enum';
import Icon from '../../Icon';
import {
  PollTemplateFieldTypeOptions,
  SELECT_LIST_MULTIPLE_CHOICE,
  SELECT_LIST_SINGLE_CHOICE,
  SourcesInformationOptions,
} from '../../../constants/questionnaires.constants';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import Avatar from '../../Profile/Avatar';
import AccessChecker from '../../AccessChecker';
import { DELETE_TEMPLATE, UPDATE_TEMPLATE } from '../../../constants/policies.constants';
import r from '../../../constants/routes.constants';

type ModalPreviewPollTemplateProps = {
  onCloseRequest: () => void;
  templateData: PollTemplate;
  onDeleteRequest: () => void;
  isOpen: boolean;
};

function ModalPreviewPollTemplate({
  onCloseRequest,
  templateData,
  onDeleteRequest,
  isOpen,
}: ModalPreviewPollTemplateProps) {
  const navigate = useHistory();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={templateData.categoryName}
      shouldCloseOnOverlayClick
      popover
      classNameModalHead={'preview_template_category_title'}
      classNameModal={'preview_template_modal center'}
      modalOptions={
        <>
          <AccessChecker verifiablePolicies={[UPDATE_TEMPLATE]}>
            <Button
              externalClass={'modal__options-btn'}
              onClick={() => {
                document.body.style.overflow = 'auto';
                onCloseRequest();
                navigate.push(r.editPollTemplate.replace(':id', `${templateData.id}`));
              }}
              color="white"
            >
              <Icon iconName={'pencil'} externalClass={'modal__options-icon'} />
            </Button>
          </AccessChecker>
          <AccessChecker verifiablePolicies={[DELETE_TEMPLATE]}>
            <Button
              externalClass={'modal__options-btn'}
              onClick={() => {
                onDeleteRequest();
                onCloseRequest();
              }}
              color="white"
            >
              <Icon iconName={'trash'} externalClass={'modal__options-icon'} />
            </Button>
          </AccessChecker>
        </>
      }
    >
      <h2 className="modal__title template_name">{templateData.name}</h2>
      <div className="modal__wrapper">
        <div className="preview_template_modal__content ">
          <div className="modal__grid modal__grid--three-col">
            <div className="modal__grid__item">
              <div className="modal__grid__item-name">
                <FormattedMessage {...messages.authorColumn} />
              </div>
              <div className="modal__grid__item-value">
                {templateData?.author.photo && (
                  //  @ts-ignore
                  <Avatar userInfo={templateData?.author} size="small" isUserPageLink fileSize={48} />
                )}
                {templateData.author.fullName}
              </div>
            </div>
            <div className="modal__grid__item">
              <div className="modal__grid__item-name">
                <FormattedMessage {...messages.editorColumn} />
              </div>
              <div className="modal__grid__item-value">
                {templateData?.lastEditor.photo && (
                  //  @ts-ignore
                  <Avatar userInfo={templateData?.lastEditor} size="small" isUserPageLink fileSize={48} />
                )}
                {templateData.lastEditor.fullName}
              </div>
            </div>
            <div className="modal__grid__item">
              <div className="modal__grid__item-name">
                <FormattedMessage {...messages.pollsTitle} />
              </div>
              <div className="modal__grid__item-value">{templateData.pollsNumber || 0}</div>
            </div>
            <div className="modal__grid__item">
              <div className="modal__grid__item-name">
                <FormattedMessage {...messages.createdColumn} />
              </div>
              <div className="modal__grid__item-value">{PollTemplate.getPollDate(templateData.createdDate)}</div>
            </div>
            <div className="modal__grid__item">
              <div className="modal__grid__item-name">
                <FormattedMessage {...messages.updatedColumn} />
              </div>
              <div className="modal__grid__item-value">{PollTemplate.getPollDate(templateData.updatedDate)}</div>
            </div>
          </div>
          <div className="modal__questions">
            {templateData.groups.map((group: PollTemplateGroupType) => (
              <div key={group.id}>
                <div className="modal__questions-head">
                  <div className="modal__questions-title">{group.name} </div>
                  <div className="modal__questions-title">
                    <FormattedMessage {...messages.answerOptionsTitle} />
                  </div>
                </div>
                <ul className="modal__questions-body">
                  {group.fields.map((field: PollTemplateGroupFieldType, index) => (
                    <li className="modal__questions-item" key={field.id}>
                      <div className="modal__questions-item-wrapper">
                        <span className="modal__questions-item-number">{`${++index}.`}</span>
                        <div className="modal__questions-item-name">{field.question} </div>
                        {field.type === SELECT_LIST_MULTIPLE_CHOICE || field.type === SELECT_LIST_SINGLE_CHOICE ? (
                          field.dataSource === 'CUSTOM_LIST' ? (
                            <ul className="modal__questions-item-value answers_list">
                              {field.options.map((item: any, index: number) => (
                                <li key={index} className="answer_wrapper">
                                  <div className="answer_block">{item.value}</div>
                                  {item.isRightAnswer && (
                                    <div className="right_answer_marker">
                                      <FormattedMessage {...messages.labelCorrect} />
                                    </div>
                                  )}
                                </li>
                              ))}
                              {field.commentingAllowed ? (
                                <li className="answer_wrapper">
                                  <FormattedMessage {...messages.comments} />
                                </li>
                              ) : null}
                            </ul>
                          ) : (
                            <div className="modal__questions-item-value">
                              {SourcesInformationOptions.map((item: any, index: number) => {
                                if (field.options.length > 0 && item.value === field.options[0].value) {
                                  return (
                                    <div key={index} className="answer_wrapper">
                                      {item.label}
                                    </div>
                                  );
                                }
                              })}
                              {field.commentingAllowed ? (
                                <p className="answer_wrapper">
                                  <FormattedMessage {...messages.comments} />
                                </p>
                              ) : null}
                            </div>
                          )
                        ) : (
                          <div className="modal__questions-item-value">
                            {field.type === 'NOTE' ? (
                              <div className="answer_wrapper">
                                <FormattedMessage {...messages.labelInput} />
                              </div>
                            ) : (
                              PollTemplateFieldTypeOptions.map((item: any, index: number) => {
                                if (item.value === field.type) {
                                  return (
                                    <div key={index} className="answer_wrapper">
                                      {item.label}
                                    </div>
                                  );
                                }
                                if (field.type === 'NOTE') {
                                  return (
                                    <div className="answer_wrapper">
                                      <FormattedMessage {...messages.labelInput} />
                                    </div>
                                  );
                                }
                              })
                            )}
                            {field.commentingAllowed ? (
                              <p className="answer_wrapper">
                                <FormattedMessage {...messages.comments} />
                              </p>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalPreviewPollTemplate;
