import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Checkbox from '../../Checkbox';
import ErrorMessage from '../../ErrorMessage';
import Search from '../../Search';
import {
  PolicyGroupLevel,
  PolicyGroupModule,
  PolicyGroupSubmodule,
  PolicyTypeEnum,
  SubmodulePolicyType,
  UserPolicy,
} from '../../../enums/policies.enum';
import { useTableData } from '../../../utils/hooks.utils';
import HierarchicalTable from '../../HierarchicalTable';
import { getTableCell } from '../../../utils/table.utils';
import { DepthLevels } from '../../../constants/tables.constants';
import { checkPolicies } from '../../../utils/policies.utils';
import PoliciesContext from '../../../PoliciesContext';
import { UPDATE_POLICY } from '../../../constants/policies.constants';
import classNames from 'classnames';
import { OfficeInfo } from '../../../enums/libraries.enum';

type ModaEditPermissionsProps = {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  updateError: string;
  tableError: string;
  getUserPolicies: () => void;
  userPolicies: UserPolicy[];
  editUserPolicies: (policyIds: any[], callback: () => void) => void;
  getPolicyGroup: () => void;
  policyGroup: PolicyGroupLevel[];
  currentUserId: string;
  officeList: OfficeInfo[];
  getOfficesFilter: () => void;
};

function ModalEditPermissions({
  isOpen,
  onClose,
  loading,
  updateError,
  tableError,
  getUserPolicies,
  userPolicies,
  editUserPolicies,
  getPolicyGroup,
  policyGroup,
  currentUserId,
  officeList,
  getOfficesFilter,
}: ModaEditPermissionsProps) {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const [searchValue, setSearchValue] = useState('');

  const { values, setValues, handleSubmit } = useFormik({
    initialValues: userPolicies,
    enableReinitialize: true,
    validationSchema: '',
    onSubmit: data => editUserPolicies(data, onClose),
  });

  useEffect(() => {
    getPolicyGroup();
    getOfficesFilter();
  }, []);

  useEffect(() => {
    currentUserId && getUserPolicies();
  }, [currentUserId]);

  const updateAccess = useMemo(() => checkPolicies([UPDATE_POLICY], policies), [policies]);

  const getPolicyInPoliciesList = useCallback(
    (policyList: UserPolicy[], policyId: string) => policyList.find(item => item.policy.id === policyId),
    [],
  );

  const getOfficeInPoliciesList = useCallback(
    (ofiiceList: string[], officeId: string) => ofiiceList.find(item => item === officeId),
    [],
  );

  const handlePolicyChange = useCallback(
    (value: boolean, id: string, policyType: PolicyTypeEnum, submodule: PolicyGroupSubmodule) => {
      const policyList = [...values];
      if (value) {
        const currentPolicy = submodule.policies.find(item => item.policyType === policyType);

        if (policyType !== PolicyTypeEnum.VIEW) {
          const viewPolicy = submodule.policies.find(item => item.policyType === PolicyTypeEnum.VIEW);

          viewPolicy &&
            !getPolicyInPoliciesList(policyList, viewPolicy.id) &&
            policyList.push({
              id: '',
              policy: viewPolicy,
              officeIds: viewPolicy.isOfficeSpecificImplemented ? officeList.map(item => item.id) : [],
            });
        }
        currentPolicy &&
          !getPolicyInPoliciesList(policyList, currentPolicy.id) &&
          policyList.push({
            id: '',
            policy: currentPolicy,
            officeIds: currentPolicy.isOfficeSpecificImplemented ? officeList.map(item => item.id) : [],
          });
      } else {
        if (policyType === PolicyTypeEnum.VIEW) {
          submodule.policies.map(policy => {
            const policyIndex = policyList.findIndex(item => item.policy.id === policy?.id);

            policyIndex !== -1 && policyList.splice(policyIndex, 1);
          });
        } else {
          policyList.splice(
            policyList.findIndex(item => item.policy.id === id),
            1,
          );
        }
      }
      setValues(policyList);
    },
    [values, officeList],
  );

  const handleModuleChange = useCallback(
    (value: boolean, module: PolicyGroupModule, polisyType: PolicyTypeEnum) => {
      const policiesList: UserPolicy[] | PolicyGroupSubmodule[] = module.submodules.reduce(
        (policies, submodule: PolicyGroupSubmodule) => {
          const policy = submodule.policies.find(item => item.policyType === polisyType);

          if (value) {
            if (policy) {
              if (polisyType !== PolicyTypeEnum.VIEW) {
                const viewPolicy = submodule.policies.find(item => item.policyType === PolicyTypeEnum.VIEW);
                viewPolicy &&
                  !getPolicyInPoliciesList(policies, viewPolicy.id) &&
                  policies.push({
                    id: '',
                    policy: viewPolicy,
                    officeIds: viewPolicy.isOfficeSpecificImplemented ? officeList.map(item => item.id) : [],
                  });
              }

              !getPolicyInPoliciesList(policies, policy.id) &&
                policies.push({
                  id: '',
                  policy: policy,
                  officeIds: policy.isOfficeSpecificImplemented ? officeList.map(item => item.id) : [],
                });
            }
          } else {
            if (polisyType === PolicyTypeEnum.VIEW) {
              submodule.policies.map(policy => {
                const policyIndex = policies.findIndex(item => item.policy.id === policy?.id);
                policyIndex !== -1 && policies.splice(policyIndex, 1);
              });
            } else {
              const policy = submodule.policies.find(item => item.name.includes(polisyType));
              const policyIndex = policies.findIndex(item => item.policy.id === policy?.id);
              policyIndex !== -1 && policies.splice(policyIndex, 1);
            }
          }

          return policies;
        },
        [...values],
      );

      setValues(policiesList);
    },
    [values, officeList],
  );

  const handleLevelChange = useCallback(
    (value: boolean, level: PolicyGroupLevel, polisyType: PolicyTypeEnum) => {
      const policiesList = [...values];
      level.modules.map(module => {
        module.submodules.map(submodule => {
          if (value) {
            const policy = submodule.policies.find(item => item.policyType === polisyType);

            if (policy) {
              if (polisyType !== PolicyTypeEnum.VIEW) {
                const viewPolicy = submodule.policies.find(item => item.policyType === PolicyTypeEnum.VIEW);

                viewPolicy &&
                  !getPolicyInPoliciesList(policiesList, viewPolicy.id) &&
                  policiesList.push({
                    id: '',
                    policy: viewPolicy,
                    officeIds: viewPolicy.isOfficeSpecificImplemented ? officeList.map(item => item.id) : [],
                  });
              }
              !getPolicyInPoliciesList(policiesList, policy.id) &&
                policiesList.push({
                  id: '',
                  policy: policy,
                  officeIds: policy.isOfficeSpecificImplemented ? officeList.map(item => item.id) : [],
                });
            }
          } else {
            if (polisyType === PolicyTypeEnum.VIEW) {
              submodule.policies.map(policy => {
                const policyIndex = policiesList.findIndex(item => item.policy.id === policy.id);
                policyIndex !== -1 && policiesList.splice(policyIndex, 1);
              });
            } else {
              const policy = submodule.policies.find(item => item.policyType === polisyType);
              const policyIndex = policiesList.findIndex(item => item.policy.id === policy?.id);
              policyIndex !== -1 && policiesList.splice(policyIndex, 1);
            }
          }
        });
      });

      setValues(policiesList);
    },
    [values, officeList],
  );

  const handleOfficePolicyChange = useCallback(
    (
      value: boolean,
      polisyType: PolicyTypeEnum,
      submodulePolicies: SubmodulePolicyType[],
      officeId: string,
      policyId: string,
    ) => {
      const policyList = [...values];

      if (value) {
        if (polisyType !== PolicyTypeEnum.VIEW) {
          const submoduleViewPolicy = submodulePolicies.find(item => item.policyType === PolicyTypeEnum.VIEW);
          const valueViewPolicy = policyList.find(item => item.policy.id === submoduleViewPolicy?.id);
          const policyIndex = policyList.findIndex(item => item.policy.id === submoduleViewPolicy?.id);

          valueViewPolicy && policyIndex !== -1
            ? policyList.splice(policyIndex, 1, {
                ...valueViewPolicy,
                officeIds: !!getOfficeInPoliciesList(valueViewPolicy.officeIds, officeId)
                  ? valueViewPolicy.officeIds
                  : [...valueViewPolicy.officeIds, officeId],
              })
            : submoduleViewPolicy &&
              policyList.push({
                id: '',
                policy: submoduleViewPolicy,
                officeIds: [officeId],
                isOfficeSpecific: true,
              });
        }

        const submodulePolicy = submodulePolicies.find(item => item.policyType === polisyType);
        const valuePolicy = policyList.find(item => item.policy.id === submodulePolicy?.id);
        const policyIndex = policyList.findIndex(item => item.policy.id === submodulePolicy?.id);

        valuePolicy && policyIndex !== -1
          ? policyList.splice(policyIndex, 1, {
              ...valuePolicy,

              officeIds: !!getOfficeInPoliciesList(valuePolicy.officeIds, officeId)
                ? valuePolicy.officeIds
                : [...valuePolicy.officeIds, officeId],
            })
          : submodulePolicy &&
            policyList.push({
              id: '',
              policy: submodulePolicy,
              officeIds: [officeId],
              isOfficeSpecific: true,
            });
      } else {
        if (polisyType === PolicyTypeEnum.VIEW) {
          submodulePolicies.map(policy => {
            const policyIndex = policyList.findIndex(item => item.policy.id === policy?.id);
            const currentPolicy = policyList.find(item => item.policy.id === policy?.id);
            const newOfficeList = currentPolicy?.officeIds.filter(item => item !== officeId);

            if (currentPolicy && policyIndex !== -1) {
              newOfficeList?.length
                ? policyList.splice(policyIndex, 1, { ...currentPolicy, officeIds: newOfficeList })
                : policyList.splice(policyIndex, 1);
            }
          });
        } else {
          const currentPolicy = policyList.find(item => item.policy.id === policyId);
          const policyIndex = policyList.findIndex(item => item.policy.id === currentPolicy?.policy.id);
          const newOfficeList = currentPolicy?.officeIds.filter(item => item !== officeId);

          if (currentPolicy && policyIndex !== -1) {
            newOfficeList?.length
              ? policyList.splice(policyIndex, 1, { ...currentPolicy, officeIds: newOfficeList })
              : policyList.splice(policyIndex, 1);
          }
        }
      }

      setValues(policyList);
    },
    [values],
  );

  const checkModulePoliciesValue = useCallback(
    (module: PolicyGroupModule, polisyType: PolicyTypeEnum) => {
      //@ts-ignore
      const policiesList: UserPolicy[] = module.submodules.reduce((policies, value: PolicyGroupSubmodule) => {
        const policy = value.policies.find(item => item.policyType === polisyType);

        return policy && !getPolicyInPoliciesList(policies, policy.id)
          ? [...policies, { id: '', policy: policy, officeIds: [] }]
          : policies;
      }, []);
      return values?.some(value => policiesList.find((policy: UserPolicy) => policy.policy.id === value.policy.id));
    },
    [values, policyGroup],
  );

  const checkAllModulePoliciesValue = useCallback(
    (module: PolicyGroupModule, polisyType: PolicyTypeEnum) => {
      const policiesList: UserPolicy[] = module.submodules.reduce(
        (policies: UserPolicy[], value: PolicyGroupSubmodule) => {
          const policy = value.policies.find(item => item.policyType === polisyType);

          return policy ? [...policies, { id: '', policy: policy, officeIds: [] }] : policies;
        },
        [],
      );

      return policiesList.every(policy => {
        const currentPolicy = values?.find(value => value.policy.id === policy.policy?.id);

        return currentPolicy?.policy?.isOfficeSpecificImplemented
          ? currentPolicy?.officeIds.length === officeList.length
          : currentPolicy;
      });
    },
    [values, policyGroup],
  );

  const checkAllPolicyOfficies = useCallback(
    (policyId: string) => values?.find(item => item.policy.id === policyId)?.officeIds.length === officeList.length,
    [values, policyGroup, officeList],
  );

  const checkModulePolicies = useCallback(
    (module: PolicyGroupModule, polisyType: PolicyTypeEnum) =>
      module.submodules.some(submodule =>
        submodule.policies.find((item: { id: string; name: string }) => item.name.includes(polisyType)),
      ),
    [values, policyGroup],
  );

  const checkLevelPoliciesValue = useCallback(
    (level: PolicyGroupLevel, polisyType: PolicyTypeEnum) =>
      level.modules.some(module => checkModulePoliciesValue(module, polisyType)),
    [values, policyGroup],
  );

  const checkAllLevelPoliciesValue = useCallback(
    (level: PolicyGroupLevel, polisyType: PolicyTypeEnum) =>
      level.modules.every(module => checkAllModulePoliciesValue(module, polisyType)),
    [values, policyGroup],
  );

  const checkLevelPolicies = useCallback(
    (level: PolicyGroupLevel, polisyType: PolicyTypeEnum) =>
      level.modules.some(module =>
        module.submodules.some(submodule =>
          submodule.policies.find((item: { id: string; name: string }) => item.name.includes(polisyType)),
        ),
      ),
    [values, policyGroup],
  );

  const checkPolicyOffice = useCallback(
    (policyId: string, officeId: string) =>
      !!values.find(item => item.policy.id === policyId)?.officeIds.find(item => item === officeId),
    [values, policyGroup],
  );

  const policyGroupWithOffices = useMemo(
    () =>
      policyGroup?.map(level => ({
        ...level,
        modules: level.modules.map(module => ({
          ...module,
          submodules: module.submodules.map(submodule => ({
            ...submodule,
            offices: submodule.policies.some(policy => policy.isOfficeSpecificImplemented)
              ? officeList.map(office => ({ ...office, policies: submodule.policies }))
              : [],
          })),
        })),
      })),
    [policyGroup, officeList],
  );

  const permissionTableData = useMemo(() => {
    return searchValue
      ? policyGroupWithOffices
          ?.map(level => {
            if (level.name.toLowerCase().includes(searchValue.toLowerCase())) {
              return level;
            } else {
              const newPages = level.modules
                .map(module => {
                  if (module.name.toLowerCase().includes(searchValue.toLowerCase())) {
                    return module;
                  } else {
                    const newPageModules = module.submodules
                      .map(submodule => {
                        if (submodule.name.toLowerCase().includes(searchValue.toLowerCase())) {
                          return submodule;
                        }
                      })
                      .filter(item => item);

                    if (newPageModules.length) {
                      return { ...module, submodules: newPageModules };
                    }
                  }
                })
                .filter((item: any) => item);

              if (newPages.length) {
                return { ...level, modules: newPages };
              }
            }
          })
          .filter(item => item)
      : policyGroupWithOffices;
  }, [values, searchValue, policyGroupWithOffices]);

  const tableColumns = useMemo(
    () => [
      {
        Header: intl.formatMessage(messages.pageModalColumn),
        accessor: 'name',
      },
      {
        id: 'viewColumn',
        Header: intl.formatMessage(messages.viewColumn),
        headClassName: 'content-center',
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: PolicyGroupLevel) => (
                <div className={'table__data-wrapper content-center'}>
                  {checkLevelPolicies(row, PolicyTypeEnum.VIEW) && (
                    <Checkbox
                      id={row.id}
                      onChange={event => handleLevelChange(event.target.checked, row, PolicyTypeEnum.VIEW)}
                      checkedValue={checkLevelPoliciesValue(row, PolicyTypeEnum.VIEW)}
                      externalClass={classNames(
                        { 'clear-all-checkbox': !checkAllLevelPoliciesValue(row, PolicyTypeEnum.VIEW) },
                        'checkbox-no-label',
                      )}
                      iconName={checkAllLevelPoliciesValue(row, PolicyTypeEnum.VIEW) ? '' : 'clear-all-checkbox'}
                      disabled={!updateAccess}
                    />
                  )}
                </div>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: PolicyGroupModule) => (
                <div className={'table__data-wrapper content-center'}>
                  {checkModulePolicies(row, PolicyTypeEnum.VIEW) && (
                    <Checkbox
                      id={row.id + PolicyTypeEnum.VIEW}
                      onChange={event => handleModuleChange(event.target.checked, row, PolicyTypeEnum.VIEW)}
                      checkedValue={checkModulePoliciesValue(row, PolicyTypeEnum.VIEW)}
                      externalClass={classNames(
                        { 'clear-all-checkbox': !checkAllModulePoliciesValue(row, PolicyTypeEnum.VIEW) },
                        'checkbox-no-label',
                      )}
                      iconName={checkAllModulePoliciesValue(row, PolicyTypeEnum.VIEW) ? '' : 'clear-all-checkbox'}
                      disabled={!updateAccess}
                    />
                  )}
                </div>
              ),
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: PolicyGroupSubmodule) => {
                const policy = row.policies.find(
                  (item: SubmodulePolicyType) => item.policyType === PolicyTypeEnum.VIEW,
                );
                return (
                  <div className={'table__data-wrapper content-center'}>
                    {policy && (
                      <Checkbox
                        id={policy.id}
                        onChange={event =>
                          handlePolicyChange(event.target.checked, policy.id, PolicyTypeEnum.VIEW, row)
                        }
                        checkedValue={values?.some((item: UserPolicy) => item.policy.id === policy.id)}
                        externalClass={classNames(
                          {
                            'clear-all-checkbox':
                              policy.isOfficeSpecificImplemented && !checkAllPolicyOfficies(policy.id),
                          },
                          'checkbox-no-label',
                        )}
                        iconName={
                          !policy.isOfficeSpecificImplemented
                            ? ''
                            : checkAllPolicyOfficies(policy.id)
                            ? ''
                            : 'clear-all-checkbox'
                        }
                        disabled={!updateAccess}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              depth: DepthLevels.FOURTH,
              content: (row: OfficeInfo & { policies: SubmodulePolicyType[] }) => {
                const policy = row.policies.find(
                  (item: SubmodulePolicyType) => item.policyType === PolicyTypeEnum.VIEW,
                );
                return (
                  <div className={'table__data-wrapper content-center'}>
                    {policy && (
                      <Checkbox
                        id={policy.id + row.id}
                        onChange={event =>
                          handleOfficePolicyChange(
                            event.target.checked,
                            PolicyTypeEnum.VIEW,
                            row.policies,
                            row.id,
                            policy.id,
                          )
                        }
                        checkedValue={checkPolicyOffice(policy.id, row.id)}
                        externalClass={'checkbox-no-label'}
                        disabled={!updateAccess}
                      />
                    )}
                  </div>
                );
              },
            },
          ]),
      },
      {
        id: 'updateColumn',
        Header: intl.formatMessage(messages.updateColumn),
        headClassName: 'content-center',
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: PolicyGroupLevel) => (
                <div className={'table__data-wrapper content-center'}>
                  {checkLevelPolicies(row, PolicyTypeEnum.UPDATE) && (
                    <Checkbox
                      id={row.id + PolicyTypeEnum.UPDATE}
                      onChange={event => handleLevelChange(event.target.checked, row, PolicyTypeEnum.UPDATE)}
                      checkedValue={checkLevelPoliciesValue(row, PolicyTypeEnum.UPDATE)}
                      externalClass={classNames(
                        { 'clear-all-checkbox': !checkAllLevelPoliciesValue(row, PolicyTypeEnum.UPDATE) },
                        'checkbox-no-label',
                      )}
                      iconName={checkAllLevelPoliciesValue(row, PolicyTypeEnum.UPDATE) ? '' : 'clear-all-checkbox'}
                      disabled={!updateAccess}
                    />
                  )}
                </div>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: PolicyGroupModule) => (
                <div className={'table__data-wrapper content-center'}>
                  {checkModulePolicies(row, PolicyTypeEnum.UPDATE) && (
                    <Checkbox
                      id={row.id + PolicyTypeEnum.UPDATE}
                      onChange={event => handleModuleChange(event.target.checked, row, PolicyTypeEnum.UPDATE)}
                      checkedValue={checkModulePoliciesValue(row, PolicyTypeEnum.UPDATE)}
                      externalClass={classNames(
                        { 'clear-all-checkbox': !checkAllModulePoliciesValue(row, PolicyTypeEnum.UPDATE) },
                        'checkbox-no-label',
                      )}
                      iconName={checkAllModulePoliciesValue(row, PolicyTypeEnum.UPDATE) ? '' : 'clear-all-checkbox'}
                      disabled={!updateAccess}
                    />
                  )}
                </div>
              ),
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: PolicyGroupSubmodule) => {
                const policy = row.policies.find(
                  (item: SubmodulePolicyType) => item.policyType === PolicyTypeEnum.UPDATE,
                );
                return (
                  <div className={'table__data-wrapper content-center'}>
                    {policy && (
                      <Checkbox
                        id={policy.id}
                        onChange={event =>
                          handlePolicyChange(event.target.checked, policy.id, PolicyTypeEnum.UPDATE, row)
                        }
                        checkedValue={values?.some((item: UserPolicy) => item.policy.id === policy.id)}
                        externalClass={classNames(
                          {
                            'clear-all-checkbox':
                              policy.isOfficeSpecificImplemented && !checkAllPolicyOfficies(policy.id),
                          },
                          'checkbox-no-label',
                        )}
                        iconName={
                          !policy.isOfficeSpecificImplemented
                            ? ''
                            : checkAllPolicyOfficies(policy.id)
                            ? ''
                            : 'clear-all-checkbox'
                        }
                        disabled={!updateAccess}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              depth: DepthLevels.FOURTH,
              content: (row: OfficeInfo & { policies: SubmodulePolicyType[] }) => {
                const policy = row.policies.find(
                  (item: SubmodulePolicyType) => item.policyType === PolicyTypeEnum.UPDATE,
                );
                return (
                  <div className={'table__data-wrapper content-center'}>
                    {policy && (
                      <Checkbox
                        id={policy.id + row.id}
                        onChange={event =>
                          handleOfficePolicyChange(
                            event.target.checked,
                            PolicyTypeEnum.UPDATE,
                            row.policies,
                            row.id,
                            policy.id,
                          )
                        }
                        checkedValue={checkPolicyOffice(policy.id, row.id)}
                        externalClass={'checkbox-no-label'}
                        disabled={!updateAccess}
                      />
                    )}
                  </div>
                );
              },
            },
          ]),
      },
      {
        id: 'deleteColumn',
        Header: intl.formatMessage(messages.deleteColumn),
        headClassName: 'content-center',
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: PolicyGroupLevel) => (
                <div className={'table__data-wrapper content-center'}>
                  {checkLevelPolicies(row, PolicyTypeEnum.DELETE) && (
                    <Checkbox
                      id={row.id + PolicyTypeEnum.DELETE}
                      onChange={event => handleLevelChange(event.target.checked, row, PolicyTypeEnum.DELETE)}
                      checkedValue={checkLevelPoliciesValue(row, PolicyTypeEnum.DELETE)}
                      externalClass={classNames(
                        { 'clear-all-checkbox': !checkAllLevelPoliciesValue(row, PolicyTypeEnum.DELETE) },
                        'checkbox-no-label',
                      )}
                      iconName={checkAllLevelPoliciesValue(row, PolicyTypeEnum.DELETE) ? '' : 'clear-all-checkbox'}
                      disabled={!updateAccess}
                    />
                  )}
                </div>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: PolicyGroupModule) => (
                <div className={'table__data-wrapper content-center'}>
                  {checkModulePolicies(row, PolicyTypeEnum.DELETE) && (
                    <Checkbox
                      id={row.id + PolicyTypeEnum.DELETE}
                      onChange={event => handleModuleChange(event.target.checked, row, PolicyTypeEnum.DELETE)}
                      checkedValue={checkModulePoliciesValue(row, PolicyTypeEnum.DELETE)}
                      externalClass={classNames(
                        { 'clear-all-checkbox': !checkAllModulePoliciesValue(row, PolicyTypeEnum.DELETE) },
                        'checkbox-no-label',
                      )}
                      iconName={checkAllModulePoliciesValue(row, PolicyTypeEnum.DELETE) ? '' : 'clear-all-checkbox'}
                      disabled={!updateAccess}
                    />
                  )}
                </div>
              ),
            },
            {
              depth: DepthLevels.THIRD,
              content: (row: PolicyGroupSubmodule) => {
                const policy = row.policies.find(
                  (item: SubmodulePolicyType) => item.policyType === PolicyTypeEnum.DELETE,
                );
                return (
                  <div className={'table__data-wrapper content-center'}>
                    {policy && (
                      <Checkbox
                        id={policy.id}
                        onChange={event =>
                          handlePolicyChange(event.target.checked, policy.id, PolicyTypeEnum.DELETE, row)
                        }
                        checkedValue={values?.some((item: UserPolicy) => item.policy.id === policy.id)}
                        externalClass={classNames(
                          {
                            'clear-all-checkbox':
                              policy.isOfficeSpecificImplemented && !checkAllPolicyOfficies(policy.id),
                          },
                          'checkbox-no-label',
                        )}
                        iconName={
                          !policy.isOfficeSpecificImplemented
                            ? ''
                            : checkAllPolicyOfficies(policy.id)
                            ? ''
                            : 'clear-all-checkbox'
                        }
                        disabled={!updateAccess}
                      />
                    )}
                  </div>
                );
              },
            },
            {
              depth: DepthLevels.FOURTH,
              content: (row: OfficeInfo & { policies: SubmodulePolicyType[] }) => {
                const policy = row.policies.find(
                  (item: SubmodulePolicyType) => item.policyType === PolicyTypeEnum.DELETE,
                );
                return (
                  <div className={'table__data-wrapper content-center'}>
                    {policy && (
                      <Checkbox
                        id={policy.id + row.id}
                        onChange={event =>
                          handleOfficePolicyChange(
                            event.target.checked,
                            PolicyTypeEnum.DELETE,
                            row.policies,
                            row.id,
                            policy.id,
                          )
                        }
                        checkedValue={checkPolicyOffice(policy.id, row.id)}
                        externalClass={'checkbox-no-label'}
                        disabled={!updateAccess}
                      />
                    )}
                  </div>
                );
              },
            },
          ]),
      },
    ],
    [values, policyGroup, updateAccess],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={intl.formatMessage(messages.editPermissionsTitle)}
      classNameModal="modal--edit-permissions"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <Search onChange={event => setSearchValue(event.target.value)} placeholder={'Search'} />
        <div className="form__inputs-wrapper">
          <HierarchicalTable
            loading={false}
            error={tableError}
            externalClass={'table--bordered-top permissions-table fixed'}
            tableData={useTableData(permissionTableData, ['modules', 'submodules', 'offices'])}
            tableColumns={tableColumns}
          />
        </div>
        <ErrorMessage>{updateError}</ErrorMessage>

        <div className="form__buttons">
          <Button type="button" onClick={onClose} color="gray" externalClass="button--modal">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          {updateAccess && (
            <Button type="submit" externalClass="button--modal" loading={loading} disabled={loading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          )}
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditPermissions;
