import React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import TableUserAvatar from '../../components/TableUserAvatar';
import ENV from '../../constants/env.constants';
import { DepthLevels, DepthLevelsType } from '../../constants/tables.constants';
import { UserInfo } from '../../enums/users.enum';
import { getForamtedTime } from '../../utils/reports.utils';
import { getTableCell } from '../../utils/table.utils';
import messages from './messages';

const IssueLink = ({ pkey, issueNum }: { pkey: string; issueNum: number }) => {
  const issueKey = `${pkey}-${issueNum}`;
  return (
    <a target="_blank" className="report__task-name" href={`${ENV.JIRA_BASE_URL}/browse/${issueKey}`} rel="noreferrer">
      {issueKey}
    </a>
  );
};

export const useDataForTable = (
  isWorkLogsRounded: boolean,
  isFoldSubtasks: boolean,
  isGroupByProjects: boolean,
  isGroupByComponents: boolean,
  isGroupByEmployee: boolean,
  usersPreviews: any,
) => {
  const intl = useIntl();
  const tableColumns = useMemo(() => {
    const isShowChildIssue = (depth: DepthLevelsType) => {
      if (isFoldSubtasks) {
        return false;
      }
      switch (depth) {
        case DepthLevels.SECOND:
          return !isGroupByComponents && !isGroupByEmployee;
        case DepthLevels.THIRD:
          return (isGroupByComponents && !isGroupByEmployee) || (isGroupByEmployee && !isGroupByComponents);
        default:
          return false;
      }
    };

    const handleDepth = (currentDepth: number, depth: DepthLevelsType) =>
      isShowChildIssue(depth) ? currentDepth >= depth : currentDepth === depth;

    return [
      {
        Header: intl.formatMessage(messages.projectsKeyColumn),
        id: 'projectsKeyColumn',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: any) => <span>{row.name}</span>,
            },
            {
              depth: (depth: number) => handleDepth(depth, DepthLevels.SECOND),
              content: (row: any) => {
                if (isGroupByComponents) {
                  return <span>{row.cname}</span>;
                }

                if (isGroupByEmployee) {
                  return (
                    <span>
                      {usersPreviews[row.id] ? (
                        <TableUserAvatar users={[new UserInfo(usersPreviews[row.id])]} fileSize={36} />
                      ) : (
                        row.displayName
                      )}
                      {!row.isFromPortal && row.id && (
                        <span className="users-jira-report-status">
                          {` (${intl.formatMessage(messages.externalLabel)})`}
                        </span>
                      )}
                      {row.isFromPortal && row.id && !row.isPortalActive && (
                        <span className="users-jira-report-status">
                          {` (${intl.formatMessage(messages.inactiveLabel)})`}
                        </span>
                      )}
                    </span>
                  );
                }

                return <IssueLink pkey={row.pkey} issueNum={row.issueNum} />;
              },
            },
            ...(!isShowChildIssue(DepthLevels.SECOND)
              ? [
                  {
                    depth: (depth: number) => handleDepth(depth, DepthLevels.THIRD),
                    content: (row: any) => {
                      if (isGroupByComponents && isGroupByEmployee) {
                        return (
                          <span>
                            {usersPreviews[row.id] ? (
                              <TableUserAvatar users={[new UserInfo(usersPreviews[row.id])]} fileSize={36} />
                            ) : (
                              row.displayName
                            )}
                            {!row.isFromPortal && row.id && (
                              <span className="users-jira-report-status">
                                {` (${intl.formatMessage(messages.externalLabel)})`}
                              </span>
                            )}
                            {row.isFromPortal && row.id && !row.isPortalActive && (
                              <span className="users-jira-report-status">
                                {` (${intl.formatMessage(messages.inactiveLabel)})`}
                              </span>
                            )}
                          </span>
                        );
                      }

                      return <IssueLink pkey={row.pkey} issueNum={row.issueNum} />;
                    },
                  },
                ]
              : []),
            ...(!isShowChildIssue(DepthLevels.THIRD)
              ? [
                  {
                    depth: (depth: number) => depth >= DepthLevels.FOURTH,
                    content: (row: any) => <IssueLink pkey={row.pkey} issueNum={row.issueNum} />,
                  },
                ]
              : []),
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.taskLabel),
        accessor: 'summary',
      },
      {
        Header: intl.formatMessage(messages.typeLabel),
        accessor: 'issueType.pname',
      },
      {
        Header: intl.formatMessage(messages.estimateLabel),
        id: 'estimateLabel',
        Cell: ({ row }: any) => {
          let currentDepth: DepthLevelsType = DepthLevels.SECOND;

          if (isGroupByComponents && isGroupByEmployee) {
            currentDepth = DepthLevels.FOURTH;
          }

          if ((isGroupByComponents && !isGroupByEmployee) || (!isGroupByComponents && isGroupByEmployee)) {
            currentDepth = DepthLevels.THIRD;
          }

          return getTableCell(row, [
            {
              depth: (depth: number) => handleDepth(depth, currentDepth),
              content: (row: any) => {
                return (
                  <span>
                    {row.timeOriginalEstimate !== null ? `${getForamtedTime(row.timeOriginalEstimate, false)}h` : '-'}
                  </span>
                );
              },
            },
          ]);
        },
      },
      {
        Header: intl.formatMessage(messages.spentLabel),
        id: 'spentLabel',
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: (depth: number) => depth >= DepthLevels.FIRST,
              content: (row: any, index, rowDepth) => {
                if (isGroupByComponents && rowDepth === DepthLevels.SECOND) {
                  const timeSpent = row.timeWorked;
                  return <span>{timeSpent ? `${getForamtedTime(timeSpent, isWorkLogsRounded)}h` : '0h'}</span>;
                }
                const timeSpent =
                  !isFoldSubtasks && row.hasOwnProperty('thisTimeSpent') ? row.thisTimeSpent : row.timeSpent;

                return <span>{timeSpent ? `${getForamtedTime(timeSpent, isWorkLogsRounded)}h` : '0h'}</span>;
              },
            },
          ]);
        },
      },
    ];
  }, [isFoldSubtasks, isWorkLogsRounded, isGroupByProjects, isGroupByComponents, isGroupByEmployee, usersPreviews]);

  return {
    tableColumns,
  };
};
