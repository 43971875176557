import { defineMessages } from 'react-intl';
import {
  nameColumn,
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
} from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  newCompetenciesTypeTitle: {
    id: 'competencies.type.positions.modal.new.competencies.type.title.new.competencies.type',
    defaultMessage: 'New Competencies Type',
  },
  editCompetenciesTypeTitle: {
    id: 'competencies.type.positions.modal.edit.competencies.type.title.edit.competencies.type',
    defaultMessage: 'Edit Competencies Type',
  },
});
