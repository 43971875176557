import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import TableUserAvatar from '../../components/TableUserAvatar';
import messages from './messages';
import { AbsencePeriodsDateType, AbsencePeriodsListItemType } from '../../enums/reports.enum';
import { DepthLevels } from '../../constants/tables.constants';
import { getTableCell } from '../../utils/table.utils';

export const useDataForTable = () => {
  const intl = useIntl();

  const tableColumns = useMemo(
    () => [
      {
        id: 'name',
        Header: intl.formatMessage(messages.membersLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: AbsencePeriodsListItemType) =>
                row?.totalItem ? (
                  <FormattedMessage {...messages.totalRow} />
                ) : (
                  <TableUserAvatar users={[row.user]} fileSize={36} />
                ),
            },
          ]),
      },
      {
        id: 'eventTypeName',
        Header: intl.formatMessage(messages.eventTypeLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: AbsencePeriodsDateType) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.eventTypeName}</span>
                </div>
              ),
            },
          ]),
      },
      {
        id: 'startDate',
        Header: intl.formatMessage(messages.startColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: AbsencePeriodsDateType) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.startDate}</span>
                </div>
              ),
            },
          ]),
      },
      {
        id: 'endDate',
        Header: intl.formatMessage(messages.endColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: AbsencePeriodsDateType) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.endDate}</span>
                </div>
              ),
            },
          ]),
      },
      {
        id: 'allDays',
        Header: intl.formatMessage(messages.allDaysColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: AbsencePeriodsListItemType) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.allDays}</span>
                </div>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: AbsencePeriodsDateType) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.allDays}</span>
                </div>
              ),
            },
          ]),
      },
      {
        id: 'calendarDays',
        Header: intl.formatMessage(messages.calendarColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: AbsencePeriodsListItemType) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.calendarDays}</span>
                </div>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: AbsencePeriodsDateType) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.calendarDays}</span>
                </div>
              ),
            },
          ]),
      },
      {
        id: 'workDays',
        Header: intl.formatMessage(messages.workColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: AbsencePeriodsListItemType) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.workDays}</span>
                </div>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: AbsencePeriodsDateType) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.workDays}</span>
                </div>
              ),
            },
          ]),
      },
    ],
    [],
  );

  return {
    tableColumns,
  };
};
