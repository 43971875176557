import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import Select from '../../Select';
import { getCurrentRateDate } from '../../../utils/finance.utils';
import { CurrencyType, DatesOfExistingPlansParams } from '../../../types/finance';
import CurrencyInput from '../../CurrencyInput';
import { CLONE_PLAN_SCHEMA, CurrencyFormatter, FinancePlan, ChekedInfo } from '../../../enums/finance/finance.enum';
import { getBaseCurrency, getFormattedDate, scrollToError } from '../../../utils';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { isEmpty } from 'lodash-es';
import { useHistory } from 'react-router';
import r from '../../../constants/routes.constants';
import ConfirmModal from './ConfirmModal';
import MonthSelect from '../../MonthSelect';

type ModalClonePlanProps = {
  onCloseRequest: () => void;
  clonePlan: (data: {
    data: FinancePlan;
    callback: (id: string) => void;
    closeConfirmModal: () => void;
    isConfirmModal: boolean;
  }) => void;
  getCurrenciesList: () => void;
  resetClonePlanCheckedInfo: () => void;
  getDatesOfExistingPlans: (data: DatesOfExistingPlansParams) => void;
  resetDatesOfExistingPlans: () => void;
  loadingDates: boolean;
  datesOfExistingPlans: string[];
  clonePlanCheckedInfo: ChekedInfo[];
  financePlan: FinancePlan;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  currencies: CurrencyType[];
};

function ModalClonePlan({
  onCloseRequest,
  clonePlan,
  getCurrenciesList,
  resetClonePlanCheckedInfo,
  getDatesOfExistingPlans,
  resetDatesOfExistingPlans,
  datesOfExistingPlans,
  loadingDates,
  error,
  isLoading,
  isOpen,
  financePlan,
  currencies,
  clonePlanCheckedInfo,
}: ModalClonePlanProps) {
  const intl = useIntl();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const history = useHistory();

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    resetClonePlanCheckedInfo();
  };

  useEffect(() => {
    getCurrenciesList();
  }, []);

  const { values, errors, touched, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      ...financePlan,
      year: Number(financePlan.month) === 11 ? String(Number(financePlan.year) + 1) : financePlan.year,
      month: Number(financePlan.month) === 11 ? '0' : String(Number(financePlan.month) + 1),
    },
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: CLONE_PLAN_SCHEMA,
    onSubmit: data => {
      return clonePlan({
        data,
        callback: (id: string) => {
          handleCloseModal();
          goToClonePage(id);
        },
        isConfirmModal: false,
        closeConfirmModal,
      });
    },
  });

  const goToClonePage = (id: string) => {
    history.push(r.projectPlan.replace(':id', id));
  };

  const handleCloseModal = () => {
    resetDatesOfExistingPlans();
    onCloseRequest();
  };

  useEffect(() => {
    setIsConfirmModalOpen(!isEmpty(clonePlanCheckedInfo));
  }, [clonePlanCheckedInfo]);

  useEffect(() => {
    const year = values.year;
    if (values.financeProjectId && values.clientId && year) {
      const startDate = moment(year).startOf('year').format(DATE_FORMAT.YYYY_MM_DD);
      const endDate = moment(year).endOf('year').format(DATE_FORMAT.YYYY_MM_DD);
      getDatesOfExistingPlans({
        projectId: values.financeProjectId,
        clientId: values.clientId,
        startDate,
        endDate,
      });
    } else {
      resetDatesOfExistingPlans();
    }
  }, [values.financeProjectId, values.year]);

  useEffect(() => {
    if (
      values.financeProjectId &&
      datesOfExistingPlans.some(date => moment(date).get('month') === Number(values.month))
    ) {
      setFieldValue('month', null);
    }
  }, [datesOfExistingPlans]);

  const handleConfirmModal = () => {
    clonePlan({
      data: values,
      isConfirmModal: true,
      callback: (id: string) => {
        onCloseRequest();
        closeConfirmModal();
        goToClonePage(id);
      },
      closeConfirmModal,
    });
  };

  const clientValue = useMemo(() => {
    if (values.client) {
      return { label: values.client.name, value: values.client };
    }
  }, [values]);

  const projectValue = useMemo(() => {
    if (values.financeProject) {
      return { label: values.financeProject.name, value: values.financeProject };
    }
  }, [values]);

  const currenciesOptions = useMemo(
    () =>
      currencies.map((currency: CurrencyType) => ({
        label: currency.name,
        value: currency,
      })),
    [currencies],
  );

  const currencyValue = useMemo(
    () =>
      values.currency &&
      currenciesOptions?.find(({ value }: { value: { id: string } }) => value.id === values.currencyId),
    [currenciesOptions, values],
  );

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleYearChange = useCallback(({ value }) => {
    setFieldValue('year', value);
  }, []);

  const handleCurrenciesChange = useCallback(item => {
    setFieldValue('currencyId', item.value.id);
    setFieldValue('currency', item.value);
  }, []);

  const handleCurrencyRateChange = useCallback(
    item => {
      const currencyRate = new CurrencyFormatter({
        ...item,
        float: item.float || 0,
      });
      setFieldValue('currencyRate', currencyRate);
      const baseRate = (Number(values.currencyWorkHourRate.float) * Number(item.float)).toFixed(2);
      setFieldValue(
        'baseRate',
        new CurrencyFormatter({
          float: Number(baseRate),
          value: baseRate,
          formatted: baseRate,
        }),
      );
    },
    [values.currencyWorkHourRate],
  );

  const handleBaseRateChange = useCallback(item => {
    const baseRate = new CurrencyFormatter({
      ...item,
      float: item.float || 0,
    });
    setFieldValue('baseRate', baseRate);
  }, []);

  const handlePlannedIncomeChange = useCallback(item => {
    const plannedIncome = new CurrencyFormatter({
      ...item,
      float: item.float || 0,
    });
    setFieldValue('plannedIncome', plannedIncome);
  }, []);

  const handleCurrencyWorkHourRateChange = useCallback(
    item => {
      const workHourRate = new CurrencyFormatter({
        ...item,
        float: item.float || 0,
      });
      setFieldValue('currencyWorkHourRate', workHourRate);
      const baseRate = (Number(item.float) * Number(values.currencyRate.float)).toFixed(2);
      setFieldValue(
        'baseRate',
        new CurrencyFormatter({
          float: Number(baseRate),
          value: baseRate,
          formatted: baseRate,
        }),
      );
    },
    [values.currencyRate],
  );

  const handleMonthChange = useCallback(
    ({ value }) => {
      setFieldValue('month', value);
      const date = getFormattedDate(values.year, value);
      if (values.financeProject && date) {
        const curentProjectRate = getCurrentRateDate(values.financeProject?.rates, date);

        setFieldValue('currencyId', curentProjectRate ? curentProjectRate.currencyId : baseCurrency?.id);
        setFieldValue('currency', curentProjectRate ? curentProjectRate.currency : baseCurrency);

        const workHourRate = new CurrencyFormatter({
          float: Number(curentProjectRate?.rate) || 0,
          value: curentProjectRate?.rate.toString() || '0.00',
          formatted: curentProjectRate?.rate.toString() || '0.00',
        });

        setFieldValue('currencyWorkHourRate', workHourRate);

        const baseRate = (Number(workHourRate.float) * Number(values.currencyRate.float)).toFixed(2);
        setFieldValue(
          'baseRate',
          new CurrencyFormatter({
            float: Number(baseRate),
            value: baseRate,
            formatted: baseRate,
          }),
        );
      }
    },
    [values.financeProject, values.currencyRate, values.year],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        title={intl.formatMessage(messages.clonePlanTitle)}
        classNameModal="new-plan-modal"
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Select
                label={intl.formatMessage(messages.clientLabel)}
                value={clientValue}
                isDisabled
                hasError={!financePlan.client.isActive}
                errorMessage={!financePlan.client.isActive ? intl.formatMessage(messages.clientIsInactive) : undefined}
              />
              <Select
                label={intl.formatMessage(messages.projectLabel)}
                value={projectValue}
                isDisabled
                hasError={!financePlan.financeProject.isActive}
                errorMessage={
                  !financePlan.financeProject.isActive ? intl.formatMessage(messages.projectIsInactive) : undefined
                }
              />
              <MonthSelect
                selectExternalClass="form__input-block--third"
                year={values.year}
                month={values.month}
                disabledMonths={datesOfExistingPlans}
                isLoadingMonths={loadingDates}
                errorMessageMonth={errors.month}
                errorMessageYear={errors.year}
                handleYearChange={handleYearChange}
                handleMonthChange={handleMonthChange}
              />
              <div className="form__inputs-subwrapper">
                <Select
                  options={currenciesOptions}
                  externalClass="select__no-label currency-select"
                  value={currencyValue}
                  handleChange={handleCurrenciesChange}
                  errorMessage={errors?.currencyId}
                  hasError={hasError('currencyId')}
                />
                <CurrencyInput
                  name="currencyWorkHourRate"
                  label={intl.formatMessage(messages.originalHourPriceLabel)}
                  value={values.currencyWorkHourRate.value}
                  onChange={handleCurrencyWorkHourRateChange}
                  //@ts-ignore
                  errorMessage={errors?.currencyWorkHourRate?.float || errors?.currencyWorkHourRate}
                  hasError={hasError('currencyWorkHourRate')}
                  wrapperClass={'rate-input'}
                />
                <CurrencyInput
                  name="currencyRate"
                  label={intl.formatMessage(messages.rateLabel)}
                  decimalsLimit={4}
                  value={values.currencyRate.value}
                  onChange={handleCurrencyRateChange}
                  //@ts-ignore
                  errorMessage={errors?.currencyRate?.float || errors?.currencyRate}
                  hasError={hasError('currencyRate')}
                  wrapperClass={'currency-rate-input'}
                />
                <CurrencyInput
                  name="baseRate"
                  label={intl.formatMessage(messages.unifiedHourPriceLabel)}
                  suffix={baseCurrency?.id ? ` ${baseCurrency.name}` : undefined}
                  value={values.baseRate.value}
                  onChange={handleBaseRateChange}
                  //@ts-ignore
                  errorMessage={errors?.baseRate?.float || errors?.baseRate}
                  hasError={hasError('baseRate')}
                  wrapperClass={'rate-input'}
                />
              </div>
              <div className="form__inputs-subwrapper">
                <CurrencyInput
                  name="plannedIncome"
                  label={intl.formatMessage(messages.plannedIncomeLabel)}
                  suffix={values.currency?.id ? ` ${values.currency.name}` : undefined}
                  value={values.plannedIncome.value}
                  onChange={handlePlannedIncomeChange}
                  //@ts-ignore
                  errorMessage={errors?.plannedIncome?.float || errors?.plannedIncome}
                  hasError={hasError('plannedIncome')}
                  externalClass="month-input"
                />
              </div>
            </div>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal button--cancel'}
              type={'button'}
              onClick={handleCloseModal}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button
              externalClass={'button--modal'}
              type={'submit'}
              loading={isLoading}
              disabled={isLoading || !financePlan.financeProject.isActive || !financePlan.client.isActive}
            >
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
        {isConfirmModalOpen && (
          <ConfirmModal
            isOpen
            checkedInfo={clonePlanCheckedInfo}
            isLoading={isLoading}
            closeConfirmModal={closeConfirmModal}
            handleConfirmModal={handleConfirmModal}
          />
        )}
      </Modal>
    </>
  );
}

export default ModalClonePlan;
