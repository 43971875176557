import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { EventsParams } from '../../../../../enums/params/schedule.params';
import { OfficesWorkDaysType } from '../../../../../types/libraries/libraries.Reducer.type';
import { DaysOffInfoType } from '../../../../../types/schedule/scheduleReducer.type';
import { getMonths, handleEventsPopUpChangePosition } from '../../../../../utils/schedule.utils';
import Month from './Month';

type CalendarYearViewProps = {
  popoverRef: any;
  popoverCurrentDate: string;
  dateFrom: string;
  officesWorkDays: OfficesWorkDaysType;
  daysOffInfo: DaysOffInfoType;
  setPopoverStyles: (styles: React.CSSProperties) => void;
  setPopoverCurrentDate: (day: string) => void;
  getEventList: (data: { params: Partial<EventsParams>; cb?: () => void }) => void;
  handleMoveToDayView: (date: string) => void;
};

function CalendarYearView({
  popoverRef,
  popoverCurrentDate,
  dateFrom,
  officesWorkDays,
  daysOffInfo,
  setPopoverStyles,
  setPopoverCurrentDate,
  getEventList,
  handleMoveToDayView,
}: CalendarYearViewProps) {
  const handleDayClick = useCallback(
    (e, day: string) => {
      if (popoverCurrentDate === day) {
        handleMoveToDayView(day);
        setPopoverCurrentDate('');
        return null;
      } else {
        getEventList({
          params: {
            dateTimeFrom: moment(day + ' 00:00:00').format(),
            dateTimeTo: moment(day + ' 23:59:59').format(),
            size: 10000,
          },
          cb: () => {
            handleEventsPopUpChangePosition(e, setPopoverStyles, popoverRef);
          },
        });
        setPopoverCurrentDate(day);
        setTimeout(() => handleEventsPopUpChangePosition(e, setPopoverStyles, popoverRef), 0);
      }
    },
    [popoverCurrentDate],
  );

  const monthsInfo = useMemo(() => getMonths(dateFrom), [dateFrom]);

  return (
    <>
      <div className="calendar-year year-view">
        <div className="calendar-year__wrapper">
          {monthsInfo.map((el, i) => (
            <Month
              key={i}
              monthDays={el.monthDays}
              monthName={el.monthName}
              officesWorkDays={officesWorkDays}
              daysOffInfo={daysOffInfo}
              handleDayClick={handleDayClick}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default React.memo(CalendarYearView);
