import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { COMPANY_POSITIONS_VALIDATION_SCHEMA } from '../../../enums/libraries.enum';
import get from 'lodash-es/get';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import Select from '../../Select';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { CompanyPositionInfoType } from '../../../types/libraries';
import { scrollToError } from '../../../utils';

type ModalEditCompanyPositionProps = {
  onCloseRequest: () => void;
  editCompanyPosition: (id: string, data: any) => void;
  companyPositionData: CompanyPositionInfoType;
  companyPositionError: string | RejectValueErrors[] | null;
  isLoading: boolean;
  resetErrors: () => void;
  gradesList: { id: string; name: string }[];
  isOpen: boolean;
};

function ModalEditCompanyPosition({
  onCloseRequest,
  editCompanyPosition,
  companyPositionData,
  companyPositionError,
  isLoading,
  resetErrors,
  gradesList,
  isOpen,
}: ModalEditCompanyPositionProps) {
  const intl = useIntl();

  const resetAndExit = useCallback(() => {
    resetForm();
    onCloseRequest();
    resetErrors();
  }, []);

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldValue, setFieldError } = useFormik({
    initialValues: companyPositionData,
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: COMPANY_POSITIONS_VALIDATION_SCHEMA,
    onSubmit: data => {
      return editCompanyPosition(companyPositionData?.id, { data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(companyPositionError, setFieldError);

  const gradesOptions = useMemo(() => gradesList?.map(({ id, name }) => ({ label: name, value: id })), [gradesList]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleChangeGrades = useCallback(item => {
    setFieldValue('gradeId', item.value);
    setFieldValue('gradeName', item.value === null ? null : item.label);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editPositionTitle)}
      size={'small'}
      classNameModal="center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Input
              id={'edit-displayName'}
              name={'displayName'}
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values?.displayName || ''}
              onChange={handleChange}
              hasError={hasError('displayName')}
              errorMessage={errors?.displayName}
            />
          </div>

          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.gredeColumn)}
              name={`grade`}
              options={gradesOptions}
              handleChange={handleChangeGrades}
              isSearchable
              value={
                values.gradeId
                  ? {
                      label: values.gradeName,
                      value: values.gradeId,
                    }
                  : null
              }
              isClearable
            />
          </div>
        </div>
        <ErrorMessage>{companyPositionError}</ErrorMessage>
        <div className="form__buttons">
          <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={resetAndExit} type={'button'}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditCompanyPosition;
