import React from 'react';
import classNames from 'classnames';

type RadioButtonGroupProps = {
  onChange: React.ChangeEventHandler<EventTarget>;
  name: string;
  disabled?: boolean;
  externalClass?: string;
  checked: string;
  groupItems: { id: string; label: React.ReactNode }[];
};

function RadioButtonGroup({ onChange, groupItems, name, disabled, externalClass, checked }: RadioButtonGroupProps) {
  const defaultClass = classNames('form__radios-wrapper', 'page__radios-wrapper', {
    [externalClass as string]: Boolean(externalClass),
  });

  return (
    <div className={defaultClass}>
      {groupItems.map(item => (
        <div className="form__radio-block" key={item.id}>
          <input
            onChange={onChange}
            className="form__radio"
            type="radio"
            id={item.id}
            name={name}
            disabled={disabled}
            checked={checked === item.id}
          />
          <label className="form__radio-label" htmlFor={item.id}>
            <span className="form__radio-label-text">{item.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default RadioButtonGroup;
