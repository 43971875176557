import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import InlineDatePicker from '../../components/InlineDatePicker';
import DurationPicker from '../../components/Dropdown';
import Icon from '../../components/Icon';
import messages from './messages';
import * as filtersActions from '../../actions/filters.actions';
import * as reportsActions from '../../actions/reports.actions';
import { EmployeesIssueTypesParams } from '../../enums/params/reports.params';
import HierarchicalTable from '../../components/HierarchicalTable';
import EmployeesIssueTypesFilter from '../../components/EmployeesIssueTypes/EmployeesIssueTypesFilter';
import { resetParamsChange, useParamsChange, useTableData, useUsersParamsChange } from '../../utils/hooks.utils';
import { useDataForTable } from './useDataForTable';
import { handleReportUsersStatuses } from '../../utils/reports.utils';
import { ReportsUserStatusesType } from '../../types/reports';
import { exportEmployeesIssueTypesReport } from '../../actions/export.actions';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { employeesIssueTypesUnsavedParams } from '../../constants/reports.constants';

function EmployeesIssueTypes({
  tableData,
  params,
  departmentsFilter,
  officesFilter,
  usersFilter,
  isLoading,
  errors,
  reportsUsersStatuses,
  setEmployeesIssueTypesParams,
  getOfficesFilter,
  getDepartmentsFilter,
  getUsersFilter,
  exportEmployeesIssueTypesReport,
  setReportsUsersStatuses,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleMultiParamsChange = useParamsChange(setEmployeesIssueTypesParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setEmployeesIssueTypesParams, dispatch);

  const handleParamsChange = useCallback(
    name => (data: any) => {
      setEmployeesIssueTypesParams({ [name]: data });
    },
    [],
  );

  const handleDataChange = useCallback((start: string, end: string) => {
    setEmployeesIssueTypesParams({ dateFrom: start, dateTo: end });
  }, []);

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.MEMBERS_ISSUE_TYPES_REPORT_FILTER);

    setEmployeesIssueTypesParams(
      currentSavedFilter
        ? new EmployeesIssueTypesParams(currentSavedFilter)
        : {
            offices: officesFilter.value,
            departmentIds: departmentsFilter.value,
            portalUserIds: usersFilter.value,
            isAllowActive: reportsUsersStatuses.isAllowActive,
            isAllowPassive: reportsUsersStatuses.isAllowPassive,
          },
    );
    getOfficesFilter();
    getDepartmentsFilter();
    getUsersFilter();
    setSavedFiltersParams({ filterType: FilterTypes.MEMBERS_ISSUE_TYPES_REPORT_FILTER });
  }, []);

  const tableDataWithTotal = useMemo(() => {
    if (tableData?.users.length && !tableData?.users[tableData.users.length - 1].totalItem) {
      const newData = tableData?.users.sort((a: any, b: any) => a.displayName?.localeCompare(b.displayName));
      newData?.push({
        worked: tableData.worked,
        countOfIssues: tableData.countOfIssues,
        totalItem: true,
      });

      return newData;
    }
    return tableData?.users;
  }, [tableData]);

  const { tableColumns } = useDataForTable(params.isGroupByProject);

  const filters = useMemo(
    () => ({
      departments: departmentsFilter.departments,
      offices: officesFilter.offices,
      users: usersFilter.users,
    }),
    [departmentsFilter.departments, officesFilter.offices, usersFilter.users],
  );

  const handleUsersStatuses = useCallback(
    data =>
      handleReportUsersStatuses((reportsUsersStatuses: ReportsUserStatusesType) => {
        setEmployeesIssueTypesParams(reportsUsersStatuses);
        setReportsUsersStatuses(reportsUsersStatuses);
      }, data),
    [],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      setEmployeesIssueTypesParams(
        new EmployeesIssueTypesParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setEmployeesIssueTypesParams(new EmployeesIssueTypesParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));

    resetParamsChange(
      [FilterParamsName.PORTAL_USER_IDS, FilterParamsName.DEPARTMENT_IDS, FilterParamsName.OFFICES],
      dispatch,
    );
  }, [params]);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new EmployeesIssueTypesParams(), employeesIssueTypesUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                />
                <DurationPicker
                  dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                  dropdownList={[
                    {
                      label: intl.formatMessage(messages.exportToXLSLabel),
                      handler: exportEmployeesIssueTypesReport,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <EmployeesIssueTypesFilter
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleParamsChange={handleParamsChange}
                  handleUsersStatuses={handleUsersStatuses}
                  handleUsersParamsChange={handleUsersParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <HierarchicalTable
            tableColumns={tableColumns}
            tableData={useTableData(
              tableDataWithTotal,
              params.isGroupByProject ? ['projects', 'issueTypes'] : ['allIssueTypes'],
            )}
            loading={isLoading}
            error={errors}
            externalClass={'employees-issue-types fixed'}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ filters, reports, auth }: RootState) => ({
  tableData: reports.employeesIssueTypesData,
  params: reports.employeesIssueTypesParams,
  departmentsFilter: filters.departmentsFilter,
  officesFilter: filters.officesFilter,
  usersFilter: filters.usersFilter,
  isLoading: reports.loading.employeesIssueTypes,
  errors: reports.errors.employeesIssueTypesErrors,
  reportsUsersStatuses: reports.reportsUserStatuses,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  setEmployeesIssueTypesParams: (data: Partial<EmployeesIssueTypesParams>) =>
    dispatch(reportsActions.setEmployeesIssueTypesParams(data)),
  exportEmployeesIssueTypesReport: () => dispatch(exportEmployeesIssueTypesReport()),
  setReportsUsersStatuses: (data: ReportsUserStatusesType) => dispatch(reportsActions.setReportsUsersStatuses(data)),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EmployeesIssueTypes);
