import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from '../../Modal';
import messages from '../messages';
import Button from '../../Button';
import TableUserAvatar from '../../TableUserAvatar';
import { ChekedInfo } from '../../../enums/finance/finance.enum';

type ConfirmModalProps = {
  isOpen: boolean;
  checkedInfo: ChekedInfo[];
  isLoading: boolean;
  closeConfirmModal: () => void;
  handleConfirmModal: () => void;
};

function ConfirmModal({ isOpen, checkedInfo, isLoading, closeConfirmModal, handleConfirmModal }: ConfirmModalProps) {
  const intl = useIntl();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeConfirmModal}
      title={intl.formatMessage(messages.confirmModalTitle)}
      classNameModal="clone-plan__confirm-modal"
    >
      <div className="form__inputs-wrapper">
        <div className="modal__subtitle">
          <FormattedMessage {...messages.confirmModalSubTitle} />
        </div>
        <table>
          {checkedInfo.map((item, index) => {
            return (
              <tr key={index} className="clone-info__list">
                <td className="user-name">
                  <TableUserAvatar users={[item.user]} fileSize={48} />
                </td>
                <td className="original-value">{item.originalValue || 0}%</td>
                <td className="new-value">{item.newValue}%</td>
              </tr>
            );
          })}
        </table>
      </div>

      <div className={'form__buttons'}>
        <Button color={'gray'} externalClass={'button--modal button--cancel'} onClick={closeConfirmModal}>
          <FormattedMessage {...messages.cancelButton} />
        </Button>
        <Button externalClass={'button--modal'} onClick={handleConfirmModal} loading={isLoading} disabled={isLoading}>
          <FormattedMessage {...messages.confirmButton} />
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
