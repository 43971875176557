import { defineMessages } from 'react-intl';
import {
  newButton,
  editButton,
  deleteButton,
  typeLabel,
  targetMemberLabel,
  eventTypesLabel,
} from '../../i18n/defaultMessage';
export default defineMessages({
  newButton,
  targetMemberLabel,
  typeLabel,
  editButton,
  deleteButton,
  eventTypesLabel,
  pageTitle: {
    id: 'schedule.title.schedule',
    defaultMessage: 'Schedule',
  },
  titleLabel: {
    id: 'schedule.layout.label.title',
    defaultMessage: 'Title',
  },
  datesLabel: {
    id: 'schedule.layout.label.dates',
    defaultMessage: 'Dates',
  },
  participantsLabel: {
    id: 'schedule.layout.label.participants',
    defaultMessage: 'Participants',
  },
  optionsLabel: {
    id: 'schedule.layout.label.options',
    defaultMessage: 'Options',
  },
  remindLabel: {
    id: 'schedule.layout.label.remind',
    defaultMessage: 'Remind',
  },
  weeklyLabel: {
    id: 'schedule.layout.label.weekly',
    defaultMessage: 'Weekly',
  },
  beforeLabel: {
    id: 'schedule.layout.label.before',
    defaultMessage: 'before',
  },
  viewDetailLabel: {
    id: 'schedule.layout.label.view.detail',
    defaultMessage: 'View Detail',
  },
  approvedLabel: {
    id: 'schedule.event.information.label.approved',
    defaultMessage: 'Approved',
  },
  finalDecisionLabel: {
    id: 'schedule.event.information.label.final.desicion',
    defaultMessage: 'Final desicion',
  },
  commentsTitle: {
    id: 'schedule.event.information.title.comments',
    defaultMessage: 'Comments',
  },
  secretCommentsTitle: {
    id: 'schedule.event.information.title.secret.comments',
    defaultMessage: 'Secret Comments',
  },
});
