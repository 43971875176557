import { defineMessages } from 'react-intl';
import { nameColumn, editButton, deleteButton, newButton } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  pageTitle: {
    id: 'candidate.receiving.sources.title.receiving.sources',
    defaultMessage: 'Receiving Sources',
  },
});
