import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import { CHANGE_PASSWORD_VALIDATION_SCHEMA } from '../../../enums/users.enum';
import Modal from '../../Modal';
import Input from '../../Input';
import Button from '../../Button';
import Icon from '../../Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import { scrollToError } from '../../../utils';

type ModalChangePasswordProps = {
  onClose: () => void;
  onSubmit: (data: any) => void;
  loading?: boolean;
  requestError: string | RejectValueErrors[] | null;
  isOpen: boolean;
};

function ModalChangePassword({ onClose, onSubmit, loading, requestError, isOpen }: ModalChangePasswordProps) {
  const { handleChange, handleSubmit, setFieldError, errors, touched } = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      sendDetailsViaEmail: false,
      isRandom: false,
    },
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: CHANGE_PASSWORD_VALIDATION_SCHEMA,
    onSubmit: data =>
      onSubmit({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        sendDetailsViaEmail: data.sendDetailsViaEmail,
        isRandom: data.isRandom,
      }),
  });
  useSetFieldsErrors(requestError, setFieldError);
  const intl = useIntl();

  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={intl.formatMessage(messages.changePasswordTitle)}
      size="small"
      classNameModal="change_password_modal center"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <Input
            type={currentPasswordVisible ? 'text' : 'password'}
            name="currentPassword"
            label={intl.formatMessage(messages.currentPasswordInput)}
            onChange={handleChange}
            hasError={hasError('currentPassword')}
            errorMessage={errors?.currentPassword}
            control={
              <button onClick={() => setCurrentPasswordVisible(!currentPasswordVisible)} type="button">
                <Icon iconName={currentPasswordVisible ? 'eye-slash' : 'eye'} externalClass={'password-icon'} />
              </button>
            }
          />
          <Input
            type={newPasswordVisible ? 'text' : 'password'}
            name="newPassword"
            label={intl.formatMessage(messages.newPasswordInput)}
            onChange={handleChange}
            hasError={hasError('newPassword')}
            errorMessage={errors?.newPassword}
            control={
              <button onClick={() => setNewPasswordVisible(!newPasswordVisible)} type="button">
                <Icon iconName={newPasswordVisible ? 'eye-slash' : 'eye'} externalClass={'password-icon'} />
              </button>
            }
          />
          <Input
            type={confirmPasswordVisible ? 'text' : 'password'}
            name="confirmPassword"
            label={intl.formatMessage(messages.confirmPasswordInput)}
            onChange={handleChange}
            hasError={hasError('confirmPassword')}
            errorMessage={errors?.confirmPassword}
            control={
              <button onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} type="button">
                <Icon iconName={confirmPasswordVisible ? 'eye-slash' : 'eye'} externalClass={'password-icon'} />
              </button>
            }
          />
        </div>
        <ErrorMessage>{requestError}</ErrorMessage>
        <div className="form__buttons">
          <Button type="button" onClick={onClose} color="gray" externalClass="button--modal">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button type="submit" externalClass="button--modal" loading={loading} disabled={loading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalChangePassword;
