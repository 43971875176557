import React, { ChangeEvent } from 'react';
import { OptionTypeBase } from 'react-select';
import { DAYS_OF_WEEK } from '../../../constants/libraries.constants';
import Input from '../../Input';
import NumberInput from '../../NumberInput';
import RadioButton from '../../RadioButton';
import Select from '../../Select';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import ErrorMessage from '../../ErrorMessage';
import { RepeatType } from '../../../constants/schedule.constants';

type EventRepeaterProps = {
  valuesEvent: any;
  errors: any;
  repeaterOptions: any;
  isCustomRepeater: boolean;
  isRepeat: boolean;
  repeaterMonthDaysOptions: any;
  repeaterValue: any;
  repeaterMonthDaysValues: any;
  handleChange: (e: ChangeEvent) => void;
  handleCountUpInterval: () => void;
  handleCountDownInterval: () => void;
  handleRepeaterChange: ({ value }: OptionTypeBase) => void;
  hasError: (field: string) => boolean | undefined;
  handleRepeaterMonthDaysChange: ({ value }: OptionTypeBase) => void;
  handleCountUpRepeatQuantity: () => void;
  handleCountDownRepeatQuantity: () => void;
};

function EventRepeater({
  valuesEvent,
  isCustomRepeater,
  isRepeat,
  errors,
  repeaterOptions,
  repeaterMonthDaysOptions,
  repeaterValue,
  repeaterMonthDaysValues,
  handleChange,
  handleCountUpInterval,
  handleCountDownInterval,
  handleRepeaterChange,
  hasError,
  handleRepeaterMonthDaysChange,
  handleCountUpRepeatQuantity,
  handleCountDownRepeatQuantity,
}: EventRepeaterProps) {
  const intl = useIntl();

  const occurrencesClass = classNames('form__input-date-repeater__label', {
    'no-checked': valuesEvent.endOfRepeatType !== 'REPEAT_QUANTITY',
  });
  return (
    <>
      {isCustomRepeater && (
        <>
          <div className="form__inputs-subwrapper">
            <div className="form__input-block form__input-block--half">
              <span>
                <FormattedMessage {...messages.repeatEveryLabel} />
              </span>
            </div>
            <div className="form__inputs-subwrapper">
              <NumberInput
                name={'repeatInterval'}
                maxLength={10}
                onChange={handleChange}
                min={0}
                externalClass={'form__input not-empty form__input--no-label'}
                defaultValue={valuesEvent.repeatInterval}
                onCountUp={handleCountUpInterval}
                onCountDown={handleCountDownInterval}
              />
              <Select
                options={repeaterOptions}
                handleChange={handleRepeaterChange}
                value={repeaterValue}
                externalClass="select__no-label"
              />
            </div>
          </div>
          {valuesEvent.unitOfRepeat === RepeatType.WEEKS && (
            <div className="form__input-block">
              <div className="form__input-block form__input-block--half">
                <span>
                  <FormattedMessage {...messages.repeatOnLabel} />
                </span>
              </div>
              <div className="form__week-checkboxes-wrapper">
                {DAYS_OF_WEEK.map(({ fullName, shortName }, index) => (
                  <div key={`${index}`} className="form__week-checkbox-block">
                    <input
                      className="form__week-checkbox"
                      type="checkbox"
                      name="repeatDays"
                      id={`${fullName}`}
                      onChange={handleChange}
                      checked={valuesEvent.repeatDays.includes(fullName)}
                      value={fullName}
                    />
                    <label className="form__week-label" htmlFor={`${fullName}`}>
                      {shortName}
                    </label>
                  </div>
                ))}
              </div>
              <ErrorMessage>{errors?.repeatDays}</ErrorMessage>
            </div>
          )}
          {valuesEvent.unitOfRepeat === RepeatType.MONTHS && (
            <div className="form__input-block">
              <Select
                externalClass="select__no-label "
                options={repeaterMonthDaysOptions}
                value={repeaterMonthDaysValues}
                handleChange={handleRepeaterMonthDaysChange}
                hasError={hasError('monthRepeatType')}
                errorMessage={errors?.monthRepeatType}
              />
            </div>
          )}
        </>
      )}
      {valuesEvent.unitOfRepeat && isRepeat && (
        <div className="form__input-block">
          <div className="form__input-block ">
            <span>
              <FormattedMessage {...messages.endsLabel} />
            </span>
          </div>
          <div className="form__inputs-subwrapper">
            <RadioButton
              name="endOfRepeatType"
              id="endOfRepeatType"
              label={intl.formatMessage(messages.onLabel)}
              checked={valuesEvent.endOfRepeatType === 'DATE'}
              externalClass="form__radio-input-repeater"
              onChange={handleChange}
              value="DATE"
            />
            <Input
              type="date"
              name="endOfRepeatDate"
              onChange={handleChange}
              disabled={valuesEvent.endOfRepeatType !== 'DATE'}
              defaultValue={valuesEvent.endOfRepeatDate}
              externalClass="form__input--no-label"
              wrapperClass="form__date-input-repeater"
              hasError={hasError('endOfRepeatDate')}
              errorMessage={errors?.endOfRepeatDate}
            />
          </div>
          <div className="form__inputs-subwrapper">
            <RadioButton
              name="endOfRepeatType"
              id="endOfRepeatType"
              externalClass="form__radio-input-repeater"
              label={intl.formatMessage(messages.afterLabel)}
              checked={valuesEvent.endOfRepeatType === 'REPEAT_QUANTITY'}
              onChange={handleChange}
              value={'REPEAT_QUANTITY'}
            />

            <NumberInput
              name={'endOfRepeatQuantity'}
              maxLength={10}
              onChange={handleChange}
              min={0}
              externalClass={'form__input not-empty form__input--no-label'}
              wrapperClass="form__number-input-repeater"
              defaultValue={valuesEvent.endOfRepeatQuantity}
              disabled={valuesEvent.endOfRepeatType !== 'REPEAT_QUANTITY'}
              onCountUp={handleCountUpRepeatQuantity}
              onCountDown={handleCountDownRepeatQuantity}
            />
            <span className={occurrencesClass}>
              {valuesEvent.endOfRepeatQuantity === 1 ? 'occurrence' : 'occurrences'}
            </span>
          </div>
          {/*TODO 'never' option*/}
          {/*{valuesEvent.unitOfRepeat !== 'YEARS' && (*/}
          {/*  <div className="form__inputs-subwrapper">*/}
          {/*    <RadioButton*/}
          {/*      name="endOfRepeatType"*/}
          {/*      id="endOfRepeatType"*/}
          {/*      label={intl.formatMessage(messages.neverLabel)}*/}
          {/*      checked={valuesEvent.endOfRepeatType === 'NEVER_REPEAT'}*/}
          {/*      externalClass="form__radio-input-repeater"*/}
          {/*      onChange={handleChange}*/}
          {/*      value="NEVER_REPEAT"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      )}
    </>
  );
}

export default EventRepeater;
