import { get } from 'lodash-es';
import { SortParams } from '../params.enum';

export class ResumesParams extends SortParams {
  size: number;
  page: number;
  approvedStatus: boolean[] | null;
  officeIds: string[];
  departmentIds: string[];
  positionIds: string[];
  employeeIds: string[];
  projectIds: string[];
  experienceFrom: number | null;
  experienceTo: number | null;
  summary: string | null;
  technicalSkills?: {
    skillGroupId: string;
    skillGroupName: string;
    skills: {
      id: string;
      skill: string;
    }[];
  }[];
  constructor(params?: unknown) {
    super('employee', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
    this.approvedStatus = get(params, 'approvedStatus', null);
    this.officeIds = get(params, 'officeIds', []);
    this.departmentIds = get(params, 'departmentIds', []);
    this.positionIds = get(params, 'positionIds', []);
    this.employeeIds = get(params, 'employeeIds', []);
    this.projectIds = get(params, 'projectIds', []);
    this.experienceFrom = get(params, 'experienceFrom', null);
    this.experienceTo = get(params, 'experienceTo', null);
    this.summary = get(params, 'summary', null);
    this.technicalSkills = get(params, 'technicalSkills', []);
  }
}
