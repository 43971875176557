import { get } from 'lodash-es';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';
import { weekPeriod } from '../../components/InlineDatePicker/constants';

export class ActivityParams {
  dateFrom: string;
  dateTo: string;
  departmentIds: string[];
  isAllowActive: boolean | null;
  isAllowPassive: boolean | null;
  offices: string[];
  portalUserIds: string[];
  isTable: boolean;
  zonedDateTime: string;

  constructor(params?: unknown) {
    this.dateFrom = get(params, 'dateFrom', moment().startOf(weekPeriod).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss));
    this.dateTo = get(params, 'dateTo', moment().endOf(weekPeriod).format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss));
    this.departmentIds = get(params, 'departmentIds', []);
    this.isAllowActive = get(params, 'isAllowActive', true);
    this.isAllowPassive = get(params, 'isAllowPassive', false);
    this.offices = get(params, 'offices', []);
    this.portalUserIds = get(params, 'portalUserIds', []);
    this.isTable = get(params, 'isTable', false);
    this.zonedDateTime = get(params, 'zonedDateTime', moment().format());
  }
}
