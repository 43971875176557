import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { PeopleLayouts } from '../../constants/users.constants';
import * as filtersActions from '../../actions/filters.actions';
import * as usersActions from '../../actions/users.actions';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewProfile from '../../components/People/Modals/ModalNewProfile';
import cx from 'classnames';
import GridLayout from '../../components/People/Layouts/Grid';
import ListLayout from '../../components/People/Layouts/List';
import GridFilters from '../../components/People/Filters/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { resetParamsChange, useLayout, useParamsChange, useUsersParamsChange } from '../../utils/hooks.utils';
import { UsersParams } from '../../enums/params/users.params';
import AccessChecker from '../../components/AccessChecker';
import {
  DELETE_ACTIVE_EMPLOYEE,
  UPDATE_USER_EXTENDED,
  VIEW_CONTACT_INFORMATION,
} from '../../constants/policies.constants';
import DurationPicker from '../../components/Dropdown';
import { exportUsers } from '../../actions/export.actions';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { convertSavedFieldsToParams, getSavedFilterParams, getSavedFilters } from '../../utils/filters.utils';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';
import isEqual from 'lodash-es/isEqual';

function Users({
  getUsers,
  createUser,
  users,
  params,
  officesFilter,
  departmentsFilter,
  jiraUsers,
  getOfficesFilter,
  getDepartmentsFilter,
  getJiraUsers,
  setUsersParams,
  loading,
  error,
  resetUserErrors,
  updateUserPartialInfo,
  resetState,
  getUsersFilter,
  getTechnicalSkillsFilter,
  getSkillLevelsFilter,
  getSpecializationsFilter,
  getCompanyPositionsFilter,
  getHrCuratorsFilter,
  usersFilter,
  authUserId,
  technicalSkillsFilter,
  skillLevelsFilter,
  exportUsers,
  specializationsFilter,
  companyPositionsFilter,
  hrCuratorsFilter,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const [isModalNewProfileOpen, setIsModalNewProfileOpen] = useState(false);
  const [layout, handleChangeActiveLayout] = useLayout(PeopleLayouts, PeopleLayouts.GRID);
  const intl = useIntl();
  const userPolicies = useContext(PoliciesContext);

  const isCanSeeInactiveUsers = useMemo(
    () => checkPolicies([UPDATE_USER_EXTENDED, DELETE_ACTIVE_EMPLOYEE], userPolicies),
    [userPolicies],
  );

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.USER_FILTER);

    if (!isCanSeeInactiveUsers && currentSavedFilter) {
      currentSavedFilter.active = true;
    }

    setUsersParams(
      currentSavedFilter
        ? new UsersParams(currentSavedFilter)
        : {
            officeIds: officesFilter.value,
            departmentIds: departmentsFilter.value,
            userIds: usersFilter.value,
          },
    );
    getOfficesFilter();
    getJiraUsers();
    getDepartmentsFilter();
    getUsersFilter();
    getTechnicalSkillsFilter();
    getSkillLevelsFilter();
    getSpecializationsFilter();
    getCompanyPositionsFilter();
    getHrCuratorsFilter();
    setSavedFiltersParams({ filterType: FilterTypes.USER_FILTER });

    return () => {
      resetState();
    };
  }, []);

  const openNewProfileModal = useCallback(() => {
    setIsModalNewProfileOpen(true);
  }, []);

  const closeNewProfileModal = useCallback(() => {
    setIsModalNewProfileOpen(false);
  }, []);

  const renderLayout = useCallback(
    (layout: string) => {
      switch (layout) {
        case PeopleLayouts.GRID:
          return (
            <GridLayout
              users={users}
              loading={loading}
              error={error}
              updateUserPartialInfo={updateUserPartialInfo}
              getUsers={getUsers}
              authUserId={authUserId}
            />
          );
        case PeopleLayouts.LIST:
          return (
            <ListLayout
              users={users}
              loading={loading}
              error={error}
              updateUserPartialInfo={updateUserPartialInfo}
              getUsers={getUsers}
              authUserId={authUserId}
            />
          );
        default:
          return (
            <GridLayout
              users={users}
              loading={loading}
              error={error}
              updateUserPartialInfo={updateUserPartialInfo}
              getUsers={getUsers}
              authUserId={authUserId}
            />
          );
      }
    },
    [users, loading, error],
  );

  const handleMultiParamsChange = useParamsChange(setUsersParams, dispatch);

  const handleParamsChange = useParamsChange(setUsersParams, null, false);

  const handleUsersParamsChange = useUsersParamsChange(setUsersParams, dispatch);

  const filters = useMemo(
    () => ({
      users: usersFilter.users,
      departments: departmentsFilter.departments,
      offices: officesFilter.offices,
      skills: technicalSkillsFilter.skills,
      skillLevels: skillLevelsFilter.skillLevels,
      specializations: specializationsFilter.specializations,
      positions: companyPositionsFilter.positions,
      hrCurators: hrCuratorsFilter.hrCurators,
    }),
    [
      usersFilter.users,
      departmentsFilter.departments,
      officesFilter.offices,
      technicalSkillsFilter.skills,
      skillLevelsFilter.skillLevels,
      specializationsFilter.specializations,
      companyPositionsFilter.positions,
      hrCuratorsFilter.hrCurators,
    ],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      const fields = convertSavedFieldsToParams(value.fields);

      if (!isCanSeeInactiveUsers) {
        fields.active = true;
      }

      setUsersParams(new UsersParams(fields));
    },
    [isCanSeeInactiveUsers],
  );

  const handleClear = useCallback(() => {
    setUsersParams(new UsersParams());

    resetParamsChange(
      [FilterParamsName.OFFICE_IDS, FilterParamsName.DEPARTMENT_IDS, FilterParamsName.USER_IDS],
      dispatch,
    );
  }, []);

  const filteredSavedFiltersData = useMemo(
    () =>
      isCanSeeInactiveUsers
        ? savedFiltersData
        : {
            ...savedFiltersData,
            filtersList: savedFiltersData.filtersList
              .map((filter: SavedFilter) => {
                const fields = convertSavedFieldsToParams(filter.fields);

                if (isEqual(fields, { active: false })) {
                  return null;
                }

                return filter;
              })
              .filter((filter: SavedFilter | null) => filter),
          },
    [savedFiltersData, isCanSeeInactiveUsers],
  );

  const showClearButton = useMemo(() => !isEqual(params, new UsersParams()), [params]);

  return (
    <>
      <div className="page__panel page__panel--fixed people-page">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <AccessChecker verifiablePolicies={[VIEW_CONTACT_INFORMATION]}>
                <div className="page__panel-view">
                  <Button
                    externalClass={cx('page__panel-view__btn', { active: layout === PeopleLayouts.GRID })}
                    onClick={() => handleChangeActiveLayout(PeopleLayouts.GRID)}
                    color="gray"
                  >
                    <Icon iconName="grid" />
                  </Button>
                  <Button
                    externalClass={cx('page__panel-view__btn', { active: layout === PeopleLayouts.LIST })}
                    onClick={() => handleChangeActiveLayout(PeopleLayouts.LIST)}
                    color="gray"
                  >
                    <Icon iconName="table" />
                  </Button>
                </div>
              </AccessChecker>
              <AccessChecker verifiablePolicies={[UPDATE_USER_EXTENDED]}>
                <DurationPicker
                  dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                  dropdownList={[
                    {
                      label: intl.formatMessage(messages.exportToXLSLabel),
                      handler: exportUsers,
                    },
                  ]}
                />
              </AccessChecker>
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_USER_EXTENDED]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewProfileModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <GridFilters
                  filters={filters}
                  values={params}
                  handleParamsChange={handleParamsChange}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleUsersParamsChange={handleUsersParamsChange}
                  setUsersParams={setUsersParams}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={filteredSavedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">{renderLayout(layout)}</div>
      </div>
      {isModalNewProfileOpen && (
        <ModalNewProfile
          isOpen
          onClose={() => {
            closeNewProfileModal();
            resetUserErrors();
          }}
          onSubmit={data => {
            createUser({ data, cb: closeNewProfileModal });
          }}
          departments={departmentsFilter.departments}
          offices={officesFilter.offices}
          jiraUsers={jiraUsers}
          loading={loading}
          requestError={error}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ users, filters, auth }: RootState) => ({
  users: users.users,
  usersFilter: filters.usersFilter,
  departmentsFilter: filters.departmentsFilter,
  officesFilter: filters.officesFilter,
  technicalSkillsFilter: filters.technicalSkillsFilter,
  skillLevelsFilter: filters.skillLevelsFilter,
  specializationsFilter: filters.specializationsFilter,
  companyPositionsFilter: filters.companyPositionsFilter,
  hrCuratorsFilter: filters.hrCuratorsFilter,
  jiraUsers: users.jiraUsers,
  loading: users.loading.users,
  error: users.errors.users,
  params: users.params,
  authUserId: auth.currentUserInfo.id,
  savedFiltersData: filters.savedFilters,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUsers: () => dispatch(usersActions.getUsers()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getTechnicalSkillsFilter: () => dispatch(filtersActions.getTechnicalSkillsFilter()),
  getSkillLevelsFilter: () => dispatch(filtersActions.getSkillLevelsFilter()),
  getSpecializationsFilter: () => dispatch(filtersActions.getSpecializationsFilter()),
  getCompanyPositionsFilter: () => dispatch(filtersActions.getCompanyPositionsFilter()),
  getHrCuratorsFilter: () => dispatch(filtersActions.getHrCuratorsFilter()),
  createUser: (data: Record<string, any>) => dispatch(usersActions.createUser(data)),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  getJiraUsers: () => dispatch(usersActions.getJiraUsers()),
  setUsersParams: (data: Partial<UsersParams>) => dispatch(usersActions.setUsersParams(data)),
  resetUserErrors: () => dispatch(usersActions.resetUserErrors()),
  updateUserPartialInfo: (uuid: string, data: any, cb: () => void) =>
    dispatch(usersActions.updateUserPartialInfo(uuid, data, cb)),
  resetState: () => dispatch(usersActions.resetState()),
  exportUsers: () => dispatch(exportUsers()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Users);
