import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Modal from '../../Modal';
import Button from '../../Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Checkbox from '../../Checkbox';
import ErrorMessage from '../../ErrorMessage';
import Search from '../../Search';
import {
  PolicyGroupSubmoduleWithOffice,
  PolicyModuleUserType,
  PolicyType,
  PolicyTypeEnum,
} from '../../../enums/policies.enum';
import { checkPolicies } from '../../../utils/policies.utils';
import PoliciesContext from '../../../PoliciesContext';
import { UPDATE_POLICY } from '../../../constants/policies.constants';
import classNames from 'classnames';
import { UserInfo, UserPreviewInfo } from '../../../enums/users.enum';
import Table from '../../Table';
import TableUserAvatar from '../../TableUserAvatar';
import { PolicyModuleParams } from '../../../enums/params/policies.params';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { DepartmentsInfoType } from '../../../types/libraries';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { FilterParamsName } from '../../../constants/filters.constants';
import { OptionTypeBase } from 'react-select';
import Select from '../../Select';

type ModaEditPermissionsProps = {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  error: string;
  tableError: string;
  editAllUsersPolicies: (payload: { data: any; callback: () => void }) => void;
  authUserId: string;
  userList: UserInfo[];
  currentSubmodule: PolicyGroupSubmoduleWithOffice | null;
  tableLoading: boolean;
  setPolicyModuleParams: (data: Partial<PolicyModuleParams>) => void;
  submoduleData: {
    users: PolicyModuleUserType[];
  };
  offices: OfficeInfo[];
  departments: DepartmentsInfoType[];
  params: PolicyModuleParams;
  handleChangeFilterParams: (name: string) => (data: OptionTypeBase) => void;
};

function ModaEditUsersPermissions({
  isOpen,
  onClose,
  loading,
  error,
  tableError,
  tableLoading,
  editAllUsersPolicies,
  authUserId,
  userList,
  currentSubmodule,
  setPolicyModuleParams,
  submoduleData,
  offices,
  departments,
  params,
  handleChangeFilterParams,
}: ModaEditPermissionsProps) {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const updateAccess = useMemo(() => checkPolicies([UPDATE_POLICY], policies), [policies]);

  const [searchValue, setSearchValue] = useState('');

  const submoduleWithOffice = useMemo(() => !!currentSubmodule?.submoduleId, [currentSubmodule]);

  useEffect(() => {
    currentSubmodule &&
      setPolicyModuleParams(
        submoduleWithOffice
          ? { levelId: currentSubmodule.submoduleId, officesIds: [currentSubmodule.id] }
          : { levelId: currentSubmodule.id, officesIds: offices.map(item => item.id) },
      );
  }, [currentSubmodule?.id]);

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      levelType: 'SUBMODULE',
      levelId: submoduleWithOffice ? currentSubmodule?.submoduleId : currentSubmodule?.id,
      users: submoduleData?.users?.map((user: PolicyModuleUserType) => {
        const toAddPolicies: string[] = [];
        const toDeletePolicies: string[] = [];

        currentSubmodule?.policies.map((policy: PolicyType) => {
          user.policies?.find(item => item.policyType === policy.policyType)
            ? toAddPolicies.push(policy.policyType)
            : toDeletePolicies.push(policy.policyType);
        });

        return { userId: user.userId, toAdd: toAddPolicies, toDelete: toDeletePolicies };
      }),
    },
    enableReinitialize: true,
    validationSchema: '',
    onSubmit: data =>
      editAllUsersPolicies({
        data: {
          ...data,
          users: data.users.filter(item => item.userId !== authUserId),
          officeIds: submoduleWithOffice ? [currentSubmodule?.id] : [],
        },
        callback: onClose,
      }),
  });

  const checkUserPolicies = useCallback(
    (userId: string, policyType: PolicyTypeEnum) => {
      const user = values.users.find(user => user.userId === userId);

      return !!user?.toAdd.find(item => item === policyType);
    },
    [values],
  );

  const checkSomeUsersPolicies = useCallback(
    (policyType: PolicyTypeEnum) =>
      values.users
        .filter(item => item.userId !== authUserId)
        .some(item => item.toAdd.find(item => item === policyType)),
    [values, authUserId],
  );

  const checkEveryUsersPolicies = useCallback(
    (policyType: PolicyTypeEnum) =>
      values.users
        .filter(item => item.userId !== authUserId)
        .every(item => item.toAdd.find(item => item === policyType)),
    [values, authUserId],
  );

  const handleUserPolicyChange = useCallback(
    (userIndex: number, value: boolean, policyType: PolicyTypeEnum) => {
      const user: any = values.users[userIndex];
      let toAddPolicies: string[] = [...user.toAdd];
      let toDeletePolicies: string[] = [...user.toDelete];

      if (value) {
        toDeletePolicies = toDeletePolicies.filter((item: string) => item !== policyType);
        toAddPolicies.push(policyType);
      } else {
        toAddPolicies = toAddPolicies.filter((item: string) => item !== policyType);
        toDeletePolicies.push(policyType);
      }

      if (policyType === PolicyTypeEnum.VIEW && !value) {
        toAddPolicies = [];
        toDeletePolicies = [PolicyTypeEnum.VIEW, PolicyTypeEnum.UPDATE, PolicyTypeEnum.DELETE];
      }

      if ((policyType === PolicyTypeEnum.UPDATE || policyType === PolicyTypeEnum.DELETE) && value) {
        toDeletePolicies = toDeletePolicies.filter((item: string) => item !== PolicyTypeEnum.VIEW);
        toAddPolicies.push(PolicyTypeEnum.VIEW);
      }

      const users = [...values.users];
      users[userIndex] = { ...user, toAdd: toAddPolicies, toDelete: toDeletePolicies };
      setFieldValue('users', users);
    },
    [values],
  );

  const handleAllUsersPolicyChange = useCallback(
    (value: boolean, policyType: PolicyTypeEnum) => {
      const users = [...values.users].map(user => {
        if (user.userId === authUserId) return user;

        let toAddPolicies: string[] = [...user.toAdd];
        let toDeletePolicies: string[] = [...user.toDelete];

        if (value) {
          toDeletePolicies = toDeletePolicies.filter((item: string) => item !== policyType);
          toAddPolicies = toAddPolicies.filter((item: string) => item !== policyType);
          toAddPolicies.push(policyType);
        } else {
          toAddPolicies = toAddPolicies.filter((item: string) => item !== policyType);
          toDeletePolicies = toDeletePolicies.filter((item: string) => item !== policyType);
          toDeletePolicies.push(policyType);
        }

        if (policyType === PolicyTypeEnum.VIEW && !value) {
          toAddPolicies = [];
          toDeletePolicies = [PolicyTypeEnum.VIEW, PolicyTypeEnum.UPDATE, PolicyTypeEnum.DELETE];
        }

        if ((policyType === PolicyTypeEnum.UPDATE || policyType === PolicyTypeEnum.DELETE) && value) {
          toDeletePolicies = toDeletePolicies.filter((item: string) => item !== PolicyTypeEnum.VIEW);
          toAddPolicies.push(PolicyTypeEnum.VIEW);
        }

        return { ...user, toAdd: toAddPolicies, toDelete: toDeletePolicies };
      });

      setFieldValue('users', users);
    },
    [values, authUserId],
  );

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.memberLabel),
        modifier: (row: any) =>
          row.firsItem ? (
            <Search onChange={event => setSearchValue(event.target.value)} placeholder={'Search'} />
          ) : (
            <TableUserAvatar users={[new UserInfo(row.userPreviewDto)]} fileSize={48} />
          ),
        className: (row: any) => (row.firsItem ? 'table__data-search' : ''),
      },
      {
        name: intl.formatMessage(messages.viewColumn),
        modifier: (row: any, rowIndex: any) =>
          currentSubmodule?.policies.find(item => item.policyType === PolicyTypeEnum.VIEW) ? (
            row.firsItem ? (
              <Checkbox
                id={row.userId + PolicyTypeEnum.VIEW}
                onChange={event => handleAllUsersPolicyChange(event.target.checked, PolicyTypeEnum.VIEW)}
                checkedValue={checkSomeUsersPolicies(PolicyTypeEnum.VIEW)}
                externalClass={classNames(
                  { 'clear-all-checkbox': !checkEveryUsersPolicies(PolicyTypeEnum.VIEW) },
                  'checkbox-no-label',
                )}
                iconName={checkEveryUsersPolicies(PolicyTypeEnum.VIEW) ? '' : 'clear-all-checkbox'}
                disabled={!updateAccess}
              />
            ) : (
              <Checkbox
                id={row.userId + PolicyTypeEnum.VIEW}
                onChange={event => handleUserPolicyChange(rowIndex - 1, event.target.checked, PolicyTypeEnum.VIEW)}
                checkedValue={checkUserPolicies(row.userId, PolicyTypeEnum.VIEW)}
                disabled={!updateAccess || authUserId === row.userId}
              />
            )
          ) : null,
      },
      {
        name: intl.formatMessage(messages.updateColumn),
        modifier: (row: any, rowIndex: any) =>
          currentSubmodule?.policies.find(item => item.policyType === PolicyTypeEnum.UPDATE) ? (
            row.firsItem ? (
              <Checkbox
                id={row.userId + PolicyTypeEnum.UPDATE}
                onChange={event => handleAllUsersPolicyChange(event.target.checked, PolicyTypeEnum.UPDATE)}
                checkedValue={checkSomeUsersPolicies(PolicyTypeEnum.UPDATE)}
                externalClass={classNames(
                  { 'clear-all-checkbox': !checkEveryUsersPolicies(PolicyTypeEnum.UPDATE) },
                  'checkbox-no-label',
                  'main-chackbox',
                )}
                iconName={checkEveryUsersPolicies(PolicyTypeEnum.UPDATE) ? '' : 'clear-all-checkbox'}
                disabled={!updateAccess}
              />
            ) : (
              <Checkbox
                id={row.userId + PolicyTypeEnum.UPDATE}
                checkedValue={checkUserPolicies(row.userId, PolicyTypeEnum.UPDATE)}
                onChange={event => handleUserPolicyChange(rowIndex - 1, event.target.checked, PolicyTypeEnum.UPDATE)}
                disabled={!updateAccess || authUserId === row.userId}
              />
            )
          ) : null,
      },
      {
        name: intl.formatMessage(messages.deleteColumn),
        modifier: (row: any, rowIndex: any) =>
          currentSubmodule?.policies.find(item => item.policyType === PolicyTypeEnum.DELETE) ? (
            row.firsItem ? (
              <Checkbox
                id={row.userId + PolicyTypeEnum.DELETE}
                onChange={event => handleAllUsersPolicyChange(event.target.checked, PolicyTypeEnum.DELETE)}
                checkedValue={checkSomeUsersPolicies(PolicyTypeEnum.DELETE)}
                externalClass={classNames(
                  { 'clear-all-checkbox': !checkEveryUsersPolicies(PolicyTypeEnum.DELETE) },
                  'checkbox-no-label',
                )}
                iconName={checkEveryUsersPolicies(PolicyTypeEnum.DELETE) ? '' : 'clear-all-checkbox'}
                disabled={!updateAccess}
              />
            ) : (
              <Checkbox
                id={row.userId + PolicyTypeEnum.DELETE}
                checkedValue={checkUserPolicies(row.userId, PolicyTypeEnum.DELETE)}
                onChange={event => handleUserPolicyChange(rowIndex - 1, event.target.checked, PolicyTypeEnum.DELETE)}
                disabled={!updateAccess || authUserId === row.userId}
              />
            )
          ) : null,
      },
    ],
    [values, currentSubmodule, authUserId, updateAccess],
  );

  const tableData = useMemo(() => {
    if (submoduleData?.users.length) {
      const usersTableData = submoduleData.users
        .filter((item: { userPreviewDto: UserPreviewInfo }) =>
          item.userPreviewDto.fullName.toLowerCase().includes(searchValue.trim().toLowerCase()),
        )
        .map(item => ({ ...item, firsItem: false }));

      usersTableData.unshift({
        firsItem: true,
        userId: '',
        userPreviewDto: new UserPreviewInfo(),
        policies: [],
      });

      return usersTableData;
    }
    return null;
  }, [submoduleData?.users, searchValue]);

  const departmentsOptions = useMemo(
    () =>
      departments?.map((department: DepartmentsInfoType) => ({
        label: department.displayName,
        value: department.id,
      })),
    [departments],
  );

  const officesOptions = useMemo(
    () =>
      offices?.map((offices: OfficeInfo) => ({
        label: offices.name,
        value: offices.id,
      })),
    [offices],
  );

  const usersOptions = useMemo(
    () =>
      userList?.map(user => ({
        label: user.fullName,
        value: user,
      })),
    [userList],
  );

  const departmentValues = useFiltersListValue(departmentsOptions, params.departmentIds);

  const officeValues = useFiltersListValue(officesOptions, params.officeIds);

  const usersValues = useFiltersListValue(usersOptions, params.userIds);

  const [filterWrapperWidth, setFilterWrapperWidth] = useState<number>();

  useEffect(() => {
    const filterBlock = document.querySelector('.edit-users-permissions-filter-wrapper');
    //@ts-ignore
    setFilterWrapperWidth(filterBlock.offsetHeight);
  }, [params]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <div>
          <div className="level-module-name">{`${currentSubmodule?.levelName} / ${currentSubmodule?.moduleName} ${
            submoduleWithOffice ? ' / ' + currentSubmodule?.submoduleName : ''
          }`}</div>
          <div>{currentSubmodule?.name}</div>
        </div>
      }
      classNameModal="edit-users-permissions"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper edit-users-permissions-filter-wrapper">
          <Select
            isMulti
            value={officeValues}
            options={officesOptions}
            handleChange={e => handleChangeFilterParams(FilterParamsName.OFFICE_IDS)(e)}
            label={intl.formatMessage(messages.officesLabel)}
          />
          <Select
            isMulti
            value={departmentValues}
            options={departmentsOptions}
            handleChange={e => handleChangeFilterParams(FilterParamsName.DEPARTMENT_IDS)(e)}
            label={intl.formatMessage(messages.departmentsLabel)}
          />
          <Select
            isMulti
            value={usersValues}
            options={usersOptions}
            handleChange={e => handleChangeFilterParams(FilterParamsName.USER_IDS)(e)}
            label={intl.formatMessage(messages.employeesLabel)}
          />
        </div>
        <div
          className="form__inputs-wrapper table-wrapper"
          style={{ maxHeight: `calc(100vh - (292px + ${filterWrapperWidth}px))` }}
        >
          <Table
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={tableLoading}
            error={tableError}
            tableActions={[]}
          />
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button type="button" onClick={onClose} color="gray" externalClass="button--modal">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          {updateAccess && (
            <Button type="submit" externalClass="button--modal" loading={loading} disabled={loading}>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          )}
        </div>
      </form>
    </Modal>
  );
}

export default ModaEditUsersPermissions;
