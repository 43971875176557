import React, { useEffect } from 'react';
import classNames from 'classnames';
import Icon from '../Icon';

type PortalNotificationProps = {
  notificationText: string;
  iconName?: string;
  externalClass?: string;
  onClose: () => void;
};

const PortalNotification = ({ notificationText, iconName, externalClass, onClose }: PortalNotificationProps) => {
  useEffect(() => {
    setTimeout(onClose, 6000);
  }, []);

  return (
    <div className={classNames('portal-notification', externalClass)}>
      {iconName && <Icon iconName={iconName} />}
      <span>{notificationText}</span>
      <button onClick={onClose} className="close-button">
        <Icon iconName={'cross'} />
      </button>
    </div>
  );
};

export default PortalNotification;
