import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import { NavLink } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { COMPETENCIES_VALIDATION_SCHEMA, CompetenciesInfo } from '../../../enums/competencies.enum';
import Input from '../../Input';
import Icon from '../../Icon';
import Select from '../../Select';
import Button from '../../Button';
import NumberInput from '../../NumberInput';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { CompetenciesTypeInfoType } from '../../../types/competencies';
import { scrollToError } from '../../../utils';

type NewCompetenceFormPropsType = {
  competenciesTypes: Array<CompetenciesTypeInfoType>;
  isLoading: boolean;
  competenciesError: string | RejectValueErrors[] | null;
  onSubmit: (data: any) => void;
};

function NewCompetenceForm({ competenciesTypes, isLoading, competenciesError, onSubmit }: NewCompetenceFormPropsType) {
  const options = competenciesTypes?.map(type => ({
    value: type.id,
    label: type.name,
  }));

  const intl = useIntl();

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit, setFieldError, dirty } = useFormik({
    initialValues: new CompetenciesInfo(),
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: COMPETENCIES_VALIDATION_SCHEMA,
    onSubmit: data => {
      onSubmit(COMPETENCIES_VALIDATION_SCHEMA.cast(data));
    },
  });

  useSetFieldsErrors(competenciesError, setFieldError);

  const addNewLevel = useCallback(() => {
    setFieldValue('competenceLevels', [
      ...values.competenceLevels,
      {
        name: '',
        priority: values.competenceLevels.length,
        skills: [
          {
            maxScore: 0,
            name: '',
            position: 0,
          },
        ],
      },
    ]);
  }, [values]);

  const addNewSkill = useCallback(
    (index: number) => {
      const levels = values.competenceLevels[index];
      setFieldValue(`competenceLevels[${index}].skills`, [
        ...levels.skills,
        {
          maxScore: 0,
          name: '',
          position: values.competenceLevels[index].skills.length,
        },
      ]);
    },
    [values],
  );

  const onSortLevelsHandler = useCallback(
    (oldIndex: number, newIndex: number) => {
      setFieldValue(`competenceLevels[${oldIndex}.priority`, oldIndex);
      setFieldValue(`competenceLevels[${newIndex}.priority`, newIndex);
    },
    [values],
  );

  const onSortSkillsHandler = useCallback(
    (levelsIndex: number, oldIndex: number, newIndex: number) => {
      setFieldValue(`competenceLevels[${levelsIndex}.skills[${oldIndex}].position`, oldIndex);
      setFieldValue(`competenceLevels[${levelsIndex}.skills[${newIndex}].position`, newIndex);
    },
    [values],
  );

  const setCompetenciesType = useCallback(
    (type: any) => {
      setFieldValue('competenceType', { id: type.value, name: type.value && type.label });
      setFieldValue('competenceTypeId', type.value);
    },
    [values],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );
  const removeLevel = useCallback(
    index => () => {
      values.competenceLevels.splice(index, 1);
      setFieldValue('competenceLevels', values.competenceLevels);
      for (let i = 0; i < values.competenceLevels.length; i++) {
        setFieldValue(`competenceLevels[${i}].priority`, i);
      }
    },
    [values],
  );
  const removeSkill = useCallback(
    (levelIndex, skillIndex) => () => {
      values.competenceLevels[levelIndex].skills.splice(skillIndex, 1);
      setFieldValue(`competenceLevels[${levelIndex}].skills`, values.competenceLevels[levelIndex].skills);
      for (let i = 0; i < values.competenceLevels[levelIndex].skills.length; i++) {
        setFieldValue(`competenceLevels[${levelIndex}].skills[${i}].position`, i);
      }
    },
    [values],
  );

  const maxScorePlus = useCallback(
    (levelIndex, skillIndex) => () => {
      setFieldValue(
        `competenceLevels[${levelIndex}].skills[${skillIndex}].maxScore`,
        values.competenceLevels[levelIndex].skills[skillIndex].maxScore + 1,
      );
    },
    [values],
  );
  const maxScoreMinus = useCallback(
    (levelIndex, skillIndex) => () => {
      setFieldValue(
        `competenceLevels[${levelIndex}].skills[${skillIndex}].maxScore`,
        values.competenceLevels[levelIndex].skills[skillIndex].maxScore - 1,
      );
    },
    [values],
  );

  const competenciesLevelsErrors: any = useMemo(() => errors.competenceLevels, [errors.competenceLevels]);
  const competenciesSkillsErrors: any = useMemo(() => errors?.competenceLevels && errors?.competenceLevels[0], [
    errors.competenceLevels,
  ]);

  return (
    <form className={'form form--page'} onSubmit={handleSubmit}>
      <div className="form__inputs-wrapper">
        <div className="form__inputs-subwrapper align-start">
          <div className="form__input-block form__input-block--two-third">
            <Input
              id={'name'}
              name="name"
              label={intl.formatMessage(messages.nameColumn)}
              defaultValue={values.name}
              onChange={handleChange}
              hasError={hasError('name')}
              errorMessage={errors?.name}
            />
          </div>
          <div className="form__input-block form__input-block--third">
            <Select
              options={options}
              handleChange={setCompetenciesType}
              hasError={hasError('competenceType')}
              errorMessage={errors.competenceType?.name}
              label={intl.formatMessage(messages.typeLabel)}
              value={
                values.competenceType.id ? { label: values.competenceType.name, value: values.competenceType.id } : null
              }
              isClearable
            />
          </div>
        </div>
        <div className="form__group">
          <ReactSortable
            list={values.competenceLevels}
            setList={newState => setFieldValue(`competenceLevels`, newState)}
            animation={200}
            handle={'.form__btn-move-inputs'}
            onSort={evt => onSortLevelsHandler(evt.oldIndex, evt.newIndex)}
            style={{ marginBottom: '14px' }}
          >
            {values.competenceLevels.map((level, index) => (
              <div className="form__group-wrapper form__group-wrapper--bordered" key={`[${index}]`}>
                <button className="form__btn-move-inputs" type={'button'}>
                  <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
                </button>
                <div className="form__inputs-wrapper">
                  <div className="form__input-block">
                    <Input
                      id={`competenceLevels[${index}].name`}
                      name={`competenceLevels[${index}].name`}
                      label={intl.formatMessage(messages.levelLabel)}
                      defaultValue={values.competenceLevels[index].name}
                      onChange={handleChange}
                      hasError={hasError(`competenceLevels[${index}].name`)}
                      errorMessage={competenciesLevelsErrors && competenciesLevelsErrors[index]?.name}
                    />
                  </div>
                  <div className="form__group">
                    <ReactSortable
                      list={values.competenceLevels[index].skills}
                      setList={newState => setFieldValue(`competenceLevels[${index}].skills`, newState)}
                      animation={200}
                      handle={'.form__btn-move-inputs'}
                      onSort={evt => onSortSkillsHandler(index, evt.oldIndex, evt.newIndex)}
                    >
                      {level.skills.map((skill, skillsIndex) => (
                        <div className="form__group-wrapper" key={skillsIndex}>
                          <button className="form__btn-move-inputs" type={'button'}>
                            <Icon iconName="grip-vertical" externalClass={'form__btn-icon form__btn-icon--move'} />
                          </button>
                          <div className="form__inputs-wrapper">
                            <div className="form__inputs-subwrapper">
                              <div className="form__input-block form__input-block--five-sixth">
                                <Input
                                  name={`competenceLevels[${index}].skills[${skillsIndex}].name`}
                                  id={`competenceLevels[${index}].skills[${skillsIndex}].name`}
                                  onChange={handleChange}
                                  defaultValue={skill.name}
                                  label={intl.formatMessage(messages.skillLabel)}
                                  hasError={hasError(`competenceLevels[${index}].skills[${skillsIndex}].name`)}
                                  errorMessage={
                                    competenciesLevelsErrors &&
                                    competenciesLevelsErrors[index]?.skills &&
                                    competenciesLevelsErrors[index].skills[skillsIndex]?.name
                                  }
                                />
                              </div>
                              <NumberInput
                                wrapperClass="form__input-block--sixth"
                                name={`competenceLevels[${index}].skills[${skillsIndex}].maxScore`}
                                id={`competenceLevels[${index}].skills[${skillsIndex}].maxScore`}
                                onChange={handleChange}
                                defaultValue={`${skill.maxScore}`}
                                label={intl.formatMessage(messages.maxScorelabel)}
                                externalClass={'form__input not-empty'}
                                hasError={hasError(`competenceLevels[${index}].skills[${skillsIndex}].maxScore`)}
                                errorMessage={
                                  competenciesLevelsErrors &&
                                  competenciesLevelsErrors[index]?.skills &&
                                  competenciesLevelsErrors[index].skills[skillsIndex]?.maxScore
                                }
                                onCountUp={maxScorePlus(index, skillsIndex)}
                                onCountDown={maxScoreMinus(index, skillsIndex)}
                              />
                            </div>
                          </div>
                          <button
                            className="form__btn-clean-inputs"
                            type={'button'}
                            onClick={removeSkill(index, skillsIndex)}
                          >
                            <Icon iconName={'cross'} />
                          </button>
                        </div>
                      ))}
                    </ReactSortable>
                    <button className="form__btn-add-group" type={'button'} onClick={() => addNewSkill(index)}>
                      <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
                      <FormattedMessage {...messages.skillLabel} />
                    </button>
                  </div>
                </div>
                <button className="form__btn-clean-inputs" type={'button'} onClick={removeLevel(index)}>
                  <Icon iconName={'cross'} externalClass={'form__btn-clean'} />
                </button>
              </div>
            ))}
          </ReactSortable>
          <button className="form__btn-add-group" onClick={addNewLevel} type={'button'}>
            <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
            <FormattedMessage {...messages.levelLabel} />
          </button>
        </div>
      </div>
      <ErrorMessage>{typeof competenciesLevelsErrors === 'string' && competenciesLevelsErrors}</ErrorMessage>
      <ErrorMessage>
        {typeof competenciesSkillsErrors?.skills === 'string' && competenciesSkillsErrors?.skills}
      </ErrorMessage>
      <div className="form__buttons">
        <NavLink to={'/competencies'}>
          <Button externalClass={'button--modal'} type={'button'} color="gray">
            <FormattedMessage {...messages.cancelButton} />
          </Button>
        </NavLink>
        <Button externalClass={'button--modal'} type={'submit'} disabled={isLoading || !dirty} loading={isLoading}>
          <FormattedMessage {...messages.saveButton} />
        </Button>
      </div>
    </form>
  );
}

export default NewCompetenceForm;
