import moment, { unitOfTime } from 'moment';
import { DATE_FORMAT } from '../constants/date.constants';
import { SortParams } from './../enums/params.enum';
import { EFileExtensions, ExtensionParamsValue } from '../constants/export.constants';

export type QueryParamsType<T> = Omit<T, 'sortBy' | 'direction'> & {
  sort: string | string[];
};

export function getQueryParams<T extends SortParams>(params: T): QueryParamsType<T> {
  const data: Partial<Pick<T, 'sortBy' | 'direction'>> &
    Omit<T, 'sortBy' | 'direction'> & {
      sort: string | string[];
    } = {
    ...params,
    sort: params.sortParam,
  };
  delete data.sortBy;
  delete data.direction;
  return data;
}

export const getDateParams = (
  settings: { getFromStart?: boolean; withTime?: boolean; period?: unitOfTime.Base } = {
    getFromStart: true,
    withTime: true,
    period: 'month',
  },
) => {
  const today = moment();
  const { getFromStart = true, withTime = true, period = 'month' } = settings;
  const format = withTime ? DATE_FORMAT.YYYY_MM_DD_HH_mm_ss : DATE_FORMAT.YYYY_MM_DD;
  if (getFromStart) {
    return today.startOf(period).format(format);
  } else {
    return today.endOf(period).format(format);
  }
};

export const getDateWithTimeZoneParams = (
  settings: { getFromStart?: boolean; withTime?: boolean; period?: unitOfTime.Base } = {
    getFromStart: true,
    withTime: true,
    period: 'month',
  },
) => {
  const today = moment();
  const { getFromStart = true, period = 'month' } = settings;
  if (getFromStart) {
    return today.startOf(period).format();
  } else {
    return today.endOf(period).format();
  }
};

export const getFileExtensionParamsValue = (ext: EFileExtensions) => {
  switch (ext) {
    case EFileExtensions.XLS:
      return ExtensionParamsValue.EXCEL;
    case EFileExtensions.PDF:
      return ExtensionParamsValue.PDF;
    default:
      return ExtensionParamsValue.EXCEL;
  }
};
