import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, LinearScale, Title, CategoryScale, Tooltip, Legend, TimeScale } from 'chart.js';
import { getSummaryChartMonth } from './utils';
import { ChartIncomesExpensesMonthType, CurrencyType } from '../../../../types/finance';
import { getIncomesExpensesOptions } from '../../../../constants/finance.constants';

declare module 'chart.js' {
  interface TooltipPositionerMap {
    cursor: TooltipPositionerFunction<ChartType>;
  }
}

type SummaryChartProps = {
  incomesExpensesData: ChartIncomesExpensesMonthType[];
  monthRange: string[];
  baseCurrency: CurrencyType | undefined;
};

function SummaryChart({ incomesExpensesData, monthRange, baseCurrency }: SummaryChartProps) {
  Tooltip.positioners.cursor = function (chartElements, coordinates) {
    return coordinates;
  };

  const chartData = useMemo(() => getSummaryChartMonth(incomesExpensesData, monthRange), [
    incomesExpensesData,
    monthRange,
  ]);

  const chartOptions = useMemo(() => getIncomesExpensesOptions(baseCurrency), [baseCurrency]);

  ChartJS.register(BarElement, LinearScale, Title, CategoryScale, TimeScale, Tooltip, Legend);

  return (
    <div className="tabs__content-work-wrapper summary">
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
}

export default React.memo(SummaryChart);
