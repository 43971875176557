import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import noop from 'lodash-es/noop';

import Icon from '../Icon';
import { useClickOutsideHandler } from '../../utils/hooks.utils';

type ModalProps = {
  isOpen: boolean;
  onRequestClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  hideCloseBtn?: boolean;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  size?: 'small' | 'big' | 'large';
  popover?: boolean;
  classNameModal?: string;
  classNameModalHead?: string;
  modalOptions?: React.ReactNode;
  shouldCloseOnOverlayClick?: boolean;
  changeDocumentOverflowStyle?: boolean;
  modalStyle?: React.CSSProperties;
  modalRef?: any;
  dropdownRef?: any;
};

function Modal({
  isOpen,
  onRequestClose,
  hideCloseBtn,
  title,
  children,
  size,
  popover,
  classNameModal,
  classNameModalHead,
  modalOptions,
  shouldCloseOnOverlayClick,
  changeDocumentOverflowStyle = true,
  modalStyle,
  modalRef,
  dropdownRef,
}: ModalProps) {
  const modalBodyRef = useRef(null);
  useClickOutsideHandler(shouldCloseOnOverlayClick ? onRequestClose : noop, modalBodyRef, dropdownRef);

  const modalClassName = classNames(
    'modal',
    classNameModal,
    size ? `modal--${size}` : false,
    popover ? 'modal--popover' : false,
    { open: isOpen },
  );
  const headClassName = classNames('modal__head', classNameModalHead);

  useEffect(() => {
    if (changeDocumentOverflowStyle && isOpen) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isOpen]);

  return (
    <div className={modalClassName} style={modalStyle} ref={modalRef}>
      <div ref={modalBodyRef} className="modal__body">
        <div className={headClassName}>
          {!!title && <div className="modal__title">{title}</div>}
          <div className="modal__options">
            {modalOptions}
            {!hideCloseBtn && (
              <button className="modal__options-btn modal__close" type="button" onClick={onRequestClose}>
                <Icon iconName="cross" externalClass="modal__options-icon" />
              </button>
            )}
          </div>
        </div>
        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
