import * as paramsTypes from '../enums/params/libraries.params';
import { QueryParamsType } from './../utils/params.utils';
import request from '../utils/request.utils';
import { FiltersParams } from '../enums/params.enum';

export function getOfficesList(params?: QueryParamsType<paramsTypes.OfficesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/offices',
    params,
  };
  return request(options);
}

export function createNewOffice(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/offices',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteOffice(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/offices/${id}`,
  };
  return request(options);
}

export function editOffice(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/offices/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getOfficesWorkDays(params?: any) {
  const options: RequestConfig = {
    method: 'get',
    url: '/offices/workdays',
    params,
  };
  return request(options);
}

export function getGradesList(params?: QueryParamsType<paramsTypes.GradesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/grades',
    params,
  };
  return request(options);
}

export function getSpecializationsList(params?: QueryParamsType<paramsTypes.SpecializationsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/specializations',
    params,
  };
  return request(options);
}
export function getSpecializationsCompetencies() {
  const options: RequestConfig = {
    method: 'get',
    url: '/specializations/preview',
  };
  return request(options);
}

export function getSpecializationById(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/specializations/${id}`,
  };
  return request(options);
}

export function createNewGrade(data: any, params: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/grades',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params,
  };
  return request(options);
}

export function getCompanyPositionsList(params?: QueryParamsType<paramsTypes.CompanyPositionsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/positions',
    params,
  };
  return request(options);
}

export function createNewSpecialization(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/specializations',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getDepartmentsList(params?: QueryParamsType<paramsTypes.DepartmentsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/departments',
    params,
  };
  return request(options);
}

export function createNewCompanyPosition(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/positions',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function createNewDepartment(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/departments',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteGrade(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/grades/${id}`,
    params: { forceDelete: true },
  };

  return request(options);
}

export function deleteSpecialization(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/specializations/${id}`,
  };
  return request(options);
}

export function deleteCompanyPosition(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/positions/${id}`,
  };
  return request(options);
}

export function deleteDepartment(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/departments/${id}`,
  };
  return request(options);
}

export function editCompanyPosition(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/positions/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editSpecialization(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/specializations/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}
export function editSpecializationCompetencies(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/specializations/${data.id}/competencies`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editGrade(data: any, params: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/grades/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params,
  };
  return request(options);
}

export function editDepartment(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/departments/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getProjectsList(params?: QueryParamsType<paramsTypes.ProjectsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/projects',
    params,
  };
  return request(options);
}

export function createNewProject(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/projects',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteProject(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/projects/${id}`,
  };
  return request(options);
}

export function editProject(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/projects/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getTechnicalSkillsList(
  params?: QueryParamsType<paramsTypes.TechnicalSkillsParams> | QueryParamsType<FiltersParams>,
) {
  const options: RequestConfig = {
    method: 'get',
    url: '/technicalSkills',
    params,
  };
  return request(options);
}

export function getSkillGroup(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/technicalSkills/${id}`,
  };
  return request(options);
}

export function createNewTechnicalSkill(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/technicalSkills',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteTechnicalSkill(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/technicalSkills/${id}`,
  };
  return request(options);
}

export function editTechnicalSkill(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/technicalSkills/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getSkillLevelsList(params?: QueryParamsType<paramsTypes.SkillLevelsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/skillLevels',
    params,
  };
  return request(options);
}

export function getSkillLevel(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/skillLevels/${id}`,
  };
  return request(options);
}

export function createNewSkillLevel(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/skillLevels',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteSkillLevel(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/skillLevels/${id}`,
  };
  return request(options);
}

export function editSkillLevel(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/skillLevels/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getLanguageLevelsList(params?: QueryParamsType<paramsTypes.LanguageLevelsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/languageLevels',
    params,
  };
  return request(options);
}

export function getLanguageLevel(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/languageLevels/${id}`,
  };
  return request(options);
}

export function createNewLanguageLevel(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/languageLevels',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteLanguageLevel(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/languageLevels/${id}`,
  };
  return request(options);
}

export function editLanguageLevel(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/languageLevels/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getForeignLanguagesList(params?: QueryParamsType<paramsTypes.ForeignLanguagesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/foreignLanguages',
    params,
  };
  return request(options);
}

export function getForeignLanguage(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/foreignLanguages/${id}`,
  };
  return request(options);
}

export function createNewForeignLanguage(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: '/foreignLanguages',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteForeignLanguage(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/foreignLanguages/${id}`,
  };
  return request(options);
}

export function editForeignLanguage(data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/foreignLanguages/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}
