import React from 'react';
import classNames from 'classnames';

import spriteIcons from '../../assets/svg/sprite-icons.svg';

type IconProps = {
  iconName: string;
  externalClass?: string;
};

function Icon({ iconName, externalClass }: IconProps) {
  const defaultClass = classNames('icons', {
    [iconName]: Boolean(iconName),
    [externalClass as string]: Boolean(externalClass),
  });

  return (
    <svg className={defaultClass}>
      <use xlinkHref={`${spriteIcons}#${iconName}`} />
    </svg>
  );
}

export default Icon;
