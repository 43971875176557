import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { UserInfo } from '../../../enums/users.enum';
import { PROFILE_TABS } from '../../../constants/users.constants';
import ProfileAbout from './About';
import ProfileRecognitions from './Recognitions';
import ProfileWorkTime from './WorkTime';
import { useHistory, useLocation } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import ProfileTechnicalSkills from './TechnicalSkills';
import GradesAndBonuses from './GradesAndBonuses/index';
import { values } from 'lodash-es';
import ProfileSchedule from './Schedule';
import ProfilePublicCV from './PublicCV';
import ProfileCompetencies from './Competencies';

type ProfileTabsProps = {
  userInfo: UserInfo;
  bonusesSettings: {
    ratio: number;
    isShow: boolean;
  };
};

function ProfileTabs({ userInfo, bonusesSettings }: ProfileTabsProps) {
  const handleDisplayName = (name: string) => {
    switch (name) {
      case PROFILE_TABS.ABOUT:
        return intl.formatMessage(messages.aboutTitle);
      case PROFILE_TABS.WORK_TIME:
        return intl.formatMessage(messages.workTimeTitle);
      case PROFILE_TABS.SCHEDULE:
        return intl.formatMessage(messages.scheduleTitle);
      case PROFILE_TABS.COMPETENCIES:
        return intl.formatMessage(messages.competenciesTitle);
      case PROFILE_TABS.TECHNICAL_SKILLS:
        return intl.formatMessage(messages.technicalSkillsTitle);
      case PROFILE_TABS.GRADES_AND_BONUSES:
        return intl.formatMessage(messages.gradesAndBonusesTitle);
      case PROFILE_TABS.PUBLIC_CV:
        return intl.formatMessage(messages.publicCVTitle);
      case PROFILE_TABS.RECOGNITIONS:
        return (
          <>
            <FormattedMessage {...messages.recognitionsTitle} />
            {userInfo.showRecCountInUserTab && (
              <div className="tabs__header-item__recognitions-count">{userInfo.recognitionsCount}</div>
            )}
          </>
        );
      default:
        return '';
    }
  };
  const intl = useIntl();
  const { search } = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(PROFILE_TABS.ABOUT);
  const profileTabs = useMemo(
    () =>
      values(PROFILE_TABS)
        .filter(el => !(el === PROFILE_TABS.GRADES_AND_BONUSES && !bonusesSettings?.isShow))
        .map(el => {
          return {
            displayName: handleDisplayName(el),
            value: el,
          };
        }),
    [userInfo.recognitionsCount, bonusesSettings],
  );

  useEffect(() => {
    const activeSearchTab = new URLSearchParams(search).get('tab_name')?.toUpperCase();
    const activeTab = Object.values(PROFILE_TABS).find(tab => tab === activeSearchTab) || PROFILE_TABS.ABOUT;
    setActiveTab(activeTab);
  }, []);

  const handleActiveTab = useCallback(activeTab => {
    history.replace({
      search: `tab_name=${activeTab.toLowerCase()}`,
    });
    setActiveTab(activeTab);
  }, []);

  const tabContent = useMemo(() => {
    switch (activeTab) {
      case PROFILE_TABS.ABOUT:
        return <ProfileAbout />;
      case PROFILE_TABS.TECHNICAL_SKILLS:
        return <ProfileTechnicalSkills />;
      case PROFILE_TABS.RECOGNITIONS:
        return <ProfileRecognitions />;
      case PROFILE_TABS.WORK_TIME:
        return <ProfileWorkTime />;
      case PROFILE_TABS.COMPETENCIES:
        return <ProfileCompetencies />;
      case PROFILE_TABS.GRADES_AND_BONUSES:
        return bonusesSettings?.isShow ? <GradesAndBonuses /> : null;
      case PROFILE_TABS.SCHEDULE:
        return <ProfileSchedule />;
      case PROFILE_TABS.PUBLIC_CV:
        return <ProfilePublicCV />;
      default:
        return null;
    }
  }, [activeTab, bonusesSettings]);

  return profileTabs ? (
    <div className="tabs page__profile-tabs">
      <div className="tabs__header">
        <div className="tabs__header-wrapper">
          {profileTabs.map(({ displayName, value }, i) => (
            <div
              key={i.toString()}
              className={classNames('tabs__header-item', { active: activeTab === value })}
              onClick={() => handleActiveTab(value)}
            >
              {displayName}
            </div>
          ))}
        </div>
      </div>
      <div className="tabs__content">{tabContent}</div>
    </div>
  ) : null;
}

export default ProfileTabs;
