import React from 'react';
import { TActivityType, TActivityUserDto } from '../../../../../types/activity';
import { getHoursAndMinutes } from '../../../../../utils/activity.utils';
import { summaryDataSet } from '../utils';
import InfoBlock from '../../../../InfoBlock';

type SummaryInfoProps = {
  activityData: TActivityUserDto | null;
  plannedHour: number;
};

function SummaryInfo({ activityData, plannedHour }: SummaryInfoProps) {
  const submitedTime = activityData?.submitted || 0;
  const remaining = plannedHour - submitedTime;
  const remainingTime = remaining < 0 ? 0 : remaining;

  return (
    <div className="summary-info-wrapper">
      {summaryDataSet.map(el => {
        let time = 0;
        const field = el.key;
        switch (field) {
          case summaryDataSet[4].key: {
            time = plannedHour;
            break;
          }

          case summaryDataSet[5].key: {
            time = remainingTime;
            break;
          }

          default: {
            time = activityData?.hasOwnProperty(field) ? activityData[field as keyof TActivityType] : 0;
          }
        }
        return (
          <InfoBlock
            key={`summary-info-${el.key}`}
            label={el.label}
            value={getHoursAndMinutes(time, false)}
            color={el.backgroundColor}
            externalWrapperClass="text-end"
          />
        );
      })}
    </div>
  );
}

export default React.memo(SummaryInfo);
