import React, { useCallback, useEffect, useMemo } from 'react';
import Icon from '../../../Icon';

import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../messages';
import { connect, ConnectedProps } from 'react-redux';
import GradeBlock from './GradeBlock';
import { useParams } from 'react-router';
import { TRouteParamId } from '../../../../enums/common.enum';
import * as usersActions from '../../../../actions/users.actions';
import * as bonusesActions from '../../../../actions/bonuses.actions';
import classNames from 'classnames';
import { BonusCategoryInfo, BonusExperiencesInfo, ExperienceGrades } from '../../../../enums/bonuses.enums';
import { BonusesParams } from '../../../../enums/params/bonuses.params';
import HierarchicalTable from '../../../HierarchicalTable';
import { useTableData } from '../../../../utils/hooks.utils';

function GradesAndBonuses({
  gradeList,
  experiences,
  bonusesList,
  userBonuses,
  bonusesSettings,
  isLoading,
  errors,
  setBonusesParams,
  getUserExperiences,
  getUserBonuses,
  resetBonusesState,
}: ConnectedProps<typeof connector>) {
  const { id } = useParams<TRouteParamId>();
  const intl = useIntl();

  useEffect(() => {
    setBonusesParams({ userId: id, isActive: true });
    getUserExperiences(id);
    getUserBonuses(id);
  }, [id]);

  useEffect(() => {
    return () => {
      resetBonusesState();
    };
  }, []);

  const tableHeaderItems = [
    {
      name: '',
      className: 'table__head-social',
      sortName: '',
    },
    {
      name: intl.formatMessage(messages.gradesLabel),
      className: 'table__head-column table__head-column--center bonus-grades-title',
      colspan: gradeList?.length,
      sortName: '',
    },
  ];

  const tableColumnsStart = useMemo(
    () => [
      {
        id: 'socialPayment',
        Header: intl.formatMessage(messages.socialPaymentLabel),
        accessor: 'name',
        headClassName: 'table__head-column--no-border table__head-social',
      },
    ],
    [gradeList, userBonuses],
  );

  if (bonusesList) {
    bonusesList.forEach((el: BonusCategoryInfo) => {
      el.experiences.forEach((el: BonusExperiencesInfo) => {
        el.experienceGrades.sort((a: any, b: any) => {
          return a.gradeFrom.level - b.gradeFrom.level;
        });
      });
    });
  }

  const isAvailableBonus = (bonus: ExperienceGrades) =>
    Boolean(userBonuses?.find((userBonus: { id: string }) => userBonus.id === bonus.id));

  const tableColumns = useMemo(
    () =>
      gradeList?.map((grade: any) => ({
        id: grade.id,
        Header: grade.name,
        headClassName: classNames('table__head-column--center table__grade', {
          available: grade.id === experiences?.grade?.id,
        }),
      })),
    [gradeList, experiences],
  );

  const gradeColumns = tableColumnsStart.concat(tableColumns || []);

  const customColumn = useCallback(
    (row: any) => {
      const originalRow: any = row.original;

      if (row.depth === 0) {
        let bonus: ExperienceGrades | undefined;
        originalRow.experiences.forEach((el: BonusExperiencesInfo) => {
          if (bonus) {
            return null;
          }

          bonus = el.experienceGrades.find((expBonus: ExperienceGrades) => isAvailableBonus(expBonus));
        });

        return (
          <>
            <td
              className="table__data first-column"
              colSpan={gradeList.length + 1}
              //@ts-ignore
              style={{ '--column-padding-left': '0px' }}
            >
              <div className="table__row-title">
                <Icon iconName="arrow-open" externalClass="icon table__open-icon" />
                <div className="table__data-wrapper user-bonus-row">
                  {originalRow.name}
                  {bonus && <span className="active-bonus">{bonus.value}</span>}
                </div>
              </div>
            </td>
          </>
        );
      } else if (row.depth === 1) {
        return (
          <>
            <td
              //@ts-ignore
              style={{ '--column-padding-left': '32px' }}
              key={originalRow.id}
              className="table__data sub_row_table__data first-column"
            >
              <div className="table__row-title">
                <div className="table__data-wrapper">
                  <span className="bonus-title"> {originalRow.name}</span>
                </div>
              </div>
            </td>
            {originalRow.experienceGrades.map((el: ExperienceGrades) => {
              const available = isAvailableBonus(el);
              return (
                <td
                  key={el.id}
                  className={classNames('table__data bonus-value', { available: available })}
                  colSpan={el.gradeTo.level - el.gradeFrom.level + 1}
                >
                  {available && <Icon iconName="check" externalClass="table__data-icon" />}
                  {el.value}
                </td>
              );
            })}
          </>
        );
      }
      return null;
    },
    [gradeList, experiences, userBonuses],
  );

  return (
    <div className="tabs__content-item active tabs__content-item--grades">
      <div className="tabs__content-item__title">
        <FormattedMessage {...messages.gradesAndBonusesTitle} />
      </div>
      <div className="tabs__content-item__wrapper tabs__content-item__wrapper--grades">
        {experiences && (
          <>
            <GradeBlock
              label={intl.formatMessage(messages.currentGradeLabel)}
              externalClass="grade-block--current-grade"
              gradeValue={experiences.grade?.name}
            />
            <GradeBlock
              label={
                <>
                  <FormattedMessage {...messages.gradeExperienceLabel} />
                  <span className="grade-block__name-info">
                    <Icon iconName="info" externalClass="button-info__icon" />
                    <span className="grade-block__hint-info">
                      <FormattedMessage {...messages.gradeExperienceLabel} /> = (
                      <FormattedMessage {...messages.previousExperienceLabel} /> x {bonusesSettings?.ratio}) +&nbsp;
                      <FormattedMessage {...messages.experienceInCompanyLabel} />
                    </span>
                  </span>
                </>
              }
              externalClass="grade-block--grade-experience"
              exprerinces={experiences.gradeExperience}
            />
            <GradeBlock
              label={intl.formatMessage(messages.previousExperienceLabel)}
              externalClass="grade-block--prev-experience"
              exprerinces={experiences.previousExperience}
            />
            <GradeBlock
              label={intl.formatMessage(messages.experienceInCompanyLabel)}
              externalClass="grade-block--experience-in-company"
              exprerinces={experiences.companyExperience}
            />
            <GradeBlock
              label={intl.formatMessage(messages.fullExperienceLabel)}
              externalClass="grade-block--full-experience"
              exprerinces={experiences.fullExperience}
            />
          </>
        )}
      </div>
      <HierarchicalTable
        tableData={useTableData(bonusesList, ['experiences'])}
        tableHeaderItems={tableHeaderItems}
        tableColumns={gradeColumns}
        loading={isLoading}
        error={errors}
        externalClass="table--striped table--bonuses-by-grades table--bonuses-by-grades user-tabs fixed"
        customColumn={customColumn}
      />
    </div>
  );
}

const mapStateToProps = ({ users, filters, bonuses }: RootState) => ({
  professionalInfo: users.current.professional,
  gradeList: filters.gradesFilter.grades,
  bonusesList: bonuses.bonusesTableData?.content || [],
  experiences: users.current.experiences,
  userBonuses: users.current.bonuses,
  bonusesSettings: bonuses.bonusesSettings,
  isLoading: users.loading.bonuses,
  errors: users.errors.bonuses,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setBonusesParams: (data: Partial<BonusesParams>) => dispatch(bonusesActions.setBonusesParams(data)),
  getUserExperiences: (uuid: string) => dispatch(usersActions.getUserExperiences(uuid)),
  getUserBonuses: (uuid: string) => dispatch(usersActions.getUserBonuses(uuid)),
  resetBonusesState: () => dispatch(bonusesActions.resetBonusesState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(GradesAndBonuses);
