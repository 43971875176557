import { defineMessages } from 'react-intl';
import { departmentsMembersLabel, totalRow, saveButton, exportToXLSLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  departmentsMembersLabel,
  totalRow,
  saveButton,
  exportToXLSLabel,
  pageTitle: {
    id: 'schedule.administration.days.used.limit.title.event.types',
    defaultMessage: 'Days (Used / Limit)',
  },
  daysLeftFromPreviousYearColumn: {
    id: 'schedule.administration.days.used.limit.column.days.left.from.previous.year',
    defaultMessage: 'Days left from previous year',
  },
  daysAvailableInThisYearColumn: {
    id: 'schedule.administration.days.used.limit.column.days.available.in.this.year',
    defaultMessage: 'Days available in this year',
  },
  availableDaysBasedOnDaysWorkedColumn: {
    id: 'schedule.administration.days.used.limit.column.available.days.based.on.days.worked',
    defaultMessage: '  Available days based on days worked',
  },
  daysUsedInThisYearColumn: {
    id: 'schedule.administration.days.used.limit.column.days.used.in.this.year',
    defaultMessage: 'Days used in this year',
  },
  daysLeftInThisYearColumn: {
    id: 'schedule.administration.days.used.limit.column.days.left.in.this.year',
    defaultMessage: 'Days left in this year',
  },
  noLimitLabel: {
    id: 'schedule.administration.days.used.limit.label.no.limit',
    defaultMessage: 'No limit',
  },
});
