import { ResumesParams } from './../enums/params/resumes.params';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/resumes.constants';
import { Resume } from '../enums/resumes.enum';

type InitialStateType = {
  resumesTableData: { content: Resume[] } | null;
  currentResume: Resume | null;
  resumesParams: ResumesParams;
  errors: {
    resumesListError: string | null;
    editResumeError: string | null;
    getResume: string | null;
  };
  loading: {
    getResumesList: boolean;
    editResume: boolean;
    getResume: boolean;
  };
};

const initialState: InitialStateType = {
  resumesTableData: null,
  currentResume: null,
  resumesParams: new ResumesParams(),
  errors: {
    resumesListError: null,
    editResumeError: null,
    getResume: null,
  },
  loading: {
    getResumesList: false,
    editResume: false,
    getResume: false,
  },
};

const resumesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_RESUMES_LIST_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          getResumesList: true,
        },
      };

    case ActionTypes.GET_RESUMES_LIST_SUCCESS:
      return {
        ...state,
        resumesTableData: {
          ...action.payload,
          content: action.payload.content.map((resume: Resume) => new Resume(resume)),
        },
        errors: {
          ...state.errors,
          resumesListError: null,
        },
        loading: {
          ...state.loading,
          getResumesList: false,
        },
      };

    case ActionTypes.GET_RESUMES_LIST_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          resumesListError: action.payload.message,
        },
        loading: {
          ...state.loading,
          getResumesList: false,
        },
      };

    case ActionTypes.PATCH_USER_RESUME_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, editResumeError: null },
        loading: { ...state.loading, editResume: true },
      };

    case ActionTypes.PATCH_USER_RESUME_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, editResumeError: null },
        loading: { ...state.loading, editResume: false },
      };

    case ActionTypes.PATCH_USER_RESUME_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, editResumeError: action.payload.message },
        loading: { ...state.loading, editResume: false },
      };

    case ActionTypes.GET_RESUME_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          getResume: true,
        },
      };

    case ActionTypes.GET_RESUME_SUCCESS:
      return {
        ...state,
        currentResume: new Resume(action.payload),
        errors: {
          ...state.errors,
          getResume: null,
        },
        loading: {
          ...state.loading,
          getResume: false,
        },
      };

    case ActionTypes.GET_RESUME_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          getResume: action.payload.message,
        },
        loading: {
          ...state.loading,
          getResume: false,
        },
      };

    case ActionTypes.SET_RESUMES_PARAMS:
      return {
        ...state,
        resumesParams: new ResumesParams({ ...state.resumesParams, ...action.payload }),
        loading: {
          ...state.loading,
          getResumesList: true,
        },
      };

    case ActionTypes.RESET_RESUMES_STATE:
      return {
        ...initialState,
        resumesParams: state.resumesParams,
      };

    default:
      return state;
  }
};

export default resumesReducer;
