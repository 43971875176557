import { defineMessages } from 'react-intl';
import {
  newButton,
  editButton,
  deleteButton,
  activeLabel,
  inactiveLabel,
  statusLabel,
  projectsLabel,
  jiraProjectsLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  activeLabel,
  inactiveLabel,
  statusLabel,
  editButton,
  deleteButton,
  projectsLabel,
  newButton,
  jiraProjectsLabel,
  fullNameTitle: {
    id: 'planning.project.groups.column.full.name',
    defaultMessage: 'Full name',
  },
  shortNameTitle: {
    id: 'planning.project.groups.column.short.name',
    defaultMessage: 'Short Name',
  },
  alertsTitle: {
    id: 'planning.project.groups.column.alerts',
    defaultMessage: 'Alerts',
  },
  yesLabel: {
    id: 'planning.project.groups.label.yes',
    defaultMessage: 'Yes',
  },
  noLabel: {
    id: 'planning.project.groups.label.no',
    defaultMessage: 'No',
  },
  pageTitle: {
    id: 'planning.project.groups.title.project.groups',
    defaultMessage: 'Project Groups',
  },
});
