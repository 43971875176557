import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewSpecialization from '../../components/Specializations/Modals/ModalNewSpecialization';
import ModalEditSpecialization from '../../components/Specializations/Modals/ModalEditSpecialization';
import ModalDeleteSpecialization from '../../components/Specializations/Modals/ModalDeleteSpecialization';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { SpecializationsParams } from '../../enums/params/libraries.params';
import { DELETE_SPECIALIZATION, UPDATE_SPECIALIZATION } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { SpecializationInfoType } from '../../types/libraries';

function Specializations({
  getSpecializationsList,
  createNewSpecialization,
  editSpecialization,
  deleteSpecialization,
  setSpecializationsParams,
  specializationsError,
  isLoading,
  tableData,
  sortParams,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const [modalNewSpecializationIsOpen, setModalNewSpecializationIsOpen] = useState(false);
  const [modalEditSpecializationIsOpen, setModalEditSpecializationIsOpen] = useState(false);
  const [modalDeleteSpecializationIsOpen, setModalDeleteSpecializationIsOpen] = useState(false);
  const [specializationClicked, setSpecializationClicked] = useState<SpecializationInfoType>({
    name: '',
    id: '',
  });

  useEffect(() => {
    getSpecializationsList();
    return () => {
      resetState();
    };
  }, []);

  const intl = useIntl();

  const openNewSpecializationModal = useCallback(() => {
    setModalNewSpecializationIsOpen(true);
  }, []);

  const closeNewSpecializationModal = useCallback(() => {
    setModalNewSpecializationIsOpen(false);
  }, []);

  const openEditSpecializationModal = useCallback(() => {
    setModalEditSpecializationIsOpen(true);
  }, []);

  const closeEditSpecializationModal = useCallback(() => {
    setModalEditSpecializationIsOpen(false);
  }, []);

  const openDeleteSpecializationModal = useCallback(() => {
    setModalDeleteSpecializationIsOpen(true);
  }, []);

  const closeDeleteSpecializationModal = useCallback(() => {
    setModalDeleteSpecializationIsOpen(false);
  }, []);

  const handleSort = useCallback((sortBy, direction) => setSpecializationsParams({ sortBy, direction }), []);

  const setSpecializationCallback = useCallback(() => {
    setSpecializationClicked({
      name: '',
      id: '',
    });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: SpecializationInfoType) => row.name,
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: SpecializationInfoType) => {
          setSpecializationClicked(row);
          openEditSpecializationModal();
        },
        verifiablePolicies: [UPDATE_SPECIALIZATION],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: SpecializationInfoType) => {
          setSpecializationClicked(row);
          openDeleteSpecializationModal();
        },
        verifiablePolicies: [DELETE_SPECIALIZATION],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper">
              <AccessChecker verifiablePolicies={[UPDATE_SPECIALIZATION]}>
                <Button externalClass={'button--with-icon'} onClick={openNewSpecializationModal}>
                  <Icon iconName={'plus'} externalClass={'button__icon'} />
                  <span className="button__text">
                    <FormattedMessage {...messages.newButton} />
                  </span>
                </Button>
              </AccessChecker>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            params={sortParams}
            loading={isLoading.getSpecializations}
            onSort={handleSort}
            error={specializationsError}
            tableActions={tableActions}
          />
        </div>
      </div>
      {modalNewSpecializationIsOpen && (
        <ModalNewSpecialization
          isOpen
          onCloseRequest={closeNewSpecializationModal}
          createNewSpecialization={createNewSpecialization}
          specializationError={specializationsError}
          isLoading={isLoading.createSpecialization}
          resetErrors={resetErrors}
        />
      )}
      {modalEditSpecializationIsOpen && (
        <ModalEditSpecialization
          isOpen
          onCloseRequest={closeEditSpecializationModal}
          editSpecialization={editSpecialization}
          specializationData={specializationClicked}
          specializationError={specializationsError}
          isLoading={isLoading.editSpecialization}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteSpecializationIsOpen && (
        <ModalDeleteSpecialization
          isOpen
          onCloseRequest={closeDeleteSpecializationModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteSpecialization({
              ...data,
              setSpecializationCallback,
            });
          }}
          isLoading={isLoading.deleteSpecialization}
          specializationError={specializationsError}
          specializationData={specializationClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries }: RootState) => ({
  tableData: libraries.specializationsTableData?.content,
  specializationsError: libraries.errors.specializationError,
  isLoading: libraries.loading,
  sortParams: libraries.specializationsParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSpecializationsList: () => dispatch(librariesActions.getSpecializationsList()),
  deleteSpecialization: (data: Record<string, unknown>) => dispatch(librariesActions.deleteSpecialization(data)),
  createNewSpecialization: (data: Record<string, unknown>) => dispatch(librariesActions.createNewSpecialization(data)),
  editSpecialization: (id: string, data: Record<string, unknown>) =>
    dispatch(librariesActions.editSpecialization({ ...data, id: id })),
  setSpecializationsParams: (params: Partial<SpecializationsParams>) =>
    dispatch(librariesActions.setSpecializationsParams(params)),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Specializations);
