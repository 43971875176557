import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../constants/recognitions.constants';
import * as api from '../api/recognitions.api';
import { AnyAction } from 'redux';
import { getQueryParams } from '../utils/params.utils';
import * as paramsTypes from '../enums/params/recognitions.params';
import { GET_USER_INFO_REQUEST } from '../constants/users.constants';

function* getRecognitionTypeList() {
  try {
    const params: paramsTypes.RecognitionTypesParams = yield select(
      (state: RootState) => state.recognitions.recognitionTypesParams,
    );
    const data = getQueryParams(params);
    const { success, results } = yield call(api.getRecognitionTypeList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_RECOGNITION_TYPE_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_RECOGNITION_TYPE_LIST_FAILURE, payload: error });
  }
}

function* createNewRecognitionType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createNewRecognitionType, payload.data);

    if (success) {
      yield put({ type: ActionTypes.POST_RECOGNITION_TYPE_SUCCESS });
      payload.callback();
      yield call(getRecognitionTypeList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_RECOGNITION_TYPE_FAILURE, payload: error });
  }
}

function* deleteRecognitionType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteRecognitionType, payload.data);

    if (success) {
      yield put({ type: ActionTypes.DELETE_RECOGNITION_TYPE_SUCCESS });
      payload.callback();
      payload.setRecognitionTypeCallback();
      yield call(getRecognitionTypeList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_RECOGNITION_TYPE_FAILURE, payload: error });
  }
}

function* editRecognitionType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editRecognitionType, payload.id, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_RECOGNITION_TYPE_SUCCESS });
      payload.callback();
      yield call(getRecognitionTypeList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_RECOGNITION_TYPE_FAILURE, payload: error });
  }
}

function* getAllRecognition() {
  try {
    const params: paramsTypes.RecognitionsParams = yield select(
      (state: RootState) => state.recognitions.recognitionsParams,
    );
    const data = getQueryParams(params);

    const { success, results } = yield call(api.getAllRecognitions, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_ALL_RECOGNITION_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ALL_RECOGNITION_FAILURE, payload: error });
  }
}

function* deleteRecognition({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteRecognition, payload.data.recognitionId);

    if (success) {
      yield put({ type: ActionTypes.DELETE_RECOGNITION_SUCCESS });
      payload.callback();
      payload.setRecognitionCallback();
      yield call(getAllRecognition);
      // @ts-ignore
      yield call(getRecognitionByUser, { payload: payload.data.targetUserId });
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_RECOGNITION_FAILURE, payload: error });
  }
}

function* editRecognition({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editRecognition, payload.data);

    if (success) {
      yield put({ type: ActionTypes.PUT_RECOGNITION_SUCCESS });
      payload.callback();
      yield call(getAllRecognition);
      // @ts-ignore
      yield call(getRecognitionByUser, { payload: payload.data.targetUser.id });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_RECOGNITION_FAILURE, payload: error });
  }
}

function* getRecognitionByUser({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getRecognitionByUser, payload);
    if (success) {
      yield put({
        type: ActionTypes.GET_RECOGNITION_BY_USER_SUCCESS,
        payload: results,
      });

      yield put({ type: GET_USER_INFO_REQUEST, payload: payload });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_RECOGNITION_BY_USER_FAILURE, payload: error });
  }
}

function* createRecognition({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createUserRecognition, payload.data);
    if (success) {
      yield put({ type: ActionTypes.POST_RECOGNITION_USER_SUCCESS });
      payload.callback();
      // @ts-ignore
      yield call(getAllRecognition, { payload: payload.filterValues });
      // @ts-ignore
      yield call(getRecognitionByUser, { payload: payload.data.targetUser.id });
      yield call(getAllRecognition);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_RECOGNITION_USER_FAILURE, payload: error });
  }
}

export default function* recognitionSaga() {
  yield all([
    takeEvery(ActionTypes.GET_RECOGNITION_TYPE_LIST_REQUEST, getRecognitionTypeList),
    takeEvery(ActionTypes.SET_RECOGNITION_TYPES_PARAMS_REQUEST, getRecognitionTypeList),
    takeEvery(ActionTypes.SET_RECOGNITION_PARAMS_REQUEST, getAllRecognition),
    takeEvery(ActionTypes.POST_RECOGNITION_TYPE_REQUEST, createNewRecognitionType),
    takeEvery(ActionTypes.DELETE_RECOGNITION_TYPE_REQUEST, deleteRecognitionType),
    takeEvery(ActionTypes.PUT_RECOGNITION_TYPE_REQUEST, editRecognitionType),
    takeEvery(ActionTypes.GET_ALL_RECOGNITION_REQUEST, getAllRecognition),
    takeEvery(ActionTypes.DELETE_RECOGNITION_REQUEST, deleteRecognition),
    takeEvery(ActionTypes.PUT_RECOGNITION_REQUEST, editRecognition),
    takeEvery(ActionTypes.GET_RECOGNITION_BY_USER_REQUEST, getRecognitionByUser),
    takeEvery(ActionTypes.POST_RECOGNITION_USER_REQUEST, createRecognition),
  ]);
}
