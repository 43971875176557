import { exportToXLSLabel, exportToPDFLabel } from './../../i18n/defaultMessage';
import { defineMessages } from 'react-intl';

export default defineMessages({
  exportToXLSLabel,
  exportToPDFLabel,
  pageTitle: {
    id: 'finance.incomes.expenses.report.title.incomes.expenses.report',
    defaultMessage: 'Incomes/Expenses Report',
  },
});
