import React from 'react';
import classNames from 'classnames';
import Loader from 'react-loader-spinner';

type LoaderProps = {
  height?: number | string;
  width?: number | string;
  color?: string;
  secondaryColor?: string;
  timeout?: number;
  radius?: number;
  externalClass?: string;
  type?: string;
};

function CustomLoader({ externalClass, type = 'TailSpin', ...props }: LoaderProps) {
  // @ts-ignore
  return <Loader type={type} className={classNames(externalClass, 'loader')} {...props} />;
}

export default CustomLoader;
