import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { DATE_FORMAT } from '../../../../../constants/date.constants';
import { EventPreviewInfo } from '../../../../../enums/schedule.enum';
import { useClickOutsideHandler } from '../../../../../utils/hooks.utils';
import Icon from '../../../../Icon';
import CustomLoader from '../../../../Loader';
import messages from '../../../messages';
import { sortedPopoverEvents } from '../../utils';
import Event from './Event';

type PopoverProps = {
  popoverRef: any;
  currentDate: string;
  styles: React.CSSProperties;
  events: EventPreviewInfo[];
  isLoadingGetEventList: boolean;
  handleCloseDayPopover: (e?: any) => void;
  openEventModal: (event: EventPreviewInfo, e: React.MouseEvent) => void;
  handleMoveToDayView: (date: string) => void;
  setPopoverCurrentDate: (date: string) => void;
  resetEvents: () => void;
};

function Popover({
  popoverRef,
  currentDate,
  events,
  styles,
  isLoadingGetEventList,
  handleCloseDayPopover,
  openEventModal,
  handleMoveToDayView,
  setPopoverCurrentDate,
  resetEvents,
}: PopoverProps) {
  useClickOutsideHandler(handleCloseDayPopover, popoverRef);
  const handleTitleClick = () => {
    handleMoveToDayView(currentDate);
    setPopoverCurrentDate('');
  };

  useEffect(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };

    return () => {
      window.onscroll = () => null;
    };
  }, []);

  useEffect(() => {
    return () => {
      resetEvents();
    };
  }, []);

  const bodyClassName = classNames('fc-popover-body', { overflow: !isLoadingGetEventList && !isEmpty(events) });

  return (
    <div className="fc-popover" ref={popoverRef} style={styles}>
      <div className="fc-popover-header">
        <span className="fc-popover-title" onClick={handleTitleClick}>
          {moment(currentDate).format(DATE_FORMAT.ddd_D)}
        </span>
        <button
          className="fc-popover-close modal__options-btn modal__close"
          title="Close"
          onClick={handleCloseDayPopover}
        >
          <Icon iconName="times-circle" externalClass="modal__options-icon" />
        </button>
      </div>
      {isLoadingGetEventList ? (
        <div className="fc-popover-loader">
          <CustomLoader />
        </div>
      ) : (
        <div className={bodyClassName}>
          {!isEmpty(events) ? (
            sortedPopoverEvents(events).map(event => (
              <Event key={event.id} event={event} openEventModal={openEventModal} />
            ))
          ) : (
            <span className="tabs__no-content-title">
              <FormattedMessage {...messages.noEventsLabel} />
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default Popover;
