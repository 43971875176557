import React, { useMemo } from 'react';
import Input, { InputProps } from '../Input';
import Icon from '../Icon';

type NumberInputProps = Omit<InputProps, 'type'> & {
  onCountUp?: () => void;
  onCountDown?: () => void;
  disabled?: boolean;
  type?: string;
};

function NumberInput({ onCountUp, onCountDown, disabled, type = 'number', ...props }: NumberInputProps) {
  const control = useMemo(
    () => (
      <div className="form__input__counter-btns">
        <Icon iconName="sort" externalClass={'modal__options-icon'} />
        <button className="form__input__counter" type="button" onClick={onCountUp} disabled={disabled} />
        <button className="form__input__counter" type="button" onClick={onCountDown} disabled={disabled} />
      </div>
    ),
    [onCountUp, onCountDown],
  );

  return <Input {...props} type={type} control={control} disabled={disabled} />;
}

export default NumberInput;
