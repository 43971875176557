import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/Candidates/candidates.constants';
import * as paramsTypes from '../enums/params/candidates.params';
import { RejectValueErrors } from '../enums/error.enum';
import { getReducerErrors } from '../utils/reducerError.utils';
import { Candidate, CandidateComment, CandidateStatus, InitialCandidateType } from '../enums/candidates.enums';
import { getCandidateCommentsContent, getCandidateCommentsContentAfterPost } from '../utils/candidate.utils';

type InitialStateType = {
  candidateStatusesList: any;
  candidateSpecializationsList: any;
  candidateTechnologiesList: any;
  candidateReceivingSourcesList: any;
  candidateList: any;
  candidateStatusesParams: paramsTypes.CandidateStatusParams;
  candidateSpecializationsParams: paramsTypes.CandidateSpecializationParams;
  candidateTechnologiesParams: paramsTypes.CandidateTechnologyParams;
  candidateReceivingSourcesParams: paramsTypes.CandidateReceivingSourceParams;
  candidateParams: paramsTypes.CandidateParams;
  candidateInfo: Candidate;
  comments: {
    content: CandidateComment[];
    number: number;
    last: false;
    size: number;
    totalElements: number;
  };
  errors: {
    candidateStatusesListError: null | string;
    candidateStatusError: null | string | RejectValueErrors[];
    candidateSpecializationsListError: null | string;
    candidateSpecializationError: null | string | RejectValueErrors[];
    candidateTechnologiesListError: null | string;
    candidateTechnologyError: null | string | RejectValueErrors[];
    candidateReceivingSourcesListError: null | string;
    candidateReceivingSourceError: null | string | RejectValueErrors[];
    candidateListError: null | string;
    candidateError: null | string | RejectValueErrors[];
    candidateInfoError: null | string;
    photoError: null | string;
    commentsError: string | null;
  };
  loading: {
    getCandidateStatusesList: boolean;
    createCandidateStatus: boolean;
    editCandidateStatus: boolean;
    deleteCandidateStatus: boolean;
    getCandidateSpecializationsList: boolean;
    createCandidateSpecialization: boolean;
    editCandidateSpecialization: boolean;
    deleteCandidateSpecialization: boolean;
    getCandidateTechnologiesList: boolean;
    createCandidateTechnology: boolean;
    editCandidateTechnology: boolean;
    deleteCandidateTechnology: boolean;
    getReceivingSourcesList: boolean;
    createCandidateReceivingSource: boolean;
    editCandidateReceivingSource: boolean;
    deleteCandidateReceivingSource: boolean;
    getCandidateList: boolean;
    createCandidate: boolean;
    editCandidate: boolean;
    deleteCandidate: boolean;
    candidateInfo: boolean;
    photo: boolean;
    getComments: boolean;
    createComment: boolean;
    editComment: boolean;
    deleteComment: boolean;
  };
};

const initialState: InitialStateType = {
  candidateStatusesList: null,
  candidateSpecializationsList: null,
  candidateTechnologiesList: null,
  candidateReceivingSourcesList: null,
  candidateList: null,
  candidateStatusesParams: new paramsTypes.CandidateStatusParams(),
  candidateSpecializationsParams: new paramsTypes.CandidateSpecializationParams(),
  candidateTechnologiesParams: new paramsTypes.CandidateTechnologyParams(),
  candidateReceivingSourcesParams: new paramsTypes.CandidateReceivingSourceParams(),
  candidateParams: new paramsTypes.CandidateParams(),
  candidateInfo: new Candidate(),
  comments: {
    content: [],
    number: 0,
    last: false,
    size: 15,
    totalElements: 0,
  },
  errors: {
    candidateStatusesListError: null,
    candidateStatusError: null,
    candidateSpecializationsListError: null,
    candidateSpecializationError: null,
    candidateTechnologiesListError: null,
    candidateTechnologyError: null,
    candidateReceivingSourcesListError: null,
    candidateReceivingSourceError: null,
    candidateListError: null,
    candidateError: null,
    candidateInfoError: null,
    photoError: null,
    commentsError: null,
  },
  loading: {
    getCandidateStatusesList: false,
    createCandidateStatus: false,
    editCandidateStatus: false,
    deleteCandidateStatus: false,
    getCandidateSpecializationsList: false,
    createCandidateSpecialization: false,
    editCandidateSpecialization: false,
    deleteCandidateSpecialization: false,
    getCandidateTechnologiesList: false,
    createCandidateTechnology: false,
    editCandidateTechnology: false,
    deleteCandidateTechnology: false,
    getReceivingSourcesList: false,
    createCandidateReceivingSource: false,
    editCandidateReceivingSource: false,
    deleteCandidateReceivingSource: false,
    getCandidateList: false,
    createCandidate: false,
    editCandidate: false,
    deleteCandidate: false,
    candidateInfo: false,
    photo: false,
    getComments: false,
    createComment: false,
    editComment: false,
    deleteComment: false,
  },
};

const candidatesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.RESET_CANDIDATES_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_CANDIDATES_STATE:
      return {
        ...initialState,
        candidateStatusesParams: state.candidateStatusesParams,
        candidateParams: state.candidateParams,
      };

    case ActionTypes.GET_CANDIDATE_STATUSES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCandidateStatusesList: true },
      };

    case ActionTypes.GET_CANDIDATE_STATUSES_LIST_SUCCESS:
      return {
        ...state,
        candidateStatusesList: {
          ...action.payload,
          content: action.payload.content.map((item: Partial<CandidateStatus>) => new CandidateStatus(item)),
        },
        errors: { ...state.errors, candidateStatusesListError: null },
        loading: { ...state.loading, getCandidateStatusesList: false },
      };

    case ActionTypes.GET_CANDIDATE_STATUSES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, candidateStatusesListError: action.payload.message },
        loading: { ...state.loading, getCandidateStatusesList: false },
      };

    case ActionTypes.POST_CANDIDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createCandidateStatus: true },
      };

    case ActionTypes.POST_CANDIDATE_STATUS_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateStatusError: null },
        loading: { ...state.loading, createCandidateStatus: false },
      };

    case ActionTypes.POST_CANDIDATE_STATUS_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateStatusError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createCandidateStatus: false },
      };

    case ActionTypes.PUT_CANDIDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editCandidateStatus: true },
      };

    case ActionTypes.PUT_CANDIDATE_STATUS_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateStatusError: null },
        loading: { ...state.loading, editCandidateStatus: false },
      };

    case ActionTypes.PUT_CANDIDATE_STATUS_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateStatusError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editCandidateStatus: false },
      };

    case ActionTypes.DELETE_CANDIDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCandidateStatus: true },
      };

    case ActionTypes.DELETE_CANDIDATE_STATUS_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateStatusError: null },
        loading: { ...state.loading, deleteCandidateStatus: false },
      };

    case ActionTypes.DELETE_CANDIDATE_STATUS_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateStatusError: action.payload.message,
        },
        loading: { ...state.loading, deleteCandidateStatus: false },
      };

    case ActionTypes.SET_CANDIDATE_STATUSES_PARAMS:
      return {
        ...state,
        candidateStatusesParams: new paramsTypes.CandidateStatusParams({
          ...state.candidateStatusesParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getCandidateStatusesList: true },
      };

    case ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCandidateSpecializationsList: true },
      };

    case ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_LIST_SUCCESS:
      return {
        ...state,
        candidateSpecializationsList: action.payload,
        errors: { ...state.errors, candidateSpecializationsListError: null },
        loading: { ...state.loading, getCandidateSpecializationsList: false },
      };

    case ActionTypes.GET_CANDIDATE_SPECIALIZATIONS_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, candidateSpecializationsListError: action.payload.message },
        loading: { ...state.loading, getCandidateSpecializationsList: false },
      };

    case ActionTypes.POST_CANDIDATE_SPECIALIZATION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createCandidateSpecialization: true },
      };

    case ActionTypes.POST_CANDIDATE_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateSpecializationError: null },
        loading: { ...state.loading, createCandidateSpecialization: false },
      };

    case ActionTypes.POST_CANDIDATE_SPECIALIZATION_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateSpecializationError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createCandidateSpecialization: false },
      };

    case ActionTypes.PUT_CANDIDATE_SPECIALIZATION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editCandidateSpecialization: true },
      };

    case ActionTypes.PUT_CANDIDATE_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateSpecializationError: null },
        loading: { ...state.loading, editCandidateSpecialization: false },
      };

    case ActionTypes.PUT_CANDIDATE_SPECIALIZATION_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateSpecializationError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editCandidateSpecialization: false },
      };

    case ActionTypes.DELETE_CANDIDATE_SPECIALIZATION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCandidateSpecialization: true },
      };

    case ActionTypes.DELETE_CANDIDATE_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateSpecializationError: null },
        loading: { ...state.loading, deleteCandidateSpecialization: false },
      };

    case ActionTypes.DELETE_CANDIDATE_SPECIALIZATION_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateSpecializationError: action.payload.message,
        },
        loading: { ...state.loading, deleteCandidateSpecialization: false },
      };

    case ActionTypes.SET_CANDIDATE_SPECIALIZATION_PARAMS:
      return {
        ...state,
        candidateSpecializationsParams: new paramsTypes.CandidateSpecializationParams({
          ...state.candidateSpecializationsParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getCandidateSpecializationsList: true },
      };

    case ActionTypes.GET_CANDIDATE_TECHNOLOGIES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCandidateTechnologiesList: true },
      };

    case ActionTypes.GET_CANDIDATE_TECHNOLOGIES_LIST_SUCCESS:
      return {
        ...state,
        candidateTechnologiesList: action.payload,
        errors: { ...state.errors, candidateTechnologiesListError: null },
        loading: { ...state.loading, getCandidateTechnologiesList: false },
      };

    case ActionTypes.GET_CANDIDATE_TECHNOLOGIES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, candidateTechnologiesListError: action.payload.message },
        loading: { ...state.loading, getCandidateTechnologiesList: false },
      };

    case ActionTypes.POST_CANDIDATE_TECHNOLOGY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createCandidateTechnology: true },
      };

    case ActionTypes.POST_CANDIDATE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateTechnologyError: null },
        loading: { ...state.loading, createCandidateTechnology: false },
      };

    case ActionTypes.POST_CANDIDATE_TECHNOLOGY_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateTechnologyError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createCandidateTechnology: false },
      };

    case ActionTypes.PUT_CANDIDATE_TECHNOLOGY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editCandidateTechnology: true },
      };

    case ActionTypes.PUT_CANDIDATE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateTechnologyError: null },
        loading: { ...state.loading, editCandidateTechnology: false },
      };

    case ActionTypes.PUT_CANDIDATE_TECHNOLOGY_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateTechnologyError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editCandidateTechnology: false },
      };

    case ActionTypes.DELETE_CANDIDATE_TECHNOLOGY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCandidateTechnology: true },
      };

    case ActionTypes.DELETE_CANDIDATE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateTechnologyError: null },
        loading: { ...state.loading, deleteCandidateTechnology: false },
      };

    case ActionTypes.DELETE_CANDIDATE_TECHNOLOGY_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateTechnologyError: action.payload.message,
        },
        loading: { ...state.loading, deleteCandidateTechnology: false },
      };

    case ActionTypes.SET_CANDIDATE_TECHNOLOGY_PARAMS:
      return {
        ...state,
        candidateTechnologiesParams: new paramsTypes.CandidateTechnologyParams({
          ...state.candidateTechnologiesParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getCandidateTechnologiesList: true },
      };

    case ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getReceivingSourcesList: true },
      };

    case ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_LIST_SUCCESS:
      return {
        ...state,
        candidateReceivingSourcesList: action.payload,
        errors: { ...state.errors, candidateReceivingSourcesListError: null },
        loading: { ...state.loading, getReceivingSourcesList: false },
      };

    case ActionTypes.GET_CANDIDATE_RECEIVING_SOURCES_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, candidateReceivingSourcesListError: action.payload.message },
        loading: { ...state.loading, getReceivingSourcesList: false },
      };

    case ActionTypes.POST_CANDIDATE_RECEIVING_SOURCE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createCandidateReceivingSource: true },
      };

    case ActionTypes.POST_CANDIDATE_RECEIVING_SOURCE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateReceivingSourceError: null },
        loading: { ...state.loading, createCandidateReceivingSource: false },
      };

    case ActionTypes.POST_CANDIDATE_RECEIVING_SOURCE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateReceivingSourceError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createCandidateReceivingSource: false },
      };

    case ActionTypes.PUT_CANDIDATE_RECEIVING_SOURCE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editCandidateReceivingSource: true },
      };

    case ActionTypes.PUT_CANDIDATE_RECEIVING_SOURCE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateReceivingSourceError: null },
        loading: { ...state.loading, editCandidateReceivingSource: false },
      };

    case ActionTypes.PUT_CANDIDATE_RECEIVING_SOURCE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateReceivingSourceError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editCandidateReceivingSource: false },
      };

    case ActionTypes.DELETE_CANDIDATE_RECEIVING_SOURCE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCandidateReceivingSource: true },
      };

    case ActionTypes.DELETE_CANDIDATE_RECEIVING_SOURCE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateReceivingSourceError: null },
        loading: { ...state.loading, deleteCandidateReceivingSource: false },
      };

    case ActionTypes.DELETE_CANDIDATE_RECEIVING_SOURCE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateReceivingSourceError: action.payload.message,
        },
        loading: { ...state.loading, deleteCandidateReceivingSource: false },
      };

    case ActionTypes.SET_CANDIDATE_RECEIVING_SOURCE_PARAMS:
      return {
        ...state,
        candidateReceivingSourcesParams: new paramsTypes.CandidateReceivingSourceParams({
          ...state.candidateReceivingSourcesParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getReceivingSourcesList: true },
      };

    case ActionTypes.GET_CANDIDATE_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCandidateList: true },
      };

    case ActionTypes.GET_CANDIDATE_LIST_SUCCESS:
      return {
        ...state,
        candidateList: {
          ...action.payload,
          content: action.payload.content.map((item: Partial<InitialCandidateType>) => new Candidate(item)),
        },
        errors: { ...state.errors, candidateListError: null },
        loading: { ...state.loading, getCandidateList: false },
      };

    case ActionTypes.GET_CANDIDATE_LIST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, candidateListError: action.payload.message },
        loading: { ...state.loading, getCandidateList: false },
      };

    case ActionTypes.POST_CANDIDATE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createCandidate: true },
      };

    case ActionTypes.POST_CANDIDATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateError: null },
        loading: { ...state.loading, createCandidate: false },
      };

    case ActionTypes.POST_CANDIDATE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, createCandidate: false },
      };

    case ActionTypes.PUT_CANDIDATE_REQUEST:
    case ActionTypes.PUT_CANDIDATE_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editCandidate: true },
      };

    case ActionTypes.PUT_CANDIDATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateError: null },
        loading: { ...state.loading, editCandidate: false },
      };

    case ActionTypes.PUT_CANDIDATE_INFO_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateError: null },
        loading: { ...state.loading, editCandidate: false },
        candidateInfo: new Candidate(action.payload),
      };

    case ActionTypes.PUT_CANDIDATE_FAILURE:
    case ActionTypes.PUT_CANDIDATE_INFO_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateError: getReducerErrors(action.payload),
        },
        loading: { ...state.loading, editCandidate: false },
      };

    case ActionTypes.DELETE_CANDIDATE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCandidate: true },
      };

    case ActionTypes.DELETE_CANDIDATE_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateError: null },
        loading: { ...state.loading, deleteCandidate: false },
      };

    case ActionTypes.DELETE_CANDIDATE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateError: action.payload.message,
        },
        loading: { ...state.loading, deleteCandidate: false },
      };

    case ActionTypes.SET_CANDIDATE_PARAMS:
      return {
        ...state,
        candidateParams: new paramsTypes.CandidateParams({
          ...state.candidateParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getCandidateList: true },
      };

    case ActionTypes.GET_CANDIDATE_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, candidateInfo: true },
      };

    case ActionTypes.GET_CANDIDATE_INFO_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, candidateInfoError: null },
        loading: { ...state.loading, candidateInfo: false },
        candidateInfo: new Candidate(action.payload),
      };

    case ActionTypes.GET_CANDIDATE_INFO_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          candidateInfoError: action.payload.message,
        },
        loading: { ...state.loading, candidateInfo: false },
      };

    case ActionTypes.RESET_CANDIDATE_INFO:
      return {
        ...state,
        candidateInfo: new Candidate(),
      };

    case ActionTypes.POST_CANDIDATE_PHOTO_REQUEST:
    case ActionTypes.DELETE_CANDIDATE_PHOTO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, photo: true },
        errors: { ...state.errors, photoError: null },
      };

    case ActionTypes.POST_CANDIDATE_PHOTO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, photo: false },
        errors: { ...state.errors, photoError: null },
        candidateInfo: new Candidate({ ...state.candidateInfo, photo: action.payload.results }),
      };

    case ActionTypes.DELETE_CANDIDATE_PHOTO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, photo: false },
        errors: { ...state.errors, photoError: null },
        candidateInfo: new Candidate({ ...state.candidateInfo, photo: null }),
      };

    case ActionTypes.POST_CANDIDATE_PHOTO_FAILURE:
    case ActionTypes.DELETE_CANDIDATE_PHOTO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, photo: false },
        errors: { ...state.errors, photoError: action.payload.message },
      };

    case ActionTypes.GET_CANDIDATE_COMMENTS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getComments: true },
        errors: { ...state.errors, commentsError: null },
      };

    case ActionTypes.GET_CANDIDATE_COMMENTS_SUCCESS: {
      const { results, afterDelete } = action.payload;
      return {
        ...state,
        loading: { ...state.loading, getComments: false },
        errors: { ...state.errors, commentsError: null },
        comments: getCandidateCommentsContent(state.comments, results, afterDelete),
      };
    }

    case ActionTypes.GET_CANDIDATE_COMMENTS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getComments: false },
        errors: { ...state.errors, commentsError: action.payload.message },
      };

    case ActionTypes.POST_CANDIDATE_COMMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createComment: true },
      };

    case ActionTypes.POST_CANDIDATE_COMMENT_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, createComment: false },
        comments: getCandidateCommentsContentAfterPost(state.comments, action.payload),
        errors: { ...state.errors, commentsError: null },
      };
    }

    case ActionTypes.POST_CANDIDATE_COMMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createComment: false },
        errors: {
          ...state.errors,
          commentsError: action.payload.message,
        },
      };

    case ActionTypes.PUT_CANDIDATE_COMMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editComment: true },
      };

    case ActionTypes.PUT_CANDIDATE_COMMENT_SUCCESS: {
      const comments = state.comments;
      return {
        ...state,
        loading: { ...state.loading, editComment: false },
        comments: {
          ...comments,
          content: comments.content.map((comment: CandidateComment) =>
            comment.id === action.payload.id ? new CandidateComment(comment.commentPage, action.payload) : comment,
          ),
        },
        errors: { ...state.errors, commentsError: null },
      };
    }

    case ActionTypes.PUT_CANDIDATE_COMMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editComment: false },
        errors: { ...state.errors, commentsError: action.payload.message },
      };

    case ActionTypes.DELETE_CANDIDATE_COMMENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteComment: true },
      };

    case ActionTypes.DELETE_CANDIDATE_COMMENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteComment: false },
        errors: { ...state.errors, commentsError: null },
      };

    case ActionTypes.DELETE_CANDIDATE_COMMENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteComment: false },
        errors: {
          ...state.errors,
          commentsError: action.payload.message,
        },
      };

    default:
      return state;
  }
};

export default candidatesReducer;
