import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose, PreloadedState } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import rootSaga from '../sagas';
import rootReducer from '../reducers';
import ENV from '../constants/env.constants';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware, routerMiddleware(history)];
if (ENV.ENVIRONMENT === 'development') {
  middleware.push(
    createLogger({
      collapsed: true,
    }),
  );
}

const enhancers = [applyMiddleware(...middleware)];
const composeEnhancers = ((window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

export default (preloadedState?: PreloadedState<any>) => {
  const store = createStore(rootReducer(history), preloadedState, composeEnhancers(...enhancers));

  sagaMiddleware.run(rootSaga);
  return store;
};
