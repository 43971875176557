import React from 'react';
import { ReactComponent as MessengerIcons } from '../../../assets/svg/messenger-icons.svg';
import classNames from 'classnames';

type MessengerIconProps = {
  iconName: string;
  externalClass?: string;
};

function MessengerIcon({ iconName, externalClass }: MessengerIconProps) {
  const defaultClass = classNames('icons', {
    [iconName.toLowerCase()]: Boolean(iconName),
    [externalClass as string]: Boolean(externalClass),
  });

  return (
    <>
      <MessengerIcons />

      <svg className={defaultClass}>
        <use xlinkHref={`#${iconName.toLowerCase()}`} />
      </svg>
    </>
  );
}

export default MessengerIcon;
