import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import FiltersControl, { FilterOptionType } from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import FilterClearButton from '../../FilterClearButton';
import { ProjectGroupsParams } from '../../../enums/params/planning.params';
import SortSelect from '../../SortSelect';
import { SortParams } from '../../../enums/params.enum';

type ProjectGroupsFilterProps = {
  jiraProjectOptions: FilterOptionType[];
  values: ProjectGroupsParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleParamsChange: (name: string) => (data: OptionTypeBase) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  handleSort: (sortBy: string, direction: string) => void;
  showClearButton: boolean;
};

const ProjectGroupsFilter = ({
  jiraProjectOptions,
  values,
  handleMultiParamsChange,
  handleParamsChange,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  handleSort,
  showClearButton,
}: ProjectGroupsFilterProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const statusesOptions = useMemo(
    () => [
      { value: null, label: intl.formatMessage(messages.allLabel) },
      { value: true, label: intl.formatMessage(messages.activeLabel) },
      { value: false, label: intl.formatMessage(messages.inactiveLabel) },
    ],
    [],
  );
  const jiraProjectValues = useFiltersListValue(jiraProjectOptions, values.jiraProjects);

  const statusesValues = useMemo(() => statusesOptions.find(el => values.status === el.value), [values.status]);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, []);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <SortSelect
        sortOptions={[
          { label: intl.formatMessage(messages.fullNameTitle), value: 'fullName' },
          { label: intl.formatMessage(messages.shortNameTitle), value: 'shortName' },
          { label: intl.formatMessage(messages.alertsTitle), value: 'alertsStatus' },
          { label: intl.formatMessage(messages.statusLabel), value: 'activeStatus' },
        ]}
        params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
        onSort={handleSort}
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.projectsLabel)}
        options={jiraProjectOptions}
        value={jiraProjectValues}
        handleChange={handleMultiParamsChange(FilterParamsName.JIRA_PROJECTS)}
        externalClass="filters__select"
      />
      <Filter
        label={intl.formatMessage(messages.statusLabel)}
        options={statusesOptions}
        value={statusesValues}
        defaultValue={statusesOptions[0]}
        handleChange={handleParamsChange('status')}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.PROJECT_GROUPS_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
    </>
  );
};

export default React.memo(ProjectGroupsFilter);
